import { LoadingICards } from 'components/IoseLoader';
import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseEnergyBillsCardValuesBarChart({ loading, data }) {
  const totalBillValue = data?.totalBillValue;
  const totalRecalcValue = data?.totalRecalcValue;

  const option = {
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: ['Comparativo'],
        axisTick: {
          alignWithLabel: true,
        },
        show: false,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
      },
    ],
    series: [
      {
        name: 'Fatura (R$)',
        type: 'bar',
        // barWidth: '40%',
        data: [totalBillValue?.toFixed(2)],
        color: '#fff',
        itemStyle: {
          emphasis: {
            barBorderRadius: [10, 10],
          },
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
        },
      },
      {
        name: 'Recálculo (R$)',
        type: 'bar',
        // barWidth: '40%',
        data: [totalRecalcValue?.toFixed(2)],
        color: '#015A9E',
        itemStyle: {
          emphasis: {
            barBorderRadius: [10, 10],
          },
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
        },
      },
    ],
  };

  return (
    <>
      {loading ? (
        <LoadingICards loading={loading} />
      ) : (
        <ReactECharts
          option={option}
          style={{ height: 300 }}
          key={JSON.stringify(option)}
        />
      )}
    </>
  );
}
