import React from 'react';

//Imports from Style
import { Container } from './style';

//Imports from IOSE components
import { IoseBoltButton } from 'components/IoseButtonIcon';
import { IoseAlertWarning } from 'components/IoseAlert';

/**
 * This component is a simple warning card that indicates there are circuits without switchboards
 */
export default function IoseSwitchboardIntegrateCard() {
  return (
    <Container>
      <IoseAlertWarning>
        <p>
          Alguns circuitos encontram-se sem quadro por favor clique no botão{' '}
          <IoseBoltButton />
          para cadastrá-los em um quadro. Caso não tenha um quadro cadastrado,
          crie um primeiramente.
        </p>
      </IoseAlertWarning>
    </Container>
  );
}
