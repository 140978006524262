import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  Grow,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from 'components/IoseLoader';
import NoDataMessage from 'components/Messages/NoDataMessage';
import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

const UC_COPEL = 10;

export default function CreditosBeneficiariaTable({ loading, data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(UC_COPEL);

  const allBeneficiaries = data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const limitedData = allBeneficiaries
    ? allBeneficiaries.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : [];

  if (data && data?.length === 0) {
    return <NoDataMessage message="Ops... Parece que não existem dados!" />;
  }

  const TableRow = ({ beneficia }) => {
    const {
      uc_beneficiaria,
      mes_cred_utilizado,
      cred_recebido,
      city,
      general_management,
      regional_management,
    } = beneficia;

    return (
      <TableRowStyled align="center" title="Unidades Beneficiárias">
        <TableCellCentered>{uc_beneficiaria}</TableCellCentered>
        <TableCellCentered>{mes_cred_utilizado}</TableCellCentered>
        <TableCellCentered>{cred_recebido}</TableCellCentered>
        <TableCellCentered>{city}</TableCellCentered>
        <TableCellCentered>{general_management}</TableCellCentered>
        <TableCellCentered>{regional_management}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = () => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UC</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            MÊS CRÉDITO UTILIZADO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>CRÉDITOS</TableHeaderCellCentered>
          <TableHeaderCellCentered>CIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>GERÊNCIA GERAL</TableHeaderCellCentered>
          <TableHeaderCellCentered>GERÊNCIA REGIONAL</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {limitedData.map((beneficiaria, index) => {
          return <TableRow key={index} beneficia={beneficiaria} />;
        })}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} timeout={500}>
      <Paper>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            <Table />
            <TablePagination
              component="div"
              count={allBeneficiaries?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </>
        )}
      </Paper>
    </Grow>
  );
}
