/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AccountContext, useClient, LocalStorage, ioseAPI } from 'common';

import * as S from './style';
import {
  IoseButton,
  IoseMapRedirectButton,
  IoseSubHeaderBar,
  LoadingIndicator,
  NoDataMessage,
  ReportMessage,
  IoseAlertLoadingCards,
  IoseSelectReferenceMonth,
  IoseDatePicker,
} from 'components';

import {
  useCreateLogs,
  useGroupPermissions,
  useUniqueRegionalManagement,
  useConsumerGroup,
} from 'hooks';

import DateErrorMessage from 'components/Messages/DateErrorMessage';

import Permissions from 'common/permissions';
import { xlsxReports } from 'reports/Xlsx';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { Form, Formik } from 'formik';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const formatToISODate = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
  }
  return '';
};

export default function FaturaReportContainer() {
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { consumerGroupsForSelect } = useConsumerGroup();
  const {
    regionalManagement: regionalManagementOptions,
  } = useUniqueRegionalManagement();
  const history = useHistory();
  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [noDataError, setNoDataError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [hasReportPermission, setHasReportPermission] = useState(true);
  const [filteredConsumerGroups, setFilteredConsumerGroups] = useState([]);

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const reportPath = ['relatorios', 'medicao', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);
    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Relatório de Faturas`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        ></div>
      </IoseSubHeaderBar>
    );
  };

  const fetchAllData = async (headers, filteredValues) => {
    let allData = [];
    let lastEvaluatedKey = null;

    do {
      try {
        const response = await ioseAPI.getFaturasForReports(
          headers,
          filteredValues,
          lastEvaluatedKey
        );
        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    } while (lastEvaluatedKey);

    return allData;
  };

  const cleanAll = (resetForm) => {
    resetForm();
    setSelectedDate(null);
  };

  const removeEmptyFields = (obj) => {
    const filteredObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        filteredObj[key] = obj[key];
      }
    });
    return filteredObj;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const { headers } = getSession();
    const isoValues = {
      ...values,
      dt_leitura_start: values.dt_leitura_start
        ? formatToISODate(new Date(values.dt_leitura_start))
        : '',
      dt_leitura_end: values.dt_leitura_end
        ? formatToISODate(new Date(values.dt_leitura_end))
        : '',
    };

    const filteredValues = removeEmptyFields(isoValues);

    try {
      const faturas = await fetchAllData(headers, filteredValues);
      if (faturas?.length > 0) {
        xlsxReports.exportFaturasToExcel(faturas);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setNoDataError(true);
    } finally {
      setLoading(false);
      cleanAll();
    }
  };

  const initialValues = {
    reference_month: null,
    dt_leitura_start: null,
    dt_leitura_end: null,
    regional_management: '',
    identification_number: '',
  };

  const handleRegionalManagementChange = (value) => {
    const filteredGroups = consumerGroupsForSelect.filter(
      (group) => group.gr === value
    );
    setFilteredConsumerGroups(filteredGroups);
  };

  const renderFilters2 = () => {
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, resetForm }) => {
          const isReferenceMonthSelected = !!values.reference_month;
          const areDatesSelected =
            !!values.dt_leitura_start || !!values.dt_leitura_end;

          return (
            <Form>
              <div style={{ display: 'flex', gap: '15px' }}>
                {!areDatesSelected && (
                  <IoseSelectReferenceMonth
                    label="REF:MÊS/ANO"
                    width="150px"
                    handleChange={(value) => {
                      const formattedDate = formatToBrazilianDate(value);
                      setFieldValue('reference_month', formattedDate);
                      setSelectedDate(value);
                      setFieldValue('dt_leitura_start', null);
                      setFieldValue('dt_leitura_end', null);
                    }}
                    selectedDate={selectedDate}
                    disabled={areDatesSelected}
                  />
                )}

                {!isReferenceMonthSelected && (
                  <>
                    <IoseDatePicker
                      label="Leitura Inicial"
                      handleChange={(date) =>
                        setFieldValue('dt_leitura_start', date)
                      }
                      initialSelectedDate={values.dt_leitura_start}
                      width="150px"
                    />

                    <IoseDatePicker
                      label="Leitura Final"
                      handleChange={(date) =>
                        setFieldValue('dt_leitura_end', date)
                      }
                      initialSelectedDate={values.dt_leitura_end}
                      width="150px"
                    />
                  </>
                )}

                <IoseDynamicSelect
                  label="GR"
                  value={values.regional_management}
                  onChange={(value) => {
                    setFieldValue('regional_management', value);
                    handleRegionalManagementChange(value);
                  }}
                  options={regionalManagementOptions}
                  width="150px"
                />

                <IoseDynamicSelect
                  label="UC"
                  value={values.identification_number}
                  onChange={(value) =>
                    setFieldValue('identification_number', value)
                  }
                  options={
                    filteredConsumerGroups.length > 0
                      ? filteredConsumerGroups
                      : consumerGroupsForSelect
                  }
                  width="150px"
                />

                <IoseButton type="submit">Download XLSX</IoseButton>

                <IoseRemoveButton onClick={() => cleanAll(resetForm)}>
                  LIMPAR FILTROS
                </IoseRemoveButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        {loading ? (
          <>
            <LoadingIndicator loading={loading} />
            <S.StyledLoadingMessage>
              Carregando dados e emitindo o relatório...
            </S.StyledLoadingMessage>
          </>
        ) : (
          <div>
            {dateError ? (
              <DateErrorMessage message="Ops! A data final não pode ser anterior a inicial." />
            ) : (
              <>
                {noDataError ? (
                  <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
                ) : (
                  <ReportMessage message="Para emitir o relatório, selecione os filtros desejados e clique em Download XLSX." />
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderFilters2()}
          {renderTableorImage()}
        </div>
      </>
    );
  };

  return (
    <S.WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReportPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão para emissão deste relatório!'
          }
        />
      )}
    </S.WrappedContainer>
  );
}
