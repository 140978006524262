import React, { useState, useContext, useEffect } from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ContainerCards } from 'containers/EnvironmentContainer/style';

import {
  IoseCardObjects,
  IoseObjectAddModalEnv,
  IoseObjectEditModalEnv,
  IoseSubHeaderBar,
  IoseSearch,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
} from 'components';

import { AccountContext, ioseAPI, Messages } from 'common';

import * as S from './style';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '400px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
}));

export default function IoseCircuitOpenObjects({
  open,
  onClose,
  selectedObject,
  group,
  getMoreUnitys,
  environments,
  allCircuits,
  getObjects,
  errorAlertText,
}) {
  const classes = useStyles();
  const { getSession } = useContext(AccountContext);

  const [addNotificationType, setAddNotificationType] = useState(0);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [cardSelected, setCardSelected] = useState(false);
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    getObjectsCards();
    //eslint-disable-next-line
  }, [selectedObject]);

  const getObjectsCards = () => {
    setCards(selectedObject?.objects);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = (environment) => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  async function addObjects(
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type
  ) {
    setAddNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.createObjects(
        sessionData.headers,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type
      );

      setLoading(false);
      setNotification(response.data.message);
      setAddNotificationType(1);
      setShowNotification(true);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingAddObjects(errorMessage);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseAddModal();
        getObjects();
        setAddNotificationType(0);
        setShowNotification(false);
        onClose();
      }, 2500);
    }
  }

  async function editObject(
    uuid_object,
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateObjects(
        sessionData.headers,
        uuid_object,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type
      );

      setNotification(response.data.message);
      setEditNotificationType(1);
      setShowNotification(true);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingEditUnity(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseEditModal();
        getObjects();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  const filterObjects = (searchName) => {
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      if (itemFiltered.length !== 0) {
        setCardsFiltered(itemFiltered);
      }
    } else {
      setSearch(false);
    }
  };

  const renderObjectsCards = ({
    uuid_environment,
    name,
    description,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type,
  }) => {
    return (
      <IoseCardObjects
        key={uuid_environment}
        uuid_environment={uuid_environment}
        name={name}
        description={description}
        voltage={voltage}
        power={power}
        current={current}
        temperature={temperature}
        condition={condition}
        charge_type={charge_type}
        handleOpenEditModal={handleOpenEditModal}
        onClose={handleCloseEditModal}
        environments={environments}
        onClick={() => handleCardClick(cards)}
        showEditButton={false}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={selectedObject?.name}
        subtitle="Objetos"
        description={'Total: ' + cards?.length}
        button={<></>}
      >
        <IoseSearch
          placeholder="Pesquisar objetos…"
          funcSearch={filterObjects}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllObjectsCards = () => {
    return (
      <ContainerCards>
        {search
          ? cardsFiltered?.map(renderObjectsCards)
          : cards?.map(renderObjectsCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (cards?.length === 0) {
      return <IoseAlertLoadingCards text={errorAlertText} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length}
            next={getMoreUnitys}
            hasMore={true}
            loading={loading}
            endList={false}
            scrollableTarget={'container'}
          >
            {renderAllObjectsCards()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseObjectAddModalEnv
          open={openAddModal}
          onClose={handleCloseAddModal}
          environments={environments}
          selectedObject={selectedObject}
          addObjects={addObjects}
          loading={loading}
          notification={notification}
          addNotificationType={addNotificationType}
          allCircuits={allCircuits}
          showNotification={showNotification}
        />
        <IoseObjectEditModalEnv
          open={openEditModal}
          onClose={handleCloseEditModal}
          environments={environments}
          selectedObject={selectedObject}
          editObject={editObject}
          loading={loading}
          notification={notification}
          editNotificationType={editNotificationType}
          cardSelected={cardSelected}
          allCircuits={allCircuits}
          showNotification={showNotification}
        />
      </>
    );
  };

  function errorHandlingAddObjects(error) {
    setLoading(false);

    setNotification(error);
    setAddNotificationType(2);
  }

  function errorHandlingEditUnity(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  function handleCardClick(card) {
    setCardSelected(card);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.HeaderModal>
            <S.LeftHeaderModal>
              <S.RoomIcon />
            </S.LeftHeaderModal>
            <S.RightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {selectedObject?.name.toUpperCase()}
              </S.ModalTitleHeader>
            </S.RightHeaderModal>
          </S.HeaderModal>

          <S.WrappedModal>
            <S.ObjectPaper>
              {renderSubHeaderAndSearchBar()}
              {renderContent()}
              {renderModals()}
            </S.ObjectPaper>
          </S.WrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
