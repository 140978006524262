import React from 'react';

import {
  IoseSubHeaderBarWithFilters,
  IoseAlertLoadingCards,
} from 'components';

import * as S from './style';
import { usePermission, useTelemetryData } from 'hooks';

import GraficoTotalDiario30Dias from './components/GraficoTotalDiario30Dias';
import IoseCardsTelemetry from 'components/Cards/IoseCardTelemetry';
import TelemetryDashFilter from './components/TelemetryDashFilter';
import GraficoFatorPotenciaGeralQ1 from './components/GraficoFatorPotenciaGeralQ1';

export default function TelemetryDashContainer() {
  const hasReadPermission = usePermission(['dashboards', 'faturas', 'leitura'])
  const {
    consumosDiarios,
    obterConsumoGraf30Dias ,
    setFinalDataConsumo,
    setInicialDataConsumo,
    inicialDataConsumo,
    finalDataConsumo,
    erroPeriodo,
    loading
  } = useTelemetryData()

  return (
    <S.GridContainer>
       <IoseSubHeaderBarWithFilters title='Dashboard de Telemetria'/>
      {hasReadPermission ? (
        <>
          <TelemetryDashFilter
            inicialDataConsumo={inicialDataConsumo}
            finalDataConsumo={finalDataConsumo}
            setInicialDataConsumo={setInicialDataConsumo}
            setFinalDataConsumo={setFinalDataConsumo}
            obterConsumoGraf30Dias={obterConsumoGraf30Dias}
            erroPeriodo={erroPeriodo}
          />
          <IoseCardsTelemetry consumos={consumosDiarios} loading={loading}/>
          <S.TelemetryConsumoContainer>
            <div style={{ display: 'flex', marginBottom: '15px'}}>
              <S.ContractsIcon />
              <S.TypographyTitle>CONSUMO NO PERÍODO</S.TypographyTitle>
            </div>
            <S.Consumo30DiasContainer>
              <GraficoTotalDiario30Dias consumos={consumosDiarios} loading={loading}/>
            </S.Consumo30DiasContainer>

            <div style={{ display: 'flex', marginBottom: '15px'}}>
              <S.ContractsIcon />
              <S.TypographyTitle>FATOR DE POTÊNCIA MÉDIO - Q1</S.TypographyTitle>
            </div>
            <S.Consumo30DiasContainer>
              <GraficoFatorPotenciaGeralQ1 consumos={consumosDiarios} loading={loading}/>
            </S.Consumo30DiasContainer>



          </S.TelemetryConsumoContainer>

          {/* <S.Consumo30DiasContainer>
              <GraficoConsumoCalendario consumos={consumosDiarios} loading={loading}/>
          </S.Consumo30DiasContainer> */}
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.GridContainer>
  );
}
