import { useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useUnityConsumption() {
  const { getSession } = useContext(AccountContext);
  const page_size = 300;

  const getUnityConsumption = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllUnityConsumption(
      headers,
      1000,
      page_size
    );

    const unityConsumption = response.data.data || [];

    if (unityConsumption.length === 0) {
      console.error(Messages.noFindUnityConsumptions);
      throw new Error(Messages.noFindUnityConsumptions);
    }

    return unityConsumption;
  };

  const {
    data: unityConsumption,
    error: unityConsumptionError,
    isLoading,
  } = useQuery(['unityConsumption'], getUnityConsumption);

  return { unityConsumption, unityConsumptionError, isLoading };
}

export default useUnityConsumption;
