import React from 'react';
import PropType from 'prop-types';

//imports from IOSE components
// import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseSwitchboardShowCard from 'components/IoseSwitchboardShowCard';

//imports from Messages
import Messages from 'common/Messages';

//imports from Style
import { Container } from './style';

/**
 This component create a card with a infinity scroll list with Swichboards Cards.It's a component intermediary between container and IoseSwitchboardShowCard.
 */
export default function IoseSwitchboardShowCardList({
  group,
  allGroups,
  allCircuits,
  showDashboard,
  showCircuit,
  loading,
  getAllCircuits,
  desassociateCircuits,
  setNameSwitchboard,
  searchError,
  triggerCircuit,
  triggerCircuitAction,
}) {
  const renderCards = (switchboard) => {
    return (
      <IoseSwitchboardShowCard
        key={switchboard.uuid_group}
        id={switchboard.uuid_group}
        name_switchboard={switchboard.name}
        uuid_unity={switchboard.uuid_unity}
        description={switchboard.description}
        goal={switchboard.goal ? switchboard.goal : 0}
        identification_number={
          switchboard.identification_number
            ? switchboard.identification_number
            : 0
        }
        group={group}
        showDashboard={showDashboard}
        showCircuit={showCircuit}
        // dataTable={switchboardCircuits}
        desassociateCircuits={desassociateCircuits}
        getAllCircuits={getAllCircuits}
        allCircuits={allCircuits}
        setNameSwitchboard={setNameSwitchboard}
        triggerCircuit={triggerCircuit}
        triggerCircuitAction={triggerCircuitAction}
      />
    );
  };

  return (
    <>
      {allGroups.length === 0 && allCircuits.length === 0
        ? !loading && (
            <Container>
              {searchError ? (
                <p>Quadro não encontrado!</p>
              ) : (
                <p>{Messages.noSwitchboardRegistered}</p>
              )}
            </Container>
          )
        : allGroups && allGroups.map(renderCards)}
    </>
  );
}

IoseSwitchboardShowCardList.propTypes = {
  /** This prop is the user group  */
  group: PropType.string,
  /** This prop is a array objects with all unity's switchboard(group) with this format:

    {
      description: "Descrição Quadro 2"
      goal: 200
      name: "Quadro 2"
      uuid_group: "a8ee6231-f963-467e-98ea-fc6b55616c4e"
      uuid_unity: "f283ceb6-104b-4526-bf81-3056f9bb9ce1"
    }
   */
  allGroups: PropType.oneOfType([PropType.array, PropType.string]),
  /** This prop is a array objects with all unity's circuits. In this component this props is just passed to IoseSwitchboardShowCard */
  allCircuits: PropType.array,
  /** This prop is a function to redirect DashboardContainer. In this component this props is just passed to IoseSwitchboardShowCard */
  showDashboard: PropType.func,
  /**  This prop is a function to redirect IoseCircuitShowCard. In this component this props is just passed to IoseSwitchboardShowCard */
  showCircuit: PropType.func,
  /** This prop is active spin in the cards loading*/
  loading: PropType.bool,
  /** This prop is a function to get all circuits in container*/
  getAllCircuits: PropType.func,
  /** This prop is a function to desassociateCircuit in container. In this component this props is just passed to IoseSwitchboardShowCard*/
  desassociateCircuits: PropType.func,
  /** This prop is function that set the name in IoseSubHeaderBar whith switchboard's name when user click in circuit name. In this component this props is just passed to IoseSwitchboardShowCard*/
  setNameSwitchboard: PropType.func,
  /** This prop is a boolean that indicates an error occurs in search*/
  searchError: PropType.bool,
};
