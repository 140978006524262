import React from "react";
import * as S from '../style'
import IoseModalTextField from "components/IoseModalTextField";

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalBillContentHeader({ ucAddress, currentEnergyBill }) {

  const energyBill = currentEnergyBill[0]

  return (
    <>
      <div>
        <S.Header>
          <S.HeaderItem>
            <S.TypographyTitle>
              DADOS DA CONTA DE ENERGIA
            </S.TypographyTitle>
          </S.HeaderItem>
        </S.Header>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
          }}
        >
          <div style={{ width: '50%' }}>
            <IoseModalTextField label="Endereço" value={ucAddress} />
          </div>

          <div style={{ width: '50%' }}>
            <IoseModalTextField
              label="Classificação"
              value={energyBill?.classification}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Unidade Consumidora"
          value={energyBill?.identification_number}
        />

        <IoseModalTextField
          label="Mês de vigência"
          value={energyBill?.reference_month}
        />

        <IoseModalTextField
          label="Leitura Anterior"
          value={energyBill?.previous_reading}
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Dias medidos"
          value={energyBill?.number_days}
        />

        <IoseModalTextField label="Fator multiplicador" value="1,00" />

        <IoseModalTextField
          label="Leitura atual"
          value={energyBill?.current_reading}
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
          marginBottom: '10px',
        }}
      >

        <IoseModalTextField
          label="Total a pagar"
          value={convertNumberToDecimalStyle(energyBill?.account_value)}
        />
      </div>
    </>
  )
}