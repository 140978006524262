import React, { useState } from 'react';
import { LoadingIndicator } from 'components/IoseLoader';
import * as S from '../style';

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function TableMedicoesCompletas({
  loading,
  selectedMeasurement,
}) {
  const Lista = ({ dados, mapeamento }) => {
    const halfLength = Math.ceil(Object.keys(dados).length / 2);
    const entries = Object.entries(dados);

    const firstHalf = entries.slice(0, halfLength / 2);
    const secondHalf = entries.slice(halfLength / 2, halfLength);
    const thirdHalf = entries.slice(halfLength, halfLength + halfLength / 2);
    const fourhtHalf = entries.slice(halfLength + halfLength / 2);

    return (
      <S.WrapContainer
        style={{
          'paddingTop': '15px',
          'paddingLeft': '0px',
          'paddingRight': '0px',
        }}
      >
        {/* Primeira metade */}
        <S.StyleTitle> Medição completa: </S.StyleTitle>
        <S.Container>
          <S.Half>
            {firstHalf.map(([key, value]) => {
              const displayName = mapeamento[key] || key;
              if (key === 'arrival_date') {
                value = value.replace(',', '').replace(' BRT', '');
              }
              if (key === 'status_measure') {
                if (value === 'received') {
                  value = 'Recebida';
                } else {
                  value = 'Não recebida';
                }
              }
              if (key === 'relay_state') {
                if (value) {
                  value = 'Ativado';
                } else if (!value) {
                  value = 'Desativado';
                } else {
                  value = 'Indefinido';
                }
              }
              if (key === 'thermistor_voltage') {
                value = value - 273;
              }
              return (
                <div
                  style={{ 'alignItems': 'left', 'color': 'var(--primary)' }}
                  key={key + value}
                >
                  <strong>{displayName}</strong>:{' '}
                  <span>{convertNumberToDecimalStyle(value)}</span>
                  <br />
                </div>
              );
            })}
          </S.Half>

          {/* Segunda metade */}
          <S.Half>
            {secondHalf.map(([key, value]) => {
              const displayName = mapeamento[key] || key;
              if (key === 'arrival_date') {
                value = value.replace(',', '').replace(' BRT', '');
              }
              if (key === 'status_measure') {
                if (value === 'received') {
                  value = 'Recebida';
                } else {
                  value = 'Não recebida';
                }
              }
              if (key === 'relay_state') {
                if (value) {
                  value = 'Ativado';
                } else if (!value) {
                  value = 'Desativado';
                } else {
                  value = 'Indefinido';
                }
              }
              if (key === 'thermistor_voltage') {
                value = value - 273;
              }
              return (
                <div
                  style={{ 'alignItems': 'left', 'color': 'var(--primary)' }}
                  key={key + value}
                >
                  <strong>{displayName}</strong>:{' '}
                  <span>{convertNumberToDecimalStyle(value)}</span>
                  <br />
                </div>
              );
            })}
          </S.Half>

          <S.Half>
            {thirdHalf.map(([key, value]) => {
              const displayName = mapeamento[key] || key;
              if (key === 'arrival_date') {
                value = value.replace(',', '').replace(' BRT', '');
              }
              if (key === 'status_measure') {
                if (value === 'received') {
                  value = 'Recebida';
                } else {
                  value = 'Não recebida';
                }
              }
              if (key === 'relay_state') {
                if (value) {
                  value = 'Ativado';
                } else if (!value) {
                  value = 'Desativado';
                } else {
                  value = 'Indefinido';
                }
              }
              if (key === 'thermistor_voltage') {
                value = value - 273;
              }
              return (
                <div
                  style={{ 'alignItems': 'left', 'color': 'var(--primary)' }}
                  key={key + value}
                >
                  <strong>{displayName}</strong>:{' '}
                  <span>{convertNumberToDecimalStyle(value)}</span>
                  <br />
                </div>
              );
            })}
          </S.Half>

          <S.Half>
            {fourhtHalf.map(([key, value]) => {
              const displayName = mapeamento[key] || key;
              if (key === 'arrival_date') {
                value = value.replace(',', '').replace(' BRT', '');
              }
              if (key === 'status_measure') {
                if (value === 'received') {
                  value = 'Recebida';
                } else {
                  value = 'Não recebida';
                }
              }
              if (key === 'relay_state') {
                if (value) {
                  value = 'Ativado';
                } else if (!value) {
                  value = 'Desativado';
                } else {
                  value = 'Indefinido';
                }
              }
              if (key === 'thermistor_voltage') {
                value = value - 273;
              }
              return (
                <div
                  style={{ 'alignItems': 'left', 'color': 'var(--primary)' }}
                  key={key + value}
                >
                  <strong>{displayName}</strong>:{' '}
                  <span>{convertNumberToDecimalStyle(value)}</span>
                  <br />
                </div>
              );
            })}
          </S.Half>
        </S.Container>
      </S.WrapContainer>
    );
  };

  if (!selectedMeasurement || selectedMeasurement.length === 0)
    return <LoadingIndicator loading={loading} />;

  const measureKeysToRemove = [
    'offset_eae',
    'offset_ere',
    'offset_iae',
    'offset_ire',
    'created_at',
  ];

  const keyMappings = {
    index: 'Índice da medida',
    reactive_power: 'Potência reativa lida (VAr)',
    status_measure: 'Status da medição',
    uuid_measure: 'UUID da medição',
    current: 'Corrente (A)',
    import_reactive_energy: 'Medida da importação de energia reativa (Wh)',
    thermistor_voltage: 'Temperatura interna do Módulo (C°)',
    line_frequency: 'Frequência da rede elétrica medida (Hz)',
    export_reactive_energy: 'Energia reativa exportada lida (Wh)',
    voltage: 'Tensão RMS da fase medida (V)',
    export_active_energy: 'Energia ativa exportada lida (Wh)',
    relay_state: 'Estado atual do relé',
    import_active_energy: 'Energia ativa consumida lida (Wh)',
    apparent_power: 'Potência aparente lida (VA)',
    active_power: 'Potência ativa lida (VA)',
    power_factor: 'Fator de potência lido pelo sensor',
    uuid_circuit: 'UUID do módulo',
    counter: 'Medida do contador',
    arrival_date: 'Data da medição',
    system_status: 'Número do status do sistema',
    // uuid_object: 'UUID do objeto',
    // condition: 'Condição do objeto',
    // temperature: 'Temperatura de funcionamento',
    // power: 'Potência (W)',
    // charge_type: 'Tipo da Carga',
  };

  return (
    <div>
      {selectedMeasurement.map((measure) => {
        const filteredMeasurement = Object.fromEntries(
          Object.entries(measure).filter(
            ([key]) => !measureKeysToRemove.includes(key)
          )
        );
        return (
          <div key={measure.uuid_measure}>
            <Lista dados={filteredMeasurement} mapeamento={keyMappings}></Lista>
          </div>
        );
      })}
    </div>
  );
}
