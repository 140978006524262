import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Modal, Grow, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import * as S from './style';

import { TextFieldStyled, IoseModalButton } from 'components';

import { validationsForm } from 'common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseAddUserModal({
  open,
  onClose,
  loading,
  notificationType,
  addNotification,
  showNotification,
  submit,
  cognitoGroupsOptions,
}) {
  const classes = useStyles();

  const handleCreateEcu = (values) => {
    const { ecu_number } = values;

    submit(ecu_number);
  };

  const solarEcuSchema = Yup.object().shape({
    name: validationsForm.string_required,
    email: validationsForm.email,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                USUÁRIOS
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              {/* <p>
                Enviaremos um nome de usuário e senha temporários para o novo
                Administrador Cadastrado.
              </p> */}

              <S.ModalTitle variant="h5" gutterBottom>
                CADASTRO DE USUÁRIOS
              </S.ModalTitle>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  group: '',
                }}
                validationSchema={solarEcuSchema}
                onSubmit={handleCreateEcu}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  errors,
                  touched,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'name'}
                        name={'name'}
                        type={'text'}
                        label={'Nome'}
                        defaultvalue={initialValues['name']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['name'] && touched['name']
                            ? errors['name']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'email'}
                        name={'email'}
                        type={'text'}
                        label={'E-mail'}
                        defaultvalue={initialValues['email']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['email'] && touched['email']
                            ? errors['email']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel
                          id="group-label"
                          className={classes.whiteText}
                        >
                          Grupo
                        </S.StyledInputLabel>
                        <Field
                          as={S.StyledSelect}
                          name="group"
                          labelId="group-label"
                          id="group-select"
                          onChange={handleChange}
                          className={classes.whiteText}
                          required={true}
                        >
                          {cognitoGroupsOptions?.map((item) => (
                            <MenuItem key={item.label} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    {/* {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : ( */}
                    <S.ButtonContainer>
                      <IoseModalButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Cadastrar
                      </IoseModalButton>
                    </S.ButtonContainer>
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
