// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueConsumerGroupAddress() {
  const [noAddressError, setNoAddressError] = useState(
    ''
  );

  const { consumerGroups } = useConsumerGroup();

  const getUniqueAddress = async () => {

    const addressSet = new Set();

    consumerGroups?.forEach((item) => {
      if (
        item.address
        && item.address !== '0'
        && item.address !== ''
        && item.address.toUpperCase() !== 'NULL'
      ) {
        addressSet.add(item.address);
      }
    });

    const address = [...addressSet].map(
      (address) => ({
        value: address,
        label: address,
      })
    );

    if (address.length === 0) {
      setNoAddressError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return { address };
  };

  const {
    data: { address } = {},
    error: addressError,
    refetch: refreshAddress,
    isLoading,
  } = useQuery(['address', consumerGroups], getUniqueAddress, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    address,
    addressError,
    noAddressError,
    refreshAddress,
    isLoading,
  };
}

export default useUniqueConsumerGroupAddress;
