import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useConsumerGroupBills(consumerGroup) {
  const { getSession } = useContext(AccountContext);

  // const pageSize = 300;

  const getBills = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllIdentificatioNumberBills(
      headers,
      consumerGroup
    );

    const bills = response.data.data || [];

    if (bills.length === 0) {
      console.error(Messages.noRegisteredEnergyBills);
      throw new Error(Messages.noRegisteredEnergyBills);
    }
    return bills;
  };

  const {
    data: bills,
    error: billsError,
    refetch: refreshBills,
    isLoading,
  } = useQuery(['consumerGroupBills', consumerGroup], getBills);

  return {
    bills,
    billsError,
    refreshBills,
    isLoading,
  };
}

export default useConsumerGroupBills;
