import styled from 'styled-components';

export const ChartsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const ChartBox = styled.div`
  width: 100%;
  height: 300px;
  /* border: 1px solid red; */
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const ChartTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  h3 {
    color: var(--primary);
  }
`;
