import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Imports from @date-io
import DateFnsUtils from '@date-io/date-fns';

import { useLocation } from 'react-router-dom';

//Imports from IOSE components
import IoseListTransferCircuits from 'components/IoseListTransferCircuits';
import IoseListCheck from 'components/IoseListCheck';
import IoseButtonSideBar from 'components/IoseButton';
import IoseSelection from 'components/IoseSelection';
import IoseSearch from 'components/IoseSearch';
import {
  IoseDeleteButton,
  IoseAddButton,
  IoseBackButton,
} from 'components/IoseButtonIcon';

//Imports from Material UI components
import { createMuiTheme, Grid, Grow } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from 'common/ValidationsForms';

//Imports from Style
import {
  Container,
  Header,
  YellowCheck,
  YellowRadio,
  RadioGroupStyled,
  FormControlLabelStyled,
  IoseTextFieldSidebarStyled,
  EmojiObjectsIconStyled,
  GridStyled,
  FormStyled,
  Error,
  DateTimePickerStyled,
  DivActionButtons,
} from './style';

//Theme to TimerPicker this is a single mod to chand css it
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E8BE00',
        color: '#015A9E',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#E8BE00',
      },
      thumb: {
        borderColor: '#E8BE00',
      },
      noPoint: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: '#e8be00',
      },
      dayDisabled: {
        color: '#e8be00',
      },
      current: {
        color: '#e8be00',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#e8be00',
      },
    },
    MuiTab: {
      fullWidth: { backgroundColor: '#e8be00' },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#015A9E',
      },
    },
  },
});

/**
 * This component render a form to edit new Scheduling
 */
export default function IoseScheduleEditCard({
  editSchedule,
  redirectValidation,
  getSchedulingModulesByGroup,
  editScheduleAddCircuits,
  editScheduleRemoveCircuits,
}) {
  let location = useLocation();

  const allGroups = location.state.allGroups;
  const allCircuits = location.state.allCircuits;
  const scheduleData = location.state.scheduleData;

  //STATES----------------------

  // State and function to change the scheduling action
  const [action, setAction] = useState(
    scheduleData.action_relay ? 'true' : 'false'
  );

  const [nameSchedule, setNameSchedule] = useState(scheduleData.name);

  const [repeat, setRepeat] = useState(scheduleData.repeat ? 'true' : 'false');

  const [switchboard, setSwitchboard] = useState(allGroups[0]);

  const [circuitsSwitchboard, setCircuitsSwitchboard] = useState([]);
  const [circuitsSelecteds, setCircuitsSelecteds] = useState([]);
  const [circuitScheduling, setCircuitScheduling] = useState(
    scheduleData.circuits ? scheduleData.circuits : []
  );

  const [circuitsFiltered, setCircuitsFiltered] = useState([]);
  const [search, setSearch] = useState(false);

  const [week, setWeek] = useState(scheduleData.week);

  const [errorEmptyCircuitChosen, setErrorEmptyCircuitChosen] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  const [run_at, setRun_at] = useState(
    new Date(Date.parse(scheduleData.run_at))
  );

  //States to switch in between environments
  const [environment, setEnvironment] = useState('');

  //--------------------------

  //Schema validation for Scheduling
  const SchedulerEditSchema = Yup.object().shape({
    name: validationsForm.name,
  });

  const labelsAction = [
    { label: 'Ligar', variable: 'true' },
    { label: 'Desligar', variable: 'false' },
  ];

  const labelRepeat = [
    { label: 'Uma vez', variable: 'false' },
    { label: 'Repetir', variable: 'true' },
  ];

  let labelWeek = [
    { name: 'sun', label: 'Dom' },
    { name: 'mon', label: 'Seg' },
    { name: 'tue', label: 'Ter' },
    { name: 'wed', label: 'Quar' },
    { name: 'thu', label: 'Qui' },
    { name: 'fri', label: 'Sex' },
    { name: 'sat', label: 'Sab' },
  ];

  //USEEFFECTS----------------------

  //Filter to change circuit when change switchboard
  useEffect(() => {
    if (allCircuits.length !== 0 && allGroups.length !== 0) {
      filterCircuits();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCircuits, allGroups, switchboard]);

  useEffect(() => {
    if (
      scheduleData.uuid_scheduling_group &&
      location.pathname.includes('schedule')
    ) {
      getSchedulingModulesByGroupInForm();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData.uuid_scheduling_group]);

  //FUNCTIONS----------------------

  // function handleNameSchedule(e) {
  //   setNameSchedule(e.target.value);
  // }

  const handleChangeDays = (e) => {
    const { name } = e.target;

    setWeek((week) => ({
      ...week,
      [name]: !week[name],
    }));
  };

  //Const to render the Checkboxes Days in the form
  const renderDaysCheck = (day) => {
    return (
      <Grid item xs={4} sm={1} key={day.name}>
        <FormControlLabelStyled
          control={<YellowCheck checked={week[day.name]} name={day.name} />}
          label={day.label}
          onChange={handleChangeDays}
        />
      </Grid>
    );
  };

  function filterCircuits() {
    const circuitsFilter = allCircuits.filter(
      (circuit) => circuit.uuid_group === switchboard.uuid_group
    );
    setCircuitsSwitchboard(circuitsFilter);
  }

  function searchCircuit(circuitNameSearch) {
    const circuitNameSearchUppcase = circuitNameSearch.toUpperCase();

    //Clean old info search
    setCircuitsFiltered([]);

    if (circuitNameSearch === '') {
      return setSearch(false);
    } else {
      setSearch(true);
      let circuitFiltered = '';

      if (environment !== 'remove') {
        circuitFiltered = circuitsSwitchboard.filter((card) =>
          card.name.toUpperCase().includes(circuitNameSearchUppcase)
        );
      } else {
        circuitFiltered = circuitScheduling.filter((card) =>
          card.circuit_name.toUpperCase().includes(circuitNameSearchUppcase)
        );
      }

      circuitFiltered.length !== 0
        ? setCircuitsFiltered(circuitFiltered)
        : setSearch(true);
    }
  }

  //Function to return all circuit by schedulings
  const getSchedulingModulesByGroupInForm = async () => {
    const response = await getSchedulingModulesByGroup(
      scheduleData.uuid_scheduling_group
    );

    if (response.result) {
      setCircuitScheduling(response.message);
      setCircuitsSelecteds(response.message);
    }
  };

  const handleRunAt = (runAtSelected) => {
    setErrorDate(false);
    const currentyDate = new Date();

    if (runAtSelected < currentyDate) {
      setErrorDate(true);
    }

    setRun_at(runAtSelected);
  };

  const clickBack = () => {
    setEnvironment('');
    getSchedulingModulesByGroupInForm();
  };

  const editScheduling = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);

      const arrayIdCircuits = circuitsSelecteds.map(
        (circuit) => circuit.uuid_circuit
      );

      run_at.setSeconds(0, 0);

      repeat !== 'true' && setWeekOneShot();

      const scheduleDataEditParams = {
        uuid_scheduling_group: scheduleData.uuid_scheduling_group,
        name: values.name,
        action_relay: action === 'true' ? true : false,
        repeat: repeat === 'true' ? true : false,
        week: week,
        run_at: run_at.toISOString(),
        switchboard: switchboard,
        circuits: circuitsSelecteds,
      };

      console.group('circuitsSelecteds.length', circuitsSelecteds);

      if (circuitsSelecteds.length !== 0 && !errorDate) {
        console.group('aqui');

        if (environment === '') {
          editSchedule({
            ...scheduleDataEditParams,
            circuits: arrayIdCircuits,
          });
        } else {
          environment === 'add'
            ? editScheduleAddCircuits({
                ...scheduleDataEditParams,
                circuits: arrayIdCircuits,
              })
            : editScheduleRemoveCircuits({
                ...scheduleDataEditParams,
                circuits: arrayIdCircuits,
              });
        }

        redirectValidation(circuitsSelecteds, 'update', scheduleDataEditParams);
      } else {
        circuitsSelecteds.length === 0 && setErrorEmptyCircuitChosen(true);
      }
    }, 500);
  };

  //Function to render environment add or remove circuits
  function renderEnvironment() {
    return (
      <>
        {/**List check to Switchboard and Circuits*/}
        <Formik
          initialValues={{
            name: scheduleData.name,
          }}
          validationSchema={SchedulerEditSchema}
          onSubmit={editScheduling}
        >
          {({ submitForm }) => (
            <FormStyled>
              <Header bottom="16px">
                <div>
                  <IoseBackButton onClick={(e) => clickBack()} />
                  {environment === 'add'
                    ? 'Adicionar circuitos ao agendamento'
                    : 'Remover circuitos do agendamento'}
                </div>

                <IoseButtonSideBar type="submit" onClick={(e) => submitForm}>
                  Confirmar
                </IoseButtonSideBar>
              </Header>
              {environment === 'add' ? (
                allGroups.length !== 0 ? (
                  <>
                    <GridStyled
                      container
                      direction="row"
                      alignItems="baseline"
                      justify="space-between"
                    >
                      <Field
                        component={IoseSelection}
                        id={switchboard}
                        name={'quadros'}
                        type={'text'}
                        func={setSwitchboard}
                        title={'Quadros'}
                        object={allGroups}
                        defaultvalue={switchboard}
                        sidebar="true"
                      />
                    </GridStyled>

                    <Grid container justify={'flex-end'}>
                      <IoseSearch
                        placeholder="Pesquisar Circuito..."
                        funcSearch={searchCircuit}
                        sidebar={'true'}
                        positionbutton="absolute"
                        inForm={true}
                      />
                      {errorEmptyCircuitChosen && (
                        <Error>Escolha ao menos um circuito!</Error>
                      )}
                      <IoseListTransferCircuits
                        choices={
                          search ? circuitsFiltered : circuitsSwitchboard
                        }
                        switchboard={switchboard}
                        allGroups={allGroups}
                        setCircuitsSelecteds={setCircuitsSelecteds}
                        circuitScheduling={circuitScheduling}
                      />
                    </Grid>
                  </>
                ) : (
                  <Error>Sem quadros cadastrados!</Error>
                )
              ) : (
                renderListRemove()
              )}
            </FormStyled>
          )}
        </Formik>
      </>
    );
  }

  function renderListRemove() {
    return (
      <>
        {errorEmptyCircuitChosen && (
          <Error margin="12px">Escolha ao menos um circuito!</Error>
        )}
        <IoseSearch
          placeholder="Pesquisar Circuito..."
          funcSearch={searchCircuit}
          positionbutton="absolute"
          inForm={true}
        />
        <IoseListCheck
          title=""
          array={search ? circuitsFiltered : circuitScheduling}
          disabledList={false}
          selectAllInitial={false}
          selectCircuits={(e) => setCircuitsSelecteds(e)}
          scheduling_form={true}
          height="220px"
        />
      </>
    );
  }

  function handleEnvironment(action) {
    setErrorEmptyCircuitChosen(false);
    // getSchedulingModulesByGroupInForm();
    setEnvironment(action);
  }

  //This function set week in one shot mode
  const setWeekOneShot = () => {
    const numberWeek = run_at.getDay();

    let weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    weekDays.forEach((day) => {
      week[day] = false;
    });

    week[weekDays[numberWeek]] = true;
  };

  return (
    <>
      {/**Form*/}
      <Container>
        {environment !== '' ? (
          renderEnvironment()
        ) : (
          <Formik
            initialValues={{
              name: nameSchedule,
            }}
            validationSchema={SchedulerEditSchema}
            onSubmit={editScheduling}
          >
            {({
              handleBlur,
              submitForm,
              touched,
              isSubmitting,
              errors,
              initialValues,
            }) => (
              <FormStyled>
                {/**Header*/}
                <Header>
                  Editar
                  <IoseButtonSideBar
                    type="submit"
                    // disabled={isSubmitting}
                    onClick={(e) => submitForm}
                  >
                    Confirmar
                  </IoseButtonSideBar>
                </Header>
                {/**Input Name*/}
                <Field
                  component={IoseTextFieldSidebarStyled}
                  id={'name'}
                  label={'Nome'}
                  defaultvalue={nameSchedule}
                  onchange={(e) => setNameSchedule(e.target.value)}
                  onblur={handleBlur}
                />

                <Grid container alignItems="center">
                  {/**TimePicker*/}
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <DateTimePickerStyled
                        autoOk
                        variant="inline"
                        label="Data e Hora"
                        value={run_at}
                        onChange={(e) => handleRunAt(e)}
                        theme="white"
                        ampm={false}
                        minDate={new Date()}
                        minDateMessage={''}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>

                  {/**Action checkboxes*/}
                  <RadioGroupStyled
                    row={true}
                    value={action}
                    onChange={(e) =>
                      setAction(e.target.value === 'true' ? 'true' : 'false')
                    }
                  >
                    {labelsAction.map((field) => (
                      <Field
                        component={FormControlLabelStyled}
                        type="radio"
                        value={field.variable}
                        control={<YellowRadio />}
                        label={field.label}
                        key={field.variable}
                      />
                    ))}
                    <EmojiObjectsIconStyled on={action} />
                  </RadioGroupStyled>
                </Grid>
                {errorDate && (
                  <Error>Escolha uma data maior ou igual à atual!</Error>
                )}
                {/**Repeat ckeckboxes*/}
                <RadioGroupStyled
                  row={true}
                  value={repeat}
                  onChange={(e) =>
                    setRepeat(e.target.value === 'true' ? 'true' : 'false')
                  }
                >
                  {labelRepeat.map((field) => (
                    <Field
                      component={FormControlLabelStyled}
                      type="radio"
                      value={field.variable}
                      control={<YellowRadio />}
                      label={field.label}
                      key={field.variable}
                    />
                  ))}
                </RadioGroupStyled>
                {/**Days checkboxes*/}
                {repeat === 'true' && (
                  <Grow in={true} {...{ timeout: 1000 }}>
                    <section>
                      <Grid container justify="space-between">
                        {labelWeek.map(renderDaysCheck)}
                      </Grid>
                    </section>
                  </Grow>
                )}
                {/**Buttons Action Update*/}
                <DivActionButtons>
                  <div>
                    <IoseAddButton
                      onClick={(e) => handleEnvironment('add')}
                      tooltip="Adicionar Circuitos"
                    />
                    <p>Adicionar circuitos ao agendamento</p>
                  </div>

                  <div>
                    <IoseDeleteButton
                      tooltip="Remover circuitos"
                      onClick={(e) => handleEnvironment('remove')}
                    ></IoseDeleteButton>
                    <p>Remover circuitos do agendamento</p>
                  </div>
                </DivActionButtons>
              </FormStyled>
            )}
          </Formik>
        )}
      </Container>
    </>
  );
}

IoseScheduleEditCard.propTypes = {
  /** This props get is the function to add sheduling in DB, it comes SidebarSheduleContainer */
  editSchedule: PropType.func,
};
