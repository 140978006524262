import React from 'react';
import PropType from 'prop-types';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import Tooltip from '@material-ui/core/Tooltip';
import { Grow } from '@material-ui/core';

//Imports from Style
import {
  CardStyled,
  UnityIcon,
  AvatarStyled,
  CardHeaderStyled,
  DivHeaderButton,
} from './style';

/**
 * This component create a card with all information about unity and allows editing/deleting unity.
 */
export default function IoseCardUnity3d(props) {
  const {
    uuid_unity,
    name,
    description,
    clickCard,
    identification_number,
    bin_link,
    geracao_estimada
  } = props;

  const unityData = {
    uuid_unity: uuid_unity,
    name: name,
    description: description,
    identification_number: identification_number,
    bin_link: bin_link,
    geracao_estimada: geracao_estimada
  };

  const renderSubtitle = () => (
    <Tooltip
      title={'CLIQUE NO CARD PARA ACESSAR O MODELO 3D DA UNIDADE.'}
      placement="bottom"
      arrow
    >
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip
      title={'CLIQUE NO CARD PARA ACESSAR O MODELO 3D DA UNIDADE.'}
      placement="bottom"
      arrow
    >
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <CardStyled>
        <DivHeaderButton disableSpacing>
          <CardHeaderStyled
            avatar={
              <AvatarStyled>
                <UnityIcon />
              </AvatarStyled>
            }
            onClick={(e) => clickCard(unityData)}
            title={renderTitle()}
            subheader={renderSubtitle()}
          />
          <div>
            <ThreeDRotationIcon />
          </div>
        </DivHeaderButton>
      </CardStyled>
    </Grow>
  );
}

IoseCardUnity3d.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
