import React from 'react';
import PropType from 'prop-types';
import {
  LoadingIndicator,
  LoadingIndicatorSidebar,
} from 'components/IoseLoader';

//Imports from Style
import { InfiniteScrollStyle, EndText } from './style';

/**  This component is div with scroll that laod new itens when arrives at the end of the list*/
export function IoseInfinityScroll({
  children,
  dataLength,
  next,
  hasMore,
  height,
  loading,
  endList,
  sidebar,
  marginTop,
  padding,
  colortextend,
  sizetextend,
  scrollableTarget,
  scrollbarColor,
}) {
  return (
    <InfiniteScrollStyle
      dataLength={dataLength}
      marginTop={marginTop}
      padding={padding}
      next={next}
      hasMore={hasMore}
      height={height}
      scrollableTarget={scrollableTarget}
      className="custom-infinity-scroll"
      scrollbarColor={scrollbarColor}
    >
      {children}
      {loading &&
        (sidebar ? (
          <LoadingIndicatorSidebar loading={loading} />
        ) : (
          <LoadingIndicator loading={loading} />
        ))}
      {endList && (
        <EndText colortextend={colortextend} sizetextend={sizetextend}>
          Todos os itens foram carregados!
        </EndText>
      )}
    </InfiniteScrollStyle>
  );
}

IoseInfinityScroll.propTypes = {
  /** This props is component inside it*/
  children: PropType.oneOfType([PropType.object, PropType.array]),
  /** This props is size array element inside it*/
  dataLength: PropType.number,
  /** This props is function that will invoke when arrives in end list*/
  next: PropType.func,
  /** This props is indicate that has function next*/
  hasMore: PropType.bool,
  /** This props is height of container that wrapper elements inside IoseInfinityScroll*/
  height: PropType.oneOfType([PropType.number, PropType.string]),
  /** This props is spin that indicates loadiung itens*/
  loading: PropType.bool,
  /** This props is boolean that indicates end list*/
  endList: PropType.bool,
  /** This props is boolean that indicates if this component to be in sidebar*/
  sidebar: PropType.bool,
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in screen of 960px height*/
  height960px: PropType.oneOfType([PropType.number, PropType.string]),
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in screen of 500px height*/
  height500px: PropType.oneOfType([PropType.number, PropType.string]),
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in screen of 1280px height*/
  height1280px: PropType.oneOfType([PropType.number, PropType.string]),
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in ultrawide screen */
  ultrawide: PropType.oneOfType([PropType.number, PropType.string]),
  /** This props set the margin-top of component*/
  marginTop: PropType.string,
  /** This props set the padding of component*/
  padding: PropType.string,
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in iphone screen */
  iphone: PropType.string,
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in iphonePlus screen */
  iphonePlus: PropType.string,
  /** This props is height of container that wrapper elements inside IoseInfinityScroll in iphoneX screen  */
  iphoneX: PropType.string,
  /** This props is color text that indicate when all iten was loading */
  colortextend: PropType.string,
  /** This props is size text that indicate when all iten was loading */
  sizetextend: PropType.string,
};
