import styled from 'styled-components';

import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Field } from 'formik';
import { TypographyGeneral } from 'components/IoseSubHeaderBar/style';

export const DivInputs = styled.div`
  width: auto;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '20px'};
`;

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 30px !important;
  height: 30px !important;
`;

export const ModalTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: var(--secondary);
  margin-top: 6px;
`;

export const StyledFormControl = styled(FormControl)`
  label {
    color: white;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid #fff;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid var(--primary);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const StyledHeaderModal = styled.div`
  background-color: var(--primary);
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledWrappedModal = styled.div`
  background-color: #4a5459;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: var(--secondary);
  margin-left: 25px;
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;

  @media (max-width: 1360px) {
    font-size: 10px;
  }
`;

export const StyledSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 12px;
  }

  @media (max-width: 1360px) {
    .MuiInputBase-input {
      font-size: 10px;
    }
  }
`;

export const StyledField = styled(Field)`
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
  }
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--secondary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const TypographyData = styled(TypographyGeneral)`
  color: var(--secondary);
  font-size: 15px;
  font-weight: bold;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const TypographyAdress = styled(TypographyGeneral)`
  color: var(--secondary);
  font-size: 13px;
  padding: 10px;
  font-weight: bold;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const Container = styled.div`
  height: auto;
  width: 91vw;
  height: 650px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  gap: 15px;
  display: flex;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const Header = styled.div`
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px 5px 0 0;
  padding: 5px;
`;

export const HeaderData = styled.div`
  background-color: var(--base);
  display: flex;
  align-items: stretch; /* Alterado */
  justify-content: space-around;
  border-radius: 0 0 5px 5px;
  height: 80px; /* Alterado */
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalContainer = styled.div`
  width: 97vw;
  height: 95vh;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-right: 20px;
`;
