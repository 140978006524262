import React from 'react';

import { useLocation } from 'react-router-dom';

//Imports from Style
import {
  Container,
  Header,
  ListStyled,
  ListItemStyled,
  ListItemTextStyled,
} from './style';

//Imports from IOSE Components
// import { IoseBackButton } from 'components/IoseButtonIcon';
// import IoseButtonSideBar from 'components/IoseButton';
import IoseIndicatorStatusSchedule from 'components/IoseIndicatorStatusSchedule';

//Imports from Materila UI components
import { ListItemIcon, Grow } from '@material-ui/core';

export default function IoseScheduleShowProcess({
  // redirectToForm,
  // closeSide,
  nameSwitchboard,
  circuitStateScheduling,
  resendRequistion,
  createResponse,
  deleteResponse,
  editResponse,
}) {
  let location = useLocation();

  // const scheduleData = location.state.scheduleData;
  const form_what = location.state.form_what;
  const circuitsSelecteds = location.state.circuitsSelecteds;

  const renderCircuitsList = (circuit) => {
    return (
      <ListItemStyled key={circuit.uuid_circuit}>
        <ListItemTextStyled
          primary={circuit.name ? circuit.name : circuit.circuit_name}
          secondary={nameSwitchboard(circuit.uuid_group)}
        />
        <ListItemIcon>
          <IoseIndicatorStatusSchedule
            id={circuit.uuid_circuit}
            circuitStateScheduling={circuitStateScheduling}
            form_what={form_what}
            resendRequistion={resendRequistion}
            createResponse={createResponse}
            deleteResponse={deleteResponse}
            editResponse={editResponse}
          />
        </ListItemIcon>
      </ListItemStyled>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <Container>
        {/**Header*/}
        <Header>
          <div>
            {/* {form_what !== 'delete' && (
              <IoseBackButton
                onClick={(e) => redirectToForm(form_what, scheduleData)}
              />
            )} */}
            Registrando nos Módulos
          </div>
        </Header>

        {/**List Circuits */}

        <ListStyled dense={true}>
          {circuitsSelecteds.map(renderCircuitsList)}
        </ListStyled>

        {/* <div>
          <IoseButtonSideBar type="submit" onClick={(e) => closeSide()}>
            Sair
          </IoseButtonSideBar>
        </div> */}
      </Container>
    </Grow>
  );
}
