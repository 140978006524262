import React from 'react';

import * as S from './style';

import {
  TrendingUp as TrendingUpIcon,
  ShowChart as ShowChartIcon,
  TrendingDown as TrendingDownIcon,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const getIcon = (title) => {
  switch (title) {
    case 'Consumo total':
      return <TrendingUpIcon />;
    case 'Estimado total':
      return <ShowChartIcon />;
    case 'Reativo total':
      return <TrendingDownIcon />
    default:
      return null;
  }
};

export default function Iose3dListSummaryConsumo({ data }) {
  const totalReativoConsumido = data?.reduce(
    (total, obj) => total + obj.reativoConsumido,
    0
  );
  const totalConsumido = data?.reduce(
    (total, obj) => total + obj.consumido,
    0
  );

  const SummaryUnitysData = [
    {
      title: 'Consumo total',
      description: 'Valor total de consumo medido pelo IOSE',
      value: convertNumberToDecimalStyle(totalConsumido) + ' kWh',
    },
    {
      title: 'Reativo total',
      description: 'Valor total de reativo medido durante o consumo pelo IOSE',
      value: convertNumberToDecimalStyle(totalReativoConsumido) + ' VArh',
    },
  ];

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  return (
    <S.SummaryListContainer>
      <S.TitleList>RESUMO</S.TitleList>
      <S.ListContainer>
        {SummaryUnitysData.map(({ title,description, value }) => (
            <Tooltip
              title={description}
              placement="bottom"
              arrow
            >
            <S.ListStyled key={title}>
              
              <S.SummaryIconWrapper>{getIcon(title)}</S.SummaryIconWrapper>
              <S.ListItemTextStyled primary={title} secondary={value} />
              
            </S.ListStyled>
          </Tooltip>
        ))}
      </S.ListContainer>
    </S.SummaryListContainer>
  );
}
