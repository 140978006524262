import React, { useMemo, useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination } from '@material-ui/core';

import {
  ButtonContainer,
  StyledTable,
  TableHeadStyled,
  TableRowStyled,
} from './style';

import * as XLSX from 'xlsx';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2 } from 'components/IoseButton';
import { useCreateLogs } from 'hooks';
import { convertNumberToMonetaryStyle } from 'services/format/format-number-to-monetary';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer', // Add cursor style to indicate sortable columns
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data?.map((obj) => {
    const { uuid_contract, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'concessionaire',
    'classification',
    'reference_month',
    'general_management',
    'regional_management',
    'cidade',
    'numero_medidor',
    'vencimento',
    'account_value',
  ];

  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'vencimento') {
        reorderedObj[column] = transformDateString(obj[column]);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    concessionaire: 'Concessionária',
    classification: 'Classificação',
    reference_month: 'REF: MÊS/ANO',
    general_management: 'Gerência Geral',
    regional_management: 'Gerência Regional',
    cidade: 'Cidade',
    numero_medidor: 'Num. Medidor',
    vencimento: 'Vencimento',
    account_value: 'Valor da fatura',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function transformDateString(inputDate) {
  const parts = inputDate?.split('T')[0].split('-');
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  return `${day}/${month}/${year}`;
}

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableCell} ${className}`}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className, onClick, sortedBy, direction }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tableHeaderCell} ${className}`} onClick={onClick}>
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};
const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" onClick={() => onClick(value)} className={classes.tableRow}>
      <TableCellCentered>{value.identification_number}</TableCellCentered>
      <TableCellCentered>{value.concessionaire}</TableCellCentered>
      <TableCellCentered>{value.classification}</TableCellCentered>
      <TableCellCentered>{value.reference_month}</TableCellCentered>
      <TableCellCentered>{value.general_management}</TableCellCentered>
      <TableCellCentered>{value.regional_management}</TableCellCentered>
      <TableCellCentered>{value.cidade}</TableCellCentered>
      <TableCellCentered>{value.numero_medidor}</TableCellCentered>
      <TableCellCentered>{transformDateString(value.vencimento)}</TableCellCentered>
      <TableCellCentered>{convertNumberToMonetaryStyle(value.account_value)}</TableCellCentered>
    </TableRowStyled>
  );
};


const CircuitsTable = ({ data, onRowClick, page, rowsPerPage, handleSort, sortBy, sortDirection }) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered onClick={() => handleSort('identification_number')} sortedBy={sortBy === 'identification_number'} direction={sortDirection}>
            UC
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('concessionaire')} sortedBy={sortBy === 'concessionaire'} direction={sortDirection}>
            CONCESSIONÁRIA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('classification')} sortedBy={sortBy === 'classification'} direction={sortDirection}>
            CLASSIFICAÇÃO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('reference_month')} sortedBy={sortBy === 'reference_month'} direction={sortDirection}>
            REF: MÊS/ANO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('general_management')} sortedBy={sortBy === 'general_management'} direction={sortDirection}>
            GG
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('regional_management')} sortedBy={sortBy === 'regional_management'} direction={sortDirection}>
            GR
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('cidade')} sortedBy={sortBy === 'cidade'} direction={sortDirection}>
            CIDADE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('numero_medidor')} sortedBy={sortBy === 'numero_medidor'} direction={sortDirection}>
            MEDIDOR
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('vencimento')} sortedBy={sortBy === 'vencimento'} direction={sortDirection}>
            VENCIMENTO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered onClick={() => handleSort('account_value')} sortedBy={sortBy === 'account_value'} direction={sortDirection}>
            VALOR DA FATURA
          </TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {pageData.map((value, index) => (
          <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
        ))}
      </TableBody>
    </StyledTable>
  );
};


export default function IoseEnergyBillListTable({
  loading,
  data,
  onClickFunction,
  hasPermission,
}) {
  const { createLog } = useCreateLogs();

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortBy, sortDirection);
  }, [data, sortBy, sortDirection]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleDownloadButtonClick = async (data) => {
    exportToExcel(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE BUSCA DE FATURAS';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  function renderChartOrErrorMessage() {
    if (sortedData && sortedData.length > 0) {
      return (
        <Paper>
          <ButtonContainer>
            {hasPermission ? (
              <IoseButton2 width="120px" onClick={() => handleDownloadButtonClick(data)}>
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            ) : null}
          </ButtonContainer>
          <CircuitsTable
            data={sortedData}
            id="scrollableDiv"
            onRowClick={onClickFunction}
            page={page}
            rowsPerPage={rowsPerPage}
            handleSort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="400px"
          width="400px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
      <>
        <IoseInfinityScroll
          dataLength={data?.length}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={0}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={600}
        >
        {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
        <TablePagination
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Faturas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            backIconButtonText="Página anterior"
            nextIconButtonText="Próxima página"
          />
      </>
      )}
    </div>
  );
}

const sortData = (data, sortBy, sortDirection) => {
  const sortedData = [...data];
  return sortedData.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};