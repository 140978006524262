import React, { createContext } from 'react';
import Pool from '../Cognito_config';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import LocalStorage from 'common/local_storage_api';
import IoseCognito from 'common/iose_cognito';
import Exception from 'common/exceptions';

//Hook to redirect beetween container
// import { useHistory, useLocation } from 'react-router-dom';

/**This a Context with all function and info needed to login, forgot password, and other controllers about session*/
const AccountContext = createContext();

export function Account(props) {

  const getSession = () => {
    return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (!user) {
        const session = LocalStorage.getSession();

        if (session && session.expiration && session.email && session.refreshToken) {
          IoseCognito.checkTokenExpiration(session.expiration, session.email, session.refreshToken)
            .then(response => {
              // console.log('Novo token: ', response.data);
              resolve({
                headers: {
                  'Authorization': response.data.idToken,
                },
                ...response.data,
              });
            })
            .catch(error => {
              // console.error('Erro ao atualizar o token: ', error);
              reject(Exception.ErrorRefreshToken);
            });
        } else {
          reject('Usuário ou sessão não encontrada no LocalStorage');
        }
      }

      if (user) {
        // try {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = await getAttribuites(user);
            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              headers: {
                'Authorization': token,
              },
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });
  };

  const getAttribuites = async (user) => {
    return await new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          const results = {};

          for (let attribute of attributes) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }

          resolve(results);
        }
      });
    });
  };

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          const status = 'newPasswordRequired';

          delete data.email_verified;
          delete data.email;

          const sessionUserAttributes = data;

          resolve({ sessionUserAttributes, status, user });
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.signOut();
    }
  };

  const getGroup = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const arrayGroups = session.idToken.payload['cognito:groups'];
            resolve(arrayGroups);
          }
        });
      } else {
        reject();
      }
    });

  return (
    <AccountContext.Provider
      value={{ authenticate, getSession, logout, getGroup }}
    >
      {props.children}
    </AccountContext.Provider>
  );
}

// export default Account;

export { AccountContext };
