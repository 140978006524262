import React, { useEffect, useState } from 'react';
import * as S from './style';

import { IoseSubHeaderBar } from 'components';
import UsuariosXAlarmesTable from './components/usuarios-alarmes-table';
import UsuariosXAlarmesAdicionar from './components/usuarios-alarmes-adicionar';
import { LocalStorage, Permissions } from 'common';
import { useGroupPermissions } from 'hooks';



export default function UsuariosXAlarmesContainer() {
  const [hasAddPermission, setHasAddPermission] = useState();

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const addPath = ['telemetria', 'usuarios_x_alarmes', 'criar'];

    if (userSession && groupPermissions) {

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);
    }
  }, [userSession, groupPermissions]);

  return (
    <S.WrappedContainer>
      <IoseSubHeaderBar title='Usuários X Alarmes'/>
      {hasAddPermission ? (
        <UsuariosXAlarmesAdicionar />
      ) : (null)}
      <UsuariosXAlarmesTable />
    </S.WrappedContainer>
  );
}