import styled from 'styled-components';
import { DateTimePicker } from '@material-ui/pickers';

//Imports from Material UI
import { DatePicker } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  direction: props.direction,
  justify: props.justify,
  alignItems: 'flex-start',
  spacing: 1,
}))`
  margin-top: ${(props) => props.margintop};
  margin-bottom: ${(props) => props.marginbottom};
  padding-left: ${(props) => props.paddingleft};
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 6,
  sm: 6,
}))`
  display: flex;
  flex-direction: row;
  height: 32px;
  padding: 0px !important;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 120px;
  margin-top: ${(props) => (props.margintop ? props.margintop : '10px')};

  span {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .Mui-disabled {
    opacity: 50%;
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  .MuiInputBase-root input:after {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 18px;
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-size: 15px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }
`;

export const DateTimePickerStyled = styled(DateTimePicker)`
  width: 150px;
  margin: 10px 50px 10px 0px;

  span {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  .MuiInputBase-root input:after {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 18px;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-size: 12px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }
`;
