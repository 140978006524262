import styled, { keyframes } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import BusinessIcon from '@material-ui/icons/Business';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from '@material-ui/icons/Close';

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContainerTable = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: black;
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AddFilterButton = styled.button`
  border-radius: 50%;
  cursor: pointer;
`;

// Filter Cards

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterCardDiv = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  gap: 5px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  transition: 0.5s ease;
  align-items: center;

  &:hover {
    scale: 1.1;
    background-color: #e3dfdf;
  }
`;

export const CloseCardIcon = styled(CloseIcon)`
  color: var(--alert);
  width: 18px;
`;

export const FilterBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  animation: ${slideIn} 0.5s ease;
`;

export const FilterContainer = styled.div`
  /* background-color: white; */
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
`;

export const OptionsButton = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--secondary);
  animation: ${slideIn} 0.5s ease;
  transition: 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:not(:hover) {
    transform: scale(1);
  }
`;

export const ShowFiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const FormDivContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  margin-bottom: 25px;
  align-items: center;
`

export const FormDivItem = styled.div`
  width: 150px;
`
