import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterText = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const FilterWrappedContainer = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  /* @media (max-width: 1200px) {
    width: 500px;
  } */
`;

export const StyledLoadingMessage = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 1380px) {
    font-size: 13px;
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  gap: 35px;
`;
