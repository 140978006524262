import React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { Container, SideHeader, Icon } from 'components/IoseSideContainer';
import UnityIcon from './Unity.svg';
import { RoutesDashboardSide } from 'routers';

function SidebarDashboardContainer({ group, setGetcards, closeSide, socket }) {
  const history = useHistory();
  let { path } = useRouteMatch();
  let location = useLocation();

  const clientName = location.state.clientName;
  const unityName = location.state.unityName;

  const clickBack = () => {
    history.push({
      pathname: `${path}/switchboard`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        switchboardData: location.state.switchboardData,
        uuid_unity: location.state.uuid_unity,
      },
    });
  };

  return (
    <Container>
      <SideHeader>
        <Icon src={UnityIcon} />
        <h2>{unityName}</h2>
      </SideHeader>
      <RoutesDashboardSide
        group={group}
        clickBack={clickBack}
        setGetcards={setGetcards}
        closeSide={closeSide}
        socket={socket}
      />
    </Container>
  );
}

export default SidebarDashboardContainer;
