import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

function formatMeasuresData(data) {
  const q1 = [];
  const q2 = [];
  const q3 = [];
  const q4 = [];
  const reativaQ1 = [];
  const reativaQ2 = [];
  const reativaQ3 = [];
  const reativaQ4 = [];

  data.forEach(obj => {
    const newObj = {
      arrival_date: obj.arrival_date,
    };

    Object.keys(obj).forEach(key => {
      if (key.toLowerCase().endsWith('_q1')) {
        newObj[key] = obj[key];
      } else if (key.toLowerCase().endsWith('_q2')) {
        newObj[key] = obj[key];
      } else if (key.toLowerCase().endsWith('_q3')) {
        newObj[key] = -Math.abs(obj[key]);
      } else if (key.toLowerCase().endsWith('_q4')) {
        newObj[key] = -Math.abs(obj[key]);
      }
    });

    q1.push({ value: [newObj.arrival_date, newObj.demanda_energia_ativa_q1 / 1000] });
    q2.push({ value: [newObj.arrival_date, newObj.demanda_energia_ativa_q2 / 1000] });
    q3.push({ value: [newObj.arrival_date, newObj.demanda_energia_ativa_q3 / 1000] });
    q4.push({ value: [newObj.arrival_date, newObj.demanda_energia_ativa_q4 / 1000] });
    reativaQ1.push({ value: [newObj.arrival_date, newObj.demanda_energia_reativa_q1 / 1000] });
    reativaQ2.push({ value: [newObj.arrival_date, newObj.demanda_energia_reativa_q2 / 1000] });
    reativaQ3.push({ value: [newObj.arrival_date, newObj.demanda_energia_reativa_q3 / 1000] });
    reativaQ4.push({ value: [newObj.arrival_date, newObj.demanda_energia_reativa_q4 / 1000] });
  });

  return { q1, q2, q3, q4, reativaQ1, reativaQ2, reativaQ3, reativaQ4 };
}

export default function GraficoQuatroQuadrantesDemanda({ data, unidadeConsumidora }) {

  const uc = unidadeConsumidora ? unidadeConsumidora[0] : null;
  const postoHorario = data?.map((item) => item.posto_horario);
  const markLines = [];

  if (uc?.modalidade === 'AZUL') {
    if (uc?.demanda_fora) {
      markLines.push({
        yAxis: uc?.demanda_fora * 1000,
        label: { formatter: 'Contratada FP' },
        lineStyle: {
          type: 'solid',
          color: '#FFA500',
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      });
      markLines.push({
        yAxis: uc?.demanda_fora ?  uc?.demanda_fora * 1000 * -1 : 0,
        label: { formatter: 'Contratada FP' },
        lineStyle: {
          type: 'solid',
          color: '#FFA500',
        },
        xAxisIndex: 3,
        yAxisIndex: 3,
      });
    }
    if (uc?.demanda_ponta) {
      markLines.push({
        yAxis: uc?.demanda_ponta * 1000,
        label: { formatter: 'Contratada PTA' },
        lineStyle: {
          type: 'solid',
          color: '#a90000',
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      });
      markLines.push({
        yAxis: uc?.demanda_ponta ? uc?.demanda_ponta * 1000 * -1 : 0,
        label: { formatter: 'Contratada PTA' },
        lineStyle: {
          type: 'solid',
          color: '#a90000',
        },
        xAxisIndex: 3,
        yAxisIndex: 3,
      });
    }
  }


  if (uc?.modalidade === 'VERDE') {
    if (uc?.demanda_fora) {
      markLines.push({
        yAxis: uc?.demanda_fora * 1000,
        label: { formatter: 'Contratada' },
        lineStyle: {
          type: 'solid',
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      });
      markLines.push({
        yAxis: uc?.demanda_fora ? uc?.demanda_fora * 1000 * -1 : 0,
        label: { formatter: 'Contratada' },
        lineStyle: {
          type: 'solid',
        },
        xAxisIndex: 3,
        yAxisIndex: 3,
      });
    }
  }

  const [formattedData, setFormattedData] = useState({
    q1: [],
    q2: [],
    q3: [],
    q4: [],
    reativaQ1: [],
    reativaQ2: [],
    reativaQ3: [],
    reativaQ4: []
  });

  useEffect(() => {
    const formatted = formatMeasuresData(data);
    setFormattedData(formatted);
  }, [data]);

  const { q1, q2, q3, q4, reativaQ1, reativaQ2, reativaQ3, reativaQ4 } = formattedData;

  const option = {
    title: [
      { text: "Geração", left: 380, top: 0},
      { text: "Demanda", right: 380, top: 0 },
      { text: "Q2 : INDUTIVO",
        left: '22%',
        top: '25%',
        textStyle: {
          color: '#D8D6D6',
          fontSize: 15
        }
      },
      { text: "Q1 : INDUTIVO",
        right: '22%',
        top: '25%',
        textStyle: {
          color: '#D8D6D6',
          fontSize: 15
        }
      },
      { text: "Q3 : CAPACITIVO",
        left: '22%',
        bottom: '20%',
        textStyle: {
          color: '#D8D6D6',
          fontSize: 15
        }
      },
      { text: "Q4 : CAPACITIVO",
        right: '22%',
        bottom: '20%',
        textStyle: {
          color: '#D8D6D6',
          fontSize: 15
        }
      },
    ],
    grid: [
      { left: '4%', top: '10%', width: '42%', height: '35%' }, // Q2
      { right: '6%', top: '10%', width: '42%', height: '35%' }, // Q1
      { left: '4%', bottom: '5%', width: '42%', height: '35%' }, // Q3
      { right: '6%', bottom: '5%', width: '42%', height: '35%' } // Q4
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const xAxisValue = params[0]?.axisValueLabel;

        const formattedParams = params.map((param) => {
          const value = param.value[1];

          // Formata o valor numérico
          const formattedValue = typeof value === 'string'
            ? parseFloat(value)?.toLocaleString('pt-BR')
            : value?.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);
        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: [
      { data: ['Demanda (kW) - Q2', 'Reativo (kVArh) - Q2'], left: '17%', top: '5%', selectedMode: false}, // Q2
      { data: ['Demanda Horário FP (kW) - Q1','Demanda Horário PTA (kW) - Q1', 'Reativo (kVArh) - Q1'], right: '8%', top: '5%', selectedMode: false}, // Q1
      { data: ['Demanda (kW) - Q3', 'Reativo (kVArh) - Q3'], left: '17%', bottom: '42%', selectedMode: false}, // Q3
      { data: ['Demanda Horário FP (kW) - Q4', 'Demanda Horário PTA (kW) - Q4', 'Reativo (kVArh) - Q4'], right: '8%', bottom: '42%', selectedMode: false}, // Q4
    ],
    xAxis: [
      { gridIndex: 0, type: 'category', boundaryGap: true }, // Q1
      { gridIndex: 1, type: 'category', boundaryGap: true }, // Q2
      { gridIndex: 2, type: 'category', boundaryGap: true }, // Q3
      { gridIndex: 3, type: 'category', boundaryGap: true } // Q4
    ],
    yAxis: [
      { gridIndex: 0, type: 'value',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          }
        }
       }, // Q1
      { gridIndex: 1, type: 'value',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          }
        }
       }, // Q2
      { gridIndex: 2, type: 'value',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          }
        }
       }, // Q3
      { gridIndex: 3, type: 'value',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          }
        }
      }  // Q4
    ],
    series: [
      {
        name: 'Demanda (kW) - Q2',
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: q2,
        itemStyle: {
          color: '#5470C6',
        },
      },
      {
        name: 'Demanda (kW) - Q1',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: q1,
        itemStyle: {
          color: function (params) {
            return postoHorario[params.dataIndex] === 'ponta'
              ? '#a90000'
              : '#87E35A';
          },
        },
      },
      {
        name: 'Demanda (kW) - Q3',
        type: 'bar',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: q3,
        itemStyle: {
          color: '#5470C6',
        },
      },
      {
        name: 'Demanda (kW) - Q4',
        type: 'bar',
        xAxisIndex: 3,
        yAxisIndex: 3,
        data: q4,
        itemStyle: {
          color: function (params) {
            return postoHorario[params.dataIndex] === 'ponta'
              ? '#a90000'
              : '#87E35A';
          },
        },
      },
      {
        name: 'Reativo (kVArh) - Q2',
        type: 'line',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: reativaQ2,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q1',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: reativaQ1,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q3',
        type: 'line',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: reativaQ3,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q4',
        type: 'line',
        xAxisIndex: 3,
        yAxisIndex: 3,
        data: reativaQ4,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Demanda Horário FP (kW) - Q1',
        type: 'bar',
        itemStyle: {
          color: '#87E35A',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        name: 'Demanda Horário PTA (kW) - Q1',
        type: 'bar',
        itemStyle: {
          color: '#a90000',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        name: 'Demanda Horário FP (kW) - Q4',
        type: 'bar',
        itemStyle: {
          color: '#87E35A',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 3,
        yAxisIndex: 3,
      },
      {
        name: 'Demanda Horário PTA (kW) - Q4',
        type: 'bar',
        itemStyle: {
          color: '#a90000',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 3,
        yAxisIndex: 3,
      },
      ...(uc?.modalidade === 'AZUL'
        ? [
            {
              name: 'Demanda Contratada FP',
              type: 'line',
              data: [uc?.demanda_fora * 1000],
              xAxisIndex: 1,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#a90000',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
            {
              name: 'Demanda Contratada PTA',
              type: 'line',
              data: [uc?.demanda_ponta * 1000],
              xAxisIndex: 1,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#FFA500',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
            {
              name: 'Demanda Contratada FP',
              type: 'line',
              data: [uc?.demanda_fora * 1000],
              xAxisIndex: 3,
              yAxisIndex: 3,
              itemStyle: {
                normal: {
                  color: '#a90000',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
            {
              name: 'Demanda Contratada PTA',
              type: 'line',
              data: [uc?.demanda_ponta * 1000],
              xAxisIndex: 3,
              yAxisIndex: 3,
              itemStyle: {
                normal: {
                  color: '#FFA500',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
          ]
        : [
            {
              name: 'Demanda Contratada',
              type: 'line',
              data: [uc?.demanda_fora * 1000],
              xAxisIndex: 1,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#a90000',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
            {
              name: 'Demanda Contratada',
              type: 'line',
              data: uc?.demanda_fora ? [uc?.demanda_fora * 1000 * -1] : [],
              xAxisIndex: 3,
              yAxisIndex: 3,
              itemStyle: {
                normal: {
                  color: '#a90000',
                  lineStyle: {
                    type: 'solid',
                  },
                },
              },
              markLine: { data: markLines },
            },
          ]),
    ]
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: 600 }}
      key={JSON.stringify(option)}
    />
  );
}
