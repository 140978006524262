// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useMenuGroupsPermissions() {
  const [noMenuPermissionsError, setNoMenuPermissionsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getMenuPermissions = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getMenuGroupPermissions(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (allData?.length === 0) {
      setNoMenuPermissionsError(Messages.noRegisteredComercializadoras);
      throw new Error(Messages.noRegisteredComercializadoras);
    }



    return allData
  };

  const {
    data: allData,
    error: menuPermissionsError,
    refetch: refreshMenuPermissions,
    isLoading,
  } = useQuery(['menu-permissions'], getMenuPermissions);

  return {
    menuPermissions: allData || [],
    menuPermissionsError,
    noMenuPermissionsError,
    refreshMenuPermissions,
    isLoading,
  };
}

export default useMenuGroupsPermissions;
