// fetchAlarms.js
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Style, Circle, Fill, Stroke } from 'ol/style';

export const fetchAlarms = (src_alarms, alarms_telemetry, createGradient) => {
  const url = src_alarms?.getUrl();

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response?.status}`);
      }
      return response.json();
    })
    .then((data) => {
      const { features } = data;
      alarms_telemetry?.getSource().clear();

      features.forEach((featureData) => {
        const pointFeature = new Feature(
          new Point(
            fromLonLat([
              featureData.geometry?.coordinates[0],
              featureData.geometry?.coordinates[1],
            ])
          )
        );
        const properties = {
          queda_energia: featureData.properties?.queda_energia,
          sem_medicao: featureData.properties?.sem_medicao,
          horario_ponta: featureData.properties?.horario_ponta,
          created_at: featureData.properties?.created_at,
        };
        pointFeature.setProperties(properties);

        const Queda = properties?.queda_energia;
        const Medicao = properties?.sem_medicao;
        const createdAt = properties?.created_at;

        let numAlarms = 0;
        if (Queda) numAlarms++;
        if (Medicao) numAlarms++;

        let style;
        if (numAlarms > 0) {
          let strokeColor = 'red';
          if (createdAt) {
            const createdDate = new Date(createdAt);
            const now = new Date();
            const timeDifference = now - createdDate;
            const hoursDifference = timeDifference / (1000 * 60 * 60);

            if (hoursDifference > 24) {
              strokeColor = 'gray';
            }
          }

          if (numAlarms > 1) {
            strokeColor = createGradient(numAlarms);
          }

          style = new Style({
            image: new Circle({
              radius: 9,
              fill: new Fill({ color: 'rgba(0, 0, 0, 0)' }),
              stroke: new Stroke({ color: strokeColor, width: 2 }),
            }),
          });
        }

        if (style) {
          pointFeature.setStyle(style);
        }

        alarms_telemetry?.getSource().addFeature(pointFeature);
      });
    })
    .catch((error) => {
      console.error('Erro durante o fetch:', error.message);
    });
};