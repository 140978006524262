import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useConsumerGroupFaturas(identification_number) {
  const { getSession } = useContext(AccountContext);

  // const pageSize = 300;

  const getFaturas = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllIdentificatioNumberFaturas(
      headers,
      identification_number
    );

    const faturas = response.data.data || [];

    if (faturas.length === 0) {
      console.error(Messages.noRegisteredEnergyBills);
      throw new Error(Messages.noRegisteredEnergyBills);
    }
    return faturas;
  };

  const {
    data: faturas,
    error: faturasError,
    refetch: refreshFaturas,
    isLoading,
  } = useQuery(['faturasPaginas', identification_number], getFaturas);

  return {
    faturas,
    faturasError,
    refreshFaturas,
    isLoading,
  };
}

export default useConsumerGroupFaturas;
