import React from 'react';
import PropType from 'prop-types';

//Imports from Material UI components
import { InputLabel, Select, MenuItem } from '@material-ui/core';

//Imports from Style
import { FormControlStyled, FormHelperTextStyled } from './style';

/**This component is a simple select styled that receive a object array and turn it in a list options for select */
export default function IoseSelection({
  title,
  object,
  defaultvalue,
  sidebar,
  func,
  disabled,
  top,
  right,
  onOpen,
  helpertext,
  onblur,
}) {
  const itemHeight = 48;
  const itemPaddingTop = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: itemHeight * 4.5 + itemPaddingTop,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    func(event.target.value);
  };

  const handleOnOpen = () => onOpen && onOpen();

  return (
    <FormControlStyled
      sidebar={sidebar}
      // disabled={disabled}
      top={top}
      right={right}
      error={helpertext ? true : false}
    >
      {sidebar ? <InputLabel>{title}</InputLabel> : <p>{title}</p>}
      <Select
        value={defaultvalue}
        onChange={handleChange}
        onOpen={handleOnOpen}
        onBlur={onblur}
        MenuProps={MenuProps}
      >
        {object.map((data, index) => {
          return (
            <MenuItem key={index} value={data}>
              {data.name ? data.name : data}
            </MenuItem>
          );
        })}
      </Select>
      {helpertext && <FormHelperTextStyled>{helpertext}</FormHelperTextStyled>}
    </FormControlStyled>
  );
}

IoseSelection.propTypes = {
  /**This props get a title of select list */
  title: PropType.string,
  /**This props get a object array contain all options with id and value  */
  object: PropType.array,
  /**This props get a  deafult value of select list*/
  defaultValue: PropType.any,
};
