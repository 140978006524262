import React, { useEffect, useState } from 'react';

import * as S from './style';

import { useConsumptionComparisonTelemetry, useGroupPermissions } from 'hooks';

import {
  IoseAlertLoadingCards,
  IoseComparisionTelemetryCharts,
  IoseSubHeaderBarWithFilters,
} from 'components';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';

import IoseTelemetryBillsComparisonContainer from 'components/DataContainers/IoseTelemetryBillsComparisonContainer';
import IoseRadarTelemetryContainer from 'components/IoseComparisonRadarContainer';

export default function TelemetryConsumptionComparisonContainer() {
  const {
    consumptionComparisonTelemetry,
  } = useConsumptionComparisonTelemetry();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [finalDate, setFinalDate] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasReadPermission, setHasReadPermission] = useState(true);

  useEffect(() => {
    const readPath = ['telemetria', 'dashboard_telemetria', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (
      consumptionComparisonTelemetry === undefined ||
      consumptionComparisonTelemetry.length === 0
    ) {
      setLoading(false);
    } else {
      setData(consumptionComparisonTelemetry);
      getDates(consumptionComparisonTelemetry);
    }
  }, [consumptionComparisonTelemetry]);

  const getDates = (data) => {
    const uniqueMonths = [];

    data?.forEach((obj) => {
      const { reference_month } = obj;

      if (!uniqueMonths.includes(reference_month)) {
        uniqueMonths.push(reference_month);
      }
    });

    uniqueMonths.sort((a, b) => {
      const [monthA, yearA] = a.split('/');
      const [monthB, yearB] = b.split('/');
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    });

    setDates(
      uniqueMonths.map((month) => ({
        value: month,
        label: month,
      }))
    );
  };

  const handleFilterChange = (filterName) => (event) => {
    const value = event.target.value;

    switch (filterName) {
      case 'initialDate':
        setInitialDate(value);
        break;
      case 'finalDate':
        setFinalDate(value);
        break;
      default:
        break;
    }
  };

  const filterData = () => {
    const [startMonth, startYear] = initialDate.split('/');
    const [endMonth, endYear] = finalDate.split('/');

    const filteredData = consumptionComparisonTelemetry?.filter((obj) => {
      const [month, year] = obj.reference_month.split('/');

      const initialDateObj = new Date(Number(year), Number(month) - 1);
      const finalDateObj = new Date(Number(endYear), Number(endMonth) - 1);

      return (
        initialDateObj >= new Date(Number(startYear), Number(startMonth) - 1) &&
        initialDateObj <= finalDateObj
      );
    });

    setData(filteredData);
  };

  const renderSubHeaderAndFilters = () => {
    return (
      <IoseSubHeaderBarWithFilters
        title={'Dashboard de Telemetria'}
        description={'Filtre os dados para alterar o Dashboard'}
        dates={dates}
        initialDate={initialDate}
        finalDate={finalDate}
        handleInitialChange={handleFilterChange('initialDate')}
        handleFinalChange={handleFilterChange('finalDate')}
        handleFilter={filterData}
      />
    );
  };

  const renderCharts = () => {
    return (
      <>
        <S.ChartsContainer>
          <IoseComparisionTelemetryCharts loading={loading} data={data} />
          <IoseRadarTelemetryContainer loading={loading} completeData={data} />
        </S.ChartsContainer>
      </>
    );
  };

  const renderTable = () => {
    return (
      <IoseTelemetryBillsComparisonContainer loading={loading} data={data} />
    );
  };

  return (
    <S.GridContainer>
      {hasReadPermission ? (
        <>
          {renderSubHeaderAndFilters()}
          {renderCharts()}
          {renderTable()}
        </>
      ) : (
        <div style={{ marginTop: '35px' }}>
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        </div>
      )}
    </S.GridContainer>
  );
}
