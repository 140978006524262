import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { IoseButton, IoseSelectReferenceMonth } from 'components';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function AdminDashboardContainerFiltro({
  setSelectedRefMonth,
}) {

  const [selectedDate, setSelectedDate] = useState(new Date(2024, 5, 1));

  const initialValues = {
    reference_month: '06/2024',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);

    if (filteredValues.reference_month) {
      setSelectedRefMonth(filteredValues.reference_month);
    }

  };

  useEffect(() => {
    setSelectedRefMonth('06/2024');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit">
              FILTRAR
            </IoseButton>

            {/* <IoseRemoveButton onClick={() => cleanAll(resetForm)}>
              LIMPAR FILTROS
            </IoseRemoveButton> */}
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
