import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import IosePermissionsModal from 'components/Modals/IosePermissionsModal';

import * as S from './style';
import { IoseAlertErroModal, IoseAlertSuccessModal } from 'components';

const formatKey = (key) => {
  const formattedKeys = {
    cadastro: 'Cadastro',
    ambientes: 'Ambientes',
    circuitos: 'Circuitos',
    comercializadoras: 'Comercializadoras',
    gemeo_digital: 'Gemeo Digital',
    contratos: 'Contratos',
    concessionarias: 'Concessionárias',
    unidades: 'Unidades',
    unidades_consumidoras: 'Unidades Consumidoras',
    fator_co2: 'Fator CO2',
    tarifas: 'Tarifas',
    ecus: 'Ecus',
    dashboards: 'Dashboards',
    faturas_energia: 'Faturas de Energia',
    faturas_anual: 'Faturas Anual',
    mercado_livre: 'Mercado Livre',
    emissao_carbono: 'Emissão de Carbono',
    comparacao_consumo: 'Comparação de Consumo',
    '3d': '3D',
    administrativo: 'Administrativo',
    gerenciamento: 'Gerenciamento',
    gerenciamento_contratos: 'Contratos',
    log_usuarios: 'Log de Usuários',
    relatorios: 'Relatórios',
    biene: 'Biene',
    contabil: 'Contábil',
    creditos: 'Créditos',
    relatorio_emissao_carbono: 'Emissão de Carbono',
    faturas: 'Faturas',
    itens_fatura: 'Itens da Fatura',
    medicao: 'Medição',
    sgf: 'SGF',
    telemetria: 'Telemetria',
    dashboard_telemetria: 'Dashboard Telemetria',
    mapa_telemetria: 'Mapa Telemetria',
    usuarios_alarmes: 'Usuários X Alarmes',
    geracao: 'Geração',
    dashboard_creditos: 'Dashboard de Créditos',
    geradoras_beneficiarias: 'Geradoras X Beneficiárias',
    geradoras: 'Geradoras',
    beneficiarias: 'Beneficiárias',
    mapas: 'Mapas',
    mapa_geo: 'Mapa Geo',
    parametros: 'Parâmetros',
    variaveis_sistema: 'Variáveis do Sistema',
    busca_faturas: 'Busca de Faturas',
    log_faturas: 'Log de Faturas',
    simulador_contratos: 'Simulador de Contratos',
    objetos: 'Objetos'
  };
  return formattedKeys[key] || key;
};


export default function MenuPermissionsContainer({
  permissions,
  submit,
  loading,
  showNotification,
  editNotificationType,
  editNotification,
}) {
  const [permissionsObject, setPermissionsObject] = useState(
    permissions?.permissions
  );
  const [accordionsOpen, setAccordionsOpen] = useState({});
  const [switchActive, setSwitchActive] = useState(null);
  const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

  const { uuid_permission, group_name } = permissions;

  const handleSubmit = async () => {
    submit(uuid_permission, group_name, permissionsObject);
  };

  const handleAccordionChange = (category) => {
    setAccordionsOpen((prevState) => ({
      ...prevState,
      [category]: !accordionsOpen[category],
    }));
  };

  const isAccordionOpen = (category) => !!accordionsOpen[category];

  const handleSwitchChange = (category, subcategory = null) => {
    setPermissionsObject((prevState) => {
      const updatedPermissions = { ...prevState };

      if (subcategory) {
        updatedPermissions[category][subcategory] = !prevState[category][subcategory];
      } else {
        updatedPermissions[category].access = !prevState[category].access;
      }

      return updatedPermissions;
    });

    setSwitchActive(subcategory ? `${category}-${subcategory}` : category);
    setTimeout(() => {
      setSwitchActive(null);
    }, 500);
  };

  const switchStyle = (category, subcategory = null) => {
    return {
      color:
        switchActive === `${category}-${subcategory}`
          ? '#015A9E'
          : subcategory
          ? permissionsObject[category][subcategory]
            ? '#015A9E'
            : 'grey'
          : permissionsObject[category].access
          ? '#015A9E'
          : 'grey',
      thumbColor: switchActive === `${category}-${subcategory}` ? '#015A9E' : null,
    };
  };

  const handleGrantAllPermissions = () => {
    setIsGrantModalOpen(true);
  };

  const handleRevokeAllPermissions = () => {
    setIsRevokeModalOpen(true);
  };

  const cancelGrantModal = () => {
    setIsGrantModalOpen(false);
  };

  const cancelRevokeModal = () => {
    setIsRevokeModalOpen(false);
  };

  const confirmGrantAllPermissions = () => {
    setPermissionsObject((prevState) => {
      const updatedPermissions = { ...prevState };
      Object.keys(updatedPermissions).forEach((category) => {
        Object.keys(updatedPermissions[category]).forEach((subcategory) => {
          updatedPermissions[category][subcategory] = true;
        });
      });
      return updatedPermissions;
    });
    setIsGrantModalOpen(false);
  };

  const confirmRevokeAllPermissions = () => {
    setPermissionsObject((prevState) => {
      const updatedPermissions = { ...prevState };
      Object.keys(updatedPermissions).forEach((category) => {
        Object.keys(updatedPermissions[category]).forEach((subcategory) => {
          updatedPermissions[category][subcategory] = false;
        });
      });
      return updatedPermissions;
    });
    setIsRevokeModalOpen(false);
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
        <Tooltip title="CONCEDER TODAS AS PERMISSÕES" arrow>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGrantAllPermissions}
            style={{ backgroundColor: '#00A65A', color: 'white' }}
          >
            <CheckIcon />
          </Button>
        </Tooltip>
        <Tooltip title="REVOGAR TODAS AS PERMISSÕES" arrow>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRevokeAllPermissions}
            style={{ backgroundColor: '#D60606', color: 'white' }}
          >
            <RemoveIcon />
          </Button>
        </Tooltip>
      </div>

      <IosePermissionsModal
        open={isGrantModalOpen}
        onClose={cancelGrantModal}
        submit={confirmGrantAllPermissions}
        cancel={cancelGrantModal}
        text={'Tem certeza que deseja conceder todas as permissões?'}
      />

      <IosePermissionsModal
        open={isRevokeModalOpen}
        onClose={cancelRevokeModal}
        submit={confirmRevokeAllPermissions}
        cancel={cancelRevokeModal}
        text={'Tem certeza que deseja revogar todas as permissões?'}
      />


      {Object.keys(permissionsObject).map((category) => (
        <Accordion
          key={category}
          expanded={isAccordionOpen(category)}
          onChange={() => handleAccordionChange(category)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
            style={{ color: 'black' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3>{formatKey(category)}</h3>
              <Switch
                color="primary"
                checked={permissionsObject[category].access}
                onChange={() => handleSwitchChange(category)}
                style={switchStyle(category)}
              />
            </div>
          </AccordionSummary>
          <Collapse in={isAccordionOpen(category) && permissionsObject[category].access}>
            <AccordionDetails>
              {Object.keys(permissionsObject[category])
                .filter((key) => key !== 'access')
                .map((subcategory) => (
                  <div key={subcategory} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '20px' }}>
                    <span>{formatKey(subcategory)}</span>
                    <Switch
                      color="primary"
                      checked={permissionsObject[category][subcategory]}
                      onChange={() => handleSwitchChange(category, subcategory)}
                      style={switchStyle(category, subcategory)}
                    />
                  </div>
                ))}
            </AccordionDetails>
          </Collapse>
        </Accordion>
      ))}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <S.DivCircularProgress>
            <S.CircularProgressBlueStyled/>
          </S.DivCircularProgress>
        ) : showNotification ? (
          <>
            {editNotificationType === 1 && (
              <IoseAlertSuccessModal text={editNotification} />
            )}

            {editNotificationType === 2 && (
              <IoseAlertErroModal text={editNotification} />
            )}
          </>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            style={{
              backgroundColor: '#015A9E',
              color: '#fff',
              marginTop: '15px',
            }}
          >
            Atualizar permissões
          </Button>
        )}
      </div>

    </div>
  );
};
