import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import {
  Error as ErrorIcon,
  Check as CheckIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import * as S from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

function formatDate(dataString) {
  const data = new Date(dataString);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const CircuitsTableRow = ({ value }) => {
  const {
    identification_number,
    measured_consumption,
    bill_consumption,
    bill_estimated,
    beginning_period,
    end_period,
    reference_month,
    balance,
    balance_status,
    measured_days,
  } = value;

  const classes = useStyles();
  const getIcon = () => {
    if (balance_status === 0) {
      return <ErrorIcon style={{ color: 'red' }} />;
    } else if (balance_status === 2) {
      return <CheckIcon style={{ color: 'green' }} />;
    } else {
      return <WarningIcon style={{ color: 'orange' }} />;
    }
  };

  return (
    <S.TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{identification_number}</TableCellCentered>
      <TableCellCentered>{reference_month}</TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(measured_consumption)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(bill_consumption)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(bill_estimated)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(balance)}
      </TableCellCentered>
      <TableCellCentered>{measured_days}</TableCellCentered>
      <TableCellCentered>{formatDate(beginning_period)}</TableCellCentered>
      <TableCellCentered>{formatDate(end_period)}</TableCellCentered>
      <TableCellCentered>{getIcon()}</TableCellCentered>
    </S.TableRowStyled>
  );
};

const CircuitsTable = ({ data }) => (
  <S.StyledTable>
    <S.TableHeadStyled>
      <S.TableRowStyled align="center">
        <TableHeaderCellCentered>UNIDADE CONSUMIDORA</TableHeaderCellCentered>
        <TableHeaderCellCentered>MÊS</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONSUMO IOSE (kWh)</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONSUMO CONTA (kWh)</TableHeaderCellCentered>
        <TableHeaderCellCentered>
          CONSUMO Estimado (kWh)
        </TableHeaderCellCentered>
        <TableHeaderCellCentered>BALANÇO (kWh)</TableHeaderCellCentered>
        <TableHeaderCellCentered>DIAS MEDIDOS</TableHeaderCellCentered>
        <TableHeaderCellCentered>INÍCIO DO PERÍODO</TableHeaderCellCentered>
        <TableHeaderCellCentered>FIM DO PERÍODO</TableHeaderCellCentered>
        <TableHeaderCellCentered>STATUS</TableHeaderCellCentered>
      </S.TableRowStyled>
    </S.TableHeadStyled>

    <TableBody>
      {data.map((value, index) => (
        <CircuitsTableRow key={index} value={value} />
      ))}
    </TableBody>
  </S.StyledTable>
);

export default function IoseEnergiBillTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          <CircuitsTable data={data} id="scrollableDiv" />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }
  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={parseInt(data?.length)}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={400}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
