import styled from 'styled-components';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WarningIcon from '@material-ui/icons/Warning';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Grid, IconButton, Typography } from '@material-ui/core';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 10px;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  padding: 10px;
  border-radius: 5px;
  width: 350px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-left: 2px solid var(--primary); */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CardTitulo = styled.h1`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary);
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary);
`;

export const ConsumoIcon = styled(FlashOnIcon)`
  color: white;
`;

export const CardConteudo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const CardItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0 10px 0;
  justify-content: space-between;
`;
export const CardChave = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: var(--primary);
  margin: 0;
  padding: 0;
`;

export const CardValor = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: var(--primary);
  margin: 0;
  padding: 0;
`;

export const VisualizadorTotaisContainer = styled.div`
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
`;
export const TotaisDiv = styled.div`
  grid-column: span 3;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TotaisHeader = styled.div`
  display: flex;
`;

export const TotaisTitulo = styled.h1`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary);
`;

export const TotaisValor = styled.h3`
  font-size: 25px;
  font-weight: 600;
  font-weight: bold;
  color: var(--primary);
`;

export const TotalTelemetriaIcon = styled(FlashOnIcon)`
  color: var(--primary);
  margin-right: 10px;
`;

export const TotalAlarmesIcon = styled(WarningIcon)`
  color: var(--primary);
  margin-right: 10px;
`;

export const TotalAlarmesMedindoIcon = styled(PowerSettingsNewIcon)`
  color: var(--primary);
  margin-right: 10px;
`;

export const TotaisPaper = styled.div`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-left: 2px solid var(--primary);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const BoxContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 0px;
  padding: 10px 0 0 0px;
  width: 320px;
  height: 180px;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  transition: 0.5s ease;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  @media (max-width: 1400px) {
    width: 250px;
  }
`;

// Estilos para BoxContainerHover permanecem os mesmos
export const BoxContainerHover = styled(BoxContainer)`
  &:hover {
    height: ${(props) => (props.showMore ? '200px' : '180px')};
    transform: ${(props) => (props.showMore ? 'scale(1.02)' : 'none')};
  }

  @media (max-width: 1400px) {
    &:hover {
      transform: ${(props) => (props.showMore ? 'scale(1.04)' : 'none')};
    }
  }
`;

export const CardGrid = styled(Grid)`
  margin: 0px;
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;

export const CardTitle = styled(Typography)`
  font-size: 15px;
  color: var(--primary);
  font-weight: bold;

  @media (max-width: 1450px) {
    font-size: 13px;
  }
`;

export const CardDataKey = styled(Typography)`
  font-size: 12px;
  color: var(--primary);
  font-style: italic;
  font-weight: bold;
  transition: 0.5s ease;
  margin-bottom: 2px;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const CardDataValue = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color: var(--primary);
  transition: 0.5s ease;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 0 15px;
`;

// export const ClickableIcon = styled(IconButton)`
//   position: absolute;
//   bottom: 10px;
//   left: 50%;
//   transform: translateX(-50%);
//   opacity: 0;
//   transition: opacity 0.5s ease;
//   cursor: pointer;
//   color: var(--primary);

//   ${BoxContainerHover}:hover & {
//     opacity: 1;
//   }
// `;
export const ClickableIcon = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;
  color: var(--primary);
  top: 48px;

  ${BoxContainerHover}:hover & {
    opacity: 1;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const IconHeader = styled.div`
  margin: 0 8px 8px 0;
  color: var(--primary);
`;
