import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSearchMessage,
  IoseNoDataMessage,
} from 'components';

import { useClient, LocalStorage, Permissions } from 'common';
import { useGroupPermissions } from 'hooks';
import ContabilFiltros from './components/contabil-filtros';

// import TelemetriaFiltros from './components/telemetria-filtros';

export default function ContabilMercadoLivreReportContainer() {
  const { client } = useClient();
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [emptyResponse, setEmptyResponse] = useState(false);

  const [hasReportPermission, setHasReportPermission] = useState(true);

  useEffect(() => {
    const reportPath = ['relatorios', 'contabil', 'emitir_relatorio'];

    if (userSession && groupPermissions) {

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );

      setHasReportPermission((prev) => {
        if (prev !== reportPermission) {
          console.log(
            `Updating report permission from ${prev} to ${reportPermission}`
          );
          return reportPermission;
        }
        return prev;
      });
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title="Relatório Contábil"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>
            {emptyResponse === true ? (
              <IoseNoDataMessage
                message="Não há dados para o filtro selecionado. Por favor, tente novamente."
                height="350px"
                width="350px"
              />
            ) : (
              <IoseSearchMessage
                message={`Selecione os filtros de busca desejados e clique em "EMITIR RELATÓRIO".`}
                height="400px"
                width="400px"
              />
            )}
          </div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      {hasReportPermission ? (
        <S.WrappedContainer>
          {renderSubHeaderAndSearchBar()}
          {
            <ContabilFiltros
              loading={setLoading}
              emptyResponse={setEmptyResponse}
            />
          }
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            renderContent()
            )
          }
        </S.WrappedContainer>
      ) : (
        <div style={{ marginTop: '150px' }}>
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão para emissão deste relatório!'
            }
          />
        </div>
      )}
    </Grow>
  );
}
