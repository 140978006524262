import React, { useState } from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DesperdicioTable from './DesperdicioTable';
import DesperdicioTableGeral from './DesperdicioTableGeral';
import { useCreateLogs } from 'hooks';
import { IoseButton2 } from 'components';
import desperdiciosReport from 'reports/EnergyBills/desperdiciosReport';
import desperdiciosReportGeral from 'reports/EnergyBills/desperdiciosReportGeral';
import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import * as S from './style';
import * as XLSX from 'xlsx';

import GetAppIcon from '@material-ui/icons/GetApp';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  modal: {
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function IoseDesperdicioChart({
  open,
  onClose,
  selectedCard,
  filteredDesperdicios,
  hasReportPermission,
}) {
  const [filteredTableData, setFilteredTableData] = useState([]);
  const classes = useStyles();
  const { createLog } = useCreateLogs();
  const cardSelecionado = selectedCard?.title;
  const isReportButtonDisabled = filteredDesperdicios?.length === 0;

  const sortData = (data, sortBy) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.sort((a, b) => {
      const valueA = parseFloat(a[sortBy]) || 0;
      const valueB = parseFloat(b[sortBy]) || 0;

      return valueB - valueA; // Ordena do maior para o menor
    });
  };

  const handleFilteredDataChange = useCallback((filteredData) => {
    // Ordene os dados antes de definir o estado
    const sortedData = sortData(filteredData, 'item_total_value_1');
    setFilteredTableData(sortedData);
  }, []);

  const renderTableOrImage = () => (
    <div className={classes.tableContainer} style={{ position: 'relative' }}>
      {cardSelecionado === 'TOTAIS' ? (
        <DesperdicioTableGeral
          onFilteredDataChange={handleFilteredDataChange}
          data={filteredDesperdicios}
        />
      ) : (
        <DesperdicioTable
          data={filteredDesperdicios}
          onFilteredDataChange={handleFilteredDataChange}
        />
      )}
    </div>
  );

  const handleDesperdicioReport = async () => {
    if (cardSelecionado === 'TOTAIS') {
      await desperdiciosReportGeral(filteredTableData);
    } else {
      await desperdiciosReport(filteredTableData);
    }

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE DESPERDÍCIOS';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };
  function getOrdinal(number) {
    if (number % 10 === 1 && number % 100 !== 11) {
      return `${number}°`;
    } else if (number % 10 === 2 && number % 100 !== 12) {
      return `${number}°`;
    } else if (number % 10 === 3 && number % 100 !== 13) {
      return `${number}°`;
    } else {
      return `${number}°`;
    }
  }

  function exportTelemetryMeasuresToExcel(data) {
    const dataWithOrdinal = data.map((obj, index) => ({
      ...obj,
      ordinal: getOrdinal(index + 1), // Adiciona a coluna ordinal com base na posição
    }));
    // Reordena as colunas
    const reorderedColumns = [
      'ordinal',
      'identification_number',
      'reference_month',
      'city',
      'general_management',
      'regional_management',
      'item_consumption_1',
      'item_total_value_1',
      'referente',
    ];

    // Reordena os dados
    const reorderedData = dataWithOrdinal.map((obj) => {
      const reorderedObj = {};
      reorderedColumns.forEach((column) => {
        reorderedObj[column] = obj[column];
      });
      return reorderedObj;
    });

    const worksheet = XLSX.utils.json_to_sheet(reorderedData);

    const headers = {
      ordinal: 'Ranking de Desperdícios',
      identification_number: 'UC',
      reference_month: 'Mês de Referência',
      city: 'Cidade',
      regional_management: 'Gerência Regional',
      general_management: 'Gerência Geral',
      item_consumption_1: 'Consumo',
      item_total_value_1: 'Valor (R$)',
      referente: 'Referente',
    };
    // Altere o nome das colunas no cabeçalho da planilha
    const columns = Object.keys(headers);
    columns.forEach((column, index) => {
      const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
      headerCell.v = headers[column];
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
    XLSX.writeFile(workbook, 'desperdicios.xlsx');
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <BatteryChargingFullIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {`${cardSelecionado}`}
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
            {!hasReportPermission ? (
              <>
                <IoseButton2
                  onClick={handleDesperdicioReport}
                  width="150px"
                  disabled={isReportButtonDisabled}
                >
                  <span style={{ marginRight: '5px' }}>PDF</span>
                  <GetAppIcon />
                </IoseButton2>
                <IoseButton2
                  style={{
                    margin: '10px',
                  }}
                  onClick={() =>
                    exportTelemetryMeasuresToExcel(filteredTableData)
                  }
                  width="150px"
                >
                  <span style={{ marginRight: '5px' }}>XLSX</span>
                  <GetAppIcon />
                </IoseButton2>
              </>
            ) : null}
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom />
            {renderTableOrImage()}
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
