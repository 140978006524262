import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Imports from Material UI components
import {
  makeStyles,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
} from '@material-ui/core';

//Imports from Style
import {
  TableStyled,
  TableContainerStyled,
  TablePaginationStyled,
  TableCellHeader,
  Container,
  Error,
} from './style';

import Messages from 'common/Messages';

/** This component render a responsive table with logs circuit selected, pagination and ordination */
export default function IoseCircuitLogTable({
  dashboard,
  getAllRows,
  uuid_circuit,
  getDataUser,
  setLoading,
}) {
  const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
      textColor: '#FFFFFF',
    },
  }));

  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const [firstTime, setFirstTime] = useState(true);

  const [nextPage, setNextPage] = useState(undefined);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('data');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead({
    classes,
    order,
    orderBy,
    onRequestSort,
    group,
    dashboard,
  }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Data',
        sort: true,
      },
      // {
      //   id: 'type',
      //   numeric: false,
      //   disablePadding: false,
      //   label: 'Tipo',
      // sort:true,
      // },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Descrição ',
        sort: true,
      },
      {
        id: 'justification',
        numeric: false,
        disablePadding: false,
        label: 'Justificativa ',
        sort: true,
      },
      {
        id: 'user_name',
        numeric: false,
        disablePadding: false,
        label: 'Usuário ',
        sort: true,
      },
    ];

    const renderTableCellHeader = (headCell) => {
      const cellSortHeader = (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );

      const cellHeader = (
        <TableCellHeader
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
          align="center"
        >
          {headCell.sort ? (
            cellSortHeader
          ) : (
            <p className="NoSortLabel">{headCell.label}</p>
          )}
        </TableCellHeader>
      );

      return cellHeader;
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => renderTableCellHeader(headCell))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Const to calc the number empty Rows
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getAllData = async () => {
    const pageTotal = parseFloat((rows.length / rowsPerPage).toFixed(0));

    const numberRows = rows.length;
    const isInLastPage = page === pageTotal - 1 && nextPage !== undefined;
    const showingAllItens = numberRows < rowsPerPage && nextPage !== undefined;

    setLoading(true);
    if (page === 0 && firstTime) {
      const response = await getAllRows(uuid_circuit);

      if (response) {
        const rowsNames = await insertUserName(response.data.logs);
        setRows(rowsNames);
        setNextPage(response.next_page);
      }

      setFirstTime(false);
    } else if (isInLastPage || showingAllItens) {
      const response = await getAllRows(uuid_circuit, nextPage);

      if (response) {
        const rowsNames = await insertUserName(response.data.logs);
        setRows(rows.concat(rowsNames));
        setNextPage(response.next_page);
      }
    }
    setLoading(false);
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);

    let year = dateObj.getFullYear();

    let month = dateObj.getMonth() + 1;
    let monthLeftZero = ('00' + month).slice(-2);

    let day = dateObj.getDate();
    let dayLeftZero = ('00' + day).slice(-2);

    let hours = dateObj.getHours();
    let hoursLeftZero = ('00' + hours).slice(-2);

    let minutes = dateObj.getMinutes();
    let minutesLeftZero = ('00' + minutes).slice(-2);

    let seconds = dateObj.getSeconds();
    let secondsLeftZero = ('00' + seconds).slice(-2);

    return `${dayLeftZero}/${monthLeftZero}/${year} ${hoursLeftZero}:${minutesLeftZero}:${secondsLeftZero}`;
  };

  const insertUserName = async (rows) => {
    const rowsNames = await Promise.all(
      rows.map(async (row) => {
        let userName = '';

        const data = await getAllDataUser(row);

        if (data) {
          userName = data === 'is_a_module' ? Messages.actionModule : data.name;
        } else {
          userName = Messages.noUserLog;
        }

        row['user_name'] = userName;
        return row;
      })
    );

    return rowsNames;
  };

  const getAllDataUser = async (row) => {
    let data = '';

    if (row.type === 'USER_REQUIRE') {
      if (row.uuid_admin && row.uuid_admin !== '') {
        data = await getDataUser('admin', row.uuid_admin);
      } else if (row.uuid_employee && row.uuid_employee !== '') {
        data = await getDataUser('employee', row.uuid_employee);
      } else {
        data = false;
      }
    } else {
      data = 'is_a_module';
    }

    return data;
  };

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const renderError = () => {
    if (!firstTime) {
      return <Error>{rows.length === 0 && Messages.emptyLogs}</Error>;
    }
  };

  const renderHeader = () => (
    <EnhancedTableHead
      classes={classes}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
    />
  );

  const renderJustification = (row) => {
    return row.justification
      ? row.justification
      : 'Ação não requer justificativa';
  };

  const renderBody = () => (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          return (
            <TableRow tabIndex={-1} key={row.uuid_log}>
              <TableCell>{formatDate(row.created_at)} </TableCell>
              {/* <TableCell>{row.type} </TableCell> */}
              <TableCell>{row.description} </TableCell>
              <TableCell>{renderJustification(row)}</TableCell>
              <TableCell>{row.user_name} </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );

  const renderPagination = () => (
    <TablePaginationStyled
      rowsPerPageOptions={[10, 20]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      dashboard={dashboard}
      labelRowsPerPage={'Medidas por página'}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} ao ${to} de ${count}`
      }
    />
  );

  return (
    <Container>
      <TableContainerStyled>
        {renderError()}

        <TableStyled dashboard={dashboard}>
          {renderHeader()}

          {renderBody()}
        </TableStyled>
      </TableContainerStyled>

      {/* Pagination */}
      {renderPagination()}
    </Container>
  );
}

IoseCircuitLogTable.propTypes = {
  rows: PropTypes.array,
  /** This props is a boolean that indicate that table to be in dashboard, adn determinate style is apply it*/
  dashboard: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** This props is a function when invoke get all measure that all circuits in container, it is used to pagination*/
  getAllMeasuresAllCircuit: PropTypes.func,
  /** This props indicate a erro in resquest API*/
  logsError: PropTypes.string,
};
