import React, { useState } from 'react';
import { Slide } from '@material-ui/core';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import * as S from './style';

import { IoseNoDataMessage, IoseAdmDashTableCircuits } from 'components';

export default function IoseAdmDashContainerCircuits({ loading, data }) {
  const [searchCircuits, setSearchCircuits] = useState('');

  const filteredCircuits = data?.filter((circuit) =>
    circuit?.uuid_circuit.toLowerCase().includes(searchCircuits.toLowerCase())
  );

  const renderHeader = () => {
    return (
      <>
        <S.Header>
          <OfflineBoltIcon />
          <h3>CIRCUITOS</h3>
          <S.Search>
            <S.InputBaseStyled
              type="text"
              placeholder="Pesquisar por nome do circuito..."
              value={searchCircuits}
              onChange={(event) => setSearchCircuits(event.target.value)}
            />
            <S.SearchIconStyled />
          </S.Search>
        </S.Header>
      </>
    );
  };

  const CircuitsData = filteredCircuits?.map(
    ({ uuid_circuit, consumption, group_name }) => ({
      group: group_name,
      id: uuid_circuit,
      consumption,
    })
  );

  function renderTableOrErrorMessage() {
    if (CircuitsData && CircuitsData.length > 0) {
      return (
        <IoseAdmDashTableCircuits
          loading={loading}
          CircuitsData={CircuitsData}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="280px"
          width="250px"
        />
      );
    }
  }

  return (
    <Slide direction="left" in={true} timeout={2000} mountOnEnter unmountOnExit>
      <S.Wrapper>
        <S.Container>
          {renderHeader()}
          {renderTableOrErrorMessage()}
        </S.Container>
      </S.Wrapper>
    </Slide>
  );
}
