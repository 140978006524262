import React, { useState, useEffect } from 'react';
import {
  IoseChartCredit,
  IoseFilterConsumerGroup,
  IoseButton,
  NoDataMessage,
  ReportMessage,
  IoseCardCreditDash,
  IoseAlertLoadingCards,
  IoseSelectReferenceMonth,
  IoseSubHeaderBarWithFilters,
} from 'components';

import * as S from './style';

import {
  useConsumerGroup,
  useBeneficiaryJoin,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';

import { DivPaper } from 'styles/defaultStyles';

export default function CreditDashboard() {
  const { consumerGroups } = useConsumerGroup();
  const { BeneficiaryJoinData } = useBeneficiaryJoin();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [identificationNumber, setIdentificationNumber] = useState('100627188');
  const [selectedDate, setSelectedDate] = useState(new Date(2024, 5, 1));
  const [ucGeradoraData, setUcGeradoraData] = useState([]);
  const [noDataError, setNoDataError] = useState(true);
  const [
    filteredBeneficiaryJoinData,
    setFilteredBeneficiaryJoinData,
  ] = useState([]);
  const [
    filteredBeneficiaryJoinDataGeral,
    setFilteredBeneficiaryJoinDataGeral,
  ] = useState([]);
  const [
    filteredBeneficiariaJoinData,
    setFilteredBeneficiariaJoinData,
  ] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('06/2024');
  const [filteredData, setFilteredData] = useState([]);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [loading, setLoading] = useState(true);

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['geracao', 'dashboard_creditos', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, []);

  useEffect(() => {
    if (consumerGroups.length && BeneficiaryJoinData.length) {
      const consumerGroupsMap = consumerGroups.reduce((map, group) => {
        map[group.identification_number] = {
          city: group.city,
          general_management: group.general_management,
          regional_management: group.regional_management,
        };
        return map;
      }, {});

      const allBeneficiaries = BeneficiaryJoinData.flatMap(
        (item) => item.uc_beneficiarias
      );

      const combinedData = allBeneficiaries.map((beneficiaria) => {
        const extraInfo = consumerGroupsMap[beneficiaria.uc_beneficiaria] || {};
        return {
          ...beneficiaria,
          ...extraInfo,
        };
      });

      setFilteredBeneficiaryJoinData(combinedData);
    }
  }, [consumerGroups, BeneficiaryJoinData]);

  const formatToBrazilianDate = (date) => {
    if (date) {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${year}`;
    }
    return '';
  };

  const handleIdentificationNumberChange = (identification_number) => {
    setIdentificationNumber(identification_number);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedMonth(formatToBrazilianDate(date));
  };

  useEffect(() => {
    setIsFilterEnabled(selectedDate || identificationNumber);
  }, [selectedDate, identificationNumber]);

  useEffect(() => {
    if (identificationNumber) {
      const filteredData = BeneficiaryJoinData?.filter(
        (item) => item.uc_geradora === identificationNumber
      );
      setUcGeradoraData(filteredData);
      setFilteredBeneficiaryJoinData(filteredData);
    }
  }, [identificationNumber, BeneficiaryJoinData]);

  useEffect(() => {
    if (consumerGroups.length && BeneficiaryJoinData.length) {
      const consumerGroupsMap = consumerGroups.reduce((map, group) => {
        map[group.identification_number] = {
          city: group.city,
          general_management: group.general_management,
          regional_management: group.regional_management,
        };
        return map;
      }, {});

      const filteredDataMes = BeneficiaryJoinData.filter(
        (item) => item.mes_referencia === selectedMonth
      );

      const combinedData = filteredDataMes.map((geradora) => {
        const extraInfo = consumerGroupsMap[geradora.uc_geradora] || {};
        return {
          ...geradora,
          ...extraInfo,
        };
      });

      const uniqueCombinedData = Array.from(
        new Map(combinedData.map((item) => [item.uc_geradora, item])).values()
      );
      setFilteredBeneficiaryJoinDataGeral(uniqueCombinedData);
    }
  }, [consumerGroups, BeneficiaryJoinData, selectedMonth]);

  useEffect(() => {
    if (
      consumerGroups.length &&
      BeneficiaryJoinData.length &&
      identificationNumber &&
      selectedDate
    ) {
      getAuditData();
    }
  }, [consumerGroups, BeneficiaryJoinData, identificationNumber, selectedDate]);

  useEffect(() => {
    const filteredDataMes = filteredBeneficiaryJoinData?.filter(
      (item) => item.mes_cred_utilizado === selectedMonth
    );
    setFilteredBeneficiariaJoinData(filteredDataMes);
  }, [selectedMonth, filteredBeneficiaryJoinData]);

  const getAuditData = () => {
    if (identificationNumber || selectedDate) {
      const selectedMonthYear = `0${
        selectedDate.getMonth() + 1
      }/${selectedDate.getFullYear()}`;

      const filteredData = BeneficiaryJoinData?.filter((item) => {
        return (
          item.uc_geradora === identificationNumber &&
          item.mes_referencia === selectedMonthYear
        );
      });

      setButtonClicked(true);

      if (filteredData.length === 0) {
        setNoDataError(true);
      } else {
        setNoDataError(false);
      }
      setFilteredData(filteredData);
    } else {
      setNoDataError(true);
    }
  };

  const consumerGroupsOptionsGeradora = consumerGroups
    ?.filter((item) => item.geradora === 'SIM')
    .map((item) => ({
      value: item.identification_number,
      label: item.identification_number,
    }));

  if (
    !consumerGroupsOptionsGeradora.find(
      (option) => option.value === identificationNumber
    )
  ) {
    consumerGroupsOptionsGeradora.unshift({
      value: identificationNumber,
      label: identificationNumber,
    });
  }

  const consumerGroupsOptions = consumerGroupsOptionsGeradora;

  const renderUnityContainer = (filteredBeneficiaryJoinData) => {
    return (
      <div>
        <IoseChartCredit
          ucGeradoraData={ucGeradoraData}
          selectedDate={selectedDate}
          beneficiaryJoinData={filteredBeneficiaryJoinData}
          filteredData={filteredData}
          filteredBeneficiariaJoinData={filteredBeneficiariaJoinData}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <S.WrapContainer>
      <h3 style={{ fontSize: '23px', paddingBottom: '11px', color: '#015A9E' }}>
        Dashboard de Créditos
      </h3>

      {hasReadPermission ? (
        <>
          <h2 style={{ color: 'grey', paddingBottom: '15px' }}>
            Filtre os dados para alterar o Dashboard
          </h2>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={handleDateChange}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton onClick={getAuditData} height="35px" type="submit">
              FILTRAR
            </IoseButton>
          </S.FormDivContainer>
          <IoseSubHeaderBarWithFilters title={'Totais Gerais: '} />

          <DivPaper>
            <IoseCardCreditDash
              loading={loading}
              auditValues={''}
              auditConsumptions={'auditConsumptions'}
              auditExtract={'auditExtract'}
              averages={'averages'}
              beneficiaryJoinData={filteredBeneficiaryJoinDataGeral}
              filteredBeneficiariaJoinData={filteredBeneficiariaJoinData}
            />
          </DivPaper>
          <div style={{ marginBottom: '19px' }}></div>

          <S.GridContainer
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <S.DatesContainer>
              <div style={{ marginRight: '5px' }}></div>

              <IoseFilterConsumerGroup
                label="U.C."
                value={identificationNumber}
                onChange={handleIdentificationNumberChange}
                options={consumerGroupsOptions}
              />
              <div style={{ marginRight: '19px' }}></div>

              <div style={{ marginRight: '12px' }}></div>
            </S.DatesContainer>

            {noDataError && buttonClicked ? (
              <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
            ) : buttonClicked ? (
              renderUnityContainer(filteredBeneficiaryJoinData, ucGeradoraData)
            ) : (
              <>
                <ReportMessage message="Para filtrar o Dashboard, selecione os filtros desejados." />
              </>
            )}
          </S.GridContainer>
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.WrapContainer>
  );
}
