// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConsumerGroup() {
  const [noConsumerGroupsError, setNoConsumerGroupsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getConsumerGroups = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getConsumerGroups(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    const telemetryConsumerGroups = allData?.filter(consumerGroup =>
      consumerGroup.telemetry === 'SIM'
    );

    if (allData?.length === 0) {
      setNoConsumerGroupsError(Messages.noRegisteredConsumerGroups);
      throw new Error(Messages.noRegisteredConsumerGroups);
    }

    const consumerGroupsForSelect = allData?.map((consumerGroup) => ({
      value: consumerGroup.identification_number,
      label: consumerGroup.identification_number,
      gr: consumerGroup.regional_management,
    }));

    const telemetryConsumerGroupsForSelect = telemetryConsumerGroups?.map(
      (consumerGroup) => ({
        value: consumerGroup.identification_number,
        label: consumerGroup.identification_number,
      })
    );

    const uniqueValues = new Set();

    const subgrupoConsumerGroupForSelect = allData
    ?.filter(classficacao => {
      if (!uniqueValues.has(classficacao.subgrupo)) {
        uniqueValues.add(classficacao.subgrupo);
        return true;
      }
      return false;
    })
    .map(classficacao => ({
      value: classficacao.subgrupo,
      label: classficacao.subgrupo,
    }));



    const consumerGroupsUniqueCities = Array.from(
      new Set(
        allData
          ?.map((consumerGroup) => consumerGroup.city)
          .filter(Boolean),
      ),
    )

    const consumerGroupsCitiesForSelect = consumerGroupsUniqueCities.map(
      (city) => ({
        value: city,
        label: city,
      }),
    )

    const consumerGroupsUniqueMeters = Array.from(
      new Set(
        allData
          ?.map((consumerGroup) => consumerGroup.meter)
          .filter((meter) => meter && meter !== "0")
      )
    );

    const consumerGroupsMetersForSelect = consumerGroupsUniqueMeters.map(
      (meter) => ({
        value: meter,
        label: meter,
      }),
    )



    return {
      allData,
      telemetryConsumerGroups,
      consumerGroupsForSelect,
      telemetryConsumerGroupsForSelect,
      subgrupoConsumerGroupForSelect,
      consumerGroupsCitiesForSelect,
      consumerGroupsMetersForSelect
    };
  };

  const {
    data: {
      allData,
      telemetryConsumerGroups,
      consumerGroupsForSelect,
      telemetryConsumerGroupsForSelect,
      subgrupoConsumerGroupForSelect,
      consumerGroupsCitiesForSelect,
      consumerGroupsMetersForSelect
    } = {},
    error: consumerGroupsError,
    refetch: refreshConsumerGroups,
    isLoading,
  } = useQuery(['consumerGroupData'], getConsumerGroups);

  return {
    consumerGroups: allData || [],
    telemetryConsumerGroups: telemetryConsumerGroups || [],
    consumerGroupsForSelect: consumerGroupsForSelect,
    telemetryConsumerGroupsForSelect: telemetryConsumerGroupsForSelect,
    subgrupoConsumerGroupForSelect: subgrupoConsumerGroupForSelect,
    consumerGroupsCitiesForSelect: consumerGroupsCitiesForSelect,
    consumerGroupsMetersForSelect: consumerGroupsMetersForSelect,
    consumerGroupsError,
    noConsumerGroupsError,
    refreshConsumerGroups,
    isLoading,
  };
}

export default useConsumerGroup;
