import styled from 'styled-components';

/**This component create a card black inside the sidebar*/
const IoseSideCard = styled.div`
  padding: 0px;
  margin: 10px 0px;
  width: 100%;
  height: auto;
  background-color: var(--primary);
  border-radius: 5px;
  display: flex;
`;

/** @component */
export default IoseSideCard;
