import styled from 'styled-components';

//Imports from Material UI components
import { FormControl, FormHelperText } from '@material-ui/core';

export const FormControlStyled = styled(FormControl)`
  min-width: 180px;
  margin-top: ${(props) => (props.top ? props.top : '10px')};
  margin-right: ${(props) => (props.right ? props.right : '10px')};

  p {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid var(--primary);
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid var(--primary);
  }

  .MuiInputBase-root input {
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid var(--primary);
    font-size: 12px;
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props.sidebar === 'true' ? 'var(--primary)' : 'var(--primary)'};
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.sidebar === 'true' ? 'white' : 'var(--primary)'};
    opacity: 100%;
    font-size: 12px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.sidebar === 'true' ? 'white' : 'var(--primary)'};
    opacity: 100%;
  }

  .Mui-disabled {
    color: ${(props) =>
      props.sidebar === 'true' ? 'white' : 'var(--primary)'};
    opacity: 50%;
  }
`;

export const FormHelperTextStyled = styled(FormHelperText)`
  margin-top: 0px !important;
`;
