import React, { useContext, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { IoseButton } from 'components';
import { AccountContext, ioseAPI } from 'common';
import IoseSelectYear from '../../../components/IoseSelectYear';

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function SummaryDashYearFilter({
  setLoading,
  setData
}) {
  const { getSession } = useContext(AccountContext);

  const [selectedYear, setSelectedYear] = useState(new Date(2024, 0, 1)); // Ano padrão

  async function getResumoAnual(year) {
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getResumoAnualPorAno(
        sessionData.headers,
        year
      );

      const { data } = response.data;

      setData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const initialValues = {
    year: '2024',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);

    await getResumoAnual(filteredValues.year);
  };

  useEffect(() => {
    getResumoAnual('2024');
    //eslint-disable-next-line
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseSelectYear
                label="ANO"
                width="150px"
                handleChange={(value) => {
                  setSelectedYear(value);
                  setFieldValue('year', value.getFullYear());
                }}
                selectedDate={selectedYear}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit">
              FILTRAR
            </IoseButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
