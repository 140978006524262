import React, { useEffect, useState, useContext } from 'react';

import { AccountContext, useClient, ioseAPI, LocalStorage } from 'common';

import { FilterText, FilterWrappedContainer, WrappedContainer } from './style';

import {
  FilterSelect,
  IoseButton,
  IoseFiltersMessage,
  IoseSubHeaderBar,
  IoseMapRedirectButton,
  LoadingIndicator,
  NoDataMessage,
  TariffComponentsTable,
  TariffsConcessionaires,
} from 'components';

import { TariffsYears } from 'constants/tariffsYears';

export default function TariffComponentsContainer() {
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);

  const [clientData, setClientData] = useState({});
  const [concessionaire, setConcessionaire] = useState(null);
  const [group, setGroup] = useState();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tariffComponents, setTariffComponents] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (concessionaire !== null || year !== null) {
      setIsFilterEnabled(true);
    } else {
      setIsFilterEnabled(false);
    }
  }, [concessionaire, year]);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getTariffComponents = async (concessionaire, year) => {
    setLoading(true);
    const { headers } = getSession();

    const response = await ioseAPI.getTariffComponentsByConcessionaireAndYear(
      headers,
      concessionaire,
      year
    );

    const { data } = response;
    const tariffs = data[0] === null ? [] : data;

    if (tariffs.length === 0) {
      setTariffComponents([]);
      setLoading(false);
      setConcessionaire(null);
      setYear(null);
    } else {
      setTariffComponents(tariffs);
      setLoading(false);
      setConcessionaire(null);
      setYear(null);
    }
  };

  const handleConcessionaireChange = (event) => {
    const concessionaire = event.target.value;
    setConcessionaire(concessionaire);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setYear(year);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Componentes Tarifários`}
        // description={'Total: ' + allContracts.length}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => {}}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    return (
      <FilterWrappedContainer>
        <div>
          <FilterText>Filtros:</FilterText>
        </div>

        <div>
          <FilterSelect
            label="Concessionária"
            value={concessionaire}
            onChange={handleConcessionaireChange}
            options={TariffsConcessionaires}
          />
        </div>
        <div>
          <FilterSelect
            label="Vigência"
            value={year}
            onChange={handleYearChange}
            options={TariffsYears}
          />
        </div>

        <div>
          <IoseButton
            onClick={() => getTariffComponents(concessionaire, year)}
            disabled={!isFilterEnabled}
          >
            FILTRAR
          </IoseButton>
        </div>
      </FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        {tariffComponents === null ? (
          <div>
            <IoseFiltersMessage message="Para visualizar os componentes tarifários desejados, escolha pelo menos uma das opções de filtro nos campos acima." />
          </div>
        ) : tariffComponents.length > 0 ? (
          <div>
            <TariffComponentsTable data={tariffComponents} />
          </div>
        ) : (
          <div>
            <NoDataMessage message="Desculpe, não foram encontrados componentes tarifários com os critérios de filtro informados. Por favor, revise suas seleções e tente novamente." />
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>
              {renderFilters()}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </WrappedContainer>
  );
}
