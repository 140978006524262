// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueConcessionaires() {
  const [noConcessioaniresError, setNoConcessionairesError] = useState('');

  const { consumerGroups } = useConsumerGroup();

  const getUniqueConcessionaires = async () => {
    if (!consumerGroups || consumerGroups.length === 0) {
      setNoConcessionairesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    const concessionaireSet = new Set();

    consumerGroups.forEach((item) => {
      if (item.concessionaire && item.concessionaire !== '0') {
        concessionaireSet.add(item.concessionaire);
      }
    });


    const uniqueConcessionaires = [...concessionaireSet].map(
      (concessioanire) => ({
        value: concessioanire,
        label: concessioanire,
      })
    );

    if (uniqueConcessionaires.length === 0) {
      setNoConcessionairesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }


    return { uniqueConcessionaires };
  };

  const {
    data: { uniqueConcessionaires } = {},
    error: uniqueConcessionairesError,
    refetch: refreshUniqueConcessionaires,
    isLoading,
  } = useQuery(['uniqueConcessionaires', consumerGroups], getUniqueConcessionaires, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });



  return {
    uniqueConcessionaires,
    uniqueConcessionairesError,
    noConcessioaniresError,
    refreshUniqueConcessionaires,
    isLoading,
  };
}

export default useUniqueConcessionaires;
