import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function GraficoConsumo(props) {
  const dados = props.data;
  var consumed = [];
  var forecast = [];
  var date = [];
  let reativo = [];

  dados.forEach((obj) => {
    consumed.push(obj.consumido);
    reativo.push(obj.reativoConsumido);
    const [day, month] = obj.date.split('/');
    const year = new Date().getFullYear(); // Assume o ano atual
    const dateObj = new Date(year, month - 1, day);
    const dayOfWeek = new Date(dateObj).getDay();
    const fimSemana = dayOfWeek === 0 || dayOfWeek === 6;
    forecast.push(
      fimSemana ? props.fimSemana.toFixed(2) : props.semana.toFixed(2)
    );
    date.push(obj.date);
  });

  const option = {
    grid: {
      left: '2%',
      right: '10%',
      bottom: '5%',
      top: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params?.map((param) => {
          if (param.value === undefined) return '';
          const formattedValue =
            typeof param?.value === 'string'
              ? parseFloat(param?.value).toLocaleString('pt-BR')
              : param?.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: ['Consumo (kWh)', 'Estimado (kWh)', 'Reativo (VArh)'],
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: date,
        name: 'Dias',
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        name: 'Consumo (kWh)',
        type: 'bar',
        data: consumed,
        emphasis: {
          focus: 'series',
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Média',
            },
          ],
        },
        itemStyle: {
          color: '#E4A619',
        },
      },
      {
        name: 'Reativo (VArh)',
        type: 'line',
        data: reativo,
        itemStyle: {
          color: '#a90000',
        },
        showSymbol: false,
      },
      {
        name: 'Estimado (kWh)',
        type: 'bar',
        data: forecast,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#87E35A',
        },
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 380, width: '99%' }} />;
}
