import React from 'react';
import PropType from 'prop-types';

//Import Material UI Components
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

//Import the color manipulator Material UI to create transparent effect
import { fade } from '@material-ui/core/styles/colorManipulator';

/**
 * A simple style used to create SideBar component
 */
const styles = (theme) => ({
  drawerPaper: {
    top: 48,
    width: 'min(95vw, 670px)',
    height: 'calc(100% )',
    background: '#B9C4CB',
    backgroundColor: fade('#015A9E', 0.8),
    [theme.breakpoints.down('xs')]: {
      top: 82,
    },
  },
});

/**
 *
 * A simple sidebar, it is a styled Drawer (from material ui)
 * used in GloBalPage component.
 */
const IoseSideBar = withStyles(styles)(
  ({ classes, open, ModalProps, children }) => (
    <Drawer
      open={open}
      ModalProps={ModalProps}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      BackdropProps={{ invisible: true }}
      PaperProps={{ elevation: 0 }}
      children={children}
    ></Drawer>
  )
);

export default IoseSideBar;

IoseSideBar.propTypes = {
  /** This prop get a boolean to open or close the sidebar*/
  open: PropType.bool,
  /** This prop get a react component inside the sidebar*/
  children: PropType.element,
};
