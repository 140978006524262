import styled from 'styled-components';

//imports from IOSE components
import IoseSideCard from 'components/IoseSideCard';

//imports from Material UI components
import Tabs from '@material-ui/core/Tabs';

import { Grid, Avatar, CardHeader, Collapse } from '@material-ui/core';

//Import image
import SwitchboardIconImage from './img/Switchboard.svg';

//General
export const Container = styled(IoseSideCard)`
  flex-direction: column;
  width: 100%;
  color: var(--base);
  margin-top: 10px !important;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

//Header
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
  border-radius: 5px;

  &:hover {
    border: 2px solid var(--primary);
  }
`;

export const HeaderTitle = styled(CardHeader)`
  padding: 0px;
  color: var(--base);
  width: 100%;

  span {
    white-space: pre-wrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--base);

    @media (max-width: 767px) {
      width: 150px;
    }
  }

  .MuiCardHeader-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.15s linear;
  }
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
  src: SwitchboardIconImage,
}))`
  img {
    width: 27px;
    height: 24px;
  }
  background: var(--primary);
`;

//Tabs
export const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    min-width: 100px;
  }
  .MuiTab-wrapper {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 0px;
    color: var(--base);
    font-size: 12.5px;
  }

  .MuiTabs-indicator {
    background-color: var(--primary);
  }

  .MuiTabs-flexContainer {
    justify-content: flex-start;
    align-items: center;
  }
`;

//InfoTabs
export const Text = styled.div`
  margin-left: ${(props) => props.left};
  margin-top: 2px;
  color: ${(props) =>
    props.colorprops === 'energy'
      ? 'var(--primary)'
      : props.colorprops === 'goal'
      ? 'var(--goal)'
      : 'var(--success)'};

  b {
    font-size: 15px;
  }

  p {
    color: var(--base);
    font-size: 11px;
  }
`;

export const CollapseStyled = styled(Collapse).attrs((props) => ({
  unmountOnExit: true,
}))`
  width: 100%;
  padding: 0px 12px 12px 12px;
`;

export const ContainerContent = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  padding-right: 16px;

  @media (max-width: 767px) {
    padding-right: 0px;
  }
`;

export const HeaderContent = styled(Grid).attrs(() => ({
  container: true,
}))`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
`;

export const ContainerTabs = styled(Grid).attrs(() => ({
  item: true,
  md: 6,
  sm: 6,
  xs: 12,
}))``;

export const ContainerSearch = styled(Grid).attrs(() => ({
  item: true,

  md: 6,
  sm: 6,
  xs: 12,
}))`
  display: flex;
  margin-top: 12px;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;
