import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function transformDateString(inputDate, haveHour=false) {
  const parts = inputDate.split('T')[0].split('-');
  const timeParts = inputDate.split('T')[1] ? inputDate.split('T')[1].split(':') : null;

  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  if (!haveHour){
    return `${day}/${month}/${year}`;
  } else {
    const hour = timeParts[0];
    const minute = timeParts[1];
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
}

function getLocalISODate() {
  const now = new Date();
  const timezoneOffset = now.getTimezoneOffset() * 60000; // converte o deslocamento para milissegundos
  const localISOTime = (new Date(now - timezoneOffset)).toISOString().slice(0, -1);
  return localISOTime;
}

const convertImageToBase64 = (imagePath) => {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

const convertToKWH = (valor) => {
  return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

const convetToNumber = (valor) => {
  return Number(valor.toFixed(2))
}

export default async function ConsumoSemUnidadeIoseReport(
  result,
  initial_date,
  final_date
) {

  const logoData = await convertImageToBase64(Logo);

  function generateReportTitle() {

    return 'Relatório de medição';

  }

  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45],
        },
        {
          text: generateReportTitle(''),
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const tableHead = [
    {
      text: '#',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Unidade',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Medido (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Faturado (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Estimado (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura inicial (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura final (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Corrente média (A)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Tensão média (V)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Quantidade de circuitos',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
  ]
  const tableBody = []
  tableBody.push(tableHead)
  let index = 0
  let totalConsumo = 0
  let totalCircuitos = 0
  result.forEach((unidade) =>{
    index += 1
    totalCircuitos += unidade.circuitos.length
    if (unidade?.medicao.length > 0) {
      let consumoUnidade = 0
      let tensao_media = 0
      let corrente_media = 0
      let leitura_inicial = 0
      let leitura_final = 0
      // eslint-disable-next-line array-callback-return
      unidade.medicao.map((medicao) => {
        let tensao = 0
        let corrente = 0
        const numeroMedicoes = medicao.measures.length
        if (numeroMedicoes > 0) {
          totalConsumo += convetToNumber(medicao.measures[0].import_active_energy/1000 - medicao.measures[numeroMedicoes -1].import_active_energy/1000)
          consumoUnidade += convetToNumber(medicao.measures[0].import_active_energy/1000 - medicao.measures[numeroMedicoes -1].import_active_energy/1000)
          // eslint-disable-next-line array-callback-return
          medicao.measures.map((measures)=> {
            corrente += measures.current
            tensao += measures.voltage
          })
          corrente_media += corrente/numeroMedicoes
          tensao_media += tensao/numeroMedicoes
          leitura_inicial += convetToNumber(medicao.measures[numeroMedicoes -1].import_active_energy/1000)
          leitura_final += convetToNumber(medicao.measures[0].import_active_energy/1000)
        }
      })
      tableBody.push([
        {
          text: index,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'center',
        },
        {
          text: unidade.unidade,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(consumoUnidade),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(unidade.fatura),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text:  convertToKWH(unidade.estimado),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(leitura_inicial),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(leitura_final),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(corrente_media/unidade.circuitos.length),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(tensao_media/unidade.circuitos.length),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: unidade.circuitos.length,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
      ])
    } else {
      tableBody.push([
        {
          text: index,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'center',
        },
        {
          text: unidade.unidade,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(unidade.fatura),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH(unidade.estimado),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: unidade.circuitos.length,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
      ])
    }

        // [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 0.5] }],

        //

  })

  const combinedTables = [{
    table: {
      headerRows: 1,
      widths: [15, '*', 40, 40, 40, 55, 55, 50, 50, 40, 40],
      body: [...tableBody]
    },layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex % 2 === 0 ? '#CCCCCC' : null;
      },
    },
  }]

  const currentDate = getLocalISODate()
  const summaryTable = {
    table: {
      headerRows: 1,
      widths: [250],
      body: [
        [
          {
            text: 'Resumo geral de medição',
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
            fillColor: '#CCCCCC',
          },
        ],
        [
          {
            text: `Data de emissão:  ${transformDateString(currentDate, true)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Período:  ${transformDateString(initial_date.toISOString())} a ${transformDateString(final_date.toISOString())}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Número de unidades:  ${index}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Número de circuitos:  ${totalCircuitos}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Total consumo medido kWh:  ${convertToKWH(totalConsumo)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
      ],
    },
    margin: [0, 20, 0, 10],
  };



  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0],
    };
  };

  const legendTable = {
    table: {
      headerRows: 1,
      widths: ['*', 40],
      body: [
        [
          {
            text: 'Legenda',
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
            fillColor: '#CCCCCC',
          },
        ],
        [
          {
            text: `Medido (kWh): Consumo medido pelo circuito IOSE.
                  Faturado (kWh): Consumo faturado pela concessionaria de energia.
                  Estimado (kWh): Consumo estimado com base nos objetos presentes na unidade.`,
            style: 'tableData',
            fontSize: 7,
            alignment: 'left',
          },
        ]
      ]
    },
    margin: [60, 20, 0, 10]
  };

  const legendAndSummaryLayout = {
    columns: [summaryTable, legendTable],
  } ;

  const reportContent = [
    legendAndSummaryLayout,
    ...combinedTables,
  ];

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [12, 50, 15, 40],
    content: reportContent,
    footer: renderFooter,
  };


  // Adicionar o título apenas na primeira página
  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
