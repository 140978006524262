import React, { useState, useEffect, useContext } from 'react';

import { useAlert } from 'react-alert';

import { useHistory } from 'react-router-dom';

//Hook to send data throught urls
import { useLocation } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { ioseAPI } from 'common/ioseAPI';

//Import from Messages
import Messages from '../../common/Messages';

//Imports from IOSE Components
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';
import { IoseAddButton } from 'components/IoseButtonIcon';
import {
  Content,
  ButtonArea,
  SubSideHeader,
} from 'components/IoseSideContainer';

import { RoutesAdminSide } from 'routers';

import { useCreateLogs } from 'hooks';

/**
 * This component is a container for all side containers
 *  used in super user area and it is used inside the side bar
 */
export default function SidebarAdminContainer({ closeSide }) {
  const history = useHistory();
  const alert = useAlert();
  let location = useLocation();
  const { createLog } = useCreateLogs();

  const clientname = location.state.clientname;
  const uuid_client = location.state.uuid_client;

  const page_code = 0;
  const page_size = 15;

  //State to change notification type and your data
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');

  const [getAdminsErro, setgetAdminsErro] = useState(false);

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //This function return session data
  const { getSession } = useContext(AccountContext);

  const [arrayAdmins, setArrayAdmins] = useState([]);

  //State control when need reloading list admins
  const [getAdmins, setgetAdmins] = useState(true);

  //State control when need reloading list admins when press backbutton
  const [reloading, setreloading] = useState(false);

  //State to save next page infinity scroll
  const [nextPage, setNextPage] = useState(0);
  const [endList, setEndList] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const { headers } = getSession();

  const fetchUsers = async () => {
    try {
      const response = await ioseAPI.listCognitoUsersAndGroups();
      const userResponse = response.data;

      const formattedUsers = userResponse.map((user) => ({
        name:
          user.Attributes.find((attr) => attr.Name === 'custom:user_nick')
            ?.Value || '',
        email:
          user.Attributes.find((attr) => attr.Name === 'email')?.Value || '',
        group: user.groups.join(', ') || '',
        sub: user.Attributes.find((attr) => attr.Name === 'sub')?.Value || '',
        username: user.Username,
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error('Erro ao obter usuários:', error);
    }
  };

  function errorHandling(error) {
    setLoading(false);
    setNotificationType(2);
  }

  const addAdmin = async (name, email, group) => {
    try {
      const existingUser = await ioseAPI.getUserByEmail(headers, email);

      if (existingUser.data.Users > 0) {
        setNotification('Usuário já cadastrado.');
        setNotificationType(2);
      } else {
        await ioseAPI.createAndRegisterUserToGroup(headers, name, email, group);

        setNotification('Usuário cadastrado com sucesso.');
        setNotificationType(1);

        const operacao = 'CRIAR';
        const form = 'CRIAR USUÁRIO';
        const logContent = {
          name: name,
          email: email,
          group: group,
        };

        await createLog(operacao, form, logContent);
        await fetchUsers();
      }
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      errorHandling(errorMessage);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchUsers();
    }

    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdmins]);

  useEffect(() => {
    const admins = users?.filter((usuario) => usuario.group === 'admin');
    setAdmins(admins);
  }, [users]);

  //Function to redirect to Show List admins
  const backShowAdminList = () => {
    history.push({
      pathname: '/global/super/admins',
      state: {
        uuid_client: uuid_client,
        clientname: clientname,
        page_code: page_code,
        page_size: page_size,
        adminPadding: true,
      },
    });
    reloading && setgetAdmins(true);
  };

  const redirectCreateAdmin = (e) => {
    history.push({
      pathname: '/global/super/admins/createdmin',
      state: {
        clientname: clientname,
        uuid_client: uuid_client,
        adminPadding: true,
      },
    });
  };

  //Functions to EDIT Admin
  function editAdmin(email, name, uuid_admin) {
    setNotificationType(0);
    setLoading(true);

    const adminSelectedData = arrayAdmins.find(
      (employee) => employee.uuid_admin === uuid_admin
    );

    getSession().then(({ headers }) => {
      ioseAPI
        .updateAdmin(headers, uuid_admin, name, email, adminSelectedData)
        .then((response) => {
          setLoading(false);
          backShowAdminList();
          setgetAdmins(true);
          setNotification(response.data.message);
          setNotificationType(1);
        })
        .catch((error) => {
          setLoading(false);
          setNotification(error.response.data.message);
          setNotificationType(2);
        });
    });
  }

  const redirectEditAdmin = (uuid_admin, name, email) => {
    history.push({
      pathname: '/global/super/admins/editadmin',
      state: {
        clientname: clientname,
        uuid_client: uuid_client,
        uuid_admin: uuid_admin,
        name: name,
        email: email,
        adminPadding: true,
      },
    });
  };

  //Functions to DELETE Client
  function deleteAdmin(uuid_admin) {
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .deleteAdmin(headers, uuid_admin)
          .then(() => {
            setgetAdmins(true);
            setNotification(Messages.successDeleteAdmin);
            setNotificationType(1);
          })
          .catch((error) => {
            setgetAdmins(true);
            setNotification(error.response.data.message);
            setNotificationType(1);
          });
      })
      .catch(() => {
        alert.show(`${Messages.errorDeleteAdmin}`, {
          title: 'Erro!',
          closeCopy: 'Fechar',
        });
      });
  }

  //Functions to INFINITYSCROLL
  function getMoreAdmins() {
    setNotificationType(0);
    setLoadingMore(true);

    getSession().then(({ headers }) => {
      ioseAPI
        .getAllAdmins(headers, uuid_client, nextPage, page_size)
        .then((response) => {
          setLoadingMore(false);

          let nextpage = response.data.next_page;
          let arrayItens = response.data.data;

          if (arrayItens.length > 0) {
            setArrayAdmins(arrayAdmins.concat(response.data.data));
          }

          if (typeof nextpage === 'undefined') {
            setEndList(true);
            setNextPage(nextpage);
          } else {
            setNextPage(nextpage);
          }
        })
        .catch((error) => {
          setLoadingMore(false);
          setNotification(error.response.data.message);
          setgetAdminsErro(true);
          setNotificationType(2);
        });
    });
  }

  const getMoreAdmin = () => {
    if (typeof nextPage !== 'undefined') {
      getMoreAdmins();
    }
  };

  return (
    <>
      <SubSideHeader>
        <h3>Administradores</h3>
        <ButtonArea>
          <IoseAddButton
            onClick={redirectCreateAdmin}
            tooltip="Adicionar Administrador"
          />
        </ButtonArea>
      </SubSideHeader>
      <Content>
        {notificationType === 1 && <IoseAlertSuccess text={notification} />}
        {notificationType === 2 && <IoseAlertErro text={notification} />}
        {loading ? (
          <LoadingIndicatorSidebar loading={loading} />
        ) : (
          getAdminsErro === false && (
            <RoutesAdminSide
              admins={admins}
              addAdmin={addAdmin}
              editAdmin={editAdmin}
              deletAdmin={deleteAdmin}
              redirectEditAdmin={redirectEditAdmin}
              backShowAdminList={backShowAdminList}
              notificationType={notificationType}
              notification={notification}
              getMoreAdmin={getMoreAdmin}
              loading={loading}
              loadingMore={loadingMore}
              endList={endList}
              closeSide={closeSide}
            />
          )
        )}
      </Content>
    </>
  );
}
