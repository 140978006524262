import React from 'react';
import PropType from 'prop-types';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import { IoseBackButton } from 'components/IoseButtonIcon';
import IoseButtonSideBar from 'components/IoseButton';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  Illustration,
  FormStyled,
} from './style';
import { useLocation } from 'react-router-dom';

/**
 * This component is a form to add a admn in the system
 */

export default function IoseFormAdminAddCard({ addAdmin, backShowAdminList }) {
  let location = useLocation();

  const uuid_client = location.state.uuid_client;
  //Schema validation for AdminAdd
  const AdminAddSchema = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Email', variable: 'email' },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    const group = 'admin';
    setTimeout(() => {
      setSubmitting(false);
      addAdmin(values.name, values.email, group);
    }, 500);
  };

  return (
    <>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: '',
            email: '',
          }}
          validationSchema={AdminAddSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>
                  <IoseBackButton onClick={(e) => backShowAdminList()} />
                  Criar
                </div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              {/**Illustration and text*/}
              <Illustration />

              <p>
                Enviaremos um nome de usuário e senha temporários para o novo
                Administrador Cadastrado.
              </p>

              {/**Inputs*/}
              {labels.map((value) => {
                return (
                  <Field
                    component={IoseTextFieldSidebarStyled}
                    key={value.variable}
                    id={value.variable}
                    name={value.variable}
                    type={value.variable === 'email' ? 'email' : 'text'}
                    label={value.label}
                    defaultvalue={initialValues[value.variable]}
                    onchange={handleChange}
                    onblur={handleBlur}
                    helpertext={
                      errors[value.variable] && touched[value.variable]
                        ? errors[value.variable]
                        : ''
                    }
                  />
                );
              })}
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseFormAdminAddCard.propTypes = {
  /** This props get a function to add in container*/
  addAdmin: PropType.func,
  /** This props get a function to show list of admins of SidebarAdminContainer*/
  backShowAdminList: PropType.func,
};
