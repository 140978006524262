import React, { useContext, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import { AccountContext, ioseAPI } from 'common';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function MercadoLivreDashboardFilters({
  setSelectedRefMonth,
  setLoading,
  setFilteredMercadoLivre,
  setProcessingData,
}) {
  const { getSession } = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState(new Date(2024, 6, 1));

  async function getFilteredMercadoLivre(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredFaturasMercadoLivre(
        sessionData.headers,
        object
      );

      const { data } = response;

      setFilteredMercadoLivre(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredLogValues(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredMercadoLivreLogs(
        sessionData.headers,
        object
      );

      const { data } = response.data;

      setProcessingData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function filterAllData(object) {
    try {
      const promises = [
        getFilteredMercadoLivre(object),
        getFilteredLogValues(object),
      ];

      await Promise.all(promises);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const initialValues = {
    reference_month: '07/2024',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);

    if (filteredValues.reference_month) {
      setSelectedRefMonth(filteredValues.reference_month);
    }

    await filterAllData(filteredValues);
  };

  useEffect(() => {
    const defaultFilter = { reference_month: '07/2024' };
    setSelectedRefMonth('07/2024');
    filterAllData(defaultFilter);
    //eslint-disable-next-line
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit">
              FILTRAR
            </IoseButton>

            {/* <IoseRemoveButton onClick={() => cleanAll(resetForm)}>
              LIMPAR FILTROS
            </IoseRemoveButton> */}
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
