import React, { useState, useEffect } from 'react';
import { Paper, Grow, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from 'components/IoseLoader';
import NoDataMessage from 'components/Messages/NoDataMessage';
import * as S from './style';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  searchInput: {
    margin: '20px',
  },
}));

const formatNumber = (number) => number?.toLocaleString('pt-br');

const TableHeaderCell = ({
  label,
  sortBy,
  sortDirection,
  handleSort,
  column,
}) => (
  <S.TableHeaderCell onClick={() => handleSort(column)}>
    {label} {sortBy === column && (sortDirection === 'asc' ? '🔼' : '🔽')}
  </S.TableHeaderCell>
);

const TableRowCell = ({ children, onCellClick, isUcColumn }) => (
  <S.TableCell onClick={isUcColumn ? onCellClick : undefined}>
    {children}
  </S.TableCell>
);

const UcSemCadastroTable = ({ data, loading }) => {
  const classes = useStyles();
  const history = useHistory();

  const [rowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if (data?.length >= 0) {
      const sortedData = sortData(data, sortBy, sortDirection);
      setUpdatedData(sortedData);
    }
  }, [data, sortBy, sortDirection]);

  useEffect(() => {
    if (searchQuery) {
      const filteredData = data?.filter(
        (item) =>
          item.identification_number
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.reference_month
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.general_management
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.regional_management
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.concessionaire?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setUpdatedData(sortData(filteredData, sortBy, sortDirection));
    } else {
      setUpdatedData(sortData(data, sortBy, sortDirection));
    }
  }, [searchQuery, data, sortBy, sortDirection]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const handleRowClick = (identificationNumber) => {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: { consumerGroup: identificationNumber },
    });
  };
  const Table = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageData = updatedData?.slice(startIndex, endIndex);

    return (
      <S.TableContainer>
        <S.Table>
          <S.TableHeader>
            <tr>
              {[
                { label: 'UC', column: 'identification_number' },
                { label: 'MÊS REFERÊNCIA', column: 'reference_month' },
                { label: 'SUBGRUPO', column: 'subgrupo' },
                { label: 'GG', column: 'general_management' },
                { label: 'GR', column: 'regional_management' },
                { label: 'CONCESSIONÁRIA', column: 'concessionaire' },
              ].map(({ label, column }) => (
                <TableHeaderCell
                  key={column}
                  label={label}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  column={column}
                />
              ))}
            </tr>
          </S.TableHeader>
          <tbody>
            {pageData?.map((value, index) => (
              <S.TableRow key={index}>
                <TableRowCell
                  onCellClick={() =>
                    handleRowClick(value?.identification_number)
                  }
                  isUcColumn={true}
                >
                  {value?.identification_number?.toUpperCase()}
                </TableRowCell>
                <TableRowCell>
                  {value?.reference_month?.toUpperCase()}
                </TableRowCell>
                <TableRowCell>{value?.subgrupo?.toUpperCase()}</TableRowCell>
                <TableRowCell>
                  {value?.general_management?.toUpperCase()}
                </TableRowCell>
                <TableRowCell>
                  {value?.regional_management?.toUpperCase()}
                </TableRowCell>
                <TableRowCell>
                  {value.concessionaire?.toUpperCase()}
                </TableRowCell>
              </S.TableRow>
            ))}
          </tbody>
        </S.Table>
      </S.TableContainer>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    }

    return (
      <>
        <S.InputBaseStyled
          className={classes?.searchInput}
          placeholder="Pesquise por qualquer campo"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {updatedData?.length === 0 ? (
          <NoDataMessage message="Ops... Parece que não existem dados!" />
        ) : (
          <>
            <IoseInfinityScroll dataLength={data?.length ?? 0} height={500}>
              <Table />
            </IoseInfinityScroll>
            <TablePagination
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPageOptions={[]}
              labelRowsPerPage=""
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </>
        )}
      </>
    );
  };

  return (
    <Grow in={true} timeout={500}>
      <Paper>{renderContent()}</Paper>
    </Grow>
  );
};

const sortData = (data, sortBy, sortDirection) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export default UcSemCadastroTable;
