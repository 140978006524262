export function formatEnergyBills(bills) {
  const updatedList = bills?.map((obj) => {
    const referenceMonth = obj.reference_month;
    const referenceParts = referenceMonth.split('/');
    let monthYear;

    if (referenceParts.length === 2) {
      monthYear = referenceMonth;
    } else {
      monthYear = referenceParts[1] + '/' + referenceParts[2];
    }

    return { ...obj, month_year: monthYear };
  });

  return updatedList;
}

