import React, { useEffect, useState, useCallback } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  DashFiltersAndCards,
  IoseSubHeaderBarWithFilters,
  IoseAlertLoadingCards,
  IoseButton2,
  IoseNoDataMessage,
} from 'components';
import { LoadingIndicator } from 'components/IoseLoader';

import * as S from './style';

import {
  useBillValueAudit,
  useConsumerGroup,
  useCalculateTotalConsumption,
  useCalculateAllGMConsumption,
  useCalculateTotalBillValues,
  useCalculateTotalExtract,
  useGroupPermissions,
  useUcSemCadastro,
} from 'hooks';

import { LocalStorage } from 'common';
import Permissions from 'common/permissions';
import { DivPaper } from 'styles/defaultStyles';
import IoseCardDesperdicios from 'components/Cards/IoseCardDesperdicios';
import EnergyBillDashboardFilters from './components/energy-bill-dashboard-filtros';
import EnergyBillsExtractTable from './components/energy-bill-extract-table';
import EnergyBillBlueChart from './components/energy-bill-blue-chart';
import EnergyBillGreenChart from './components/energy-bill-green-chart';
import { xlsxReports } from 'reports/Xlsx';
import EnergyBillProcessingModal from './components/enery-bill-processing-modal';
import MaioresDesperdiciosPorUcChart from 'components/Charts/MaioresDesperdiciosPorUcChart';

const calculateTotalBillByUnit = (data) => {
  const billValueByUnit = {};
  const billCountByUnit = {};

  data?.forEach((item) => {
    const { identification_number, bill_value } = item;

    if (billValueByUnit[identification_number]) {
      billValueByUnit[identification_number] += bill_value;
      billCountByUnit[identification_number] += 1;
    } else {
      billValueByUnit[identification_number] = bill_value;
      billCountByUnit[identification_number] = 1;
    }
  });

  const billValueByUnitArray = Object.entries(billValueByUnit).map(
    ([identification_number, totalBillValue]) => ({
      identification_number,
      totalBillValue,
      averageBillValue: totalBillValue / billCountByUnit[identification_number],
    })
  );

  return billValueByUnitArray;
};

function sumByConcessionaire(data) {
  const groupedData = {};

  data?.forEach((item) => {
    const concessionaire = item.concessionaire;

    if (groupedData[concessionaire]) {
      groupedData[concessionaire].quantity += item.quantity;
    } else {
      groupedData[concessionaire] = {
        ...item,
        quantity: item.quantity,
      };
    }
  });

  return Object.values(groupedData);
}

export default function EnergyBillsContainer() {
  const { billValueAudit } = useBillValueAudit();
  const { consumerGroups } = useConsumerGroup();
  const { totalConsumptions } = useCalculateTotalConsumption();
  const { totalGeneralConsumptions } = useCalculateAllGMConsumption();
  const { totalBillValues } = useCalculateTotalBillValues();
  const { extractValues } = useCalculateTotalExtract();
  const { ucSemCadastro } = useUcSemCadastro();

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingDesperdicios, setLoadingDesperdicios] = useState(true);
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [filteredConsumption, setFilteredConsumption] = useState();
  const [generalConsumption, setGeneralConsumption] = useState();
  const [
    filteredGeneralConsumption,
    setFilteredGeneralConsumption,
  ] = useState();

  const [
    totalByIdentificationNumber,
    setTotalByIdentificationNumber,
  ] = useState([]);

  const [auditValues, setAuditValues] = useState({});
  const [auditConsumptions, setAuditConsumptions] = useState({});
  const [auditExtract, setAuditExtract] = useState({});
  const [filteredBillValue, setfilteredBillValue] = useState();
  const [filteredExtract, setFilteredExtract] = useState();
  const [filteredExtractTable, setFilteredExtractTable] = useState();
  const [filteredMercadoLivre, setFilteredMercadoLivre] = useState();
  const [desperdiciosFiltrados, setDesperdiciosFiltradas] = useState({});
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [aggregatedDemanda, setAggregatedDemanda] = useState();
  const [aggregatedGreenDemanda, setAggregatedGreenDemanda] = useState();
  const [billItensDesperdicios, setBillItensDesperdicios] = useState({});
  const [ucsDesativadas, setucsDesativadas] = useState({});
  const [consumptionData, setConsumptionData] = useState();
  const [semConsumo, setSemConsumo] = useState();
  const [processingData, setProcessingData] = useState();
  const [processedData, setProcessedData] = useState();
  const [isProcessingVisible, setIsProcessingVisible] = useState(true);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const filtrarESomar = (referente, campo) => {
    if (!Array?.isArray(billItensDesperdicios)) {
      return 0;
    }

    const filtados = billItensDesperdicios?.filter(
      (item) =>
        item.referente === referente &&
        item.reference_month === selectedRefMonth
    );

    const somaTotal = filtados.reduce((acumulador, item) => {
      const valor = parseFloat(item[campo]);
      if (!isNaN(valor)) {
        return acumulador + valor;
      } else {
        console.warn(`Valor inválido encontrado e ignorado: ${item[campo]}`);
        return acumulador;
      }
    }, 0);

    setDesperdiciosFiltradas(somaTotal);
    return somaTotal;
  };

  const filtrarDesperdicios = (referente, campo) => {
    if (!Array.isArray(billItensDesperdicios)) {
      return 0;
    }

    let somaTotalDesperdicios = 0;
    billItensDesperdicios?.forEach((item) => {
      if (
        item.referente === referente &&
        item.reference_month === selectedRefMonth
      ) {
        const valor = parseFloat(item[campo]);

        if (!isNaN(valor)) {
          somaTotalDesperdicios += valor;
        } else {
          console.warn(`Valor inválido encontrado e ignorado: ${item[campo]}`);
        }
      }
    });

    return somaTotalDesperdicios;
  };

  const semCadastro = ucSemCadastro;
  const semCadastroFiltrado = semCadastro?.filter(
    (item) => item.reference_month === selectedRefMonth
  );

  const handleFilteredDataChange = useCallback((filteredData) => {
    setFilteredTableData(filteredData);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  }, []);

  useEffect(() => {
    if (consumptionData && consumptionData.length > 0) {
      setLoadingTable(false);
    } else {
      setLoadingTable(true);
    }
  }, [consumptionData]);

  useEffect(() => {
    const billLogsFormatted = sumByConcessionaire(processingData);

    setProcessedData(billLogsFormatted);
  }, [processingData]);

  useEffect(() => {
    const readPath = ['dashboards', 'faturas', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (filteredBillValue) {
      setAuditValues({
        totalBillValue: filteredBillValue?.totalBillValue,
        totalRecalcValue: filteredBillValue?.totalRecalcValue,
        totalDifValue: filteredBillValue?.totalBalance,
        totalConsumerGroup: filteredBillValue?.totalConsumerGroup,
        altaTensaoCount: filteredBillValue?.altaTensaoCount,
        baixaTensaoCount: filteredBillValue?.baixaTensaoCount,
      });
    } else {
      setAuditValues({
        totalBillValue: totalBillValues?.totalBillValue,
        totalRecalcValue: totalBillValues?.totalRecalcValue,
        totalDifValue: totalBillValues?.totalBalance,
        totalConsumerGroup: totalBillValues?.totalConsumerGroup,
        altaTensaoCount: totalBillValues?.altaTensaoCount,
        baixaTensaoCount: totalBillValues?.baixaTensaoCount,
      });
    }
  }, [totalBillValues, filteredBillValue]);

  useEffect(() => {
    if (filteredConsumption) {
      setAuditConsumptions({
        totalBillConsumption: filteredConsumption.totalBillConsumption,
        totalRecalcConsumption: filteredConsumption.totalCalcConsumption,
        totalDifConsumption: filteredConsumption.totalBalance,
      });
    } else {
      setAuditConsumptions({
        totalBillConsumption: totalConsumptions?.totalBillConsumption,
        totalRecalcConsumption: totalConsumptions?.totalCalcConsumption,
        totalDifConsumption: totalConsumptions?.totalBalance,
      });
    }
  }, [totalConsumptions, filteredConsumption]);

  useEffect(() => {
    if (filteredGeneralConsumption) {
      setGeneralConsumption({
        series: filteredGeneralConsumption?.series,
        xAxis: filteredGeneralConsumption?.xAxis,
        yAxis: filteredGeneralConsumption?.yAxis,
      });
    } else {
      setGeneralConsumption({
        series: totalGeneralConsumptions?.series,
        xAxis: totalGeneralConsumptions?.xAxis,
        yAxis: totalGeneralConsumptions?.yAxis,
      });
    }
  }, [totalGeneralConsumptions, filteredGeneralConsumption]);

  useEffect(() => {
    // if (!billItensDesperdicios.length) return;

    const totalUltrapDemandaPtaFiltrados = filtrarESomar(
      'Demanda Ultrap. PTA',
      'item_consumption_1'
    );

    const totalUltrapDemandaFpFiltrados = filtrarESomar(
      'Demanda Ultrap. FP',
      'item_consumption_1'
    );
    const totalUltrapDemandaFiltrados = filtrarESomar(
      'Demanda Ultrap.',
      'item_consumption_1'
    );
    const totalDemandaExcReativaFiltrados = filtrarESomar(
      'Demanda EXC. Reativa',
      'item_consumption_1'
    );
    const totalDemandaExcReativaPtaFiltrados = filtrarESomar(
      'Demanda EXC. Reativa PTA',
      'item_consumption_1'
    );
    const totalDemandaExcReativaFpFiltrados = filtrarESomar(
      'Demanda EXC. Reativa FP',
      'item_consumption_1'
    );
    const totalConsumoExcReativaFiltrados = filtrarESomar(
      'Consumo Exc. Reativo',
      'item_consumption_1'
    );
    const totalConsumoExcReativaPtaFiltrados = filtrarESomar(
      'Consumo Exc. Reativo PTA',
      'item_consumption_1'
    );
    const totalConsumoExcReativaFpFiltrados = filtrarESomar(
      'Consumo Exc. Reativo FP',
      'item_consumption_1'
    );

    const totalAdicionalDemanda = filtrarESomar(
      'Adicional de demanda',
      'item_consumption_1'
    );

    const totalAdicionalDemandaPTA = filtrarESomar(
      'Adicional de demanda PTA',
      'item_consumption_1'
    );
    const totalAdicionalDemandaFP = filtrarESomar(
      'Adicional de demanda FP',
      'item_consumption_1'
    );

    const totalUltrapDemandaPtaFiltradosValor = filtrarESomar(
      'Demanda Ultrap. PTA',
      'item_total_value_1'
    );
    const totalUltrapDemandaFpFiltradosValor = filtrarESomar(
      'Demanda Ultrap. FP',
      'item_total_value_1'
    );
    const totalUltrapDemandaFiltradosValor = filtrarESomar(
      'Demanda Ultrap.',
      'item_total_value_1'
    );
    const totalDemandaExcReativaFiltradosValor = filtrarESomar(
      'Demanda EXC. Reativa',
      'item_total_value_1'
    );
    const totalDemandaExcReativaPtaFiltradosValor = filtrarESomar(
      'Demanda EXC. Reativa PTA',
      'item_total_value_1'
    );
    const totalDemandaExcReativaFpFiltradosValor = filtrarESomar(
      'Demanda EXC. Reativa FP',
      'item_total_value_1'
    );

    const totalConsumoExcReativaFpFiltradosValor = filtrarESomar(
      'Consumo Exc. Reativo FP',
      'item_total_value_1'
    );
    const totalConsumoExcReativaPtaFiltradosValor = filtrarESomar(
      'Consumo Exc. Reativo PTA',
      'item_total_value_1'
    );
    const totalConsumoExcReativaFiltradosValor = filtrarESomar(
      'Consumo Exc. Reativo',
      'item_total_value_1'
    );

    const totalUltrapDemandaFp = filtrarDesperdicios(
      'Demanda Ultrap. FP',
      'item_consumption_1'
    );
    const totalUltrapDemandaPta = filtrarDesperdicios(
      'Demanda Ultrap. PTA',
      'item_consumption_1'
    );
    const totalUltrapDemanda = filtrarDesperdicios(
      'Demanda Ultrap.',
      'item_consumption_1'
    );
    const totalDemandaExcReativa = filtrarDesperdicios(
      'Demanda EXC. Reativa',
      'item_consumption_1'
    );

    const totalDemandaExcReativaPta = filtrarDesperdicios(
      'Demanda EXC. Reativa PTA',
      'item_consumption_1'
    );

    const totalDemandaExcReativaFp = filtrarDesperdicios(
      'Demanda EXC. Reativa FP',
      'item_consumption_1'
    );

    const totalConsumoExcReativaPta = filtrarDesperdicios(
      'Consumo Exc. Reativo PTA',
      'item_consumption_1'
    );

    const totalConsumoExcReativaFp = filtrarDesperdicios(
      'Consumo Exc. Reativo FP',
      'item_consumption_1'
    );

    const totalAdicionalDemandaValor = filtrarDesperdicios(
      'Adicional de demanda',
      'item_total_value_1'
    );

    const totalAdicionalDemandaPTAValor = filtrarDesperdicios(
      'Adicional de demanda PTA',
      'item_total_value_1'
    );

    const totalAdicionalDemandaFPValor = filtrarDesperdicios(
      'Adicional de demanda FP',
      'item_total_value_1'
    );

    const totalUltrapDemandaFpValor = filtrarDesperdicios(
      'Demanda Ultrap. FP',
      'item_total_value_1'
    );
    const totalUltrapDemandaPtaValor = filtrarDesperdicios(
      'Demanda Ultrap. PTA',
      'item_total_value_1'
    );
    const totalUltrapDemandaValor = filtrarDesperdicios(
      'Demanda Ultrap.',
      'item_total_value_1'
    );
    const totalDemandaExcReativaValor = filtrarDesperdicios(
      'Demanda EXC. Reativa',
      'item_total_value_1'
    );
    const totalDemandaExcReativaPtaValor = filtrarDesperdicios(
      'Demanda EXC. Reativa PTA',
      'item_total_value_1'
    );

    const totalConsumoExcReativaPtaValor = filtrarDesperdicios(
      'Consumo Exc. Reativo PTA',
      'item_total_value_1'
    );
    const totalConsumoExcReativaFpValor = filtrarDesperdicios(
      'Consumo Exc. Reativo FP',
      'item_total_value_1'
    );

    const totalDemandaExcReativaFpValor = filtrarDesperdicios(
      'Demanda EXC. Reativa FP',
      'item_total_value_1'
    );

    const desperdicios = {
      totalUltrapDemandaPta,
      totalUltrapDemandaFp,
      totalUltrapDemanda,
      totalDemandaExcReativa,
      totalDemandaExcReativaPta,
      totalDemandaExcReativaFp,
      totalConsumoExcReativaPta,
      totalConsumoExcReativaFp,
      totalAdicionalDemanda,
      totalAdicionalDemandaPTA,
      totalAdicionalDemandaFP,
      totalUltrapDemandaFpValor,
      totalUltrapDemandaPtaValor,
      totalUltrapDemandaValor,
      totalDemandaExcReativaValor,
      totalDemandaExcReativaPtaValor,
      totalConsumoExcReativaPtaValor,
      totalConsumoExcReativaFpValor,
      totalDemandaExcReativaFpValor,
      totalAdicionalDemandaValor,
      totalAdicionalDemandaPTAValor,
      totalAdicionalDemandaFPValor,
    };

    if (
      filteredExtract ||
      (desperdiciosFiltrados !== null && desperdiciosFiltrados !== undefined)
    ) {
      setAuditExtract({
        totalDemanPonta: filteredExtract?.totalDemanPonta,
        semCadastro: semCadastroFiltrado,
        semConsumo: semConsumo,
        ucsDesativadas: ucsDesativadas,
        totalDemanFora: filteredExtract?.totalDemanFora,
        totalUltrapDemandaFp:
          desperdiciosFiltrados?.totalUltrapDemandaFpFiltrados ??
          totalUltrapDemandaFpFiltrados,
        totalUltrapDemandaPta:
          desperdiciosFiltrados?.totalUltrapDemandaPtaFiltrados ??
          totalUltrapDemandaPtaFiltrados,
        totalUltrapDemanda:
          desperdiciosFiltrados?.totalUltrapDemandaFiltrados ??
          totalUltrapDemandaFiltrados,
        totalDemandaExcReativa:
          desperdiciosFiltrados?.totalDemandaExcReativaFiltrados ??
          totalDemandaExcReativaFiltrados,
        totalDemandaExcReativaPta:
          desperdiciosFiltrados?.totalDemandaExcReativaPtaFiltrados ??
          totalDemandaExcReativaPtaFiltrados,
        totalDemandaExcReativaFp:
          desperdiciosFiltrados?.totalDemandaExcReativaFpFiltrados ??
          totalDemandaExcReativaFpFiltrados,

        totalConsumoExcReativaFp:
          desperdiciosFiltrados?.totalConsumoExcReativaFpFiltrados ??
          totalConsumoExcReativaFpFiltrados,
        totalConsumoExcReativaPta:
          desperdiciosFiltrados?.totalConsumoExcReativaPtaFiltrados ??
          totalConsumoExcReativaPtaFiltrados,
        totalConsumoExcReativa:
          desperdiciosFiltrados?.totalConsumoExcReativaFiltrados ??
          totalConsumoExcReativaFiltrados,

        totalDemandaExcReativaPtaFiltrados:
          desperdiciosFiltrados?.totalDemandaExcReativaPtaFiltrados ??
          totalDemandaExcReativaPtaFiltrados,
        totalDemandaExcReativaFpFiltrados:
          desperdiciosFiltrados?.totalDemandaExcReativaFpFiltrados ??
          totalDemandaExcReativaFpFiltrados,

        totalAdicionalDemanda:
          desperdiciosFiltrados?.totalAdicionalDemanda ?? totalAdicionalDemanda,
        totalAdicionalDemandaPTA:
          desperdiciosFiltrados?.totalAdicionalDemandaPTA ??
          totalAdicionalDemandaPTA,
        totalAdicionalDemandaFP:
          desperdiciosFiltrados?.totalAdicionalDemandaFP ??
          totalAdicionalDemandaFP,

        totalUltrapDemandaFpValor:
          desperdiciosFiltrados?.totalUltrapDemandaFpFiltradosValor ??
          totalUltrapDemandaFpFiltradosValor,
        totalUltrapDemandaPtaValor:
          desperdiciosFiltrados?.totalUltrapDemandaPtaValor ??
          totalUltrapDemandaPtaFiltradosValor,
        totalUltrapDemandaValor:
          desperdiciosFiltrados?.totalUltrapDemandaFiltradosValor ??
          totalUltrapDemandaFiltradosValor,
        totalDemandaExcReativaValor:
          desperdiciosFiltrados?.totalDemandaExcReativaFiltradosValor ??
          totalDemandaExcReativaFiltradosValor,
        totalDemandaExcReativaPtaValor:
          desperdiciosFiltrados?.totalDemandaExcReativaPtaFiltradosValor ??
          totalDemandaExcReativaPtaFiltradosValor,
        totalDemandaExcReativaFpValor:
          desperdiciosFiltrados?.totalDemandaExcReativaFpFiltradosValor ??
          totalDemandaExcReativaFpFiltradosValor,
        totalConsumoExcReativaFpValor:
          desperdiciosFiltrados?.totalConsumoExcReativaFpFiltradosValor ??
          totalConsumoExcReativaFpFiltradosValor,
        totalConsumoExcReativaPtaValor:
          desperdiciosFiltrados?.totalConsumoExcReativaPtaFiltradosValor ??
          totalConsumoExcReativaPtaFiltradosValor,
        totalConsumoExcReativaValor:
          desperdiciosFiltrados?.totalConsumoExcReativaFiltradosValor ??
          totalConsumoExcReativaFiltradosValor,

        totalAdicionalDemandaValor:
          desperdiciosFiltrados?.totalAdicionalDemandaValor ??
          totalAdicionalDemandaValor,

        totalAdicionalDemandaPTAValor:
          desperdiciosFiltrados?.totalAdicionalDemandaPTAValor ??
          totalAdicionalDemandaPTAValor,

        totalAdicionalDemandaFPValor:
          desperdiciosFiltrados?.totalAdicionalDemandaFPValor ??
          totalAdicionalDemandaFPValor,

        totalDemanContPonta: filteredExtract?.totalDemanContPonta,
        totalDemanContFora: filteredExtract?.totalDemanContFora,
        totalConsumPonta: filteredExtract?.totalConsumPonta,
        totalConsumFora: filteredExtract?.totalConsumFora,
      });
    } else {
      setAuditExtract({
        semCadastro: semCadastroFiltrado,
        semConsumo: semConsumo,
        ucsDesativadas: ucsDesativadas,

        totalDemanPonta: extractValues?.totalDemanPonta,
        totalDemanFora: extractValues?.totalDemanFora,
        totalUltrapDemandaFp: desperdicios?.totalUltrapDemandaFp,
        totalUltrapDemandaPta: desperdicios?.totalUltrapDemandaPta,
        totalUltrapDemanda: desperdicios?.totalUltrapDemanda,
        totalDemandaExcReativa: desperdicios?.totalDemandaExcReativa,
        totalDemandaExcReativaFp: desperdicios?.totalDemandaExcReativaFp,
        totalConsumoExcReativaPta: desperdicios?.totalConsumoExcReativaPta,
        totalConsumoExcReativa: desperdicios?.totalConsumoExcReativa,
        totalConsumoExcReativaFp: desperdicios?.totalConsumoExcReativaFp,
        totalDemandaExcReativaPta: desperdicios?.totalDemandaExcReativaPta,

        totalDemandaNaoConsumida: desperdicios?.totalDemandaNaoConsumida,
        totalAdicionalDemanda: desperdicios?.totalAdicionalDemanda,
        totalAdicionalDemandaPTA: desperdicios?.totalAdicionalDemandaPTA,
        totalAdicionalDemandaFP: desperdicios?.totalAdicionalDemandaFP,

        totalUltrapDemandaFpValor: desperdicios?.totalUltrapDemandaFpValor,
        totalUltrapDemandaPtaValor: desperdicios?.totalUltrapDemandaPtaValor,
        totalUltrapDemandaValor: desperdicios?.totalUltrapDemandaValor,
        totalDemandaExcReativaValor: desperdicios?.totalDemandaExcReativaValor,
        totalDemandaExcReativaPtaValor:
          desperdicios?.totalDemandaExcReativaPtaValor,
        totalDemandaExcReativaFpValor:
          desperdicios?.totalDemandaExcReativaFpValor,

        totalConsumoExcReativaFpValor:
          desperdicios?.totalConsumoExcReativaFpValor,
        totalConsumoExcReativaPtaValor:
          desperdicios?.totalConsumoExcReativaPtaValor,
        totalConsumoExcReativaValor: desperdicios?.totalConsumoExcReativaValor,

        totalDemandaNaoConsumidaValor:
          desperdicios?.totalDemandaNaoConsumidaValor,

        totalAdicionalDemandaValor: desperdicios?.totalAdicionalDemandaValor,
        totalAdicionalDemandaFPValor:
          desperdicios?.totalAdicionalDemandaFPValor,
        totalAdicionalDemandaPTAValor:
          desperdicios?.totalAdicionalDemandaPTAValor,

        totalDemanContPonta: extractValues?.totalDemanContPonta,
        totalDemanContFora: extractValues?.totalDemanContFora,
        totalConsumPonta: extractValues?.totalConsumPonta,
        totalConsumFora: extractValues?.totalConsumFora,
      });
    }
    //eslint-disable-next-line
  }, [extractValues, filteredExtract, billItensDesperdicios]);

  useEffect(() => {
    const totalBillByUnit = calculateTotalBillByUnit(billValueAudit);
    setTotalByIdentificationNumber(totalBillByUnit);
  }, [billValueAudit]);

  const averages = {
    valueAverage: Number.isNaN(
      auditValues?.totalBillValue / consumerGroups?.length
    )
      ? 0
      : auditValues.totalBillValue / consumerGroups.length,
    consumptionAverage: Number.isNaN(
      auditConsumptions?.totalBillConsumption / consumerGroups?.length
    )
      ? 0
      : auditConsumptions.totalBillConsumption / consumerGroups.length,
  };

  const cleanFilters = () => {
    // Filtra e soma valores específicos
    const totalAdicionalDemanda = filtrarESomar(
      'Adicional de demanda',
      'item_consumption_1'
    );
    const totalAdicionalDemandaPTA = filtrarESomar(
      'Adicional de demanda PTA',
      'item_consumption_1'
    );
    const totalAdicionalDemandaFP = filtrarESomar(
      'Adicional de demanda FP',
      'item_consumption_1'
    );

    // Filtra valores específicos
    const totalAdicionalDemandaValor = filtrarDesperdicios(
      'Adicional de demanda',
      'item_total_value_1'
    );
    const totalAdicionalDemandaPTAValor = filtrarDesperdicios(
      'Adicional de demanda PTA',
      'item_total_value_1'
    );
    const totalAdicionalDemandaFPValor = filtrarDesperdicios(
      'Adicional de demanda FP',
      'item_total_value_1'
    );
    const totalDemandaNaoConsumida2Valor = filtrarDesperdicios(
      'Adicional de demanda',
      'item_total_value_1'
    );

    // Atualiza os estados com os valores filtrados
    setAuditValues({
      totalBillValue: totalBillValues?.totalBillValue,
      totalRecalcValue: totalBillValues?.totalRecalcValue,
      totalDifValue: totalBillValues?.totalBalance,
      totalConsumerGroup: totalBillValues?.totalConsumerGroup,
      altaTensaoCount: totalBillValues?.altaTensaoCount,
      baixaTensaoCount: totalBillValues?.baixaTensaoCount,
    });

    setAuditConsumptions({
      totalBillConsumption: totalConsumptions?.totalBillConsumption,
      totalRecalcConsumption: totalConsumptions?.totalCalcConsumption,
      totalDifConsumption: totalConsumptions?.totalBalance,
    });

    setGeneralConsumption({
      series: totalGeneralConsumptions?.series,
      xAxis: totalGeneralConsumptions?.xAxis,
      yAxis: totalGeneralConsumptions?.yAxis,
    });

    setAuditExtract({
      totalDemanPonta: extractValues?.totalDemanPonta,
      totalDemanFora: extractValues?.totalDemanFora,
      totalDemanContPonta: extractValues?.totalDemanContPonta,
      totalDemanContFora: extractValues?.totalDemanContFora,
      totalConsumPonta: extractValues?.totalConsumPonta,
      totalConsumFora: extractValues?.totalConsumFora,
      semCadastro: semCadastroFiltrado,
      semConsumo: semConsumo,
      ucsDesativadas: ucsDesativadas,

      totalUltrapDemandaFp: filtrarDesperdicios(
        'Demanda Ultrap. FP',
        'item_consumption_1'
      ),
      totalUltrapDemandaFpValor: filtrarDesperdicios(
        'Demanda Ultrap. FP',
        'item_total_value_1'
      ),

      totalUltrapDemandaPta: filtrarDesperdicios(
        'Demanda Ultrap. PTA',
        'item_consumption_1'
      ),
      totalUltrapDemandaPtaValor: filtrarDesperdicios(
        'Demanda Ultrap. PTA',
        'item_total_value_1'
      ),

      totalUltrapDemanda: filtrarDesperdicios(
        'Demanda Ultrap.',
        'item_consumption_1'
      ),
      totalUltrapDemandaValor: filtrarDesperdicios(
        'Demanda Ultrap.',
        'item_total_value_1'
      ),

      totalDemandaExcReativa: filtrarDesperdicios(
        'Demanda EXC. Reativa',
        'item_consumption_1'
      ),
      totalDemandaExcReativaValor: filtrarDesperdicios(
        'Demanda EXC. Reativa',
        'item_total_value_1'
      ),

      totalDemandaExcReativaPta: filtrarDesperdicios(
        'Demanda EXC. Reativa PTA',
        'item_consumption_1'
      ),
      totalDemandaExcReativaPtaValor: filtrarDesperdicios(
        'Demanda EXC. Reativa PTA',
        'item_total_value_1'
      ),

      totalDemandaExcReativaFp: filtrarDesperdicios(
        'Demanda EXC. Reativa FP',
        'item_consumption_1'
      ),
      totalDemandaExcReativaFpValor: filtrarDesperdicios(
        'Demanda EXC. Reativa FP',
        'item_total_value_1'
      ),

      totalConsumoExcReativaPta: filtrarDesperdicios(
        'Consumo Exc. Reativo PTA',
        'item_consumption_1'
      ),
      totalConsumoExcReativaFp: filtrarDesperdicios(
        'Consumo Exc. Reativo FP',
        'item_consumption_1'
      ),
      totalConsumoExcReativa: filtrarDesperdicios(
        'Consumo Exc. Reativo',
        'item_consumption_1'
      ),

      totalConsumoExcReativaPtaValor: filtrarDesperdicios(
        'Consumo Exc. Reativo PTA',
        'item_total_value_1'
      ),
      totalConsumoExcReativaValor: filtrarDesperdicios(
        'Consumo Exc. Reativo',
        'item_total_value_1'
      ),
      totalConsumoExcReativaFpValor: filtrarDesperdicios(
        'Consumo Exc. Reativo FP',
        'item_total_value_1'
      ),

      totalDemandaNaoConsumida2Valor: totalDemandaNaoConsumida2Valor,
      totalAdicionalDemanda: totalAdicionalDemanda,
      totalAdicionalDemandaPTA: totalAdicionalDemandaPTA,
      totalAdicionalDemandaFP: totalAdicionalDemandaFP,
      totalAdicionalDemandaValor: totalAdicionalDemandaValor,
      totalAdicionalDemandaPTAValor: totalAdicionalDemandaPTAValor,
      totalAdicionalDemandaFPValor: totalAdicionalDemandaFPValor,
    });
  };

  const renderCards = () => {
    return (
      <DashFiltersAndCards
        loading={loading}
        auditValues={auditValues}
        auditConsumptions={auditConsumptions}
        auditExtract={auditExtract}
        averages={averages}
        filteredMercadoLivre={filteredMercadoLivre}
      />
    );
  };

  const renderDesperdicios = () => {
    return (
      <DivPaper style={{ marginTop: '10px' }}>
        <IoseCardDesperdicios
          loading={loading}
          auditExtract={auditExtract}
          selectedRefMonth={selectedRefMonth}
          loadingDesperdicios={loadingDesperdicios}
          billItensDesperdicios={billItensDesperdicios}
        />
      </DivPaper>
    );
  };

  const renderTableOrMessage = () => {
    if (loadingTable) {
      return <LoadingIndicator loading={loadingTable} />;
    }

    if (consumptionData?.length > 0) {
      return (
        <EnergyBillsExtractTable
          data={consumptionData}
          loading={loadingTable}
          onFilteredDataChange={handleFilteredDataChange}
        />
      );
    }

    return (
      <IoseNoDataMessage
        message="Ops... sem dados suficientes para exibir a tabela!"
        height="200px"
        width="200px"
      />
    );
  };
  return (
    <S.GridContainer>
      <S.HeaderDiv>
        <S.LeftHeaderDiv>
          <S.Title>Dashboard Faturas de energia</S.Title>

          <EnergyBillDashboardFilters
            setfilteredBillValue={setfilteredBillValue}
            setFilteredConsumption={setFilteredConsumption}
            setFilteredGeneralConsumption={setFilteredGeneralConsumption}
            setFilteredExtract={setFilteredExtract}
            setSelectedRefMonth={setSelectedRefMonth}
            setFilteredExtractTable={setFilteredExtractTable}
            setFilteredMercadoLivre={setFilteredMercadoLivre}
            setConsumptionData={setConsumptionData}
            setAggregatedDemanda={setAggregatedDemanda}
            setSemConsumo={setSemConsumo}
            setAggregatedGreenDemanda={setAggregatedGreenDemanda}
            setBillItensDesperdicios={setBillItensDesperdicios}
            setucsDesativadas={setucsDesativadas}
            setProcessingData={setProcessingData}
            cleanFilters={cleanFilters}
            setLoading={setLoading}
            setLoadingDesperdicios={setLoadingDesperdicios}
            setLoadingTable={setLoadingTable}
          />
        </S.LeftHeaderDiv>

        <S.RightHeaderDiv>
          {isProcessingVisible ? (
            <EnergyBillProcessingModal
              data={processedData}
              onClose={() => setIsProcessingVisible(false)}
            />
          ) : null}
        </S.RightHeaderDiv>
      </S.HeaderDiv>
      {hasReadPermission ? (
        <>
          <IoseSubHeaderBarWithFilters title={'Totais Gerais: '} />
          {renderCards()}
          <IoseSubHeaderBarWithFilters title={'Desperdícios: '} />
          {renderDesperdicios()}

          <S.GridDataContainer>
            <S.ChartGGContainer>
              <S.HeaderContainer>
                <div style={{ display: 'flex' }}>
                  <S.ContractsIcon />
                  <S.TypographyTitle>
                    10 UCS COM OS MAIORES DESPERDÍCIOS
                  </S.TypographyTitle>
                </div>
              </S.HeaderContainer>
              <MaioresDesperdiciosPorUcChart data={billItensDesperdicios} />
            </S.ChartGGContainer>
            <S.TableExtractContainer>
              <S.HeaderContainer>
                <div style={{ display: 'flex' }}>
                  <S.ContractsIcon />
                  <S.TypographyTitle>
                    CONSUMO PONTA E FORA PONTA
                  </S.TypographyTitle>
                </div>
                <div>
                  <IoseButton2
                    onClick={() =>
                      xlsxReports.exportAggregatedConsumptionTableToExcel(
                        filteredTableData
                      )
                    }
                    width="80px"
                  >
                    <span style={{ marginRight: '5px' }}>XLSX</span>
                    <GetAppIcon />
                  </IoseButton2>
                </div>
              </S.HeaderContainer>
              {renderTableOrMessage()}
            </S.TableExtractContainer>
          </S.GridDataContainer>

          <S.GridDataContainer>
            <S.TableBlueChartContainer>
              <S.HeaderContainer>
                <div style={{ display: 'flex' }}>
                  <S.ContractsIcon />
                  <S.TypographyTitle>
                    DEMANDA (kW) VS DEMANDA CONTRATADA (kW) - UC'S MODALIDE AZUL
                  </S.TypographyTitle>
                </div>
              </S.HeaderContainer>
              <EnergyBillBlueChart data={aggregatedDemanda} loading={loading} />
            </S.TableBlueChartContainer>
          </S.GridDataContainer>

          <S.GridDataContainer>
            <S.TableGreenChartContainer>
              <S.HeaderContainer>
                <div style={{ display: 'flex' }}>
                  <S.ContractsIcon />
                  <S.TypographyTitle>
                    DEMANDA (kW) VS DEMANDA CONTRATADA (kW) - UC'S MODALIDE
                    VERDE
                  </S.TypographyTitle>
                </div>
              </S.HeaderContainer>
              <EnergyBillGreenChart
                data={aggregatedGreenDemanda}
                loading={loading}
              />
            </S.TableGreenChartContainer>
          </S.GridDataContainer>
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.GridContainer>
  );
}
