import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';
import { useClient, LocalStorage, AccountContext, ioseAPI } from 'common';
import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';
import {

  useGroupPermissions,
} from 'hooks';

import IoseRecaclTable from 'components/IoseRecaclTable';
import IoseSearchMessage from 'components/IoseSearchMessage';
import IoseBillSearchModalDifferece from 'components/Modals/IoseBillSearchModalDifferece';
import Permissions from 'common/permissions';
import RecalcBillContainerFilters from './components/recalc-bill-container-filtros';

export default function RecalcEnergyBillsContainer() {
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);

  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();

  // Permissions States
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const [energyBills, setEnergyBills] = useState()

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['faturas', 'recalculo_faturas', 'leitura'];
    const reportPath = ['faturas', 'recalculo_faturas', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client?.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const openAuditModal = () => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  };

  const renderTable = () => {
    const getEnergyBill = async (rowData) => {
      setLoading(true);
      const { identification_number, link_pdf, pdf_name } = rowData;

      try {
        const result = await getBillByUUID(identification_number, pdf_name);

        if (result && result?.length > 0) {
          // Agrupar os dados por identification_number
          const groupedResult = result.reduce((groups, item) => {
            const key = item.identification_number;

            if (!groups[key]) {
              groups[key] = [];
            }

            groups[key].push(item);
            return groups;
          }, {});

          // Ordenar os grupos por identification_number
          const sortedGroups = Object?.values(groupedResult).sort((a, b) => {
            const identificationNumberA = a[0].identification_number;
            const identificationNumberB = b[0].identification_number;

            return identificationNumberA - identificationNumberB;
          });

          // Exibir os resultados ordenados
          const flattenedResult = sortedGroups?.reduce((flattened, group) => {
            return flattened.concat(group);
          }, []);

          const url = `${link_pdf}${flattenedResult[0].pdf_name}`;
          flattenedResult[0].link_completo = url;

          setSelectedRowData(flattenedResult);
          setLoading(false);
          openAuditModal();
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }

      function processBillsData(energyBills) {
        const result = [];
        energyBills?.forEach((data) => {
          const keys = Object.keys(data).filter((key) =>
            /^item_\w+_\d+$/.test(key)
          );

          keys?.sort((a, b) => {
            const numA = parseInt(a.substring(a.lastIndexOf('_') + 1));
            const numB = parseInt(b.substring(b.lastIndexOf('_') + 1));

            return numA - numB;
          });

          const processedData = {};

          for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            const index = key.substring(key.lastIndexOf('_') + 1);
            const propertyName = key.substring(
              key.indexOf('_') + 1,
              key.lastIndexOf('_')
            );
            const value = data[key];

            if (!processedData[index]) {
              processedData[index] = {};
            }

            processedData[index][propertyName] = value;
          }

          for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            const index = key.substring(key.lastIndexOf('_') + 1);
            const propertyName = key.substring(
              key.indexOf('_') + 1,
              key.lastIndexOf('_')
            );
            const value = data[key];

            if (!processedData[index]) {
              processedData[index] = {};
            }

            processedData[index][propertyName] = value;
            // Mantenha o campo original
            processedData[index][`${propertyName}_original`] = key;
          }

          const lines = 12;
          const properties = [
            'measurer',
            'magnitude',
            'timetables',
            'measurer_previous_reading',
            'measurer_current_reading',
            'measurer_constant',
            'kwh_consumption',
          ];

          const measurer_data = {};

          properties.forEach((property) => {
            for (let i = 1; i <= lines; i++) {
              measurer_data[`${property}_${i}`] = data?.[`${property}_${i}`];
            }
          });

          const taxes_data = {
            icms_calculation_basis_total: data?.icms_calculation_basis_total,
            cofins_calculation_basis: data?.cofins_calculation_basis,
            pis_calculation_basis: data?.pis_calculation_basis,
            icms_aliquot: data?.icms_aliquot,
            cofins_aliquot: data?.cofins_aliquot,
            pis_aliquot: data?.pis_aliquot,
            icms_total: data?.icms_total,
            cofins_total: data?.cofins_total,
            pis_total: data?.pis_total,
          };

          const itensLines = 17;
          const itensProperties = [
            'item_description',
            'item_unity',
            'item_consumption',
            'item_unitary_value',
            'item_total_value',
            'item_piscofins',
            'item_icms',
            'item_tax',
          ];

          const itens_data = {};

          itensProperties.forEach((property) => {
            for (let i = 1; i <= itensLines; i++) {
              itens_data[`${property}_${i}`] = data?.[`${property}_${i}`];
            }
          });

          const completeBillData = {
            uuid_energy_bill: data?.uuid_energy_bill,
            bill_name: data?.bill_name,
            classification: data?.classification,
            identification_number: data?.identification_number,
            reference_month: data?.reference_month,
            number_days: data?.number_days,
            current_reading: data?.current_reading,
            previous_reading: data?.previous_reading,
            measurer: data?.measurer,
            account_value: data?.account_value,
            cnpj: data?.cnpj,
            pdf_name: data?.pdf_name,

            measurer_data: measurer_data,

            taxes_data: taxes_data,

            itens_data: itens_data,

            table_data: Object.values(processedData),
          };

          result.push(completeBillData);
        });

        return result;
      }

      async function getBillByUUID(identification_number, pdf_name) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getAllIdentificatioNumberBills(
            sessionData.headers,
            identification_number
          );

          const { data } = response.data;

          const filteredBill = data?.filter(
            (item) => item.pdf_name === pdf_name
          );

          const formatedBill = processBillsData(filteredBill);

          return formatedBill;
        } catch (error) {
          console.log(error);
          throw error;
        }
      }

      let result;

      try {
        result = await getBillByUUID(identification_number, pdf_name);

        if (result && result?.length > 0) {
          const url = `${link_pdf}${result[0].pdf_name}`;
          result[0].link_completo = url;
        } else {
          console.log(
            'Fatura não encontrada para o identification_number e pdf_name fornecidos.'
          );
        }
      } catch (error) {
        console.log(error);
      }

      setSelectedRowData(result);
      setLoading(false);
      openAuditModal();
    };

    if (energyBills) {
      return (
        <S.ContainerTable>
          <IoseRecaclTable
            tableTitle=""
            searchInTable={false}
            data={energyBills}
            loading={loading}
            onClickFunction={getEnergyBill}
            hasPermission={hasReportPermission}
          />
        </S.ContainerTable>
      );
    } else {
      return (
        <IoseSearchMessage
          message={`Selecione os filtros de busca desejados e clique em "BUSCAR FATURAS".`}
          height="400px"
          width="400px"
        />
      );
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Fatura x Recálculo"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>
            {renderTable()}
            {renderBillModal()}
          </div>
        </>
      );
    }
  };

  const renderBillModal = () => {
    return (
      <IoseBillSearchModalDifferece
        open={openModal}
        onClose={openAuditModal}
        data={selectedRowData}
        refreshBills={() => {}}
        refreshFaturas={() => {}}
      />
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        <RecalcBillContainerFilters
          energyBills={setEnergyBills}
          loading={setLoading}
          />
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

RecalcEnergyBillsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
