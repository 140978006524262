import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  makeStyles,
  TablePagination,
} from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

// function formatDate(dataString) {
//   const data = new Date(dataString);

//   const dia = String(data.getDate()).padStart(2, '0');
//   const mes = String(data.getMonth() + 1).padStart(2, '0');
//   const ano = data.getFullYear();

//   return `${dia}/${mes}/${ano}`;
// }

const handleAlarmStatus = (type) => {
  switch (type) {
    case 1:
      return 'Queda de energia';
    case 2:
      return 'Retorno de energia';
    case 4:
      return 'Sem medição';
    case 5:
      return 'Horário fora de ponta';
    case 6:
      return 'Horário de ponta';
    case 28:
      return 'Abertura da porta';
    case 29:
      return 'Fechamento da porta';
    case 7:
      return 'Horário Capacitivo';
    case 56:
      return 'Reset pelo Usuário';
    case 3:
      return 'Fim do intervalo de 15 min.';
    case 102:
      return 'Fim do intervalo timeout';
    case 200:
      return 'Ultrapassagem de demanda';
    case 201:
      return 'Excesso de Reativos';
    default:
      return 'Evento não suportado';
  }
};

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

export default function TelemetryAlarmsTable({ loading, data }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = data?.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          <LogsTable data={paginatedData} id="scrollableDiv" />
        </Paper>
      );
    } else {
      return (
        <div style={{ minWidth: '400px' }}>
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="150px"
            width="150px"
          />
        </div>
      );
    }
  }

  // const formatDate = (dateString) => {
  //   return new Date(dateString).toLocaleDateString('pt-BR');
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const LogsTable = ({ data, onRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>CIRCUITO</TableHeaderCellCentered>
          <TableHeaderCellCentered>ALARME</TableHeaderCellCentered>
          <TableHeaderCellCentered>DATA DE CRIAÇÃO</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <LogsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            index={index}
          />
        ))}
      </TableBody>
    </StyledTable>
  );

  const LogsTableRow = ({ value, onClick, index }) => {
    const classes = useStyles();

    return (
      <TableRowStyled align="center" className={classes.tableRow}>
        <TableCellCentered>{value?.uuid_circuit}</TableCellCentered>
        <TableCellCentered>
          {handleAlarmStatus(value?.cod_evento).toLocaleUpperCase()}
        </TableCellCentered>
        <TableCellCentered>{formatDate(value?.dat_evento)}</TableCellCentered>
      </TableRowStyled>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 10}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={350}
          >
            {renderChartOrErrorMessage()}
          </IoseInfinityScroll>

          <TablePagination
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Itens por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            backIconButtonText="Página anterior"
            nextIconButtonText="Próxima página"
          />
        </>
      )}
    </div>
  );
}
