import React, { useContext, useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { useConsumerGroup, useUniqueConcessionaires } from 'hooks';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { AccountContext, ioseAPI } from 'common';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function RecalcBillContainerFilters({ loading, energyBills }) {
  const { consumerGroupsForSelect } = useConsumerGroup();
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { getSession } = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEnergyBillsItems = useCallback(async (object) => {
    try {
      setIsLoading(true);
      loading(true);
      const sessionData = await getSession();

      let allData = [];
      let lastEvaluatedKey = null;
      const limit = 10000;

      do {
        const response = await ioseAPI.getFilteredSearchEnergyBill(
          sessionData.headers,
          object,
          lastEvaluatedKey,
          limit
        );


        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } while (lastEvaluatedKey);

        energyBills(allData)
        setIsLoading(false);
        loading(false);

    } catch (error) {
      console.error(error);
      setIsLoading(false);
      loading(false);
    }
  }, [energyBills, getSession, loading]);

  const initialValues = {
    identification_number: '',
    concessionaire: '',
    reference_month: '',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);
    await getEnergyBillsItems(filteredValues);
    setSelectedDate(null)
  };

  const cleanFilters = (resetForm) => {
    resetForm()
    setSelectedDate(null)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDynamicSelect
                label="UC"
                value={values.identification_number}
                onChange={(value) => setFieldValue('identification_number', value)}
                options={consumerGroupsForSelect}
                width="150px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Concessionárias"
                value={values.concessionaire}
                onChange={(value) => setFieldValue('concessionaire', value)}
                options={uniqueConcessionaires}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isLoading}>
              BUSCAR FATURAS
            </IoseButton>

            <IoseRemoveButton onClick={() => cleanFilters(resetForm)}>
            LIMPAR FILTROS
          </IoseRemoveButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
