import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import { StatesAndCities } from '../../constants/index';
import consumerGroupsReport from 'reports/ConsumerGroups/consumerGroupReport';
import Permissions from 'common/permissions';
import ConsumerGroupMainTable from './components/consumer-group-table';
import ConsumerGroupFilters from './components/consumer-group-filtros';
import { xlsxReports } from '../../reports/Xlsx/index'
import GetAppIcon from '@material-ui/icons/GetApp';
import * as S from './style';
import {
  useContracts,
  useConsumerGroup,
  useConcessionaires,
  useGroupPermissions,
  useAddConsumerGroup,
  useCreateLogs,

} from 'hooks';
import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
  useClient,
} from 'common';
import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseAddConsumerGroupModal,
  LoadingIndicator,
  IoseButton2,
} from 'components';

export default function ConsumerGroupContainer() {
  const [cards, setCards] = useState([]);
  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  //eslint-disable-next-line
  const [selectedCities, setSelectedCities] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [filteredConsumerGroups, setFilteredConsumerGroups] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  //eslint-disable-next-line
  const [hasEditPermission, setHasEditPermission] = useState();
  //eslint-disable-next-line
  const [hasDeletePermission, setHasDeletePermission] = useState();
  const [lastFilterObject, setlastFilterObject] = useState();

  const { client } = useClient();
  const { contracts } = useContracts();
  const { concessionaires } = useConcessionaires();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const {
    consumerGroups,
    noConsumerGroupsError,
    refreshConsumerGroups,
    isLoading,
  } = useConsumerGroup();

  const {
    addConsumerGroup,
    addNotification,
    addErrorMessage,
  } = useAddConsumerGroup();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'unidades_consumidoras', 'leitura'];
    const addPath = ['cadastro', 'unidades_consumidoras', 'criar'];
    const editPath = ['cadastro', 'unidades_consumidoras', 'editar'];
    const deletePath = ['cadastro', 'unidades_consumidoras', 'deletar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consumerGroups.length !== 0) {
      if (filteredConsumerGroups?.length > 0) {
        setCards(filteredConsumerGroups);
      } else if (filteredConsumerGroups?.length === 0) {
        setCards([]);
      } else {
        setCards(consumerGroups);
      }

      if (filteredConsumerGroups?.length === 0 || consumerGroups?.length === 0) {
        setError(noConsumerGroupsError);
      }
    }
  }, [filteredConsumerGroups, consumerGroups, noConsumerGroupsError]);


  useEffect(() => {
    getCitiesBySelectedState(selectedState);
  }, [selectedState]);

  function getAllConsumerGroups() {
    if (consumerGroups) {
      setCards(consumerGroups)
    }
  }

  async function handleDownloadButtonClick() {
    xlsxReports.exportConsumerGroupsToExcel(cards && cards);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE UCs - XLSX';
    const logContent = {};

    await createLog(operacao, form, logContent);
  }

  async function handleDownloadPdfButtonClick() {
    consumerGroupsReport(cards && cards)

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE UCs - PDF';
    const logContent = {};

    await createLog(operacao, form, logContent);
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddConsumerGroup = async (
    concessionaire,
    identification_number,
    contract,
    operating_unity,
    general_management,
    regional_management,
    coordinate_x,
    coordinate_y,
    address,
    city,
    carbon_credit,
    telemetry,
    meter,
    consumer_group_load,
    atividade,
    cod_local,
    cod_unidade,
    descricao_unidade,
    cod_orgao_pagador,
    cogeracao,
    csbio,
    geradora,
    beneficiaria,
    potencia_transformador,
    subgrupo,
    modalidade,
    ativo,
    mercado_livre,
    inicio_vigencia,
    fim_vigencia,
    inicio_teste,
    fim_teste,
    constante,
    relogio_reset,
    demanda_fora,
    demanda_ponta,
    tensao,
    demanda,
    rtc,
    rtp,
    ke,
    pulso_watt,
    numero_telemetria,
    fornecedor_acl,
    boleto_id
  ) => {

    setOperationLoading(true);
    try {
      await addConsumerGroup(
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        inicio_vigencia,
        fim_vigencia,
        inicio_teste,
        fim_teste,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao,
        demanda,
        rtc,
        rtp,
        ke,
        pulso_watt,
        numero_telemetria,
        fornecedor_acl,
        boleto_id
      );
      setNotificationType(1);
      refreshConsumerGroups();
    } catch (error) {
      console.error(
        'Ocorreu um erro ao editar o grupo de consumidores:',
        error
      );

      errorHandlingAddConcessionaires(addErrorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
        setSelectedState(null);
      }, 2500);
    }
  };

  const getCitiesBySelectedState = (selectedState) => {
    if (selectedState) {
      const selectedStateObj = StatesAndCities.find((stateObj) =>
        stateObj.estados.some((estado) => estado.nomeEstado === selectedState)
      );

      if (selectedStateObj) {
        const selectedEstado = selectedStateObj.estados.find(
          (estado) => estado.nomeEstado === selectedState
        );
        setSelectedCities(selectedEstado.cidades);
      }
    }
  };

  function errorHandlingAddConcessionaires(error) {
    setLoading(false);
    setNotificationType(2);
  }

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  async function getFilteredConsumerGroups(object) {
    try {
      const { headers } = await getSession();

      const response = await ioseAPI.getFilteredConsumerGroups(
        headers,
        object
      );

      const { data } = response;

      setFilteredConsumerGroups(data)
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.noRegisteredConsumerGroups;

        console.log('erro ao buscar ucs filtradas', errorMessage)
    }
  }

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR UNIDADE CONSUMIDORA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Unidades Consumidoras"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {hasReadPermission ? (
            <>
              <ConsumerGroupMainTable
                data={cards}
                getAll={getAllConsumerGroups}
                filter={setFilteredConsumerGroups}
                getFilteredConsumerGroups={getFilteredConsumerGroups}
                lastFilterObject={lastFilterObject}
              />
              {/* <div style={{marginTop: '15px'}}>
                <ConsumerGroupNewMainTable
                  data={cards}
                  getAll={getAllConsumerGroups}
                  filter={setFilteredConsumerGroups}
                  getFilteredConsumerGroups={getFilteredConsumerGroups}
                  lastFilterObject={lastFilterObject}
                />
              </div> */}
            </>
          ) : (
            setError(
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            )
          )}
        </>
      );
    }
  };

  const renderDownloadButtons = () => {
    return (
      <div style={{ display: 'flex', gap: '15px' }}>
        <IoseButton2
          onClick={handleDownloadButtonClick}
          style={{ width: '100px', margin: '0' }}
        >
          <span style={{ marginRight: '5px' }}>XLSX</span>
          <GetAppIcon />
        </IoseButton2>
        <IoseButton2
          onClick={() => handleDownloadPdfButtonClick()}
          style={{ width: '100px', margin: '0' }}
        >
          <span style={{ marginRight: '5px' }}>PDF</span>
          <GetAppIcon />
        </IoseButton2>
      </div>
    );
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddConsumerGroupModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          modalHeaderTitle="UNIDADE CONSUMIDORA"
          modalTitle="CADASTRAR UNIDADE CONSUMIDORA"
          submit={handleAddConsumerGroup}
          notificationType={notificationType}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={operationLoading}
          selectData={concessionaires}
          contractData={contracts}
        />
      </>
    );
  };


  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {hasReadPermission ? (
          <>
            {renderSubHeaderAndSearchBar()}
            {cards?.length > 0 ? renderDownloadButtons() : <></>}
            <ConsumerGroupFilters
              filter={setFilteredConsumerGroups}
              error={setError}
              loading={setLoading}
              getAll={getAllConsumerGroups}
              setLastFilter={setlastFilterObject}
            />
            {isLoading || loading ? (
              <>
                <LoadingIndicator loading={isLoading || loading} />
              </>
            ) : (
              <>
                {renderContent()}
                {renderModals()}
              </>
            )}
          </>
        ) : (
          <div style={{ marginTop: '35px' }}>
            <IoseAlertLoadingCards
              text={
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              }
            />
          </div>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}
