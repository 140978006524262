import React, { useState, useEffect } from 'react';
import { GridContainer, StyledTab, StyledTable, StyledTabs, StyledTd, StyledTh } from './style';
import { ContainerCards } from 'containers/SuperUserContainer/style';
import { IoseAlertLoadingCards } from 'components/IoseAlert';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import LocalStorage from 'common/local_storage_api';
import { useClient } from 'common/contexts/client';
import { useSystemUrls } from 'hooks';

export default function MapConfigurationContainer() {
  const [cardsExterno, setCardsExterno] = useState([]);
  const [cardsInterno, setCardsInterno] = useState([]);
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { systemUrls } = useSystemUrls()

  const { client } = useClient();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (systemUrls?.length > 0) {
      const interno = []
      const externo = []

      systemUrls.forEach((api) => {
        if (api.externa) {
          externo.push(api)
        } else {
          interno.push(api)
        }

      })

      setCardsExterno(externo)
      setCardsInterno(interno)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemUrls]);

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Configurações gerais"
        description={'Total: ' + (cardsExterno?.length + cardsInterno?.length)}
      >
      </IoseSubHeaderBar>
    );
  };

  const renderTabelaVariaveisSistema = () => {
    return (
      <div style={{width: '100%'}}>
        <StyledTabs value={selectedTab} onChange={handleChange}>
          <StyledTab label="APIS INTERNAS" />
          <StyledTab label="APIS EXTERNAS" />
        </StyledTabs>
        {selectedTab === 0 && (
          <StyledTable>
            <thead>
              <tr style={{ fontSize: 10 }}>
                <StyledTh>DESCRIÇÃO</StyledTh>
                <StyledTh>URL</StyledTh>
              </tr>
            </thead>
            <tbody>
              {cardsInterno?.map((item) => (
                <tr key={item.uuid_url} style={{ fontSize: 10 }}>
                  <StyledTd>{item.descricao}</StyledTd>
                  <StyledTd>{item.url}</StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        )}
        {selectedTab === 1 && (
          <StyledTable>
          <thead>
            <tr style={{ fontSize: 10 }}>
              <StyledTh>DESCRIÇÃO</StyledTh>
              <StyledTh>URL</StyledTh>
            </tr>
          </thead>
          <tbody>
            {cardsExterno?.map((item) => (
              <tr key={item.uuid_url} style={{ fontSize: 10 }}>
                <StyledTd>{item.descricao}</StyledTd>
                <StyledTd>{item.url}</StyledTd>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </div>
  );

  }

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cardsExterno?.length + cardsInterno?.length}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            <ContainerCards>{renderTabelaVariaveisSistema()}</ContainerCards>
          </IoseInfinityScroll>
        </>
      );
    }
  };

  return (
    <GridContainer container>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </GridContainer>
  );
}
