import React, { useState, useEffect } from 'react';

//Imports from Style
import {
  CircularProgressStyled,
  ErrorIconStyled,
  CheckCircleIconStyled,
  Message,
} from './style';

//Imports from IOSE Components
// import { IoseResendButton } from 'components/IoseButtonIcon';

export default function IoseIndicatorStatusSchedule({
  id,
  circuitStateScheduling,
  form_what,
  createResponse,
  deleteResponse,
  editResponse,
}) {
  const [status, setStatus] = useState('');
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState({ type: 0, text: '' });

  //Change icon through websocket
  useEffect(() => {
    setloading(true);
    changeStatusAccordinglyResponse(form_what);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitStateScheduling, createResponse, deleteResponse, editResponse]);

  const changeStatusAccordinglyResponse = (form) => {
    let responseRequistion = '';

    switch (form) {
      case 'create':
        responseRequistion = createResponse;
        break;
      case 'update':
        responseRequistion = editResponse;
        break;
      default:
        responseRequistion = deleteResponse;
    }

    if (responseRequistion === '') {
      circuitStateScheduling &&
        circuitStateScheduling.type === 'scheduling' &&
        circuitStateScheduling.uuid_circuit === id &&
        handleChangeStatus(circuitStateScheduling.state);
    } else {
      if (responseRequistion === 200) {
        handleChangeStatus('resolved');
      } else {
        status === '' && handleChangeStatus();
      }
      setloading(false);
    }
  };

  const handleChangeStatus = (state) => {
    setloading(false);
    setStatus(state);

    switch (state) {
      case 'resolved':
        setMessage({
          type: 1,
          text:
            circuitStateScheduling.action === 'delete'
              ? 'Removido com sucesso!'
              : 'Resolvido !',
        });
        break;
      case 'no_slot':
        setMessage({ type: 2, text: 'Sem espaço! ' });
        break;
      case 'invalid_slot':
        setMessage({ type: 2, text: 'Falha na operação! ' });
        break;
      case 'failure_in_module':
        setMessage({ type: 2, text: 'Falha na operação! ' });
        break;
      default:
        setMessage({ type: 2, text: 'Pendente' });
    }
  };

  const renderMessage = () => {
    return message.type === 1 ? (
      <Message type={message.type}>
        <p>{message.text}</p>
        <CheckCircleIconStyled />
      </Message>
    ) : (
      <Message type={message.type}>
        <p>{message.text}</p>
        <ErrorIconStyled />
      </Message>
    );
  };

  return loading ? <CircularProgressStyled /> : renderMessage();
}
