import React from 'react';
import { FormControlStyled, StyledInputLabel, StyledSelect } from './style';
import { MenuItem } from '@material-ui/core';

export default function FilterSelect({
  value,
  onChange,
  label,
  options,
  width,
}) {
  return (
    <FormControlStyled>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect value={value} onChange={onChange} width={width}>
        {options?.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControlStyled>
  );
}
