import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import { Messages, LocalStorage } from 'common';
import * as S from './style';
import Permissions from 'common/permissions';
import EnvironmentMainTable from './components/environment-table';
import {
  useClientData,
  useEnvironments,
  useUnitys,
  useGroupPermissions,
} from 'hooks';
import {
  IoseInfinityScroll,
  CreateEnviromentModal,
  IoseSearch,
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
} from 'components';

export default function EnvironmentContainer() {
  const [cards, setCards] = useState([]);
  const [error, setError] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  //eslint-disable-next-line
  const [hasEditPermission, setHasEditPermission] = useState();
  const [updatedEnvironments, setUpdatedEnvironments] = useState();

  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { groupPermissions } = useGroupPermissions();
  const { environments, noEnvironmentsError } = useEnvironments()
  const userSession = LocalStorage.getSession();


  useEffect(() => {
    const readPath = ['cadastro', 'ambientes', 'leitura'];
    const addPath = ['cadastro', 'ambientes', 'criar'];
    const editPath = ['cadastro', 'ambientes', 'editar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    environments?.length > 0
      ? setCards(environments)
      : setError(noEnvironmentsError);
  }, [environments, noEnvironmentsError]);

  useEffect(() => {
    if (environments?.length > 0) {
      const updatedEnvs = environments?.map((environment) => {
        const unity = unitys?.find((unity) => unity.uuid_unity === environment?.uuid_unity);

        return {
          ...environment,
          unity_name: unity ? unity.name : null,
        };
      });

      setUpdatedEnvironments(updatedEnvs);
    } else {
      setError(noEnvironmentsError);
    }
  }, [environments, noEnvironmentsError, unitys]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const filterEnvironments = (searchName) => {
    setError('');

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = environments?.filter((item) =>
        item?.name?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setUpdatedEnvironments(itemFiltered)
        : setError(Messages.noFindEnvironments);
    } else {
      setUpdatedEnvironments(environments?.map((environment) => {
        const unity = unitys?.find((unity) => unity.uuid_unity === environment?.uuid_unity);
        return {
          ...environment,
          unity_name: unity ? unity.name : null,
        };
      }));
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="Criar Ambiente"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Ambientes"
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Ambientes…"
          funcSearch={filterEnvironments}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable  = () => {
    return (
      <EnvironmentMainTable data={updatedEnvironments}/>
    )
  }

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length ? cards.length : 5}
            hasMore={true}
            endList={false}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderTable()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <CreateEnviromentModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          unitys={unitys}
        />

      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrapContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </S.WrapContainer>
    </Grow>
  );
}
