import React, { useState } from 'react';
import {
  AttachMoney as AttachMoneyIcon,
  BatteryChargingFull as BatteryChargingFullIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';
import DesktopAccessDisabled from '@material-ui/icons/DesktopAccessDisabled';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { NewLoadingValueCards } from 'components/IoseLoader';
import { Tooltip } from '@material-ui/core';
import * as S from './style';
import IoseEnergyBillsCardValuesBarChart from 'components/IoseEnergyBillsCardValuesBarChart';
import IoseUcSemCadastroChart from 'components/IoseUcSemCadastroChart';
import IoseUcsSemConsumoChart from 'components/IoseUcsSemConsumoChart';
import IoseUcsDesativadasChart from 'components/IoseUcsDesativadasChart';

const convertNumberToMonetaryStyle = (number = 0) => {
  const money = number?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return money;
};

const formatNumber = (number) => {
  const formattedNumber = number?.toLocaleString();
  return formattedNumber;
};

export default function IoseCardsEnergyBills({
  loading,
  auditValues,
  auditExtract,
  averages,
  selectedRefMonth,
  filteredMercadoLivre,
}) {
  const {
    totalBillValue,
    totalDifValue,
    totalRecalcValue,
    totalConsumerGroup,
  } = auditValues ?? {};

  const { valueAverage, consumptionAverage } = averages;

  const {
    totalDemanContFora,
    totalDemanContPonta,
    totalDemanFora,
    totalDemanPonta,
    totalConsumPonta,
    totalConsumFora,
    semCadastro,
    semConsumo,
    ucsDesativadas,
  } = auditExtract ?? {};

  const valueBalance = totalBillValue - totalRecalcValue;
  const qtdSemCadastro = semCadastro?.length;
  const qtdSemConsumo = semConsumo?.length;
  const qtdUcsDesativadas = ucsDesativadas?.length;

  const [isCadastroModalOpen, setIsCadastroModalOpen] = useState(false);
  const [isConsumoModalOpen, setIsConsumoModalOpen] = useState(false);
  const [isDesativadasModalOpen, setIsDesativadasModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [filteredSemCadastro, setFilteredSemCadastro] = useState([]);
  const [filteredSemConsumo, setFilteredSemConsumo] = useState([]);
  const [filteredUcsDesativas, setFilteredUcsDesativas] = useState([]);

  const filterSemCadastro = (semCadastro, selectedRefMonth) => {
    if (!selectedRefMonth) {
      return semCadastro;
    } else {
      return semCadastro?.filter(
        ({ reference_month }) => reference_month === selectedRefMonth
      );
    }
  };

  const handleSemCadastro = () => {
    const filteredData = filterSemCadastro(semCadastro, selectedRefMonth);
    setFilteredSemCadastro(filteredData);
    setSelectedCard({ title: 'NOVAS UCS', data: filteredData });
    setIsCadastroModalOpen(true);
  };

  const handleSemConsumo = () => {
    const filteredData = filterSemCadastro(semConsumo, selectedRefMonth);
    setFilteredSemConsumo(filteredData);
    setSelectedCard({ title: 'UCS SEM CONSUMO', data: filteredData });
    setIsConsumoModalOpen(true);
  };
  const handleUcsDesativadas = () => {
    const filteredData = filterSemCadastro(ucsDesativadas, selectedRefMonth);
    setFilteredUcsDesativas(filteredData);
    setSelectedCard({ title: 'DESATIVADAS', data: filteredData });
    setIsDesativadasModalOpen(true);
  };

  const cardsData = [
    {
      icon: <AttachMoneyIcon />,
      chart: (
        <IoseEnergyBillsCardValuesBarChart
          loading={loading}
          data={auditValues}
        />
      ),
      title: 'VALOR TOTAL (R$)',
      data: [
        {
          key: 'FATURAS:',
          value:
            totalBillValue !== undefined
              ? convertNumberToMonetaryStyle(totalBillValue)
              : 0,
        },
        {
          key: 'RECÁLCULO:',
          value:
            totalRecalcValue !== undefined
              ? convertNumberToMonetaryStyle(totalRecalcValue)
              : 0,
        },
        {
          key: 'DIFERENÇA:',
          value:
            totalDifValue !== undefined
              ? convertNumberToMonetaryStyle(valueBalance)
              : 0,
        },
        {
          key: 'MERCADO LIVRE:',
          value:
            filteredMercadoLivre !== undefined
              ? convertNumberToMonetaryStyle(filteredMercadoLivre)
              : 0,
        },
        {
          key: 'TOTAL:',
          value:
            filteredMercadoLivre !== undefined
              ? convertNumberToMonetaryStyle(
                  totalBillValue + filteredMercadoLivre
                )
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'CONSUMO TOTAL (kWh)',
      data: [
        {
          key: 'CONSUMO PTA: ',
          value:
            totalConsumPonta !== undefined
              ? `${formatNumber(totalConsumPonta)}`
              : 0,
        },
        {
          key: 'CONSUMO F. PTA: ',
          value:
            totalConsumFora !== undefined
              ? `${formatNumber(totalConsumFora)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'DEMANDAS (kW)',
      data: [
        {
          key: 'DEMANDA PTA: ',
          value:
            totalDemanPonta !== undefined
              ? `${formatNumber(totalDemanPonta)}`
              : 0,
        },
        {
          key: 'DEMANDA F. PTA: ',
          value:
            totalDemanFora !== undefined
              ? `${formatNumber(totalDemanFora)}`
              : 0,
        },
        {
          key: 'DEMANDA CONT. F. PTA: ',
          value:
            totalDemanContFora !== undefined
              ? `${formatNumber(totalDemanContFora)}`
              : 0,
        },
        {
          key: 'DEMANDA CONT. PTA: ',
          value:
            totalDemanContPonta !== undefined
              ? `${formatNumber(totalDemanContPonta)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon />,
      title: 'RESUMOS',
      data: [
        {
          key: 'MÉDIA POR U.C.',
          value:
            valueAverage > 0 ? convertNumberToMonetaryStyle(valueAverage) : 0,
        },
        {
          key: 'MÉDIA POR U.C. (kWh)',
          value:
            consumptionAverage > 0 ? `${formatNumber(consumptionAverage)}` : 0,
        },
        {
          key: 'TOTAL DE  U.C.',
          value:
            totalConsumerGroup > 0 ? `${formatNumber(totalConsumerGroup)}` : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon style={{ color: '#D60606' }} />,
      title: 'UCS',
      data: [
        {
          key: 'NOVAS:',
          value: qtdSemCadastro !== undefined ? qtdSemCadastro : 0,
        },
        {
          key: 'SEM CONSUMOS:',
          value: qtdSemConsumo !== undefined ? qtdSemConsumo : 0,
        },
        {
          key: 'VALIDAR DESATIVADAS:',
          value: qtdUcsDesativadas !== undefined ? qtdUcsDesativadas : 0,
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <DesktopAccessDisabled />,
      showMoreIconTooltip: 'NOVAS UCS',
      onIconClick: handleSemCadastro,
      showSecondIcon: true,
      secondIcon: <BatteryCharging20Icon />,
      secondIconTooltip: 'UCS SEM CONSUMO',
      onSecondIconClick: handleSemConsumo,
      showThirdIcon: true,
      thirdIcon: <NotInterestedIcon />,
      thirdIconTooltip: 'DESATIVADAS',
      onThirdIconClick: handleUcsDesativadas,
    },
  ];

  return (
    <>
      <S.CardGrid container spacing={2}>
        {cardsData.map(
          ({
            icon,
            title,
            data,
            bgColor,
            showMore,
            showMoreIcon,
            onIconClick,
            showMoreIconTooltip,
            secondIconTooltip,
            onSecondIconClick,
            secondIcon,
            showSecondIcon,
            thirdIcon,
            thirdIconTooltip,
            onThirdIconClick,
          }) => (
            <S.BoxContainerHover
              key={title}
              style={{ backgroundColor: bgColor }}
              showMore={showMore}
            >
              <S.HeaderContainer>
                <S.IconHeader>{icon}</S.IconHeader>
                <S.CardTitle>{title}</S.CardTitle>
              </S.HeaderContainer>
              {data.map(({ key, value }) => (
                <S.DataContainer
                  key={key}
                  style={key === 'TOTAL:' ? { marginTop: '25px' } : {}}
                >
                  <S.CardDataKey>{key}</S.CardDataKey>
                  {loading ? (
                    <NewLoadingValueCards loading={loading} margin="0px" />
                  ) : (
                    <S.CardDataValue>{value}</S.CardDataValue>
                  )}
                </S.DataContainer>
              ))}
              {showMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Tooltip title={showMoreIconTooltip} arrow>
                    <S.ClickableIcon onClick={onIconClick}>
                      {showMoreIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                  <Tooltip title={secondIconTooltip} arrow>
                    <S.ClickableIcon onClick={onSecondIconClick}>
                      {secondIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                  <Tooltip title={thirdIconTooltip} arrow>
                    <S.ClickableIcon onClick={onThirdIconClick}>
                      {thirdIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                </div>
              )}
            </S.BoxContainerHover>
          )
        )}
      </S.CardGrid>
      <IoseUcSemCadastroChart
        open={isCadastroModalOpen}
        onClose={() => setIsCadastroModalOpen(false)}
        selectedCard={selectedCard}
        filteredSemCadastro={filteredSemCadastro}
      />
      <IoseUcsSemConsumoChart
        open={isConsumoModalOpen}
        onClose={() => setIsConsumoModalOpen(false)}
        selectedCard={selectedCard}
        filteredSemConsumo={filteredSemConsumo}
      />
      <IoseUcsDesativadasChart
        open={isDesativadasModalOpen}
        onClose={() => setIsDesativadasModalOpen(false)}
        selectedCard={selectedCard}
        filteredUcsDesativas={filteredUcsDesativas}
      />
    </>
  );
}
