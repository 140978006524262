import React from "react";
import { PreviousPageIcon, FixedButtonPreviousContainer, PreviousIconWrapper, PaginationInfo } from "../style";
import { Tooltip } from "@material-ui/core";

export default function FixedButtonPreviousButton({ onClick, disabled, currentPage, pages }) {
  return (
    <FixedButtonPreviousContainer>
      <div>
        <PreviousIconWrapper
          onClick={() => onClick()}
          disabled={disabled}
        >
          <Tooltip title="PÁGINA ANTERIOR" placement="top" arrow>
            <PreviousPageIcon />
          </Tooltip>
        </PreviousIconWrapper>
      </div>
      <div>
        <PaginationInfo> {`${currentPage} / ${pages.length}`}</PaginationInfo>
      </div>
    </FixedButtonPreviousContainer>
  )
}