import React, { useState } from 'react';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

//Imports from IOSE components
import {
  IoseBackButton,
  IoseLogButton,
  IoseEventsButton,
  IoseEditButton,
  IoseCalendarButton,
  IoseInfoButton,
} from 'components/IoseButtonIcon';
import IoseButton from 'components/Buttons/IoseButton';
import IoseSchedulerTable from 'components/IoseSchedulerTable';
import IoseCircuitLogTable from 'components/IoseCircuitLogTable';

//imports from Material UI components
import { Grow } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import Messages from 'common/Messages';

//Imports from Style
import {
  Container,
  DivHeaderButton,
  CardHeaderStyled,
  CircuitIcon,
  AvatarStyledHeader,
  Error,
  ContainerInfo,
  TCIcon,
  ContainerLogTable,
  HeaderLogTable,
  LogIcon,
  TitleHeader,
  ContainerButton,
  ContainerButtons,
  QRIcon,
} from './style';

/** This components create a card in sidebar with more details circuit */
export default function IoseCircuitShowCard({
  clickBack,
  name,
  description,
  uuid_circuit,
  redirectEditCircuit,
  group,
  redirectViewSchedule,
  closeSide,
  dataTable,
  getSchedulingModulesByGroup,
  resendRequistion,
  informationTC,
  getAllLogs,
  getDataUser,
  getSchedulingGroupByCircuit,
  setLoading,
  redirectEvents,
}) {
  let location = useLocation();

  const circuitData = location.state.circuitData;

  const [section, setSection] = useState(0);

  const handleChangeRedirectSchedulingsUnity = () => {
    closeSide();
    setTimeout(() => {
      redirectViewSchedule();
    }, 500);
  };

  const handleChangeScheduling = (selectLogScheduling) => {
    setSection(selectLogScheduling);
    selectLogScheduling === 2 && getSchedulingGroupByCircuit();
  };

  const handleRedirectEvents = () => {
    closeSide();
    setTimeout(() => {
      redirectEvents(circuitData);
    }, 500);
  };

  const renderHeader = () => {
    const isOnDashboard = location.pathname.includes('dashboard');

    let selectLogSection = section === 0 ? 1 : section === 2 ? 1 : 0;
    let selectLogScheduling = section === 0 ? 2 : section === 1 ? 2 : 0;

    const avatarComp = (
      <AvatarStyledHeader>
        <CircuitIcon />
      </AvatarStyledHeader>
    );

    const backbutton = <IoseBackButton onClick={(e) => clickBack()} />;

    return (
      <DivHeaderButton>
        <TitleHeader>
          {location.state.backbutton && backbutton}{' '}
          <CardHeaderStyled
            avatar={avatarComp}
            title={name}
            subheader={description}
          />
        </TitleHeader>

        <ContainerButtons>
          <IoseInfoButton
            onClick={() => setSection(0)}
            currentsection={section}
            section={0}
          />
          <IoseLogButton
            onClick={() => setSection(selectLogSection)}
            left={'24px'}
            currentsection={section}
            section={1}
          />
          {group === 'super' && (
            <IoseEventsButton
              onClick={() => handleRedirectEvents()}
              left={'24px'}
            />
          )}
          <IoseCalendarButton
            onClick={() => handleChangeScheduling(selectLogScheduling)}
            tooltip="Ver Agendamentos"
            left={'24px'}
            currentsection={section}
            section={2}
          />
          {location.state.backbutton && !isOnDashboard && (
            <IoseEditButton
              tooltip="Editar Circuito"
              onClick={() => redirectEditCircuit()}
              left={'24px'}
            />
          )}
        </ContainerButtons>
      </DivHeaderButton>
    );
  };

  const renderCircuitSchedulings = () => {
    const emptyArray = dataTable !== '' && dataTable.length === 0;

    return (
      <>
        <HeaderLogTable>
          <div>
            <EventIcon />
            <p>Agendamentos relacionados ao circuito: </p>
          </div>
        </HeaderLogTable>

        {emptyArray ? (
          <Error>{Messages.emptyScheduling}</Error>
        ) : (
          <IoseSchedulerTable
            sidebar="true"
            dashboard="false"
            group={group}
            rows={dataTable === '' ? [] : dataTable}
            resendRequistion={resendRequistion}
            getSchedulingModulesByGroup={getSchedulingModulesByGroup}
          />
        )}

        <ContainerButton>
          <IoseButton onClick={() => handleChangeRedirectSchedulingsUnity()}>
            Agendamentos da unidade
          </IoseButton>
        </ContainerButton>
      </>
    );
  };

  const renderTC = () => {
    let has_tc = false;

    const has_tc_primary = 'primary_tc' in circuitData;
    const has_tc_secondary = 'secondary_tc' in circuitData;

    if (has_tc_primary && has_tc_secondary) {
      let factor = circuitData.primary_tc / circuitData.secondary_tc;
      if (factor !== 1) has_tc = true;
    }

    const paramsTC = (
      <>
        <p>Primário: {informationTC.primary_tc}</p>
        <p>Secundário: {informationTC.secondary_tc}</p>
      </>
    );

    return (
      <ContainerInfo>
        <div>
          <TCIcon />
          <p>Transformador de Corrente: </p>
        </div>

        {has_tc ? paramsTC : <p>Não Possui</p>}
      </ContainerInfo>
    );
  };

  const renderInfo = () => {
    const hasCodeModule = 'code_module' in circuitData;

    const codeModuleUndef =
      hasCodeModule && circuitData.code_module === undefined;

    const msgCodeMod = 'Circuito sem identificação QR Code';

    const codeModule =
      hasCodeModule && !codeModuleUndef ? circuitData.code_module : msgCodeMod;

    const hasIdentificationNumber = 'identification_number' in circuitData;

    const identificationNumberUndf =
      hasIdentificationNumber &&
      circuitData.identification_number === undefined;

    const msgIdentificationNumber = 'Unidade consumidora não cadastrada';

    const identificationNumber =
      hasIdentificationNumber && !identificationNumberUndf
        ? circuitData.identification_number
        : msgIdentificationNumber;

    return (
      <>
        <ContainerInfo>
          <div>
            <b>ID</b>
            <p>Identificador: </p>
            <p>{uuid_circuit}</p>
          </div>
        </ContainerInfo>

        {renderTC()}

        <ContainerInfo>
          <div>
            <QRIcon />
            <p>QR Code: </p>
            <p>{codeModule}</p>
          </div>
        </ContainerInfo>

        <ContainerInfo>
          <div>
            <b>UC</b>
            <p>Unidade Consumidora: </p>
            <p>{identificationNumber}</p>
          </div>
        </ContainerInfo>
      </>
    );
  };

  const renderTableLogs = () => {
    return (
      <>
        <HeaderLogTable>
          <div>
            <LogIcon />
            <p>Eventos relacionados ao circuito: </p>
          </div>
        </HeaderLogTable>
        <IoseCircuitLogTable
          getAllRows={getAllLogs}
          uuid_circuit={uuid_circuit}
          getDataUser={getDataUser}
          setLoading={setLoading}
        />
      </>
    );
  };

  const renderSection = () => {
    switch (section) {
      case 1:
        return renderTableLogs();

      case 2:
        return renderCircuitSchedulings();

      default:
        return renderInfo();
    }
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <Container>
        {renderHeader()}
        <ContainerLogTable>{renderSection()}</ContainerLogTable>
      </Container>
    </Grow>
  );
}

IoseCircuitShowCard.propTypes = {
  /** This props is a function to back show switchboards*/
  clickBack: PropType.func,
  /** This props is circuit name*/
  name: PropType.string,
  /** This props is circuit description */
  description: PropType.string,
  /** This props is circuit uuid in DB*/
  uuid_circuit: PropType.string,
  /** This props is a function that redirect to IoseFormCircuitEditCard*/
  redirectEditCircuit: PropType.func,
};
