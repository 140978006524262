import React from 'react';
import PropType from 'prop-types';

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { useUnity, useClient } from 'common/contexts/client';

//Imports from IOSE Components
import {
  Container,
  SideHeader,
  ButtonArea,
  Icon,
} from 'components/IoseSideContainer';
import { IoseOperatorButton } from 'components/IoseButtonIcon';

import UnityIcon from './Unity.svg';
import { RoutesUnitySide } from 'routers';

export default function SidebarUnityContainer({
  setGetcards,
  setEmpytArray,
  group,
  closeSide,
  socket,
}) {
  const history = useHistory();
  let { path } = useRouteMatch();
  let location = useLocation();

  const { unity } = useUnity();
  const { client } = useClient();

  //Variables to keep name and unity name sidebar shows when change urls
  const clientName = client.name;

  const openEmployee = () => {
    history.push({
      pathname: `${path}/employee`,
      state: {
        clientName: clientName,
        unityName: unity?.name,
        uuid_unity: unity?.uuid_unity,
        uuid_client: location.state?.uuid_client,
        tariff_period: location.state?.tariff_period,
        tariff_data: location.state?.tariff_data,
        contracted_demand: location.state?.contracted_demand,
      },
    });
  };

  const clickBack = () => {
    history.push({
      pathname: '/global/client/switchboard',
      state: {
        clientName: clientName,
        unityName: unity?.name,
        uuid_unity: unity?.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
  };

  return (
    <Container>
      <SideHeader>
        <Icon src={UnityIcon} />
        <h2>
          {location.pathname === '/global/client/unity'
            ? 'Unidade'
            : unity.name}
        </h2>
        <ButtonArea>
          {group.includes('operator') ||
          location.pathname === '/global/client/unity' ? (
            <></>
          ) : (
            <IoseOperatorButton
              onClick={(e) => openEmployee()}
              tooltip="VER FUNCIONÁRIOS"
            />
          )}
        </ButtonArea>
      </SideHeader>

      <RoutesUnitySide
        setGetcards={setGetcards}
        setEmpytArray={setEmpytArray}
        group={group}
        clickBack={clickBack}
        closeSide={closeSide}
        socket={socket}
      />
    </Container>
  );
}

SidebarUnityContainer.propTypes = {
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
};
