import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountContext, LocalStorage, ioseAPI } from 'common';
import * as S from './style';
import { MeasurementReport } from 'reports';
import { xlsxReports } from 'reports/Xlsx';
import Permissions from 'common/permissions';
import DateErrorMessage from 'components/Messages/DateErrorMessage';
import SelectReferenceMonth from 'components/SelectReferenceMonth';
import {
  IoseFilterConsumerGroup,
  IoseMapRedirectButton,
  IoseSelectCompleteDate,
  IoseSelectFilter,
  IoseSubHeaderBar,
  LoadingIndicator,
  NoDataMessage,
  ReportMessage,
  IoseAlertLoadingCards,
  IoseButton2,
} from 'components';
import {
  useConsumerGroup,
  useConcessionaires,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';
import {
  GeneralManagement,
  RegionalManagement,
  Managements,
} from '../../constants';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const formatToISODate = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
  }
  return '';
};

export default function MeasurementeReportContainer() {
  const { getSession } = useContext(AccountContext);
  const { consumerGroups } = useConsumerGroup();
  const { concessionaires } = useConcessionaires();
  const { createLog } = useCreateLogs();
  const history = useHistory();

  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [referenceMonth, setReferenceMonth] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [selectedInitialReading, setSelectedInitialReading] = useState(null);
  const [initialReading, setInitialReading] = useState(null);
  const [selectedFinalReading, setSelectedFinalReading] = useState(null);
  const [finalReading, setFinalReading] = useState(null);
  const [selectedInitialExpiration, setSelectedInitialExpiration] = useState(
    null
  );
  const [initialExpiration, setInitialExpiration] = useState(null);
  const [selectedFinalExpiration, setSelectedFinalExpiration] = useState(null);
  const [finalExpiration, setFinalExpiration] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('REF: MÊS/ANO');
  const [noDataError, setNoDataError] = useState(false);
  const [uniqueConcessionaires, setUniqueConcessionaires] = useState([]);
  const [concessionaire, setConcessionaire] = useState(null);
  const [generalManagement, setGeneralManagement] = useState(undefined);
  const [regionalManagement, setRegionalManagement] = useState(undefined);
  const [dateError, setDateError] = useState(false);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const reportPath = ['relatorios', 'medicao', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);


  function redirectToMapPage() {
    history.push('/map');
  }

  useEffect(() => {
    const uniqueSet = new Set(concessionaires?.map((obj) => obj.bill_name));
    const uniqueConcessionairesArray = [...uniqueSet].map((bill_name) => ({
      value: bill_name,
      label: bill_name,
    }));
    setUniqueConcessionaires(uniqueConcessionairesArray);
  }, [concessionaires]);


  useEffect(() => {
    const hasFilter =
      selectedDate ||
      identificationNumber ||
      initialExpiration ||
      initialReading ||
      finalReading ||
      initialExpiration ||
      finalExpiration ||
      concessionaire ||
      regionalManagement ||
      generalManagement;

    setIsFilterEnabled(hasFilter);
  }, [
    selectedDate,
    identificationNumber,
    initialReading,
    finalReading,
    initialExpiration,
    finalExpiration,
    concessionaire,
    generalManagement,
    regionalManagement,
  ]);


  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleDateChange = (date) => {
    const formattedDate = formatToBrazilianDate(date);

    setSelectedDate(date);
    setReferenceMonth(formattedDate);
  };

  const handleInitalExpirationChange = (date) => {
    const formattedDate = formatToISODate(date);

    setInitialExpiration(formattedDate);
    setSelectedInitialExpiration(date);
  };

  const handleFinalExpirationChange = (date) => {
    const formattedDate = formatToISODate(date);

    setFinalExpiration(formattedDate);
    setSelectedFinalExpiration(date);
  };

  const handleInitialReadingChange = (date) => {
    const formattedData = formatToISODate(date);

    setInitialReading(formattedData);
    setSelectedInitialReading(date);
  };

  const handleFinalReadingChange = (date) => {
    const formattedData = formatToISODate(date);

    setFinalReading(formattedData);
    setSelectedFinalReading(date);
  };

  const handleIdentificationNumberChange = (identification_number) => {
    setIdentificationNumber(identification_number);
  };

  const handleConcessionaireChange = (event) => {
    setConcessionaire(event.target.value);
  };

  const handleGGChange = (event) => {
    setGeneralManagement(event.target.value);
  };

  const handleGRChange = (event) => {
    setRegionalManagement(event.target.value);
  };

  const getAuditData = async () => {
    setLoading(true);
    const { headers } = getSession();

    if (selectedInitialExpiration && selectedFinalExpiration) {
      if (selectedFinalExpiration < selectedInitialExpiration) {
        setDateError(true);

        setTimeout(() => {
          setSelectedInitialExpiration(null);
          setInitialExpiration(null);
          setSelectedFinalExpiration(null);
          setFinalExpiration(null);
          setDateError(false);
        }, 5000);

        setLoading(false);
        return;
      }
    }

    if (selectedInitialReading && selectedFinalReading) {
      if (selectedFinalReading < selectedInitialReading) {
        setDateError(true);

        setTimeout(() => {
          setSelectedInitialReading(null);
          setInitialReading(null);
          setSelectedFinalReading(null);
          setFinalReading(null);
          setDateError(false);
        }, 5000);

        setLoading(false);
        return;
      }
    }

    const response = await ioseAPI.getAuditForReport(
      headers,
      identificationNumber,
      referenceMonth,
      initialReading,
      finalReading,
      initialExpiration,
      finalExpiration,
      concessionaire,
      generalManagement,
      regionalManagement
    );

    const { data } = response;
    const audits = data[0] === null ? [] : data;

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE MEDIÇÃO';
    const logContent = {
      identification_number: identificationNumber,
      reference_month: referenceMonth,
      initial_reading: initialReading,
      finalReading: finalReading,
      concessionaire: concessionaire,
      general_management: generalManagement,
      regional_mmanagement: regionalManagement,
    };

    await createLog(operacao, form, logContent);

    if (audits?.data.length === 0) {
      setLoading(false);
      setNoDataError(true);
      setIdentificationNumber(null);
      setSelectedDate(null);
      setReferenceMonth(null);
      setSelectedInitialReading(null);
      setInitialReading(null);
      setSelectedFinalReading(null);
      setFinalReading(null);
      setSelectedInitialExpiration(null);
      setInitialExpiration(null);
      setSelectedFinalExpiration(null);
      setFinalExpiration(null);
      setConcessionaire(null);
      setGeneralManagement(undefined);
      setRegionalManagement(undefined);
    } else {
      MeasurementReport(audits);
      setLoading(false);
      setIdentificationNumber(null);
      setSelectedDate(null);
      setReferenceMonth(null);
      setSelectedInitialReading(null);
      setInitialReading(null);
      setSelectedFinalReading(null);
      setFinalReading(null);
      setSelectedInitialExpiration(null);
      setInitialExpiration(null);
      setSelectedFinalExpiration(null);
      setFinalExpiration(null);
      setConcessionaire(null);
      setGeneralManagement(undefined);
      setRegionalManagement(undefined);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const getAuditXlsxData = async () => {
    setLoading(true);
    const { headers } = getSession();

    if (selectedInitialExpiration && selectedFinalExpiration) {
      if (selectedFinalExpiration < selectedInitialExpiration) {
        setDateError(true);

        setTimeout(() => {
          setSelectedInitialExpiration(null);
          setInitialExpiration(null);
          setSelectedFinalExpiration(null);
          setFinalExpiration(null);
          setDateError(false);
        }, 5000);

        setLoading(false);
        return;
      }
    }

    if (selectedInitialReading && selectedFinalReading) {
      if (selectedFinalReading < selectedInitialReading) {
        setDateError(true);

        setTimeout(() => {
          setSelectedInitialReading(null);
          setInitialReading(null);
          setSelectedFinalReading(null);
          setFinalReading(null);
          setDateError(false);
        }, 5000);

        setLoading(false);
        return;
      }
    }

    const response = await ioseAPI.getAuditForReport(
      headers,
      identificationNumber,
      referenceMonth,
      initialReading,
      finalReading,
      initialExpiration,
      finalExpiration,
      concessionaire,
      generalManagement,
      regionalManagement
    );

    const { data } = response;
    const audits = data[0] === null ? [] : data;

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE MEDIÇÃO';
    const logContent = {
      identification_number: identificationNumber,
      reference_month: referenceMonth,
      initial_reading: initialReading,
      finalReading: finalReading,
      concessionaire: concessionaire,
      general_management: generalManagement,
      regional_mmanagement: regionalManagement,
    };

    await createLog(operacao, form, logContent);

    if (audits?.data.length === 0) {
      setLoading(false);
      setNoDataError(true);
      setIdentificationNumber(null);
      setSelectedDate(null);
      setReferenceMonth(null);
      setSelectedInitialReading(null);
      setInitialReading(null);
      setSelectedFinalReading(null);
      setFinalReading(null);
      setSelectedInitialExpiration(null);
      setInitialExpiration(null);
      setSelectedFinalExpiration(null);
      setFinalExpiration(null);
      setConcessionaire(null);
      setGeneralManagement(undefined);
      setRegionalManagement(undefined);
    } else {
      xlsxReports.exportMeasurementReporToExcel(audits);
      setLoading(false);
      setIdentificationNumber(null);
      setSelectedDate(null);
      setReferenceMonth(null);
      setSelectedInitialReading(null);
      setInitialReading(null);
      setSelectedFinalReading(null);
      setFinalReading(null);
      setSelectedInitialExpiration(null);
      setInitialExpiration(null);
      setSelectedFinalExpiration(null);
      setFinalExpiration(null);
      setConcessionaire(null);
      setGeneralManagement(undefined);
      setRegionalManagement(undefined);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title="Relatório de Medição"
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    const filterOptions = [
      { value: 'REF: MÊS/ANO', label: 'REF: MÊS/ANO' },
      { value: 'Vencimento', label: 'Vencimento' },
      { value: 'Leitura Anterior', label: 'Leitura Anterior' },
      { value: 'Concessionária', label: 'Concessionária' },
    ];

    const getFilteredConsumerGroups = () => {
      if (generalManagement || regionalManagement) {
        const filteredOptions = consumerGroups?.filter((item) => {
          if (generalManagement && regionalManagement) {
            return (
              item.general_management === generalManagement &&
              item.regional_management === regionalManagement
            );
          } else if (generalManagement) {
            return item.general_management === generalManagement;
          } else if (regionalManagement) {
            return item.regional_management === regionalManagement;
          }
          return false;
        });

        const filteredData = filteredOptions?.map((item) => ({
          value: item.identification_number,
          label: item.identification_number,
        }));

        return filteredData;
      } else {
        const originalData = consumerGroups?.map((item) => ({
          value: item.identification_number,
          label: item.identification_number,
        }));

        return originalData;
      }
    };

    const renderDynamicFilters = () => {
      if (selectedFilter === 'REF: MÊS/ANO') {
        return (
          <S.DatesContainer>
            <SelectReferenceMonth
              label="REF: MÊS/ANO"
              handleChange={handleDateChange}
              selectedDate={selectedDate}
            />

            <IoseSelectFilter
              label="Gerência Geral"
              handleChange={handleGGChange}
              selectedOption={generalManagement}
              options={GeneralManagement}
            />

            <IoseSelectFilter
              label="Gerência Regional"
              handleChange={handleGRChange}
              selectedOption={regionalManagement}
              options={
                generalManagement === undefined
                  ? RegionalManagement
                  : Managements.find((item) => item[generalManagement])[
                      generalManagement
                    ]
              }
            />

            <IoseFilterConsumerGroup
              label="U.C."
              value={identificationNumber}
              onChange={handleIdentificationNumberChange}
              options={getFilteredConsumerGroups()}
            />
          </S.DatesContainer>
        );
      } else if (selectedFilter === 'Vencimento') {
        return (
          <S.DatesContainer>
            <IoseSelectCompleteDate
              label="Data inicial"
              handleChange={handleInitalExpirationChange}
              selectedDate={selectedInitialExpiration}
            />

            <IoseSelectCompleteDate
              label="Data final"
              handleChange={handleFinalExpirationChange}
              selectedDate={selectedFinalExpiration}
            />

            <IoseSelectFilter
              label="Gerência Geral"
              handleChange={handleGGChange}
              selectedOption={generalManagement}
              options={GeneralManagement}
            />

            <IoseSelectFilter
              label="Gerência Regional"
              handleChange={handleGRChange}
              selectedOption={regionalManagement}
              options={
                generalManagement === undefined
                  ? RegionalManagement
                  : Managements.find((item) => item[generalManagement])[
                      generalManagement
                    ]
              }
            />

            <IoseFilterConsumerGroup
              label="U.C."
              value={identificationNumber}
              onChange={handleIdentificationNumberChange}
              options={getFilteredConsumerGroups()}
            />
          </S.DatesContainer>
        );
      } else if (selectedFilter === 'Leitura Anterior') {
        return (
          <S.DatesContainer>
            <IoseSelectCompleteDate
              label="Data inicial"
              handleChange={handleInitialReadingChange}
              selectedDate={selectedInitialReading}
            />

            <IoseSelectCompleteDate
              label="Data final"
              handleChange={handleFinalReadingChange}
              selectedDate={selectedFinalReading}
            />

            <IoseSelectFilter
              label="Gerência Geral"
              handleChange={handleGGChange}
              selectedOption={generalManagement}
              options={GeneralManagement}
            />

            <IoseSelectFilter
              label="Gerência Regional"
              handleChange={handleGRChange}
              selectedOption={regionalManagement}
              options={
                generalManagement === undefined
                  ? RegionalManagement
                  : Managements.find((item) => item[generalManagement])[
                      generalManagement
                    ]
              }
            />
            <IoseFilterConsumerGroup
              label="U.C."
              value={identificationNumber}
              onChange={handleIdentificationNumberChange}
              options={getFilteredConsumerGroups()}
            />
          </S.DatesContainer>
        );
      } else if (selectedFilter === 'Concessionária') {
        return (
          <S.DatesContainer>
            <IoseSelectFilter
              label="Concessionária"
              handleChange={handleConcessionaireChange}
              selectedOption={concessionaire}
              options={uniqueConcessionaires}
            />

            <SelectReferenceMonth
              label="REF: MÊS/ANO"
              handleChange={handleDateChange}
              selectedDate={selectedDate}
            />

            <IoseSelectFilter
              label="Gerência Geral"
              handleChange={handleGGChange}
              selectedOption={generalManagement}
              options={GeneralManagement}
            />

            <IoseSelectFilter
              label="Gerência Regional"
              handleChange={handleGRChange}
              selectedOption={regionalManagement}
              options={
                generalManagement === undefined
                  ? RegionalManagement
                  : Managements.find((item) => item[generalManagement])[
                      generalManagement
                    ]
              }
            />
          </S.DatesContainer>
        );
      }

      return (
        <SelectReferenceMonth
          label="REF: MÊS/ANO"
          handleChange={handleDateChange}
          selectedDate={selectedDate}
        />
      );
    };

    return (
      <S.FilterWrappedContainer>
        <div>
          <S.FilterText>Filtro:</S.FilterText>
        </div>

        <div style={{ marginRight: '15px' }}>
          <IoseSelectFilter
            label="Selecione um filtro"
            handleChange={handleFilterChange}
            selectedOption={selectedFilter}
            options={filterOptions}
          />
        </div>

        <div>{renderDynamicFilters()}</div>

        <div></div>

        <div>
          {/* <IoseButton
            onClick={() => getAuditData()}
            disabled={!isFilterEnabled}
          >
            EMITIR RELATÓRIO
          </IoseButton> */}

          <IoseButton2
              onClick={() => getAuditData()}
              width="80px"
              disabled={!isFilterEnabled}
            >
              <span style={{ marginRight: '5px' }}>PDF</span>
              <GetAppIcon />
          </IoseButton2>

          <IoseButton2
              onClick={() => getAuditXlsxData()}
              width="80px"
              disabled={!isFilterEnabled}
            >
              <span style={{ marginRight: '5px' }}>XLSX</span>
              <GetAppIcon />
          </IoseButton2>
        </div>
      </S.FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        {loading ? (
          <>
            <LoadingIndicator loading={loading} />
            <S.StyledLoadingMessage>
              Carregando dados e emitindo o relatório...
            </S.StyledLoadingMessage>
          </>
        ) : (
          <div>
            {dateError ? (
              <DateErrorMessage message="Ops! A data final não pode ser anterior a inicial." />
            ) : (
              <>
                {noDataError ? (
                  <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
                ) : (
                  <ReportMessage message="Para emitir o relatório, selecione os filtros desejados e clique em EMITIR RELATÓRIO." />
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderFilters()}
          {renderTableorImage()}
        </div>
      </>
    );
  };

  return (
    <S.WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReportPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão para emissão deste relatório!'
          }
        />
      )}
    </S.WrappedContainer>
  );
}
