import React from 'react';
import PropType from 'prop-types';

//Imports from Style
import { IoseTextFieldSidebarStyled } from './style';

/**
 * This Component is used to create a inputs with style Iose in component inside sidebar
 */
export default function IoseTextFieldSidebar(props) {
  const {
    id,
    label,
    name,
    autoComplete,
    type,
    placeholder,
    required,
    autofocus,
    defaultvalue,
    onchange,
    value,
    shrink,
    onblur,
    helpertext,
    theme,
    bottom,
    disabled,
  } = props;

  return (
    <IoseTextFieldSidebarStyled
      margin="normal"
      required={required}
      fullWidth
      id={id}
      label={label}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      type={type}
      autoFocus={autofocus === 'true' ? true : false}
      defaultValue={defaultvalue}
      onChange={onchange}
      value={value}
      InputLabelProps={{
        shrink: shrink,
      }}
      theme={theme}
      bottom={bottom}
      onBlur={onblur}
      helperText={helpertext}
      disabled={disabled}
      style={{ marginTop: '3px' }}
    />
  );
}

IoseTextFieldSidebar.propTypes = {
  /** This props get a id of input*/
  id: PropType.string,
  /** This props get a label of input*/
  label: PropType.string,
  /** This props get a name of input*/
  name: PropType.string,
  /** This props get if input will go auto complete the sentence*/
  autoComplete: PropType.string,
  /** This props get the type information will be in the input*/
  type: PropType.string,
  /** This props get the placeholder will be in the input*/
  placeholder: PropType.string,
  /** This props get input will be mandatory*/
  required: PropType.bool,
  /** This props implement autofocu in form */
  autofocus: PropType.string,
  /** This props get func to do anything  with taken*/
  onchange: PropType.func,
  /** This props get a value's input  */
  value: PropType.string,
  /** This props is the value that is loading and first loading input*/
  defaultvalue: PropType.oneOfType([PropType.string, PropType.number]),
  /** This props is a function that will be execute when an object loses focus.*/
  onBlur: PropType.func,
  /** This props shows the placeholder in the input (true: show | false: hide)   */
  shrink: PropType.bool,
  /** This props show a erro mensagem above the input */
  helpertext: PropType.string,
};
