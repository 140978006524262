import React from 'react';
import {
  StyledImage,
  StyledImageWrapper,
  StyledIoseErrorMessage,
  StyledWrapp,
} from './style';

export default function NoDataMessage({ message, height, width }) {
  return (
    <StyledWrapp>
      <StyledImageWrapper>
        <StyledImage height={height} width={width} />
      </StyledImageWrapper>
      <StyledIoseErrorMessage>{message}</StyledIoseErrorMessage>
    </StyledWrapp>
  );
}
