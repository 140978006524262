import styled from 'styled-components';
import { List, ListItem, ListItemText } from '@material-ui/core';

export const SummaryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ecf0f5;
  width: 100%;
  border-radius: 10px;
  overflow-y: scroll;
  margin: 5px 0 0 0;

  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const SummaryIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  transition: 0.3s ease;
  border-radius: 10px;

  @media (max-width: 1360px) {
    width: 25px;
    height: 25px;
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  font-size: 30px;
`;

export const ListStyled = styled(ListItem)`
  transition: 0.3s;
  margin-bottom: 35px;
  &:hover {
    background-color: #fff;
    border-radius: 10px;
    border-left: 2px solid var(--primary);

    ${SummaryIconWrapper} {
      width: 42px;
      height: 42px;
      background-color: var(--primary);
    }
  }

  @media (max-width: 1360px) {
    margin-bottom: 20px;

    &:hover {
      background-color: #fff;
      border-radius: 10px;
      border-left: 2px solid var(--primary);

      ${SummaryIconWrapper} {
        width: 35px;
        height: 35px;
        background-color: var(--primary);
      }
    }
  }
`;

export const ListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
