import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import {
  ButtonContainer,
  StyledTable,
  TableHeadStyled,
  TableRowStyled,
} from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2 } from 'components/IoseButton';
import energyBillRecalcReport from 'reports/EnergyBills/energyBillRecalcReport';

import * as XLSX from 'xlsx';
import { useCreateLogs } from 'hooks';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data?.map((obj) => {
    const { uuid_contract, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'concessionaire',
    'pdf_name',
    'reference_month',
    'bill_value',
    'recalc_value',
    'difference_value',
  ];

  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'vencimento') {
        reorderedObj[column] = transformDateString(obj[column]);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    concessionaire: 'Concessionária',
    pdf_name: 'PDF',
    reference_month: 'Mês de referência',
    bill_value: 'Valor da Fatura',
    recalc_value: 'Vslor do Recálculo',
    difference_value: 'Valor de Diferença',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object?.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function transformDateString(inputDate) {
  const parts = inputDate.split('T')[0].split('-');
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  return `${day}/${month}/${year}`;
}

function convertNumberToMonetaryStyle(number = 0) {
  // number = typeof number === 'string' && Number(number);

  const money = number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return money;
}

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <TableRowStyled
      align="center"
      onClick={() => onClick(value)}
      className={classes.tableRow}
    >
      <TableCellCentered>{value.identification_number}</TableCellCentered>
      <TableCellCentered>{value.concessionaire}</TableCellCentered>
      <TableCellCentered>{value.pdf_name}</TableCellCentered>
      <TableCellCentered>{value.reference_month}</TableCellCentered>
      <TableCellCentered>
        {convertNumberToMonetaryStyle(value.bill_value)}
      </TableCellCentered>{' '}
      <TableCellCentered>
        {convertNumberToMonetaryStyle(value.recalc_value)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToMonetaryStyle(value.difference_value)}
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>UC</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONCESSIONÁRIA</TableHeaderCellCentered>
        <TableHeaderCellCentered>PDF</TableHeaderCellCentered>
        <TableHeaderCellCentered>MÊS DE REFERÊNCIA</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR FATURA</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR RECÁLCULO</TableHeaderCellCentered>
        <TableHeaderCellCentered>DIFERENÇA VALOR</TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseEnergyBillListTable({
  loading,
  data,
  onClickFunction,
  hasPermission,
}) {
  const { createLog } = useCreateLogs();

  const handleDownloadButtonClick = async (data) => {
    exportToExcel(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO XLSX DE RECÁLCULO DE FATURAS';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  const handleReport = async (data) => {
    energyBillRecalcReport(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE RECÁLCULO DE FATURAS';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          {hasPermission ? (
            <ButtonContainer>
              <IoseButton2 onClick={() => handleDownloadButtonClick(data)}>
                Download XLSX
              </IoseButton2>
              <IoseButton2 onClick={() => handleReport(data)}>
                EMITIR RELATÓRIO
              </IoseButton2>
            </ButtonContainer>
          ) : null}
          <CircuitsTable
            data={data}
            id="scrollableDiv"
            onRowClick={onClickFunction}
          />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="400px"
          width="400px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={data?.length}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={0}
          padding={0}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={600}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
