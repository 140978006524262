import styled from 'styled-components';

export const NavButton = styled.button`
  width: 40px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #015A9E;
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    color: #015A9E;
  }

  &:hover {
    opacity: 40%;

    & svg {
      transform: scale(2);
      transition: transform 0.2s ease;
    }
  }
`;
