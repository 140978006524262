import React from "react";
import * as S from '../style';

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalBillTable({ currentEnergyBill }) {
  if (!currentEnergyBill || currentEnergyBill.length === 0) return null;

  const energyBill = currentEnergyBill[0];

  // Função para obter o valor da chave correspondente
  const getValue = (keyPrefix, col) => {
    const key = `${keyPrefix}${col}`;
    return energyBill[key] !== undefined ? energyBill[key] : '0';
  };

  return (
    <S.TableContainer>
      <S.TableTitleContainer>
        <S.TableTitle>ITENS DE FATURA </S.TableTitle>
      </S.TableTitleContainer>
      <S.Table>
        <S.TableHeader>
          <tr>
            <S.TableHeaderCell>ITENS</S.TableHeaderCell>
            <S.TableHeaderCell>UNID.</S.TableHeaderCell>
            <S.TableHeaderCell>QTD. FATURADA</S.TableHeaderCell>
            <S.TableHeaderCell>TARIFA COM TRIBUTOS</S.TableHeaderCell>
            <S.TableHeaderCell>VALOR (R$)</S.TableHeaderCell>
            <S.TableHeaderCell>BASE CÁLCULO PIS/COFINS</S.TableHeaderCell>
            <S.TableHeaderCell>BASE CALC. ICMS</S.TableHeaderCell>
            <S.TableHeaderCell>TARIFA UNITÁRIA</S.TableHeaderCell>
          </tr>
        </S.TableHeader>
        <tbody>

          {Array.from({ length: 17 }, (_, i) => i + 1).map((col) => (
            <S.TableExtractRow key={col}>
              <S.TableCell>{getValue('item_description_', col)}</S.TableCell>
              <S.TableCell>{getValue('item_unity_', col)}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_consumption_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_unitary_value_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_total_value_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_piscofins_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_icms_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('item_tax_', col))}</S.TableCell>
            </S.TableExtractRow>
          ))}

        </tbody>
      </S.Table>
    </S.TableContainer>
  );
}
