import styled from 'styled-components';

import { TypographyGeneral } from 'components/IoseSubHeaderBar/style';

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--secondary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const TypographyData = styled(TypographyGeneral)`
  color: var(--secondary);
  font-size: 15px;
  font-weight: bold;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const TypographyAdress = styled(TypographyGeneral)`
  color: var(--secondary);
  font-size: 13px;
  padding: 10px;
  font-weight: bold;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const Container = styled.div`
  height: auto;
  width: 96vw;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  gap: 15px;
  display: flex;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const Header = styled.div`
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px 5px 0 0;
  padding: 5px;
`;

export const HeaderData = styled.div`
  background-color: var(--base);
  display: flex;
  align-items: stretch; /* Alterado */
  justify-content: space-around;
  border-radius: 0 0 5px 5px;
  height: 80px; /* Alterado */
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
