import React, { useEffect, useState } from 'react';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IoseCloseModalButton } from 'components/IoseButtonIcon';
import * as S from './style';
import { LocalStorage, useClient } from 'common';
import BillsModalRightSidebar from './components/bills-modal-right-sidebar.js.js';
import BillsModalExtractContent from './components/bills-modal-extract-content';
import BillsModalBillContent from './components/bills-modal-bill-content';
import BillsModalAclContent from './components/bills-modal-acl-content';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: '16px 50px 24px 50px',
    borderRadius: '10px',
    backgroundColor: '#ECF0F5',
    height: '85vh',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

function filterExtracts(data) {
  // Verifica se `monthData` e `monthData[0]` estão definidos
  if (!data || !data.result || !data.result[0]) {
    return [];
  }

  const extracts = data.extracts;
  const mesRef = data.result[0].reference_month;

  return extracts?.filter(
    (extract) => extract.reference_month === mesRef
  );
}

function filterEnergyBills(data) {
  if (!data || !data.result || !data.result[0]) {
    return [];
  }

  const bills = data?.bills;
  const mesRef = data.result[0].reference_month;
  const previousReading = data.result[0].previous_reading;

  return bills?.filter(bill =>
    bill?.reference_month === mesRef &&
    bill?.previous_reading === previousReading
  );
}

function filterFaturasAcl(data) {
  if (!data || !data.result || !data.result[0]) {
    return [];
  }

  const faturasAcl = data.faturasAcl;
  const mesRef = data.result[0].reference_month;

  return faturasAcl?.filter(
    (bill) => bill?.reference_month === mesRef
  )
}

export default function IoseBillsAndAclModal({
  open,
  onClose,
  data,
}) {
  const classes = useStyles();
  const { client } = useClient();

  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  //eslint-disable-next-line
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [visualization, setVisualization] = useState('bill')
  const [monthExtracts, setMonthExtracts] = useState();
  const [monthEnergyBills, setMonthEnergyBills] = useState();
  const [monthAcl, setMonthAcl] = useState();

  useEffect(() => {

    setMonthExtracts(filterExtracts(data))
  }, [data]);

  useEffect(() => {
    setMonthEnergyBills(filterEnergyBills(data))
  }, [data]);

  useEffect(() => {

    setMonthAcl(filterFaturasAcl(data))
  }, [data]);


  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientData = async () => {
    setError('');
    // setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderBillContent = () => {
    return (
      <BillsModalBillContent
        energyBills={monthEnergyBills}
        linksACL={data}
      />
    )
  }

  const renderExtractContent = () => {
    return (
      <BillsModalExtractContent
        extracts={monthExtracts}
      />
    )
  }

  const renderAclContent = () => {
    return (
      <BillsModalAclContent
        faturasAcl={monthAcl}
      />
    )
  }

  const renderContent = () => {
    switch (visualization) {
      case 'bill':
        return renderBillContent();
      case 'extract':
        return renderExtractContent();
      case 'acl':
        return renderAclContent();
      default:
        return renderBillContent();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <S.ModalContainer>
          <S.StyledHeaderModal>
            <S.RoomIcon />

            <S.ModalTitleHeader variant="h2" gutterBottom>
              CONTAS DE ENERGIA DA UNIDADE CONSUMIDORA
            </S.ModalTitleHeader>

            <S.ButtonsContainer>
              <IoseCloseModalButton
                tooltip="FECHAR MODAL"
                bottom="5px"
                onClick={() => onClose()}
                left="150px"
              />
            </S.ButtonsContainer>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <>
                {renderContent()}
              </>
            </div>
            <BillsModalRightSidebar
              visualization={visualization}
              setVisualization={setVisualization}
            />
          </S.StyledWrappedModal>
        </S.ModalContainer>
      </Grow>
    </Modal>
  );
}
