import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

import {
  Container,
  Header,
  HeaderItem,
  TypographyTitle,
  WrappedContainer,
} from './style';

//Import from IOSE
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';

import {
  IoseAddButton,
  IoseAuditRedirectButton,
  IoseDownloadButton,
  IoseEditBillButton,
  IoseMapRedirectButton,
} from 'components/IoseButtonIcon';
import { IoseAlertLoadingCards } from 'components/IoseAlert';

//Imports from common
import { useClient } from 'common/contexts/client';
import LocalStorage from 'common/local_storage_api';

//Import from Material UI
import { Grow } from '@material-ui/core/';

//Import icons
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { LoadingIndicator } from 'components/IoseLoader';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from 'components/IoseNewTextField';

import BillDataTable from 'components/IoseTables';
import IoseNavButton from 'components/IoseNavButton';

export default function EnergyBillsAuditContainer() {
  const [billsArray, setBillsArray] = useState([]);
  const [clientData, setClientData] = useState({});
  const [currentBill, setCurrentBill] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const { client } = useClient();
  const { state } = useLocation();

  useEffect(() => {
    const billsArray = state;
    setBillsArray(billsArray);

    if (billsArray.length > 0) {
      const energyBills = billsArray[0].energyBills;
      const selectedIndex = energyBills.findIndex(
        (bill) => bill.table_data.length > 0
      );

      if (selectedIndex >= 0) {
        setCurrentIndex(selectedIndex);
        setCurrentBill(energyBills[selectedIndex]);
      } else {
        setCurrentBill(null);
      }
    }
  }, [state]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const linkCompleto = currentBill?.link_completo;

    if (linkCompleto) {
      const modifiedLink = linkCompleto.replace('faturas-san/', '');

      const linkParts = modifiedLink?.split('/');

      if (linkParts?.length >= 2) {
        const objectKey = linkParts?.join('/');

        const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

        const fullS3Url = `${s3BaseUrl}${objectKey}`;

        setDownloadLink(fullS3Url);
      } else {
        console.error('Link de PDF inválido.');
      }
    } else {
      console.error('Link de PDF inválido.');
    }
  }, [currentBill]);

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  const history = useHistory();

  function redirectToMapPage() {
    history.push('/map');
  }

  function redirectToEditBillsPage() {
    history.push({
      pathname: '/edit-bills-measurer',
      state: currentBill,
    });
  }

  function redirectToConsumerGroupPage() {
    history.goBack();
  }

  const handleDownload = (downloadLink) => {
    if (downloadLink) {
      window.open(downloadLink, '_blank');
    } else {
      console.error('Link de download não disponível.');
    }
  };

  const nextBillNavigation = () => {
    if (currentIndex < billsArray[0].energyBills.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentBill(billsArray[0].energyBills[currentIndex + 1]);
    }

    setIsPrevButtonDisabled(false);

    setIsNextButtonDisabled(
      currentIndex + 1 === billsArray[0].energyBills.length - 1
    );
  };

  const prevBillNavigation = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentBill(billsArray[0].energyBills[currentIndex - 1]);
    }

    setIsNextButtonDisabled(false);

    setIsPrevButtonDisabled(currentIndex - 1 === 0);
  };

  const getClientData = async () => {
    setError('');
    // setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle={`Contas de energia da Unidade Consumidora `}
        // description={'Total: ' + allContracts.length}
        button={showButton ? button : <></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseAuditRedirectButton
            tooltip="RETORNAR PARA UNIDADE CONSUMIDORA"
            bottom="5px"
            onClick={() => redirectToConsumerGroupPage()}
          />

          <IoseDownloadButton
            tooltip="BAIXAR FATURA"
            bottom="5px"
            onClick={() => handleDownload(downloadLink)}
          />

          <IoseEditBillButton
            tooltip="EDITAR DADOS DO MEDIDOR DA FATURA"
            bottom="5px"
            onClick={() => redirectToEditBillsPage()}
          />

          <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <Container>
          <IoseNavButton
            icon={<NavigateBeforeIcon />}
            tooltip="ANTERIOR"
            onClick={() => prevBillNavigation()}
            disabled={isPrevButtonDisabled}
          />
          <div style={{ width: '96vw' }}>
            <div>
              <Header>
                <HeaderItem>
                  <TypographyTitle>DADOS DA CONTA DE ENERGIA</TypographyTitle>
                </HeaderItem>
              </Header>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <TextField label="Endereço" value={currentBill?.bill_name} />
                </div>

                <div style={{ width: '50%' }}>
                  <TextField
                    label="Classificação"
                    value={currentBill?.classification}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <TextField
                label="Unidade Consumidora"
                value={currentBill?.identification_number}
              />

              <TextField
                label="Mês de vigência"
                value={currentBill?.reference_month}
              />

              <TextField
                label="Leitura Anterior"
                value={currentBill?.previous_reading}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <TextField
                label="Dias medidos"
                value={currentBill?.number_days}
              />

              <TextField label="Fator multiplicador" value="1,00" />

              <TextField
                label="Leitura atual"
                value={currentBill?.current_reading}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <TextField
                label="Número do medidor"
                value={currentBill?.measurer}
              />

              <TextField
                label="Total a pagar"
                value={convertNumberToDecimalStyle(currentBill?.account_value)}
              />
            </div>

            <div>
              <BillDataTable data={currentBill?.table_data} />
            </div>
          </div>
          <IoseNavButton
            icon={<NavigateNextIcon />}
            tooltip="PRÓXIMO"
            onClick={() => {
              nextBillNavigation();
            }}
            disabled={isNextButtonDisabled}
          />
        </Container>
      );
    }
  };

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>{renderContent()}</>
        )}
      </WrappedContainer>
    </Grow>
  );
}

EnergyBillsAuditContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
