import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SaneparCreditBeneficiaryFinalTable } from 'components/IoseTables';
import {
  useBeneficiaryPerMonth,
  useCreateLogs,
  useBeneficiaryJoin,
} from 'hooks';
import { IoseButton } from 'components';
import energyBillBeneficiaryMonthReport from 'reports/EnergyBills/energyBillBeneficiaryMonthReport';
import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';

import * as S from './style';

const useStyles = makeStyles((theme) => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  // paper: {
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  //   borderRadius: '10px',
  //   backgroundColor: '#015A9E',
  //   color: 'white',
  //   width: '1200px',
  //   height: '480px',
  //   marginTop: '15px',
  // },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    // marginTop: '4px',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],

    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function IoseCircuitChartSanepar({
  open,
  onClose,
  selectedCard,
  chartMode,
  setChartMode,
  hasReportPermission,
}) {
  const classes = useStyles();
  const { getBeneficiaryPerMonthData } = useBeneficiaryPerMonth();
  const { createLog } = useCreateLogs();

  const { BeneficiaryJoinData } = useBeneficiaryJoin();

  const creditoBeneficiario = getBeneficiaryPerMonthData
    ? getBeneficiaryPerMonthData
    : [];

  const cardSelecionado = selectedCard?.uc_geradora;

  const filteredBeneficiario = creditoBeneficiario?.filter(
    (item) =>
      item.uc_geradora === selectedCard?.uc_geradora &&
      (item.mes_cred_gerado === selectedCard?.mes_ref ||
        item.mes_cred_utilizado === selectedCard?.mes_ref) &&
      item.uc_geradora !== item.uc_beneficiaria
  );

  const sortedBeneficiario = filteredBeneficiario?.slice().sort((a, b) => {
    const dateA = new Date(a.mes_cred_gerado);
    const dateB = new Date(b.mes_cred_gerado);
    return dateA - dateB;
  });

  const compensadoValues = BeneficiaryJoinData?.reduce((acc, item) => {
    const matchingBeneficiario = filteredBeneficiario.find(
      (beneficiario) =>
        beneficiario.uc_geradora === item.uc_geradora &&
        beneficiario.mes_cred_gerado === item.mes_referencia
    );

    if (matchingBeneficiario) {
      acc[item.uc_geradora] = item.compensado;
    }

    return acc;
  }, {});

  // Adicionando o valor de compensado aos objetos em sortedBeneficiario
  const benefWithCompensado = sortedBeneficiario?.map((item) => ({
    ...item,
    compensado: compensadoValues[item.uc_geradora] || 0,
  }));

  const mes = sortedBeneficiario?.map((item) => item.mes_cred_utilizado);

  const isReportButtonDisabled = sortedBeneficiario?.length === 0;

  const renderTableorImage = () => {
    return (
      <div className={classes.tableContainer} style={{ position: 'relative' }}>
        <SaneparCreditBeneficiaryFinalTable
          data={sortedBeneficiario}
          selectedCard={selectedCard}
        />
        {/* <IoseButton
          style={{
            position: 'absolute',
            top: 5,
            right: 19,
            zIndex: 5,
            margin: '2px',
            border: '1px solid white',
            fontSize: '1px',
          }}
          onClick={() => energyBillBeneficiaryMonthReport(sortedBeneficiario)}
          disabled={isReportButtonDisabled}
        >
          EMITIR RELATÓRIO
        </IoseButton> */}
      </div>
    );
  };

  const handleBillBeneficiaryMonthReport = async () => {
    energyBillBeneficiaryMonthReport(benefWithCompensado);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CRÉDITOS ACUMULADOS ';
    const logContent = {};

    await createLog(operacao, form, logContent);

    return;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <BatteryChargingFullIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {mes.length > 0
                  ? `Créditos acumulados da unidade ${cardSelecionado} no mês ${mes[0]}`
                  : `Créditos acumulados da unidade ${cardSelecionado}`}
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
            {hasReportPermission ? (
              <IoseButton
                style={{
                  position: 'relative ',
                  right: 9,
                  display: 'inline-block',
                  margin: '2px',
                  width: 'auto',
                  height: 'auto',
                }}
                onClick={() =>
                  handleBillBeneficiaryMonthReport(sortedBeneficiario)
                }
                disabled={isReportButtonDisabled}
              >
                EMITIR&nbsp;RELATÓRIO&nbsp;&nbsp;&nbsp;
              </IoseButton>
            ) : null}
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom></S.ModalTitle>
            {renderTableorImage()}
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
