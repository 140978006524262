import styled from 'styled-components';
import { Avatar, Card, Grid } from '@material-ui/core';
import Consumption_energy_icon from './img/consumption_energy_icon.svg';
import Generation_energy_icon from './img/generation_energy_icon.svg';

export const Container = styled(Card)`
  padding: 5%;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  @media (min-width: 960px) {
    padding: 2%;
  }
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  direction: 'row',
  justify: props.justify,
  alignItems: 'center',
  spacing: 2,
}))`
  margin-top: ${(props) => props.margintop};
  margin-bottom: ${(props) => props.marginbottom};
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 4,
  md: 2,
}))`
  display: flex;
  flex-direction: row;

  h4 {
    font-size: 11px;
  }
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: ${(props) => props.color};

  margin-right: 5px;
  width: 30px;
  height: 30px;
  border: solid 2px var(--primary);
  color: var(--secondary);
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const IconImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;

  h3 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  p {
    margin-left: 25px;
    font-size: 0.9rem;

    @media (max-width: 767px) {
      margin-left: 0px;
      padding-top: 10px;
    }
  }
`;

export const ConsumptionEnergyIcon = styled.img.attrs((props) => ({
  src: Consumption_energy_icon,
}))`
  width: 20px;
  height: 20px;
`;

export const GenerationEnergyIcon = styled.img.attrs((props) => ({
  src: Generation_energy_icon,
}))`
  width: 20px;
  height: 20px;
`;
