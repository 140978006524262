import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import { useHistory, useLocation } from 'react-router-dom';
import PropType from 'prop-types';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  IoseAddButton,
  IoseMapRedirectButton,
  IoseBarChartConsumerGroup,
  IoseNoDataMessage,
  IoseButton2,
  LoadingIndicator,
  TextField,
  IoseBillsAuditModal,
  IoseButton,
  IoseSelection,
  IoseChartsFaturasTotais,
} from 'components';

import {
  useContracts,
  useConsumerGroupBills,
  useConsumerGroupData,
  useConsumerGroupFaturas,
  useBillItems,
  useExtractsByIdentificationNumber,
  useAclByIdentificationNumber,
  useCreateLogs,
  useNewTariffs,
} from 'hooks';

import { useClient, LocalStorage, AccountContext, ioseAPI } from 'common';

import {
  energyBillByConsumerGroupReport,
  TelemetryAlarmsReport,
} from 'reports';
import {
  IoseConsumerGroupRedirectButton,
  IoseMapTelemetryRedirectButton,
  IoseRedirectBillsaDashButton,
} from 'components/IoseButtonIcon';
import TelemetryMeasuresTable from 'components/Tables/TelemetryMeasuresTable';
import TelemetryAlarmsTable from 'components/Tables/TelemetryAlarmsTable';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import { ioseIotAPI } from 'common/ioseIotAPI';
import TelemetryMeasuresReport from 'reports/Iot/TelemetryMeasuresReport';
import GraficoConsumo30Dias from './components/GraficoConsumo30Dias';
import TarifasTable from 'components/Tables/TarifasTable';
import TarifasFooterTable from 'components/Tables/tarfiasTableFooter';
import GraficoFatorPotenciaQ1 from './components/GraficoFatorPotenciaQ1';
import GraficoAlarmesNoPeriodo from './components/GraficoAlarmesNoPeriodo';
import GraficoFatorPotenciaPorPacote from './components/GraficoFatorPotenciaPorPacote';
import TabelaDeFaturas from './components/TabelaDeFaturas';
import { GraficoDemandaRf12 } from './components/GraficoDemandaRf12';
import { xlsxReports } from 'reports/Xlsx';
import { GraficoDesperdicios } from './components/GraficoDesperdicios';
import GraficoQuatroQuadrantes from './components/GraficoQuatroQuadrantes';
import GraficoQuatroQuadrantesDemanda from './components/GraficoQuatroQuadrantesDemanda';
import GraficoFatorPotenciaPorPacoteQ4 from './components/GraficoFatorPotenciaPorPacoteQ4';
import RightSidebar from './components/RightSidebar';
import useFormatBillData from './hooks/useFormatBillData';
import { GraficoCreditoEnergia } from './components/GraficoCreditoEnergia';
import GraficoConsumo30DiasQ4 from './components/GraficoConsumo30DiasQ4';
import GraficoFatorPotenciaQ4 from './components/GraficoFatorPotenciaQ4';

//Auxiliar functions
function transformContracts(contracts) {
  const today = moment().startOf('day');

  const formattedContracts = contracts?.map((contract) => {
    const finalDate = moment(contract.final_date).startOf('day');
    const initialDate = moment(contract.initial_date).startOf('day');

    const formattedFinalDate = finalDate.format('DD/MM/YYYY');
    const formattedInitialDate = initialDate.format('DD/MM/YYYY');

    const daysDiff = finalDate.diff(today, 'days');

    return {
      ...contract,
      expires_in: daysDiff,
      final_date: formattedFinalDate,
      initial_date: formattedInitialDate,
    };
  });

  return formattedContracts;
}

function formatEnergyBills(bills) {
  const updatedList = bills?.map((obj) => {
    const referenceMonth = obj.reference_month;
    const referenceParts = referenceMonth.split('/');
    let monthYear;

    if (referenceParts.length === 2) {
      monthYear = referenceMonth;
    } else {
      monthYear = referenceParts[1] + '/' + referenceParts[2];
    }

    return { ...obj, month_year: monthYear };
  });

  return updatedList;
}

function filterContracts(filteredConsumerGroup, contracts, allContracts) {
  const contract = allContracts?.find(
    (contract) =>
      contract.contract_number === filteredConsumerGroup?.contract_number
  );

  return contract || null;
}

function mergeObjects(faturas, completeBillData) {
  // Mapeia as faturas e completa os dados
  const mergedData = faturas?.map((fatura) => {
    const completeData = completeBillData.filter(
      (bill) =>
        bill.reference_month === fatura.mes_referencia &&
        bill.previous_reading === fatura.leitura_anterior
    );

    if (completeData) {
      const energyBills = completeData?.map((billItem) => ({
        ...billItem,
        link_completo: `${fatura.link_pdf}${billItem.pdf_name}`,
        link_extrato_pdf: `${fatura.link_extrato_pdf}`,
        link_acl_boleto: `${fatura.link_acl_boleto}`,
        link_acl_danfe: `${fatura.link_acl_danfe}`,
      }));

      return {
        ...fatura,
        energyBills,
      };
    }

    return fatura;
  });

  // Filtra itens duplicados
  const uniqueItems = mergedData?.reduce((acc, current) => {
    const x = acc.find(
      (item) =>
        item.unidade_consumidora === current.unidade_consumidora &&
        item.mes_referencia === current.mes_referencia &&
        item.total === current.total
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  // Ordena os itens do mês mais atual para o mais antigo
  uniqueItems?.sort((a, b) => {
    const [mesA, anoA] = a.mes_referencia.split('/').map(Number);
    const [mesB, anoB] = b.mes_referencia.split('/').map(Number);

    if (anoA !== anoB) {
      return anoB - anoA;
    } else {
      return mesB - mesA;
    }
  });

  return uniqueItems;
}

const formatToLocalISOString = (date) => {
  const pad = (num) => String(num).padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export default function ConsumerGroupDashContainer() {
  const [allContracts, setAllContracts] = useState([]);
  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [completeBillData, setCompleteBillData] = useState([]);
  const [consumptionByMonth, setConsumptionByMonth] = useState([]);
  const [currentBillData, setCurrentBillData] = useState([]);
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [filteredContract, setFilteredContract] = useState('');
  const [filteredConsumerGroup, setFilteredConsumerGroup] = useState('');
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [mapConsumerGroup, setMapConsumerGroup] = useState(null);
  const [mercadoLivreconsumerGroup, setMercadoLivreconsumerGroup] = useState(
    null
  );
  const [mapUuidCircuit, setMapUuidCircuit] = useState(null);
  const [faturasCompletas, setFaturasCompletas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [filteredTelemetryAlarms, setFilteredTelemetryAlarms] = useState(null);

  //Filter States
  const initalDates = new Date();
  const pastDate = new Date();
  pastDate.setDate(initalDates.getDate() - 30);

  const [initialDate, setInitialDate] = useState(pastDate);
  const [finalDate, setFinalDate] = useState(initalDates);
  const [initialAlarmDate, setInitialAlarmDate] = useState(pastDate);
  const [finalAlarmDate, setFinalAlarmDate] = useState(initalDates);
  const [alarmType, setAlarmType] = useState(`Todos`);
  const [initialDateConsumo, setInitialDateConsumo] = useState(initalDates);
  const [consumos, setConsumos] = useState([]);
  const [telemetryMeasures, setTelemetryMeasures] = useState([]);
  const [consumosDiarios, setConsumosDiarios] = useState([]);
  const [inicialDataConsumo, setInicialDataConsumo] = useState(null);
  const [finalDataConsumo, setFinalDataConsumo] = useState(initalDates);
  const [consumptionAggregation, setConsumptionAggregation] = useState();
  const [erroPeriodo, setErroPeriodo] = useState(null);
  const [visualization, setVisualization] = useState('all');

  const history = useHistory();
  const { client } = useClient();
  const { state } = useLocation();
  const { contracts } = useContracts();
  const { consumerGroup } = useConsumerGroupData(mapConsumerGroup);
  const { bills, refreshBills } = useConsumerGroupBills(mapConsumerGroup);
  const { faturas, refreshFaturas } = useConsumerGroupFaturas(mapConsumerGroup);
  const { getSession } = useContext(AccountContext);
  const { headers } = getSession();
  const { billItems } = useBillItems(mapConsumerGroup);
  const { extracts } = useExtractsByIdentificationNumber(mapConsumerGroup);
  const { faturasAcl } = useAclByIdentificationNumber(mapConsumerGroup);
  const formatBillData = useFormatBillData();
  const { createLog } = useCreateLogs();
  const { tarifas } = useNewTariffs();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, [mapConsumerGroup]);

  const obterMedicoesTelemetria = async (
    uuid_circuit,
    start_date,
    end_date
  ) => {
    const initialDateObj = new Date(start_date);
    const finalDateObj = new Date(end_date);

    initialDateObj.setUTCHours(0, 0, 0, 0);
    finalDateObj.setUTCHours(23, 59, 59, 999);

    const initialDateFormatted = initialDateObj.toISOString();
    const finalDateFormatted = finalDateObj.toISOString();

    try {
      const { data } = await ioseAPI.obterConsumoMedicaoTelemetria(
        headers,
        uuid_circuit,
        initialDateFormatted,
        finalDateFormatted
      );

      setTelemetryMeasures(data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const getTelemetryAlarms = async (
    uuid_circuit,
    start_date,
    end_date,
    alarm_type
  ) => {
    const initialDateObj = new Date(start_date);
    const finalDateObj = new Date(end_date);

    initialDateObj.setUTCHours(0, 0, 0, 0);
    finalDateObj.setUTCHours(23, 59, 59, 999);

    const initialDateFormatted = initialDateObj.toISOString();
    const finalDateFormatted = finalDateObj.toISOString();

    try {
      const response = await ioseIotAPI.getAllAlarmsTelemetry(
        headers,
        uuid_circuit,
        initialDateFormatted,
        finalDateFormatted,
        alarm_type
      );

      setFilteredTelemetryAlarms(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const obterConsumoTelemetria = async (uuid_circuit, day) => {
    const dataAtual = new Date(day);

    const inicioDia = new Date(dataAtual);
    inicioDia.setHours(0, 0, 0, 0);

    const fimDia = new Date(dataAtual);
    fimDia.setHours(23, 59, 59, 999);

    const inicioDiaISO = formatToLocalISOString(inicioDia);

    const fimDiaISO = formatToLocalISOString(fimDia);

    try {
      const { data } = await ioseAPI.obterConsumoMedicaoTelemetria(
        headers,
        uuid_circuit,
        inicioDiaISO,
        fimDiaISO
      );

      setConsumos(data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const obterConsumoGraf30Dias = async (
    numero_telemetria,
    data_inicial,
    data_final
  ) => {
    const dataInicial = new Date(data_inicial);
    const dataFinal = new Date(data_final);

    dataInicial.setHours(0, 0, 0, 0);
    dataFinal.setHours(0, 0, 0, 0);

    // Verifica se o período selecionado é maior que 30 dias
    const diferencaEmDias = Math.ceil(
      (dataFinal - dataInicial) / (1000 * 60 * 60 * 24)
    );
    if (diferencaEmDias > 30) {
      setErroPeriodo('O período selecionado não pode ser maior que 30 dias.');
      return;
    }

    const diaInicial = new Date(dataInicial);
    diaInicial.setUTCHours(0, 0, 0, 0);

    const diaFinal = new Date(dataFinal);
    diaFinal.setUTCHours(23, 59, 59, 999);

    const diaInicialISO = diaInicial.toISOString();
    const diaFinalISO = diaFinal.toISOString();

    try {
      const { data } = await ioseAPI.obterConsumoMedicoesGraf30Telemetria(
        headers,
        numero_telemetria,
        diaInicialISO,
        diaFinalISO
      );

      setConsumosDiarios(data.data);
      setErroPeriodo(null);
    } catch (error) {
      // console.log(error);
    }
  };

  async function getConsumptionAggregation(identification_number) {
    let aggregatedData = [];
    let lastEvaluatedKey = null;
    let hasNextPage = true;
    setLoading(true);

    try {
      const sessionData = await getSession();

      do {
        const response = await ioseAPI.getDataAggregationByIdentificationNumber(
          sessionData.headers,
          identification_number,
          lastEvaluatedKey
        );

        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

        aggregatedData = [...aggregatedData, ...data];

        if (newLastEvaluatedKey) {
          lastEvaluatedKey = newLastEvaluatedKey;
        } else {
          hasNextPage = false;
        }
      } while (hasNextPage);

      setConsumptionAggregation(aggregatedData);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getConsumptionAggregation(mapConsumerGroup);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapConsumerGroup]);

  useEffect(() => {
    if (consumerGroup?.length > 0) {
      setFilteredConsumerGroup(consumerGroup[0]);
    }
  }, [consumerGroup]);

  useEffect(() => {
    const mapConsumerGroup = state?.consumerGroup;
    const mapCircuit = state?.uuidCircuit;

    setMapConsumerGroup(mapConsumerGroup);
    setMapUuidCircuit(mapCircuit);
  }, [state]);

  useEffect(() => {
    const mercadolivreConsumerGroup = state?.identificationNumber;

    setMercadoLivreconsumerGroup(mercadolivreConsumerGroup);
  }, [state]);

  useEffect(() => {
    const faturasAtualizadas = mergeObjects(faturas, completeBillData);

    setFaturasCompletas(faturasAtualizadas);
  }, [faturas, completeBillData, bills]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredContract = filterContracts(
      filteredConsumerGroup,
      contracts,
      allContracts
    );

    setFilteredContract(filteredContract);
  }, [filteredConsumerGroup, contracts, allContracts]);

  useEffect(() => {
    calculateConsumptionByMonth(currentBillData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBillData]);

  useEffect(() => {
    const formattedBills = formatBillData(bills);
    setCompleteBillData(formattedBills);
  }, [bills, formatBillData]);

  useEffect(() => {
    const transformedContracts = transformContracts(contracts);
    setAllContracts(transformedContracts);
  }, [contracts]);

  useEffect(() => {
    const transformedBills = formatEnergyBills(bills);
    setCurrentBillData(transformedBills);
  }, [bills]);

  useEffect(() => {
    const buscarDadosTelemetria = async () => {
      // setLoading(true)
      try {
        await Promise.all([
          obterConsumoTelemetria(mapUuidCircuit, initialDateConsumo),
          obterMedicoesTelemetria(mapUuidCircuit, initialDate, finalDate),
          getTelemetryAlarms(mapUuidCircuit, initialDate, finalDate, 'Todos'),
        ]);

        // setLoading(false)
      } catch (error) {
        // console.log('Erro ao obter dados:', error);
        // setLoading(false)
      }
    };

    buscarDadosTelemetria();

    //eslint-disable-next-line
  }, [mapUuidCircuit, initialDateConsumo]);

  useEffect(() => {
    obterConsumoGraf30Dias(
      mapUuidCircuit,
      inicialDataConsumo,
      finalDataConsumo
    );
    //eslint-disable-next-line
  }, [mapUuidCircuit, inicialDataConsumo]);

  useEffect(() => {
    const hoje = new Date();

    const trintaDiasAtras = new Date();
    trintaDiasAtras.setDate(hoje.getDate() - 30);
    setInicialDataConsumo(trintaDiasAtras);
  }, []);

  const openAuditModal = (rowData) => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setSelectedRowData(rowData);
      setOpenModal(true);
    }
  };

  function redirectToMapPage() {
    history.push('/map');
  }
  function redirectToMapTelemetryPage() {
    history.push('/map-telemetry');
  }
  function redirectToConsumerGroupPage() {
    history.push('/consumer-group');
  }
  function redirectToEnergyBillsDashPage() {
    history.push('/energy-bills');
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  function calculateConsumptionByMonth(currentBillData) {
    const consumptionByMonth = {};

    currentBillData
      ?.filter((obj) => obj.identification_number === mapConsumerGroup)
      ?.forEach((obj) => {
        const monthYear = obj.month_year;

        if (!consumptionByMonth.hasOwnProperty(monthYear)) {
          consumptionByMonth[monthYear] = 0;
        }

        for (let i = 1; i <= 12; i++) {
          consumptionByMonth[monthYear] += obj[`kwh_consumption_${i}`] || 0;
        }
      });

    const sortedDates = Object.keys(consumptionByMonth).sort((a, b) => {
      const [monthA, yearA] = a.split('/');
      const [monthB, yearB] = b.split('/');

      const dateA = new Date(yearA, monthA - 1);
      const dateB = new Date(yearB, monthB - 1);

      return dateA - dateB;
    });

    const sortedConsumption = sortedDates.map(
      (date) => consumptionByMonth[date]
    );

    const result = {
      date: sortedDates,
      consumption_kwh: sortedConsumption,
    };

    setConsumptionByMonth(result);
  }

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={`Dashboard da Unidade Consumidora ${mapConsumerGroup}`}
        // description={'Total: ' + allContracts.length}
        button={showButton ? button : <></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseRedirectBillsaDashButton
            tooltip="RETORNAR PARA DASHBOARD DE FATURAS"
            bottom="5px"
            onClick={() => redirectToEnergyBillsDashPage()}
          />
          <IoseConsumerGroupRedirectButton
            tooltip="RETORNAR PARA CADASTRO DA UC"
            bottom="5px"
            onClick={() => redirectToConsumerGroupPage()}
          />
          <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
          <IoseMapTelemetryRedirectButton
            tooltip="RETORNAR PARA O MAPA DE TELEMETRIA"
            bottom="5px"
            onClick={() => redirectToMapTelemetryPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  function renderTarifaOpcaoTableOrErrorMessage() {
    if (
      filteredConsumerGroup.subgrupo === 'B3' ||
      filteredConsumerGroup.concessionaria === 'ENERGISA' ||
      !tarifas ||
      !completeBillData ||
      tarifas?.length === 0 ||
      !filteredConsumerGroup ||
      completeBillData?.length === 0 ||
      filteredConsumerGroup?.length === 0 ||
      billItems?.length === 0 ||
      !billItems
    ) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que Parece que não há dados suficientes para exibir a tabela!"
          height="250px"
          width="250px"
        />
      );
    } else {
      return (
        <TarifasTable
          data={{
            'itens': billItems,
            'tarifas': tarifas,
            'faturas': completeBillData,
            'modalidade': filteredConsumerGroup.modalidade,
            'subgrupo': filteredConsumerGroup.subgrupo,
            'concessionaria': filteredConsumerGroup.concessionaire,
            'demandas': [
              filteredConsumerGroup.demanda,
              filteredConsumerGroup.demanda_fora,
              filteredConsumerGroup.demanda_ponta,
            ],
          }}
        />
      );
    }
  }

  function renderTarifaOpcaoRodapeOrErrorMessage() {
    if (
      filteredConsumerGroup.subgrupo === 'B3' ||
      filteredConsumerGroup.concessionaria === 'ENERGISA' ||
      !tarifas ||
      !completeBillData ||
      tarifas?.length === 0 ||
      !filteredConsumerGroup ||
      completeBillData?.length === 0 ||
      filteredConsumerGroup?.length === 0 ||
      billItems?.length === 0 ||
      !billItems
    ) {
      return <p />;
    } else {
      return (
        <TarifasFooterTable
          data={{
            'itens': billItems,
            'tarifas': tarifas,
            'faturas': completeBillData,
            'modalidade': filteredConsumerGroup.modalidade,
            'subgrupo': filteredConsumerGroup.subgrupo,
            'concessionaria': filteredConsumerGroup.concessionaire,
            'demandas': [
              filteredConsumerGroup.demanda,
              filteredConsumerGroup.demanda_fora,
              filteredConsumerGroup.demanda_ponta,
            ],
          }}
        />
      );
    }
  }

  const renderTarifaOpcaoTable = () => {
    return (
      <S.ContractDataContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              INDICADOR DE OPÇÃO TARIFÁRIA DE ENERGIA ELÉTRICA (ACR)
            </S.TypographyTitle>
          </div>
          <div style={{ textAlign: 'right', fontSize: '5px' }}>
            <p style={{ fontSize: '10px' }}>
              obs: todos os valores são calculados com base nos valores de
              consumos, demandas, etc, para aquele mês da fatura.
            </p>
            <p style={{ fontSize: '10px' }}>
              <span style={{ color: '#3da860', fontSize: '10px' }}>verde</span>:
              Valores calculados na tarifa verde atual.
            </p>
            <p style={{ fontSize: '10px' }}>
              <span style={{ color: '#3374b0', fontSize: '10px' }}>azul</span>:
              Valores calculados na tarifa azul atual.
            </p>
            <p style={{ fontSize: '10px' }}>
              <span style={{ color: '#dba156', fontSize: '10px' }}>
                laranja
              </span>
              : Valores presentes na fatura
            </p>
          </div>
        </S.HeaderContainer>
        {renderTarifaOpcaoTableOrErrorMessage()}
        {renderTarifaOpcaoRodapeOrErrorMessage()}
      </S.ContractDataContainer>
    );
  };

  function renderDashOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return <IoseBarChartConsumerGroup data={consumptionAggregation} />;
    }
  }
  function renderFaturasTotais() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return <IoseChartsFaturasTotais data={faturasCompletas} />;
    }
  }

  function renderDemandaFaturadaOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return (
        <GraficoDemandaRf12
          data={consumptionAggregation}
          unidadeConsumidora={consumerGroup}
        />
      );
    }
  }

  const renderDashboard = () => {
    return (
      <>
        <S.DemandaEConsumoContainer>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              GRÁFICO DE CONSUMO DA UNIDADE CONSUMIDORA (kWh)
            </S.TypographyTitle>
          </div>
          {renderDashOrErrorMessage()}
        </S.DemandaEConsumoContainer>
      </>
    );
  };
  const renderFaturas = () => {
    return (
      <>
        <S.DemandaEConsumoContainer>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>GRÁFICO TOTAL (R$) DE FATURAS</S.TypographyTitle>
          </div>
          {renderFaturasTotais()}
        </S.DemandaEConsumoContainer>
      </>
    );
  };

  function renderCreditoOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return <GraficoCreditoEnergia data={consumptionAggregation} />;
    }
  }

  const renderCreditoEnergia = () => {
    if (consumerGroup && consumerGroup[0]?.beneficiaria === 'NAO') {
      return null;
    } else {
      return (
        <S.DashboardContainer>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              GRÁFICO DE CRÉDITOS UTILIZADOS DA UNIDADE CONSUMIDORA.
            </S.TypographyTitle>
          </div>
          {renderCreditoOrErrorMessage()}
        </S.DashboardContainer>
      );
    }
  };

  const renderDemandaRf12 = () => {
    return (
      <>
        <S.DemandaEConsumoContainer>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              GRÁFICO DE DEMANDA DA UNIDADE CONSUMIDORA (kW)
            </S.TypographyTitle>
          </div>
          {renderDemandaFaturadaOrErrorMessage()}
        </S.DemandaEConsumoContainer>
      </>
    );
  };

  function renderDesperdiciosOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return (
        <GraficoDesperdicios
          data={consumptionAggregation}
          unidadeConsumidora={consumerGroup}
          loading={loading}
        />
      );
    }
  }

  const renderDesperdiciosRf12 = () => {
    return (
      <>
        <S.DashboardContainer>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              GRÁFICO DE DESPERDÍCIOS DA UNIDADE CONSUMIDORA (R$)
            </S.TypographyTitle>
          </div>
          {renderDesperdiciosOrErrorMessage()}
        </S.DashboardContainer>
      </>
    );
  };

  function renderTableOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return (
        <TabelaDeFaturas
          data={faturasCompletas}
          openModal={openModal}
          openAuditModal={openAuditModal}
        />
      );
    }
  }

  function renderMeasuresTableOrErrorMessage() {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    } else {
      return <TelemetryMeasuresTable data={telemetryMeasures} />;
    }
  }

  function renderAlarmsTableOrErrorMessage() {
    if (filteredTelemetryAlarms?.length === 0) {
      return (
        <div
          style={{
            minWidth: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IoseNoDataMessage
            message="Ops... sem dados suficientes para exibir a tabela!"
            height="200px"
            width="200px"
          />
        </div>
      );
    } else {
      return (
        <div style={{ width: '50%' }}>
          <TelemetryAlarmsTable data={filteredTelemetryAlarms} />
        </div>
      );
    }
  }

  function renderGraficoAlarmesPizza() {
    return (
      <div style={{ width: '50%' }}>
        <GraficoAlarmesNoPeriodo data={filteredTelemetryAlarms} />
      </div>
    );
  }

  const renderTable = () => {
    async function handleDownloadFaturasTable(data) {
      xlsxReports.exportFaturasConsumerGroupDashToExcel(data);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE FATURAS DA UNIDADE CONSUMIDORA - XLSX';
      const logContent = {};

      await createLog(operacao, form, logContent);
    }

    async function handleDownloadFaturasTablePdf(data) {
      energyBillByConsumerGroupReport(data);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE FATURAS DA UNIDADE CONSUMIDORA - PDF';
      const logContent = {};

      await createLog(operacao, form, logContent);
    }

    return (
      <S.TableContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              FATURAS DA UNIDADE CONSUMIDORA
            </S.TypographyTitle>
          </div>
          <div>
            <IoseButton2
              onClick={() => handleDownloadFaturasTablePdf(faturasCompletas)}
              width="120px"
            >
              <span style={{ marginRight: '5px' }}>PDF</span>
              <GetAppIcon />
            </IoseButton2>
            <IoseButton2
              onClick={() => handleDownloadFaturasTable(faturasCompletas)}
              width="120px"
            >
              <span style={{ marginRight: '5px' }}>XLSX</span>
              <GetAppIcon />
            </IoseButton2>
          </div>
        </S.HeaderContainer>
        {renderTableOrErrorMessage()}
      </S.TableContainer>
    );
  };

  const renderTelemetryTableMeasures = () => {
    const handleInitialDate = (dateInitial) => {
      let dateInitialISOString = dateInitial.toISOString();
      setInitialDate(dateInitialISOString);
    };

    const handleFinalDate = (dateFinal) => {
      let dateFinalISOString = dateFinal.toISOString();
      setFinalDate(dateFinalISOString);
    };

    const handleMeasuresXlsxReport = async () => {
      xlsxReports.exportTelemetryMeasuresToExcel(telemetryMeasures);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE MEDIÇÕES DA TELEMETRIA - XLSX';
      const logContent = {};

      await createLog(operacao, form, logContent);
    };

    const handleMeasuresPdfReport = async () => {
      TelemetryMeasuresReport(telemetryMeasures);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE MEDIÇÕES DA TELEMETRIA - PDF';
      const logContent = {};

      await createLog(operacao, form, logContent);
    };

    return (
      <S.TelemetryTableContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              MEDIÇÕES DO EQUIPAMENTO (ÚLTIMOS 30 DIAS)
            </S.TypographyTitle>
          </div>
          <div>
            <IoseButton2 onClick={() => handleMeasuresPdfReport()} width="80px">
              <span style={{ marginRight: '5px' }}>PDF</span>
              <GetAppIcon />
            </IoseButton2>
            <IoseButton2
              onClick={() => handleMeasuresXlsxReport()}
              width="80px"
            >
              <span style={{ marginRight: '5px' }}>XLSX</span>
              <GetAppIcon />
            </IoseButton2>
          </div>
        </S.HeaderContainer>
        <S.FilterContainer>
          <IoseSelectDayMouth
            title="Dia inicial "
            theme="black"
            getDate={handleInitialDate}
            initialDate={initialDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseSelectDayMouth
            title="Dia final "
            theme="black"
            getDate={handleFinalDate}
            initialDate={finalDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseButton
            theme="black"
            onClick={() =>
              obterMedicoesTelemetria(mapUuidCircuit, initialDate, finalDate)
            }
          >
            FILTRAR
          </IoseButton>
        </S.FilterContainer>
        {renderMeasuresTableOrErrorMessage()}
      </S.TelemetryTableContainer>
    );
  };

  const renderTelemetryTableAlarms = () => {
    const handleInitialDate = (dateInitial) => {
      let dateInitialISOString = dateInitial.toISOString();
      setInitialAlarmDate(dateInitialISOString);
    };

    const handleFinalDate = (dateFinal) => {
      let dateFinalISOString = dateFinal.toISOString();
      setFinalAlarmDate(dateFinalISOString);
    };

    const handleAlarmsXlsxReport = async () => {
      xlsxReports.exportTelemetryAlarmsToExcel(filteredTelemetryAlarms);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE ALARMES DE EQUIPAMENTO - XLSX';
      const logContent = {};

      await createLog(operacao, form, logContent);
    };

    const handleAlarmsPdfReport = async () => {
      TelemetryAlarmsReport(filteredTelemetryAlarms);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE ALARMES DE EQUIPAMENTO - PDF';
      const logContent = {};

      await createLog(operacao, form, logContent);
    };

    return (
      <S.TelemetryAlarmsContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              ALARMES DO EQUIPAMENTO (ÚLTIMOS 30 DIAS)
            </S.TypographyTitle>
          </div>
          <div style={{ display: 'flex' }}>
            <IoseButton2 onClick={() => handleAlarmsPdfReport()} width="80px">
              <span style={{ marginRight: '5px' }}>PDF</span>
              <GetAppIcon />
            </IoseButton2>
            <IoseButton2 onClick={() => handleAlarmsXlsxReport()} width="80px">
              <span style={{ marginRight: '5px' }}>XLSX</span>
              <GetAppIcon />
            </IoseButton2>
          </div>
        </S.HeaderContainer>
        <S.FilterContainer>
          <IoseSelectDayMouth
            title="Dia inicial "
            theme="black"
            getDate={handleInitialDate}
            initialDate={initialAlarmDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseSelectDayMouth
            title="Dia final "
            theme="black"
            getDate={handleFinalDate}
            initialDate={finalAlarmDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseSelection
            title="Tipo do alarme"
            object={[
              'Todos',
              'Queda de Energia',
              'Retorno de Energia',
              'Sem Medição',
              'Horário Ponta',
              'Horário Fora Ponta',
              'Horário Capacitivo',
              'Reset pelo Usuário',
              'Fim do intervalo de 15 min.',
              'Fim do intervalo timeout',
              'Abertura Porta',
              'Ultrapassagem de demanda',
              'Excesso de Reativos',
            ]}
            defaultvalue={alarmType}
            func={setAlarmType}
            top={'0px'}
            right={'16px'}
          />

          <IoseButton
            theme="black"
            onClick={() =>
              getTelemetryAlarms(
                mapUuidCircuit,
                initialAlarmDate,
                finalAlarmDate,
                alarmType
              )
            }
          >
            FILTRAR
          </IoseButton>
        </S.FilterContainer>
        <S.AlarmesDiv>
          {renderAlarmsTableOrErrorMessage()}
          {renderGraficoAlarmesPizza()}
        </S.AlarmesDiv>
      </S.TelemetryAlarmsContainer>
    );
  };

  const renderConsumo30dias = () => {
    const gerenciarDataInicialConsumo = (data) => {
      let dataISOString = data.toISOString();
      setInicialDataConsumo(dataISOString);
    };

    const gerenciarDataFinalConsumo = (data) => {
      let dataISOString = data.toISOString();
      setFinalDataConsumo(dataISOString);
    };

    return (
      <S.Consumo30DiasContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              CONSUMOS (ÚLTIMOS 30 DIAS) Q-1
            </S.TypographyTitle>
          </div>
        </S.HeaderContainer>
        <S.FilterContainer>
          <IoseSelectDayMouth
            title="Data inicial"
            theme="black"
            getDate={gerenciarDataInicialConsumo}
            initialDate={inicialDataConsumo}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseSelectDayMouth
            title="Data final"
            theme="black"
            getDate={gerenciarDataFinalConsumo}
            initialDate={finalDataConsumo}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseButton
            theme="black"
            onClick={() =>
              obterConsumoGraf30Dias(
                mapUuidCircuit,
                inicialDataConsumo,
                finalDataConsumo
              )
            }
          >
            FILTRAR
          </IoseButton>

          {erroPeriodo ? (
            <S.ErrorText>Período máximo de 30 dias.</S.ErrorText>
          ) : null}
        </S.FilterContainer>

        {consumosDiarios && consumosDiarios.length > 0 && (
          <div
            style={{
              width: '100%',
              marginBottom: '15px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <IoseButton2
              onClick={() =>
                xlsxReports.exportResumoGraficoConsumo30dias(
                  consumosDiarios,
                  mapConsumerGroup
                )
              }
              width="80px"
            >
              <span style={{ marginRight: '5px' }}>XLSX</span>
              <GetAppIcon />
            </IoseButton2>
          </div>
        )}

        <GraficoConsumo30Dias consumos={consumosDiarios} />

        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <S.ContractsIcon />
          <S.TypographyTitle>CONSUMO - Q4</S.TypographyTitle>
        </div>

        <GraficoConsumo30DiasQ4 consumos={consumosDiarios} />

        <div
          style={{
            display: 'flex',
            marginBottom: '15px',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', width: '50%' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>FATOR DE POTÊNCIA MÉDIO - Q1</S.TypographyTitle>
          </div>

          {consumosDiarios && consumosDiarios.length > 0 && (
            <div
              style={{
                width: '100%',
                marginBottom: '15px',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <IoseButton2
                onClick={() =>
                  xlsxReports.exportResumoGraficoFatorPotenciaMedio(
                    consumosDiarios,
                    mapConsumerGroup
                  )
                }
                width="80px"
              >
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            </div>
          )}
        </div>

        <GraficoFatorPotenciaQ1 consumos={consumosDiarios} />

        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <S.ContractsIcon />
          <S.TypographyTitle>FATOR DE POTÊNCIA MÉDIO - Q4</S.TypographyTitle>
        </div>

        <GraficoFatorPotenciaQ4 consumos={consumosDiarios} />
      </S.Consumo30DiasContainer>
    );
  };

  const renderTelemetriaConsumo = () => {
    const gerenciarDataConsumo = (dateInitial) => {
      let dateInitialISOString = dateInitial.toISOString();
      setInitialDateConsumo(dateInitialISOString);
    };

    return (
      <S.ConsumoTelemetriaContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>GRÁFICOS - 4 QUADRANDES</S.TypographyTitle>
          </div>
        </S.HeaderContainer>
        <S.FilterContainer>
          <IoseSelectDayMouth
            title="Dia"
            theme="black"
            getDate={gerenciarDataConsumo}
            initialDate={initialDateConsumo}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseButton
            theme="black"
            onClick={() =>
              obterConsumoTelemetria(mapUuidCircuit, initialDateConsumo)
            }
          >
            FILTRAR
          </IoseButton>
        </S.FilterContainer>

        <S.HeaderContainer>
          <div style={{ display: 'flex', marginTop: '20px', width: '50%' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>CONSUMO - 4 QUADRANTES</S.TypographyTitle>
          </div>

          {consumos && consumos.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <IoseButton2
                onClick={() =>
                  xlsxReports.exportResumoGraficoConsumo4Quadrantes(
                    consumos,
                    mapConsumerGroup
                  )
                }
                width="80px"
              >
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            </div>
          )}
        </S.HeaderContainer>

        <GraficoQuatroQuadrantes
          data={consumos}
          unidadeConsumidora={consumerGroup}
        />
      </S.ConsumoTelemetriaContainer>
    );
  };

  const renderTelemetriaDemanda = () => {
    return (
      <S.DemandaTelemetriaContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex', width: '50%' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>DEMANDA - 4 QUADRANTES</S.TypographyTitle>
          </div>

          {consumos && consumos.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <IoseButton2
                onClick={() =>
                  xlsxReports.exportResumoGraficoDemanda4Quadrantes(
                    consumos,
                    mapConsumerGroup
                  )
                }
                width="80px"
              >
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            </div>
          )}
        </S.HeaderContainer>

        <div style={{ marginTop: '20px' }}>
          <GraficoQuatroQuadrantesDemanda
            data={consumos}
            unidadeConsumidora={consumerGroup}
          />
        </div>
      </S.DemandaTelemetriaContainer>
    );
  };

  const renderTelemetriaFatorPotencia = () => {
    return (
      <S.FatorPotenciaTelemetriaContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex', width: '50%' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>FATOR POTÊNCIA Q1 - DIA</S.TypographyTitle>
          </div>

          {consumos && consumos.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <IoseButton2
                onClick={() =>
                  xlsxReports.exportResumoGraficoFatPotenciaDiario(
                    consumos,
                    mapConsumerGroup
                  )
                }
                width="80px"
              >
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            </div>
          )}
        </S.HeaderContainer>

        <div style={{ marginTop: '10px' }}>
          <GraficoFatorPotenciaPorPacote consumos={consumos} />
        </div>

        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>FATOR POTÊNCIA Q4 - DIA</S.TypographyTitle>
          </div>
        </S.HeaderContainer>

        <div style={{ marginTop: '10px' }}>
          <GraficoFatorPotenciaPorPacoteQ4 consumos={consumos} />
        </div>
      </S.FatorPotenciaTelemetriaContainer>
    );
  };

  function renderConsumerGroupOrErrorMessage() {
    if (
      !filteredConsumerGroup ||
      Object.keys(filteredConsumerGroup).length === 0 ||
      Object.values(filteredConsumerGroup).some((value) => value === undefined)
    ) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que essa unidade consumidora não tem dados!"
          height="250px"
          width="250px"
        />
      );
    } else {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>DADOS DA UNIDADE CONSUMIDORA</S.TypographyTitle>
          </div>
          <div style={{ display: 'flex', gap: '10px', fontSize: 10 }}>
            <div style={{ width: '50%' }}>
              <TextField
                label="Concessionária"
                value={filteredConsumerGroup.concessionaire}
              />
              <TextField
                label="Endereço"
                value={filteredConsumerGroup.address}
              />
              <TextField
                label="Gerência Regional"
                value={filteredConsumerGroup.regional_management}
              />
              <TextField
                label="Gerência Geral"
                value={filteredConsumerGroup.general_management}
              />
              <TextField
                label="Unidade Operacional"
                value={filteredConsumerGroup.operating_unity}
              />
              <TextField label="Cidade" value={filteredConsumerGroup.city} />
              <TextField
                label="Crédito de carbono"
                value={
                  filteredConsumerGroup.carbon_credit === 'true'
                    ? 'POSSUI'
                    : 'NÃO POSSUI'
                }
              />
              <TextField
                label="Telemetria"
                value={
                  filteredConsumerGroup.telemetry === 'SIM'
                    ? 'POSSUI'
                    : 'NÃO POSSUI'
                }
              />
              <TextField
                label="Modalidade"
                value={filteredConsumerGroup.modalidade}
                color={
                  filteredConsumerGroup.modalidade === 'VERDE'
                    ? '#3da86077'
                    : filteredConsumerGroup.modalidade === 'AZUL'
                    ? '#3374b077'
                    : 'white'
                }
              />
            </div>

            <div style={{ width: '50%', fontSize: 10 }}>
              <TextField
                label="Atividade"
                value={filteredConsumerGroup.atividade}
              />
              <TextField
                label="USBIO"
                value={
                  filteredConsumerGroup.csbio === 'SIM'
                    ? 'POSSUI'
                    : 'NÃO POSSUI'
                }
              />

              <TextField
                label="Mercado Livre"
                value={
                  filteredConsumerGroup.mercado_livre === 'SIM' ? 'SIM' : 'NÃO'
                }
                color={
                  filteredConsumerGroup.mercado_livre === 'SIM'
                    ? '#ffA500'
                    : '(--var secundary)'
                }
              />
              <TextField
                label="Descrição da Unidade"
                value={filteredConsumerGroup.descricao_unidade}
              />
              <TextField
                label="Beneficiária"
                value={filteredConsumerGroup.csbio === 'SIM' ? 'SIM' : 'NÃO'}
              />
              <TextField
                label="Número Telemetria"
                value={
                  filteredConsumerGroup.numero_telemetria
                    ? filteredConsumerGroup.numero_telemetria
                    : 'NÃO POSSUI'
                }
              />

              <TextField
                label="Coordenada X"
                value={filteredConsumerGroup.coordinate_x}
              />
              <TextField
                label="Coordenada Y"
                value={filteredConsumerGroup.coordinate_y}
              />
              <TextField
                label="Subgrupo"
                value={filteredConsumerGroup.subgrupo}
              />
            </div>
          </div>
        </>
      );
    }
  }

  const renderBillModel = () => {
    return (
      <S.ConsumerGroupDataContainer>
        {renderConsumerGroupOrErrorMessage()}
      </S.ConsumerGroupDataContainer>
    );
  };

  const renderAllContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '98%',
            }}
          >
            <>
              <S.ConsumoDemandaRf12Container>
                {renderDashboard()}
                {renderDemandaRf12()}
              </S.ConsumoDemandaRf12Container>

              <div style={{ width: '100%' }}>{renderTable()}</div>
              {renderFaturas()}

              <S.ConsumoInfosContainer>
                {renderTarifaOpcaoTable()}
                {renderBillModel()}
                {renderAuditModal()}
              </S.ConsumoInfosContainer>

              <S.ConsumoDemandaRf12Container>
                {renderDesperdiciosRf12()}
              </S.ConsumoDemandaRf12Container>

              <S.ConsumoDemandaRf12Container>
                {renderCreditoEnergia()}
              </S.ConsumoDemandaRf12Container>

              {mapUuidCircuit ? (
                <>
                  <S.TelemetryConsumoContainer>
                    {renderConsumo30dias()}
                  </S.TelemetryConsumoContainer>

                  <S.TelemetryConsumoContainer>
                    {renderTelemetriaConsumo()}
                    {renderTelemetriaDemanda()}
                    {renderTelemetriaFatorPotencia()}
                  </S.TelemetryConsumoContainer>

                  <S.TelemetryContainer>
                    {renderTelemetryTableMeasures()}
                    {renderTelemetryTableAlarms()}
                  </S.TelemetryContainer>
                </>
              ) : null}
            </>
          </div>
        </>
      );
    }
  };

  const renderTelemetryContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '98%',
            }}
          >
            <>
              {mapUuidCircuit ? (
                <>
                  <S.TelemetryConsumoContainer>
                    {renderConsumo30dias()}
                  </S.TelemetryConsumoContainer>

                  <S.TelemetryConsumoContainer>
                    {renderTelemetriaConsumo()}
                    {renderTelemetriaDemanda()}
                    {renderTelemetriaFatorPotencia()}
                  </S.TelemetryConsumoContainer>

                  <S.TelemetryContainer>
                    {renderTelemetryTableMeasures()}
                    {renderTelemetryTableAlarms()}
                  </S.TelemetryContainer>
                </>
              ) : null}
            </>
          </div>
        </>
      );
    }
  };

  const renderBillContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '98%',
            }}
          >
            <>
              <S.ConsumoDemandaRf12Container>
                {renderDashboard()}
                {renderDemandaRf12()}
              </S.ConsumoDemandaRf12Container>

              <div style={{ width: '100%' }}>{renderTable()}</div>
              {renderFaturas()}

              <S.ConsumoInfosContainer>
                {renderTarifaOpcaoTable()}
                {renderBillModel()}
                {renderAuditModal()}
              </S.ConsumoInfosContainer>

              <S.ConsumoDemandaRf12Container>
                {renderDesperdiciosRf12()}
              </S.ConsumoDemandaRf12Container>
            </>
          </div>
        </>
      );
    }
  };

  const renderCreditContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '98%',
            }}
          >
            <S.ConsumoDemandaRf12Container>
              {renderCreditoEnergia()}
            </S.ConsumoDemandaRf12Container>
          </div>
        </>
      );
    }
  };

  const renderContent = () => {
    switch (visualization) {
      case 'telemetry':
        return renderTelemetryContent();
      case 'bills':
        return renderBillContent();
      case 'credit':
        return renderCreditContent();
      default:
        return renderAllContent();
    }
  };

  const renderAuditModal = () => {
    return (
      <IoseBillsAuditModal
        open={openModal}
        onClose={openAuditModal}
        data={selectedRowData}
        refreshBills={refreshBills}
        refreshFaturas={refreshFaturas}
        extracts={extracts}
        bills={bills}
        faturasAcl={faturasAcl}
      />
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        <RightSidebar
          visualization={visualization}
          setVisualization={setVisualization}
          consumerGroup={consumerGroup}
        />
      </S.WrappedContainer>
    </Grow>
  );
}

ConsumerGroupDashContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
