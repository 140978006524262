import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseNewChartConsumption(props) {
  //DATA
  const dados = props.data;

  var consumed = [];
  var forecast = [];
  var date = [];

  dados.forEach((obj) => {
    consumed.push(obj.consumido);
    forecast.push(obj.previsao);
    date.push(obj.date);
  });

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        crossStyle: {
          color: '#999',
        },
      },
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: ['Consumido', 'Previsão'],
    },
    xAxis: {
      type: 'category',
      data: date,
      name: 'Dias',
    },
    yAxis: {
      type: 'value',
      name: 'Energia Consumida (kWh)',
      axisLabel: {
        formatter: '{value} kWh',
      },
    },
    series: [
      {
        name: 'Consumido',
        type: 'bar',
        color: '#015A9E',
        barWidth: '60%',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' kWh';
          },
        },
        data: consumed,
        markLine: {
          data: [
            {
              symbol: 'none',
              name: 'Meta',
              yAxis: props.goal,
              label: {
                normal: {
                  position: 'end',
                },
              },
              lineStyle: {
                show: true,
                normal: {
                  color: '#D61706',
                  width: 2,
                },
              },
            },
          ],
        },
      },
      {
        name: 'Previsão',

        type: 'bar',
        barWidth: '60%',
        color: '#67C6E3',
        barCategoryGap: '5%',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' kWh';
          },
        },
        data: forecast,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 380 }} />;
}
