import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAlarmsDisplayed, useCreateLogs } from 'hooks';
import { IoseButton2 } from 'components';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import * as S from '../style';

import GetAppIcon from '@material-ui/icons/GetApp';
import AlarmsNotDisplayedTable from './AlarmsNotDisplayedTable';
import { xlsxReports } from 'reports/Xlsx';
import AlarmsNoDisplayedReports from 'reports/Alarms/AlarmsNoDisplayedReports';

const useStyles = makeStyles((theme) => ({
  modal: {
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function AlarmsNotDisplayedModal({
  open,
  onClose,
  hasReportPermission,
}) {
  const classes = useStyles();
  const { createLog } = useCreateLogs();
  const { alarmsNotDisplayed } = useAlarmsDisplayed()

  const isReportButtonDisabled = alarmsNotDisplayed?.length === 0;

  const renderTableOrImage = () => (
    <div className={classes.tableContainer} style={{ position: 'relative' }}>
      <AlarmsNotDisplayedTable data={alarmsNotDisplayed} />
    </div>
  );

  const handleDesperdicioReport = async () => {
    AlarmsNoDisplayedReports(alarmsNotDisplayed);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CRÉDITOS ACUMULADOS ';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };



  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <NotificationImportantIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                ALARMES NÃO VISUALIZADOS NO APP
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
            {!hasReportPermission ? (
              <>
                <IoseButton2
                  onClick={handleDesperdicioReport}
                  width="150px"
                  disabled={isReportButtonDisabled}
                >
                  <span style={{ marginRight: '5px' }}>PDF</span>
                  <GetAppIcon />
                </IoseButton2>
                <IoseButton2
                  style={{
                    margin: '10px',
                  }}
                  onClick={() =>
                    xlsxReports.exportAlarmsNotDisplayedToExcel(alarmsNotDisplayed)
                  }
                  width="150px"
                >
                  <span style={{ marginRight: '5px' }}>XLSX</span>
                  <GetAppIcon />
                </IoseButton2>
              </>
            ) : null}
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom />
            {renderTableOrImage()}
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
