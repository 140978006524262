import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function UserLogReports(data) {
  function formatContent(logContent) {
    const contentLines = [];

    for (const key in logContent) {
      if (Object.prototype.hasOwnProperty.call(logContent, key)) {
        const line = `${key}: ${logContent[key]}`;
        contentLines.push(line);
      }
    }

    return contentLines.join('\n');
  }

  function formatDate(dataISOString) {
    const data = new Date(dataISOString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de logs de usuário`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('Usuário'),
    generateHeaderCell('E-mail'),
    generateHeaderCell('Operação'),
    generateHeaderCell('Formulário / Ação'),
    generateHeaderCell('Data'),
    generateHeaderCell('Conteúdo'),
  ];
  const renderData = data?.map((log) => [
    generateCell(log.log_user),
    generateCell(log.log_user_email),
    generateCell(log.log_operation),
    generateCell(log.log_form),
    generateCell(formatDate(log.log_date)),
    generateCell(formatContent(log.log_content)),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [30, 150, 100, '*', 60, '*'],
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [
    ...details,
    // summaryTable,
  ];

  const docDefinitions = {
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    pageSize: 'A4',
    pageOrientation: 'landscape',
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
