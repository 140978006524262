import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { ContractsIcon } from 'containers/ConctractContainer/style';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();
  const getIcon = (value) => {
    if (value.expires_in <= 0) {
      return <ErrorIcon style={{ color: 'red' }} />;
    } else if (value.expires_in <= 29) {
      return <WarningIcon style={{ color: 'orange' }} />;
    } else {
      return <CheckIcon style={{ color: 'green' }} />;
    }
  };

  const getExpiresText = (value) => {
    if (value.expires_in <= 0) {
      return 'VENCIDO';
    } else if (value.expires_in === 1) {
      return value.expires_in + ' DIA';
    } else {
      return value.expires_in + ' DIAS';
    }
  };

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value.contract_number}</TableCellCentered>
      <TableCellCentered>{value.concessionaire}</TableCellCentered>
      <TableCellCentered>{value.readjustment_index}</TableCellCentered>
      <TableCellCentered>
        R$ {convertNumberToDecimalStyle(value.monthly_value)}
      </TableCellCentered>
      <TableCellCentered>
        R$ {convertNumberToDecimalStyle(value.total_contracts)}
      </TableCellCentered>
      <TableCellCentered>{value.final_date}</TableCellCentered>
      <TableCellCentered>{getExpiresText(value)}</TableCellCentered>
      <TableCellCentered>
        <a href={value.file_link} download>
          <ContractsIcon />
        </a>
      </TableCellCentered>
      <TableCellCentered>{getIcon(value)}</TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>Nº DO CONTRATO</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONCESSIONÁRIA</TableHeaderCellCentered>
        <TableHeaderCellCentered>ÍNDICE DE REAJUSTE</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR MENSAL</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR TOTAL</TableHeaderCellCentered>
        <TableHeaderCellCentered>DATA DE EXPIRAÇÃO</TableHeaderCellCentered>
        <TableHeaderCellCentered>EXPIRA EM...</TableHeaderCellCentered>
        <TableHeaderCellCentered>ARQUIVO</TableHeaderCellCentered>
        <TableCellCentered></TableCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseContractsTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          <CircuitsTable data={data} id="scrollableDiv" />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={parseInt(data?.length)}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={400}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
