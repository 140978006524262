import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from '../ConsumerGroups/img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function TelemetryMeasuresReport(data) {
  // const formatDate = (dateString) => {
  //   return new Date(dateString).toLocaleString('pt-BR').replace(',', '');
  // };

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Medições de Telemetria do Circuito ${data[0].numero_circuito}`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('Circuito'),
    generateHeaderCell('Potência Ativa (Wh)'),
    generateHeaderCell('Potência Reativa (VArh)'),
    generateHeaderCell('Demanda Ativa (kW)'),
    generateHeaderCell('Demanda Reativa (kVAr)'),
    generateHeaderCell('Data / Hora'),
  ];
  const renderData = data?.map((item) => [
    generateCell(item.numero_circuito),
    generateCell(item.potencia_a_wh_q1),
    generateCell(item.potencia_reativa_a_varh_q1),
    generateCell(item.demanda_energia_ativa_q1),
    generateCell(item.demanda_energia_reativa_q1),
    generateCell(item.arrival_date),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [
    ...details,
    // summaryTable,
  ];

  const docDefinitions = {
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    pageSize: 'A4',
    pageOrientation: 'landscape',
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
