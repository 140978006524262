import styled from 'styled-components';

//Import from Material-UI
import Divider from '@material-ui/core/Divider';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

//Import from Iose Components
import IoseSideCard from 'components/IoseSideCard';
import IoseTextFieldSidebar from 'components/IoseTextFieldSidebar';

import EmojiObjectsSharpIcon from '@material-ui/icons/EmojiObjectsSharp';

//Import from formik
import { Form } from 'formik';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;
  margin-bottom: 100px;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  color: var(--base);

  div {
    font-weight: 600;
  }
`;

export const FormStyled = styled(Form)`
  padding: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: var(--base);
    text-align: left;
    margin-top: 20px;
  }
`;

export const IoseTextFieldSidebarStyled = styled(IoseTextFieldSidebar).attrs(
  (props) => ({
    required: true,
  })
)`
  margin-bottom: 30px;

  @media (max-width: 1360px) {
    margin-bottom: 15px;
  }
`;

export const IoseTextFieldSidebarStyled2 = styled(IoseTextFieldSidebar).attrs(
  (props) => ({
    required: false,
  })
)``;

export const Error = styled.span`
  color: red;
  font-weight: bold;
  text-align: center;
`;

export const DivSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: solid 2px green; */
  width: 100%;
  align-items: center;
  align-content: center;
  margin-top: 24px;

  p {
    padding-bottom: 20px;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-content: flex-start !important ;
    align-content: flex-start !important;
    margin: 10px;
  }
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
}))`
  margin-top: 8px;
  overflow: hidden;
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
}))`
  margin-bottom: 24px;
`;

export const DividerStyled = styled(Divider).attrs((props) => ({}))`
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-top: 30px;
  opacity: 70%;
  border-radius: 10px;

  @media (max-width: 767px) {
    margin-top: 10px;
    height: 0px;

    @media (max-width: 1360px) {
      display: none;
    }
  }
`;

export const DivInputs = styled.div`
  height: 60px;
  margin: 0px;

  @media (max-width: 1360px) {
    height: 20px;
  }
`;

export const StyledOptionsInputs = styled.div`
  height: 35px;
  margin: 35px 0 25px 0;

  @media (max-width: 1360px) {
    height: 20px;
    margin: 35px 0 25px 0;
  }
`;

export const StyledTextInputs = styled.div`
  height: 60px;
  margin-bottom: 0px;
  margin-top: 0 !important;

  @media (max-width: 1360px) {
    height: 45px;
  }
`;

export const DivInputs2 = styled.div`
  margin-bottom: 32px;
`;

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--primary);
  width: 30px !important;
  height: 30px !important;
`;

export const formContainer = styled.div`
  color: var(--primary);
  width: 30px !important;
  height: 30px !important;
`;

export const ModalTitle = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: #fff;
  text-align: center;
`;

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: black;
  margin-top: 6px;
`;

export const StyledFormControl = styled(FormControl)`
  label {
    color: white;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid #fff;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid var(--primary);
  }

  .MuiFormControl-marginNormal {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const StyledHeaderModal = styled.div`
  background-color: var(--secondary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;

  @media (max-width: 1360px) {
    height: 35px;
  }
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #4a5459;
  width: 500px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const ObjectIcon = styled(EmojiObjectsSharpIcon)`
  color: black;
`;

export const StyledFormLabel = styled(FormLabel)`
  color: #fff;
  margin-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
`;

export const StyledRadio = styled(Radio)`
  color: #fff;

  &:hover {
    color: var(--secondary);
  }

  &.Mui-checked {
    color: var(--secondary);
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-around;

  @media (max-width: 1360px) {
    font-size: 10px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;

  @media (max-width: 1360px) {
    span {
      font-size: 10px;
    }
  }
`;
export const StyledSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 12px;
  }

  @media (max-width: 1360px) {
    .MuiInputBase-input {
      font-size: 10px;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;
  color: #fff;

  @media (max-width: 1360px) {
    font-size: 10px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) {
    flex-direction: row;
    gap: 20px;
  }
`;

export const WrappedItens = styled.div`
  @media (max-width: 1360px) {
    width: 50%;
  }
`;
