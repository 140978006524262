import React from 'react';

import { TableBox, TableContainer } from './style';

import IoseCarbonTariffsTable from 'components/IoseCarbonTariffsTable';

export default function IoseCarbonTableContainer({
  loading,
  tableTitle,
  searchInTable,
  searchInputLabel,
  data,
}) {

  return (
    <TableContainer id="scrollableDiv">
      <TableBox>
        {/* <TableHeaderBox>
          <TableTitle>
            <h3>{tableTitle}</h3>
          </TableTitle>
          {searchInTable ? (
            <DivSearch>
              <InputBaseStyled
                type="text"
                placeholder={'Pesquisar por ' + { searchInputLabel } + '...'}
                value={() => {}}
                onChange={() => {}}
              />
              <SearchIconStyled />
            </DivSearch>
          ) : (
            <></>
          )}
          <IoseButton2 onClick={() => handleDownloadButtonClick()}>
            Download XLSX
          </IoseButton2>
        </TableHeaderBox> */}

        <IoseCarbonTariffsTable loading={loading} data={data} />
      </TableBox>
    </TableContainer>
  );
}
