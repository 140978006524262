import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

//Imports from Material UI
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

//Imports from Style
import {
  DatePickerStyled,
  GridContainer,
  GridItem,
  DateTimePickerStyled,
} from './style';

//This is a override css for DatePick this is single mod to change it
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: '#e8be00',
        '&:hover': {
          backgroundColor: 'black',
        },
      },
      dayDisabled: {
        color: '#e8be00',
      },
      current: {
        color: '#e8be00',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#e8be00',
      },
    },
  },
});

const materialThemeDateHour = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E8BE00',
        color: '#015A9E',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#E8BE00',
      },
      thumb: {
        borderColor: '#E8BE00',
      },
      noPoint: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: '#e8be00',
      },
      dayDisabled: {
        color: '#e8be00',
      },
      current: {
        color: '#e8be00',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#e8be00',
      },
    },
    MuiTab: {
      fullWidth: { backgroundColor: '#e8be00' },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#015A9E',
      },
    },
  },
});

/** This component create a grid conteiner with two selector dates  */
export default function IoseChoosePeriod(props) {
  const {
    title,
    theme,
    paddingleft,
    getDatesISOString,
    initialDate,
    finalDate,
    disabled,
    hours,
  } = props;

  //States and function to change the initial and final date
  const [dateInitial, setDateInitial] = useState(new Date());
  const [dateFinal, setDateFinal] = useState(new Date());

  const [dateInitialISOString, setDateInitialISOString] = useState(
    initialDate
      ? new Date(initialDate).toISOString()
      : new Date(new Date().setDate(dateInitial.getDate() - 4)).toISOString()
  );
  const [dateFinalISOString, setDateFinalISOString] = useState(
    finalDate ? finalDate : dateFinal.toISOString()
  );

  useEffect(() => {
    if (getDatesISOString) {
      getDatesISOString(dateInitialISOString, dateFinalISOString);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInitialISOString, dateFinalISOString]);

  function handleChangeDateInitial(value) {
    setDateInitialISOString(value.toISOString());
    setDateInitial(value);
  }

  function handleChangeDateFinal(value) {
    setDateFinalISOString(value.toISOString());
    setDateFinal(value);
  }

  //Array datetypes to render the GridItem with selectors data of circuit
  let dates = [
    {
      label: 'Data Inicial',
      value: dateInitialISOString,
      function: handleChangeDateInitial,
    },
    {
      label: 'Data Final',
      value: dateFinalISOString,
      function: handleChangeDateFinal,
    },
  ];

  //Function to render the Grid Item with selectors date
  const renderSelectdays = (dates) => {
    return (
      <GridItem key={dates.label}>
        <ThemeProvider theme={hours ? materialThemeDateHour : materialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            {hours ? (
              <DateTimePickerStyled
                autoOk
                variant="inline"
                label={dates.label}
                value={dates.value}
                onChange={dates.function}
                theme={theme}
                disabled={disabled}
                ampm={false}
                minDateMessage={''}
                maxDateMessage={''}
              />
            ) : (
              <DatePickerStyled
                disableToolbar
                autoOk
                variant="inline"
                label={dates.label}
                value={dates.value}
                onChange={dates.function}
                theme={theme}
                disabled={disabled}
              />
            )}
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </GridItem>
    );
  };

  return (
    <GridContainer direction="column" paddingleft={paddingleft}>
      <p>{title}</p>
      <GridContainer marginbottom="30px">
        {dates.map(renderSelectdays)}
      </GridContainer>
    </GridContainer>
  );
}

IoseChoosePeriod.propTypes = {
  /** This props get a title of selectors dates */
  title: PropType.string,
  /** This props get a theme (black ou white) of selector dates */
  theme: PropType.string,
  /** This props get a paddingleft in conteiner of selector dates */
  paddingleft: PropType.string,
  /** This props is function  to set date in container */
  getDatesISOString: PropType.func,
};
