import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function energyBillSaneparReport(data) {
  const ucGeradora = data?.map((item) => item.uc_geradora);

  const totalCreditGenerated = data?.reduce(
    (total, item) => total + parseFloat(item.credito_gerado || 0),
    0
  );
  const totalCreditConsumption = data?.reduce(
    (total, item) => total + parseFloat(item.consumido || 0),
    0
  );

  // Ordenar os dados por mes_ref em ordem decrescente
  data.sort((a, b) => {
    const [monthA, yearA] = a.mes_ref.split('/');
    const [monthB, yearB] = b.mes_ref.split('/');

    const yearComparison = yearB.localeCompare(yearA);

    if (yearComparison === 0) {
      return monthB.localeCompare(monthA);
    }

    return yearComparison;
  });

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45],
        },
        {
          text: `Relatório de créditos acumulados por mês da Unidade ${ucGeradora[0]}`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];

  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('REF: MÊS/ANO'),
    generateHeaderCell('UC Geradora'),
    generateHeaderCell('Energia Injetada'),
    generateHeaderCell('Crédito Utilizado'),
    generateHeaderCell('Saldo Acumulado'),
    generateHeaderCell('Unidade'),
    generateHeaderCell('Validade'),
    generateHeaderCell('Vencimento (dias)'),
  ];

  const renderData = data
    ?.filter((bill) => bill.uc_beneficiaria !== bill.uc_geradora)
    .map((bill) => [
      generateCell(bill.mes_ref),
      generateCell(bill.uc_geradora),
      generateCell(bill.credito_gerado),
      generateCell(bill.consumido),
      generateCell(bill.saldo),
      generateCell(bill.unidade),
      generateCell(bill.validade),
      generateCell(bill.indicador),
    ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: Array(8).fill('*'),
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0],
    };
  };

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de Energia Injetada',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalCreditGenerated?.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total Créditos Utilizados',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalCreditConsumption.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
