import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Table, TableHead, TableRow } from '@material-ui/core/';
//Import image
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import FlashOnIcon from '@material-ui/icons/FlashOn';

export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const CircuitsIcon = styled(FlashOnIcon)`
  color: var(--primary);
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: var(--primary);
`;

export const WrapContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormDivContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  margin: 15px 0 25px 0;
  align-items: center;
`

export const FormDivItem = styled.div`
  width: 150px;
`

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
`;

export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;