import React from 'react';
import { Formik, Form, Field } from 'formik';
import IoseButton from 'components/Buttons/IoseButton';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonContainer,
  DivInputs,
  IoseTextFieldSidebarStyled,
  ModalTitle,
  ModalTitleHeader,
  RoomIcon,
  StyledHeaderModal,
  StyledLeftHeaderModal,
  StyledRightHeaderModal,
  StyledWrappedModal,
} from './style';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '400px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseUrlAddModal({ open, onClose, loading, submit }) {
  const classes = useStyles();

  const handleAddUrl = (values) => {
    setTimeout(() => {
      const { url_vector, url_image, description } = values;
      submit(url_vector, url_image, description);
    }, 500);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <RoomIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                CONFIGURAÇÃO DE MAPA
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                CRIAR URL
              </ModalTitle>
              <Formik
                initialValues={{
                  url_vector: '',
                  url_image: '',
                  description: '',
                }}
                onSubmit={handleAddUrl}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                }) => (
                  <Form>
                    <DivInputs marginBottom="10px">
                      <Field
                        component={IoseTextFieldSidebarStyled}
                        fullWidth
                        id={'url_vector'}
                        name={'url_vector'}
                        type={'text'}
                        label={'Url do vetor'}
                        defaultvalue={initialValues['url_vector']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </DivInputs>

                    <DivInputs marginBottom="10px">
                      <Field
                        component={IoseTextFieldSidebarStyled}
                        fullWidth
                        id={'url_image'}
                        name={'url_image'}
                        type={'text'}
                        label={'Url da imagem'}
                        defaultvalue={initialValues['url_image']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </DivInputs>

                    <DivInputs marginBottom="10px">
                      <Field
                        component={IoseTextFieldSidebarStyled}
                        fullWidth
                        id={'description'}
                        name={'description'}
                        type={'text'}
                        label={'Descrição'}
                        defaultvalue={initialValues['description']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </DivInputs>

                    <ButtonContainer>
                      <IoseButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        CRIAR URL
                      </IoseButton>
                    </ButtonContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
