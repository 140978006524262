import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseCircuitModalChart({ selectedCard, chartMode }) {
  let max;

  if (chartMode !== 'Diário') {
    if (
      selectedCard.max_value_cumulative_estimated >
      selectedCard.max_value_cumulative
    ) {
      max = selectedCard.max_value_cumulative_estimated;
    } else {
      max = selectedCard.max_value_cumulative;
    }
  } else {
    max = selectedCard.max_value;
  }

  if (selectedCard?.max_value_estimated > max) {
    max = selectedCard.max_value_estimated;
  }

  const option = {
    grid: {
      left: '5%',
      right: '3%',
      bottom: '15%',
      top: '10%',
    },
    title: {
      text:
        chartMode === 'Diário'
          ? 'CONSUMO DIÁRIO DO CIRCUITO ' + selectedCard.uuid_circuit
          : 'CONSUMO ACUMULADO DO CIRCUITO ' + selectedCard.uuid_circuit,
      color: '#015A9E',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    legend: {
      data: ['Consumo (kWh)', 'Consumo estimado (kWh)'],
      bottom: 0,
    },

    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {},
      },
      dataZoom: {
        yAxisIndex: 'none',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: selectedCard.dates,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
      },
      min:
        chartMode === 'Diário'
          ? selectedCard.min_value
          : selectedCard.min_value_cumulative,
      max: max,
    },
    series: [
      {
        name: 'Consumo (kWh)',
        type: 'line',
        color: '#015A9E',
        data:
          chartMode === 'Diário'
            ? selectedCard.daily_consumption
            : selectedCard.cumulative_consumption,
        markPoint: {
          data: [
            { type: 'max', name: 'Max' },
            { type: 'min', name: 'Min' },
          ],
        },
      },
      {
        name: 'Consumo estimado (kWh)',
        type: 'line',
        color: '#D60606',
        data:
          chartMode === 'Diário'
            ? selectedCard.daily_estimated_consumption
            : selectedCard.cumulative_estimated_consumption,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 400 }} />;
}
