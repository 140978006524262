import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

export const LinearProgressStyled = styled(LinearProgress)`
  height: 8px !important;
  border-radius: 10px;

  .MuiLinearProgress-barColorPrimary {
    background-color: var(--primary);
  }
`;
