import React from 'react';
import { useHistory } from 'react-router-dom';

//Import Styled Components
import {
  TypographyTitle,
  TypographyDescription,
  GridContainer,
  GridTiles,
  UnityIconAdmDash,
  GridTilesAdmDash,
} from './style';

import { LocalStorage } from 'common';

import { IoseDashAdmButton } from 'components';

export default function IoseAdmDashSubHeader() {
  const sessionData = LocalStorage.getSession();
  let userName = sessionData?.name;
  let userGroup = sessionData?.group;

  const history = useHistory();

  function redirectToGlobalPage() {
    if (userGroup === 'super') {
      history.push('/global/super');
    } else {
      history.push('/global/client');
    }
  }

  return (
    <GridContainer container>
      <GridTiles item xs={9} sm={6} md={6}>
        {userName ? <TypographyTitle>Olá, {userName}.</TypographyTitle> : <></>}
        <TypographyDescription>
          Bem-vindo ao painel administrativo IOSE.
        </TypographyDescription>
      </GridTiles>
      <GridTilesAdmDash>
        <UnityIconAdmDash />
        <IoseDashAdmButton
          tooltip="GERENCIAMENTO DE UNIDADES"
          onClick={(e) => {
            redirectToGlobalPage();
          }}
        />
      </GridTilesAdmDash>
    </GridContainer>
  );
}
