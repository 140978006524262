import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#015A9E',
    },
  },
});

export default function IoseSelectReferenceMonth({
  label,
  handleChange,
  selectedDate,
  width
}) {
  // Função para formatar a data no padrão brasileiro (MM/YYYY)
  const formatToBrazilianDate = (date) => {
    if (date) {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${year}`;
    }
    return '';
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: width ? width : '170px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={['year', 'month']}
            label={label}
            value={selectedDate}
            onChange={handleChange}
            format="MM/yyyy"
            TextFieldComponent={(props) => (
              <TextField
                {...props}
                value={formatToBrazilianDate(selectedDate)}
                InputProps={{
                  style: {
                    color: '#015A9E',
                    borderBottom: '1.5px solid #015A9E',
                    borderRadius: '5px',
                    width: width ? width : '220px'
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: '#015A9E',
                    fontWeight: 'bold',
                    marginTop: '-8px',
                   },

                }}
              />
            )}
          />
        </MuiPickersUtilsProvider>
      </div>
    </ThemeProvider>
  );
}
