import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--primary);
  width: 30px !important;
  height: 30px !important;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  color: red;
  width: 30px !important;
  height: 30px !important;
`;

export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  color: var(--success);
  width: 30px !important;
  height: 30px !important;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    color: ${(props) => (props.type === 1 ? 'var(--success)' : 'red')};
    font-weight: bolder;
    padding-right: 10px;

    @media (max-width: 767px) {
      overflow: hidden;
      white-space: pre-wrap;
      width: ${(props) => (props.type === 1 ? '100px' : '75px')};
    }
  }
`;
