import React from 'react';
import * as S from './style';
import { LoadingValueCards } from 'components';
import EcoIcon from '@material-ui/icons/Eco';

function formatNumberToLocaleString(numberString) {
  if (typeof numberString === 'string' || numberString instanceof String) {
    const cleanedString = numberString.replace(/\s/g, '').replace(',', '.');

    if (!isNaN(cleanedString)) {
      const number = parseFloat(cleanedString);

      const hasDecimals = number % 1 !== 0;

      const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: hasDecimals ? 2 : 0,
      });

      return formatter.format(number);
    }
  }

  return numberString;
}

const toFixedReplace = (number, decimals) => {
  return number.toFixed(decimals).replace('.', ',');
};

const calculateCO2Intensity = (data) => {
  const totalKwh = data.reduce((sum, item) => sum + item.kwh_total, 0);
  const totalCO2 = data.reduce((sum, item) => sum + item.resultado_tco2, 0);
  return toFixedReplace(totalCO2 / totalKwh, 6);
};

const calculateTotalCO2Emissions = (data) => {
  return toFixedReplace(
    data.reduce((sum, item) => sum + item.resultado_tco2, 0),
    2
  );
};

const calculateSummary = (data) => {
  // Exemplo: calcular o total de kWh e CO2
  const totalKwh = data.reduce((sum, item) => sum + item.kwh_total, 0);
  const totalCO2 = data.reduce((sum, item) => sum + item.resultado_tco2, 0);

  return {
    totalKwh: totalKwh,
    totalCO2: totalCO2,
  };
};

const findLastMonthData = (data) => {
  if (data.length === 0) return { kwh_total: 0, resultado_tco2: 0 };

  // Ordena os dados por ano e mês para garantir que o último registro é o mais recente
  const sortedData = [...data].sort((a, b) => {
    return a.ano !== b.ano ? b.ano - a.ano : b.mes - a.mes;
  });

  return sortedData[0];
};

const calculateTotalKwhAndCO2LastSixMonths = (data) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  // Calcula a data 6 meses atrás
  let year = currentYear;
  let month = currentMonth - 6;
  if (month <= 0) {
    month += 12;
    year--;
  }

  // Filtra os dados dos últimos 6 meses
  const sixMonthsData = data.filter((item) => {
    const date = new Date(item.ano, item.mes - 1);
    const sixMonthsAgo = new Date(year, month - 1);
    return date >= sixMonthsAgo && date < currentDate;
  });

  // Soma o consumo total de kWh e a emissão total de CO2
  const totalKwh = sixMonthsData.reduce((sum, item) => sum + item.kwh_total, 0);
  const totalCO2 = sixMonthsData.reduce(
    (sum, item) => sum + item.resultado_tco2,
    0
  );

  return {
    totalKwh: totalKwh,
    totalCO2: totalCO2,
  };
};

export default function IoseCardCo2({ loading, data }) {
  const totalKwh = calculateSummary(data).totalKwh;
  const totalKwhLast6Months = calculateTotalKwhAndCO2LastSixMonths(data)
    .totalKwh;
  const totalCO2Last6Months = calculateTotalKwhAndCO2LastSixMonths(data)
    .totalCO2;
  const totalCO2Last6MonthsFormatted = totalCO2Last6Months
    .toFixed(2)
    .replace('.', ',');

  const cardsData = [
    {
      icon: <EcoIcon />,
      title: 'EMISSÃO DE CO2',
      data: [
        {
          key: 'INTENSIDADE DE EMISSÃO (tCO2/kWh):',
          value: `${calculateCO2Intensity(data)} `,
        },
        {
          key: 'TOTAL DE EMISSÃO (tCO2):',
          value: `${formatNumberToLocaleString(
            calculateTotalCO2Emissions(data)
          )}`,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <EcoIcon />,
      title: 'TOTAIS',
      data: [
        {
          key: 'CONSUMO TOTAL:',
          value: totalKwh.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        },
        {
          key: 'EMISSÃO TOTAL:',
          value: `${formatNumberToLocaleString(
            calculateTotalCO2Emissions(data)
          )}`,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <EcoIcon />,
      title: 'ÚLTIMO MÊS',
      data: [
        {
          key: 'CONSUMO: ',
          value: findLastMonthData(data).kwh_total.toFixed(2).replace('.', ','),
        },
        {
          key: 'EMISSÃO DE CO2 (tCO2): ',
          value: findLastMonthData(data)
            .resultado_tco2.toFixed(2)
            .replace('.', ','),
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <EcoIcon />,
      title: 'ÚLTIMOS 6 MESES',
      data: [
        {
          key: 'CONSUMO: ',
          value: totalKwhLast6Months.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        },
        {
          key: 'EMISSÃO DE CO2 (tCO2): ',
          value: formatNumberToLocaleString(totalCO2Last6MonthsFormatted),
        },
      ],
      bgColor: 'var(--base)',
    },
  ];

  return (
    <S.CardGrid container spacing={2}>
      {cardsData.map(({ icon, chart, title, data, bgColor }) => (
        <S.BoxContainer
          key={title}
          style={{ transition: '0.5s ease', backgroundColor: bgColor }}
        >
          <S.HeaderContainer>
            <S.IconHeader>{icon}</S.IconHeader>
            <S.CardTitle>{title}</S.CardTitle>
          </S.HeaderContainer>
          {data.map(({ key, value }) => (
            <S.DataContainer key={key}>
              <S.CardDataKey>{key}</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{value}</S.CardDataValue>
              )}
            </S.DataContainer>
          ))}
          {chart}
        </S.BoxContainer>
      ))}
    </S.CardGrid>
  );
}
