import React, { useEffect, useState } from 'react';
import { LocalStorage } from 'common';
import Permissions from 'common/permissions';
import IoseBillLogsTable from 'components/IoseBillLogsTable';
import EnergyBillLogsFilters from './components/energy-bill-logs-filtros';
import * as S from './style';
import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';
import {
  useEnergyBillLogs,
  useGroupPermissions,
} from 'hooks';

export default function EnergyBillLogsContainer() {
  const [loading, setLoading] = useState(false);
  const [logValues, setLogValues] = useState([]);
  const [filteredLogValues, setFilteredLogValues] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { energyBillLogs } = useEnergyBillLogs();
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['faturas', 'log_faturas', 'leitura'];
    const reportPath = ['faturas', 'log_faturas', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (filteredLogValues) {
      setLogValues(filteredLogValues);
    } else {
      setLogValues(energyBillLogs);

    }
  }, [energyBillLogs, filteredLogValues]);

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <IoseBillLogsTable
          tableTitle=""
          searchInTable={false}
          data={logValues}
          hasReportPermission={hasReportPermission}
        />
      </S.ContainerTable>
    );
  };

  const renderContent = () => {
      return (
        <>
          <div>{renderTable()}</div>
        </>
      );
  };

  return (
      <S.WrappedContainer>
        <IoseSubHeaderBar title="Log de Faturas"/>
        <EnergyBillLogsFilters  loading={setLoading} logs={setFilteredLogValues}/>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
  );
}
