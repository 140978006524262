import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import IoseButton from 'components/Buttons/IoseButton';
import { CircularProgress } from '@material-ui/core';

export const TypographyConfirmation = styled(Typography)`
  color: var(--alert);
  text-align: center;
  /* font-weight: bold; */
`;

export const IoseButtonStyled = styled(IoseButton)`
  display: flex;
  margin-left: 12px !important;
  background: #fcfcfc;

  .MuiButton-label {
    color: var(--secondary);
  }

  p {
    color: var(--secondary);
    margin-left: 8px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  height: 16px !important;
  width: 16px !important;
  text-align: center;
`;
