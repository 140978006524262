import styled from 'styled-components';

//Imports Material Ui
import {
  CardHeader,
  Card,
  Avatar,
  CardContent,
  CardActions,
} from '@material-ui/core';

//Import Image
import ClientIconImage from './img/enterpriseIcon.svg';

export const CardStyled = styled(Card)`
  width: 280px;
  /* height: 120px; */
  margin: 0px 15px 15px 0px;
  transition: all 0.3s ease-in-out !important;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--primary);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ClientIcon = styled.img.attrs((props) => ({
  src: ClientIconImage,
}))`
  width: 30px;
  height: 30px;
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--primary);
`;

export const CardContentStyled = styled(CardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px 0px 0px 0px !important;

  /* border: solid 1px red; */
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding-bottom: 15px;
  /* border: solid 1px green; */

  span {
    white-space: pre-wrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-title {
    p {
      font-size: 13px !important;
      line-height: 14px;
      max-height: 28px;
      font-weight: 600;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      transition: all 0.3s ease-in-out;
    }
  }

  .MuiCardHeader-subheader {
    p {
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px !important;
    }
  }

  /* &:hover .MuiCardHeader-title {
    font-size: 13.5px;
    cursor: pointer;
  } */
  /* border: solid 1px red; */
`;

export const DivHeaderButton = styled(CardActions)`
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: solid 1px red; */
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'center'};
  width: 100%;
  margin-bottom: 16px;

  p {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 7px;
    max-width: 150px;
  }
`;
