import { useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useDesperdiciosTotal() {
  const { getSession } = useContext(AccountContext);

  const obterDesperdicios = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      try {
        const response = await ioseAPI.getAllDesperdicios(
          headers,
          lastEvaluatedKey,
          limit
        );

        const { data, next_page: newLastEvaluatedKey } = response.data;

        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    } while (lastEvaluatedKey);

    if (allData.length === 0) {
      throw new Error(Messages.noFindResults);
    }

    return allData;
  };

  const { data, isLoading, isError, error } = useQuery(
    ['totaldesperdicios'],
    obterDesperdicios
    // {
    //   retry: 3,
    //   refetchOnWindowFocus: false,
    // }
  );

  return {
    desperdicios: data || [],
    isLoading,
    isError,
    error,
  };
}

export default useDesperdiciosTotal;
