import React, { useEffect, useState } from 'react';

import 'ol-ext/dist/ol-ext.css';

import { useGroupPermissions } from 'hooks';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';
import MapTelemetryComponent from 'components/MapTelemetryComponent';
import { IoseAlertLoadingCards } from 'components';

export default function MapTelemetryContainer() {
  const [hasReadPermission, setHasReadPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['telemetria', 'mapa_telemetria', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  return hasReadPermission ? (
    <MapTelemetryComponent />
  ) : (
    <div style={{ marginTop: '100px' }}>
      <IoseAlertLoadingCards
        text={
          'Seu grupo de usuários não tem permissão de visualização do mapa!'
        }
      />
    </div>
  );
}
