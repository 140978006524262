import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { useCircuits, useClientData, useUnityConsumption, useUnitys } from 'hooks';
import { IoseButton, IoseDatePicker } from 'components';
import { AccountContext } from 'common';

import IoseDatePicker2 from 'components/IoseDatePicker2';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { ioseIotAPI } from 'common/ioseIotAPI';
import exportConsumoToExcel from '../exportConsumoToExcel';
import ConsumoUnidadeIoseReports from 'reports/Consumo/ConsumoUnidadeIoseReport';
import ConsumoSemUnidadeIoseReport from 'reports/Consumo/ConsumoSemUnidadeIoseReport';

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });

  return filteredObj;
};

export default function ConsumoFiltros({ loading, emptyResponse }) {

  const { getSession } = useContext(AccountContext);
  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { circuits } = useCircuits(uuidClient)

  const { unityConsumption } = useUnityConsumption()

  const [initialSelectedDate, setInitialSelectedDate] = useState(null);
  const [finalSelectedDate, setFinalSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [unidades, setUnidades] = useState([])
  const [pdf, setPdf] = useState(false)
  const [xlsx, setXlxs] = useState(false)

  const getConsumoCircuitos = useCallback(
    async (object) => {
      try {
        setIsLoading(true);
        loading(true);
        const sessionData = await getSession();
        if (object.indentification_number !== undefined){
          let circuitosDaUnidade = []
          circuits.forEach((circuit) => {
            if (object.indentification_number === circuit.uuid_unity) {
              circuitosDaUnidade.push(circuit.uuid_circuit)
            }
          })

          const dateinitial = new Date(Date.parse(initialSelectedDate));
          const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
          const initialDateZeroHour = new Date(
            initialDateHistoricConverted
          ).toISOString();

          const datefinal = new Date(Date.parse(finalSelectedDate));
          const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
          const finalDateZeroHour = new Date(
            finalDateHistoricConverted
          ).toISOString();

          let allData = []
          for (const circuito of circuitosDaUnidade) {
            let lastEvaluatedKey = 0;
            do {
              const iot = await ioseIotAPI.getAllMeasure(
                sessionData.headers,
                circuito,
                initialDateZeroHour,
                finalDateZeroHour,
                lastEvaluatedKey,
                300
              )
              const { data, next_page: newLastEvaluatedKey } = iot.data;

              allData = allData.concat(data);
              lastEvaluatedKey = newLastEvaluatedKey;
            } while (lastEvaluatedKey);
          }

          const circuitosProcessados = allData.reduce((acumulador, objetoAtual) => {
            const grupoExistente = acumulador.find(item => item.uuid_circuit === objetoAtual.uuid_circuit);
            if (grupoExistente) {
              grupoExistente.measures = grupoExistente.measures.concat(objetoAtual.measures);
            } else {
              acumulador.push({
                uuid_circuit: objetoAtual.uuid_circuit,
                measures: objetoAtual.measures.slice()
              });
            }
            return acumulador;
          }, []);

          if(xlsx) {
            exportConsumoToExcel(
              circuitosProcessados
            );
          }
          else if (pdf) {
            ConsumoUnidadeIoseReports(
              circuitosProcessados,
              initialSelectedDate,
              finalSelectedDate
            )
          }

          if (circuitosProcessados?.length === 0) {
            emptyResponse(true);
          }
        } else {
          const totalUnidades = unitys?.map((unidade) => {
            let circuitosDaUnidade = []
            circuits.forEach((circuit) => {
              if (unidade.uuid_unity === circuit.uuid_unity) {
                circuitosDaUnidade.push(circuit.uuid_circuit)
              }
            })
            return {'unidade': unidade.name, 'circuitos': circuitosDaUnidade}
          })

          const dateinitial = new Date(Date.parse(initialSelectedDate));
          const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
          const initialDateZeroHour = new Date(
            initialDateHistoricConverted
          ).toISOString();

          const datefinal = new Date(Date.parse(finalSelectedDate));
          const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
          const finalDateZeroHour = new Date(
            finalDateHistoricConverted
          ).toISOString();

          const promises = totalUnidades.map(async (unidade) => {
            let allData = []
            for (const circuito of unidade.circuitos) {
              let lastEvaluatedKey = 0;
              do {
                const iot = await ioseIotAPI.getAllMeasure(
                  sessionData.headers,
                  circuito,
                  initialDateZeroHour,
                  finalDateZeroHour,
                  lastEvaluatedKey,
                  300
                )
                const { data, next_page: newLastEvaluatedKey } = iot.data;

                allData = allData.concat(data);
                lastEvaluatedKey = newLastEvaluatedKey;
              } while (lastEvaluatedKey);
            }

            const circuitosProcessados = allData.reduce((acumulador, objetoAtual) => {
              const grupoExistente = acumulador.find(item => item.uuid_circuit === objetoAtual.uuid_circuit);
              if (grupoExistente) {
                grupoExistente.measures = grupoExistente.measures.concat(objetoAtual.measures);
              } else {
                acumulador.push({
                  uuid_circuit: objetoAtual.uuid_circuit,
                  measures: objetoAtual.measures.slice()
                });
              }
              return acumulador;
            }, []);
            unidade.medicao = circuitosProcessados

            // eslint-disable-next-line array-callback-return
            unityConsumption.map((consumo) => {
              if (consumo.unity_name === unidade.unidade) {
                unidade.fatura = consumo.consumption_fatura
                unidade.estimado = consumo.consumption_estimado
              }
            })
            return unidade
          })
          const unidadesProcessadas = await Promise.all(promises);
          if (pdf) {
            ConsumoSemUnidadeIoseReport(
              unidadesProcessadas,
              initialSelectedDate,
              finalSelectedDate
            )
          } else if (xlsx) {
            exportConsumoToExcel(
              unidadesProcessadas
            )
          }
        }

        setIsLoading(false);
        loading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        loading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSession, loading, emptyResponse, circuits, initialSelectedDate, finalSelectedDate, pdf, xlsx]
  );

  const initialValues = {
    unidade: '',
    indentification_number: '',
    circuitos: [],
    reference_month: '',
    previous_reading_start: '',
    previous_reading_end: '',
  };

  const onSubmit = async (values, { resetForm }) => {
    const filteredValues = removeEmptyFields(values);
    await getConsumoCircuitos(filteredValues);
    resetForm();
  };

  const pdf_click = () => {
    setPdf(true)
    setXlxs(false)
  }
  const xlsx_click = () => {
    setXlxs(true)
    setPdf(false)
  }

  useEffect(() => {
    if (unitys && unitys.length > 0) {
      let unidades_definidas = []
      unitys.forEach((unity) => {
        unidades_definidas.push({'value': unity.uuid_unity, 'label': unity.name})
      })
      setUnidades(unidades_definidas)
    }
  }, [unitys])
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
          <S.FormDivItem>
              <IoseDynamicSelect
                label="Unidade"
                value={values.indentification_number}
                onChange={(value) =>  {
                  setFieldValue('indentification_number', value)
                }}
                options={unidades}
                width="220px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDatePicker
                label="Data inicial"
                handleChange={(value) => {
                  const selectedDate = new Date(value);
                  selectedDate.setUTCHours(0, 0, 0, 0);
                  const isoDateString = selectedDate.toISOString();
                  setFieldValue('previous_reading_start', isoDateString);
                  setInitialSelectedDate(value);
                }}
                initialSelectedDate={initialSelectedDate}
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDatePicker2
                label="Data final"
                handleChange={(value) => {
                  setFieldValue('previous_reading_end', value);
                  setFinalSelectedDate(value);
                }}
                finalSelectedDate={finalSelectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isLoading} onClick={pdf_click}>
              EMITIR RELATÓRIO PDF
            </IoseButton>

            <IoseButton height="35px" type="submit" disabled={isLoading} onClick={xlsx_click}>
              EMITIR RELATÓRIO XLSX
            </IoseButton>

          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
