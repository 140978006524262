import React from 'react';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import {
  IoseConsumerGroupRedirectButton,
  IoseDownloadButtonAcl,
  IoseDownloadButtonAclDanfe,
} from 'components/IoseButtonIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useConsumerGroupData } from 'hooks';

const handleDownloadBoleto = (faturaAcl) => {
  const fatura = faturaAcl[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (fatura) {
    const concessionaire = fatura?.comercializadora.toLowerCase();
    const referenceMonth = fatura?.reference_month.replace('/', '');
    const pdfNumber = fatura?.pdf_boleto;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}M/boleto/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

const handleDownloadDanfe = (faturaAcl) => {
  const fatura = faturaAcl[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (fatura) {
    const concessionaire = fatura?.comercializadora.toLowerCase();
    const referenceMonth = fatura?.reference_month.replace('/', '');
    const pdfNumber = fatura?.pdf_danfe;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}M/danfe/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

export default function BillsModalAclSubHeader({ faturasAcl }) {
  const history = useHistory();
  const faturaAcl = faturasAcl[0];
  const { consumerGroup } = useConsumerGroupData(
    faturaAcl?.identification_number
  );

  function redirectToUcDash() {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: faturaAcl?.identification_number,
        uuidCircuit: consumerGroup[0]?.numero_telemetria,
      },
    });
  }

  return (
    <>
      <IoseSubHeaderBar
        subtitle={`Detalhamento da fatura de energia ACL da UC ${faturaAcl?.identification_number}`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <div>
            {faturaAcl?.identification_number && consumerGroup ? (
              <IoseConsumerGroupRedirectButton
                tooltip="IR PARA O DASHBOARD DA UC"
                bottom="5px"
                onClick={() => redirectToUcDash()}
              />
            ) : null}
          </div>

          <div>
            {faturasAcl[0].pdf_boleto && faturasAcl[0].pdf_boleto !== '0' ? (
              <IoseDownloadButtonAcl
                tooltip={'BAIXAR BOLETO ACL'}
                bottom="5px"
                onClick={() => handleDownloadBoleto(faturasAcl)}
              />
            ) : null}
          </div>

          <div>
            {faturasAcl[0].pdf_danfe && faturasAcl[0].pdf_danfe !== '0' ? (
              <IoseDownloadButtonAclDanfe
                tooltip={'BAIXAR DANFE ACL'}
                bottom="5px"
                onClick={() => handleDownloadDanfe(faturasAcl)}
              />
            ) : null}
          </div>
        </div>
      </IoseSubHeaderBar>
    </>
  );
}
