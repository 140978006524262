import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//imports from IOSE components
import IoseCircuitTable from 'components/IoseCircuitTable';
import IoseButton from 'components/Buttons/IoseButton';
import IoseSearch from 'components/IoseSearch';
import IoseListCheck from 'components/IoseListCheck';
import {
  IoseDashboardButton,
  IoseCollapseButton,
} from 'components/IoseButtonIcon';

import Messages from 'common/Messages';

//imports from Material UI components
import { Tab, Grow } from '@material-ui/core';

//imports from Style
import {
  Container,
  StyledTabs,
  AvatarStyled,
  Header,
  HeaderTitle,
  CollapseStyled,
  ContainerContent,
  HeaderContent,
  ContainerSearch,
  ContainerTabs,
  TableContainer,
  ButtonContainer,
} from './style';

/**
 * This component create a card with resume info switchboard, circuits, name, description, disassociate circuits in UnityContainer Sidebar.
 */
export default function IoseSwitchboardShowCard({
  id,
  name_switchboard,
  goal,
  group,
  uuid_unity,
  showDashboard,
  showCircuit,
  desassociateCircuits,
  allCircuits,
  description,
  setNameSwitchboard,
  triggerCircuit,
  triggerCircuitAction,
  identification_number,
}) {
  const [tab, setTab] = useState(0);

  const [circuitsUnsubscribed, setChecked] = useState([]);
  const [circuitList, setCircuitList] = useState([]);

  //State to filter circuits
  const [circuitsFiltered, setCircuitsFiltered] = useState([]);
  const [search, setSearch] = useState(false);

  //Set collapse container
  const [expanded, setExpanded] = useState(false);

  const switchboardData = {
    uuid_group: id,
    circuits: circuitList,
    name: name_switchboard,
    goal: goal,
    uuid_unity: uuid_unity,
    description: description,
    identification_number: identification_number,
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      reloadingListcircuit();
    }
    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCircuits]);

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  //Function to update list circuit
  function reloadingListcircuit() {
    const circuitSelected = allCircuits.filter(
      (circuit) => circuit.uuid_group === id
    );

    setCircuitList(circuitSelected);

    circuitSelected.length === 0 && setTab(0);
  }

  //Function to filter Circuit
  function filterCircuit(circuitNameSearch) {
    const circuitNameSearchUppcase = circuitNameSearch.toUpperCase();

    //Clean old info search
    setCircuitsFiltered([]);

    if (circuitNameSearch === '') {
      return setSearch(false);
    } else {
      setSearch(true);

      const circuitFiltered = circuitList.filter((card) =>
        card.name.toUpperCase().includes(circuitNameSearchUppcase)
      );

      if (circuitFiltered.length !== 0) {
        setCircuitsFiltered(circuitFiltered);
      } else {
        setSearch(true);
      }
    }
  }

  function clickCircuitName(circuitData) {
    setNameSwitchboard(name_switchboard);
    showCircuit(circuitData, id);
  }

  const renderHeader = () => {
    const avatar = <AvatarStyled />;

    return (
      <Header>
        <HeaderTitle
          avatar={avatar}
          subheader={description}
          title={name_switchboard}
          onClick={() => showDashboard(switchboardData)}
        ></HeaderTitle>
        <IoseDashboardButton
          top="6px"
          tooltip="Entrar no  Quadro"
          onClick={() => showDashboard(switchboardData)}
        />
        <IoseCollapseButton
          top="6px"
          left="12px"
          onClick={() => setExpanded(!expanded)}
          primary={'true'}
          expand={expanded.toString()}
        />
      </Header>
    );
  };

  const renderCircuitTableSection = () => (
    <TableContainer>
      <IoseCircuitTable
        showCircuit={clickCircuitName}
        group={group}
        dashboard="false"
        triggerCircuit={triggerCircuit}
        triggerCircuitAction={triggerCircuitAction}
        rows={search ? circuitsFiltered : circuitList}
      />
    </TableContainer>
  );

  const renderDesassociateSection = () => {
    return (
      <>
        <IoseListCheck
          title=""
          height="220px"
          disabledList={false}
          selectAllInitial={false}
          selectCircuits={(e) => setChecked(e)}
          array={search ? circuitsFiltered : circuitList}
        />

        <ButtonContainer>
          <IoseButton
            onClick={(e) => desassociateCircuits(circuitsUnsubscribed)}
          >
            Desassociar
          </IoseButton>
        </ButtonContainer>
      </>
    );
  };

  const renderSection = () => {
    const hasPermission = group === 'super' || group === 'admin';

    if (tab === 0) {
      return renderCircuitTableSection();
    } else if (hasPermission) {
      return renderDesassociateSection();
    } else {
      return <p>{Messages.noPermissionDisassociate}</p>;
    }
  };

  const renderContent = () => {
    const empty = circuitList.length !== 0;

    return (
      <ContainerContent>
        <HeaderContent>
          <ContainerTabs>
            <StyledTabs value={tab} onChange={handleChange}>
              <Tab label="Circuitos" />
              <Tab label="Desassociar Circuitos" />
            </StyledTabs>
          </ContainerTabs>

          <ContainerSearch>
            <IoseSearch
              placeholder="Pesquisar Circuitos..."
              funcSearch={filterCircuit}
              sidebar={'true'}
              positionbutton="absolute"
            />
          </ContainerSearch>
        </HeaderContent>

        {empty ? renderSection() : <p>{Messages.noAssociateCircuits}</p>}
      </ContainerContent>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <Container>
        {renderHeader()}
        <CollapseStyled in={expanded}>{renderContent()}</CollapseStyled>
      </Container>
    </Grow>
  );
}

IoseSwitchboardShowCard.propTypes = {
  /** This prop is switchboard's uuid*/
  id: PropType.string,
  /** This prop is switchboard's name */
  name_switchboard: PropType.string,
  /** This prop is switchboard's goal */
  goal: PropType.number,
  /** This prop is the user group  */
  group: PropType.string,
  /** This prop is unity uuid that switchboard belong */
  uuid_unity: PropType.string,
  /** This prop is a function to redirect DashboardContainer*/
  showDashboard: PropType.func,
  /** This prop is a function to redirect IoseCircuitShowCard*/
  showCircuit: PropType.func,
  /** This prop is a function to desassociateCircuit in container*/
  desassociateCircuits: PropType.func,
  /** This prop is a array objects with all unity's circuits with this format:

      {
        description: "Descrição módulo 2",
        name: "Módulo 2",
        uuid_circuit: "2",
        uuid_group: "a8ee6231-f963-467e-98ea-fc6b55616c4e",
        uuid_unity: "f283ceb6-104b-4526-bf81-3056f9bb9ce1",
      }
   */
  allCircuits: PropType.array,
  /** This prop is switchboard's description*/
  description: PropType.string,
  /** This prop is function that set the name in IoseSubHeaderBar whith switchboard's name when user click in circuit name */
  setNameSwitchboard: PropType.func,
};
