import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseAdmDashChartUnity({ data }) {
  const seriesdata = data?.map((obj) => ({
    unity_name: obj?.unity_name,
    consumption_fatura: obj?.consumption_fatura,
    consumption_estimado: obj?.consumption_estimado,
    consumo_iose: obj?.consumption
  }));

  const sums = seriesdata.map(obj => ({
    unity_name: obj.unity_name,
    consumption_fatura: obj?.consumption_fatura,
    consumption_estimado: obj?.consumption_estimado,
    consumo_iose: obj?.consumo_iose,
    total: parseFloat(obj.consumption_fatura) + parseFloat(obj.consumo_iose) + parseFloat(obj.consumption_estimado)
  }));
  
  sums.sort((a, b) => a.total - b.total);
  const sortedSeriesData = sums.map(obj => obj.unity_name);

  const option = {
    dataZoom: [
      {
        type: 'slider', 
        yAxisIndex: 0, 
        start: 80, 
        end: 100, 
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow' 
      },
      formatter: function (params) {
        const formattedParams = params.map((param) => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value).toLocaleString('pt-BR')
              : param.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: sortedSeriesData,
      axisLabel: {
        width: 100, 
        overflow: 'truncate', 
        interval: 0
      }
    },
    series: [
      {
        name: 'Consumo fatura (kWh)',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: sums?.map((obj) => {
          return parseFloat((obj.consumption_fatura)).toFixed(2)
        })
      },
      {
        name: 'Consumo medido (kWh)',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: sums?.map((obj) => {
          return parseFloat((obj.consumo_iose)).toFixed(2)
        })
      },
      {
        name: 'Consumo Estimado (kWh)',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: sums?.map((obj) => {
          return parseFloat((obj.consumption_estimado)).toFixed(2)
        })
      },
    ]
  };

  return <ReactECharts option={option} style={{ height: 400, width: '100%' }} />;
}
