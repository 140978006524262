import React from 'react';
import PropType from 'prop-types';

//Imports from Material UI components
import {
  Container,
  GridContainer,
  GridItem,
  AvatarStyled,
  Header,
  GenerationEnergyIcon,
  ConsumptionEnergyIcon,
} from './style';

//Imports from Material Icons
// import PowerIcon from '@material-ui/icons/Power';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FlagIcon from '@material-ui/icons/Flag';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

/** This component create a card in dashboard with consumption and consumption value about circuit(s) selected */
export default function IoseDashboardInfoCircuitsSelected({
  consumptionTotal,
  moneyConsumption,
  generationCircuits,
}) {
  //Array info to render the GridItem with circuit info chosen in list circuit dashboard
  let info = [
    {
      name: 'Consumo',
      value: convertNumberToDecimalStyle(consumptionTotal) + ' kWh',
    },
    {
      name: 'Valor Consumido',
      value: convertNumberToMonetaryStyle(moneyConsumption),
    },
    {
      name: 'Geração',
      value: convertNumberToDecimalStyle(generationCircuits) + ' kWh',
    },
    // { name: 'Meta', value: goal + ' kWh' },
  ];

  function convertNumberToMonetaryStyle(number = 0) {
    // number = typeof number === 'string' && Number(number);

    const money = number.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  const renderInfo = (info) => {
    return (
      <GridItem key={info.name}>
        <AvatarStyled color={chooseColor(info.name)}>
          {info.name === 'Consumo' ? (
            <ConsumptionEnergyIcon />
          ) : info.name === 'Valor Consumido' ? (
            <AttachMoneyIcon />
          ) : info.name === 'Bandeira' ? (
            <FlagIcon />
          ) : info.name === 'Geração' ? (
            <GenerationEnergyIcon />
          ) : (
            <TrendingUpIcon />
          )}
        </AvatarStyled>
        <div>
          <h4>{info.name}</h4>
          <h2>{info.value}</h2>
        </div>
      </GridItem>
    );
  };

  function chooseColor(name) {
    let color = '';

    switch (name) {
      case 'Consumo':
        color = 'var(--secondary)';
        break;

      case 'Valor Consumido':
        color = 'var(--primary)';
        break;

      case 'Geração':
        color = '#FFF';
        break;

      default:
        color = 'white';
    }

    return color;
  }

  return (
    <Container>
      <Header>
        <h3>
          <OfflineBoltIcon />
          CIRCUITOS SELECIONADOS
        </h3>
        <p>
          Os somatórios abaixo referem-se ao período escolhido no gráfico de
          consumo.
        </p>
      </Header>
      <GridContainer>{info.map(renderInfo)}</GridContainer>
    </Container>
  );
}

IoseDashboardInfoCircuitsSelected.propTypes = {
  /** This props get the energy consumption of circuit selected. This info comes from container */
  consumptionTotal: PropType.oneOfType([PropType.string, PropType.number]),
  /** This props get the money of circuit selected. This info comes from container */
  moneyConsumption: PropType.oneOfType([PropType.string, PropType.number]),
};
