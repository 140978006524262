import React, { useEffect, useState, useContext } from 'react';
import { Grow } from '@material-ui/core/';
import { AccountContext, LocalStorage, ioseAPI } from 'common';
import * as S from './style';
import { Messages } from 'common'
import Permissions from 'common/permissions';
import GemeoDigitalMainTable from './componenets/gemeo-digital-table';
import {
  useClientData,
  useUnitys,
  useGroupPermissions,
} from 'hooks';
import {
  IoseInfinityScroll,
  CreateGemeoDigitalModal,
  IoseSearch,
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
} from 'components';

export default function GemeoDigitalContainer() {
  const [error, setError] = useState('');
  const [search, setSearch] = useState(false);
  const [filteredGemeos, setFilteredGemeos] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasBinLink, setHasBinLink] = useState(false);
  const [filteredUnitys, setFilteredUnitys] = useState([]);
  //eslint-disable-next-line
  const [hasEditPermission, setHasEditPermission] = useState();
  const[ gemeos, setGemeos] = useState([]);

  const { getSession } = useContext(AccountContext)
  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const addPath = ['cadastro', 'gemeo_digital', 'criar'];
    const editPath = ['cadastro', 'gemeo_digital', 'editar'];

    if (userSession && groupPermissions) {
      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (unitys !== undefined) {
      const bin_links = []
      unitys.forEach((unity) => {
        if (unity.bin_link !== "NULL") {
          bin_links.push(unity)
        }
      })
      if (bin_links.length > 0) {
        setHasBinLink(true)
      }
      setFilteredUnitys(bin_links)
    }
  }, [unitys])

  useEffect(() => {
    const getGemeos = async () => {
      if (unitys !== undefined) {
        try {
          const { headers } = await getSession();
          const avaliableGemeos = await Promise.all(unitys.map(async (unidade) => {
            const _gemeos =  await ioseAPI.getGemeoDigitalByUnity(headers, unidade.uuid_unity, 0, 300)
            const data = _gemeos.data.data || []
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                data[i]['unity_name'] = unidade.name
                data[i]['unityData'] = unidade
              }
            }
            return data
          }))

          setGemeos(avaliableGemeos.flat())
        }
        catch (error) {
          console.log(error)
        }
      }
    }

    getGemeos()
    //eslint-disable-next-line
  }, [unitys])



//   useEffect(() => {
//     environments?.length > 0
//       ? setCards(environments)
//       : setError(noEnvironmentsError);
//   }, [environments, noEnvironmentsError]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const filterGemeos = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppercase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = gemeos.filter((item) =>
        item.unity_name.toUpperCase().includes(searchNameUppercase)
      );

      setFilteredGemeos(itemFiltered);
      if (itemFiltered.length === 0) {
        setError(Messages.noFindEnvironments);
      }
    } else {
      setSearch(false);
      setFilteredGemeos([]);
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="Criar Gemeo Digital"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Gemeo Digital"
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Objetos do Gemeo Digital..."
          funcSearch={filterGemeos}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable  = () => {
    return (
      <GemeoDigitalMainTable data={search ? filteredGemeos : gemeos}/>
    )
  }

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={5}
            hasMore={true}
            endList={false}
            scrollableTarget={'container'}
          >
            {renderTable()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <CreateGemeoDigitalModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          unitys={hasBinLink ? filteredUnitys: unitys}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrapContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </S.WrapContainer>
    </Grow>
  );
}
