import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useUniqueConcessionaires } from 'hooks';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import { AccountContext, ioseAPI } from 'common';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import * as S from '../style';
// import exportBillItensToExcel from '../excelReport';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function EnergyBillLogsFilters({ loading, logs }) {
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { getSession } = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState(null)

  async function getFilteredLogValues(object) {
    loading(true)
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredEnergyBillLogs(
        sessionData.headers,
        object
      );

      const { data } = response.data;

      logs(data);
      loading(false)
    } catch (error) {
      console.log(error);
      loading(false)
    }
  }

  const initialValues = {
    concessionaire: '',
    reference_month: '',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);
    await getFilteredLogValues(filteredValues);
  };

  const cleanFilters = (resetForm) => {
    resetForm()
    setSelectedDate(null)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm, isSubmitting}) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDynamicSelect
                label="Concessionárias"
                value={values.concessionaire}
                onChange={(value) => setFieldValue('concessionaire', value)}
                options={uniqueConcessionaires}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isSubmitting}>
              FILTRAR
            </IoseButton>

            <IoseRemoveButton onClick={() => cleanFilters(resetForm)}>
            LIMPAR FILTROS
          </IoseRemoveButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
