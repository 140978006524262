import { useMutation } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';
import useUserAlarms from './useUserAlarms';

function useDeleteUserAlarms() {
  const { getSession } = useContext(AccountContext);
  const { refreshUsersAlarms } = useUserAlarms()

  const mutation = useMutation(
    async (uuid_item) => {
      const { headers } = getSession();

      return ioseAPI.deletarUsuariosXAlarmes(
        headers,
        uuid_item,
      );
    },
    {
      onSuccess: () => {
        refreshUsersAlarms()
      },
      onError: (error) => {
        console.error(Messages.errorDeleteUserAlarm, error);
      },
    }
  );

  return mutation;
}

export default useDeleteUserAlarms;
