import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseAdmDashChartGeracao({ data }) {
  const seriesdata = data?.map((obj) => ({
    date: obj?.date,
    gerado: obj?.gerado,
    consumido: obj?.consumido
  }));

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow' 
      },
      formatter: function (params) {
        const formattedParams = params.map((param) => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value).toLocaleString('pt-BR')
              : param.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: {
      type: 'value',
    },
    xAxis: {
      type: 'category',
      data: seriesdata?.map((obj)=>{
        return obj.date
      }),
      axisLabel: {
        width: 100, 
        overflow: 'truncate', 
        interval: 0
      }
    },
    series: [
      {
        name: 'Geração (kWh)',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: seriesdata?.map((obj) => {
          return parseFloat((obj.gerado)).toFixed(2)
        })
      },
      {
        name: 'Consumo (kWh)',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: seriesdata?.map((obj) => {
          return parseFloat((obj.consumido)).toFixed(2)
        })
      },
    ]
  };

  return <ReactECharts option={option} style={{ height: 400, width: '100%' }} />;
}
