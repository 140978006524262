import styled from 'styled-components';
import IoseContainer from 'components/IoseContainer';
import IoseButton from 'components/Buttons/IoseButton';
import { CircularProgress } from '@material-ui/core';

import { Grid, Collapse, Checkbox } from '@material-ui/core';

export const GridContainer = styled(Grid)`
  padding-right: 20px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const IoseContainerStyled = styled(IoseContainer)`
  width: 100%;
  overflow-y: scroll;
  overflow-x: none !important;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IoseButtonStyled = styled(IoseButton)`
  display: flex;
  margin-left: 12px !important;
  background: #fcfcfc;

  .MuiButton-label {
    color: var(--secondary);
  }

  p {
    color: var(--secondary);
    margin-left: 8px;
  }
`;

export const CollapseStyled = styled(Collapse).attrs(() => ({
  unmountOnExit: true,
}))`
  width: auto;
  background-color: white;
  border-radius: 4px;
  margin: 8px 0px;
  transition: all 0.3 linear;
`;

export const GridContainerFilter = styled(Grid).attrs(() => ({
  container: true,
}))`
  padding: 2% 20%;

  @media (max-width: 767px) {
    padding: 2% 10%;
  }
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 12,
  md: 5,
  lg: 5,
}))`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: 40px;
    padding-left: ${(props) => (props.period === 'true' ? '10%' : '0%')};
  }
`;

export const ContainerFilterButton = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 12,
  md: 2,
  lg: 2,
}))`
  display: flex;

  padding: 20px 0px;

  @media (max-width: 767px) {
    /* padding-left: 30px; */
  }
`;

export const AlertEvents = styled.p`
  color: red;
  padding-bottom: 1%;
  text-align: center;
  font-weight: bolder;
  width: 100%;
`;

export const CheckBoxStyled = styled(Checkbox)`
  margin-right: 12px;
  height: 40px;

  .MuiSvgIcon-root {
    color: var(--primary);
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  height: 16px !important;
  width: 16px !important;
  text-align: center;
`;
