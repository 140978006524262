import styled from 'styled-components';
import { Avatar, CardHeader, CardContent, Card } from '@material-ui/core';

export const CardStyled = styled(Card)`
  width: auto;
  height: ${(props) => (props.open ? 'auto' : '80px')};
  margin: 0px 15px 15px 0px;
  padding: 16px;
  border: solid 2px transparent;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer;

  &:hover {
    border: solid 2px var(--primary);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DivHeaderButton = styled.div`
  padding: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;

  span {
    white-space: pre-wrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-title {
    p {
      font-size: 13px !important;
      line-height: 14px;
      max-height: 28px;
      font-weight: 600;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      transition: all 0.3s ease-in-out;
      color: var(--primary);
    }
  }

  .MuiCardHeader-subheader {
    p {
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px !important;
    }
  }
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--secondary);
`;

export const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px !important;
  /* height: 35px; */
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
`;

export const DivMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px;
  width: 100%;
`;

export const DivWrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0 10px 0;
  background-color: #ecf0f5;
  border-radius: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const DivInfos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-bottom: 2px;
  font-family: 'Montserrat', sans-serif;

  p {
    margin: 0;
    width: 100%;

    strong {
      margin-right: 10px;
    }
  }
`;
