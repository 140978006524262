import React, { useEffect, useState } from 'react';

import { useClient, LocalStorage } from 'common';

import { WrappedContainer } from './style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { useLocation } from 'react-router-dom';

import { IoseSubHeaderBar, LoadingIndicator } from 'components';

import { useBeneficiaryPerMonth } from 'hooks';
import { IoseAuditRedirectButton } from 'components/IoseButtonIcon';
import { CreditBeneficiarioTable } from 'components/IoseTables';

export default function CreditBeneficiarioContainer() {
  const { client } = useClient();
  const { getBeneficiaryPerMonthData } = useBeneficiaryPerMonth();
  const location = useLocation();

  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [loading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { rowData } = location.state;

  const ucBeneficiaria = rowData?.uc_beneficiaria;

  useEffect(() => {
    const data = getBeneficiaryPerMonthData || [];

    const uniqueMesCredGerado = new Set();

    const filteredData = data.filter((item) => {
      if (
        item.uc_beneficiaria === ucBeneficiaria &&
        item.mes_cred_gerado !== 'NULL'
      ) {
        const isUnique = !uniqueMesCredGerado.has(item.mes_cred_gerado);

        if (isUnique) {
          uniqueMesCredGerado.add(item.mes_cred_gerado);
          return true;
        }
      }

      return false;
    });

    setFilteredData(filteredData);
  }, [getBeneficiaryPerMonthData, ucBeneficiaria]);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const history = useHistory();

  const handleCredit = () => {
    history.push('/credit');
  };

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line
  }, []);

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Unidade Beneficiária ${ucBeneficiaria}`}
        button={<></>}
        style={{ marginTop: '10px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseAuditRedirectButton
            tooltip="Voltar para Unidades Beneficiárias."
            bottom="5px"
            onClick={handleCredit}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <CreditBeneficiarioTable data={filteredData} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>{renderTableorImage()}</>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </WrappedContainer>
  );
}
