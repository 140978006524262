// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useGroupPermissions() {
  const [noPermissionsError, setNoPermissionsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getGroupPermissions = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllGroupPermissions(
      headers,
      10000,
      page_size
    );

    const groupPermissions = response.data.data || [];

    if (groupPermissions.length === 0) {
      setNoPermissionsError(Messages.noRegisteredPermissions);
      throw new Error(Messages.noRegisteredPermissions);
    }
    return groupPermissions;
  };

  const {
    data: groupPermissions,
    error: groupPermissionsError,
    refetch: refreshPermissions,
    isLoading: loadingPermissions,
  } = useQuery(['groupPermissionsData'], getGroupPermissions);

  return {
    groupPermissions,
    groupPermissionsError,
    noPermissionsError,
    refreshPermissions,
    loadingPermissions,
  };
}

export default useGroupPermissions;
