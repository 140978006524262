import React from 'react';
import PropType from 'prop-types';
//Imports from Material UI
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  List,
} from '@material-ui/core';

//Import from Messages
import Messages from '../../common/Messages';

//Imports from IOSE Components
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

//Imports from Style
import { ListContainer, ListItemTextStyled, Container, Header } from './style';
import { IoseEditButton } from 'components/IoseButtonIcon';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';

/**
 * This component create a card with list admins that user can edit or delete them.
 */
export default function IoseShowAdminsCard({
  onClickDeletAdmin,
  onClickEditAdmin,
  admins,
  getMoreAdmin,
  loading,
  loadingMore,
  endList,
}) {
  const renderRowsList = (admin) => {
    return (
      <ListItem key={admin.sub}>
        <ListItemTextStyled primary={admin.name} secondary={admin.email} />
        <ListItemSecondaryAction>
          <IoseEditButton
            onClick={(e) =>
              onClickEditAdmin(admin.uuid_admin, admin.name, admin.email)
            }
          />

          <IoseDeleteInputModal
            title="DELETAR ADMINISTRADOR"
            name={admin.name}
            deletefunc={(e) => onClickDeletAdmin(admin.uuid_admin)}
            left={'10px'}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Container>
      <Header>
        <h3>Lista de Cadastrados</h3>
      </Header>
      <ListContainer id={'listContainer'}>
        {admins.length === 0 ? (
          <p>{Messages.noAdminsRegistered}</p>
        ) : (
          <IoseInfinityScroll
            dataLength={admins.length}
            next={(e) => getMoreAdmin()}
            hasMore={true}
            loading={loadingMore}
            endList={endList}
            sidebar={true}
            scrollableTarget={'listContainer'}
          >
            <ListItemText>
              <List dense={true}>{admins.map(renderRowsList)}</List>
            </ListItemText>
          </IoseInfinityScroll>
        )}
      </ListContainer>
    </Container>
  );
}

IoseShowAdminsCard.propTypes = {
  /** This prop is function to delete admin in container */
  onClickDeletAdmin: PropType.func,
  /** This prop is function to edit admin in container */
  onClickEditAdmin: PropType.func,
  /** This prop is a arry with objects in this format <br>

    {
      email: "admiose2020@gmail.com"
      name: "Administrador Dev"
      uuid_admin: "f1e40dc6-d9e5-4b4b-adfb-a35b7bc5c915"
      uuid_client: "daf4abcb-429d-4298-a9a6-ce7517fb1449"
     }
  */
  admins: PropType.array,
  /** This prop to get more admin whe user arrives in end list, it is related InfinityScroll*/
  getMoreAdmin: PropType.func,
  /** This prop is spin that indicates laod list */
  loading: PropType.bool,
  /** This prop is spin that indicates laod admins in list, it is related InfinityScroll */
  loadingMore: PropType.bool,
  /** This prop indicate the end of list, it is related InfinityScroll*/
  endList: PropType.bool,
};
