import React, { useEffect, useContext, useState } from 'react';
// import PropType from 'prop-types';

//Hook to redirect beetween container
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';

//Imports from Accounts
// import { AccountContext } from 'common/contexts/Accounts';

//Import from Messages
// import Messages from 'common/Messages';

//Import from Material UI
import { Grow } from '@material-ui/core/';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { ioseAPI } from 'common/ioseAPI';

//Import from IOSE
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import IoseSearch from 'components/IoseSearch';
import { IoseAddButton } from 'components/IoseButtonIcon';
import IoseSchedulerTable from 'components/IoseSchedulerTable';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseAlertLoadingCards } from 'components/IoseAlert';

//Imports from style
import { GridContainer, IoseContainerStyled } from './style';

/**This component create a container for show, edit and delete  unity schedulings */
export default function SchedulerContainer({
  group,
  openSide,
  getcards,
  setGetcards,
}) {
  let location = useLocation();

  const history = useHistory();
  const { path } = useRouteMatch();

  //This function return session data
  const { getSession } = useContext(AccountContext);

  const unityData = location.state.unityData;
  const unity_name = location.state.unityData.name;
  const clientName = location.state.clientName;
  const uuid_client = location.state.uuid_client;

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //State to get all switchboards of Unit
  const [allGroups, setAllGroups] = useState('');

  //State to get all circuits of Unit
  const [allCircuits, setAllCircuits] = useState('');

  //State to get all schedulings of Unit
  const [allSchedulings, setAllSchedulings] = useState('');

  //State to filter schedulings
  const [allSchedulingsFiltered, setAllSchedulingsFiltered] = useState([]);
  const [search, setSearch] = useState(false);

  //State to set notification about info requisit scheduling
  const [notification, setNotification] = useState({
    status: false,
    message: '',
  });

  useEffect(() => {
    if (unityData.uuid_unity !== '' && location.pathname.includes('schedule')) {
      getAllGroups();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unityData.uuid_unity]);

  useEffect(() => {
    if (unityData.uuid_unity !== '' && location.pathname.includes('schedule')) {
      getAllCircuits();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unityData.uuid_unity]);

  useEffect(() => {
    if (
      unityData.uuid_unity !== '' &&
      location.pathname.includes('schedule') &&
      getcards
    ) {
      getAllSchedulings();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unityData.uuid_unity, getcards]);

  //Functions to GET infos
  async function getAllGroups() {
    const sessionData = await getSession();

    const response = await ioseAPI.getAllGroup(
      sessionData.headers,
      unityData.uuid_unity,
      0,
      200
    );

    let allGroups = response.data.data[0] === null ? [] : response.data.data;
    setAllGroups(allGroups);
  }

  async function getAllCircuits() {
    const sessionData = await getSession();

    const response = await ioseAPI.getAllCircuit(
      sessionData.headers,
      unityData.uuid_unity,
      0,
      1000
    );
    let allCircuits = response.data.data[0] === null ? [] : response.data.data;

    const allCircuitsRelay = allCircuits.filter((circuit) => circuit.has_relay);

    setAllCircuits(allCircuitsRelay);
  }

  async function getAllSchedulings() {
    setLoading(true);
    setNotification({
      status: false,
      message: '',
    });

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getSchedulingGroup(
        sessionData.headers,
        unityData.uuid_unity,
        0,
        2000
      );

      let allSchedulings = response.data.data && response.data.data;

      allSchedulings.length === 0 &&
        setNotification({
          status: true,
          message: 'Não possui agendamentos cadastrados!',
        });

      setAllSchedulings(allSchedulings);
      setLoading(false);
      setGetcards(false);
    } catch (error) {
      let messageError = error.response
        ? error.response.data.message
        : 'Error ao buscar agendamentos!';
      setLoading(false);
      setNotification({ status: true, message: messageError });
    }
  }

  async function getSchedulingModulesByGroup(uuid_scheduling_group) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getSchedulingModulesByGroup(
        sessionData.headers,
        uuid_scheduling_group,
        0,
        2000
      );

      return { result: true, message: response.data && response.data.data };
    } catch (error) {
      setLoading(false);
      const message = error.response
        ? error.response.data.message
        : 'Erro ao buscar circuitos relacionados ao agendamento';

      return {
        result: false,
        message: message,
      };
    }
  }

  //Functions to REDIRECT
  const redirectCreateScheduler = () => {
    history.push({
      pathname: `${path}/add`,
      state: {
        unityData: unityData,
        uuid_client: uuid_client,
        clientName: clientName,
        allGroups: allGroups,
        allCircuits: allCircuits,
      },
    });
    allGroups && allCircuits && openSide();
  };

  const redirectEditSheduler = (scheduleData) => {
    history.push({
      pathname: `${path}/edit`,
      state: {
        scheduleData: scheduleData,
        unityData: unityData,
        uuid_client: uuid_client,
        clientName: clientName,
        allGroups: allGroups,
        allCircuits: allCircuits,
      },
    });
    openSide();
  };

  const redirectDeleteSheduler = async (uuid_scheduling_group) => {
    let response = await getSchedulingModulesByGroup(uuid_scheduling_group);

    const scheduleData = {
      uuid_scheduling_group: uuid_scheduling_group,
      uuid_unity: unityData.uuid_unity,
    };

    response.result
      ? redirectValidation(response.message, 'delete', scheduleData)
      : redirectValidation([], 'delete', scheduleData);
  };

  const redirectValidation = (circuitsSelecteds, form_what, scheduleData) => {
    history.push({
      pathname: `${path}/validation`,
      state: {
        clientName: clientName,
        uuid_client: uuid_client,
        unityData: unityData,
        allGroups: allGroups,
        allCircuits: allCircuits,
        circuitsSelecteds: circuitsSelecteds,
        form_what: form_what,
        scheduleData: scheduleData,
      },
    });
    openSide();
  };
  ////////////////////////////////

  //Function to filter Schedulings in search
  function filterSchedulings(schedulingNameSearch) {
    setLoading(true);

    const schedulingNameSearchUp = schedulingNameSearch.toUpperCase();

    //Clean old info search
    setAllSchedulingsFiltered([]);

    //Clean any erro
    setNotification({ status: false, message: '' });

    if (schedulingNameSearch === '') {
      setLoading(false);
      setNotification({ status: false, message: '' });
      setSearch(false);
    } else {
      setLoading(false);
      setSearch(true);

      const schedulingsFiltered = allSchedulings.filter((scheduling) =>
        scheduling.name.toUpperCase().includes(schedulingNameSearchUp)
      );

      if (schedulingsFiltered.length !== 0) {
        setAllSchedulingsFiltered(schedulingsFiltered);
      } else {
        setAllSchedulingsFiltered([]);
        setNotification({
          status: true,
          message: 'Nenhum agendamento encontrado',
        });
      }
    }
  }

  async function resendRequistion(
    uuid_scheduling_module,
    uuid_scheduling_group,
    action
  ) {
    try {
      const sessionData = await getSession();
      let response = {};

      if (action === 'update') {
        response = await ioseAPI.retryUpdateScheduling(
          sessionData.headers,
          uuid_scheduling_group,
          uuid_scheduling_module
        );
      } else {
        response = await ioseAPI.retryCreateScheduling(
          sessionData.headers,
          uuid_scheduling_module
        );
      }

      const responseMessage = response.data && response.data.data;

      return { result: true, message: responseMessage };
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : 'Erro ao reenviar requisição!';

      return {
        result: false,
        message: message,
      };
    }
  }

  const button =
    group === 'super' || group === 'admin' || group === 'manager' ? (
      <IoseAddButton
        onClick={redirectCreateScheduler}
        tooltip="Criar Agendamento"
        bottom="0px"
      />
    ) : (
      <> </>
    );

  return (
    <>
      <Grow in={true} {...{ timeout: 1000 }}>
        <IoseContainerStyled>
          <IoseSubHeaderBar
            title={unity_name}
            subtitle="Agendamentos"
            description={'Total: ' + allSchedulings.length}
            button={button}
          >
            <IoseSearch
              placeholder="Pesquisar Agendamentos…"
              funcSearch={filterSchedulings}
            />
          </IoseSubHeaderBar>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <GridContainer>
              {notification.status ? (
                <IoseAlertLoadingCards text={notification.message} />
              ) : (
                allSchedulings !== '' && (
                  <IoseSchedulerTable
                    rows={search ? allSchedulingsFiltered : allSchedulings}
                    dashboard="true"
                    group={group}
                    redirectEditSheduler={redirectEditSheduler}
                    deleteScheduler={redirectDeleteSheduler}
                    getSchedulingModulesByGroup={getSchedulingModulesByGroup}
                    resendRequistion={resendRequistion}
                  />
                )
              )}
            </GridContainer>
          )}
        </IoseContainerStyled>
      </Grow>
    </>
  );
}
