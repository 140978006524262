/* eslint-disable camelcase */
/* eslint-disable no-useless-concat */

export async function getTotalTele(src_telemetria_pontos) {
  try {
    const response = await fetch(src_telemetria_pontos?.getUrl());
    const data = await response.json();
    const totalFeatures = data?.totalFeatures;
    return typeof totalFeatures === 'number' ? totalFeatures : 0;
  } catch (error) {
    return 0;
  }
}

export async function getTotalFeatures(src_alarms) {
  try {
    const response = await fetch(src_alarms?.getUrl());
    const data = await response?.json();

    let trueCount = 0;
    let falseCount = 0;
    let quedaEnergiaCount = 0;
    let semMedicaoCount = 0;
    let totalMaisDe24Horas = 0;

    const agora = new Date();

    for (const feature of data?.features) {
      const { queda_energia, sem_medicao, created_at } = feature?.properties;

      if (queda_energia === true || queda_energia === 1) {
        quedaEnergiaCount++;
      }
      if (sem_medicao === true || sem_medicao === 1) {
        semMedicaoCount++;
      }

      if (
        queda_energia === true ||
        queda_energia === 1 ||
        sem_medicao === true ||
        sem_medicao === 1
      ) {
        falseCount++;
      } else {
        trueCount++;
      }

      // Verifica se created_at é maior que 24 horas
      if (created_at) {
        const dataCriacao = new Date(created_at);

        const diferencaHoras = (agora - dataCriacao) / (1000 * 60 * 60);
        if (diferencaHoras > 24) {
          totalMaisDe24Horas++;
        }
      }
    }
    let somaTotais = quedaEnergiaCount + semMedicaoCount;

    somaTotais -= totalMaisDe24Horas;

    return {
      totalFeatures: data?.features?.length,
      trueCount,
      falseCount,
      quedaEnergiaCount,
      semMedicaoCount,
      somaTotais,
      totalMaisDe24Horas,
    };
  } catch (error) {
    // console.error('Error retrieving totalFeatures:', error);

    return {
      totalFeatures: 0,
      trueCount: 0,
      falseCount: 0,
      quedaEnergiaCount: 0,
      semMedicaoCount: 0,
      somaTotais: 0,
      totalMaisDe24Horas: 0,
    };
  }
}

export async function getLastMeasureDate(src_alarms) {
  try {
    const response = await fetch(src_alarms?.getUrl());
    const data = await response.json();
    let notNullCount = 0;
    let nullCount = 0;

    for (const feature of data.features) {
      if (feature.properties?.last_date !== null) {
        notNullCount++;
      } else {
        nullCount++;
      }
    }
    return {
      totalFeatures: data?.totalFeatures,
      notNullCount,
      nullCount,
    };
  } catch (error) {
    return {
      totalFeatures: 0,
      notNullCount: 0,
      nullCount: 0,
    };
  }
}

export async function getMedindo(src_medindo) {
  const url = src_medindo?.getUrl();

  if (!url) {
    return {
      totalFeatures: 0,
      notNullCount: 0,
      nullCount: 0,
    };
  }

  try {
    const response = await fetch(url);
    const text = await response.text(); // Obtendo a resposta como texto

    // Extrair o JSON do texto
    const jsonText = text.replace(/^parseResponse\(/, '').replace(/\);?$/, '');
    // Fazer o parsing do JSON
    const data = JSON.parse(jsonText);

    let notNullCount = 0;
    let nullCount = 0;

    for (const feature of data?.features || []) {
      if (feature.properties?.last_date !== null) {
        notNullCount++;
      } else {
        nullCount++;
      }
    }

    return {
      totalFeatures: data?.totalFeatures || 0,
      notNullCount,
      nullCount,
    };
  } catch (error) {
    return {
      totalFeatures: 0,
      notNullCount: 0,
      nullCount: 0,
    };
  }
}

export async function getTotalFeaturesregiao(url) {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data?.totalFeatures;
  } catch (error) {
    return 0;
  }
}
