export const TariffsConcessionaires = [
  { label: 'Energisa AC', value: 'Energisa AC' },
  { label: 'Energisa MT', value: 'Energisa MT' },
  { label: 'Energisa PB', value: 'Energisa PB' },
  { label: 'Energisa SE', value: 'Energisa SE' },
  { label: 'Energisa MG', value: 'Energisa MG' },
  { label: 'Energisa MS', value: 'Energisa MS' },
  { label: 'Energisa MT', value: 'Energisa MT' },
  { label: 'Energisa RO', value: 'Energisa RO' },
  { label: 'Energisa TO', value: 'Energisa TO' },
  { label: 'Energisa Nova Friburgo', value: 'Energisa Nova Friburgo' },
  { label: 'Energisa Borborema', value: 'Energisa Borborema' },
  { label: 'Cemig-D', value: 'Cemig-D' },
  { label: 'Cemirim', value: 'Cemirim' },
  { label: 'Coorsel', value: 'Coorsel' },
  { label: 'Copel-DIS', value: 'Copel-DIS' },
  { label: 'CPFL Leste Paulista', value: 'CPFL Leste Paulista' },
  { label: 'CPFL Mococa', value: 'CPFL Mococa' },
  { label: 'CPFL Paulista', value: 'CPFL Paulista' },
  { label: 'CPFL Piratininga', value: 'CPFL Piratininga' },
  { label: 'CPFL Santa Cruz', value: 'CPFL Santa Cruz' },
  { label: 'CPFL Sul Paulista', value: 'CPFL Sul Paulista' },
  { label: 'Enel CE', value: 'Enel CE' },
  { label: 'Enel RJ', value: 'Enel RJ' },
  { label: 'Neoenergia Coelba', value: 'Neoenergia Coelba' },
  { label: 'Neoenergia Cosern', value: 'Neoenergia Cosern' },
  { label: 'Sulgipe', value: 'Sulgipe' },
  { label: 'Light', value: 'Light' },
  { label: 'Equatorial AL', value: 'Equatorial AL' },
  { label: 'Cemirim', value: 'Cemirim' },
];
