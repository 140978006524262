import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Select, MenuItem, Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as S from './style';
import { TextFieldStyled, IoseModalButton } from 'components';
import { AccountContext, ioseAPI } from 'common';
import { useCircuits, useClientData, useCreateLogs, useEnvironments } from 'hooks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: 'white',
    opacity: '70%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function CreateGemeoDigitalModal({
  open,
  onClose,
  unitys,
}) {
  const classes = useStyles();
  const { environments } = useEnvironments()
  const { uuidClient } = useClientData()
  const { circuits } = useCircuits(uuidClient)

  const { createLog } = useCreateLogs()

  const { getSession } = useContext(AccountContext)

  const [loading, setLoading] = useState(false);
  const [avaliableEnvironments, setAvaliableEnvironments] = useState([])
  const [selectedCircuits, setSelectedCircuits] = useState([]);
  const [selectedUnity, setSelectedUnity] = useState("")
  const [selectedEnvironment, setSelectedEnvironment] = useState("")
  const [avaliableCircuits, setAvaliableCircuits] = useState([])
  const [objects, setObjects] = useState([])
  const [avaliableGroups, setAvaliableGruop] = useState([])



  const handleAddCircuits = (e) => {
    setSelectedCircuits(e.target.value);
  };

  const onEnvironmentChange = async(e) => {
    try {
      const { headers } = await getSession();

      if (environments !== undefined && selectedUnity !== undefined) {
        const objetos = await Promise.all(environments.map(async (ambiente) => {
          if (ambiente.uuid_unity === selectedUnity && e.target.value === ambiente.uuid_environment) {
            const _objetos =  await ioseAPI.obterObjetoComUUIDAmbiente(headers, ambiente.uuid_environment, 0, 300)
            return _objetos.data.Items || []
          }
          return [];
        }))

        setSelectedEnvironment(e.target.value)
        setObjects(objetos.flat())
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onUnityChange = (e) => {
    const circuitos = ['']
    circuits?.forEach((circuito) => {
      if(circuito.uuid_unity === e.target.value) {
        circuitos.push(circuito)
      }
    })

    setAvaliableCircuits(circuitos)
    setSelectedUnity(e.target.value)
  }

  const onIsGroupChange = async(e) => {
    if (e.target.value === '1') {
      setAvaliableEnvironments([])
      setObjects([])
      const { headers } = await getSession();
      const quadros = await ioseAPI.getAllGroup(headers, selectedUnity, 0 , 300)

      const avaliableQuadros = quadros.data.data || []
      setAvaliableGruop(avaliableQuadros)
    }
    else if (e.target.value === '0'){
      setAvaliableGruop([])
      const ambientes = ['']
      environments?.forEach((ambiente) => {
        if (ambiente.uuid_unity === selectedUnity) {
          ambientes.push(ambiente)
        }
      })
      setAvaliableEnvironments(ambientes)
    }
  };



  async function addGemeoDigital(id_3d, uuid_object, uuid_unity, uuid_environment, uuid_group, uuid_circuit, is_iose) {
    setLoading(true);
    let env = '0'
    if (selectedEnvironment !== '') {
      env = selectedEnvironment
    }
    try {
      const { headers } = await getSession();
      await ioseAPI.createGemeoDigital(
        headers,
        id_3d,
        uuid_object,
        selectedUnity,
        env,
        uuid_group,
        selectedCircuits,
        (is_iose === '1' ? true : false)
      );
      const operacao = 'CRIAR';
      const form = 'CRIAR OBJETO DE GEMEO DIGITAL';
      const logContent = {
        id_3d: id_3d,
        uuid_object: uuid_object,
        uuid_unity: selectedUnity,
        uuid_environment: env,
        uuid_group: uuid_group,
        uuid_circuit: uuid_circuit,
        is_iose: (is_iose === '1' ? true : false)
      };
      await createLog(operacao, form, logContent);
      setLoading(false);
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
      onClose();
      // history.go(0)
    }
  }

  const handleCreateGemeoDigital = (values) => {
    setTimeout(() => {
      addGemeoDigital(
        values.id_3d,
        values.object,
        values.unity,
        values.environment,
        values.group,
        values.circuit,
        values.isIose
      );
    }, 500);
  };

  return (

    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                GEMEO DIGITAL
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                CADASTRO DE OBJETOS DO GEMEO DIGITAL
              </S.ModalTitle>
              <Formik
                initialValues={{
                  id_3d: '',
                  object: '0',
                  unity: '',
                  environment: '0',
                  circuit: [''],
                  group: '0',
                  isIose: '0'
                }}
                onSubmit={handleCreateGemeoDigital}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="unity-label">
                          Unidade
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="unity"
                          labelId="unity-label"
                          id="unity-select"
                          className={classes.whiteText}
                          onChange={onUnityChange}
                          value={selectedUnity}
                        >
                          {unitys?.map((unity) => (
                            <MenuItem
                              key={unity.uuid_unity}
                              value={unity.uuid_unity}
                            >
                              {unity.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>
                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="is-group-label">
                          É quadro de força?
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="is-group"
                          labelId="is-group-label"
                          id="is-group-select"
                          className={classes.whiteText}
                          onChange={onIsGroupChange}
                        >
                        <MenuItem
                          key={'1'}
                          value={'1'}
                        >
                          Sim
                        </MenuItem>
                        <MenuItem
                          key={'0'}
                          value={'0'}
                        >
                          Não
                        </MenuItem>

                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>
                    <S.DivInputs>
                    {avaliableGroups[0] !== undefined   && (<S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="group-label">
                          Quadro de força
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="group"
                          labelId="group-label"
                          id="group-select"
                          className={classes.whiteText}
                        >
                          {avaliableGroups?.map((group) => (
                            <MenuItem
                              key={group.uuid_group}
                              value={group.uuid_group}
                            >
                              {group.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    )}
                    </S.DivInputs>

                    <S.DivInputs>
                      {avaliableEnvironments[0] !== undefined  && (<S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="environment-label">
                          Ambiente
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="environment"
                          labelId="environment-label"
                          id="environment-select"
                          className={classes.whiteText}
                          onChange={onEnvironmentChange}
                          value={selectedEnvironment}
                        >
                          {avaliableEnvironments?.map((environment) => (
                            <MenuItem
                              key={environment.uuid_environment}
                              value={environment.uuid_environment}
                            >
                              {environment.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                      )}
                    </S.DivInputs>

                    <S.DivInputs>
                    {objects[0] !== undefined   && (<S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="object-label">
                          Objeto
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="object"
                          labelId="object-label"
                          id="object-select"
                          className={classes.whiteText}
                        >
                          {objects?.map((object) => (
                            <MenuItem
                              key={object.uuid_object}
                              value={object.uuid_object}
                            >
                              {object.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    )}
                    </S.DivInputs>

                    <S.DivInputs>
                      {avaliableCircuits[0] !== undefined   && (<S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="circuit-label">
                          Circuitos
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          multiple
                          name="circuit"
                          labelId="circuit-label"
                          id="circuit-select"
                          className={classes.whiteText}
                          value={selectedCircuits}
                          onChange={handleAddCircuits}
                        >
                          {avaliableCircuits?.map((circuit) => (
                            <MenuItem
                              key={circuit.uuid_circuit}
                              value={circuit.uuid_circuit}
                            >
                              {circuit.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                      )}
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'id_3d'}
                        name={'id_3d'}
                        type={'text'}
                        label={'ID 3D'}
                        defaultvalue={initialValues['id_3d']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>
                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="isIoseLabel">
                          É ligado diretamente a um IOSE?
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="isIose"
                          labelId="isIoseLabel"
                          id="isIoseSelect"
                          className={classes.whiteText}
                        >
                        <MenuItem
                          key={'1'}
                          value={'1'}
                        >
                          Sim
                        </MenuItem>
                        <MenuItem
                          key={'0'}
                          value={'0'}
                        >
                          Não
                        </MenuItem>

                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Objeto no Gemeo Digital
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
