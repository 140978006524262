import React, { useEffect, useState } from 'react';
import * as S from './style';
import {
  AttachMoney as AttachMoneyIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';
import { DivPaper } from 'styles/defaultStyles';
import { useAlarmsDisplayed, useSystemUrls } from 'hooks';
import { NewLoadingValueCards } from 'components/IoseLoader';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { Tooltip } from '@material-ui/core';
import AlarmsNotDisplayedModal from 'containers/TelemetryDashContainer/components/AlarmsNotDisplayedModal';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useHistory } from 'react-router-dom';

const formatNumber = (number) => {
  return number?.toLocaleString();
};

export default function IoseCardsTelemetry({ consumos, loading }) {
  const [totalTele, setTotalTele] = useState(0);
  const [totalAlarmes, setTotalAlarmes] = useState(0);
  const [totalMedindo, setTotalMedindo] = useState(0);
  const [totalQueda, setTotalQueda] = useState(0);
  const [totalSemMedicao, setTotalSemMedicao] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { systemUrls } = useSystemUrls();
  const { alarmsDisplayed } = useAlarmsDisplayed();
  const history = useHistory();

  async function fetchTotal(url, isTotalMedindo = false) {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const totalFeatures = data.features.reduce((total, feature) => {
        if (!isTotalMedindo || feature.properties.last_date !== null) {
          return total + 1;
        } else {
          return total;
        }
      }, 0);
      return totalFeatures;
    } catch (error) {
      console.error('Erro ao buscar o total:', error);
      return 0;
    }
  }

  async function getGeoserverInfoAlarms(url, filter) {
    const completeUrl = `${url}${filter}`;

    try {
      const response = await fetch(completeUrl);
      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }

      const data = await response.json();

      return data?.totalFeatures || 0;
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
      return 0;
    }
  }

  const alarmsUrl =
    'https://geoserver-nexum.com/geoserver/Sanepar/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=Sanepar%3Aview_alarms_telemetry_3&outputFormat=application%2Fjson';

  useEffect(() => {
    const fetchAndUpdateTotals = async () => {
      try {
        const [
          teleData,
          alarmDataResponse,
          medindoData,
          totalQueda,
          totalSemMedicao,
        ] = await Promise.all([
          fetchTotal(
            systemUrls?.find(
              (item) => item.attribute === 'map_url_total_telemetria'
            )?.url
          ),
          fetch(alarmsUrl), // Fetching the raw response
          fetchTotal(
            systemUrls?.find((item) => item.attribute === 'map_view_medindo_2')
              ?.url,
            true
          ),
          getGeoserverInfoAlarms(alarmsUrl, '&cql_filter=queda_energia=true'),
          getGeoserverInfoAlarms(alarmsUrl, '&cql_filter=sem_medicao=true'),
        ]);

        const alarmData = await alarmDataResponse.json(); // Parsing the JSON response

        if (alarmData && alarmData.features) {
          const twentyFourHoursAgo = new Date();
          twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

          const recentAlarms = alarmData.features.filter((feature) => {
            const createdAt = new Date(feature.properties.created_at);
            return createdAt > twentyFourHoursAgo;
          });
          const numRecentAlarms = recentAlarms.length;

          setTotalTele(teleData);
          setTotalAlarmes(numRecentAlarms);
          setTotalMedindo(medindoData);
          setTotalQueda(totalQueda);
          setTotalSemMedicao(totalSemMedicao);
        } else {
        }
      } catch (error) {
        console.error('Erro ao buscar os totais:', error);
      }
    };

    fetchAndUpdateTotals();
  }, [systemUrls]);

  function redirectToConsumerGrouDash(uc, telemetry) {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: uc,
        uuidCircuit: telemetry,
      },
    });
  }

  const totais = consumos?.totaisGerais;

  const cardsData = [
    {
      icon: <AttachMoneyIcon />,
      // chart: (
      //   <IoseEnergyBillsCardValuesBarChart
      //     loading={loading}
      //     data={auditValues}
      //   />
      // ),
      title: 'CONSUMOS',
      data: [
        {
          key: 'CONSUMO PTA (Wh):',
          value: formatNumber(totais?.total_potencia_a_wh_q1_pta)
        },
        {
          key: 'CONSUMO FP (Wh):',
          value: formatNumber(totais?.total_potencia_a_wh_q1_fp)
        },
        {
          key: 'CONSUMO REATIVO PTA (VArh):',
          value: formatNumber(totais?.total_potencia_reativa_a_varh_q1_pta)
        },
        {
          key: 'CONSUMO REATIVO FP (VArh):',
          value: formatNumber(totais?.total_potencia_reativa_a_varh_q1_fp)
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon />,
      title: 'ALARMES',
      data: [
        {
          key: 'UCs COM TELEMETRIA:',
          value: formatNumber(totalTele),
        },
        {
          key: 'TOTAL DE ALARMES:',
          value: formatNumber(totalAlarmes),
        },
        {
          key: 'TOTAL MEDINDO:',
          value: formatNumber(totalMedindo),
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon />,
      title: 'INFORMAÇÕES - ALARMES E APP',
      data: [
        {
          key: 'QUEDA DE ENERGIA:',
          value: formatNumber(totalQueda),
        },
        {
          key: 'EQUIPAMENTO SEM MEDIÇÃO:',
          value: formatNumber(totalSemMedicao),
        },
        {
          key: 'ALARMES VISUALIZADOS NO APP:',
          value: formatNumber(alarmsDisplayed),
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <NotificationImportantIcon />,
      showMoreIconTooltip: 'ABRIR ALARMES NÃO VISUALIZADOS NO APP',
      onIconClick: () => setIsModalOpen(true),
    },
    {
      icon: <FlagIcon style={{ color: '#D60606' }} />,
      title: 'MENOR FATOR POTÊNCIA',
      data: [
        {
          key: 'UC:',
          value: consumos?.menorFatorPorUnidade?.unidade_consumidora,
        },
        {
          key: 'FATOR DE POTÊNCIA:',
          value: formatNumber(consumos?.menorFatorPorUnidade?.fator_a_q1),
        },
        {
          key: 'DATA:',
          value: consumos?.menorFatorPorUnidade?.data,
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <EqualizerIcon />,
      showMoreIconTooltip: 'IR PARA O DASHBOARDE DESTA UC',
      onIconClick: () =>
        redirectToConsumerGrouDash(
          consumos?.menorFatorPorUnidade?.unidade_consumidora,
          consumos?.menorFatorPorUnidade?.numero_telemetria
        ),
    },
  ];

  return (
    <>
      <DivPaper>
        <S.CardGrid container spacing={2}>
          {cardsData.map(
            ({
              icon,
              title,
              data,
              bgColor,
              showMore,
              showMoreIcon,
              onIconClick,
              showMoreIconTooltip,
            }) => (
              <S.BoxContainerHover
                key={title}
                style={{ backgroundColor: bgColor }}
                showMore={showMore}
              >
                <S.HeaderContainer>
                  <S.IconHeader>{icon}</S.IconHeader>
                  <S.CardTitle>{title}</S.CardTitle>
                </S.HeaderContainer>
                {data.map(({ key, value }) => (
                  <S.DataContainer key={key}>
                    <S.CardDataKey>{key}</S.CardDataKey>
                    {loading ? (
                      <NewLoadingValueCards loading={loading} margin="0px" />
                    ) : (
                      <S.CardDataValue>{value}</S.CardDataValue>
                    )}
                  </S.DataContainer>
                ))}
                {showMore && (
                  <Tooltip title={showMoreIconTooltip} arrow>
                    <S.ClickableIcon onClick={onIconClick}>
                      {showMoreIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                )}
              </S.BoxContainerHover>
            )
          )}
        </S.CardGrid>
      </DivPaper>
      <AlarmsNotDisplayedModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
