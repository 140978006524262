import { useSession } from 'common/contexts/session';
import Exception from 'common/exceptions';
import LocalStorage from 'common/local_storage_api';
import IoseHeaderBar from 'components/IoseHeaderBar';
import ioseApiLib from 'iose-api-lib';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutesMainContainer } from 'routers';

export default function CircuitPage() {
  const history = useHistory();

  const { verifySession, clearSession } = useSession();

  const session = LocalStorage.getSession();

  const [sessionVerified, setSessionVerified] = useState(false);

  useEffect(() => {
    verifyIsLogged();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyIsLogged = async () => {
    try {
      if (session) {
        await ioseApiLib.setToken(session.idToken);
        const sessionVerificated = await verifySession(session);
        sessionVerificated && setSessionVerified(true);
      } else {
        throw Exception.notLoggedIn;
      }
    } catch (error) {
      clearSession(error.message);
    }
  };

  const redirectToSuperPage = () => history.replace('/global/super');

  const redirectToClientPage = () => history.replace('/global/client');

  const redirectToHome = () => {
    if (session.group === 'super') {
      redirectToSuperPage();
    } else {
      redirectToClientPage();
    }
  };

  return (
    <>
      {sessionVerified && (
        <>
          <IoseHeaderBar logoAction={redirectToHome} />
          <RoutesMainContainer />
        </>
      )}
    </>
  );
}
