import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grow, Typography } from '@material-ui/core';

import * as S from '../style';

import {
  IoseTextField,
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseButton,
} from 'components';

import { useDeleteUserAlarms } from 'hooks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '300px',
    width: '600px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function UsuariosXAlarmesDeleteModal({
  open,
  onClose,
  selectedItem,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);
  const [notificationType, setNotificationType] = useState(false);
  const [loading, setLoading] = useState();

  const deletarUsuariosXAlarmes = useDeleteUserAlarms()

  const handleConfirmation = () => {
    if (confirmation === selectedItem?.unidade_consumidora) {
      setLoading(true)
      deletarUsuariosXAlarmes.mutate(selectedItem.uuid_item, {
        onSuccess: () => {
          setLoading(false);
          setNotificationType(1);
          setTimeout(() => {
            onClose();
            setNotificationType();
          }, 2000);
          setConfirmation('')
        },
        onError: () => {
          setLoading(false);
          setNotificationType(2);
          setTimeout(() => {
            setNotificationType();
          }, 2000);
        },
      })

    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
        setConfirmation('');
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              {/* <S.RoomIcon /> */}
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                USUÁRIOS X ALARMES
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom marginBottom="35px">
                DELETAR REGISTRO
              </S.ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar este registro da UC <b>{selectedItem?.unidade_consumidora}</b>
                ?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite <b>{selectedItem?.unidade_consumidora}</b> logo abaixo
                e aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <S.DivCircularProgress>
                  <S.CircularProgressStyled />
                </S.DivCircularProgress>
              ) : (
                <>
                  {notificationType === 1 ? (
                    <IoseAlertSuccessModal text="Registro deletado com sucesso." />
                  ) : notificationType === 2 ? (
                    <IoseAlertErroModal text="Erro ao deletar registro." />
                  ) : showError ? (
                    <IoseAlertErroModal text="Texto de confirmação incorreto." />
                  ) : (
                    <S.ButtonContainer>
                      <IoseButton
                        type="submit"
                        color="primary"
                        theme='white'
                        fullWidth
                        onClick={handleConfirmation}
                      >
                        Deletar
                      </IoseButton>
                    </S.ButtonContainer>
                  )}
                </>
              )}
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
