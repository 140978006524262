import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UcSemCadastroTable from './UcSemCadastroTable';
import { useCreateLogs } from 'hooks';
import { IoseButton2 } from 'components';
import semCadastroReport from 'reports/EnergyBills/semCadastroReport';
import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import * as S from './style';
import * as XLSX from 'xlsx';

import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  modal: {
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function IoseUcSemCadastroChart({
  open,
  onClose,
  selectedCard,
  filteredSemCadastro,
  hasReportPermission,
}) {
  const classes = useStyles();
  const { createLog } = useCreateLogs();

  const cardSelecionado = selectedCard?.title;

  const isReportButtonDisabled = filteredSemCadastro?.length === 0;

  const renderTableorImage = () => (
    <div className={classes.tableContainer} style={{ position: 'relative' }}>
      <UcSemCadastroTable data={filteredSemCadastro} />
    </div>
  );

  const handleDesperdicioReport = async () => {
    semCadastroReport(filteredSemCadastro);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CRÉDITOS ACUMULADOS ';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  function exportTelemetryMeasuresToExcel(data) {
    // Reordena as colunas
    const reorderedColumns = [
      'uc_energy_bills',
      'reference_month',
      'classification',
      'bill_name',
    ];

    // Reordena os dados
    const reorderedData = data?.map((obj) => {
      const reorderedObj = {};
      reorderedColumns.forEach((column) => {
        reorderedObj[column] = obj[column];
      });
      return reorderedObj;
    });

    const worksheet = XLSX.utils.json_to_sheet(reorderedData);

    const headers = {
      uc_energy_bills: 'UC',
      reference_month: 'Mês de  Referência',
      classification: 'Classificação',
      bill_name: 'Endereço',
    };

    // Altere o nome das colunas no cabeçalho da planilha
    const columns = Object.keys(headers);
    columns.forEach((column, index) => {
      const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
      headerCell.v = headers[column];
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
    XLSX.writeFile(workbook, 'novas.xlsx');
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <BatteryChargingFullIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {`${cardSelecionado}`}
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
            {!hasReportPermission ? (
              <>
                <IoseButton2
                  onClick={handleDesperdicioReport}
                  width="150px"
                  disabled={isReportButtonDisabled}
                >
                  <span style={{ marginRight: '5px' }}>PDF</span>
                  <GetAppIcon />
                </IoseButton2>
                <IoseButton2
                  style={{
                    margin: '10px',
                  }}
                  onClick={() =>
                    exportTelemetryMeasuresToExcel(filteredSemCadastro)
                  }
                  width="150px"
                >
                  <span style={{ marginRight: '5px' }}>XLSX</span>
                  <GetAppIcon />
                </IoseButton2>
              </>
            ) : null}
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom />
            {renderTableorImage()}
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
