import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useCircuits(uuidClient) {
  const { getSession } = useContext(AccountContext);

  const pageSize = 300;

  const getCircuits = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllClientCircuits(
      headers,
      uuidClient,
      0,
      pageSize
    );

    const circuits = response.data.data || [];

    if (circuits.length === 0) {
      // console.error(Messages.noRegisteredCircuits);
      throw new Error(Messages.noRegisteredCircuits);
    }
    return circuits;
  };

  const { data: circuits, error: circuitsError, isLoading } = useQuery(
    ['circuits', uuidClient],
    getCircuits
  );

  return {
    circuits,
    circuitsError,
    isLoading,
  };
}

export default useCircuits;
