// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCalculateTotalExtract() {
  const [noDataError, setNoDataError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCalculateTotalBillValues = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getCalculateTotalExtract(headers);

    const extractValues = response.data.data || [];

    if (extractValues.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return extractValues;
  };

  const {
    data: extractValues,
    error: extractValuesError,
    refetch: refreshExtractValues,
    isLoading,
  } = useQuery(['extractValues'], getCalculateTotalBillValues);

  return {
    extractValues,
    extractValuesError,
    noDataError,
    refreshExtractValues,
    isLoading,
  };
}

export default useCalculateTotalExtract;
