import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import BusinessIcon from '@material-ui/icons/Business';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { TypographyGeneral } from 'components/IoseSubHeaderBar/style';
import { Table, TableHead, TableRow } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';

export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContainerTable = styled.div`
  background-color: #fff;
  border-radius: 5px;
  height: 600px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: black;
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const BillModelContainer = styled.div`
  width: 100%;
  height: 750px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  padding: 10px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContractDataContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  gap: 10px;
  display: flex;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  flex-direction: column;
  grid-column: span 4;
`;

export const ConsumerGroupDataContainer = styled.div`
  width: 30%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  gap: 10px;
  display: flex;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  flex-direction: column;
  grid-column: span 4;
`;

export const DashboardContainer = styled.div`
  grid-column: span 8;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const DemandaEConsumoContainer = styled.div`
  grid-column: span 4;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TableContainer = styled.div`
  /* height: auto; */
  height: 400px;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TelemetryContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
`;

export const TelemetryTableContainer = styled.div`
  height: 530px;
  grid-column: span 3;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TelemetryAlarmsContainer = styled.div`
  height: 530px;
  grid-column: span 5;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 15px;
  gap: 15px;
`;

export const FilterTitle = styled.h3`
  font-weight: 600;
`;

export const Consumo30DiasContainer = styled.div`
  height: 1550ypx;
  grid-column: span 6;
`;

export const ConsumoTelemetriaContainer = styled.div`
  height: 770px;
  grid-column: span 6;
`;

export const DemandaTelemetriaContainer = styled.div`
  height: 700px;
  grid-column: span 6;
`;

export const FatorPotenciaTelemetriaContainer = styled.div`
  height: 700px;
  grid-column: span 6;
`;

export const TelemetryConsumoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TelemetryQuatroQuadrantesContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const ErrorText = styled.p`
  color: #d60606;
  font-weight: 600;
`;

export const AlarmesDiv = styled.div`
  width: 100%;
  display: flex;
`;

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const ConsumoDemandaRf12Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
`;

export const ConsumoInfosContainer = styled.div`
  width: 100%;
  display: flex;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
`;

//Right Sidebar Styles
export const SidebarContainer = styled.div`
  position: fixed;
  right: 15px;
  top: 26%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  border-radius: 8px;
`;

export const IconWrapper = styled.div`
  margin: 10px 0;
  cursor: pointer;
  color: #333;
  font-size: 24px;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  border-bottom: ${(props) => (props.isSelected ? '2px solid #015A9E' : 0)};

  &:hover {
    transform: scale(1.1);
  }
`;

export const TelemetryIcon = styled(AccessTimeIcon)`
  color: var(--primary);
`;

export const BillsIcon = styled(ReceiptIcon)`
  color: var(--primary);
`;

export const CreditIcon = styled(BatteryChargingFullIcon)`
  color: var(--primary);
`;

export const AllIcon = styled(CloseIcon)`
  color: var(--alert);
`;

export const TypographySubTitle = styled(TypographyGeneral)`
  color: var(--gray);
  /* margin-left: 30px; */
  font-size: 14px;

  /* @media (max-width: 978px) {
    margin-left: 0px;
  } */
`;

export const TypographyFilters = styled(TypographyGeneral)`
  color: var(--primary);
  /* margin-left: 30px; */
  font-size: 12px;

  /* @media (max-width: 978px) {
    margin-left: 0px;
  } */
`;