// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSolarEcus() {
  const [noEcuError, setNoEcuError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getSolarEcus = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

   do {
    const response = await ioseAPI.getAllSolarEcus(
      headers,
      lastEvaluatedKey,
      limit
    );

    const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

    allData = allData.concat(data);
    lastEvaluatedKey = newLastEvaluatedKey;
   } while (lastEvaluatedKey)


    if (allData.length === 0) {
      setNoEcuError(Messages.noRegisteredEcus);
      throw new Error(Messages.noRegisteredEcus);
    }
    return allData;
  };

  const {
    data: solarEcus,
    error: solarEcusError,
    refetch: refreshSolarEcus,
    isLoading,
  } = useQuery(['solarEcusData'], getSolarEcus);

  return {
    solarEcus,
    solarEcusError,
    noEcuError,
    refreshSolarEcus,
    isLoading,
  };
}

export default useSolarEcus;
