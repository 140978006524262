import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import BusinessIcon from '@material-ui/icons/Business';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContainerTable = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: black;
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
