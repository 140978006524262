import React, { useContext, useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { useConsumerGroup, useUniqueConcessionaires, useUniqueGeneralManagement, useUniqueRegionalManagement } from 'hooks';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { AccountContext, ioseAPI } from 'common';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';
import { classificationForSelect } from '../../../constants/index';
// import exportBillItensToExcel from '../excelReport';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function EnergyBillContainerFilters({ loading, energyBills }) {
  const { consumerGroupsForSelect, consumerGroupsCitiesForSelect, consumerGroupsMetersForSelect} = useConsumerGroup();
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { regionalManagement } = useUniqueRegionalManagement();
  const { generalManagements } = useUniqueGeneralManagement();
  const { getSession } = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEnergyBillsItems = useCallback(async (object) => {
    try {
      setIsLoading(true);
      loading(true);
      const sessionData = await getSession();

      let allData = [];
      let lastEvaluatedKey = null;
      const limit = 10000;

      do {
        const response = await ioseAPI.getFilteredSearchEnergyBill(
          sessionData.headers,
          object,
          lastEvaluatedKey,
          limit
        );

        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } while (lastEvaluatedKey);

      // Ordena os dados por reference_month (ou outro campo desejado)
      allData.sort((a, b) => {
        // Supondo que reference_month seja uma string no formato 'MM/YYYY' ou 'YYYY-MM'
        const parseDate = (dateString) => {
          const [month, year] = dateString.split('/');
          return new Date(`${year}-${month}-01`); // Formato para Date: YYYY-MM-DD
        };

        const dateA = parseDate(a.reference_month);
        const dateB = parseDate(b.reference_month);

        return dateB - dateA; // Ordenação descendente: mais recente primeiro
      });

      energyBills(allData);
      setIsLoading(false);
      loading(false);
    } catch (error) {
      setIsLoading(false);
      loading(false);
      throw error;
    }
  }, [energyBills, getSession, loading]);
  const initialValues = {
    identification_number: '',
    concessionaire: '',
    reference_month: '',
    general_management: '',
    regional_management: '',
    classification: '',
    cidade: '',
    numero_medidor: ''
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);
    await getEnergyBillsItems(filteredValues);
  };

  const cleanFilters = (resetForm) => {
    resetForm()
    setSelectedDate(null)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDynamicSelect
                label="UC"
                value={values.identification_number}
                onChange={(value) => setFieldValue('identification_number', value)}
                options={consumerGroupsForSelect}
                width="150px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Concessionárias"
                value={values.concessionaire}
                onChange={(value) => setFieldValue('concessionaire', value)}
                options={uniqueConcessionaires}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="GG"
                value={values.general_management}
                onChange={(value) => setFieldValue('general_management', value)}
                options={generalManagements}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="GR"
                value={values.regional_management}
                onChange={(value) => setFieldValue('regional_management', value)}
                options={regionalManagement}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Classificação"
                value={values.classification}
                onChange={(value) => setFieldValue('classification', value)}
                options={classificationForSelect}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Cidade"
                value={values.cidade}
                onChange={(value) => setFieldValue('cidade', value)}
                options={consumerGroupsCitiesForSelect}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Medidor"
                value={values.numero_medidor}
                onChange={(value) => setFieldValue('numero_medidor', value)}
                options={consumerGroupsMetersForSelect}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isLoading}>
              FILTRAR
            </IoseButton>

            <IoseRemoveButton onClick={() => cleanFilters(resetForm)}>
            LIMPAR FILTROS
          </IoseRemoveButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
