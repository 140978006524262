import styled from 'styled-components';

//Import from Material UI Components
import { Grid, AppBar, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

//Images
import LogoImg from './img/logo-iose-blacksvg.svg';

export const GridLeft = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}))`
  padding-left: 30px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
`;

export const GridRight = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}))``;

export const HeaderBar = styled(AppBar).attrs(() => ({
  position: 'static',
}))`
  background-color: var(--primary);
  height: auto;
`;

export const Logo = styled.img.attrs(() => ({
  src: LogoImg,
}))`
  height: 25px;
  width: 65px;
  margin-top: 11px;
  transition: all 0.15s linear;

  &:hover {
    height: 28px;
    width: 68px;
    cursor: pointer;
  }
`;

export const LogoSideBar = styled.img.attrs(() => ({
  src: LogoImg,
}))`
  height: 25px;
  width: 65px;
  margin-top: 11px;
  transition: all 0.15s linear;

  &:hover {
    cursor: pointer;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: ${(props) =>
      props.justify ? 'space-between' : 'flex-end'};
    padding-left: 10px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary);
  margin-bottom: 10px;
  width: 150px;
  padding-right: 10px;
`;

export const UserName = styled.h2`
  width: 250px;
  font-size: 13px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  margin-right: 8px;
`;

export const LogoSidebarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const StyledListItemText = styled(ListItemText).attrs(() => ({}))`
  font-size: 25px;
`;

export const StyledMenuIcon = styled(MenuIcon)`
  color: var(--secondary);
  transition: 0.3s ease;

  &:hover {
    color: var(--dark);
  }
`;

export const UserNameSidebarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--secondary);
`;

export const ClientNameSidebarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--secondary);
  margin-bottom: 25px;
`;
