import React from "react";
import * as S from '../style';

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalBillTableTaxes({ currentEnergyBill }) {
  if (!currentEnergyBill || currentEnergyBill.length === 0) return null;

  const energyBill = currentEnergyBill[0];

  return (
    <S.TableContainer>
      <S.TableTitleContainer>
        <S.TableTitle>TRIBUTOS</S.TableTitle>
      </S.TableTitleContainer>
      <S.Table>
        <S.TableHeader>
          <tr>
            <S.TableHeaderCell>TRIBUTO</S.TableHeaderCell>
            <S.TableHeaderCell>BASE DE CÁLC. (R$)</S.TableHeaderCell>
            <S.TableHeaderCell>ALÍQUOTA (%)</S.TableHeaderCell>
            <S.TableHeaderCell>VALOR (R$)</S.TableHeaderCell>
          </tr>
        </S.TableHeader>
        <tbody>

          <S.TableExtractRow>
            <S.TableCell>ICMS</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.icms_calculation_basis_total)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.icms_aliquot)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.icms_total)}</S.TableCell>
          </S.TableExtractRow>

          <S.TableExtractRow>
            <S.TableCell>COFINS</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.cofins_calculation_basis_total)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.cofins_aliquot)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.cofins_total)}</S.TableCell>
          </S.TableExtractRow>

          <S.TableExtractRow>
            <S.TableCell>PIS</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.pis_calculation_basis_total)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.pis_aliquot)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(energyBill?.pis_total)}</S.TableCell>
          </S.TableExtractRow>

        </tbody>
      </S.Table>
    </S.TableContainer>
  );
}
