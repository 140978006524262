import * as Yup from 'yup';

import validationsForm from 'common/ValidationsForms';

export const LoginSchema = Yup.object().shape({
  email: validationsForm.email,
  password: validationsForm.required,
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: validationsForm.email,
});
