import { useContext, useState } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useEnvironments() {
  const { getSession } = useContext(AccountContext);

  const [noEnvironmentsError, setNoEnvironmentsError] = useState('');

  const getEnvironments = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getEnvironments(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (allData.length === 0) {
      setNoEnvironmentsError(Messages.noRegisteredEnvironments);
      throw new Error(Messages.noRegisteredEnvironments);
    } else {
      const environments = allData;

      return environments
    }
  };

  const {
    data: environments,
    error: environmentsError,
    isLoading,
    refetch: refreshEnvironments,
  } = useQuery(['environmentsData'], getEnvironments);

  return {
    environments,
    environmentsError,
    noEnvironmentsError,
    refreshEnvironments,
    isLoading,
  };
}

export default useEnvironments;
