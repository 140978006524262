import React from 'react';
import * as XLSX from 'xlsx';

import { IoseButton2 } from 'components/IoseButton';
import {
  DivSearch,
  InputBaseStyled,
  SearchIconStyled,
  TableBox,
  TableContainer,
  TableHeaderBox,
  TableTitle,
} from './style';

import IoseEnergyBillLogsTable from 'components/IoseEnergyBillLogsTable';

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data?.map((obj) => {
    const { uuid_contract, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'concessionaire',
    'classification_code',
    'reference_month',
    'quantity',
    'identification_number',
  ];

  // Reordena os dados
  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    concessionaire: 'Concessionária',
    classification_code: 'Classificação',
    reference_month: 'REF: MÊS/ANO',
    quantity: 'Quantidade',
    identification_number: 'Quantidade de UCS',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'log-faturas-iose.xlsx');
}

export default function IoseBillLogsTable({
  loading,
  tableTitle,
  searchInTable,
  searchInputLabel,
  data,
  hasReportPermission,
}) {
  function handleDownloadButtonClick() {
    exportToExcel(data);
  }

  return (
    <TableContainer id="scrollableDiv">
      <TableBox>
        <TableHeaderBox>
          <TableTitle>
            <h3>{tableTitle}</h3>
          </TableTitle>
          {searchInTable ? (
            <DivSearch>
              <InputBaseStyled
                type="text"
                placeholder={'Pesquisar por ' + { searchInputLabel } + '...'}
                value={() => {}}
                onChange={() => {}}
              />
              <SearchIconStyled />
            </DivSearch>
          ) : (
            <></>
          )}
          {hasReportPermission ? (
            <IoseButton2 onClick={() => handleDownloadButtonClick()}>
              Download XLSX
            </IoseButton2>
          ) : null}
        </TableHeaderBox>

        <IoseEnergyBillLogsTable loading={loading} data={data} />
      </TableBox>
    </TableContainer>
  );
}
