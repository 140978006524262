import styled from 'styled-components';
import CircuitImage from './img/circuitIcon.svg';

//Imports from Material UI components
import { Card } from '@material-ui/core';

export const Container = styled(Card)`
  padding: 6%;
  /* flex-direction: column; */
  width: 100%;
  /* border: solid 1px red; */
  /* height: 272px; */
  height: 100%;

  /* @media (min-width: 400px) and (max-width: 599px) {
    height: 259px !important;
  }

  @media (min-width: 600px) and (max-width: 959px) {
    height: 348px !important;
  }

  @media (min-width: 960px) and (max-width: 1300px) {
    height: 259px !important;
  }

  //Screen UltraWide
  @media (min-height: 947px) {
    height: 335px !important;
  }
  //FullScreen
  @media (min-width: 960px) and (min-height: 732px) {
    height: 255px;
  } */
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin-left: 5px;
  }
`;

export const CircuitIcon = styled.img.attrs((props) => ({
  src: CircuitImage,
}))`
  width: 20px;
  height: 20px;
`;
