import React from 'react';

import GlobalStyles from 'styles/GlobalStyles';
import { StylesProvider } from '@material-ui/core/styles';
import { positions, Provider } from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import { RoutesPages } from 'routers';
import './index.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'common/queryClient';

const options = {
  position: positions.MIDDLE,
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <Provider template={AlertMUITemplate} {...options}>
          {/* <React.StrictMode> */}
          <RoutesPages />
          <GlobalStyles />
          {/* </React.StrictMode> */}
        </Provider>
      </StylesProvider>
    </QueryClientProvider>
  );
}
