import React from 'react';
import { TableBody, TableCell, Paper, makeStyles, } from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { realizaCalculosTarifa } from 'common/mappings/faturaCalculoMapping';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

const tranformaReal = (dado) => {
  return dado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

const TableCellCentered2 = ({ children, colors }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.tableCell}>
      {Array.isArray(children) ? (
        children.map((item, index) => (
          <div key={index} style={{ color: colors[index], fontSize: '10px' }}>
            {item}
          </div>
        ))
      ) : (
        <div style={{fontSize: '10px'}}>{children}</div>
      )}
    </TableCell>
  );
};

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  let cor

  if (children === 'AZUL') {
    cor = '#3374b0'
  }
  else if (children === 'VERDE') {
    cor = '#3da860'
  }
  else {
    cor = ''
  }

  return <TableCell style={{color:cor}} className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children,description }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={description}
      placement="top"
      arrow
    >
      <TableCell className={classes.tableHeaderCell}>{children}</TableCell>
    </Tooltip>
  );
};


export default function TarifasTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    if (data) {
      if (
        data?.modalidade !== '' &&
        data?.subgrupo !== '' &&
        data?.tarifas.length > 0 &&
        data?.faturas.length > 0 &&
        data?.itens.length > 0 &&
        data?.demandas.length > 0
      ) {
        return (
          <Paper>
            <LogsTable data={data} id="scrollableDiv" />
          </Paper>
        );
      } else {
        return (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="300px"
            width="300px"
          />
        );
      }
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  const LogsTable = ({ data, onRowClick }) => {
    data = realizaCalculosTarifa(data)

    const sortedData = data.sort((a, b) => {
      const [mesA, anoA] = a.mes.split('/').map(Number);
      const [mesB, anoB] = b.mes.split('/').map(Number);

      if (anoA !== anoB) {
        return anoB - anoA; // Ordena por ano
      }
      return mesB - mesA; // Ordena por mês
    });

    return (
        <StyledTable>
        <TableHeadStyled>
            <TableRowStyled align="center">
              <TableHeaderCellCentered description={'Mês de referência presente na fatura'}>MÊS</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado dos consumos ponta e fora ponta na tarifa atual'}>CONSUMO</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado das demandas ponta e fora ponta na tarifa atual'}>DEMANDA</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado das utrapassagens de demanda ponta e fora ponta na tarifa atual'}>DEMANDA ULTRAP.</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado dos adcionionais de demanda ponta e fora ponta na tarifa atual'}>ADIC. DE DEMANDA</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado dos reativos de consumo e demanda'}>REATIVOS</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado dos demais valores da fatura'}>OUTROS</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor somado de todas as colunas anteriores'}>TOTAIS</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Valor da diferença entre a tarifa azul e a verde'}>DIFERENÇA TOTAL</TableHeaderCellCentered>
              <TableHeaderCellCentered description={'Tarifa recomendada com base no valor de diferença entre as tarifas'}>TARIFA RECOMENDADA</TableHeaderCellCentered>
            </TableRowStyled>
        </TableHeadStyled>

        <TableBody>
            {sortedData?.slice(0,12).map((value, index) => (
                <LogsTableRow
                    key={index}
                    value={value}
                    onClick={onRowClick}
                    index={index}
                />
            ))}
        </TableBody>
        </StyledTable>
    )};

  const LogsTableRow = ({ value, onClick, index }) => {
    const classes = useStyles();

    return (
      <TableRowStyled align="center" className={classes.tableRow}>
        <TableCellCentered>{(value?.mes).toUpperCase()}</TableCellCentered>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.consumoVerde),
            tranformaReal(value?.consumoAzul),
            tranformaReal(value?.consumoFatura/2),
          ]}
        </TableCellCentered2>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.demandaVerde),
            tranformaReal(value?.demandaAzul),
            tranformaReal(value?.demandaFatura/2),
          ]}
        </TableCellCentered2>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.demandaUltrapVerde),
            tranformaReal(value?.demandaUltrapAzul),
            tranformaReal(value?.demandaUltrapFatura/2),
          ]}
        </TableCellCentered2>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.adcionalDemandaVerde),
            tranformaReal(value?.adcionalDemandaAzul),
            tranformaReal(value?.adcionalDemandaFatura/2),
          ]}
        </TableCellCentered2>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.reativosVerde),
            tranformaReal(value?.reativosAzul),
            tranformaReal(value?.reativosFatura),
          ]}
        </TableCellCentered2>
        <TableCellCentered>{tranformaReal(value?.outros)}</TableCellCentered>
        <TableCellCentered2 colors={['#3da860', '#3374b0', '#dba156']}>
          {[
            tranformaReal(value?.verde),
            tranformaReal(value?.azul),
            tranformaReal(value?.fatura),
          ]}
        </TableCellCentered2>
        <TableCellCentered>
            {
                value?.azul - value?.verde > 0
                    ? tranformaReal(value?.azul - value?.verde)
                    : tranformaReal(value?.verde - value?.azul)
            }
        </TableCellCentered>
        <TableCellCentered >
            {value?.verde > value?.azul ? 'AZUL' : 'VERDE'}
        </TableCellCentered>
      </TableRowStyled>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={13}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          height={580}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
