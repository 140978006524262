import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { ioseAPI } from 'common/ioseAPI';

//Import from Messages
import Messages from '../../common/Messages';

//Imports from IOSE Components
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';
import { IoseBackButton } from 'components/IoseButtonIcon';
import { IoseAddButton } from 'components/IoseButtonIcon';
import {
  ButtonArea,
  SubSideHeader,
  Content,
} from 'components/IoseSideContainer';

import { RoutesEmployee } from 'routers';

export default function SidebarEmployeeContainer({ group, closeSide }) {
  const history = useHistory();

  let location = useLocation();
  const alert = useAlert();

  const clientName = location.state.clientName;
  const unityName = location.state.unityName;

  const page_code = 0;
  const page_size = 10;

  //State to change notification type and your data
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');

  const [getEmployeesErro, setgetEmployeesErro] = useState(false);

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //This function return session data
  const { getSession } = useContext(AccountContext);

  const [arrayEmployee, setArrayEmployee] = useState([]);

  //State control when need reloading list employees
  const [getEmployee, setgetEmployee] = useState(true);

  //State control when need reloading list employees when press backbutton
  const [reloading, setreloading] = useState(false);

  //State to save next page infinity scroll
  const [nextPage, setNextPage] = useState(0);
  const [endList, setEndList] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (getEmployee === true) {
        getAllEmployee();
      }
    }

    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployee]);

  //Functions to GET a page employees
  function errorHandlingGetEmployee(error) {
    setLoading(false);
    setNotification(error ? error : Messages.employeeGetErro);
    setgetEmployeesErro(true);
    setNotificationType(2);
    setgetEmployee(false);
    setreloading(false);
  }

  function getAllEmployee() {
    setNotificationType(0);
    setLoading(true);

    getSession().then(({ headers }) => {
      ioseAPI
        .getAllEmployee(
          headers,
          location.state.uuid_unity,
          location.state.uuid_client,
          page_code,
          page_size
        )
        .then((response) => {
          setLoading(false);
          setArrayEmployee(response.data.data);
          setNextPage(response.data.next_page);
          setgetEmployee(false);
          setreloading(false);
        })
        .catch((error) => {
          errorHandlingGetEmployee(error.response.data.message);
        });
    });
  }
  ////////////////////

  //Functions to INFINITYSCROLL
  function getMoreEmployee() {
    setNotificationType(0);
    setLoadingMore(true);

    getSession().then(({ headers }) => {
      ioseAPI
        .getAllEmployee(
          headers,
          location.state.uuid_unity,
          location.state.uuid_client,
          nextPage
        )
        .then((response) => {
          setLoadingMore(false);

          let nextpage = response.data.next_page;
          let arrayItens = response.data.data;

          if (arrayItens.length > 0) {
            setArrayEmployee(arrayEmployee.concat(response.data.data));
          }

          if (typeof nextpage === 'undefined') {
            setEndList(true);
            setNextPage(nextpage);
          } else {
            setNextPage(nextpage);
          }
        })
        .catch((error) => {
          setLoadingMore(false);
          setNotification(error.response.data.message);
          setgetEmployeesErro(true);
          setNotificationType(2);
        });
    });
  }

  const getMoreEmployees = () => {
    if (typeof nextPage !== 'undefined') {
      getMoreEmployee();
    }
  };

  //Functions to ADD Employee
  function addEmployee(
    employeeChosen,
    email,
    name,
    uuid_client,
    uuid_unity,
    activate_circuits
  ) {
    setNotificationType(0);
    setLoading(true);

    getSession().then(({ headers }) => {
      if (employeeChosen === 'manager') {
        ioseAPI
          .createManager(headers, email, name, uuid_client, uuid_unity)
          .then((response) => {
            setLoading(false);
            setNotification(response.data.message);
            setNotificationType(1);
            setreloading(true);
          })
          .catch((error) => {
            setLoading(false);
            setNotification(error.response.data.message);
            setNotificationType(2);
          });
      } else {
        ioseAPI
          .createOperator(
            headers,
            email,
            name,
            uuid_client,
            uuid_unity,
            activate_circuits
          )
          .then((response) => {
            setLoading(false);
            setNotification(response.data.message);
            setNotificationType(1);
            setreloading(true);
          })
          .catch((error) => {
            setLoading(false);
            setNotification(error.response.data.message);
            setNotificationType(2);
          });
      }
    });
  }

  const redirectAddEmployee = () => {
    history.push({
      pathname: '/global/client/employee/add',
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: location.state.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
  };

  //Funtions to DELETE Employee
  const deleteEmployee = (uuid_employee) => {
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .deleteEmployee(headers, uuid_employee)
          .then(() => {
            setgetEmployee(true);
            setNotification(Messages.successDeleteEmployee);
            setNotificationType(1);
          })
          .catch((error) => {
            setgetEmployee(true);
            setNotification(error.response.data.message);
            setNotificationType(2);
          });
      })
      .catch(() => {
        alert.show(`${Messages.errorDeleteEmployee}`, {
          title: 'Erro!',
          closeCopy: 'Fechar',
        });
      });
  };

  //Funtions to UPDATE Employee
  function editEmployee(email, name, uuid_employee) {
    setNotificationType(0);
    setLoading(true);

    const employeeSelectedData = arrayEmployee.find(
      (employee) => employee.uuid_employee === uuid_employee
    );

    if (email) {
      getSession().then(({ headers }) => {
        ioseAPI
          .updateEmployee(
            headers,
            uuid_employee,
            name,
            email,
            employeeSelectedData
          )
          .then((response) => {
            setLoading(false);
            clickBackShowEmployee();
            setgetEmployee(true);
            setNotification(response.data.message);
            setNotificationType(1);
          })
          .catch((error) => {
            setLoading(false);
            setNotification(
              typeof error.response === 'undefined'
                ? 'Erro ao editar funcionário'
                : error.response.data.message
            );
            setNotificationType(2);
          });
      });
    }
  }

  //Function to REDIRECT to determinated card
  const redirectEditEmployee = (uuid_employee, name, email) => {
    history.push({
      pathname: '/global/client/employee/edit',
      state: {
        uuid_employee: uuid_employee,
        name: name,
        email: email,
        clientName: clientName,
        unityName: unityName,
        uuid_unity: location.state.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
  };

  const clickBack = () => {
    history.push({
      pathname: '/global/client/switchboard',
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: location.state.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
  };

  const clickBackShowEmployee = () => {
    history.push({
      pathname: '/global/client/employee',
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: location.state.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
    reloading && setgetEmployee(true);
  };

  return (
    <>
      <SubSideHeader>
        <IoseBackButton primary="true" onClick={(e) => clickBack()} />
        <h3>Funcionários</h3>
        <ButtonArea>
          <IoseAddButton
            onClick={redirectAddEmployee}
            tooltip="Adicionar Funcionário"
          />
        </ButtonArea>
      </SubSideHeader>
      <Content>
        {notificationType === 1 && <IoseAlertSuccess text={notification} />}
        {notificationType === 2 && <IoseAlertErro text={notification} />}
        {loading ? (
          <LoadingIndicatorSidebar loading={loading} />
        ) : (
          getEmployeesErro === false && (
            <RoutesEmployee
              deleteEmployee={deleteEmployee}
              redirectEditEmployee={redirectEditEmployee}
              group={group}
              clickBackShowEmployee={clickBackShowEmployee}
              arrayEmployee={arrayEmployee}
              loadingMore={loadingMore}
              endList={endList}
              getMoreEmployees={getMoreEmployees}
              addEmployee={addEmployee}
              editEmployee={editEmployee}
              closeSide={closeSide}
            />
          )
        )}
      </Content>
    </>
  );
}

SidebarEmployeeContainer.propTypes = {
  /** This props get a group user */
  group: PropType.string,
};
