import React from 'react';

import { DivPaper } from 'styles/defaultStyles';

import { IoseCardsEnergyBills } from 'components';

export default function EnergyBillsDashHeader({
  loading,
  auditValues,
  auditConsumptions,
  auditExtract,
  averages,
  semCadastro,
  filteredMercadoLivre,
  ucsDesativadas
}) {
  return (
    <DivPaper>
      <IoseCardsEnergyBills
        loading={loading}
        auditValues={auditValues}
        auditConsumptions={auditConsumptions}
        auditExtract={auditExtract}
        averages={averages}
        semCadastro={semCadastro}
        filteredMercadoLivre={filteredMercadoLivre}
        ucsDesativadas={ucsDesativadas}
      />
    </DivPaper>
  );
}
