// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCreditGeneration() {
  const [noCreditGeneration, setNoCreditGeneration] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCreditGeneration = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllCreditGeneration(headers, 3000, 3000);

    const { data } = response.data;

    const creditGenerationData = data || [];

    if (creditGenerationData.length === 0) {
      setNoCreditGeneration(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return creditGenerationData;
  };

  const {
    data: creditGenerationData,
    error: creditGenerationDataError,
    refetch: refreshCreditGeneration,
    isLoading,
  } = useQuery(['creditGeneration'], getCreditGeneration);

  return {
    creditGenerationData,
    creditGenerationDataError,
    noCreditGeneration,
    refreshCreditGeneration,
    isLoading,
  };
}

export default useCreditGeneration;
