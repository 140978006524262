import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterText = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const FilterWrappedContainer = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
`;

export const StyledLoadingMessage = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
  text-align: center;

  @media (max-width: 1380px) {
    font-size: 13px;
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  gap: 35px;
`;
