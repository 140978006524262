import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import * as S from './style';
import Permissions from 'common/permissions';
import { LocalStorage } from 'common';
import { useGroupPermissions, useLogs } from 'hooks';
import IoseTableContainer from 'components/IoseTableContainer';
import UserLogsFilters from './components/user-logs-filtros';
import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
} from 'components';

export default function UserLogsContainer() {
  const { logs } = useLogs();

  const [logData, setLogData] = useState();

  useEffect(() => {
    if (logs) {
      const sortedLogs = logs?.sort(
        (a, b) => new Date(b.log_date) - new Date(a.log_date)
      );

      setLogData(sortedLogs)
    }
  }, [logs]);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['gerenciamento', 'log_usuarios', 'leitura'];
    const reportPath = ['gerenciamento', 'log_usuarios', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  const renderContent = () => {
    return (
      <S.ContainerTable>
        <IoseTableContainer
          tableTitle=""
          searchInTable={false}
          data={logData}
          hasReportPermission={hasReportPermission}
        />
      </S.ContainerTable>
    )
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        <IoseSubHeaderBar title="Logs de usuários" />
        <UserLogsFilters logs={setLogData}/>
        {hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}
