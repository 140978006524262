import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination, Tooltip, Button } from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled } from '../style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import CreateIcon from '@material-ui/icons/Create';
import { IoseObjectEditModal } from 'components';
import { useEnvironments } from 'hooks';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  smallerColumn: {
    minWidth: '80px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  mediumColumn: {
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconColumn: {
    minWidth: '40px',
    maxWidth: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableCell} ${className}`}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className, sortedBy, direction, onClick }) => {
  const classes = useStyles();
  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${className}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({
  value,
  onEditClick,
  editPermission
}) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered className={classes.mediumColumn}>{value.name?.toUpperCase()}</TableCellCentered>
      <TableCellCentered className={classes.mediumColumn}>{value.environment_name?.toUpperCase()}</TableCellCentered>
      <TableCellCentered className={classes.smallerColumn}>{value.power}</TableCellCentered>
      <TableCellCentered className={classes.smallerColumn}>{value.current}</TableCellCentered>
      <TableCellCentered className={classes.mediumColumn}>{value.temperature}</TableCellCentered>
      <TableCellCentered className={classes.smallerColumn}>{value.condition?.toUpperCase()}</TableCellCentered>
      <TableCellCentered className={classes.smallerColumn}>{value.charge_type?.toUpperCase()}</TableCellCentered>
      <TableCellCentered className={classes.smallerColumn}>{value.voltage}</TableCellCentered>
      {editPermission ? (
        <TableCellCentered className={classes.iconColumn}>
          <Tooltip title='EDITAR REGISTRO'>
            <Button style={{ height: '30px' }} onClick={() => onEditClick(value)}>
              <CreateIcon style={{ color: '#015A9E' }}/>
            </Button>
          </Tooltip>
        </TableCellCentered>
      ) : null}
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  onDeleteClick,
  onEditClick,
  editPermission,
  handleSort,
  sortBy,
  sortDirection
}) => {
  const classes = useStyles();
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('name')}
            sortedBy={sortBy === 'name'}
            direction={sortDirection}
          >
            NOME
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('environment_name')}
            sortedBy={sortBy === 'environment_name'}
            direction={sortDirection}
          >
            AMBIENTE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smallerColumn}
            onClick={() => handleSort('power')}
            sortedBy={sortBy === 'power'}
            direction={sortDirection}
          >
            POTÊNCIA (W)
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smallerColumn}
            onClick={() => handleSort('current')}
            sortedBy={sortBy === 'current'}
            direction={sortDirection}
          >
            CORRENTE (A)
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('temperature')}
            sortedBy={sortBy === 'temperature'}
            direction={sortDirection}
          >
            TEMPERATURA FUNC. (ºC)
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smallerColumn}
            onClick={() => handleSort('condition')}
            sortedBy={sortBy === 'condition'}
            direction={sortDirection}
          >
            CONDIÇÃO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smallerColumn}
            onClick={() => handleSort('charge_type')}
            sortedBy={sortBy === 'charge_type'}
            direction={sortDirection}
          >
            TIPO DE CARGA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smallerColumn}
            onClick={() => handleSort('voltage')}
            sortedBy={sortBy === 'voltage'}
            direction={sortDirection}
          >
            VOLTAGEM
          </TableHeaderCellCentered>
          <TableHeaderCellCentered />
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            editPermission={editPermission}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function ObjectsMainTable({ data = [], editPermission }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const { environments } = useEnvironments();

  useEffect(() => {
    setLoading(true);
    if (data?.length > 0 && environments?.length > 0) {
      const updatedData = data.map(item => {
        const environment = environments.find(env => env.uuid_environment === item.uuid_environment);

        return {
          ...item,
          environment_name: environment ? environment.name : 'Não encontrado',
          uuid_unity: environment ? environment?.uuid_unity : 'Não encontrado'
        };
      });
      setUpdatedData(sortData(updatedData, sortBy, sortDirection));
      setLoading(false);
    }
  }, [data, environments, sortBy, sortDirection]);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEditItem = (itemToRemove) => {
    setOpenEditModal(true);
    setSelectedItem(itemToRemove);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  function renderChartOrErrorMessage() {
    if (updatedData && updatedData?.length > 0) {
      return (
        <CircuitsTable
          data={updatedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          onEditClick={handleEditItem}
          editPermission={editPermission}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={updatedData ? parseInt(updatedData?.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={650}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={updatedData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>

          <IoseObjectEditModal
            open={openEditModal}
            onClose={handleCloseEditModal}
            environments={environments}
            selectedObject={selectedItem}
          />
        </>
      )}
    </div>
  );
}

const sortData = (data, sortBy, sortDirection) => {
  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
