// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCreditPercentage() {
  const [nogetCreditPercentage, setNogetCreditPercentage] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCreditPercentage = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllCreditPercentage(headers, 3000, 3000);

    const { data } = response.data;

    const getCreditPercentageData = data || [];

    if (getCreditPercentageData.length === 0) {
      setNogetCreditPercentage(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return getCreditPercentageData;
  };

  const {
    data: getCreditPercentageData,
    error: getCreditPercentageDataError,
    refetch: refreshgetCreditPercentage,
    isLoading,
  } = useQuery(['creditPercentage'], getCreditPercentage);

  return {
    getCreditPercentageData,
    getCreditPercentageDataError,
    nogetCreditPercentage,
    refreshgetCreditPercentage,
    isLoading,
  };
}

export default useCreditPercentage;
