import styled from 'styled-components';

import { Select, InputLabel, FormControl } from '@material-ui/core';

export const FormControlStyled = styled(FormControl)`
  width: 150px;

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 1400px) {
    width: 100px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`;

export const StyledSelect = styled(Select)`
  width: ${(props) => (props.width ? props.width : '120px')};

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-root > .Mui-disabled {
    opacity: 50%;
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  @media (max-width: 1400px) {
    width: 100px;
  }
`;
