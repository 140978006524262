import { useContext } from 'react';
import { AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useFaturas() {
  const { getSession } = useContext(AccountContext);


  const getHeader = async () => {
    const { headers } = getSession();

    return headers;
  };

  const {
    headers,
  } = useQuery(['headers'], getHeader);

  return { headers };
}

export default useFaturas;