import React, { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core/';
import { WrapContainer } from './style';
import Permissions from 'common/permissions';
import { LocalStorage, Messages } from 'common';
import ObjectsMainTable from './components/objects-table';
import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseSubHeaderBar,
  IoseSearch,
  IoseObjectAddModal,
} from 'components';
import {
  useObjects,
  useEnvironments,
  useClientData,
  useCircuits,
  useGroupPermissions,
} from 'hooks';

export default function ObjectContainer() {
  const [cards, setCards] = useState([]);
  const [filteredObjects, setFilteredObjects] = useState([]);
  const [error, setError] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [search, setSearch] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();

  const { objects, noObjectsError } = useObjects();
  const { environments } = useEnvironments();
  const { uuidClient } = useClientData();
  const { circuits } = useCircuits(uuidClient);
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'objetos', 'leitura'];
    const addPath = ['cadastro', 'objetos', 'criar'];
    const editPath = ['cadastro', 'objetos', 'editar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (hasReadPermission) {
      objects?.length > 0 ? setCards(objects) : setError(noObjectsError);
    }
  }, [objects, noObjectsError, hasReadPermission]);

  const filterObjects = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppercase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = objects.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppercase)
      );

      setFilteredObjects(itemFiltered);
      if (itemFiltered.length === 0) {
        setError(Messages.noFindEnvironments);
      }
    } else {
      setSearch(false);
      setFilteredObjects([]);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="Criar Objeto"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Objetos"
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Objetos…"
          funcSearch={filterObjects}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable = () => {
    return (
      <ObjectsMainTable
        data={search ? filteredObjects : objects}
        editPermission={hasEditPermission}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <IoseInfinityScroll
          dataLength={cards.length}
          next={() => {}}
          hasMore={true}
          endList={false}
          scrollableTarget={'container'}
        >
          {hasReadPermission
            ? renderTable()
            : setError(
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              )}
        </IoseInfinityScroll>
      );
    }
  };

  const renderModals = () => {
    return (
      <IoseObjectAddModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        environments={environments}
        allCircuits={circuits}
      />
    );
  };

  return (
    <Slide direction="up" in={true} timeout={600} mountOnEnter unmountOnExit>
      <WrapContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrapContainer>
    </Slide>
  );
}
