import { useState, useContext, useCallback } from 'react';
import { ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useObjects() {
  const [noObjectsError, setNoObjectsError] = useState('');
  const { getSession } = useContext(AccountContext);

  const getObjects = useCallback(async () => {
    try {
      const { headers } = await getSession();

      let allData = [];
      let lastEvaluatedKey = null;
      const limit = 10000;

      do {
        const response = await ioseAPI.getAllObjects(
          headers,
          lastEvaluatedKey,
          limit
        );

        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } while (lastEvaluatedKey);

      if (allData.length === 0) {
        setNoObjectsError('Nenhum objeto cadastrado.');
        return [];
      } else {
        setNoObjectsError('');
        return allData;
      }

    } catch (error) {
      console.error(error);
      setNoObjectsError('Erro ao buscar objetos, recarregue a página.');
      throw error;
    }
  }, [getSession]);

  const {
    data: objects,
    error: objectsError,
    refetch: refreshObjects,
    isLoading,
  } = useQuery(['objectsData'], getObjects, {
    onError: () => {
      if (!noObjectsError) {
        setNoObjectsError('Erro ao buscar objetos, recarregue a página.');
      }
    },
    onSuccess: (data) => {
      if (data.length === 0) {
        setNoObjectsError('Nenhum objeto cadastrado.');
      }
    },
  });

  // Geração do mapeamento dinâmico do 3D
  const device3dMapping = objects
    ?.filter((object) => object.id_3d && object.uuid_circuit)
    .map((object) => ({
      uuid_circuit: object.uuid_circuit,
      uuid_object: object.id_3d,
    }));

  return {
    objects,
    objectsError,
    noObjectsError,
    refreshObjects,
    isLoading,
    device3dMapping,
  };
}

export default useObjects;
