import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueGeneralManagement() {
  const [noGeneralManagementError, setNoGeneralManagementError] = useState('');
  const { consumerGroups } = useConsumerGroup();

  const getUniqueGeneralManagement = async () => {
    const generalManagementSet = new Set();

    consumerGroups?.forEach((item) => {
      if (
        item.general_management
        && item.general_management !== '0'
        && item.general_management !== ''
        && item.general_management.toUpperCase() !== 'NULL'
      ) {
        generalManagementSet.add(item.general_management);
      }
    });

    const generalManagements = [...generalManagementSet].map(
      (generalManagement) => ({
        value: generalManagement,
        label: generalManagement,
      })
    );

    if (generalManagements.length === 0) {
      setNoGeneralManagementError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return generalManagements;
  };

  const {
    data: generalManagements,
    error: generalManagementsError,
    refetch: refreshGeneralManagement,
    isLoading,
  } = useQuery(['generalManagements', consumerGroups], getUniqueGeneralManagement, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    generalManagements,
    generalManagementsError,
    noGeneralManagementError,
    refreshGeneralManagement,
    isLoading,
  };
}

export default useUniqueGeneralManagement;
