import * as XLSX from 'xlsx';

function transformDateString(inputDate) {
  const parts = inputDate?.split('T')[0]?.split('-');
  const day = parts?.[2];
  const month = parts?.[1];
  const year = parts?.[0];
  return `${day}/${month}/${year}`;
}

function transformDateString2(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export default function exportTelemetriaToExcel(
  data,
  previousReadingStart,
  previousReadingEnd
) {
  const formattedPreviousReadingStart = transformDateString(
    previousReadingStart
  );
  const formattedPreviousReadingEnd = transformDateString2(previousReadingEnd);

  // Convertendo as datas formatadas de volta para o formato Date
  const dateStart = new Date(previousReadingStart);
  const dateEnd = new Date(previousReadingEnd);

  const daysDifference = Math.ceil((dateEnd - dateStart) / (1000 * 3600 * 24));

  const groupedData = {};

  data.forEach((obj) => {
    const {
      numero_telemetria,
      total_potencia_a_wh_q1_pta = 0,
      total_potencia_a_wh_q1_fp = 0,
      total_demanda_energia_reativa_q1_pta = 0,
      total_demanda_energia_reativa_q1_fp = 0,
      total_demanda_energia_ativa_q1_pta = 0,
      total_demanda_energia_ativa_q1_fp = 0,
      min_fator_a_q1 = 0,
      conta_pacotes = 0,
      conta_pacotes_alarmes = 0,
      unidade_consumidora = '',
      demanda_fora = '',
      demanda_ponta = '',
      general_management = '',
      regional_management = '',
      city = '',
    } = obj;

    if (!groupedData[numero_telemetria]) {
      groupedData[numero_telemetria] = {
        numero_telemetria,
        unidade_consumidora,
        demanda_fora,
        demanda_ponta,
        general_management,
        regional_management,
        city,
        total_potencia_a_wh_q1_pta: 0,
        total_potencia_a_wh_q1_fp: 0,
        total_demanda_energia_reativa_q1_pta: 0,
        total_demanda_energia_reativa_q1_fp: 0,
        max_total_demanda_energia_ativa_q1_pta: -Infinity,
        max_total_demanda_energia_ativa_q1_fp: -Infinity,
        min_fator_a_q1_total: [],
        conta_pacotes: 0,
        pacote_estimado: daysDifference * 96,
        confiabilidade: 0,
        conta_pacotes_alarmes: 0,
      };
    }

    groupedData[
      numero_telemetria
    ].total_potencia_a_wh_q1_pta += total_potencia_a_wh_q1_pta;
    groupedData[
      numero_telemetria
    ].total_potencia_a_wh_q1_fp += total_potencia_a_wh_q1_fp;
    groupedData[
      numero_telemetria
    ].total_demanda_energia_reativa_q1_pta += total_demanda_energia_reativa_q1_pta;
    groupedData[
      numero_telemetria
    ].total_demanda_energia_reativa_q1_fp += total_demanda_energia_reativa_q1_fp;

    if (
      total_demanda_energia_ativa_q1_pta >
      groupedData[numero_telemetria].max_total_demanda_energia_ativa_q1_pta
    ) {
      groupedData[
        numero_telemetria
      ].max_total_demanda_energia_ativa_q1_pta = total_demanda_energia_ativa_q1_pta;
    }

    if (
      total_demanda_energia_ativa_q1_fp >
      groupedData[numero_telemetria].max_total_demanda_energia_ativa_q1_fp
    ) {
      groupedData[
        numero_telemetria
      ].max_total_demanda_energia_ativa_q1_fp = total_demanda_energia_ativa_q1_fp;
    }

    groupedData[numero_telemetria].min_fator_a_q1_total.push(min_fator_a_q1);
    groupedData[numero_telemetria].conta_pacotes += conta_pacotes;
    groupedData[
      numero_telemetria
    ].conta_pacotes_alarmes += conta_pacotes_alarmes;
  });

  const formattedData = Object.values(groupedData).map((obj) => ({
    numero_telemetria: obj.numero_telemetria,
    unidade_consumidora: obj.unidade_consumidora,
    total_potencia_a_wh_q1_pta: (obj.total_potencia_a_wh_q1_pta / 1000).toFixed(
      2
    ),
    total_potencia_a_wh_q1_fp: (obj.total_potencia_a_wh_q1_fp / 1000).toFixed(
      2
    ),
    total_demanda_energia_reativa_q1_pta: (
      obj.total_demanda_energia_reativa_q1_pta / 1000
    ).toFixed(2),
    total_demanda_energia_reativa_q1_fp: (
      obj.total_demanda_energia_reativa_q1_fp / 1000
    ).toFixed(2),
    max_total_demanda_energia_ativa_q1_pta: (
      obj.max_total_demanda_energia_ativa_q1_pta / 1000
    ).toFixed(2),
    max_total_demanda_energia_ativa_q1_fp: (
      obj.max_total_demanda_energia_ativa_q1_fp / 1000
    ).toFixed(2),
    demanda_fora: obj.demanda_fora,
    demanda_ponta: obj.demanda_ponta,
    min_fator_a_q1_total: (
      obj.min_fator_a_q1_total.reduce((acc, val) => acc + val, 0) /
      obj.min_fator_a_q1_total.length
    ).toFixed(2),
    conta_pacotes: obj.conta_pacotes,
    pacote_estimado: obj.pacote_estimado,
    confiabilidade: ((obj.conta_pacotes / obj.pacote_estimado) * 100).toFixed(
      2
    ),
    conta_pacotes_alarmes: obj.conta_pacotes_alarmes,
    general_management: obj.general_management,
    regional_management: obj.regional_management,
    city: obj.city,
  }));

  const reorderedColumns = [
    'numero_telemetria',
    'unidade_consumidora',
    'total_potencia_a_wh_q1_pta',
    'total_potencia_a_wh_q1_fp',
    'total_demanda_energia_reativa_q1_pta',
    'total_demanda_energia_reativa_q1_fp',
    'max_total_demanda_energia_ativa_q1_pta',
    'max_total_demanda_energia_ativa_q1_fp',
    'demanda_fora',
    'demanda_ponta',
    'min_fator_a_q1_total',
    'conta_pacotes',
    'pacote_estimado',
    'confiabilidade',
    'conta_pacotes_alarmes',
    'general_management',
    'regional_management',
    'city',
  ];

  const headers = {
    numero_telemetria: 'Telemetria',
    unidade_consumidora: 'Unidade Consumidora',
    total_potencia_a_wh_q1_pta: 'Consumo PTA(kWh)',
    total_potencia_a_wh_q1_fp: 'Consumo FP(kWh)',
    total_demanda_energia_reativa_q1_pta: 'Reativo PTA(kVar)',
    total_demanda_energia_reativa_q1_fp: 'Reativo FP(kVar)',
    max_total_demanda_energia_ativa_q1_pta: 'Demanda PTA Máx.(kW)',
    max_total_demanda_energia_ativa_q1_fp: 'Demanda FP Máx.(kW)',
    demanda_fora: 'Demanda FP Cont.(kW)',
    demanda_ponta: 'Demanda PTA Cont.(kW)',
    min_fator_a_q1_total: 'Fator de Potência Médio',
    conta_pacotes: 'Pacotes Recebidos',
    pacote_estimado: 'Pacotes Estimados',
    confiabilidade: 'Confiabilidade (%)',
    conta_pacotes_alarmes: 'Pacotes Alarmes',
    general_management: 'GG',
    regional_management: 'GR',
    city: 'Cidade',
  };

  const worksheetData = [
    [
      `Relatório de Telemetria. Período filtrado ${formattedPreviousReadingStart} até ${formattedPreviousReadingEnd}`,
    ],
    reorderedColumns.map((column) => headers[column]),
    ...formattedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  reorderedColumns.forEach((column, index) => {
    worksheet[XLSX.utils.encode_cell({ r: 1, c: index })].v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'relatorio-telemetria.xlsx');
}
