import styled from 'styled-components';

//Imports from Material UI
import { DatePicker } from '@material-ui/pickers';

export const Container = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  p {
    font-size: 12px;
    margin-bottom: 4px;

    @media (max-width: 1360px) {
      font-size: 10px;
    }
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 120px;

  span {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--primary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--primary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--primary) !important'
        : 'var(--base) !important'};
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--primary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  .MuiInputBase-root input:after {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 18px;
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-size: 15px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }
`;
