import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

import IoseApiLib from 'iose-api-lib';

//Hook to redirect beetween container
import { useHistory } from 'react-router-dom';

//Imports to generate Delete Alert
import { useAlert } from 'react-alert';

//Imports from styles
import { ContainerCards } from './style';

//Imports from common
import Messages from 'common/Messages';
import LocalStorage from 'common/local_storage_api';
import { useSession } from 'common/contexts/session';
import { useClient } from 'common/contexts/client';

//Import from IOSE
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import IoseContainer from 'components/IoseContainer';
import IoseSearch from 'components/IoseSearch';
import IoseCardClient from 'components/IoseCardClient';
import { IoseAlertLoadingCards } from 'components/IoseAlert';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseButtonIcon2 from 'components/IoseButtonIcon2';

//Imports from Icons
import AddIcon from '@material-ui/icons/Add';

//Imports img
import EnterpriseIcon from './img/enterpriseIcon.svg';

//Import from Material UI
import { Grow } from '@material-ui/core/';
import IoseAddUserModal from 'components/Modals/IoseAddUserModal';

/**This function create a Container that handles everything that will happen in the super user area */
export default function SuperUserContainer({
  openSide,
  getcards,
  setGetcards,
}) {
  const history = useHistory();
  const alert = useAlert();

  const { verifySession } = useSession();
  const { setClient } = useClient();
  const session = LocalStorage.getSession();

  const title = 'Super Usuário';
  const subtitle = 'Painel de Controle';

  const [allClients, setAllClients] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');

  const [nextPage, setNextPage] = useState('');

  const [endListInfinityScroll, setEndListInfinityScroll] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);

  //State to filter cards
  const [allClientsFiltered, setallClientsFiltered] = useState([]);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcards]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const getAllData = async (moreClients) => {
    setError('');
    setLoading(true);

    let allClientsLocal = {};

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        if (!moreClients) {
          allClientsLocal = await getAllClients();
          setAllClients(allClientsLocal.data);
        } else if (nextPage) {
          allClientsLocal = await getAllClients(nextPage);
          setAllClients(allClients.concat(allClientsLocal.data));
          allClientsLocal.next_page && setEndListInfinityScroll(true);
        }

        allClientsLocal.length === 0 && setError(Messages.noRegisteredClients);
        setNextPage(allClientsLocal.next_page);
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorGetData;
      setError(message);
    }

    setGetcards(false);
    setLoading(false);
  };

  const getAllClients = async (nextpage = 0) => {
    try {
      let response = await IoseApiLib.Client.getAll(nextpage);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getMoreClients = () => nextPage && getAllData(true);

  const showAlert = (success, message) => {
    return alert.show(`${message}`, {
      title: success ? 'Sucesso!' : 'Erro!',
      closeCopy: 'Fechar',
    });
  };

  const deleteClient = async (uuid_client) => {
    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        const response = await IoseApiLib.Client.delete(uuid_client);
        showAlert(true, response.message);
        getAllData();
      }
    } catch (err) {
      const errorMessage = error.response
        ? error.response.data.message
        : `${Messages.errorDeleteClient}`;

      showAlert(false, errorMessage);
    }
  };

  const redirectUnityContainer = (client) => {
    setClient(client);
    history.push({
      pathname: '/global/client',
      state: {
        clientData: client,
      },
    });
  };

  const redirectEditClient = (client) => {
    setClient(client);
    history.push({
      pathname: '/global/super/editclient',
      state: {
        uuid_client: client.uuid_client,
        clientname: client.name,
        description_client: client.description,
      },
    });
    openSide();
  };

  const redirectCreateClient = (e) => {
    history.push({
      pathname: '/global/super/createclient',
      state: {
        client: '',
      },
    });
    openSide();
  };

  const redirectToSidebarAdminsContainer = (client) => {
    setClient(client);
    history.push({
      pathname: '/global/super/admins',
      state: {
        clientname: client.name,
        uuid_client: client.uuid_client,
        adminPadding: true,
      },
    });
    openSide();
  };

  const redirectToUserGroupsContainer = () => {
    history.push({
      pathname: '/user-groups',
      // state: {
      //   clientname: client.name,
      //   uuid_client: client.uuid_client,
      //   adminPadding: true,
      // },
    });
  };

  const redirectToUsersContainer = () => {
    history.push({
      pathname: '/users',
      // state: {
      //   clientname: client.name,
      //   uuid_client: client.uuid_client,
      //   adminPadding: true,
      // },
    });
  };

  const renderCard = (client) => {
    return (
      <IoseCardClient
        key={client.uuid_client}
        client_name={client.name}
        client_description={client.description}
        client_uuid={client.uuid_client}
        onClickCard={() => redirectUnityContainer(client)}
        onClickEdit={() => redirectEditClient(client)}
        onClickAdmin={() => redirectToSidebarAdminsContainer(client)}
        onClickAddUser={() => redirectToUsersContainer()}
        onClickDelet={() => deleteClient(client.uuid_client)}
        identification={client.uuid_client}
        onClickGroups={() => redirectToUserGroupsContainer()}
      />
    );
  };

  const filterClient = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = allClients.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setallClientsFiltered(itemFiltered)
        : setError(Messages.noFindClient);
    } else {
      setSearch(false);
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const description = `Total: ${allClients.length}`;

    const button = (
      <IoseButtonIcon2
        left="20px"
        icon1={EnterpriseIcon}
        icon2={<AddIcon />}
        click={redirectCreateClient}
      />
    );

    return (
      <IoseSubHeaderBar
        title={title}
        subtitle={subtitle}
        button={button}
        description={description}
      >
        <IoseSearch
          placeholder="Pesquisar Cliente..."
          funcSearch={filterClient}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards justify="center">
        {search
          ? allClientsFiltered.map(renderCard)
          : allClients.map(renderCard)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <IoseInfinityScroll
          dataLength={allClients.length}
          next={getMoreClients}
          hasMore={true}
          loading={loading}
          endList={endListInfinityScroll}
          marginTop={'30px'}
          scrollableTarget={'container'}
        >
          {renderAllCards()}
        </IoseInfinityScroll>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddUserModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={() => {}}
          notificationType={() => {}}
          notification={() => {}}
          showNotification={() => {}}
          addNotification={() => {}}
          cognitoGroupsOptions={() => {}}
          loading={false}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <IoseContainer id="container">
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </IoseContainer>
    </Grow>
  );
}

SuperUserContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
};
