import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { IoseButton } from 'components';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { AccountContext, ioseAPI, Messages } from 'common';
import { SimpleOptions } from '../../../constants/index';
import * as S from '../style';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
  useConsumerGroup,
  useUniqueAtividades,
  useUniqueConcessionaires,
  useUniqueConsumerGroupAddress,
  useUniqueConsumerGroupModalidade,
  useUniqueConsumerGroupOperatingUnity,
  useUniqueConsumerGroupSubgroup,
  useUniqueGeneralManagement,
  useUniqueRegionalManagement
} from 'hooks';

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function ConsumerGroupFilters({ filter, error: setError, loading, getAll, setLastFilter }) {
  const { uniqueAtividades } = useUniqueAtividades()
  const { uniqueConcessionaires } = useUniqueConcessionaires()
  const { regionalManagement } = useUniqueRegionalManagement();
  const { generalManagements } = useUniqueGeneralManagement();
  const { consumerGroupsForSelect,  consumerGroupsCitiesForSelect, consumerGroupsMetersForSelect} = useConsumerGroup()
  const { address } = useUniqueConsumerGroupAddress()
  const { modalidades } = useUniqueConsumerGroupModalidade()
  const { operatingUnitys } = useUniqueConsumerGroupOperatingUnity()
  const { subgroups } = useUniqueConsumerGroupSubgroup()
  const { getSession } = useContext(AccountContext);

  async function getConsumerGroups(object) {
    loading(true);

    try {
      const { headers } = await getSession();

      const response = await ioseAPI.getFilteredConsumerGroups(
        headers,
        object
      );

      const { data } = response;

      filter(data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.noRegisteredConsumerGroups;

        setError(errorMessage);
    } finally {
      loading(false);
    }
  }

  const initialValues = {
    identification_number: '',
    atividade: '',
    concessionaire: '',
    general_management: '',
    regional_management: '',
    address: '',
    modalidade: '',
    operating_unity: '',
    mercado_livre: '',
    telemetry: '',
    subgrupo: '',
    meter: '',
    city: '',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);
    setLastFilter(filteredValues)
    await getConsumerGroups(filteredValues);
  };

  const cleanFilters = (resetForm) => {
    resetForm()
    getAll()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm, isSubmitting }) => (
        <Form>
          <S.FormDivContainer>
          <S.FormDivItem width='100px'>
              <IoseDynamicSelect
                label="UC"
                value={values.identification_number}
                onChange={(value) => setFieldValue('identification_number', value)}
                options={consumerGroupsForSelect}
                width="100px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='120px'>
              <IoseDynamicSelect
                label="Atividade"
                value={values.atividade}
                onChange={(value) => setFieldValue('atividade', value)}
                options={uniqueAtividades}
                width="120px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='150px'>
              <IoseDynamicSelect
                label="Concessionária"
                value={values.concessionaire}
                onChange={(value) => setFieldValue('concessionaire', value)}
                options={uniqueConcessionaires}
                width="150px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='80px'>
              <IoseDynamicSelect
                label="GG"
                value={values.general_management}
                onChange={(value) => setFieldValue('general_management', value)}
                options={generalManagements}
                width="80px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='80px'>
              <IoseDynamicSelect
                label="GR"
                value={values.regional_management}
                onChange={(value) => setFieldValue('regional_management', value)}
                options={regionalManagement}
                width="80px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='130px'>
              <IoseDynamicSelect
                label="Endereço"
                value={values.address}
                onChange={(value) => setFieldValue('address', value)}
                options={address}
                width="130px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='130px'>
              <IoseDynamicSelect
                label="Modalidade"
                value={values.modalidade}
                onChange={(value) => setFieldValue('modalidade', value)}
                options={modalidades}
                width="130px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='140px'>
              <IoseDynamicSelect
                label="Unidade Op."
                value={values.operating_unity}
                onChange={(value) => setFieldValue('operating_unity', value)}
                options={operatingUnitys}
                width="140px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='110px'>
              <IoseDynamicSelect
                label="M. Livre"
                value={values.mercado_livre}
                onChange={(value) => setFieldValue('mercado_livre', value)}
                options={SimpleOptions}
                width="110px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='120px'>
              <IoseDynamicSelect
                label="Telemetria"
                value={values.telemetry}
                onChange={(value) => setFieldValue('telemetry', value)}
                options={SimpleOptions}
                width="120px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='120px'>
              <IoseDynamicSelect
                label="Subgrupo"
                value={values.subgrupo}
                onChange={(value) => setFieldValue('subgrupo', value)}
                options={subgroups}
                width="120px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='120px'>
              <IoseDynamicSelect
                label="Medidor"
                value={values.meter}
                onChange={(value) => setFieldValue('meter', value)}
                options={consumerGroupsMetersForSelect}
                width="120px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='120px'>
              <IoseDynamicSelect
                label="Cidade"
                value={values.city}
                onChange={(value) => setFieldValue('city', value)}
                options={consumerGroupsCitiesForSelect}
                width="120px"
              />
            </S.FormDivItem>

            <div style={{marginLeft: '5px', display: 'flex', gap: '10px'}}>
              <IoseButton height="35px" type="submit" disabled={isSubmitting}>
                {/* FILTRAR */}
                <SearchIcon />
              </IoseButton>

              <IoseRemoveButton onClick={() => cleanFilters(resetForm)}>
              {/* LIMPAR */}
              <CloseIcon />
            </IoseRemoveButton>
            </div>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
