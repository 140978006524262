import React from 'react';
import { TableCell, Paper, makeStyles, TableFooter } from '@material-ui/core';

import { StyledTable, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { realizaCalculosTarifa } from 'common/mappings/faturaCalculoMapping';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

const tranformaReal = (dado) => {
    return dado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

const TableCellCentered = ({ children, description }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={description}
      placement="top"
      arrow
    >
      <TableCell className={classes.tableCell}>{children}</TableCell>
    </Tooltip>
  );
};

export default function TarifasFooterTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    if (data) {
      if (
        data?.modalidade !== '' &&
        data?.subgrupo !== '' &&
        data?.tarifas.length > 0 &&
        data?.faturas.length > 0 &&
        data?.itens.length > 0
      ) {
        return (
          <Paper style={{alignContent: 'end', alignItems: 'revert'}}>
            <LogsTable data={data} id="scrollableDiv" />
          </Paper>
        );
      } else {
        return (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="300px"
            width="300px"
          />
        );
      }
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  const LogsTable = ({ data, onRowClick }) => {
    const classes = useStyles();

    data = realizaCalculosTarifa(data)

    const totalAzul = data?.reduce((total, item) => {
        return total + item.azul;
    }, 0);
    const totalVerde = data?.reduce((total, item) => {
        return total + item.verde;
    }, 0);

    // const totalConsumo = data?.reduce((total, item) => {
    //   return total + (item.consumoVerde > item.consumoAzul ? item.consumoVerde : item.consumoAzul);
    // }, 0);

    // const totalDemanda = data?.reduce((total, item) => {
    //   return total + (item.demandaVerde > item.demandaAzul ? item.demandaVerde : item.demandaAzul);
    // }, 0);

    // const totalDemandaUltrap = data?.reduce((total, item) => {
    //   return total + (item.demandaUltrapVerde > item.demandaUltrapAzul ? item.demandaUltrapVerde : item.demandaUltrapAzul);
    // }, 0);

    // const totalAdcionalDemanda = data?.reduce((total, item) => {
    //   return total + (item.adcionalDemandaVerde > item.adcionalDemandaAzul ? item.adcionalDemandaVerde : item.adcionalDemandaAzul);
    // }, 0);

    // const totalReativos = data?.reduce((total, item) => {
    //   return total + (item.reativos > item.adcionalDemandaAzul ? item.reativos : item.adcionalDemandaAzul);
    // }, 0);

    // const totalOutros = data?.reduce((total, item) => {
    //   return total + item.outros;
    // }, 0);

    return (
        <StyledTable>
            <TableFooter>
            <TableRowStyled className={classes.tableRow}>
                <TableCellCentered description={'Valores totais nas tarifas azul e verde nos últimos doze meses'}>TOTAL</TableCellCentered>
                {/* <TableCellCentered>{tranformaReal(totalConsumo)}</TableCellCentered>
                <TableCellCentered>{tranformaReal(totalDemanda)}</TableCellCentered>
                <TableCellCentered>{tranformaReal(totalDemandaUltrap)}</TableCellCentered>
                <TableCellCentered>{tranformaReal(totalAdcionalDemanda)}</TableCellCentered>
                <TableCellCentered>{tranformaReal(totalReativos)}</TableCellCentered>
                <TableCellCentered>{tranformaReal(totalOutros)}</TableCellCentered> */}
                <TableCellCentered description={'Valor total da tarifa azul nos últimos doze meses'}>
                  {
                    tranformaReal(totalAzul)
                  }
                </TableCellCentered>
                <TableCellCentered description={'Valor total da tarifa verde nos últimos doze meses'}>
                  {
                    tranformaReal(totalVerde)
                  }
                </TableCellCentered>
                <TableCellCentered description={'Diferança entre os valores das tarifas nos últimos doze meses'}>
                    {
                        totalAzul - totalVerde > 0
                            ? tranformaReal(totalAzul -totalVerde)
                            : tranformaReal(totalVerde - totalAzul)
                    }
                </TableCellCentered>
                <TableCellCentered description={'Recomendção baseada nos últimos doze meses'}>
                    {totalVerde > totalAzul ? 'AZUL' : 'VERDE'}
                </TableCellCentered>
            </TableRowStyled>
            </TableFooter>
        </StyledTable>
    )};


  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={1}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={50}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
