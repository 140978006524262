import { useCallback } from 'react';

const useFormatBillData = () => {
  const formatBillData = useCallback((energyBills) => {
    const result = [];

    energyBills?.forEach((data) => {
      const keys = Object.keys(data).filter((key) =>
        /^item_\w+_\d+$/.test(key)
      );

      keys.sort((a, b) => {
        const numA = parseInt(a.substring(a.lastIndexOf('_') + 1));
        const numB = parseInt(b.substring(b.lastIndexOf('_') + 1));

        return numA - numB;
      });

      const processedData = {};

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const index = key.substring(key.lastIndexOf('_') + 1);
        const propertyName = key.substring(
          key.indexOf('_') + 1,
          key.lastIndexOf('_')
        );
        const value = data[key];

        if (!processedData[index]) {
          processedData[index] = {};
        }

        processedData[index][propertyName] = value;
      }

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const index = key.substring(key.lastIndexOf('_') + 1);
        const propertyName = key.substring(
          key.indexOf('_') + 1,
          key.lastIndexOf('_')
        );
        const value = data[key];

        if (!processedData[index]) {
          processedData[index] = {};
        }

        processedData[index][propertyName] = value;
        processedData[index][`${propertyName}_original`] = key;
      }

      const lines = 12;
      const properties = [
        'measurer',
        'magnitude',
        'timetables',
        'measurer_previous_reading',
        'measurer_current_reading',
        'measurer_constant',
        'kwh_consumption',
      ];

      const measurer_data = {};

      properties.forEach((property) => {
        for (let i = 1; i <= lines; i++) {
          measurer_data[`${property}_${i}`] = data?.[`${property}_${i}`];
        }
      });

      const taxes_data = {
        icms_calculation_basis_total: data?.icms_calculation_basis_total,
        cofins_calculation_basis_total: data?.cofins_calculation_basis_total,
        pis_calculation_basis_total: data?.pis_calculation_basis_total,
        icms_aliquot: data?.icms_aliquot,
        cofins_aliquot: data?.cofins_aliquot,
        pis_aliquot: data?.pis_aliquot,
        icms_total: data?.icms_total,
        cofins_total: data?.cofins_total,
        pis_total: data?.pis_total,
      };

      const itensLines = 17;
      const itensProperties = [
        'item_description',
        'item_unity',
        'item_consumption',
        'item_unitary_value',
        'item_total_value',
        'item_piscofins',
        'item_icms',
        'item_tax',
      ];

      const itens_data = {};

      itensProperties.forEach((property) => {
        for (let i = 1; i <= itensLines; i++) {
          itens_data[`${property}_${i}`] = data?.[`${property}_${i}`];
        }
      });

      const completeBillData = {
        uuid_energy_bill: data?.uuid_energy_bill,
        bill_name: data?.bill_name,
        classification: data?.classification,
        identification_number: data?.identification_number,
        reference_month: data?.reference_month,
        number_days: data?.number_days,
        current_reading: data?.current_reading,
        previous_reading: data?.previous_reading,
        measurer: data?.measurer,
        account_value: data?.account_value,
        cnpj: data?.cnpj,
        pdf_name: data?.pdf_name,
        pagina: data?.pagina,
        measurer_data: measurer_data,
        taxes_data: taxes_data,
        itens_data: itens_data,
        table_data: Object.values(processedData),
      };

      result.push(completeBillData);
    });

    result.sort((a, b) => {
      const [monthA, yearA] = a.reference_month.split('/');
      const [monthB, yearB] = b.reference_month.split('/');

      const currentDate = new Date();

      const dateA = new Date(yearA, monthA - 1);
      const dateB = new Date(yearB, monthB - 1);

      const diffA = Math.abs(currentDate - dateA);
      const diffB = Math.abs(currentDate - dateB);

      if (diffA < diffB) {
        return -1;
      } else if (diffA > diffB) {
        return 1;
      } else {
        return 0;
      }
    });

    result.reverse();

    return result;
  }, []);

  return formatBillData;
};

export default useFormatBillData;
