import React from 'react';
import * as XLSX from 'xlsx';

import {
  DivSearch,
  InputBaseStyled,
  SearchIconStyled,
  TableBox,
  TableContainer,
  TableHeaderBox,
  TableTitle,
} from './style';

import IoseLogsTable from 'components/Tables/IoseLogsTable';
import { IoseButton2 } from 'components/IoseButton';
import UserLogReports from 'reports/Logs/UserLogReports';

import { useCreateLogs } from 'hooks';

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data?.map((obj) => {
    const { uuid_contract, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'log_user',
    'log_user_email',
    'log_operation',
    'log_form',
    'log_date',
    'client_ip',
    'log_content',
  ];

  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'log_content') {
        reorderedObj[column] = JSON.stringify(obj[column], null, 2);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    log_user: 'Usuário',
    log_user_email: 'E-mail',
    log_operation: 'Operação',
    log_form: 'Formulário / Ação',
    log_date: 'Data',
    client_ip: 'IP do cliente',
    log_content: 'Conteúdo',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');
  XLSX.writeFile(workbook, 'log-iose.xlsx');
}

export default function IoseTableContainer({
  loading,
  tableTitle,
  searchInTable,
  searchInputLabel,
  data,
  hasReportPermission,
}) {
  const { createLog } = useCreateLogs();

  function handleDownloadButtonClick() {
    exportToExcel(data);
  }

  const handleRegisterLog = async (data) => {
    UserLogReports(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE LOGS';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };
  return (
    <TableContainer id="scrollableDiv">
      <TableBox>
        <TableHeaderBox>
          <TableTitle>
            <h3>{tableTitle}</h3>
          </TableTitle>
          {searchInTable ? (
            <DivSearch>
              <InputBaseStyled
                type="text"
                placeholder={'Pesquisar por ' + { searchInputLabel } + '...'}
                value={() => {}}
                onChange={() => {}}
              />
              <SearchIconStyled />
            </DivSearch>
          ) : (
            <></>
          )}

          {hasReportPermission ? (
            <>
              <IoseButton2 onClick={() => handleRegisterLog(data)}>
                Download PDF
              </IoseButton2>
              <IoseButton2 onClick={() => handleDownloadButtonClick(data)}>
                Download XLSX
              </IoseButton2>
            </>
          ) : null}
        </TableHeaderBox>

        <IoseLogsTable loading={loading} data={data} />
      </TableBox>
    </TableContainer>
  );
}
