import { useContext } from 'react';
import { ioseAPI, AccountContext, Messages } from 'common';
import { useQuery } from '@tanstack/react-query';

function useGemeos(uuid_unity) {
  const { getSession } = useContext(AccountContext);

  const { headers } = getSession();

  const getAllGemeos = async () => {
    const response = await ioseAPI.getGemeoDigitalByUnity(headers, uuid_unity, 0, 300);

    const gemeos = response.data.data || [];

    if (gemeos.length === 0) {
      console.error(Messages.noRegistered3dUnits);
      throw new Error(Messages.noRegistered3dUnits);
    }

    return gemeos;
  };

  const { data: gemeos, error: gemeosError } = useQuery(
    ['Gemeos'],
    getAllGemeos
  );

  return {
    gemeos,
    gemeosError,
  };
}

export default useGemeos;
