import React from "react";
import { AllIcon, BillsIcon, CreditIcon, IconWrapper, SidebarContainer, TelemetryIcon } from "../style";
import { Tooltip } from "@material-ui/core";

export default function RightSidebar({ visualization, setVisualization,consumerGroup }) {

  return (
    <SidebarContainer>
      <IconWrapper
        onClick={() => setVisualization('telemetry')}
        isSelected={visualization === 'telemetry'}
      >
        <Tooltip title="TELEMETRIA" placement="bottom" arrow>
          <TelemetryIcon />
        </Tooltip>
      </IconWrapper>

      <IconWrapper
        onClick={() => setVisualization('bills')}
        isSelected={visualization === 'bills'}
      >
        <Tooltip title="FATURAS" placement="bottom" arrow>
          <BillsIcon />
        </Tooltip>
      </IconWrapper>

      {consumerGroup && consumerGroup[0]?.beneficiaria === "SIM" ? (
        <IconWrapper
          onClick={() => setVisualization('credit')}
          isSelected={visualization === 'credit'}
        >
        <Tooltip title="CREDITOS" placement="bottom" arrow>
          <CreditIcon />
        </Tooltip>
      </IconWrapper>) : null}

      <IconWrapper
        onClick={() => setVisualization('all')}
        isSelected={visualization === 'all'}
      >
        <Tooltip title="REMOVER FILTRO" placement="bottom" arrow>
          <AllIcon />
        </Tooltip>
      </IconWrapper>
    </SidebarContainer>
  )
}