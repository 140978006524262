import { ioseAPI, Messages } from "common";


export async function getFaturasAclByIdentificationNumber(identification_number, sessionData) {
  try {
    const response = await ioseAPI.getAclByIdentificationNumber(
      sessionData.headers,
      identification_number
    );

    const faturasAcl = response.data

    if (faturasAcl.length === 0) {
      console.error(Messages.noFindAcl);
      throw new Error(Messages.noFindAcl);
    }
    return faturasAcl;
  } catch (error) {
    console.error(error)
  }
};