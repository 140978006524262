import styled from 'styled-components';
import { Typography, Grid, Card, InputBase } from '@material-ui/core';
import SwitchboardImage from './img/Switchboard.svg';
import SearchIcon from '@material-ui/icons/Search';

export const TitleDashboardComponent = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const Wrapper = styled.div`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 50%;

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const GridContainer = styled(Grid)`
  padding: 0px 10px 5px 25px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h3 {
    margin-left: 10px;
    color: var(--primary);
  }
`;

export const Container = styled(Card)`
  padding: 3%;
  width: 100%;
  height: 100%;
`;

export const SwitchboardIcon = styled.img.attrs((props) => ({
  src: SwitchboardImage,
}))`
  width: 20px;
  height: 20px;
  clear: var(--primary);
`;

export const Search = styled.div`
  position: relative;
  margin: auto, 0px, auto, 50px;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: var(--secondary);

  &:hover {
    .MuiInputBase-root {
      width: 100%;
      opacity: 100%;
    }

    svg {
      color: var(--secondary);
    }
  }

  .Mui-focused {
    width: 100%;
    opacity: ${(props) => (props.sidebar === 'true' ? '70%' : '100%')};
  }
`;

export const InputBaseStyled = styled(InputBase)`
  width: 50%;
  opacity: 80%;
  height: 30px;
  background: #ecf0f5;
  border-radius: 15px;
  padding-left: 10px;
  transition: all 0.3s linear;
  color: black;
  margin-left: 50px;

  @media (max-width: 500px) {
    width: 100%;
    opacity: 100%;
  }
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  color: var(--primary);
`;
