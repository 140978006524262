import React, { useContext, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import { AccountContext, ioseAPI } from 'common';
import { useUniqueGeneralManagement, useUniqueRegionalManagement } from 'hooks';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

function sumTotalValueAcl(data) {
  return data?.reduce((total, item) => {
    return total + item.valor_total;
  }, 0);
}

export default function EnergyBillDashboardFilters({
  setfilteredBillValue,
  setFilteredConsumption,
  setFilteredGeneralConsumption,
  setFilteredExtract,
  setFilteredExtractTable,
  setFilteredMercadoLivre,
  setSelectedRefMonth,
  setConsumptionData,
  setAggregatedDemanda,
  setAggregatedGreenDemanda,
  setSemConsumo,
  setLoading,
  setProcessingData,
  setBillItensDesperdicios,
  setLoadingDesperdicios,
  setucsDesativadas,
  setLoadingTable
}) {
  const { getSession } = useContext(AccountContext);
  const { regionalManagement } = useUniqueRegionalManagement();
  const { generalManagements } = useUniqueGeneralManagement();

  const [selectedDate, setSelectedDate] = useState(new Date(2024, 6, 1));
  const [filteredRegionalManagement, setFilteredRegionalManagement] = useState(regionalManagement);
  const [selectedGeneralManagement, setSelectedGeneralManagement] = useState();

  useEffect(() => {
    if (regionalManagement) {
      if (selectedGeneralManagement) {
        const filtered = regionalManagement.filter(
          (item) => item.general_management === selectedGeneralManagement
        );
        setFilteredRegionalManagement(filtered);
      } else {
        setFilteredRegionalManagement(regionalManagement);
      }
    }
  }, [selectedGeneralManagement, regionalManagement]);

  function aggregateConsumption(data) {
    const filteredData = data?.filter(
      (item) => item.modalidade !== 'CONVENCIONAL'
    );

    const consumos = {};

    filteredData.forEach((item) => {
      const identification_number = item.identification_number;

      if (!consumos[identification_number]) {
        consumos[identification_number] = {
          identification_number: identification_number,
          general_management: item.general_management,
          regional_management: item.regional_management,
          modalidade: item.modalidade,
          atividade: item.atividade,
          reference_month: item.reference_month,
          city: item.city,
          numero_telemetria: item.numero_telemetria,
          total_consumo_pta: 0,
          total_consumo_fp: 0,
        };
      }

      if (item.referente === 'Consumo PTA') {
        consumos[identification_number].total_consumo_pta +=
          item.referente_consumption;
      } else if (item.referente === 'Consumo FP') {
        consumos[identification_number].total_consumo_fp +=
          item.referente_consumption;
      }
    });

    // Adiciona o campo percentual_pta_fp
    Object.values(consumos).forEach((item) => {
      if (item.total_consumo_pta === 0 || item.total_consumo_fp === 0) {
        item.percentual_pta_fp = 0;
      } else {
        item.percentual_pta_fp = (
          (item.total_consumo_pta / item.total_consumo_fp) *
          100
        ).toFixed(4);
      }
    });

    return Object.values(consumos);
  }

  function aggregateBlueDemand(data) {
    const filteredData = data?.filter((item) => item.modalidade === 'AZUL');

    const demandas = {};

    filteredData.forEach((item) => {
      const identification_number = item?.identification_number;

      if (!demandas[identification_number]) {
        demandas[identification_number] = {
          identification_number: identification_number,
          demanda_contratada_fp: item.demanda_contratada_fp,
          demanda_contratada_pta: item.demanda_contratada_pta,
          total_demanda_pta: 0,
          total_demanda_fp: 0,
        };
      }

      if (item.referente === 'Demanda PTA') {
        demandas[identification_number].total_demanda_pta +=
          item.referente_consumption;
      } else if (item.referente === 'Demanda FP') {
        demandas[identification_number].total_demanda_fp +=
          item.referente_consumption;
      }
    });

    return Object.values(demandas);
  }

  function aggregateGreenDemand(data) {
    const filteredData = data?.filter((item) => item.modalidade === 'VERDE');

    const demandas = {};

    filteredData.forEach((item) => {
      const identification_number = item.identification_number;

      if (!demandas[identification_number]) {
        demandas[identification_number] = {
          identification_number: identification_number,
          demanda_contratada: item.demanda_contratada_fp,
          total_demanda: 0,
        };
      }

      if (item.referente === 'Demanda') {
        demandas[identification_number].total_demanda +=
          item.referente_consumption;
      }
    });

    return Object.values(demandas);
  }

  async function getFilteredData(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getCalculatedTotalFilteredConsumption(
        sessionData.headers,
        object
      );

      const { data } = response.data;

      setFilteredConsumption(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredSemConsumo(object) {
    try {
      const { headers } = await getSession();
      let allData = [];
      let lastEvaluatedKey = null;
      const limit = 10000;

      do {
        const response = await ioseAPI.getConsumptioResults(
          headers,
          lastEvaluatedKey,
          limit
        );

        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

        const filteredData = data?.filter(
          (item) => item?.reference_month === object.reference_month
        );
        allData = allData.concat(filteredData);

        lastEvaluatedKey = newLastEvaluatedKey;
      } while (lastEvaluatedKey);

      setSemConsumo(allData);
    } catch (error) {
      console.error(error);
    }
  }

  async function getFilteredUcsDesativadas(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.obterDesativadasFiltradas(
        sessionData.headers,
        object
      );
      const { data } = response.data;

      setucsDesativadas(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredBillValue(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getCalculateFilteredTotalBillValues(
        sessionData.headers,
        object
      );
      const { data } = response.data;
      setfilteredBillValue(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredGMData(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getCalculatedGeneralFilteredConsumption(
        sessionData.headers,
        object
      );

      const { data } = response.data;

      setFilteredGeneralConsumption(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredExtract(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getCalculateFilteredExtract(
        sessionData.headers,
        object
      );

      const { data, tableData } = response.data;
      setFilteredExtract(data);
      setFilteredExtractTable(tableData);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredDataAggregation(object) {
    let aggregatedData = [];
    let currentPageParams = { ...object };
    let hasNextPage = true;
    setLoading(true);
    setLoadingTable(true);

    try {
      const sessionData = await getSession();

      do {
        const response = await ioseAPI.getDataAggregation(
          sessionData.headers,
          currentPageParams
        );

        const { data, lastEvaluatedKey } = response.data;
        aggregatedData = [...aggregatedData, ...data];

        if (lastEvaluatedKey) {
          currentPageParams = { ...currentPageParams, lastEvaluatedKey };
        } else {
          hasNextPage = false;
        }
      } while (hasNextPage);

      const { general_management, regional_management } = object;

      if (general_management || regional_management) {
        aggregatedData = aggregatedData.filter(item => {
          let matches = true;

          if (general_management) {
            matches = matches && item.general_management === general_management;
          }

          if (regional_management) {
            matches = matches && item.regional_management === regional_management;
          }

          return matches;
        });
      }

      setAggregatedDemanda(aggregateBlueDemand(aggregatedData));
      setConsumptionData(aggregateConsumption(aggregatedData));
      setAggregatedGreenDemanda(aggregateGreenDemand(aggregatedData));

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingTable(false);
    }
  }

  async function getFilteredLogValues(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredEnergyBillLogs(
        sessionData.headers,
        object
      );

      const { data } = response.data;


      setProcessingData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getFilteredMercadoLivre(object) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredFaturasMercadoLivre(
        sessionData.headers,
        object
      );

      const { data } = response;

      if (data) {
        const totalSum = sumTotalValueAcl(data);

        setFilteredMercadoLivre(totalSum);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getFilteredDesperdicios = async (object) => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getFilteredDesperdicios(
        headers,
        object,
        lastEvaluatedKey, // Mantém a estrutura exata do lastEvaluatedKey retornado
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
      allData = allData.concat(data);

      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);
    setLoadingDesperdicios(false);

    setBillItensDesperdicios(allData);

    if (allData?.length === 0) {
      throw new Error('Nenhum desperdício registrado encontrado.');
    }

    return {
      allData,
    };
  };

  async function filterAllData(object) {
    try {
      const promises = [
        getFilteredData(object),
        getFilteredGMData(object),
        getFilteredBillValue(object),
        getFilteredSemConsumo(object),
        getFilteredExtract(object),
        getFilteredDataAggregation(object),
        getFilteredLogValues(object),
        getFilteredMercadoLivre(object),
        getFilteredDesperdicios(object),
        getFilteredUcsDesativadas(object),
      ];

      await Promise.all(promises);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setLoadingDesperdicios(false);
    }
  }

  const initialValues = {
    reference_month: '07/2024',
    general_management: '',
    regional_management: '',
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);

    if (filteredValues.reference_month) {
      setSelectedRefMonth(filteredValues.reference_month);
    }

    await filterAllData(filteredValues);
  };

  useEffect(() => {
    const defaultFilter = { reference_month: '07/2024' };
    setSelectedRefMonth('07/2024');
    filterAllData(defaultFilter);
    //eslint-disable-next-line
  }, []);

  const clear = (resetForm) => {
    resetForm()
    setSelectedGeneralManagement()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values, resetForm}) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem width='80px'>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                width="120px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <S.FormDivItem width='80px'>
              <IoseDynamicSelect
                label="GG"
                value={values.general_management}
                onChange={(value) => {
                  setFieldValue('general_management', value)
                  setSelectedGeneralManagement(value)
                }}
                options={generalManagements}
                width="120px"
              />
            </S.FormDivItem>

            <S.FormDivItem width='80px'>
              <IoseDynamicSelect
                label="GR"
                value={values.regional_management}
                onChange={(value) => setFieldValue('regional_management', value)}
                options={filteredRegionalManagement}
                width="120px"
              />
            </S.FormDivItem>

            <IoseButton type="submit">
              <SearchIcon />
            </IoseButton>

            <IoseRemoveButton onClick={() => clear(resetForm)}>
              {/* LIMPAR */}
              <CloseIcon />
            </IoseRemoveButton>

            {/* <IoseRemoveButton onClick={() => cleanAll(resetForm)}>
              LIMPAR FILTROS
            </IoseRemoveButton> */}
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
