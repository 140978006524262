import React from 'react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { GraficoDemandaVerdeRf12 } from './GraficoDemandaVerdeRf12';
import { GraficoDemandaAzulRf12 } from './GraficoDemandaAzulRf12';

export const GraficoDemandaRf12 = ({ data, unidadeConsumidora }) => {
  const uc = unidadeConsumidora ? unidadeConsumidora[0] : null;

  const renderGraficoPorModalidade = (uc) => {
    if (uc && uc.modalidade === 'VERDE') {
      return <GraficoDemandaVerdeRf12 data={data} unidadeConsumidora={unidadeConsumidora}/>;
    } else if (uc && uc.modalidade === 'AZUL') {
      return <GraficoDemandaAzulRf12 data={data} unidadeConsumidora={unidadeConsumidora} />;
    } else {
      return null;
    }
  };

  return (
    <div>
     {data && data.length > 0 ? (
      renderGraficoPorModalidade(uc)
     ) : (
     <IoseNoDataMessage
        message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
        height="300px"
        width="280px"
      />)}
    </div>
  )
};


