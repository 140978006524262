import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const convertImageToBase64 = (imagePath) => {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

const formatValues = (value) => {
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedValue;
};

function unitysWithErrorValue(data) {
  const fieldsToCheck = ['balance_value'];

  const errorThreshold = 0.2;

  return data.reduce((count, consumerGroup) => {
    if (
      fieldsToCheck.some(
        (field) =>
          consumerGroup[field] >= errorThreshold ||
          consumerGroup[field] <= -errorThreshold
      )
    ) {
      return count + 1;
    }
    return count;
  }, 0);
}

// function unitysWithErrorKwh(data) {
//   const fieldsToCheck = ['balance_consumption'];
//   //   'hist_consumo_ponta1',
//   //   'hist_consumo_fora1',
//   //   'hist_demanda_ponta1',
//   //   'hist_dem_fora1',
//   //   'dem_cont_ponta1',
//   //   'dem_cont_fora1',
//   //   'dem_tol_ponta1',
//   //   'dem_tol_fora1',
//   // ];

//   return data.reduce((count, consumerGroup) => {
//     if (fieldsToCheck.some((field) => consumerGroup[field] !== 0)) {
//       return count + 1;
//     }
//     return count;
//   }, 0);
// }

export default async function MeasurementReport(result) {

  const { data, parameters } = result;

  const consumerGroupSet = new Set();

  data.forEach((item) => {
    consumerGroupSet.add(item.identification_number);
  });

  const totalObjects = data?.length;
  const referenceMonth = data[0].reference_month;

  const totalPonta = data?.reduce(
    (acc, bill) => acc + bill.hist_consumo_ponta1,
    0
  );
  const totalForaPonta = data?.reduce(
    (acc, bill) => acc + bill.hist_consumo_fora1,
    0
  );
  const totalDemanPonta = data?.reduce(
    (acc, bill) => acc + bill.dem_tol_ponta1,
    0
  );
  const totalDemanForaPonta = data?.reduce(
    (acc, bill) => acc + bill.dem_tol_fora1,
    0
  );

  const totalValue = data?.reduce((acc, bill) => acc + bill.bill_value, 0);

  const totalCalcValue = data?.reduce(
    (acc, bill) => acc + bill.recalc_value,
    0
  );
  const totalBalance = totalValue - totalCalcValue;

  const totalUnitysWithErrorValue = unitysWithErrorValue(data);

  // const totalUnitysWithErrorKwh = unitysWithErrorKwh(data);

  const logoData = await convertImageToBase64(Logo);

  function generateReportTitle(parameters) {
    const {
      concessionaire,
      initial_expiration,
      final_expiration,
      initial_reading,
      final_reading,
      identification_number,
      reference_month,
      general_management,
      regional_management,
    } = parameters;

    if (concessionaire) {
      if (reference_month && identification_number) {
        return `Relatório de Medição - U.C. ${identification_number} - Concessionária ${concessionaire} - REF: MÊS/ANO ${reference_month}`;
      } else if (reference_month) {
        return `Relatório de Medição - Concessionária ${concessionaire} - REF: MÊS/ANO ${reference_month}`;
      } else {
        return `Relatório de Medição - Concessionária ${concessionaire}`;
      }
    } else if (
      initial_expiration &&
      final_expiration &&
      identification_number
    ) {
      return `Relatório de Medição - U.C. ${identification_number} - Vencimento - Período ${initial_expiration} a ${final_expiration}`;
    } else if (initial_reading && final_reading && identification_number) {
      return `Relatório de Medição - U.C. ${identification_number} - Leitura Anterior - Período ${initial_reading} a ${final_reading}`;
    } else if (initial_expiration && final_expiration) {
      return `Relatório de Medição - Vencimento - Período ${initial_expiration} a ${final_expiration}`;
    } else if (initial_reading && final_reading) {
      return `Relatório de Medição - Leitura Anterior - Período ${initial_reading} a ${final_reading}`;
    } else if (identification_number && reference_month) {
      return `Relatório de Medição - U.C. ${identification_number} - REF: MÊS/ANO ${reference_month}`;
    } else if (general_management && regional_management) {
      return `Relatório de Medição - Gerência Geral ${general_management} e Gerência Regional ${regional_management}`;
    } else if (general_management) {
      return `Relatório de Medição - Gerência Geral ${general_management}`;
    } else if (regional_management) {
      return `Relatório de Medição - Gerência Regional ${regional_management}`;
    } else if (reference_month) {
      return `Relatório de Medição - REF: MÊS/ANO ${reference_month}`;
    } else {
      return 'Relatório de Medição';
    }
  }

  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45],
        },
        {
          text: generateReportTitle(parameters),
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];

  const renderTributsTable = data?.map((bill) => {
    return {
      style: 'tableExample',
      table: {
        widths: ['*', '*', '*', '*', '*', '*', '*'],
        headerRows: 1,
        body: [
          [
            {
              text: `Inconsistências U.C ${bill.identification_number}`,
              style: 'tableHeader',
              colSpan: 7,
              alignment: 'left',
              fontSize: 7,
              bold: true,
              // fillColor: '#CCCCCC',
            },
            '',
            '',
            '',
            '',
            '',
            '',
          ],
          [
            {
              text: 'Tributo',
              style: 'tableHeader',
              alignment: 'center',
              fontSize: 7,
              bold: true,
            },
            { text: `ICMS: ${bill.icms_dif.toLocaleString()}`, fontSize: 7 },
            {
              text: `Cofins: ${bill.cofins_dif.toLocaleString()}`,
              fontSize: 7,
            },
            { text: `PIS: ${bill.pis_dif.toLocaleString()}`, fontSize: 7 },
            { text: '', fontSize: 7 },
            { text: '', fontSize: 7 },
            { text: '', fontSize: 7 },
          ],
          [
            {
              text: 'Consumo',
              style: 'tableHeader',
              alignment: 'center',
              fontSize: 7,
              bold: true,
            },
            {
              text: `Ponta: ${bill?.hist_consumo_ponta1.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Fora Ponta: ${bill?.hist_consumo_fora1.toLocaleString()}`,
              fontSize: 7,
            },
            { text: '', fontSize: 7 },
            { text: '', fontSize: 7 },
            { text: '', fontSize: 7 },
            { text: '', fontSize: 7 },
          ],
          [
            {
              text: 'Demanda',
              style: 'tableHeader',
              alignment: 'center',
              fontSize: 7,
              bold: true,
            },
            {
              text: `Ponta: ${bill?.hist_demanda_ponta1?.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Fora Ponta: ${bill?.hist_dem_fora1?.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Cont.Ponta: ${bill?.dem_cont_ponta1?.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Cont.Fora Ponta: ${bill?.dem_cont_fora1?.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Dem. Tol. Ponta: ${bill?.dem_tol_ponta1?.toLocaleString()}`,
              fontSize: 7,
            },
            {
              text: `Dem. Tol. Fora Ponta: ${bill?.dem_tol_fora1?.toLocaleString()}`,
              fontSize: 7,
            },
          ],
          [
            {
              text: 'Taxas',
              style: 'tableHeader',
              alignment: 'center',
              fontSize: 7,
              bold: true,
            },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
          ],
          [
            {
              text: 'Bandeira',
              style: 'tableHeader',
              alignment: 'center',
              fontSize: 7,
              bold: true,
            },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
            { text: ``, fontSize: 7 },
          ],
        ],
      },
    };
  });

  const combinedTables = data?.map((bill, index) => {
    const combinedTable = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'UC',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Concessionária',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Consumo (kWh)',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Valor da fatura',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Valor Calculado',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Diferença',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'REF: MÊS/ANO',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Leitura Anterior',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Data da vencimento',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'GG',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'GR',
                style: 'tableHeader',
                fontSize: 7,
                alignment: 'center',
                bold: true,
              },
            ],

            [
              {
                text: bill?.identification_number,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.concessionaire,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill.bill_consumption?.toLocaleString(),
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill.bill_value?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill.recalc_value?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.balance_value?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.reference_month,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.previous_reading,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.vencimento,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.general_management,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
              {
                text: bill?.regional_management,
                fontSize: 7,
                margin: [0, 1, 0, 1],
                alignment: 'center',
              },
            ],
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#CCCCCC' : null;
          },
        },
      },

      [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 0.5] }],

      renderTributsTable[index],

      [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 10] }],
    ];

    return combinedTable;
  });

  const summaryTable = {
    table: {
      headerRows: 1,
      widths: [250],
      body: [
        [
          {
            text: 'Resumo geral de medição',
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
            fillColor: '#CCCCCC',
          },
        ],
        [
          {
            text: `REF: MÊS/ANO:  ${referenceMonth}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Quantidade de UC’s:  ${consumerGroupSet.size}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Quantidade de Faturas:  ${totalObjects}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `UC’s com diferenças em valores:  ${totalUnitysWithErrorValue}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        // [
        //   {
        //     text: `UC’s com diferenças em medições:  ${totalUnitysWithErrorKwh}`,
        //     style: 'tableHeader',
        //     fontSize: 7,
        //     alignment: 'left',
        //     bold: true,
        //   },
        // ],
        [
          {
            text: `Consumo  Ponta total (kWh):  ${totalPonta.toLocaleString()}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Consumo Fora Ponta total (kWh):  ${totalForaPonta.toLocaleString()}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Demanda Ponta total: ${totalDemanPonta.toLocaleString()}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Demanda Fora Ponta total:  ${totalDemanForaPonta.toLocaleString()}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Valor total:  ${formatValues(totalValue)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Valor correto a pagar:  ${formatValues(totalCalcValue)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Total da diferença:  ${formatValues(totalBalance)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
      ],
    },
    margin: [0, 20, 0, 10],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [
    ...combinedTables,
    { text: '', pageBreak: 'before' },
    summaryTable,
  ];

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [12, 50, 15, 40],
    content: reportContent,
    footer: renderFooter,
  };

  // Adicionar o título apenas na primeira página
  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
