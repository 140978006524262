import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const DivSearch = styled.div`
  position: relative;
  margin: auto, 0px, auto, 10px;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: ${(props) => (props.sidebar === 'true' ? '100%' : '100%')};
  color: ${(props) =>
    props.sidebar === 'true' ? 'white' : 'var(--secondary)'};

  &:hover {
    .MuiInputBase-root {
      width: 100%;
      opacity: 100%;
    }

    svg {
      color: var(--secondary);
    }
  }

  .Mui-focused {
    width: 100%;
    opacity: ${(props) => (props.sidebar === 'true' ? '70%' : '100%')};
  }
`;

export const InputBaseStyled = styled(InputBase)`
  width: ${(props) => (props.sidebar === 'true' ? '30%' : '100%')};
  opacity: ${(props) => (props.sidebar === 'true' ? '0%' : '100%')};
  height: 30px;
  background: white;
  border-radius: 15px;
  padding-left: 10px;
  transition: all 0.15s linear;
  color: black;

  @media (max-width: 500px) {
    width: ${(props) => (props.sidebar === 'true' ? '10%' : '100%')};
    opacity: ${(props) => (props.sidebar === 'true' ? '0' : '100%')};
  }
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  color: ${(props) => (props.color ? props.color : 'var(--secondary)')};
`;
