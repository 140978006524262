import React, { useState } from 'react';
import PropType from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CsvDownloader from 'react-csv-downloader';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Messages } from 'common';

import {
  CircularProgressStyled,
  IoseButtonStyled,
  TypographyConfirmation,
} from './style';

import {
  IoseDeleteButton,
  IoseHistoricButtonDownload,
  IoseTextField,
  LoadingIndicatorSidebar,
} from 'components';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/** This component is a group of modals used in system, this Following refer jus to IoseDeleteInputModal
 *@visibleName IoseModals
 */

function formatDate(dateInfo) {
  const date = new Date(Date.parse(dateInfo));

  const day = String(date.getDate()).padStart(2, '0');
  const mounth = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');

  const circuitData = `${day}/${mounth}/${year}  ${hour}:${minute}:${second}`;
  return circuitData;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function IoseDeleteInputModal({
  title,
  name,
  deletefunc,
  param,
  cardbutton,
  primarybutton,
  left,
}) {
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    setLoading(true);

    if (confirmation === 'Confirmar') {
      deletefunc(param);
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
      setMessage(Messages.confirmationTextIncorret);
    }
  };

  return (
    <>
      <IoseDeleteButton
        card={cardbutton}
        primary={primarybutton}
        tooltip={title}
        onClick={handleClickOpen}
        left={left}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {loading ? (
          <LoadingIndicatorSidebar loading={loading} />
        ) : (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <b>{title}</b>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Tem certeza que deseja deletar <b>{name}</b>?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite <b>Confirmar</b> logo abaixo e aperte o botão
                Deletar em seguida.
              </Typography>
              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />
              {message !== '' && (
                <TypographyConfirmation gutterBottom>
                  {message}
                </TypographyConfirmation>
              )}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleConfirmation}>
                Deletar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export function IoseProfileModal({
  group,
  name,
  openModal,
  handleClose,
  // uuidUser,
  email,
}) {
  // const [formEdit, setFormEdit] = useState(false);
  // const [confirmation, setConfirmation] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState('');

  const groupName =
    group === 'admin'
      ? 'Administrador'
      : group === 'manager'
      ? 'Gerente'
      : group === 'operator'
      ? 'Operador'
      : 'Operador com Acionamento';

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <b>Perfil</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>Nome: </b> {name}
            </Typography>
            <Typography gutterBottom>
              <b>Email: </b>
              {email}
            </Typography>
            <Typography gutterBottom>
              <b>Grupo: </b> {groupName}
            </Typography>
          </DialogContent>
        </>
      </Dialog>
    </>
  );
}

export function IoseDowloadCSVModal({
  title,
  suffix,
  headerFileCSV,
  dataCSV,
  loading,
  order,
  orderBy,
}) {
  const [open, setOpen] = useState(false);
  const [preparedData, setPreparedData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    prepareData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const prepareData = () => {
    const stableSortArray = stableSort(dataCSV, getComparator(order, orderBy));

    const preparedDataLocal = stableSortArray.map((element) => {
      const formattedDate = formatDate(element.data);

      return { ...element, data: formattedDate };
    });

    setPreparedData(preparedDataLocal);
  };

  return (
    <>
      <IoseHistoricButtonDownload
        tooltip="Download Histórico de Medidas"
        onClick={handleClickOpen}
        right={'9px'}
        rightmobile={'22px'}
        loading={loading}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <b>{title}</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Será feito o download de um arquivo .csv contendo todas as medidas
              dos circuitos selecionados dentro do período escolhido. O arquivo
              pode ser aberto no Microsoft Excel ou em qualquer outro programa
              que trabalhe com planilhas.
            </Typography>
            <Typography gutterBottom>Deseja Continuar?</Typography>
          </DialogContent>
          <DialogActions>
            <CsvDownloader
              filename="Histórico de Medidas"
              separator=";"
              columns={headerFileCSV}
              datas={preparedData}
              bom={true}
              suffix={suffix}
            >
              <Button
                autoFocus
                onClick={(e) => {
                  handleClose();
                }}
              >
                Sim
              </Button>
            </CsvDownloader>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
}

export function IoseDowloadCSVModalEvents({
  title,
  suffix,
  headerFileCSV,
  dataCSV,
  loading,
  disabled,
  order,
  orderBy,
}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [preparedData, setPreparedData] = useState([]);

  const handleClickOpen = async () => {
    setMessage('');
    prepareData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMsg = () => {
    setMessage('Esse período não possui eventos!');
  };

  const prepareData = () => {
    const stableSortArray = stableSort(dataCSV, getComparator(order, orderBy));

    const preparedDataLocal = stableSortArray.map((element) => {
      const formattedDate = formatDate(element.created_at);

      return { ...element, created_at: formattedDate };
    });

    setPreparedData(preparedDataLocal);
  };

  const renderActions = () => {
    const noData = dataCSV.length === 0;

    const buttonDownload = (
      <CsvDownloader
        filename="Tabela_de_Eventos"
        separator=";"
        columns={headerFileCSV}
        datas={preparedData}
        bom={true}
        suffix={suffix}
      >
        <Button autoFocus onClick={handleClose}>
          Sim
        </Button>
      </CsvDownloader>
    );

    const button = (
      <Button autoFocus onClick={handleMsg}>
        Sim
      </Button>
    );

    return noData ? button : buttonDownload;
  };

  const renderPeriodString = () => {
    const string = suffix.split('_');
    return `${string[2]} até ${string[4]}`;
  };

  const renderMessage = () =>
    message !== '' && (
      <TypographyConfirmation gutterBottom>{message}</TypographyConfirmation>
    );

  const exportButton = (
    <IoseButtonStyled
      tooltip="Exportar Tabela de Eventos"
      onClick={handleClickOpen}
      disabled={disabled}
      right={'9px'}
      rightmobile={'22px'}
    >
      {loading ? (
        <CircularProgressStyled />
      ) : (
        <>
          <GetAppIcon /> <p>Exportar</p>
        </>
      )}
    </IoseButtonStyled>
  );

  return (
    <>
      {exportButton}
      <Dialog onClose={handleClose} open={open}>
        <>
          <DialogTitle onClose={handleClose}>
            <b>{title}</b>
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>
              Será feito o download de um <b>arquivo .csv </b>, contendo todos
              os <b> eventos</b> do circuito dentro
              <b> do período tarifário da unidade </b>ou
              <b> um período escolhido na seção de filtros</b>. O arquivo pode
              ser aberto no Microsoft Excel ou em qualquer outro editor de
              planilhas.
            </Typography>
            <Typography gutterBottom>
              <b>Período: </b>
              {renderPeriodString()}
            </Typography>
            <Typography gutterBottom>Deseja Continuar?</Typography>
            {renderMessage()}
          </DialogContent>
          <DialogActions>{renderActions()}</DialogActions>
        </>
      </Dialog>
    </>
  );
}

export function IoseJustificationActionModal({
  title,
  name,
  openModal,
  setOpenModalOut,
  handleTrigger,
}) {
  const [justification, setJustification] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setOpenModalOut(false);
  };

  const handleJustification = () => {
    setMessage('');
    setJustification('');

    if (justification.length === 0 || !justification.trim()) {
      setMessage('Justificativa obrigatória!');
    } else {
      handleTrigger(justification);
      handleClose();
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={openModal}>
        <DialogTitle onClose={handleClose}>
          <b>{title}</b>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Tem certeza que deseja acionar o circuito <b>{name}</b>?
          </Typography>
          <Typography gutterBottom>
            Insira a abaixo uma breve justificativa para o acionamento
            solicitado:
          </Typography>
          <IoseTextField
            value={justification}
            onChange={(event) => setJustification(event.target.value)}
            placeholder="Insira uma justificativa*"
          />
          {message !== '' && (
            <TypographyConfirmation gutterBottom>
              {message}
            </TypographyConfirmation>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleJustification}>
            Acionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
IoseDeleteInputModal.propTypes = {
  /** This props is title modal   */
  title: PropType.string,
  /** This props is name of element that will be delete*/
  name: PropType.string,
  /** This props is func to delete in container*/
  deletefunc: PropType.func,
  /** This props is params to insert in deletefunc */
  param: PropType.string,
  /** This props is indicate the button delete to be in a card or not ("true" or "false") */
  cardbutton: PropType.string,
  /** This props is indicate the button delete is primary or not ("true" or "false") */
  primarybutton: PropType.string,
  /** This props is indicate marg-left of button delete*/
  left: PropType.string,
};

IoseProfileModal.propTypes = {
  /** This props is the group user   */
  group: PropType.string,
  /** This props is the name user  */
  name: PropType.string,
  /** This props indicate to open or close modal */
  openModal: PropType.bool,
  /** This props is a function to close modal  */
  handleClose: PropType.func,
  /** This props is the email user  */
  email: PropType.string,
};

IoseDowloadCSVModal.propTypes = {
  /** This props is title modal */
  title: PropType.string,
  /** This props is suffix in the name file download */
  suffix: PropType.string,
  /** This props is header of table in CSV file */
  headerFileCSV: PropType.array,
  /** This props is a data to form CSV file in this case is dataTableHistoric */
  dataCSV: PropType.array,
};
