import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';
import { useAlert } from 'react-alert';
import IoseCard from 'components/Cards/IoseCard';
import IoseSolarEcuAddModal from 'components/Modals/IoseSolarEcuAddModal';
import IoseSolarEcuEditModal from 'components/Modals/IoseSolarEcuEditModal';
import IoseSolarEcuDeleteModal from 'components/Modals/IoseSolarEcuDeleteModal';
import { ContainerCards, SunnyIcon, WrappedContainer } from './style';
import { useSolarEcus, useCreateLogs, useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseSearch,
  IoseSubHeaderBar,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
} from 'common';

export default function SolarEcuContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [cards, setCards] = useState([]);
  const [deleteNotificationType, setDeleteNotificationType] = useState(0);
  const [deleteNotification, setDeleteNotification] = useState('');
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [editNotification, setEditNotification] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();
  const [hasDeletePermission, setHasDeletePermission] = useState();

  const alert = useAlert();

  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const { solarEcus, noEcuError, refreshSolarEcus } = useSolarEcus();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'ecu_solar', 'leitura'];
    const addPath = ['cadastro', 'ecu_solar', 'criar'];
    const editPath = ['cadastro', 'ecu_solar', 'editar'];
    const deletePath = ['cadastro', 'ecu_solar', 'deletar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    solarEcus?.length > 0 ? setCards(solarEcus) : setError(noEcuError);
  }, [solarEcus, noEcuError]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  function errorHandlingAddEcu(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  function errorHandlingEditEcu(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  function errorHandlingDeleteConcessionaires(error) {
    alert.show(error ? error : `${Messages.errorDeleteConcessionaires}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  /// ========== OPERATIONS ===========

  async function addSolarEcu(ecu_number) {
    setNotificationType(0);
    setLoading(true);

    let sessionData;

    try {
      sessionData = await getSession();
      const response = await ioseAPI.createSolarEcu(
        sessionData.headers,
        ecu_number
      );

      setAddNotification(response.data.message);
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR ECU';
      const logContent = {
        ecu_number: ecu_number,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.ecusNoRegistered;
      errorHandlingAddEcu(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        refreshSolarEcus();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function editConcessionaire(uuid_ecu, ecu_number) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateSolarEcu(
        sessionData.headers,
        uuid_ecu,
        ecu_number
      );

      setEditNotification(response.data.message);
      setEditNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR ECU';
      const logContent = {
        uuid_ecu: uuid_ecu,
        ecu_number: ecu_number,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.errorUpdateEcus;

      errorHandlingEditEcu(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseEditModal();
        refreshSolarEcus();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function deleteConcessionaire(uuid_ecu) {
    setDeleteNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteSolarEcu(
        sessionData.headers,
        uuid_ecu
      );

      setDeleteNotification(response.data.message);
      setDeleteNotificationType(1);

      const operacao = 'DELETAR';
      const form = 'DELETAR ECU';
      const logContent = {
        uuid_ecu: uuid_ecu,
      };

      await createLog(operacao, form, logContent);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.errorDeleteEcus;

      errorHandlingDeleteConcessionaires(errorMessage);
      setDeleteNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);

      setTimeout(() => {
        handleCloseDeleteModal();
        refreshSolarEcus();
        setDeleteNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  /// ========== OPERATIONS ===========
  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName?.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item?.ecu_number?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindEcus);
    } else {
      setSearch(false);
    }
  };
  const renderCards = (card) => {
    return (
      <IoseCard
        key={card.uuid_ecu}
        name={card.ecu_number}
        description={card.city}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR ECU'}
        cardEditTitle={'EDITAR ECU'}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        cardIcon={<SunnyIcon />}
        showDeleteButton={hasDeletePermission}
        showEditButton={hasEditPermission}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR CONCESSIONÁRIA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="ECU's"
        description={'Total: ' + cards.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar ECU's…"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards.length}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseSolarEcuAddModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={addSolarEcu}
          notificationType={notificationType}
          notification={notification}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
        />
        <IoseSolarEcuDeleteModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          submit={deleteConcessionaire}
          notificationType={deleteNotificationType}
          notification={notification}
          showNotification={showNotification}
          deleteNotification={deleteNotification}
          loading={loading}
          selectedCard={selectedCard}
        />
        <IoseSolarEcuEditModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          submit={editConcessionaire}
          notificationType={editNotificationType}
          notification={notification}
          editNotification={editNotification}
          showNotification={showNotification}
          loading={loading}
          selectedCard={selectedCard}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

SolarEcuContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
