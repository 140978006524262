import styled from 'styled-components';

//Imports from IOSE Components
import IoseSideCard from 'components/IoseSideCard';
import IoseTextFieldSidebar from 'components/IoseTextFieldSidebar';

//Import from formik
import { Form } from 'formik';

//Imports from Material UI components
import {
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

import Divider from '@material-ui/core/Divider';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  color: var(--base);

  div {
    font-weight: 600;
  }
`;

export const FormArea = styled.div`
  color: var(--base);
  padding: 3% 10% 0% 10%;

  p {
    text-align: left;
    font-size: 12px;
  }
`;

export const YellowCheck = styled(Checkbox)`
  color: var(--primary);

  &.MuiCheckbox-root {
    color: var(--primary);
  }
`;

export const YellowRadio = styled(Radio)`
  color: var(--primary);
  width: 25px;
  height: 25px;

  &.MuiRadio-root {
    color: var(--primary);
  }
`;

export const PermissionArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: var(--base);
    text-align: left;
    width: 100%;
    margin-top: 24px;
  }
`;

export const IoseTextFieldSidebarStyled = styled(IoseTextFieldSidebar).attrs(
  (props) => ({
    required: true,
  })
)``;

export const DividerStyled = styled(Divider)`
  width: 100%;
  height: 3px;
  background-color: var(--primary);
  margin-top: 12px;
  opacity: 70%;
  border-radius: 10px;
`;

export const ContainerInputsTC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  div {
    margin-right: 16px;
  }
`;

export const RadioGroupStyled = styled(RadioGroup)`
  width: 100%;
  padding: 8px;
  /* border: solid 1px red; */
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-top: 5px;
  margin-right: 20px;
  .MuiTypography-body1 {
    font-family: 'Montserrat', sans-serif;
    color: var(--base);
    font-size: 12px;
  }
`;

export const DivInputsCircuits = styled.div`
  margin-bottom: 32px;
  width: 100%;
`;

export const IoseTextFieldSidebarStyled2 = styled(IoseTextFieldSidebar).attrs(
  (props) => ({
    required: false,
  })
)``;
