import React from 'react';
import PropType from 'prop-types';

//Import from Material UI components
import { IconButton } from '@material-ui/core';

//Import from Material UI lab
import { AlertTitle } from '@material-ui/lab';

//Import from Material style
import {
  Header,
  AlertStyled,
  CollapseStyled,
  Container,
  IllustrationAlert,
  CollapseStyledModal,
} from './style';

//Import from Material Icon
import CloseIcon from '@material-ui/icons/Close';

/**
 * This component has all the alerts used in the system to notify user.
 * @visibleName IoseAlert
 */
export function IoseAlertErro(props) {
  //State to open or close the alert
  const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyled in={open}>
      <AlertStyled severity="error">
        <Header>
          <AlertTitle>Error</AlertTitle>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Header>
        {text}
      </AlertStyled>
    </CollapseStyled>
  );
}

export function IoseAlertErroModal(props) {
  //State to open or close the alert
  // const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyled in={() => {}}>
      <AlertStyled severity="error">{text}</AlertStyled>
    </CollapseStyled>
  );
}

export function IoseErroMessage(props) {
  //State to open or close the alert
  // const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
      <AlertStyled severity="error">{text}</AlertStyled>
  );
}

export function IoseAlertWarning(props) {
  //State to open or close the alert
  const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyled in={open}>
      <AlertStyled severity="warning">
        <Header>
          <AlertTitle>Aviso</AlertTitle>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Header>

        {text}
      </AlertStyled>
    </CollapseStyled>
  );
}

export function IoseAlertInfo(props) {
  //State to open or close the alert
  const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyled in={open}>
      <AlertStyled severity="info">
        <Header>
          <AlertTitle>Informação</AlertTitle>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Header>
        {text}
      </AlertStyled>
    </CollapseStyled>
  );
}

export function IoseAlertSuccess(props) {
  //State to open or close the alert
  const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyled in={open}>
      <AlertStyled severity="success">
        <Header>
          <AlertTitle>Sucesso</AlertTitle>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Header>
        {text}
      </AlertStyled>
    </CollapseStyled>
  );
}
export function IoseAlertSuccessModal(props) {
  //State to open or close the alert
  // const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
    <CollapseStyledModal in={() => {}}>
      <AlertStyled severity="success">{text}</AlertStyled>
    </CollapseStyledModal>
  );
}

export function IoseSuccessMessage(props) {
  //State to open or close the alert
  // const [open, setOpen] = React.useState(true);
  const text = props.text;

  return (
      <AlertStyled severity="success">{text}</AlertStyled>
  );
}




export function IoseAlertLoadingCards({ text, bottom }) {
  return (
    <Container bottom={bottom}>
      <IllustrationAlert />
      {text || text !== '' ? (
        <p>{text}</p>
      ) : (
        <>
          <p>
            Infelizmente, parece que não conseguimos obter comunicação com o
            servidor.
          </p>
          <p>Recarregue a página</p>
        </>
      )}
    </Container>
  );
}

export function IoseAlertNoExtract({ text, bottom }) {
  return (
    <Container bottom={bottom}>
      <IllustrationAlert />
      {text || text !== '' ? (
        <p style={{color: '#015A9E'}}>{text}</p>
      ) : (
        <>
          <p>
            Infelizmente, parece que não conseguimos obter comunicação com o
            servidor.
          </p>
          <p>Recarregue a página</p>
        </>
      )}
    </Container>
  );
}

const generalProps = {
  /** This prop is a text inside the Alert*/
  text: PropType.string,
};

IoseAlertErro.propTypes = generalProps;
IoseAlertWarning.propTypes = generalProps;
IoseAlertInfo.propTypes = generalProps;
IoseAlertSuccess.propTypes = generalProps;
