import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    height: 'calc(40px + 4px)',
    width: '100%',
    fontSize: '13px',
    opacity: state.isFocused || state.menuIsOpen ? 1 : 0.5,
    borderBottom: `1px solid ${state.isFocused ? '#212121' : '#212121'}`, // A
    borderRadius: 0,
    '&:hover': {
      borderBottom: `2px solid ${state.isFocused ? '#212121' : '#212121'}`,
      opacity: 1,
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isFocused ? '#212121' : '#212121',
    '&:hover': {
      backgroundcolor: '#fff',
      color: '#212121',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#212121',
  }),
};

export default function IoseFilterConsumerGroup({
  value,
  onChange,
  label,
  options,
  width,
}) {
  const selectedOption = options?.find((option) => option?.value === value);

  const selectValue = selectedOption ? selectedOption : null;

  return (
    <div style={{ width: '170px' }}>
      <Select
        value={selectValue}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        options={options}
        isSearchable
        placeholder={label}
        styles={customStyles}
      />
    </div>
  );
}
