import {
  IoseConsumerGroupRedirectButton,
  IoseDownloadButton,
} from 'components/IoseButtonIcon';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';

import { useConsumerGroupData } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const classify = (classification) => {
  if (classification.includes('A4') || classification.includes('A3a')) {
    return 'A';
  } else if (classification.includes('B3')) {
    return 'B';
  }
  return '';
};

const handleDownloadEnergyBill = (currentEnergyBill) => {
  const energyBill = currentEnergyBill[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (energyBill) {
    const concessionaire = energyBill?.concessionaire.toLowerCase();
    const referenceMonth = energyBill?.reference_month.replace('/', '');
    const classification = classify(energyBill?.classification);
    const pdfNumber = energyBill?.pdf_name;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}${classification}/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

export default function BillsModalBillSubHeader({ currentEnergyBill }) {
  const history = useHistory();
  const energyBill = currentEnergyBill[0];
  const { consumerGroup } = useConsumerGroupData(
    energyBill?.identification_number
  );

  function redirectToUcDash() {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: energyBill?.identification_number,
        uuidCircuit: consumerGroup[0]?.numero_telemetria,
      },
    });
  }

  return (
    <>
      <IoseSubHeaderBar
        subtitle={`Detalhamento da fatura de energia da UC ${energyBill?.identification_number}`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          {energyBill?.identification_number && consumerGroup ? (
            <IoseConsumerGroupRedirectButton
              tooltip="IR PARA O DASHBOARD DA UC"
              bottom="5px"
              onClick={() => redirectToUcDash()}
            />
          ) : null}

          <IoseDownloadButton
            tooltip="BAIXAR FATURA"
            bottom="5px"
            onClick={() => handleDownloadEnergyBill(currentEnergyBill)}
          />
        </div>
      </IoseSubHeaderBar>
    </>
  );
}
