import styled from 'styled-components';
import { Button, Table, TableCell, TextField } from '@material-ui/core';

export const StyledTable = styled(Table)({
  width: '100%',
});

export const StyledTh = styled(TableCell)({
  backgroundColor: 'var(--primary)',
  padding: '10px',
  textAlign: 'center',
  color: 'var(--secondary)',
});

export const StyledTd = styled(TableCell)({
  padding: '10px',
  border: '1px solid #ddd',
  textAlign: 'center',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#ddd',
    cursor: 'pointer',
  },
});

export const StyledInput = styled(TextField)({
  width: '80%',
  marginRight: '10px',
});

export const StyledButton = styled(Button)({
  cursor: 'pointer',
});

export const CheckButton = styled(Button)({
  cursor: 'pointer',
  backgroundColor: '#00a65a',
  color: 'white',
  padding: '7px',
  borderRadius: '5px',
  width: '5px',
  height: '5px',
});

export const RemoveButton = styled(Button)({
  cursor: 'pointer',
  backgroundColor: '#d60606',
  color: 'white',
  padding: '7px',
  borderRadius: '5px',
  width: '5px',
  height: '5px',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
  justifyContent: 'flex-end',
});

export const InputContainer = styled('div')({
  display: 'flex',
  // justifyContent: 'space-around',
});

export const InputField = styled(TextField)({
  width: '200%',
});
