import React from 'react';
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';

import { Account } from 'common/contexts/Accounts';
import SessionProvider from 'common/contexts/session';
import ClientProvider from 'common/contexts/client';

import GlobalPage from 'pages/GlobalPage';
import ForgotPassword from 'pages/ForgotPassword';
import Login from 'pages/Login';
import Page404 from 'pages/Page404';
import NoConnectionPage from 'pages/NoConnectionPage';
// import MaintenancePage from 'pages/MaintenancePage';

//Components for RoutesMainContainer
import SuperUserContainer from 'containers/SuperUserContainer';
import UnityContainer from 'containers/UnityContainer';
import DashboardContainer from 'containers/DashboardContainer';
import SchedulerContainer from 'containers/SchedulerContainer';
import EventsContainer from 'containers/EventsContainer';

//components for RoutesSidebarContainer
import SidebarAdminContainer from 'containers/SidebarAdminContainer';
import SidebarClientContainer from 'containers/SidebarClientContainer';
import SidebarUnityContainer from 'containers/SidebarUnityContainer';
import SidebarDashboardContainer from 'containers/SidebarDashboardContainer';

//components for RoutesAdminSide
import IoseFormAdminAddCard from 'components/IoseFormAdminAddCard';
import IoseFormAdminEditCard from 'components/IoseFormAdminEditCard';
import IoseShowAdminsCard from 'components/IoseShowAdminsCard';

//components for RoutesClientSide
import IoseFormClientAddCard from 'components/IoseFormClientAddCard';
import IoseFormClientAditCard from 'components/IoseFormClientEditCard';

// components for RoutesUnitySide
import SidebarSwitchboardContainer from 'containers/SidebarSwitchboardContainer';
import SidebarScheduleContainer from 'containers/SidebarScheduleContainer';
import SidebarCircuitContainer from 'containers/SidebarCircuitContainer';
import SidebarEmployeeContainer from 'containers/SidebarEmployeeContainer';
import SidebarUnityOperationsContainer from 'containers/SidebarUnityOperationsContainer';

// components for RoutesUnity
import IoseFormUnityAddCard from 'components/IoseFormUnityAddCard';
import IoseFormUnityEditCard from 'components/IoseFormUnityEditCard';

//components for RoutesEmployee
import IoseEmployeeShowCard from 'components/IoseEmployeeShowCard';
import IoseFormEmployeeCreateCard from 'components/IoseFormEmployeeCreateCard';
import IoseFormEmployeeEditCard from 'components/IoseFormEmployeeEditCard';

// components for RouterSchedule
import IoseFormScheduleCreateCard from 'components/IoseFormScheduleCreateCard';
import IoseFormScheduleEditCard from 'components/IoseFormScheduleEditCard';
import IoseScheduleShowProcess from 'components/IoseScheduleShowProcess';

//components for RoutesSwitchboard
import IoseSwitchboardShowCardList from 'components/IoseSwitchboardShowCardList';
import IoseFormSwitchboardCreateCard from 'components/IoseFormSwitchboardCreateCard';
import IoseFormSwitchboardEditCard from 'components/IoseFormSwitchboardEditCard';
import IoseSwitchboardIntegrateCard from 'components/IoseSwitchboardIntegrateCard';

//componets for RoutesCircuits
import IoseCircuitShowCard from 'components/IoseCircuitShowCard';
import IoseFormCircuitEditCard from 'components/IoseFormCircuitEditCard';
import AdminDashboad from 'pages/AdminDashboard';
// import { Admin } from 'iose-api-lib';

//components for Admin Dashboard
import AdminDashboardContainer from 'containers/AdminDashboardContainer';
import SwitchboardDashboardContainer from 'containers/SwitchboardDashContainer';
import SwitchboardDashboard from 'pages/SwitchboardDashboard';

//components for energy bill page
import EnergyBillsPage from 'pages/EnergyBill';
import EnergyBillsContainer from 'containers/EnergyBillsContainer';
import EnvironmentPage from 'pages/EnvironmentPage';
import EnvironmentContainer from 'containers/EnvironmentContainer';
import SidebarEnvironmentContainer from 'containers/SidebarEnvironmentContainer';
import ObjectContainer from 'containers/ObjectContainer';
import ObjectPage from 'pages/ObjectPage';
import CircuitPage from 'pages/CircuitPage';
import CircuitContainer from 'containers/CircuitContainer';
import ConsumptionComparisionContainer from 'containers/ConsumptionComparisionContainer';
import ConsumptionComparisionPage from 'pages/ConsumptionComparision';
import MapPage from 'pages/MapPage';
import MapContainer from 'containers/MapContainer';
import ConcessionairePage from 'pages/ConcessionairePage';
import ConcessionairesContainer from 'containers/ConcessionairesContainer';
import MapConfigurationPage from 'pages/MapConfigurationPage';
import MapConfigurationContainer from 'containers/MapConfigurationContainer';
import ConsumerGroupContainer from 'containers/ConsumerGroupContainer';
import ConsumerGroupPage from 'pages/ConsumerGroupPage';
import ContractPage from 'pages/ContractPage';
import ContractContainer from 'containers/ConctractContainer';
import ContractListPage from 'pages/ContractListPage';
import ContractListContainer from 'containers/ConctractListContainer';
import ConsumerGroupDashPage from 'pages/ConsumerGroupDashPage';
import ConsumerGroupDashContainer from 'containers/ConsumerGroupDashContainer';
import EnergyBillsAuditPage from 'pages/EnergyBillsAuditPage';
import EnergyBillsAuditContainer from 'containers/EnergyBillsAuditContainer';
import TariffComponentsPage from 'pages/TariffComponentsPage';
import TariffComponentsContainer from 'containers/TariffComponentsContainer';
import MeasurementReportPage from 'pages/MeasurementReportPage';
import MeasurementeReportContainer from 'containers/MeasurementeReportContainer';
import CreditPage from 'pages/CreditPage';
import CreditContainer from 'containers/CreditContainer';
import CreditBeneficiarioContainer from 'containers/CreditBeneficiarioContainer';
import CreditBeneficiarioPage from 'pages/CreditBeneficiarioPage';
import SGFExportPage from 'pages/SGFExportPage';
import SGFExportContainer from 'containers/SGFExportContainer';
import BIENEExportPage from 'pages/BIENEExportPage';
import BIENEExportContainer from 'containers/BIENEExportContainer';
import GeradorasXBeneficiariasPage from 'pages/GeradorasXBeneficiariasPage';
import GeradorasXBeneficiariasContainer from 'containers/GeradorasXBeneficiariasContainer';
import SystemVariablesPage from 'pages/SystemVariablesPage';
import SystemVariablesContainer from 'containers/SystemVariablesContainer';
import SaneparCreditPage from 'pages/SaneparCreditPage';
import SaneparCreditBeneficiaryPage from 'pages/SaneparCreditBeneficiaryPage';
import SaneparCreditContainer from 'containers/SaneparCreditContainer';
import SaneparCreditBeneficiaryContainer from 'containers/SaneparCreditBeneficiaryContainer';
import SaneparReportContainer from 'containers/SaneparReportContainer';
import SaneparReportPage from 'pages/SaneparReportPage';
import EditBillsMeasurerPage from 'pages/EditBillsMeasurerPage';
import EditBillsMeasurerContainer from 'containers/EditBillsMeasurerContainer';
import SolarConfigurationContainer from 'containers/SolarConfigurationContainer';
import SolarConfigurationPage from 'pages/SolarConfigurationPage';
import SolarEcuPage from 'pages/SolarEcuPage';
import SolarEcuContainer from 'containers/SolarEcuContainer';
import CreditDashboardPage from 'pages/CreditDashboardPage';
import CreditDashboard from 'containers/CreditDashboard';
import DashboardGridPage from 'pages/DashboardGridPage';
import DashboardGridContainer from 'containers/DashboardGridContainer';
import EnergyBillLogsPage from 'pages/EnergyBillLogsPage';
import EnergyBillLogsContainer from 'containers/EnergyBillLogsContainer';
import SearchEnergyBillPage from 'pages/SearchEnergyBillPage';
import SearchEnergyBillContainer from 'containers/SearchEnergyBillContainer';
import Bim3dPage from 'pages/Viewer3dPage';
import Bim3dContainer from 'containers/Bim3dContainer';
import Viewer3dPage from 'pages/Viewer3dPage';
import Viewer3dContainer from 'containers/Viewer3dContainer';
import FaturaPage from 'pages/TariffsPage';
import TariffsContainer from 'containers/TariffsContainer';
import AuthPage from 'pages/AuthPage';
import EnergyBillsRecalcPage from 'pages/EnergyBillsRecalcPage';
import RecalcEnergyBillsContainer from 'containers/RecalcEnergyBillsContainer';
import UserLogsPage from 'pages/UserLogsPage';
import UserLogsContainer from 'containers/UserLogsContainer';
import UserGroupsPage from 'pages/UserGroupsPage';
import UserGroupsContainer from 'containers/UserGroupsContainer';
import PermissionsManagementContainer from 'containers/PermissionsManagementContainer';
import PermissionsManagementPage from 'pages/PermissionsManagementPage';
import UsersPage from 'pages/UsersPage';
import UsersContainer from 'containers/UsersContainer';
import TariffsCarbonPage from 'pages/TariffsCarbonPage';
import CarbonReportPage from 'pages/CarbonReportPage';
import TariffsCarbonContainer from 'containers/TariffsCarbonContainer';
import CarbonDashContainer from 'containers/CarbonDashContainer';
import CarbonMonthlyPage from 'pages/CarbonMonthlyPage';
import CarbonMonthlyContainer from 'containers/CarbonMonthlyContainer';
import SimulatorPage from 'pages/SimulatorPage';
import SimulatorContainer from 'containers/SimulatorContainer';
import SearchItemBillContainer from 'containers/SearchItemBillContainer';
import ItemBillReportPage from 'pages/ItemBillReportPage';
import TelemetryConsumptionComparisionPage from 'pages/TelemetryConsumptionComparision';
import TelemetryConsumptionComparisonContainer from 'containers/TelemetryConsumptionComparisionContainer';
import MapTelemtryPage from 'pages/MapTelemtryPage';
import MapTelemetryContainer from 'containers/MapTelemetryContainer';
import TelemetryDashPage from 'pages/TelemetryDashPage';
import TelemetryDashContainer from 'containers/TelemetryDashContainer';
import TarifaReportPage from 'pages/TarifaReportPage';
import TarifaReportContainer from 'containers/FaturaReportContainer';
import UsuariosXAlarmesPage from 'pages/UsuariosXAlarmesPage';
import UsuariosXAlarmesContainer from 'containers/UsuariosXAlarmesContainer';
import GemeoDigitalPage from 'pages/GemeoDigitalPage';
import GemeoDigitalContainer from 'containers/GemeoDigitalContainer';
import DesperdiciosReportPage from 'pages/DesperdiciosReportPage';
import DesperdiciosReportContainer from 'containers/DesperdiciosReportContainer';

import TelemetriaReportPage from 'pages/TelemetriaReportPage';
import TelemetriaReportContainer from 'containers/TelemetriaReportContainer';
import SemConsumoPage from 'pages/SemConsumoPage';
import ConsumoIosePage from 'pages/ConsumoIosePage';
import ConsumoIoseContainer from 'containers/ConsumoIoseContainer';
import ContabilMercadoLivreReportPage from 'pages/ContabilMercadoLivreReportPage';
import ContabilMercadoLivreReportContainer from 'containers/ContabilMercadoLivreReportContainer';
import ComercializadorasPage from 'pages/ComercializadorasPage';
import ComercializadorasContainer from 'containers/ComercializadorasContainer';

import MercadoLivreDashPage from 'pages/MercadoLivreDashPage';
import MercadoLivreDashContainer from 'containers/MercadoLivreDashContainer';
import SummaryDashByYearPage from 'pages/SummaryDashByYearPage';
import SummaryDashByYearContainer from 'containers/SummaryDashByYearContainer';
import MenuPermissionsManagementPage from 'pages/MenuPermissionsManagementPage';
import MenuPermissionsManagementContainer from 'containers/MenuPermissionsManagementContainer';
import ConsumerGroupAnalysisDashPage from 'pages/ConsumerGroupAnalysisDashPage';
import ConsumerGroupAnalysisDashContainer from 'containers/ConsumerGroupAnalysisDashContainer';

//TODO: Lembrar de fazer uma documentação falando
// que esse basename aqui foi colocado para o deploy
// do dev no gitlab. Para a amazon talvez não precise
// e tem que modificar posteriormente.
/**
 * Routes for pages
 * */
export function RoutesPages() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Account>
        <SessionProvider>
          <ClientProvider>
            <Switch>
              <Route path="/" exact component={Login} />

              <Route path="/auth" exact component={AuthPage} />
              {/* <Route path="/" exact component={MaintenancePage} /> */}
              <Route path="/admin-dashboard" component={AdminDashboad} />

              <Route path="/dashboard-grid" component={DashboardGridPage} />

              <Route path="/energy-bills" component={EnergyBillsPage} />

              <Route
                path="/mercado-livre-dashboard"
                component={MercadoLivreDashPage}
              />

              <Route path="/user-logs" component={UserLogsPage} />

              <Route path="/user-groups" component={UserGroupsPage} />

              <Route path="/users" component={UsersPage} />

              <Route path="/carbon-tariffs" component={TariffsCarbonPage} />

              <Route path="/carbon-report" component={CarbonReportPage} />

              <Route path="/carbon-monthly" component={CarbonMonthlyPage} />

              <Route path="/simulator" component={SimulatorPage} />

              <Route path="/users-alarms" component={UsuariosXAlarmesPage} />

              <Route
                path="/search-item-bill-report"
                component={ItemBillReportPage}
              />

              <Route
                path="/permissions-management"
                component={PermissionsManagementPage}
              />

              <Route
                path="/energy-bills-audit"
                component={EnergyBillsAuditPage}
              />

              <Route
                path="/edit-bills-measurer"
                component={EditBillsMeasurerPage}
              />

              <Route
                path="/recalc-energy-bill"
                component={EnergyBillsRecalcPage}
              />

              <Route path="/concessionaires" component={ConcessionairePage} />

              <Route path="/solar-ecu" component={SolarEcuPage} />

              <Route path="/consumer-group" component={ConsumerGroupPage} />

              <Route path="/bim-unitys" component={Bim3dPage} />

              <Route path="/3d-viewer" component={Viewer3dPage} />

              <Route path="/gemeo-digital" component={GemeoDigitalPage} />

              <Route
                path="/consumer-group-dashboard"
                component={ConsumerGroupDashPage}
              />

              <Route path="/contracts" component={ContractPage} />

              <Route path="/contracts-list" component={ContractListPage} />

              <Route path="/energy-bill-logs" component={EnergyBillLogsPage} />

              <Route
                path="/search-energy-bill"
                component={SearchEnergyBillPage}
              />

              <Route path="/tariffs" component={FaturaPage} />

              <Route
                path="/tariff-components"
                component={TariffComponentsPage}
              />
              <Route path="/credit" component={CreditPage} />

              <Route path="/credit-dashboard" component={CreditDashboardPage} />

              <Route
                path="/credit-beneficiario"
                component={CreditBeneficiarioPage}
              />

              <Route path="/sanepar-report" component={SaneparReportPage} />

              <Route
                path="/geradorasxbeneficiarias"
                component={GeradorasXBeneficiariasPage}
              />

              <Route path="/saneparcredit" component={SaneparCreditPage} />
              <Route
                path="/saneparcreditbeneficiarias"
                component={SaneparCreditBeneficiaryPage}
              />

              <Route
                path="/measurement-report"
                component={MeasurementReportPage}
              />
              <Route path="/fatura-report" component={TarifaReportPage} />

              <Route path="/system-variables" component={SystemVariablesPage} />

              <Route path="/export-sgf" component={SGFExportPage} />
              <Route path="/export-biene" component={BIENEExportPage} />

              <Route
                path="/map-configuration"
                component={MapConfigurationPage}
              />
              <Route
                path="/solar-configuration"
                component={SolarConfigurationPage}
              />
              <Route
                path="/consumption-comparison"
                component={ConsumptionComparisionPage}
              />
              <Route path="/environments" component={EnvironmentPage} />
              <Route path="/circuits" component={CircuitPage} />
              <Route path="/objects" component={ObjectPage} />
              <Route
                path="/switchboard-dashboard"
                component={SwitchboardDashboard}
              />
              <Route path="/forgot" component={ForgotPassword} />
              <Route path="/global" component={GlobalPage} />
              <Route path="/map" component={MapPage} />
              <Route
                path="/telemetry-consumption-comparison"
                component={TelemetryConsumptionComparisionPage}
              />
              <Route path="/telemetry-dash" component={TelemetryDashPage} />

              <Route path="/map-telemetry" component={MapTelemtryPage} />

              <Route
                path="/telemetry-report"
                component={TelemetriaReportPage}
              />

              <Route path="/sem-consumo-report" component={SemConsumoPage} />

              <Route
                path="/desperdicios-report"
                component={DesperdiciosReportPage}
              />

              <Route
                path="/analysis-dash"
                component={ConsumerGroupAnalysisDashPage}
              />

              <Route
                path="/contabil-mercado-livre-report"
                component={ContabilMercadoLivreReportPage}
              />

              {/* <Route path="/consumo-iose-report" component={ConsumoIosePage}/> */}

              <Route
                path="/comercializadoras"
                component={ComercializadorasPage}
              />

              <Route
                path="/summary-dash-year"
                component={SummaryDashByYearPage}
              />

              <Route path="/consumo-iose-report" component={ConsumoIosePage} />

              <Route
                path="/menu-permissions"
                component={MenuPermissionsManagementPage}
              />

              <Route component={Page404} />
            </Switch>
          </ClientProvider>
        </SessionProvider>
      </Account>
    </BrowserRouter>
  );
}

/**
 *
 *
 */
export function RoutesMainContainer({
  openSide,
  group,
  username,
  getcards,
  setGetcards,
  empytArray,
  setEmpytArray,
  backAction,
}) {
  return (
    <Switch>
      <Route path="/admin-dashboard">
        <AdminDashboardContainer />
      </Route>

      <Route path="/dashboard-grid">
        <DashboardGridContainer />
      </Route>

      <Route path="/user-logs">
        <UserLogsContainer />
      </Route>

      <Route path="/user-groups">
        <UserGroupsContainer />
      </Route>

      <Route path="/users">
        <UsersContainer />
      </Route>

      <Route path="/permissions-management">
        <PermissionsManagementContainer />
      </Route>

      <Route path="/map">
        <MapContainer />
      </Route>

      <Route path="/users-alarms">
        <UsuariosXAlarmesContainer />
      </Route>

      <Route path="/search-item-bill-report">
        <SearchItemBillContainer />
      </Route>

      <Route path="/simulator">
        <SimulatorContainer />
      </Route>

      <Route path="/map-configuration">
        <MapConfigurationContainer />
      </Route>

      <Route path="/solar-configuration">
        <SolarConfigurationContainer />
      </Route>

      <Route path="/switchboard-dashboard">
        <SwitchboardDashboardContainer />
      </Route>

      <Route path="/contracts">
        <ContractContainer />
      </Route>

      <Route path="/carbon-tariffs">
        <TariffsCarbonContainer />
      </Route>

      <Route path="/carbon-report">
        <CarbonDashContainer />
      </Route>

      <Route path="/recalc-energy-bill">
        <RecalcEnergyBillsContainer />
      </Route>

      <Route path="/contracts-list">
        <ContractListContainer />
      </Route>

      <Route path="/bim-unitys">
        <Bim3dContainer />
      </Route>

      <Route path="/3d-viewer">
        <Viewer3dContainer />
      </Route>

      <Route path="/energy-bill-logs">
        <EnergyBillLogsContainer />
      </Route>

      <Route path="/search-energy-bill">
        <SearchEnergyBillContainer />
      </Route>

      <Route path="/tariffs">
        <TariffsContainer />
      </Route>

      <Route path="/tariff-components">
        <TariffComponentsContainer />
      </Route>

      <Route path="/credit">
        <CreditContainer />
      </Route>

      <Route path="/credit-dashboard">
        <CreditDashboard />
      </Route>

      <Route path="/credit-beneficiario">
        <CreditBeneficiarioContainer />
      </Route>

      <Route path="/sanepar-report">
        <SaneparReportContainer />
      </Route>

      <Route path="/geradorasxbeneficiarias">
        <GeradorasXBeneficiariasContainer />
      </Route>

      <Route path="/saneparcredit">
        <SaneparCreditContainer />
      </Route>

      <Route path="/saneparcreditbeneficiarias">
        <SaneparCreditBeneficiaryContainer />
      </Route>

      <Route path="/system-variables">
        <SystemVariablesContainer />
      </Route>

      <Route path="/measurement-report">
        <MeasurementeReportContainer />
      </Route>
      <Route path="/fatura-report">
        <TarifaReportContainer />
      </Route>

      <Route path="/edit-bills-measurer">
        <EditBillsMeasurerContainer />
      </Route>

      <Route path="/export-sgf">
        <SGFExportContainer />
      </Route>

      <Route path="/export-biene">
        <BIENEExportContainer />
      </Route>

      <Route path="/carbon-monthly">
        <CarbonMonthlyContainer />
      </Route>

      <Route path="/desperdicios-report">
        <DesperdiciosReportContainer />
      </Route>

      {group === 'super' && (
        <Route path="/global/super">
          <SuperUserContainer
            openSide={openSide}
            getcards={getcards}
            setGetcards={setGetcards}
          />
        </Route>
      )}

      <Route path="/global/client">
        <UnityContainer
          getcards={getcards}
          setGetcards={setGetcards}
          openSide={openSide}
          empytArray={empytArray}
          setEmpytArray={setEmpytArray}
          group={group}
          username={username}
        />
      </Route>

      <Route path="/energy-bills">
        <EnergyBillsContainer />
      </Route>

      <Route path="/mercado-livre-dashboard">
        <MercadoLivreDashContainer />
      </Route>

      <Route path="/energy-bills-audit">
        <EnergyBillsAuditContainer />
      </Route>

      <Route path="/concessionaires">
        <ConcessionairesContainer />
      </Route>

      <Route path="/solar-ecu">
        <SolarEcuContainer />
      </Route>

      <Route path="/consumer-group">
        <ConsumerGroupContainer />
      </Route>

      <Route path="/consumer-group-dashboard">
        <ConsumerGroupDashContainer />
      </Route>

      <Route path="/consumption-comparison">
        <ConsumptionComparisionContainer />
      </Route>

      <Route path="/environments">
        <EnvironmentContainer openSide={openSide} />
      </Route>

      <Route path="/gemeo-digital">
        <GemeoDigitalContainer openSide={openSide} />
      </Route>

      <Route path="/objects">
        <ObjectContainer openSide={openSide} />
      </Route>

      <Route path="/circuits">
        <CircuitContainer openSide={openSide} />
      </Route>

      <Route path="/telemetry-consumption-comparison">
        <TelemetryConsumptionComparisonContainer />
      </Route>

      <Route path="/global/events">
        <EventsContainer />
      </Route>

      <Route path="/telemetry-dash">
        <TelemetryDashContainer />
      </Route>

      <Route path="/map-telemetry">
        <MapTelemetryContainer />
      </Route>

      <Route path="/telemetry-report">
        <TelemetriaReportContainer />
      </Route>

      <Route path="/contabil-mercado-livre-report">
        <ContabilMercadoLivreReportContainer />
      </Route>

      <Route path="/consumo-iose-report">
        <ConsumoIoseContainer />
      </Route>

      <Route path="/comercializadoras">
        <ComercializadorasContainer />
      </Route>

      <Route path="/summary-dash-year">
        <SummaryDashByYearContainer />
      </Route>

      <Route path="/menu-permissions">
        <MenuPermissionsManagementContainer />
      </Route>

      <Route path="/analysis-dash">
        <ConsumerGroupAnalysisDashContainer />
      </Route>

      <Route path="/global/schedule">
        <SchedulerContainer
          group={group}
          openSide={openSide}
          getcards={getcards}
          setGetcards={setGetcards}
        />
      </Route>

      <Route path="/global/dashboard">
        <DashboardContainer
          openSide={openSide}
          group={group}
          backAction={backAction}
          getcards={getcards}
          setGetcards={setGetcards}
        />
      </Route>

      <Route path="/global/noconnection">
        <NoConnectionPage />
      </Route>

      {group !== '' && <Route component={Page404} />}
    </Switch>
  );
}

export function RoutesSidebarContainer({
  setGetcards,
  setEmpytArray,
  group,
  closeSide,
}) {
  return (
    <Switch>
      <Route path="/global/client">
        <SidebarUnityContainer
          setGetcards={setGetcards}
          setEmpytArray={setEmpytArray}
          group={group}
          closeSide={closeSide}
        />
      </Route>
      <Route path="/global/schedule">
        <SidebarScheduleContainer
          closeSide={closeSide}
          setGetcards={setGetcards}
        />
      </Route>
      <Route path="/global/super">
        <SidebarClientContainer
          setGetcards={setGetcards}
          closeSide={closeSide}
        />
      </Route>
      <Route path="/global/dashboard">
        <SidebarDashboardContainer
          group={group}
          setGetcards={setGetcards}
          closeSide={closeSide}
        />
      </Route>
      <Route>{<Page404 sidebar={true} closeSide={closeSide} />}</Route>
    </Switch>
  );
}

/**
 * This component returns routes
 * for tasks related to admin
 */
export function RoutesAdminSide({
  admins,
  addAdmin,
  notificationType,
  notification,
  deletAdmin,
  editAdmin,
  redirectEditAdmin,
  backShowAdminList,
  getMoreAdmin,
  loading,
  loadingMore,
  endList,
  closeSide,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <IoseShowAdminsCard
          onClickDeletAdmin={deletAdmin}
          onClickEditAdmin={redirectEditAdmin}
          admins={admins}
          getMoreAdmin={getMoreAdmin}
          loading={loading}
          loadingMore={loadingMore}
          endList={endList}
        />
      </Route>
      <Route path={`${path}/createdmin`}>
        <IoseFormAdminAddCard
          addAdmin={addAdmin}
          backShowAdminList={backShowAdminList}
          notificationType={notificationType}
          notification={notification}
        />
      </Route>
      <Route path={`${path}/editadmin`}>
        <IoseFormAdminEditCard
          backShowAdminList={backShowAdminList}
          editAdmin={editAdmin}
        />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

/**
 * This component returns routes
 * for tasks related to client
 */
export function RoutesClientSide({
  clientname,
  editClient,
  addClient,
  notificationType,
  notification,
  closeSide,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/createclient`}>
        <IoseFormClientAddCard
          addClient={addClient}
          notificationType={notificationType}
          notification={notification}
        />
      </Route>
      <Route path={`${path}/editclient`}>
        <IoseFormClientAditCard
          editClient={editClient}
          notificationType={notificationType}
          notification={notification}
        />
      </Route>
      <Route path={`${path}/admins`}>
        <SidebarAdminContainer clientname={clientname} closeSide={closeSide} />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesDashboardSide({
  group,
  clickBack,
  setGetcards,
  closeSide,
  socket,
}) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path + '/switchboard'}>
        <SidebarSwitchboardContainer
          group={group}
          clickBack={clickBack}
          setGetcards={setGetcards}
          closeSide={closeSide}
          socket={socket}
        />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

/**
 *  This function return routes
 * for diferent kinds of sidebar content
 *
 * @returns react-router-dom.Switch
 */
export function RoutesUnitySide({
  setGetcards,
  setEmpytArray,
  group,
  clickBack,
  closeSide,
  socket,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/schedule`}>
        <SidebarScheduleContainer clickBack={clickBack} />
      </Route> */}
      <Route path={`${path}/switchboard`}>
        <SidebarSwitchboardContainer
          group={group}
          clickBack={clickBack}
          setGetcards={setGetcards}
          closeSide={closeSide}
          socket={socket}
        />
      </Route>
      <Route path={`${path}/employee`}>
        <SidebarEmployeeContainer
          group={group}
          clickBack={clickBack}
          closeSide={closeSide}
        />
      </Route>
      <Route path={`${path}/unity`}>
        <SidebarUnityOperationsContainer
          setGetcards={setGetcards}
          setEmpytArray={setEmpytArray}
          group={group}
          clickBack={clickBack}
          closeSide={closeSide}
        />
      </Route>
      <Route path={`${path}/environment`}>
        <SidebarEnvironmentContainer
          setGetcards={setGetcards}
          setEmpytArray={setEmpytArray}
          group={group}
          clickBack={clickBack}
          closeSide={closeSide}
        />
      </Route>

      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

/**
 *  This function return routes
 * for diferent kinds of sidebar content
 *
 * @returns react-router-dom.Switch
 */
export function RoutesEnvironmentSide({
  setGetcards,
  setEmpytArray,
  group,
  clickBack,
  closeSide,
  socket,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/schedule`}>
        <SidebarScheduleContainer clickBack={clickBack} />
      </Route> */}
      <Route path={`${path}/switchboard`}>
        <SidebarSwitchboardContainer
          group={group}
          clickBack={clickBack}
          setGetcards={setGetcards}
          closeSide={closeSide}
          socket={socket}
        />
      </Route>
      <Route path={`${path}/employee`}>
        <SidebarEmployeeContainer
          group={group}
          clickBack={clickBack}
          closeSide={closeSide}
        />
      </Route>
      <Route path={`${path}/environment`}>
        <SidebarEnvironmentContainer
          setGetcards={setGetcards}
          setEmpytArray={setEmpytArray}
          group={group}
          clickBack={clickBack}
          closeSide={closeSide}
        />
      </Route>

      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesUnityOperations({
  addUnity,
  editUnity,
  getAllAgents,
  getFilterAgents,
  closeSide,
}) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <IoseFormUnityAddCard
          addUnity={addUnity}
          getAllAgents={getAllAgents}
          getFilterAgents={getFilterAgents}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <IoseFormUnityEditCard
          editUnity={editUnity}
          getAllAgents={getAllAgents}
          getFilterAgents={getFilterAgents}
        />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesEmployee({
  deleteEmployee,
  editEmployee,
  redirectEditEmployee,
  addEmployee,
  group,
  clickBackShowEmployee,
  getMoreEmployees,
  arrayEmployee,
  loadingMore,
  endList,
  closeSide,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <IoseEmployeeShowCard
          arrayEmployee={arrayEmployee}
          clickDelete={deleteEmployee}
          clickEdit={redirectEditEmployee}
          loadingMore={loadingMore}
          endList={endList}
          getMoreEmployees={getMoreEmployees}
        />
      </Route>
      <Route path={`${path}/add`}>
        <IoseFormEmployeeCreateCard
          group={group}
          clickBackShowEmployee={clickBackShowEmployee}
          addEmployee={addEmployee}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <IoseFormEmployeeEditCard
          group={group}
          clickBackShowEmployee={clickBackShowEmployee}
          editEmployee={editEmployee}
        />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesSchedule({
  addSchedule,
  editSchedule,
  editScheduleAddCircuits,
  editScheduleRemoveCircuits,
  redirectValidation,
  redirectToForm,
  nameSwitchboard,
  closeSide,
  circuitStateScheduling,
  getSchedulingModulesByGroup,
  resendRequistion,
  createResponse,
  deleteResponse,
  editResponse,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add`}>
        <IoseFormScheduleCreateCard
          addSchedule={addSchedule}
          redirectValidation={redirectValidation}
        />
      </Route>
      <Route path={`${path}/validation`}>
        <IoseScheduleShowProcess
          redirectToForm={redirectToForm}
          nameSwitchboard={nameSwitchboard}
          closeSide={closeSide}
          circuitStateScheduling={circuitStateScheduling}
          resendRequistion={resendRequistion}
          createResponse={createResponse}
          deleteResponse={deleteResponse}
          editResponse={editResponse}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <IoseFormScheduleEditCard
          editSchedule={editSchedule}
          redirectValidation={redirectValidation}
          getSchedulingModulesByGroup={getSchedulingModulesByGroup}
          editScheduleAddCircuits={editScheduleAddCircuits}
          editScheduleRemoveCircuits={editScheduleRemoveCircuits}
        />
      </Route>
      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesSwitchboard({
  showCircuit,
  showDashboard,
  group,
  clickBack,
  allGroups,
  allCircuits,
  loadingMore,
  loading,
  endList,
  getMoreGroups,
  getAllCircuits,
  desassociateCircuits,
  addSwitchboard,
  editSwitchboard,
  setNameSwitchboard,
  setGetcards,
  searchError,
  triggerCircuit,
  triggerCircuitAction,
  closeSide,
  getAllDataSwitchboardContainer,
}) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <IoseSwitchboardShowCardList
          showCircuit={showCircuit}
          showDashboard={showDashboard}
          group={group}
          allGroups={allGroups}
          allCircuits={allCircuits}
          loadingMore={loadingMore}
          loading={loading}
          endList={endList}
          getMoreGroups={getMoreGroups}
          getAllCircuits={getAllCircuits}
          desassociateCircuits={desassociateCircuits}
          setNameSwitchboard={setNameSwitchboard}
          searchError={searchError}
          triggerCircuit={triggerCircuit}
          triggerCircuitAction={triggerCircuitAction}
        />
      </Route>
      <Route path={`${path}/add`}>
        <IoseFormSwitchboardCreateCard
          clickBack={clickBack}
          addSwitchboard={addSwitchboard}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <IoseFormSwitchboardEditCard
          clickBack={clickBack}
          editSwitchboard={editSwitchboard}
        />
      </Route>
      <Route path={`${path}/integrate`}>
        <IoseSwitchboardIntegrateCard />
      </Route>
      <Route path={`${path}/circuit`}>
        <SidebarCircuitContainer
          clickBack={clickBack}
          setGetcards={setGetcards}
          group={group}
          allGroups={allGroups}
          closeSide={closeSide}
          setNameSwitchboard={setNameSwitchboard}
          getAllDataSwitchboardContainer={getAllDataSwitchboardContainer}
        />
      </Route>

      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}

export function RoutesCircuit({
  clickBack,
  clickBackShowCircuit,
  name,
  description,
  informationTC,
  uuid_circuit,
  redirectEditCircuit,
  editCircuit,
  allGroups,
  redirectViewSchedule,
  closeSide,
  dataTable,
  getSchedulingModulesByGroup,
  resendRequistion,
  getAllLogs,
  getDataUser,
  getSchedulingGroupByCircuit,
  setLoading,
  group,
  redirectEvents,
}) {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <IoseCircuitShowCard
          clickBack={clickBack}
          name={name}
          description={description}
          uuid_circuit={uuid_circuit}
          redirectEditCircuit={redirectEditCircuit}
          allGroups={allGroups}
          redirectViewSchedule={redirectViewSchedule}
          closeSide={closeSide}
          informationTC={informationTC}
          dataTable={dataTable}
          getSchedulingModulesByGroup={getSchedulingModulesByGroup}
          resendRequistion={resendRequistion}
          getAllLogs={getAllLogs}
          getDataUser={getDataUser}
          getSchedulingGroupByCircuit={getSchedulingGroupByCircuit}
          setLoading={setLoading}
          group={group}
          redirectEvents={redirectEvents}
        />
      </Route>
      <Route path={`${path}/edit`}>
        <IoseFormCircuitEditCard
          clickBack={clickBackShowCircuit}
          name={name}
          description={description}
          uuid_circuit={uuid_circuit}
          editCircuit={editCircuit}
        />
      </Route>

      <Route>
        <Page404 sidebar={true} closeSide={closeSide} />
      </Route>
    </Switch>
  );
}
