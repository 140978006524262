import React, { useEffect, useState } from 'react';
import { TablePagination } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import * as S from '../style';

const formatNumber = (number) => number?.toLocaleString();

const TableHeaderCell = ({ label, sortBy, sortDirection, handleSort, column }) => (
  <S.TableHeaderCell onClick={() => handleSort(column)}>
    {label} {sortBy === column && (sortDirection === 'asc' ? '🔼' : '🔽')}
  </S.TableHeaderCell>
);

const TableRowCell = ({ children, onCellClick, isUcColumn }) => (
  <S.TableCell onClick={isUcColumn ? onCellClick : undefined}>
    {children}
  </S.TableCell>
);

const MercadoLivreTable = ({ data, onRowClick, page, rowsPerPage, handleSort, sortBy, sortDirection }) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
     <IoseInfinityScroll
        dataLength={20}
        next={() => {}}
        hasMore={false}
        loading={false}
        endList={false}
        sidebar={false}
        marginTop="0"
        padding="0"
        colortextend={null}
        sizetextend={null}
        scrollableTarget="scrollableDiv"
        height={380}
      >
      <S.Table>
        <S.TableHeader>
          <tr>
            {[
              { label: 'UC', column: 'identification_number' },
              { label: 'COMERCIALIZADORA', column: 'comercializadora' },
              { label: 'MODALIDADE', column: 'modalidade' },
              { label: 'CIDADE', column: 'city' },
              { label: 'GG', column: 'general_management' },
              { label: 'GR', column: 'regional_management' },
              { label: 'VALOR ACL (R$)', column: 'valor_total' },
              { label: 'VALOR ACR (R$)', column: 'item_total_value_1' },
              { label: 'VALOR TOTAL (R$)', column: 'total_geral_faturas' },
              { label: 'CONS. ACL (MWh)', column: 'quantidade' },
              { label: 'CONS. ACR (MWh)', column: 'item_consumption_1' },
            ].map(({ label, column }) => (
              <TableHeaderCell
                key={column}
                label={label}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSort={handleSort}
                column={column}
              />
            ))}
          </tr>
        </S.TableHeader>
        <tbody>
          {pageData.map((value, index) => (
            <S.TableRow key={index}>
              <TableRowCell
                onCellClick={() => onRowClick(value.identification_number, value.numero_telemetria)}
                isUcColumn={true}
              >
                {value.identification_number?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>{formatNumber(value.comercializadora)}</TableRowCell>
              <TableRowCell>{value.modalidade?.toUpperCase()}</TableRowCell>
              <TableRowCell>{value.city?.toUpperCase()}</TableRowCell>
              <TableRowCell>{value.general_management?.toUpperCase()}</TableRowCell>
              <TableRowCell>{value.regional_management}</TableRowCell>
              <TableRowCell>{formatNumber(value.valor_total)}</TableRowCell>
              <TableRowCell>{formatNumber(value.item_total_value_1)}</TableRowCell>
              <TableRowCell>{formatNumber(value.total_geral_faturas)}</TableRowCell>
              <TableRowCell>{formatNumber(value.quantidade)}</TableRowCell>
              <TableRowCell>{formatNumber(value.item_consumption_1 / 1000)}</TableRowCell>
            </S.TableRow>
          ))}
        </tbody>
      </S.Table>
    </IoseInfinityScroll>
  );
};

export default function MercadoLivreTableWrapper({ data = [], loading }) {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (data.length > 0) {
      const dataWithTotals = data.map(item => ({
        ...item,
        total_geral_faturas: item.item_total_value_1 + item.valor_total,
      }));
      const sortedData = sortData(dataWithTotals, sortBy, sortDirection);
      setUpdatedData(sortedData);
    }
  }, [data, sortBy, sortDirection]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const handleRowClick = (identificationNumber, numeroTelemetria) => {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: identificationNumber,
        uuidCircuit: numeroTelemetria
       },
    });
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    }

    if (updatedData.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }

    return (
      <>
        <MercadoLivreTable
          data={updatedData}
          onRowClick={handleRowClick}
          page={page}
          rowsPerPage={rowsPerPage}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
        <TablePagination
          component="div"
          count={updatedData.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Itens por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
        />
      </>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  )
}

// Função para ordenação
function sortData(data, sortBy, sortDirection) {
  if (!sortBy) return data;

  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
}
