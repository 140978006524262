import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext } from 'react';

import * as S from './style';

import {
  IoseCloseModalButton,
  IoseMapRedirectButton,
} from 'components/IoseButtonIcon';

import { AccountContext, ioseAPI } from 'common';
import {
  IoseAlertLoadingCards,
  IoseAlertSuccessModal,
} from 'components/IoseAlert';
import { IoseModalButton } from 'components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#ECF0F5',
    // height: '90vh',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseBillsEditMeasurerModal({
  open,
  onClose,
  submit,
  data,
  editInitialValues,
  refreshBills,
  refreshFaturas,
  closeAuditModal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { getSession } = useContext(AccountContext);

  //eslint-disable-next-line
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [uuidBill, setUuidBill] = useState('');
  const [showNotification, setShowNotification] = useState(null);

  useEffect(() => {
    setUuidBill(data?.uuid_energy_bill);
  }, [data]);

  function redirectToMapPage() {
    history.push('/map');
  }

  const renderContent = () => {
    async function updateBillMeasurer(
      uuid_energy_bill,
      measurer_1,
      measurer_2,
      measurer_3,
      measurer_4,
      measurer_5,
      measurer_6,
      measurer_7,
      measurer_8,
      measurer_9,
      measurer_10,
      measurer_11,
      measurer_12,
      magnitude_1,
      magnitude_2,
      magnitude_3,
      magnitude_4,
      magnitude_5,
      magnitude_6,
      magnitude_7,
      magnitude_8,
      magnitude_9,
      magnitude_10,
      magnitude_11,
      magnitude_12,
      timetables_1,
      timetables_2,
      timetables_3,
      timetables_4,
      timetables_5,
      timetables_6,
      timetables_7,
      timetables_8,
      timetables_9,
      timetables_10,
      timetables_11,
      timetables_12,
      measurer_previous_reading_1,
      measurer_previous_reading_2,
      measurer_previous_reading_3,
      measurer_previous_reading_4,
      measurer_previous_reading_5,
      measurer_previous_reading_6,
      measurer_previous_reading_7,
      measurer_previous_reading_8,
      measurer_previous_reading_9,
      measurer_previous_reading_10,
      measurer_previous_reading_11,
      measurer_previous_reading_12,
      measurer_current_reading_1,
      measurer_current_reading_2,
      measurer_current_reading_3,
      measurer_current_reading_4,
      measurer_current_reading_5,
      measurer_current_reading_6,
      measurer_current_reading_7,
      measurer_current_reading_8,
      measurer_current_reading_9,
      measurer_current_reading_10,
      measurer_current_reading_11,
      measurer_current_reading_12,
      measurer_constant_1,
      measurer_constant_2,
      measurer_constant_3,
      measurer_constant_4,
      measurer_constant_5,
      measurer_constant_6,
      measurer_constant_7,
      measurer_constant_8,
      measurer_constant_9,
      measurer_constant_10,
      measurer_constant_11,
      measurer_constant_12,
      kwh_consumption_1,
      kwh_consumption_2,
      kwh_consumption_3,
      kwh_consumption_4,
      kwh_consumption_5,
      kwh_consumption_6,
      kwh_consumption_7,
      kwh_consumption_8,
      kwh_consumption_9,
      kwh_consumption_10,
      kwh_consumption_11,
      kwh_consumption_12
    ) {
      // setEditNotificationType(0);
      setLoading(true);

      try {
        const sessionData = await getSession();

        await ioseAPI.updateEnergyBillMeasurer(
          sessionData.headers,
          uuid_energy_bill,
          measurer_1,
          measurer_2,
          measurer_3,
          measurer_4,
          measurer_5,
          measurer_6,
          measurer_7,
          measurer_8,
          measurer_9,
          measurer_10,
          measurer_11,
          measurer_12,
          magnitude_1,
          magnitude_2,
          magnitude_3,
          magnitude_4,
          magnitude_5,
          magnitude_6,
          magnitude_7,
          magnitude_8,
          magnitude_9,
          magnitude_10,
          magnitude_11,
          magnitude_12,
          timetables_1,
          timetables_2,
          timetables_3,
          timetables_4,
          timetables_5,
          timetables_6,
          timetables_7,
          timetables_8,
          timetables_9,
          timetables_10,
          timetables_11,
          timetables_12,
          measurer_previous_reading_1,
          measurer_previous_reading_2,
          measurer_previous_reading_3,
          measurer_previous_reading_4,
          measurer_previous_reading_5,
          measurer_previous_reading_6,
          measurer_previous_reading_7,
          measurer_previous_reading_8,
          measurer_previous_reading_9,
          measurer_previous_reading_10,
          measurer_previous_reading_11,
          measurer_previous_reading_12,
          measurer_current_reading_1,
          measurer_current_reading_2,
          measurer_current_reading_3,
          measurer_current_reading_4,
          measurer_current_reading_5,
          measurer_current_reading_6,
          measurer_current_reading_7,
          measurer_current_reading_8,
          measurer_current_reading_9,
          measurer_current_reading_10,
          measurer_current_reading_11,
          measurer_current_reading_12,
          measurer_constant_1,
          measurer_constant_2,
          measurer_constant_3,
          measurer_constant_4,
          measurer_constant_5,
          measurer_constant_6,
          measurer_constant_7,
          measurer_constant_8,
          measurer_constant_9,
          measurer_constant_10,
          measurer_constant_11,
          measurer_constant_12,
          kwh_consumption_1,
          kwh_consumption_2,
          kwh_consumption_3,
          kwh_consumption_4,
          kwh_consumption_5,
          kwh_consumption_6,
          kwh_consumption_7,
          kwh_consumption_8,
          kwh_consumption_9,
          kwh_consumption_10,
          kwh_consumption_11,
          kwh_consumption_12
        );

        refreshBills();
        refreshFaturas();
        setLoading(false);
        setShowNotification(1);

        setTimeout(() => {
          onClose();
          closeAuditModal();
          setShowNotification(null);
        }, 2500);
      } catch (err) {
        console.error(err);
      }
    }

    const handleUpdate = (values) => {
      function converterStringParaNumero(valor) {
        if (typeof valor === 'string') {
          valor = valor.replace(',', '.');
        }

        return parseFloat(valor);
      }

      const {
        measurer_1,
        measurer_2,
        measurer_3,
        measurer_4,
        measurer_5,
        measurer_6,
        measurer_7,
        measurer_8,
        measurer_9,
        measurer_10,
        measurer_11,
        measurer_12,
        magnitude_1,
        magnitude_2,
        magnitude_3,
        magnitude_4,
        magnitude_5,
        magnitude_6,
        magnitude_7,
        magnitude_8,
        magnitude_9,
        magnitude_10,
        magnitude_11,
        magnitude_12,
        timetables_1,
        timetables_2,
        timetables_3,
        timetables_4,
        timetables_5,
        timetables_6,
        timetables_7,
        timetables_8,
        timetables_9,
        timetables_10,
        timetables_11,
        timetables_12,
        measurer_previous_reading_1,
        measurer_previous_reading_2,
        measurer_previous_reading_3,
        measurer_previous_reading_4,
        measurer_previous_reading_5,
        measurer_previous_reading_6,
        measurer_previous_reading_7,
        measurer_previous_reading_8,
        measurer_previous_reading_9,
        measurer_previous_reading_10,
        measurer_previous_reading_11,
        measurer_previous_reading_12,
        measurer_current_reading_1,
        measurer_current_reading_2,
        measurer_current_reading_3,
        measurer_current_reading_4,
        measurer_current_reading_5,
        measurer_current_reading_6,
        measurer_current_reading_7,
        measurer_current_reading_8,
        measurer_current_reading_9,
        measurer_current_reading_10,
        measurer_current_reading_11,
        measurer_current_reading_12,
        measurer_constant_1,
        measurer_constant_2,
        measurer_constant_3,
        measurer_constant_4,
        measurer_constant_5,
        measurer_constant_6,
        measurer_constant_7,
        measurer_constant_8,
        measurer_constant_9,
        measurer_constant_10,
        measurer_constant_11,
        measurer_constant_12,
        kwh_consumption_1,
        kwh_consumption_2,
        kwh_consumption_3,
        kwh_consumption_4,
        kwh_consumption_5,
        kwh_consumption_6,
        kwh_consumption_7,
        kwh_consumption_8,
        kwh_consumption_9,
        kwh_consumption_10,
        kwh_consumption_11,
        kwh_consumption_12,
      } = values;

      const f_measurer_previous_reading_1 = converterStringParaNumero(
        measurer_previous_reading_1
      );
      const f_measurer_previous_reading_2 = converterStringParaNumero(
        measurer_previous_reading_2
      );
      const f_measurer_previous_reading_3 = converterStringParaNumero(
        measurer_previous_reading_3
      );
      const f_measurer_previous_reading_4 = converterStringParaNumero(
        measurer_previous_reading_4
      );
      const f_measurer_previous_reading_5 = converterStringParaNumero(
        measurer_previous_reading_5
      );
      const f_measurer_previous_reading_6 = converterStringParaNumero(
        measurer_previous_reading_6
      );
      const f_measurer_previous_reading_7 = converterStringParaNumero(
        measurer_previous_reading_7
      );
      const f_measurer_previous_reading_8 = converterStringParaNumero(
        measurer_previous_reading_8
      );
      const f_measurer_previous_reading_9 = converterStringParaNumero(
        measurer_previous_reading_9
      );
      const f_measurer_previous_reading_10 = converterStringParaNumero(
        measurer_previous_reading_10
      );
      const f_measurer_previous_reading_11 = converterStringParaNumero(
        measurer_previous_reading_11
      );
      const f_measurer_previous_reading_12 = converterStringParaNumero(
        measurer_previous_reading_12
      );

      const f_measurer_current_reading_1 = converterStringParaNumero(
        measurer_current_reading_1
      );
      const f_measurer_current_reading_2 = converterStringParaNumero(
        measurer_current_reading_2
      );
      const f_measurer_current_reading_3 = converterStringParaNumero(
        measurer_current_reading_3
      );
      const f_measurer_current_reading_4 = converterStringParaNumero(
        measurer_current_reading_4
      );
      const f_measurer_current_reading_5 = converterStringParaNumero(
        measurer_current_reading_5
      );
      const f_measurer_current_reading_6 = converterStringParaNumero(
        measurer_current_reading_6
      );
      const f_measurer_current_reading_7 = converterStringParaNumero(
        measurer_current_reading_7
      );
      const f_measurer_current_reading_8 = converterStringParaNumero(
        measurer_current_reading_8
      );
      const f_measurer_current_reading_9 = converterStringParaNumero(
        measurer_current_reading_9
      );
      const f_measurer_current_reading_10 = converterStringParaNumero(
        measurer_current_reading_10
      );
      const f_measurer_current_reading_11 = converterStringParaNumero(
        measurer_current_reading_11
      );
      const f_measurer_current_reading_12 = converterStringParaNumero(
        measurer_current_reading_12
      );

      const f_measurer_constant_1 = converterStringParaNumero(
        measurer_constant_1
      );
      const f_measurer_constant_2 = converterStringParaNumero(
        measurer_constant_2
      );
      const f_measurer_constant_3 = converterStringParaNumero(
        measurer_constant_3
      );
      const f_measurer_constant_4 = converterStringParaNumero(
        measurer_constant_4
      );
      const f_measurer_constant_5 = converterStringParaNumero(
        measurer_constant_5
      );
      const f_measurer_constant_6 = converterStringParaNumero(
        measurer_constant_6
      );
      const f_measurer_constant_7 = converterStringParaNumero(
        measurer_constant_7
      );
      const f_measurer_constant_8 = converterStringParaNumero(
        measurer_constant_8
      );
      const f_measurer_constant_9 = converterStringParaNumero(
        measurer_constant_9
      );
      const f_measurer_constant_10 = converterStringParaNumero(
        measurer_constant_10
      );
      const f_measurer_constant_11 = converterStringParaNumero(
        measurer_constant_11
      );
      const f_measurer_constant_12 = converterStringParaNumero(
        measurer_constant_12
      );

      const f_kwh_consumption_1 = converterStringParaNumero(kwh_consumption_1);
      const f_kwh_consumption_2 = converterStringParaNumero(kwh_consumption_2);
      const f_kwh_consumption_3 = converterStringParaNumero(kwh_consumption_3);
      const f_kwh_consumption_4 = converterStringParaNumero(kwh_consumption_4);
      const f_kwh_consumption_5 = converterStringParaNumero(kwh_consumption_5);
      const f_kwh_consumption_6 = converterStringParaNumero(kwh_consumption_6);
      const f_kwh_consumption_7 = converterStringParaNumero(kwh_consumption_7);
      const f_kwh_consumption_8 = converterStringParaNumero(kwh_consumption_8);
      const f_kwh_consumption_9 = converterStringParaNumero(kwh_consumption_9);
      const f_kwh_consumption_10 = converterStringParaNumero(
        kwh_consumption_10
      );
      const f_kwh_consumption_11 = converterStringParaNumero(
        kwh_consumption_11
      );
      const f_kwh_consumption_12 = converterStringParaNumero(
        kwh_consumption_12
      );

      updateBillMeasurer(
        uuidBill,
        measurer_1,
        measurer_2,
        measurer_3,
        measurer_4,
        measurer_5,
        measurer_6,
        measurer_7,
        measurer_8,
        measurer_9,
        measurer_10,
        measurer_11,
        measurer_12,
        magnitude_1,
        magnitude_2,
        magnitude_3,
        magnitude_4,
        magnitude_5,
        magnitude_6,
        magnitude_7,
        magnitude_8,
        magnitude_9,
        magnitude_10,
        magnitude_11,
        magnitude_12,
        timetables_1,
        timetables_2,
        timetables_3,
        timetables_4,
        timetables_5,
        timetables_6,
        timetables_7,
        timetables_8,
        timetables_9,
        timetables_10,
        timetables_11,
        timetables_12,
        f_measurer_previous_reading_1,
        f_measurer_previous_reading_2,
        f_measurer_previous_reading_3,
        f_measurer_previous_reading_4,
        f_measurer_previous_reading_5,
        f_measurer_previous_reading_6,
        f_measurer_previous_reading_7,
        f_measurer_previous_reading_8,
        f_measurer_previous_reading_9,
        f_measurer_previous_reading_10,
        f_measurer_previous_reading_11,
        f_measurer_previous_reading_12,
        f_measurer_current_reading_1,
        f_measurer_current_reading_2,
        f_measurer_current_reading_3,
        f_measurer_current_reading_4,
        f_measurer_current_reading_5,
        f_measurer_current_reading_6,
        f_measurer_current_reading_7,
        f_measurer_current_reading_8,
        f_measurer_current_reading_9,
        f_measurer_current_reading_10,
        f_measurer_current_reading_11,
        f_measurer_current_reading_12,
        f_measurer_constant_1,
        f_measurer_constant_2,
        f_measurer_constant_3,
        f_measurer_constant_4,
        f_measurer_constant_5,
        f_measurer_constant_6,
        f_measurer_constant_7,
        f_measurer_constant_8,
        f_measurer_constant_9,
        f_measurer_constant_10,
        f_measurer_constant_11,
        f_measurer_constant_12,
        f_kwh_consumption_1,
        f_kwh_consumption_2,
        f_kwh_consumption_3,
        f_kwh_consumption_4,
        f_kwh_consumption_5,
        f_kwh_consumption_6,
        f_kwh_consumption_7,
        f_kwh_consumption_8,
        f_kwh_consumption_9,
        f_kwh_consumption_10,
        f_kwh_consumption_11,
        f_kwh_consumption_12
      );
    };

    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.StyledWrappedModal2>
          <Formik initialValues={editInitialValues} onSubmit={handleUpdate}>
            {({
              handleChange,
              handleBlur,
              isSubmitting,
              errors,
              touched,
              values,
            }) => {
              return (
                <Form>
                  <S.FormConsumerGroupContainer>
                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key) => {
                        const regex = /^measurer_(\d+)$/;
                        const match = key.match(regex);

                        if (match) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('magnitude_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('timetables_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('measurer_previous_reading_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('measurer_current_reading_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('measurer_constant_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      {Object.keys(values).map((key, index) => {
                        if (key.startsWith('kwh_consumption_')) {
                          return (
                            <S.DivInputs key={key}>
                              <S.StyledLabel htmlFor={key}>{key}</S.StyledLabel>
                              <S.StyledInput
                                type="text"
                                id={key}
                                name={key}
                                value={values[key]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors[key] && touched[key] && (
                                <div>{errors[key]}</div>
                              )}
                            </S.DivInputs>
                          );
                        }
                        return null;
                      })}
                    </S.WrappedConsumerGroupItens>
                  </S.FormConsumerGroupContainer>

                  <S.ButtonContainer>
                    {loading ? (
                      <S.CircularProgressStyled />
                    ) : (
                      showNotification === 1 && (
                        <IoseAlertSuccessModal
                          text={'Dados da fatura atualizados com sucesso.'}
                        />
                      )
                    )}
                    {!loading && showNotification !== 1 && (
                      <IoseModalButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Editar Campos
                      </IoseModalButton>
                    )}
                  </S.ButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </S.StyledWrappedModal2>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <S.ModalContainer>
          <S.StyledHeaderModal>
            <S.RoomIcon />

            <S.ModalTitleHeader variant="h5" gutterBottom>
              EDITAR DADOS DA ÁREA DO MEDIDOR DA FATURA
            </S.ModalTitleHeader>

            <S.ButtonsContainer>
              {/* <IoseAuditRedirectButton
                tooltip="RETORNAR PARA UNIDADE CONSUMIDORA"
                bottom="5px"
                onClick={() => redirectToConsumerGroupPage()}
              /> */}

              <IoseMapRedirectButton
                tooltip="RETORNAR PARA O MAPA"
                bottom="5px"
                onClick={() => redirectToMapPage()}
              />

              <IoseCloseModalButton
                tooltip="FECHAR MODAL"
                bottom="5px"
                onClick={() => onClose()}
              />
            </S.ButtonsContainer>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <>
                {/* {renderSubHeaderAndSearchBar()} */}
                {renderContent()}
              </>
            </div>
          </S.StyledWrappedModal>
        </S.ModalContainer>
      </Grow>
    </Modal>
  );
}
