import styled from 'styled-components';

//Imports from IOSE Components
import IoseSideCard from 'components/IoseSideCard';

//Imports from Material Ui components
import { Avatar, CardHeader } from '@material-ui/core';

//Imports images
import Icon1 from './img/gerente_yellow2.svg';
import Icon2 from './img/operador_yellow2.svg';

//General
export const Container = styled(IoseSideCard)`
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  width: 100%;
  color: var(--base);
  margin-top: 10px !important;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ContainerInityScroll = styled.div.attrs((props) => ({
  id: props.id,
}))`
  height: 95%;

  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

//Header
export const DivHeaderButton = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.1s ease-in-out;
  border: 2px solid transparent;
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;
  color: var(--base);
  width: 100%;

  span {
    white-space: pre-wrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--base);

    @media (max-width: 767px) {
      width: 150px;
    }
  }

  .MuiCardHeader-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.15s linear;
  }
`;

export const Icon = styled.img.attrs((props) => ({
  src: props.operator === 'true' ? Icon2 : Icon1,
}))`
  width: 30px;
  height: 30px;
`;

export const AvatarStyledHeader = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--primary);
`;

//Content
export const Content = styled.div`
  padding: 0px 16px 16px 11%;
  p {
    font-size: 12px;
  }

  @media (max-width: 767px) {
    padding: 0px 16px 16px 22%;
  }
`;
