import { useState, useEffect } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueAtividades() {
  const [noAtividadesError, setNoAtividadesError] = useState('');
  const {
    consumerGroups,
    isLoading: consumerGroupsLoading,
    error: consumerGroupsError
  } = useConsumerGroup();

  const getUniqueAtividades = async () => {
    if (!consumerGroups || consumerGroups.length === 0) {
      setNoAtividadesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    const atividadesSet = new Set();
    consumerGroups?.forEach((item) => {
      if (item.atividade && item.atividade !== '0') {
        atividadesSet.add(item.atividade);
      }
    });

    const uniqueAtividades = [...atividadesSet].map((atividade) => ({
      value: atividade,
      label: atividade,
    }));

    if (uniqueAtividades.length === 0) {
      setNoAtividadesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return uniqueAtividades;
  };

  const {
    data: uniqueAtividades,
    error: uniqueAtividadesError,
    refetch: refreshUniqueAtividades,
    isLoading,
  } = useQuery(['uniqueAtividades', consumerGroups], getUniqueAtividades, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  useEffect(() => {
    if (consumerGroupsError) {
      setNoAtividadesError(consumerGroupsError.message || Messages.noFindResults);
    }
  }, [consumerGroupsError]);

  return {
    uniqueAtividades,
    uniqueAtividadesError,
    noAtividadesError,
    refreshUniqueAtividades,
    isLoading: isLoading || consumerGroupsLoading,
  };
}

export default useUniqueAtividades;
