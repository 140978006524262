// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useComercializadoras() {
  const [noComercializadorasError, setNoComercializadorasError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getComercializadoras = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getComercializadoras(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (allData?.length === 0) {
      setNoComercializadorasError(Messages.noRegisteredComercializadoras);
      throw new Error(Messages.noRegisteredComercializadoras);
    }

    const comercializadorasForSelect = allData?.map((comercializadora) => ({
      value: comercializadora.name,
      label: comercializadora.name,
    }));

    return {
      allData,
      comercializadorasForSelect,
    };
  };

  const {
    data: {
      allData,
      comercializadorasForSelect,
    } = {},
    error: comercializadoraError,
    refetch: refreshComercializadoras,
    isLoading,
  } = useQuery(['comercializadoraData'], getComercializadoras);

  return {
    comercializadoras: allData || [],
    comercializadorasForSelect: comercializadorasForSelect,
    comercializadoraError,
    noComercializadorasError,
    refreshComercializadoras,
    isLoading,
  };
}

export default useComercializadoras;
