import React, { useState } from 'react';

import { LoadingIndicatorDashboard } from 'components';

import * as S from './style';

import DraggableContainer from 'components/IoseDragabbleContainer';

export default function DashboardGridContainer() {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const renderDragabbleContainer = () => {
    return <DraggableContainer />;
  };

  return (
    <S.WrapContainer>
      {loading ? (
        <LoadingIndicatorDashboard loading={loading} />
      ) : (
        <S.GridContainer container>
          {renderDragabbleContainer()}
        </S.GridContainer>
      )}
    </S.WrapContainer>
  );
}
