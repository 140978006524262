import React, { useEffect, useState } from 'react';

import {
  IoseNoDataMessage,
  IoseRadioChartConsumptionComparision,
  LoadingIndicator,
} from 'components';

import * as S from './style';
import IoseRadioChartComparisionTelemetry from 'components/IoseRadioChartComparisionTelemetry';

export default function IoseRadarTelemetryContainer({ loading, completeData }) {
  const [data, setData] = useState();

  useEffect(() => {
    getLastMonthObjects(completeData);
  }, [completeData]);

  const getLastMonthObjects = (data) => {
    const lastMonth = data?.reduce((last, obj) => {
      if (obj.reference_month > last) {
        return obj.reference_month;
      } else {
        return last;
      }
    }, '');

    setData(
      data?.filter(
        (obj) => obj.reference_month === lastMonth && obj.balance_status === 0
      )
    );
  };

  const renderChartOrErrorMessage = () => {
    return data && data.length > 0 ? (
      <IoseRadioChartConsumptionComparision data={data} />
    ) : (
      <IoseNoDataMessage
        message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
        height="400px"
        width="400px"
      />
    );
  };

  return (
    <S.ChartsContainer>
      <S.ChartBox>
        <S.ChartTitle>
          <h3>UNIDADES CONSUMIDORAS COM ALERTAS NO ÚLTIMO MÊS DO PERÍODO</h3>
        </S.ChartTitle>

        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          renderChartOrErrorMessage()
        )}
      </S.ChartBox>
    </S.ChartsContainer>
  );
}
