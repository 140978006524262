import React from "react";
import * as S from '../style';

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalBillTableMeasurements({ currentEnergyBill }) {
  if (!currentEnergyBill || currentEnergyBill.length === 0) return null;

  const energyBill = currentEnergyBill[0];

  // Função para obter o valor da chave correspondente
  const getValue = (keyPrefix, col) => {
    const key = `${keyPrefix}${col}`;
    return energyBill[key] !== undefined ? energyBill[key] : '0';
  };

  return (
    <S.TableContainer>
      <S.TableTitleContainer>
        <S.TableTitle>MEDIDORES E GRANDEZAS</S.TableTitle>
      </S.TableTitleContainer>
      <S.Table>
        <S.TableHeader>
          <tr>
            <S.TableHeaderCell>MEDIDOR</S.TableHeaderCell>
            <S.TableHeaderCell>GRANDEZAS</S.TableHeaderCell>
            <S.TableHeaderCell>POSTOS HORÁRIOS</S.TableHeaderCell>
            <S.TableHeaderCell>LEITURA ANTERIOR</S.TableHeaderCell>
            <S.TableHeaderCell>LEITURA ATUAL</S.TableHeaderCell>
            <S.TableHeaderCell>CONST. MEDIDOR</S.TableHeaderCell>
            <S.TableHeaderCell>CONSUMO kWh</S.TableHeaderCell>
          </tr>
        </S.TableHeader>
        <tbody>

          {Array.from({ length: 15 }, (_, i) => i + 1).map((col) => (
            <S.TableExtractRow key={col}>
              <S.TableCell>{getValue('measurer_', col)}</S.TableCell>
              <S.TableCell>{getValue('magnitude_', col)}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('timetables_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('measurer_previous_reading_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('measurer_current_reading_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('measurer_constant_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('kwh_consumption_', col))}</S.TableCell>
            </S.TableExtractRow>
          ))}

        </tbody>
      </S.Table>
    </S.TableContainer>
  );
}
