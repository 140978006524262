// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueConsumerGroupOperatingUnity() {
  const [noOperatingUnityError, setNoOperatingUnityError] = useState(
    ''
  );

  const { consumerGroups } = useConsumerGroup();

  const getUniqueModalidade = async () => {

    const operatingUnitySet = new Set();

    consumerGroups?.forEach((item) => {
      if (
        item.operating_unity
        && item.operating_unity !== '0'
        && item.operating_unity !== ''
        && item.operating_unity.toUpperCase() !== 'NULL'
      ) {
        operatingUnitySet.add(item.operating_unity);
      }
    });

    const operatingUnitys = [...operatingUnitySet].map(
      (operatingUnity) => ({
        value: operatingUnity,
        label: operatingUnity,
      })
    );

    if (operatingUnitys.length === 0) {
      setNoOperatingUnityError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return { operatingUnitys };
  };

  const {
    data: { operatingUnitys } = {},
    error: operatingUnityError,
    refetch: refreshAddress,
    isLoading,
  } = useQuery(['operatingUnity', consumerGroups], getUniqueModalidade, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    operatingUnitys,
    operatingUnityError,
    noOperatingUnityError,
    refreshAddress,
    isLoading,
  };
}

export default useUniqueConsumerGroupOperatingUnity;
