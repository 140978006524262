import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  CircularProgress,
  Grid
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Field } from 'formik';

export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: var(--primary);
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

//MODALS
export const DivInputs = styled.div`
  width: auto;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '20px'};
`;

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 30px !important;
  height: 30px !important;
`;

export const ModalTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: var(--primary);
  margin-top: 6px;
`;

export const StyledFormControl = styled(FormControl)`
  label {
    color: white;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid #fff;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid var(--primary);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const StyledHeaderModal = styled.div`
  background-color: var(--secondary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #fff;
  width: 500px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: var(--primary);
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;

  @media (max-width: 1360px) {
    font-size: 10px;
  }
`;

export const StyledSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 12px;
  }

  @media (max-width: 1360px) {
    .MuiInputBase-input {
      font-size: 10px;
    }
  }
`;

export const StyledField = styled(Field)`
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
  }
`;

export const StyledDeleteWrappedModal = styled.div`
  background-color: #fff;
  height: 350px;
  width: 620px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;
