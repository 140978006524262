import styled from 'styled-components';

const IoseContainer = styled.div.attrs((props) => ({
  id: props.id,
}))`
  height: 100%;
  margin-left: 20px;
  overflow-x: hidden;

  scrollbar-width: thin; /* Firefox */
  scrollbar-color: var(--primary) transparent; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: thin; /* Chrome, Edge, Safari */
  scrollbar-color: var(--primary) transparent; /* Chrome, Edge, Safari */

  &::-webkit-scrollbar {
    width: 5px;
    display: none; /* Oculta o scroll padrão no Chrome e Safari */
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (min-width: 2000px) {
    padding: 0% 15% 0% 12%;
  }
`;

export default IoseContainer;
