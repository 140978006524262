import styled from 'styled-components';

//Import from Iose Components
import IoseSideCard from 'components/IoseSideCard';
import IoseTextFieldSidebar from 'components/IoseTextFieldSidebar';

//Import from formik
import { Form } from 'formik';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  color: var(--base);

  div {
    font-weight: 600;
  }
`;

export const FormStyled = styled(Form)`
  padding: 0% 0% 5% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IoseTextFieldSidebarStyled = styled(IoseTextFieldSidebar).attrs(
  props => ({
    required: true,
  })
)``;

export const Error = styled.span`
  color: red;
  font-weight: bold;
  text-align: center;
`;
