// hooks/useContractEffects.js
import { useContext, useState } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUserAlarms() {
  const [noUsersError, setNoUsersError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUserAlarms = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.obterTodosUsuariosXAlarmes(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
     } while (lastEvaluatedKey)


    if (allData.length === 0) {
      setNoUsersError(Messages.noRegisteredUsers);
      throw new Error(Messages.noRegisteredUsers);
    }
    return allData;
  };

  const {
    data: usersAlarms,
    error: usersAlarmsError,
    refetch: refreshUsersAlarms,
    isLoading,
  } = useQuery(['usersAlarmsData'], getUserAlarms);

  return {
    usersAlarms,
    usersAlarmsError,
    noUsersError,
    refreshUsersAlarms,
    isLoading,
  };
}

export default useUserAlarms;
