import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function IoseBarChartTelemetryComparision({ data }) {
  const [dates, setDates] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    formatChartData(data);
  }, [data]);

  function formatChartData(data) {
    const yAxisData = new Set();
    const seriesData = [];

    const groupedData = data?.reduce((groups, item) => {
      const key = `${item.identification_number}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});

    for (const key in groupedData) {
      const group = groupedData[key];

      group.sort((a, b) => {
        const [monthA, yearA] = a.reference_month.split('/');
        const [monthB, yearB] = b.reference_month.split('/');
        return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
      });

      const ucDataConta = {
        name: `UC ${group[0].identification_number} - CONTA`,
        type: 'bar',
        // Removido a propriedade stack
        emphasis: {
          focus: 'series',
        },
        data: [],
      };

      const ucDataIOSE = {
        name: `UC ${group[0].identification_number} - TELEMETRIA`,
        type: 'bar',
        // Removido a propriedade stack
        emphasis: {
          focus: 'series',
        },
        data: [],
      };

      for (const item of group) {
        const formattedDate = item.reference_month;
        if (item.bill_consumption !== undefined) {
          ucDataConta.data.push(item.bill_consumption);
        } else {
          ucDataConta.data.push(0);
        }
        if (item.measured_consumption !== undefined) {
          ucDataIOSE.data.push(item.measured_consumption);
        } else {
          ucDataIOSE.data.push(0);
        }
        yAxisData.add(formattedDate);
      }

      seriesData.push(ucDataConta);
      seriesData.push(ucDataIOSE);
    }

    const sortedYAxisData = Array.from(yAxisData).sort((a, b) => {
      const [monthA, yearA] = a.split('/');
      const [monthB, yearB] = b.split('/');
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    });

    setDates(sortedYAxisData);
    setSeries(seriesData);

    return { yAxisData: sortedYAxisData, seriesData };
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    // legend: {},
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: dates,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: series,
    // Adicionado um barGap para controlar o espaçamento entre as barras
    barGap: '20%', // Ajuste este valor conforme necessário
  };

  return <ReactECharts option={option} style={{ height: 280 }} />;
}
