import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Modal, Grow } from '@material-ui/core';

import * as S from './style';
import { StyledSelect } from './style';

import {
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseModalButton,
  TextFieldStyled,
} from 'components';
import { useClientData, useCreateLogs, useEnvironments, useUnitys } from 'hooks';
import { AccountContext, ioseAPI } from 'common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: 'white',
    opacity: '70%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseEnvironmentEditModal({
  open,
  onClose,
  loading,
  selectedObject,
}) {
  const classes = useStyles();

  const { getSession } = useContext(AccountContext);
  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { createLog } = useCreateLogs();
  const { refreshEnvironments } = useEnvironments()

  const [notificationType, setNotificationType] = useState();

  async function editEnvironment(
    uuidEnvironment,
    name,
    description,
    uuidUnity,
    id_3d
  ) {

    try {
      const sessionData = await getSession();

      await ioseAPI.updateEnvironment(
        sessionData.headers,
        uuidEnvironment,
        name,
        description,
        uuidUnity,
        id_3d
      );

      setNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR AMBIENTE';
      const logContent = {
        uuid_environment: uuidEnvironment,
        name: name,
        description: description,
        uuid_unity: uuidUnity,
        id_3d: id_3d,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      setNotificationType(2);
    } finally {
      setTimeout(() => {
        onClose();
        refreshEnvironments();
        setNotificationType();
      }, 2500);
    }
  }


  const handleUpdateEnvironment = (values) => {
    setTimeout(() => {
      const uuidEnvironment = selectedObject.uuid_environment;
      const environmentName = values.name;
      const environmentDescription = values.description;

      editEnvironment(
        uuidEnvironment,
        environmentName,
        environmentDescription,
        values.unity,
        values.id_3d
      );
    }, 500);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                AMBIENTES
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                EDITAR AMBIENTE
              </S.ModalTitle>
              <Formik
                initialValues={{
                  unity: selectedObject?.uuid_unity,
                  name: selectedObject?.name,
                  description: selectedObject?.description,
                  id_3d: selectedObject?.id_3d,
                }}
                onSubmit={handleUpdateEnvironment}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="unity-label">
                          Unidade
                        </S.StyledInputLabel>
                        <Field
                          component={StyledSelect}
                          name="unity"
                          labelId="unity-label"
                          id="unity-select"
                          value={selectedObject?.uuid_unity || ''}
                          // className={classes.whiteText}
                          disabled
                          classes={{ disabled: classes.disabledButton }}
                        >
                          {unitys.map((unity) => (
                            <MenuItem
                              key={unity.uuid_unity}
                              value={unity.uuid_unity}
                              className={classes.whiteText}
                            >
                              {unity.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>
                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'name'}
                        name={'name'}
                        type={'text'}
                        label={'Nome'}
                        defaultvalue={initialValues['name']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'description'}
                        name={'description'}
                        type={'text'}
                        label={'Descrição'}
                        defaultvalue={initialValues['description']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'id_3d'}
                        name={'id_3d'}
                        type={'text'}
                        label={'ID 3D'}
                        defaultvalue={initialValues['id_3d']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar Ambiente
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
              {notificationType === 1 && (
                <IoseAlertSuccessModal text="Ambiente atualizado com sucesso." />
              )}

              {notificationType === 2 && (
                <IoseAlertErroModal text="Erro ao editar o ambiente." />
              )}
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
