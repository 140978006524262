import React, { useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination, Tooltip, Button } from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled } from '../style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  addressColumn: {
    minWidth: '150px',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconColumn: {
    minWidth: '40px',
    maxWidth: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableCell} ${className}`}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableHeaderCell} ${className}`}>{children}</TableCell>;
};

const CircuitsTableRow = ({
    value,
    redirectToObject3d
  }) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value.unity_name}</TableCellCentered>
      <TableCellCentered className={classes.addressColumn}>{value.id_3d}</TableCellCentered>
      <TableCellCentered className={classes.iconColumn}>
        <Tooltip title='VISUALIZAR OBJETO 3D'>
          <Button style={{ height: '30px' }} onClick={() => redirectToObject3d(value)}>
            <EqualizerIcon style={{ color: '#015A9E' }}/>
          </Button>
        </Tooltip>
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  onDeleteClick,
  onEditClick,
  redirectToObject3d,
  handleObjectsItem
}) => {

  const classes = useStyles();
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered>
          {/* <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered> */}
          <TableHeaderCellCentered
            className={classes.addressColumn}>LINK 3D</TableHeaderCellCentered>
          <TableHeaderCellCentered></TableHeaderCellCentered>
          {/* <TableHeaderCellCentered></TableHeaderCellCentered>
          <TableHeaderCellCentered></TableHeaderCellCentered> */}
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            redirectToObject3d={redirectToObject3d}
            handleObjectsItem={handleObjectsItem}
            />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function GemeoDigitalMainTable({ loading, data }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const history = useHistory()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function redirectToObject3d(value) {
    history.push({
      pathname: '/3d-viewer',
      state: {
        unityData: value?.unityData
      },
    });
  }

  function renderChartOrErrorMessage() {
    if (data !== undefined && data.length > 0) {
      return (
        <CircuitsTable
          data={data}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          redirectToObject3d={redirectToObject3d}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={data ? parseInt(data.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={650}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>

        </>
      )}
    </div>
  );
}
