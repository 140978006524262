import moment from 'moment';

export function transformContracts(contracts) {
  const today = moment().startOf('day');

  const formattedContracts = contracts?.map((contract) => {
    const finalDate = moment(contract.final_date).startOf('day');
    const initialDate = moment(contract.initial_date).startOf('day');

    const formattedFinalDate = finalDate.format('DD/MM/YYYY');
    const formattedInitialDate = initialDate.format('DD/MM/YYYY');

    const daysDiff = finalDate.diff(today, 'days');

    return {
      ...contract,
      expires_in: daysDiff,
      final_date: formattedFinalDate,
      initial_date: formattedInitialDate,
    };
  });

  return formattedContracts;
}
