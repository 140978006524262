import styled from 'styled-components';
import {
  Typography,
  Grid,
  Card,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import IoseContainer from 'components/IoseContainer';

export const TitleDashboardComponent = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const GridStyledList = styled(Grid)`
  flex: 1;

  @media (max-width: 500px) {
    padding: 5px 0px 5px 0px;
  }
`;

export const GridContainer = styled(Grid)`
  padding: 0px 15px 5px 15px;
  width: 100%;
  height: 93%;
  border: 1px solid red;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const HeaderGridStyled = styled(Grid)`
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FilterContainer = styled(Grid)`
  margin: 0px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const PageTitle = styled.div`
  width: 40%;
`;

export const FilterWrapped = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 25px 0 0;
`;

export const FilterInputsWrapped = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin-left: 10px;
  }
`;

export const StyledContainer = styled(Card)`
  padding: 3%;
  width: 100%;
  height: 100%;
  border: 2px solid red;
`;

export const IoseContainerStyled = styled(IoseContainer)`
  @media (max-width: 500px) {
    padding-right: 20px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  width: 70%;
  padding: 0 20px 0 0;
`;

export const RightContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 120px;

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-root > .Mui-disabled {
    opacity: 50%;
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  @media (max-width: 1400px) {
    width: 70px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`;

export const FormControlStyled = styled(FormControl)`
  width: 120px;

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 1400px) {
    width: 70px;
  }
`;

export const MessageDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
`;

export const MessageTypography = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
  color: var(--alert);
`;
