import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

//Imports from Style
import {
  ListStyled,
  ListItemTextStyled,
  ListItemIconStyled,
  ListItemStyled,
  CheckboxStyled,
  TitleList,
} from './style';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

/**
 This component create a list of circuits checkable.
 */
export default function IoseListCheck(props) {
  const {
    title,
    color,
    array,
    disabledList,
    selectAllInitial,
    selectCircuits,
    height,
    scheduling_form,
  } = props;

  const [checked, setChecked] = useState([]);

  useEffect(() => {
    selectAllInitial && setChecked(array);
    sendSelectCircuitsTocontainer();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array]);

  useEffect(() => {
    sendSelectCircuitsTocontainer();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (array) => intersection(checked, array).length;

  const handleToggleAll = (array) => () => {
    if (numberOfChecked(array) === array.length) {
      setChecked(not(checked, array));
    } else {
      setChecked(union(checked, array));
    }
  };

  function sendSelectCircuitsTocontainer() {
    selectCircuits(checked);
  }

  return (
    <>
      <TitleList variant="body2">{title}</TitleList>
      <ListStyled height={height} color={color}>
        <ListItemStyled
          dense={true}
          button={true}
          onClick={handleToggleAll(array)}
        >
          <ListItemIconStyled>
            <CheckboxStyled
              edge="start"
              onClick={handleToggleAll(array)}
              checked={
                numberOfChecked(array) === array.length && array.length !== 0
              }
              indeterminate={
                numberOfChecked(array) !== array.length &&
                numberOfChecked(array) !== 0
              }
              disabled={array.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          </ListItemIconStyled>
          <ListItemTextStyled
            // id={labelId}
            primary={`Selecionar Todos`}
            color={color}
          />
        </ListItemStyled>

        {array.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItemStyled
              key={value.uuid_circuit}
              dense={true}
              button={true}
              onClick={handleToggle(value)}
              disabled={disabledList}
            >
              <ListItemIconStyled>
                <CheckboxStyled
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple={true}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIconStyled>
              <ListItemTextStyled
                id={labelId}
                primary={` ${
                  scheduling_form ? value.circuit_name : value.name
                }`}
                color={color}
              />
            </ListItemStyled>
          );
        })}
      </ListStyled>
    </>
  );
}

IoseListCheck.propTypes = {
  /** This prop get a title of list */
  title: PropType.string,
  /** This prop get the theme of list white or black */
  color: PropType.string,
  /** This prop get the array of objetc with this formart:
   
     {
      description: "Descrição módulo 2",
      name: "Módulo 2",
      uuid_circuit: "2",
      uuid_group: "a8ee6231-f963-467e-98ea-fc6b55616c4e",
      uuid_unity: "f283ceb6-104b-4526-bf81-3056f9bb9ce1",
    }
  */
  array: PropType.array,
  /** This prop indicate if list will be disable or not*/
  disabledList: PropType.bool,
  /** This prop indicates if all itens will be select in fisrt loading component*/
  selectAllInitial: PropType.bool,
  /** This prop is a function to select all circuits in container*/
  selectCircuits: PropType.func,
  /** This prop set height of component*/
  height: PropType.string,
};
