import { ioseAPI, Messages } from "common";

export async function getBillsByIdentificationNumber(identification_number, sessionData) {
  try {
    // busca faturas da uc
    const response = await ioseAPI.getAllIdentificatioNumberBills(
      sessionData.headers,
      identification_number
    );

    const bills = response.data.data || [];

    if (bills.length === 0) {
      throw new Error(Messages.noRegisteredEnergyBills);
    }
    return bills;
  } catch (error) {
    console.error(error)
  }
};