import { useState, useEffect, useCallback } from 'react';
import { LocalStorage, useClient, Messages } from 'common';

const useClientData = () => {
  const [clientData, setClientData] = useState({});
  const [clientName, setClientName] = useState('');
  const [uuidClient, setUuidClient] = useState('');
  const [group, setGroup] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { client } = useClient();

  const getClientDataByGroup = useCallback(
    async (group) => {
      let clientData = {};

      if (group === 'super') {
        clientData['name'] = client.name;
        clientData['uuid_client'] = client.uuid_client;
      } else {
        clientData = LocalStorage.getClientData();
      }

      return clientData;
    },
    [client?.name, client?.uuid_client]
  );

  const getClientData = useCallback(async () => {
    setError('');
    setLoading(true);

    try {
      const clientData = await getClientDataByGroup(group);
      setClientData(clientData);
      setClientName(clientData.name);
      setUuidClient(clientData.uuid_client);

      const session = LocalStorage.getSession();
      setGroup(session.group);
    } catch (error) {
      console.error(Messages.noRegisteredObjects);
      setError(Messages.noRegisteredObjects);
    } finally {
      setLoading(false);
    }
  }, [getClientDataByGroup, group]);

  useEffect(() => {
    getClientData();
  }, [getClientData]);

  return {
    clientData,
    clientName,
    uuidClient,
    group,
    loading,
    error,
    getClientData,
  };
};

export default useClientData;
