import React, { useContext, useEffect, useState } from 'react';

import {
  IoseAdmDashContainerCircuits,
  // IoseAdmDashContainerSolar,
  IoseAdmDashContainerSwitchboard,
  IoseAdmDashContainerUnity,
  IoseAdmDashSubHeader,
  IoseAlertLoadingCards,
  LoadingIndicatorDashboard,
} from 'components';

import * as S from './style';

import {
  useUnityConsumption,
  useGroupsConsumption,
  useCircuitsConsumption,
  useClientData,
  useCircuits,
  // useSummaryEnergyByEcuList,
  useGroupPermissions,
  useUnitys,
} from 'hooks';

import { ioseIotAPI } from 'common/ioseIotAPI';

import Permissions from 'common/permissions';
import { LocalStorage, AccountContext } from 'common';
import IoseAdmDashContainerGeracao from 'components/DataContainers/IoseAdmDashContainerGeracao';
import AdminDashboardContainerFiltro from './components/adminDashboadContainerFiltro';

export default function AdminDashboardContainer() {
  //eslint-disable-next-line
  const { getSession } = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState(true);

  const { unityConsumption } = useUnityConsumption();

  const { groupsConsumption } = useGroupsConsumption();
  const { circuitsConsumption } = useCircuitsConsumption();
  
  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient)
  const { circuits } = useCircuits(uuidClient); 
  const [consumoCiruito, setConsumoCircuito] = useState([]);
  const [reference_month, setReferenceMonth] = useState()
  const [unityConsumptionFiltrado, SetUnityConsumptionFiltrado] = useState([])
  const [groupsConsumptionFiltrado, SetGroupsConsumptionFiltrado] = useState([])
  const [circuitsConsumptionFiltrado, SetCircuitsConsumptionFiltrado] = useState([])
  // const { energyByEcu } = useSummaryEnergyByEcuList();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['dashboards', 'administrativo', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (circuits !== undefined && circuits.length > 0) {
      prepareData()
    }
  }, [circuits, reference_month])

  useEffect(()=> {
    if(circuitsConsumption !== undefined && circuitsConsumption.length > 0) {
      const filtrado = []
      circuitsConsumption.forEach((unidade) => {
        if (unidade.reference_month === reference_month) {
          filtrado.push(unidade)
        }
      })
      SetCircuitsConsumptionFiltrado(filtrado)
    }
  }, [circuitsConsumption, reference_month])

  useEffect(()=> {
    if(groupsConsumption !== undefined && groupsConsumption.length > 0) {
      const filtrado = []
      groupsConsumption.forEach((unidade) => {
        if (unidade.reference_month === reference_month) {
          filtrado.push(unidade)
        }
      })
      SetGroupsConsumptionFiltrado(filtrado)
    }
  }, [groupsConsumption, reference_month])

  useEffect(()=> {
    if(unityConsumption !== undefined && unityConsumption.length > 0) {
      const filtrado = []
      unityConsumption.forEach((unidade) => {
        if (unidade.reference_month === reference_month) {
          filtrado.push(unidade)
        }
      })
      SetUnityConsumptionFiltrado(filtrado)
    }
  }, [unityConsumption, reference_month])

  function convertToKWH(measuresWatts) {
    const measuresConverted = Number((measuresWatts / 1000).toFixed(2));
    return measuresConverted;
  }

  async function getAllMeasureAllCircuitsRequisition () { 
    const { headers } = await getSession();
    const allDataMeasuresCircuits = [];
    
    const year = reference_month.split('/')[1]; 
    const month = reference_month.split('/')[0] - 1;
    const dataInicial = new Date(year, month, 1);
    
    const dataFinal = new Date(year, month + 1, 0);
    dataFinal.setHours(0,0,0)
    const dateinitial = new Date(Date.parse(dataInicial));
    const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
    const initialDateZeroHour = new Date(
      initialDateHistoricConverted
    ).toISOString();

    const datefinal = new Date(Date.parse(dataFinal));
    const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
    const finalDateZeroHour = new Date(
      finalDateHistoricConverted
    ).toISOString();

    circuits?.forEach((circuit)=> {
      if(circuit.active) {
        const iot = ioseIotAPI.getLastMeasure(
          headers,
          circuit.uuid_circuit,
          initialDateZeroHour,
          finalDateZeroHour,
        )
        allDataMeasuresCircuits.push(iot);
      }
    })  

    return Promise.all(allDataMeasuresCircuits);
    
  }

  async function prepareData() {
    //Ids selected to prepare data
    const promisesMeasures = await getAllMeasureAllCircuitsRequisition()
    let measures =[]
    promisesMeasures.forEach((measure) => {
      measures.push(measure.data || [])
    })
    let id = circuits?.map((circuito) => {
      return circuito.uuid_circuit;
    });
    measures = measures.flat()
    id = id.flat()

    //Array to guard objects json to form DataChartBar
    let chartData = [];
    // let chartDataDaily = [];

    // Select measures according id circuits selecteds
    let selectedMeasures = id.map((idCircuit) => {
      return measures.filter(
        (selected) => selected.data.uuid_circuit === idCircuit
      )[0];
    });

    selectedMeasures = measures
    if (selectedMeasures.length > 0) {
      const arrayDate = selectedMeasures[0].data.measures.dates;

      // Sum all generate data
      let arrayGenerated = selectedMeasures.map(
        (data) => data.data.measures.arrayGenerate
      );
      arrayGenerated = arrayGenerated.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all consumed data
      let arrayConsumed = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumed
      );

      arrayConsumed = arrayConsumed.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all predicted data
      let arrayConsumedPredicted = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumedPredicted
      );
      arrayConsumedPredicted = arrayConsumedPredicted.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Form a object to accumulate mode chart
      for (let i = 0; i < arrayDate.length; i++) {
        let arrayDateConverted = new Date(arrayDate[i]);
        let month = arrayDateConverted.getMonth() + 1;
        let day = arrayDateConverted.getDate();

        const dateFormated = `${day}/${('00' + month).slice(-2)}`;

        chartData.push({
          'date': dateFormated,
          'consumido': convertToKWH(arrayConsumed[i]),
          'gerado': convertToKWH(arrayGenerated[i]),
          'balanço': convertToKWH(arrayConsumed[i] - arrayGenerated[i]),
          'previsao': convertToKWH(arrayConsumedPredicted[i]),
        });
      }
      let chartDataDaily = chartData.map((currentDay, index) => {
        const lastDay = chartData[index - 1];

        const arrayDate = currentDay.date.split('/');
        const defaultData = new Date();
        defaultData.setDate(arrayDate[0]);
        defaultData.setMonth(arrayDate[1] - 1);
        const lessThanToday = defaultData <= new Date();

        let newConsumido = 0;
        let newGenerated = 0;
        let newBalance = 0;

        if (lastDay && lessThanToday) {
          newConsumido = Number(
            (currentDay.consumido - lastDay.consumido).toFixed(2)
          );

          newGenerated = Number(
            (currentDay.gerado - lastDay.gerado).toFixed(2)
          );

          newBalance = Number(
            (currentDay.balanço - lastDay.balanço).toFixed(2)
          );
        } else {
          newConsumido = currentDay.consumido;
          newGenerated = currentDay.gerado;
          newBalance = currentDay.balanço;
        }

        return {
          'date': currentDay.date,
          'consumido': newConsumido,
          'gerado': newGenerated,
          'balanço': newBalance,
          'previsao': 0,
        };
        
      });
      setConsumoCircuito(chartDataDaily)
    }
  }

  const renderSubHeader = () => {
    return (
      <div style={{width: '100%'}}>
        <IoseAdmDashSubHeader />
        <AdminDashboardContainerFiltro 
          setSelectedRefMonth={setReferenceMonth}
        />
      </div>
    )
  };

  // const renderSolarContainer = () => {
  //   return <IoseAdmDashContainerSolar ecuData={energyByEcu} />;
  // };

  const renderGeracaoContainer = () => {
    return <IoseAdmDashContainerGeracao  data={consumoCiruito}/>;
  };

  const renderUnityContainer = () => {
    return <IoseAdmDashContainerUnity data={unityConsumptionFiltrado} />;
  };

  const renderGroupContainer = () => {
    return (
      <IoseAdmDashContainerSwitchboard
        loading={loading}
        allCircuits={circuits}
        data={groupsConsumptionFiltrado}
      />
    );
  };

  const renderCircuitsContainer = () => {
    return (
      <IoseAdmDashContainerCircuits
        loading={loading}
        data={circuitsConsumptionFiltrado}
      />
    );
  };

  return (
    <S.WrapContainer>
      {loading ? (
        <LoadingIndicatorDashboard loading={loading} />
      ) : (
        <S.GridContainer
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <S.GridStyled container direction="row" justify="center">
            {renderSubHeader()}
            {hasReadPermission ? (
              <>
                {renderUnityContainer()}
                {/* {renderSolarContainer()} */}
                {renderGeracaoContainer()}
                {renderGroupContainer()}
                {renderCircuitsContainer()}
              </>
            ) : (
              <IoseAlertLoadingCards
                text={
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                }
              />
            )}
          </S.GridStyled>
        </S.GridContainer>
      )}
    </S.WrapContainer>
  );
}
