import styled, { keyframes } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { TypographyGeneral } from 'components/IoseSubHeaderBar/style';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WarningIcon from '@material-ui/icons/Warning';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

export const GridContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FilterContainer = styled.div`
  /* background-color: white; */
  padding: 10px;
  margin: 5px 0 20px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const FilterBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  animation: ${slideIn} 0.5s ease;

  @media (max-width: 1405px) {
    gap: 5px;
  }
`;

export const OptionsButton = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--secondary);
  animation: ${slideIn} 0.5s ease;
  transition: 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:not(:hover) {
    transform: scale(1);
  }

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

// Filter Cards

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterCardDiv = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  gap: 5px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

export const FilterCardValue = styled.div`
  @media (max-width: 1405px) {
    font-size: 10px;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  transition: 0.5s ease;
  align-items: center;

  &:hover {
    scale: 1.1;
    background-color: #e3dfdf;
  }
`;

export const CloseCardIcon = styled(CloseIcon)`
  color: var(--alert);
  width: 18px;
`;

export const ShowFiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

export const ErrorText = styled.p`
  color: #D60606;
  font-weight: 600;
`

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const Consumo30DiasContainer = styled.div`
  height: 400px;
  grid-column: span 6;
`
export const TelemetryConsumoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 10px;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const VisualizadorTotaisContainer = styled.div`
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
`
export const TotaisDiv = styled.div`
  grid-column: span 2;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const TotaisHeader = styled.div`
  display: flex;
`

export const TotaisTitulo = styled.h1`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary);
`

export const TotaisValor = styled.h3`
  font-size: 25px;
  font-weight: 600;
  font-weight: bold;
  color: var(--primary);
`

export const TotalTelemetriaIcon = styled(FlashOnIcon)`
  color: var(--primary);
  margin-right: 10px;
`

export const TotalAlarmesIcon = styled(WarningIcon)`
  color: var(--primary);
  margin-right: 10px;
`

export const TotalAlarmesMedindoIcon = styled(PowerSettingsNewIcon)`
  color: var(--primary);
  margin-right: 10px;
`

export const TotaisPaper = styled.div`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-left: 2px solid var(--primary);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`

export const StyledHeaderModal = styled.div`
  background-color: var(--secondary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #4a5459;
  /* height: 450px; */
  width: auto;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: black;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  border-radius: 15px;
`;

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 12px;
  font-weight: bold;
  align-items: center;
`;

export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const ModalTitle = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: #fff;
  text-align: center;
`;

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: black;
  margin-top: 6px;
`;

