import styled from 'styled-components';

export const IoseContentArea = styled.div`
  flex-direction: column;
  margin: 0px 0px 0px 30px;
  padding-right: 4px;
  height: 91vh;

  //Iphone 6/7/8
  @media (max-width: 376px) and (max-height: 668px) {
    height: 84vh !important;
  }

  //Iphone 6/7/8 Plus
  @media (min-width: 377px) and (max-width: 415px) and (min-height: 669px) and (max-height: 736px) {
    height: 85vh !important;
  }

  //Iphone X
  @media (max-width: 376px) and (min-height: 812px) {
    height: 87vh !important;
  }

  @media (max-width: 767px) {
    margin: 0px 0px 0px 20px;
  }
`;
