import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Imports from Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, ListItemIcon, Divider } from '@material-ui/core';

//Imports from style
import {
  CardHeaderStyled,
  CardStyled,
  CheckboxStyled,
  ListStyled,
  ButtonStyled,
  ListItemTextStyled,
} from './style';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    marginTop: 10,
  },
  list: {
    width: 250,
    height: 230,
    backgroundColor: '#015A9E',
    overflow: 'auto',
  },
}));

function not(a, b) {
  return a.filter(
    (valueA) =>
      b.findIndex((valueB) => valueB.uuid_circuit === valueA.uuid_circuit) ===
      -1
  );
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function IoseListTransferCircuits({
  choices,
  initialChosen,
  switchboard,
  allGroups,
  setCircuitsSelecteds,
  circuitScheduling,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(choices);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let rightCheckedSwitchboard = rightChecked.filter(
      (circuit) => circuit.uuid_group === switchboard.uuid_group
    );

    setLeft(left.concat(rightCheckedSwitchboard));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <CardStyled>
      <CardHeaderStyled
        avatar={
          <CheckboxStyled
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <ListStyled className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.uuid_circuit}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <CheckboxStyled
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemTextStyled
                id={labelId}
                primary={value.name ? value.name : value.circuit_name}
                secondary={nameSwitchboard(value.uuid_group)}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </ListStyled>
    </CardStyled>
  );

  const nameSwitchboard = (uuid_group) => {
    const nameSwitchboardArray = allGroups.filter(
      (group) => group.uuid_group === uuid_group
    );

    return nameSwitchboardArray[0] ? nameSwitchboardArray[0].name : '';
  };

  useEffect(() => {
    if (circuitScheduling) {
      let circuits = not(choices, right);
      setLeft(not(circuits, circuitScheduling));
    } else {
      setLeft(not(choices, right));
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  useEffect(() => {
    setCircuitsSelecteds(right);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);

  useEffect(() => {
    if (initialChosen) {
      setRight(initialChosen);
      setLeft(not(left, initialChosen));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialChosen]);

  // useEffect(() => {
  //   if (circuitScheduling) {
  //     setLeft(not(choices, circuitScheduling));
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [circuitScheduling]);

  return (
    <Grid
      container
      spacing={0}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList('Circuitos do Quadro Selecionado', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <ButtonStyled
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </ButtonStyled>
          <ButtonStyled
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </ButtonStyled>
        </Grid>
      </Grid>
      <Grid item>{customList('Circuitos para Agendamento', right)}</Grid>
    </Grid>
  );
}

IoseListTransferCircuits.propTypes = {
  /** This props  is circuits choices that user will chose*/
  choices: PropType.array,
  /** This props is the switchboard choosen used to choose which circuits will go to the left */
  switchboard: PropType.object,
  /** This props is all switchboards used to filter the name it */
  allGroups: PropType.array,
  /** This props get is the function to select circuits to form , it comes any form that use it  */
  setCircuitsSelecteds: PropType.func,
};
