export const fetchQuantiadePorGG = async (
  url,
  setTotalGGML,
  setTotalGGND,
  setTotalGGNO,
  setTotalGGSD,
  setTotalGGSO
) => {
  if (!url) {
    return;
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Erro ao buscar dados da URL.');
    }
    const data = await response.json();

    data.features.forEach((feature) => {
      const gg = feature.properties.regiao;
      const qtdGG = feature.properties.quantidade_gg;

      switch (gg) {
        case 'GGML':
          setTotalGGML(qtdGG);
          break;
        case 'GGND':
          setTotalGGND(qtdGG);
          break;
        case 'GGNO':
          setTotalGGNO(qtdGG);
          break;
        case 'GGSD':
          setTotalGGSD(qtdGG);
          break;
        case 'GGSO':
          setTotalGGSO(qtdGG);
          break;
        default:
      }
    });
  } catch (error) {
    console.error('Erro ao buscar quantidade por GG:', error);
  }
};

export const fetchQuantidadePorGR = async (
  url,
  setTotalGDOP,
  setTotalGESA,
  setTotalGGPINF,
  setTotalGIDLD,
  setTotalGPDAGC,
  setTotalGRAP,
  setTotalGRAR,
  setTotalGRCA,
  setTotalGRCM,
  setTotalGRCP,
  setTotalGRFB,
  setTotalGRFI,
  setTotalGRGA,
  setTotalGRLI,
  setTotalGRMA,
  setTotalGRPB,
  setTotalGRPG,
  setTotalGRPV,
  setTotalGRSP,
  setTotalGRTB,
  setTotalGRTO,
  setTotalGRUM,
  setTotalGRUV,
  setTotalGTESG
) => {
  if (!url) {
    return;
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Erro ao buscar dados da URL.');
    }
    const data = await response.json();

    data.features.forEach((feature) => {
      const orgaoPagador = feature.properties.orgao_pagador;
      const qtdGR = feature.properties.quantidade_gr;

      switch (orgaoPagador) {
        case 'GDOP':
          setTotalGDOP(qtdGR);
          break;
        case 'GESA':
          setTotalGESA(qtdGR);
          break;
        case 'GGPINF':
          setTotalGGPINF(qtdGR);
          break;
        case 'GIDLD':
          setTotalGIDLD(qtdGR);
          break;
        case 'GPDAGC':
          setTotalGPDAGC(qtdGR);
          break;
        case 'GRAP':
          setTotalGRAP(qtdGR);
          break;
        case 'GRAR':
          setTotalGRAR(qtdGR);
          break;
        case 'GRCA':
          setTotalGRCA(qtdGR);
          break;
        case 'GRCM':
          setTotalGRCM(qtdGR);
          break;
        case 'GRCP':
          setTotalGRCP(qtdGR);
          break;
        case 'GRFB':
          setTotalGRFB(qtdGR);
          break;
        case 'GRFI':
          setTotalGRFI(qtdGR);
          break;
        case 'GRGA':
          setTotalGRGA(qtdGR);
          break;
        case 'GRLI':
          setTotalGRLI(qtdGR);
          break;
        case 'GRMA':
          setTotalGRMA(qtdGR);
          break;
        case 'GRPB':
          setTotalGRPB(qtdGR);
          break;
        case 'GRPG':
          setTotalGRPG(qtdGR);
          break;
        case 'GRPV':
          setTotalGRPV(qtdGR);
          break;
        case 'GRSP':
          setTotalGRSP(qtdGR);
          break;
        case 'GRTB':
          setTotalGRTB(qtdGR);
          break;
        case 'GRTO':
          setTotalGRTO(qtdGR);
          break;
        case 'GRUM':
          setTotalGRUM(qtdGR);
          break;
        case 'GRUV':
          setTotalGRUV(qtdGR);
          break;
        case 'GTESG':
          setTotalGTESG(qtdGR);
          break;
        default:
      }
    });
  } catch (error) {
    console.error('Erro ao buscar quantidade por GR:', error);
  }
};
