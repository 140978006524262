import styled from 'styled-components';
import { Typography, Grid, Card } from '@material-ui/core';
import IoseContainer from 'components/IoseContainer';
import UnityImage from './img/Unity.svg';
import SwitchboardImage from './img/Switchboard.svg';

export const TitleDashboardComponent = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const GridStyled = styled.div`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const GridStyledList = styled(Grid)`
  flex: 1;
  padding: 5px 10px 5px 0px;

  @media (max-width: 500px) {
    padding: 5px 0px 5px 0px;
  }
`;

export const GridContainer = styled(Grid)`
  padding: 0px 10px 5px 25px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  color: var(--primary);

  h3 {
    margin-left: 10px;
    color: var(--primary);
  }
`;

export const StyledContainer = styled(Card)`
  padding: 3%;
  width: 100%;
  height: 100%;
`;

export const IoseContainerStyled = styled(IoseContainer)`
  @media (max-width: 500px) {
    padding-right: 20px;
  }
`;

export const UnityIcon = styled.img.attrs((props) => ({
  src: UnityImage,
}))`
  width: 30px;
  height: 30px;
`;

export const SwitchboardIcon = styled.img.attrs((props) => ({
  src: SwitchboardImage,
}))`
  width: 20px;
  height: 20px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 350px;
`;

export const LeftContainer = styled.div`
  width: 70%;
  padding: 0 20px 0 0;
`;

export const RightContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
