import React, { useState, useContext } from 'react';
import PropType from 'prop-types';

//Hook to send data throught urls
import { useLocation } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';
import { useClient } from 'common/contexts/client';

//Import from ioseAPI
import { ioseAPI } from 'common/ioseAPI';

//Imports from IOSE Components
import { Container, SideHeader, Icon } from 'components/IoseSideContainer';
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';

//Import from style
import { DivNotifications } from './style';

import { RoutesClientSide } from 'routers';

//Import Client Icon Image
import ClientIcon from './img/enterpriseIcon.svg';

//Import from Messages
import Messages from '../../common/Messages';

/**
 * This component is a container for all side containers
 *  used in super user area and it is used inside the side bar
 */
export default function SidebarClientContainer({ setGetcards, closeSide }) {
  let location = useLocation();
  const { client } = useClient();

  //This function return session data
  const { getSession } = useContext(AccountContext);

  //State to change notification type and your data
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  function getNameClient() {
    if (location.pathname === '/global/super/createclient') {
      return '';
    } else if (client) {
      return client.name;
    }
  }

  //Functions to EDIT Client
  function errorHandlingEditClient(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  function editClient(uuid_client, name, description) {
    setNotificationType(0);
    setLoading(true);
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .updateClient(headers, uuid_client, name, description)
          .then((response) => {
            setLoading(false);
            setNotification(response.data.message);
            setNotificationType(1);
            setGetcards(true);
          })
          .catch((error) => {
            errorHandlingEditClient(error.response.data.message);
          });
      })
      .catch(() => {
        errorHandlingEditClient();
      });
  }

  //Functions to ADD Client
  function errorHandlingAddClient(error) {
    setLoading(false);
    setNotification(error ? error : Messages.clientNoRegistered);
    setNotificationType(2);
  }

  function addClient(name, description) {
    setNotificationType(0);
    setLoading(true);
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .createClient(headers, name, description)
          .then((response) => {
            setLoading(false);
            setNotification(response.data.message);
            setNotificationType(1);
            setGetcards(true);
          })
          .catch((error) => {
            const messageError = error.response
              ? error.response.data.message
              : Messages.clientNoRegistered;
            errorHandlingAddClient(messageError);
          });
      })
      .catch(() => {
        errorHandlingAddClient();
      });
  }

  return (
    <Container>
      <SideHeader>
        <Icon src={ClientIcon} />
        <h2>Cliente {getNameClient()}</h2>
      </SideHeader>

      <DivNotifications>
        {notificationType === 1 && <IoseAlertSuccess text={notification} />}
        {notificationType === 2 && <IoseAlertErro text={notification} />}
      </DivNotifications>

      <LoadingIndicatorSidebar loading={loading} />
      <RoutesClientSide
        addClient={addClient}
        clientname={getNameClient()}
        editClient={editClient}
        notificationType={notificationType}
        notification={notification}
        closeSide={closeSide}
      />
    </Container>
  );
}

SidebarClientContainer.propTypes = {
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
};
