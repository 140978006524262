import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

export const CardGrid = styled(Grid)`
  margin: 0px;
  /* padding: 5px 0px 5px 0px; */
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;

export const CardTitle = styled(Typography)`
  font-size: 13px;
  color: var(--primary);
  font-weight: bold;
`;

export const IconHeader = styled.div`
  margin: 0 8px 8px 0;
`;
export const CardDataKey = styled(Typography)`
  font-size: 12px;
  color: var(--primary);
  font-weight: bold;
  transition: 0.5s ease;
`;

export const CardDataValue = styled(Typography)`
  font-size: 11px;
  font-weight: bold;
  color: var(--primary);
  transition: 0.5s ease;
`;

export const BoxContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 0px;
  padding: 10px 0 0 0px;
  width: 48%;
  height: 180px;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  scale: 1;

  background-color: ${(props) => props.bgColor};
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:not(:hover) {
    transform: scale(0.9);
  }

  &:hover {
    transform: scale(1.1);
    ${CardTitle} {
      color: #fff;
    }
    ${IconHeader} {
      color: #fff;
      font-size: 15px;
    }
    ${CardDataKey} {
      color: #fff;
    }
    ${CardDataValue} {
      color: #fff;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-itens: center;
  margin-left: 15px;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 0 15px;
`;
