import React from 'react';
import { Container, Icon, MiniIcon } from './style';
import PropType from 'prop-types';

/**
 * This component is used to generate a button with to icons this button is used in Dashboard and SuperUser container
 */
function IoseButtonIcon2(props) {
  const { icon1, icon2, left, click } = props;
  return (
    <Container
      left={left}
      badgeContent={<MiniIcon>{icon2}</MiniIcon>}
      onClick={() => click()}
    >
      <Icon src={icon1} />
    </Container>
  );
}

IoseButtonIcon2.propTypes = {
  /** This prop is a src for a icon used in the button*/
  icon1: PropType.string,
  /** This prop is a object form material-ui-icons used in the button */
  icon2: PropType.object,
  /** This prop is used to set a left margin if necessary*/
  left: PropType.string,
  /** This prop is a function to be executed when click in the button*/
  click: PropType.func,
};

export default IoseButtonIcon2;
