import React, { useState } from 'react';
import { IoseEditButton, IoseMoreOptions } from 'components/IoseButtonIcon';
import { ObjectIcon } from 'components/Modals/IoseObjectAddModal/style';
import PropType from 'prop-types';
import { Zoom, Tooltip } from '@material-ui/core';
import {
  CardStyled,
  AvatarStyled,
  CardHeaderStyled,
  CardContentStyled,
  DivHeaderButton,
  DivButtons,
  DivMoreInfo,
  DivInfos,
  DivWrapperInfos,
  PcIcon,
  LaptopIcon,
  AirConditionerIcon,
  CoffeeIcon,
  RefrigeratorIcon,
  WaveIcon,
  ServersItensIcon,
  MonitorIcon,
  TvIcons,
} from './style';

export default function IoseCardObjects({
  uuid_environment,
  name,
  voltage,
  power,
  current,
  temperature,
  condition,
  charge_type,
  environments,
  handleOpenEditModal,
  onClick,
  showEditButton,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
  };

  const objectEnvironment = environments?.find(
    (env) => env.uuid_environment === uuid_environment
  );

  const ObjectsData = [
    { title: 'Ambiente', value: objectEnvironment?.name ?? '' },
    { title: 'Tensão', value: voltage ? voltage + ' V' : 'Não cadastrado' },
    { title: 'Potência', value: power ? power + ' W' : 'Não cadastrado' },
    { title: 'Corrente', value: current ? current + ' A' : 'Não cadastrado' },
    {
      title: 'Temperatura',
      value: temperature ? temperature + ' °C' : 'Não cadastrado',
    },
    {
      title: 'Condição do objeto',
      value: condition ? condition : 'Não cadastrado',
    },
    {
      title: 'Tipo de Carga',
      value: charge_type ? charge_type : 'Não cadastrado',
    },
  ];

  const renderMoreInfoAndButtos = () => {
    return (
      <Zoom
        style={{ transitionDelay: true ? '200ms' : '0ms' }}
        in={true}
        {...{ timeout: 500 }}
      >
        <DivMoreInfo>
          <DivWrapperInfos>
            {ObjectsData.map((info, index) => (
              <DivInfos key={index}>
                <p>
                  <strong>{info.title}: </strong>
                  <span>
                    <i>{info.value}</i>
                  </span>
                </p>
              </DivInfos>
            ))}
          </DivWrapperInfos>

          <DivButtons>
            {showEditButton ? (
              <IoseEditButton
                primary="true"
                card="true"
                tooltip="Editar Objeto"
                onClick={() => handleOpenEditModal()}
              />
            ) : (
              <></>
            )}
          </DivButtons>
        </DivMoreInfo>
      </Zoom>
    );
  };
  const renderTitle = () => (
    <Tooltip title={name} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  const renderIcon = () => {
    let icon;

    switch (true) {
      case name?.toLowerCase().includes('computador'):
      case name?.toLowerCase().includes('pc'):
        icon = <PcIcon />;
        break;

      case name?.toLowerCase().includes('notebook'):
      case name?.toLowerCase().includes('macbook'):
        icon = <LaptopIcon />;
        break;

      case name?.toLowerCase().includes('monitor'):
        icon = <MonitorIcon />;
        break;

      case name?.toLowerCase().includes('tv'):
      case name?.toLowerCase().includes('televisão'):
        icon = <TvIcons />;
        break;

      case name?.toLowerCase().includes('ar condicionado'):
      case name?.toLowerCase().includes('condicionado'):
        icon = <AirConditionerIcon />;
        break;

      case name?.toLowerCase().includes('cafeteira'):
      case name?.toLowerCase().includes('maquina de cafe'):
      case name?.toLowerCase().includes('máquina de café'):
        icon = <CoffeeIcon />;
        break;

      case name?.toLowerCase().includes('geladeira'):
        icon = <RefrigeratorIcon />;
        break;

      case name?.toLowerCase().includes('microondas'):
        icon = <WaveIcon />;
        break;

      case name?.toLowerCase().includes('servidor'):
        icon = <ServersItensIcon />;
        break;

      default:
        icon = <ObjectIcon />;
        break;
    }

    return icon;
  };

  return (
    <Zoom
      in={true}
      style={{ transitionDelay: true ? '300ms' : '0ms' }}
      onClick={handleClick}
    >
      <CardStyled open={moreOptions}>
        <DivHeaderButton disableSpacing>
          <CardHeaderStyled
            avatar={<AvatarStyled>{renderIcon()}</AvatarStyled>}
            title={renderTitle()}
          ></CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </DivHeaderButton>

        <CardContentStyled>
          {moreOptions && renderMoreInfoAndButtos()}
          {/* {renderData()} */}
        </CardContentStyled>
      </CardStyled>
    </Zoom>
  );
}

IoseCardObjects.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
