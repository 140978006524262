import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function transformDateString(inputDate, haveHour=false) {
  const parts = inputDate.split('T')[0].split('-');
  const timeParts = inputDate.split('T')[1] ? inputDate.split('T')[1].split(':') : null;

  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  if (!haveHour){
    return `${day}/${month}/${year}`;
  } else {
    const hour = timeParts[0];
    const minute = timeParts[1];
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
}

function getLocalISODate() {
  const now = new Date();
  const timezoneOffset = now.getTimezoneOffset() * 60000; // converte o deslocamento para milissegundos
  const localISOTime = (new Date(now - timezoneOffset)).toISOString().slice(0, -1);
  return localISOTime;
}

const convertImageToBase64 = (imagePath) => {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

const convertToKWH = (valor) => {
  return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export default async function ConsumoUnidadeIoseReports(
  result,
  initial_date,
  final_date
) {

  const logoData = await convertImageToBase64(Logo);

  function generateReportTitle() {

    return 'Relatório de medição';

  }

  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45],
        },
        {
          text: generateReportTitle(''),
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const tableHead = [
    {
      text: '#',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'ID do circuito',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Data de leitura inicial',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Data de leitura final',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura inicial de consumo (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura final de consumo (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura inicial de geração (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Leitura final de geração (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Consumo(kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Geração (kWh)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Corrente média (A)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Fator de potência',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Tensão média (V)',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
    {
      text: 'Ativo?',
      style: 'tableHeader',
      fontSize: 7,
      alignment: 'center',
      bold: true,
    },
  ]
  const tableBody = []
  tableBody.push(tableHead)
  let numero_circuitos = 0
  let total_consumo = 0
  let total_exportacao = 0
  result.forEach((circuito) =>{
    numero_circuitos += 1
    let corrente_media = 0
    let tensao_media = 0
    const numero_pacotes = circuito.measures.length
    if (numero_pacotes > 0) {
      total_consumo += ((circuito.measures[0].import_active_energy) - (circuito.measures[numero_pacotes -1].import_active_energy))/1000
      total_exportacao += ((circuito.measures[0].export_active_energy) - (circuito.measures[numero_pacotes -1].export_active_energy))/1000
      // eslint-disable-next-line array-callback-return
      circuito.measures.map((measures) => {
        corrente_media += measures.current
        tensao_media += measures.voltage
      })
      tableBody.push([
        {
          text: numero_circuitos,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'center',
        },
        {
          text: circuito.uuid_circuit,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: transformDateString(circuito.measures[numero_pacotes - 1].created_at),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: transformDateString(circuito.measures[0].created_at),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[numero_pacotes - 1].import_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[0].import_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[numero_pacotes -1].export_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[0].export_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[0].import_active_energy/1000 - circuito.measures[numero_pacotes -1].import_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((circuito.measures[0].export_active_energy/1000 - circuito.measures[numero_pacotes -1].export_active_energy/1000)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((corrente_media/numero_pacotes)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: circuito.measures[0].power_factor,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: convertToKWH((tensao_media/numero_pacotes)),
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 'sim',
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
      ])
    } else {
      tableBody.push([
        {
          text: numero_circuitos,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'center',
        },
        {
          text: circuito.uuid_circuit,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 0,
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
        {
          text: 'não',
          fontSize: 7,
          margin: [0, 1, 0, 1],
          alignment: 'left',
        },
      ])
    }

        // [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 0.5] }],

        //

  })

  const combinedTables = [{
    table: {
      headerRows: 1,
      widths: [15, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
      body: [...tableBody]
    },layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex % 2 === 0 ? '#CCCCCC' : null;
      },
    },
  }]

  const currentDate = getLocalISODate()
  const summaryTable = {
    table: {
      headerRows: 1,
      widths: [250],
      body: [
        [
          {
            text: 'Resumo geral de medição',
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
            fillColor: '#CCCCCC',
          },
        ],
        [
          {
            text: `Data de emissão:  ${transformDateString(currentDate, true)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Período:  ${transformDateString(initial_date.toISOString())} a ${transformDateString(final_date.toISOString())}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Número de circuitos:  ${numero_circuitos}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Total consumo kWh:  ${convertToKWH(total_consumo)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
        [
          {
            text: `Total geração kWh:  ${convertToKWH(total_exportacao)}`,
            style: 'tableHeader',
            fontSize: 7,
            alignment: 'left',
            bold: true,
          },
        ],
      ],
    },
    margin: [0, 20, 0, 10],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0],
    };
  };

  const reportContent = [
    summaryTable,
    ...combinedTables,
  ];

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [12, 50, 15, 40],
    content: reportContent,
    footer: renderFooter,
  };


  // Adicionar o título apenas na primeira página
  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
