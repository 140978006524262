import { IoseDownloadButton, IoseMapRedirectButton } from "components/IoseButtonIcon";
import IoseSubHeaderBar from "components/IoseSubHeaderBar";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const classify = (classification) => {
  if (classification.includes('A4') || classification.includes('A3a')) {
    return 'A';
  } else if (classification.includes('B3')) {
    return 'B';
  }
  return '';
}

const handleDownloadEnergyBill = (currentEnergyBill) => {
  const energyBill = currentEnergyBill[0]
  const s3BaseUrl = 'https://iose-prod-pdf-bucket.s3.amazonaws.com/';

  if (energyBill) {
    const concessionaire = energyBill?.concessionaire.toLowerCase();
    const referenceMonth = energyBill?.reference_month.replace('/', '');
    const classification = classify(energyBill?.classification)
    const pdfNumber = energyBill?.pdf_name;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}${classification}/${pdfNumber}`

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};


export default function BillsModalBillSubHeader({ currentEnergyBill, linksACL }) {

  const history = useHistory();

  function redirectToMapPage() {
    history.push('/map');
  }

  const energyBill = currentEnergyBill[0]

  return (
    <>
    <IoseSubHeaderBar
      subtitle={`Detalhamento da fatura de energia da UC ${energyBill?.identification_number}`}
      button={<></>}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '15px',
        }}
      >

      {/*
        <IoseEditBillButton
          tooltip="EDITAR DADOS DO MEDIDOR DA FATURA"
          bottom="5px"
          onClick={() => openEditModal()}
        />

        <IoseEditTaxesBillButton
          tooltip="EDITAR DADOS DOS TRIBUTOS DA FATURA"
          bottom="5px"
          onClick={() => openEditTaxesModal()}
        />

        */}

        <IoseDownloadButton
          tooltip="BAIXAR FATURA"
          bottom="5px"
          onClick={() => handleDownloadEnergyBill(currentEnergyBill)}
        />

        {/* <IoseMapRedirectButton
          tooltip="RETORNAR PARA O MAPA"
          bottom="5px"
          onClick={() => redirectToMapPage()}
        /> */}
      </div>
    </IoseSubHeaderBar>
  </>
  )
}