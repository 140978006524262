// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUcSemCadastro() {
  const [noDataError, setNoDataError] = useState('');

  const { getSession } = useContext(AccountContext);

  const obterUcSemCadastro = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.obterUcSemCadastro(headers);

    const ucSemCadastro = response.data.data || [];

    if (ucSemCadastro.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return ucSemCadastro;
  };

  const {
    data: ucSemCadastro,
    error: ucSemCadastroError,
    refetch: refreshucSemCadastro,
    isLoading,
  } = useQuery(['totalsemcadastro'], obterUcSemCadastro);

  return {
    ucSemCadastro,
    ucSemCadastroError,
    noDataError,
    refreshucSemCadastro,
    isLoading,
  };
}

export default useUcSemCadastro;
