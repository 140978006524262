import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';

//Import from MUI
import {
  Modal,
  MenuItem,
  Slide,
  Grow,
  Typography,
  InputLabel,
} from '@material-ui/core';

//Import from Formik
import { Formik, Form, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';
import validationsForm from 'common/ValidationsForms';

import {
  AtividadeDaUc,
  GeralOptions,
  ModalidadeOptions,
  MonthOptions,
  OptionsBoolean2,
  PostoOptions,
  SimpleOptions,
  SubgrupoOptions,
  TarifaOptions,
  YearOptions,
} from '../../constants';

//Import from styles
import {
  ButtonContainer,
  CircularProgressStyled,
  ContractLink,
  DeleteContractButton,
  DivCircularProgress,
  DivInputs,
  FieldDiv,
  FormConsumerGroupContainer,
  FormContainer,
  FormTariffsContainer,
  IoseTextFieldRequired,
  IoseTextFieldSidebarStyled3,
  ModalTitle,
  ModalTitleHeader,
  ObjectIcon,
  StyledDragContainer,
  StyledField,
  StyledFormControl,
  StyledHeaderModal,
  StyledInputLabel,
  StyledLeftHeaderModal,
  StyledRightHeaderModal,
  StyledSelect,
  StyledTextInputs,
  StyledWrappedModal,
  StyledWrappedTariffsModal,
  TextFieldStyled,
  UploadButton,
  WrappedConsumerGroupItens,
  WrappedItens,
} from './style';

//Import from components
import { IoseModalButton } from 'components/Buttons/IoseButton';
import {
  IoseAlertErroModal,
  IoseAlertSuccessModal,
} from 'components/IoseAlert';
import IoseTextField from 'components/IoseTextField';

//Import from messages
import messages from 'common/Messages';

//Import from constants
import {
  ReadjustmentIndex,
  EnergyMarket,
  ReadjustmentRate,
  TermContract,
  DaysToExpire,
  PrCities,
  GeneralManagement,
  RegionalManagement,
  VariableTypes,
} from '../../constants';

import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import { ContractsIcon } from 'containers/ConctractContainer/style';
import { UploadLoading } from 'components/IoseLoader';

import {
  useComercializadoras,
  useConcessionaires,
  useContracts,
  useCreateLogs,
  useEditConsumerGroup,
  useSystemVariables,
} from 'hooks';
import { useQueryClient } from '@tanstack/react-query';
import AutocompleteField from 'components/AutoCompleteField';

//Styles
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  blackText: {
    color: '#015A9E',
  },
  noMargin: {
    marginTop: '0px !important',
  },
}));

export function IoseAddConsumerGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  selectData,
  contractData,
}) {
  const [selectedConcessionaire, setSelectedConcessionaire] = useState(null);
  const [contractOptions, setContractOptions] = useState();

  const [startValidity, setStartValidity] = useState(new Date());
  const [endValidity, setEndValidity] = useState(new Date());
  const [startTest, setStartTest] = useState(new Date());
  const [endTest, setEndTest] = useState(new Date());
  const [mercadoLivre, setMercadoLivre] = useState();
  const { comercializadorasForSelect } = useComercializadoras();

  useEffect(() => {
    if (contractData) {
      const options = contractData.map((contract) => ({
        value: contract.contract_number,
        label: contract.contract_number,
      }));
      setContractOptions(options);
    }
  }, [contractData]);

  const handleConcessionaireChange = (event) => {
    const selectedConcessionaire = event.target.value;
    setSelectedConcessionaire(selectedConcessionaire);
  };

  const classes = useStyles();

  const handleCreateConsumerGroup = (values) => {
    const {
      identification_number,
      coordinate_x,
      coordinate_y,
      address,
      consumer_group_load,
      operating_unity,
      general_management,
      regional_management,
      city,
      meter,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      geradora,
      beneficiaria,
      potencia_transformador,
      subgrupo,
      modalidade,
      credito_carbono,
      cogeracao,
      telemetria,
      csbio,
      ativo,
      mercado_livre,
      constante,
      reset_relogio,
      demanda_fora,
      demanda_ponta,
      tensao,
      demanda,
      rtc,
      rtp,
      ke,
      pulso_watt,
      numero_telemetria,
      fornecedor_acl,
      boleto_id,
      empresa,
      un_negocio,
      departamento,
      localidade,
      conta_erp,
      contract_number
    } = values;

    const ConsumerGroupLoad = parseFloat(consumer_group_load.replace(',', '.'));
    const tensaoFormatted = parseFloat(tensao.replace(',', '.'));
    const demandaFormatted = parseFloat(demanda.replace(',', '.'));
    const potenciaFormatted = parseFloat(
      potencia_transformador.replace(',', '.')
    );
    const constanteFormatted = parseFloat(constante.replace(',', '.'));
    const demandaForaFormatted = parseFloat(demanda_fora.replace(',', '.'));
    const demandaPontaFormatted = parseFloat(demanda_ponta.replace(',', '.'));
    const keFormatted = parseFloat(ke.replace(',', '.'));
    const pulsoWattFormatted = parseFloat(pulso_watt.replace(',', '.'));

    submit(
      selectedConcessionaire,
      identification_number,
      contract_number,
      operating_unity,
      general_management,
      regional_management,
      coordinate_x,
      coordinate_y,
      address,
      city,
      credito_carbono,
      telemetria,
      meter,
      ConsumerGroupLoad,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      cogeracao,
      csbio,
      geradora,
      beneficiaria,
      potenciaFormatted,
      subgrupo,
      modalidade,
      ativo,
      mercado_livre,
      startValidity,
      endValidity,
      startTest,
      endTest,
      constanteFormatted,
      reset_relogio,
      demandaForaFormatted,
      demandaPontaFormatted,
      tensaoFormatted,
      demandaFormatted,
      rtc,
      rtp,
      keFormatted,
      pulsoWattFormatted,
      numero_telemetria,
      fornecedor_acl,
      boleto_id,
      empresa,
      un_negocio,
      departamento,
      localidade,
      conta_erp
    );
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    identification_number: validationsForm.identificationNumber,
    address: validationsForm.address,
    consumer_group_load: validationsForm.value,
    potencia_transformador: validationsForm.value,
    constante: validationsForm.value,
    reset_relogio: validationsForm.number,
    demanda_fora: validationsForm.value,
    demanda_ponta: validationsForm.value,
    tensao: validationsForm.value,
    rtc: validationsForm.value,
    rtp: validationsForm.value,
    ke: validationsForm.value,
    pulso_watt: validationsForm.value,
    numero_telemetria: validationsForm.number_required,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  concessionaire: '',
                  identification_number: '',
                  coordinate_x: '',
                  coordinate_y: '',
                  address: '',
                  consumer_group_load: '',
                  operating_unity: '',
                  atividade: '',
                  cod_local: '',
                  cod_unidade: '',
                  descricao_unidade: '',
                  cod_orgao_pagador: '',
                  geradora: '',
                  beneficiaria: '',
                  potencia_transformador: '',
                  subgrupo: '',
                  modalidade: '',
                  credito_carbono: '',
                  cogeracao: '',
                  telemetria: '',
                  csbio: '',
                  ativo: '',
                  mercado_livre: '',
                  constante: '',
                  reset_relogio: '',
                  demanda_fora: '',
                  demanda_ponta: '',
                  tensao: '',
                  demanda: '',
                  rtc: '',
                  rtp: '',
                  ke: '',
                  pulso_watt: '',
                  numero_telemetria: '',
                  fornecedor_acl: '',
                  boleto_id: '',
                  empresa: '',
                  un_negocio: '',
                  departamento: '',
                  localidade: '',
                  conta_erp: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={(values) => {
                  handleCreateConsumerGroup(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormConsumerGroupContainer>
                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="concessionaire"
                              className={classes.whiteText}
                            >
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="state"
                              labelId="state-label"
                              id="state-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {selectData?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.uuid_concessionaire}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'identification_number'}
                            name={'identification_number'}
                            type={'text'}
                            label={'UC'}
                            defaultvalue={
                              initialValues['identification_number']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['identification_number'] &&
                              touched['identification_number']
                                ? errors['identification_number']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="general_management"
                              label="Gerência Geral"
                              options={GeneralManagement}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="regional_management"
                              label="Gerência Regional"
                              options={RegionalManagement}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_orgao_pagador'}
                            name={'cod_orgao_pagador'}
                            type={'text'}
                            label={'Cod. Org. Pagador'}
                            defaultvalue={initialValues['cod_orgao_pagador']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_orgao_pagador'] &&
                              touched['cod_orgao_pagador']
                                ? errors['cod_orgao_pagador']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '12px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="city"
                              label="Cidade"
                              options={PrCities}
                              getOptionLabel={(option) => option.nomeCidade}
                              getOptionSelected={(option, value) => option.nomeCidade === value}
                              onChange={handleChange}
                            />
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'address'}
                            name={'address'}
                            type={'text'}
                            label={'Endereço'}
                            defaultvalue={initialValues['address']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['address'] && touched['address']
                                ? errors['address']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="contract_number"
                              label="Contrato"
                              options={contractOptions}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'operating_unity'}
                            name={'operating_unity'}
                            type={'text'}
                            label={'Un. Operacional'}
                            defaultvalue={initialValues['operating_unity']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['operating_unity'] &&
                              touched['operating_unity']
                                ? errors['operating_unity']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'consumer_group_load'}
                            name={'consumer_group_load'}
                            label={'Carga da UC (kWh)'}
                            defaultvalue={initialValues['consumer_group_load']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['consumer_group_load'] &&
                              touched['consumer_group_load']
                                ? errors['consumer_group_load']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '10px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="atividade-label"
                              className={classes.whiteText}
                            >
                              Atividade da UC
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="atividade"
                              labelId="atividade-label"
                              id="atividade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {AtividadeDaUc?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'coordinate_x'}
                            name={'coordinate_x'}
                            type={'text'}
                            label={'Coordenada X'}
                            defaultvalue={initialValues['coordinate_x']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_x'] && touched['coordinate_x']
                                ? errors['coordinate_x']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'coordinate_y'}
                            name={'coordinate_y'}
                            type={'text'}
                            label={'Coordenada Y'}
                            defaultvalue={initialValues['coordinate_y']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_y'] && touched['coordinate_y']
                                ? errors['coordinate_y']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'descricao_unidade'}
                            name={'descricao_unidade'}
                            type={'text'}
                            label={'Desc. Unidade'}
                            defaultvalue={initialValues['descricao_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['descricao_unidade'] &&
                              touched['descricao_unidade']
                                ? errors['descricao_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'meter'}
                            name={'meter'}
                            label={'Medidor'}
                            defaultvalue={initialValues['meter']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['meter'] && touched['meter']
                                ? errors['meter']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_local'}
                            name={'cod_local'}
                            type={'text'}
                            label={'Código do local'}
                            defaultvalue={initialValues['cod_local']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_local'] && touched['cod_local']
                                ? errors['cod_local']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_unidade'}
                            name={'cod_unidade'}
                            type={'text'}
                            label={'Código da unidade'}
                            defaultvalue={initialValues['cod_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_unidade'] && touched['cod_unidade']
                                ? errors['cod_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '7px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="geradora-label"
                              className={classes.whiteText}
                            >
                              É Geradora?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="geradora"
                              labelId="geradora-label"
                              id="geradora-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="beneficiaria-label"
                              className={classes.whiteText}
                            >
                              É Beneficiaria?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="beneficiaria"
                              labelId="beneficiaria-label"
                              id="beneficiaria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'potencia_transformador'}
                            name={'potencia_transformador'}
                            type={'text'}
                            label={'Pot. Transformador'}
                            defaultvalue={
                              initialValues['potencia_transformador']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['potencia_transformador'] &&
                              touched['potencia_transformador']
                                ? errors['potencia_transformador']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '2px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="subgrupo-label"
                              className={classes.whiteText}
                            >
                              Subgrupo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="subgrupo"
                              labelId="subgrupo-label"
                              id="subgrupo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SubgrupoOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '2px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="modalidade-label"
                              className={classes.whiteText}
                            >
                              Modalidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="modalidade"
                              labelId="modalidade-label"
                              id="modalidade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ModalidadeOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="credito_carbono-label"
                              className={classes.whiteText}
                            >
                              Credito de Carbono
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="credito_carbono"
                              labelId="credito_carbono-label"
                              id="credito_carbono-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '8px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="cogeracao-label"
                              className={classes.whiteText}
                            >
                              Cogeração
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="cogeracao"
                              labelId="cogeracao-label"
                              id="cogeracao-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs style={{ marginTop: '8px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="telemetria-label"
                              className={classes.whiteText}
                            >
                              Telemetria
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="telemetria"
                              labelId="telemetria-label"
                              id="telemetria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="csbio-label"
                              className={classes.whiteText}
                            >
                              USBIO
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="csbio"
                              labelId="csbio-label"
                              id="csbio-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="ativo-label"
                              className={classes.whiteText}
                            >
                              Está Ativo?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="ativo"
                              labelId="ativo-label"
                              id="ativo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'constante'}
                            name={'constante'}
                            type={'text'}
                            label={'Constante'}
                            defaultvalue={initialValues['constante']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['constante'] && touched['constante']
                                ? errors['constante']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'reset_relogio'}
                            name={'reset_relogio'}
                            type={'number'}
                            label={'Reset Relógio'}
                            defaultvalue={initialValues['reset_relogio']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['reset_relogio'] &&
                              touched['reset_relogio']
                                ? errors['reset_relogio']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda_fora'}
                            name={'demanda_fora'}
                            type={'text'}
                            label={'Demanda FP'}
                            defaultvalue={initialValues['demanda_fora']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_fora'] && touched['demanda_fora']
                                ? errors['demanda_fora']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda_ponta'}
                            name={'demanda_ponta'}
                            type={'text'}
                            label={'Demanda PTA'}
                            defaultvalue={initialValues['demanda_ponta']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_ponta'] &&
                              touched['demanda_ponta']
                                ? errors['demanda_ponta']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'tensao'}
                            name={'tensao'}
                            type={'text'}
                            label={'Tensão'}
                            defaultvalue={initialValues['tensao']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['tensao'] && touched['tensao']
                                ? errors['tensao']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda'}
                            name={'demanda'}
                            type={'text'}
                            label={'Demanda'}
                            defaultvalue={initialValues['demanda']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda'] && touched['demanda']
                                ? errors['demanda']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'rtc'}
                            name={'rtc'}
                            type={'text'}
                            label={'RTC'}
                            defaultvalue={initialValues['rtc']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['rtc'] && touched['rtc']
                                ? errors['rtc']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'rtp'}
                            name={'rtp'}
                            type={'text'}
                            label={'RTP'}
                            defaultvalue={initialValues['rtp']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['rtp'] && touched['rtp']
                                ? errors['rtp']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'ke'}
                            name={'ke'}
                            type={'text'}
                            label={'Const. elétrica'}
                            defaultvalue={initialValues['ke']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['ke'] && touched['ke'] ? errors['ke'] : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'pulso_watt'}
                            name={'pulso_watt'}
                            type={'text'}
                            label={'Pulso para Watt'}
                            defaultvalue={initialValues['pulso_watt']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['pulso_watt'] && touched['pulso_watt']
                                ? errors['pulso_watt']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'numero_telemetria'}
                            name={'numero_telemetria'}
                            type={'text'}
                            label={'Num. telemetria'}
                            defaultvalue={initialValues['numero_telemetria']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['numero_telemetria'] &&
                              touched['numero_telemetria']
                                ? errors['numero_telemetria']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '4px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="mercado_livre-label"
                              className={classes.whiteText}
                            >
                              É Mercado Livre?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="mercado_livre"
                              labelId="mercado_livre-label"
                              id="mercado_livre-select"
                              onChange={(event) => {
                                handleChange(event);
                                setMercadoLivre(event.target.value);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '4px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="fornecedor_acl-label"
                              className={classes.whiteText}
                            >
                              Comercializadora
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="fornecedor_acl"
                              labelId="fornecedor_acl-label"
                              id="fornecedor_acl-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={false}
                              disabled={mercadoLivre === 'NAO'}
                            >
                              {comercializadorasForSelect?.map((item) => (
                                <MenuItem
                                  key={item.uuid_comercializadoras}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'boleto_id'}
                            name={'boleto_id'}
                            type={'text'}
                            label={'ID ACL'}
                            defaultvalue={initialValues['boleto_id']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['boleto_id'] && touched['boleto_id']
                                ? errors['boleto_id']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>
                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'empresa'}
                            name={'empresa'}
                            type={'text'}
                            label={'Empresa-ERP'}
                            defaultvalue={initialValues['empresa']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['empresa'] && touched['empresa']
                                ? errors['empresa']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'un_negocio'}
                            name={'un_negocio'}
                            type={'text'}
                            label={'Un.negócio-ERP'}
                            defaultvalue={initialValues['un_negocio']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['un_negocio'] && touched['un_negocio']
                                ? errors['un_negocio']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'departamento'}
                            name={'departamento'}
                            type={'text'}
                            label={'Depart-ERP'}
                            defaultvalue={initialValues['departamento']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['departamento'] && touched['departamento']
                                ? errors['departamento']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'localidade'}
                            name={'localidade'}
                            type={'text'}
                            label={'Localidade-ERP'}
                            defaultvalue={initialValues['localidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['localidade'] && touched['localidade']
                                ? errors['localidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'conta_erp'}
                            name={'conta_erp'}
                            type={'text'}
                            label={'Contábil-ERP'}
                            defaultvalue={initialValues['conta_erp']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['conta_erp'] && touched['conta_erp']
                                ? errors['conta_erp']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>
                    </FormConsumerGroupContainer>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                        width: '100%',
                        borderTop: '2px solid #fff',
                        opacity: '.8',
                        padding: '10px 0 10px 0',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início da vigência"
                          getDate={setStartValidity}
                          initialDate={startValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim da vigência"
                          getDate={setEndValidity}
                          initialDate={endValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Início do Teste"
                          getDate={setStartTest}
                          initialDate={startTest}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do Teste"
                          getDate={setEndTest}
                          initialDate={endTest}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Unidade Consumidora
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditConsumerGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  selectedItem,
  getFilteredConsumerGroups,
  lastFilterObject,
}) {
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');

  const [notificationType, setNotificationType] = useState();
  const [loading, setLoading] = useState();
  const [mercadoLivre, setMercadoLivre] = useState();
  const [contractOptions, setContractOptions] = useState();

  console.log('selectedItem:', selectedItem);



  const today = new Date();

  const [startValidity, setStartValidity] = useState(null);
  const [endValidity, setEndValidity] = useState(null);
  const [startTest, setStartTest] = useState(today);
  const [endTest, setEndTest] = useState(today);
  const { contracts } = useContracts();
  const { concessionaires } = useConcessionaires();
  const { createLog } = useCreateLogs();
  const editConsumerGroupFn = useEditConsumerGroup();
  const queryClient = useQueryClient();
  const { comercializadorasForSelect } = useComercializadoras();

  useEffect(() => {
    if (contracts) {
      const options = contracts.map((contract) => ({
        value: contract.contract_number,
        label: contract.contract_number,
      }));
      setContractOptions(options);
    }
  }, [contracts]);

  useEffect(() => {
    setSelectedConcessionaire(selectedItem?.concessionaire);
    setStartValidity(selectedItem?.inicio_vigencia);
    setEndValidity(selectedItem?.fim_vigencia);
    setMercadoLivre(selectedItem?.mercado_livre);

    if (!selectedItem?.inicio_teste || !selectedItem?.fim_teste) {
      setStartTest(today);
      setEndTest(today);
    } else {
      setStartTest(selectedItem?.inicio_teste);
      setEndTest(selectedItem?.fim_teste);
    }
    //eslint-disable-next-line
  }, [selectedItem]);

  const handleConcessionaireChange = (event) => {
    const selectedConcessionaire = event.target.value;
    setSelectedConcessionaire(selectedConcessionaire);
  };

  const classes = useStyles();

  const handleEditConsumerGroup = (values) => {
    setLoading(true);
    const {
      identification_number,
      coordinate_x,
      coordinate_y,
      address,
      consumer_group_load,
      operating_unity,
      general_management,
      regional_management,
      city,
      meter,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      geradora,
      beneficiaria,
      potencia_transformador,
      subgrupo,
      modalidade,
      credito_carbono,
      cogeracao,
      telemetria,
      csbio,
      ativo,
      mercado_livre,
      constante,
      relogio_reset,
      demanda_fora,
      demanda_ponta,
      tensao,
      demanda,
      rtc,
      rtp,
      ke,
      pulso_watt,
      numero_telemetria,
      fornecedor_acl,
      boleto_id,
      empresa,
      un_negocio,
      departamento,
      localidade,
      conta_erp,
      contract
    } = values;

    const ConsumerGroupLoad = parseFloat(
      consumer_group_load?.replace(',', '.')
    );
    const potenciaFormatted = parseFloat(
      potencia_transformador.replace(',', '.')
    );
    const constanteFormatted = parseFloat(constante?.replace(',', '.'));
    const demandaForaFormatted = parseFloat(demanda_fora?.replace(',', '.'));
    const demandaPontaFormatted = parseFloat(demanda_ponta?.replace(',', '.'));
    const demandaFormatted = parseFloat(demanda?.replace(',', '.'));
    const keFormatted = parseFloat(ke.replace(',', '.'));
    const pulsoWattFormatted = parseFloat(pulso_watt.replace(',', '.'));

    editConsumerGroupFn.mutate(
      {
        uuid_consumer_group: selectedItem.uuid_consumer_group,
        concessionaire: selectedConcessionaire,
        identification_number: identification_number,
        contract: contract,
        operating_unity: operating_unity,
        general_management: general_management,
        regional_management: regional_management,
        coordinate_x: coordinate_x,
        coordinate_y: coordinate_y,
        address: address,
        city: city,
        carbon_credit: credito_carbono,
        telemetry: telemetria,
        meter: meter,
        consumer_group_load: ConsumerGroupLoad,
        atividade: atividade,
        cod_local: cod_local,
        cod_unidade: cod_unidade,
        descricao_unidade: descricao_unidade,
        cod_orgao_pagador: cod_orgao_pagador,
        cogeracao: cogeracao,
        csbio: csbio,
        geradora: geradora,
        beneficiaria: beneficiaria,
        potencia_transformador: potenciaFormatted,
        subgrupo: subgrupo,
        modalidade: modalidade,
        ativo: ativo,
        mercado_livre: mercado_livre,
        inicio_vigencia: startValidity,
        fim_vigencia: endValidity,
        inicio_teste: startTest,
        fim_teste: endTest,
        constante: constanteFormatted,
        relogio_reset: relogio_reset,
        demanda_fora: demandaForaFormatted,
        demanda_ponta: demandaPontaFormatted,
        tensao: tensao,
        demanda: demandaFormatted,
        rtc: rtc,
        rtp: rtp,
        ke: keFormatted,
        pulso_watt: pulsoWattFormatted,
        numero_telemetria: numero_telemetria,
        fornecedor_acl: fornecedor_acl,
        boleto_id: boleto_id,
        empresa: empresa,
        un_negocio: un_negocio,
        departamento: departamento,
        localidade: localidade,
        conta_erp: conta_erp,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setNotificationType(1);

          const cached = queryClient.getQueryData(['consumerGroupData']);
          if (cached) {
            queryClient.setQueryData(['consumerGroupData'], {
              ...cached,
              ...data,
            });
          }

          setTimeout(() => {
            onClose();
            setNotificationType();
            getFilteredConsumerGroups(lastFilterObject);
            // getAll()
            // filter([])
          }, 2000);
        },

        onError: () => {
          setLoading(false);
          setNotificationType(2);
          setTimeout(() => {
            setNotificationType();
          }, 2000);
        },
      }
    );

    const operacao = 'EDITAR';
    const form = 'EDITAR UNIDADE CONSUMIDORA';
    const logContent = {
      uuid_consumer_group: selectedItem.uuid_consumer_group,
      concessionaire: selectedConcessionaire,
      identification_number: identification_number,
      contract: contract,
      operating_unity: operating_unity,
      general_management: general_management,
      regional_management: regional_management,
      coordinate_x: coordinate_x,
      coordinate_y: coordinate_y,
      address: address,
      city: city,
      carbon_credit: credito_carbono,
      telemetry: telemetria,
      meter: meter,
      consumer_group_load: consumer_group_load,
      atividade: atividade,
      cod_local: cod_local,
      cod_unidade: cod_unidade,
      descricao_unidade: descricao_unidade,
      cod_orgao_pagador: cod_orgao_pagador,
      cogeracao: cogeracao,
      csbio: csbio,
      geradora: geradora,
      beneficiaria: beneficiaria,
      potencia_transformador: potencia_transformador,
      subgrupo: subgrupo,
      modalidade: modalidade,
      constante: constante,
      relogio_reset: relogio_reset,
      demanda_fora: demanda_fora,
      demanda_ponta: demanda_ponta,
      tensao: tensao,
      demanda: demanda,
      rtc: rtc,
      rtp: rtp,
      ke: ke,
      pulso_watt: pulso_watt,
      numero_telemetria: numero_telemetria,
      fornecedor_acl: fornecedor_acl,
      boleto_id: boleto_id,
      empresa: empresa,
      un_negocio: un_negocio,
      departamento: departamento,
      localidade: localidade,
      conta_erp: conta_erp,
    };

    createLog(operacao, form, logContent);
  };

  const formattedConsumerGroupLoad = selectedItem?.consumer_group_load
    ?.toString()
    .replace('.', ',');

  const formattedPotencia = selectedItem?.potencia_transformador
    ?.toString()
    .replace('.', ',');

  const formattedConstante = selectedItem?.constante
    ?.toString()
    .replace('.', ',');

  const formattedDemandaFora = selectedItem?.demanda_fora
    ?.toString()
    .replace('.', ',');

  const formattedDemandaPonta = selectedItem?.demanda_ponta
    ?.toString()
    .replace('.', ',');

  const formattedTensao = selectedItem?.tensao?.toString().replace('.', ',');

  const formattedDemanda = selectedItem?.demanda?.toString().replace('.', ',');
  const formattedKe = selectedItem?.ke?.toString().replace('.', ',');
  const formattedPulso = selectedItem?.pulso_watt?.toString().replace('.', ',');

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    identification_number: validationsForm.identificationNumber,
    address: validationsForm.address,
    consumer_group_load: validationsForm.value,
    meter: validationsForm.required,
    constante: validationsForm.value,
    relogio_reset: validationsForm.number,
    demanda_fora: validationsForm.value,
    demanda_ponta: validationsForm.value,
    rtc: validationsForm.value,
    rtp: validationsForm.value,
    ke: validationsForm.value,
    pulso_watt: validationsForm.value,
    numero_telemetria: validationsForm.number_required,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  concessionaire: selectedItem?.concessionaire,
                  identification_number: selectedItem?.identification_number,
                  contract: selectedItem?.contract,
                  operating_unity: selectedItem?.operating_unity,
                  general_management: selectedItem?.general_management,
                  regional_management: selectedItem?.regional_management,
                  coordinate_x: selectedItem?.coordinate_x,
                  coordinate_y: selectedItem?.coordinate_y,
                  address: selectedItem?.address,
                  city: selectedItem?.city,
                  credito_carbono: selectedItem?.carbon_credit,
                  telemetria: selectedItem?.telemetry,
                  meter: selectedItem?.meter,
                  consumer_group_load: formattedConsumerGroupLoad,
                  atividade: selectedItem?.atividade,
                  cod_local: selectedItem?.cod_local,
                  cod_unidade: selectedItem?.cod_unidade,
                  descricao_unidade: selectedItem?.descricao_unidade,
                  cod_orgao_pagador: selectedItem?.cod_orgao_pagador,
                  cogeracao: selectedItem?.cogeracao,
                  csbio: selectedItem?.csbio,
                  geradora: selectedItem?.geradora,
                  beneficiaria: selectedItem?.beneficiaria,
                  potencia_transformador: formattedPotencia,
                  subgrupo: selectedItem?.subgrupo,
                  modalidade: selectedItem?.modalidade,
                  ativo: selectedItem?.ativo,
                  mercado_livre: selectedItem?.mercado_livre,
                  constante: formattedConstante,
                  relogio_reset: selectedItem?.relogio_reset,
                  demanda_fora: formattedDemandaFora,
                  demanda_ponta: formattedDemandaPonta,
                  tensao: formattedTensao,
                  demanda: formattedDemanda,
                  rtc: selectedItem?.rtc,
                  rtp: selectedItem?.rtp,
                  ke: formattedKe,
                  pulso_watt: formattedPulso,
                  numero_telemetria: selectedItem?.numero_telemetria,
                  fornecedor_acl: selectedItem?.fornecedor_acl
                    ? selectedItem?.fornecedor_acl
                    : '',
                  boleto_id: selectedItem?.boleto_id
                    ? selectedItem?.boleto_id
                    : '',
                  empresa: selectedItem?.empresa,
                  un_negocio: selectedItem?.un_negocio,
                  departamento: selectedItem?.departamento,
                  localidade: selectedItem?.localidade,
                  conta_erp: selectedItem?.conta_erp,
                }}
                validationSchema={ValidantionAdd}
                onSubmit={(values) => {
                  handleEditConsumerGroup(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                  setFieldValue,
                  values,
                }) => (
                  <Form>
                    <FormConsumerGroupContainer>
                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="concessionaire"
                              className={classes.whiteText}
                            >
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {concessionaires?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.name}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'identification_number'}
                            name={'identification_number'}
                            type={'text'}
                            label={'UC'}
                            defaultvalue={
                              initialValues['identification_number']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['identification_number'] &&
                              touched['identification_number']
                                ? errors['identification_number']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="general_management"
                              label="Gerência Geral"
                              options={GeneralManagement}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="regional_management"
                              label="Gerência Regional"
                              options={RegionalManagement}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_orgao_pagador'}
                            name={'cod_orgao_pagador'}
                            type={'text'}
                            label={'Cod. Org. Pagador'}
                            defaultvalue={initialValues['cod_orgao_pagador']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_orgao_pagador'] &&
                              touched['cod_orgao_pagador']
                                ? errors['cod_orgao_pagador']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '12px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="city"
                              label="Cidade"
                              options={PrCities}
                              getOptionLabel={(option) => option.nomeCidade}
                              getOptionSelected={(option, value) => option.nomeCidade === value}
                              onChange={handleChange}
                            />
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'address'}
                            name={'address'}
                            type={'text'}
                            label={'Endereço'}
                            defaultvalue={initialValues['address']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['address'] && touched['address']
                                ? errors['address']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <AutocompleteField
                              name="contract"
                              label="Contrato"
                              options={contractOptions}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) => option.value === value}
                              className={classes.whiteText}
                              required={true}
                            />
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'operating_unity'}
                            name={'operating_unity'}
                            type={'text'}
                            label={'Un. Operacional'}
                            defaultvalue={initialValues['operating_unity']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['operating_unity'] &&
                              touched['operating_unity']
                                ? errors['operating_unity']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'consumer_group_load'}
                            name={'consumer_group_load'}
                            label={'Carga da UC (kWh)'}
                            defaultvalue={initialValues['consumer_group_load']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['consumer_group_load'] &&
                              touched['consumer_group_load']
                                ? errors['consumer_group_load']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="atividade-label"
                              className={classes.whiteText}
                            >
                              Atividade da UC
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="atividade"
                              labelId="atividade-label"
                              id="atividade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {AtividadeDaUc?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'coordinate_x'}
                            name={'coordinate_x'}
                            type={'text'}
                            label={'Coordenada X'}
                            defaultvalue={initialValues['coordinate_x']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_x'] && touched['coordinate_x']
                                ? errors['coordinate_x']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'coordinate_y'}
                            name={'coordinate_y'}
                            type={'text'}
                            label={'Coordenada Y'}
                            defaultvalue={initialValues['coordinate_y']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_y'] && touched['coordinate_y']
                                ? errors['coordinate_y']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'descricao_unidade'}
                            name={'descricao_unidade'}
                            label={'Desc. Unidade'}
                            defaultvalue={initialValues['descricao_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['descricao_unidade'] &&
                              touched['descricao_unidade']
                                ? errors['descricao_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'meter'}
                            name={'meter'}
                            label={'Medidor'}
                            defaultvalue={initialValues['meter']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['meter'] && touched['meter']
                                ? errors['meter']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_local'}
                            name={'cod_local'}
                            label={'Código do local'}
                            defaultvalue={initialValues['cod_local']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_local'] && touched['cod_local']
                                ? errors['cod_local']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'cod_unidade'}
                            name={'cod_unidade'}
                            label={'Código da unidade'}
                            defaultvalue={initialValues['cod_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_unidade'] && touched['cod_unidade']
                                ? errors['cod_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="geradora-label"
                              className={classes.whiteText}
                            >
                              É Geradora?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="geradora"
                              labelId="geradora-label"
                              id="geradora-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="beneficiaria-label"
                              className={classes.whiteText}
                            >
                              É Beneficiaria?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="beneficiaria"
                              labelId="beneficiaria-label"
                              id="beneficiaria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'potencia_transformador'}
                            name={'potencia_transformador'}
                            label={'Pot. Transformador'}
                            defaultvalue={
                              initialValues['potencia_transformador']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['potencia_transformador'] &&
                              touched['potencia_transformador']
                                ? errors['potencia_transformador']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="subgrupo-label"
                              className={classes.whiteText}
                            >
                              Subgrupo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="subgrupo"
                              labelId="subgrupo-label"
                              id="subgrupo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SubgrupoOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="modalidade-label"
                              className={classes.whiteText}
                            >
                              Modalidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="modalidade"
                              labelId="modalidade-label"
                              id="modalidade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ModalidadeOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '8px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="credito_carbono-label"
                              className={classes.whiteText}
                            >
                              Crédito de Carbono
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="credito_carbono"
                              labelId="credito_carbono-label"
                              id="credito_carbono-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '5px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="cogeracao-label"
                              className={classes.whiteText}
                            >
                              Cogeração
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="cogeracao"
                              labelId="cogeracao-label"
                              id="cogeracao-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="telemetria-label"
                              className={classes.whiteText}
                            >
                              Telemetria
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="telemetria"
                              labelId="telemetria-label"
                              id="telemetria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="csbio-label"
                              className={classes.whiteText}
                            >
                              USBIO
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="csbio"
                              labelId="csbio-label"
                              id="csbio-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="ativo-label"
                              className={classes.whiteText}
                            >
                              Está Ativo?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="ativo"
                              labelId="ativo-label"
                              id="ativo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'constante'}
                            name={'constante'}
                            type={'text'}
                            label={'Constante'}
                            defaultvalue={initialValues['constante']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['constante'] && touched['constante']
                                ? errors['constante']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'relogio_reset'}
                            name={'relogio_reset'}
                            type={'number'}
                            label={'Reset Relógio'}
                            defaultvalue={initialValues['relogio_reset']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['relogio_reset'] &&
                              touched['relogio_reset']
                                ? errors['relogio_reset']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda_fora'}
                            name={'demanda_fora'}
                            type={'text'}
                            label={'Demanda FP'}
                            defaultvalue={initialValues['demanda_fora']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_fora'] && touched['demanda_fora']
                                ? errors['demanda_fora']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda_ponta'}
                            name={'demanda_ponta'}
                            type={'text'}
                            label={'Demanda PTA'}
                            defaultvalue={initialValues['demanda_ponta']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_ponta'] &&
                              touched['demanda_ponta']
                                ? errors['demanda_ponta']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'tensao'}
                            name={'tensao'}
                            type={'text'}
                            label={'Tensão'}
                            defaultvalue={initialValues['tensao']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['tensao'] && touched['tensao']
                                ? errors['tensao']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'demanda'}
                            name={'demanda'}
                            type={'text'}
                            label={'Demanda'}
                            defaultvalue={initialValues['demanda']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda'] && touched['demanda']
                                ? errors['demanda']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'rtc'}
                            name={'rtc'}
                            type={'text'}
                            label={'RTC'}
                            defaultvalue={initialValues['rtc']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['rtc'] && touched['rtc']
                                ? errors['rtc']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'rtp'}
                            name={'rtp'}
                            type={'text'}
                            label={'RTP'}
                            defaultvalue={initialValues['rtp']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['rtp'] && touched['rtp']
                                ? errors['rtp']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'ke'}
                            name={'ke'}
                            type={'text'}
                            label={'Const. elétrica'}
                            defaultvalue={initialValues['ke']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['ke'] && touched['ke'] ? errors['ke'] : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'pulso_watt'}
                            name={'pulso_watt'}
                            type={'text'}
                            label={'Pulso para Watt'}
                            defaultvalue={initialValues['pulso_watt']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['pulso_watt'] && touched['pulso_watt']
                                ? errors['pulso_watt']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'numero_telemetria'}
                            name={'numero_telemetria'}
                            type={'text'}
                            label={'Num. telemetria'}
                            defaultvalue={initialValues['numero_telemetria']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['numero_telemetria'] &&
                              touched['numero_telemetria']
                                ? errors['numero_telemetria']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs style={{ marginTop: '4px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="mercado_livre-label"
                              className={classes.whiteText}
                            >
                              É Mercado Livre?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="mercado_livre"
                              labelId="mercado_livre-label"
                              id="mercado_livre-select"
                              onChange={(event) => {
                                handleChange(event);
                                setMercadoLivre(event.target.value);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '4px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="fornecedor_acl-label"
                              className={classes.whiteText}
                            >
                              Comercializadora
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="fornecedor_acl"
                              labelId="fornecedor_acl-label"
                              id="fornecedor_acl-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={false}
                              disabled={mercadoLivre === 'NAO'}
                            >
                              <MenuItem value="" style={{ color: 'red' }}>
                                Remover
                              </MenuItem>

                              {comercializadorasForSelect?.map((item) => (
                                <MenuItem
                                  key={item.uuid_comercializadoras}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'boleto_id'}
                            name={'boleto_id'}
                            type={'text'}
                            label={'ID ACL'}
                            defaultvalue={initialValues['boleto_id']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['boleto_id'] && touched['boleto_id']
                                ? errors['boleto_id']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'empresa'}
                            name={'empresa'}
                            type={'text'}
                            label={'Empresa-ERP'}
                            defaultvalue={initialValues['empresa']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['empresa'] && touched['empresa']
                                ? errors['empresa']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'un_negocio'}
                            name={'un_negocio'}
                            type={'text'}
                            label={'Un.negócio-ERP'}
                            defaultvalue={initialValues['un_negocio']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['un_negocio'] && touched['un_negocio']
                                ? errors['un_negocio']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'departamento'}
                            name={'departamento'}
                            type={'text'}
                            label={'Depart-ERP'}
                            defaultvalue={initialValues['departamento']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['departamento'] && touched['departamento']
                                ? errors['departamento']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'localidade'}
                            name={'localidade'}
                            type={'text'}
                            label={'Localidade-ERP'}
                            defaultvalue={initialValues['localidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['localidade'] && touched['localidade']
                                ? errors['localidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldRequired}
                            fullWidth
                            id={'conta_erp'}
                            name={'conta_erp'}
                            type={'text'}
                            label={'Contábil-ERP'}
                            defaultvalue={initialValues['conta_erp']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            // disabled={mercadoLivre === 'NAO'}
                            helpertext={
                              errors['conta_erp'] && touched['conta_erp']
                                ? errors['conta_erp']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>
                    </FormConsumerGroupContainer>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                        width: '100%',
                        borderTop: '2px solid #fff',
                        opacity: '.8',
                        padding: '10px 0 10px 0',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início da vigência"
                          getDate={setStartValidity}
                          initialDate={startValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim da vigência"
                          getDate={setEndValidity}
                          initialDate={endValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Início do Teste"
                          getDate={setStartTest}
                          initialDate={startTest}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do Teste"
                          getDate={setEndTest}
                          initialDate={endTest}
                          margin={'0px'}
                        />
                      </div>
                    </div>
                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : (
                      <>
                        {notificationType === 1 ? (
                          <IoseAlertSuccessModal text="Unidade Consumidora atualizada com sucesso." />
                        ) : notificationType === 2 ? (
                          <IoseAlertErroModal text="Erro ao editar unidade consumidora." />
                        ) : (
                          <ButtonContainer>
                            <IoseModalButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              theme="white"
                              fullWidth
                              disabled={isSubmitting}
                            >
                              Editar Unidade Consumidora
                            </IoseModalButton>
                          </ButtonContainer>
                        )}
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export default function IoseConsumerGroupDeleteModal({
  open,
  onClose,
  loading,
  notificationType,
  deleteNotification,
  showNotification,
  submit,
  selectedItem,
  modalHeaderTitle,
  modalTitle,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);

  const handleConfirmation = () => {
    if (confirmation === selectedItem?.identification_number) {
      submit(selectedItem.uuid_consumer_group);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom marginBottom="35px">
                {modalTitle}
              </ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar a unidade consumidora{' '}
                <b>{selectedItem?.identification_number}</b>?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite <b>{selectedItem?.identification_number}</b>{' '}
                logo abaixo e aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <DivCircularProgress>
                  <CircularProgressStyled />
                </DivCircularProgress>
              ) : showNotification ? (
                <>
                  {notificationType === 1 && (
                    <IoseAlertSuccessModal text={deleteNotification} />
                  )}
                  {notificationType === 2 && (
                    <IoseAlertErroModal text={deleteNotification} />
                  )}
                  {showError && (
                    <IoseAlertErroModal
                      text={messages.confirmationTextIncorrect}
                    />
                  )}
                </>
              ) : showError ? (
                <IoseAlertErroModal text="Texto de confirmação incorreto." />
              ) : (
                <ButtonContainer>
                  <IoseModalButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    theme="white"
                    fullWidth
                    onClick={handleConfirmation}
                  >
                    Deletar
                  </IoseModalButton>
                </ButtonContainer>
              )}
            </div>
          </StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}

export function IoseAddContractsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  concessionaires,
  uploadFile,
}) {
  //Select States
  const [marketType, setMarketType] = useState(null);
  const [concessionaire, setConcessionaire] = useState(null);
  const [termOfContract, setTermOfContract] = useState(null);
  const [readjustmentIndex, setReadjustmentIndex] = useState('');
  const [readjustmentRate, setReadjustmentRate] = useState('');
  const [daysExpire, setDaysExpire] = useState('');
  const [s3Link, setS3Link] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  function handleMarketTypeChange(event) {
    const marketType = event.target;
    setMarketType(marketType);
  }

  function handleConcessionaireChange(event) {
    const concessionaire = event.target;
    setConcessionaire(concessionaire);
  }

  function handletermOfContractChange(event) {
    const termOfContract = event.target;
    setTermOfContract(termOfContract);
  }

  function handlereadjustmentIndexChange(event) {
    const ReadjustmentIndex = event.target;
    setReadjustmentIndex(ReadjustmentIndex);
  }

  function handlereadjustmentRateChange(event) {
    const ReadjustmentRate = event.target;
    setReadjustmentRate(ReadjustmentRate);
  }

  function handleDaysExpireChange(event) {
    const daysExpire = event.target;
    setDaysExpire(daysExpire);
  }

  const labelsTextsContracts = [
    { label: 'Número do Contrato', variable: 'contractNumber' },
    { label: 'Valor total do contrato', variable: 'totalContracts' },
    { label: 'Valor mensal', variable: 'monthlyValue' },
    { label: 'Observação', variable: 'note' },
  ];

  const HandleFileUpload = () => {
    //eslint-disable-next-line
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLink, setFileLink] = useState(null);
    //eslint-disable-next-line
    const [fileCount, setFileCount] = useState(0);
    const [uploadLoading, setUploadLoading] = useState(false);

    const handleUpload = async (file) => {
      setUploadLoading(true);
      try {
        const response = await uploadFile(file);
        setFileLink(response.fileUrl);
        setS3Link(response.fileUrl);
        setIsFileUploaded(true);
        setUploadLoading(false);
      } catch (err) {
        console.error(err);
        setUploadLoading(false);
      }
    };

    const onDrop = (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        setSelectedFile(acceptedFiles[0]);
        handleUpload(acceptedFiles[0]);
      } else {
        setSelectedFile(null);
        console.error('Apenas 1 arquivo pode ser selecionado por contrato.');
      }
      setFileCount(acceptedFiles.length);
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragReject,
    } = useDropzone({
      onDrop,
      accept: 'application/pdf, image/png, image/jpeg',
    });

    return (
      <StyledDragContainer style={{ marginTop: '30px' }}>
        {uploadLoading ? (
          <DivCircularProgress>
            <UploadLoading loading={uploadLoading} color="#015A9E" />
          </DivCircularProgress>
        ) : fileLink ? (
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: '13px' }}>Upload realizado com sucesso!</p>
          </div>
        ) : (
          <div
            {...getRootProps()}
            style={{
              border: isDragActive ? '2px dashed green' : '2px dashed gray',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Solte o arquivo aqui...</p>
            ) : isDragReject ? (
              <p>Arquivo não suportado</p>
            ) : (
              <p>
                Arraste e solte o arquivo do contrato ou clique para selecionar{' '}
                <br />
                (Apenas 1 arquivo por contrato)
              </p>
            )}
          </div>
        )}
      </StyledDragContainer>
    );
  };

  const classes = useStyles();

  const handleCreateContracts = async (values) => {
    if (isFileUploaded) {
      const monthlyValue = parseFloat(values.monthlyValue.replace(',', '.'));
      const totalContracts = parseFloat(
        values.totalContracts.replace(',', '.')
      );

      // const formatDate = (dateString) => {
      //   const date = new Date(dateString);
      //   const day = date.getUTCDate();
      //   const month = date.getUTCMonth() + 1;
      //   const year = date.getUTCFullYear();

      //   const formattedDay = day < 10 ? `0${day}` : day;
      //   const formattedMonth = month < 10 ? `0${month}` : month;

      //   return `${formattedDay}/${formattedMonth}/${year}`;
      // };

      // const formattedInitialDate = formatDate(initialDate);
      // const formattedFinalDate = formatDate(finalDate);

      const data = {
        contractNumber: values.contractNumber,
        marketType: marketType.value,
        concessionaire: concessionaire.value,
        termOfContract: termOfContract.value,
        readjustmentIndex: readjustmentIndex.value,
        readjustmentRate: readjustmentRate.value,
        totalContracts: totalContracts,
        monthlyValue: monthlyValue,
        note: values.note,
        daysExpire: daysExpire.value,
      };

      submit(
        data.contractNumber,
        data.marketType,
        data.concessionaire,
        data.termOfContract,
        data.readjustmentIndex,
        data.readjustmentRate,
        data.totalContracts,
        data.monthlyValue,
        data.note,
        data.daysExpire,
        initialDate,
        finalDate,
        s3Link
      );
    }
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    contractNumber: validationsForm.number,
    totalContracts: validationsForm.value,
    monthlyValue: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  contractNumber: '',
                  totalContracts: '',
                  monthlyValue: '',
                  note: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateContracts}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="type_market">
                              Tipo de Mercado
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="type_market"
                              labelId="type_market-label"
                              id="type_market-select"
                              onChange={handleMarketTypeChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {EnergyMarket?.map((type) => (
                                <MenuItem key={type.name} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="concessionaire">
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {concessionaires?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.name}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="term_contract">
                              Vigência do contrato
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="term_contract"
                              labelId="term_contract-label"
                              id="term_contract-select"
                              onChange={handletermOfContractChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {TermContract?.map((term) => (
                                <MenuItem key={term.name} value={term.value}>
                                  {term.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="term_contract">
                              Índice de reajuste
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_index"
                              labelId="readjustment_index-label"
                              id="readjustment_index-select"
                              onChange={handlereadjustmentIndexChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentIndex?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="readjustment_rate">
                              Taxa de reajuste
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_rate"
                              labelId="readjustment_rate-label"
                              id="readjustment_rate-select"
                              onChange={handlereadjustmentRateChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentRate?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedItens>

                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="days_expire">
                              Aviso de vencimento
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="days_expire"
                              labelId="days_expire-label"
                              id="days_expire-select"
                              onChange={handleDaysExpireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {DaysToExpire?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início do contrato"
                          getDate={setInitialDate}
                          initialDate={initialDate}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do contrato"
                          getDate={setFinalDate}
                          initialDate={finalDate}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {HandleFileUpload()}

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : isFileUploaded !== false ? (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting || !isFileUploaded}
                        >
                          Cadastrar Contrato
                        </IoseModalButton>
                      </ButtonContainer>
                    ) : (
                      <></>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditContractsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  editNotification,
  loading,
  concessionaires,
  selectedCard,
  uploadFile,
  removeFile,
}) {
  //Select States
  const [uuidContract, setUuidContract] = useState('');
  const [marketType, setMarketType] = useState('');
  const [concessionaire, setConcessionaire] = useState('');
  const [termOfContract, setTermOfContract] = useState('');
  const [readjustmentIndex, setReadjustmentIndex] = useState('');
  const [readjustmentRate, setReadjustmentRate] = useState('');
  const [daysExpire, setDaysExpire] = useState('');
  const [s3Link, setS3Link] = useState('');
  //eslint-disable-next-line
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  useEffect(() => {
    setUuidContract(selectedCard?.uuid_contract);
    setInitialDate(selectedCard?.initial_date);
    setFinalDate(selectedCard?.final_date);
    setS3Link(selectedCard?.file_link);
    setConcessionaire(selectedCard?.concessionaire);
    setMarketType(selectedCard?.market_type);
    setTermOfContract(selectedCard?.term_contract);
    setReadjustmentIndex(selectedCard?.readjustment_index);
    setReadjustmentRate(selectedCard?.readjustment_rate);
    setDaysExpire(selectedCard?.days_expire);
  }, [selectedCard]);

  function handleMarketTypeChange(event) {
    const marketType = event.target.value;
    setMarketType(marketType);
  }

  function handleConcessionaireChange(event) {
    const concessionaire = event.target.value;
    setConcessionaire(concessionaire);
  }

  function handletermOfContractChange(event) {
    const termOfContract = event.target.value;
    setTermOfContract(termOfContract);
  }

  function handlereadjustmentIndexChange(event) {
    const ReadjustmentIndex = event.target.value;
    setReadjustmentIndex(ReadjustmentIndex);
  }

  function handlereadjustmentRateChange(event) {
    const ReadjustmentRate = event.target.value;
    setReadjustmentRate(ReadjustmentRate);
  }

  function handleDaysExpireChange(event) {
    const daysExpire = event.target.value;
    setDaysExpire(daysExpire);
  }

  const labelsTextsContracts = [
    { label: 'Número do Contrato', variable: 'contractNumber' },
    { label: 'Valor total do contrato', variable: 'totalContracts' },
    { label: 'Valor mensal', variable: 'monthlyValue' },
    { label: 'Observação', variable: 'note' },
  ];

  const HandleFileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLink, setFileLink] = useState(null);
    const [existingLink, setExistingLink] = useState(null);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    useEffect(() => {
      if (selectedCard && selectedCard.file_link) {
        setExistingLink(selectedCard.file_link);
      }
    }, []);

    const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async (file) => {
      setUploadLoading(true);
      try {
        const response = await uploadFile(file);

        setFileLink(response.fileUrl);
        setS3Link(response.fileUrl);
        setIsFileUploaded(true);
        setUploadLoading(false);
      } catch (err) {
        console.error(err);
        setUploadLoading(false);
      }
    };

    const getFileKeyFromLink = (fileLink) => {
      const parts = fileLink.split('/');
      return parts[parts.length - 1];
    };

    const removeExistingContract = async (file) => {
      const fileKey = getFileKeyFromLink(file);

      try {
        setLoadingRemove(true);
        await removeFile(fileKey);

        setExistingLink(null);
      } catch (err) {
      } finally {
        setLoadingRemove(false);
      }
    };

    return (
      <div
        style={{
          marginTop: '30px',
        }}
      >
        {loadingRemove || uploadLoading ? ( // Adicionando condicional de carregamento
          <DivCircularProgress>
            <UploadLoading
              loading={uploadLoading || loadingRemove}
              color="#015A9E"
            />
          </DivCircularProgress>
        ) : (
          <>
            {existingLink ? (
              <div>
                <p style={{ marginBottom: '15px' }}>Contrato atual:</p>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ContractLink
                    href={existingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visualizar Contrato
                  </ContractLink>
                  <DeleteContractButton
                    onClick={() => removeExistingContract(existingLink)}
                  >
                    Remover Contrato
                  </DeleteContractButton>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: '30px' }}>
                {fileLink ? (
                  <div>
                    <p>Upload realizado com sucesso!</p>
                  </div>
                ) : (
                  <>
                    <div style={{ marginBottom: '5px' }}>
                      Upload de contrato
                    </div>
                    <input type="file" onChange={handleFileInput} />

                    {selectedFile ? (
                      <>
                        <UploadButton
                          type="button"
                          onClick={() => handleUpload(selectedFile)}
                        >
                          Enviar
                        </UploadButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const classes = useStyles();

  const handleEditContracts = async (values) => {
    const monthlyValue = parseFloat(values.monthlyValue.replace(',', '.'));
    const totalContracts = parseFloat(values.totalContracts.replace(',', '.'));

    const data = {
      contractNumber: values.contractNumber,
      uuidContract: uuidContract,
      marketType: marketType,
      concessionaire: concessionaire,
      termOfContract: termOfContract,
      readjustmentIndex: readjustmentIndex,
      readjustmentRate: readjustmentRate,
      totalContracts: totalContracts,
      monthlyValue: monthlyValue,
      note: values.note,
      daysExpire: daysExpire,
    };

    submit(
      data.uuidContract,
      data.contractNumber,
      data.marketType,
      data.concessionaire,
      data.termOfContract,
      data.readjustmentIndex,
      data.readjustmentRate,
      data.totalContracts,
      data.monthlyValue,
      data.note,
      data.daysExpire,
      initialDate,
      finalDate,
      s3Link
    );
  };

  const formattedTotalContracts = selectedCard?.total_contracts
    .toString()
    .replace('.', ',');

  const formattedMonthlyValue = selectedCard?.monthly_value
    .toString()
    .replace('.', ',');

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  type_market: selectedCard?.market_type,
                  contractNumber: selectedCard?.contract_number,
                  concessionaire: selectedCard?.concessionaire,
                  term_contract: selectedCard?.term_contract,
                  readjustment_index: selectedCard?.readjustment_index,
                  readjustment_rate: selectedCard?.readjustment_rate,
                  totalContracts: formattedTotalContracts,
                  monthlyValue: formattedMonthlyValue,
                  note: selectedCard?.note,
                  days_expire: selectedCard?.days_expire,
                }}
                onSubmit={handleEditContracts}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="type_market">
                              Tipo de Mercado
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="type_market"
                              labelId="type_market-label"
                              id="type_market-select"
                              onChange={(event) => {
                                handleMarketTypeChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {EnergyMarket?.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="concessionaire">
                              Concessionária
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={(event) => {
                                handleConcessionaireChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {concessionaires?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.name}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="term_contract">
                              Vigência do contrato
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="term_contract"
                              labelId="term_contract-label"
                              id="term_contract-select"
                              onChange={(event) => {
                                handletermOfContractChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {TermContract?.map((term) => (
                                <MenuItem key={term.name} value={term.value}>
                                  {term.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="readjustment_index">
                              Índice de reajuste
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_index"
                              labelId="readjustment_index-label"
                              id="readjustment_index-select"
                              onChange={(event) => {
                                handlereadjustmentIndexChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentIndex?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="readjustment_rate">
                              Taxa de reajuste
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_rate"
                              labelId="readjustment_rate-label"
                              id="readjustment_rate-select"
                              onChange={(event) => {
                                handlereadjustmentRateChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentRate?.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedItens>

                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="days_expire">
                              Aviso de vencimento
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="days_expire"
                              labelId="days_expire-label"
                              id="days_expire-select"
                              onChange={(event) => {
                                handleDaysExpireChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {DaysToExpire?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início do contrato"
                          getDate={setInitialDate}
                          initialDate={initialDate}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do contrato"
                          getDate={setFinalDate}
                          initialDate={finalDate}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {HandleFileUpload()}

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      // Renderizar notificação se showNotification for verdadeiro
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disableElevation={isSubmitting}
                        >
                          Editar Contrato
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseContractsDeleteModal({
  open,
  onClose,
  loading,
  notificationType,
  deleteNotification,
  showNotification,
  submit,
  selectedCard,
  modalTitle,
  modalHeaderTitle,
  removeFile,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);
  const [existingLink, setExistingLink] = useState(null);
  const [uuidContract, setUuidContract] = useState(null);

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  useEffect(() => {
    setUuidContract(selectedCard?.uuid_contract);
    setExistingLink(selectedCard?.file_link);
  }, [selectedCard]);

  const getFileKeyFromLink = (fileLink) => {
    const parts = fileLink.split('/');
    return parts[parts.length - 1];
  };

  const removeExistingContract = async (file) => {
    const fileKey = getFileKeyFromLink(file);

    try {
      await removeFile(fileKey);

      setExistingLink(null);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmation = () => {
    if (confirmation === 'deletar') {
      submit(uuidContract);
      removeExistingContract(existingLink);
    } else {
      setShowError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom marginBottom="35px">
                {modalTitle}
              </ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar este contrato?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite{' '}
                <b>
                  <i>deletar</i>
                </b>{' '}
                logo abaixo e aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <DivCircularProgress>
                  <CircularProgressStyled />
                </DivCircularProgress>
              ) : showNotification ? (
                <>
                  {notificationType === 1 && (
                    <IoseAlertSuccessModal text={deleteNotification} />
                  )}
                  {notificationType === 2 && (
                    <IoseAlertErroModal text={deleteNotification} />
                  )}
                  {showError && (
                    <IoseAlertErroModal
                      text={messages.confirmationTextIncorrect}
                    />
                  )}
                </>
              ) : showError ? (
                <IoseAlertErroModal text="Texto de confirmação incorreto." />
              ) : (
                <ButtonContainer>
                  <IoseModalButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    theme="white"
                    fullWidth
                    onClick={handleConfirmation}
                  >
                    Deletar
                  </IoseModalButton>
                </ButtonContainer>
              )}
            </div>
          </StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}

export function IoseAddSystemVariables({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
}) {
  //Select States
  const [itemType, setItemType] = useState(null);
  const [constantType, setConstantType] = useState(null);
  //eslint-disable-next-line
  const [filteredConstantType, setFilteredConstantTypes] = useState(null);
  const { systemVariables } = useSystemVariables();

  const classes = useStyles();

  function handleItemTypeChange(event) {
    const itemType = event.target.value;

    setItemType(itemType);

    const selectedItemType = systemVariables.find(
      (type) => type.item_type === itemType
    );

    setFilteredConstantTypes(selectedItemType?.item_constant);
  }

  function handleConstantChange(event) {
    const constantType = event.target.value;
    setConstantType(constantType);
  }

  const labelsTextsContracts = [
    { label: 'Descrição', variable: 'typeDescription' },
  ];

  const handleCreateSystemVariables = async (values) => {
    const data = {
      typeDescription: values.typeDescription,
      itemType: itemType,
      constantType: constantType,
    };

    submit(data.itemType, data.constantType, data.typeDescription);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  contractNumber: '',
                  totalContracts: '',
                  monthlyValue: '',
                  note: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateSystemVariables}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_type">
                              Tipo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_type"
                              labelId="item_type-label"
                              id="item_type-select"
                              onChange={handleItemTypeChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {systemVariables
                                // Filtrar valores únicos de item_type
                                ?.filter(
                                  (value, index, self) =>
                                    self.findIndex(
                                      (t) => t.item_type === value.item_type
                                    ) === index
                                )
                                // Mapear os valores únicos para os elementos MenuItem
                                .map((type) => (
                                  <MenuItem
                                    key={type.item_type}
                                    value={type.item_type}
                                  >
                                    {type.item_type}
                                  </MenuItem>
                                ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="constant_type">
                              Constantes
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="constant_type"
                              labelId="constant_type-label"
                              id="constant_type-select"
                              onChange={handleConstantChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {systemVariables?.map((item) => (
                                <MenuItem
                                  key={item.item_constant}
                                  value={item.item_constant}
                                >
                                  {item.item_constant}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Variável
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditSystemVariables({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  editNotification,
  loading,
  initialValues,
}) {
  //Select States
  const [itemType, setItemType] = useState(null);
  const [constantType, setConstantType] = useState(null);
  const [filteredConstantType, setFilteredConstantTypes] = useState(null);

  const classes = useStyles();

  function handleItemTypeChange(event) {
    const itemType = event.target.value;
    setItemType(itemType);

    const selectedItemType = VariableTypes.find(
      (type) => type.value === itemType
    );

    setFilteredConstantTypes(selectedItemType?.constant_type);
  }

  function handleConstantChange(event) {
    const constantType = event.target.value;
    setConstantType(constantType);
  }

  const labelsTextsContracts = [
    { label: 'Descrição', variable: 'typeDescription' },
  ];

  const handleCreateSystemVariables = async (values) => {
    const data = {
      typeDescription: values.typeDescription,
      itemType: itemType,
      constantType: constantType,
    };

    submit(data.itemType, data.constantType, data.typeDescription);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  item_type: initialValues?.item_type,
                  item_constant: initialValues?.item_constant,
                  item_description: initialValues?.item_description,
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateSystemVariables}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_type">
                              Tipo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_type"
                              labelId="item_type-label"
                              id="item_type-select"
                              onChange={(event) => {
                                handleItemTypeChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {VariableTypes?.map((type) => (
                                <MenuItem key={type.name} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_constant">
                              Constante
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_constant"
                              labelId="item_constant-label"
                              id="item_constant-select"
                              onChange={handleConstantChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {filteredConstantType?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={
                                    initialValues['item_description']
                                  }
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar Variável
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddTariffsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
}) {
  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  const classes = useStyles();

  const handleCreateTariff = (values) => {
    const { modalidade, subgrupo, posto, tarifa, valor, porcentagem } = values;

    const formattedValue = parseFloat(valor.replace(',', '.'));
    const formatedPercentage = parseFloat(porcentagem.replace(',', '.'));

    submit(
      tarifa,
      subgrupo,
      posto,
      modalidade,
      initialDate,
      finalDate,
      formattedValue,
      formatedPercentage
    );
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    valor: validationsForm.value,
    porcentagem: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  modalidade: '',
                  subgrupo: '',
                  posto: '',
                  tarifa: '',
                  valor: '',
                  porcentagem: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateTariff}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="modalidade-label"
                            className={classes.whiteText}
                          >
                            Modalidade
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="modalidade"
                            labelId="modalidade-label"
                            id="modalidade-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {ModalidadeOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="subgrupo-label"
                            className={classes.whiteText}
                          >
                            Subgrupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="subgrupo"
                            labelId="subgrupo-label"
                            id="subgrupo-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {SubgrupoOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="posto-label"
                            className={classes.whiteText}
                          >
                            Posto
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="posto"
                            labelId="posto-label"
                            id="posto-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {PostoOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="tarifa-label"
                            className={classes.whiteText}
                          >
                            Tarifa
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="tarifa"
                            labelId="tarifa-label"
                            id="tarifa-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {TarifaOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'valor'}
                          name={'valor'}
                          type={'text'}
                          label={'R$ / MWh'}
                          defaultvalue={initialValues['valor']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['valor'] && touched['valor']
                              ? errors['valor']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'porcentagem'}
                          name={'porcentagem'}
                          type={'text'}
                          label={'Porcentagem de desconto'}
                          defaultvalue={initialValues['porcentagem']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['porcentagem'] && touched['porcentagem']
                              ? errors['porcentagem']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <div
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <IoseSelectDayMouth
                            title="Início da vigência"
                            getDate={setInitialDate}
                            initialDate={initialDate}
                            margin={'0px'}
                          />
                        </div>

                        <div>
                          <IoseSelectDayMouth
                            title="Fim da vigência"
                            getDate={setFinalDate}
                            initialDate={finalDate}
                            margin={'0px'}
                          />
                        </div>
                      </div>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Tarifa
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddUsersModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  groupOptions,
}) {
  const classes = useStyles();

  const handleCreateUser = (values) => {
    const { name, email, group } = values;

    submit(name, email, group);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateUser}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'name'}
                          name={'name'}
                          type={'text'}
                          label={'Nome'}
                          defaultvalue={initialValues['name']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['name'] && touched['name']
                              ? errors['name']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'email'}
                          name={'email'}
                          type={'text'}
                          label={'E-mail'}
                          defaultvalue={initialValues['email']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['email'] && touched['email']
                              ? errors['email']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="group-label"
                            className={classes.whiteText}
                          >
                            Grupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="group"
                            labelId="group-label"
                            id="group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseChangeGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  groupOptions,
  seletectedRow,
}) {
  const classes = useStyles();

  const handleCreateUser = (values) => {
    const { new_group } = values;

    submit(seletectedRow.username, seletectedRow.group, new_group);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  name: seletectedRow?.name,
                  email: seletectedRow?.email,
                  group: seletectedRow?.group,
                  new_group: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateUser}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <DivInputs marginBottom="10px">
                        <StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'name'}
                          name={'name'}
                          type={'text'}
                          label={
                            <span style={{ color: 'white', opacity: '80%' }}>
                              Nome
                            </span>
                          }
                          defaultvalue={initialValues['name']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['name'] && touched['name']
                              ? errors['name']
                              : ''
                          }
                          disabled
                        />
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          disabled
                          id={'email'}
                          name={'email'}
                          type={'text'}
                          label={
                            <span style={{ color: 'white', opacity: '80%' }}>
                              E-mail
                            </span>
                          }
                          defaultvalue={initialValues['email']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['email'] && touched['email']
                              ? errors['email']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="group-label"
                            className={classes.whiteText}
                          >
                            Grupo Anterior
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="group"
                            labelId="group-label"
                            id="group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                            inputProps={{
                              readOnly: true,
                              style: { color: 'white' },
                            }}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="new_group-label"
                            className={classes.whiteText}
                          >
                            Novo Grupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="new_group"
                            labelId="new_group-label"
                            id="new_group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Alterar
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddCarbonTariffsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
}) {
  const classes = useStyles();

  const handleCreateTariff = (values) => {
    const {
      month_select,
      year_select,
      yearTariff,
      fator_medio_mensal_tco2_mwh,
    } = values;

    const formattedYearTariff = parseFloat(yearTariff.replace(',', '.'));
    const formattedMonthlyFactor = parseFloat(
      fator_medio_mensal_tco2_mwh.replace(',', '.')
    );
    submit(
      formattedMonthlyFactor,
      formattedYearTariff,
      month_select,
      year_select
    ); // Passar month_select e year_select diretamente
  };

  const ValidationAdd = Yup.object().shape({
    month_select: Yup.string().required('Mês é obrigatório'),
    year_select: Yup.string().required('Ano é obrigatório'),
    fator_medio_mensal_tco2_mwh: validationsForm.value,
    yearTariff: validationsForm.value,
    // Inclua validações para outros campos conforme necessário
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          {/* ... Código do cabeçalho ... */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>
          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  month_select: '',
                  year_select: '',
                  annual_factor_rate: '',
                  annual_factor_year_select: '',
                  fator_medio_mensal_tco2_mwh: '',
                  yearTariff: '',
                }}
                validationSchema={ValidationAdd}
                onSubmit={handleCreateTariff}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      {/* Campo de Seleção do Mês */}
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel id="month-select-label">
                            Mês
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="month_select"
                            labelId="month-select-label"
                            id="month-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {MonthOptions.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      {/* Campo de Seleção do Ano */}
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel id="year-select-label">
                            Ano
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="year_select"
                            labelId="year-select-label"
                            id="year-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {YearOptions.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'fator_medio_mensal_tco2_mwh'}
                          name={'fator_medio_mensal_tco2_mwh'}
                          type={'text'}
                          label={'Tarifa mensal'}
                          defaultvalue={
                            initialValues['fator_medio_mensal_tco2_mwh']
                          }
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['fator_medio_mensal_tco2_mwh'] &&
                            touched['fator_medio_mensal_tco2_mwh']
                              ? errors['fator_medio_mensal_tco2_mwh']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'yearTariff'}
                          name={'yearTariff'}
                          type={'text'}
                          label={'Tarifa anual'}
                          defaultvalue={initialValues['yearTariff']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['yearTariff'] && touched['yearTariff']
                              ? errors['yearTariff']
                              : ''
                          }
                        />
                      </StyledTextInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          theme="white"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Tarifa
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}
