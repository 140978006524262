import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  useClient,
  Messages,
  LocalStorage,
  ioseAPI,
  AccountContext,
} from 'common';

import IoseUsersTable from 'components/IoseUsersTable';

import { useCreateLogs, useUserGroups } from 'hooks';

import {
  IoseSubHeaderBar,
  IoseSearch,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';

import {
  IoseAddUsersModal,
  IoseChangeGroupModal,
} from 'components/IoseNewModals';

export default function UsersContainer({ getcards }) {
  const [usersFiltered, serUsersFiltered] = useState([]);
  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [addNotification, setAddNotification] = useState('');
  const [changeNotification, setChangeNotification] = useState('');
  const [notificationType, setNotificationType] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [seletectedRow, setSeletectedRow] = useState();

  const [users, setUsers] = useState([]);

  const [groupOptions, setGroupOptions] = useState();

  const { getSession } = useContext(AccountContext);

  const { client } = useClient();
  const { createLog } = useCreateLogs();
  const { groups } = useUserGroups();
  const { headers } = getSession();

  const fetchUsers = async () => {
    try {
      const response = await ioseAPI.listCognitoUsersAndGroups();
      const userResponse = response?.data;

      const formattedUsers = userResponse?.map((user) => {
        const groups = user?.groups?.filter(
          (group) => group !== 'us-east-1_hWmApDv4W_SANEPAR'
        );
        const group =
          groups?.length > 0 ? groups?.join(', ') : user.groups.join(',');

        const name =
          user.Attributes.find((attr) => attr.Name === 'custom:user_nick')
            ?.Value ||
          user.Attributes.find((attr) => attr.Name === 'given_name')?.Value ||
          '';

        return {
          name,
          email:
            user.Attributes.find((attr) => attr.Name === 'email')?.Value || '',
          group,
          sub: user.Attributes.find((attr) => attr.Name === 'sub')?.Value || '',
          username: user.Username,
        };
      });

      setUsers(formattedUsers);
    } catch (error) {
      console.error('Erro ao obter usuários:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [groups]);

  useEffect(() => {
    const usualGroups = groups?.filter(
      (group) => group?.GroupName !== 'us-east-1_hWmApDv4W_SANEPAR'
    );

    const groupOptions = usualGroups?.map((grupo) => ({
      value: grupo.GroupName,
      label: grupo.GroupName,
    }));

    setGroupOptions(groupOptions);
  }, [groups]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenChangeModal = () => {
    setOpenChangeModal(true);
  };

  const handleCloseChangeModal = () => {
    setOpenChangeModal(false);
  };

  function errorHandlingAddTariffs(error) {
    setLoading(false);
    setNotificationType(2);
  }

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const addUser = async (name, email, group) => {
    try {
      const response = await ioseAPI.getUserByEmail(headers, email);

      const users = response.data.Users;

      if (users.length > 0) {
        setAddNotification('Usuário já cadastrado.');
        setNotificationType(2);
      } else {
        await ioseAPI.createAndRegisterUserToGroup(headers, name, email, group);

        setAddNotification('Usuário cadastrado com sucesso.');
        setNotificationType(1);

        const operacao = 'CRIAR';
        const form = 'CRIAR USUÁRIO';
        const logContent = {
          name: name,
          email: email,
          group: group,
        };

        await createLog(operacao, form, logContent);
        await fetchUsers();
      }
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      errorHandlingAddTariffs(errorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  };

  const changeUserGroup = async (username, currentGroup, newGroup) => {
    setOperationLoading(true);
    try {
      await ioseAPI.changeCognitoUserGroup(
        headers,
        username,
        currentGroup,
        newGroup
      );

      setChangeNotification('Grupo alterado com sucesso.');
      setNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR GRUPO DE USUÁRIO';
      const logContent = {
        username: username,
        currentGroup: currentGroup,
        newGroup: newGroup,
      };

      await createLog(operacao, form, logContent);
      fetchUsers();
    } catch (error) {
      setChangeNotification('Erro ao trocar grupo do usuário.');
      setNotificationType(2);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseChangeModal();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const filterContracts = (searchName = '') => {
    setError('');

    if (searchName !== '') {
      const searchNamerUppercase = searchName.toUpperCase();
      const itemFiltered = users?.filter((item) =>
        item?.name?.includes(searchNamerUppercase)
      );

      if (itemFiltered && itemFiltered.length !== 0) {
        serUsersFiltered(itemFiltered);
      } else {
        serUsersFiltered([]);
        setError(Messages.noFindUser);
      }
    } else {
      serUsersFiltered([]);
    }
  };

  const filteredData = usersFiltered?.length > 0 ? usersFiltered : users;

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = hasAddPermission ? true : false;

    const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CADASTRAR USUÁRIO"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={''}
        subtitle="Usuários"
        description={`Total: ${users?.length}`}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar por nome de usuário..."
          funcSearch={filterContracts}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const handleRowClick = (rowData) => {
    setSeletectedRow(rowData);

    handleOpenChangeModal();
    // Realize outras ações conforme necessário
  };

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <S.TableContainer>
          <S.TableBox>
            <IoseUsersTable data={filteredData} onRowClick={handleRowClick} />
          </S.TableBox>
        </S.TableContainer>
      </S.ContainerTable>
    );
  };

  const renderContent = () => {
    if (error) {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>{renderTable()}</div>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddUsersModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          modalHeaderTitle="USUÁRIOS"
          modalTitle="CADASTRO DE USUÁRIOS"
          submit={addUser}
          notificationType={notificationType}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={operationLoading}
          groupOptions={groupOptions}
        />

        <IoseChangeGroupModal
          open={openChangeModal}
          onClose={handleCloseChangeModal}
          modalHeaderTitle="USUÁRIOS"
          modalTitle="ALTERAR GRUPO DO USUÁRIO"
          submit={changeUserGroup}
          notificationType={notificationType}
          showNotification={showNotification}
          addNotification={changeNotification}
          loading={operationLoading}
          groupOptions={groupOptions}
          seletectedRow={seletectedRow}
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            {renderContent()}
            {renderModals()}
          </>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

UsersContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
