import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseSubHeaderBar,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  IoseCognito,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { WrappedContainer } from './style';

import { useLocation, useHistory } from 'react-router-dom';

import { useCreateLogs, useGroupPermissions } from 'hooks';

import IoseAddUserGroupModal from 'components/Modals/IoseAddUserGroupModal';
import { useUserGroups } from 'hooks';
import { IoseBackButton } from 'components/IoseButtonIcon';
import MenuPermissionsContainer from 'containers/MenuPermissionsContainer';

export default function MenuPermissionsManagementContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');
  //eslint-disable-next-line
  const [cards, setCards] = useState([]);
  const [clientData, setClientData] = useState({});

  const [editNotificationType, setEditNotificationType] = useState(0);
  const [editNotification, setEditNotification] = useState('');
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);

  const [permissions, setPermissions] = useState();

  const history = useHistory();
  const { state } = useLocation();
  const { groups, refreshCognitoGroups } = useUserGroups();
  const { refreshPermissions } = useGroupPermissions();

  useEffect(() => {
    const groupPermissions = state;

    setPermissions(groupPermissions);
  }, [state]);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const clickBackToUserGroups = () => {
    history.push({
      pathname: '/user-groups',
    });
  };

  const handlePermissionChange = (permission, subPermission, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: {
        ...prevPermissions[permission],
        [subPermission]: value,
      },
    }));
  };

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups?.length > 0) {
      setCards(groups);
    }
  }, [groups]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  function errorHandlingAddGroup(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  function errorHandlingEditEcu(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  /// ========== OPERATIONS ===========

  async function addGroup(groupName) {
    setNotificationType(0);
    setLoading(true);

    try {
      await IoseCognito.createGroup(groupName);

      setAddNotification('Grupo cadastrado com sucesso!');
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR GRUPO';
      const logContent = {
        group_name: groupName,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.groupNoRegistered;
      errorHandlingAddGroup(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
        refreshCognitoGroups();
      }, 2500);
    }
  }

  async function updateMenuGroupPermissions(uuid_permission, group_name, permissions) {
    setEditNotificationType(0);
    setOperationLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateMenuGroupPermissions(
        sessionData.headers,
        uuid_permission,
        group_name,
        permissions
      );

      setEditNotification(response.data.message);
      setEditNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR PERMISSÕES DE MENU POR GRUPO';
      const logContent = {
        uuid_permission: uuid_permission,
        group_name: group_name,
        permissions: permissions,
      };

      await createLog(operacao, form, logContent);
      await refreshCognitoGroups();
      await refreshPermissions();
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.errorUpdateEcus;

      errorHandlingEditEcu(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        setEditNotificationType(0);
        setShowNotification(false);

        clickBackToUserGroups();
      }, 2500);
    }
  }

  /// ========== OPERATIONS ===========
  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client?.name,
        uuid_client: client?.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="GERÊNCIAMENTO DE PERMISSÕES"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Gerênciamento de permissões de menu do grupo "${permissions?.group_name}"`}
        description={''}
        button={showButton ? button : <></>}
      >
        <IoseBackButton onClick={(e) => clickBackToUserGroups()} />
      </IoseSubHeaderBar>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else if (permissions !== undefined && permissions !== null) {
      return (
        <>
          <IoseInfinityScroll
            dataLength={5}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            <MenuPermissionsContainer
              permissions={permissions}
              onChange={handlePermissionChange}
              submit={updateMenuGroupPermissions}
              loading={operationLoading}
              showNotification={showNotification}
              editNotificationType={editNotificationType}
              editNotification={editNotification}
            />
          </IoseInfinityScroll>
        </>
      );
    } else {
      return null;
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddUserGroupModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={addGroup}
          notificationType={notificationType}
          notification={notification}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

MenuPermissionsManagementContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
