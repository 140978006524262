import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Imports from Material Components
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AlertTitle } from '@material-ui/lab';
import {
  Grid,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core/';

//Imports from Material Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Imports from IOSE components
import IoseButton from 'components/Buttons/IoseButton';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';
import IoseSearch from 'components/IoseSearch';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

//Import from Material Icon
import CloseIcon from '@material-ui/icons/Close';

//Imports from Style
import {
  GridItem,
  GridContainer,
  Header,
  CollapseStyled,
  Content,
  AlertStyled,
  IconButtonStyled,
  FormControlStyled,
  CardsButtonDiv,
  SelectorsDiv,
  Notification,
  DivSearch,
  ListStyled,
  Subheader,
  CheckboxStyled,
} from './style';

import Messages from 'common/Messages';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

/**
 This component show the a alert about new circuits (without unity and switchboard associated)
 */
export default function IoseCircuitCommissioning({
  circuitsNew,
  allUnitys,
  getAllGroups,
  updateNewCircuit,
  getMoreNewCircuits,
  open,
  setOpen,
  nextpage,
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const [checked, setChecked] = useState([]);

  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(false);

  const [unity, setUnity] = useState('');
  const [switchboard, setSwitchboard] = useState('');
  const [allGroups, setAllGroups] = useState([]);

  const [left, setLeft] = useState(circuitsNew);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setNotification('');
    unity !== '' && handleGetAllGroup();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unity]);

  useEffect(() => {
    circuitsNew !== left && setLeft(circuitsNew);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitsNew]);

  const handleGetAllGroup = async () => {
    setSwitchboard('');
    setAllGroups([]);

    const response = await getAllGroups(unity.uuid_unity);

    if (response.status) {
      setAllGroups(response.data);
    } else {
      setNotification(response.message);
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const associateCircuits = async () => {
    if (right.length !== 0) {
      setLoading(true);

      let response = await updateNewCircuit(
        right,
        unity.uuid_unity,
        switchboard.uuid_group
      );

      response && setNotification(response.message);

      setRight([]);
      setLoading(false);
    } else {
      setNotification(Messages.noCircuitSelected);
    }
  };

  function closeAlert() {
    setExpanded(false);
    setOpen(false);
  }

  function filterCircuitsInComissioning(circuitName) {
    const circuitNameUppcase = circuitName.toUpperCase();

    if (circuitName === '') {
      return setLeft(circuitsNew);
    } else {
      const circuitFiltered = circuitsNew.filter((circuitNew) =>
        circuitNew.name.toUpperCase().includes(circuitNameUppcase)
      );

      if (typeof circuitFiltered !== 'undefined') {
        setLeft(circuitFiltered);
      } else {
        setLeft([]);
      }
    }
  }

  const selectColorNotification = (notification) => {
    if (notification.includes('Selecione') || notification.includes('Erro')) {
      return 'var(--alert)';
    } else {
      return 'var(--success)';
    }
  };

  //Render Functions
  const renderHeader = () => (
    <Header>
      <AlertTitle>Aviso</AlertTitle>
      <IconButtonStyled onClick={() => closeAlert()}>
        <CloseIcon fontSize="inherit" />
      </IconButtonStyled>
    </Header>
  );

  const renderContent = () => (
    <Content>
      <p>
        Existem <b>circuitos desassociados</b> a uma unidade.
      </p>
      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={() => setExpanded(!expanded)}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Content>
  );

  const renderSelectors = () => {
    let disableSelectGroups =
      unity === '' || allGroups.length === 0 ? true : false;

    return (
      <SelectorsDiv>
        <FormControlStyled>
          <InputLabel>Unidades</InputLabel>
          <Select
            value={unity}
            onChange={(event) => setUnity(event.target.value)}
          >
            {allUnitys.map((data) => (
              <MenuItem key={data.uuid_unity} value={data}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControlStyled>

        <FormControlStyled disabled={disableSelectGroups}>
          <InputLabel>Quadros</InputLabel>
          <Select
            value={switchboard}
            onChange={(event) => setSwitchboard(event.target.value)}
          >
            {allGroups.map((data) => (
              <MenuItem key={data.uuid_group} value={data}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControlStyled>
      </SelectorsDiv>
    );
  };

  const renderCheckBoxHeader = (items) => (
    <CheckboxStyled
      onClick={handleToggleAll(items)}
      checked={numberOfChecked(items) === items.length && items.length !== 0}
      indeterminate={
        numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
      }
      disabled={items.length === 0 || unity === '' || switchboard === ''}
      inputProps={{ 'aria-label': 'all items selected' }}
    />
  );

  const renderListItem = (value) => {
    let disableItem =
      unity === '' || switchboard === '' || allGroups.length === 0;

    return (
      <ListItem
        key={value.uuid_circuit}
        onClick={handleToggle(value)}
        disabled={disableItem}
        button
      >
        <ListItemIcon>
          <CheckboxStyled
            checked={checked.indexOf(value) !== -1}
            tabIndex={0}
            disableRipple
            disabled={unity === '' || switchboard === ''}
          />
        </ListItemIcon>
        <ListItemText
          primary={`${value.name}`}
          secondary={`${value.description}`}
        />
      </ListItem>
    );
  };

  const renderListLeft = (title, items) => {
    let subheaderTitle = '';

    if (nextpage === undefined) {
      subheaderTitle = 'Todos os circuitos foram carregados';
    } else {
      subheaderTitle = 'Role até o final para obter mais circuitos';
    }

    return (
      <Card>
        <CardHeader
          avatar={renderCheckBoxHeader(items)}
          title={`${items.length} ${title}`}
          subheader={
            <Subheader text={subheaderTitle}>
              <p>
                {subheaderTitle}
                <br />
                {numberOfChecked(items)}/{items.length} Selecionado
              </p>
            </Subheader>
          }
        />
        <Divider />
        <ListStyled id="list" dense>
          <IoseInfinityScroll
            dataLength={items.length}
            next={getMoreNewCircuits}
            hasMore={true}
            scrollableTarget="list"
          >
            {items.map((value) => renderListItem(value))}
          </IoseInfinityScroll>
        </ListStyled>
      </Card>
    );
  };

  const renderListRight = (title, items, subtitle) => {
    let switchboardName = allGroups.length !== 0 && subtitle;

    return (
      <Card>
        <CardHeader
          avatar={renderCheckBoxHeader(items)}
          title={title}
          subheader={
            <Subheader text={''}>
              <p>{switchboardName}</p>
              <p>
                {allGroups.length !== 0 &&
                  `${numberOfChecked(items)}/${items.length} Selecionado`}
              </p>
            </Subheader>
          }
        />
        <Divider />
        <ListStyled className={classes.list} dense component="div" role="list">
          {items.map((value) => renderListItem(value))}
          <ListItem />
        </ListStyled>
      </Card>
    );
  };

  const renderTransferButtons = () => {
    let disabledLeftButton =
      leftChecked.length === 0 || unity === '' || switchboard === '';

    let disabledRightButton = rightChecked.length === 0;

    return (
      <Grid container direction="column" alignItems="center">
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={disabledLeftButton}
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={disabledRightButton}
        >
          &lt;
        </Button>
      </Grid>
    );
  };

  const renderCollapseContent = () => {
    let unityName = unity.name;
    let switchName = switchboard.name;

    return (
      <CollapseStyled in={expanded} timeout="auto">
        <p>
          Para <b>associar</b> é necessário <b>criar uma unidade e um quadro</b>
          . Caso não tenha criado-os, clique no <b>botão + </b> acima para criar
          uma unidade e após entre nela e adicione um quadro.{<br></br>} Caso
          queira <b>desassociar </b>
          um circuito do quadro e unidade, clique na aba{' '}
          <b>Desassociar Circuitos</b> presente no <b>card do quadro</b> na{' '}
          <b>sidebar</b>.
        </p>

        <CardsButtonDiv>
          {renderSelectors()}

          <DivSearch>
            <IoseSearch
              placeholder="Pesquisar Circuitos…"
              funcSearch={filterCircuitsInComissioning}
            />
          </DivSearch>

          <GridContainer spacing={3} paddingtop={'20px'}>
            <Grid item>{renderListLeft('Circuitos sem Unidade ', left)}</Grid>
            <Grid item>{renderTransferButtons()}</Grid>
            <Grid item>{renderListRight(unityName, right, switchName)}</Grid>
          </GridContainer>

          {loading ? (
            <LoadingIndicatorSidebar
              loading={loading}
              margin={'0px 30px 0px 0px'}
              margin767px={'0px'}
            />
          ) : (
            notification !== '' && (
              <Notification
                text={notification}
                color={selectColorNotification(notification)}
              >
                {notification}
              </Notification>
            )
          )}

          <IoseButton
            margin={'0px 30px 0px 0px'}
            margin767px={'0px 10px 0px 0px'}
            onClick={() => associateCircuits()}
          >
            Associar
          </IoseButton>
        </CardsButtonDiv>
      </CollapseStyled>
    );
  };

  return (
    <GridContainer>
      <GridItem>
        <CollapseStyled in={open} padding="0%">
          <AlertStyled severity="warning">
            {renderHeader()}
            {renderContent()}
            {renderCollapseContent()}
          </AlertStyled>
        </CollapseStyled>
      </GridItem>
    </GridContainer>
  );
}

IoseCircuitCommissioning.propTypes = {
  /** This prop get the array of Circuits without unity or switchboard */
  circuitsNew: PropType.array,
  /** This prop get the array of Client's unitys*/
  allUnitys: PropType.array,
  /** This prop get the array of Client's switchboards*/
  allGroups: PropType.array,
  /** This prop is a function that get all Client's switchboards in container*/
  getAllGroups: PropType.func,
  /** This prop is a function that set all Client's switchboards in container*/
  setAllGroups: PropType.func,
  /** This prop is a messsage that notify user if occurred trouble or success*/
  notificationCommissioning: PropType.string,
  /** This prop is a boolean that show or not the spin related comissioning*/
  loadingComissioning: PropType.bool,
  /** This prop is a function that associate one or more newcircuits to unity and switchboard*/
  updateNewCircuit: PropType.func,
  /** This prop is a function that get more newcircuits when the user reaches the end new circuits list, this is use to InfinityScroll*/
  getMoreNewCircuits: PropType.func,
  /** This prop is a boolean to open collapse warning or close to improve performance */
  open: PropType.bool,
  /** This prop is a function to set open collapse warning or close to improve performance */
  setOpen: PropType.func,
  // /** This prop is a boolean that indicate if has other news circuits page in DB , this is use to InfinityScroll */
  // nextpage: PropType.object,
};
