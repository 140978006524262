import styled from 'styled-components';

//Import Material UI  Components
import { Typography, Grid } from '@material-ui/core';

export const TypographyGeneral = styled(Typography)`
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  font-size: 23px;
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    max-width: 220px;
    /* border: solid 1px red; */
  }
`;

export const TypographySubTitle = styled(TypographyGeneral)`
  color: var(--gray);
  /* margin-left: 30px; */
  font-size: 18px;

  /* @media (max-width: 978px) {
    margin-left: 0px;
  } */
`;

export const TypographyDescription = styled(TypographyGeneral)`
  color: var(--gray);
  /* margin-left: 30px; */
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const GridGeneral = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const GridContainer = styled(GridGeneral)`
  width: 98%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 0px 0px;

  @media (max-width: 767px) {
    width: 95%;
  }
`;

export const GridTiles = styled(GridGeneral)`
  align-items: baseline;
  flex-direction: row;

  /* @media (max-width: 978px) {
    flex-direction: column;
  } */
`;
