import styled from 'styled-components';

//Imports from Material UI components
import TextField from '@material-ui/core/TextField';

export const IoseTextFieldSidebarStyled = styled(TextField)`
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }

  .MuiInputBase-root > .Mui-disabled {
    opacity: 50%;
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
    margin-top: 0px !important;
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    /* opacity: 55%; */
    font-size: 12px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    opacity: 100%;
  }

  .MuiFormHelperText-root {
    color: #f44336;
    margin: 0;
    font-size: 0.8rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  @media (max-width: 1360px) {
    label {
      font-family: 'Montserrat', sans-serif;
      color: ${(props) =>
        props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
      /* opacity: 55%; */
      font-size: 10px;
    }

    .MuiInputBase-root input {
      color: ${(props) =>
        props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
      font-family: 'Montserrat', sans-serif;
      border-bottom: 2px solid #ffff;
      font-size: 10px;
      margin-top: 0px;
    }
  }
`;
