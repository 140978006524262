import React, { useEffect, useState } from 'react';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as S from './style';
import {
  IoseCloseModalButton,
  IoseMapRedirectButton,
} from 'components/IoseButtonIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AccountContext, ioseAPI } from 'common';
import {
  IoseAlertLoadingCards,
  IoseAlertSuccessModal,
} from 'components/IoseAlert';

import { IoseModalButton, TextFieldStyled } from 'components';

import { Form, Formik } from 'formik';
import { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#ECF0F5',
    // height: '90vh',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseBillsEditTaxesModal({
  open,
  onClose,
  submit,
  data,
  editInitialValues,
  refreshBills,
  refreshFaturas,
  closeAuditModal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { getSession } = useContext(AccountContext);

  //eslint-disable-next-line
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [uuidBill, setUuidBill] = useState('');
  const [showNotification, setShowNotification] = useState(null);

  useEffect(() => {
    setUuidBill(data?.uuid_energy_bill);
  }, [data]);

  function redirectToMapPage() {
    history.push('/map');
  }

  const renderContent = () => {
    async function updateBillTaxes(
      uuid_energy_bill,
      icms_calculation_basis_total,
      cofins_calculation_basis_total,
      pis_calculation_basis_total,
      icms_aliquot,
      cofins_aliquot,
      pis_aliquot,
      icms_total,
      cofins_total,
      pis_total
    ) {
      // setEditNotificationType(0);
      setLoading(true);

      try {
        const sessionData = await getSession();

        await ioseAPI.updateEnergyBillTaxes(
          sessionData.headers,
          uuid_energy_bill,
          icms_calculation_basis_total,
          cofins_calculation_basis_total,
          pis_calculation_basis_total,
          icms_aliquot,
          cofins_aliquot,
          pis_aliquot,
          icms_total,
          cofins_total,
          pis_total
        );

        refreshBills();
        refreshFaturas();
        setLoading(false);
        setShowNotification(1);

        setTimeout(() => {
          onClose();
          closeAuditModal();
          setShowNotification(null);
        }, 2500);
      } catch (err) {
        console.error(err);
      }
    }

    const handleUpdate = (values) => {
      const {
        icms_calculation_basis_total,
        cofins_calculation_basis_total,
        pis_calculation_basis_total,
        icms_aliquot,
        cofins_aliquot,
        pis_aliquot,
        icms_total,
        cofins_total,
        pis_total,
      } = values;

      function converterStringParaNumero(valor) {
        if (!isNaN(valor)) {
          return parseFloat(valor);
        }

        valor = valor.replace(',', '.');

        return parseFloat(valor);
      }

      const f_icms_calculation_basis_total = converterStringParaNumero(
        icms_calculation_basis_total
      );
      const f_pis_calculation_basis_total = converterStringParaNumero(
        pis_calculation_basis_total
      );
      const f_cofins_calculation_basis_total = converterStringParaNumero(
        cofins_calculation_basis_total
      );
      const f_icms_aliquot = converterStringParaNumero(icms_aliquot);
      const f_cofins_aliquot = converterStringParaNumero(cofins_aliquot);
      const f_pis_aliquot = converterStringParaNumero(pis_aliquot);
      const f_icms_total = converterStringParaNumero(icms_total);
      const f_cofins_total = converterStringParaNumero(cofins_total);
      const f_pis_total = converterStringParaNumero(pis_total);

      updateBillTaxes(
        uuidBill,
        f_icms_calculation_basis_total,
        f_cofins_calculation_basis_total,
        f_pis_calculation_basis_total,
        f_icms_aliquot,
        f_cofins_aliquot,
        f_pis_aliquot,
        f_icms_total,
        f_cofins_total,
        f_pis_total
      );
    };

    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.StyledWrappedModal2>
          <Formik initialValues={editInitialValues} onSubmit={handleUpdate}>
            {({
              handleChange,
              handleBlur,
              isSubmitting,
              errors,
              touched,
              initialValues,
            }) => {
              return (
                <Form>
                  <S.FormConsumerGroupContainer>
                    <S.WrappedConsumerGroupItens>
                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'icms_calculation_basis_total'}
                          name={'icms_calculation_basis_total'}
                          type={'text'}
                          label={'icms_calculation_basis_total'}
                          defaultvalue={
                            initialValues['icms_calculation_basis_total']
                          }
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['icms_calculation_basis_total'] &&
                            touched['icms_calculation_basis_total']
                              ? errors['icms_calculation_basis_total']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'cofins_calculation_basis_total'}
                          name={'cofins_calculation_basis_total'}
                          type={'text'}
                          label={'cofins_calculation_basis_total'}
                          defaultvalue={
                            initialValues['cofins_calculation_basis_total']
                          }
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['cofins_calculation_basis_total'] &&
                            touched['cofins_calculation_basis_total']
                              ? errors['cofins_calculation_basis_total']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'pis_calculation_basis_total'}
                          name={'pis_calculation_basis_total'}
                          type={'text'}
                          label={'pis_calculation_basis_total'}
                          defaultvalue={
                            initialValues['pis_calculation_basis_total']
                          }
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['pis_calculation_basis_total'] &&
                            touched['pis_calculation_basis_total']
                              ? errors['pis_calculation_basis_total']
                              : ''
                          }
                        />
                      </S.DivInputs>
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'icms_aliquot'}
                          name={'icms_aliquot'}
                          type={'text'}
                          label={'icms_aliquot'}
                          defaultvalue={initialValues['icms_aliquot']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['icms_aliquot'] && touched['icms_aliquot']
                              ? errors['icms_aliquot']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'cofins_aliquot'}
                          name={'cofins_aliquot'}
                          type={'text'}
                          label={'cofins_aliquot'}
                          defaultvalue={initialValues['cofins_aliquot']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['cofins_aliquot'] &&
                            touched['cofins_aliquot']
                              ? errors['cofins_aliquot']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'pis_aliquot'}
                          name={'pis_aliquot'}
                          type={'text'}
                          label={'pis_aliquot'}
                          defaultvalue={initialValues['pis_aliquot']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['pis_aliquot'] && touched['pis_aliquot']
                              ? errors['pis_aliquot']
                              : ''
                          }
                        />
                      </S.DivInputs>
                    </S.WrappedConsumerGroupItens>

                    <S.WrappedConsumerGroupItens>
                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'icms_total'}
                          name={'icms_total'}
                          type={'text'}
                          label={'icms_total'}
                          defaultvalue={initialValues['icms_total']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['icms_total'] && touched['icms_total']
                              ? errors['icms_total']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'cofins_total'}
                          name={'cofins_total'}
                          type={'text'}
                          label={'cofins_total'}
                          defaultvalue={initialValues['cofins_total']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['cofins_total'] && touched['cofins_total']
                              ? errors['cofins_total']
                              : ''
                          }
                        />
                      </S.DivInputs>

                      <S.DivInputs marginBottom="5px">
                        <S.StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'pis_total'}
                          name={'pis_total'}
                          type={'text'}
                          label={'pis_total'}
                          defaultvalue={initialValues['pis_total']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['pis_total'] && touched['pis_total']
                              ? errors['pis_total']
                              : ''
                          }
                        />
                      </S.DivInputs>
                    </S.WrappedConsumerGroupItens>
                  </S.FormConsumerGroupContainer>

                  <S.ButtonContainer>
                    {loading ? (
                      <S.CircularProgressStyled />
                    ) : (
                      showNotification === 1 && (
                        <IoseAlertSuccessModal
                          text={'Dados da fatura atualizados com sucesso.'}
                        />
                      )
                    )}
                    {!loading && showNotification !== 1 && (
                      <IoseModalButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Editar Campos
                      </IoseModalButton>
                    )}
                  </S.ButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </S.StyledWrappedModal2>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <S.ModalContainer>
          <S.StyledHeaderModal>
            <S.RoomIcon />

            <S.ModalTitleHeader variant="h5" gutterBottom>
              EDITAR DADOS DA ÁREA DE TRIBUTOS DA FATURA
            </S.ModalTitleHeader>

            <S.ButtonsContainer>
              {/* <IoseAuditRedirectButton
                tooltip="RETORNAR PARA UNIDADE CONSUMIDORA"
                bottom="5px"
                onClick={() => redirectToConsumerGroupPage()}
              /> */}

              <IoseMapRedirectButton
                tooltip="RETORNAR PARA O MAPA"
                bottom="5px"
                onClick={() => redirectToMapPage()}
              />

              <IoseCloseModalButton
                tooltip="FECHAR MODAL"
                bottom="5px"
                onClick={() => onClose()}
              />
            </S.ButtonsContainer>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <>
                {/* {renderSubHeaderAndSearchBar()} */}
                {renderContent()}
              </>
            </div>
          </S.StyledWrappedModal>
        </S.ModalContainer>
      </Grow>
    </Modal>
  );
}
