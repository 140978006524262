import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 30px !important;
  height: 30px !important;
`;
