import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from '../../common/contexts/Accounts';

//Imports IOSE components
import IoseButton from 'components/Buttons/IoseButton';

//Imports from Style
import { Ilustration, Container } from './style';
import HomeIcon from '@material-ui/icons/Home';

export default function Page404({ sidebar, closeSide }) {
  //Get function from AccountContext to avalible if user is logged or not
  const { getSession, getGroup } = useContext(AccountContext);

  //State to show or not the page
  const [status, setStatus] = useState(true);

  //Function useHistory routers works
  const history = useHistory();

  //Function to avaible user session when component was rendered
  useEffect(() => {
    sidebar && closeSide();
    getSession().catch(() => {
      setStatus(false);
    });
    //eslint-disable-next-line
  }, [getSession]);

  //Function to go home
  const GoHome = () => {
    getGroup()
      .then((arrayGroups) => {
        arrayGroups.map((name) => {
          return name === 'super'
            ? history.replace('/global/super')
            : history.replace('/global/client');
        });
      })
      .catch((err) => {
        console.log('');
      });
  };

  return (
    <>
      {!sidebar ? (
        <Container sidebar={sidebar}>
          {status ? (
            <>
              <Ilustration />
              <h1>OoPS!</h1>
              <h2>Página não Encontrada!</h2>
              {!sidebar ? (
                <>
                  <p>
                    Infelizmente, parece que não conseguimos encontrar a página
                    que você está procurando.
                    <br />
                    Então volte para o início clicando no botão abaixo.
                  </p>

                  <IoseButton
                    onClick={() => {
                      GoHome();
                    }}
                  >
                    <HomeIcon />
                  </IoseButton>
                </>
              ) : (
                <p>
                  Infelizmente, parece que não conseguimos encontrar a página
                  que você está procurando aqui na sidebar.
                  <br />
                  Então atualize a página e tente novamente.
                </p>
              )}
            </>
          ) : (
            history.replace('/')
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
