import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { xlsxReports } from 'reports/Xlsx';
import { IoseButton2 } from 'components/IoseButton';
import GetAppIcon from '@material-ui/icons/GetApp';

export const IoseChartsFaturasTotais = ({ data, uc }) => {

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const sortedData = sortDataByMonthYear([...data]);

      setSortedData(sortedData)
    }
  }, [data]);

  const sortDataByMonthYear = (data) => {
    return data?.sort((a, b) => {
      const [monthA, yearA] = a.mes_referencia.split('/');
      const [monthB, yearB] = b.mes_referencia.split('/');
      const dateA = new Date(yearA, monthA - 1);
      const dateB = new Date(yearB, monthB - 1);
      return dateA - dateB;
    });
  };

  const months = sortedData?.map((item) => item.mes_referencia);
  const totals = sortedData?.map((item) => item.total);

  const formatValue = (value) => {
    return `R$ ${value.toLocaleString('pt-BR')}`;
  };

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '10%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params?.length > 0) {
          let tooltipContent = `${params[0].axisValue}<br />`;
          params.forEach((param) => {
            tooltipContent += `${param.seriesName}: ${formatValue(
              param.value
            )}<br />`;
          });
          return tooltipContent;
        }
        return 'Sem dados';
      },
    },
    xAxis: {
      type: 'category',
      data: months,
      axisTick: {
        alignWithLabel: true,
      },
      show: true,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        formatter: function (value) {
          return formatValue(value);
        },
      },
    },
    series: [
      {
        name: 'Total (R$)',
        type: 'line',
        barWidth: '30%',
        data: totals,
        color: '#015A9E',
        itemStyle: {
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            barBorderRadius: [10, 10],
          },
        },
        label: {
          show: true,
          position: 'bottom',
          formatter: function (params) {
            return formatValue(params.value);
          },
          color: '#000',
        },
      },
    ],
  };

  return (
    <div>
      {/* Botão de Download */}
      <div
        style={{
          width: '100%',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <IoseButton2
          onClick={() =>
            xlsxReports.exportFaturasTotaisConsumerGroupDashToExcel(
              sortedData,
              uc
            )
          }
          width="80px"
        >
          <GetAppIcon />
          <span style={{ marginRight: '5px' }}>XLSX</span>
        </IoseButton2>
      </div>

      {/* Gráfico */}
      {data && data.length > 0 ? (
        <ReactECharts option={option} style={{ height: '485px' }} />
      ) : (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="300px"
          width="280px"
        />
      )}
    </div>
  );
};
