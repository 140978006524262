import styled from 'styled-components';
import { Grid, Card, Typography, Table, TableCell, TableHead, TableRow, IconButton } from '@material-ui/core/';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 15px;
  gap: 15px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const ErrorText = styled.p`
  color: #d60606;
  font-weight: 600;
`;

export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
  width: 99%;
  margin-left:10px;
  margin-right:10px;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
`;

export const TableSecondaryHeadStyled = styled(TableHead)`
  background-color: #ecf0f5;
  border-radius: 15px;
  margin-top: 15px;
`;

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 12px;
  font-weight: bold;
  align-items: center;
`;

export const TypographyGeneral = styled(Typography)`
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`;

export const WrapContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const GraphDiv = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
`

export const GraphConsumoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const ConsumoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1px;
  flex-direction: row;
  padding: 15px 5px 5px 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;


export const SubHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
  color: ${props => props.color};
  font-size: 10px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const ListDiv = styled(Card)`
  margin-bottom: 15px;
  background-color: #ecf0f5;
  padding: 10px;
  border-radius: 10px;
`;

export const SpanLine = styled.span`
  margin-bottom: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

// style dos cards
export const CardGrid = styled(Grid)`
  margin: 0px;
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;

export const BoxContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 0px;
  padding: 10px 0 0 0px;
  width: 330px;
  height: 180px;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  transition: 0.5s ease;
  
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:not(:hover) {
    transform: scale(0.9);
  }

   @media (max-width: 1400px) {
    width: 250px;
  }
`;

export const CardDataKey = styled(Typography)`
  font-size: 11.7px;
  color: var(--primary);
  font-style: italic;
  font-weight: bold;
  transition: 0.5s ease;
  margin-bottom: 3px;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const CardDataValue = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color: var(--primary);
  transition: 0.5s ease;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 0 15px;
`;

export const CardTitle = styled(Typography)`
  font-size: 15px;
  color: var(--primary);
  font-weight: bold;

  @media (max-width: 1490px) {
    font-size: 13px;
  }
`;

export const IconHeader = styled.div`
  margin: 10px 8px 8px 0;
  color: var(--primary);
`;

export const BoxContainerHover = styled(BoxContainer)`
  &:hover {
    height: ${(props) => (props.showmore ? '220px' : '180px')};
    transform: ${(props) => (props.showmore ? 'scale(1.0)' : 'none')};
  }

  @media (max-width: 1400px) {
    &:hover {
      transform: ${(props) => (props.showmore ? 'scale(1.04)' : 'none')};
    }
  }
`;

export const ClickableIcon = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;
  color: var(--primary);
  top: 0px;

  ${BoxContainerHover}:hover & {
    opacity: 1;
  }
`;

// styles das tables
export const ModalTitle = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: #fff;
  text-align: center;
`;

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: black;
  margin-top: 6px;
`;

export const StyledHeaderModal = styled.div`
  background-color: var(--secondary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #4a5459;
  /* height: 450px; */
  width: auto;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 15px;
  gap: 162px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
`;

export const StyleTitle = styled.h2`
  text-align: left;
  color: var(--primary)
`;

export const Half = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: space-between;
`;