import React, { useState } from 'react';
import PropType from 'prop-types';

//Imports from Style
import { IoseTextFieldStyled } from './style';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

/**
 * This Component is used to create a inputs with style Iose in component outside sidebar
 */
export default function IoseTextField(props) {
  const {
    id,
    label,
    name,
    autoComplete,
    type,
    value,
    defaultvalue,
    onChange,
    helperText,
    onblur,
    error,
    placeholder,
  } = props;

  const [showPassword, setshowPassword] = useState(
    type === 'password' ? false : true
  );

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setshowPassword(!showPassword);
  };

  return (
    <IoseTextFieldStyled
      id={id}
      label={label}
      defaultValue={defaultvalue}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      autoComplete={autoComplete}
      error={error}
      helperText={helperText}
      onBlur={onblur}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment:
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton onClick={(e) => handleClickShowPassword(e)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
      }}
    />
  );
}

IoseTextField.propTypes = {
  /** This props is input id */
  id: PropType.string,
  /** This props get a input label*/
  label: PropType.string,
  /** This props get a input name*/
  name: PropType.string,
  /** This props get if input will go auto complete the sentence*/
  autoComplete: PropType.string,
  /** This props get the type information will be in the input*/
  type: PropType.string,
  /** This props is the value input*/
  value: PropType.string,
  /** This props is the value that is loading and first loading input*/
  defaultvalue: PropType.oneOfType([PropType.string, PropType.number]),
  /** This props is a function that will be executed when user hold enter*/
  onChange: PropType.func,
};
