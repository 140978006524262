import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function semConsumoReport(data) {
  const formatNumber = (number) => {
    const formattedNumber = number?.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    });
    return formattedNumber;
  };

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number?.toLocaleString('pt-br');
    return decimalNumber;
  }

  const valor_item = data?.map((item) => formatNumber(item.account_value));

  const totalValor = valor_item
    ?.reduce((accumulator, currentValue) => {
      const parsedValue = parseFloat(currentValue);
      if (!isNaN(parsedValue)) {
        return accumulator + parsedValue;
      }
      return accumulator;
    }, 0)
    .toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  const totalObjects = data?.length;

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Ucs sem consumo `,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('UC'),
    generateHeaderCell('Mês de Referência'),
    generateHeaderCell('Subgrupo'),
    generateHeaderCell('Consumido'),
    generateHeaderCell('Valor da Fatura (R$)'),
    generateHeaderCell('GG'),
    generateHeaderCell('GR'),
    generateHeaderCell('Concessionária'),
  ];

  const renderData = data?.map((bill) => [
    generateCell(bill.identification_number),
    generateCell(bill.reference_month),
    generateCell(bill.subgrupo),
    generateCell(bill.bill_consumption),
    generateCell(convertNumberToDecimalStyle(bill.account_value)),
    generateCell(bill.general_management),
    generateCell(bill.regional_management),
    generateCell(bill.concessionaire),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: Array(8).fill('*'),
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de unidades',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalObjects.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total valor ($)',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalValor.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [15, 15, 35, 500], // left, top, right, bottom
    };
  };
  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
