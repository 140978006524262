import { useState, useEffect, useContext } from 'react';
import { AccountContext, ioseAPI } from 'common';

const useTelemetryData = () => {
  const { getSession } = useContext(AccountContext);
  const { headers } = getSession();
  const initalDates = new Date();

  const [loading, setLoading] = useState(true);
  const [inicialDataConsumo, setInicialDataConsumo] = useState(null);
  const [finalDataConsumo, setFinalDataConsumo] = useState(initalDates);
  const [erroPeriodo, setErroPeriodo] = useState(null);
  const [consumosDiarios, setConsumosDiarios] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    const hoje = new Date();
    const trintaDiasAtras = new Date();
    trintaDiasAtras.setDate(hoje.getDate() - 7);
    setInicialDataConsumo(trintaDiasAtras);
  }, []);

  useEffect(() => {
    obterConsumoGraf30Dias(inicialDataConsumo, finalDataConsumo);
    //eslint-disable-next-line
  }, [inicialDataConsumo]);

  useEffect(() => {
    const verificarPeriodo = () => {
      const dataInicial = new Date(inicialDataConsumo);
      const dataFinal = new Date(finalDataConsumo);

      dataInicial.setHours(0, 0, 0, 0);
      dataFinal.setHours(0, 0, 0, 0);

      const diferencaEmDias = Math.ceil((dataFinal - dataInicial) / (1000 * 60 * 60 * 24));
      if (diferencaEmDias > 7) {
        setErroPeriodo('O período selecionado não pode ser maior que 7 dias.');
      } else {
        setErroPeriodo(null);
      }
    };


    verificarPeriodo();
    //eslint-disable-next-line
  }, [inicialDataConsumo, finalDataConsumo]);

  const obterConsumoGraf30Dias = async (data_inicial, data_final) => {
    const dataInicial = new Date(data_inicial);
    const dataFinal = new Date(data_final);

    const diferencaEmDias = Math.ceil((dataFinal - dataInicial) / (1000 * 60 * 60 * 24));
    if (diferencaEmDias > 7) {
      setErroPeriodo('O período selecionado não pode ser maior que 15 dias.');
      return;
    }

    const diaInicial = new Date(dataInicial);
    diaInicial.setUTCHours(0, 0, 0, 0);

    const diaFinal = new Date(dataFinal);
    diaFinal.setUTCHours(23, 59, 59, 999);

    const diaInicialISO = diaInicial.toISOString();
    const diaFinalISO = diaFinal.toISOString();

    try {
      const { data } = await ioseAPI.obterTotaisDiariosGraf30Telemetria(
        headers,
        diaInicialISO,
        diaFinalISO
      );
      setConsumosDiarios(data.data);
      setErroPeriodo(null);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loading,
    inicialDataConsumo,
    setInicialDataConsumo,
    finalDataConsumo,
    setFinalDataConsumo,
    erroPeriodo,
    consumosDiarios,
    obterConsumoGraf30Dias,
  };
};

export default useTelemetryData;
