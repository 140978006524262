import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropType from 'prop-types';

import { IoseUserButton, IoseProfileModal } from 'components';

import { LocalStorage } from 'common';

import { useSession } from 'common/contexts/session';

import { useCreateLogs } from 'hooks';

//Import from Styled Components
import * as S from './style';

import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Drawer,
  Grid,
  MenuItem,
  Menu,
  Collapse,
} from '@material-ui/core';

import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  MeetingRoom as MeetingRoomIcon,
  EmojiObjectsSharp as EmojiObjectsSharpIcon,
  FlashOn as FlashOnIcon,
  Map as MapIcon,
  WbIncandescent as WbIncandescentIcon,
  InsertDriveFile as InsertDriveFileIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Assessment as AssessmentIcon,
  DonutSmall as DonutSmallIcon,
  Timeline as TimelineIcon,
  LibraryBooks as LibraryBooksIcon,
  Note as NoteIcon,
  AttachMoney as AttachMoneyIcon,
  Tune as TuneIcon,
  Assignment as AssignmentIcon,
  BatteryChargingFull as BatteryChargingFullIcon,
  DataUsage as DataUsageIcon,
  WbSunny as WbSunnyIcon,
  Receipt as ReceiptIcon,
  FindInPage as FindInPageIcon,
  ThreeDRotation as ThreeDRotationIcon,
  AccountCircle as AccountCircleIcon,
  Money as MoneyIcon,
  AccessTime as AccessTimeIcon,
} from '@material-ui/icons';
import { IoseConfigButton } from 'components/IoseButtonIcon';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import useMenuGroupsPermissions from 'hooks/useMenuGroupsPermissions';

/**
 *
 *This is a Headerbar Static with Back Button and icon profile
 */
export default function IoseHeaderBar({ logoAction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [userGroupMenuPermissions, setuserGroupMenuPermissions] = useState();

  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const session = LocalStorage.getSession();
  const { clearSession } = useSession();
  const { createLog } = useCreateLogs();
  const { menuPermissions } = useMenuGroupsPermissions();
  const isMenuOpen = Boolean(anchorEl);

  const sessionData = LocalStorage.getSession();
  let userName = sessionData?.name;
  let userGroup = sessionData?.group;

  const clientData = JSON.parse(localStorage.getItem('clientData'));
  let userClient = clientData?.name;

  useEffect(() => {
    if (userGroup && menuPermissions && menuPermissions.length > 0) {
      const foundGroup = menuPermissions.find(
        (group) => group.group_name === userGroup
      );

      if (foundGroup) {
        setuserGroupMenuPermissions(foundGroup);
      }
    }
  }, [userGroup, menuPermissions]);

  const handleMenuClose = () => setAnchorEl(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const redirectConfiguration = () => {
    history.push('/map-configuration');
  };

  const handleProfileModalClose = () => setOpenModal(false);

  const handleProfileModalOpen = () => {
    handleMenuClose();
    setOpenModal(true);
  };

  const handleExit = async () => {
    const operacao = 'LOGOUT';
    const form = 'LOGOUT';
    const logContent = {};

    await createLog(operacao, form, logContent);

    handleMenuClose();
    clearSession();

    // const url =
    //   'https://sso-external.sanepar.com.br/auth/realms/sanepar-external/protocol/openid-connect/logout?redirect_uri=https://iose-sanepar.com/';

    // window.location.href = url;
  };

  const toggleSlider = () => {
    setOpen(!open);
  };

  const showBackbutton = () => {
    if (session.group === 'super') {
      return path.includes('super') ? false : true;
    } else {
      return path.includes('client') ? false : true;
    }
  };

  const renderBackAndUserButtons = () => {
    const showButton = showBackbutton();
    const userName = session?.group === 'super' ? 'Super' : session?.name;

    return (
      <S.ContainerButtons justify={showButton}>
        <S.UserContainer>
          {session?.group === 'super' && (
            <IoseConfigButton onClick={redirectConfiguration} />
          )}
          <S.UserName>{userName}</S.UserName>
          <IoseUserButton onClick={handleMenuOpen} />
        </S.UserContainer>
      </S.ContainerButtons>
    );
  };

  const renderMenu = () => (
    <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
      {session.group !== 'super' && (
        <MenuItem onClick={handleProfileModalOpen}>Perfil</MenuItem>
      )}
      <MenuItem onClick={handleExit}>Sair</MenuItem>
    </Menu>
  );

  const renderProfileModal = () => {
    return (
      <IoseProfileModal
        name={session?.name}
        openModal={openModal}
        handleClose={handleProfileModalClose}
        group={session?.group}
        uuidUser={session?.userId}
        email={session?.email}
      />
    );
  };

  //Sidebar Styles
  const useStyles = makeStyles((theme) => ({
    menuSliderContainer: {
      width: 400,
      background: 'var(--primary)',
      height: '100vh',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--primary)',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'var(--primary)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--primary)',
      },
    },
    drawerPaper: {
      // borderRadius: '0 20px 20px 0',
      overflowX: 'hidden',
      height: '100vh',
    },
    listItem: {
      color: 'var(--secondary)',
      transition: 'margin-left 0.3s ease',
      marginLeft: 0,
      '&:hover': {
        marginLeft: 10,
      },
    },
    text: {
      fontSize: 13,
      fontWeight: 'bold',
      transition: '.3s ease',
      '&:hover': {
        fontSize: 13.5,
        letterSpacing: 1.5,
      },
    },

    icon: {
      fontSize: 15,
      backgroundColor: 'yellow',
      borderRadius: 10,
      padding: 5,
    },
    selectedItem: {
      borderLeft: '4px solid gray',
    },
    subMenu: {
      marginLeft: theme.spacing(2),
    },
    arrowIcon: {
      transition: 'margin-left 0.3s ease',
      color: 'var(--back)',
    },
  }));

  const classes = useStyles();

  const checkAccess = (permissions, key) => {
    return (
      (permissions && permissions[key] && permissions[key].access) || false
    );
  };

  const handleListItemClick = (index) => {
    const listItem = sidebarItems[index];

    if (listItem.subItems) {
      if (openSubMenuIndex === index) {
        setOpenSubMenuIndex(null);
      } else {
        setOpenSubMenuIndex(index);
      }
    }

    setSelectedIndex(index);
  };

  const handleSubItemClick = (parentIndex, subIndex, subItem) => {
    if (subItem.listText === 'Administrativo') {
      history.push('/admin-dashboard/client');
    } else if (subItem.listText === 'Faturas de Energia') {
      history.push('/energy-bills');
    } else if (subItem.listText === 'Mercado Livre') {
      history.push('/mercado-livre-dashboard');
    } else if (subItem.listText === 'Unidades') {
      history.push('/global/client');
    } else if (subItem.listText === 'Comparação de Consumo') {
      history.push('/consumption-comparison');
    } else if (subItem.listText === 'Ambientes') {
      history.push('/environments');
    } else if (subItem.listText === 'Gemeo Digital') {
      history.push('/gemeo-digital');
    } else if (subItem.listText === 'Objetos') {
      history.push('/objects');
    } else if (subItem.listText === 'Faturas de energia') {
      history.push('/energy-bills');
    } else if (subItem.listText === 'Circuitos') {
      history.push('/circuits');
    } else if (subItem.listText === 'Concessionária') {
      history.push('/concessionaires');
    } else if (subItem.listText === 'Mapa Geo') {
      history.push('/map');
    } else if (subItem.listText === 'Configurações gerais') {
      history.push('/map-configuration');
    } else if (subItem.listText === 'Unidade Consumidora') {
      history.push('/consumer-group');
    } else if (subItem.listText === 'Contratos') {
      history.push('/contracts');
    } else if (subItem.listText === 'Contratos ') {
      history.push('/contracts-list');
    } else if (subItem.listText === 'Auditoria de Contas') {
      history.push('/energy-bills-audit');
    } else if (subItem.listText === 'Tarifas') {
      history.push('/tariffs');
    } else if (subItem.listText === 'Medição') {
      history.push('/measurement-report');
    } else if (subItem.listText === 'Unidades Beneficiárias') {
      history.push('/credit');
    } else if (subItem.listText === 'SGF') {
      history.push('/export-sgf');
    } else if (subItem.listText === 'BIENE') {
      history.push('/export-biene');
    } else if (subItem.listText === 'Geradoras X Beneficiárias') {
      history.push('/geradorasxbeneficiarias');
    } else if (subItem.listText === 'Variáveis do Sistema') {
      history.push('/system-variables');
    } else if (subItem.listText === 'Geradoras') {
      history.push('/saneparcredit');
    } else if (subItem.listText === 'Beneficiárias') {
      history.push('/credit');
    } else if (subItem.listText === 'Créditos') {
      history.push('/sanepar-report');
    } else if (subItem.listText === 'ECUs') {
      history.push('/solar-ecu');
    } else if (subItem.listText === 'Dashboard de créditos') {
      history.push('/credit-dashboard');
    } else if (subItem.listText === 'Log de Faturas') {
      history.push('/energy-bill-logs');
    } else if (subItem.listText === 'Busca de Faturas') {
      history.push('/search-energy-bill');
    } else if (subItem.listText === '3D') {
      history.push('/bim-unitys');
    } else if (subItem.listText === 'Recálculo de Faturas') {
      history.push('/recalc-energy-bill');
    } else if (subItem.listText === 'Log de usuários') {
      history.push('/user-logs');
    } else if (subItem.listText === 'Emissão de carbono') {
      history.push('/carbon-report');
    } else if (subItem.listText === 'Fator CO2') {
      history.push('/carbon-tariffs');
    } else if (subItem.listText === 'Emissão de carbono') {
      history.push('/carbon-monthly');
    } else if (subItem.listText === 'Simulador de Contratos') {
      history.push('/simulator');
    } else if (subItem.listText === 'Itens da Fatura') {
      history.push('/search-item-bill-report');
    } else if (subItem.listText === 'Comparação de Consumo ') {
      history.push('/telemetry-consumption-comparison');
    } else if (subItem.listText === 'Mapa Telemetria') {
      history.push('/map-telemetry');
    } else if (subItem.listText === 'Dashboard de Telemetria') {
      history.push('/telemetry-dash');
    } else if (subItem.listText === 'Faturas') {
      history.push('/fatura-report');
    } else if (subItem.listText === 'Usuários X Alarmes') {
      history.push('/users-alarms');
    } else if (subItem.listText === 'Telemetria') {
      history.push('/telemetry-report');
    } else if (subItem.listText === 'Contábil') {
      history.push('/contabil-mercado-livre-report');
    } else if (subItem.listText === 'Comercializadoras') {
      history.push('/comercializadoras');
    } else if (subItem.listText === 'Faturas Anual') {
      history.push('/summary-dash-year');
    } else if (subItem.listText === 'Desperdícios') {
      history.push('/desperdicios-report');
    }
  };

  //Sidebar items
  const sidebarItems = [
    {
      listIcon: <DashboardIcon />,
      listText: 'Dashboards',
      subItems: [
        {
          listIcon: <AssessmentIcon />,
          listText: 'Administrativo',
          permissionKey: 'administrativo',
        },
        {
          listIcon: <DonutSmallIcon />,
          listText: 'Faturas de Energia',
          permissionKey: 'faturas_energia',
        },
        {
          listIcon: <ShoppingCartIcon />,
          listText: 'Mercado Livre',
          permissionKey: 'mercado_livre',
        },
        {
          listIcon: <TimelineIcon />,
          listText: 'Comparação de Consumo',
          permissionKey: 'comparacao_consumo',
        },
        {
          listIcon: <ThreeDRotationIcon />,
          listText: '3D',
          permissionKey: '3d',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Emissão de carbono',
          permissionKey: 'emissao_carbono',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Faturas Anual',
          permissionKey: 'faturas_anual',
        },
      ],
      permissionCategory: 'dashboards',
    },
    {
      listIcon: <TuneIcon />,
      listText: 'Cadastro',
      subItems: [
        {
          listIcon: <BusinessIcon />,
          listText: 'Unidades',
          permissionKey: 'unidades',
        },
        {
          listIcon: <MeetingRoomIcon />,
          listText: 'Ambientes',
          permissionKey: 'ambientes',
        },
        {
          listIcon: <EmojiObjectsSharpIcon />,
          listText: 'Objetos',
          permissionKey: 'objetos',
        },
        {
          listIcon: <FlashOnIcon />,
          listText: 'Circuitos',
          permissionKey: 'circuitos',
        },
        {
          listIcon: <WbIncandescentIcon />,
          listText: 'Concessionária',
          permissionKey: 'concessionarias',
        },
        {
          listIcon: <WbIncandescentIcon />,
          listText: 'Comercializadoras',
          permissionKey: 'comercializadoras',
        },
        {
          listIcon: <NoteIcon />,
          listText: 'Unidade Consumidora',
          permissionKey: 'unidades_consumidoras',
        },
        {
          listIcon: <LibraryBooksIcon />,
          listText: 'Contratos',
          permissionKey: 'contratos',
        },
        {
          listIcon: <WbSunnyIcon />,
          listText: 'ECUs',
          permissionKey: 'ecus',
        },
        {
          listIcon: <AttachMoneyIcon />,
          listText: 'Tarifas',
          permissionKey: 'tarifas',
        },
        {
          listIcon: <AttachMoneyIcon />,
          listText: 'Fator CO2',
          permissionKey: 'fator_co2',
        },
        {
          listIcon: <BusinessIcon />,
          listText: 'Gemeo Digital',
          permissionKey: 'gemeo_digital',
        },
      ],
      permissionCategory: 'cadastro',
    },
    {
      listIcon: <InsertDriveFileIcon />,
      listText: 'Gerenciamento',
      subItems: [
        {
          listIcon: <LibraryBooksIcon />,
          listText: 'Contratos ',
          permissionKey: 'gerenciamento_contratos',
        },
        {
          listIcon: <AccountCircleIcon />,
          listText: 'Log de usuários',
          permissionKey: 'log_usuarios',
        },
      ],
      permissionCategory: 'gerenciamento',
    },
    {
      listIcon: <AssignmentIcon />,
      listText: 'Relatórios',
      subItems: [
        {
          listIcon: <AssignmentIcon />,
          listText: 'Medição',
          permissionKey: 'medicao',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Telemetria',
          permissionKey: 'telemetria',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Créditos',
          permissionKey: 'creditos',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Faturas',
          permissionKey: 'faturas',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Emissão de carbono',
          permissionKey: 'emissao_carbono',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Itens da Fatura',
          permissionKey: 'itens_fatura',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Desperdícios',
          permissionKey: 'desperdicios_report',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'SGF',
          permissionKey: 'sgf',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'BIENE',
          permissionKey: 'biene',
        },
        {
          listIcon: <AssignmentIcon />,
          listText: 'Contábil',
          permissionKey: 'contabil',
        },
      ],
      permissionCategory: 'relatorios',
    },
    {
      listIcon: <AccessTimeIcon />,
      listText: 'Telemetria',
      subItems: [
        {
          listIcon: <DashboardIcon />,
          listText: 'Dashboard de Telemetria',
          permissionKey: 'dashboard_telemetria',
        },
        {
          listIcon: <AccessTimeIcon />,
          listText: 'Comparação de Consumo ',
          permissionKey: 'comparacao_consumo',
        },

        {
          listIcon: <MapIcon />,
          listText: 'Mapa Telemetria',
          permissionKey: 'mapa_telemetria',
        },
        {
          listIcon: <MapIcon />,
          listText: 'Usuários X Alarmes',
          permissionKey: 'usuarios_alarmes',
        },
      ],
      permissionCategory: 'telemetria',
    },
    {
      listIcon: <BatteryChargingFullIcon />,
      listText: 'Geração',
      subItems: [
        {
          listIcon: <BatteryChargingFullIcon />,
          listText: 'Dashboard de créditos',
          permissionKey: 'dashboard_creditos',
        },
        {
          listIcon: <BatteryChargingFullIcon />,
          listText: 'Geradoras X Beneficiárias',
          permissionKey: 'geradoras_beneficiarias',
        },
        {
          listIcon: <BatteryChargingFullIcon />,
          listText: 'Geradoras',
          permissionKey: 'geradoras',
        },
        {
          listIcon: <BatteryChargingFullIcon />,
          listText: 'Beneficiárias',
          permissionKey: 'beneficiarias',
        },
      ],
      permissionCategory: 'geracao',
    },
    {
      listIcon: <MapIcon />,
      listText: 'Mapas',
      subItems: [
        {
          listIcon: <MapIcon />,
          listText: 'Mapa Geo',
          permissionKey: 'mapa_geo',
        },
      ],
      permissionCategory: 'mapas',
    },
    {
      listIcon: <DataUsageIcon />,
      listText: 'Parâmetros',
      subItems: [
        {
          listIcon: <DataUsageIcon />,
          listText: 'Variáveis do Sistema',
          permissionKey: 'variaveis_sistema',
        },
      ],
      permissionCategory: 'parametros',
    },
    {
      listIcon: <ReceiptIcon />,
      listText: 'Faturas',
      subItems: [
        {
          listIcon: <FindInPageIcon />,
          listText: 'Busca de Faturas',
          permissionKey: 'busca_faturas',
        },
        {
          listIcon: <DonutSmallIcon />,
          listText: 'Log de Faturas',
          permissionKey: 'log_faturas',
        },
        {
          listIcon: <MoneyIcon />,
          listText: 'Simulador de Contratos',
          permissionKey: 'simulador_contratos',
        },
      ],
      permissionCategory: 'faturas',
    },
  ];

  const renderSubMenu = (subItems, parentIndex) => (
    <Collapse
      in={openSubMenuIndex === parentIndex}
      timeout="auto"
      unmountOnExit
    >
      <List className={classes.subMenu}>
        {subItems.map((subItem, subIndex) => (
          <div key={subIndex}>
            <ListItem
              className={classes.listItem}
              button
              onClick={() => handleSubItemClick(parentIndex, subIndex, subItem)}
            >
              <ListItemIcon className={classes.listItem}>
                {subItem.listIcon}
              </ListItemIcon>
              <S.StyledListItemText
                primary={subItem.listText}
                classes={{ primary: classes.text }}
              />
            </ListItem>
          </div>
        ))}
      </List>
    </Collapse>
  );

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <S.LogoSidebarContainer>
        <S.LogoSideBar className={classes?.Logo} />
      </S.LogoSidebarContainer>

      <S.UserNameSidebarContainer>{userName}</S.UserNameSidebarContainer>

      <S.ClientNameSidebarContainer ClientNameSidebarContainer>
        {userClient ? userClient : ' '}
      </S.ClientNameSidebarContainer>
      <List>
        {sidebarItems.map((listItem, index) => {
          const hasAccess = checkAccess(
            userGroupMenuPermissions?.permissions,
            listItem.permissionCategory
          );

          // Se não tiver acesso ao item principal, não renderize-o
          if (!hasAccess) return null;

          return (
            <div key={index}>
              <ListItem
                className={`${classes.listItem} ${
                  selectedIndex === index ? classes.selectedItem : ''
                }`}
                button
                onClick={() => handleListItemClick(index)}
              >
                <ListItemIcon className={classes.listItem}>
                  {listItem.listIcon}
                </ListItemIcon>
                <S.StyledListItemText
                  primary={listItem.listText}
                  classes={{ primary: classes.text }}
                />
                {listItem.subItems && (
                  <ListItemIcon className={classes.arrowIcon}>
                    {openSubMenuIndex === index ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </ListItemIcon>
                )}
              </ListItem>

              {listItem.subItems &&
                renderSubMenu(
                  listItem.subItems.filter(
                    (subItem) =>
                      userGroupMenuPermissions?.permissions[
                        listItem.permissionCategory
                      ]?.[subItem.permissionKey]
                  ),
                  index
                )}
            </div>
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: '#015A9E' }}>
          {session.group !== 'super' ? (
            <>
              <IconButton onClick={toggleSlider}>
                <S.StyledMenuIcon />
              </IconButton>
            </>
          ) : null}

          <Grid container>
            <S.GridLeft>
              <S.Logo onClick={logoAction} />
            </S.GridLeft>
            <S.GridRight>{renderBackAndUserButtons()}</S.GridRight>
          </Grid>
          <Drawer
            open={open}
            anchor="left"
            onClose={toggleSlider}
            classes={{ paper: classes.drawerPaper }}
          >
            {sideList()}
          </Drawer>
        </Toolbar>
      </AppBar>

      {renderMenu()}

      {renderProfileModal()}
    </>
  );
}

IoseHeaderBar.propTypes = {
  /** This prop get a function to execute when the logo is clicked */
  logoAction: PropType.func,
  /** This prop get the name of current user from cognito*/
  usernameCognito: PropType.string,
  /** This prop get a function to execute when the BackButton is clicked*/
  backButtonClick: PropType.func,
  /** This props is the user group*/
  group: PropType.string,
};
