import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseAlertNoExtract } from 'components/IoseAlert';

function aggregateCreditsByMonth(data) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        energy_credits: 0,
        credits_consumption: 0,
      };
    }

    if (item.referente === 'Compensação crédito energia') {
      aggregatedData[month].energy_credits += item.referente_total_value;
      aggregatedData[month].credits_consumption += item.referente_consumption;
    }
  });

  const aggregatedArray = Object.values(aggregatedData);

  aggregatedArray.sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);

    return dateA - dateB;
  });

  return aggregatedArray.map((item) => ({
    ...item,
    energy_credits: Math.abs(item.energy_credits),
    credits_consumption: Math.abs(item.credits_consumption),
  }));
}

export const GraficoCreditoEnergia = ({ data }) => {

  const [formattedCredits, setFormattedCredits] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setFormattedCredits(aggregateCreditsByMonth(data));
    }
  }, [data]);

  const months = formattedCredits.map((item) => item.reference_month);
  const credits = formattedCredits.map((item) => item.energy_credits);
  const credits_consumption = formattedCredits.map(
    (item) => item.credits_consumption
  );

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params.map((param) => {
          const formattedValue = parseFloat(param.value).toLocaleString(
            'pt-BR',
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });

        const xAxisValue = params[0]?.axisValueLabel;
        const rate =
          credits_consumption[params[0].dataIndex] !== 0
            ? (
                credits[params[0].dataIndex] /
                credits_consumption[params[0].dataIndex]
              ).toFixed(2)
            : '0';

        const formattedRate = parseFloat(rate).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);
        formattedParams.push(`<strong>R$/kWh:</strong> ${formattedRate}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {},
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
        dataView: { show: true, readOnly: false },
      },
    },
    grid: {
      width: '92%',
      left: '2%',
      right: '2%',
      top: '10%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: months,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        formatter: (value) => value.toLocaleString('pt-BR'),
      },
    },
    series: [
      {
        name: 'Desconto na fatura (R$)',
        type: 'line',
        data: credits,
        barWidth: 50,
      },
      {
        name: 'Créditos utilizados (kWh)',
        type: 'bar',
        data: credits_consumption,
        barWidth: 50,
      },
    ],
  };

  return formattedCredits.length > 0 &&
    credits.some((credit) => credit !== 0) ? (
    <ReactECharts option={option} style={{ height: 475 }} />
  ) : (
    <IoseAlertNoExtract text="Esta UC não tem creditos de energia no período." />
  );
};
