// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCalculateAllGMConsumption() {
  const [noDataError, setNoDataError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCalculateAllGMConsumption = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getCalculateAllGMConsumption(headers);

    const totalGeneralConsumptions = response.data.data || [];

    if (totalGeneralConsumptions.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return totalGeneralConsumptions;
  };

  const {
    data: totalGeneralConsumptions,
    error: totalGeneralConsumptionsError,
    refetch: refreshGeneralConsumptions,
    isLoading,
  } = useQuery(['totalGeneralConsumptions'], getCalculateAllGMConsumption);

  return {
    totalGeneralConsumptions,
    totalGeneralConsumptionsError,
    noDataError,
    refreshGeneralConsumptions,
    isLoading,
  };
}

export default useCalculateAllGMConsumption;
