import React from 'react';
import PropTypes from 'prop-types';

//Imports from IOSE Components
import { IoseEditButton } from 'components/IoseButtonIcon';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';

//Imports from Style
import {
  Container,
  Icon,
  DivHeaderButton,
  CardHeaderStyled,
  AvatarStyledHeader,
  Content,
  ContainerInityScroll,
} from './style';

//imports from Material UI components
import { Grow } from '@material-ui/core';

//Import from Messages
import Messages from '../../common/Messages';

/**
 * This component render a list of cards containing employee info
 *
 */
export default function IoseEmployeeShowCard(props) {
  const {
    clickDelete,
    clickEdit,
    arrayEmployee,
    loadingMore,
    endList,
    getMoreEmployees,
  } = props;

  const renderEmployeeCard = (employee) => {
    return (
      <Grow in={true} {...{ timeout: 1000 }} key={employee.uuid_employee}>
        <Container>
          <DivHeaderButton disableSpacing>
            <CardHeaderStyled
              avatar={
                <AvatarStyledHeader>
                  <Icon
                    operator={
                      employee.group.includes('operator') ? 'true' : 'false'
                    }
                  />
                </AvatarStyledHeader>
              }
              title={employee.name}
              subheader={employee.email}
            ></CardHeaderStyled>

            <IoseEditButton
              onClick={(e) =>
                clickEdit(employee.uuid_employee, employee.name, employee.email)
              }
              tooltip="Editar Funcionário"
            />
            <IoseDeleteInputModal
              title="DELETAR FUNCIONÁRIO"
              name={employee.name}
              deletefunc={(e) => clickDelete(employee.uuid_employee)}
              left={'10px'}
            />
          </DivHeaderButton>
          <Content>
            <p>
              {employee.group === 'operator'
                ? 'Operador'
                : employee.group === 'operator-activate'
                ? 'Operador com acionamento'
                : 'Gerente'}
            </p>
          </Content>
        </Container>
      </Grow>
    );
  };

  return (
    <>
      {arrayEmployee.length === 0 ? (
        <Container padding={'16px'}>
          <p>{Messages.noEmployeeRegistered}</p>
        </Container>
      ) : (
        <ContainerInityScroll id="sidebarContainer">
          <IoseInfinityScroll
            dataLength={arrayEmployee.length}
            next={(e) => getMoreEmployees()}
            hasMore={true}
            loading={loadingMore}
            endList={endList}
            sidebar={true}
            scrollableTarget={'sidebarContainer'}
          >
            {arrayEmployee.map((employee) => renderEmployeeCard(employee))}
          </IoseInfinityScroll>
        </ContainerInityScroll>
      )}
    </>
  );
}

IoseEmployeeShowCard.propTypes = {
  /** This props is a function to delete employee in container*/
  clickDelete: PropTypes.func,
  /** This props is a function to edit employee in container*/
  clickEdit: PropTypes.func,
  /** This props is a array objects this format
   *
    {
      email: "Testing@test.com",
      group: "manager",
      name: "teste",
      uuid_client: "daf4abcb-429d-4298-a9a6-ce7517fb1449",
      uuid_employee: "0ec04267-e2e6-47e5-8f7f-07b180149961",
      uuid_unity: "f283ceb6-104b-4526-bf81-3056f9bb9ce1",
    }
  */
  arrayEmployee: PropTypes.array,
  /** This props is  loading related InfinityScroll*/
  loadingMore: PropTypes.bool,
  /** This props is a function to get more emploeed when user was in end list related InfinityScroll*/
  getMoreEmployees: PropTypes.func,
  /** This props is boolean that indicates that uswer be in end list, related InfinityScroll*/
  endList: PropTypes.bool,
};
