import React, { createContext, useContext } from 'react';

import IoseApiLib from 'iose-api-lib';

//Imports from common
import IoseCognito from 'common/iose_cognito';
import LocalStorage from 'common/local_storage_api';

import { useHistory } from 'react-router-dom';

const SessionContext = createContext({});

export default function SessionProvider(props) {
  const history = useHistory();

  const redirectToLogin = (message) => {
    let data = {
      pathname: '/',
      state: {
        message: message,
      },
    };
    return history.push(data);
  };

  const clearSession = async (message) => {
    IoseCognito.logout();
    LocalStorage.removeAll();
    redirectToLogin(message);
  };

  const verifySession = async (session) => {
    try {
      const newTokenData = await IoseCognito.checkTokenExpiration(
        session.expiration,
        session.email,
        session.refreshToken
      );

      if (newTokenData.status) {
        const refreshSession = {
          ...session,
          expiration: newTokenData.data.expiration,
          idToken: newTokenData.data.idToken,
          accessToken: newTokenData.data.accessToken,
          refreshToken: newTokenData.data.refreshToken,
          newToken: true,
        };
        await IoseApiLib.setToken(refreshSession.idToken);

        LocalStorage.storageSession(refreshSession);

        return true;
      } else {
        return true;
      }
    } catch (error) {
      clearSession(error.message);
    }
  };

  return (
    <SessionContext.Provider value={{ verifySession, clearSession }}>
      {props.children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  const { clearSession, verifySession } = context;
  return { verifySession, clearSession };
}
