import React from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseNoDataMessage } from 'components';

export default function GraficoFatorPotenciaPorPacoteQ4({ consumos }) {
  const datas = consumos?.map(item => item.arrival_date);
  const fatorPotencia = consumos?.map(item => item.fator_potencia_q4);

  const formatFatorPotencia = (value) => {
    return parseFloat(value).toFixed(4);
  };

  const markLines = [];
  if (fatorPotencia) {
    markLines.push({
      yAxis: 0.92,
      label: { formatter: 'Limite' },
      lineStyle: {
        type: 'solid',
        color: '#D60606'
      }
    });
  }

  const option = {
    grid: {
      left: '2%',
      right: '5%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const formattedParams = params.map(param => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value).toLocaleString('pt-BR')
              : param.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        // Adiciona o valor da markLine na tooltip com ponto trocado por vírgula
        const markLineValue = markLines[0]?.yAxis;
        if (markLineValue !== undefined) {
          const formattedMarkLineValue = markLineValue.toString().replace('.', ',');
          formattedParams.push(`<strong>Limite:</strong> ${formattedMarkLineValue}`);
        }

        return formattedParams.join('<br>') || 'Sem dados';
      }
    },
    legend: {
      data: [
        'Limite'
      ]
    },
    toolbox: {
      show: true,
      feature: {
        magicType: { show: true, type: ['line', 'bar'] },
        saveAsImage: { show: true }
      }
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        data: datas,
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toFixed(2).replace('.', ',');
          },
        },
        minInterval: 0.1,
        interval: 0.1,
      }
    ],
    series: [
      {
        name: 'Fator potência - Q4',
        type: 'bar',
        data: fatorPotencia?.map(value => {
          const formattedValue = parseFloat(formatFatorPotencia(value));
          return {
            value: formattedValue,
            itemStyle: {
              color: formattedValue >= 0.9200 ? '#87E35A' : '#a90000'
            }
          };
        })
      },
      ...(fatorPotencia ? [{
        name: 'Limite',
        type: 'line',
        itemStyle: {
          normal: {
            color: '#D60606',
            lineStyle: {
              type: 'solid'
            }
          }
        },
        markLine: { data: markLines }
      }] : []),
    ]
  };

  return consumos?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 300 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="200px"
      width="200px"
    />
  );
}
