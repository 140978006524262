import React, { useState } from "react";
import BillsModalExtractContentHeader from "./bills-modal-extract-content-header";
import * as S from '../style'
import IoseNavButton from "components/IoseNavButton";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BillsModalExtractTable from "./bills-modal-extract-table";
import BillsModalExtractSubHeader from "./bills-modal-extract-sub-header";
import { useConsumerGroup } from "hooks";
import { IoseAlertNoExtract } from "components/IoseAlert";

export default function BillsModalExtractContent({ extracts }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { consumerGroups } = useConsumerGroup();

  if (extracts) {
    // Extrair todas as páginas disponíveis a partir dos itens
    const pages = [...new Set(extracts?.map(item => parseInt(item?.pagina?.split('/')[0], 10)))];
    pages.sort((a, b) => a - b);

    // Filtrar itens para a página atual
    const currentItems = extracts?.filter(item => parseInt(item?.pagina?.split('/')[0], 10) === currentPage);

    const handlePageChange = (direction) => {
      if (direction === 'next' && currentPage < pages[pages.length - 1]) {
        setCurrentPage(prevPage => prevPage + 1);
      } else if (direction === 'previous' && currentPage > pages[0]) {
        setCurrentPage(prevPage => prevPage - 1);
      }
    };

    let address = consumerGroups?.filter(
      (item) => item?.identification_number === currentItems[0]?.identification_number
    )?.[0]?.address;

    return (
      <div>
        <BillsModalExtractSubHeader currentExtract={currentItems}/>
        <S.Container>
          <div style={{ width: '96vw' }}>
            <div>
              <BillsModalExtractContentHeader ucAddress={address} currentExtract={currentItems} />
              <BillsModalExtractTable extracts={currentItems}/>
            </div>
            <S.NavContainer>
              <IoseNavButton
                icon={<NavigateBeforeIcon />}
                tooltip="ANTERIOR"
                onClick={() => handlePageChange('previous')}
                disabled={currentPage === pages[0]}
              />
              <div>
                <S.pageNumbers>
                  {`Página ${currentPage} de ${pages.length}`}
                </S.pageNumbers>
              </div>
              <IoseNavButton
                icon={<NavigateNextIcon />}
                tooltip="PRÓXIMO"
                onClick={() => handlePageChange('next')}
                disabled={currentPage === pages[pages.length - 1]}
              />
            </S.NavContainer>
          </div>
        </S.Container>
      </div>
    );
  } else {
    return (
      <div style={{marginTop: '250px'}}>
        <IoseAlertNoExtract text="Esta UC ainda não possui extrato."/>;
      </div>
    );

  }


}