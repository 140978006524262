import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const GridStyled = styled(Grid)`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;
export const FormDivContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  margin: 10px 0 0 0;
  align-items: center;
`;

export const FormDivItem = styled.div`
  width: 150px;
`;

export const GridContainer = styled.div`
  padding: 0px 10px 5px 25px;

  @media (max-width: 500px) {
    width: 100%;
  }
  background-color: white;
  padding-top: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const WrapContainer = styled.div`
  padding: 15px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  border-radius: 4px;
  -webkit-box-align: center !important;
  align-items: center !important;
  margin-top: 7px !important;
  width: 37%;
  padding: 6px;
`;
