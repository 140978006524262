import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useExtractsByIdentificationNumber(consumerGroup) {
  const { getSession } = useContext(AccountContext);

  // const pageSize = 300;

  const getExtracts = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getExtractsByIdentificationNumber(
      headers,
      consumerGroup
    );

    const extracts = response.data

    if (extracts.length === 0) {
      console.error(Messages.noFindExctracts);
      throw new Error(Messages.noFindExctracts);
    }
    return extracts;
  };

  const {
    data: extracts,
    error: extractsError,
    refetch: refreshExtracts,
    isLoading,
  } = useQuery(['consumerGroupExtracts', consumerGroup], getExtracts);

  return {
    extracts,
    extractsError,
    refreshExtracts,
    isLoading,
  };
}

export default useExtractsByIdentificationNumber;
