import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function GraficoGeracao(props) {
  const dados = props.data;
  var gerado = [];
  var date = [];
  let reativo = [];
  const geracao_estimada = props.goal;

  dados.forEach((obj) => {
    gerado.push(obj.gerado);
    date.push(obj.date);
    reativo.push(obj.reativoGerado);
  });

  const option = {
    grid: {
      left: '2%',
      right: '10%',
      bottom: '5%',
      top: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params?.map((param) => {
          if (param.value === undefined) return '';
          const formattedValue =
            typeof param?.value === 'string'
              ? parseFloat(param?.value).toLocaleString('pt-BR')
              : param?.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: ['Geração (kWh)', 'Estimado (kWh)', 'Reativo (VArh)'],
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: 'category',
        data: date,
        name: 'Dias',
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        name: 'Geração (kWh)',
        type: 'bar',
        data: gerado,
        emphasis: {
          focus: 'series',
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Média',
            },
          ],
        },
        itemStyle: {
          color: '#E4A619',
        },
      },
      {
        name: 'Estimado (kWh)',
        type: 'bar',
        data: geracao_estimada,
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#87E35A',
        },
      },
      {
        name: 'Reativo (VArh)',
        type: 'line',
        data: reativo,
        itemStyle: {
          color: '#a90000',
        },
        showSymbol: false,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 380, width: '99%' }} />;
}
