import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';


function isWeekend(dateString) {
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);
  return date.getDay() === 0 || date.getDay() === 6;
}

export default function GraficoEstimadosMes(props) {

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const estimado = props.estimado
  const dados = props.data;
  var consumed = [];

  dados.forEach((obj) => {
    const [day, month] = obj.date.split('/');
    const year = new Date().getFullYear();
    const dateObj = new Date(year, month - 1, day);
    if (new Date(dateObj).getMonth() === new Date().getMonth()) {
      consumed.push({'data':echarts.time.format(dateObj,'{yyyy}-{MM}-{dd}', false), 'valor': obj.consumido});
    }
  });

  const processedData = consumed.map(item => {
    return {
      ...item,
      isWeekend: isWeekend(item.data)
    };
  });

  const weekdayData = processedData.filter(item => !item.isWeekend).map(item => [item.data, item.valor]);
  const weekendData = processedData.filter(item => item.isWeekend).map(item => [item.data, item.valor]);

  const combinedOption = {
    tooltip: {},
    visualMap: [
      {

        show: true,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        selectedMode: false,
        top: 5,
        pieces: [
          { min: 0.1, max: estimado, label: 'Consumo normal', color: 'green' },
          { min: estimado+1, max: 10000, label: 'Consumo alto', color: '#f03b20' }
        ],
        formatter: function (label) {
          return label;
        },
        seriesIndex: 0,
      },
      {

        show: false,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        selectedMode: false,
        top: 40,
        pieces: [
          { min: 0.1, max: estimado/5, label: 'Consumo normal', color: 'green' },
          { min: (estimado/5)+1, max: 10000, label: 'Consumo alto', color: '#f03b20' }
        ],
        formatter: function (label) {
          return label;
        },
        seriesIndex: 1,
      }
    ],
    calendar: {
      top: 80,
      left: 30,
      right: 30,
      cellSize: ['auto'],
      orient: 'vertical',
      range: `${currentYear}-${currentMonth}`,
      itemStyle: {
        normal: {
          backgroundColor: 'var(--secundary)'
        },
        borderWidth: 0.5
      },
      dayLabel: {
        nameMap: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
      },
      monthLabel: {
        nameMap: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      },
      yearLabel: { show: false }
    },
    series: [
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: weekdayData
      },
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: weekendData
      }
    ]
  };

return <ReactECharts option={combinedOption} style={{ height: 300, width: '100%', opacity: '10% !important' }} />;










}
