import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useAclByIdentificationNumber(consumerGroup) {
  const { getSession } = useContext(AccountContext);

  const getFaturasAcl = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAclByIdentificationNumber(
      headers,
      consumerGroup
    );

    const faturasAcl = response.data

    if (faturasAcl.length === 0) {
      console.error(Messages.noFindAcl);
      throw new Error(Messages.noFindAcl);
    }
    return faturasAcl;
  };

  const {
    data: faturasAcl,
    error: faturasAclError,
    refetch: refreshAcl,
    isLoading,
  } = useQuery(['faturasAcl', consumerGroup], getFaturasAcl);

  return {
    faturasAcl,
    faturasAclError,
    refreshAcl,
    isLoading,
  };
}

export default useAclByIdentificationNumber;
