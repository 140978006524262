import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import IoseApiLib from 'iose-api-lib';

//Import from common
import Messages from 'common/Messages';

//Import from Material UI
import { Grow } from '@material-ui/core/';
import FilterListIcon from '@material-ui/icons/FilterList';

//Import from IOSE
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseAlertLoadingCards } from 'components/IoseAlert';
import { IoseCloseButton } from 'components/IoseButtonIcon';
import IoseEventsTable from 'components/IoseEventsTable';
import IoseChoosePeriod from 'components/IoseChoosePeriod';
import IoseButton from 'components/Buttons/IoseButton';
import { IoseDowloadCSVModalEvents } from 'components/Modals/IoseModals';
import IoseTextFieldSidebar from 'components/IoseTextFieldSidebar';

//Imports from style
import {
  GridContainer,
  IoseContainerStyled,
  ContainerContent,
  ContainerButtons,
  IoseButtonStyled,
  CollapseStyled,
  GridContainerFilter,
  GridItem,
  ContainerFilterButton,
  AlertEvents,
  CheckBoxStyled,
  CircularProgressStyled,
} from './style';

/**This component create a container for show, edit and delete  unity schedulings */
export default function EventsContainer() {
  const location = useLocation();

  const circuitData = location.state.circuitData;
  const unityData = location.state.unityData;

  const headerFileCSV = [
    { id: 'created_at', displayName: 'Data' },
    { id: 'type', displayName: 'Tipo' },
    { id: 'relay', displayName: 'Relé' },
    { id: 'network', displayName: 'Conexão' },
    { id: 'power', displayName: 'Carga' },
  ];

  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const [error, setError] = useState('');

  const [allEvents, setAllEvents] = useState([]);

  const [dataTable, setDataTable] = useState([]);

  const [expand, setExpand] = useState(false);

  const [alertEvents, setAlertEvents] = useState('');

  const [initialDate, setInitialDate] = useState(preparedDates('initial'));

  const [finalDate, setFinalDate] = useState(preparedDates());

  const [typeSelected, setTypeSelected] = useState('');

  const [enable, setEnable] = useState({ period: true, type: false });

  const [nextPage, setNextPage] = useState(null);

  const [inFilter, setInFilter] = useState(false);

  const [ordernationCSV, setOrderingCSV] = useState({
    order: 'desc',
    orderBy: 'created_at',
  });

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getAllData() {
    setError('');
    setLoading(true);

    try {
      const response = await getAllAlarms(false);
      response.alarms.length === 0 && setError(Messages.emptyEvents);
      setNextPage(response.next_page);
      setAllEvents(response.alarms);
      prepareData(response.alarms);
      setLoading(false);

      return response.alarms;
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorEventsTable;
      setError(message);
      setLoading(false);
    }
  }

  async function getAllAlarms(moreData) {
    setLoadingButton(true);
    try {
      if (!moreData) {
        const response = await getAllAlarmsByCircuitBetweenDates(0);
        setLoadingButton(false);

        return response;
      } else if (nextPage) {
        const response = await getAllAlarmsByCircuitBetweenDates(nextPage);

        setNextPage(response.next_page);
        setAllEvents(allEvents.concat(response.alarms));
        prepareData(allEvents.concat(response.alarms));

        return response;
      } else {
        setLoadingButton(false);
      }
    } catch (err) {
      throw err;
    }
  }

  async function getAllAlarmsByCircuitBetweenDates(nextPageLocal) {
    setError('');
    setAlertEvents('');
    try {
      const response = await IoseApiLib.Alarms.getAllAlarmsByCircuitBetweenDates(
        circuitData.uuid_circuit,
        initialDate,
        finalDate,
        nextPageLocal,
        1000
      );

      return response;
    } catch (err) {
      throw err;
    }
  }

  function prepareData(events = []) {
    const preparedData = [];

    for (const event of events) {
      const relayFormated = formatRelay(event.relay);
      const networkFormated = formatNetwork(event.network);
      const powerConnectedFormated = formatPowerConnected(event.power);

      const eventPrepared = {
        created_at: event.created_at,
        type: event.type,
        relay: relayFormated,
        network: networkFormated,
        power: powerConnectedFormated,
      };

      preparedData.push(eventPrepared);
    }

    setDataTable(preparedData);
  }

  function formatDate(date, noHours = false) {
    const dateObj = new Date(date);

    let year = dateObj.getFullYear();

    let month = dateObj.getMonth() + 1;
    let monthLeftZero = ('00' + month).slice(-2);

    let day = dateObj.getDate();
    let dayLeftZero = ('00' + day).slice(-2);

    let hours = dateObj.getHours();
    let hoursLeftZero = ('00' + hours).slice(-2);

    let minutes = dateObj.getMinutes();
    let minutesLeftZero = ('00' + minutes).slice(-2);

    let seconds = dateObj.getSeconds();
    let secondsLeftZero = ('00' + seconds).slice(-2);

    if (noHours) {
      return `${dayLeftZero}/${monthLeftZero}/${year}`;
    } else {
      return `${dayLeftZero}/${monthLeftZero}/${year} ${hoursLeftZero}:${minutesLeftZero}:${secondsLeftZero}`;
    }
  }

  function formatRelay(stateRelay) {
    if (stateRelay) {
      return 'Fechado';
    } else {
      return 'Aberto';
    }
  }

  function formatNetwork(stateNetwork) {
    if (stateNetwork) {
      return 'Conectado';
    } else {
      return 'Não Conectado';
    }
  }

  function formatPowerConnected(powerConnected) {
    if (powerConnected) {
      return 'Possui Carga';
    } else {
      return 'Não Possui Carga';
    }
  }

  function preparedDates(date) {
    const tariffPeriod = unityData.tariff_period;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    const month = currentDay < tariffPeriod ? currentMonth - 1 : currentMonth;

    const initialDay = tariffPeriod
      ? new Date(currentYear, month, tariffPeriod)
      : new Date();

    const finalDay = tariffPeriod
      ? new Date(currentYear, month + 1, tariffPeriod)
      : new Date();

    return date === 'initial'
      ? initialDay.toISOString()
      : finalDay.toISOString();
  }

  function setDates(initialDate, finalDate) {
    setInitialDate(initialDate);
    setFinalDate(finalDate);
  }

  function validatePeriod(initialDate, finalDate) {
    const initialDateObj = new Date(initialDate);
    const finalDateObj = new Date(finalDate);

    if (initialDateObj > finalDateObj) {
      setAlertEvents(Messages.initialDateGreaterFinal);
      return false;
    } else {
      return true;
    }
  }

  function validateCheckBoxes(type, period) {
    if (type || period) {
      return true;
    } else {
      setAlertEvents(Messages.chooseAtLeastOne);
      return false;
    }
  }

  async function filterEvents() {
    setInFilter(true);
    setLoadingButton(true);

    const validate1 = validatePeriod(initialDate, finalDate);
    const validate2 = validateCheckBoxes(enable.type, enable.period);

    if (validate1 && validate2) {
      let nextPageLocal = 0;
      let allEventsLocal = [];

      do {
        const response = await getAllAlarmsByCircuitBetweenDates(nextPageLocal);
        allEventsLocal = allEventsLocal.concat(response.alarms);

        if (enable.type) {
          const dataFiltered = filterByType(allEventsLocal);
          dataFiltered.length === 0 && setAlertEvents(Messages.emptyEvents);
          prepareData(dataFiltered);
        } else {
          allEventsLocal.length === 0 && setAlertEvents(Messages.emptyEvents);
          prepareData(allEventsLocal);
        }

        nextPageLocal = response.next_page;
      } while (nextPageLocal);
    }

    setInFilter(false);
    setLoadingButton(false);
  }

  function filterByType(events) {
    const eventsFiltered = events.filter((event) => {
      const sameType = event.type.toString().includes(typeSelected);
      return sameType && event;
    });

    return eventsFiltered;
  }

  const generateSuffix = () => {
    const dateInitial = formatDate(initialDate, true);
    const dateFinal = formatDate(finalDate, true);

    const suffixCSV = `_${circuitData.name}_${dateInitial}_até_${dateFinal}`;

    return suffixCSV;
  };

  const handleCloseButton = () => {
    setExpand(false);
    setAlertEvents('');
  };

  const handleOpenCollpase = () => {
    setExpand(true);
  };

  const renderHeader = () => (
    <IoseSubHeaderBar
      title={circuitData.name}
      subtitle="Eventos"
      description={'Total: ' + dataTable.length}
    />
  );

  const renderButtons = () => {
    const labeFilterButton = (
      <>
        <FilterListIcon /> <p>Filtros</p>
      </>
    );

    const filterButton = (
      <IoseButtonStyled onClick={handleOpenCollpase} disabled={loadingButton}>
        {loadingButton ? <CircularProgressStyled /> : labeFilterButton}
      </IoseButtonStyled>
    );

    const suffix = generateSuffix();

    const closeButton = (
      <IoseCloseButton onClick={handleCloseButton} left={'24px'} />
    );

    return (
      <ContainerButtons>
        <IoseDowloadCSVModalEvents
          title="Exportar Tabela de Eventos"
          suffix={suffix}
          headerFileCSV={headerFileCSV}
          dataCSV={dataTable}
          loading={loadingButton}
          disabled={loadingButton}
          order={ordernationCSV.order}
          orderBy={ordernationCSV.orderBy}
        />
        {expand ? closeButton : filterButton}
      </ContainerButtons>
    );
  };

  const renderSectionFilter = () => {
    const disableType = { ...enable, type: !enable.type };

    // const disablePeriod = { ...enable, period: !enable.period };

    return (
      <CollapseStyled in={expand} timeout="auto">
        <GridContainerFilter>
          <GridItem period={'true'}>
            {/* <CheckBoxStyled
              checked={enable.period}
              onChange={() => setEnable(disablePeriod)}
            /> */}
            <IoseChoosePeriod
              theme="black"
              getDatesISOString={setDates}
              initialDate={initialDate}
              finalDate={finalDate}
              disabled={!enable.period}
              hours={true}
            />
          </GridItem>

          <GridItem>
            <CheckBoxStyled
              checked={enable.type}
              onChange={() => setEnable(disableType)}
            />
            <IoseTextFieldSidebar
              label={'Tipo'}
              value={typeSelected}
              bottom={'16px'}
              theme={'white'}
              disabled={!enable.type}
              onchange={(event) => setTypeSelected(event.target.value)}
            />
          </GridItem>

          <ContainerFilterButton>
            {loadingButton ? (
              <CircularProgressStyled />
            ) : (
              <IoseButton onClick={filterEvents}>FILTRAR</IoseButton>
            )}
          </ContainerFilterButton>
        </GridContainerFilter>

        {alertEvents !== '' && <AlertEvents>{alertEvents}</AlertEvents>}
      </CollapseStyled>
    );
  };

  const renderContents = () => {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    } else if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <IoseEventsTable
          rows={dataTable}
          getAllAlarms={getAllAlarms}
          inFilter={inFilter}
          formatDate={formatDate}
          setOrderingCSV={setOrderingCSV}
        />
      );
    }
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <IoseContainerStyled>
        {renderHeader()}
        <GridContainer>
          <ContainerContent>
            {renderButtons()}
            {renderSectionFilter()}
            {renderContents()}
          </ContainerContent>
        </GridContainer>
      </IoseContainerStyled>
    </Grow>
  );
}
