// hooks/useContractEffects.js
import { useContext, useState } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUserGroups() {
  const [noGroupsError, setNoGroupsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCognitoGroups = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getCognitoGroups(headers);

    const groups = response?.data;

    if (groups.length === 0) {
      setNoGroupsError(Messages.noRegisteredGroups);
      throw new Error(Messages.noRegisteredGroups);
    }
    return groups;
  };

  const {
    data: groups,
    error: groupsError,
    refetch: refreshCognitoGroups,
    isLoading,
  } = useQuery(['groupsData'], getCognitoGroups);

  return {
    groups,
    groupsError,
    noGroupsError,
    refreshCognitoGroups,
    isLoading,
  };
}

export default useUserGroups;
