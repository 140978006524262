import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import * as S from '../style';

const sumFields = (acc, item, fields) => {
  fields.forEach(field => {
    acc[field] = (acc[field] || 0) + (parseFloat(item[field]) || 0);
  });
};

const sortMonths = (a, b) => {
  const [monthA, yearA] = a.split('/').map(Number);
  const [monthB, yearB] = b.split('/').map(Number);

  if (yearA !== yearB) {
    return yearA - yearB;
  }

  return monthA - monthB;
};

export const SummaryDashYearDemandChart = ({ data }) => {
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const groupedData = data.reduce((acc, item) => {
        const { reference_month, modalidade } = item;

        // Inicializa o objeto para o mês, se ainda não existir
        if (!acc[reference_month]) {
          acc[reference_month] = { VERDE: {}, AZUL: {} };
        }

        const fields = [
          'demanda_pta_valor', 'demanda_pta_kwh',
          'demanda_fp_valor', 'demanda_fp_kwh'
        ];

        if (modalidade === 'VERDE' || modalidade === 'AZUL') {
          sumFields(acc[reference_month][modalidade], item, fields);
        }

        return acc;
      }, {});

      const combinedGroupedData = Object.keys(groupedData).reduce((acc, month) => {
        const verde = groupedData[month].VERDE || {};
        const azul = groupedData[month].AZUL || {};

        acc[month] = {
          demanda_pta_valor: (verde.demanda_pta_valor || 0) + (azul.demanda_pta_valor || 0),
          demanda_pta_kwh: (verde.demanda_pta_kwh || 0) + (azul.demanda_pta_kwh || 0),
          demanda_fp_valor: (verde.demanda_fp_valor || 0) + (azul.demanda_fp_valor || 0),
          demanda_fp_kwh: (verde.demanda_fp_kwh || 0) + (azul.demanda_fp_kwh || 0),
        };

        return acc;
      }, {});

      const orderedGroupedData = Object.keys(combinedGroupedData)
        .sort(sortMonths)
        .map(month => ({
          month,
          ...combinedGroupedData[month]
        }));

      setGroupedData(orderedGroupedData);
    }
  }, [data]);

  const months = groupedData.map(data => data.month);
  const demandaPTAKwh = groupedData.map(data => data.demanda_pta_kwh);
  const demandaFPKwh = groupedData.map(data => data.demanda_fp_kwh);
  // const consumoPTAValores = groupedData.map(data => data.demanda_pta_valor);
  // const consumoFPValores = groupedData.map(data => data.demanda_fp_valor);

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params.length > 0) {
          let tooltipContent = `${params[0].axisValue}<br />`;
          params.forEach((param) => {
            tooltipContent += `${
              param.seriesName
            }: ${param.value.toLocaleString('pt-BR')}<br />`;
          });

          return tooltipContent;
        }
        return 'Sem dados';
      },
    },
    legend: {},
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      // {
      //   name: 'Consumo PTA Valor (R$)',
      //   type: 'bar',
      //   barWidth: '30%',
      //   data: consumoPTAValores,
      //   color: '#015A9E',
      //   itemStyle: {
      //     normal: {
      //       barBorderRadius: [5, 5, 0, 0],
      //     },
      //     emphasis: {
      //       barBorderRadius: [10, 10],
      //     },
      //   },
      // },
      {
        name: 'Demanda PTA kW',
        type: 'bar',
        data: demandaPTAKwh,
        itemStyle: {
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            barBorderRadius: [10, 10],
          },
        },
      },
      // {
      //   name: 'Consumo FP Valor (R$)',
      //   type: 'bar',
      //   barWidth: '30%',
      //   data: consumoFPValores,
      //   color: '#4CAF50',
      //   itemStyle: {
      //     normal: {
      //       barBorderRadius: [5, 5, 0, 0],
      //     },
      //     emphasis: {
      //       barBorderRadius: [10, 10],
      //     },
      //   },
      // },
      {
        name: 'Demanda FP kW',
        type: 'bar',
        data: demandaFPKwh,
        itemStyle: {
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            barBorderRadius: [10, 10],
          },
        },
      },
    ],
  };

  return groupedData.length > 0 ? (
    <S.ChartPaperContainer>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <S.ContractsIcon />
        <S.TypographyTitle>
          GRÁFICO DE DEMANDA ANUAL (kW)
        </S.TypographyTitle>
      </div>
      <ReactECharts
        option={option}
        style={{ height: 450 }}
      />
    </S.ChartPaperContainer>
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
};
