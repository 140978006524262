import React from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseNoDataMessage } from 'components';

export default function GraficoConsumo30DiasQ4({ consumos }) {
  const datas = consumos?.map(item => item.dat_envio);
  const potenciaAtiva = consumos?.map(item => item.total_potencia_a_wh_q4 / 1000);
  const potenciaReativa = consumos?.map(item => item.total_potencia_reativa_a_varh_q4 / 1000);

  const option = {
    grid: {
      left: '2%',
      right: '5%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const formattedParams = params.map(param => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value).toLocaleString('pt-BR')
              : param.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      }
    },
    legend: {
      data: [
        'Consumo (kWh)',
        'Consumo Reativo (kVArh)',
      ]
    },
    toolbox: {
      show: true,
      feature: {
        magicType: { show: true, type: ['line', 'bar'] },
        saveAsImage: { show: true }
      }
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        data: datas,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0,
          fontSize: 12,
          formatter: function (value) {
            // Remove o ano e retorna apenas o dia/mês
            return value.substring(0, 5);
          },
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      }
    ],
    series: [
      {
        name: 'Consumo (kWh)',
        type: 'bar',
        data: potenciaAtiva,
      },
      {
        name: 'Consumo Reativo (kVArh)',
        type: 'bar',
        data: potenciaReativa,
        showSymbol: false
      },
    ]
  };

  return consumos?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 320 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="250px"
      width="250px"
    />
  );
}
