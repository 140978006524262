// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueRegionalManagement() {
  const [noRegionalManagementError, setNoRegionalManagementError] = useState(
    ''
  );
  const { consumerGroups } = useConsumerGroup();

  const getUniqueRegionalManagement = async () => {
    const managementMap = new Map();

    consumerGroups?.forEach((item) => {
      if (
        item.regional_management
        && item.regional_management !== '0'
        && item.regional_management !== ''
        && item.regional_management.toUpperCase() !== 'NULL'
      ) {
        managementMap.set(item.regional_management, item.general_management);
      }
    });

    const regionalManagement = [...managementMap.entries()].map(([regional_management, general_management]) => ({
      value: regional_management,
      label: regional_management,
      general_management: general_management
    }));


    if (regionalManagement.length === 0) {
      setNoRegionalManagementError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return { regionalManagement };
  };

  const {
    data: { regionalManagement } = {},
    error: regionalManagementsError,
    refetch: refreshRegionalManagement,
    isLoading,
  } = useQuery(['regionalManagements', consumerGroups], getUniqueRegionalManagement, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    regionalManagement,
    regionalManagementsError,
    noRegionalManagementError,
    refreshRegionalManagement,
    isLoading,
  };
}

export default useUniqueRegionalManagement;
