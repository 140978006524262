import React from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit'; // Importar o ícone de edição

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();

  const handleEditClick = () => {
    // Chame a função de clique passada como prop (onClick) e forneça o valor da linha
    onClick(value);
  };

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value?.name}</TableCellCentered>
      <TableCellCentered>{value?.email}</TableCellCentered>
      <TableCellCentered>{value?.group}</TableCellCentered>
      <TableCellCentered>
        <IconButton onClick={handleEditClick}>
          <Tooltip title={'EDITAR GRUPO DO USUÁRIO'} placement="bottom" arrow>
            <EditIcon />
          </Tooltip>
        </IconButton>
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>NOME</TableHeaderCellCentered>
        <TableHeaderCellCentered>E-MAIL</TableHeaderCellCentered>
        <TableHeaderCellCentered>GRUPO</TableHeaderCellCentered>
        <TableHeaderCellCentered></TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseUsersTable({ loading, data, onRowClick }) {
  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          <CircuitsTable
            data={data}
            onRowClick={onRowClick}
            id="scrollableDiv"
          />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={data ? data.length : 5}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={0}
          padding={0}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={550}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
