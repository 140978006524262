import styled from 'styled-components';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Typography } from '@material-ui/core';

export const ModalTitleHeader = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 12px;
  margin-top: 5px; */
  color: var(--secondary);
  margin-top: 6px;
`;

export const HeaderModal = styled.div`
  background-color: var(--primary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const LeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const RightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const WrappedModal = styled.div`
  background-color: #ecf0f5;
  height: auto;
  width: auto;
  min-height: 400px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  border: 2px solid var(--primary);
`;

export const ObjectPaper = styled.div`
  background-color: #ecf0f5;
  height: auto;
  max-width: 900px;
  min-width: 750px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: var(--secondary);
`;
