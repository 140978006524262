// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useBillItems(identificationNumber) {
  const [noDataError, setNoDataError] = useState('');
  const { getSession } = useContext(AccountContext);
  const obterBillItems = async () => {
    const { headers } = getSession();
    const response = await ioseAPI.getBillsItemsByIdentificationNumber(headers, identificationNumber);

    const billItems = response.data || [];

    if (billItems.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return billItems;
  };

  const {
    data: billItems,
    error: billItemsError,
    refetch: refreshBillItems,
    isLoading,
  } = useQuery(['BillItems', identificationNumber], obterBillItems);

  return {
    billItems,
    billItemsError,
    noDataError,
    refreshBillItems,
    isLoading,
  };
}

export default useBillItems;
