import { useSession } from 'common/contexts/session';
import Exception from 'common/exceptions';
import LocalStorage from 'common/local_storage_api';
import IoseHeaderBar from 'components/IoseHeaderBar';
import { useGroupPermissions } from 'hooks';
import ioseApiLib from 'iose-api-lib';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutesMainContainer } from 'routers';
import Permissions from 'common/permissions';
import { IoseAlertLoadingCards, LoadingIndicator } from 'components';

export default function UsuariosXAlarmesPage() {
  const history = useHistory();

  const { verifySession, clearSession } = useSession();
  const { groupPermissions, loadingPermissions } = useGroupPermissions();

  const session = LocalStorage.getSession();

  const [sessionVerified, setSessionVerified] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState(null);

  useEffect(() => {
    const readPath = ['telemetria', 'usuarios_x_alarmes', 'leitura'];

    if (session && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        session,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [session, groupPermissions]);

  useEffect(() => {
    verifyIsLogged();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyIsLogged = async () => {
    try {
      if (session) {
        await ioseApiLib.setToken(session.idToken);
        const sessionVerificated = await verifySession(session);
        sessionVerificated && setSessionVerified(true);
      } else {
        throw Exception.notLoggedIn;
      }
    } catch (error) {
      clearSession(error.message);
    }
  };

  const redirectToSuperPage = () => history.replace('/global/super');

  const redirectToClientPage = () => history.replace('/global/client');

  const redirectToHome = () => {
    if (session.group === 'super') {
      redirectToSuperPage();
    } else {
      redirectToClientPage();
    }
  };

  return (
    <>
      <IoseHeaderBar logoAction={redirectToHome} />
      {loadingPermissions ? (
        <LoadingIndicator loading={loadingPermissions} />
      ) : (
        <>
          {sessionVerified && hasReadPermission !== null && (
            <>
              {hasReadPermission ? (
                <>
                  <RoutesMainContainer />
                </>
              ) : (
                <div style={{marginTop: '150px'}}>
                  <IoseAlertLoadingCards
                    text="Seu grupo de usuários não tem permissão de leitura destes dados!"
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
