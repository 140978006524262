import React from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IoseButton2 } from 'components/IoseButton';
import { xlsxReports } from 'reports/Xlsx';

function getTop10Consumptions(data) {
  if (!Array.isArray(data)) return [];

  const totalsByIdentificationNumber = {};

  data.forEach(item => {
    const id = item.identification_number;
    const totalValue = parseFloat(item.item_total_value_1) || 0;

    if (!totalsByIdentificationNumber[id]) {
      totalsByIdentificationNumber[id] = {
        total: totalValue,
        general_management: item.general_management,
        regional_management: item.regional_management,
        identification_number: id
      };
    } else {
      totalsByIdentificationNumber[id].total += totalValue;
    }
  });

  const totalsArray = Object.values(totalsByIdentificationNumber);

  totalsArray.sort((a, b) => b.total - a.total);

  return totalsArray.slice(0, 10);
}

export default function MaioresDesperdiciosPorUcChart({ data }) {
  const top10Data = getTop10Consumptions(data);

  if (!top10Data || top10Data.length === 0) {
    return (
      <IoseNoDataMessage
        message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
        height="300px"
        width="280px"
      />
    );
  }

  const colorPalette = [
    '#2980b9',
    '#cb4335',
    '#16a085',
    '#F39C12',
    '#8E44AD',
    '#1ABC9C',
    '#D35400',
    '#2980B9',
    '#C0392B',
    '#27AE60',
  ];

  const option = {
    color: colorPalette,
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '15%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const { name, value } = params[0];

        const item = top10Data.find(d => d.identification_number === name);

        if (item) {
          const formattedValue = value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          });

          return `
            <strong>UC:</strong> ${item.identification_number}<br/>
            <strong>GG:</strong> ${item.general_management}<br/>
            <strong>GR:</strong> ${item.regional_management}<br/>
            <strong>Total em desperdícios:</strong> ${formattedValue}
          `;
        }

        return '';
      },
    },
    xAxis: {
      type: 'category',
      data: top10Data.map(item => item.identification_number),
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        rotate: 45,
      },
      show: true,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Total Value',
        type: 'bar',
        data: top10Data.map(item => item.total),
        itemStyle: {
          color: (params) => colorPalette[params.dataIndex], // Aplica a cor fixa baseada na posição
        },
      },
    ],
  };

  return (
    <div>
      {top10Data && top10Data.length > 0 && (
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end'
        }}>
          <IoseButton2
            onClick={() => xlsxReports.exportTop10DesperdiciosPorUc(top10Data)}
            width="80px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      )}

      <div>
        {top10Data && top10Data.length > 0 ? (
          <ReactECharts option={option} style={{ height: 420 }} />
        ) : (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
            height="300px"
            width="280px"
          />
        )}
      </div>
    </div>
  );
}
