/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Viewer, ViewerEvent } from '@speckle/viewer';
import {
  DivPaper,
  TypographyStyled,
  LegendStyled,
  BoxStyled,
  LegendItemStyled,
  Div3d,
  DivGrafico,
} from './style';
import { LoadingIndicator } from 'components';

const SpeckleViewer = ({
  onElementSelected,
  data,
  unityData,
  gemeos,
  GraficoMes,
  consumo,
  estimado,
}) => {
  const [grupos, setGrupos] = useState([]);
  const [loading, setLoading] = useState(false);

  const containerId = 'renderedView';
  const commitUrl = unityData.bin_link;

  useEffect(() => {
    if (gemeos.length > 0) {
      if (data !== undefined && data?.length > 0) {
        const groups = gemeos
          // eslint-disable-next-line array-callback-return
          ?.map((device) => {
            if (device.is_iose) {
              let comunicando = [];
              let medindo = [];
              data.forEach((medicao) => {
                device.uuid_circuit.forEach((circuito) => {
                  if (medicao.uuid_circuit === circuito) {
                    if (
                      medicao.current !== undefined &&
                      medicao.arrival_date !== undefined
                    ) {
                      let parts = medicao.arrival_date.split(/[/, :]/);
                      parts = parts.filter((part) => part !== '');
                      const data_medicao = new Date(
                        Date.UTC(
                          parts[2],
                          parts[1] - 1,
                          parts[0],
                          parts[3],
                          parts[4],
                          parts[5]
                        )
                      );
                      const data_atual = new Date();

                      const diferenca =
                        (data_atual - data_medicao) / 1000 / 60 / 60;
                      if (diferenca <= 3.5) {
                        comunicando.push(medicao.uuid_circuit);
                        if (device.uuid_circuit.length === 1) {
                          medindo.push(medicao.uuid_circuit);
                        }
                      }
                    }
                    if (medicao.current > 0.1) {
                      let parts = medicao.arrival_date.split(/[/, :]/);

                      parts = parts.filter((part) => part !== '');
                      const data_medicao = new Date(
                        Date.UTC(
                          parts[2],
                          parts[1] - 1,
                          parts[0],
                          parts[3],
                          parts[4],
                          parts[5]
                        )
                      );
                      const data_atual = new Date();

                      const diferenca =
                        (data_atual - data_medicao) / 1000 / 60 / 60;

                      if (diferenca <= 3.5) {
                        medindo.push(medicao.uuid_circuit);
                      }
                    }
                  }
                });
              });
              comunicando = [...new Set(comunicando)];
              medindo = [...new Set(medindo)];
              if (medindo.length === device.uuid_circuit.length) {
                const color = 'green';
                return { objectId: device.id_3d, color };
              } else if (comunicando.length === device.uuid_circuit.length) {
                const color = 'yellow';
                return { objectId: device.id_3d, color };
              } else if (comunicando.length > 0 || medindo.length > 0) {
                const color = 'orange';
                return { objectId: device.id_3d, color };
              } else {
                const color = 'red';
                return { objectId: device.id_3d, color };
              }
            }
          })
          .filter(Boolean);

        const objectGroups = groups.map((group) => ({
          objectIds: [group.objectId],
          color: group.color,
        }));
        setGrupos(objectGroups);
      }
    }
  }, [data, gemeos]);

  useEffect(() => {
    if (gemeos.length > 0 && grupos.length > 0 && data?.length > 0) {
      const element = getContainerHTMLElement();

      if (!element) {
        return;
      }

      async function loadViewerIntoContainer() {
        setLoading(true);
        const newViewer = new Viewer(element);
        await newViewer.loadObject(commitUrl);
        const id_3d = [];
        const objetos_ambientes = [];
        const circuitos_principais = [];
        gemeos.forEach((gemeo) => {
          if (gemeo.is_iose) {
            id_3d.push(gemeo.id_3d);
            gemeo.uuid_circuit.forEach((circuit) => {
              circuitos_principais.push({
                'id': gemeo.id_3d,
                'circuito': circuit,
              });
            });
          } else {
            objetos_ambientes.push({
              'id': gemeo.id_3d,
              'circuito': gemeo.uuid_circuit[0],
            });
          }
        });
        newViewer.on(
          ViewerEvent.ObjectDoubleClicked,
          async (selectionEvent) => {
            await newViewer.resetHighlight();
            if (selectionEvent?.hits) {
              const filtered = selectionEvent.hits.filter((hit) =>
                id_3d.includes(hit.object.id)
              );
              if (filtered.length) {
                newViewer.selectObjects([filtered[0].object.id]);
                onElementSelected(filtered[0].object.id);
              } else newViewer.resetSelection();
            }
          }
        );

        newViewer.on(ViewerEvent.ObjectClicked, async (selectionEvent) => {
          if (selectionEvent?.hits) {
            const filtered = selectionEvent.hits.filter((hit) =>
              id_3d.includes(hit.object.id)
            );
            if (filtered.length) {
              newViewer.selectObjects([filtered[0].object.id]);
              const objetos = [];
              circuitos_principais.forEach((circuito) => {
                objetos_ambientes.forEach((objeto) => {
                  if (
                    circuito.circuito === objeto.circuito &&
                    filtered[0].object.id === circuito.id
                  ) {
                    objetos.push(objeto.id);
                  }
                });
              });
              await newViewer.highlightObjects(objetos, false);
            }
          }
        });

        await newViewer.setUserObjectColors(grupos);
        await newViewer.isolateObjects(id_3d, '', false, true);
        newViewer.setLightConfiguration({
          enabled: true,
          castShadow: false,
        });

        await newViewer.init();
        newViewer.requestRender();
        setLoading(false);
      }

      if (!element.children || !element.children.length) {
        loadViewerIntoContainer();
      }
    }
  }, [onElementSelected, gemeos, grupos, data]);

  function getContainerHTMLElement() {
    return document.getElementById(containerId);
  }

  return (
    <DivPaper>
      <>
        <LegendStyled>
          <LegendItemStyled>
            <BoxStyled bgcolor="green" />
            <TypographyStyled variant="body1">
              Todos os circuitos possuem <strong>comunicação</strong> e{' '}
              <strong>medição</strong>
            </TypographyStyled>
          </LegendItemStyled>
          <LegendItemStyled>
            <BoxStyled bgcolor="yellow" />
            <TypographyStyled variant="body1">
              Os circuitos possuem <strong>comunicação</strong>, mas nem todos
              possuem <strong>medição</strong>
            </TypographyStyled>
          </LegendItemStyled>
          <LegendItemStyled>
            <BoxStyled bgcolor="orange" />
            <TypographyStyled variant="body1">
              Um ou mais circuitos não possuem <strong>comunicação</strong>
            </TypographyStyled>
          </LegendItemStyled>
          <LegendItemStyled>
            <BoxStyled bgcolor="red" />
            <TypographyStyled variant="body1">
              Nenhum circuito possui <strong>comunicação</strong>
            </TypographyStyled>
          </LegendItemStyled>
        </LegendStyled>
        <Div3d>
          <div>
            {loading ? <LoadingIndicator loading={loading} /> : <></>}
            <div id={containerId} style={{ height: '800px', width: '1200px' }}>
              {' '}
            </div>
          </div>
          <DivGrafico>
            <GraficoMes data={consumo} estimado={estimado} />
          </DivGrafico>
        </Div3d>
      </>
    </DivPaper>
  );
};

export default SpeckleViewer;
