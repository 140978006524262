import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination } from '@material-ui/core';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { StyledTable, TableHeadStyled, TableRowStyled } from '../style';


const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  smallerColumn: {
    minWidth: '60px',
    maxWidth: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  mediumColumn: {
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableCell} ${className}`}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className, sortedBy, direction, onClick }) => {
  const classes = useStyles();
  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${className}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({
  value,
  onEditClick,
  editPermission
}) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered className={classes.mediumColumn}>{value.email_usuario}</TableCellCentered>
      <TableCellCentered className={classes.mediumColumn}>{value.numero_telemetria}</TableCellCentered>
      <TableCellCentered className={classes.mediumColumn}>{value.unidade_consumidora}</TableCellCentered>
      <TableCellCentered className={classes.mediumColumn}>{value.tipo_alarme.toUpperCase()}</TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  onDeleteClick,
  onEditClick,
  editPermission,
  handleSort,
  sortBy,
  sortDirection
}) => {
  const classes = useStyles();
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('email_usuario')}
            sortedBy={sortBy === 'email_usuario'}
            direction={sortDirection}
          >
            USUÁRIO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('numero_telemetria')}
            sortedBy={sortBy === 'numero_telemetria'}
            direction={sortDirection}
          >
            NUM. TELEMETRIA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('unidade_consumidora')}
            sortedBy={sortBy === 'unidade_consumidora'}
            direction={sortDirection}
          >
            UC
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('tipo_alarme')}
            sortedBy={sortBy === 'tipo_alarme'}
            direction={sortDirection}
          >
            TIPO DO ALARME
          </TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            editPermission={editPermission}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function AlarmsNotDisplayedTable({ data = [], loading}) {

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (data?.length > 0) {
      // Ordena os dados baseados nas colunas sortBy e sortDirection
      const sortedData = sortData(data, sortBy, sortDirection);
      setUpdatedData(sortedData);
    }
  }, [data, sortBy, sortDirection]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  function renderChartOrErrorMessage() {
    if (updatedData && updatedData?.length > 0) {
      return (
        <CircuitsTable
          data={updatedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={updatedData ? updatedData.length : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={550}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={updatedData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>
        </>
      )}
    </div>
  );
}

const sortData = (data, sortBy, sortDirection) => {
  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
