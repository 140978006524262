// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueConsumerGroupSubgroup() {
  const [noSubgroupsError, setNoSubgroupError] = useState(
    ''
  );

  const { consumerGroups } = useConsumerGroup();

  const getUniqueSubgroup = async () => {

    const subgroupSet = new Set();

    consumerGroups?.forEach((item) => {
      if (
        item.subgrupo
        && item.subgrupo !== '0'
        && item.subgrupo !== ''
        && item.subgrupo.toUpperCase() !== 'NULL'
      ) {
        subgroupSet.add(item.subgrupo);
      }
    });

    const subgroups = [...subgroupSet].map(
      (subgroups) => ({
        value: subgroups,
        label: subgroups,
      })
    );

    if (subgroups.length === 0) {
      setNoSubgroupError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return { subgroups };
  };

  const {
    data: { subgroups } = {},
    error: subGroupsError,
    refetch: refreshAddress,
    isLoading,
  } = useQuery(['subgroups', consumerGroups], getUniqueSubgroup, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    subgroups,
    subGroupsError,
    noSubgroupsError,
    refreshAddress,
    isLoading,
  };
}

export default useUniqueConsumerGroupSubgroup;
