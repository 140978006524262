// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useGenerationAccumulated() {
  const [nogetGenerarionAccumulated, setNogetGenerarionAccumulated] = useState('');

  const { getSession } = useContext(AccountContext);

  const getGenerarionAccumulated = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllGenerationAccumulated(headers, 3000, 3000);

    const { data } = response.data;

    const getGenerarionAccumulatedData = data || [];

    if (getGenerarionAccumulatedData.length === 0) {
      setNogetGenerarionAccumulated(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return getGenerarionAccumulatedData;
  };

  const {
    data: getGenerarionAccumulatedData,
    error: getGenerarionAccumulatedDataError,
    refetch: refreshgetGenerarionAccumulated,
    isLoading,
  } = useQuery(['generationAccumulated'], getGenerarionAccumulated);

  return {
    getGenerarionAccumulatedData,
    getGenerarionAccumulatedDataError,
    nogetGenerarionAccumulated,
    refreshgetGenerarionAccumulated,
    isLoading,
  };
}

export default useGenerationAccumulated;
