import styled from 'styled-components';
import { Grid, Table, TableHead, TableRow } from '@material-ui/core/';

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const WrapContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
`;

export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;
