import styled from 'styled-components';

//Imports from Material Ui components
import { Button } from '@material-ui/core';

const IoseButton = styled(Button)`
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  background-color: ${(props) =>
    props.theme === 'white' ? 'var(--base)' : 'var(--primary)'};
  width: auto;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-left: ${(props) => (props.left ? props.left : '0')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  &:hover {
    background-color: ${(props) =>
      props.theme === 'white' ? 'var(--primary)' : 'var(--base)'};
    span {
      color: ${(props) =>
        props.theme === 'white' ? '#fff' : 'var(--dark)'};
    }
  }

  span {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--primary)' : 'var(--secondary)'};
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: ${(props) => (props.margin767px ? props.margin767px : '0px')};
  }
`;

export const IoseRemoveButton = styled(Button)`
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  background-color: #D60606;
  width: auto;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-left: ${(props) => (props.left ? props.left : '0')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  &:hover {
    background-color: ${(props) =>
      props.theme === 'white' ? 'var(--dark)' : 'var(--base)'};
    span {
      color: ${(props) =>
        props.theme === 'white' ? 'var(--primary)' : 'var(--dark)'};
    }
  }

  span {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--dark)' : 'var(--secondary)'};
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: ${(props) => (props.margin767px ? props.margin767px : '0px')};
  }
`;

export const IoseModalButton = styled(Button)`
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  background-color: ${(props) =>
    props.theme === 'white' ? '#fff' : 'var(--dark)'};
  width: auto;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-left: ${(props) => (props.left ? props.left : '0')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  &:hover {
    background-color: ${(props) =>
      props.theme === 'white' ? 'var(--primary)' : 'var(--primary)'};
    span {
      color: ${(props) =>
        props.theme === 'white' ? '#fff' : 'var(--primary)'};
    }
  }

  span {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--primary)' : 'var(--secondary)'};
    font-size: 12px;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    margin: ${(props) => (props.margin767px ? props.margin767px : '0px')};
  }
`;

export const IoseButton2 = styled(Button)`
  margin: 0 0 0 50px;
  background-color: ${(props) =>
    props.theme === 'white' ? 'var(--base)' : 'var(--dark)'};
  width: 400px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  height: ${(props) => (props.height ? props.height : 'auto')};
  color: var(--secondary);

  &:hover {
    background-color: ${(props) =>
      props.theme === 'white' ? 'var(--dark)' : 'var(--base)'};
    span {
      color: ${(props) =>
        props.theme === 'white' ? 'var(--primary)' : 'var(--dark)'};
    }
  }

  span {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--dark)' : 'var(--primary)'};
    font-size: 12px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: ${(props) => (props.margin767px ? props.margin767px : '0px')};
  }
`;

/**
 * @component
 */ export default IoseButton;
