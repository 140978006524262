import styled from 'styled-components';
import { Typography } from '@material-ui/core';

//Import from Material UI Components
import { Grid } from '@material-ui/core';

import AuthImage from './img/auth.svg';
//Grids
export const GridMain = styled(Grid).attrs(() => ({
  container: true,
}))`
  height: 100vh;
`;

export const Container = styled.div`
  height: 100vh;
  background-repeat: no-repeat;
  background-color: var(--base);
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.div`
  width: ${(props) => (props.width ? props.width : '300px')};
  height: ${(props) => (props.height ? props.height : '300px')};
  background-image: url(${AuthImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const StyledMessage = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 25px;
`;

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  align-items: center;
`;
