import React, { useState } from 'react';

import {
  AttachMoney as AttachMoneyIcon,
  BatteryChargingFull as BatteryChargingFullIcon,
} from '@material-ui/icons';

import * as S from './style';
import IoseDesperdicioChart from 'components/IoseDesperdicioChart';

import { LoadingValueCards } from 'components';

const formatNumber = (number) => {
  const formattedNumber = number?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });
  return formattedNumber;
};

export default function IoseCardDesperdicios({
  loading,
  auditExtract,
  selectedRefMonth,
  loadingDesperdicios,
  billItensDesperdicios,
}) {
  const {
    totalUltrapDemandaFp,
    totalUltrapDemandaPta,
    totalUltrapDemanda,
    totalDemandaExcReativa,
    totalDemandaExcReativaPta,
    totalDemandaExcReativaFp,
    totalUltrapDemandaFpValor,
    totalUltrapDemandaPtaValor,
    totalUltrapDemandaValor,
    totalDemandaExcReativaValor,
    totalDemandaExcReativaPtaValor,
    totalDemandaExcReativaFpValor,
    totalConsumoExcReativa,
    totalConsumoExcReativaPta,
    totalConsumoExcReativaFp,
    totalConsumoExcReativaPtaValor,
    totalConsumoExcReativaFpValor,
    totalConsumoExcReativaValor,
    totalAdicionalDemanda,
    totalAdicionalDemandaPTA,
    totalAdicionalDemandaFP,
    totalAdicionalDemandaValor,
    totalAdicionalDemandaPTAValor,
    totalAdicionalDemandaFPValor,
  } = auditExtract ?? {};

  const totalUltrap =
    totalUltrapDemandaFpValor +
    totalUltrapDemandaPtaValor +
    totalUltrapDemandaValor;

  const totalDemandaExc =
    totalDemandaExcReativaValor +
    totalDemandaExcReativaPtaValor +
    totalDemandaExcReativaFpValor;

  const totalConsumoExc =
    totalConsumoExcReativaPtaValor +
    totalConsumoExcReativaFpValor +
    totalConsumoExcReativaValor;

  const totalAdicioalDemanda =
    totalAdicionalDemandaValor +
    totalAdicionalDemandaPTAValor +
    totalAdicionalDemandaFPValor;

  const totais =
    totalUltrap + totalDemandaExc + totalConsumoExc + totalAdicioalDemanda;

  const filterDesperdicios = (
    cardData,
    billItensDesperdicios,
    selectedRefMonth
  ) => {
    const keys = cardData?.data?.map(({ key }) => key);

    if (!selectedRefMonth) {
      return Array.isArray(billItensDesperdicios)
        ? billItensDesperdicios.filter(({ referente }) =>
            keys?.includes(referente)
          )
        : [];
    } else {
      return Array.isArray(billItensDesperdicios)
        ? billItensDesperdicios.filter(
            ({ referente, reference_month }) =>
              keys?.includes(referente) && reference_month === selectedRefMonth
          )
        : [];
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [filteredDesperdicios, setFilteredDesperdicios] = useState([]);

  const handleCardClick = (cardData) => {
    let filteredData = [];

    if (cardData.title === 'TOTAIS') {
      filteredData = billItensDesperdicios;
    } else {
      filteredData = filterDesperdicios(
        cardData,
        billItensDesperdicios,
        selectedRefMonth
      );
    }

    setFilteredDesperdicios(filteredData);
    setIsModalOpen(true);
    setSelectedCard(cardData);
  };

  const cardsData = [
    {
      icon: <BatteryChargingFullIcon />,
      title: 'ULTRAPASSAGEM',
      data: [
        {
          key: 'Demanda Ultrap.',
          label: 'DEMANDA ULTRAP (kW):',
          value:
            totalUltrapDemanda !== undefined
              ? `${formatNumber(totalUltrapDemanda)}`
              : 0,
        },
        {
          key: 'Demanda Ultrap. FP',
          label: 'DEMANDA ULTRAP FP (kW):',
          value:
            totalUltrapDemandaFp !== undefined
              ? `${formatNumber(totalUltrapDemandaFp)}`
              : 0,
        },
        {
          key: 'Demanda Ultrap. PTA',
          label: 'DEMANDA ULTRAP PTA (kW):',
          value:
            totalUltrapDemandaPta !== undefined
              ? `${formatNumber(totalUltrapDemandaPta)}`
              : 0,
        },
        { key: 'spacer_1', component: <S.Spacer height="16px" /> }, // Spacer
        {
          key: 'DEMANDA_ULTRAP_RS',
          label: 'DEMANDA ULTRAP:',
          value:
            totalUltrapDemandaValor !== undefined
              ? `R$ ${formatNumber(totalUltrapDemandaValor)}`
              : 0,
        },
        {
          key: 'DEMANDA_ULTRAP_FP_RS',
          label: 'DEMANDA ULTRAP FP:',
          value:
            totalUltrapDemandaFpValor !== undefined
              ? `R$ ${formatNumber(totalUltrapDemandaFpValor)}`
              : 0,
        },
        {
          key: 'DEMANDA_ULTRAP_PTA_RS',
          label: 'DEMANDA ULTRAP PTA:',
          value:
            totalUltrapDemandaPtaValor !== undefined
              ? `R$ ${formatNumber(totalUltrapDemandaPtaValor)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'DEMANDA EXCEDENTE',
      data: [
        {
          key: 'Demanda EXC. Reativa',
          label: 'DEMANDA EXC. REATIVA (kVAr):',
          value:
            totalDemandaExcReativa !== undefined
              ? `${formatNumber(totalDemandaExcReativa)}`
              : 0,
        },
        {
          key: 'Demanda EXC. Reativa PTA',
          label: 'DEMANDA EXC. REATIVA PTA (kVAr):',
          value:
            totalDemandaExcReativaPta !== undefined
              ? `${formatNumber(totalDemandaExcReativaPta)}`
              : 0,
        },
        {
          key: 'Demanda EXC. Reativa FP',
          label: 'DEMANDA EXC. REATIVA FP (kVAr):',
          value:
            totalDemandaExcReativaFp !== undefined
              ? `${formatNumber(totalDemandaExcReativaFp)}`
              : 0,
        },
        { key: 'spacer_2', component: <S.Spacer height="16px" /> },
        {
          key: 'DEMANDA_EXC_REATIVA_RS',
          label: 'DEMANDA EXC. REATIVA:',
          value:
            totalDemandaExcReativaValor !== undefined
              ? `R$ ${formatNumber(totalDemandaExcReativaValor)}`
              : 0,
        },
        {
          key: 'DEMANDA_EXC_REATIVA_PTA_RS',
          label: 'DEMANDA EXC. REATIVA PTA:',
          value:
            totalDemandaExcReativaPtaValor !== undefined
              ? `R$ ${formatNumber(totalDemandaExcReativaPtaValor)}`
              : 0,
        },
        {
          key: 'DEMANDA_EXC_REATIVA_FP_RS',
          label: 'DEMANDA EXC. REATIVA FP:',
          value:
            totalDemandaExcReativaFpValor !== undefined
              ? `R$ ${formatNumber(totalDemandaExcReativaFpValor)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },

    {
      icon: <BatteryChargingFullIcon />,
      title: 'CONSUMO EXCEDENTE',
      data: [
        {
          key: 'Consumo Exc. Reativo',
          label: 'CONSUMO EXC. REATIVO (kVAr):',
          value:
            totalConsumoExcReativa !== undefined
              ? `${formatNumber(totalConsumoExcReativa)}`
              : 0,
        },
        {
          key: 'Consumo Exc. Reativo PTA',
          label: 'CONSUMO EXC. REATIVO PTA (kVAr):',
          value:
            totalConsumoExcReativaPta !== undefined
              ? `${formatNumber(totalConsumoExcReativaPta)}`
              : 0,
        },
        {
          key: 'Consumo Exc. Reativo FP',
          label: 'CONSUMO EXC. REATIVO FP (kVAr):',
          value:
            totalConsumoExcReativaFp !== undefined
              ? `${formatNumber(totalConsumoExcReativaFp)}`
              : 0,
        },
        { key: 'spacer_2', component: <S.Spacer height="16px" /> },

        {
          key: 'CONSUMO_EXC_REATIVA_RS',
          label: 'CONSUMO EXC. REATIVO:',
          value:
            totalConsumoExcReativaValor !== undefined
              ? `R$ ${formatNumber(totalConsumoExcReativaValor)}`
              : 0,
        },
        {
          key: 'CONSUMO_EXC_REATIVA_PTA_RS',
          label: 'CONSUMO EXC. REATIVO PTA:',
          value:
            totalConsumoExcReativaPtaValor !== undefined
              ? `R$ ${formatNumber(totalConsumoExcReativaPtaValor)}`
              : 0,
        },
        {
          key: 'CONSUMO_EXC_REATIVA_FP_RS',
          label: 'CONSUMO EXC. REATIVO FP:',
          value:
            totalConsumoExcReativaFpValor !== undefined
              ? `R$ ${formatNumber(totalConsumoExcReativaFpValor)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'ADICIONAL DE DEMANDA',
      data: [
        {
          key: 'Adicional de demanda',
          label: 'ADICIONAL DE DEMANDA (kW):',
          value:
            totalAdicionalDemanda !== undefined
              ? `${formatNumber(totalAdicionalDemanda)}`
              : 0,
        },
        {
          key: 'Adicional de demanda PTA',
          label: 'ADICIONAL DE DEMANDA PTA (kW):',
          value:
            totalAdicionalDemandaPTA !== undefined
              ? `${formatNumber(totalAdicionalDemandaPTA)}`
              : 0,
        },
        {
          key: 'Adicional de demanda FP',
          label: 'ADICIONAL DE DEMANDA FP (kW):',
          value:
            totalAdicionalDemandaFP !== undefined
              ? `${formatNumber(totalAdicionalDemandaFP)}`
              : 0,
        },
        { key: 'spacer_3', component: <S.Spacer height="16px" /> },
        {
          key: 'ADICIONAL_DEMANDA_R$',
          label: 'ADICIONAL DE DEMANDA:',
          value:
            totalAdicionalDemandaValor !== undefined
              ? `R$ ${formatNumber(totalAdicionalDemandaValor)}`
              : 0,
        },
        {
          key: 'ADICIONAL_DEMANDA_PTA_R$',
          label: 'ADICIONAL DE DEMANDA PTA:',
          value:
            totalAdicionalDemandaPTAValor !== undefined
              ? `R$ ${formatNumber(totalAdicionalDemandaPTAValor)}`
              : 0,
        },
        {
          key: 'ADICIONAL_DEMANDA_FP_R$',
          label: 'ADICIONAL DE DEMANDA FP:',
          value:
            totalAdicionalDemandaFPValor !== undefined
              ? `R$ ${formatNumber(totalAdicionalDemandaFPValor)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <AttachMoneyIcon />,
      title: 'TOTAIS',
      data: [
        {
          key: 'TOTAL_ULTRAPASSAGEM_RS',
          label: 'TOTAL ULTRAPASSAGEM:',
          value:
            totalUltrap !== undefined ? `R$ ${formatNumber(totalUltrap)}` : 0,
        },
        {
          key: 'TOTAL_DEMANDA_EXC_RS',
          label: 'TOTAL DEMANDA EXC.:',
          value:
            totalDemandaExc !== undefined
              ? `R$ ${formatNumber(totalDemandaExc)}`
              : 0,
        },
        {
          key: 'TOTAL_CONSUMO_EXC_RS',
          label: 'TOTAL CONSUMO EXC.:',
          value:
            totalConsumoExc !== undefined
              ? `R$ ${formatNumber(totalConsumoExc)}`
              : 0,
        },
        {
          key: 'TOTALA_ADICIONAL_DEMANDA_RS',
          label: 'TOTAL ADICIONAL DEMANDA.:',
          value:
            totalAdicioalDemanda !== undefined
              ? `R$ ${formatNumber(totalAdicioalDemanda)}`
              : 0,
        },
        { key: 'separator', component: <S.Separator /> },
        {
          key: 'TOTAL',
          label: 'TOTAL:',
          value: totais !== undefined ? `R$ ${formatNumber(totais)}` : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
  ];

  return (
    <>
      <S.CardGrid container spacing={2}>
        {cardsData.map(({ icon, chart, title, data, bgColor }) => (
          <S.BoxContainer
            key={title}
            style={{ transition: '0.5s ease', backgroundColor: bgColor }}
            onClick={() => handleCardClick({ title, data })}
          >
            <S.HeaderContainer>
              <S.IconHeader>{icon}</S.IconHeader>
              <S.CardTitle>{title}</S.CardTitle>
            </S.HeaderContainer>
            {data.map(({ key, label, value, component }) =>
              component ? (
                <div key={key}>{component}</div>
              ) : (
                <S.DataContainer key={key}>
                  <S.CardDataKey>{label}</S.CardDataKey>
                  {loadingDesperdicios ? (
                    <LoadingValueCards loading={loadingDesperdicios} />
                  ) : (
                    <S.CardDataValue>{value}</S.CardDataValue>
                  )}
                </S.DataContainer>
              )
            )}
            {chart}
          </S.BoxContainer>
        ))}
      </S.CardGrid>
      <IoseDesperdicioChart
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedCard={selectedCard}
        filteredDesperdicios={filteredDesperdicios}
      />
    </>
  );
}
