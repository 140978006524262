function formatDate(datetime) {
  // Criar um objeto Date a partir da string fornecida
  const date = new Date(datetime);

  const opcoes = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    timeZone: 'America/Sao_Paulo',
  };

  const formatter = new Intl.DateTimeFormat('pt-BR', opcoes);
  const formattedDate = formatter.format(date);

  return formattedDate;
}

export function mapToMeasurement(data) {
  return {
    index: Number(data.index) || 0,
    reactive_power: Number(data.reactive_power) || 0,
    status_measure: String(data.status_measure) || '',
    uuid_measure: String(data.uuid_measure) || '',
    offset_eae: Number(data.offset_eae) || 0,
    current: Number(data.current) || 0,
    import_reactive_energy: Number(data.import_reactive_energy) || 0,
    thermistor_voltage: Number(data.thermistor_voltage) || 0,
    power_factor: Number(data.power_factor) || 0,
    arrival_date: String(formatDate(data.arrival_date)) || '',
    export_reactive_energy: Number(data.export_reactive_energy) || 0,
    line_frequency: Number(data.line_frequency) || 0,
    counter: Number(data.counter) || 0,
    offset_ere: Number(data.offset_ere) || 0,
    created_at: String(formatDate(data.created_at)) || '',
    uuid_circuit: String(data.uuid_circuit) || '',
    offset_iae: Number(data.offset_iae) || 0,
    export_active_energy: Number(data.export_active_energy) || 0,
    relay_state: Boolean(data.relay_state),
    voltage: Number(data.voltage) || 0,
    system_status: Number(data.system_status) || 0,
    import_active_energy: Number(data.import_active_energy) || 0,
    apparent_power: Number(data.apparent_power) || 0,
    active_power: Number(data.active_power) || 0,
    offset_ire: Number(data.offset_ire) || 0,
  };
}
