import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#015A9E',
    },
  },
});

export default function IoseSelectFilter({
  label,
  handleChange,
  selectedOption,
  options,
}) {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '170px' }}>
        <TextField
          select
          label={label}
          value={selectedOption}
          onChange={handleChange}
          style={{ width: '170px' }} // Ajuste a largura conforme necessário
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </ThemeProvider>
  );
}
