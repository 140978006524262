import React, { useState, useEffect } from 'react';
import IoseApiLib from 'iose-api-lib';
import jwt from 'jsonwebtoken';
import { useHistory, useLocation } from 'react-router-dom';

import { IoseCognito, LocalStorage, ioseAPI, useSession } from 'common';

import { Container, StyledDiv, StyledImage, StyledMessage } from './style';

import { LoadingAuth } from 'components/IoseLoader';
import { useCreateLogs } from 'hooks';
import Exception from 'common/exceptions';

const decodeToken = (token) => {
  try {
    const decoded = jwt.decode(token, { complete: true });

    return decoded;
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return null;
  }
};

const getMessage = (messageType) => {
  switch (messageType) {
    case 0:
      return <StyledMessage>Aguarde, estamos te autenticando...</StyledMessage>;
    case 1:
      return <StyledMessage>Verificando usuário...</StyledMessage>;
    case 2:
      return (
        <StyledMessage>Usuário encontrado! Redirecionando...</StyledMessage>
      );
    case 3:
      return <StyledMessage>Cadastrando usuário! Aguarde...</StyledMessage>;
    case 4:
      return (
        <StyledMessage>
          Usuário autenticado com sucesso! Redirecionando...
        </StyledMessage>
      );
    default:
      return null;
  }
};

/**

 *

 *This is the Login Page with all elements

 * @export

 * @returns

 */

export default function AuthPage() {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [messageType, setMessageType] = useState(0);

  const { createLoginLog } = useCreateLogs();
  const history = useHistory();
  const location = useLocation();

  const { clearSession } = useSession();

  useEffect(() => {
    const code = location?.state?.code;

    if (code) {
      handleToken(code);
    }

    //eslint-disable-next-line
  }, []);

  const message = getMessage(messageType);

  const handleSessionData = async (sessionData, tokenData) => {
    try {
      let clientData;

      if (sessionData.group !== 'super') {
        clientData = process.env.REACT_APP_UUID_CLIENT;
        LocalStorage.storageClientData(clientData);

        // LocalStorage.storageSessionDynamoData(dataDynamoEmployee);
      }

      const expiration = decodeToken(tokenData.idToken)

      const session = {
        userId: sessionData.userId,
        name: sessionData.name,
        email: sessionData.email,
        group: sessionData.group,
        accessToken: tokenData.accessToken,
        refreshToken: tokenData.refreshToken,
        expiration: expiration.payload.exp,
        idToken: tokenData.idToken,
        // expiration: tokenData.expires ? tokenData.expires : expiration.payload.exp,
      };

      LocalStorage.storageSession(session);

      const operacao = 'LOGIN';
      const form = 'LOGIN';
      const logContent = {};

      await createLoginLog(sessionData, operacao, form, logContent);
    } catch (err) {
      throw err;
    }
  };

  const handleUsers = async (users, email, name) => {
    setMessageType(1);

    const usersList = users.data;

    // Verifica se o usuário já existe com base no email
    const existingUser = usersList?.find((user) => {
      const emailAttribute = user?.Attributes?.find(
        (attr) => attr.Name === 'email'
      );
      return emailAttribute?.Value === email;
    });

    if (existingUser) {
      const userGroups = existingUser.groups || [];

      if (
        userGroups.length === 1 &&
        userGroups[0] === 'us-east-1_hWmApDv4W_SANEPAR'
      ) {
        // Se o usuário tiver apenas um grupo e for "us-east-1_hWmApDv4W_SANEPAR", adicione-o ao grupo "visualizador"
        const group = 'visualizador';

        // Verifica se o usuário já pertence ao grupo "visualizador"
        if (!userGroups.includes(group)) {
          // Adiciona o grupo "visualizador" ao usuário
          userGroups.push(group);

          await ioseAPI.addUserToCognitoGroup(existingUser.Username, group);

          setMessageType(4);
          return;
        }
      }

      setMessageType(2);
      return;
    } else {
      // Usuário não existe, criar e registrar
      setMessageType(3);

      const group = 'visualizador';
      await IoseCognito.createAndRegisterUser(name, email, group);

      setMessageType(4);
      return;
    }
  };

  const handleToken = async (code) => {
    try {
        const response = await ioseAPI.getSsoToken(code);

        // const data = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data;
        const { data } = response;

        IoseApiLib.setToken(data.idToken);

        const decodedIdToken = decodeToken(data.idToken);

        const { given_name, email } = decodedIdToken.payload;

        if (!given_name || !email) {
          throw Exception.SsoLoginError;
        }

        IoseCognito.initCognitoSession(data, given_name);

        const users = await ioseAPI.listCognitoUsersAndGroups();

        await handleUsers(users, email, given_name);

        const sessionData = await IoseCognito.getSessionParams();

        await handleSessionData(sessionData, data);

        if (sessionData) {
            history.replace('/map');
        }
    } catch (error) {
      clearSession(error.message);
    }
};


  const renderContent = () => (
    <StyledDiv>
      <StyledImage width="610px" height="380px" />

      {message}

      <LoadingAuth loading={loading} />
    </StyledDiv>
  );

  return <Container>{renderContent()}</Container>;
}
