import React, { useEffect, useState, useContext } from 'react';
import { StatesAndCities } from '../../constants/index';
import { Grow } from '@material-ui/core/';
import { useAlert } from 'react-alert';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseCardConcessionaires,
  IoseInfinityScroll,
  IoseSearch,
  IoseSubHeaderBar,
} from 'components';

import { AccountContext, ioseAPI, LocalStorage, Messages } from 'common';

import { ConcessionaireIcon, ContainerCards, WrappedContainer } from './style';

import {
  useComercializadoras,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';
import ComercializadorasAddModal from './components/comercializadoras-add-modal';
import ComercializadorasEditModal from './components/comercializadoras-edit-modal';
import ComercializadorasDeleteModal from './components/comercializadoras-delete-modal';

export default function ComercializadorasContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [cards, setCards] = useState([]);
  const [deleteNotificationType, setDeleteNotificationType] = useState(0);
  const [deleteNotification, setDeleteNotification] = useState('');
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [editNotification, setEditNotification] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);

  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();
  const [hasDeletePermission, setHasDeletePermission] = useState();

  const alert = useAlert();

  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();
  const {
    comercializadoras,
    noComercializadorasError,
    refreshComercializadoras,
  } = useComercializadoras();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const addPath = ['cadastro', 'comercializadora', 'criar'];
    const editPath = ['cadastro', 'comercializadora', 'editar'];
    const deletePath = ['cadastro', 'comercializadora', 'deletar'];

    if (userSession && groupPermissions) {
      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    comercializadoras?.length > 0
      ? setCards(comercializadoras)
      : setError(noComercializadorasError);
  }, [comercializadoras, noComercializadorasError]);

  useEffect(() => {
    getCitiesBySelectedState(selectedState);
  }, [selectedState]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const getCitiesBySelectedState = (selectedState) => {
    if (selectedState) {
      const selectedStateObj = StatesAndCities.find((stateObj) =>
        stateObj.estados.some((estado) => estado.nomeEstado === selectedState)
      );

      if (selectedStateObj) {
        const selectedEstado = selectedStateObj.estados.find(
          (estado) => estado.nomeEstado === selectedState
        );
        setSelectedCities(selectedEstado.cidades);
      }
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
  };

  function errorHandlingAddConcessionaires(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  function errorHandlingEditConcessionaires(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  function errorHandlingDeleteConcessionaires(error) {
    alert.show(error ? error : `${Messages.errorDeleteConcessionaires}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  /// ========== OPERATIONS ===========

  async function addComercializadora(
    name,
    code,
    cnpj,
    state,
    city,
    quantidade
  ) {
    setNotificationType(0);
    setLoading(true);

    let sessionData;

    try {
      sessionData = await getSession();
      const response = await ioseAPI.createComercializadora(
        sessionData.headers,
        name,
        code,
        cnpj,
        state,
        city,
        quantidade
      );

      setAddNotification(response.data.message);
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR COMERCIALIZADORA';
      const logContent = {
        name: name,
        code: code,
        cnpj: cnpj,
        state: state,
        city: city,
        quantidade: quantidade,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      errorHandlingAddConcessionaires(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        refreshComercializadoras();
        setNotificationType(0);
        setShowNotification(false);
        setSelectedState(null);
      }, 2500);
    }
  }

  async function editComercializadora(
    uuid_comercializadora,
    name,
    code,
    cnpj,
    state,
    city,
    quantidade
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateComercializadora(
        sessionData.headers,
        uuid_comercializadora,
        name,
        code,
        cnpj,
        state,
        city,
        quantidade
      );

      setEditNotification(response.data.message);
      setEditNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR COMERCIALIZADORA';
      const logContent = {
        uuid_comercializadora: uuid_comercializadora,
        name: name,
        code: code,
        cnpj: cnpj,
        state: state,
        city: city,
        quantidade: quantidade,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;

      errorHandlingEditConcessionaires(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseEditModal();
        refreshComercializadoras();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function deleteComercializadora(uuid_comercializadora) {
    setDeleteNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteComercializadora(
        sessionData.headers,
        uuid_comercializadora
      );

      setDeleteNotification(response.data.message);
      setDeleteNotificationType(1);

      const operacao = 'DELETAR';
      const form = 'DELETAR COMERCIALIZADORA';
      const logContent = {
        uuid_comercializadora: uuid_comercializadora,
      };

      await createLog(operacao, form, logContent);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.errorDeleteComercializadora;

      errorHandlingDeleteConcessionaires(errorMessage);
      setDeleteNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);

      setTimeout(() => {
        handleCloseDeleteModal();
        refreshComercializadoras();
        setDeleteNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  /// ========== OPERATIONS ===========

  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindComercializadoras);
    } else {
      setSearch(false);
    }
  };

  const renderCards = (card) => {
    return (
      <IoseCardConcessionaires
        key={card.uuid_comercializadora}
        name={card.name}
        description={card.city}
        quantidade={card.quantidade}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR COMERCIALIZADORA'}
        cardEditTitle={'EDITAR COMERCIALIZADORA'}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        cardIcon={<ConcessionaireIcon />}
        showDeleteButton={hasDeletePermission}
        showEditButton={hasEditPermission}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR COMERCIALIZADORA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Comercializadoras"
        description={'Total: ' + cards.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Comercializadoras…"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards.length}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {renderAllCards()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <ComercializadorasAddModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          handleStateChange={handleStateChange}
          getCitiesBySelectedState={getCitiesBySelectedState}
          submit={addComercializadora}
          selectedState={selectedState}
          notificationType={notificationType}
          notification={notification}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
          selectedCities={selectedCities}
        />
        <ComercializadorasDeleteModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          submit={deleteComercializadora}
          notificationType={deleteNotificationType}
          notification={notification}
          showNotification={showNotification}
          deleteNotification={deleteNotification}
          loading={loading}
          selectedCard={selectedCard}
        />
        <ComercializadorasEditModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          handleStateChange={handleStateChange}
          getCitiesBySelectedState={getCitiesBySelectedState}
          submit={editComercializadora}
          notificationType={editNotificationType}
          notification={notification}
          editNotification={editNotification}
          showNotification={showNotification}
          loading={loading}
          selectedCard={selectedCard}
          selectedCities={selectedCities}
          setSelectedCities={setSelectedCities}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}
