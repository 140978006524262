import styled from 'styled-components';
import { Paper, Typography, Box } from '@material-ui/core';

export const DivPaper = styled(Paper)`
  height: 750px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  background-color: #081534;
`;

export const Div3d = styled(Paper)`
  height: 750px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
  background-color: #081534;
`;

export const TypographyStyled = styled(Typography)`
  background-color: white;
`;

export const BoxStyled = styled(Box)`
  width: 15px;
  height: 15px;
`

export const LegendItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const LegendStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  padding: 10px;
  position: absolute;
  left: 30px;
  top: 150px;
  width: 530px;
  height: 130px;
  opacity: 85%;
`

export const DivGrafico = styled(Paper)`
  gap: 0px;
  padding: 0px;
  position: absolute;
  right: 30px;
  top: 150px;
  width: 400px;
  height: 330px;
  opacity: 85%;
`