// Função para desenhar pontos preenchidos
export function coresPontos(id, color) {
    const canvas = document.getElementById(id);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const radius = 4.5;
      ctx.beginPath();
      ctx.arc(10, 10, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.lineWidth = 0.1;
      ctx.strokeStyle = '#000000';
      ctx.stroke();
    }
  }
  
  // Função para desenhar pontos com contorno
  export function coresPontos2(id, color) {
    const canvas = document.getElementById(id);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const radius = 4.5;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.arc(10, 10, radius, 0, 2 * Math.PI, false);
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.stroke();
    }
  }