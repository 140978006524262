import React from 'react';
// import  { useState } from 'react';

import Proptype from 'prop-types';

//Imports from IOSE components
import IoseListCheck from 'components/IoseListCheck';
// import IoseButtonSidebar from 'components/IoseButton';

//Imports from Syle
import { Container, Header, CircuitIcon } from './style';

/** This component create a card with a list circuits that user can to select and so can analysy them */
export default function IoseDashboardListCircuits({
  circuits,
  selectCircuits,
  disabledList,
}) {
  return (
    <Container>
      <Header>
        <CircuitIcon />
        <h3>CIRCUITOS</h3>
        {/* <IoseButtonSidebar onClick={handleCircuit} theme="white">
          Ok
        </IoseButtonSidebar> */}
      </Header>
      <IoseListCheck
        title=""
        array={circuits}
        color="black"
        disabledList={disabledList}
        selectAllInitial={true}
        selectCircuits={selectCircuits}
      />
    </Container>
  );
}

IoseDashboardListCircuits.propTypes = {
  /** This props get the array circuit that will be listed. In IoseDashboardListCircuits just pass it to IoseListCheck */
  circuits: Proptype.array,
  /** This props is a function to set circuit select in container. In IoseDashboardListCircuits just pass it to IoseListCheck*/
  selectCircuits: Proptype.func,
  /** This props is boolean that disabled or not the list */
  disabledList: Proptype.bool,
};
