export function mergeFaturas(faturas, completeBillData) {
  // Mapeia as faturas e completa os dados
  const mergedData = faturas?.map((fatura) => {
    const completeData = completeBillData.filter(
      (bill) =>
        bill.reference_month === fatura.mes_referencia &&
        bill.previous_reading === fatura.leitura_anterior
    );

    if (completeData) {
      const energyBills = completeData?.map((billItem) => ({
        ...billItem,
        link_completo: `${fatura.link_pdf}${billItem.pdf_name}`,
        link_extrato_pdf: `${fatura.link_extrato_pdf}`,
        link_acl_boleto: `${fatura.link_acl_boleto}`,
        link_acl_danfe: `${fatura.link_acl_danfe}`,
      }));

      return {
        ...fatura,
        energyBills,
      };
    }

    return fatura;
  });

  // Filtra itens duplicados
  const uniqueItems = mergedData?.reduce((acc, current) => {
    const x = acc.find(
      (item) =>
        item.unidade_consumidora === current.unidade_consumidora &&
        item.mes_referencia === current.mes_referencia &&
        item.total === current.total
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  // Ordena os itens do mês mais atual para o mais antigo
  uniqueItems?.sort((a, b) => {
    const [mesA, anoA] = a.mes_referencia.split('/').map(Number);
    const [mesB, anoB] = b.mes_referencia.split('/').map(Number);

    if (anoA !== anoB) {
      return anoB - anoA;
    } else {
      return mesB - mesA;
    }
  });

  return uniqueItems;
}