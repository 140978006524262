import styled from 'styled-components';
import { FormControl, List } from '@material-ui/core';

//Imports from Material UI components
import { IconButton, Collapse, Grid, Checkbox } from '@material-ui/core';

//Imports from IOSE components
import { Alert } from '@material-ui/lab';

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Subheader = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.text.includes('carregados') && '600'};

  p {
    font-size: 0.875rem;
  }
`;

export const IconButtonStyled = styled(IconButton).attrs((props) => ({
  label: 'close',
  color: 'inherit',
  size: 'small',
}))``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
`;

export const CollapseStyled = styled(Collapse).attrs((props) => ({
  unmountOnExit: true,
}))`
  width: 100%;
  padding: ${(props) => props.padding};

  p {
    /* margin-bottom: 10px; */
    line-height: 24px;
  }

  @media (max-width: 500px) {
    padding: 0%;
    margin-top: 20px;
  }
`;

export const CardsButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const SelectorsDiv = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    width: 100%;
    margin: 32px 0px;
  }
`;

export const AlertStyled = styled(Alert)`
  width: 100%;

  .MuiAlert-message {
    width: 100%;
  }
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  spacing: props.spacing,
}))`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.paddingtop};
  padding-right: 30px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 767px) {
    padding-right: 12px;
  }
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
}))``;

export const FormControlStyled = styled(FormControl)`
  min-width: 120px;
  color: var(--secondary);

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-root input {
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: var(--secondary);
    opacity: 55%;
    font-size: 12px;
  }

  label.Mui-focused {
    color: var(--secondary);
    opacity: 100%;
  }
`;

export const Notification = styled.p`
  color: ${(props) => props.color};
  text-align: center;
  margin-bottom: 20px;
`;

export const DivSearch = styled.div`
  margin-top: 10px;
  width: 35%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ListStyled = styled(List)`
  background: none;
  color: var(--dark);
  overflow-y: scroll;
  padding: 0px !important;
  height: 230px;
  width: 300px;

  @media (max-width: 767px) {
    width: 260px;
  }

  cursor: pointer;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 2px;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  color: var(--base);

  .MuiSvgIcon-root {
    color: var(--primary) !important;
  }
`;
