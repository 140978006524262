import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

export const InfiniteScrollStyle = styled(InfiniteScroll).attrs((props) => ({
  height: props.height,
}))`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  padding: ${(props) => (props.padding ? props.padding : '10px')};

  /* Exemplo de personalização de barra de rolagem */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.scrollbarColor ? props.scrollbarColor : '#015A9E'};
    border-radius: 4px;
  }
  scrollbar-width: thin;
  scrollbar-color: ${(props) =>
      props.scrollbarColor ? props.scrollbarColor : '#015A9E'}
    transparent;
`;

export const EndText = styled.p`
  text-align: center;
  color: ${(props) =>
    props.colortextend ? props.colortextend : 'var(--goal)'};
  font-size: ${(props) => (props.sizetextend ? props.sizetextend : '13px')};
  font-weight: bold;

  @media (max-width: 500px) {
    text-align: left;
    color: ${(props) =>
      props.colortextend ? props.colortextend : 'var(--goal)'};
    font-size: ${(props) => (props.sizetextend ? props.sizetextend : '13px')};
    font-weight: bold;
    padding-left: 40px;
    margin-top: 50px;
  }
`;
