import { useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useGroupsConsumption() {
  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getGroupsConsumption = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllGroupsConsumption(
      headers,
      10000,
      page_size
    );

    const groupsConsumptions = response.data.data || [];

    if (groupsConsumptions.length === 0) {
      console.error(Messages.noFindGroupsConsumptions);
      throw new Error(Messages.noFindGroupsConsumptions);
    }

    return groupsConsumptions;
  };

  const {
    data: groupsConsumption,
    error: groupsConsumptionError,
    isLoading,
  } = useQuery(['groupsConsumptions'], getGroupsConsumption);

  return { groupsConsumption, groupsConsumptionError, isLoading };
}

export default useGroupsConsumption;
