import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClient, LocalStorage } from 'common';
import { WrappedContainer } from './style';
import * as S from './style';

import {
  useGenerarionAccumulated,
  useCreditPercentage,
  useBeneficiaryJoin,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';

import {
  IoseAlertLoadingCards,
  IoseButton,
  IoseSubHeaderBar,
} from 'components';

import Permissions from 'common/permissions';

import { SaneparCreditTable } from 'components/IoseTables';

import energyBillPercentageReport from 'reports/EnergyBills/energyBillPercentageReport';

export default function SaneparCreditContainer() {
  const { client } = useClient();

  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState('');

  const { getGenerarionAccumulatedData } = useGenerarionAccumulated();
  const { getCreditPercentageData } = useCreditPercentage();
  const { BeneficiaryJoinData } = useBeneficiaryJoin();
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['geracao', 'unidades_geradoras', 'leitura'];
    const reportPath = ['geracao', 'unidades_geradoras', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  const totalCredit = getCreditPercentageData
    ? getCreditPercentageData.length
    : '';

  const creditoBeneficiario = getGenerarionAccumulatedData
    ? getGenerarionAccumulatedData
    : [];

  const renderData = BeneficiaryJoinData?.map((audit) => {
    const filteredBeneficiarias = audit?.uc_beneficiarias.filter(
      (beneficiaria) => {
        const [mesCredGerado, anoCredGerado] = beneficiaria.mes_cred_utilizado
          ? beneficiaria.mes_cred_utilizado.split('/')
          : ['', ''];
        const [mesRef, anoRef] = audit.mes_referencia
          ? audit.mes_referencia.split('/')
          : ['', ''];

        // Condição para incluir beneficiárias com mes_cred_gerado igual a null
        return (
          (mesCredGerado === mesRef && anoCredGerado === anoRef) ||
          beneficiaria.mes_cred_gerado === 'NULL'
        );
      }
    );
    return {
      ...audit,
      uc_beneficiarias: filteredBeneficiarias?.map((beneficiaria) => ({
        ...beneficiaria,
        mes_referencia: audit.mes_referencia,
      })),
    };
  }).filter((audit) =>
    audit.uc_beneficiarias.map((beneficiaria) => [
      beneficiaria.uc_beneficiaria,
      beneficiaria.mes_cred_gerado,
      beneficiaria.cred_recebido,
      'kWh',
      beneficiaria.mes_cred_utilizado,
    ])
  );

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line
  }, []);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const FilterWrappedContainer = styled.div`
    display: flex;
    justify-content: flex-end; /* Align to the right */
    align-items: center;
    padding: 1px;
    background-color: #ecf0f5;
    border-radius: 5px;
    margin-bottom: 5px;
    /* Add any other styles you want */
  `;

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        // title={`Sanepar`}
        subtitle={`Unidades Geradoras`}
        description={'Total de Unidades Geradoras: ' + totalCredit}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        ></div>
      </IoseSubHeaderBar>
    );
  };
  const isReportButtonDisabled = creditoBeneficiario.length === 0;

  const filteredData = getCreditPercentageData?.filter((item) => {
    const ucGeradora = item.uc_geradora || '';
    const ucBeneficiaria = item.uc_beneficiaria || '';

    return (
      ucGeradora.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ucBeneficiaria.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handlePercentageReport = async (renderData) => {
    energyBillPercentageReport(renderData);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE PORCENTAGEM DE CRÉDITO';
    const logContent = {};

    await createLog(operacao, form, logContent);

    return;
  };

  const renderFilters = () => {
    return (
      <FilterWrappedContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {hasReportPermission ? (
            <IoseButton
              onClick={() => handlePercentageReport(renderData)}
              disabled={isReportButtonDisabled}
            >
              EMITIR RELATÓRIO
            </IoseButton>
          ) : null}
          {isReportButtonDisabled && (
            <p
              style={{
                fontSize: '12px',
                color: 'red',
                marginTop: '5px',
                marginBottom: '0',
              }}
            >
              Nenhum dado disponível para relatório.
            </p>
          )}
        </div>
      </FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <SaneparCreditTable
          data={filteredData}
          hasReportPermission={hasReportPermission}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <>
            {renderFilters()}
            <S.TableHeaderBox>
              <S.DivSearch>
                <S.InputBaseStyled
                  type="text"
                  placeholder="Pesquisar por UC Geradora..."
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <S.SearchIconStyled />
              </S.DivSearch>
            </S.TableHeaderBox>
            {renderTableorImage()}
          </>
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReadPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </WrappedContainer>
  );
}
