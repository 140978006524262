import React, { useState } from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  makeStyles,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import IoseNoDataMessage from 'components/IoseNoDataMessage';

import { Visibility as VisibilityIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function formatDate(dataString) {
  const data = new Date(dataString);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

export default function IoseLogsTable({ loading, data }) {
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTooltipOpen = (index) => {
    setTooltipIndex(index);
  };

  const handleTooltipClose = () => {
    setTooltipIndex(null);
  };

  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
          <LogsTable
            data={data} id="scrollableDiv"
            page={page}
            rowsPerPage={rowsPerPage}
          />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  const LogsTable = ({ data, onRowClick }) => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageData = data.slice(startIndex, endIndex);


   return (
    <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>USUÁRIO</TableHeaderCellCentered>
        <TableHeaderCellCentered>EMAIL</TableHeaderCellCentered>
        <TableHeaderCellCentered>OPERAÇÃO</TableHeaderCellCentered>
        <TableHeaderCellCentered>AÇÃO / FORMULÁRIO</TableHeaderCellCentered>
        <TableHeaderCellCentered>DATA</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONTEÚDO</TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {pageData?.map((value, index) => (
        <LogsTableRow
          key={index}
          value={value}
          onClick={onRowClick}
          index={index}
        />
      ))}
    </TableBody>
  </StyledTable>
   )
  }

  const LogsTableRow = ({ value, onClick, index,  page, rowsPerPage }) => {
    const classes = useStyles();

    return (
      <TableRowStyled align="center" className={classes.tableRow}>
        <TableCellCentered>{value?.log_user}</TableCellCentered>
        <TableCellCentered>{value?.log_user_email}</TableCellCentered>
        <TableCellCentered>{value?.log_operation}</TableCellCentered>
        <TableCellCentered>{value?.log_form}</TableCellCentered>
        <TableCellCentered>{formatDate(value?.log_date)}</TableCellCentered>
        <TableCellCentered>
          {value?.log_content && Object.keys(value?.log_content).length > 0 ? (
            <Tooltip
              title={
                <Paper className={classes.tooltipPaper}>
                  <List>
                    {Object.entries(value?.log_content).map(([key, val]) => (
                      <ListItem key={key}>
                        <ListItemText
                          primary={`${key}: ${val}`}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              }
              arrow
              interactive
              open={tooltipIndex === index}
              onClose={handleTooltipClose}
            >
              <IconButton
                onClick={() => handleTooltipOpen(index)}
                style={{ height: '10px' }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          ) : (
            'SEM CONTEÚDO'
          )}
        </TableCellCentered>
      </TableRowStyled>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
          dataLength={data ? parseInt(data.length) : 10}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={500}
          >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>

        <TablePagination
          component="div"
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Itens por página:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
          }
          backIconButtonText="Página anterior"
          nextIconButtonText="Próxima página"
        />
        </Paper>
      )}
    </div>
  );
}
