const Exception = {};

const ioseError = (name, message) => {
  const error = Error(message);
  error.name = name;
  return error;
};

Exception.UserNotFoundException = ioseError(
  'UserNotFoundException',
  'Usuário não encontrado!'
);

Exception.NotAuthorizedException = ioseError(
  'NotAuthorizedException',
  'Usuário ou senha incorreta!'
);

Exception.CanNotGetSessionException = ioseError(
  'CanNotGetSessionException',
  'Não foi possível pegar a sessão'
);

Exception.NotLoggedException = ioseError(
  'notLoggedException',
  'O usuário não fez o login no sistema'
);

Exception.NewPasswordRequiredException = ioseError(
  'NewPasswordRequiredException',
  'Usuário precisa mudar a senha'
);

Exception.InvalidParameterException = ioseError(
  'InvalidParameterException',
  'Email ou Senha devem ser preenchido!'
);

Exception.PasswordFormatInvalid = ioseError(
  'PasswordFormatInvalid',
  'A senha não está em conformidade com os requistos pedidos acima.'
);

Exception.SuperUserException = ioseError(
  'SuperUserException',
  'Usuário não permito para esse ambiente!'
);

Exception.ItemNotFoundItemException = ioseError(
  'ItemNotFoundItemException',
  'Item não encontrado!'
);

Exception.AttemptLimitExceeded = ioseError(
  'AttemptLimitExceeded',
  'Limite de tentativas excedido, por favor, tente novamente após alguns minutos!'
);

Exception.notSamePasswords = ioseError(
  'notSamePasswords',
  'As senhas não estão iguais!'
);

Exception.ErrorRefreshToken = ioseError(
  'ErrorRefreshToken',
  'Sessão expirada, refaça o login !'
);

Exception.InvalidCode = ioseError(
  'InvalidCode',
  'Código de verificação inválido, por favor tente novamente!'
);

Exception.notLoggedIn = ioseError(
  'NotLoggedIn',
  'Oooops! Faça o login primeiro!'
);

Exception.SsoLoginError = ioseError(
  'SsoLoginError',
  'Não foi possível realizar o login via SSO devido à falta de informações. Por favor, entre em contato com o setor GTIN da Sanepar.'
);

export default Exception;
