import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import * as S from './style';

import { IoseButton2 } from 'components';
import IoseTelemetryEnergyBillTable from 'components/Tables/IoseTelemetryEnergyBillTable';
import { useCreateLogs } from 'hooks';

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data.map((obj) => {
    const { uuid_circuit, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'reference_month',
    'measured_consumption',
    'bill_consumption',
    'balance',
    'beginning_period',
    'end_period',
    'measured_days',
  ];

  // Reordena os dados
  const reorderedData = filteredData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    date: 'Mês/Ano',
    measured_consumption: 'Consumo Iose (kWh)',
    bill_consumption: 'Consumo Conta (kWh)',
    balance: 'Balanço (kWh)',
    beginning_period: 'Início do período',
    end_period: 'Final do Período',
    measured_days: 'Dias medidos',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Comparação de Consumo');
  XLSX.writeFile(workbook, 'comparação-consumo-iose.xlsx');
}

export default function IoseTelemetryBillsComparisonContainer({
  loading,
  data,
}) {
  const { createLog } = useCreateLogs();
  const [filteredData, setFilteredData] = useState('');

  const values = data ? Object.values(data) : [];
  const filteredIdentificationNumber = values
    ?.flat()
    ?.filter((item) =>
      item?.identification_number?.toString().includes(filteredData.toString())
    );

  async function handleDownloadButtonClick() {
    exportToExcel(filteredIdentificationNumber);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'COMPARAÇÃO DE CONSUMO TELEMETRIA';
    const logContent = {};

    try {
      await createLog(operacao, form, logContent);
    } catch (error) {
      console.error(error);
    }
  }

  const renderTableHeader = () => {
    return (
      <S.TableHeaderBox>
        <S.TableTitle>
          <h3>COMPARAÇÃO DE CONSUMO POR UNIDADE CONSUMIDORA</h3>
        </S.TableTitle>
        <S.DivSearch>
          <S.InputBaseStyled
            type="text"
            placeholder="Pesquisar por Unidade Consumidora..."
            value={filteredData}
            onChange={(event) => setFilteredData(event.target.value)}
          />
          <S.SearchIconStyled />
        </S.DivSearch>
        <IoseButton2 onClick={handleDownloadButtonClick}>
          Download XLSX
        </IoseButton2>
      </S.TableHeaderBox>
    );
  };

  const renderTable = () => {
    return (
      <IoseTelemetryEnergyBillTable
        loading={loading}
        data={filteredIdentificationNumber}
      />
    );
  };

  return (
    <S.TableContainer id="scrollableDiv">
      <S.TableBox>
        {renderTableHeader()}
        {renderTable()}
      </S.TableBox>
    </S.TableContainer>
  );
}
