import { useContext } from 'react';
import { ioseAPI, AccountContext, Messages } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConsumptionComparisonTelemetry() {
  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getAllConsumptionComparisonsTelemetry = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllConsumptionComparisonTelemetry(
      headers,
      10000,
      page_size
    );

    const consumptionComparisonTelemetry = response.data.data || 0;

    if (consumptionComparisonTelemetry.length === 0) {
      console.error(Messages.noRegisteredComparison);
      throw new Error(Messages.noRegisteredComparison);
    }
    return consumptionComparisonTelemetry;
  };

  const {
    data: consumptionComparisonTelemetry,
    error: consumptionComparisonError,
    isLoading,
  } = useQuery(['consumptionComparisonTelemetry'], getAllConsumptionComparisonsTelemetry);

  return {
    consumptionComparisonTelemetry,
    consumptionComparisonError,
    isLoading,
  };
}

export default useConsumptionComparisonTelemetry;
