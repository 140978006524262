import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import moment from 'moment';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseSearch,
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseContractsAlertCard,
  IoseContractsTableContainer,
  LoadingIndicator,
} from 'components';

import { useClient, Messages, LocalStorage } from 'common';

import { useContracts, useGroupPermissions } from 'hooks';

import Permissions from 'common/permissions';

const formatCurrency = (value) => {
  const formattedValue = value?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  return formattedValue;
};

export default function ContractListContainer({ getcards }) {
  const [allContracts, setAllContracts] = useState([]);
  const [contractsFiltered, setContractsFiltered] = useState([]);
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { client } = useClient();

  const { contracts, noContractsError } = useContracts();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['gerenciamento', 'contratos', 'leitura'];
    const reportPath = ['gerenciamento', 'contratos', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const formatContracts = (contracts) => {
      const today = moment().startOf('day');

      const formattedContracts = contracts?.map((contract) => {
        const finalDate = moment(contract.final_date).startOf('day');
        const initialDate = moment(contract.initial_date).startOf('day');

        const formattedFinalDate = finalDate.format('DD/MM/YYYY');
        const formattedInitialDate = initialDate.format('DD/MM/YYYY');

        const daysDiff = finalDate.diff(today, 'days');

        return {
          ...contract,
          expires_in: daysDiff,
          final_date: formattedFinalDate,
          initial_date: formattedInitialDate,
        };
      });

      setAllContracts(formattedContracts);
    };

    formatContracts(contracts);
  }, [contracts, noContractsError]);

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  //Function to filter
  const filterContracts = (searchName = '') => {
    setError('');

    if (searchName !== '') {
      const itemFiltered = allContracts?.filter((item) =>
        item.contract_number.includes(searchName)
      );

      itemFiltered.length !== 0
        ? setContractsFiltered(itemFiltered)
        : setError(Messages.noFindContracts);
    } else {
      setContractsFiltered([]);
    }
  };

  const filteredData =
    contractsFiltered?.length > 0 ? contractsFiltered : allContracts;

  const renderCards = () => {
    // Filtrar contratos vencidos
    const expiredContracts = filteredData?.filter(
      (contract) => contract.expires_in <= 0
    );

    // Filtrar contratos a vencer em 15 dias
    const contractsExpiringIn15Days = filteredData?.filter(
      (contract) => contract.expires_in <= 29 && contract.expires_in > 0
    );

    // Filtrar contratos a vencer em 30 dias
    const contractsExpiringIn30Days = filteredData?.filter(
      (contract) => contract.expires_in > 30 && contract.expires_in > 0
    );

    const totalContractsSum = filteredData?.reduce((sum, contract) => {
      const contractValue = parseFloat(contract.total_contracts);
      if (!isNaN(contractValue)) {
        return sum + contractValue;
      }
      return sum.toFixed(2);
    }, 0);

    const formattedSum = formatCurrency(totalContractsSum);

    return (
      <S.ContainerCards>
        <IoseContractsAlertCard
          title="CONTRATOS A VENCER (30 DIAS)"
          value={contractsExpiringIn30Days?.length.toString()}
          valueColor="#13B838"
          color="#ECF0F5"
        />
        <IoseContractsAlertCard
          title="CONTRATOS A VENCER (15 DIAS)"
          value={contractsExpiringIn15Days?.length.toString()}
          valueColor="#FF9518"
          color="#ECF0F5"
        />
        <IoseContractsAlertCard
          title="CONTRATOS VENCIDOS"
          value={expiredContracts?.length.toString()}
          valueColor="#DC1F1F"
          color="#ECF0F5"
        />
        <IoseContractsAlertCard
          title="TOTAL DE CONTRATOS"
          value={formattedSum}
          valueColor="#7ED90C"
          color="#ECF0F5"
        />
      </S.ContainerCards>
    );
  };

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <IoseContractsTableContainer
          tableTitle="TABELA DE CONTROLE DE CONTRATOS"
          searchInTable={false}
          data={filteredData}
          hasReportPermission={hasReportPermission}
        />
      </S.ContainerTable>
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Controle de Contratos"
        description={`Total: ${
          allContracts?.length ? allContracts?.length : 0
        }`}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar por número do contrato..."
          funcSearch={filterContracts}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div style={{ marginBottom: '10px' }}>{renderCards()}</div>
          <div>{renderTable()}</div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

ContractListContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
