import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ErrorImage from './img/error_illustration.svg';

export const StyledIoseErrorMessage = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledWrapp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

export const StyledImage = styled.div`
  width: ${(props) => (props.width ? props.width : '120px')};
  height: ${(props) => (props.height ? props.height : '120px')};
  background-image: url(${ErrorImage});
  background-size: cover;
  background-repeat: no-repeat;
`;
