// hooks/useContractEffects.js
import { useState } from 'react';
import { Messages } from 'common';
import { useQuery } from '@tanstack/react-query';
import useConsumerGroup from './useConsumerGroup';

function useUniqueConsumerGroupModalidade() {
  const [noModalidadeError, setNoModalidadeError] = useState(
    ''
  );

  const { consumerGroups } = useConsumerGroup();

  const getUniqueModalidade = async () => {

    const modalidadeSet = new Set();

    consumerGroups?.forEach((item) => {
      if (
        item.modalidade
        && item.modalidade !== '0'
        && item.modalidade !== ''
        && item.modalidade.toUpperCase() !== 'NULL'
      ) {
        modalidadeSet.add(item.modalidade);
      }
    });

    const modalidades = [...modalidadeSet].map(
      (modalidade) => ({
        value: modalidade,
        label: modalidade,
      })
    );

    if (modalidades.length === 0) {
      setNoModalidadeError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }

    return { modalidades };
  };

  const {
    data: { modalidades } = {},
    error: modalidadeError,
    refetch: refreshAddress,
    isLoading,
  } = useQuery(['modalidade', consumerGroups], getUniqueModalidade, {
    enabled: !!consumerGroups && consumerGroups.length > 0,
  });

  return {
    modalidades,
    modalidadeError,
    noModalidadeError,
    refreshAddress,
    isLoading,
  };
}

export default useUniqueConsumerGroupModalidade;
