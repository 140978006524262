import React from "react";
import * as S from '../style';
import { IoseInfinityScroll } from "components/IoseInfinityScroll";

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalExtractTable({ extracts }) {
  if (!extracts || extracts.length === 0) return null;

  const extrato = extracts[0];

  // Função para obter o valor da chave correspondente
  const getValue = (keyPrefix, col) => {
    const key = `${keyPrefix}${col}`;
    return extrato[key] !== undefined ? extrato[key] : '0';
  };

  return (
    <S.TableContainer>
      <IoseInfinityScroll
            dataLength={22}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={0}
            padding={0}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={389}
            scrollbarColor="#015A9E"
          >
      <S.Table>
        <S.TableHeader>
          <tr>
            <S.TableHeaderCell>PRODUTO</S.TableHeaderCell>
            <S.TableHeaderCell>LEITURA ANTERIOR</S.TableHeaderCell>
            <S.TableHeaderCell>LEITURA ATUAL</S.TableHeaderCell>
            <S.TableHeaderCell>MEDIDO</S.TableHeaderCell>
            <S.TableHeaderCell>CONTRATADO</S.TableHeaderCell>
            <S.TableHeaderCell>FATURADO</S.TableHeaderCell>
            <S.TableHeaderCell>TARIFA</S.TableHeaderCell>
            <S.TableHeaderCell>TOTAL</S.TableHeaderCell>
          </tr>
        </S.TableHeader>
        <tbody>

          {Array.from({ length: 22 }, (_, i) => i + 1).map((col) => (
            <S.TableExtractRow key={col}>
              <S.TableCell>{getValue('prod_descri', col)}</S.TableCell>
              <S.TableCell>{getValue('prod_leitura_ant', col)}</S.TableCell>
              <S.TableCell>{getValue('prod_leitura_atual', col)}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('consumo_medido', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('contratado', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('consumo_faturado', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('prod_tarifa_', col))}</S.TableCell>
              <S.TableCell>{convertNumberToDecimalStyle(getValue('prod_val_total_', col))}</S.TableCell>
            </S.TableExtractRow>
          ))}

        </tbody>
      </S.Table>
      </IoseInfinityScroll>
    </S.TableContainer>
  );
}
