import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormikContext, Field } from "formik";
import styled from "styled-components";

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    color: #fff;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
  }

  .MuiInputBase-input {
    font-size: 12px;
  }

  @media (max-width: 1360px) {
    .MuiInputBase-input {
      font-size: 11px;
    }
  }
`;

const AutocompleteField = ({ name, options, getOptionLabel, getOptionSelected, label, ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field }) => (
        <StyledAutocomplete
          {...props}
          options={options}
          getOptionLabel={getOptionLabel}
          value={options.find(option => getOptionSelected(option, field.value)) || null}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue ? getOptionLabel(newValue) : '');
          }}
          renderInput={(params) => <TextField {...params} label={label} margin="normal" required={true} />}
        />
      )}
    </Field>
  );
};

export default AutocompleteField;
