import React, { useEffect, useState } from 'react';
import * as S from '../style'
import CloseIcon from '@material-ui/icons/Close';
import { Slide } from '@material-ui/core';


export default function EnergyBillProcessingModal({ onClose, data }) {

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      const hasProcessedItems = data?.some(item => item.recebido);
      setShowContent(hasProcessedItems);
    } else {
      setShowContent(false);
    }
  }, [data]);

  return (
    <Slide
      direction="left"
      in={showContent}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 1000, exit: 400 }}
    >
      <S.WrapperModal>
        <S.ModalCloseButton onClick={() => onClose(false)}>
          <CloseIcon />
        </S.ModalCloseButton>
        <div>
          <div>
            <strong>STATUS DAS LEITURAS DO MÊS {data && data[0]?.reference_month}</strong>
          </div>
          <div style={{ display: 'flex', gap: '2px', padding: '10px', alignItems: 'center' }}>
            {data?.map((item, i) => (
              <div key={i} style={{ display: 'flex', flexDirection: 'column', minWidth: '130px' }}>
                <p style={{ fontSize: '11px', color: item?.processado && item?.quantity === 0 ? '#FF6C00' : '#000000' }}><strong style={{ fontSize: '11px' }}>{item?.concessionaire}</strong></p>
                <p style={{ fontSize: '11px', color: item?.processado && item?.quantity === 0 ? '#FF6C00' : '#000000' }}><strong style={{ fontSize: '11px' }}>Entregue:</strong> {item?.recebido}</p>
                <p style={{ fontSize: '11px', color: item?.processado && item?.quantity === 0 ? '#FF6C00' : '#000000' }}><strong style={{ fontSize: '11px' }}>Processado:</strong> {item?.processado}</p>
                <p style={{ fontSize: '11px', color: item?.processado && item?.quantity === 0 ? '#FF6C00' : '#000000' }}><strong style={{ fontSize: '11px' }}>Quantidade:</strong> {item?.quantity}</p>
              </div>
            ))}
          </div>
        </div>
      </S.WrapperModal>
    </Slide>
  );
}
