// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCreditConnections() {
  const [noCreditConnection, setNoCreditConnection] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCreditConnection = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getAllCreditConnections(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
     } while (lastEvaluatedKey)

    if (allData.length === 0) {
      setNoCreditConnection(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return allData;
  };

  const {
    data: creditConnectionData,
    error: creditConnectionDataError,
    refetch: refreshCreditConnection,
    isLoading,
  } = useQuery(['creditConnection'], getCreditConnection);

  return {
    creditConnectionData,
    creditConnectionDataError,
    noCreditConnection,
    refreshCreditConnection,
    isLoading,
  };
}

export default useCreditConnections;
