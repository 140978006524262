import styled from 'styled-components';

//Imports from Material Ui components
import {
  Avatar,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Divider,
} from '@material-ui/core';

//Import image
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

export const CardStyled = styled(Card)`
  width: auto;
  height: ${(props) => (props.open ? 'auto' : '80px')};
  margin: 0px 15px 15px 0px;
  padding: 16px;
  border: solid 2px transparent;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer;

  &:hover {
    border: solid 2px var(--primary);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const CardAlertStyled = styled.div`
  width: ${(props) => (props.width ? 'auto' : '280px')};
  height: ${(props) => (props.height ? 'auto' : '120px')};
  margin: 10px 0px 10px 0px;
  padding: 16px;
  background-color: ${(props) => (props.color ? props.color : '#015A9E')};
  border-radius: 10px;
  transition: all 0.3s ease-in-out !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    scale: 1.1;
  }

  @media (max-width: 767px) {
    width: 70%;
  }
`;

export const IconDiv = styled.div`
  width: 35px;
`;

export const CardAlertHeader = styled.div`
  height: 25px;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const CardAlertContent = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color};
`;

export const TypographyHeaderStyled = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 0px;
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 1400px) {
    font-size: 11px;
  }
`;

export const TypographySubHeaderStyled = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-style: italic;
  font-weight: bold;
  padding: 0px;
  text-align: center;
`;

export const TypographyContentStyled = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-size: 20px;
  font-weight: bold;
  padding: 0px;
  text-align: center;
  color: ${(props) => props.valueColor};

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

export const DivHeaderButton = styled.div`
  padding: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;

  span {
    white-space: pre-wrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-title {
    p {
      font-size: 13px !important;
      line-height: 14px;
      max-height: 28px;
      font-weight: 600;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      transition: all 0.3s ease-in-out;
    }
  }

  .MuiCardHeader-subheader {
    p {
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px !important;
    }
  }

  /* &:hover .MuiCardHeader-title {
    font-size: 13.5px;
    cursor: pointer;
  } */
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--secondary);
`;

export const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px !important;
  /* height: 35px; */
`;

export const TypographyStyled = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  text-align: right;
  opacity: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0px;
  color: ${(props) =>
    props.red
      ? 'var(--alert) !important'
      : props.green
      ? 'var(--success) !important'
      : 'var(--goal) !important'};

  b {
    margin-left: 10px;
  }
  @media (max-width: 500px) {
    margin-top: 0px !important;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
`;

export const DividerStyled = styled(Divider).attrs((props) => ({}))`
  width: 30%;
  height: 2px;
  background-color: #fff;

  border-radius: 10px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: black;
`;

export const ConsumerGroupIcon = styled(OfflineBoltIcon)`
  color: var(--primary);
`;

export const DivMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px;
  width: 100%;
`;

export const DivWrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0 10px 0;
  background-color: #ecf0f5;
  border-radius: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const DivInfos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-bottom: 2px;
  font-family: 'Montserrat', sans-serif;

  p {
    margin: 0;
    width: 100%;
    color: var(--primary);

    strong {
      margin-right: 10px;
    }
  }
`;

export const ContractAnchor = styled.a`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0 10px 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: 0.3s ease-in-out;

  &:hover {
    letter-spacing: 1.2px;
  }
`;
