import styled from 'styled-components';
import {
  Table,
  TableCell,
  TableContainer,
  TablePagination,
} from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const TableStyled = styled(Table)`
  .MuiTableCell-root {
    display: table-cell;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--primary)' : 'var(--primary)'};
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
    font-size: 12px;

    &:hover {
      .MuiTableSortLabel-root,
      .MuiTableSortLabel-icon {
        color: var(--secondary);
      }
    }

    .MuiTableSortLabel-active,
    .MuiTableSortLabel-iconDirectionAsc,
    .MuiTableSortLabel-iconDirectionDesc {
      color: var(--secondary) !important;
    }
  }
`;

export const TableCellHeader = styled(TableCell)`
  .MuiButtonBase-root,
  .NoSortLabel {
    font-size: 12px;
    padding-left: 20px;
    font-weight: bolder;
  }

  .NoSortLabel {
    cursor: auto;
  }
`;

export const TableContainerStyled = styled(TableContainer)`
  /* height: 220px; */
  width: 100%;
  cursor: pointer;
  overflow-x: none;
  overflow-y: scroll;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const TablePaginationStyled = styled(TablePagination)`
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
  align-content: baseline;
  justify-content: flex-end;
  color: ${(props) =>
    props.dashboard === 'true' ? 'var(--primary)' : 'var(--base)'};

  svg {
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--primary)' : 'var(--base)'};
  }
`;

export const TableCellRelay = styled(TableCell)`
  color: ${(props) =>
    props.relaystate === 'true' ? 'var(--success)' : 'var(--alert)'} !important;
`;

export const Error = styled.b`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  color: red;
`;
