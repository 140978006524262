import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-input-mask';
import {
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Grow,
  Input,
} from '@material-ui/core';

import {
  TextFieldStyled,
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseModalButton,
} from 'components';

import { StatesAndCities } from '../../../constants/index.js';

import * as S from '../style';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function ComercializadorasEditModal({
  open,
  onClose,
  loading,
  handleStateChange,
  notificationType,
  showNotification,
  submit,
  selectedCard,
  selectedCities,
  setSelectedCities,
  editNotification,
}) {
  const classes = useStyles();

  const handleUpdateConcessionaire = (values) => {
    setTimeout(() => {
      const { name, code, cnpj, state, city, quantidade } = values;
      submit(
        selectedCard.uuid_comercializadora,
        name,
        code,
        cnpj,
        state,
        city,
        quantidade
      );
    }, 500);
  };

  useEffect(() => {
    if (open) {
      const selectedStateCities = StatesAndCities.find((stateObj) =>
        stateObj.estados.some(
          (estado) => estado.nomeEstado === selectedCard?.state
        )
      )?.estados.find((estado) => estado.nomeEstado === selectedCard?.state)
        ?.cidades;

      setSelectedCities(selectedStateCities || []);
    }
    //eslint-disable-next-line
  }, [open, selectedCard]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                COMERCIALIZADORAS DE ENERGIA
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                EDITAR COMERCIALIZADORA DE ENERGIA
              </S.ModalTitle>
              <Formik
                initialValues={{
                  name: selectedCard?.name,
                  code: selectedCard?.code,
                  cnpj: selectedCard?.cnpj,
                  state: selectedCard?.state,
                  city: selectedCard?.city,
                  quantidade: selectedCard?.quantidade,
                }}
                onSubmit={handleUpdateConcessionaire}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  errors,
                  touched,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'name'}
                        name={'name'}
                        type={'text'}
                        label={'Nome'}
                        defaultvalue={initialValues['name']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['name'] && touched['name']
                            ? errors['name']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'code'}
                        code={'code'}
                        label={'Código da comercializadora'}
                        defaultvalue={initialValues['code']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['code'] && touched['code']
                            ? errors['code']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field name="cnpj">
                        {({ field }) => (
                          <S.StyledFormControl fullWidth>
                            <S.StyledInputLabel htmlFor="cnpj">
                              Cnpj
                            </S.StyledInputLabel>
                            <Input
                              id="cnpj"
                              type="text"
                              {...field}
                              inputComponent={MaskedInput}
                              inputProps={{
                                mask: '99.999.999/9999-99',
                                placeholder: '00.000.000/0000-00',
                              }}
                              style={{ color: '#ffff', opacity: '80%' }}
                            />
                          </S.StyledFormControl>
                        )}
                      </Field>
                    </S.DivInputs>

                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'quantidade'}
                        quantidade={'quantidade'}
                        label={'Quantidade Contratada'}
                        defaultvalue={initialValues['quantidade']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['quantidade'] && touched['quantidade']
                            ? errors['quantidade']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <InputLabel id="state-label">Estado</InputLabel>
                        <Field
                          as={Select}
                          name="state"
                          labelId="state-label"
                          id="state-select"
                          className={classes.whiteText}
                          defaultvalue={initialValues['state']}
                          onChange={(event) => {
                            handleChange(event);
                            handleStateChange(event);
                          }}
                        >
                          {StatesAndCities.map((stateObj) =>
                            stateObj.estados.map((estado) => (
                              <MenuItem
                                key={estado.id}
                                value={estado.nomeEstado}
                              >
                                {estado.nomeEstado}
                              </MenuItem>
                            ))
                          )}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <InputLabel id="city-label">Cidade</InputLabel>
                        <Field
                          as={Select}
                          name="city"
                          labelId="city-label"
                          id="city-select"
                          defaultvalue={initialValues['city']}
                          className={classes.whiteText}
                        >
                          {selectedCities.map((city) => (
                            <MenuItem
                              key={city.id_cidade}
                              value={city.nomeCidade}
                            >
                              {city.nomeCidade}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          theme="white"
                          disabled={isSubmitting}
                        >
                          Editar Comercializadora
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
