import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import IoseContainer from 'components/IoseContainer';

export const TitleDashboardComponent = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const GridStyled = styled(Grid)`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const GridStyledList = styled(Grid)`
  flex: 1;
  padding: 5px 10px 5px 0px;

  @media (max-width: 500px) {
    padding: 5px 0px 5px 0px;
  }
`;

export const GridContainer = styled(Grid)`
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const IoseContainerStyled = styled(IoseContainer)`
  /* width: 100%; */
  /* padding-right: 25%; */

  /* overflow-y: scroll;
  overflow-x: none !important; */
  /* border: solid 1px green; */

  /* &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px; */

  @media (max-width: 500px) {
    padding-right: 20px;
  }

  /* @media (min-width: 2000px) {
    margin: 0% 10%;
  } */
`;
