import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseNoDataMessage, LoadingIndicator } from 'components';

function ordenarPorData(consumos) {
  return consumos?.sort((a, b) => {
    const [dayA, monthA, yearA] = a.data.split('/').map(Number);
    const [dayB, monthB, yearB] = b.data.split('/').map(Number);
    const dateA = new Date(yearA, monthA - 1, dayA); // Mês é zero-indexado em JavaScript
    const dateB = new Date(yearB, monthB - 1, dayB);
    return dateA - dateB;
  });
}

export default function GraficoTotalDiario30Dias({ consumos, loading}) {

  const { totaisPorDia } = consumos ? consumos : []

  const [consumosOrdenados, setConsumosOrdenados] = useState();

  useEffect(() => {
    const consumosOrdenados = ordenarPorData(totaisPorDia)

    setConsumosOrdenados(consumosOrdenados)
  }, [totaisPorDia]);

  const datas = consumosOrdenados?.map(item => item.data);
  const potenciaAtiva = consumosOrdenados?.map(item => item.total_potencia_a_wh_q1);
  const potenciaReativa = consumosOrdenados?.map(item => item.total_potencia_reativa_a_varh_q1);

  const option = {
    grid: {
      left: '2%',
      right: '5%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        const formattedParams = params.map(param => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value).toLocaleString('pt-BR')
              : param.value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });
        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      }
    },
    legend: {
      data: [
        'Consumo (Wh)',
        'Consumo Reativo (VArh)',
        'Demanda (kW)',
        'Demanda Reativa (kVAr)',
        'Demanda P',
        'Demanda FP'
      ]
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        // prettier-ignore
        data: datas
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      }
    ],
    series: [
      {
        name: 'Consumo (Wh)',
        type: 'bar',
        data: potenciaAtiva,
      },
      {
        name: 'Consumo Reativo (VArh)',
        type: 'bar',
        data: potenciaReativa,
        showSymbol: false
      }
    ]
  };

  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  return consumosOrdenados?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 400 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}
