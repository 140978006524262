import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function consumerGroupsReport(data) {
  const totalConsumerGroups = data?.length;

  function formatDate(dataISOString) {
    const data = new Date(dataISOString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Resumo de Unidades Consumidoras`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 4,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('Cidade'),
    generateHeaderCell('Unidade Operacional'),
    generateHeaderCell('Unidade Consumidora'),
    generateHeaderCell('Endereço'),
    generateHeaderCell('Subgrupo'),
    generateHeaderCell('Tensão'),
    generateHeaderCell('Modalidade'),
    generateHeaderCell('Descrição da Unidade'),
    generateHeaderCell('Atividade'),
    generateHeaderCell('Código GR'), //cod_unidade
    generateHeaderCell('Código do Local'),
    generateHeaderCell('Código do Orgão Pagador'),
    generateHeaderCell('Ínicio Vigência'),
    generateHeaderCell('Fim Vigência'),
    generateHeaderCell('Gerência Regional'),
    generateHeaderCell('Gerência Geral'),
    generateHeaderCell('Concessionária'),
    generateHeaderCell('Cogeração'),
    generateHeaderCell('USBIO'),
    generateHeaderCell('Crédito de Carbono'),
    generateHeaderCell('Geradora'),
    generateHeaderCell('Beneficiária'),
    generateHeaderCell('Telemetria'),
    generateHeaderCell('Status'),
    generateHeaderCell('Código do Faturamento'),
  ];

  const renderData = data?.map((log) => [
    generateCell(log.city), // Cidade
    generateCell(log.operating_unity), // Unidade Operacional
    generateCell(log.identification_number), // Unidade Consumidora
    generateCell(log.address), // Endereço
    generateCell(log.subgrupo), // Subgrupo
    generateCell(log.tensao), // Tensão
    generateCell(log.modalidade), // Modalidade
    generateCell(log.descricao_unidade), // Descrição da Unidade
    generateCell(log.atividade), // Atividade
    generateCell(log.cod_unidade), // Código GR
    generateCell(log.cod_local), // Código do Local
    generateCell(log.cod_orgao_pagador), // Código do Orgão Pagador
    generateCell(formatDate(log.inicio_vigencia)), // Ínicio Vigência
    generateCell(formatDate(log.fim_vigencia)), // Fim Vigência
    generateCell(log.regional_management), // Gerência Regional
    generateCell(log.general_management), // Gerência Geral
    generateCell(log.concessionaire), // Concessionária
    generateCell(log.cogeracao), // Cogeração
    generateCell(log.csbio), // USBIO
    generateCell(log.carbon_credit), // Crédito de Carbono
    generateCell(log.geradora), // Geradora
    generateCell(log.beneficiaria), // Beneficiária
    generateCell(log.telemetry), // Telemetria
    generateCell(log.ativo), // Status
    generateCell(log.cod_faturamento), // Código do Faturamento
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
        ],
        body: [generateHeaderRow, ...renderData],
        layout: 'autowidth',
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de Unidades Consumidoras',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalConsumerGroups,
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    pageSize: 'A4',
    pageOrientation: 'landscape',
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
