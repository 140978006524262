import styled from 'styled-components';

//imports from IOSE components
import IoseSideCard from 'components/IoseSideCard';

export const Container = styled(IoseSideCard)`
  flex-direction: column;
  padding: 4%;
  color: var(--base);
  height: auto;
  margin-bottom: 10px;
`;
