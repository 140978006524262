import styled from 'styled-components';

//Imports Material UI components
import TextField from '@material-ui/core/TextField';

export const IoseTextFieldStyled = styled(TextField).attrs((props) => ({
  variant: 'outlined',
  fullWidth: true,
  autoFocus: false,
}))`
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.error ? '20px' : '10px')};
  background: var(--base);
  border-radius: 5px;
  opacity: 80%;

  label {
    font-family: 'Montserrat', sans-serif;
  }

  label.Mui-focused {
    color: var(--primary);
  }

  && .Mui-focused fieldset {
    border-color: var(--primary);
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    color: red;
    margin: 0;
    font-size: 0.8rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 700;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
