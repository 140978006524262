import React, { useState, useEffect } from 'react';

import IoseApiLib from 'iose-api-lib';

//Hook to redirect beetween container
import { useHistory, useLocation } from 'react-router-dom';

//Imports from IOSE Components
import IoseHeaderBar from 'components/IoseHeaderBar';
import IoseSideBar from 'components/IoseSideBar';

//Imports from Style
import { IoseContentArea } from './style';

//Imports from common
import LocalStorage from 'common/local_storage_api';
import Exception from 'common/exceptions';

import { useSession } from 'common/contexts/session';
//Imports from routers
import { RoutesMainContainer, RoutesSidebarContainer } from 'routers';

/**
 * This component is used as a container
 * for other components and will be a page
 */
export default function GlobalPage() {
  const history = useHistory();
  let location = useLocation();

  const { verifySession, clearSession } = useSession();

  const session = LocalStorage.getSession();

  //State to open sidebar
  const [open, setOpen] = useState(false);

  //State to reload page content from within container or sidebar
  const [getcards, setGetcards] = useState(true);

  const [empytArray, setEmpytArray] = useState(false);

  const [sessionVerified, setSessionVerified] = useState(false);

  useEffect(() => {
    verifyIsLogged();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcards]);

  const verifyIsLogged = async () => {
    try {
      if (session) {
        await IoseApiLib.setToken(session.idToken);
        const sessionVerificated = await verifySession(session);
        sessionVerificated && setSessionVerified(true);
      } else {
        throw Exception.notLoggedIn;
      }
    } catch (error) {
      clearSession(error.message);
    }
  };

  const closeSidebar = () => setOpen(false);

  const openSidebar = () => setOpen(true);

  const redirectToSuperPage = () => history.replace('/global/super');

  const redirectToClientPage = () => history.replace('/global/client');

  const redirectToClientPageWithData = () => {
    let clientName = location.state.clientName;
    let uuid_client = location.state.uuid_client;
    let clientData = location.state.clientData;

    const data = clientData
      ? {
          clientData: clientData,
        }
      : {
          clientName: clientName,
          uuid_client: uuid_client,
        };

    let redirectClientPageData = {
      pathname: '/global/client',
      state: data,
    };

    return history.push(redirectClientPageData);
  };

  const redirectPreviousPage = () => {
    if (location.pathname.includes('client')) {
      redirectToSuperPage();
    } else {
      redirectToClientPageWithData();
    }
  };

  const redirectToHome = () => {
    if (session.group === 'super') {
      redirectToSuperPage();
    } else {
      redirectToClientPage();
    }
  };

  const renderSidebar = () => {
    const closeSidebarClickOutProps = {
      onBackdropClick: closeSidebar,
    };

    return (
      <IoseSideBar ModalProps={closeSidebarClickOutProps} open={open}>
        <RoutesSidebarContainer
          setGetcards={setGetcards}
          setEmpytArray={setEmpytArray}
          closeSide={closeSidebar}
          group={session.group}
        />
      </IoseSideBar>
    );
  };

  return (
    <>
      {sessionVerified && (
        <>
          <IoseHeaderBar
            backButtonClick={redirectPreviousPage}
            logoAction={redirectToHome}
          />
          {renderSidebar()}
          <IoseContentArea>
            <RoutesMainContainer
              openSide={openSidebar}
              group={session.group}
              username={session.name}
              getcards={getcards}
              setGetcards={setGetcards}
              empytArray={empytArray}
              setEmpytArray={setEmpytArray}
              backAction={redirectPreviousPage}
            />
          </IoseContentArea>
        </>
      )}
    </>
  );
}
