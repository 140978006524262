import styled, { keyframes } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { TypographyGeneral } from 'components/IoseSubHeaderBar/style';
import { TableHead } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';

export const GridContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// export const FilterContainer = styled.div`
//   /* background-color: white; */
//   padding: 10px;
//   margin: 10px 0;
//   border-radius: 10px;
//   display: flex;
//   gap: 10px;
// `;

export const FilterContainer = styled.div`
  /* background-color: white; */
  padding: 10px;
  margin: 5px 0 20px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const FilterBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  animation: ${slideIn} 0.5s ease;

  @media (max-width: 1405px) {
    gap: 5px;
  }
`;

export const OptionsButton = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--secondary);
  animation: ${slideIn} 0.5s ease;
  transition: 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:not(:hover) {
    transform: scale(1);
  }

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

// Filter Cards

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterCardDiv = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  gap: 5px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

export const FilterCardValue = styled.div`
  @media (max-width: 1405px) {
    font-size: 10px;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  transition: 0.5s ease;
  align-items: center;

  &:hover {
    scale: 1.1;
    background-color: #e3dfdf;
  }
`;

export const CloseCardIcon = styled(CloseIcon)`
  color: var(--alert);
  width: 18px;
`;

// export const ShowFiltersContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   align-items: center;
// `;
export const ShowFiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

export const FormDivContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 35px;
  margin: 10px 0 0 0;
  align-items: center;
`;

export const FormDivItem = styled.div`
  width: 150px;
`;

export const GridDataContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 25px;
`;

export const ChartGGContainer = styled.div`
  height: 500px;
  grid-column: span 2;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TableMercadoLivreContainer = styled.div`
  height: 500px;
  grid-column: span 6;

  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  margin-left: 10px;
  font-size: 13px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
`;

export const TableBlueChartContainer = styled.div`
  height: 550px;
  grid-column: span 7;

  padding: 8px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TableGreenChartContainer = styled.div`
  height: 550px;
  grid-column: span 6;

  padding: 8px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const Title = styled(TypographyGeneral)`
  color: var(--primary);
  font-size: 23px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

export const HeaderDiv = styled.div`
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  margin-top: 25px;
`;

export const LeftHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-column: span 2;
`;

export const RightHeaderDiv = styled.div`
  grid-column: span 6;
`;

//EnergyBillProcessingModal
export const WrapperModal = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  background-color: #beffb0;
  border: 2px solid #55ff33;
  border-radius: 10px;
  padding: 10px;
  overflow-x: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  /* Personalização do scroll */
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
`;

export const ProcessCard = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: #ecf0f5;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  background-color: var(--primary);
`;

export const TableHeaderCell = styled.th`
  padding: 11px;
  font-weight: bold;
  color: #fff;
`;

export const TableRow = styled.tr`
  cursor: pointer;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  color: #000;
  font-weight: bold;
  font-size: 11px;
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  color: var(--primary);
`;

export const InputBaseStyled = styled(InputBase)`
  width: 30%;
  opacity: 80%;
  height: 1px;
  background: white;
  border-radius: 5px;
  padding-left: 10px;
  transition: all 0.3s linear;
  color: black;
  margin-left: 50px;

  @media (max-width: 50px) {
    width: 40%;
    opacity: 100%;
  }
`;
