import React from 'react';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import {
  IoseDownloadButtonAcl,
  IoseDownloadButtonAclDanfe,
  IoseMapRedirectButton,
} from 'components/IoseButtonIcon';

const handleDownloadBoleto = (faturaAcl) => {
  const fatura = faturaAcl[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (fatura) {
    const concessionaire = fatura?.comercializadora.toLowerCase();
    const referenceMonth = fatura?.reference_month.replace('/', '');
    const pdfNumber = fatura?.pdf_boleto;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}M/boleto/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

const handleDownloadDanfe = (faturaAcl) => {
  const fatura = faturaAcl[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (fatura) {
    const concessionaire = fatura?.comercializadora.toLowerCase();
    const referenceMonth = fatura?.reference_month.replace('/', '');
    const pdfNumber = fatura?.pdf_danfe;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}M/danfe/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

export default function BillsModalAclSubHeader({ faturasAcl }) {
  const faturaAcl = faturasAcl[0];

  return (
    <>
      <IoseSubHeaderBar
        subtitle={`Detalhamento da fatura de energia ACL da UC ${faturaAcl?.identification_number}`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <div>
            {faturasAcl[0].pdf_boleto && faturasAcl[0].pdf_boleto !== '0' ? (
              <IoseDownloadButtonAcl
                tooltip={'BAIXAR BOLETO ACL'}
                bottom="5px"
                onClick={() => handleDownloadBoleto(faturasAcl)}
              />
            ) : null}
          </div>

          <div>
            {faturasAcl[0].pdf_danfe && faturasAcl[0].pdf_danfe !== '0' ? (
              <IoseDownloadButtonAclDanfe
                tooltip={'BAIXAR DANFE ACL'}
                bottom="5px"
                onClick={() => handleDownloadDanfe(faturasAcl)}
              />
            ) : null}
          </div>

          {/* <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            // onClick={() => redirectToMapPage()}
            onClick={() => {}}
          /> */}
        </div>
      </IoseSubHeaderBar>
    </>
  );
}
