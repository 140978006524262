import React, { useState, useContext } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

//Imports from IOSE Components
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';

import { RoutesCircuit } from 'routers';

//Imports from common
import IoseApiLib from 'iose-api-lib';
import { useSession } from 'common/contexts/session';
import { AccountContext } from 'common/contexts/Accounts';
import LocalStorage from 'common/local_storage_api';
import { ioseAPI } from 'common/ioseAPI';
import Messages from 'common/Messages';

function SidebarCircuitContainer({
  setGetcards,
  setNameSwitchboard,
  closeSide,
  getAllDataSwitchboardContainer,
}) {
  const history = useHistory();
  const { verifySession } = useSession();
  const session = LocalStorage.getSession();
  const { path } = useRouteMatch();
  let location = useLocation();

  const clientName = location.state.clientName;
  const uuid_client = location.state.uuid_client;
  const unityName = location.state.unityName;
  const uuid_unity = location.state.uuid_unity;
  const circuitData = location.state.circuitData;
  const allGroups = location.state.allGroups;
  // const uuid_group = location.state.uuid_group;

  const unityData = {
    uuid_unity: uuid_unity,
    name: unityName,
    tariff_period: location.state.tariff_period,
    tariff_data: location.state.tariff_data,
    contracted_demand: location.state.contracted_demand,
  };

  const clientData = {
    name: location.state.clientName,
    uuid_client: location.state.uuid_client,
  };

  const [name, setName] = useState(circuitData.name);
  const [description, setDescription] = useState(circuitData.description);
  const [informationTC, setInformationTC] = useState({
    'primary_tc': circuitData.primary_tc,
    'secondary_tc': circuitData.secondary_tc,
  });

  //This function return session data
  const { getSession } = useContext(AccountContext);

  //State to change notification type and your data
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //State control when need reloading list switchboard when press backbutton
  const [reloading, setreloading] = useState(false);

  //State to get all schedulings of Unit
  const [allSchedulings, setAllSchedulings] = useState('');

  const getAllLogs = async (uuid_circuit, nextPage) => {
    // setLoading(true);
    let response = {};

    try {
      const sessionVerificated = await verifySession(session);
      if (sessionVerificated) {
        if (nextPage === undefined) {
          response = await IoseApiLib.Logs.getAllLogs(uuid_circuit, 0, 20);
        } else {
          response = await IoseApiLib.Logs.getAllLogs(
            uuid_circuit,
            nextPage,
            20
          );
        }
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorGetLogs;

      setNotification(message);
      setNotificationType(2);
      return false;
    }

    // setLoading(false);
    return response;
  };

  const getDataUser = async (group, id) => {
    let employeeData = {};

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        if (group === 'admin') {
          employeeData = await IoseApiLib.Admin.getAdmin(id);
        } else {
          employeeData = await IoseApiLib.Employee.getEmployee(id);
        }
      }
    } catch (err) {
      return false;
    }

    return employeeData.data;
  };

  //Functions to EDIT CIRCUIT
  function errorHandlingEditCircuit(error) {
    const message = error ? error : Messages.switchboardNoEdited;
    setLoading(false);
    setNotification(message);
    setNotificationType(2);
  }

  function editCircuit(
    name,
    description,
    primary_tc,
    secondary_tc,
    identification_number
  ) {
    setNotificationType(0);
    setLoading(true);
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .updateCircuit(
            headers,
            circuitData.uuid_circuit,
            name,
            description,
            primary_tc,
            secondary_tc,
            identification_number
          )
          .then((response) => {
            setLoading(false);
            setNotification(response.data.message);
            setNotificationType(1);

            setName(name);
            setDescription(description);
            setInformationTC({
              'primary_tc': primary_tc,
              'secondary_tc': secondary_tc,
            });

            clickBackShowCircuit();
            setreloading(true);

            location.pathname.includes('dashboard') && setGetcards(true);
          })
          .catch((error) => {
            errorHandlingEditCircuit(error.response.data.message);
          });
      })
      .catch(() => {
        errorHandlingEditCircuit();
      });
  }

  //Functions to REDIRECT
  const clickBackShowSwitchboards = () => {
    setNameSwitchboard('Quadros');
    history.push({
      pathname: location.pathname.includes('dashboard')
        ? `/global/dashboard/switchboard`
        : `/global/client/switchboard`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: uuid_unity,
        switchboardData: location.state.switchboardData,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
    reloading && getAllDataSwitchboardContainer();
  };

  const clickBackShowCircuit = () => {
    history.push({
      pathname: location.pathname.includes('dashboard')
        ? `/global/dashboard/switchboard/circuit`
        : `/global/client/switchboard/circuit`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: uuid_unity,
        uuid_client: location.state.uuid_client,
        circuitData: circuitData,
        backbutton: true,
        switchboardData: location.state.switchboardData,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
        // uuid_group: uuid_group,
      },
    });
  };

  const redirectEditCircuit = () => {
    history.push({
      pathname: `${path}/edit`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: uuid_unity,
        uuid_client: location.state.uuid_client,
        circuitData: circuitData,
        switchboardData: location.state.switchboardData,
        backbutton: true,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
  };

  const redirectViewSchedule = () => {
    history.push({
      pathname: '/global/schedule',
      state: {
        uuid_client: uuid_client,
        unityData: unityData,
        clientName: clientName,
      },
    });

    setGetcards(true);
  };

  const redirectEvents = (circuitData) => {
    history.push({
      pathname: '/global/events',
      state: {
        clientData: clientData,
        circuitData: circuitData,
        unityData: unityData,
      },
    });
  };

  async function getSchedulingGroupByCircuit() {
    setNotificationType(0);
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getSchedulingGroupByCircuit(
        sessionData.headers,
        circuitData.uuid_circuit,
        0,
        2000
      );

      let allSchedulings = response.data.data && response.data.data;
      setAllSchedulings(allSchedulings);
      setLoading(false);
    } catch (error) {
      const messageError = error.response
        ? error.response.data.message
        : 'Erro ao buscar agendamentos relacionados ao circuito!';
      setLoading(false);
      setNotification(messageError);
      setNotificationType(2);
    }
  }

  async function getSchedulingModulesByGroup(uuid_scheduling_group) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getSchedulingModulesByGroup(
        sessionData.headers,
        uuid_scheduling_group,
        0,
        2000
      );

      return { result: true, message: response.data && response.data.data };
    } catch (error) {
      setLoading(false);
      const message = error.response
        ? error.response.data.message
        : 'Erro ao buscar circuitos relacionados ao agendamento';

      return {
        result: false,
        message: message,
      };
    }
  }

  function resendRequistion(uuid_circuit) {
    try {
      // const sessionData = await getSession();

      // const response = await ioseAPI.getSchedulingModulesByGroup(
      //   sessionData.headers,
      //   uuid_scheduling_group,
      //   0,
      //   2000
      //   );

      // return { result: true, message: response.data && response.data.data };
      return { result: true, message: 'resolved' };
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : 'Erro ao reenviar requisição!';

      return {
        result: false,
        message: message,
      };
    }
  }

  return (
    <>
      {notificationType === 1 && <IoseAlertSuccess text={notification} />}
      {notificationType === 2 && <IoseAlertErro text={notification} />}
      {loading && <LoadingIndicatorSidebar loading={loading} />}
      <RoutesCircuit
        clickBack={clickBackShowSwitchboards}
        clickBackShowCircuit={clickBackShowCircuit}
        name={name}
        description={description}
        informationTC={informationTC}
        uuid_circuit={circuitData.uuid_circuit}
        allGroups={allGroups}
        dataTable={allSchedulings}
        group={session.group}
        editCircuit={editCircuit}
        getSchedulingModulesByGroup={getSchedulingModulesByGroup}
        getAllLogs={getAllLogs}
        getDataUser={getDataUser}
        getSchedulingGroupByCircuit={getSchedulingGroupByCircuit}
        resendRequistion={resendRequistion}
        redirectViewSchedule={redirectViewSchedule}
        redirectEditCircuit={redirectEditCircuit}
        redirectEvents={redirectEvents}
        setLoading={setLoading}
        closeSide={closeSide}
      />
    </>
  );
}

export default SidebarCircuitContainer;
