import { ioseAPI, Messages } from "common";

export async function getExtractsByUuid(identification_number, sessionData) {
  try {
    const response = await ioseAPI.getExtractsByIdentificationNumber(
      sessionData.headers,
      identification_number
    );

    const extracts = response.data

    if (extracts.length === 0) {
      throw new Error(Messages.noFindExctracts);
    }

    return extracts
  } catch (error) {
    console.error(error)
  }
};
