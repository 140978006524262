import React from 'react';
import PropType from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

//Imports from Material UI
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

//Imports from Style
import { DatePickerStyled, Container } from './style';

//This is a override css for DatePick this is single mod to change it
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: '#015A9E',
        '&:hover': {
          backgroundColor: 'black',
        },
      },
      dayDisabled: {
        color: '#015A9E',
      },
      current: {
        color: '#015A9E',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#015A9E',
      },
    },
  },
});

/** This component create a grid conteiner with two selector dates  */
export default function IoseSelectDayMouth({
  title,
  theme,
  margin,
  padding,
  initialDate,
  getDate,
}) {
  return (
    <Container margin={margin} padding={padding}>
      <p>{title}</p>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <DatePickerStyled
            disableToolbar
            autoOk
            variant="inline"
            // minDate={minDate}
            // maxDate={maxDate}
            value={initialDate}
            onChange={getDate}
            theme={theme}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Container>
  );
}

IoseSelectDayMouth.propTypes = {
  /** This props get a title of selectors dates */
  title: PropType.string,
  /** This props get a theme (black ou white) of selector dates */
  theme: PropType.string,
  /** This props get a padding in conteiner of selector dates */
  padding: PropType.string,
  /** This props get a margin in conteiner of selector dates */
  margin: PropType.string,
  /** This props is function to set date in container */
  getDate: PropType.func,
  /** This props is function to get  initialDate in container */
  initialDate: PropType.oneOfType([PropType.string, PropType.instanceOf(Date)]),
};
