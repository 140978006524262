import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import IoseButtonSideBar from 'components/IoseButton';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import IoseSelection from 'components/IoseSelection';
import PropType from 'prop-types';
import validationsForm from '../../common/ValidationsForms';
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
  GridItem,
  GridContainer,
  DividerStyled,
  DivInputs,
  CircularProgressStyled,
  DivCircularProgress,
  DivInputs2,
  IoseTextFieldSidebarStyled2,
} from './style';

export default function IoseFormUnityAddCard({
  addUnity,
  getAllAgents,
  getFilterAgents,
}) {
  //Stato to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(new Date());

  const [nameAgent, setNameAgent] = useState('');
  const [nameAgents, setNameAgents] = useState([]);

  const [subgroup, setSubgroup] = useState('');
  //eslint-disable-next-line
  const [subgroups, setSubgroups] = useState([]);

  const [modality, setModality] = useState('');
  //eslint-disable-next-line
  const [modalitys, setModalitys] = useState([]);

  const [accessor, setAccessor] = useState('');
  //eslint-disable-next-line
  const [accessors, setAccessors] = useState([]);

  const [classe, setClasse] = useState('');
  //eslint-disable-next-line
  const [classes, setClasses] = useState([]);

  const [subclass, setSubclass] = useState('');
  //eslint-disable-next-line
  const [subclasses, setSubclasses] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    handleGetAllAgents();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Schema validation for UnityAdd
  const UnityAddSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.name,
    demandContracted: validationsForm.number,
    identificationNumber: validationsForm.identificationNumber,
  });

  //Labels forms to render inputs fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
  ];

  const classList = [
    'TIPO 01',
    'TIPO 02',
    'RESIDENCIAL',
    'RURAL',
    'NA',
    'ILUMINAÇÃO PÚBLICA',
  ];

  const modalityList = [
    'AZUL',
    'AZUL APE',
    'GERAÇÃO',
    'DISTRIBUIÇÃO',
    'VERDE',
    'VERDE APE',
    'GERAÇÃO',
    'BRANCA',
    'CONVENCIONAL',
    'PRÉ-PAGAMENTO',
  ];

  const subClassList = [
    'NA',
    'RESIDENCIAL',
    'BAIXA RENDA',
    'COOPERATIVA DE ELETRIFICAÇÃO RURAL',
    'NÃO SE APLICA',
    'SERVIÇO PÚBLICO DE IRRIGAÇÃO RURAL',
    'ILUMINAÇÃO PÚBLICA – B4A',
    'ILUMINAÇÃO PÚBLICA – B4B',
  ];

  const subGroupList = ['A1', 'A3', 'A4', 'B', 'B1', 'B2', 'B3', 'B4'];

  const acessorList = ['CCCP', 'NÃO SE APLICA', 'CEPISA', 'EPB', ''];


  //Labels forms to render selects related tariff
  const labelsSelectsTariff = [
    {
      label: 'Concessionária',
      list: nameAgents,
      variable: nameAgent,
      func: handleSetAgentName,
    },
    {
      label: 'Subgrupos',
      list: subGroupList,
      variable: subgroup,
      func: handleSetSubgroup,
    },
    {
      label: 'Modalidade',
      list: modalityList,
      variable: modality,
      func: handleSetModality,
    },
    {
      label: 'Acessante',
      list: acessorList,
      variable: accessor,
      func: handleSetAccessor,
    },
    {
      label: 'Classe',
      list: classList,
      variable: classe,
      func: handleSetClass,
    },
    {
      label: 'SubClasse',
      list: subClassList,
      variable: subclass,
      func: handleSetSubClass,
    },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);

      const initialDateDay = initialDate.getDate();

      const tariff_data = {
        acessor: accessor,
        subclass: subclass,
        name_agent: nameAgent,
        class: classe,
        modality: modality,
        subgroup: subgroup,
      };

      const demandContracted = parseInt(values.demandContracted);
      const identificationNumber = parseInt(values.identificationNumber);

      addUnity(
        values.name,
        values.description,
        initialDateDay,
        demandContracted,
        tariff_data,
        identificationNumber,
        values.binLink,
        values.id3d
      );
    }, 500);
  };

  async function handleGetAllAgents() {
    const allAgentsResponse = await getAllAgents();

    if (allAgentsResponse && allAgentsResponse.data) {
      const allAgents = allAgentsResponse.data;

      const nameAgentArray = allAgents.map((value) => value?.name);

      if (allAgents) {
        // setAllAgents(allAgents.response);
        setNameAgents(nameAgentArray);
      }
    } else {
      // Tratamento para caso não haja dados
      console.error('Erro: Não foi possível obter os agentes.');
    }
  }

  async function handleSetAgentName(agenteSelected) {
    setloading(true);
    setNameAgent(agenteSelected);

    let dataFiltered = await getFilterAgents(agenteSelected);


    if (dataFiltered) {
      setSubgroups(dataFiltered.response.subgroup);
      setSubgroup('');

      setModalitys([]);
      setModality('');

      setAccessors([]);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetSubgroup(subgroupSelected) {
    setloading(true);
    setSubgroup(subgroupSelected);

    let dataFiltered = await getFilterAgents(nameAgent, subgroupSelected);

    if (dataFiltered) {
      setModalitys(dataFiltered.response.modality);
      setModality('');

      setAccessors([]);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetModality(modalitySelected) {
    setloading(true);
    setModality(modalitySelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modalitySelected
    );

    if (dataFiltered) {
      setAccessors(dataFiltered.response.accessor);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetAccessor(accessorSelected) {
    setloading(true);
    setAccessor(accessorSelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessorSelected
    );

    if (dataFiltered) {
      setClasses(dataFiltered.response.class);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetClass(classSelected) {
    setloading(true);
    setClasse(classSelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessor,
      classSelected
    );

    if (dataFiltered) {
      setSubclasses(dataFiltered.response.subclass);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetSubClass(subClassSelected) {
    setloading(true);
    setSubclass(subClassSelected);
    setloading(false);
  }

  function handleDisabledField(field) {
    let response = true;

    switch (field) {
      case 'Concessionária':
        response = false;
        break;

      case 'Subgrupos':
        if (nameAgent !== '') {
          response = false;
        }
        break;

      case 'Modalidade':
        if (subgroup !== '') {
          response = false;
        }
        break;

      case 'Acessante':
        if (modality !== '') {
          response = false;
        }
        break;

      case 'Classe':
        if (accessor !== '') {
          response = false;
        }
        break;

      case 'SubClasse':
        if (classe !== '') {
          response = false;
        }
        break;

      default:
        response = true;
    }

    return response;
  }

  return (
    <>
      {/**Form*/}

      <Container>
        <Formik
          initialValues={{
            name: '',
            description: '',
            demandContracted: '',
            identificationNumber: 0,
            binLink: '',
            id3d: '',
          }}
          validationSchema={UnityAddSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>Criar</div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              {/**Inputs*/}
              <DivInputs>
                {labels.map((value) => {
                  return (
                    <Field
                      component={IoseTextFieldSidebarStyled}
                      key={value.variable}
                      id={value.variable}
                      name={value.variable}
                      type={'text'}
                      label={value.label}
                      defaultvalue={initialValues[value.variable]}
                      onchange={handleChange}
                      onblur={handleBlur}
                      helpertext={
                        errors[value.variable] && touched[value.variable]
                          ? errors[value.variable]
                          : ''
                      }
                    />
                  );
                })}
              </DivInputs>

              <DivInputs2>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'identificationNumber'}
                  name={'identificationNumber'}
                  type={'text'}
                  label={'Unidade Consumidora'}
                  defaultvalue={initialValues['identificationNumber']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['identificationNumber'] &&
                    touched['identificationNumber']
                      ? errors['identificationNumber']
                      : ''
                  }
                />
              </DivInputs2>

              {/**Tariff data comboboxes */}
              <p>Informações relacionadas ao período tarifário</p>
              <DividerStyled />

              <DivInputs2>
                <Field
                  component={IoseTextFieldSidebarStyled}
                  id={'demandContracted'}
                  name={'demandContracted'}
                  type={'text'}
                  label={'Demanda Contratante'}
                  defaultvalue={initialValues['demandContracted']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['demandContracted'] && touched['demandContracted']
                      ? errors['demandContracted']
                      : ''
                  }
                />
              </DivInputs2>

              <IoseSelectDayMouth
                title="Dia inicial do período tarifário"
                getDate={setInitialDate}
                initialDate={initialDate}
                margin={'0px'}
              />

              <GridContainer container>
                {loading ? (
                  <DivCircularProgress>
                    <CircularProgressStyled />
                  </DivCircularProgress>
                ) : (
                  labelsSelectsTariff.map((value, index) => {
                    return (
                      <GridItem key={index}>
                        <Field
                          component={IoseSelection}
                          id={value.variable}
                          name={value.variable}
                          type={'text'}
                          func={value.func}
                          onblur={handleBlur}
                          title={value.label}
                          object={value.list}
                          defaultvalue={value.variable}
                          sidebar="true"
                          disabled={handleDisabledField(value.label)}
                        />
                      </GridItem>
                    );
                  })
                )}
              </GridContainer>

              <DivInputs>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'binLink'}
                  name={'binLink'}
                  type={'text'}
                  label={'Link 3D'}
                  defaultvalue={initialValues['binLink']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['binLink'] && touched['binLink']
                      ? errors['binLink']
                      : ''
                  }
                />
              </DivInputs>

              <DivInputs>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'id3d'}
                  name={'id3d'}
                  type={'text'}
                  label={'ID 3D'}
                  defaultvalue={initialValues['id3d']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['id3d'] && touched['id3d'] ? errors['id3d'] : ''
                  }
                />
              </DivInputs>
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseFormUnityAddCard.propTypes = {
  /** This props get a function to add Unity of SidebarUnityOperationsContainer*/
  addUnity: PropType.func,
};
