// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCalculateTotalBillValues() {
  const [noDataError, setNoDataError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCalculateTotalBillValues = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getCalculateTotalBillValues(headers);

    const totalBillValues = response.data.data || [];

    if (totalBillValues.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return totalBillValues;
  };

  const {
    data: totalBillValues,
    error: totalBillValuesError,
    refetch: refreshTotalBillValues,
    isLoading,
  } = useQuery(['totalBillValues'], getCalculateTotalBillValues);

  return {
    totalBillValues,
    totalBillValuesError,
    noDataError,
    refreshTotalBillValues,
    isLoading,
  };
}

export default useCalculateTotalBillValues;
