//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

const validationsForm = {
  required: Yup.string().required('Obrigatório'),
  number_required: Yup.number()
    .required('Obrigatório')
    .typeError('Apenas números!'),

  name: Yup.string()
    .min(3, 'Muito curto!')
    .max(50, 'Muito Longo!')
    .required('Obrigatório'),

  description: Yup.string()
    .min(3, 'Muito curto!')
    .max(100, 'Muito Longo!')
    .required('Obrigatório'),

  identificationNumber: Yup.number().typeError('Apenas números!'),

  adress: Yup.string()
    .min(3, 'Muito curto!')
    .max(70, 'Muito Longo!')
    .required('Obrigatório'),

  email: Yup.string()
    .email(
      'Email Inválido',
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
    )
    .min(3, 'Muito curto!')
    .max(50, 'Muito Longo!')
    .required('Obrigatório'),

  email_not_required: Yup.string()
    .email(
      'Email Inválido',
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
    )
    .min(3, 'Muito curto!')
    .max(50, 'Muito Longo!'),

  number: Yup.number()
    .positive('Deve ser positivo e diferente de zero!')
    .typeError('Apenas números!')
    .required('Obrigatório'),

  float: Yup.string()
    .transform((value) => value.replace(',', '.'))
    .matches(/^\d+(\.\d{1,2})?$/, 'Apenas números')
    .required('Campo obrigatório'),

  string_required: Yup.string().required('Campo Obrigatório'),
  value: Yup.string()
    .matches(/^[\d,]+$/, 'Apenas números e vírgula são permitidos!')
    .required('Obrigatório'),

  id_3d: Yup.string(),
};

export default validationsForm;
