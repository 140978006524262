import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function ConcessionaireLineChart() {
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    // legend: {
    //   data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
    // },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['01/2023', '02/2023', '03/2023', '04/2023', '05/2023'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'COPEL',
        type: 'line',
        stack: 'Total',
        data: [120, 132, 101, 134, 90, 230, 210],
      },
      {
        name: 'ENERGISA',
        type: 'line',
        stack: 'Total',
        data: [220, 182, 191, 234, 290, 330, 310],
      },
      {
        name: 'CERAL',
        type: 'line',
        stack: 'Total',
        data: [150, 232, 201, 154, 190, 330, 410],
      },
      {
        name: 'COPEL 2',
        type: 'line',
        stack: 'Total',
        data: [320, 332, 301, 334, 390, 330, 320],
      },
      {
        name: 'CERAL',
        type: 'line',
        stack: 'Total',
        data: [220, 50, 65, 145, 305, 569, 400],
      },
      {
        name: 'COPEL 3',
        type: 'line',
        stack: 'Total',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
      {
        name: 'CPFL',
        type: 'line',
        stack: 'Total',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
      {
        name: 'COPE 4',
        type: 'line',
        stack: 'Total',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 280 }} />;
}
