import React from "react";
import * as S from '../style';

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalAclTable({ faturasAcl }) {
  if (!faturasAcl || faturasAcl.length === 0) return null;

  const fatura = faturasAcl[0];

  return (
    <S.TableContainer>
      <S.TableTitleContainer>
        {/* <S.TableTitle>TRIBUTOS</S.TableTitle> */}
      </S.TableTitleContainer>
      <S.Table>
        <S.TableHeader>
          <tr>
            <S.TableHeaderCell>QUANTIDADE (MWh)</S.TableHeaderCell>
            <S.TableHeaderCell>VALOR UNITÁRIO (R$)</S.TableHeaderCell>
            <S.TableHeaderCell>VALOR TOTAL (R$)</S.TableHeaderCell>
          </tr>
        </S.TableHeader>
        <tbody>

          <S.TableExtractRow>
            <S.TableCell>{convertNumberToDecimalStyle(fatura?.quantidade)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(fatura?.valor_unitario_acl)}</S.TableCell>
            <S.TableCell>{convertNumberToDecimalStyle(fatura?.valor_total)}</S.TableCell>
          </S.TableExtractRow>
        </tbody>
      </S.Table>
    </S.TableContainer>
  );
}
