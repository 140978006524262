import React, { useEffect, useState } from 'react';

import { useClient, LocalStorage } from 'common';

import {
  DivSearch,
  InputBaseStyled,
  SearchIconStyled,
  WrappedContainer,
} from './style';
import { useCreditConnections, useGroupPermissions } from 'hooks';

import {
  IoseAlertLoadingCards,
  IoseSubHeaderBar,
  LoadingIndicator,
} from 'components';

import { CreditTable } from 'components/IoseTables';

import Permissions from 'common/permissions';

export default function CreditContainer() {
  const { client } = useClient();
  const { creditConnectionData } = useCreditConnections();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);

  const totalBeneficiary = creditConnectionData
    ? creditConnectionData.length
    : '';

  const filteredGroups = creditConnectionData?.filter((group) =>
    group.uc_beneficiaria.toLowerCase().includes(search?.toLowerCase())
  );

  useEffect(() => {
    const readPath = ['geracao', 'unidades_beneficiarias', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const renderFilters = () => {
    return (
      <DivSearch>
        <InputBaseStyled
          type="text"
          placeholder="Pesquisar por UC Beneficiária."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <SearchIconStyled />
      </DivSearch>
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        // title={`Sanepar`}
        subtitle={`Unidades Beneficiárias`}
        description={'Total de Unidades Beneficiárias: ' + totalBeneficiary}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          {/* <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => {handleCredit}}
          /> */}
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <CreditTable
          data={filteredGroups ? filteredGroups : creditConnectionData}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>
              {renderFilters()}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReadPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </WrappedContainer>
  );
}
