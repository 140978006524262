import React, { useEffect, useState } from 'react';
import * as S from './style';
import { useGroupPermissions } from 'hooks';
import { LocalStorage } from 'common';
import Permissions from 'common/permissions';
import {
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';
import SummaryDashYearCards from './components/summary-dash-year-cards';
import SummaryDashYearFilter from './components/summary-dash-year-filter';
import SummaryDashYearTotalsCards from './components/summary-dash-year-totals-cards';
import { SummaryDashYearConsumptionChart } from './components/summary-dash-year-consumption-chart';
import { SummaryDashYearDemandChart } from './components/summary-dash-year-demand-chart';
import { SummaryDashYearDesperdiciosChart } from './components/summary-dash-year-desperdicios-chart';

export default function SummaryDashByYearContainer() {
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  const [loading, setLoading] = useState(true);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, []);

  useEffect(() => {
    const readPath = ['dashboards', 'faturas', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  return (
    <S.GridContainer>
      <S.HeaderDiv>
        <S.LeftHeaderDiv>
          <S.Title>Dashboard de Faturas Anual</S.Title>
          <SummaryDashYearFilter
            setLoading={setLoading}
            setData={setData}
          />
        </S.LeftHeaderDiv>
      </S.HeaderDiv>
      {hasReadPermission ? (
        <>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>
              <SummaryDashYearCards data={data} />
              <SummaryDashYearTotalsCards data={data}/>
              <SummaryDashYearConsumptionChart data={data}/>
              <SummaryDashYearDemandChart data={data}/>
              <SummaryDashYearDesperdiciosChart data={data}/>
            </>
          ) }

        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.GridContainer>
  );
}
