import React, { useCallback, useEffect, useState } from 'react';
import { TablePagination, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import * as S from './style';

const formatNumber = (number) => number?.toLocaleString();

const TableHeaderCell = ({
  label,
  sortBy,
  sortDirection,
  handleSort,
  column,
}) => (
  <S.TableHeaderCell onClick={() => handleSort(column)}>
    {label} {sortBy === column && (sortDirection === 'asc' ? '🔼' : '🔽')}
  </S.TableHeaderCell>
);

const TableRowCell = ({ children, onCellClick, isUcColumn }) => (
  <S.TableCell onClick={isUcColumn ? onCellClick : undefined}>
    {children}
  </S.TableCell>
);

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  handleSort,
  sortBy,
  sortDirection,
  // onFilteredDataChange,
}) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <S.TableContainer>
      <S.Table>
        <S.TableHeader>
          <tr>
            {[
              { label: 'UC', column: 'identification_number' },
              { label: 'MÊS DE REFERÊNCIA', column: 'reference_month' },
              { label: 'CIDADE', column: 'city' },
              { label: 'GG', column: 'general_management' },
              { label: 'GR', column: 'regional_management' },
              { label: 'CONSUMO', column: 'item_consumption_1' },
              { label: 'VALOR', column: 'item_total_value_1' },
              { label: 'REFERENTE', column: 'referente' },
            ].map(({ label, column }) => (
              <TableHeaderCell
                key={column}
                label={label}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSort={handleSort}
                column={column}
              />
            ))}
          </tr>
        </S.TableHeader>
        <tbody>
          {pageData.map((value, index) => (
            <S.TableRow key={index}>
              <TableRowCell
                onCellClick={() =>
                  onRowClick(
                    value.identification_number,
                    value.numero_telemetria
                  )
                }
                isUcColumn={true}
              >
                {value.identification_number?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>
                {value.reference_month?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>{value.city?.toUpperCase()}</TableRowCell>
              <TableRowCell>
                {value.general_management?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>
                {value.regional_management?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>
                {formatNumber(value.item_consumption_1)}
              </TableRowCell>
              <TableRowCell>
                {formatNumber(value.item_total_value_1)}
              </TableRowCell>
              <TableRowCell>{value.referente?.toUpperCase()}</TableRowCell>
            </S.TableRow>
          ))}
        </tbody>
      </S.Table>
    </S.TableContainer>
  );
};

export default function DesperdicioTable({
  data = [],
  loading,
  onFilteredDataChange,
}) {
  const useStyles = makeStyles(() => ({
    tableHeaderCell: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      color: '#fff',
    },
    tableCell: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 'bold',
    },
    smallerColumn: {
      minWidth: '60px',
      maxWidth: '60px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    mediumColumn: {
      minWidth: '100px',
      maxWidth: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }));
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [cityFilter, setCityFilter] = useState('');
  const [ggFilter, setGgFilter] = useState('');
  const [grFilter, setGrFilter] = useState('');
  const [referenteFilter, setReferenteFilter] = useState('');
  const [ucFilter, setUcFilter] = useState('');
  const classes = useStyles();

  const handleFilteredDataChange = useCallback((data) => {
    onFilteredDataChange(data);
  }, []);

  useEffect(() => {
    if (data?.length >= 0) {
      const sortedData = sortData(data, sortBy, sortDirection);
      setUpdatedData(sortedData);
    }
  }, [data, sortBy, sortDirection]);

  useEffect(() => {
    let filteredData = data;

    if (cityFilter) {
      filteredData = filteredData.filter((item) =>
        item.city?.toLowerCase().includes(cityFilter.toLowerCase())
      );
    }
    if (ggFilter) {
      filteredData = filteredData.filter((item) =>
        item.general_management?.toLowerCase().includes(ggFilter.toLowerCase())
      );
    }
    if (grFilter) {
      filteredData = filteredData.filter((item) =>
        item.regional_management?.toLowerCase().includes(grFilter.toLowerCase())
      );
    }
    if (referenteFilter) {
      filteredData = filteredData.filter((item) =>
        item.referente?.toLowerCase().includes(referenteFilter.toLowerCase())
      );
    }
    if (ucFilter) {
      filteredData = filteredData.filter((item) =>
        item.identification_number
          ?.toLowerCase()
          .includes(ucFilter.toLowerCase())
      );
    }

    setUpdatedData(sortData(filteredData, sortBy, sortDirection));
    handleFilteredDataChange(filteredData);
  }, [
    cityFilter,
    ggFilter,
    grFilter,
    referenteFilter,
    ucFilter,
    data,
    sortBy,
    sortDirection,
    handleFilteredDataChange,
  ]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const handleRowClick = (identificationNumber, uuidCircuit) => {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: { consumerGroup: identificationNumber, uuidCircuit: uuidCircuit },
    });
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    }

    return (
      <div style={{ paddingTop: '8px' }}>
        <S.InputBaseStyled
          className={classes.searchInput}
          placeholder="U.C"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setUcFilter(e.target.value)}
        />
        <S.InputBaseStyled
          className={classes.searchInput}
          placeholder="Cidade"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setCityFilter(e.target.value)}
        />
        <S.InputBaseStyled
          className={classes.searchInput}
          placeholder="GG"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setGgFilter(e.target.value)}
        />
        <S.InputBaseStyled
          className={classes.searchInput}
          placeholder="GR"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setGrFilter(e.target.value)}
        />
        <S.InputBaseStyled
          className={classes.searchInput}
          placeholder="Referente"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setReferenteFilter(e.target.value)}
        />

        {updatedData.length === 0 ? (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="250px"
            width="250px"
          />
        ) : (
          <>
            <CircuitsTable
              data={updatedData}
              onRowClick={handleRowClick}
              page={page}
              rowsPerPage={rowsPerPage}
              handleSort={handleSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onFilteredDataChange={handleFilteredDataChange} // Passando a função corretamente
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <IoseInfinityScroll
        dataLength={updatedData?.length ? updatedData?.length : 0}
        next={() => {}}
        hasMore={false}
        loading={false}
        endList={false}
        sidebar={false}
        marginTop="0"
        padding="0"
        colortextend={null}
        sizetextend={null}
        scrollableTarget="scrollableDiv"
        height={380}
      >
        {renderContent()}
      </IoseInfinityScroll>

      <TablePagination
        component="div"
        count={updatedData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Itens por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
        backIconButtonText="Página anterior"
        nextIconButtonText="Próxima página"
      />
    </>
  );
}

const sortData = (data, sortBy, sortDirection) => {
  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
