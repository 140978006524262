import React from 'react';

import * as S from './style';

import {
  TrendingUp as TrendingUpIcon,
  Equalizer as EqualizerIcon,
  ShowChart as ShowChartIcon,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const getIcon = (title) => {
  switch (title) {
    case 'Consumo fatura':
      return <TrendingUpIcon />;
    case 'Balanço':
      return <ShowChartIcon />;
    case 'Consumo estimado':
      return <EqualizerIcon />;
    case 'Consumo medido':
      return <EqualizerIcon />;
    default:
      return null;
  }
};

export default function IoseAdmDashListSummaryUnitys({ data }) {
  const totalConsumption = data?.reduce(
    (total, obj) => total + obj.consumption_fatura,
    0
  );
  const totalConsumptionEstimado = data?.reduce(
    (total, obj) => total + obj.consumption_estimado,
    0
  );

  const totalConsumoIose = data?.reduce(
    (total, obj) => total + obj.consumption,
    0
  )

  const SummaryUnitysData = [
    {
      title: 'Consumo fatura',
      description: 'Consumo lido na fatura',
      value: convertNumberToDecimalStyle(totalConsumption) + ' kWh',
    },
    {
      title: 'Consumo estimado',
      description: 'Consumo estimado com base nos objetos presentes na unidade',
      value: convertNumberToDecimalStyle(totalConsumptionEstimado) + ' kWh',
    },
    {
      title: 'Consumo medido',
      description: 'Consumo lido pelo aparelho de medição IOSE',
      value: convertNumberToDecimalStyle(totalConsumoIose) + ' kWh',
    },
    {
      title: 'Balanço',
      description: 'Diferença entre o consumo da fatura e o consumo estimado',
      value:
        convertNumberToDecimalStyle(
          totalConsumptionEstimado - totalConsumption
        ) + ' kWh',
    },
  ];

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  return (
    <S.SummaryListContainer>
      <S.TitleList>RESUMO</S.TitleList>
      <S.ListContainer>
        {SummaryUnitysData.map(({ title, description, value }) => (
          <Tooltip
            title={description}
            placement="bottom"
            arrow
          >
            <S.ListStyled key={title}>
              
              <S.SummaryIconWrapper>{getIcon(title)}</S.SummaryIconWrapper>
              <S.ListItemTextStyled primary={title} secondary={value} />
            </S.ListStyled>
          </Tooltip>
        ))}
        
      </S.ListContainer>
     
    </S.SummaryListContainer>
  );
}
