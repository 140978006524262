import React, { useState, useMemo } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination, Tooltip, Button } from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled, CircuitsIcon } from '../style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { IoseCircuitChartModal, IoseEnvironmentOpenObjects } from 'components';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  addressColumn: {
    minWidth: '150px',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconColumn: {
    minWidth: '40px',
    maxWidth: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return <TableCell className={`${classes.tableCell} ${className}`}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className, onClick, sortedBy, direction }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tableHeaderCell} ${className}`} onClick={onClick}>
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({
  value,
  onDeleteClick,
  onEditClick,
  handleObjectsItem,
}) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value.name?.toUpperCase()}</TableCellCentered>
      <TableCellCentered>{value.unity_name?.toUpperCase()}</TableCellCentered>
      <TableCellCentered>{value.description?.toUpperCase()}</TableCellCentered>
      {/* <TableCellCentered className={classes.addressColumn}>{value.id_3d}</TableCellCentered> */}
      <TableCellCentered className={classes.iconColumn}>
        <Tooltip title='VISUALIZAR GRAFICOS DE CONSUMO'>
          <Button style={{ height: '30px' }} onClick={() => onEditClick(value)}>
            <CircuitsIcon style={{ color: '#015A9E' }}/>
          </Button>
        </Tooltip>
      </TableCellCentered>
      <TableCellCentered className={classes.iconColumn}>
        <Tooltip title='VISUALIZAR OBJETOS'>
          <Button style={{ height: '30px' }} onClick={() => handleObjectsItem(value)}>
            <EmojiObjectsIcon style={{ color: '#015A9E' }}/>
          </Button>
        </Tooltip>
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  onEditClick,
  handleObjectsItem,
  handleSort,
  sortBy,
  sortDirection,
}) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            onClick={() => handleSort('name')}
            sortedBy={sortBy === 'name'}
            direction={sortDirection}
          >
            NOME
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('unity_name')}
            sortedBy={sortBy === 'unity_name'}
            direction={sortDirection}
          >
            UNIDADE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('description')}
            sortedBy={sortBy === 'description'}
            direction={sortDirection}
          >
            DESCRIÇÃO
          </TableHeaderCellCentered>
          {/* <TableHeaderCellCentered
            className={classes.addressColumn}>LINK 3D</TableHeaderCellCentered> */}
          <TableHeaderCellCentered></TableHeaderCellCentered>
          <TableHeaderCellCentered></TableHeaderCellCentered>
          {/* <TableHeaderCellCentered></TableHeaderCellCentered> */}
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            onEditClick={onEditClick}
            handleObjectsItem={handleObjectsItem}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function CircuitosMainTable({ loading, data = [] }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

	const [openChartModal, setOpenChartModal] = useState(false);
  const [openObjectsModal, setOpenObjectsModal] = useState(false);
	const [chartMode, setChartMode] = useState('Diário');

  const handleCloseObjectsModal = () => {
    setOpenObjectsModal(false);
  };

	const handleOpenChartModal = (item) => {
    setOpenChartModal(true);
		setSelectedItem(item);
  };

	const handleCloseChartModal = () => {
    setOpenChartModal(false);
    setChartMode('Diário');
  };

  const handleObjectsItem = (item) => {
    setOpenObjectsModal(true);
    setSelectedItem(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortBy, sortDirection);
  }, [data, sortBy, sortDirection]);

  function renderChartOrErrorMessage() {
    if (sortedData && sortedData.length > 0) {
      return (
        <CircuitsTable
          data={sortedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          onEditClick={handleOpenChartModal}
          handleObjectsItem={handleObjectsItem}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={data ? parseInt(data.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={650}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>

          <IoseCircuitChartModal
            open={openChartModal}
            onClose={handleCloseChartModal}
            selectedCard={selectedItem}
            chartMode={chartMode}
            setChartMode={setChartMode}
        	/>

          <IoseEnvironmentOpenObjects
            open={openObjectsModal}
            selectedObject={selectedItem}
            onClose={handleCloseObjectsModal}
            loading={loading}
          />

        </>
      )}
    </div>
  );
}

function sortData(data, sortBy, sortDirection) {
  if (!sortBy) return data;

  const sortedData = [...data].sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedData;
}
