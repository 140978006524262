import styled from 'styled-components';
import { Grid, TextField, Table, TableCell } from '@material-ui/core/';


export const StyledTable = styled(Table)({
  width: '10%',
});

export const StyledTh = styled(TableCell)({
  backgroundColor: 'var(--primary)',
  padding: '10px',
  textAlign: 'center',
  color: 'var(--secondary)',
  textTransform: 'uppercase',
});

export const StyledTd = styled(TableCell)({
  padding: '10px',
  border: '1px solid #ddd',
  textAlign: 'center',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#ddd',
    cursor: 'pointer',
  },
});


export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const WrapContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-around;
  width: 100%;
`;

export const ContainerPaper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const ContainerTarifasPaper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 38%;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
`;

export const ContainerTarifasPaperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
`;

export const ContainerTarifasPaperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
`;


export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledText = styled(TextField)`
  & .MuiInputBase-root {}
`;

export const TextFieldContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
`;

export const TitleSeparator = styled.div`
  border-bottom: 2px solid var(--primary);
`;

export const Title = styled.h2`
  margin-bottom: 5px;
`;

export const ValoresContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;

export const ValoresContainerPaper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid ${(props) => props.borderColor || '#FFF'};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const FormFooterContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const FormContainerPaper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 35%;
  background-color: #fff;
  align-items: end;
  border-radius: 5px;
  padding: 15px;
`;
