import React from 'react';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import IoseButtonSideBar from 'components/IoseButton';
import { Content } from 'components/IoseSideContainer';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
} from './style';

/**
 * This component is a form to edit a client in the system
 */
export default function IoseFormClientEditCard({ editClient }) {
  let location = useLocation();

  const name = location.state.clientname;
  const desctription = location.state.description_client;
  const uuid_client = location.state.uuid_client;

  //Schema validation for ClientAdd
  const ClientAddSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.name,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      editClient(uuid_client, values.name, values.description);
    }, 500);
  };

  return (
    <Content>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: name,
            description: desctription,
          }}
          validationSchema={ClientAddSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>Editar</div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              {/**Inputs*/}
              {labels.map((value) => {
                return (
                  <Field
                    component={IoseTextFieldSidebarStyled}
                    key={value.variable}
                    id={value.variable}
                    name={value.variable}
                    type={'text'}
                    label={value.label}
                    defaultvalue={initialValues[value.variable]}
                    onchange={handleChange}
                    onblur={handleBlur}
                    helpertext={
                      errors[value.variable] && touched[value.variable]
                        ? errors[value.variable]
                        : ''
                    }
                  />
                );
              })}
            </FormStyled>
          )}
        </Formik>
      </Container>
    </Content>
  );
}

IoseFormClientEditCard.propTypes = {
  /** This props get a function to edit Client of SidebarClientContainer*/
  editClient: PropType.func,
};
