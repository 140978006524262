import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import * as S from './style';

import axios from 'axios';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';

import {
  useClient,
  Messages,
  LocalStorage,
  AccountContext,
  ioseAPI,
} from 'common';

import IoseCarbonTableContainer from 'components/IoseCarbonTableContainer';
import { IoseAddCarbonTariffsModal } from 'components/IoseNewModals';

import { useCreateLogs, useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';

export default function TariffsCarbonContainer() {
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [addNotification, setAddNotification] = useState('');
  const [notificationType, setNotificationType] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [data, setData] = useState([]);
  const [filterYear, setFilterYear] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'fator_co2', 'leitura'];
    const addPath = ['cadastro', 'fator_co2', 'criar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);
    }
  }, [userSession, groupPermissions]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/getCarbon'
      );
      const parsedData = JSON.parse(response.data.body);
      setData(parsedData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  useEffect(() => {
    fetchData();
    getClientData();
    //eslint-disable-next-line
  }, []);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };
  function errorHandlingAddTariffs(error) {
    setLoading(false);
    setNotificationType(2);
  }
  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const addTariff = async (tarifaValue, yearTariff, month, year) => {
    try {
      const { headers } = await getSession();
      // Ajuste na ordem dos argumentos
      const response = await ioseAPI.createCarbonTariff(
        headers,
        tarifaValue,
        yearTariff,
        month,
        year
      );

      setAddNotification(response.data.message);
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR TARIFA CO2';
      const logContent = {
        tarifaValue: tarifaValue,
        yearTariff: yearTariff,
        month: month,
        year: year,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      errorHandlingAddTariffs(errorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  // Função para atualizar o estado do termo de pesquisa
  const handleSearchChange = (event) => {
    setFilterYear(event.target.value);
  };

  // Função para filtrar os dados
  const getFilteredData = () => {
    if (!filterYear) {
      return data; // se não houver termo de pesquisa, retorna todos os dados
    }
    return data.filter((item) => item.year.toString() === filterYear);
  };

  const useStyles = makeStyles((theme) => ({
    searchIcon: {
      color: '#fff', // Define a cor do ícone para amarelo
      marginRight: theme.spacing(1),
    },
    searchBar: {
      margin: theme.spacing(1),
      padding: '2px 4px', // Ajuste o preenchimento conforme necessário
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid #cccccc',
      borderRadius: '20px', // Isso irá arredondar os cantos da barra de pesquisa
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: '#f5f5f5', // Muda a cor de fundo ao passar o mouse
        borderColor: '#aaaaaa', // Muda a cor da borda ao passar o mouse
      },
      '& input': {
        marginLeft: theme.spacing(1),
        flex: 1,
        border: 'none', // Remove a borda do input
        '&:focus': {
          outline: 'none', // Remove o contorno ao focar
        },
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR TARIFA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Fator CO2"
        button={showButton ? button : <></>}
      >
        <div className={classes.searchBar}>
          <input
            className={classes.input}
            type="text"
            placeholder="Digite o ano para filtrar..."
            value={filterYear}
            onChange={handleSearchChange}
          />
          <button className={classes.button}>
            <SearchIcon className={classes.searchIcon} />
          </button>
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderTable = () => {
    const filteredData = getFilteredData(); // dados filtrados
    return (
      <S.ContainerTable>
        <IoseCarbonTableContainer
          tableTitle="TABELA DE TARIFAS"
          searchInTable={false}
          data={filteredData} // use filteredData aqui
        />
      </S.ContainerTable>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {hasReadPermission
            ? renderTable()
            : setError(
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              )}
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <IoseAddCarbonTariffsModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        modalHeaderTitle="TARIFA"
        modalTitle="CADASTRAR TARIFA"
        submit={addTariff}
        notificationType={notificationType}
        showNotification={showNotification}
        addNotification={addNotification}
        loading={operationLoading}
      />
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            {renderContent()}
            {renderModals()}
          </>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

TariffsCarbonContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
