import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

function formatMeasuresData(data) {
  const q1 = [];
  const q2 = [];
  const q3 = [];
  const q4 = [];
  const reativaQ1 = [];
  const reativaQ2 = [];
  const reativaQ3 = [];
  const reativaQ4 = [];

  data.forEach(obj => {
    const newObj = {
      arrival_date: obj.arrival_date,
    };

    Object.keys(obj).forEach(key => {
      if (key.toLowerCase().endsWith('_q1')) {
        newObj[key] = obj[key];
      } else if (key.toLowerCase().endsWith('_q2')) {
        newObj[key] = obj[key];
      } else if (key.toLowerCase().endsWith('_q3')) {
        newObj[key] = -Math.abs(obj[key]);
      } else if (key.toLowerCase().endsWith('_q4')) {
        newObj[key] = -Math.abs(obj[key]);
      }
    });

    q1.push({ value: [newObj.arrival_date, newObj.potencia_a_wh_q1  / 1000] });
    q2.push({ value: [newObj.arrival_date, newObj.potencia_a_wh_q2 / 1000] });
    q3.push({ value: [newObj.arrival_date, newObj.potencia_a_wh_q3 / 1000] });
    q4.push({ value: [newObj.arrival_date, newObj.potencia_a_wh_q / 1000] });
    reativaQ1.push({ value: [newObj.arrival_date, newObj.potencia_reativa_a_varh_q1 / 1000] });
    reativaQ2.push({ value: [newObj.arrival_date, newObj.potencia_reativa_a_varh_q2 / 1000] });
    reativaQ3.push({ value: [newObj.arrival_date, newObj.potencia_reativa_a_varh_q3 / 1000] });
    reativaQ4.push({ value: [newObj.arrival_date, newObj.potencia_reativa_a_varh_q4 / 1000] });
  });

  return { q1, q2, q3, q4, reativaQ1, reativaQ2, reativaQ3, reativaQ4 };
}

export default function GraficoQuatroQuadrantes({ data }) {
  const postoHorario = data?.map((item) => item.posto_horario);

  const [formattedData, setFormattedData] = useState({
    q1: [],
    q2: [],
    q3: [],
    q4: [],
    reativaQ1: [],
    reativaQ2: [],
    reativaQ3: [],
    reativaQ4: []
  });

  useEffect(() => {
    const formatted = formatMeasuresData(data);
    setFormattedData(formatted);
  }, [data]);

  const { q1, q2, q3, q4, reativaQ1, reativaQ2, reativaQ3, reativaQ4 } = formattedData;

  const option = {
      title: [
        { text: "Geração", left: 400, top: 0},
        { text: "Consumo", right: 400, top: 0 },
        { text: "Q2 : INDUTIVO",
          left: '22%',
          top: '25%',
          textStyle: {
            color: '#D8D6D6',
            fontSize: 15
          }
        },
        { text: "Q1 : INDUTIVO",
          right: '22%',
          top: '25%',
          textStyle: {
            color: '#D8D6D6',
            fontSize: 15
          }
        },
        { text: "Q3 : CAPACITIVO",
          left: '22%',
          bottom: '20%',
          textStyle: {
            color: '#D8D6D6',
            fontSize: 15
          }
        },
        { text: "Q4 : CAPACITIVO",
          right: '22%',
          bottom: '20%',
          textStyle: {
            color: '#D8D6D6',
            fontSize: 15
          }
        },
      ],
      grid: [
        { left: '4%', top: '10%', width: '42%', height: '35%' }, // Q2
        { right: '4%', top: '10%', width: '42%', height: '35%' }, // Q1
        { left: '4%', bottom: '5%', width: '42%', height: '35%' }, // Q3
        { right: '4%', bottom: '5%', width: '42%', height: '35%' } // Q4
      ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const xAxisValue = params[0]?.axisValueLabel;

        const formattedParams = params.map((param) => {
          const value = param.value[1];

          // Formata o valor numérico
          const formattedValue = typeof value === 'string'
            ? parseFloat(value).toLocaleString('pt-BR')
            : value.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);
        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: [
      { data: ['Geração (kWh) - Q2', 'Reativo (kVArh) - Q2'], left: '17%', top: '5%', selectedMode: false}, // Q2
      { data: ['Consumo Horário FP (kWh) - Q1','Consumo Horário PTA (kWh) - Q1', 'Reativo (kVArh) - Q1'], right: '8%', top: '5%', selectedMode: false}, // Q1
      { data: ['Geração (kWh) - Q3', 'Reativo (kVArh) - Q3'], left: '17%', bottom: '42%', selectedMode: false }, // Q3
      { data: ['Consumo Horário FP (kWh) - Q4', 'Consumo Horário PTA (kWh) - Q4', 'Reativo (kVArh) - Q4'], right: '8%', bottom: '42%', selectedMode: false}, // Q4
    ],
    xAxis: [
      { gridIndex: 0, type: 'category', boundaryGap: true }, // Q1
      { gridIndex: 1, type: 'category', boundaryGap: true }, // Q2
      { gridIndex: 2, type: 'category', boundaryGap: true }, // Q3
      { gridIndex: 3, type: 'category', boundaryGap: true } // Q4
    ],
    yAxis: [
      { gridIndex: 0,
        type: 'value',
        axisLabel: {
        formatter: function (value) {
          return value.toLocaleString('pt-BR');
        }
      }}, // Q1
      { gridIndex: 1, type: 'value' ,
        axisLabel: {
        formatter: function (value) {
          return value.toLocaleString('pt-BR');
        }
      }}, // Q2
      { gridIndex: 2, type: 'value' ,
        axisLabel: {
        formatter: function (value) {
          return value.toLocaleString('pt-BR');
        }
      }}, // Q3
      { gridIndex: 3, type: 'value',
        axisLabel: {
        formatter: function (value) {
          return value.toLocaleString('pt-BR');
        }
      } }  // Q4

    ],
    series: [
      {
        name: 'Geração (kWh) - Q2',
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: q2,
        itemStyle: {
          color: '#5470C6',
        },
      },
      {
        name: 'Consumo (kWh) - Q1',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: q1,
        itemStyle: {
          color: function (params) {
            return postoHorario[params.dataIndex] === 'ponta'
              ? '#a90000'
              : '#87E35A';
          },
        },
      },
      {
        name: 'Geração (kWh) - Q3',
        type: 'bar',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: q3,
        itemStyle: {
          color: '#5470C6',
        },
      },
      {
        name: 'Consumo (kWh) - Q4',
        type: 'bar',
        xAxisIndex: 3,
        yAxisIndex: 3,
        data: q4,
        itemStyle: {
          color: function (params) {
            return postoHorario[params.dataIndex] === 'ponta'
              ? '#a90000'
              : '#87E35A';
          },
        },
      },
      {
        name: 'Reativo (kVArh) - Q2',
        type: 'line',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: reativaQ2,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q1',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: reativaQ1,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q3',
        type: 'line',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: reativaQ3,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Reativo (kVArh) - Q4',
        type: 'line',
        xAxisIndex: 3,
        yAxisIndex: 3,
        data: reativaQ4,
        itemStyle: {
          color: '#CE9000',
        },
        showSymbol: false,
      },
      {
        name: 'Consumo Horário FP (kWh) - Q1',
        type: 'bar',
        itemStyle: {
          color: '#87E35A',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        name: 'Consumo Horário PTA (kWh) - Q1',
        type: 'bar',
        itemStyle: {
          color: '#a90000',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        name: 'Consumo Horário FP (kWh) - Q4',
        type: 'bar',
        itemStyle: {
          color: '#87E35A',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 3,
        yAxisIndex: 3,
      },
      {
        name: 'Consumo Horário PTA (kWh) - Q4',
        type: 'bar',
        itemStyle: {
          color: '#a90000',
        },
        silent: true,
        legendHoverLink: false,
        showSymbol: false,
        xAxisIndex: 3,
        yAxisIndex: 3,
      },
    ]
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: 600 }}
      key={JSON.stringify(option)}
    />
  );
}
