import React from 'react';

import { DivPaper } from 'styles/defaultStyles';

import { IoseCardMercadoLivre } from 'components';

export default function IoseMercadoLIvreDashHeader({ loading, mercadoLivre }) {
  return (
    <DivPaper>
      <IoseCardMercadoLivre loading={loading} mercadoLivre={mercadoLivre} />
    </DivPaper>
  );
}
