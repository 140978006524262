import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useLogs } from 'hooks';
import { IoseButton } from 'components';
import { IoseRemoveButton } from 'components/Buttons/IoseButton';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import * as S from '../style';
import { LogOperationOptions } from '../../../constants';
import SelectCompleteDate from 'components/SelectCompleteDate';

const formatToBrazilianDate = (date) => {
  if (date) {
    const day = String(date?.getDate()).padStart(2, '0');
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function UserLogsFilters({ logs: setLogs }) {
  const { logs } = useLogs();

  const [selectedInitialDate, setInitialSelectedDate] = useState(null)
  const [selectedFinalDate, setSelectedFinalDate] = useState(null)
  const [uniqueUsers, setUniqueUsers] = useState([]);

  useEffect(() => {
    // Extrair usuários únicos
    if (logs) {
      const uniqueUsers = [...new Set(logs?.map((log) => log?.log_user))];

      // Criar o formato desejado
      const formattedUsers = uniqueUsers?.map((user) => ({
        value: user,
        label: user,
      }));

      setUniqueUsers(formattedUsers);
    }
  }, [logs]);


  const applyFilters = (logs, filters) => {
    const formattedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (key === 'log_date_start' || key === 'log_date_end') {
          const isoDate = value
            ? new Date(value.split('/').reverse().join('-'))
            : null;

          if (key === 'log_date_end' && isoDate) {
            // Adiciona um dia apenas se não for um intervalo de dias
            const isSameDay =
              acc['log_date_start'] && acc['log_date_start'] === value;
            if (!isSameDay) {
              isoDate.setDate(isoDate.getDate() + 1);
            }
          }

          acc[key] = isoDate ? isoDate.toISOString() : null;
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const filteredLogs = logs?.filter((log) => {
      return Object.entries(formattedFilters).every(([key, value]) => {
        if (key === 'log_date_start' || key === 'log_date_end') {
          const logDate = new Date(log.log_date);

          if (key === 'log_date_start' && value) {
            const startDate = new Date(value);
            return logDate >= startDate;
          } else if (key === 'log_date_end' && value) {
            const endDate = new Date(value);
            return logDate < endDate;
          }

          return true;
        } else if (key === 'contains' && value) {
          return log.log_message.includes(value);
        }

        return log[key] === value;
      });
    });

    setLogs(filteredLogs);
  };

  const initialValues = {
    log_operation: '',
    log_user: '',
    log_date_start: '',
    log_date_end: ''
  };

  const onSubmit = async (values) => {
    const filteredValues = removeEmptyFields(values);
    applyFilters(logs, filteredValues);
  };

  const cleanFilters = (resetForm) => {
    resetForm()
    setInitialSelectedDate(null)
    setSelectedFinalDate(null)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm, isSubmitting}) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDynamicSelect
                label="Operação"
                value={values.log_operation}
                onChange={(value) => setFieldValue('log_operation', value)}
                options={LogOperationOptions}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Usuário"
                value={values.log_user}
                onChange={(value) => setFieldValue('log_user', value)}
                options={uniqueUsers}
                width="160px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <SelectCompleteDate
                label="Data inicial"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setInitialSelectedDate(value);
                  setFieldValue('log_date_start', formattedDate);
                }}
                selectedDate={selectedInitialDate}
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <SelectCompleteDate
                label="Data final"
                width="150px"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedFinalDate(value);
                  setFieldValue('log_date_end', formattedDate);
                }}
                selectedDate={selectedFinalDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isSubmitting}>
              FILTRAR
            </IoseButton>

            <IoseRemoveButton onClick={() => cleanFilters(resetForm)}>
            LIMPAR FILTROS
          </IoseRemoveButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
