import styled from 'styled-components';

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexDivColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivPaper = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;
