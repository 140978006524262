/** this file contains style for components used
 * in IoseButtonIcon2
 */
import styled from 'styled-components';

/** Imports from material-ui */
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';

export const Container = styled(Badge).attrs({
  overleap: 'circle',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
})`
  margin-left: ${(props) => props.left || '0px'};
  width: 45px;
  height: 45px;

  &:hover {
    svg {
      width: 30px;
      height: 30px;
      transition: all 0.05s linear;
    }
    .MuiAvatar-circle {
      width: 30px;
      height: 30px;
      transition: all 0.05s linear;
    }
  }
`;

export const Icon = styled(Avatar).attrs({
  variant: 'square',
})`
  width: 50px;
  height: 50px;
`;

export const MiniIcon = styled(Avatar)`
  width: 20px;
  height: 20px;
  background-color: var(--primary);
`;
