import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseNewChartGeneration(props) {
  //DATA
  const dados = props.data;

  var consumed = [];
  var generated = [];
  var balance = [];
  var date = [];

  dados.forEach((obj) => {
    consumed.push(obj.consumido);
    generated.push(obj.gerado);
    balance.push(obj['balanço']);
    date.push(obj.date);
  });

  //CHANGED DATA

  var consumedChanged = [];
  var generatedChanged = [];
  var balanceChanged = [];
  var dateChanged = [];

  dados.forEach((obj) => {
    consumedChanged.push(obj.consumido);
    generatedChanged.push(obj.gerado);
    balanceChanged.push(obj['balanço']);
    dateChanged.push(obj.date);
  });

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: ['Consumido', 'Gerado', 'Balanço'],
    },
    xAxis: {
      type: 'category',
      data: props.ModifiedChart ? dateChanged : date,
      name: 'Dias',
    },
    yAxis: {
      type: 'value',
      name: 'Energia Consumida (kWh)',
      axisLabel: {
        formatter: '{value} kWh',
      },
    },
    series: [
      {
        name: 'Consumido',
        type: 'bar',
        color: '#fff',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' kWh';
          },
        },
        data: props.ModifiedChart ? consumedChanged : consumed,
      },
      {
        name: 'Balanço',
        color: '#D65D29',
        type: 'bar',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' kWh';
          },
        },
        data: props.ModifiedChart ? balanceChanged : balance,
      },
      {
        name: 'Gerado',
        color: '#799136',
        type: 'bar',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' kWh';
          },
        },
        data: props.ModifiedChart ? generatedChanged : generated,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 380 }} />;
}
