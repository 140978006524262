import * as XLSX from 'xlsx';

function exportMeasurementReporToExcel(result) {
  const { data } = result;

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'bill_consumption',
    'bill_value',
    'recalc_value',
    'balance_value',
    'modalidade',
    'previous_reading',
    'vencimento',
    'reference_month',
    'regional_management',
    'general_management',
    'concessionaire',
    'icms_dif',
    'cofins_dif',
    'pis_dif',
    'hist_consumo_ponta1',
    'hist_consumo_fora1',
    'hist_demanda_ponta1',
    'hist_dem_fora1',
    'dem_cont_ponta1',
    'dem_cont_fora1',
    'dem_tol_ponta1',
    'dem_tol_fora1',
  ];

  // Reordena os dados
  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    bill_consumption: 'Consumo (kWh)',
    bill_value: 'Valor da fatura',
    recalc_value: 'Valor da fatura calculado',
    balance_value: 'Diferença do valor da fatura',
    modalidade: 'Modalidade',
    previous_reading: 'Leitura anterior',
    vencimento: 'Data da vencimento',
    reference_month: 'REF: MÊS/ANO',
    regional_management: 'GR',
    general_management: 'GG',
    concessionaire: 'Concessionária',
    icms_dif: 'Dif. ICMS',
    cofins_dif: 'Dif. COFINS',
    pis_dif: 'Dif. PIS',
    hist_consumo_ponta1: 'Consumo PTA',
    hist_consumo_fora1: 'Consumo FP',
    hist_demanda_ponta1: 'Demanda PTA',
    hist_dem_fora1: 'Demanda FP',
    dem_cont_ponta1: 'Demanda Cont. PTA',
    dem_cont_fora1: 'Demanda Cont. FP',
    dem_tol_ponta1: 'Demanda Total PTA',
    dem_tol_fora1: 'Demanda Total FP',
  };

  // Altera o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'relatorio-faturas.xlsx');
}

function exportEnergyBillTableToExcel(data) {
  // Reordena as colunas
  const reorderedColumns = [
    'bill_name',
    'cnpj',
    'identification_number',
    'reference_month',
    'account_value',
  ];

  // Reordena os dados
  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    bill_name: 'Endereço',
    cnpj: 'Cnpj',
    identification_number: 'Unidade Consumidora',
    reference_month: 'REF: MÊS/ANO',
    account_value: 'Valor da conta',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas de Energia');
  XLSX.writeFile(workbook, 'faturas.xlsx');
}

function exportAggregatedConsumptionTableToExcel(data) {
  const referenceMonth =
    data.length > 0 ? data[0].reference_month : 'Mês não especificado';

  const reorderedColumns = [
    'identification_number',
    'modalidade',
    'atividade',
    'city',
    'general_management',
    'regional_management',
    'total_consumo_pta',
    'total_consumo_fp',
    'percentual_pta_fp',
  ];

  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Define os cabeçalhos personalizados
  const headers = {
    identification_number: 'Unidade Consumidora',
    modalidade: 'Modalidade',
    atividade: 'Atividade',
    city: 'Cidade',
    general_management: 'GG',
    regional_management: 'GR',
    total_consumo_pta: 'Consumo PTA (kWh)',
    total_consumo_fp: 'Consumo FP (kWh)',
    percentual_pta_fp: 'Relação PTA/FP (%)',
  };

  const worksheetData = [
    // Primeira linha com o mês de referência
    [`Consumo Ponta e Fora Ponta - Mês de Referência: ${referenceMonth}`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas de Energia');

  XLSX.writeFile(workbook, 'consumos-pta-fp.xlsx');
}

function exportMercadoLivreonsumptionTableToExcel(data) {
  const referenceMonth =
    data.length > 0 ? data[0].reference_month : 'Mês não especificado';

  const reorderedColumns = [
    'identification_number',
    'comercializadora',
    'modalidade',
    // 'atividade',
    'city',
    'general_management',
    'regional_management',
    'valor_total',
    'item_total_value_1',
    'quantidade',
    'consumoFormatado',
  ];

  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'consumoFormatado') {
        reorderedObj[column] = (
          obj['item_consumption_1'] / 1000
        ).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        });
      } else if (column === 'quantidade') {
        reorderedObj[column] = parseFloat(obj[column]).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        });
      } else if (column === 'valor_total') {
        reorderedObj[column] = parseFloat(obj[column]).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        });
      } else if (column === 'item_total_value_1') {
        reorderedObj[column] = parseFloat(obj[column]).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        });
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });
  // Define os cabeçalhos personalizados
  const headers = {
    identification_number: 'Unidade Consumidora',
    comercializadora: 'Comercializadora',
    modalidade: 'Modalidade',
    // atividade: 'Atividade',
    city: 'Cidade',
    general_management: 'GG',
    regional_management: 'GR',
    valor_total: 'Valor ACL (R$)',
    item_total_value_1: 'Valor ACR (R$)',
    quantidade: 'Consumo ACL (MWh)',
    consumoFormatado: 'Consumo ACR (MWh)',
  };

  const worksheetData = [
    // Primeira linha com o mês de referência
    [`Consumo Mercado Livre - Mês de Referência: ${referenceMonth}`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas de Energia');

  XLSX.writeFile(workbook, 'consumos-mercado-livre.xlsx');
}

function exportAlarmsNotDisplayedToExcel(data) {
  const reorderedColumns = [
    'email_usuario',
    'numero_telemetria',
    'unidade_consumidora',
    'tipo_alarme',
  ];

  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Define os cabeçalhos personalizados
  const headers = {
    email_usuario: 'E-mail do usuário',
    numero_telemetria: 'Núm. telemetria',
    unidade_consumidora: 'Unidade Consumidora',
    tipo_alarme: 'Tipo do Alarme',
  };

  const worksheetData = [
    // Primeira linha - ítulo
    [`Relatório de alarmes não visualizados no App.`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Alarmes não visualizados');

  XLSX.writeFile(workbook, 'alarmes-nao-visualizados.xlsx');
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function exportConsumerGroupsToExcel(data) {
  const reorderedColumns = [
    'city',
    'operating_unity',
    'identification_number',
    'address',
    'meter',
    'subgrupo',
    'tensao',
    'modalidade',
    'demanda',
    'demanda_fora',
    'demanda_ponta',
    'potencia_transformador',
    'coordinate_x',
    'coordinate_y',
    'descricao_unidade',
    'atividade',
    'cod_unidade',
    'cod_local',
    'cod_orgao_pagador',
    'inicio_vigencia',
    'fim_vigencia',
    'regional_management',
    'general_management',
    'mercado_livre',
    'concessionaire',
    'cogeracao',
    'csbio',
    'carbon_credit',
    'geradora',
    'beneficiaria',
    'telemetry',
    'ativo',
    'cod_faturamento',
    'carga_estimada',
    'constante',
    'consumer_group_load',
    'inicio_teste',
    'fim_teste',
    'ke',
    'rtc',
    'rtp',
    'numero_telemetria',
    'pulso_watt',
    'estado',
    'relogio_reset',
  ];

  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'inicio_vigencia' || column === 'fim_vigencia') {
        reorderedObj[column] = formatDate(obj[column]);
      } else if (column === 'inicio_teste' || column === 'fim_teste') {
        // Se for NaN, substitui por 0, caso contrário formata a data
        reorderedObj[column] = isNaN(Date.parse(obj[column]))
          ? '0'
          : formatDate(obj[column]);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  // Define os cabeçalhos personalizados
  const headers = {
    identification_number: 'Unidade Consumidora',
    concessionaire: 'Concessionária',
    regional_management: 'Gerência Regional',
    general_management: 'Gerência Geral',
    operating_unity: 'Unidade Operacional',
    cod_unidade: 'Código GR',
    city: 'Cidade',
    address: 'Endereço',
    cogeracao: 'Cogeração',
    carbon_credit: 'Crédito de Carbono',
    geradora: 'Geradora',
    beneficiaria: 'Beneficiária',
    modalidade: 'Modalidade',
    telemetry: 'Telemetria',
    cod_local: 'Código do Local',
    descricao_unidade: 'Descrição da Unidade',
    tensao: 'Tensão',
    subgrupo: 'Subgrupo',
    ativo: 'Status',
    cod_orgao_pagador: 'Código do Orgão Pagador',
    inicio_vigencia: 'Ínicio Vigência',
    fim_vigencia: 'Fim Vigência',
    atividade: 'Atividade',
    cod_faturamento: 'Código do Faturamento',
    mercado_livre: 'Mercado Livre',
    carga_estimada: 'Carga Estimada',
    constante: 'Constante',
    consumer_group_load: 'Carga da U.C.',
    coordinate_x: 'Coordenada X',
    coordinate_y: 'Coordenada Y',
    demanda: 'Demanda',
    demanda_fora: 'Demanda FP',
    demanda_ponta: 'Demanda PTA',
    estado: 'Estado',
    inicio_teste: 'Início do Teste',
    fim_teste: 'Fim do Teste',
    ke: 'KE',
    meter: 'Medidor',
    numero_telemetria: 'Número de Telemetria',
    potencia_transformador: 'Potência do Transformador',
    pulso_watt: 'Pulso Watt',
    relogio_reset: 'Relógio Reset',
    rtc: 'RTC',
    rtp: 'RTP',
    csbio: 'USBIO',
  };

  const worksheetData = [
    // Primeira linha - Título
    [`Relatório de Unidades Consumidoras.`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Unidades Consumidoras');

  XLSX.writeFile(workbook, 'unidades-consumidoras.xlsx');
}

function exportFaturasToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data.map((obj) => {
    const { uuid_faturas, ...rest } = obj;
    return rest;
  });

  // Formatar a data no campo dt_leitura
  const formattedData = filteredData.map((obj) => {
    if (obj.dt_leitura) {
      obj.dt_leitura = formatDate(obj.dt_leitura);
    }
    return obj;
  });

  const reorderedColumns = [
    'reference_month',
    'cod_unidade',
    'unidade',
    'cod_local',
    'city',
    'cod_contabil',
    'cod_pagador',
    'regional_management',
    'concessionaire',
    'identification_number',
    'nome_cliente',
    'atividade',
    'num_fatura',
    'dt_leitura',
    // 'f_pot',
    'medido_kwh_convencional',
    'faturado_kwh_convencional',
    'medido_valor_convencional',
    'medido_kwh_consumo_fp',
    'faturado_kwh_consumo_fp',
    'consumo_valor_fp',
    'medido_kwh_pta',
    'faturado_kwh_consumo_pta',
    'consum_valor_pta',
    'medida_kw_demanda_fp',
    'faturada_kw_demanda_fp',
    'demanda_valor_fp',
    'medida_kw_demanda_pta',
    'faturada_kw_demanda_pta',
    'demanda_valor_pta',
    'demanda_ult_fp_kw',
    'demanda_valor_ultrap_fp',
    'demanda_ult_pta_kw',
    'demanda_valor_ult_pta_kw',
    'consumo_kw_exc_reativo_fp',
    'consumo_valor_kw_exc_reativo_fp',
    'consumo_kw_exc_reativo_pta',
    'consumo_valor_kw_exc_reativo_pta',
    'demanda_kw_exc_reativo_fp',
    'demanda_valor_kw_exc_reativo_fp',
    'demanda_kw_exc_reativo_pta',
    'demanda_valor_kw_exc_reativo_pta',
    'tx_ilum_cov_valor',
    'adicional_bandeira',
    'outros_produtos_valor',
    'total_valor',
    'medido_acl_kwh',
    'valor_acl',
  ];

  const reorderedData = formattedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });
  // Define os cabeçalhos personalizados
  const headers = {
    reference_month: 'REF: MÊS/ANO',
    cod_unidade: 'Código Unidade',
    unidade: 'Unidade',
    cod_local: 'Código Local',
    city: 'Cidade',
    cod_contabil: 'Código Contábil',
    cod_pagador: 'Código Pagador',
    regional_management: 'Gerência Regional',
    concessionaire: 'Concessionária',
    identification_number: 'Unidade Consumidora',
    nome_cliente: 'Nome Cliente',
    atividade: 'Atividade',
    num_fatura: 'Número Fatura',
    dt_leitura: 'Data de Leitura',
    // f_pot: 'F Pot',
    medido_kwh_convencional: 'Medido kWh Convencional',
    faturado_kwh_convencional: 'Faturado kWh Convencional',
    medido_valor_convencional: 'Convencional (R$)',
    medido_kwh_consumo_fp: 'Medido kWh Consumo FP',
    faturado_kwh_consumo_fp: 'Faturado kWh Consumo FP',
    consumo_valor_fp: 'Consumo FP (R$)',
    medido_kwh_pta: 'Medido kWh Consumo PTA',
    faturado_kwh_consumo_pta: 'Faturado kWh Consumo PTA',
    consum_valor_pta: 'Consumo PTA (R$)',
    medida_kw_demanda_fp: 'Medida kW Demanda FP.',
    faturada_kw_demanda_fp: 'Faturada kW Demanda FP.',
    demanda_valor_fp: 'Demanda FP. (R$)',
    medida_kw_demanda_pta: 'Medida kW Demanda PTA',
    faturada_kw_demanda_pta: 'Faturada kW Demanda PTA',
    demanda_valor_pta: 'Demanda PTA (R$)',
    demanda_ult_fp_kw: 'Demanda kW ULTRAP. FP.',
    demanda_valor_ultrap_fp: 'Demanda Ultrap. FP. (R$)',
    demanda_ult_pta_kw: 'Demanda kW Ultrap. PTA',
    demanda_valor_ult_pta_kw: 'Demanda Ultrap. PTA (R$)',
    consumo_kw_exc_reativo_fp: 'Consumo kW Exc. REATIVO FP',
    consumo_valor_kw_exc_reativo_fp: 'Consumo Exc. REATIVO FP (R$)',
    consumo_kw_exc_reativo_pta: 'Consumo kW Exc. REATIVO PTA',
    consumo_valor_kw_exc_reativo_pta: ' Consumo Exc. REATIVO PTA (R$)',
    demanda_kw_exc_reativo_fp: 'Demanda kW Exc. REATIVO FP.',
    demanda_valor_kw_exc_reativo_fp: 'Demanda Exc. REATIVO FP. (R$)',
    demanda_kw_exc_reativo_pta: 'Demanda Exc. REATIVO PTA',
    demanda_valor_kw_exc_reativo_pta: ' Demanda Exc. REATIVO PTA (R$)',
    tx_ilum_cov_valor: 'Tx Ilum Cov (R$)',
    adicional_bandeira: 'Adicional Bandeira (R$)',
    outros_produtos_valor: 'Outros Produtos (R$)',
    total_valor: 'Total (R$)',
    medido_acl_kwh: 'Medido MWh ACL',
    valor_acl: 'Valor ACL (R$)',
  };

  const worksheetData = [
    // Primeira linha - ítulo
    [`Relatório de Faturas.`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');

  XLSX.writeFile(workbook, 'faturas.xlsx');
}

function exportFaturasConsumerGroupDashToExcel(data) {
  // Reordena as colunas
  const reorderedColumns = [
    'unidade_consumidora',
    'mes_referencia',
    'qtd_paginas',
    'total',
  ];

  // Reordena os dados
  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    unidade_consumidora: 'Unidade Consumidora',
    mes_referencia: 'REF: MÊS/ANO',
    qtd_paginas: 'Qtd Páginas',
    total: 'Valor da Fatura',
  };

  // Altera o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function exportFaturasTotaisConsumerGroupDashToExcel(data) {
  // Função para converter 'MM/YYYY' para um valor que pode ser comparado
  const convertToComparableDate = (str) => {
    const [month, year] = str.split('/').map(Number);
    return year * 12 + month; // Convertendo para um valor de comparação numérica (ano * 12 + mês)
  };

  // Ordena os dados por mês/ano de forma decrescente
  const sortedData = [...data]?.sort(
    (a, b) =>
      convertToComparableDate(b.mes_referencia) -
      convertToComparableDate(a.mes_referencia)
  );

  // Define as colunas que serão incluídas e a unidade consumidora
  const reorderedColumns = ['mes_referencia', 'total'];
  const uc = sortedData[0]?.unidade_consumidora;

  // Reorganiza os dados com base nas colunas definidas
  const reorderedData = sortedData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Define os cabeçalhos das colunas
  const headers = {
    mes_referencia: 'REF: MÊS/ANO',
    total: 'Valor da Fatura',
  };

  // Cria os dados da planilha
  const worksheetData = [
    [`VALOR TOTAL (R$) DA FATURA POR MÊS DA UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  // Cria a planilha e o livro
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');

  // Salva o arquivo Excel
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function exportTelemetryAlarmsToExcel(data) {
  const handleAlarmStatus = (type) => {
    switch (type) {
      case 1:
        return 'Queda de energia';
      case 2:
        return 'Retorno de energia';
      case 3:
        return 'Fim intervalo 15 min.';
      case 4:
        return 'Sem medição';
      case 5:
        return 'Horário fora de ponta';
      case 6:
        return 'Horário de ponta';
      case 7:
        return 'Horário capacitivo';
      case 28:
        return 'Abertura da porta';
      case 29:
        return 'Fechamento da porta';
      case 56:
        return 'Reset pelo usuário';
      case 102:
        return 'Fim intervalo timeou';
      case 200:
        return 'Ultrapassagem de demanda';
      case 201:
        return 'Excesso de Reativos';
      default:
        return 'Evento não suportado';
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.dat_evento);
    const dateB = new Date(b.dat_evento);
    return dateB - dateA; // Ordem decrescente
  });

  // Reordena as colunas
  const reorderedColumns = ['uuid_circuit', 'cod_evento', 'dat_evento'];

  // Reordena os dados e traduz o valor do campo 'type'
  const reorderedData = sortedData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'cod_evento') {
        reorderedObj[column] = handleAlarmStatus(obj[column]);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    uuid_circuit: 'Circuito',
    type: 'Código do alarme',
    dat_evento: 'Data da criação',
  };

  // Altera o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'alarmes-telemetria.xlsx');
}

function exportTelemetryMeasuresToExcel(data) {
  function parseDateTime(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');

    const currentYear = new Date().getFullYear();
    return new Date(currentYear, month - 1, day, hours, minutes);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDateTime(a.arrival_date);
    const dateB = parseDateTime(b.arrival_date);
    return dateB - dateA; // Ordem decrescente
  });

  // Reordena as colunas
  const reorderedColumns = [
    'numero_circuito',
    'potencia_a_wh_q1',
    'potencia_reativa_a_varh_q1',
    'demanda_energia_ativa_q1',
    'demanda_energia_reativa_q1',
    'arrival_date',
  ];

  // Reordena os dados
  const reorderedData = sortedData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    numero_circuito: 'Circuito',
    potencia_a_wh_q1: 'Potência Ativa (Wh)',
    potencia_reativa_a_varh_q1: 'Potência Reativa (VArh)',
    demanda_energia_ativa_q1: 'Demanda Ativa (kW)',
    demanda_energia_reativa_q1: 'Demanda Reativa (kVAr)',
    arrival_date: 'Data / Hora',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'medições-telemetria.xlsx');
}

function exportContabilToExcel(data) {
  const referenceMonth = data[0] ? data[0].cop_ano_mes : '';

  const reorderedColumns = [
    'cop_num_cliente',
    'cop_ano_mes',
    'cop_chave_nfe',
    'cop_dt_apresentacao',
    'cop_dt_emissao',
    'cop_num_cnpj',
    'cop_num_nota',
    'cop_serie_nf',
    'cop_status_nota',
    'cop_sub_serie_nf',
    'cop_valor_nota',
  ];

  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Define os cabeçalhos personalizados
  const headers = {
    cop_num_cliente: 'UC',
    cop_ano_mes: 'REF:MÊS/ANO',
    cop_chave_nfe: 'Chave nota fiscal',
    cop_dt_apresentacao: 'Data de emissão',
    cop_dt_emissao: 'Data de emissão nota fiscal COPEL',
    cop_num_cnpj: 'CNPJ',
    cop_num_nota: 'Nota fiscal COPEL',
    cop_serie_nf: 'Serie nota fiscal COPEL',
    cop_status_nota: 'Status da nota',
    cop_sub_serie_nf: 'Sub série da nota fiscal COPEL',
    cop_valor_nota: 'Valor da nota',
  };

  const worksheetData = [
    // Primeira linha - ítulo
    [`Relatório Contábil - ${referenceMonth}`],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas (para ajustar automaticamente a largura das colunas)
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Contábil');

  XLSX.writeFile(workbook, 'contabil.xlsx');
}

function exportYearSummaryByMonthToExcel(data) {
  // Define os cabeçalhos personalizados
  const headers = {
    referenceMonth: 'REF:MÊS/ANO',
    modalidade: 'Modalidade / Subgrupo',
    qtd_ucs: 'Qtd UCs',
    consumo_pta_valor: 'Consumo PTA (R$)',
    consumo_pta_kwh: 'Consumo PTA (kWh)',
    consumo_fp_valor: 'Consumo FP (R$)',
    consumo_fp_kwh: 'Consumo FP (kWh)',
    consumo_exe_reat_pta_valor: 'Consumo Exe Reat PTA (R$)',
    consumo_exe_reat_pta_kwh: 'Consumo Exe Reat PTA (kVAr)',
    consumo_exe_reat_fp_valor: 'Consumo Exe Reat FP (R$)',
    consumo_exe_reat_fp_kwh: 'Consumo Exe Reat FP (kVAr)',
    demanda_pta_valor: 'Demanda PTA (R$)',
    demanda_pta_kwh: 'Demanda PTA (kW)',
    demanda_fp_valor: 'Demanda FP (R$)',
    demanda_fp_kwh: 'Demanda FP (kW)',
    demanda_exe_reat_pta_valor: 'Demanda Exe Reat PTA (R$)',
    demanda_exe_reat_pta_kwh: 'Demanda Exe Reat PTA (kVAr)',
    demanda_exe_reat_fp_valor: 'Demanda Exe Reat FP (R$)',
    demanda_exe_reat_fp_kwh: 'Demanda Exe Reat FP (kVAr)',
    ad_demanda_pta_valor: 'Ad Demanda PTA (R$)',
    ad_demanda_pta_kwh: 'Ad Demanda PTA (kW)',
    ad_demanda_fp_valor: 'Ad Demanda FP (R$)',
    ad_demanda_fp_kwh: 'Ad Demanda FP (kW)',
    ad_demanda_valor: 'Ad Demanda (R$)',
    ad_demanda_kwh: 'Ad Demanda (kW)',
    cip_valor: 'CIP (R$)',
    cip_kwh: 'CIP (kWh)',
    energia_inj_valor: 'Energia Inj (R$)',
    energia_inj_kwh: 'Energia Inj (kWh)',
    outros_valor: 'Outros (R$)',
    outros_kwh: 'Outros (kWh)',
    demanda_ult_pta_valor: 'Demanda Ult PTA (R$)',
    demanda_ult_pta_kwh: 'Demanda Ult PTA (kW)',
    demanda_ult_fp_valor: 'Demanda Ult FP (R$)',
    demanda_ult_fp_kwh: 'Demanda Ult FP (kW)',
    demanda_valor: 'Demanda (R$)',
    demanda_kwh: 'Demanda (kWh)',
    demanda_exe_reat_valor: 'Demanda Exe Reat (R$)',
    demanda_exe_reat_kwh: 'Demanda Exe Reat (kVAr)',
    consumo_exe_reat_valor: 'Consumo Exe Reat (R$)',
    consumo_exe_reat_kwh: 'Consumo Exe Reat (kVAr)',
    consumo_valor: 'Consumo (R$)',
    consumo_kwh: 'Consumo (kWh)',
    consumo_acl_valor: 'Consumo ACL (R$)',
    consumo_acl_kwh: 'Consumo ACL (kWh)',
  };

  const flattenedData = Object.entries(data).flatMap(
    ([monthYear, modalities]) =>
      Object.entries(modalities).map(([modalidade, data]) => ({
        referenceMonth: monthYear,
        modalidade,
        ...data,
      }))
  );

  const reorderedColumns = [
    'referenceMonth',
    'modalidade',
    ...Object.keys(headers).filter(
      (key) => key !== 'referenceMonth' && key !== 'modalidade'
    ),
  ];

  const worksheetData = [
    [`Relatório Resumo Anual`],
    reorderedColumns.map((column) => headers[column]),
    ...flattenedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Resumo Anual por Mês');

  XLSX.writeFile(workbook, 'resumo-anual.xlsx');
}

function exportYearSummaryTotals(data, year) {
  const ano = year[0]?.ano;

  const reorderedColumns = [
    'consumo_pta_valor',
    'consumo_pta_kwh',
    'consumo_fp_valor',
    'consumo_fp_kwh',
    'consumo_exe_reat_pta_valor',
    'consumo_exe_reat_pta_kwh',
    'consumo_exe_reat_fp_valor',
    'consumo_exe_reat_fp_kwh',
    'demanda_pta_valor',
    'demanda_pta_kwh',
    'demanda_fp_valor',
    'demanda_fp_kwh',
    'demanda_exe_reat_pta_valor',
    'demanda_exe_reat_pta_kwh',
    'demanda_exe_reat_fp_valor',
    'demanda_exe_reat_fp_kwh',
    'ad_demanda_pta_valor',
    'ad_demanda_pta_kwh',
    'ad_demanda_fp_valor',
    'ad_demanda_fp_kwh',
    'cip_valor',
    'cip_kwh',
    'energia_inj_valor',
    'energia_inj_kwh',
    'outros_valor',
    'outros_kwh',
    'consumo_valor',
    'consumo_kwh',
    'consumo_exe_reat_valor',
    'consumo_exe_reat_kwh',
    'demanda_valor',
    'demanda_kwh',
    'demanda_exe_reat_valor',
    'demanda_exe_reat_kwh',
    'ad_demanda_valor',
    'ad_demanda_kwh',
    'demanda_ult_pta_valor',
    'demanda_ult_pta_kwh',
    'demanda_ult_fp_valor',
    'demanda_ult_fp_kwh',
    'demanda_ult_valor',
    'demanda_ult_kwh',
    'consumo_acl_valor',
    'consumo_acl_kwh',
  ];

  // Definindo cabeçalhos personalizados
  const headers = {
    consumo_pta_valor: 'Consumo PTA (R$)',
    consumo_fp_valor: 'Consumo FP (R$)',
    consumo_exe_reat_pta_valor: 'Consumo Exe Reat PTA (R$)',
    consumo_exe_reat_fp_valor: 'Consumo Exe Reat FP (R$)',
    demanda_pta_valor: 'Demanda PTA (R$)',
    demanda_fp_valor: 'Demanda FP (R$)',
    demanda_exe_reat_pta_valor: 'Demanda Exe Reat PTA (R$)',
    demanda_exe_reat_fp_valor: 'Demanda Exe Reat FP (R$)',
    ad_demanda_pta_valor: 'Adicional Demanda PTA (R$)',
    ad_demanda_fp_valor: 'Adicional Demanda FP (R$)',
    cip_valor: 'CIP (R$)',
    energia_inj_valor: 'Energia Injetada (R$)',
    outros_valor: 'Outros (R$)',
    outros_kwh: 'Outros (kWh)',
    consumo_pta_kwh: 'Consumo PTA (kWh)',
    consumo_fp_kwh: 'Consumo FP (kWh)',
    consumo_exe_reat_pta_kwh: 'Consumo Exe Reat PTA (kVAr)',
    consumo_exe_reat_fp_kwh: 'Consumo Exe Reat FP (kVAr)',
    demanda_pta_kwh: 'Demanda PTA (kW)',
    demanda_fp_kwh: 'Demanda FP (kW)',
    demanda_exe_reat_pta_kwh: 'Demanda Exe Reat PTA (kVAr)',
    demanda_exe_reat_fp_kwh: 'Demanda Exe Reat FP (kVAr)',
    ad_demanda_pta_kwh: 'Adicional Demanda PTA (kW)',
    ad_demanda_fp_kwh: 'Adicional Demanda FP (kW)',
    cip_kwh: 'CIP (kWh)',
    energia_inj_kwh: 'Energia Injetada (kWh)',
    consumo_valor: 'Consumo (R$)',
    consumo_kwh: 'Consumo (kWh)',
    consumo_exe_reat_valor: 'Consumo Exe Reat (R$)',
    consumo_exe_reat_kwh: 'Consumo Exe Reat (kVAr)',
    demanda_valor: 'Demanda (R$)',
    demanda_kwh: 'Demanda (kW)',
    demanda_exe_reat_valor: 'Demanda Exe Reat (R$)',
    demanda_exe_reat_kwh: 'Demanda Exe Reat (kVAr)',
    ad_demanda_valor: 'Adicional Demanda (R$)',
    ad_demanda_kwh: 'Adicional Demanda (kW)',
    demanda_ult_pta_valor: 'Demanda Ultrapassagem PTA (R$)',
    demanda_ult_pta_kwh: 'Demanda Ultrapassagem PTA (kW)',
    demanda_ult_fp_valor: 'Demanda Ultrapassagem FP (R$)',
    demanda_ult_fp_kwh: 'Demanda Ultrapassagem FP (kW)',
    demanda_ult_valor: 'Demanda Ultrapassagem (R$)',
    demanda_ult_kwh: 'Demanda Ultrapassagem (kW)',
    consumo_acl_valor: 'Consumo ACL (R$)',
    consumo_acl_kwh: 'Consumo ACL (kWh)',
  };

  // Reorganizando os dados
  const reorderedData = [data].map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Criando dados para a planilha XLSX
  const worksheetData = [
    [`Relatório Resumo Anual Totais - ${ano}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Resumo Anual Total');

  XLSX.writeFile(workbook, `resumo-anual-total.xlsx`);
}

function exportResumoGraficoDemandaRf12(data, uc) {
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.reference_month.split('/').reverse().join('-'));
    const dateB = new Date(b.reference_month.split('/').reverse().join('-'));
    return dateB - dateA; // Ordena do mais recente para o mais antigo
  });

  const headers = {
    reference_month: 'REF:MÊS/ANO',
    demanda_fp: 'Demanda Faturada FP (kW)',
    demanda_pta: 'Demanda Faturada PTA (kW)',
    demanda_faturada: 'Demanda Faturada(kW)',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`RESUMO DE DEMANDA POR MÊS UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'RESUMO DEMANDA POR MÊS');

  XLSX.writeFile(workbook, `resumo-demanda-mes-uc-${uc}.xlsx`);
}

function exportResumoGraficoDesperdicios(data, uc) {
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.reference_month.split('/').reverse().join('-'));
    const dateB = new Date(b.reference_month.split('/').reverse().join('-'));
    return dateB - dateA;
  });

  const headers = {
    reference_month: 'REF:MÊS/ANO (R$)',
    adicional_demanda: 'Adic. Demanda (R$)',
    adicional_demanda_fp: 'Adic. Demanda FP  (R$)',
    adicional_demanda_pta: 'Adic. Demanda PTA  (R$)',
    consumo_exc_reativo: 'Consumo Exc. Reat. (R$)',
    consumo_exc_reativo_fp: 'Consumo Exc. Reat. FP (R$)',
    consumo_exc_reativo_pta: 'Consumo Exc. Reat. PTA (R$)',
    demanda_exc_reativa: 'Demanda Exc. Reat. (R$)',
    demanda_exc_reativa_fp: 'Demanda Exc. Reat. FP (R$)',
    demanda_exc_reativa_pta: 'Demanda Exc. Reat. PTA (R$)',
    demanda_ultrapassagem: 'Ultrap. Demanda (R$)',
    demanda_ultrapassagem_fp: 'Ultrap. Demanda FP (R$)',
    demanda_ultrapassagem_pta: 'Ultrap. Demanda PTA (R$)',
  };
  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`RESUMO DESPERDÍCIOS POR MÊS UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    'RESUMO DESPERDÍCIOS POR MÊS'
  );

  XLSX.writeFile(workbook, `resumo-desperdicios-mes-uc-${uc}.xlsx`);
}

function exportResumoGraficoConsumo(data, uc) {
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.reference_month.split('/').reverse().join('-'));
    const dateB = new Date(b.reference_month.split('/').reverse().join('-'));
    return dateB - dateA;
  });

  const headers = {
    reference_month: 'REF:MÊS/ANO',
    total_consumo_fp: 'Consumo FP (kWh)',
    total_consumo_pta: 'Consumo PTA (kWh)',
    total_consumo: 'Consumo (kWh)',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`RESUMO DE CONSUMO POR MÊS UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'RESUMO CONSUMO POR MÊS');

  XLSX.writeFile(workbook, `resumo-consumo-mes-uc-${uc}.xlsx`);
}

function exportResumoGraficoConsumo30dias(data, uc) {
  function parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDate(a.dat_envio);
    const dateB = parseDate(b.dat_envio);
    return dateB - dateA;
  });

  const headers = {
    dat_envio: 'Data da Medição',
    total_potencia_a_wh_q1: 'Consumo Q1 (kWh)',
    total_potencia_reativa_a_varh_q1: 'Consumo Reativo Q1 (kVArh)',
    total_potencia_a_wh_q4: 'Consumo Q4 (kWh)',
    total_potencia_reativa_a_varh_q4: 'Consumo Reativo Q4 (kVArh)',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados e dividindo os campos específicos por 1000
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (
        [
          'total_potencia_a_wh_q1',
          'total_potencia_reativa_a_varh_q1',
          'total_potencia_a_wh_q4',
          'total_potencia_reativa_a_varh_q4',
        ].includes(column)
      ) {
        reorderedObj[column] =
          obj[column] !== undefined ? obj[column] / 1000 : '';
      } else {
        reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
      }
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`RESUMO DE CONSUMO - TELEMERIA - UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    'RESUMO DE CONSUMO - TELEMERIA'
  );

  XLSX.writeFile(workbook, `consumo-telemetria-uc-${uc}.xlsx`);
}

function exportResumoGraficoFatorPotenciaMedio(data, uc) {
  function parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDate(a.dat_envio);
    const dateB = parseDate(b.dat_envio);
    return dateB - dateA;
  });

  const headers = {
    dat_envio: 'Data',
    fator_a_q1: 'Fator Potência Médio Q1',
    fator_a_q4: 'Fator Potência Médio Q4',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`FAT. POT. MÉDIO TELEMERIA - UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'FATOR POT.');

  XLSX.writeFile(workbook, `fator-potencia-medio-uc-${uc}.xlsx`);
}

function exportResumoGraficoConsumo4Quadrantes(data, uc) {
  function parseDateTime(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');
    // Para criar uma data no ano atual
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, month - 1, day, hours, minutes);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDateTime(a.arrival_date);
    const dateB = parseDateTime(b.arrival_date);
    return dateB - dateA;
  });

  const headers = {
    arrival_date: 'Data / Hora',
    potencia_a_wh_q1: 'Consumo (kWh) - Q1',
    potencia_a_wh_q2: 'Consumo (kWh) - Q2',
    potencia_a_wh_q3: 'Consumo (kWh) - Q3',
    potencia_a_wh_q4: 'Consumo (kWh) - Q4',
    potencia_reativa_a_varh_q1: 'Consumo Reativo (kVArh) - Q4',
    potencia_reativa_a_varh_q2: 'Consumo Reativo (kVArh) - Q4',
    potencia_reativa_a_varh_q3: 'Consumo Reativo (kVArh) - Q4',
    potencia_reativa_a_varh_q4: 'Consumo Reativo (kVArh) - Q4',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (
        column.includes('potencia_a_wh') ||
        column.includes('potencia_reativa_a_varh')
      ) {
        reorderedObj[column] =
          obj[column] !== undefined ? obj[column] / 1000 : '';
      } else {
        reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
      }
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`CONSUMO - 4 QUADRANTES - UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'CONSUMO 4 QUADRANTES.');

  XLSX.writeFile(workbook, `consumo-4-quadrantes-diario-${uc}.xlsx`);
}

function exportResumoGraficoDemanda4Quadrantes(data, uc) {
  function parseDateTime(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');
    // Para criar uma data no ano atual
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, month - 1, day, hours, minutes);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDateTime(a.arrival_date);
    const dateB = parseDateTime(b.arrival_date);
    return dateB - dateA;
  });

  const headers = {
    arrival_date: 'Data / Hora',
    demanda_energia_ativa_q1: 'Demanda (kW) - Q1',
    demanda_energia_ativa_q2: 'Demanda (kW) - Q2',
    demanda_energia_ativa_q3: 'Demanda (kW) - Q3',
    demanda_energia_ativa_q4: 'Demanda (kW) - Q4',
    demanda_energia_reativa_q1: 'Demanda Reativa (kVArh) - Q4',
    demanda_energia_reativa_q2: 'Demanda Reativa (kVArh) - Q4',
    demanda_energia_reativa_q3: 'Demanda Reativa (kVArh) - Q4',
    demanda_energia_reativa_q4: 'Demanda Reativa (kVArh) - Q4',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (
        column.includes('demanda_energia_ativa') ||
        column.includes('demanda_energia_reativa')
      ) {
        reorderedObj[column] =
          obj[column] !== undefined ? obj[column] / 1000 : '';
      } else {
        reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
      }
    });
    return reorderedObj;
  });
  const worksheetData = [
    [`DEMANDA - 4 QUADRANTES - UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'DEMANDA 4 QUADRANTES.');

  XLSX.writeFile(workbook, `demanda-4-quadrantes-diario-${uc}.xlsx`);
}

function exportResumoGraficoFatPotenciaDiario(data, uc) {
  function parseDateTime(dateString) {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');
    // Para criar uma data no ano atual
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, month - 1, day, hours, minutes);
  }

  const sortedData = [...data].sort((a, b) => {
    const dateA = parseDateTime(a.arrival_date);
    const dateB = parseDateTime(b.arrival_date);
    return dateB - dateA;
  });

  const headers = {
    arrival_date: 'Data / Hora',
    fator_potencia: 'Fator Potência - Q1',
    fator_potencia_q4: 'Fator Potência - Q4',
  };

  const fields = [...new Set(sortedData.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = sortedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`FATOR POTÊNCIA DIÁRIO - UC ${uc}`],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'FAT. POT. DIÁRIO');

  XLSX.writeFile(workbook, `fator-potencia-diario-${uc}.xlsx`);
}

function exportTop10DesperdiciosPorUc(data) {
  const headers = {
    identification_number: 'UC',
    general_management: 'GG',
    regional_management: 'GR',
    total: 'Valor total (R$)',
  };

  const fields = [...new Set(data.flatMap(Object.keys))];

  const reorderedColumns = Object.keys(headers).filter((field) =>
    fields.includes(field)
  );

  // Reorganizando os dados
  const reorderedData = data.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });
    return reorderedObj;
  });

  const worksheetData = [
    [`MAIORES DESPERDÍCIOS POR UC `],
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Desperdicios');

  XLSX.writeFile(workbook, `maiores-desperdicios-ucs.xlsx`);
}

function exportCreditosGeracao(data) {
  const ucGeradora = data[0]?.uc_geradora;
  const mesReferencia = data[0]?.mes_referencia;
  // Somar os valores de gerados_pta e gerados_fpta
  const geradosPta = data[0]?.gerados_pta || 0;
  const geradosFp = data[0]?.gerados_fpta || 0;
  const injetadoTotal = geradosPta + geradosFp;

  const headersGeradora = {
    uc_geradora: 'UC Geradora',
    mes_referencia: 'REF:MÊS/ANO',
    gerados_pta: 'Energia Injetada PT',
    gerados_fpta: 'Energia Injetada FP',
    injetadoTotal: 'Energia Injetada Total',
    compensado: 'Compensados (kW)',
  };

  const headersBeneficiaria = {
    uc_beneficiaria: 'UC Beneficiária',
    cred_recebido: 'Crédito Recebido',
    mes_cred_gerado: 'Mês Crédito Gerado',
    mes_cred_utilizado: 'Mês Crédito Utilizado',
    porcentagem: 'Porcentagem',
  };

  // Dados da UC Geradora

  const geradoraData = data.map((obj) => {
    const reorderedObj = {};
    Object.keys(headersGeradora).forEach((column) => {
      reorderedObj[column] = obj[column] !== undefined ? obj[column] : '';
    });

    reorderedObj.injetadoTotal = injetadoTotal;
    return reorderedObj;
  });

  // Dados das UC Beneficiárias
  const beneficiariaData = data.flatMap((obj) => {
    return obj.uc_beneficiarias.map((beneficiaria) => {
      const reorderedObj = {};
      Object.keys(headersBeneficiaria).forEach((column) => {
        reorderedObj[column] =
          beneficiaria[column] !== undefined ? beneficiaria[column] : '';
      });

      return reorderedObj;
    });
  });

  // Combine UC Geradora com UC Beneficiárias
  const worksheetData = [
    [`RESUMO DE CRÉDITOS DA UC GERADORA ${ucGeradora} NO MÊS ${mesReferencia}`],
    Object.values(headersGeradora),
    ...geradoraData.map((obj) => Object.values(obj)),
    [],
    [`BENEFICIÁRIAS DA UC ${ucGeradora}`],
    Object.values(headersBeneficiaria),
    ...beneficiariaData.map((obj) => Object.values(obj)),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Define os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: {
      r: worksheetData.length - 1,
      c:
        Math.max(
          Object.keys(headersGeradora).length,
          Object.keys(headersBeneficiaria).length
        ) - 1,
    },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    'RESUMO DE CRÉDITOS POR MÊS'
  );

  XLSX.writeFile(workbook, `resumo-geracao-credito-mes-uc-${ucGeradora}.xlsx`);
}

function exportCreditosGeracaoGeral(data) {
  const ucGeradora = data[0]?.uc_geradora;
  // Obter todos os meses de referência disponíveis
  const mesesReferencia = [...new Set(data.map((obj) => obj.mes_referencia))];

  // Estrutura inicial: Título e UC Geradora
  const titleRow = ['RESUMO DE CRÉDITOS DA UC GERADORA'];
  const ucRow = ['UC Geradora', ucGeradora];

  // Linha para os meses
  const headers = ['Campo/Mês', ...mesesReferencia];

  // Preparar as linhas com campos embaixo de cada mês
  const campos = [
    'Energia Injetada Ponta (kWh)',
    'Energia Injetada Fora Ponta (kWh)',
    'Energia Injetada Total (kWh)',
    'Créditos Recebidos (kWh)',
    'Valor dos Créditos (R$)',
  ];

  // Matriz para armazenar os valores organizados por campo e mês
  const valoresRows = campos.map((campo) => {
    const row = [campo];
    mesesReferencia.forEach((mes) => {
      const obj = data.find((d) => d.mes_referencia === mes) || {};
      let valor;
      switch (campo) {
        case 'Energia Injetada Ponta (kWh)':
          valor = obj.gerados_pta || 0;
          break;
        case 'Energia Injetada Fora Ponta (kWh)':
          valor = obj.gerados_fp || 0;
          break;
        case 'Energia Injetada Total (kWh)':
          valor = (obj.gerados_pta || 0) + (obj.gerados_fp || 0);
          break;
        case 'Créditos Recebidos (kWh)':
          valor = obj.compensado || 0;
          break;
        case 'Valor dos Créditos (R$)':
          valor = obj.valor_creditos || 0;
          break;
        default:
          valor = 0;
      }
      row.push(valor);
    });
    return row;
  });

  // Combinar tudo em uma estrutura de saída
  const worksheetData = [titleRow, ucRow, headers, ...valoresRows];
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Ajustar a largura das colunas
  const colWidths = headers.map(() => ({ wch: 20 }));
  worksheet['!cols'] = colWidths;

  // Definir os limites de células usadas
  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: {
      r: worksheetData.length - 1,
      c: headers.length - 1,
    },
  });
  // Adicionar bordas às células
  for (let R = 0; R <= worksheetData.length; R++) {
    for (let C = 0; C <= headers.length; C++) {
      const cell_address = { c: C, r: R };
      const cell_ref = XLSX.utils.encode_cell(cell_address);
      if (!worksheet[cell_ref]) worksheet[cell_ref] = { t: 's', v: '' };
      worksheet[cell_ref].s = {
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };
    }
  }
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    'RESUMO DE CRÉDITOS POR MÊS'
  );

  XLSX.writeFile(workbook, `resumo-geracao-credito-mes-uc-${ucGeradora}.xlsx`);
}

function exportDesperdíciosToExcel(data) {
  function mapReferenteToUnit(referente) {
    if (
      referente.includes('Demanda Ultrap.') ||
      referente.includes('Adicional de demanda') ||
      referente.includes()
    ) {
      return 'kW';
    } else if (
      referente.includes('Demanda EXC. Reativa') ||
      referente.includes('Consumo Exc. Reativo')
    ) {
      return 'kVArh';
    }
    return '';
  }
  const filteredData = data.map((obj) => {
    const { uuid_faturas, ...rest } = obj;
    return rest;
  });

  const formattedData = filteredData.map((obj) => {
    if (obj.dt_leitura) {
      obj.dt_leitura = formatDate(obj.dt_leitura);
    }

    // Mapear o referente para a unidade apropriada
    obj.grandezas = mapReferenteToUnit(obj.referente);

    return obj;
  });

  const reorderedColumns = [
    'reference_month',
    'city',
    'regional_management',
    'concessionaire',
    'identification_number',
    'numero_telemetria',
    'referente',
    'item_consumption_1',
    'grandezas',
    'item_total_value_1',
    'tarifa',
  ];

  const reorderedData = formattedData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  // Define os cabeçalhos personalizados
  const headers = {
    reference_month: 'REF: MÊS/ANO',
    regional_management: 'Gerência Regional',
    city: 'Cidade',
    concessionaire: 'Concessionária',
    identification_number: 'Unidade Consumidora',
    numero_telemetria: 'Número Telemetria',
    referente: 'Referente',
    item_consumption_1: 'Consumo',
    grandezas: 'Grandezas',
    item_total_value_1: 'Valor (R$)',
    tarifa: 'Tarifa',
  };

  const worksheetData = [
    // Primeira linha - título
    ['Relatório de Desperdícios'],
    // Segunda linha com os cabeçalhos das colunas
    reorderedColumns.map((column) => headers[column]),
    ...reorderedData.map((obj) =>
      reorderedColumns.map((column) => obj[column])
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  worksheet['!ref'] = XLSX.utils.encode_range({
    s: { r: 0, c: 0 },
    e: { r: worksheetData.length - 1, c: reorderedColumns.length - 1 },
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Desperdícios');

  XLSX.writeFile(workbook, 'desperdicios.xlsx');
}

export const xlsxReports = {
  exportMeasurementReporToExcel,
  exportEnergyBillTableToExcel,
  exportAggregatedConsumptionTableToExcel,
  exportAlarmsNotDisplayedToExcel,
  exportConsumerGroupsToExcel,
  exportFaturasToExcel,
  exportFaturasConsumerGroupDashToExcel,
  exportTelemetryAlarmsToExcel,
  exportTelemetryMeasuresToExcel,
  exportContabilToExcel,
  exportMercadoLivreonsumptionTableToExcel,
  exportYearSummaryByMonthToExcel,
  exportYearSummaryTotals,
  exportResumoGraficoDemandaRf12,
  exportResumoGraficoDesperdicios,
  exportResumoGraficoConsumo,
  exportResumoGraficoConsumo30dias,
  exportResumoGraficoFatorPotenciaMedio,
  exportResumoGraficoConsumo4Quadrantes,
  exportResumoGraficoDemanda4Quadrantes,
  exportResumoGraficoFatPotenciaDiario,
  exportCreditosGeracao,
  exportCreditosGeracaoGeral,
  exportTop10DesperdiciosPorUc,
  exportFaturasTotaisConsumerGroupDashToExcel,
  exportDesperdíciosToExcel,
};
