import React from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseNoDataMessage } from 'components';

export default function GraficoClima({ geracao }) {
  const data = [];
  const gerado = [];
  const weather = [];
  const reativo = [];
  const temp = [];

  let contador = 0;
  geracao.forEach((obj) => {
    gerado.push(obj.export_active_energy);
    reativo.push(obj.export_reactive_energy);
    if (obj.data === '0:00' && contador === 0) {
      data.push(obj.data);
      contador += 1;
    } else if (obj.data !== '0:00') {
      data.push(obj.data);
    }

    weather.push(
      `https://openweathermap.org/img/wn/${obj.weather_icon}@2x.png`
    );
    temp.push(obj.main_temp - 273.15);
  });

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '15%',
      top: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params?.map((param) => {
          const formattedValue =
            typeof param?.value === 'string'
              ? parseFloat(param?.value).toLocaleString('pt-BR')
              : param?.value.toLocaleString('pt-BR');
          if (formattedValue === 'NaN') return;
          return `<strong>${param?.seriesName}</strong>: ${formattedValue}`;
        });

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: ['Temperatura (C°)'],
      selectedMode: false,
    },
    xAxis: {
      type: 'category',
      data: data,
    },
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    visualMap: {
      show: false,
      pieces: [
        { min: -Infinity, max: 0, color: '#5470C6' }, // Azul para temperaturas <= 0
        { min: 0, max: 15, color: '#91cc75' }, // Verde para temperaturas entre 0 e 15
        { min: 15, max: 25, color: '#fac858' }, // Amarelo para temperaturas entre 15 e 30
        { min: 25, max: Infinity, color: '#ee6666' }, // Vermelho para temperaturas > 30
      ],
      outOfRange: {
        color: '#999', // Cor padrão para valores fora do intervalo
      },
    },
    series: [
      {
        name: 'Temperatura (C°)',
        data: temp,
        type: 'line',
        symbolSize: 0,
        lineStyle: {
          width: 4,
        },
        label: {
          show: true,
          formatter: function (value) {
            const index = value.dataIndex;
            if (index === 0 || Math.abs(temp[index] - temp[index - 1]) > 0.5) {
              return (
                Number(value.data.toFixed(0)).toLocaleString('pt-BR') + '°'
              );
            }
            return '';
          },
        },
      },
      {
        name: '',
        data: weather,
        type: 'line',
        markPoint: {
          data: weather.reduce((acc, icon, index) => {
            if (index === 0 || icon !== weather[index - 1]) {
              acc.push({
                coord: [data[index], 5],
                symbol: `image://${icon}`,
                symbolSize: [30, 30],
                label: {
                  show: false, // Oculta o rótulo do markPoint
                },
                itemStyle: {
                  color: 'transparent', // Torna o fundo do markPoint transparente
                },
              });
            }
            return acc;
          }, []),
        },
      },
    ],
  };

  return geracao?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 380, width: '100%' }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}
