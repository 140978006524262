import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2, LoadingIndicator } from 'components';
import GetAppIcon from '@material-ui/icons/GetApp';
import { xlsxReports } from 'reports/Xlsx';

// Função para formatar números como Real (R$)
function formatReal(value) {
  return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
}

// Função para agregar dados de desperdícios para modalidade VERDE
function aggregateGreenDesperdiciosByMonth(data) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        demanda_exc_reativa: 0,
        demanda_nao_consumida: 0,
        demanda_nao_utilizada: 0,
        demanda_ultrapassagem: 0,
        demanda_isenta: 0,
        adicional_demanda: 0,
        adicional_demanda_fp: 0,
        adicional_demanda_pta: 0,
        consumo_exc_reativo_pta: 0,
        consumo_exc_reativo_fp: 0,
      };
    }

    // Agregar valores de acordo com o tipo de desperdício
    switch (item.referente) {
      case 'Demanda EXC. Reativa':
        aggregatedData[month].demanda_exc_reativa += item.referente_total_value;
        break;
      case 'Demanda não consumida':
        aggregatedData[month].demanda_nao_consumida +=
          item.referente_total_value;
        break;
      case 'Adicional de demanda':
        aggregatedData[month].adicional_demanda += item.referente_total_value;
        break;
      case 'Adicional de demanda PTA':
        aggregatedData[month].adicional_demanda_fp +=
          item.referente_total_value;
        break;
      case 'Adicional de demanda FP':
        aggregatedData[month].adicional_demanda_pta +=
          item.referente_total_value;
        break;
      case 'Demanda Ultrap.':
        aggregatedData[month].demanda_ultrapassagem +=
          item.referente_total_value;
        break;
      case 'Demanda isenta':
        aggregatedData[month].demanda_isenta += item.referente_total_value;
        break;
      case 'Consumo Exc. Reativo PTA':
        aggregatedData[month].consumo_exc_reativo_pta +=
          item.referente_total_value;
        break;
      case 'Consumo Exc. Reativo FP':
        aggregatedData[month].consumo_exc_reativo_fp +=
          item.referente_total_value;
        break;
      default:
        break;
    }
  });

  // Ordenar por mês/ano
  const aggregatedArray = Object.values(aggregatedData).sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);
    return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
  });

  return aggregatedArray;
}

// Função para agregar dados de desperdícios para modalidade AZUL
function aggregateBlueDesperdiciosByMonth(data, loading) {
  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        demanda_exc_reativa_pta: 0,
        demanda_exc_reativa_fp: 0,
        demanda_isenta: 0,
        consumo_exc_reativo_pta: 0,
        consumo_exc_reativo_fp: 0,
        demanda_ultrapassagem_pta: 0,
        demanda_ultrapassagem_fp: 0,
        adicional_demanda: 0,
        adicional_demanda_fp: 0,
        adicional_demanda_pta: 0,
      };
    }

    // Agregar valores de acordo com o tipo de desperdício
    switch (item.referente) {
      case 'Demanda EXC. Reativa PTA':
        aggregatedData[month].demanda_exc_reativa_pta +=
          item.referente_total_value;
        break;
      case 'Demanda EXC. Reativa FP':
        aggregatedData[month].demanda_exc_reativa_fp +=
          item.referente_total_value;
        break;
      case 'Demanda isenta':
        aggregatedData[month].demanda_isenta += item.referente_total_value;
        break;
      case 'Consumo Exc. Reativo PTA':
        aggregatedData[month].consumo_exc_reativo_pta +=
          item.referente_total_value;
        break;
      case 'Consumo Exc. Reativo FP':
        aggregatedData[month].consumo_exc_reativo_fp +=
          item.referente_total_value;
        break;
      case 'Demanda Ultrap. PTA':
        aggregatedData[month].demanda_ultrapassagem_pta +=
          item.referente_total_value;
        break;
      case 'Demanda Ultrap. FP':
        aggregatedData[month].demanda_ultrapassagem_fp +=
          item.referente_total_value;
        break;
      case 'Adicional de demanda':
        aggregatedData[month].adicional_demanda += item.referente_total_value;
        break;
      case 'Adicional de demanda PTA':
        aggregatedData[month].adicional_demanda_pta +=
          item.referente_total_value;
        break;
      case 'Adicional de demanda FP':
        aggregatedData[month].adicional_demanda_fp +=
          item.referente_total_value;
        break;
      default:
        break;
    }
  });

  // Ordenar por mês/ano
  const aggregatedArray = Object.values(aggregatedData).sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);
    return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
  });

  return aggregatedArray;
}

function calculateTotalDesperdiciosByMonth(data, modalidade) {
  const totalByMonth = {};

  data.forEach((item) => {
    const month = item.reference_month;
    if (!totalByMonth[month]) {
      totalByMonth[month] = 0;
    }

    if (modalidade === 'VERDE') {
      totalByMonth[month] +=
        item.demanda_exc_reativa +
        item.demanda_nao_consumida +
        item.demanda_nao_utilizada +
        item.demanda_ultrapassagem +
        item.adicional_demanda +
        item.adicional_demanda_pta +
        item.adicional_demanda_fp +
        item.consumo_exc_reativo_pta +
        item.demanda_isenta;
    } else if (modalidade === 'AZUL') {
      totalByMonth[month] +=
        item.demanda_exc_reativa_pta +
        item.demanda_exc_reativa_fp +
        item.consumo_exc_reativo_pta +
        item.consumo_exc_reativo_fp +
        item.demanda_ultrapassagem_pta +
        item.demanda_ultrapassagem_fp +
        item.adicional_demanda +
        item.adicional_demanda_pta +
        item.adicional_demanda_fp +
        item.demanda_isenta;
    }
  });

  return totalByMonth;
}

// Componente GraficoDesperdicios
export const GraficoDesperdicios = ({ data, unidadeConsumidora }) => {
  const [formattedDesperdicios, setFormattedDesperdicios] = useState([]);
  const uc = unidadeConsumidora ? unidadeConsumidora[0] : null;


  useEffect(() => {
    if (data && data.length > 0) {
      if (data[0].modalidade === 'VERDE') {
        setFormattedDesperdicios(aggregateGreenDesperdiciosByMonth(data));
      } else if (data[0].modalidade === 'AZUL') {
        setFormattedDesperdicios(aggregateBlueDesperdiciosByMonth(data));
      }
    }
  }, [data]);

  const months = formattedDesperdicios.map((item) => item.reference_month);

  // VERDE
  const demandaExcReativa = formattedDesperdicios.map(
    (item) => item.demanda_exc_reativa
  );
  const demandaNaoConsumida = formattedDesperdicios.map(
    (item) => item.demanda_nao_consumida
  );
  const demandaUltrapassagem = formattedDesperdicios.map(
    (item) => item.demanda_ultrapassagem
  );
  // const demandaAdicional = formattedDesperdicios.map(
  //   (item) => item.demanda_isenta
  // );

  // AZUL
  const demandaExcReativaPTA = formattedDesperdicios.map(
    (item) => item.demanda_exc_reativa_pta
  );
  const demandaExcReativaFP = formattedDesperdicios.map(
    (item) => item.demanda_exc_reativa_fp
  );
  const consumoExcReativaPTA = formattedDesperdicios.map(
    (item) => item.consumo_exc_reativo_pta
  );
  const consumoExcReativaFP = formattedDesperdicios.map(
    (item) => item.consumo_exc_reativo_fp
  );
  const demandaUltrapassagemPTA = formattedDesperdicios.map(
    (item) => item.demanda_ultrapassagem_pta
  );
  const demandaUltrapassagemFP = formattedDesperdicios.map(
    (item) => item.demanda_ultrapassagem_fp
  );
  const adicionalDemanda = formattedDesperdicios.map(
    (item) => item.adicional_demanda
  );
  const adicionalDemandaFP = formattedDesperdicios.map(
    (item) => item.adicional_demanda_fp
  );
  const adicionalDemandaPTA = formattedDesperdicios.map(
    (item) => item.adicional_demanda_pta
  );

  const totalDesperdiciosMes = calculateTotalDesperdiciosByMonth(
    formattedDesperdicios,
    uc?.modalidade
  );

  const series = [];

  if (uc?.modalidade === 'VERDE') {
    // Adicionar apenas séries que não tenham todos os valores zerados
    if (demandaExcReativa.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda Exc. Reativa',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaExcReativa,
      });
    }

    if (demandaNaoConsumida.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda Não Consumida',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaNaoConsumida,
      });
    }

    // if (demandaNaoUtilizada.some((value) => value !== 0)) {
    //   series.push({
    //     name: 'Adicional de demanda',
    //     type: 'bar',
    //     stack: 'total',
    //     label: {
    //       show: true,
    //       formatter: function (params) {
    //         return params.value !== 0 ? formatReal(params.value) : '';
    //       },
    //     },
    //     emphasis: {
    //       focus: 'series',
    //     },
    //     data: demandaNaoUtilizada,
    //   });
    // }

    if (demandaUltrapassagem.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda Ultrapassagem',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaUltrapassagem,
      });
    }

    // if (demandaAdicional.some((value) => value !== 0)) {
    //   series.push({
    //     name: 'Adicional de Demanda',
    //     type: 'bar',
    //     stack: 'total',
    //     label: {
    //       show: true,
    //       formatter: function (params) {
    //         return params.value !== 0 ? formatReal(params.value) : '';
    //       },
    //     },
    //     emphasis: {
    //       focus: 'series',
    //     },
    //     data: demandaAdicional,
    //   });
    // }
    if (adicionalDemanda.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemanda,
      });
    }
    if (adicionalDemandaPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemandaPTA,
      });
    }
    if (adicionalDemandaFP.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemandaFP,
      });
    }
    if (consumoExcReativaPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Consumo Exc. Reativo PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: consumoExcReativaPTA,
      });
    }
    if (consumoExcReativaFP.some((value) => value !== 0)) {
      series.push({
        name: 'Consumo Exc. Reativo FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: consumoExcReativaFP,
      });
    }
  }

  if (uc?.modalidade === 'AZUL') {
    // Adicionar apenas séries que não tenham todos os valores zerados
    if (demandaExcReativaPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda EXC. Reativa PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaExcReativaPTA,
      });
    }

    if (demandaExcReativaFP.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda EXC. Reativa FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaExcReativaFP,
      });
    }

    if (consumoExcReativaPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Consumo Exc. Reativo PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: consumoExcReativaPTA,
      });
    }

    if (consumoExcReativaFP.some((value) => value !== 0)) {
      series.push({
        name: 'Consumo Exc. Reativo FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: consumoExcReativaFP,
      });
    }

    if (demandaUltrapassagemPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda Ultrap. PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaUltrapassagemPTA,
      });
    }

    if (demandaUltrapassagemFP.some((value) => value !== 0)) {
      series.push({
        name: 'Demanda Ultrap. FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: demandaUltrapassagemFP,
      });
    }

    if (adicionalDemanda.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemanda,
      });
    }
    if (adicionalDemandaPTA.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda PTA',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemandaPTA,
      });
    }
    if (adicionalDemandaFP.some((value) => value !== 0)) {
      series.push({
        name: 'Adicional de Demanda FP',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return params.value !== 0 ? formatReal(params.value) : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: adicionalDemandaFP,
      });
    }
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params
          .filter((param) => param.value !== undefined)
          .map((param) => {
            const formattedValue =
              typeof param.value === 'string'
                ? parseFloat(param.value)?.toLocaleString('pt-BR')
                : param.value?.toLocaleString('pt-BR');

            return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
          });

        const xAxisValue = params[0]?.axisValueLabel;
        const totalValue = totalDesperdiciosMes[xAxisValue] || 0;
        const formattedTotalValue = formatReal(totalValue);

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);
        formattedParams.push(`<strong>Total:</strong> ${formattedTotalValue ? formattedTotalValue : '0'}`);

        return formattedParams.length > 1 ? formattedParams.join('<br>') : 'Sem dados';
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: months,
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          return formatReal(value);
        },
      },
    },
    series: series,
  };

  return (
    <div>
      {formattedDesperdicios && formattedDesperdicios.length > 0 && (
        <div style={{
          width: '100%',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'end'
        }}>
          <IoseButton2
            onClick={() =>
              xlsxReports.exportResumoGraficoDesperdicios(formattedDesperdicios, uc.identification_number)}
            width="80px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      )}

      <div>
        {formattedDesperdicios && formattedDesperdicios.length > 0 ? (
          <ReactECharts option={option} style={{ height: 475 }} />
        ) : (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
            height="300px"
            width="280px"
          />
        )}
      </div>
    </div>
  );
};
