import { useState, useContext, useCallback } from 'react';
import { ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useAlarmsDisplayed() {
  const [noAlarmsError, setNoAlarmsError] = useState('');
  const { getSession } = useContext(AccountContext);

  const getAlarmsDisplayed = useCallback(async () => {
    try {
      const { headers } = await getSession();

      let allData = [];
      let lastEvaluatedKey = null;
      const limit = 10000;

      do {
        const response = await ioseAPI.getAllAlarmsDisplayed(
          headers,
          lastEvaluatedKey,
          limit
        );

        const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

        allData = allData.concat(data);
        lastEvaluatedKey = newLastEvaluatedKey;
      } while (lastEvaluatedKey);

      if (allData.length === 0) {
            setNoAlarmsError('Nenhum alarme visualizado encontrado.');
             throw new Error('Nenhum alarme visualizado encontrado.');
      } else {
        const alarms = allData

        const AlarmsDisplayed = allData?.filter(item => item.status === 'true');
        const AlarmsNotDisplayed = allData?.filter(item => item.status === 'false');

        return { alarms, AlarmsDisplayed, AlarmsNotDisplayed}
      }

    } catch (error) {
      console.error(error);
      setNoAlarmsError('Erro ao buscar objetos, recarregue a página.')
    }
  }, [ getSession ]);

  const {
    data: { alarms, AlarmsDisplayed, AlarmsNotDisplayed} = {},
    error: alarmsDisplayedError,
    refetch: refreshAlarms,
    isLoading,
  } = useQuery(['alarmsDisplayed'], getAlarmsDisplayed);


  return {
    alarms: alarms,
    alarmsDisplayed: AlarmsDisplayed?.length,
    alarmsNotDisplayed: AlarmsNotDisplayed,
    alarmsDisplayedError,
    noAlarmsError,
    refreshAlarms,
    isLoading,
  };
}

export default useAlarmsDisplayed;
