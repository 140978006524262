import React, { useEffect, useState } from 'react';

import { useClient, LocalStorage } from 'common';

import { WrappedContainer } from './style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { IoseButton, IoseSubHeaderBar, LoadingIndicator } from 'components';

import { useGenerarionAccumulated, useCreateLogs } from 'hooks';
import { SaneparCreditBeneficiaryTable } from 'components/IoseTables';

import { IoseAuditRedirectButton } from 'components/IoseButtonIcon';

import energyBillSaneparReport from 'reports/EnergyBills/energyBillSaneparReport';

export default function SaneparCreditBeneficiarioContainer() {
  const { client } = useClient();
  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [loading] = useState(false);

  const { createLog } = useCreateLogs();

  const location = useLocation();
  const { rowData, hasReportPermission } = location.state;

  const {
    getGenerarionAccumulatedData,
    refreshgetGenerarionAccumulated,
  } = useGenerarionAccumulated();

  const ucGeradora = rowData?.uc_geradora;
  const creditoBeneficiario = getGenerarionAccumulatedData
    ? getGenerarionAccumulatedData
    : [];

  const filteredData = creditoBeneficiario.filter(
    (item) => item.uc_geradora === ucGeradora
  );

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  useEffect(() => {
    refreshgetGenerarionAccumulated();
    getClientData();
    //eslint-disable-next-line
  }, []);

  const history = useHistory();

  const handleCredit = () => {
    history.push('/saneparcredit');
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle={`Créditos acumulados por mês da Unidade ${ucGeradora}`}
        button={<></>}
        style={{ marginTop: '10px' }} // Ajuste o valor conforme necessário
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseAuditRedirectButton
            tooltip="Voltar para Unidades Geradoras"
            bottom="5px"
            onClick={handleCredit}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const FilterWrappedContainer = styled.div`
    display: flex;
    justify-content: flex-end; /* Align to the right */
    align-items: center;
    padding: 1px;
    background-color: #ecf0f5;
    border-radius: 5px;
    margin-bottom: 5px;
    /* Add any other styles you want */
  `;

  const handleBillReport = async (filteredData) => {
    energyBillSaneparReport(filteredData);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CRÉDITOS ACUMULADOS';
    const logContent = {};

    await createLog(operacao, form, logContent);

    return;
  };

  const renderFilters = () => {
    const isReportButtonDisabled = filteredData.length === 0;
    return (
      <FilterWrappedContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {hasReportPermission ? (
            <IoseButton
              onClick={() => handleBillReport(filteredData)}
              disabled={isReportButtonDisabled}
            >
              EMITIR RELATÓRIO
            </IoseButton>
          ) : null}
          {isReportButtonDisabled && (
            <p
              style={{
                fontSize: '12px',
                color: 'red',
                marginTop: '5px',
                marginBottom: '0',
              }}
            >
              Nenhum dado disponível para relatório.
            </p>
          )}
        </div>
      </FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <SaneparCreditBeneficiaryTable
          data={filteredData}
          hasReportPermission={hasReportPermission}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>
              {renderFilters()}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </WrappedContainer>
  );
}
