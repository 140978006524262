import styled from 'styled-components';

//Import from Iose Components
import IoseSideCard from 'components/IoseSideCard';

import { ListItemText } from '@material-ui/core';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;
  height: 90%;
`;

export const Header = styled.div`
  color: var(--base);

  h3 {
    font-size: 13px;
    font-weight: bolder;
  }
`;

export const ListContainer = styled.div`
  margin-top: 20px;
  height: 100%;

  p {
    color: var(--base);
  }

  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiTypography-root {
    color: var(--base) !important;
    font-family: 'Montserrat';
    font-weight: normal;
  }

  .MuiListItemText-primary {
    font-size: 13px;
  }
  .MuiListItemText-secondary {
    font-size: 12.5px;
    text-align: left;
  }

  @media (max-width: 500px) {
    .MuiListItemText-primary {
      font-size: 13px;
    }
    .MuiListItemText-secondary {
      font-size: 12.5px;
      padding-left: 0px;
      margin-top: 0px;
    }
  }
`;

// export const ListStyled = styled(List)`
/* height: 250px;
  margin-bottom: 20px;
  overflow-y: scroll;

  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
/* -ms-overflow-style: 1px; IE 10+ */

/* &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  } */
// `;
