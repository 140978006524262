/* eslint-disable camelcase */
/* eslint-disable no-useless-concat */
import 'ol/ol.css';

import { Style, Stroke, Circle as CircleStyle, Fill } from 'ol/style';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';

import 'ol-ext/dist/ol-ext.css';

export function getLayerStyle(baseLayerTitle, isChecked) {
  const baseStyle = new Style({
    fill: new Fill({
      color: isChecked ? 'rgba(227, 221, 159, 0.6)' : 'rgba(77, 255, 0, 0.3)',
    }),
    stroke: new Stroke({
      color: isChecked ? 'rgba(255, 129, 0, 0.6)' : '#000000',
      width: 1.5,
    }),
    zIndex: isChecked ? 1 : 0,
  });

  const circleStyle = new Style({
    image: new CircleStyle({
      radius: 4.5,
      fill: new Fill({
        color: isChecked ? '#e3dd9f' : '#4dff00',
      }),
      stroke: new Stroke({
        color: isChecked ? 'rgba(255, 129, 0, 0.6)' : '#000000',
        width: 1.5,
      }),
    }),
    zIndex: isChecked ? 1 : 0,
  });

  const specialLayers = [
    'Telemetria',
    'Cocel',
    'Cpfl',
    'Ceral',
    'Celesc',
    'Energisa',
    'Copel',
    'Forcel',
    'Cerpa',
    'credito',
  ];

  const otherLayers = [
    'Telemetria',
    'Cocel',
    'Cpfl',
    'Ceral',
    'Celesc',
    'Energisa',
    'Copel',
    'Forcel',
    'Cerpa',
    'credito',
  ];

  if (specialLayers.includes(baseLayerTitle)) {
    return circleStyle;
  } else if (baseLayerTitle === 'Orgão pagador') {
    return baseStyle;
  } else if (
    baseLayerTitle.startsWith('gg') ||
    baseLayerTitle.startsWith('gr') ||
    otherLayers.includes(baseLayerTitle)
  ) {
    return baseStyle;
  }

  return null;
}
