import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const DivSearch = styled.div`
  position: relative;
  margin: auto, 0px, auto, 50px;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: var(--secondary);

  &:hover {
    .MuiInputBase-root {
      width: 100%;
      opacity: 100%;
    }

    svg {
      color: var(--secondary);
    }
  }

  .Mui-focused {
    width: 100%;
    opacity: ${(props) => (props.sidebar === 'true' ? '70%' : '100%')};
  }
`;

export const InputBaseStyled = styled(InputBase)`
  width: 50%;
  opacity: 80%;
  height: 30px;
  background: #ecf0f5;
  border-radius: 15px;
  padding-left: 10px;
  transition: all 0.3s linear;
  color: black;
  margin-left: 50px;

  @media (max-width: 500px) {
    width: 100%;
    opacity: 100%;
  }
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  color: var(--primary);
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const TableBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const TableTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  h3 {
    color: var(--primary);
  }
`;

export const TableHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
`;
