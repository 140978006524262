import React from "react";
import * as S from '../style'
import { IoseAlertNoExtract } from "components/IoseAlert";
import BillsModalAclSubHeader from "./bills-modal-acl-sub-header";
import BillsModalAclContentHeader from "./bills-modal-acl-content-header";
import BillsModalAclTable from "./bills-modal-acl-table";

export default function BillsModalAclContent({ faturasAcl }) {

  if (faturasAcl) {
    return (
      <div>
        <BillsModalAclSubHeader faturasAcl={faturasAcl}/>
        <S.Container>
          <div style={{ width: '96vw' }}>
            <div>
              <BillsModalAclContentHeader faturasAcl={faturasAcl} />
              <BillsModalAclTable faturasAcl={faturasAcl}/>
            </div>
          </div>
        </S.Container>
      </div>
    );
  } else {
    return (
      <div style={{marginTop: '250px'}}>
        <IoseAlertNoExtract text="Esta UC não possui faturas ACL."/>;
      </div>
    );
  }
}