import React, { useState } from 'react';
import PropType from 'prop-types';

//Imports from IOSE Components
import {
  IoseEditButton,
  IoseBackButton,
  IoseAdminButton,
  IoseIDButton,
  IoseAddUserButton,
  IoseAddGroupButton,
} from 'components/IoseButtonIcon';

import { IoseDeleteInputModal } from 'components/Modals/IoseModals';

//Imports from Material Ui Components
import { Grow, Tooltip } from '@material-ui/core';

//Imports components from Style
import {
  CardStyled,
  ClientIcon,
  AvatarStyled,
  CardContentStyled,
  DivButtons,
  CardHeaderStyled,
  DivHeaderButton,
} from './style';

/**
 *This is the Client Card show all information about client and allows editing/deleting client, see list of adms and uuid client.
 */
export default function IoseCardClient(props) {
  const {
    client_description,
    client_name,
    client_uuid,
    identification,
    onClickCard,
    onClickDelet,
    onClickEdit,
    onClickAdmin,
    onClickAddUser,
    onClickGroups,
  } = props;

  const [showID, setshowID] = useState(false);

  const renderButtonsCard = () => {
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <DivButtons>
          <IoseDeleteInputModal
            title="DELETAR CLIENTE"
            name={client_name}
            deletefunc={onClickDelet}
            param={client_uuid}
            cardbutton={'true'}
            primarybutton={'true'}
          />
          <IoseEditButton
            primary="true"
            card="true"
            tooltip="Editar Cliente"
            onClick={onClickEdit}
          />
          <IoseAdminButton
            primary="true"
            card="true"
            tooltip="Visualizar Administradores"
            onClick={(e) => onClickAdmin()}
          />
          <IoseIDButton
            primary="true"
            card="true"
            tooltip="Identificador"
            onClick={(e) => setshowID(true)}
          />

          <IoseAddUserButton
            primary="true"
            card="true"
            tooltip="Usuários"
            onClick={onClickAddUser}
          />

          <IoseAddGroupButton
            primary="true"
            card="true"
            tooltip="Grupos de Usuários"
            onClick={onClickGroups}
          />
        </DivButtons>
      </Grow>
    );
  };

  const renderShowID = () => {
    return (
      <DivButtons justify={'space-around'}>
        <IoseBackButton onClick={(e) => setshowID(false)} />
        <Tooltip title={identification} placement="bottom" arrow>
          <p>{identification}</p>
        </Tooltip>
      </DivButtons>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={client_description} placement="bottom" arrow>
      <p>{client_description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={client_name} placement="bottom" arrow>
      <p>{client_name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <CardStyled>
        <DivHeaderButton disableSpacing>
          <CardHeaderStyled
            avatar={
              <AvatarStyled>
                <ClientIcon />
              </AvatarStyled>
            }
            title={renderTitle()}
            subheader={renderSubtitle()}
            onClick={onClickCard}
          />
        </DivHeaderButton>

        <CardContentStyled>
          {showID ? renderShowID() : renderButtonsCard()}
        </CardContentStyled>
      </CardStyled>
    </Grow>
  );
}

IoseCardClient.propTypes = {
  /** This prop get the name of client */
  name_company: PropType.string,

  /** This prop get the description of client */
  client_description: PropType.string,

  /** This prop get the function when click on the card */
  onClickCard: PropType.func,

  /** This prop get the function when click on the button delete */
  onClickDelet: PropType.func,

  /** This prop get the function when click on the button edit */
  onClickEdit: PropType.func,

  /** This prop get the uuid of client in DB */
  client_uuid: PropType.string,

  /** This prop get the uuid of client of factory (MAC) */
  identification: PropType.string,

  /** This prop get the function when click on the button admin to show list admins*/
  onClickAdmin: PropType.func,
};
