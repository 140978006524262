import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grow, Typography } from '@material-ui/core';

import * as S from './style';

import {
  IoseTextField,
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseModalButton,
} from 'components';

import { Messages } from 'common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '300px',
    width: '600px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseConcessionaireDeleteModal({
  open,
  onClose,
  loading,
  notificationType,
  deleteNotification,
  showNotification,
  submit,
  selectedCard,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);

  const handleConfirmation = () => {
    if (confirmation === selectedCard?.name) {
      submit(selectedCard.uuid_concessionaire);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
        setConfirmation('');
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                CONCESSIONÁRIAS
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom marginBottom="35px">
                DELETAR CONCESSIONÁRIA
              </S.ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar <b>{selectedCard?.name}</b>?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite <b>{selectedCard?.name}</b> logo abaixo e
                aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <S.DivCircularProgress>
                  <S.CircularProgressStyled />
                </S.DivCircularProgress>
              ) : showNotification ? (
                <>
                  {notificationType === 1 && (
                    <IoseAlertSuccessModal text={deleteNotification} />
                  )}
                  {notificationType === 2 && (
                    <IoseAlertErroModal text={deleteNotification} />
                  )}
                  {showError && (
                    <IoseAlertErroModal
                      text={Messages.confirmationTextIncorrect}
                    />
                  )}
                </>
              ) : showError ? (
                <IoseAlertErroModal text="Texto de confirmação incorreto." />
              ) : (
                <S.ButtonContainer>
                  <IoseModalButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    theme="white"
                    onClick={handleConfirmation}
                  >
                    Deletar
                  </IoseModalButton>
                </S.ButtonContainer>
              )}
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
