import React, { useCallback, useEffect, useState } from 'react';
import { TablePagination, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import * as S from './style';
import { IoseButton2 } from 'components';
import EqualizerIcon from '@material-ui/icons/Equalizer';

const formatNumber = (number) => number?.toLocaleString();

function convertStringToDecimalStyle(str = '0') {
  const number = parseFloat(str.replace(',', '.'));

  return number.toLocaleString('pt-BR');
}

const TableHeaderCell = ({
  label,
  sortBy,
  sortDirection,
  handleSort,
  column,
}) => (
  <S.TableHeaderCell onClick={() => handleSort(column)}>
    {label} {sortBy === column && (sortDirection === 'asc' ? '🔼' : '🔽')}
  </S.TableHeaderCell>
);

const TableRowCell = ({ children, onCellClick, isUcColumn }) => (
  <S.TableCell onClick={isUcColumn ? onCellClick : undefined}>
    {children}
  </S.TableCell>
);

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  handleSort,
  sortBy,
  sortDirection,
}) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <IoseInfinityScroll
      dataLength={20}
      next={() => {}}
      hasMore={false}
      loading={false}
      endList={false}
      sidebar={false}
      marginTop="0"
      padding="0"
      colortextend={null}
      sizetextend={null}
      scrollableTarget="scrollableDiv"
      height={350}
    >
      <S.Table>
        <S.TableHeader>
          <tr>
            {[
              { label: 'UC', column: 'identification_number' },
              { label: 'MODALIDADE', column: 'modalidade' },
              { label: 'ATIVIDADE', column: 'atividade' },
              { label: 'CIDADE', column: 'city' },
              { label: 'GG', column: 'general_management' },
              { label: 'GR', column: 'regional_management' },
              { label: 'CONS. PTA (kWh)', column: 'total_consumo_pta' },
              { label: 'CONS. FP (kWh)', column: 'total_consumo_fp' },
              { label: '% kWh (P / F.P.)', column: 'percentual_pta_fp' },
            ].map(({ label, column }) => (
              <TableHeaderCell
                key={column}
                label={label}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSort={handleSort}
                column={column}
              />
            ))}
          </tr>
        </S.TableHeader>
        <tbody>
          {pageData.map((value, index) => (
            <S.TableRow key={index}>
              <TableRowCell
                onCellClick={() =>
                  onRowClick(
                    value?.identification_number,
                    value?.numero_telemetria
                  )
                }
                isUcColumn={true}
              >
                {value.identification_number?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>{value.modalidade?.toUpperCase()}</TableRowCell>
              <TableRowCell>{value.atividade?.toUpperCase()}</TableRowCell>
              <TableRowCell>{value.city?.toUpperCase()}</TableRowCell>
              <TableRowCell>
                {value.general_management?.toUpperCase()}
              </TableRowCell>
              <TableRowCell>{value.regional_management}</TableRowCell>
              <TableRowCell>
                {formatNumber(value.total_consumo_pta)}
              </TableRowCell>
              <TableRowCell>
                {formatNumber(value.total_consumo_fp)}
              </TableRowCell>
              <TableRowCell>
                {convertStringToDecimalStyle(formatNumber(value.percentual_pta_fp))}
              </TableRowCell>
            </S.TableRow>
          ))}
        </tbody>
      </S.Table>
    </IoseInfinityScroll>
  );
};

export default function EnergyBillsExtractTable({
  data = [],
  loading,
  onFilteredDataChange,
}) {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [updatedData, setUpdatedData] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [cityFilter, setCityFilter] = useState('');
  const [ggFilter, setGgFilter] = useState('');
  const [grFilter, setGrFilter] = useState('');
  const [modalidadeFilter, setmodalidadeFilter] = useState('');
  const [ucFilter, setUcFilter] = useState('');
  const [atividadeFilter, setAtividadeFilter] = useState('');
  const [activeFilters, setActiveFilters] = useState();

  function redirectToConsumerGrouAnalysisDash(consumerGroupsArray) {
    history.push({
      pathname: '/analysis-dash',
      state: {
        consumerGroupsArray,
        activeFilters
      },
    });
  }

  const handleFilteredDataChange = useCallback((data) => {
    onFilteredDataChange(data);
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const sortedData = sortData(data, sortBy, sortDirection);
      setUpdatedData(sortedData);
    }
  }, [data, sortBy, sortDirection]);

  useEffect(() => {
    let filteredData = data;

    const filters = [
      { key: 'city', value: cityFilter, filterName: 'Cidade'},
      { key: 'general_management', value: ggFilter, filterName: 'GG'},
      { key: 'regional_management', value: grFilter, filterName: 'GR'},
      { key: 'modalidade', value: modalidadeFilter, filterName: 'Modalidade'},
      { key: 'atividade', value: atividadeFilter, filterName: 'Atividade'},
      { key: 'identification_number', value: ucFilter, filterName: 'UC'},
    ];

    const activeFilters = {};

    filters.forEach(filter => {
      if (filter.value) {
        filteredData = filteredData.filter(item =>
          item[filter.key]?.toLowerCase().includes(filter.value.toLowerCase())
        );
        activeFilters[filter.filterName] = filter.value;
      }
    });

    setUpdatedData(sortData(filteredData, sortBy, sortDirection));
    handleFilteredDataChange(filteredData);
    setActiveFilters(activeFilters)
  }, [
    cityFilter,
    ggFilter,
    grFilter,
    modalidadeFilter,
    ucFilter,
    atividadeFilter,
    data,
    sortBy,
    sortDirection,
    handleFilteredDataChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const handleRowClick = (identificationNumber, uuidCircuit) => {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: { consumerGroup: identificationNumber, uuidCircuit: uuidCircuit },
    });
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator loading={loading} />;
    }

    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', gap: '5px'}}>
            <S.InputBaseStyled
              placeholder="U.C"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setUcFilter(e.target.value)}
            />
            <S.InputBaseStyled
              placeholder="Modalidade"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setmodalidadeFilter(e.target.value)}
            />
            <S.InputBaseStyled
              placeholder="Atividade"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setAtividadeFilter(e.target.value)}
            />
            <S.InputBaseStyled
              placeholder="Cidade"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setCityFilter(e.target.value)}
            />
            <S.InputBaseStyled
              placeholder="GG"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setGgFilter(e.target.value)}
            />
            <S.InputBaseStyled
              placeholder="GR"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => setGrFilter(e.target.value)}
            />
          </div>
          <div>
          <Tooltip title="IR PARA DASHBOARD DE ANALISE DAS UCs FILTRADAS" placement="bottom" arrow>
            <IoseButton2
              onClick={() => redirectToConsumerGrouAnalysisDash(updatedData)}
              width="100px"
            >
              <span style={{ marginRight: '5px' }}>ANÁLISE</span>
              <EqualizerIcon />
            </IoseButton2>
            </Tooltip>
          </div>
        </div>

        {updatedData.length === 0 ? (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="250px"
            width="250px"
          />
        ) : (
          <>
            <div style={{marginTop: '10px'}}>
              <CircuitsTable
                data={updatedData}
                onRowClick={handleRowClick}
                page={page}
                rowsPerPage={rowsPerPage}
                handleSort={handleSort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onFilteredDataChange={handleFilteredDataChange}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {renderContent()}

      <TablePagination
        component="div"
        count={updatedData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Itens por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
        backIconButtonText="Página anterior"
        nextIconButtonText="Próxima página"
      />
    </>
  );
}

const sortData = (data, sortBy, sortDirection) => {
  return data.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
