/* eslint-disable camelcase */
/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as LayerGroup,
} from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import {
  MousePosition,
  OverviewMap,
  Rotate,
  ScaleLine,
  Zoom,
  FullScreen,
  Attribution,
  ZoomToExtent,
} from 'ol/control';
import { format as formatCoordinate } from 'ol/coordinate';

import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import {
  Style,
  Stroke,
  Circle as CircleStyle,
  Fill,
  Circle,
  Text,
} from 'ol/style';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import { fetchAlarms } from './fetchAlarms.js';
import { createPopup } from './pop-up';

import 'ol-ext/dist/ol-ext.css';
import SearchPhoton from 'ol-ext/control/SearchPhoton';
import { useHistory } from 'react-router-dom';

import { useSystemUrls } from 'hooks';

import { getLayerStyle } from './dropdown_menu_telemetria';
import {
  getTotalTele,
  getTotalFeatures,
  getLastMeasureDate,
  getTotalFeaturesregiao,
  getMedindo,
} from './obtendoTotaisFn';
import { fetchQuantiadePorGG, fetchQuantidadePorGR } from './quantidades-gg-gr';
import { coresPontos, coresPontos2 } from './cor-pontos-legenda';

// Import from Map.ccs
import './map2.css';

export default function MapTelemetryComponent() {
  const [open, setOpen] = useState(true);

  const [totalTele, setTotalTele] = useState(0);

  let [totalSemMedicao, setTotalSemMedicao] = useState(0);

  let [totalQueda, setTotalQueda] = useState(0);
  let [totalUltrapDemanda, setTotalUltrapDemanda] = useState(0);
  let [totalForaPonta, setTotalForaPonta] = useState(0);
  let [totalLastMeasureDate, setTotalLastMeasureDate] = useState(0);
  let [totalMedindo, setTotalMedindo] = useState(0);

  const { systemUrls } = useSystemUrls();

  const comunica =
    systemUrls?.find((item) => item.attribute === 'map_url_telemetria_comunica')
      ?.url || null;

  const telemetria_pontos =
    systemUrls?.find((item) => item.attribute === 'map_url_total_telemetria')
      ?.url || null;

  const alarmes =
    systemUrls?.find((item) => item.attribute === 'map_url_alarms_telemetry_3')
      ?.url || null;

  const medindo =
    systemUrls?.find((item) => item.attribute === 'map_view_medindo_2')?.url ||
    null;

  const urlOrgaoGr =
    systemUrls?.find((item) => item?.attribute === 'map_description')?.url ||
    '';
  const regiaoGg =
    systemUrls?.find((item) => item?.attribute === 'map_description')?.url ||
    '';

  const qtdGg =
    systemUrls?.find((item) => item?.attribute === 'map_qtd_gg')?.url || '';

  const qtdGr =
    systemUrls?.find((item) => item?.attribute === 'map_qtd_gr')?.url || '';

  const semComunicacao =
    systemUrls?.find((item) => item?.attribute === 'map_url_sem_comunicacao')
      ?.url || '';
  const confiabilidadePacotes =
    systemUrls?.find(
      (item) => item?.attribute === 'map_url_confiabilidade_pacote'
    )?.url || '';

  const [totalGGML, setTotalGGML] = useState(0);
  const [totalGGND, setTotalGGND] = useState(0);
  const [totalGGNO, setTotalGGNO] = useState(0);
  const [totalGGSD, setTotalGGSD] = useState(0);
  const [totalGGSO, setTotalGGSO] = useState(0);

  useEffect(() => {
    fetchQuantiadePorGG(
      qtdGg,
      setTotalGGML,
      setTotalGGND,
      setTotalGGNO,
      setTotalGGSD,
      setTotalGGSO
    );
  }, [qtdGg]);

  const [totalGDOP, setTotalGDOP] = useState(0);
  const [totalGESA, setTotalGESA] = useState(0);
  const [totalGGPINF, setTotalGGPINF] = useState(0);
  const [totalGIDLD, setTotalGIDLD] = useState(0);
  const [totalGPDAGC, setTotalGPDAGC] = useState(0);
  const [totalGRAP, setTotalGRAP] = useState(0);
  const [totalGRAR, setTotalGRAR] = useState(0);
  const [totalGRCA, setTotalGRCA] = useState(0);
  const [totalGRCM, setTotalGRCM] = useState(0);
  const [totalGRCP, setTotalGRCP] = useState(0);
  const [totalGRFB, setTotalGRFB] = useState(0);
  const [totalGRFI, setTotalGRFI] = useState(0);
  const [totalGRGA, setTotalGRGA] = useState(0);
  const [totalGRLI, setTotalGRLI] = useState(0);
  const [totalGRMA, setTotalGRMA] = useState(0);
  const [totalGRPB, setTotalGRPB] = useState(0);
  const [totalGRPG, setTotalGRPG] = useState(0);
  const [totalGRPV, setTotalGRPV] = useState(0);
  const [totalGRSP, setTotalGRSP] = useState(0);
  const [totalGRTB, setTotalGRTB] = useState(0);
  const [totalGRTO, setTotalGRTO] = useState(0);
  const [totalGRUM, setTotalGRUM] = useState(0);
  const [totalGRUV, setTotalGRUV] = useState(0);
  const [totalGTESG, setTotalGTESG] = useState(0);

  useEffect(() => {
    fetchQuantidadePorGR(
      qtdGr,
      setTotalGDOP,
      setTotalGESA,
      setTotalGGPINF,
      setTotalGIDLD,
      setTotalGPDAGC,
      setTotalGRAP,
      setTotalGRAR,
      setTotalGRCA,
      setTotalGRCM,
      setTotalGRCP,
      setTotalGRFB,
      setTotalGRFI,
      setTotalGRGA,
      setTotalGRLI,
      setTotalGRMA,
      setTotalGRPB,
      setTotalGRPG,
      setTotalGRPV,
      setTotalGRSP,
      setTotalGRTB,
      setTotalGRTO,
      setTotalGRUM,
      setTotalGRUV,
      setTotalGTESG
    );
  }, [qtdGr]);

  function generateFilteredUrlAlarms(filterName, filterValue) {
    const baseUrl = alarmes;
    let cqlFilter = '';

    if (filterName === 'last_date') {
      cqlFilter = `${filterName} IS NOT NULL`;
    } else if (filterValue) {
      cqlFilter = `${filterName}=${filterValue}`;
    }

    const separator = baseUrl?.includes('?') ? '&' : '?';
    return `${baseUrl}${separator}cql_filter=${encodeURIComponent(cqlFilter)}`;
  }

  const SemMedicao = generateFilteredUrlAlarms('sem_medicao', 'true');
  const quedaEnergia = generateFilteredUrlAlarms('queda_energia', 'true');
  const ultrapDemanda = generateFilteredUrlAlarms('ultrap_demanda', 'true');
  const lastMeasureDate = generateFilteredUrlAlarms('last_date');
  const HorarioPonta = generateFilteredUrlAlarms('horario_ponta', 'true');

  //filtrando os alarmes - fim

  function generateFilteredUrl4(camada2) {
    const typeNameParam = 'typeName=Sanepar%3A' + camada2;
    const encodedUrl = encodeURIComponent(regiaoGg + typeNameParam);
    return encodedUrl;
  }

  //essa é para o orgão -ibício
  function generateFilteredUrlOrgao(orgao) {
    const cqlFilter = `&cql_filter=nome=%27${orgao}%27`;
    const encodedCqlFilter = `${url_orgao_pagador}${encodeURIComponent(
      cqlFilter
    )}`;

    return encodedCqlFilter;
  }

  function generateFilteredUrl3(camada) {
    const typeNameParam = 'typeName=Sanepar%3A' + camada;
    const encodedUrl = encodeURIComponent(urlOrgaoGr + typeNameParam);
    return encodedUrl;
  }

  const url_orgao_pagador = decodeURIComponent(
    generateFilteredUrl3('orgao_pagador')
  );

  const url_regiao = decodeURIComponent(generateFilteredUrl4('regiao'));

  function generateFilteredUrlRegiao(regiao) {
    const cqlFilter = `&cql_filter=nome=%27${regiao}%27`;
    const encodedCqlFilter = `${url_regiao}&${encodeURIComponent(cqlFilter)}`;
    return encodedCqlFilter;
  }
  // url para mostrar os vetores da vm
  const urlGGML = decodeURIComponent(generateFilteredUrlRegiao('GGML'));
  const urlGGND = decodeURIComponent(generateFilteredUrlRegiao('GGND'));
  const urlGGNO = decodeURIComponent(generateFilteredUrlRegiao('GGNO'));
  const urlGGSD = decodeURIComponent(generateFilteredUrlRegiao('GGSD'));
  const urlGGSO = decodeURIComponent(generateFilteredUrlRegiao('GGSO'));

  const urlGRPV = decodeURIComponent(generateFilteredUrlOrgao('GRPV'));
  const urlGRAR = decodeURIComponent(generateFilteredUrlOrgao('GRAR'));
  const urlGRCP = decodeURIComponent(generateFilteredUrlOrgao('GRCP'));
  const urlGRMA = decodeURIComponent(generateFilteredUrlOrgao('GRMA'));
  const urlGRSP = decodeURIComponent(generateFilteredUrlOrgao('GRSP'));
  const urlGIDLD = decodeURIComponent(generateFilteredUrlOrgao('GIDLD'));
  const urlGRUM = decodeURIComponent(generateFilteredUrlOrgao('GRUM'));
  const urlGRAP = decodeURIComponent(generateFilteredUrlOrgao('GRAP'));
  const urlGRCM = decodeURIComponent(generateFilteredUrlOrgao('GRCM'));
  const urlGRTB = decodeURIComponent(generateFilteredUrlOrgao('GRTB'));
  const urlGRTO = decodeURIComponent(generateFilteredUrlOrgao('GRTO'));
  const urlGRGA = decodeURIComponent(generateFilteredUrlOrgao('GRGA'));
  const urlGPDAG = decodeURIComponent(generateFilteredUrlOrgao('GPDAG'));
  const urlGRCA = decodeURIComponent(generateFilteredUrlOrgao('GRCA'));
  const urlGRFI = decodeURIComponent(generateFilteredUrlOrgao('GRFI'));
  const urlGRPG = decodeURIComponent(generateFilteredUrlOrgao('GRPG'));
  const urlGRLI = decodeURIComponent(generateFilteredUrlOrgao('GRLI'));
  const urlGRFB = decodeURIComponent(generateFilteredUrlOrgao('GRFB'));
  const urlGRUV = decodeURIComponent(generateFilteredUrlOrgao('GRUV'));
  const urlGRPB = decodeURIComponent(generateFilteredUrlOrgao('GRPB'));
  const urlGDOP = decodeURIComponent(generateFilteredUrlOrgao('GDOP'));
  const urlGESA = decodeURIComponent(generateFilteredUrlOrgao('GESA'));
  const urlGGPINF = decodeURIComponent(generateFilteredUrlOrgao('GGPINF'));
  const urlGTESG = decodeURIComponent(generateFilteredUrlOrgao('GTESG'));

  const [totalAlarmes, setTotalAlarmes] = useState(0);
  const [totalAlarmes24h, setTotalAlarmes24h] = useState(0);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [orgaoSubMenuOpen, setOrgaoSubMenuOpen] = useState(false);
  const [telemetriaOpen, setTelemetriaOpen] = useState(false);
  // eslint-disable-next-line
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef();

  const history = useHistory();
  const mapRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    // remover o event listener
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setInputVisible(!inputVisible);
    setInputValue('');
  };

  const handleDocumentClick = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      !e.target.matches('#botao-telemetry')
    ) {
      setInputVisible(false);
      setInputValue('');
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();

    setInputVisible(false);
    setInputValue('');
  };

  const menuRef = useRef();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-51.834076, -24.69453]),
        zoom: 8,
      }),
      controls: [],
    });
    viewRef.current = map.getView();

    const rotateControl = new Rotate({
      tipLabel: 'Redefinir Norte (Shift + Alt para rotacionar)',
      autoHide: false,
    });
    map.addControl(rotateControl);

    const scaleLineControl = new ScaleLine();
    map.addControl(scaleLineControl);

    const zoomControl = new Zoom({
      zoomInTipLabel: 'Aproximar (Shift + Mouse)',
      zoomOutTipLabel: 'Afastar',
    });
    map.addControl(zoomControl);

    const attributionControl = new Attribution({
      collapsible: true,
    });
    map.addControl(attributionControl);

    const fullScreenControl = new FullScreen({
      tipLabel: 'Mudar para tela cheia',
    });
    map.addControl(fullScreenControl);

    const zoomSliderControl = new Zoom();
    map.addControl(zoomSliderControl);

    const mousePositionControl = new MousePosition({
      coordinateFormat(coordinate) {
        return `${formatCoordinate(coordinate, '{y}, {x}', 6)} (WGS 84)`;
      },
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('map'),
      undefinedHTML: '&nbsp;',
    });
    map.addControl(mousePositionControl);

    const zoomToExtentControl = new ZoomToExtent({
      tipLabel: 'Zoom para a extensão total do mapa',
      extent: map.getView().calculateExtent(map.getSize()),
      className: 'custom-zoom-to-extent-control',
    });

    map.getView().on('change:center', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent });
    });
    map.getView().on('change:resolution', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent });
    });

    map.addControl(zoomToExtentControl);

    const overviewMapControl = new OverviewMap({
      collapsed: false,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new ImageLayer({
          source: new ImageWMS({}),
        }),
      ],
    });
    map.addControl(overviewMapControl);

    //camadas - início
    const src_alarms = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: alarmes,
    });

    const alarms_telemetry = new VectorLayer({
      name: 'alarmes',
      title: 'Alarmes',
      source: src_alarms,
      visible: true,
      zIndex: 1,
      style: function (feature) {
        const Queda = feature?.get('queda_energia');
        const Medicao = feature?.get('sem_medicao');
        // const ultrap_demanda = feature?.get('ultrap_demanda');
        // const excesso_reativo = feature?.get('excesso_reativos');
        const update_at = feature?.get('update_at');

        let numAlarms = 0;
        if (Queda) numAlarms++;
        if (Medicao) numAlarms++;
        // if (ultrap_demanda) numAlarms++;
        // if(excesso_reativo) numAlarms++;

        let strokeColor = 'red';
        if (update_at) {
          const createdDate = new Date(update_at);
          const now = new Date();
          const timeDifference = now - createdDate;
          const hoursDifference = timeDifference / (1000 * 60 * 60);

          if (hoursDifference > 24) {
            strokeColor = 'gray';
          }
        }

        if (numAlarms > 0) {
          if (numAlarms > 1) {
            strokeColor = createGradient(numAlarms);
          }

          return new Style({
            image: new Circle({
              radius: 9,
              fill: new Fill({ color: 'rgba(0, 0, 0, 0)' }),
              stroke: new Stroke({ color: strokeColor, width: 2 }),
            }),
          });
        }

        return null;
      },
    });

    function createGradient(numAlarms) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const radius = 9;
      const gradient = context.createLinearGradient(0, -radius, 0, radius);
      for (let i = 0; i < numAlarms; i++) {
        gradient.addColorStop(i / numAlarms, getColorForAlarm(i));
      }
      return gradient;
    }

    //CRIAR STYLE PARA FEATURES DE ALARMES
    fetchAlarms(src_alarms, alarms_telemetry, createGradient);

    function getColorForAlarm(index) {
      const colors = [
        'yellow',
        'red',
        'blue',
        'green',
        'orange',
        'purple',
        'cyan',
      ];
      return colors[index % colors.length];
    }

    function createVectorLayer(name, title, url) {
      const source = new VectorSource({
        format: new GeoJSON(),
        attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
        url: url,
      });

      const layer = new VectorLayer({
        name: name,
        title: title,
        source: source,
        visible: false,
        zIndex: 1,
      });

      return layer;
    }
    const semMedicao_telemetry = createVectorLayer(
      'sem medicao',
      'semMedicao',
      SemMedicao
    );
    const ultrapassagem_demanda = createVectorLayer(
      'ultrapDemanda',
      'ultrapDemanda',
      ultrapDemanda
    );

    const queda_energia = createVectorLayer(
      'SemEnergia',
      'SemEnergia',
      quedaEnergia
    );

    const last_date = createVectorLayer(
      'lastMeasureDate',
      'lastMeasureDate',
      medindo
    );

    const src_telemetria_pontos = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: telemetria_pontos,
    });

    const telemetria_total = new VectorLayer({
      name: 'telemetriaPontos',
      title: 'telemetriaPontos',
      source: src_telemetria_pontos,
      visible: true,
      zIndex: 1,
    });

    const src_sem_comunicacao = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: semComunicacao,
    });

    const sem_comunicacao = new VectorLayer({
      name: 'semComunicacao',
      title: 'semComunicacao',
      source: src_sem_comunicacao,
      visible: true,
      zIndex: 1,
    });

    const src_confiabilidade_pacotes = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: confiabilidadePacotes,
    });

    const confiabilidade_pacotes = new VectorLayer({
      name: 'confiabilidade',
      title: 'confiabilidade',
      source: src_confiabilidade_pacotes,
      visible: false,
      zIndex: 1,
    });

    const src_medindo = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: medindo,
    });

    const medindo_telemetria = new VectorLayer({
      name: 'Medindo',
      title: 'Medindo',
      source: src_medindo,
      visible: true,
      zIndex: 1,
    });

    const src_sem_medicao = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: SemMedicao,
    });

    const sem_medicao_telemetry = new VectorLayer({
      name: 'SemMedicao',
      title: 'SemMedicao',
      source: src_sem_medicao,
      visible: true,
      zIndex: 1,
    });

    const redStyle = new Style({
      image: new Circle({
        radius: 4.5,
        fill: new Fill({ color: 'rgba(255, 55, 0, 1)' }),
        stroke: new Stroke({ color: 'black', width: 0.8 }),
      }),
    });
    sem_medicao_telemetry.setStyle(redStyle);

    // Define o estilo verde
    const greenStyle = new Style({
      image: new Circle({
        radius: 4.5,
        fill: new Fill({ color: 'rgba(168, 255, 144, 1)' }), // Verde claro
        stroke: new Stroke({ color: 'black', width: 0.8 }),
      }),
    });

    medindo_telemetria.setStyle(greenStyle);

    //estilo para confiabilidade

    const confiabilidateStyle = (feature) => {
      const porcentagem = feature.get('porcentagem');

      return new Style({
        image: new Circle({
          radius: 4.5,
          fill: new Fill({ color: 'rgba(168, 168, 168, 1)' }),
          stroke: new Stroke({ color: 'black', width: 0.9 }),
        }),
        text: new Text({
          font: '17px Calibri,sans-serif',
          text: `${porcentagem}%`,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({
            color: 'white',
            width: 7.5,
          }),
        }),
      });
    };

    confiabilidade_pacotes.setStyle(confiabilidateStyle);

    // Define o estilo preto
    const greyStylew = new Style({
      image: new Circle({
        radius: 4.5,
        fill: new Fill({ color: 'rgba(168, 168, 168, 1)' }),
        stroke: new Stroke({ color: 'black', width: 0.8 }),
      }),
    });

    sem_comunicacao.setStyle(greyStylew);

    const src_telemetria_comunica = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: comunica,
    });

    const telemetria_comunica = new VectorLayer({
      name: 'telemetriaComunica',
      title: 'TelemetriaComunica',
      source: src_telemetria_comunica,
      visible: false,
      zIndex: 1,
    });

    // camada gg para visualização
    var src_orgao_pagador2 = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_orgao_pagador,
    });

    var orgao_pagador2 = new VectorLayer({
      name: 'orgao_pagador',
      title: 'Orgao2',
      source: src_orgao_pagador2,
      visible: true,
    });

    //regiões - início
    var src_regiao = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_regiao,
    });

    var regiao = new VectorLayer({
      name: 'regiao',
      title: 'regiao',
      source: src_regiao,
      visible: false,
    });

    var src_regiao_ggml = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGML,
    });

    var regiao_ggml = new VectorLayer({
      name: 'ggml',
      title: 'ggml',
      source: src_regiao_ggml,
      visible: false,
    });

    var src_regiao_ggnd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGND,
    });

    var regiao_ggnd = new VectorLayer({
      name: 'ggnd',
      title: 'ggnd',
      source: src_regiao_ggnd,
      visible: false,
    });

    var src_regiao_ggno = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGNO,
    });

    var regiao_ggno = new VectorLayer({
      name: 'ggno',
      title: 'ggno',
      source: src_regiao_ggno,
      visible: false,
    });

    var src_regiao_ggsd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSD,
    });

    var regiao_ggsd = new VectorLayer({
      name: 'ggsd',
      title: 'ggsd',
      source: src_regiao_ggsd,
      visible: false,
    });

    var src_regiao_ggso = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSO,
    });

    var regiao_ggso = new VectorLayer({
      name: 'ggso',
      title: 'ggso',
      source: src_regiao_ggso,
      visible: false,
    });

    //regiões - fim

    // Definir informações das camadas
    const orgaoLayers = [
      { name: 'grpv', url: urlGRPV },
      { name: 'grar', url: urlGRAR },
      { name: 'grcp', url: urlGRCP },
      { name: 'grma', url: urlGRMA },
      { name: 'grsp', url: urlGRSP },
      { name: 'gidld', url: urlGIDLD },
      { name: 'grum', url: urlGRUM },
      { name: 'grap', url: urlGRAP },
      { name: 'grcm', url: urlGRCM },
      { name: 'grtb', url: urlGRTB },
      { name: 'grto', url: urlGRTO },
      { name: 'grga', url: urlGRGA },
      { name: 'gpdag', url: urlGPDAG },
      { name: 'grca', url: urlGRCA },
      { name: 'grfi', url: urlGRFI },
      { name: 'grpg', url: urlGRPG },
      { name: 'grli', url: urlGRLI },
      { name: 'grfb', url: urlGRFB },
      { name: 'gruv', url: urlGRUV },
      { name: 'grpb', url: urlGRPB },
      { name: 'gdop', url: urlGDOP },
      { name: 'grap', url: urlGRAP },
      { name: 'gesa', url: urlGESA },
      { name: 'gtesg', url: urlGTESG },
      { name: 'ggpinf', url: urlGGPINF },
    ];

    // Função para criar camadas
    function createOrgaoLayer(layerInfo) {
      const source = new VectorSource({
        format: new GeoJSON(),
        attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
        url: layerInfo.url,
      });

      return new VectorLayer({
        name: layerInfo.name,
        title: layerInfo.name,
        source: source,
        visible: false,
      });
    }

    // Estilos para as features
    const style2 = new Style({
      image: new CircleStyle({
        radius: 4.5,
        fill: new Fill({ color: 'rgba(1, 137, 255, 0.8)' }),
        stroke: new Stroke({ color: '#000000', width: 0.4 }),
      }),
    });
    telemetria_total?.setStyle(style2);

    // Criar camadas para cada objeto no array orgaoLayers
    const orgaoLayersArray = orgaoLayers?.map((layerInfo) =>
      createOrgaoLayer(layerInfo)
    );
    //camadas -fim

    // Funções de totais
    getTotalTele(src_telemetria_pontos).then((totalTele) => {
      const totalTeleElement = document?.getElementById('totalTele');
      if (totalTeleElement) {
        totalTeleElement.innerHTML = `(${totalTele})`;
      }
      setTotalTele(totalTele);
    });

    getTotalFeatures(src_alarms).then(
      ({ totalFeatures, somaTotais, falseCount, totalMaisDe24Horas }) => {
        const totalAlarmes = somaTotais || 0;
        const totalAlarmesElement = document.getElementById('totalAlarmes');
        if (totalAlarmesElement) {
          totalAlarmesElement.innerHTML = `(${totalAlarmes})`;
        }
        setTotalAlarmes(totalAlarmes);

        const totalAlarmes24h = totalMaisDe24Horas || 0;
        const totalAlarmes24hElement = document.getElementById(
          'totalAlarmes24h'
        );
        if (totalAlarmes24hElement) {
          totalAlarmes24hElement.innerHTML = ` (${totalAlarmes24h})`;
        }
        setTotalAlarmes24h(totalAlarmes24h);
      }
    );

    getLastMeasureDate(src_alarms).then(
      ({ totalFeatures, notNullCount, nullCount }) => {
        const totalNullMeasureDate = nullCount || 0;
        const totalNullMeasureDateElement = document.getElementById(
          'totalNullMeasureDate'
        );
        if (totalNullMeasureDateElement) {
          totalNullMeasureDateElement.innerHTML = `(${totalNullMeasureDate})`;
        }
        setTotalLastMeasureDate(totalNullMeasureDate);
      }
    );

    getLastMeasureDate(src_sem_comunicacao).then(
      ({ totalFeatures, notNullCount, nullCount }) => {
        const totalSemComunicacao = nullCount || 0;
        const totalSemComunicacaoElement = document.getElementById(
          'totalSemComunicacao'
        );
        if (totalSemComunicacaoElement) {
          totalSemComunicacaoElement.innerHTML = `(${totalSemComunicacao})`;
        }
        setTotalLastMeasureDate(totalSemComunicacao);
      }
    );

    getMedindo(src_medindo).then(
      ({ totalFeatures, notNullCount, nullCount }) => {
        const totalMedindo = notNullCount || 0;
        const totalMedindoElement = document.getElementById('totalMedindo');
        if (totalMedindoElement) {
          totalMedindoElement.innerHTML = `(${totalMedindo})`;
        }
        setTotalMedindo(totalMedindo);
      }
    );
    const urls = [
      { url: lastMeasureDate, setter: setTotalLastMeasureDate },
      { url: telemetria_pontos, setter: setTotalTele },
      { url: SemMedicao, setter: setTotalSemMedicao },
      { url: ultrapDemanda, setter: setTotalUltrapDemanda },
      { url: quedaEnergia, setter: setTotalQueda },
      { url: HorarioPonta, setter: setTotalForaPonta },
    ];

    urls.forEach(({ url, setter }) => {
      getTotalFeaturesregiao(url).then((totalFeatures) =>
        setter(totalFeatures)
      );
    });

    //CRIAÇÃO DO POP-UP AO CLICAR NA FEATURE
    createPopup(map, history);

    const search = new SearchPhoton({
      target: document.getElementById('map'),
      lang: 'fr',
      reverse: true,
      position: true,
      placeholder: 'Pesquisar por endereço',
      title: 'Pesquisar por endereço',
    });
    map.addControl(search);

    search.on('select', function (e) {
      map.getView().animate({
        center: e.coordinate,
        zoom: Math.max(map.getView().getZoom(), 16),
      });
    });

    const baseLayerGroup = new LayerGroup();
    // Adicionar camadas ao baseLayerGroup

    baseLayerGroup.getLayers().push(orgao_pagador2);
    baseLayerGroup.getLayers().push(regiao);
    baseLayerGroup.getLayers().push(regiao_ggml);
    baseLayerGroup.getLayers().push(regiao_ggnd);
    baseLayerGroup.getLayers().push(regiao_ggno);
    baseLayerGroup.getLayers().push(regiao_ggsd);
    baseLayerGroup.getLayers().push(regiao_ggso);
    baseLayerGroup.getLayers().push(telemetria_total);
    baseLayerGroup.getLayers().push(telemetria_comunica);

    baseLayerGroup.getLayers().push(alarms_telemetry);
    baseLayerGroup.getLayers().push(semMedicao_telemetry);
    baseLayerGroup.getLayers().push(ultrapassagem_demanda);
    baseLayerGroup.getLayers().push(sem_medicao_telemetry);
    baseLayerGroup.getLayers().push(queda_energia);
    // baseLayerGroup.getLayers().push(horario_ponta);
    baseLayerGroup.getLayers().push(sem_comunicacao);
    baseLayerGroup.getLayers().push(medindo_telemetria);
    baseLayerGroup.getLayers().push(last_date);
    baseLayerGroup.getLayers().push(confiabilidade_pacotes);

    // Adicionar as camadas do orgaoLayersArray
    orgaoLayersArray.forEach((layer) => {
      baseLayerGroup.getLayers().push(layer);
    });

    map.addLayer(baseLayerGroup);

    function setupLayerVisibilityToggles(baseLayerGroup) {
      const baseLayerElements = document.querySelectorAll(
        '.dropdown-menu-telemetria input[type="checkbox"]'
      );

      baseLayerElements.forEach((element) => {
        element.addEventListener('change', function (e) {
          const elementValue = this.value;
          const isChecked = this.checked;

          baseLayerGroup.getLayers().forEach((layer) => {
            const baseLayerTitle = layer?.get('title');

            if (baseLayerTitle === elementValue) {
              layer.setVisible(isChecked);
              const style = getLayerStyle(baseLayerTitle, isChecked);
              if (style) {
                layer.setStyle(style);
              }
            }
          });
        });
      });
    }

    // Inicializar a configuração dos listeners
    setupLayerVisibilityToggles(baseLayerGroup);

    //estilo dosmpontos na Legenda - início
    coresPontos('canvasTotalPontos', 'rgba(1, 137, 255, 0.8)');
    coresPontos('canvasTotalLastMeasureDate', 'rgba(168, 255, 144, 1)');
    coresPontos('canvasTotalNullMeasureDate', 'rgba(255, 55, 0, 1)');
    coresPontos('canvasTotalSemComunicacao', 'rgba(168, 168, 168, 1)');

    coresPontos2('canvasTotalNaoComuicacao', 'rgba(255, 0, 0, 0.8)');
    coresPontos2('canvasAlarmes24h', 'rgba(168, 168, 168, 1)');
    //estilo dos pontos na Legenda - fim

    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      map.setTarget(null);
      document.removeEventListener('mousedown', handler);
    };
    //eslint-disable-next-line
  }, [systemUrls]);

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const numeroUnidadeConsumidora = inputValue?.trim();

      if (numeroUnidadeConsumidora === '') {
        showPopup('Digite um número de unidade consumidora válido');
      } else {
        try {
          setLoading(true);
          const pontoSelecionado = await findPontoByUnidadeConsumidora(
            numeroUnidadeConsumidora
          );

          if (pontoSelecionado) {
            const { latitude, longitude } = pontoSelecionado;
            irParaLocal(longitude, latitude);
          } else {
            showPopup('Número da unidade consumidora não encontrado');
          }
        } catch (error) {
          showPopup('Error fetching data');
        } finally {
          setLoading(false);
        }
      }
    }
  };

  // Função para buscar dados de uma URL
  async function fetchDataFromURL(url) {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

  // Função para encontrar o ponto pelo número da unidade consumidora
  async function findPontoByUnidadeConsumidora(unidadeConsumidora) {
    const urls = [
      telemetria_pontos,
      alarmes,
      medindo,
      SemMedicao,
      ultrapDemanda,
    ];
    for (const url of urls) {
      if (url) {
        const data = await fetchDataFromURL(url);

        if (data) {
          const { features } = data;
          for (const feature of features) {
            const { properties } = feature;

            if (
              properties?.identification_number?.toString() ===
                unidadeConsumidora ||
              properties?.uuid_circuit === unidadeConsumidora
            ) {
              const latitude = feature.geometry?.coordinates[1];
              const longitude = feature.geometry?.coordinates[0];
              return { latitude, longitude };
            }
          }
        }
      }
    }
    return null;
  }

  // Função para mover o mapa para o local especificado
  function irParaLocal(longitude, latitude) {
    const view = viewRef?.current;
    if (view) {
      view.animate({
        center: fromLonLat([longitude, latitude]),
        duration: 2000,
        zoom: 19,
      });
    }
  }

  function showPopup(message) {
    const popupElement = document.getElementById('customPopup');
    const popupMessageElement = document.getElementById('popupMessage');

    popupMessageElement.textContent = message;
    popupElement.classList.add('show');

    // Add a close button ('x') to the popup
    const closeButton = document.createElement('span');
    closeButton.textContent = 'x';
    closeButton.classList.add('closeButton');
    closeButton.addEventListener('click', () => {
      popupElement.classList.remove('show');
    });
    popupElement.appendChild(closeButton);

    // Hide the popup after a few seconds (you can adjust the delay as needed)
    setTimeout(() => {
      popupElement.classList.remove('show');
    }, 15000);
  }
  const LayersLegend = [
    {
      value: 'Alarmes',
      label: ` Alarmes`,
      subvalues: [
        { value: 'SemMedicao', label: ` Sem medição (${totalSemMedicao}) ` },
        // {
        //   value: 'ultrapDemanda',
        //   label: ` Ultrapassagem demanda (${totalUltrapDemanda}) `,
        // },
        { value: 'SemEnergia', label: ` Queda de energia (${totalQueda}) ` },
      ],
    },
    {
      // value: 'lastMeasureDate',
      value: 'Medindo',
      label: ` Medindo`,
    },
    {
      value: 'semComunicacao',
      label: ` Sem comunicação`,
    },
    {
      value: 'confiabilidade',
      label: (
        <div className="tooltip-container">
          &nbsp;Confiabilidade de pacotes/dia
          <span className="tooltip-text">
            96 pacotes de medição enviados por dia = 100% de confiabilidade.
          </span>
        </div>
      ),
    },
    {
      value: 'Orgao2',
      label: ' GR',
      subvalues: [
        { value: 'grpv', label: ` GRPV (${totalGRPV}) ` },
        { value: 'grar', label: ` GRAR (${totalGRAR}) ` },
        { value: 'grcp', label: ` GRCP (${totalGRCP}) ` },
        { value: 'grma', label: ` GRMA (${totalGRMA}) ` },
        { value: 'grsp', label: ` GRSP (${totalGRSP}) ` },
        { value: 'gidld', label: ` GIDLD (${totalGIDLD}) ` },
        { value: 'grum', label: ` GRUM (${totalGRUM}) ` },
        { value: 'grcm', label: ` GRCM (${totalGRCM}) ` },
        { value: 'grtb', label: ` GRTB (${totalGRTB}) ` },
        { value: 'grto', label: ` GRTO (${totalGRTO}) ` },
        { value: 'grga', label: ` GRGA (${totalGRGA}) ` },
        { value: 'gpdag', label: ` GPDAG (${totalGPDAGC}) ` },
        { value: 'grca', label: ` GRCA (${totalGRCA}) ` },
        { value: 'grfi', label: ` GRFI (${totalGRFI}) ` },
        { value: 'grpg', label: ` GRPG (${totalGRPG}) ` },
        { value: 'grli', label: ` GRLI (${totalGRLI}) ` },
        { value: 'grfb', label: ` GRFB (${totalGRFB}) ` },
        { value: 'gruv', label: ` GRUV (${totalGRUV}) ` },
        { value: 'grpb', label: ` GRPB (${totalGRPB}) ` },
        { value: 'grap', label: ` GRAP (${totalGRAP}) ` },
        { value: 'gdop', label: ` GDOP (${totalGDOP}) ` },
        { value: 'gesa', label: ` GESA (${totalGESA}) ` },
        { value: 'ggpinf', label: ` GGPINF (${totalGGPINF}) ` },
        { value: 'gtesg', label: ` GTESG (${totalGTESG}) ` },
      ],
    },
    {
      value: 'regiao',
      label: ' GG',
      subvalues: [
        { value: 'ggml', label: ` GGML (${totalGGML})` },
        { value: 'ggsd', label: ` GGSD (${totalGGSD})` },
        { value: 'ggnd', label: ` GGND (${totalGGND})` },
        { value: 'ggno', label: ` GGNO (${totalGGNO}) ` },
        { value: 'ggso', label: ` GGSO (${totalGGSO}) ` },
      ],
    },
  ];

  function updateLabel(totalValue, subvalueKey) {
    const index = LayersLegend?.findIndex((layer) => {
      return (
        layer.subvalues &&
        layer.subvalues?.some((subvalue) => subvalue?.value === subvalueKey)
      );
    });

    if (index !== -1) {
      const subvalueIndex = LayersLegend[index].subvalues?.findIndex(
        (subvalue) => subvalue?.value === subvalueKey
      );
      if (subvalueIndex !== -1) {
        LayersLegend[index].subvalues[
          subvalueIndex
        ].label = `${subvalueKey.toUpperCase()} (${totalValue})`;
      }
    }
  }

  //alarmes- início
  updateLabel(totalSemMedicao, 'totalSemMedicao');
  updateLabel(totalQueda, 'semEnergia');
  updateLabel(totalUltrapDemanda, 'ultrapDemanda');
  updateLabel(totalForaPonta, 'foraPonta');
  updateLabel(totalLastMeasureDate, 'last');
  //alarmes - fim

  return (
    <div>
      {/* <MapLoading loading={loadingData} /> */}
      <div className="MapTelemetryComponent">
        <div id="customPopup">
          {/* pop up do input */}
          <p id="popupMessage" />
        </div>
        <div style={{ width: '100%', height: '93vh' }}>
          <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
            {/* criando sidebar do lado direito */}
          </div>
        </div>
        <div className="menu-container" ref={menuRef}>
          {/* Botão adicionado ao mapa */}
          <div
            id="botao-telemetry"
            onClick={handleButtonClick}
            title="Pesquisa por UC ou Circuito"
          >
            <button type="button" title="Pesquisa por UC ou Circuito" />
          </div>

          {/* Campo de entrada que aparecerá ao clicar no botão */}
          {inputVisible && (
            <div
              ref={inputRef}
              onSubmit={handleInputSubmit}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                position: 'absolute',
                top: '170px',
                left: '35px',
                zIndex: 9999,
                background: 'rgba(255, 255, 255, 0.7)',
                height: '22px',
                border: '2px solid black',
                borderRadius: '4px',
              }}
            >
              <input
                type="search"
                autoComplete="off"
                placeholder="Digite UC ou Circuito"
                value={inputValue}
                onChange={handleInputChange}
                id="inputNumeroPonto"
                title="Pesquisa por UC"
                style={{
                  border: 'none' /* Remove the input border */,
                  outline: 'none' /* Remove the input outline */,
                  paddingRight: loading ? '30px' : '0',
                }}
              />
              {loading && (
                <div
                  className="loading-circle"
                  style={{
                    position: 'absolute',
                    top: '9%',
                    right: '8px',
                    transform: 'translateY(-50%)',
                  }}
                />
              )}
            </div>
          )}

          <div
            className={`menu-trigger-telemetria ${open ? 'active' : ''}`}
            title="Camadas"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
            style={{
              pointerEvents: 'auto',
              // Defina outras propriedades de estilo conforme necessário
            }}
          />
          <div
            className={`dropdown-menu-telemetria ${open ? 'active' : 'active'}`}
          >
            <h3>
              <u>Camadas</u>
              <br />
            </h3>
            <ul>
              {LayersLegend.map((item) => (
                <label key={item.value} style={{ fontSize: '13px' }}>
                  <input
                    type="checkbox"
                    name="baseLayerCheck"
                    value={item?.value}
                    defaultChecked={
                      item?.value === 'Alarmes' ||
                      item?.value === 'Orgao2' ||
                      item?.value === 'semComunicacao' ||
                      item?.value === 'Medindo'
                    }
                    // disabled={item.value == 'Pontos Sanepar'}
                    style={{ marginBottom: '10px' }}
                    onClick={(e) => e.stopPropagation()}
                  />

                  {item.label}
                  {item.value === 'regiao' && item.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        subMenuOpen ? 'open' : ''
                      }`}
                    >
                      <div
                        className="submenu-icon-telemetria"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSubMenuOpen(!subMenuOpen);
                        }}
                      >
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          subMenuOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues.map((subitem) => (
                          <div key={subitem.value} style={{ fontSize: '13px' }}>
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {item?.value === 'Orgao2' && item?.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        orgaoSubMenuOpen ? 'open' : ''
                      }`}
                      style={{ maxHeight: '250px', overflowY: 'auto' }}
                    >
                      <div
                        className="submenu-icon-telemetria"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOrgaoSubMenuOpen(!orgaoSubMenuOpen);
                        }}
                      >
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          orgaoSubMenuOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues?.map((subitem) => (
                          <div
                            key={subitem?.value}
                            style={{ fontSize: '13px' }}
                          >
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                            />
                            {subitem?.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {item?.value === 'Alarmes' && item?.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        telemetriaOpen ? 'open' : ''
                      }`}
                    >
                      <div
                        className="submenu-icon-telemetria"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setTelemetriaOpen(!telemetriaOpen);
                        }}
                      >
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          telemetriaOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues?.map((subitem) => (
                          <div
                            key={subitem?.value}
                            style={{ fontSize: '13px' }}
                          >
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                              defaultChecked={subitem.value === 'SemMedicao'}
                            />
                            {subitem?.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <br />
                </label>
              ))}
              <table
                className="tabelaLegenda-telemetria"
                style={{
                  tableLayout: 'fixed',
                  marginTop: '10px',
                  fontSize: '13px',
                }}
              >
                <tbody>
                  <tr height="20">
                    <td colSpan="3">
                      &nbsp;&nbsp;&nbsp;
                      <u>
                        <b>Legenda:</b>
                      </u>
                    </td>
                    <td>&nbsp;</td>
                  </tr>

                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalPontos"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>
                    <td type="checkbox">
                      Telemetrias instaladas <span id="totalTele" />
                    </td>
                  </tr>

                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalNaoComuicacao"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>
                    <td
                      type="checkbox"
                      // title="
                      //     Sem medição
                      //    Queda de energia
                      //   "
                      class="tooltip-container"
                    >
                      Telemetrias com alarmes <span id="totalAlarmes" />
                      <span class="tooltip-text">
                        Sem medição e Queda de energia
                      </span>
                    </td>
                  </tr>
                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasAlarmes24h"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>
                    <td
                      type="checkbox"
                      // title="
                      //    Telemetrias com alarmes que estão a mais de 24 horas sem enviar novos pacotes
                      //     "
                      class="tooltip-container"
                    >
                      Telemetrias com alarmes acima de 24h
                      <span id="totalAlarmes24h" />
                      <span class="tooltip-text">
                        Telemetrias com alarmes que estão a mais de 24 horas sem
                        enviar novos pacotes
                      </span>
                    </td>
                  </tr>

                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalLastMeasureDate"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>
                    <td
                      type="checkbox"
                      // title="
                      //   UCs que não estejam a mais de 1 hora sem enviar pacote de medição"
                      class="tooltip-container"
                    >
                      Telemetrias medindo <span id="totalMedindo" />
                      <span class="tooltip-text">
                        UCs que não estejam a mais de 1 hora sem enviar pacote
                        de medição
                      </span>
                    </td>
                  </tr>
                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                      document.body.style.cursor = 'auto';
                    }}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('highlighted');
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalNullMeasureDate"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>
                    <td
                      type="checkbox"
                      // title="
                      //   UCs a mais de uma hora sem enviar pacote de medição"
                      class="tooltip-container"
                    >
                      Telemetrias sem medição <span id="totalNullMeasureDate" />
                      <span class="tooltip-text">
                        UCs a mais de uma hora sem enviar pacote de medição
                      </span>
                    </td>
                  </tr>
                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                      document.body.style.cursor = 'auto';
                    }}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('highlighted');
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalSemComunicacao"
                        width="25"
                        height="16"
                        fontSize="13px"
                      />
                    </td>

                    <td
                      type="checkbox"
                      // title="Telemetrias que não enviaram nenhum tipo de pacote, tanto alarmes quanto medições"
                      class="tooltip-container"
                    >
                      Telemetrias sem comunicação
                      <span id="totalSemComunicacao"></span>
                      <span class="tooltip-text">
                        Telemetrias que não enviaram nenhum tipo de pacote,
                        tanto alarmes quanto medições
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
