import { useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useBillValueAudit() {
  const { getSession } = useContext(AccountContext);

  const getBillValueAudit = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllBillValueAudits(headers, 10000, 300);

    const { data } = response.data;

    const billValueAudit = data || [];

    if (billValueAudit.length === 0) {
      console.error(Messages.noFindBillValueAudit);
      throw new Error(Messages.noFindBillValueAudit);
    }

    return billValueAudit;
  };

  const {
    data: billValueAudit,
    error: billValueAuditError,
    isLoading,
  } = useQuery(['billValueAudit'], getBillValueAudit);

  return { billValueAudit, billValueAuditError, isLoading };
}

export default useBillValueAudit;
