import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import IoseApiLib from 'iose-api-lib';
import { useHistory } from 'react-router-dom';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
} from 'components';

import { Messages, useUnity } from 'common';

import { useClientData, useGroupPermissions, useObjects } from 'hooks';

import IoseCardUnity3d from 'components/IoseCardUnity3d';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';

export default function Bim3dContainer() {
  const history = useHistory();

  const { setUnity } = useUnity();
  const { clientData, uuidClient, group } = useClientData();
  const { groupPermissions } = useGroupPermissions();
  //eslint-disable-next-line
  const { device3dMapping } = useObjects();

  const userSession = LocalStorage.getSession();

  const [cards, setCards] = useState([]);
  const [endListInfinityScroll, setEndListInfinityScroll] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [hasReadPermission, setHasReadPermission] = useState(true);

  useEffect(() => {
    const readPath = ['dashboards', '3d', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line
  }, [uuidClient, clientData]);

  const getAllData = async (moreUnitys) => {
    setError('');
    setLoading(true);

    try {
      if (uuidClient) {
       await getMoreThanOneUnity(moreUnitys);
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorGetData;
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  const getMoreThanOneUnity = async (moreUnitys) => {
    let allUnitys = [];

    if (!moreUnitys) {
      allUnitys = await getAllUnitys(uuidClient);
      allUnitys.data.length === 0 && setError(Messages.noRegisteredUnits);

      
      const filteredUnitys = []
      allUnitys.data.forEach((unity) => {
        if (unity.bin_link !== "NULL") {
          filteredUnitys.push(unity)
        }
      }
        
      );
      setCards(filteredUnitys);

      if (filteredUnitys === 0) {
        setError(Messages.noRegistered3dUnits);
      }
    } else if (nextPage) {
      allUnitys = await getAllUnitys(uuidClient, nextPage);
      !allUnitys.next_page && setEndListInfinityScroll(true);

      setCards(cards.concat(allUnitys.data));
    }

    setNextPage(allUnitys.next_page);
  };

  const getAllUnitys = async (uuid_client, nextpage = 0) => {
    try {
      let response = await IoseApiLib.Unity.getAllUnity(uuid_client, nextpage);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getMoreUnitys = () => nextPage && getAllData(true);

  const redirect3dViewer = (unityData) => {
    setUnity(unityData);
    history.push({
      pathname: '/3d-viewer',
      state: {
        uuid_client: uuidClient,
        clientName: clientData.name,
        unityData: unityData,
      },
    });
  };

  const renderCards = (card) => {
    return (
      <IoseCardUnity3d
        key={card?.uuid_unity}
        uuid_unity={card?.uuid_unity}
        name={card?.name}
        description={card?.description}
        group={group}
        energy={card?.energy}
        money={card?.money}
        tariff_period={card?.tariff_period}
        tariff_data={card?.tariff_data}
        contracted_demand={card?.contracted_demand}
        identification_number={card?.identification_number}
        bin_link={card?.bin_link}
        geracao_estimada={card.geracao_estimada}
        clickCard={redirect3dViewer}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Unidades com projeto 3D"
        description={'Total: ' + cards?.length}
        button={<></>}
        style={{ margin: '0' }}
      >
        {/* <IoseSearch
          placeholder="Pesquisar Unidades…"
          funcSearch={filterUnity}
        /> */}
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return <S.ContainerCards>{cards.map(renderCards)}</S.ContainerCards>;
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards.length}
            next={getMoreUnitys}
            hasMore={true}
            loading={loading}
            endList={endListInfinityScroll}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  return (
    <S.WrapContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </S.WrapContainer>
  );
}

Bim3dContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
