import React, { useState, useEffect } from 'react';

import {
  BatteryChargingFull as BatteryChargingFullIcon,
  LibraryBooks as ExtraInformaçõesIcon
} from '@material-ui/icons';

import * as S from '../style';

import { Tooltip } from '@material-ui/core';
import { LoadingValueCards } from 'components';
import ChartsTable from './ChartsTable';

const formatNumber = (number) => {
  const formattedNumber = number?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });
  return formattedNumber;
};

export default function IoseCardMedicoes({
  loading,
  medicoes,
  agendamentos,
  grupo,
  selecionados
}) {
  medicoes = medicoes.flat()
  const [isMedicaoCompletaOpen, setIsMedicaoCompletaOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleMedicao = (e) => {
    console.log(e.currentTarget)
    const key = e.currentTarget.name;
    setSelectedCard({ title: 'ULTIMA MEDIÇÂO COMPLETA E AGENDAMENTOS DO CIRCUITO', data: medicoes, circuit: key});
    setIsMedicaoCompletaOpen(true);
  };

  return (
    <>
      <S.CardGrid container spacing={2}>
        {medicoes.map((medicao) =>
          <S.BoxContainerHover
            key={medicao.uuid_circuit}
            style={{ backgroundColor: 'var(--base)'}}
            showmore={true}
          >
            <S.HeaderContainer>
              <S.IconHeader>
                <BatteryChargingFullIcon />
              </S.IconHeader>
              <S.CardTitle>{medicao.uuid_circuit}</S.CardTitle>
            </S.HeaderContainer>
            <S.DataContainer key={medicao.import_active_energy}>
              <S.CardDataKey>Energia ativa consumida lida (Wh):</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{formatNumber(medicao.import_active_energy)}</S.CardDataValue>
              )}
            </S.DataContainer>
            <S.DataContainer key={medicao.export_active_energyo}>
              <S.CardDataKey>Energia ativa exportada lida (Wh):</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{formatNumber(medicao.export_active_energy)}</S.CardDataValue>

              )}
            </S.DataContainer>
            <S.DataContainer key={medicao.voltage}>
              <S.CardDataKey>Tensão RMS da fase medida (V):</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{formatNumber(medicao.voltage)}</S.CardDataValue>

              )}
            </S.DataContainer>
            {/* <S.DataContainer key={medicao.relay_state}>
              <S.CardDataKey>Estado atual do relé:</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{medicao.relay_state}</S.CardDataValue>
              )}
            </S.DataContainer> */}
            <S.DataContainer key={medicao.current}>
              <S.CardDataKey>Corrente (A):</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{formatNumber(medicao.current)}</S.CardDataValue>
              )}
            </S.DataContainer>
            <S.DataContainer key={medicao.arrival_date}>
              <S.CardDataKey>Data da medição:</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{medicao.arrival_date}</S.CardDataValue>
              )}
            </S.DataContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Tooltip title={'Medição completa e Agendamentos do circuito'} arrow>
                <S.ClickableIcon name={medicao.uuid_circuit} onClick={handleMedicao}>
                  <ExtraInformaçõesIcon/>
                </S.ClickableIcon>
              </Tooltip>
            </div>
          </S.BoxContainerHover>
        )}
      </S.CardGrid>
      <ChartsTable
        open={isMedicaoCompletaOpen}
        onClose={() => setIsMedicaoCompletaOpen(false)}
        selectedCard={selectedCard}
        agendamentos={agendamentos}
        medicoes={medicoes}
        selecionados={selecionados}
        redirectEditSheduler={()=> {console.log('teste')}}
        redirectDeleteSheduler={()=> {console.log('teste')}}
        getSchedulingModulesByGroup={()=> {console.log('teste')}}
        resendRequistion={()=> {console.log('teste')}}
        grupo={grupo}
        loading={loading}
      />
    </>
  );
}
