import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, Button, Tooltip } from '@material-ui/core';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2 } from 'components/IoseButton';
import energyBillRecalcReport from 'reports/EnergyBills/energyBillRecalcReport';
import * as XLSX from 'xlsx';
import { useCreateLogs, useGroupPermissions, useUserAlarms } from 'hooks';
import { ButtonContainer, StyledTable, TableHeadStyled, TableRowStyled } from '../style';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import UsuariosXAlarmesDeleteModal from './usuarios-alarmes-delete-modal';
import { LocalStorage, Permissions } from 'common';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function exportToExcel(data) {
  const filteredData = data?.map((obj) => {
    const { uuid_contract, ...rest } = obj;
    return rest;
  });

  const reorderedColumns = [
    'identification_number',
    'concessionaire',
    'pdf_name',
    'reference_month',
    'bill_value',
    'recalc_value',
    'difference_value',
  ];

  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    concessionaire: 'Concessionária',
    pdf_name: 'PDF',
    reference_month: 'Mês de referência',
    bill_value: 'Valor da Fatura',
    recalc_value: 'Valor do Recálculo',
    difference_value: 'Valor de Diferença',
  };

  const columns = Object?.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function transformDateString(inputDate) {
  const parts = inputDate.split('T')[0].split('-');
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
}

const TableCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, onDeleteClick, hasDeletePermission }) => {
  return (
    <TableRowStyled align="center">
      <TableCellCentered>{value?.email_usuario}</TableCellCentered>
      <TableCellCentered>{value?.unidade_consumidora}</TableCellCentered>
      <TableCellCentered>{value?.numero_telemetria}</TableCellCentered>
      <TableCellCentered>{value?.tipo_alarme}</TableCellCentered>
      <TableCellCentered>{transformDateString(value?.data)}</TableCellCentered>
      <TableCellCentered>{value?.push_token}</TableCellCentered>
      <TableCellCentered>
        {hasDeletePermission ? (
            <Tooltip title='DELETAR REGISTRO'>
            <Button style={{ height: '30px' }} onClick={() => onDeleteClick(value)}>
              <HighlightOffIcon style={{ color: '#D60606' }} />
            </Button>
          </Tooltip>
        ) : (null)
        }
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onDeleteClick, hasDeletePermission }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>E-MAIL USUÁRIO</TableHeaderCellCentered>
        <TableHeaderCellCentered>UC</TableHeaderCellCentered>
        <TableHeaderCellCentered>NÚMERO TELEMETRIA</TableHeaderCellCentered>
        <TableHeaderCellCentered>TIPO DO ALARME</TableHeaderCellCentered>
        <TableHeaderCellCentered>DATA DO REGISTRO</TableHeaderCellCentered>
        <TableHeaderCellCentered>TOKEN</TableHeaderCellCentered>
        <TableHeaderCellCentered></TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>
    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onDeleteClick={onDeleteClick} hasDeletePermission={hasDeletePermission} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function UsuariosXAlarmesTable({ loading, data, hasPermission }) {
  const { createLog } = useCreateLogs();
  const { usersAlarms } = useUserAlarms();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasDeletePermission, setHasDeletePermission] = useState();

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const deletePath = ['telemetria', 'usuarios_x_alarmes', 'deletar'];

    if (userSession && groupPermissions) {

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDownloadButtonClick = async (data) => {
    exportToExcel(data);
    await createLog('EMITIR RELATÓRIO', 'RELATÓRIO XLSX DE RECÁLCULO DE FATURAS', {});
  };

  const handleReport = async (data) => {
    energyBillRecalcReport(data);
    await createLog('EMITIR RELATÓRIO', 'RELATÓRIO DE RECÁLCULO DE FATURAS', {});
  };

  const handleRemoveItem = (itemToRemove) => {
    setOpenDeleteModal(true);
    setSelectedItem(itemToRemove);
  };

  function renderChartOrErrorMessage() {
    if (usersAlarms && usersAlarms.length > 0) {
      return (
        <Paper>
          {hasPermission ? (
            <ButtonContainer>
              <IoseButton2 onClick={() => handleDownloadButtonClick(data)}>Download XLSX</IoseButton2>
              <IoseButton2 onClick={() => handleReport(data)}>EMITIR RELATÓRIO</IoseButton2>
            </ButtonContainer>
          ) : null}
          <CircuitsTable data={usersAlarms} onDeleteClick={handleRemoveItem} hasDeletePermission={hasDeletePermission}/>
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="400px"
          width="400px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={usersAlarms ? parseInt(usersAlarms.length) : 0}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop="0"
          padding="0"
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={600}
        >
          {renderChartOrErrorMessage()}
          <UsuariosXAlarmesDeleteModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            loading={loading}
            selectedItem={selectedItem}
          />
        </IoseInfinityScroll>
      )}
    </div>
  );
}
