import styled from 'styled-components';

//Imports from Material UI Components
import {
  List,
  ListItemText,
  ListItem,
  Checkbox,
  ListItemIcon,
  Typography,
} from '@material-ui/core';

export const ListStyled = styled(List)`
  width: 100%;
  background: none;
  color: ${(props) =>
    props.color === 'black' ? 'var(--dark)' : 'var(--base)'};
  height: ${(props) => (props.height ? props.height : '178px')};
  /* max-width: 360px; */
  overflow-y: scroll;
  padding: 0px !important;

  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiTypography-body2 {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-size: 12px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(props) =>
      props.color === 'black' ? 'var(--dark)' : 'var(--base)'};
  }
`;

export const ListItemStyled = styled(ListItem)`
  height: 25px;
  margin-bottom: 10px;
`;

export const CheckboxStyled = styled(Checkbox)`
  svg {
    color: var(--secondary);
  }
`;

export const TitleList = styled(Typography)`
  color: var(--base);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 10px;
`;
