import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { IoseJustificationActionModal } from 'components/Modals/IoseModals';

export default function IoseSwitch({
  relay_state,
  id,
  triggerCircuit,
  triggerCircuitAction,
  dashboard,
  name_circuit,
}) {
  const useStyles = makeStyles((theme) => ({
    fabProgress: {
      color: '#015A9E',
      position: 'absolute',
      top: 2.5,
      left: 2,
      zIndex: 1,
    },
    errorOn: {
      color: 'red',
      position: 'absolute',
      fontWeight: 'bold',
      width: '200px',
      top: dashboard === 'true' ? 22 : 20,
      left: dashboard === 'true' ? -60 : -130,
      zIndex: 1,
      fontSize: '12px',
      [theme.breakpoints.down('xs')]: {
        left: dashboard === 'true' ? -60 : -125,
        top: 23,
      },
    },
    errorOff: {
      color: 'red',
      position: 'absolute',
      fontWeight: 'bold',
      width: '200px',
      top: dashboard === 'true' ? 22 : 20,
      left: dashboard === 'true' ? -40 : -110,
      zIndex: 1,
      fontSize: '12px',
      [theme.breakpoints.down('xs')]: {
        left: dashboard === 'true' ? -40 : -100,
        top: 23,
      },
    },
    successOn: {
      color: '#00A65A',
      position: 'absolute',
      fontWeight: 'bold',
      top: 22,
      left: -30,
      zIndex: 1,
      fontSize: '12px',
    },
    successOff: {
      color: '#00A65A',
      position: 'absolute',
      fontWeight: 'bold',
      top: 22,
      left: -5,
      zIndex: 1,
      fontSize: '12px',
    },
  }));

  const classes = useStyles();
  const [checked, setChecked] = useState(relay_state ? relay_state : false);
  const [checkedModal, setCheckedModal] = useState(
    relay_state ? relay_state : false
  );
  const [message, setMessage] = useState('');
  const [loading, setloading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  //Change switch through requisition
  // useEffect(() => {
  //   setChecked(relay_state);
  // }, [relay_state]);

  //Change switch through websocket
  useEffect(() => {
    triggerCircuit &&
      triggerCircuit.type === 'activation' &&
      triggerCircuit.uuid_circuit === id &&
      typeof triggerCircuit.state_circuit !== 'undefined' &&
      setChecked(triggerCircuit.state_circuit);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCircuit]);

  const handleChange = (checked) => {
    setOpenModal(true);
    setCheckedModal(checked);
  };

  const handleTrigger = async (justification) => {
    setloading(true);
    const response = await triggerCircuitAction(
      id,
      checkedModal,
      justification
    );

    if (response.result) {
      setloading(false);
      setMessage('Sucesso!');
    } else {
      setloading(false);
      response.message.includes('circuitActionInProcessException')
        ? setMessage('Já está sendo acionado!')
        : response.message.includes('circuitActionErrorException')
        ? setMessage('Falha no módulo!')
        : response.message.includes('invalidParamsException')
        ? setMessage('Parâmetros inválidos!')
        : setMessage('Erro na Requisição!');
    }
  };

  const renderMenssages = (onOff) => {
    if (loading) {
      return (
        <>
          <CircularProgress size={15} className={classes.fabProgress} />
        </>
      );
    } else {
      timeOutMessages();

      return message !== '' && typeof message !== 'undefined' ? (
        message.includes('Sucesso') ? (
          <p className={onOff ? classes.successOn : classes.successOff}>
            {message}
          </p>
        ) : (
          <p className={onOff ? classes.errorOn : classes.errorOff}>
            {message}
          </p>
        )
      ) : (
        <></>
      );
    }
  };

  function timeOutMessages() {
    setTimeout(() => {
      if (message !== '') {
        setMessage('');
      }
    }, 40000);
  }

  return (
    <>
      <label>
        <Switch
          onChange={handleChange}
          checked={checked}
          handleDiameter={20}
          offColor="#D60606"
          onColor="#00A65A"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={15}
          width={40}
          disabled={loading}
          uncheckedHandleIcon={renderMenssages(false)}
          checkedHandleIcon={renderMenssages(true)}
        />
      </label>
      <IoseJustificationActionModal
        title="ACIONAR CIRCUITO"
        name={name_circuit}
        setOpenModalOut={setOpenModal}
        openModal={openModal}
        handleTrigger={handleTrigger}
      />
    </>
  );
}
