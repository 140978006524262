import React, { useEffect, useState } from "react";
import * as S from '../style';
import { IoseButton2 } from "components";
import { xlsxReports } from "reports/Xlsx";
import GetAppIcon from '@material-ui/icons/GetApp';

function convertNumberToDecimalStyle(value) {
  if (value === undefined || value === null) return '0';
  const convertedToNumber = parseFloat(value);
  const decimalNumber = convertedToNumber.toLocaleString('pt-BR');
  return decimalNumber;
}

const Table = ({ title, data }) => {
  if (!data) {
    return null;
  }

  const tableData = [
    { description: 'Consumo PTA', valor: data.consumo_pta_valor, kwh: data.consumo_pta_kwh },
    { description: 'Consumo FP', valor: data.consumo_fp_valor, kwh: data.consumo_fp_kwh },
    { description: 'Consumo', valor: data?.consumo_valor, kwh: data?.consumo_kwh },
    { description: 'Consumo ACL', valor: data?.consumo_acl_valor, kwh: data?.consumo_acl_kwh },
    { description: 'Cons. Excedente Reativo PTA', valor: data.consumo_exe_reat_pta_valor, kwh: data.consumo_exe_reat_pta_kwh },
    { description: 'Cons. Excedente Reativo FP', valor: data.consumo_exe_reat_fp_valor, kwh: data.consumo_exe_reat_fp_kwh },
    { description: 'Cons. Excedente Reativo', valor: data?.consumo_exe_reat_valor, kwh: data?.consumo_exe_reat_kwh },
    { description: 'Demanda PTA', valor: data.demanda_pta_valor, kwh: data.demanda_pta_kwh },
    { description: 'Demanda FP', valor: data.demanda_fp_valor, kwh: data.demanda_fp_kwh },
    { description: 'Demanda', valor: data?.demanda_valor, kwh: data?.demanda_kwh },
    { description: 'Dem. Excedente Reativa PTA', valor: data.demanda_exe_reat_pta_valor, kwh: data.demanda_exe_reat_pta_kwh },
    { description: 'Dem. Excedente Reativa FP', valor: data.demanda_exe_reat_fp_valor, kwh: data.demanda_exe_reat_fp_kwh },
    { description: 'Dem. Exedente Reativo', valor: data?.demanda_exe_reat_valor, kwh: data?.demanda_exe_reat_kwh },
    { description: 'Adicional Demanda PTA', valor: data.ad_demanda_pta_valor, kwh: data.ad_demanda_pta_kwh },
    { description: 'Adicional Demanda FP', valor: data.ad_demanda_fp_valor, kwh: data.ad_demanda_fp_kwh },
    { description: 'Adicional Demanda', valor: data?.ad_demanda_valor, kwh: data?.ad_demanda_kwh },
    { description: 'Ultrapassagem de Demanda PTA', valor: data?.demanda_ult_pta_valor, kwh: data?.demanda_ult_pta_kwh },
    { description: 'Ultrapassagem de Demanda FP', valor: data?.demanda_ult_fp_valor, kwh: data?.demanda_ult_fp_kwh },
    { description: 'Ultrapassagem de Demanda ', valor: data?.demanda_ult_valor, kwh: data?.demanda_ult_kwh },
    { description: 'CIP', valor: data.cip_valor, kwh: data.cip_kwh },
    { description: 'Energia Injetada', valor: data.energia_inj_valor, kwh: data.energia_inj_kwh },
    { description: 'Outros', valor: data.outros_valor, kwh: data.outros_kwh }
  ];

  const totalValor = tableData.reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);

  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{title}</h3>
      <S.CardTable>
        <S.CardTableHeader>
          <S.CardTableRow>
            <S.CardTableHeaderCell style={{fontSize: '11px'}}>Descrição</S.CardTableHeaderCell>
            <S.CardTableHeaderCell style={{fontSize: '11px'}}>(R$)</S.CardTableHeaderCell>
            <S.CardTableHeaderCell style={{fontSize: '11px'}}>Unidade</S.CardTableHeaderCell>
          </S.CardTableRow>
        </S.CardTableHeader>
        <tbody>
          {tableData.map((item, index) => (
            <S.CardTableRow key={index}>
              <S.CardTableCell style={{fontSize: '11px'}}>{item.description}</S.CardTableCell>
              <S.CardTableCell style={{fontSize: '11px'}}>{convertNumberToDecimalStyle(item.valor)}</S.CardTableCell>
              <S.CardTableCell style={{fontSize: '11px'}}>{convertNumberToDecimalStyle(item.kwh)}</S.CardTableCell>
            </S.CardTableRow>
          ))}
          <S.CardTableRow>
            <S.TotalCell colSpan={2} style={{fontSize: '11px'}}>Total (R$)</S.TotalCell>
            <S.TotalCell style={{fontSize: '11px'}}>{convertNumberToDecimalStyle(totalValor)}</S.TotalCell>
          </S.CardTableRow>
        </tbody>
      </S.CardTable>
    </div>
  );
};

export default function SummaryDashYearTables({ data }) {
  const [totalData, setTotalData] = useState({});

  useEffect(() => {
    if (data && data.length > 0) {
      const aggregatedData = data.reduce((acc, item) => {
        const fields = [
          'consumo_pta_valor', 'consumo_fp_valor', 'consumo_exe_reat_pta_valor', 'consumo_exe_reat_fp_valor',
          'demanda_pta_valor', 'demanda_fp_valor', 'demanda_exe_reat_pta_valor', 'demanda_exe_reat_fp_valor',
          'ad_demanda_pta_valor', 'ad_demanda_fp_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor',
          'consumo_pta_kwh', 'consumo_fp_kwh', 'consumo_exe_reat_pta_kwh', 'consumo_exe_reat_fp_kwh',
          'demanda_pta_kwh', 'demanda_fp_kwh', 'demanda_exe_reat_pta_kwh', 'demanda_exe_reat_fp_kwh',
          'ad_demanda_pta_kwh', 'ad_demanda_fp_kwh', 'cip_kwh', 'energia_inj_kwh', 'outros_kwh', 'consumo_valor',
          'consumo_kwh', 'consumo_exe_reat_valor', 'consumo_exe_reat_kwh', 'demanda_valor', 'demanda_kwh',
          'demanda_exe_reat_valor', 'demanda_exe_reat_kwh', 'ad_demanda_valor', 'ad_demanda_kwh',
          'demanda_ult_pta_valor', 'demanda_ult_pta_kwh', 'demanda_ult_fp_valor', 'demanda_ult_fp_kwh',
          'demanda_ult_valor', 'demanda_ult_kwh', 'consumo_acl_valor', 'consumo_acl_kwh'
        ];

        fields.forEach(field => {
          acc[field] = (acc[field] || 0) + (parseFloat(item[field]) || 0);
        });

        return acc;
      }, {});

      setTotalData(aggregatedData);
    }
  }, [data]);

  return (
    <S.CardsSummaryContainer>
      <S.CardTitleHeaderContainer>
        <div>
          <S.TitleContainer>RESUMO ANUAL TOTAL</S.TitleContainer>
        </div>
        <div>
          <IoseButton2
            style={{
              margin: '10px',
            }}
            onClick={() =>xlsxReports.exportYearSummaryTotals(totalData, data)}
            width="150px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      </S.CardTitleHeaderContainer>
      <Table data={totalData} />
    </S.CardsSummaryContainer>
  );
}
