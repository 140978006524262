import styled from 'styled-components';

export const DragabbleContainerCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  /* min-width: 350px; */
  /* resize: both; */
`;

export const CloseButton = styled.span`
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
`;
