import styled from 'styled-components';
import IoseContainer from 'components/IoseContainer';

import { Grid } from '@material-ui/core';

export const GridContainer = styled(Grid)`
  padding-right: 20px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const IoseContainerStyled = styled(IoseContainer)`
  width: 100%;
  overflow-y: scroll;
  overflow-x: none !important;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;
