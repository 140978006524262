import styled from 'styled-components';

//Imports from Material UI components
import Switch from '@material-ui/core/Switch';
import {
  Table,
  TableCell,
  TableContainer,
  TablePagination,
} from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const TableStyled = styled(Table)`
  /* margin-top: 5px; */
  .MuiTableCell-root {
    display: table-cell;
    padding: ${(props) => (props.group === 'operator' ? '16px' : '16px')};
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--secondary)' : 'var(--base)'};
    max-width: ${(props) => (props.dashboard === 'true' ? '100%' : '110px')};
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
    font-size: 12px;

    @media (max-width: 767px) {
      padding: ${(props) =>
        props.dashboard === 'true' ? '8px 55px ' : '10px 25px 20px 0px'};
      height: auto;
      max-width: ${(props) => (props.dashboard === 'true' ? '100%' : '50px')};
    }

    &:hover {
      .MuiTableSortLabel-root,
      .MuiTableSortLabel-icon {
        color: ${(props) =>
          props.dashboard === 'true' ? 'var(--secondary)' : 'var(--base)'};
      }
    }

    .MuiTableSortLabel-active,
    .MuiTableSortLabel-iconDirectionAsc,
    .MuiTableSortLabel-iconDirectionDesc {
      color: ${(props) =>
        props.dashboard === 'true'
          ? 'var(--secondary) !important'
          : 'var(--base) !important'};
    }
  }
`;

export const TableCellHeader = styled(TableCell)`
  .MuiButtonBase-root,
  .NoSortLabel {
    font-size: 12px;
    padding-left: 20px;
    font-weight: bolder;
  }

  .NoSortLabel {
    cursor: auto;
  }
`;

export const TableCellName = styled(TableCell)`
  transition: all 0.15s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--secondary)' : 'var(--base)'};
    margin-left: 5px;
    height: 12px;
    width: 12px;
    transition: all 0.15s linear;
  }
  &:hover {
    svg {
      color: var(--primary);
      margin-right: 6px;
      transform: rotate(90deg);
    }
  }
`;

export const TableCellRelay = styled(TableCell)`
  color: red !important;
  font-weight: bold;
  text-align: ${(props) => (props.dashboard === 'true' ? 'center' : 'right')};
`;

export const TableContainerStyled = styled(TableContainer)`
  width: 100%;
  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const TablePaginationStyled = styled(TablePagination)`
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  color: ${(props) =>
    props.dashboard === 'true' ? 'var(--secondary)' : 'var(--base)'};
  border-radius: 10px;
  display: flex;
  align-items: baseline;
  align-content: baseline;
  justify-content: flex-end;

  svg {
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--secondary)' : 'var(--base)'};
  }

  .MuiTablePagination-actions {
    margin-left: 0px;
  }

  .MuiTablePagination-caption {
    margin: 0px;
  }

  .MuiTablePagination-select {
    padding-bottom: 2px;
    padding-top: 2px;
  }
`;

export const StyledSwitch = styled(Switch)`
  & .Mui-checked {
    color: var(--primary);
  }
  & .Mui-checked + .MuiSwitch-track {
    background-color: var(--primary);
  }
`;

export const TableCellActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 120px;
  margin-left: 10px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;
