import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as S from './style';

import { IoseBackButton } from 'components/IoseButtonIcon';
import {
  TableBody,
  TableCell,
  TableFooter,
  makeStyles,
} from '@material-ui/core';
import * as XLSX from 'xlsx';

import Permissions from 'common/permissions';

import SpeckleViewer from 'components/SpeckleViewer';
import { mapToMeasurement } from 'models/measurement';
import OffCanvas from 'components/OffCanvas';
import { IoseButton, IoseButton2, LoadingIndicator } from 'components';
import { LocalStorage } from 'common';
import {
  useClientData,
  useClima,
  useGemeos,
  useGroupPermissions,
  useSession,
  useSystemUrls,
} from 'hooks';
import { IoseAlertLoadingCards } from 'components';

import { ioseIotAPI } from 'common/ioseIotAPI';
import { ioseAPI } from 'common';
// import GraficoConsumoObjetos from './components/GraficoConsumoObjetos';
import GraficoConsumo from './components/GraficoConsumos';
import GraficoEstimadosMes from './components/GraficoEstimadosMes';
import GraficoGeracao from './components/GraficoGeracao';
// import GraficoGeracaoGeral from './components/GraficoGeracaoGeral';
import Iose3dListSummaryGeracao from 'components/Lists/Iose3dListSummaryGeracao';
import Iose3dListSummaryConsumo from 'components/Lists/Iose3dListSummaryConsumo';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import GraficoGeracaoTempo from './components/GraficoGeracaoXtempo';
import GraficoConsumoTempo from './components/GraficoConsumoXtempo';
import GraficoClima from './components/GraficoClima';
import IoseCardMedicoes from './components/CardsMedicoes';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

export default function Viewer3dContainer() {
  const { state } = useLocation();
  const { unityData } = state;
  const { clientData } = useClientData();

  const { systemUrls } = useSystemUrls();
  const { headers } = useSession();
  const { gemeos } = useGemeos(unityData.uuid_unity);

  const history = useHistory();
  const userSession = LocalStorage.getSession();
  const { groupPermissions } = useGroupPermissions();

  const [error, setError] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [latestMeasurements, setLatestMeasurements] = useState();
  const [selectedMeasurement, setSelectedMeasurement] = useState();
  const [objects, setObjects] = useState([]);
  const [category, setCategories] = useState([]);
  const [consumoCiruito, setConsumoCircuito] = useState([]);
  const [geracaoDiaria, setGeracaoDiaria] = useState([]);
  const [initialDateGeracaoDiaria, setInicialDataGeracaoDiaria] = useState(
    new Date()
  );
  const [initialDateConsumo, setInicialDataConsumo] = useState(new Date());
  const [finalDateConsumo, setFinalDataConsumo] = useState(new Date());
  const [agendamentosComDescricao, setAgendamentosComDescricao] = useState([]);
  const [agendamentos, setAgendamentos] = useState([]);
  const [mensagemRecebida, setMensagemRecebida] = useState(true);
  const [erroPeriodo, setErroPeriodo] = useState(null);

  const [loading, setLoading] = useState(true);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const readPath = ['dashboards', '3d', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  async function getCurrentMeasure(circuit) {
    try {
      const data = await ioseIotAPI.getCurrentMeasure(headers, circuit);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getAgendamentos() {
    try {
      const response = await ioseAPI.getSchedulingGroup(
        headers,
        unityData.uuid_unity,
        0,
        2000
      );

      let allSchedulings = response.data.data && response.data.data;
      return allSchedulings;
    } catch (error) {
      console.log(error);
    }
  }
  async function getAgendamentosByGroup(uuid_scheduling_group) {
    try {
      const response = await ioseAPI.getSchedulingModulesByGroup(
        headers,
        uuid_scheduling_group,
        0,
        2000
      );
      return response.data && response.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (gemeos !== undefined && gemeos.length > 0) {
      getLatestMeasurements().then((lm) => {
        setLatestMeasurements(lm);
      });
    }
    //eslint-disable-next-line
  }, [gemeos]);

  useEffect(() => {
    if (gemeos !== undefined && gemeos.length > 0) {
      getAgendamentos().then((agendamento) => {
        const agenda = [];
        if (agendamento) {
          setAgendamentosComDescricao(agendamento);
          agendamento.forEach((id) => {
            getAgendamentosByGroup(id.uuid_scheduling_group).then((_) => {
              agenda.push(_);
            });
          });
        }
        setAgendamentos(agenda);
      });
    }
    //eslint-disable-next-line
  }, [gemeos]);

  useEffect(() => {
    if (gemeos && gemeos.length > 0) {
      prepareDataGeracao(
        initialDateGeracaoDiaria ? initialDateGeracaoDiaria : new Date()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gemeos]);

  useEffect(() => {
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);

    setInicialDataGeracaoDiaria(hoje);
  }, []);

  useEffect(() => {
    const hoje = new Date();

    const trintaDiasAtras = new Date();
    trintaDiasAtras.setDate(hoje.getDate() - 30);
    setInicialDataConsumo(trintaDiasAtras);
    setFinalDataConsumo(hoje);
  }, []);

  const onHideOffCanvas = () => {
    return setShowOffCanvas(false);
  };

  useEffect(() => {
    async function obtemObjetosGemeos() {
      try {
        if (unityData !== undefined) {
          const objetos = await ioseAPI.obterObjetoComUnidade(
            headers,
            unityData.uuid_unity
          );
          setObjects(objetos.data.Items || []);
        }
      } catch (error) {
        console.log(error);
      }
    }
    obtemObjetosGemeos();

    //eslint-disable-next-line
  }, [unityData]);

  useEffect(() => {
    if (systemUrls?.length > 0) {
      let socketUrl = '';
      systemUrls?.forEach((url) => {
        if (url.attribute === '3d_gateway_url') {
          socketUrl = url.url;
        }
      });
      const socket = new WebSocket(socketUrl);

      socket.addEventListener('open', (event) => {});

      socket.addEventListener('message', (event) => {
        if (mensagemRecebida) {
          if (gemeos !== undefined && gemeos.length > 0) {
            getLatestMeasurements().then((lm) => {
              setLatestMeasurements(lm);
            });
            setMensagemRecebida(false);
          }
          setTimeout(() => {
            setMensagemRecebida(true);
          }, 60000);
        }
      });

      return () => {
        socket.close();
      };
    }
    //eslint-disable-next-line
  }, [systemUrls]);

  useEffect(() => {
    if (gemeos !== undefined && gemeos.length > 0) {
      prepareData(new Date());
    }
    //eslint-disable-next-line
  }, [latestMeasurements, gemeos]);

  // useEffect(() =>{
  //   if(unityConsumption !== undefined && unityConsumption.length > 0) {
  //     unityConsumption.forEach((unity) => {
  //       if(unity.unity_name === unityData.name) {
  //         setConsumo(unity)
  //       }
  //     })
  //   }
  //   //eslint-disable-next-line
  // }, [unityConsumption])

  useEffect(() => {
    async function getObjects() {
      const horasPorCategoria = {
        lampadas: 8,
        impressoras: 1,
        chuveiros: 1,
        geladeiras: 24,
        ventiladores: 6,
        arCondicionados: 6,
        computadores: 8,
        midia: 1,
        tomadas: 24,
        outros: 1,
      };

      let categorias = {
        lampadas: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Lampâda',
          horas: 0,
        },
        impressoras: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Impressoras',
          horas: 0,
        },
        chuveiros: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Chuveiros',
          horas: 0,
        },
        geladeiras: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Geladeiras e Freezers',
          horas: 0,
        },
        ventiladores: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Ventiladores',
          horas: 0,
        },
        arCondicionados: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Ar Condicionado',
          horas: 0,
        },
        computadores: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Computadores e Periféricos',
          horas: 0,
        },
        tomadas: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Tomadas e Interrupitores',
          horas: 0,
        },
        midia: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Aparelhos de Multimídia',
          horas: 0,
        },
        outros: {
          items: [],
          total: 0,
          quantidade: 0,
          nome: 'Outros',
          horas: 0,
        },
        totais: { total: 0, quantidade: 0, nome: 'Totais', horas: 0 },
      };

      objects.forEach((objeto) => {
        const nome = objeto.name.toLowerCase();
        if (
          /["']?\s*lamp(ada|âda)s?|luminárias?|luz|holofote\s*["']?/i.test(nome)
        ) {
          categorias.lampadas.items.push(objeto);
        } else if (/\s*["']?impressora\s*["']?/i.test(nome)) {
          categorias.impressoras.items.push(objeto);
        } else if (/\s*["']?chuveiro\s*["']?/i.test(nome)) {
          categorias.chuveiros.items.push(objeto);
        } else if (
          /\s*["']?geladeira\s*["']?/i.test(nome) ||
          /\s*["']?freezer\s*["']?/i.test(nome)
        ) {
          categorias.geladeiras.items.push(objeto);
        } else if (/\s*["']?ventilador\s*["']?/i.test(nome)) {
          categorias.ventiladores.items.push(objeto);
        } else if (/\s*["']?ar condicionado\s*["']?/i.test(nome)) {
          categorias.arCondicionados.items.push(objeto);
        } else if (
          /\s*["']?computador\s*["']?/i.test(nome) ||
          /\s*["']?estabilizador\s*["']?/i.test(nome) ||
          /\s*["']?monitor\s*["']?/i.test(nome) ||
          /\s*["']?CPU\s*["']?/i.test(nome)
        ) {
          categorias.computadores.items.push(objeto);
        } else if (
          /(\s*["']?interruptor(es)?|tomada(s)?)\s*["']?/i.test(nome)
        ) {
          categorias.tomadas.items.push(objeto);
        } else if (
          /\s*["']?televis[ãa]o\s*["']?/i.test(nome) ||
          /\s*["']?caixas de som\s*["']?/i.test(nome)
        ) {
          categorias.midia.items.push(objeto);
        } else {
          categorias.outros.items.push(objeto);
        }
      });

      Object.entries(categorias).forEach(([key, category]) => {
        if (category.items && Array.isArray(category.items)) {
          category.total = 0; // Inicializa o total para cada categoria
          category.items.forEach((item) => {
            try {
              const horas = horasPorCategoria[key] || 1;
              const value = parseFloat(item.power.replace(',', '.'));
              category.total += value;
              category.quantidade += 1;
              categorias.totais.total += value;
              categorias.totais.quantidade += 1;
              category.horas += (value / 1000) * horas;
              categorias.totais.horas += (value / 1000) * horas;
            } catch (err) {
              console.log(err);
            }
          });
        }
      });

      setCategories(categorias);
    }
    getObjects();
  }, [unityData, objects]);

  function exportToExcel(categorias) {
    // Extrai todos os itens de cada categoria
    const valores_objetos = Object.values(categorias).flatMap(
      (categoria) => categoria
    );
    // Reordena as colunas
    const reorderedData = valores_objetos.map((item) => ({
      nome: item.nome,
      potencia: item.total.toFixed(2).toString().replace('.', ','),
      quantidade: item.quantidade,
      consumo: item.horas.toFixed(2).toString().replace('.', ','),
    }));
    // Reordena os dados
    const worksheet = XLSX.utils.json_to_sheet(reorderedData);

    const headers = {
      nome: 'Nome',
      potencia: 'POTENCIA (W)',
      quantidade: 'QUANTIDADE',
      consumo: 'CONSUMO (kWh)',
    };

    // Altera o nome das colunas no cabeçalho da planilha
    const columns = Object.keys(headers);
    columns.forEach((column, index) => {
      const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
      headerCell.v = headers[column];
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Objetos');
    XLSX.writeFile(workbook, 'objetos-iose.xlsx');
  }

  const prepareDataGeracao = async (data) => {
    console.log(data);
    const medicoes = await getAllMeasureAllCircuitsRequisition(true, data);
    const clima = await getAllClimaByDia(data);
    const somaMedicoesPorData = {};
    medicoes.forEach((medicao) => {
      medicao.measures.forEach((measure) => {
        const {
          created_at,
          import_active_energy,
          export_active_energy,
          import_reactive_energy,
          export_reactive_energy,
        } = measure;
        const measureDate = new Date(created_at);

        // Encontra a chave de data existente ou cria uma nova dentro do intervalo de 5 minutos
        let key = Object.keys(somaMedicoesPorData).find((data) => {
          const existingDate = new Date(data);
          const timeDifference = Math.abs(existingDate - measureDate);
          return timeDifference <= 10 * 60 * 1000; // 5 minutos em milissegundos
        });

        if (!key) {
          key = created_at;
          somaMedicoesPorData[key] = {
            import_active_energy: 0,
            export_active_energy: 0,
            import_reactive_energy: 0,
            export_reactive_energy: 0,
          };
        }

        somaMedicoesPorData[key].import_active_energy +=
          import_active_energy / 1000;
        somaMedicoesPorData[key].export_active_energy +=
          export_active_energy / 1000;
        somaMedicoesPorData[key].import_reactive_energy +=
          import_reactive_energy / 1000;
        somaMedicoesPorData[key].export_reactive_energy +=
          export_reactive_energy / 1000;
      });
    });

    // Converte o objeto em um array de objetos com data e valores somados
    const somaMedicoesArray = Object.keys(somaMedicoesPorData).map((data) => ({
      data,
      ...somaMedicoesPorData[data],
    }));

    somaMedicoesArray.forEach((medicao) => {
      const medicaoDate = new Date(medicao.data);
      const climaData = clima.find((c) => {
        const climaDate = new Date(c.created_at);
        const timeDifference = Math.abs(medicaoDate - climaDate);
        return timeDifference <= 3 * 60 * 1000; // 10 minutos em milissegundos
      });

      if (climaData) {
        medicao.weather_icon = climaData.weather_icon_0;
        medicao.weather_main = climaData.weather_main_0;
        medicao.weather_descritopn = clientData.weather_descritopn_0;
        medicao.main_temp = climaData.main_temp;
        medicao.main_humidity = climaData.main_humidity;
      }
    });

    // Ordena o array pela data
    somaMedicoesArray.sort((a, b) => new Date(a.data) - new Date(b.data));

    // Ajusta os valores para a geração diária
    for (let i = somaMedicoesArray.length - 1; i > 0; i--) {
      somaMedicoesArray[i].import_active_energy = Math.max(
        0,
        somaMedicoesArray[i].import_active_energy -
          somaMedicoesArray[i - 1].import_active_energy
      );
      somaMedicoesArray[i].export_active_energy = Math.max(
        0,
        somaMedicoesArray[i].export_active_energy -
          somaMedicoesArray[i - 1].export_active_energy
      );
      somaMedicoesArray[i].import_reactive_energy = Math.max(
        0,
        somaMedicoesArray[i].import_reactive_energy -
          somaMedicoesArray[i - 1].import_reactive_energy
      );
      somaMedicoesArray[i].export_reactive_energy = Math.max(
        0,
        somaMedicoesArray[i].export_reactive_energy -
          somaMedicoesArray[i - 1].export_reactive_energy
      );
    }

    if (somaMedicoesArray[0] !== undefined) {
      somaMedicoesArray[0].import_active_energy = 0;
      somaMedicoesArray[0].export_active_energy = 0;
      somaMedicoesArray[0].import_reactive_energy = 0;
      somaMedicoesArray[0].export_reactive_energy = 0;
    }
    // O primeiro valor deve ser zero, pois não há pacote anterior para comparar

    somaMedicoesArray.forEach((medicao, index) => {
      const date = new Date(medicao.data);
      medicao.data = `${date.getHours()}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    });

    setGeracaoDiaria(somaMedicoesArray);
    setLoading(false);
  };

  async function getLatestMeasurements() {
    const promises = gemeos?.flatMap((device) =>
      device.uuid_circuit.map((circuit) => getCurrentMeasure(circuit))
    );

    const responses = await Promise.all(promises);

    if (responses?.length > 0) {
      const data = responses.flat().map((response) => {
        if (response !== undefined && response.data !== undefined) {
          const uuid_circuit = response.data.data.uuid_circuit || '';
          const measurementData = response.data.data.lastMeasure || '';
          if (measurementData !== '') {
            return mapToMeasurement(measurementData);
          } else if (uuid_circuit !== '') {
            return { 'uuid_circuit': uuid_circuit };
          }
          return '';
        }
      });
      if (data.every((medicao) => medicao === '')) {
        return [];
      }
      return data;
    }

    return [];
  }

  async function getAllClimaByDia(dia) {
    const dataInicial = new Date(dia);
    const dataFinal = new Date(dataInicial);
    if (dataInicial.getDate() === new Date().getDate()) {
      dataFinal.setTime(new Date().getTime());
    } else {
      dataFinal.setDate(dataFinal.getDate() + 1);
    }

    const dateinitial = new Date(Date.parse(dataInicial));
    const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0, 0);
    const initialDateZeroHour = new Date(
      initialDateHistoricConverted
    ).toISOString();

    const datefinal = new Date(Date.parse(dataFinal));
    const finalDateZeroHour = new Date(datefinal).toISOString();
    let climas = [];
    let lastEvaluatedKey = null;
    do {
      const response = await ioseAPI.getClimaUltimoDia(
        headers,
        initialDateZeroHour,
        finalDateZeroHour,
        lastEvaluatedKey,
        300
      );
      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
      climas = climas.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);
    return climas;
  }

  async function getAllMeasureAllCircuitsRequisition(diaria = false, data) {
    console.log(data, diaria);
    if (diaria) {
      const dataFinal = new Date(data);
      const dataInicial = new Date(dataFinal);
      dataInicial.setDate(dataInicial.getDate());

      const dateinitial = new Date(Date.parse(dataInicial));
      const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0, 0);
      const initialDateZeroHour = new Date(
        initialDateHistoricConverted
      ).toISOString();

      const datefinal = new Date(Date.parse(dataFinal));
      const finalDateZeroHour = new Date(datefinal).toISOString();
      let allDataMeasuresCircuits = [];
      const fetchData = async (gemeo) => {
        if (gemeo.uuid_circuit.length > 1) {
          for (const circuito of gemeo.uuid_circuit) {
            let lastEvaluatedKey = 0;
            do {
              const iot = await ioseIotAPI.getAllMeasure(
                headers,
                circuito,
                initialDateZeroHour,
                finalDateZeroHour,
                lastEvaluatedKey,
                300
              );
              const { data, next_page: newLastEvaluatedKey } = iot.data;
              allDataMeasuresCircuits = allDataMeasuresCircuits.concat(data);
              lastEvaluatedKey = newLastEvaluatedKey;
            } while (lastEvaluatedKey);
          }
        }
      };

      const promises = gemeos?.map(fetchData);
      await Promise.all(promises);

      return allDataMeasuresCircuits;
    } else {
      const allDataMeasuresCircuits = [];

      const dataFinal = new Date(finalDateConsumo);
      const dataInicial = new Date(initialDateConsumo);
      dataInicial.setHours(0, 0, 0);

      const dateinitial = dataInicial;
      const initialDateHistoricConverted = dateinitial.setSeconds(0, 0);
      const initialDateZeroHour = new Date(
        initialDateHistoricConverted
      ).toISOString();

      const datefinal = dataFinal;
      // const finalDateHistoricConverted = dataFinal.setSeconds(0, 0);
      console.log(initialDateZeroHour, dataFinal.toISOString());
      const finalDateZeroHour = dataFinal.toISOString();
      console.log(
        finalDateZeroHour,
        initialDateZeroHour,
        dataFinal.toISOString()
      );
      const diferencaEmDias = Math.ceil(
        (datefinal - dataInicial) / (1000 * 60 * 60 * 24)
      );

      if (diferencaEmDias > 31) {
        setErroPeriodo('O período selecionado não pode ser maior que 30 dias.');
        return [];
      }

      gemeos?.forEach((gemeo) => {
        if (gemeo.uuid_circuit.length > 1) {
          gemeo.uuid_circuit.forEach(async (circuit) => {
            const iot = ioseIotAPI.getLastMeasure(
              headers,
              circuit,
              initialDateZeroHour,
              finalDateZeroHour
            );
            allDataMeasuresCircuits.push(iot);
          });
        }
      });

      return Promise.all(allDataMeasuresCircuits);
    }
  }

  function convertToKWH(measuresWatts) {
    const measuresConverted = Number((measuresWatts / 1000).toFixed(2));
    return measuresConverted;
  }

  async function prepareData(data) {
    const promisesMeasures = await getAllMeasureAllCircuitsRequisition(
      false,
      data
    );
    let measures = [];
    promisesMeasures.forEach((measure) => {
      measures.push(measure.data || []);
    });
    let id = gemeos?.map((gemeo) => {
      return gemeo.uuid_circuit.map((uuid) => {
        return uuid;
      });
    });

    measures = measures.flat();
    id = id.flat();

    id = [...new Set(id)];
    let chartData = [];

    let selectedMeasures = measures;

    selectedMeasures = selectedMeasures.map((measure) => measure.data);

    if (selectedMeasures.length > 0) {
      const arrayDate = selectedMeasures[0].measures.dates;

      // Sum all generate data
      let arrayGenerated = selectedMeasures.map(
        (data) => data.measures.arrayGenerate
      );
      arrayGenerated = arrayGenerated.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      let arrayReactiveConsumed = selectedMeasures.map(
        (data) => data.measures.arrayReactiveConsumed
      );
      arrayReactiveConsumed = arrayReactiveConsumed.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      let arrayReactiveGenerated = selectedMeasures.map(
        (data) => data.measures.arrayReactiveGenerate
      );
      arrayReactiveGenerated = arrayReactiveGenerated.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      let arrayConsumed = selectedMeasures.map(
        (data) => data.measures.arrayConsumed
      );

      arrayConsumed = arrayConsumed.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );
      let arrayConsumedPredicted = selectedMeasures.map(
        (data) => data.measures.arrayConsumedPredicted
      );
      arrayConsumedPredicted = arrayConsumedPredicted.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      let primeiroValorConsumido = 0;
      let primeiroValorGerado = 0;
      let primeiroValorReativoGerado = 0;
      let primeiroValorReativoConsumido = 0;
      for (let i = 0; i < arrayDate.length; i++) {
        if (
          arrayConsumed[i] > 0 ||
          arrayGenerated[i] > 0 ||
          arrayReactiveConsumed[i] > 0 ||
          arrayReactiveGenerated[i] > 0
        ) {
          primeiroValorConsumido = arrayConsumed[i];
          primeiroValorGerado = arrayGenerated[i];
          primeiroValorReativoConsumido = arrayReactiveConsumed[i];
          primeiroValorReativoGerado = arrayReactiveGenerated[i];
          break;
        }
      }

      for (let i = 0; i < arrayDate.length; i++) {
        let arrayDateConverted = new Date(arrayDate[i]);
        let month = arrayDateConverted.getMonth() + 1;
        let day = arrayDateConverted.getDate();

        const dateFormated = `${day}/${('00' + month).slice(-2)}`;
        if (
          (arrayConsumed[i] > 0 && arrayConsumed[i - 1] > 0) ||
          (arrayGenerated[i] > 0 && arrayGenerated[i - 1] > 0) ||
          (arrayReactiveConsumed[i] > 0 && arrayReactiveConsumed[i - 1] > 0) ||
          (arrayReactiveGenerated[i] > 0 && arrayReactiveGenerated[i - 1] > 0)
        ) {
          chartData.push({
            'date': dateFormated,
            'consumido': convertToKWH(
              arrayConsumed[i] - primeiroValorConsumido
            ),
            'gerado': convertToKWH(arrayGenerated[i] - primeiroValorGerado),
            'balanço': convertToKWH(
              arrayConsumed[i] -
                primeiroValorConsumido -
                (arrayGenerated[i] - primeiroValorGerado)
            ),
            'previsao': convertToKWH(arrayConsumedPredicted[i]),
            'reativoConsumido': convertToKWH(
              arrayReactiveConsumed[i] - primeiroValorReativoConsumido
            ),
            'reativoGerado': convertToKWH(
              arrayReactiveGenerated[i] - primeiroValorReativoGerado
            ),
          });
        }
      }

      let chartDataDaily = chartData.map((currentDay, index) => {
        const lastDay = chartData[index - 1];

        const arrayDate = currentDay.date.split('/');
        const defaultData = new Date();
        defaultData.setDate(arrayDate[0]);
        defaultData.setMonth(arrayDate[1] - 1);
        const lessThanToday = defaultData <= new Date();

        let newConsumido = 0;
        let newGenerated = 0;
        let newBalance = 0;
        let newReativoConsumido = 0;
        let newReativoGerado = 0;

        if (lastDay && lessThanToday) {
          newConsumido = Number(
            (currentDay.consumido - lastDay.consumido).toFixed(2)
          );

          newGenerated = Number(
            (currentDay.gerado - lastDay.gerado).toFixed(2)
          );

          newBalance = Number(
            (currentDay.balanço - lastDay.balanço).toFixed(2)
          );
          newReativoConsumido = Number(
            (currentDay.reativoConsumido - lastDay.reativoConsumido).toFixed(2)
          );
          newReativoGerado = Number(
            (currentDay.reativoGerado - lastDay.reativoGerado).toFixed(2)
          );
        } else {
          newConsumido = currentDay.consumido;
          newGenerated = currentDay.gerado;
          newBalance = currentDay.balanço;
          newReativoConsumido = currentDay.reativoConsumido;
          newReativoGerado = currentDay.reativoGerado;
        }
        return {
          'date': currentDay.date,
          'consumido': newConsumido,
          'gerado': newGenerated,
          'balanço': newBalance,
          'previsao': 0,
          'reativoGerado': newReativoGerado,
          'reativoConsumido': newReativoConsumido,
        };
      });
      setConsumoCircuito(chartDataDaily);
    }
  }

  const onElementSelected = (id_3d) => {
    const gemeo = gemeos?.find((gemeo) => gemeo.id_3d === id_3d);

    setShowOffCanvas(true);

    setLatestMeasurements((prevLatestMeasurements) => {
      const response = [];
      gemeo.uuid_circuit.forEach((circuito) => {
        const measurementIndex = prevLatestMeasurements.findIndex(
          (m) => m.uuid_circuit === circuito
        );

        if (measurementIndex !== -1) {
          const measurementWithoutObjectInfo = {
            ...prevLatestMeasurements[measurementIndex],
          };

          const measureInfo = {
            ...measurementWithoutObjectInfo,
          };

          const combinedArray = [measureInfo];

          response.push(combinedArray);
          return combinedArray;
        }
        response.push(prevLatestMeasurements);
      });
      setSelectedMeasurement(response);
      setShowOffCanvas(true);
      return prevLatestMeasurements;
    });
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <S.SubHeaderDiv>
        <IoseBackButton onClick={() => goBack()} />
      </S.SubHeaderDiv>
    );
  };

  const render3dViewer = () => {
    // console
    //   .log
    //   // !consumoCiruito || consumoCiruito.length === 0
    //   // !category ||
    //   // category.length === 0
    //   ();
    // if (
    //   !consumoCiruito ||
    //   consumoCiruito.length === 0 ||
    //   !category ||
    //   category.length === 0
    // )
    // return <LoadingIndicator loading={loading} />;
    return (
      <SpeckleViewer
        data={latestMeasurements}
        consumo={consumoCiruito}
        estimado={category.totais.horas}
        onElementSelected={onElementSelected}
        GraficoMes={GraficoEstimadosMes}
        unityData={unityData}
        gemeos={gemeos}
      />
    );
  };

  const renderConsumo = () => {
    if (
      !consumoCiruito ||
      consumoCiruito.length === 0 ||
      !category ||
      category.length === 0
    )
      return <LoadingIndicator loading={loading} />;
    return (
      <div style={{ width: '100%' }}>
        <div style={{ marginTop: '30px' }}>
          <GraficoConsumo
            data={consumoCiruito}
            fimSemana={category.totais.horas / 5}
            semana={category.totais.horas}
            goal={category.totais.horas}
          />
        </div>
      </div>
    );
  };

  // const renderConsumoGeral = () => {
  //   if (!consumo || consumo.length === 0 || !category || category.length === 0) return <LoadingIndicator loading={loading}/>
  //   return (
  //     <div>
  //       <S.HeaderContainer>
  //         <div style={{ display: 'flex' }}>
  //           <S.ContractsIcon />
  //           <S.TypographyTitle>CONSUMOS DO ULTIMO MÊS (kWh)</S.TypographyTitle>
  //         </div>
  //       </S.HeaderContainer>
  //       <div style={{ marginTop: '30px' }}>
  //         <GraficoConsumoObjetos
  //           consumos={[consumo.consumption]}
  //           objetos={[(category.totais.horas) * 31]}
  //           faturas={[consumo.consumption_fatura]}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  const renderGeracao = () => {
    if (!consumoCiruito || consumoCiruito.length === 0)
      return <LoadingIndicator loading={loading} />;
    let mensal = [];
    for (let index = 0; index < consumoCiruito.length; index++) {
      mensal.push(unityData.geracao_estimada);
    }
    return (
      <div style={{ width: '100%' }}>
        <div style={{ marginTop: '30px' }}>
          <GraficoGeracao data={consumoCiruito} goal={mensal} />
        </div>
      </div>
    );
  };

  // const renderGeracaoGeral = () => {
  //   if (!consumo || consumo.length === 0 || !category || category.length === 0) return <LoadingIndicator loading={loading}/>
  //   return (
  //     <div>
  //       <S.HeaderContainer>
  //         <div style={{ display: 'flex' }}>
  //           <S.ContractsIcon />
  //           <S.TypographyTitle>GERAÇÃO DO ULTIMO MÊS (kWh)</S.TypographyTitle>
  //         </div>
  //       </S.HeaderContainer>
  //       <div style={{ marginTop: '30px' }}>
  //         <GraficoGeracaoGeral
  //           estimado={[unityData.geracao_estimada*30]}
  //           geracao={[consumo.consumption]}
  //           faturas={[consumo.consumption_fatura]}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  const renderTempo = () => {
    const gerenciarDataGeracao = (dateInitial) => {
      let dateInitialISOString = dateInitial.toISOString();
      setInicialDataGeracaoDiaria(dateInitialISOString);
    };
    return (
      <S.ConsumoContainer>
        <div style={{ width: '100%' }}>
          <S.HeaderContainer>
            <div style={{ display: 'flex' }}>
              <S.ContractsIcon />
              <S.TypographyTitle>
                CLIMA X GERAÇÃO X CONSUMO - DIA
              </S.TypographyTitle>
            </div>
          </S.HeaderContainer>
          <S.FilterContainer>
            <IoseSelectDayMouth
              title="Dia"
              theme="black"
              getDate={gerenciarDataGeracao}
              initialDate={initialDateGeracaoDiaria}
              margin={'0xp 50px 0px 0px'}
            />

            <IoseButton
              theme="black"
              onClick={() => prepareDataGeracao(initialDateGeracaoDiaria)}
            >
              FILTRAR
            </IoseButton>
          </S.FilterContainer>
          <div style={{ width: '100%' }}>
            <GraficoClima geracao={geracaoDiaria} />
            <GraficoGeracaoTempo geracao={geracaoDiaria} />
            <GraficoConsumoTempo geracao={geracaoDiaria} />
          </div>
        </div>
      </S.ConsumoContainer>
    );
  };

  const renderAgendamentos = (selectedMeasurement, agendamentos) => {
    if (
      !selectedMeasurement ||
      selectedMeasurement.length === 0 ||
      !agendamentos ||
      !agendamentosComDescricao
    )
      return <LoadingIndicator loading={loading} />;

    return (
      <IoseCardMedicoes
        loading={loading}
        medicoes={selectedMeasurement}
        agendamentos={agendamentos}
        selecionados={agendamentosComDescricao}
      />
    );
  };
  const renderOffCanvas = () => {
    return (
      <OffCanvas
        name={selectedMeasurement?.uuid_circuit}
        show={showOffCanvas}
        onHide={onHideOffCanvas}
      >
        {/* {renderJSXForMeasurement(selectedMeasurement)} */}
        {renderAgendamentos(selectedMeasurement, agendamentos.flat())}
      </OffCanvas>
    );
  };

  const renderObjetos = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.ConsumoContainer>
          <div style={{ alignContent: 'center', width: '100%' }}>
            <S.HeaderContainer>
              <div style={{ display: 'flex' }}>
                <S.ContractsIcon />
                <S.TypographyTitle>
                  CONSUMOS ESTIMADOS DOS OBJETOS (W)
                </S.TypographyTitle>
              </div>
              <IoseButton2 onClick={() => exportToExcel(category)} width="80px">
                <span style={{ marginRight: '5px' }}>XLSX</span>
                <GetAppIcon />
              </IoseButton2>
            </S.HeaderContainer>
            <S.StyledTable>
              <S.TableHeadStyled>
                <S.TableRowStyled>
                  <TableHeaderCellCentered>NOME</TableHeaderCellCentered>
                  <TableHeaderCellCentered>
                    POTENCIA (W)
                  </TableHeaderCellCentered>
                  <TableHeaderCellCentered>QUANTIDADE</TableHeaderCellCentered>
                  <TableHeaderCellCentered>
                    CONSUMO (kWh)
                  </TableHeaderCellCentered>
                </S.TableRowStyled>
              </S.TableHeadStyled>
              <TableBody>
                {Object.entries(category).map(([key, objeto]) => {
                  if (objeto.total > 0 && objeto.nome !== 'Totais') {
                    return (
                      <S.TableRowStyled>
                        <TableCellCentered>{objeto.nome}</TableCellCentered>
                        <TableCellCentered>
                          {objeto.total.toFixed(2).toString().replace('.', ',')}
                        </TableCellCentered>
                        <TableCellCentered>
                          {objeto.quantidade}
                        </TableCellCentered>
                        <TableCellCentered>
                          {objeto.horas.toFixed(2).toString().replace('.', ',')}
                        </TableCellCentered>
                      </S.TableRowStyled>
                    );
                  }
                })}
              </TableBody>
              <TableFooter>
                <S.TableRowStyled>
                  <TableCellCentered>{category.totais.nome}</TableCellCentered>
                  <TableCellCentered>
                    {category.totais.total
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </TableCellCentered>
                  <TableCellCentered>
                    {category.totais.quantidade}
                  </TableCellCentered>
                  <TableCellCentered>
                    {category.totais.horas
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')}
                  </TableCellCentered>
                </S.TableRowStyled>
              </TableFooter>
            </S.StyledTable>
          </div>
        </S.ConsumoContainer>
      );
    }
  };

  const renderAllGraps = () => {
    const gerenciarDataFinalConsumo = (data) => {
      let dataISOString = data.toISOString();
      setFinalDataConsumo(dataISOString);
    };

    const gerenciarDataInicialConsumo = (data) => {
      let dataISOString = data.toISOString();
      setInicialDataConsumo(dataISOString);
    };
    return (
      <S.ConsumoContainer>
        <div style={{ flexDirection: 'row', width: '100%' }}>
          <S.HeaderContainer>
            <div style={{ display: 'flex' }}>
              <S.ContractsIcon />
              <S.TypographyTitle>
                CONSUMO E GERAÇÃO DE ENERGIA - PERÍODO{' '}
              </S.TypographyTitle>
            </div>
          </S.HeaderContainer>
          <S.FilterContainer>
            <IoseSelectDayMouth
              title="Data inicial"
              theme="black"
              getDate={gerenciarDataInicialConsumo}
              initialDate={initialDateConsumo}
              margin={'0xp 50px 0px 0px'}
            />

            <IoseSelectDayMouth
              title="Data final"
              theme="black"
              getDate={gerenciarDataFinalConsumo}
              initialDate={finalDateConsumo}
              margin={'0xp 50px 0px 0px'}
            />

            <IoseButton theme="black" onClick={() => prepareData(new Date())}>
              FILTRAR
            </IoseButton>

            {erroPeriodo ? (
              <S.ErrorText>Período máximo de 30 dias.</S.ErrorText>
            ) : null}
          </S.FilterContainer>
          <div style={{ width: '100%' }}>
            {renderGraphsConsumo()}
            <div style={{ height: '20px' }}></div>
            {renderGraphsGeracao()}
          </div>
        </div>
      </S.ConsumoContainer>
    );
  };

  const renderGraphsConsumo = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.GraphConsumoDiv>
          {renderConsumo()}
          <Iose3dListSummaryConsumo data={consumoCiruito} />
          {/* <S.GraphDiv>
          {renderConsumoGeral()}
            </S.GraphDiv> */}
        </S.GraphConsumoDiv>
      );
    }
  };

  const renderGraphsGeracao = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.GraphConsumoDiv>
          {renderGeracao()}
          <Iose3dListSummaryGeracao data={consumoCiruito} loading={loading} />
          {/* <S.GraphDiv>
            {renderGeracaoGeral()}
          </S.GraphDiv> */}
        </S.GraphConsumoDiv>
      );
    }
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {hasReadPermission
            ? render3dViewer()
            : setError(
                'Seu grupo de usuários não tem permissão de visualização destes dados!'
              )}
        </>
      );
    }
  };

  if (loading) return <LoadingIndicator loading={loading} />;
  return (
    <S.WrapContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
      {renderOffCanvas()}
      {renderAllGraps()}
      {renderTempo()}
      {renderObjetos()}
    </S.WrapContainer>
  );
}
