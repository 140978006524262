import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseSearch,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';

import {
  useClient,
  Messages,
  LocalStorage,
  AccountContext,
  ioseAPI,
} from 'common';

import { useTariffs, useCreateLogs, useGroupPermissions, useNewTariffs } from 'hooks';
import { IoseAddTariffsModal } from 'components/IoseNewModals';
import IoseTableTarrifsContainer from 'components/IoseTableTarrifsContainer';

import Permissions from 'common/permissions';

export default function TariffsContainer({ getcards }) {
  const [tariffsFiltered, setTariffsFiltered] = useState([]);
  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [addNotification, setAddNotification] = useState('');
  const [notificationType, setNotificationType] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { tarifas } = useNewTariffs()
  const { createLog } = useCreateLogs();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'tarifas', 'leitura'];
    const addPath = ['cadastro', 'tarifas', 'criar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  function errorHandlingAddTariffs(error) {
    setLoading(false);
    setNotificationType(2);
  }

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const addTariff = async (
    tarifa,
    subgrupo,
    posto,
    modalidade,
    inicio_igencia,
    fim_vigencia,
    valor,
    porcentagem
  ) => {
    // setEditNotificationType(0);
    // setOperationLoading(true);
    try {
      const { headers } = await getSession();

      const response = await ioseAPI.createTariff(
        headers,
        tarifa,
        subgrupo,
        posto,
        modalidade,
        inicio_igencia,
        fim_vigencia,
        valor,
        porcentagem
      );

      setAddNotification(response.data.message);
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR TARIFA';
      const logContent = {
        tarifa: tarifa,
        subgrupo: subgrupo,
        posto: posto,
        modalidade: modalidade,
        inicio_igencia: inicio_igencia,
        fim_vigencia: fim_vigencia,
        valor: valor,
        porcentagem: porcentagem,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      errorHandlingAddTariffs(errorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const filterContracts = (searchName = '') => {
    setError('');

    if (searchName !== '') {
      const searchNamerUppercase = searchName.toUpperCase();
      const itemFiltered = tarifas?.filter((item) =>
        item?.modalidade?.includes(searchNamerUppercase)
      );

      if (itemFiltered && itemFiltered.length !== 0) {
        setTariffsFiltered(itemFiltered);
      } else {
        setTariffsFiltered([]);
        setError(Messages.noFindTariffs);
      }
    } else {
      setTariffsFiltered([]);

      if (!tarifas || tarifas.length === 0) {
        setError(Messages.noFindTariffs);
      }
    }
  };

  const filteredData =
    tariffsFiltered?.length > 0 ? tariffsFiltered : tarifas;

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR TARIFA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title="Tarifas"
        description={`Total: ${tarifas?.length ? tarifas?.length : 0}`}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar por modalidade tarifária..."
          funcSearch={filterContracts}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <IoseTableTarrifsContainer
          tableTitle="TABELA DE TARIFAS"
          searchInTable={false}
          data={filteredData}
        />
      </S.ContainerTable>
    );
  };

  const renderContent = () => {
    if (error) {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>
            {hasReadPermission
              ? renderTable()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </div>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <IoseAddTariffsModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        modalHeaderTitle="TARIFAS"
        modalTitle="CADASTRAR TARIFA"
        submit={addTariff}
        notificationType={notificationType}
        showNotification={showNotification}
        addNotification={addNotification}
        loading={operationLoading}
      />
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>
            {renderContent()}
            {renderModals()}
          </>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

TariffsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
