import React from "react";
import { AclIcon, BillsIcon, EnergyBillIcon, IconWrapper, SidebarContainer } from "../style";
import { Tooltip } from "@material-ui/core";

export default function BillsModalRightSidebar({ visualization, setVisualization }) {
  return (
    <SidebarContainer>
      <IconWrapper
        onClick={() => setVisualization('bill')}
        isSelected={visualization === 'bill'}
      >
        <Tooltip title="FATURA" placement="bottom" arrow>
          <EnergyBillIcon />
        </Tooltip>
      </IconWrapper>

      <IconWrapper
        onClick={() => setVisualization('extract')}
        isSelected={visualization === 'extract'}
      >
        <Tooltip title="EXTRATO" placement="bottom" arrow>
          <BillsIcon />
        </Tooltip>
      </IconWrapper>

      <IconWrapper
        onClick={() => setVisualization('acl')}
        isSelected={visualization === 'acl'}
      >
        <Tooltip title="ACL" placement="bottom" arrow>
          <AclIcon />
        </Tooltip>
      </IconWrapper>
    </SidebarContainer>
  )
}