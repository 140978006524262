import React, { useState, useEffect } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DragabbleContainerCard } from './style';
import ConcessionaireLineChart from 'components/Charts/ConcessionaireLineChart';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DraggableContainer = () => {
  const [layouts, setLayouts] = useState({
    lg: [
      {
        'w': 6,
        'h': 8,
        'x': 0,
        'y': 0,
        'i': '0',
        'moved': false,
        'static': false,
      },
      {
        'w': 6,
        'h': 8,
        'x': 6,
        'y': 0,
        'i': '1',
        'moved': false,
        'static': false,
      },
      {
        'w': 12,
        'h': 8,
        'x': 0,
        'y': 8,
        'i': '2',
        'moved': false,
        'static': false,
      },
    ],
  });
  const [allLayouts, setAllLayouts] = useState([]);

  useEffect(() => {
    // Atualize o estado allLayouts sempre que layouts mudar
    setAllLayouts(layouts.lg);
  }, [layouts]);

  const handleAddContainer = () => {
    const newLayout = {
      i: layouts.lg.length.toString(),
      x: 0,
      y: Infinity,
      w: 12,
      h: 4,
    };

    setLayouts({ lg: [...layouts.lg, newLayout] });
  };

  const handleRemoveContainer = (i) => {
    const updatedLayouts = {
      lg: layouts.lg.filter((layout) => layout.i !== i),
    };
    setLayouts(updatedLayouts);
  };

  const handleResize = (layout, oldItem, newItem, placeholder, e, element) => {
    const updatedAllLayouts = allLayouts.map((l) =>
      l.i === newItem.i ? newItem : l
    );
    setAllLayouts(updatedAllLayouts);
  };

  return (
    <div>
      <button onClick={handleAddContainer}>Adicionar</button>
      <ResponsiveReactGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        width={1200}
        isResizable={true}
        onResize={handleResize}
      >
        {layouts.lg.map((layout) => (
          <div key={layout.i}>
            <DragabbleContainerCard>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Container {layout.i}</div>
                <button onClick={() => handleRemoveContainer(layout.i)}>
                  X
                </button>
              </div>

              <ConcessionaireLineChart />
            </DragabbleContainerCard>
          </div>
        ))}
      </ResponsiveReactGridLayout>
    </div>
  );
};

export default DraggableContainer;
