const Permissions = {};

Permissions.hasPermissions = (userSession, groupPermissions, path) => {
  const userGroup = groupPermissions?.find(
    (group) => group.group_name === userSession.group
  );

  if (!userGroup) {
    return false;
  }

  const permissions = userGroup?.permissions;

  const targetPermission = path?.reduce((acc, key) => {
    return acc && acc[key];
  }, permissions);

  return targetPermission === true;
};

export default Permissions;
