import React, { useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination } from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function ordenarPorData(array) {
  function converterParaData(stringData) {
    const [diaMes, hora] = stringData.split(' ');
    const [dia, mes] = diaMes.split('/');
    const [horaStr, minutoStr] = hora.split(':');
    const anoAtual = new Date().getFullYear();
    return new Date(anoAtual, parseInt(mes) - 1, parseInt(dia), parseInt(horaStr), parseInt(minutoStr));
  }

  function compararDatas(a, b) {
    const dataA = converterParaData(a.arrival_date);
    const dataB = converterParaData(b.arrival_date);
    return dataB - dataA;
  }

  return array?.sort(compararDatas);
}

export default function TelemetryMeasuresTable({ loading, data }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const datasOrdenadas = ordenarPorData(data);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = datasOrdenadas?.slice(startIndex, endIndex);

  function renderChartOrErrorMessage() {
    if (datasOrdenadas && datasOrdenadas.length > 0) {
      return <LogsTable data={paginatedData} id="scrollableDiv" />;
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="150px"
          width="150px"
        />
      );
    }
  }

  const LogsTable = ({ data, onRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>CIRCUITO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CONSUMO ATIVO (Wh)</TableHeaderCellCentered>
          <TableHeaderCellCentered>CONSUMO REATIVO (VArh)</TableHeaderCellCentered>
          <TableHeaderCellCentered>DEMANDA ATIVA (W)</TableHeaderCellCentered>
          <TableHeaderCellCentered>DEMANDA REATIVA (VAr)</TableHeaderCellCentered>
          <TableHeaderCellCentered>DATA / HORA</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <LogsTableRow key={index} value={value} onClick={onRowClick} index={index} />
        ))}
      </TableBody>
    </StyledTable>
  );

  const LogsTableRow = ({ value, onClick, index }) => {
    const classes = useStyles();

    return (
      <TableRowStyled align="center" className={classes.tableRow}>
        <TableCellCentered>{value?.numero_circuito}</TableCellCentered>
        <TableCellCentered>{value?.potencia_a_wh_q1}</TableCellCentered>
        <TableCellCentered>{value?.potencia_reativa_a_varh_q1}</TableCellCentered>
        <TableCellCentered>{value?.demanda_energia_ativa_q1}</TableCellCentered>
        <TableCellCentered>{value?.demanda_energia_reativa_q1}</TableCellCentered>
        <TableCellCentered>{value?.arrival_date}</TableCellCentered>
      </TableRowStyled>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <IoseInfinityScroll
            dataLength={datasOrdenadas ? parseInt(datasOrdenadas.length) : 10}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={350}
          >
            {renderChartOrErrorMessage()}
          </IoseInfinityScroll>

          <TablePagination
            component="div"
            count={datasOrdenadas?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Itens por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            backIconButtonText="Página anterior"
            nextIconButtonText="Próxima página"
          />
        </>
      )}
    </div>
  );
}
