import styled from 'styled-components';

//Import from Material UI Components
import { Grid, Button, Typography } from '@material-ui/core';

//Import from React
import { Link } from 'react-router-dom';

//Imports Images
import PhotoCover from './img/temporaria.jpg';
import LogoIose from './img/logo-iose-black.svg';
import ChangePasswordIllustration from './img/password.svg';

//Grids
export const GridMain = styled(Grid).attrs(() => ({
  container: true,
}))`
  height: 100vh;
`;

export const Container = styled.div`
  height: 100vh;
  background-image: url(${PhotoCover});
  background-repeat: no-repeat;
  background-color: var(--base);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridImage = styled(Grid).attrs(() => ({
  item: true,
  xs: false,
  sm: 4,
  md: 7,
}))`
  background-image: url(${PhotoCover});
  background-repeat: no-repeat;
  background-color: var(--base);
  background-size: cover;
  background-position: 'center';
`;

export const GridContent = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 8,
  md: 5,
}))`
  background-image: linear-gradient(to top, #fff 10%, #fff, #fff);
  padding-top: 100px;
`;

export const FormLogin = styled.div`
  background-image: linear-gradient(to top, #fff 10%, #fff, #fff);
  width: 500px;
  max-height: 850px;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

//Images
export const Logo = styled.img.attrs(() => ({
  src: LogoIose,
}))`
  background-size: contain;
  margin: 20px 20px 10px;
  height: 100px;
  width: 200px;

  @media (max-width: 1360px) {
    height: 100px;
    width: 150px;

    margin: 0px 20px 0px;
  }

  @media (max-width: 767px) {
    height: 75px;
    width: 195px;
  }
`;

export const Illustration = styled.img.attrs(() => ({
  src: ChangePasswordIllustration,
}))`
  background-size: contain;
  margin: 0px 20px 0px;
  height: 150px;
  width: 270px;

  @media (max-width: 1360px) {
    margin: 0px 20px 0px;
    height: 100px;
    width: 220px;
  }
`;

//Typography
export const ErroText = styled(Typography)`
  color: var(--alert);
  font-weight: bold;
  text-align: center;
`;

export const InstructionsText = styled(Typography)`
  margin: 5px 0%;
  text-align: center;
`;

//Others
export const BoxLoginContent = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1360px) {
    height: 500px;
  }
`;

export const MethodLoginContent = styled.div`
  height: 200px;
  margin: 25px;
`;

export const Form = styled.form`
  margin-top: 10%;
  text-align: right;
`;

export const ButtonConfirmation = styled(Button).attrs(() => ({
  type: 'submit',
  fullWidth: true,
  variant: 'contained',
}))`
  background-color: var(--primary);
  margin: 25px 0 15px 0;

  span {
    color: var(--secondary);
  }

  &:hover {
    background-color: var(--base);
    span {
      color: var(--primary);
    }
  }
`;

export const LinkForgotPassword = styled(Link)`
  color: var(--alert);

  &:hover {
    font-weight: bold;
  }
`;
