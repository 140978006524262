import React from 'react';

import {
  AttachMoney as AttachMoneyIcon,
} from '@material-ui/icons';

import * as S from './style';

import {
  LoadingValueCards,
} from 'components';

const tranformaReal = (dado) => {
  return dado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}
export default function CardsSimuladorTarifarioMercadoLivre({
  loading,
  totalMercadoLivreAzul,
  totalMercadoLivreVerde
}) {  
  

  const cardsData = [
    {
      icon: <AttachMoneyIcon />,
      title: 'VALORES TARIFÁRIOS VERDE (R$)',
      data: [
        {
          key: 'TOTAL:',
          value:
            totalMercadoLivreVerde !== undefined
              ? tranformaReal(totalMercadoLivreVerde)
              : 0,
        },
        {
          key: 'MENSAL:',
          value:
            totalMercadoLivreVerde !== undefined
              ? tranformaReal(totalMercadoLivreVerde/12)
              : 0,
        }
      ],
      cor: 'green',
      bgCor: 'var(--base)'
    },
    {
      icon: <AttachMoneyIcon />,
      title: 'VALORES TARIFÁRIOS AZUL (R$)',
      data: [
        {
          key: 'TOTAL:',
          value:
            totalMercadoLivreAzul !== undefined
              ? tranformaReal(totalMercadoLivreAzul)
              : 0,
        },
        {
          key: 'MENSAL:',
          value:
            totalMercadoLivreAzul !== undefined
              ? tranformaReal(totalMercadoLivreAzul/12)
              : 0,
        },
      ],
      cor: 'blue',
      bgCor: 'var(--base)'
    }
  ];

  return (
    <S.CardGrid container spacing={2}> 
      {cardsData.map(({ icon, chart, title, data, cor, bgCor }) => (
        <S.BoxContainer
          key={title}
          style={{ transition: '0.5s ease', backgroundColor: bgCor}}
        >
          <S.HeaderContainer>
            <S.IconHeader style={{color: cor }}>{icon}</S.IconHeader>
            <S.CardTitle style={{color: cor }}>{title}</S.CardTitle>
          </S.HeaderContainer>
          {data.map(({ key, value }) => (
            <S.DataContainer key={key}>
              <S.CardDataKey>{key}</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{value}</S.CardDataValue>
              )}
            </S.DataContainer>
          ))}
          {chart}
        </S.BoxContainer>
      ))}
    </S.CardGrid>
  );
}
