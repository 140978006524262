import {
    Modal,
    Paper,
    Table,
    TableCell,
    TableHead,
    TableRow,
  } from '@material-ui/core';
  import styled from 'styled-components';
  
  export const TableHeadStyled = styled(TableHead)`
    background-color: var(--primary);
    margin-top: 15px;
  `;
  
  export const TableSecondaryHeadStyled = styled(TableHead)`
    background-color: #ecf0f5;
    border-radius: 15px;
    margin-top: 15px;
  `;
  
  export const TableRowStyled = styled(TableRow)`
    border-radius: 10px;
    text-align: center;
  `;
  
  export const TableCellStyled = styled(TableCell)`
    font-size: 12px;
    font-weight: bold;
    align-items: right;
  `;
  
  export const ModalStyled = styled(Modal)`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
  `;
  
  export const ModalPaper = styled(Paper)`
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: space-around;
    border: 2px solid var(--primary);
  `;
  
  export const StyledTable = styled(Table)`
    align-items: right;
    tbody tr:hover {
      background-color: #f5f5f5;
      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
        font-weight: bold;
      }
    }
  `;
  