import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function EnergyBillReport(data) {
  const totalObjects = data.length;
  const totalValue = data.reduce((acc, bill) => acc + bill.account_value, 0);
  const formattedTotalValue = totalValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const logoData = await convertImageToBase64(Logo);

  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: 'Relatório de Faturas de Energia',
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];

  const renderData = data.map((bill) => {
    return [
      {
        text: bill.bill_name,
        fontSize: 8,
        margin: [0, 2, 0, 2],
        alignment: 'center',
      },
      {
        text: bill.identification_number,
        fontSize: 9,
        margin: [0, 2, 0, 2],
        alignment: 'center',
      },
      {
        text: bill.cnpj,
        fontSize: 9,
        margin: [0, 2, 0, 2],
        alignment: 'center',
      },
      {
        text: bill.reference_month,
        fontSize: 9,
        margin: [0, 2, 0, 2],
        alignment: 'center',
      },
      {
        text: bill.account_value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        fontSize: 9,
        margin: [0, 2, 0, 2],
        alignment: 'center',
      },
    ];
  });

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          [
            {
              text: 'Logradouro',
              style: 'tableHeader',
              fontSize: 10,
              alignment: 'center',
              bold: true,
            },
            {
              text: 'Unidade Consumidora',
              style: 'tableHeader',
              fontSize: 10,
              alignment: 'center',
              bold: true,
            },
            {
              text: 'Cnpj',
              style: 'tableHeader',
              fontSize: 10,
              alignment: 'center',
              bold: true,
            },
            {
              text: 'REF: MÊS/ANO',
              style: 'tableHeader',
              fontSize: 10,
              alignment: 'center',
              bold: true,
            },
            {
              text: 'Valor da conta',
              style: 'tableHeader',
              fontSize: 10,
              alignment: 'center',
              bold: true,
            },
          ],
          ...renderData,
        ],
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : null;
        },
      },
    },
  ];

  const summaryTable = {
    table: {
      headerRows: 1,
      widths: ['*', '*'],
      body: [
        [
          {
            text: 'Total de contas',
            style: 'tableHeader',
            fontSize: 10,
            alignment: 'center',
            bold: true,
          },
          {
            text: 'Valor total',
            style: 'tableHeader',
            fontSize: 10,
            alignment: 'center',
            bold: true,
          },
        ],
        [
          { text: totalObjects.toString(), fontSize: 9, alignment: 'center' },
          { text: formattedTotalValue, fontSize: 9, alignment: 'center' },
        ],
      ],
    },
    margin: [0, 20, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 9,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [12, 50, 15, 40],
    content: [details, summaryTable],
    footer: renderFooter,
  };

  // Adicionar o título apenas na primeira página
  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
