import { Overlay } from 'ol';
import { Style, Fill, Stroke, Circle as CircleStyle } from 'ol/style';
import { format } from 'date-fns';

// Função para criar e configurar o pop-up
export function createPopup(map, history) {
  const info = document.createElement('div');
  info.classList.add('info');
  const overlay = new Overlay({ element: info });
  map.addOverlay(overlay);

  const displayPopup = (evt) => {
    info.style.display = 'none';
    info.classList.remove('show');
    info.classList.remove('close');

    const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature);

    if (feature) {
      const regiaoLayer = feature?.getId();
      if (
        regiaoLayer?.indexOf('regiao_telemetria') !== -1 ||
        regiaoLayer?.indexOf('pontos_geo') !== -1 ||
        regiaoLayer?.indexOf('orgao_pagador') !== -1 ||
        regiaoLayer?.indexOf('regiao') !== -1
      ) {
        let nome;
        if (regiaoLayer?.indexOf('regiao_telemetria') !== -1) {
          nome = feature.get('regiao');
        } else if (regiaoLayer?.indexOf('pontos_geo') !== -1) {
          nome = feature.get('orgao_pagador');
        } else if (regiaoLayer?.indexOf('regiao') !== -1) {
          nome = feature.get('nome');
        } else if (regiaoLayer?.indexOf('orgao_pagador') !== -1) {
          nome = feature.get('nome');
        }
        const atributos = `<b><font color="#090e66">Nome: </font color></b>${nome}`;

        info.innerHTML = '';
        info.style.display = atributos ? '' : 'none';
        info.innerHTML = atributos;

        setTimeout(() => {
          info.style.display = 'block';
          info.classList.add('show');
        }, 12);

        overlay.setPosition(evt.coordinate);

        feature.setStyle(
          new Style({
            fill: new Fill({
              color: [245, 229, 9, 0.2],
            }),
            stroke: new Stroke({
              color: [255, 255, 0, 0.5],
              width: 1.5,
            }),
          })
        );

        map.once('click', (event) => {
          const clickedFeature = map.forEachFeatureAtPixel(
            map.getEventPixel(event.originalEvent),
            (feature) => feature
          );

          if (!clickedFeature || clickedFeature !== feature) {
            feature.setStyle(null);
          }
        });
      } else if (
        regiaoLayer?.indexOf('vm_telemetria_total_2') !== -1 ||
        regiaoLayer?.indexOf('vm_telemetria_total') !== -1 ||
        regiaoLayer?.indexOf('view_medicao') !== -1 ||
        regiaoLayer?.indexOf('view_alarms_telemetry_2') !== -1 ||
        regiaoLayer?.indexOf('view_alarms_telemetry') !== -1 ||
        regiaoLayer?.indexOf('view_medicao_2') !== -1 ||
        regiaoLayer?.indexOf('view_sem_comunicacao') !== -1 ||
        regiaoLayer?.indexOf('view_confiabilidade_pacotes') !== -1
      ) {
        function formatarData(dataString) {
          if (dataString === null) {
            return ''; // Retorna uma string vazia se a data for null
          }

          const dataObj = new Date(dataString);
          if (!isNaN(dataObj.getTime())) {
            return format(dataObj, 'dd/MM/yyyy HH:mm:ss');
          } else {
            return 'Data inválida';
          }
        }
        const unidade_operacional = feature?.get('unidade');
        const ultimaComunicacao = formatarData(feature?.get('last_measure_date'));

        const endereco = feature?.get('endereco');
        const concessionaria = feature?.get('concessionaria');
        const identification_number = feature?.get('identification_number');
        const uuid_nulo = feature?.get('uuid_circuit');
        const uuidCircuit = uuid_nulo === null ? 'Sem circuito' : uuid_nulo;
        const regiao = feature?.get('regiao');
        const orgao = feature?.get('orgao_pagador');
        const municipio = feature?.get('municipio');
        const lastMeasure = feature?.get('last_measure');

        // alarmes
        const Queda = feature?.get('queda_energia');
        const Medicao = feature?.get('sem_medicao');
        const resetUsuario = feature?.get('reset_usuario');

        let horarioStatus;

        const alarmes = [];

        if (Queda) {
          alarmes.push('Queda de energia');
        }
        if (Medicao) {
          alarmes.push('Sem medição');
        }
        alarmes.push(horarioStatus);

        let tipos_alarmes;
        if (alarmes && alarmes?.length > 0) {
          const alarmes_definidos = alarmes?.filter((alarme) => alarme !== undefined);
          if (alarmes_definidos?.length > 0) {
            tipos_alarmes = alarmes_definidos?.join(' / ');
          } else {
            tipos_alarmes = 'Sem alarmes';
          }
        } else {
          tipos_alarmes = 'Sem alarmes';
        }

        const displayMeasure = lastMeasure === null ? 0 : lastMeasure;
        let alarmeClasseCSS = '';

        if (tipos_alarmes !== 'Sem alarmes') {
          alarmeClasseCSS = 'alarme-vermelho';
        }

        let atributos =
          `<b><font color="#090e66">ID do circuito: </font color></b>` +
          `<font color="#090e66"> ${uuidCircuit}</a></font>` +
          `<br>` +
          `<b>Unidade consumidora: </b>${identification_number}<br>` +
          `<b>Alarme: </b><span class="${alarmeClasseCSS}">${tipos_alarmes}</span><br>` +
          `<b>Dt. útltima medição: </b>${ultimaComunicacao}<br>` +
          `<b>Última medição: </b>${displayMeasure} kW</br>` +
          `<b>Unidade operacional: </b>${unidade_operacional}<br>` +
          `<b>Endereço: </b>${endereco}</br>` +
          `<b>Município: </b>${municipio}</br>` +
          `<b>Concessionária: </b>${concessionaria}</br>` +
          `<b>Gerencia geral: </b>${regiao}</br>` +
          `<b>Gerencia regional: </b>${orgao}</br>` +
          `<button class="redirect-button" id="myButton">Ir para o Dashboard</button>`;

        info.innerHTML = '';
        info.style.display = atributos ? '' : 'none';
        info.innerHTML = atributos;

        setTimeout(() => {
          info.style.display = 'block';
          info.classList.add('show');
        }, 12);

        overlay.setPosition(evt.coordinate);

        feature.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 10,
              fill: new Fill({ color: [245, 229, 9, 0.7] }),
            }),
          })
        );

        map.on('click', (event) => {
          if (!event.target || !event.target.getProperties().feature) {
            feature.setStyle(null);
          }
        });

        info.style.maxWidth = '100%';
        info.style.width = '100%';
        info.style.padding = '10px';

        const smallScreenQuery = window.matchMedia('(max-width: 600px)');
        if (smallScreenQuery.matches) {
          info.style.maxWidth = '80%';
        }

        atributos = '';

        const featureSession = String(feature?.values_?.identification_number);
        const buttonElement = document.getElementById('myButton');
        buttonElement.addEventListener('click', () => {
          history.push({
            pathname: '/consumer-group-dashboard',
            state: {
              consumerGroup: featureSession,
              uuidCircuit: uuidCircuit,
            },
          });
        });
      }
    }
  };

  map.on('singleclick', displayPopup);
}
