import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import * as S from '../style';
import TableMedicoesCompleta from './TableMedicoesCompletas';
import IoseSchedulerTable from 'components/IoseSchedulerTable';
import { LoadingIndicator } from 'components';
import { CalendarTodayOutlined as AgendaIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function ChartsTable({
  open,
  onClose,
  selectedCard,
  agendamentos,
  medicoes,
  selecionados,
  grupo,
  redirectEditSheduler,
  redirectDeleteSheduler,
  getSchedulingModulesByGroup,
  resendRequistion,
  loading,
}) {
  const classes = useStyles();

  const cardSelecionado = selectedCard?.title;

  const renderTableMedicao = () => {
    const medicoes_selecionadas = [];
    medicoes.forEach((medicao) => {
      if (selectedCard?.circuit === medicao.uuid_circuit) {
        medicoes_selecionadas.push(medicao);
      }
    });
    return (
      <TableMedicoesCompleta
        loading={loading}
        selectedMeasurement={medicoes_selecionadas}
      />
    );
  };
  const renderTableAgendamentos = () => {
    medicoes = medicoes.flat();
    const agendammentos_selecionados = [];
    if (!selecionados) {
      return <LoadingIndicator loading={loading} />;
    }
    agendamentos.forEach((agendamento) => {
      selecionados.forEach((agenda) => {
        if (selectedCard?.circuit === agendamento.uuid_circuit) {
          if (
            agenda.uuid_scheduling_group === agendamento.uuid_scheduling_group
          ) {
            agendammentos_selecionados.push(agenda);
          }
        }
      });
    });
    return (
      <IoseSchedulerTable
        rows={agendammentos_selecionados}
        dashboard="3d"
        group={grupo}
        redirectEditSheduler={redirectEditSheduler}
        deleteScheduler={redirectDeleteSheduler}
        getSchedulingModulesByGroup={getSchedulingModulesByGroup}
        resendRequistion={resendRequistion}
      />
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <BatteryChargingFullIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {`${cardSelecionado}`}
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom />
            <div
              className={classes.tableContainer}
              style={{ position: 'relative' }}
            >
              <S.WrapContainer>
                <S.GraphConsumoDiv>{renderTableMedicao()}</S.GraphConsumoDiv>
              </S.WrapContainer>
              <br></br>
              <S.WrapContainer>
                <S.StyleTitle> Agendamentos do circuito: </S.StyleTitle>
                {renderTableAgendamentos()}
              </S.WrapContainer>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
