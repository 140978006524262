import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}
export default async function energyBillSaneparMonthReport(data) {


  const allPercentages = data?.map((item) => item.porcentagem);
  const geradora = data?.map((item) => item.uc_geradora);
  const mes = data?.map((item) => item.mes_referencia);

  // let indicadorValue = 0;

  const totalGerado = data?.reduce(
    (accumulator, item) => accumulator + item.gerados_pta + item.gerados_fpta,
    0
  );

  const totalPercentage = allPercentages?.reduce(
    (accumulator, currentValue) => {
      const parsedValue = parseFloat(currentValue);
      if (!isNaN(parsedValue)) {
        return accumulator + parsedValue;
      }
      return accumulator;
    },
    0
  );

  const allLength = data
    ?.filter((item) => item.uc_beneficiarias)
    .map((beneficiaria) => ({
      ...beneficiaria,
      uc_beneficiarias: beneficiaria.uc_beneficiarias.filter(
        (item) => item.uc_beneficiaria !== item.uc_geradora
      ),
    }))
    .map((beneficiaria) =>
      beneficiaria.uc_beneficiarias.map((item) => item.uc_beneficiaria)
    );

  // Reduzir a matriz nested em uma única matriz de todas as uc_beneficiarias
  const flattenedBeneficiarias = allLength?.flat();

  // Remover duplica
  const uniqueBeneficiarias = [...new Set(flattenedBeneficiarias)];

  const totalObjects = uniqueBeneficiarias?.length;

  const totaldosCreditos = data?.flatMap((audit) =>
    audit.uc_beneficiarias.map((beneficiaria) => [beneficiaria.cred_recebido])
  );

  const totalCredito = totaldosCreditos.reduce((accumulator, currentValue) => {
    const parsedValue = parseFloat(currentValue);
    if (!isNaN(parsedValue)) {
      return accumulator + parsedValue;
    }
    return accumulator;
  }, 0);

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Créditos da U.C ${geradora} no mês ${mes} `,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 6,
    margin: [15, 2, 10, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('UC Beneficiária'),
    generateHeaderCell('Mês do crédito gerado'),
    generateHeaderCell('Mês do crédito utilizado'),
    generateHeaderCell('Crédito utilizado'),
    generateHeaderCell('Unidade'),
    generateHeaderCell('%'),
  ];
  const renderData = data?.flatMap((audit) =>
    audit.uc_beneficiarias
      .filter(
        (beneficiaria) =>
          beneficiaria.uc_beneficiaria !== audit.uc_geradora &&
          (beneficiaria.mes_cred_utilizado === audit.mes_referencia ||
            beneficiaria.mes_cred_gerado === 'NULL' ||
            beneficiaria.mes_cred_gerado === 'sem benefício') &&
          audit.mes_referencia === beneficiaria.mes_cred_utilizado
      )
      .map((beneficiaria) => [
        generateCell(beneficiaria?.uc_beneficiaria),
        generateCell(beneficiaria?.mes_cred_gerado),
        generateCell(beneficiaria?.mes_cred_utilizado),
        generateCell(beneficiaria?.cred_recebido),
        generateCell('kWh'),
        generateCell(beneficiaria?.porcentagem),
      ])
  );

  const details = [
    {
      table: {
        dontBreakRows: true,

        headerRows: 1,
        widths: Array(6).fill('*'),
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const combinedTables = data.map((bill, index) => {
    const combinedTable = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'UC Geradora',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Gerado PT',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Gerado FP',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Saldo',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Compensado',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },

              {
                text: 'Autocompensado PT',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Autocompensado FP',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Unidade',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },

              {
                text: 'REF: MÊS/ANO',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Validade',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'Vencimento (dias)',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },

              {
                text: '%',
                style: 'tableHeader',
                fontSize: 6,
                alignment: 'center',
                bold: true,
              },
            ],

            [
              {
                text: bill.uc_geradora,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.gerados_pta,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.gerados_fpta,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.total,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },

              {
                text: bill.compensado,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },

              {
                text: bill.autocompensados_pta,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.autocompensados_fpta,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },

              {
                text: bill.unidade,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.mes_referencia,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.validade,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
              {
                text: bill.indicador,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },

              {
                text: totalPercentage,
                fontSize: 6,
                margin: [0, 2, 0, 2],
                alignment: 'center',
              },
            ],
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#CCCCCC' : null;
          },
        },
      },

      [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 2] }],

      details[index],

      [{ text: '', colSpan: 6, fillColor: 'white', margin: [0, 12] }],
    ];
    return combinedTable;
  });

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de unidades beneficiárias',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalObjects?.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total de créditos gerados',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalGerado,
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total de créditos utilizados',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalCredito.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],

        [
          {
            text: '% total',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalPercentage,
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 6,
      bold: true,
      margin: [15, 15, 35, 500], // left, top, right, bottom
    };
  };

  const reportContent = [...combinedTables, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [35, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
  };

  // Adicionar o título apenas na primeira página
  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
