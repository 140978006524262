import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import { AccountContext, ioseAPI, Messages } from 'common';
import { xlsxReports } from 'reports/Xlsx';
import { useCreateLogs } from 'hooks';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${year}${month}`;
  }
  return '';
};

export default function ContabilFiltros({ loading, emptyResponse }) {
  const [selectedDate, setSelectedDate] = useState();

  const { getSession } = useContext(AccountContext)
  const { createLog } = useCreateLogs();

  const initialValues = {
    reference_month: '',
  };

  function formatReferenteMonthDate(dateString) {
    if (dateString.length !== 6) {
        throw new Error('Invalid date string format');
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);

    return `${month}/${year}`;
  }

  function formatCompleteDate(dateString) {
    if (dateString.length !== 8) {
        throw new Error('Invalid date string format');
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${day}/${month}/${year}`;
  }

async function getContabil(reference_month) {
    loading(true);

    try {
        const { headers } = await getSession();

        const response = await ioseAPI.getContabilByReferenceMonth(
            headers,
            reference_month
        );

        let { Items } = response.data;

        const operacao = 'EMITIR RELATÓRIO';
        const form = 'RELATÓRIO CONTÁBIL';
        const logContent = {
          reference_month: reference_month,
        };

        await createLog(operacao, form, logContent);

        if (Items.length === 0 ){
          emptyResponse(true)
        } else {
          Items = Items.map(item => {
            return {
                ...item,
                cop_ano_mes: formatReferenteMonthDate(item.cop_ano_mes),
                cop_dt_emissao: formatCompleteDate(item.cop_dt_emissao),
                cop_dt_apresentacao: formatCompleteDate(item.cop_dt_apresentacao)
            };
        });
        xlsxReports.exportContabilToExcel(Items);
        emptyResponse(false)
      }

    } catch (error) {
        const errorMessage = error.response
            ? error.response.data.message
            : Messages.noRegisteredConsumerGroups;

        console.error(errorMessage);
    } finally {
        loading(false);
    }
}

  const onSubmit = async (values, { resetForm }) => {

    const { reference_month } = values;

    await getContabil(reference_month)

    resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ setFieldValue }) => (
      <Form>
        <S.FormDivContainer>
          <S.FormDivItem>
            <IoseSelectReferenceMonth
              label="REF:MÊS/ANO"
              width="150px"
              handleChange={(value) => {
                const formattedDate = formatToBrazilianDate(value);
                setSelectedDate(value);
                setFieldValue('reference_month', formattedDate);
              }}
              selectedDate={selectedDate}
            />
          </S.FormDivItem>

          <IoseButton height="35px" type="submit">
            EMITIR RELATÓRIO
          </IoseButton>

          {/* <IoseRemoveButton onClick={() => cleanAll(resetForm)}>
            LIMPAR FILTROS
          </IoseRemoveButton> */}
        </S.FormDivContainer>
      </Form>
    )}
  </Formik>
  );
}
