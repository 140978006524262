/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as LayerGroup,
} from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import {
  MousePosition,
  OverviewMap,
  Rotate,
  ScaleLine,
  Zoom,
  FullScreen,
  Attribution,
  ZoomToExtent,
} from 'ol/control';
import { format as formatCoordinate } from 'ol/coordinate';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import { Style, Stroke, Circle as CircleStyle, Fill } from 'ol/style';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import 'ol-ext/dist/ol-ext.css';
import SearchPhoton from 'ol-ext/control/SearchPhoton';
import { useSystemUrls } from 'hooks';

import { getLayerStyle } from './dropdown_menu';

//Import from Map.ccs
import './map.css';

import { useHistory } from 'react-router-dom';

export default function MapComponent() {
  let [open, setOpen] = useState(false);
  let [totalCopel, setTotalCopel] = useState(0);
  let [totalCocel, setTotalCocel] = useState(0);
  let [totalEnergisa, setTotalEnergisa] = useState(0);
  let [totalCeral, setTotalCeral] = useState(0);
  let [totalCelesc, setTotalCelesc] = useState(0);
  let [totalCpfl, setTotalCpfl] = useState(0);
  let [totalForcel, setTotalForcel] = useState(0);
  let [totalCerpa, setTotalCerpa] = useState(0);

  let [totalpontos, setTotalPontos] = useState(0);
  let [totalNao, setTotalNao] = useState(0);

  let [totalCredito, setTotalCredito] = useState(0);

  let [totalTele, setTotalTele] = useState(0);
  let [totalGSO, setTotalGSO] = useState(0);
  let [totalGGML, setTotalGGML] = useState(0);
  let [totalGGSD, setTotalGGSD] = useState(0);
  let [totalGGND, setTotalGGND] = useState(0);
  let [totalGGNO, setTotalGGNO] = useState(0);
  let [totalGGRPV, setTotalGRPV] = useState(0);
  let [totalGRAR, setTotalGRAR] = useState(0);
  let [totalGRCP, setTotalGRCP] = useState(0);
  let [totalGRMA, setTotalGRMA] = useState(0);
  let [totalGRSP, setTotalGRSP] = useState(0);
  let [totalGIDLD, setTotalGIDLD] = useState(0);
  let [totalGRUM, setTotalGRUM] = useState(0);
  let [totalGRAP, setTotalGRAP] = useState(0);
  let [totalGRCM, setTotalGRCM] = useState(0);
  let [totalGRTB, setTotalGRTB] = useState(0);
  let [totalGRTO, setTotalGRTO] = useState(0);
  let [totalGRGA, setTotalGRGA] = useState(0);
  let [totalGPDAG, setTotalGPDAG] = useState(0);
  let [totalGRCA, setTotalGRCA] = useState(0);
  let [totalGRFI, setTotalGRFI] = useState(0);
  let [totalGRPG, setTotalGRPG] = useState(0);
  let [totalGRLI, setTotalGRLI] = useState(0);
  let [totalGRFB, setTotalGRFB] = useState(0);
  let [totalGRUV, setTotalGRUV] = useState(0);
  let [totalGGRPB, setTotalGRPB] = useState(0);
  //const [searchValue, setSearchValue] = useState('');
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [orgaoSubMenuOpen, setOrgaoSubMenuOpen] = useState(false);
  const [telemetriaOpen, setTelemetriaOpen] = useState(false);
  //eslint-disable-next-line
  const [loadingData, setLoadingData] = useState(true);

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  const { systemUrls } = useSystemUrls();

  const history = useHistory();
  const mapRef = useRef(null);
  const viewRef = useRef(null); // Create a ref for the map view

  useEffect(() => {
    if (totalpontos > 0) {
      setLoadingData(false);
    }
  }, [totalpontos]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    // remover o event listener
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    //eslint-disable-next-line
  }, []);

  const urlSaneparVector =
    systemUrls?.find((item) => item?.attribute === 'map_url_vector')?.url || '';

  const urlSaneparImage =
    systemUrls?.find((item) => item?.attribute === 'map_url_image')?.url || '';

  const urlOrgao =
    systemUrls?.find((item) => item?.attribute === 'map_description')?.url ||
    '';

  const telemetria_pontos =
    systemUrls?.find((item) => item?.attribute === 'map_url_total_telemetria')
      ?.url || null;

  const urlSanepar = urlSaneparImage;

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setInputVisible(!inputVisible);
    setInputValue('');
  };

  const handleDocumentClick = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      !e.target.matches('#botao')
    ) {
      setInputVisible(false);
      setInputValue('');
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();

    setInputVisible(false);
    setInputValue('');
  };

  function generateFilteredUrl(concessionaria) {
    const cqlFilter = `concessionaria='${concessionaria}'`;
    const url = `${urlSaneparVector}${encodeURIComponent(cqlFilter)}`;
    return url;
  }
  function generateFilteredUrlCredito(credito_csbio) {
    const cqlFilter = `credito_csbio='${credito_csbio}'`;
    const url = `${urlSaneparVector}${encodeURIComponent(cqlFilter)}`;
    return url;
  }
  // function generateFilteredUrl2(telemetria) {
  //   const cqlFilter2 = `telemetria='${telemetria}'`;
  //   const url = `${urlSaneparVector}${encodeURIComponent(cqlFilter2)}`;
  //   return url;
  // }

  function generateFilteredUrl22(regiao) {
    const cqlFilter2 = `regiao='${regiao}'`;
    const url = `${urlSaneparVector}${encodeURIComponent(cqlFilter2)}`;
    return url;
  }

  function generateFilteredUrl3(camada) {
    const typeNameParam = 'typeName=Sanepar%3A' + camada;
    const encodedUrl = encodeURIComponent(urlOrgao + typeNameParam);
    return encodedUrl;
  }
  function generateFilteredUrl4(camada2) {
    const typeNameParam = 'typeName=Sanepar%3A' + camada2;
    const encodedUrl = encodeURIComponent(urlOrgao + typeNameParam);
    return encodedUrl;
  }

  const url_orgao_pagador = decodeURIComponent(
    generateFilteredUrl3('orgao_pagador')
  );
  const url_orgao_pagador2 = decodeURIComponent(
    generateFilteredUrl3('vm_pontos_geo')
  );

  function generateFilteredUrlOrgao(orgao) {
    const cqlFilter = `&cql_filter=nome=%27${orgao}%27`;
    const encodedCqlFilter = `${url_orgao_pagador}${encodeURIComponent(
      cqlFilter
    )}`;

    return encodedCqlFilter;
  }
  function generateFilteredUrlOrgao2(orgao) {
    const cqlFilter = `&cql_filter=orgao_pagador=%27${orgao}%27`;
    const encodedCqlFilter = `${url_orgao_pagador2}${encodeURIComponent(
      cqlFilter
    )}`;
    //const url = `${url_regiao}?cql_filter=${encodedCqlFilter}`;

    return encodedCqlFilter;
  }

  const urlCopel = generateFilteredUrl('COPEL');
  const urlCocel = generateFilteredUrl('COCEL');
  const urlCeral = generateFilteredUrl('CERAL');
  const urlEnergisa = generateFilteredUrl('ENERGISA');
  const urlCelesc = generateFilteredUrl('CELESC');
  const urlCpfl = generateFilteredUrl('CPFL');
  const urlForecel = generateFilteredUrl('FORCEL');
  const urlCerpa = decodeURIComponent(generateFilteredUrl('CERPA'));
  // const urlTelemetria = generateFilteredUrl2(true);

  const url_credito_csbio = decodeURIComponent(
    generateFilteredUrlCredito('SIM')
  );

  //urls para mostrar totais por regiao
  const urlregiaoggso = decodeURIComponent(generateFilteredUrl22('GGSO'));
  const urlregiaoggml = decodeURIComponent(generateFilteredUrl22('GGML'));
  const urlregiaoggsd = decodeURIComponent(generateFilteredUrl22('GGSD'));
  const urlregiaoggnd = decodeURIComponent(generateFilteredUrl22('GGND'));
  const urlregiaoggno = decodeURIComponent(generateFilteredUrl22('GGNO'));
  //urls para mostrar totais por orgão pagador
  const url_orgao_GRPV = decodeURIComponent(generateFilteredUrlOrgao2('GRPV'));
  const url_orgao_GRAR = decodeURIComponent(generateFilteredUrlOrgao2('GRAR'));
  const url_orgao_GRCP = decodeURIComponent(generateFilteredUrlOrgao2('GRCP'));
  const url_orgao_GRMA = decodeURIComponent(generateFilteredUrlOrgao2('GRMA'));
  const url_orgao_GRSP = decodeURIComponent(generateFilteredUrlOrgao2('GRSP'));
  const url_orgao_GIDLD = decodeURIComponent(
    generateFilteredUrlOrgao2('GIDLD')
  );
  const url_orgao_GRUM = decodeURIComponent(generateFilteredUrlOrgao2('GRUM'));
  const url_orgao_GRAP = decodeURIComponent(generateFilteredUrlOrgao2('GRAP'));
  const url_orgao_GRCM = decodeURIComponent(generateFilteredUrlOrgao2('GRCM'));
  const url_orgao_GRTB = decodeURIComponent(generateFilteredUrlOrgao2('GRTB'));
  const url_orgao_GRTO = decodeURIComponent(generateFilteredUrlOrgao2('GRTO'));
  const url_orgao_GRGA = decodeURIComponent(generateFilteredUrlOrgao2('GRGA'));
  const url_orgao_GPDAG = decodeURIComponent(
    generateFilteredUrlOrgao2('GPDAG')
  );
  const url_orgao_GRCA = decodeURIComponent(generateFilteredUrlOrgao2('GRCA'));
  const url_orgao_GRFI = decodeURIComponent(generateFilteredUrlOrgao2('GRFI'));
  const url_orgao_GRPG = decodeURIComponent(generateFilteredUrlOrgao2('GRPG'));
  const url_orgao_GRLI = decodeURIComponent(generateFilteredUrlOrgao2('GRLI'));
  const url_orgao_GRFB = decodeURIComponent(generateFilteredUrlOrgao2('GRFB'));
  const url_orgao_GRUV = decodeURIComponent(generateFilteredUrlOrgao2('GRUV'));
  const url_orgao_GRPB = decodeURIComponent(generateFilteredUrlOrgao2('GRPB'));

  const url_regiao = decodeURIComponent(generateFilteredUrl4('regiao'));
  function generateFilteredUrlRegiao(regiao) {
    const cqlFilter = `&cql_filter=nome=%27${regiao}%27`;
    const encodedCqlFilter = `${url_regiao}${encodeURIComponent(cqlFilter)}`;

    return encodedCqlFilter;
  }
  // url para mostrar os vetores da vm
  const urlGGML = decodeURIComponent(generateFilteredUrlRegiao('GGML'));
  const urlGGND = decodeURIComponent(generateFilteredUrlRegiao('GGND'));
  const urlGGNO = decodeURIComponent(generateFilteredUrlRegiao('GGNO'));
  const urlGGSD = decodeURIComponent(generateFilteredUrlRegiao('GGSD'));
  const urlGGSO = decodeURIComponent(generateFilteredUrlRegiao('GGSO'));

  const urlGRPV = decodeURIComponent(generateFilteredUrlOrgao('GRPV'));
  const urlGRAR = decodeURIComponent(generateFilteredUrlOrgao('GRAR'));
  const urlGRCP = decodeURIComponent(generateFilteredUrlOrgao('GRCP'));
  const urlGRMA = decodeURIComponent(generateFilteredUrlOrgao('GRMA'));
  const urlGRSP = decodeURIComponent(generateFilteredUrlOrgao('GRSP'));
  const urlGIDLD = decodeURIComponent(generateFilteredUrlOrgao('GIDLD'));
  const urlGRUM = decodeURIComponent(generateFilteredUrlOrgao('GRUM'));
  const urlGRAP = decodeURIComponent(generateFilteredUrlOrgao('GRAP'));
  const urlGRCM = decodeURIComponent(generateFilteredUrlOrgao('GRCM'));
  const urlGRTB = decodeURIComponent(generateFilteredUrlOrgao('GRTB'));
  const urlGRTO = decodeURIComponent(generateFilteredUrlOrgao('GRTO'));
  const urlGRGA = decodeURIComponent(generateFilteredUrlOrgao('GRGA'));
  const urlGPDAG = decodeURIComponent(generateFilteredUrlOrgao('GPDAG'));
  const urlGRCA = decodeURIComponent(generateFilteredUrlOrgao('GRCA'));
  const urlGRFI = decodeURIComponent(generateFilteredUrlOrgao('GRFI'));
  const urlGRPG = decodeURIComponent(generateFilteredUrlOrgao('GRPG'));
  const urlGRLI = decodeURIComponent(generateFilteredUrlOrgao('GRLI'));
  const urlGRFB = decodeURIComponent(generateFilteredUrlOrgao('GRFB'));
  const urlGRUV = decodeURIComponent(generateFilteredUrlOrgao('GRUV'));
  const urlGRPB = decodeURIComponent(generateFilteredUrlOrgao('GRPB'));

  let menuRef = useRef();

  // pesquisa por UC  e centralizar no mapa - iício
  // const [latitude, setLatitude] = useState('');
  // const [longitude, setLongitude] = useState('');
  const [loading, setLoading] = useState(false);

  // pesquisa por UC  e centralizar no mapa - fim

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-50.834076, -24.69453]), //
        zoom: 8,
      }),
      controls: [], // Remova a configuração existente ou defina como uma matriz vazia
    });
    viewRef.current = map.getView(); // tive que deixar aqui dentro do useEffect para fazer funcionar

    const rotateControl = new Rotate({
      tipLabel: 'Redefinir Norte (Shift + Alt para rotacionar)',
      autoHide: false,
    });
    map.addControl(rotateControl);

    const scaleLineControl = new ScaleLine();
    map.addControl(scaleLineControl);

    const zoomControl = new Zoom({
      zoomInTipLabel: 'Aproximar (Shift + Mouse)',
      zoomOutTipLabel: 'Afastar',
    });
    map.addControl(zoomControl);

    const attributionControl = new Attribution({
      collapsible: true,
    });
    map.addControl(attributionControl);

    const fullScreenControl = new FullScreen({
      tipLabel: 'Mudar para tela cheia',
    });
    map.addControl(fullScreenControl);

    const zoomSliderControl = new Zoom();
    map.addControl(zoomSliderControl);

    //posição do mouse para mostrar coordenadas
    const mousePositionControl = new MousePosition({
      coordinateFormat: function (coordinate) {
        return formatCoordinate(coordinate, '{y}, {x}', 6) + ' (WGS 84)';
      },
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('map'),
      undefinedHTML: '&nbsp;',
    });
    map.addControl(mousePositionControl);

    // Additional control
    const zoomToExtentControl = new ZoomToExtent({
      tipLabel: 'Zoom para a extensão total do mapa',
      extent: map.getView().calculateExtent(map.getSize()),
      className: 'custom-zoom-to-extent-control',
    });

    //ter as mesmas coordenadas que o zoom e center da view
    map.getView().on('change:center', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent: extent });
    });
    map.getView().on('change:resolution', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent: extent });
    });

    map.addControl(zoomToExtentControl);

    const overviewMapControl = new OverviewMap({
      collapsed: false,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new ImageLayer({
          source: new ImageWMS({}),
        }),
      ],
    });
    map.addControl(overviewMapControl);

    var src_sanepar_selecionados = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlSanepar,
    });

    var sanepar_selecionados = new VectorLayer({
      name: 'modulos_selecionados',
      title: 'Pontos Sanepar',
      source: src_sanepar_selecionados,
    });

    const src_telemetria_pontos = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: telemetria_pontos,
    });

    const styleFunction = (feature) => {
      const ativo = feature?.get('ativo');
      const color =
        ativo === 'NAO' ? 'rgba(255, 0, 0, 0.8)' : 'rgba(76, 255, 0, 0.8)';

      return new Style({
        image: new CircleStyle({
          radius: 4.5,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: '#000000', width: 0.4 }),
        }),
      });
    };
    sanepar_selecionados.setStyle(styleFunction);

    const telemetria_total = new VectorLayer({
      name: 'Telemetria',
      title: 'Telemetria',
      source: src_telemetria_pontos,
      visible: false,
      zIndex: 1,
    });

    // var src_telemetria = new VectorSource({
    //   format: new GeoJSON(),
    //   attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
    //   url: urlTelemetria,
    // });

    // var telemetria_selecionados = new VectorLayer({
    //   name: 'telemetria',
    //   title: 'Telemetria',
    //   source: src_telemetria,
    //   visible: false,
    // });

    map.addLayer(telemetria_total);

    var src_orgao_pagador = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_orgao_pagador,
    });

    var orgao_pagador = new VectorLayer({
      name: 'orgao_pagador',
      title: 'Orgao',
      source: src_orgao_pagador,
      visible: true,
    });
    //crédito de carbono
    var src_credito_csbio = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_credito_csbio,
    });

    var credito_csbio = new VectorLayer({
      name: 'credito',
      title: 'credito',
      source: src_credito_csbio,
      visible: false,
    });

    // concessionárias - início
    var src_copel = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCopel,
    });

    var copel = new VectorLayer({
      name: 'copel',
      title: 'Copel',
      source: src_copel,
      visible: false,
    });
    var src_cpfl = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCpfl,
    });

    var cpfl = new VectorLayer({
      name: 'copel',
      title: 'Cpfl',
      source: src_cpfl,
      visible: false,
    });

    var src_energisa = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlEnergisa,
    });
    var energisa = new VectorLayer({
      name: 'energisa',
      title: 'Energisa',
      source: src_energisa,
      visible: false,
    });

    var src_cocel = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCocel,
    });
    var cocel = new VectorLayer({
      name: 'cocel',
      title: 'Cocel',
      source: src_cocel,
      visible: false,
    });

    var src_celesc = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCelesc,
    });
    var celesc = new VectorLayer({
      name: 'celesc',
      title: 'Celesc',
      source: src_celesc,
      visible: false,
    });

    var src_ceral = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCeral,
    });

    var ceral = new VectorLayer({
      name: 'ceral',
      title: 'Ceral',
      source: src_ceral,
      visible: false,
    });

    var src_forcel = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlForecel,
    });
    var forcel = new VectorLayer({
      name: 'forcel',
      title: 'Forcel',
      source: src_forcel,
      visible: false,
    });

    var src_cerpa = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlCerpa,
    });

    var cerpa = new VectorLayer({
      name: 'cerpa',
      title: 'Cerpa',
      source: src_cerpa,
      visible: false,
    });
    // concessionárias - fim

    //regiões - início
    var src_regiao = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_regiao,
    });

    var regiao = new VectorLayer({
      name: 'regiao',
      title: 'regiao',
      source: src_regiao,
      visible: false,
    });

    var src_regiao_ggml = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGML,
    });

    var regiao_ggml = new VectorLayer({
      name: 'ggml',
      title: 'ggml',
      source: src_regiao_ggml,
      visible: false,
    });

    var src_regiao_ggnd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGND,
    });

    var regiao_ggnd = new VectorLayer({
      name: 'ggnd',
      title: 'ggnd',
      source: src_regiao_ggnd,
      visible: false,
    });

    var src_regiao_ggno = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGNO,
    });

    var regiao_ggno = new VectorLayer({
      name: 'ggno',
      title: 'ggno',
      source: src_regiao_ggno,
      visible: false,
    });

    var src_regiao_ggsd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSD,
    });

    var regiao_ggsd = new VectorLayer({
      name: 'ggsd',
      title: 'ggsd',
      source: src_regiao_ggsd,
      visible: false,
    });

    var src_regiao_ggso = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSO,
    });

    var regiao_ggso = new VectorLayer({
      name: 'ggso',
      title: 'ggso',
      source: src_regiao_ggso,
      visible: false,
    });

    //regiões - fim

    // Definir informações das camadas
    const orgaoLayers = [
      { name: 'grpv', url: urlGRPV },
      { name: 'grar', url: urlGRAR },
      { name: 'grcp', url: urlGRCP },
      { name: 'grma', url: urlGRMA },
      { name: 'grsp', url: urlGRSP },
      { name: 'gidld', url: urlGIDLD },
      { name: 'grum', url: urlGRUM },
      { name: 'grap', url: urlGRAP },
      { name: 'grcm', url: urlGRCM },
      { name: 'grtb', url: urlGRTB },
      { name: 'grto', url: urlGRTO },
      { name: 'grga', url: urlGRGA },
      { name: 'gpdag', url: urlGPDAG },
      { name: 'grca', url: urlGRCA },
      { name: 'grfi', url: urlGRFI },
      { name: 'grpg', url: urlGRPG },
      { name: 'grli', url: urlGRLI },
      { name: 'grfb', url: urlGRFB },
      { name: 'gruv', url: urlGRUV },
      { name: 'grpb', url: urlGRPB },
    ];

    // Função para criar camadas
    function createOrgaoLayer(layerInfo) {
      const source = new VectorSource({
        format: new GeoJSON(),
        attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
        url: layerInfo?.url,
      });

      return new VectorLayer({
        name: layerInfo?.name,
        title: layerInfo?.name,
        source: source,
        visible: false,
      });
    }

    // Criar camadas para cada objeto no array orgaoLayers
    const orgaoLayersArray = orgaoLayers?.map((layerInfo) =>
      createOrgaoLayer(layerInfo)
    );

    async function getTotalFeatures() {
      try {
        const response = await fetch(src_sanepar_selecionados?.getUrl());
        const data = await response.json();
        const totalFeatures = data?.totalFeatures;

        if (!Array.isArray(data?.features)) {
          return { total: 0, totalNao: 0 };
        }

        // Conta o total de features e o total de 'NAO'
        const totalNao = data.features.reduce((count, feature) => {
          return feature.properties?.ativo === 'NAO' ? count + 1 : count;
        }, 0);

        return {
          total: totalFeatures || 0,
          totalNao: totalNao,
        };
      } catch (error) {
        // console.error('Error fetching total features:', error);
        return { total: 0, totalNao: 0 };
      }
    }

    getTotalFeatures()
      .then(({ total, totalNao }) => {
        const totalPontosElement = document.getElementById('totalPontos');
        const totalNaoElement = document.getElementById('totalNao');

        if (totalPontosElement) {
          totalPontosElement.innerHTML = `(${total})`;
        }

        if (totalNaoElement) {
          totalNaoElement.innerHTML = `(${totalNao})`;
        }

        setTotalPontos(total);
        setTotalNao(totalNao);
      })
      .catch((error) => {
        // console.error('Error updating total points:', error);
        const totalPontosElement = document.getElementById('totalPontos');
        const totalNaoElement = document.getElementById('totalNao');

        if (totalPontosElement) {
          totalPontosElement.innerHTML = '(Erro ao carregar)';
        }

        if (totalNaoElement) {
          totalNaoElement.innerHTML = '(Erro ao carregar)';
        }
      });

    async function getTotalTele() {
      try {
        const response = await fetch(src_telemetria_pontos?.getUrl());
        const data = await response.json();
        const totalFeatures = data?.totalFeatures;

        if (typeof totalFeatures !== 'number') {
          return 0;
        }

        return totalFeatures;
      } catch (error) {
        console.error('Error fetching total telemetry:', error);
        return 0;
      }
    }

    getTotalTele()
      .then((totalTele) => {
        const totalTeleElement = document.getElementById('totalTele');
        if (totalTeleElement) {
          totalTeleElement.innerHTML = `(${totalTele})`;
        }
        setTotalTele(totalTele);
      })
      .catch((error) => {
        console.error('Error updating total telemetry:', error);
        const totalTeleElement = document.getElementById('totalTele');
        if (totalTeleElement) {
          totalTeleElement.innerHTML = '(Erro ao carregar)';
        }
      });

    const dataSources = [
      { source: src_copel, setter: setTotalCopel },
      { source: src_cocel, setter: setTotalCocel },
      { source: src_ceral, setter: setTotalCeral },
      { source: src_celesc, setter: setTotalCelesc },
      { source: src_energisa, setter: setTotalEnergisa },
      { source: src_forcel, setter: setTotalForcel },
      { source: src_cpfl, setter: setTotalCpfl },
      { source: src_cerpa, setter: setTotalCerpa },
      { source: src_credito_csbio, setter: setTotalCredito },
    ];

    // Função para obter total de features com valor inicial 0
    function getTotalFeaturesCount(dataSource) {
      return fetch(dataSource.source?.getUrl())
        .then((response) => response?.json())
        .then((data) => {
          const totalFeatures = data?.totalFeatures;
          if (typeof totalFeatures !== 'number') {
            return 0; // Retorna 0 se totalFeatures não for um número
          }
          return totalFeatures;
        })
        .catch((error) => {
          //console.log('Error retrieving totalFeatures:', error);
          return 0; // Retorna 0 em caso de erro
        });
    }

    // Inicializar valores em 0
    dataSources.forEach((dataSource) => {
      dataSource?.setter(0); // Inicializa o valor como 0
      getTotalFeaturesCount(dataSource).then((totalFeatures) => {
        dataSource?.setter(totalFeatures);
        //console.log(totalFeatures)
      });
    });

    // Função para obter total de features por região
    function getTotalFeaturesregiao(url, setter) {
      return new Promise((resolve, reject) => {
        setter(0);
        fetch(url)
          .then((response) => response?.json())
          .then((data) => {
            const totalFeatures = data?.totalFeatures;
            if (typeof totalFeatures !== 'number') {
              resolve(0); // Resolve com 0 se totalFeatures não for um número
              setter(0);
            } else {
              resolve(totalFeatures);
              setter(totalFeatures);
            }
          })
          .catch((error) => {
            //console.log('Error retrieving totalFeatures:', error);
            reject(error);
            setter(0);
          });
      });
    }

    getTotalFeaturesregiao(urlregiaoggso, setTotalGSO).catch((error) => {});
    getTotalFeaturesregiao(
      url_credito_csbio,
      setTotalCredito
    ).catch((error) => {});

    getTotalFeaturesregiao(urlregiaoggml, setTotalGGML).catch((error) => {});

    getTotalFeaturesregiao(urlregiaoggsd, setTotalGGSD).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggnd, setTotalGGND).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggno, setTotalGGNO).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPV, setTotalGRPV).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRAR, setTotalGRAR).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCP, setTotalGRCP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRMA, setTotalGRMA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRSP, setTotalGRSP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GIDLD, setTotalGIDLD).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRUM, setTotalGRUM).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRAP, setTotalGRAP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCM, setTotalGRCM).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRTB, setTotalGRTB).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRTO, setTotalGRTO).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRGA, setTotalGRGA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GPDAG, setTotalGPDAG).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCA, setTotalGRCA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRFI, setTotalGRFI).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPG, setTotalGRPG).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRLI, setTotalGRLI).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRUV, setTotalGRUV).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPB, setTotalGRPB).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRFB, setTotalGRFB).catch((error) => {});
    //pegar total urls - fim

    var info = document.createElement('div');
    info.classList.add('info');
    var overlay = new Overlay({ element: info });
    map.addOverlay(overlay);

    // Função para exibir o popup quando um recurso for clicado

    const displayPopup = (evt) => {
      info.style.display = 'none';
      info.classList.remove('show');
      info.classList.remove('close');

      const feature = map.forEachFeatureAtPixel(
        evt.pixel,
        (feature) => feature
      );

      if (feature) {
        const regiaoLayer = feature?.getId();

        if (regiaoLayer?.indexOf('regiao') !== -1) {
          const nome = feature.get('nome');

          let atributos = `<b><font color="#090e66">Nome: </font color></b>${nome}`;

          info.innerHTML = '';
          info.style.display = atributos ? '' : 'none';
          info.innerHTML = atributos;

          setTimeout(() => {
            info.style.display = 'block';
            info.classList.add('show');
          }, 12);

          overlay.setPosition(evt.coordinate);

          feature.setStyle(
            new Style({
              fill: new Fill({
                color: [245, 229, 9, 0.2], // Amarelo com opacidade
              }),
              stroke: new Stroke({
                color: [255, 255, 0, 0.5], // Bordas amarelas com opacidade
                width: 1.5,
              }),
            })
          );
          // Remove o estilo da feature quando clicar fora
          map.once('click', (event) => {
            const clickedFeature = map?.forEachFeatureAtPixel(
              map.getEventPixel(event.originalEvent),
              (feature) => feature
            );

            if (!clickedFeature || clickedFeature !== feature) {
              feature.setStyle(null);
            }
          });
        } else if (regiaoLayer?.indexOf('orgao_pagador') !== -1) {
          const nome = feature?.get('nome');
          let atributos = `<b><font color="#090e66">Nome: </font color></b>${nome} `;

          info.innerHTML = '';
          info.style.display = atributos ? '' : 'none';
          info.innerHTML = atributos;

          setTimeout(() => {
            info.style.display = 'block';
            info.classList.add('show');
          }, 12);

          overlay.setPosition(evt.coordinate);

          feature.setStyle(
            new Style({
              fill: new Fill({
                color: [245, 229, 9, 0.2], // Amarelo com opacidade
              }),
              stroke: new Stroke({
                color: [255, 255, 0, 0.5], // Bordas amarelas com opacidade
                width: 1.5,
              }),
            })
          );
          // Remove o estilo da feature quando clicar fora
          map.once('click', (event) => {
            const clickedFeature = map.forEachFeatureAtPixel(
              map.getEventPixel(event.originalEvent),
              (feature) => feature
            );

            if (!clickedFeature || clickedFeature !== feature) {
              feature.setStyle(null);
            }
          });
        } else {
          var unidade_operacional = feature?.get('unidade');
          var endereco = feature?.get('endereco');
          var concessionaria = feature?.get('concessionaria');
          var latitude = feature?.get('latitude');
          var longitude = feature?.get('longitude');
          let unidade_consumidora = feature?.get('unidade_consumidora');
          if (unidade_consumidora === undefined) {
            unidade_consumidora = feature?.get('identification_number');
          }
          const identification_number = feature?.get('identification_number');
          var regiao = feature?.get('regiao');
          var telemetria = feature?.get('telemetria');
          var orgao = feature?.get('orgao_pagador');
          var municipio = feature?.get('municipio');
          var credito = feature?.get('credito_csbio');
          const uuid_nulo = feature?.get('uuid_circuit');
          const uuidCircuit = uuid_nulo === null ? 'Sem circuito' : uuid_nulo;

          if (telemetria === true) {
            telemetria = 'Sim';
          } else {
            telemetria = 'Não';
          }

          var atributos =
            '<b><font color="#090e66">Unidade consumidora: </font color></b>' +
            '<font color="#090e66"> ' +
            unidade_consumidora +
            '</a></font>' +
            '<br>' +
            '<b>Unidade operacional: </b>' +
            unidade_operacional +
            '<br>' +
            '<b>Endereço: </b>' +
            endereco +
            '</br>' +
            '<b>Município: </b>' +
            municipio +
            '</br>' +
            '<b>Concessionária: </b>' +
            concessionaria +
            '</br>' +
            '<b>Gerencia geral: </b>' +
            regiao +
            '</br>' +
            '<b>Gerencia regional: </b>' +
            orgao +
            '</br>' +
            '<b>Compensação: </b>' +
            credito +
            '</br>' +
            '<b>Telemetria: </b>' +
            telemetria +
            '</br>' +
            '<b>Latitude: </b>' +
            latitude +
            '</br>' +
            '<b>Longitude: </b>' +
            longitude +
            '</br>' +
            '<button class="redirect-button" id="myButton">Ir para o Dashboard</button>';

          info.innerHTML = '';
          info.style.display = atributos ? '' : 'none';
          info.innerHTML = atributos;

          setTimeout(() => {
            info.style.display = 'block';
            info.classList.add('show');
          }, 12);

          overlay.setPosition(evt.coordinate);

          // Define o estilo da feature quando ela for clicada
          feature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({ color: [245, 229, 9, 0.7] }), // Amarelo
              }),
            })
          );

          // Remove o estilo da feature quando clicar fora
          map.on('click', (event) => {
            if (!event.target || !event.target.getProperties().feature) {
              feature.setStyle(null);
            }
          });

          // Apply responsive CSS styles
          info.style.maxWidth = '100%';
          info.style.width = '100%';
          info.style.padding = '10px';

          // Media query for smaller screens
          var smallScreenQuery = window.matchMedia('(max-width: 600px)');
          if (smallScreenQuery.matches) {
            info.style.maxWidth = '80%';
          }

          atributos = '';

          let featureSession = feature?.values_?.unidade_consumidora
            ? String(feature.values_.unidade_consumidora)
            : String(feature?.values_?.identification_number || '');

          var buttonElement = document.getElementById('myButton');
          buttonElement.addEventListener('click', handleButtonClick);

          function handleButtonClick() {
            history.push({
              pathname: '/consumer-group-dashboard',
              state: {
                consumerGroup: featureSession,
                uuidCircuit: uuidCircuit,
              },
            });
          }
        }
      }
    };

    map.on('singleclick', displayPopup);

    const baseLayerGroup = new LayerGroup();
    // Adicionar camadas ao baseLayerGroup
    baseLayerGroup.getLayers().push(orgao_pagador);
    baseLayerGroup.getLayers().push(regiao);
    baseLayerGroup.getLayers().push(regiao_ggml);
    baseLayerGroup.getLayers().push(regiao_ggnd);
    baseLayerGroup.getLayers().push(regiao_ggno);
    baseLayerGroup.getLayers().push(regiao_ggsd);
    baseLayerGroup.getLayers().push(regiao_ggso);
    // Adicionar as camadas do orgaoLayersArray
    orgaoLayersArray.forEach((layer) => {
      baseLayerGroup.getLayers().push(layer);
    });
    baseLayerGroup.getLayers().push(sanepar_selecionados);
    baseLayerGroup.getLayers().push(telemetria_total);
    baseLayerGroup.getLayers().push(credito_csbio);
    baseLayerGroup.getLayers().push(copel);
    baseLayerGroup.getLayers().push(energisa);
    baseLayerGroup.getLayers().push(cocel);
    baseLayerGroup.getLayers().push(celesc);
    baseLayerGroup.getLayers().push(ceral);
    baseLayerGroup.getLayers().push(forcel);
    baseLayerGroup.getLayers().push(cpfl);
    baseLayerGroup.getLayers().push(cerpa);

    map.addLayer(baseLayerGroup);

    // Set the control grid reference
    const search = new SearchPhoton({
      target: document.getElementById('map'),
      lang: 'fr', // Defina o idioma desejado
      reverse: true,
      position: true, // Ativar pesquisa por posição geográfica
      placeholder: 'Pesquisar por endereço',
      title: 'Pesquisar por endereço',
    });
    map.addControl(search);

    // Lidar com o evento 'select' quando um local é selecionado
    search.on('select', function (e) {
      map.getView().animate({
        center: e.coordinate,
        zoom: Math.max(map.getView().getZoom(), 16),
      });
    });

    function setupLayerVisibilityToggles(baseLayerGroup) {
      const baseLayerElements = document.querySelectorAll(
        '.dropdown-menu input[type="checkbox"]'
      );

      baseLayerElements.forEach((element) => {
        element.addEventListener('change', function (e) {
          const elementValue = this.value;
          const isChecked = this.checked;

          baseLayerGroup.getLayers().forEach((layer) => {
            const baseLayerTitle = layer?.get('title');

            if (baseLayerTitle === elementValue) {
              layer.setVisible(isChecked);
              const style = getLayerStyle(baseLayerTitle, isChecked);
              if (style) {
                layer.setStyle(style);
              }
            }
          });
        });
      });
    }

    // Inicializar a configuração dos listeners
    setupLayerVisibilityToggles(baseLayerGroup);

    document.body.style.margin = '0';
    document.body.style.padding = '0';

    var radius = 4.5;

    // Desenhar o círculo verde
    var cVerde = document?.getElementById('canvasTotalPontos');
    if (cVerde) {
      var ctxVerde = cVerde.getContext('2d');
      ctxVerde.beginPath();
      ctxVerde.arc(10, 10, radius, 0, 2 * Math.PI, false);
      ctxVerde.fillStyle = 'rgba(76, 255, 0, 0.8)'; // Verde
      ctxVerde.fill();
      ctxVerde.lineWidth = 0.1;
      ctxVerde.strokeStyle = '#000000';
      ctxVerde.stroke();
    }

    // Desenhar o círculo vermelho
    var cVermelho = document?.getElementById('canvasTotalPontosNao');
    if (cVermelho) {
      var ctxVermelho = cVermelho.getContext('2d');
      ctxVermelho.beginPath();
      ctxVermelho.arc(10, 10, radius, 0, 2 * Math.PI, false);
      ctxVermelho.fillStyle = 'rgba(255, 0, 0, 0.8)'; // Vermelho
      ctxVermelho.fill();
      ctxVermelho.lineWidth = 0.1;
      ctxVermelho.strokeStyle = '#000000';
      ctxVermelho.stroke();
    }

    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      map.setTarget(null);
      document.removeEventListener('mousedown', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemUrls]);

  async function fetchDataFromURL(url) {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      // console.log('Erro ao buscar os dados:', error);
      return null;
    }
  }
  // Função para encontrar a coordenada pelo número da unidade consumidora
  async function findPontoByUnidadeConsumidora(unidadeConsumidora) {
    const data = await fetchDataFromURL(urlSanepar);

    if (data) {
      const features = data?.features;
      for (const feature of features) {
        const properties = feature?.properties;
        if (properties?.unidade_consumidora === unidadeConsumidora) {
          const latitude = feature?.geometry.coordinates[1];
          const longitude = feature?.geometry.coordinates[0];
          return { latitude, longitude };
        }
      }
    }
    return null;
  }
  // Function to set the map view to a new location
  function irParaLocal(longitude_si, latitude_si) {
    const view = viewRef.current;
    if (view) {
      view.animate({
        center: fromLonLat([longitude_si, latitude_si]),
        duration: 2000,
        zoom: 19,
      });
    }
  }

  function showPopup(message) {
    const popupElement = document?.getElementById('customPopup');
    const popupMessageElement = document?.getElementById('popupMessage');

    popupMessageElement.textContent = message;
    popupElement.classList.add('show');

    // Add a close button ('x') to the popup
    const closeButton = document.createElement('span');
    closeButton.textContent = 'x';
    closeButton.classList.add('closeButton');
    closeButton.addEventListener('click', () => {
      popupElement.classList.remove('show');
    });
    popupElement.appendChild(closeButton);

    // Hide the popup after a few seconds (you can adjust the delay as needed)
    setTimeout(() => {
      popupElement.classList.remove('show');
    }, 15000); // 3000 milliseconds = 3 seconds
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const numeroUnidadeConsumidora = inputValue.trim();
      if (numeroUnidadeConsumidora === '') {
        showPopup('Digite um número de unidade consumidora válido');
      } else {
        try {
          setLoading(true); // Set loading state when starting the fetch
          const pontoSelecionado = await findPontoByUnidadeConsumidora(
            numeroUnidadeConsumidora
          );
          if (pontoSelecionado) {
            const { latitude, longitude } = pontoSelecionado;
            // setLatitude(latitude);
            // setLongitude(longitude);
            irParaLocal(longitude, latitude);
          } else {
            showPopup('Número da unidade consumidora não encontrado');
          }
        } catch (error) {
          // Handle any error that might occur during fetching
          // console.log('Error fetching data:', error);
          showPopup('Error fetching data');
        } finally {
          setLoading(false); // Set loading state to false when fetch is done
        }
      }
    }
  };

  const LayersLegend = [
    {
      value: 'Pontos Sanepar',
      label: ` UC's (${totalpontos})`,
      subvalues: [
        { value: 'Celesc', label: ' Celesc' },
        { value: 'Ceral', label: ' Ceral' },
        { value: 'Copel', label: ' Copel ' },
        { value: 'Cocel', label: ' Cocel' },
        { value: 'Cpfl', label: ' Cpfl' },
        { value: 'Energisa', label: ' Energisa' },
        { value: 'Forcel', label: ' Forcel' },
        { value: 'Cerpa', label: ' Cerpa' },
      ],
    },
    {
      value: 'Telemetria',
      label: ` Telemetria
      (${totalTele})`,
    },
    {
      value: 'credito',
      label: ` Compensação (${totalCredito}) `,
    },
    {
      value: 'Orgao',
      label: ' GR',
      subvalues: [
        { value: 'grpv', label: ' GRPV' },
        { value: 'grar', label: ' GRAR' },
        { value: 'grcp', label: ' GRCP' },
        { value: 'grma', label: ' GRMA' },
        { value: 'grsp', label: ' GRSP' },
        { value: 'gidld', label: ' GIDLD' },
        { value: 'grum', label: ' GRUM' },
        { value: 'grap', label: ' GRAP' },
        { value: 'grcm', label: ' GRCM' },
        { value: 'grtb', label: ' GRTB' },
        { value: 'grto', label: ' GRTO' },
        { value: 'grga', label: ' GRGA' },
        { value: 'gpdag', label: ' GPDAG' },
        { value: 'grca', label: ' GRCA' },
        { value: 'grfi', label: ' GRFI' },
        { value: 'grpg', label: ' GRPG' },
        { value: 'grli', label: ' GRLI' },
        { value: 'grfb', label: ' GRFB' },
        { value: 'gruv', label: ' GRUV' },
        { value: 'grpb', label: ' GRPB' },
      ],
    },
    {
      value: 'regiao',
      label: ' GG',
      subvalues: [
        { value: 'ggml', label: ' GGML' },
        { value: 'ggsd', label: ' GGSD' },
        { value: 'ggnd', label: ' GGND' },
        { value: 'ggno', label: ' GGNO' },
        { value: 'ggso', label: ' GGSO' },
      ],
    },
  ];

  function updateLabelWithValue(totalValue, layerValue, subitemValue) {
    const layerIndex = LayersLegend.findIndex(
      (layer) => layer?.value === layerValue
    );
    if (layerIndex !== -1) {
      const subitemIndex = LayersLegend[layerIndex].subvalues.findIndex(
        (subitem) => subitem?.value === subitemValue
      );
      if (subitemIndex !== -1) {
        LayersLegend[layerIndex].subvalues[
          subitemIndex
        ].label = ` ${subitemValue} (${totalValue})`;
      }
    }
  }

  updateLabelWithValue(totalCopel, 'Pontos Sanepar', 'Copel');
  updateLabelWithValue(totalCocel, 'Pontos Sanepar', 'Cocel');
  updateLabelWithValue(totalCeral, 'Pontos Sanepar', 'Ceral');
  updateLabelWithValue(totalCelesc, 'Pontos Sanepar', 'Celesc');
  updateLabelWithValue(totalEnergisa, 'Pontos Sanepar', 'Energisa');
  updateLabelWithValue(totalForcel, 'Pontos Sanepar', 'Forcel');
  updateLabelWithValue(totalCpfl, 'Pontos Sanepar', 'Cpfl');
  updateLabelWithValue(totalCerpa, 'Pontos Sanepar', 'Cerpa');
  // //updateLabelWithValue(totalpontos, 'Pontos Sanepar','Pontos Sanepar');
  // updateLabelWithValue(totalTele, 'Telemetria', 'Telemetria');

  function updateLabel(totalValue, subvalueKey) {
    const index = LayersLegend?.findIndex((layer) => {
      return (
        layer?.subvalues &&
        layer?.subvalues.some((subvalue) => subvalue?.value === subvalueKey)
      );
    });

    if (index !== -1) {
      const subvalueIndex = LayersLegend[index].subvalues.findIndex(
        (subvalue) => subvalue?.value === subvalueKey
      );
      if (subvalueIndex !== -1) {
        LayersLegend[index].subvalues[
          subvalueIndex
        ].label = ` ${subvalueKey.toUpperCase()} (${totalValue})`;
      }
    }
  }
  //regiao - inicio
  updateLabel(totalGSO, 'ggso', 'totalGSO');
  updateLabel(totalGGML, 'ggml', 'totalGGML');
  updateLabel(totalGGSD, 'ggsd', 'totalGGSD');
  updateLabel(totalGGND, 'ggnd', 'totalGGND');
  updateLabel(totalGGNO, 'ggno', 'totalGGNO');
  //regiao - fim
  //orgao pagador - início
  updateLabel(totalGGRPV, 'grpv', 'totalGRPV');
  updateLabel(totalGRAR, 'grar', 'totalGRAR');
  updateLabel(totalGRCP, 'grcp', 'totalGRCP');
  updateLabel(totalGRMA, 'grma', 'totalGRMA');
  updateLabel(totalGRSP, 'grsp', 'totalGRSP');
  updateLabel(totalGIDLD, 'gidld', 'totalGIDLD');
  updateLabel(totalGRUM, 'grum', 'totalGRUM');
  updateLabel(totalGRAP, 'grap', 'totalGRAP');
  updateLabel(totalGRCM, 'grcm', 'totalGRCM');
  updateLabel(totalGRTB, 'grtb', 'totalGRTB');
  updateLabel(totalGRTO, 'grto', 'totalGRTO');
  updateLabel(totalGRGA, 'grga', 'totalGRGA');
  updateLabel(totalGPDAG, 'gpdag', 'totalGPDAG');
  updateLabel(totalGRCA, 'grca', 'totalGRCA');
  updateLabel(totalGRFI, 'grfi', 'totalGRFI');
  updateLabel(totalGRPG, 'grpg', 'totalGRPG');
  updateLabel(totalGRLI, 'grli', 'totalGRLI');
  updateLabel(totalGRFB, 'grfb', 'totalGRFB');
  updateLabel(totalGRUV, 'gruv', 'totalGRUV');
  updateLabel(totalGGRPB, 'grpb', 'totalGGRPB');
  //orgao pagador - fim

  return (
    <div>
      {/* <MapLoading loading={loadingData} /> */}
      <div className="MapComponent">
        <div id="customPopup">
          {/*pop up do input */}
          <p id="popupMessage"></p>
        </div>
        <div style={{ width: '100%', height: '93vh' }}>
          <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
            {/* criando sidebar do lado direito */}
          </div>
        </div>
        <div className="menu-container" ref={menuRef}>
          {/* Botão adicionado ao mapa */}
          <div id="botao" onClick={handleButtonClick} title="Pesquisa por UC">
            <button type="button" title="Pesquisa por UC"></button>
          </div>

          {/* Campo de entrada que aparecerá ao clicar no botão */}
          {inputVisible && (
            <div
              ref={inputRef}
              onSubmit={handleInputSubmit}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                position: 'absolute',
                top: '170px',
                left: '35px',
                zIndex: 9999,
                background: 'rgba(255, 255, 255, 0.7)',
                height: '22px',
                border: '2px solid black',
                borderRadius: '4px',
              }}
            >
              <input
                type="search"
                autoComplete="off"
                placeholder="Pesquisa por UC"
                value={inputValue}
                onChange={handleInputChange}
                id="inputNumeroPonto"
                title="Pesquisa por UC"
                style={{
                  border: 'none' /* Remove the input border */,
                  outline: 'none' /* Remove the input outline */,
                  paddingRight: loading ? '30px' : '0',
                }}
              />
              {loading && (
                <div
                  className="loading-circle"
                  style={{
                    position: 'absolute',
                    top: '9%',
                    right: '8px',
                    transform: 'translateY(-50%)',
                  }}
                ></div>
              )}
            </div>
          )}

          <div
            className={`menu-trigger ${open ? 'active' : ''}`}
            title="Camadas"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
            style={{
              pointerEvents: 'auto',
              // Defina outras propriedades de estilo conforme necessário
            }}
          ></div>
          <div className={`dropdown-menu ${open ? 'active' : 'active'}`}>
            <h3>
              <u>Camadas</u>
              <br />
            </h3>
            <ul>
              {LayersLegend.map((item) => (
                <label key={item.value} style={{ fontSize: '13px' }}>
                  <input
                    type="checkbox"
                    name="baseLayerCheck"
                    value={item?.value}
                    defaultChecked={
                      item?.value === 'Pontos Sanepar' ||
                      item?.value === 'Orgao'
                    }
                    // disabled={item.value == 'Pontos Sanepar'}
                    style={{ marginBottom: '10px' }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {item?.label}
                  {item.value === 'regiao' && item.subvalues && (
                    <div
                      className={`submenu-trigger ${subMenuOpen ? 'open' : ''}`}
                    >
                      <div
                        className="submenu-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSubMenuOpen(!subMenuOpen);
                        }}
                      >
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu ${subMenuOpen ? 'open' : ''}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item.subvalues.map((subitem) => (
                          <div key={subitem.value} style={{ fontSize: '13px' }}>
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {item?.value === 'Pontos Sanepar' && item?.subvalues && (
                    <div
                      className={`submenu-trigger ${
                        telemetriaOpen ? 'open' : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTelemetriaOpen(!telemetriaOpen);
                      }}
                    >
                      <div className="submenu-icon">
                        <span className="icon"></span>
                      </div>
                      <div
                        className={`submenu ${telemetriaOpen ? 'open' : ''}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.subvalues.map((subitem) => (
                          <div
                            key={subitem?.value}
                            style={{ fontSize: '12px' }}
                          >
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            {subitem?.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {item?.value === 'Orgao' && item?.subvalues && (
                    <div
                      className={`submenu-trigger ${
                        orgaoSubMenuOpen ? 'open' : ''
                      }`}
                      style={{ maxHeight: '250px', overflowY: 'auto' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOrgaoSubMenuOpen(!orgaoSubMenuOpen);
                      }}
                    >
                      <div className="submenu-icon">
                        <span className="icon"></span>
                      </div>
                      <div
                        className={`submenu ${orgaoSubMenuOpen ? 'open' : ''}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.subvalues.map((subitem) => (
                          <div
                            key={subitem?.value}
                            style={{ fontSize: '11px' }}
                          >
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem?.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <br />
                </label>
              ))}
              <table
                className="tabelaLegenda"
                style={{
                  tableLayout: 'fixed',
                  marginTop: '10px',
                  fontSize: '10px',
                }}
              >
                <tbody>
                  <tr height="20">
                    <td colSpan="3">
                      &nbsp;&nbsp;&nbsp;
                      <u>
                        <b>Legenda:</b>
                      </u>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                      document.body.style.cursor = 'auto';
                    }}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('highlighted');
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalPontos"
                        width="25"
                        height="15"
                        fontSize="12.5px"
                      ></canvas>
                    </td>
                    <td type="checkbox">
                      Pontos Sanepar <span id="totalPontos"></span>
                    </td>
                  </tr>
                  <td>
                    <canvas
                      id="canvasTotalPontosNao"
                      width="25"
                      height="15"
                      fontSize="12.5px"
                    ></canvas>
                  </td>
                  <td type="checkbox">
                    Pontos Inativos <span id="totalNao"></span>
                  </td>
                </tbody>
              </table>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
