import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

function convertNumberToDecimalStyle(value) {
  const convertedToNumber = parseInt(value, 10);
  const decimalNumber = convertedToNumber.toLocaleString('pt-BR');

  return decimalNumber;
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function energyBillBeneficiaryMonthReport(data) {
  const ucGeradora = data?.map((item) => item.uc_geradora);
  const credito = data?.map((item) => item.cred_recebido);
  const mes = data?.map((item) => item.mes_cred_utilizado);
  const compensado = [...new Set(data?.map((item) => item.compensado))];

  const totalCredito = credito?.reduce((accumulator, currentValue) => {
    const parsedValue = parseFloat(currentValue);
    if (!isNaN(parsedValue)) {
      return accumulator + parsedValue;
    }
    return accumulator;
  }, 0);

  const totalObjects = data?.length;
  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de créditos acumulados da Unidade ${ucGeradora[0]} no mês ${mes[0]} `,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('REF:MÊS/ANO CRÉDITO GERADO'),
    generateHeaderCell('CRÉDITO RECEBIDO'),
    generateHeaderCell('UC BENEFICIÁRIA'),
    generateHeaderCell('REF:MÊS/ANO UTILIZAÇÃO DO CRÉDITO'),
  ];

  const renderData = data?.map((bill) => [
    generateCell(bill.mes_cred_gerado),
    generateCell(convertNumberToDecimalStyle(bill.cred_recebido)),
    generateCell(bill.uc_beneficiaria),
    generateCell(bill.mes_cred_utilizado),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: Array(4).fill('*'),
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'TOTAL DE UNIDADES BENEFICIÁRIAS',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalObjects.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],

        [
          {
            text: 'TOTAL DE CRÉDITOS UTILIZADOS PELAS BENEFICIÁRIAS',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: convertNumberToDecimalStyle(totalCredito.toString()),
            fontSize: 8,
            alignment: 'left',
          },
        ],

        [
          {
            text: 'TOTAL DE CRÉDITOS UTILIZADOS PELA GERADORA',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: convertNumberToDecimalStyle(compensado - totalCredito),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'TOTAL DE CRÉDITOS UTILIZADOS',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: convertNumberToDecimalStyle(compensado.toString()),
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [15, 15, 35, 500], // left, top, right, bottom
    };
  };
  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
