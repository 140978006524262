import styled from 'styled-components';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { Typography } from '@material-ui/core';
import UnityImage from './img/Unity.svg';

export const ModalTitleHeader = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: var(--secondary);
  margin-top: 6px;
`;

export const HeaderModal = styled.div`
  background-color: var(--primary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const LeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const RightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const WrappedModal = styled.div`
  background-color: #ecf0f5;
  height: auto;
  width: auto;
  min-height: 400px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  border: 2px solid var(--primary);
`;

export const ObjectPaper = styled.div`
  background-color: #ecf0f5;
  min-height: 450px;
  max-width: 1000px;
  min-width: 900px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const CircuitsIcon = styled(FlashOnIcon)`
  color: var(--secondary);
  margin-right: 15px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ChartTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-right: 10px;
  }
`;

export const IconValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const TypographyGeneral = styled(Typography)`
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`;

export const TypographyTitle = styled(TypographyGeneral)`
  color: var(--primary);
  font-size: 18px;
  text-align: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767px) {
    max-width: 220px;
    /* border: solid 1px red; */
  }
`;

export const TypographySubTitle = styled(TypographyGeneral)`
  color: var(--gray);
  /* margin-left: 30px; */
  font-size: 18px;

  /* @media (max-width: 978px) {
    margin-left: 0px;
  } */
`;

export const UnityIcon = styled.img.attrs((props) => ({
  src: UnityImage,
}))`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;
