import React from 'react';
import { NavButton } from './style';
import Tooltip from '@material-ui/core/Tooltip';

export default function IoseNavButton({
  icon,
  onClick,
  tooltip,
  disabled,
  title,
}) {
  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <NavButton onClick={onClick} disabled={disabled}>
        {icon}
        {title && <span>{title}</span>}
      </NavButton>
    </Tooltip>
  );
}
