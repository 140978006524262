import { useContext } from 'react';
import { ioseAPI, AccountContext, Messages } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConsumptionComparison() {
  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getAllConsumptionComparisons = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllConsumptionComparison(
      headers,
      10000,
      page_size
    );

    const consumptionComparison = response.data.data || 0;

    if (consumptionComparison.length === 0) {
      console.error(Messages.noRegisteredComparison);
      throw new Error(Messages.noRegisteredComparison);
    }
    return consumptionComparison;
  };

  const {
    data: consumptionComparison,
    error: consumptionComparisonError,
    isLoading,
  } = useQuery(['consumptionComparison'], getAllConsumptionComparisons);

  return {
    consumptionComparison,
    consumptionComparisonError,
    isLoading,
  };
}

export default useConsumptionComparison;
