import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

//Imports from Material UI
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//Imports from style
import {
  GridStyled,
  GridContainer,
  IoseContainerStyled,
  GridStyledList,
} from './style';

//Imports to generate Delete Alert
import { useAlert } from 'react-alert';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { ioseAPI, WebSocketEndPoint } from 'common/ioseAPI';

//Import from Messages
import Messages from 'common/Messages';
import LocalStorage from 'common/local_storage_api';

//Import from IOSE
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import { IoseEditButton } from 'components/IoseButtonIcon';
import { Grid } from '@material-ui/core';
import IoseDashboardListCircuits from 'components/IoseDashboardListCircuits';
import IoseDashboardInfoCircuitsSelected from 'components/IoseDashboardInfoCircuitsSelected';
import IoseDashboardInfoSwitchboard from 'components/IoseDashboardInfoSwitchboard';
import IoseDashboardAnalysisCard from 'components/IoseDashboardAnalysisCard';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';
// import IoseContainer from 'components/IoseContainer';
// import IoseDashboardComparationCard from 'components/IoseDashboardComparationCard';
// import IoseDashboardSwitchboardLog from 'components/IoseDashboardSwitchboardLog';

import IoseButtonIcon2 from 'components/IoseButtonIcon2';

import UnityIcon from './img/Unity.svg';

import IoseApiLib from 'iose-api-lib';
import { ioseIotAPI } from 'common/ioseIotAPI';

export default function DashboardContainer({
  openSide,
  group,
  backAction,
  getcards,
  setGetcards,
}) {
  const alert = useAlert();
  const history = useHistory();
  const { path } = useRouteMatch();
  let location = useLocation();
  let socket = {};

  const { getSession } = useContext(AccountContext);
  const session = LocalStorage.getSession();

  const clientName = location.state.clientName;
  const unityName = location.state.unityName;
  const switchboardData = location.state.switchboardData;
  const uuid_unity = location.state.switchboardData.uuid_unity;
  const uuid_group = location.state.switchboardData.uuid_group;
  const allCircuits = location.state.switchboardData.circuits;
  const tariff_period = location.state.tariff_period;
  const tariff_data = location.state.tariff_data;
  const contracted_demand = location.state.contracted_demand;
  //eslint-disable-next-line
  const identification_number = location.state.identification_number;
  const subtitle = unityName;

  const page_size = 200;

  const button = (
    <IoseButtonIcon2
      icon1={UnityIcon}
      icon2={<ArrowForwardIcon />}
      click={redirectOpenSwitchboard}
    />
  );

  //Create object new Date to initial date according tariff period day
  const current_year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  const current_day = new Date().getDate();

  //Handle to set moth so show current day in the chart
  const month = current_day < tariff_period ? current_month - 1 : current_month;

  const defaultInitialDay = tariff_period
    ? new Date(current_year, month, tariff_period)
    : new Date();

  //STATES >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const [title, setTitle] = useState(switchboardData.name);
  const [description, setDescription] = useState(switchboardData.description);
  const [goal, setGoal] = useState(switchboardData.goal);
  const [identificationNumber, setIdentificationNumber] = useState(
    switchboardData.identification_number
  );

  //This state set the all circuit of switchboard
  const [circuits, setCircuits] = useState(switchboardData.circuits);

  //This state set the all circuit selected of switchboard
  const [circuitsSelected, setcircuitsSelected] = useState(
    switchboardData.circuits
  );

  //This state set the measures returned of request
  const [measures, setMeasures] = useState([]);

  //This state set the json to plot ChartBar
  const [preparedData, setPreparedData] = useState([]);

  //This state set error in get lastmeasures circuits
  const [lastMeasuresError, setLastMeasuresError] = useState('');

  //This state set error in get all measures  circuits
  const [allMeasuresError, setAllMeasuresError] = useState('');

  //This state set error in get getTariffBasedUnityType requisiton
  const [getInfoError, setGetInfoError] = useState('');

  //This state set error in get getInfo

  //This state set the flag to identify first load of component
  const [firstLoad, setFirstLoad] = useState(true);

  //States to set date of ChartBar
  const [initialDate, setInitialDate] = useState(defaultInitialDay);

  const [finalDate, setFinalDate] = useState(
    new Date(
      new Date(defaultInitialDay).setMonth(
        new Date(defaultInitialDay).getMonth() + 1
      )
    ).toISOString()
  );

  //States to set date of Historic Table
  const [initialDateHistoric, setInitialDateHistoric] = useState(initialDate);
  const [finalDateHistoric, setFinalDateHistoric] = useState(finalDate);

  //States related to Info Switchboard
  const [
    consumptionTotalSwitchboard,
    setConsumptionTotalSwitchboard,
  ] = useState(0);
  const [
    moneyConsumptionSwitchboard,
    setMoneyConsumptionSwitchboard,
  ] = useState(0);

  //States related to Info Circuits Selecteds
  const [consumptionTotal, setConsumptionTotal] = useState(0);
  const [money_consumption, setMoneyConsumption] = useState(0);
  // const [money_generated, setMoneyGenerated] = useState(0);

  //This state set the json to form data in Historic/Measures Table
  const [dataTableHistoric, setDataTableHistoric] = useState([]);

  const [pageCodeHistoricTable, setPageCodeHistoricTable] = useState([]);
  const [loadingSeeHistoric, setLoadingSeeHistoric] = useState(true);

  //This state set the json to form data in Circut Table
  const [dataCircuitTable, setDataCircuitTable] = useState([]);

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //State to set loading chart
  const [loadingChart, setLoadingChart] = useState(false);
  // const [getCircuits, setGetCircuits] = useState(false);

  //State to trigger Circuit
  const [triggerCircuit, setTriggerCircuit] = useState({});

  const [chartMode, setChartMode] = useState('Acumulado');
  const [chartType, setChartType] = useState('Sem Geração');

  const [modifiedChart, setModifiedChart] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [dataChanged, setDataChanged] = useState({});

  //State to guard info all circuits switchboard
  const [infoAllCircuits, setInfoAllCircuits] = useState('');

  //State to guard totalAddFlag
  const [totalAddFlag, setTotalAddFlag] = useState(0);

  //State to set y values limit chatbar generation
  const [limitsChartBar, setlimitsChartBar] = useState({
    positive: 200,
    negative: 200,
  });

  const [generation, setGeneration] = useState(0);
  const [generationCircuits, setGenerationCircuits] = useState(0);

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  //USEEFFECTS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // Update name and description when edit it
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getcards && changeInfoSwitchboard();
    }
    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcards]);

  // Update circuit list
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getcards && getAllCircuits();
    }
    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcards]);

  // Change circuit list when circuits change
  useEffect(() => {
    getLastMeasureAllCircuits();
    getInfoCircuits();
    setHistoricDate(initialDate, finalDate);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuits, initialDate, finalDate]);

  //Change goal change switchboard
  useEffect(() => {
    setGoal(switchboardData.goal);
  }, [switchboardData.goal]);

  //Change goal change switchboard
  useEffect(() => {
    setIdentificationNumber(switchboardData.identification_number);
  }, [switchboardData.identification_number]);

  //Prepare Data to send Charbar always first load
  useEffect(() => {
    if (!firstLoad) {
      prepareData();
      changeChart('', true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, circuitsSelected, measures, chartMode]);

  // Desconect websocket when out from Dashboard and listen server
  useEffect(() => {
    if (allCircuits.lenght !== 0) {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      socket = new WebSocket(WebSocketEndPoint);

      if (socket.readyState !== 1) {
        socket.onopen = (e) => {
          dashSubscribeWebSocket(socket);
        };
      }

      socket.onmessage = (e) => {
        let websocketMessage = JSON.parse(e.data);

        setTriggerCircuit(websocketMessage);
        updateStateCircuit(websocketMessage);
      };

      return () => {
        socket.close();
      };
    }
  }, [allCircuits]);

  // Update states when change switchbards
  useEffect(() => {
    setFirstLoad(true);
    setCircuits(switchboardData.circuits);
    setTitle(switchboardData.name);
    setDescription(switchboardData.description);
    socket !== {} && socket.readyState === 1 && dashSubscribeWebSocket(socket);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchboardData]);

  useEffect(() => {
    prepareDataInfoSwitchboard();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAllCircuits]);

  useEffect(() => {
    prepareDataCircuitTable();
    prepareDataInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAllCircuits, circuitsSelected]);

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  //FUNCTIONS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  function redirectOpenSwitchboard() {
    history.push({
      pathname: `${path}/switchboard`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: location.state.uuid_unity,
        switchboardData: location.state.switchboardData,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
    openSide();
  }

  function redirectEditSwitchboard() {
    history.push({
      pathname: `${path}/switchboard/edit`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: location.state.uuid_unity,
        switchboardData: location.state.switchboardData,
        name: title,
        description: description,
        goal: goal,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
        identification_number: identificationNumber,
      },
    });
    openSide();
  }

  function redirectShowCircuit(circuitSelected) {
    history.push({
      pathname: location.pathname.includes('dashboard')
        ? `/global/dashboard/switchboard/circuit`
        : `/global/client/switchboard/circuit`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: location.state.uuid_unity,
        switchboardData: location.state.switchboardData,
        circuitData: circuitSelected,
        backbutton: false,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
    openSide();
  }

  //Function to redirecto to IoseFormCircuitEditCard
  function redirectEditCircuit(circuitSelected) {
    history.push({
      pathname: `${path}/switchboard/circuit/edit`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: location.state.uuid_unity,
        switchboardData: location.state.switchboardData,
        circuitData: circuitSelected,
        backbutton: false,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
    openSide();
  }

  //Functions to Delete Switchboard(Group)
  function errorHandlingDeleteSwitchboard(error) {
    alert.show(error ? error : `${Messages.errorDeleteSwitchboard}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  const deleteSwitchboard = () => {
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .deleteGroup(headers, location.state.switchboardData.uuid_group)
          .then(() => {
            backAction();
            alert.show(`${Messages.successDeleteSwitchboard}`, {
              title: 'Sucesso!',
              closeCopy: 'Fechar',
            });
            // setGetcards(true);
          })
          .catch((error) => {
            errorHandlingDeleteSwitchboard(error.response.data.message);
          });
      })
      .catch((error) => {
        errorHandlingDeleteSwitchboard();
      });
  };

  //Functions to Delete Circuit
  function errorHandlingDeleteCircuit(error) {
    alert.show(error ? error : `${Messages.errorDeleteCircuit}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  const deleteCircuit = (uuid_circuit) => {
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .deleteCircuit(headers, uuid_circuit)
          .then(() => {
            alert.show(`${Messages.successDeleteCircuit}`, {
              title: 'Sucesso!',
              closeCopy: 'Fechar',
            });
            // setGetcards(true);
          })
          .catch((error) => {
            errorHandlingDeleteCircuit(error.response.data.message);
          });
      })
      .catch(() => {
        errorHandlingDeleteCircuit();
      });
  };

  //Function to change the name and description when edit it
  function changeInfoSwitchboard() {
    getSession().then(({ headers }) => {
      ioseAPI
        .getAllGroup(headers, uuid_unity, 0, page_size)
        .then((response) => {
          setGetcards(false);
          let allGroups =
            response.data.data[0] === null ? [] : response.data.data;

          const switchboardCurrent = allGroups.filter(
            (switchboard) => switchboard.uuid_group === uuid_group
          );
          setTitle(switchboardCurrent[0].name);
          setDescription(switchboardCurrent[0].description);
          setGoal(switchboardCurrent[0].goal);
        });
    });
  }

  //Functions to GET ALL CIRCUITS
  function getAllCircuits() {
    // setNotificationType(0);

    getSession().then(({ headers }) => {
      ioseAPI
        .getAllCircuit(headers, uuid_unity, 0, page_size)
        .then((response) => {
          let allCircuits =
            response.data.data[0] === null ? [] : response.data.data;
          // setNextPage(response.data.next_page);

          const circuitOfSwitchboatd = allCircuits.filter(
            (circuit) => circuit.uuid_group === switchboardData.uuid_group
          );

          setCircuits(circuitOfSwitchboatd);
          // setAllCircuits(allCircuits);
          // setLoading(false);
          // setGetSwitchboard(false);
          // setreloading(false);
        });
    });
  }

  //Functions to GET ALL LAST MEASURE OF EACH CIRCUIT TO CHARTBAR
  async function getLastMeasureAllCircuitsRequisition(circuits, headers) {
    const dateinitial = new Date(Date.parse(initialDate));
    const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
    const initialDateZeroHour = new Date(
      initialDateHistoricConverted
    ).toISOString();

    const datefinal = new Date(Date.parse(finalDate));
    const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
    const finalDateZeroHour = new Date(
      finalDateHistoricConverted
    ).toISOString();

    const lastMeasuresAllCircuit = circuits.map((circuit) =>
      ioseIotAPI.getLastMeasure(
        headers,
        circuit.uuid_circuit,
        initialDateZeroHour,
        finalDateZeroHour
      )
    );
    return Promise.all(lastMeasuresAllCircuit);
  }

  async function getLastMeasureAllCircuits() {
    setLoadingChart(true);
    getSession().then(({ headers }) => {
      getLastMeasureAllCircuitsRequisition(circuits, headers)
        .then((data) => {
          setMeasures(data);
          setFirstLoad(false);
          setLoadingChart(false);
          setLastMeasuresError('');
        })
        .catch(() => {
          setLoadingChart(false);
          setLastMeasuresError('Erro ao buscar medidas de algum circuito');
        });
    });
  }

  //Functions related to resquest to GET ALL DATA MEASURES EACH CIRCUIT TO HISTORIC/CIRCUIT TABLE --------------
  async function getAllMeasureAllCircuitsRequisition(
    circuits,
    headers,
    firstTime
  ) {
    if (firstTime) {
      const dateinitial = new Date(Date.parse(initialDateHistoric));
      const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
      const initialDate = new Date(initialDateHistoricConverted).toISOString();

      const datefinal = new Date(Date.parse(finalDateHistoric));
      const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
      const finalDate = new Date(finalDateHistoricConverted).toISOString();

      const allDataMeasuresCircuits = circuits.map((circuit) =>
        ioseIotAPI.getAllMeasure(
          headers,
          circuit.uuid_circuit,
          initialDate,
          finalDate,
          0,
          1000
        )
      );

      return Promise.all(allDataMeasuresCircuits);
    } else {
      let allDataMeasuresCircuits = [];

      for (let i = 0; i < circuits.length; i++) {
        const circuit = circuits[i];

        let nextpage = pageCodeHistoricTable.filter(
          (pageCode) => pageCode.uuid_circuit === circuit.uuid_circuit
        );

        nextpage.length !== 0 &&
          allDataMeasuresCircuits.push(
            ioseIotAPI.getAllMeasure(
              headers,
              circuit.uuid_circuit,
              initialDateHistoric,
              finalDateHistoric,
              nextpage[0],
              1000
            )
          );
      }

      return Promise.all(allDataMeasuresCircuits);
    }
  }

  async function getAllMeasuresAllCircuit(firstTime) {
    setLoadingSeeHistoric(true);

    const getHeader = () => {
      getSession().then(({ headers }) => {
        getAllMeasureAllCircuitsRequisition(
          circuitsSelected,
          headers,
          firstTime
        )
          .then((data) => {
            setLoading(false);
            prepareDataHistoricTable(data, firstTime);
            setAllMeasuresError('');
          })
          .catch((error) => {
            setLoading(false);
            setAllMeasuresError('Erro ao buscar medidas de algum circuito');
          });
      });
    };

    if (firstTime) {
      setLoading(true);
      setPageCodeHistoricTable([]);
      getHeader();
    } else {
      pageCodeHistoricTable.length !== 0
        ? getHeader()
        : setLoadingSeeHistoric(false);
    }
  }
  //------------------------------------------------------------

  //Function to get consumption and vaule each circuit
  async function getInfoCircuits() {
    setGetInfoError('');

    const allcircuitsdIdArray = circuits.map((circuit) => {
      return circuit.uuid_circuit;
    });

    try {
      const sessionData = await getSession();

      const response = await ioseIotAPI.getTariffBasedUnityType(
        sessionData.headers,
        uuid_unity,
        allcircuitsdIdArray,
        initialDate,
        finalDate
      );

      let allInfo = response.data;

      setInfoAllCircuits(allInfo);
      setTotalAddFlag(allInfo.totalAddFlag);

      return { result: true, message: allInfo };
    } catch (error) {
      let messageErro = error
        ? error.response.data.message
        : 'Erro ao buscar dados dos circuitos!';

      setGetInfoError(messageErro);
      return { result: false, message: messageErro };
    }
  }

  const convertToNegative = (value) => {
    return value !== 0 ? -1 * value : 0;
  };

  //Function for prepare data before send to ChartBar
  function prepareData() {
    //Ids selected to prepare data
    const id = circuitsSelected.map((circuit) => {
      return circuit.uuid_circuit;
    });

    //Array to guard objects json to form DataChartBar
    let chartData = [];
    let chartDataDaily = [];

    //Select measures according id circuits selecteds
    let selectedMeasures = id.map((idCircuit) => {
      return measures.filter(
        (selected) => selected.data.data.uuid_circuit === idCircuit
      )[0];
    });

    selectedMeasures = selectedMeasures.map((measure) => measure.data);

    if (selectedMeasures.length > 0) {
      const arrayDate = selectedMeasures[0].data.measures.dates;

      // Sum all generate data
      let arrayGenerated = selectedMeasures.map(
        (data) => data.data.measures.arrayGenerate
      );
      arrayGenerated = arrayGenerated.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all consumed data
      let arrayConsumed = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumed
      );

      arrayConsumed = arrayConsumed.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all predicted data
      let arrayConsumedPredicted = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumedPredicted
      );
      arrayConsumedPredicted = arrayConsumedPredicted.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Form a object to accumulate mode chart
      for (let i = 0; i < arrayDate.length; i++) {
        let arrayDateConverted = new Date(arrayDate[i]);
        let month = arrayDateConverted.getMonth() + 1;
        let day = arrayDateConverted.getDate();

        const dateFormated = `${day}/${('00' + month).slice(-2)}`;

        chartData.push({
          'date': dateFormated,
          'consumido': convertToKWH(arrayConsumed[i]),
          'gerado': convertToKWH(arrayGenerated[i]),
          'balanço': convertToKWH(arrayConsumed[i] - arrayGenerated[i]),
          'previsao': convertToKWH(arrayConsumedPredicted[i]),
        });
      }

      //Form a object to daily mode chart
      chartDataDaily = chartData.map((currentDay, index) => {
        const lastDay = chartData[index - 1];

        const arrayDate = currentDay.date.split('/');
        const defaultData = new Date();
        defaultData.setDate(arrayDate[0]);
        defaultData.setMonth(arrayDate[1] - 1);
        const lessThanToday = defaultData <= new Date();

        let newConsumido = 0;
        let newGenerated = 0;
        let newBalance = 0;

        if (lastDay && lessThanToday) {
          newConsumido = Number(
            (currentDay.consumido - lastDay.consumido).toFixed(2)
          );

          newGenerated = Number(
            (currentDay.gerado - lastDay.gerado).toFixed(2)
          );

          newBalance = Number(
            (currentDay.balanço - lastDay.balanço).toFixed(2)
          );
        } else {
          newConsumido = currentDay.consumido;
          newGenerated = currentDay.gerado;
          newBalance = currentDay.balanço;
        }

        return {
          'date': currentDay.date,
          'consumido': newConsumido,
          'gerado': newGenerated,
          'balanço': newBalance,
          'previsao': 0,
        };
      });

      getLimitsChartValue(chartMode === 'Diário' ? chartDataDaily : chartData);

      if (chartType === 'Sem geração') {
        setPreparedData(chartMode === 'Diário' ? chartDataDaily : chartData);
      } else {
        let newChartDataDaily = [...chartDataDaily];
        newChartDataDaily = newChartDataDaily.map((data) => {
          data.gerado = convertToNegative(data.gerado);

          return data;
        });

        let newChartData = [...chartData];
        newChartData = newChartData.map((data) => {
          data.gerado = convertToNegative(data.gerado);
          return data;
        });

        setPreparedData(
          chartMode === 'Diário' ? newChartDataDaily : newChartData
        );
      }
    } else {
      setPreparedData([]);

      if (circuits.length === 0) {
        setConsumptionTotalSwitchboard(0);
        setMoneyConsumptionSwitchboard(0);
        setConsumptionTotal(0);
        setMoneyConsumption(0);
      }
    }
  }

  function getLimitsChartValue(data) {
    let maxValue = 0;

    const compareValues = (a, b) => a - b;

    const keyValueArray = Object.entries(data);

    const arrayConsump = keyValueArray
      .map((element) => element[1].consumido)
      .sort((a, b) => compareValues(a, b));

    const arrayGenerate = keyValueArray
      .map((element) => element[1].gerado)
      .sort((a, b) => compareValues(a, b));

    const arrayBalance = keyValueArray
      .map((element) => element[1].balanço)
      .sort((a, b) => compareValues(a, b));

    const maxValueConsump = arrayConsump[arrayConsump.length - 1];
    const maxValueGenerate = arrayGenerate[arrayGenerate.length - 1];
    const maxValueBalance = arrayBalance[arrayBalance.length - 1];

    if (maxValueConsump > maxValueGenerate) {
      maxValue = maxValueConsump;
    } else {
      maxValue =
        maxValueGenerate > maxValueBalance ? maxValueGenerate : maxValueBalance;
    }

    const limits = {
      positive: maxValue + 5,
      negative: (maxValue + 5) * -1,
    };

    setlimitsChartBar(limits);
  }

  //Function for prepare data before send Historic/Measure Table
  function prepareDataHistoricTable(data, firstTime) {
    const preparedDataHistoricTable = [];
    const nextPagesArray = [];

    for (let j = 0; j < data.length; j++) {
      const value = data[j];

      if (typeof value.data.next_page !== 'undefined') {
        nextPagesArray.push(value.data.next_page);
      } else {
        setLoadingSeeHistoric(false);
      }

      const circuit = circuitsSelected.filter(
        (circuitSelected) => value.data.uuid_circuit === circuitSelected.uuid
      );

      const circuitName = circuit[j].name;
      const circuitMeasures = value.data.data.measures;

      if (circuitMeasures.length !== 0) {
        for (let i = 0; i < circuitMeasures.length; i++) {
          preparedDataHistoricTable.push({
            'circuit': circuitName,
            'uuid': circuitMeasures[i].uuid_measure,
            'active_power': circuitMeasures[i].active_power,
            'apparent_power': circuitMeasures[i].apparent_power,
            'line_frequency': circuitMeasures[i].line_frequency,
            'power_factor': circuitMeasures[i].power_factor,
            'reactive_power': circuitMeasures[i].reactive_power,
            'relay_state': circuitMeasures[i].relay_state,
            'voltage': circuitMeasures[i].voltage,
            'data': circuitMeasures[i].created_at,
            'current': circuitMeasures[i].current,
            'export_active_energy': convertToKWH(
              circuitMeasures[i].export_active_energy
            ),
            'export_reactive_energy': convertToKWH(
              circuitMeasures[i].export_reactive_energy
            ),
            'import_active_energy': convertToKWH(
              circuitMeasures[i].import_active_energy
            ),
            'import_reactive_energy': convertToKWH(
              circuitMeasures[i].import_reactive_energy
            ),
          });
        }
      }
    }

    setPageCodeHistoricTable(nextPagesArray);

    if (firstTime) {
      setDataTableHistoric(preparedDataHistoricTable);
    } else {
      setDataTableHistoric(dataTableHistoric.concat(preparedDataHistoricTable));
    }
  }

  //Function for prepare data before send Circuit Table
  async function prepareDataCircuitTable() {
    let dataCircuitTableLocal = [];

    let totalEnergy = infoAllCircuits.totalEnergy;

    if (infoAllCircuits.arrayConsumption && circuits.lenght !== 0) {
      circuits.forEach((circuit) => {
        let circuitInfo = infoAllCircuits.arrayConsumption.find(
          (info) => info.uuid_circuit === circuit.uuid_circuit
        );

        if (circuitInfo) {
          let consumptionPercent = 0;

          if (totalEnergy > 0) {
            consumptionPercent = parseFloat(
              ((circuitInfo.totalEnergyCircuit / totalEnergy) * 100).toFixed(2)
            );
          }

          const dataTable = {
            'uuid_circuit': circuit.uuid_circuit.toString(),
            'name': circuit.name,
            'description': circuit.description,
            'energy_consumed': parseFloat(
              circuitInfo.totalEnergyCircuit.toFixed(2)
            ),
            'money_consumed': parseFloat(
              circuitInfo.totalTaxCircuit.toFixed(2)
            ),
            'has_relay': circuit.has_relay,
            'relay_state': circuit.relay_state,
            'percent_energy_consumed': consumptionPercent,
            'code_module': circuit.code_module,
          };

          const has_tc_primary = 'primary_tc' in circuit;
          const has_tc_secondary = 'secondary_tc' in circuit;

          if (has_tc_primary && has_tc_secondary) {
            dataTable['primary_tc'] = circuit.primary_tc;
            dataTable['secondary_tc'] = circuit.secondary_tc;
          }

          dataCircuitTableLocal.push(dataTable);
        }
      });
    }
    setDataCircuitTable(dataCircuitTableLocal);
  }
  // console.log(dataCircuitTable);
  //Function for prepare data before send to Info Circuits Selecteds
  function prepareDataInfo() {
    let consumptionsCircuits = [];
    let generationsCircuits = [];
    let taxsCircuits = [];

    let totalConsumptionsCircuits = 0;
    let totalGenerationsCircuits = 0;
    let totalTaxsCircuits = 0;

    if (infoAllCircuits.arrayConsumption && circuitsSelected.length !== 0) {
      circuitsSelected.forEach((circuit) => {
        let circuitInfo = infoAllCircuits.arrayConsumption.find(
          (info) => info.uuid_circuit === circuit.uuid_circuit
        );

        if (circuitInfo) {
          consumptionsCircuits.push(circuitInfo.totalEnergyCircuit);
          taxsCircuits.push(circuitInfo.totalTaxCircuit);
          generationsCircuits.push(
            circuitInfo.totalExportEnergyCircuit
              ? circuitInfo.totalExportEnergyCircuit
              : 0
          );
        }
      });

      if (
        consumptionsCircuits.length !== 0 &&
        taxsCircuits.length !== 0 &&
        generationsCircuits.length !== 0
      ) {
        totalConsumptionsCircuits = consumptionsCircuits.reduce(
          (total, value) => total + value
        );

        totalTaxsCircuits = taxsCircuits.reduce(
          (total, value) => total + value
        );

        totalGenerationsCircuits = generationsCircuits.reduce(
          (total, value) => total + value
        );
      }
    }

    setConsumptionTotal(parseFloat(totalConsumptionsCircuits.toFixed(2)));
    setMoneyConsumption(parseFloat(totalTaxsCircuits.toFixed(2)));
    setGenerationCircuits(parseFloat(totalGenerationsCircuits.toFixed(3)));
  }

  //Function for prepare data before send to ChartBar
  function prepareDataInfoSwitchboard() {
    let totalConsumptionSwitchboard = 0;
    let totalTaxSwitchboard = 0;
    let totalGeneration = 0;

    if (infoAllCircuits.totalEnergy !== 0 && infoAllCircuits.totalTaxEnergy) {
      totalConsumptionSwitchboard = infoAllCircuits.totalEnergy;
      totalTaxSwitchboard = infoAllCircuits.totalTaxEnergy;
    }

    if (infoAllCircuits.totalExportEnergy) {
      totalGeneration = infoAllCircuits.totalExportEnergy;
      setGeneration(parseFloat(totalGeneration.toFixed(3)));
    }

    setConsumptionTotalSwitchboard(
      parseFloat(totalConsumptionSwitchboard.toFixed(2))
    );
    setMoneyConsumptionSwitchboard(parseFloat(totalTaxSwitchboard.toFixed(2)));
  }

  function convertToKWH(measuresWatts) {
    const measuresConverted = Number((measuresWatts / 1000).toFixed(2));
    return measuresConverted;
  }

  function setDate(initialDateCard, finalDateCard) {
    setInitialDate(initialDateCard);
    setFinalDate(finalDateCard);
    // setFirstLoad(true);
  }

  //Function to set initial and final date of Historic/Measures Tables
  function setHistoricDate(initialDateCard, finalDateCard) {
    setInitialDateHistoric(initialDateCard);
    setFinalDateHistoric(finalDateCard);
  }

  const getUuids = (group, userId) => {
    switch (group) {
      case 'admin':
        return { uuidEmployee: '', uuidAdmin: userId };

      case 'manager' || 'employee':
        return { uuidEmployee: userId, uuidAdmin: '' };

      default:
        return { uuidEmployee: '', uuidAdmin: '' };
    }
  };

  //Function to TRIGGER CIRCUIT
  async function triggerCircuitAction(
    uuid_circuit,
    state_actuation,
    justification
  ) {
    const group = session.group;
    const userId = session.userId;

    const { uuidEmployee, uuidAdmin } = getUuids(group, userId);

    try {
      const response = await IoseApiLib.Circuit.triggerCircuit(
        uuid_circuit,
        state_actuation,
        uuidEmployee,
        uuidAdmin,
        justification
      );
      return { result: true, message: response.data.message };
    } catch (err) {
      const message = err.response
        ? err.response.data.name
        : Messages.errorTrigger;

      return { result: false, message: message };
    }
  }

  //Function to Subscribe in Websocket
  function dashSubscribeWebSocket(socket) {
    socket.send(
      JSON.stringify({
        'action': 'subscribeGroup',
        'data': { 'uuid_group': switchboardData.uuid_group },
      })
    );
  }

  function updateStateCircuit(websocketMessage) {
    // const allCircuits = location.state.switchboardData.circuits;

    if (websocketMessage.type === 'activation') {
      const circuitselected = allCircuits.filter(
        (circuit) => circuit.uuid_circuit === websocketMessage.uuid_circuit
      );

      circuitselected[0].relay_state = websocketMessage.state_circuit;
    }
  }

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const changeChart = (column, close = false) => {
    const data = preparedData;

    if (!close) {
      setShowButton(true);

      const changeData = data.map((item) => {
        let defaultObj = {
          balanço: 0,
          consumido: 0,
          date: item.date,
          gerado: 0,
          previsao: 0,
        };

        const arrayKeys = Object.keys(item);

        for (let key of arrayKeys) {
          if (key === column) {
            defaultObj[key] = item[key];
          }
        }

        return defaultObj;
      });

      setDataChanged(changeData);
      setModifiedChart(true);
    } else {
      setModifiedChart(false);
      setShowButton(false);
    }
  };

  return (
    <IoseContainerStyled>
      <IoseSubHeaderBar
        title={title}
        subtitle={subtitle}
        button={button}
        description={description}
        dashboard="true"
      >
        {group === 'super' || group === 'admin' ? (
          <>
            <IoseDeleteInputModal
              title="DELETAR QUADRO"
              name={switchboardData.name}
              deletefunc={deleteSwitchboard}
              param={switchboardData.uuid_group}
              primarybutton={'true'}
            />
            <IoseEditButton
              left="20px"
              primary="true"
              onClick={(e) => {
                redirectEditSwitchboard();
              }}
              tooltip="Editar Quadro"
            />
          </>
        ) : (
          <></>
        )}
      </IoseSubHeaderBar>

      {/* <IoseCardArea> */}
      <GridContainer
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {/* Grid Infos and Chart */}
        <GridStyled item xs={12} sm={12} md={12}>
          <Grid container direction="row">
            {/* Grid Circuit List */}
            <GridStyledList item xs={12} sm={5} md={3}>
              <IoseDashboardListCircuits
                circuits={circuits}
                selectCircuits={(e) => setcircuitsSelected(e)}
                disabledList={false}
              />
            </GridStyledList>

            {/* Grid Info Circuits*/}
            <GridStyled item xs={12} sm={7} md={9}>
              <IoseDashboardInfoSwitchboard
                consumption={consumptionTotalSwitchboard}
                money={moneyConsumptionSwitchboard}
                goal={goal}
                totalAddFlag={totalAddFlag}
                generation={generation}
              />
              <IoseDashboardInfoCircuitsSelected
                consumptionTotal={consumptionTotal}
                moneyConsumption={money_consumption}
                generationCircuits={generationCircuits}
              />
            </GridStyled>

            {/* Grid Consumption Charts */}
            <GridStyled item xs={12} sm={12} md={12}>
              <IoseDashboardAnalysisCard
                dataTable={dataCircuitTable}
                dataChartBar={preparedData}
                showCircuit={redirectShowCircuit}
                redirectEditCircuit={redirectEditCircuit}
                deleteCircuit={deleteCircuit}
                group={group}
                setDate={setDate}
                setHistoricDate={setHistoricDate}
                setPageCodeHistoricTable={setPageCodeHistoricTable}
                setDataTableHistoric={setDataTableHistoric}
                getAllMeasuresAllCircuit={getAllMeasuresAllCircuit}
                dataTableHistoric={dataTableHistoric}
                loading={loading}
                loadingChart={loadingChart}
                goal={goal}
                switchboardData={switchboardData}
                initialDateHistoric={initialDateHistoric}
                finalDateHistoric={finalDateHistoric}
                lastMeasuresError={lastMeasuresError}
                allMeasuresError={allMeasuresError}
                getInfoError={getInfoError}
                triggerCircuit={triggerCircuit}
                triggerCircuitAction={triggerCircuitAction}
                chartMode={chartMode}
                setChartMode={setChartMode}
                chartType={chartType}
                setChartType={setChartType}
                initialDateContainer={initialDate}
                finalDateContainer={finalDate}
                circuits={circuits}
                limitsChartBar={limitsChartBar}
                loadingSeeHistoric={loadingSeeHistoric}
                changeChart={changeChart}
                modifiedChart={modifiedChart}
                dataChanged={dataChanged}
                showButton={showButton}
              />
            </GridStyled>
          </Grid>
        </GridStyled>
      </GridContainer>
    </IoseContainerStyled>
  );
}
