import React from 'react';
import { TableBody, TableCell, Paper, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as S from './style';

import { LoadingIndicator, IoseInfinityScroll } from 'components';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const CircuitsTableRow = ({ value }) => (
  <S.TableRowStyled align="center">
    <TableCellCentered>{value.group}</TableCellCentered>
    <TableCellCentered>{value.id}</TableCellCentered>
    <TableCellCentered>
      {convertNumberToDecimalStyle(value.consumption)}
    </TableCellCentered>
  </S.TableRowStyled>
);

const CircuitsTable = ({ data }) => (
  <S.StyledTable>
    <S.TableHeadStyled>
      <S.TableRowStyled align="center">
        <TableHeaderCellCentered>QUADRO</TableHeaderCellCentered>
        <TableHeaderCellCentered>ID DO CIRCUITO</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONSUMO KWH</TableHeaderCellCentered>
      </S.TableRowStyled>
    </S.TableHeadStyled>

    <TableBody>
      {data.map((value, index) => (
        <CircuitsTableRow key={index} value={value} />
      ))}
    </TableBody>
  </S.StyledTable>
);

export default function IoseAdmDashTableCircuits({ loading, CircuitsData }) {
  const renderTable = () => {
    return (
      <IoseInfinityScroll
        dataLength={CircuitsData.length}
        next={() => {}}
        hasMore={false}
        loading={false}
        endList={false}
        sidebar={false}
        marginTop={0}
        padding={0}
        colortextend={null}
        sizetextend={null}
        scrollableTarget="scrollableDiv"
        height={400}
      >
        <CircuitsTable data={CircuitsData} />
      </IoseInfinityScroll>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>{renderTable()}</Paper>
      )}
    </Grow>
  );
}
