// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSystemVariables() {
  const [noSystemVariablesError, setNoSystemVariablesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getSystemVariables = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

   do {
    const response = await ioseAPI.getAllSystemVariables(
      headers,
      lastEvaluatedKey,
      limit
    );

    const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

    allData = allData.concat(data);
    lastEvaluatedKey = newLastEvaluatedKey;
   } while (lastEvaluatedKey)


    if (allData.length === 0) {
      setNoSystemVariablesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return allData;
  };

  const {
    data: systemVariables,
    error: systemVariablesError,
    refetch: refreshSystemVariables,
    isLoading,
  } = useQuery(['systemVariables'], getSystemVariables);

  return {
    systemVariables,
    systemVariablesError,
    noSystemVariablesError,
    refreshSystemVariables,
    isLoading,
  };
}

export default useSystemVariables;
