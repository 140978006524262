import React, { useState } from 'react';
import PropType from 'prop-types';

//Hook to send data throught urls
import { useLocation } from 'react-router-dom';

//Imports from Style
import {
  Container,
  Header,
  FormStyled,
  IoseTextFieldSidebarStyled,
  YellowRadio,
  RadioGroupStyled,
  FormControlLabelStyled,
} from './style';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from 'common/ValidationsForms';

//Imports from IOSE Components
import { IoseBackButton } from 'components/IoseButtonIcon';
import IoseButtonSideBar from 'components/IoseButton';

/**
 This component create a form to create employee
 */
export default function IoseEmployeeCreateCard({
  group,
  clickBackShowEmployee,
  addEmployee,
}) {
  let location = useLocation();

  //State and function to change office
  const [employee, setEmployee] = useState('manager');

  //Schema validation for AdminAdd
  const EmployeeAddSchema = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Email', variable: 'email' },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      let activate_circuits = false;

      if (employee === 'operator-activate') {
        activate_circuits = true;
      }

      addEmployee(
        employee,
        values.email,
        values.name,
        location.state.uuid_client,
        location.state.uuid_unity,
        activate_circuits
      );
    }, 500);
  };

  return (
    <>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: '',
            email: '',
          }}
          validationSchema={EmployeeAddSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>
                  <IoseBackButton onClick={(e) => clickBackShowEmployee()} />
                  Criar
                </div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              <p>Enviaremos uma senha temporária para o primeiro acesso.</p>

              {/**Inputs*/}
              {labels.map((value) => {
                return (
                  <Field
                    component={IoseTextFieldSidebarStyled}
                    key={value.variable}
                    id={value.variable}
                    name={value.variable}
                    type={value.variable === 'email' ? 'email' : 'text'}
                    label={value.label}
                    defaultvalue={initialValues[value.variable]}
                    onchange={handleChange}
                    onblur={handleBlur}
                    helpertext={
                      errors[value.variable] && touched[value.variable]
                        ? errors[value.variable]
                        : ''
                    }
                  />
                );
              })}

              <RadioGroupStyled
                row={true}
                value={employee}
                onChange={(e) => setEmployee(e.target.value)}
              >
                {(group === 'super' || group === 'admin') && (
                  <Field
                    component={FormControlLabelStyled}
                    type="radio"
                    value="manager"
                    control={<YellowRadio />}
                    label="Gerente"
                  />
                )}
                <Field
                  component={FormControlLabelStyled}
                  type="radio"
                  value="operator"
                  control={<YellowRadio />}
                  label="Operador"
                />

                <Field
                  component={FormControlLabelStyled}
                  type="radio"
                  value="operator-activate"
                  control={<YellowRadio />}
                  label="Operador com acionamento"
                />
              </RadioGroupStyled>
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseEmployeeCreateCard.propTypes = {
  /** This props get a group user */
  group: PropType.object,
  /** This props get a function to show list of employees of SidebarEmployeeContainer */
  clickBackShowEmployee: PropType.func,
  /** This props get a function to add employees of SidebarEmployeeContainer */
  addEmployee: PropType.func,
};
