import styled from 'styled-components';
import { Typography, Grid, Card } from '@material-ui/core';
import IoseContainer from 'components/IoseContainer';

export const WrappedContainer = styled.div`
  overflow-x: hidden;
`;

export const TitleDashboardComponent = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const GridStyled = styled(Grid)`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const GridStyledList = styled(Grid)`
  flex: 1;
  padding: 5px 10px 5px 0px;

  @media (max-width: 500px) {
    padding: 5px 0px 5px 0px;
  }
`;

export const GridContainer = styled(Grid)`
  padding: 0px 10px 5px 25px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin-left: 10px;
  }
`;

export const StyledContainer = styled(Card)`
  padding: 3%;
  width: 100%;
  height: 100%;
`;

export const IoseContainerStyled = styled(IoseContainer)`
  margin-left: 22px;
  @media (max-width: 500px) {
    padding-right: 20px;
  }
`;
export const Container = styled(Card)`
  padding: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;

  p {
    /* text-align: center;
    margin-bottom: 20px; */

    b {
      color: var(--alert);
    }
  }
`;
