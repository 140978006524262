import React, { useMemo, useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination } from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled } from './style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, className, onClick, sortedBy, direction }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tableHeaderCell} ${className}`} onClick={onClick}>
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({ value }) => {
  const classes = useStyles();
  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value.concessionaire}</TableCellCentered>
      <TableCellCentered>{value.classification_code}</TableCellCentered>
      <TableCellCentered>{value.reference_month}</TableCellCentered>
      <TableCellCentered>{value.quantity}</TableCellCentered>
      <TableCellCentered>{value.identification_number}</TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
    data,
    onRowClick,
    page,
    rowsPerPage,
    handleSort,
    sortBy,
    sortDirection
  }) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            onClick={() => handleSort('concessionaire')}
            sortedBy={sortBy === 'concessionaire'}
            direction={sortDirection}
          >
            CONCESSIONÁRIA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('classification_code')}
            sortedBy={sortBy === 'classification_code'}
            direction={sortDirection}
          >
            CLASSIFICAÇÃO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('reference_month')}
            sortedBy={sortBy === 'reference_month'}
            direction={sortDirection}
          >
            REF: MÊS/ANO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('quantity')}
            sortedBy={sortBy === 'quantity'}
            direction={sortDirection}
          >
            QUANTIDADE DE FATURAS
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('identification_number')}
            sortedBy={sortBy === 'identification_number'}
            direction={sortDirection}
          >
            QUANTIDADE DE UCS
          </TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function IoseEnergyBillLogsTable({ loading, data = [] }) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortBy, sortDirection);
  }, [data, sortBy, sortDirection]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderChartOrErrorMessage() {
    if (sortedData && sortedData.length > 0) {
      return (
        <CircuitsTable
          data={sortedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={data ? parseInt(data.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={530}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>
        </>
      )}
    </div>
  );
}

function sortData(data, sortBy, sortDirection) {
  if (!sortBy) return data;

  const sortedData = [...data].sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedData;
}
