import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSearchMessage,
  IoseNoDataMessage,
} from 'components';

import {
  useClient,
  LocalStorage,
  Permissions,
} from 'common';
import { useGroupPermissions } from 'hooks';

import ItensBillContainerFilters from './components/bill-container-filtros';

export default function SearchItemBillContainer() {
  const { client } = useClient();
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  //eslint-disable-next-line
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [emptyResponse, setEmptyResponse] = useState(false);

  //eslint-disable-next-line
  const [energyBills, setEnergyBills] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);
  //eslint-disable-next-line
  const [hasReportPermission, setHasReportPermission] = useState(true);

  useEffect(() => {
    const readPath = ['relatorios', 'itens_fatura', 'leitura'];
    const reportPath = ['relatorios', 'itens_fatura', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );

      setHasReadPermission((prev) => {
        if (prev !== readPermission) {
          console.log(
            `Updating read permission from ${prev} to ${readPermission}`
          );
          return readPermission;
        }
        return prev;
      });

      setHasReportPermission((prev) => {
        if (prev !== reportPermission) {
          console.log(
            `Updating report permission from ${prev} to ${reportPermission}`
          );
          return reportPermission;
        }
        return prev;
      });
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };


  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title="Relatório de itens da fatura"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>
           {emptyResponse === true ? (
              <IoseNoDataMessage
                message='Não há dados para o filtro selecionado. Por favor, tente novamente.'
                height='350px'
                width='350px'
              />
           ) : (
            <IoseSearchMessage
            message={`Selecione os filtros de busca desejados e clique em "EMITIR RELATÓRIO".`}
            height="400px"
            width="400px"
          />
           )}
          </div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      {hasReadPermission ? (
        <S.WrappedContainer>
          {renderSubHeaderAndSearchBar()}
          <ItensBillContainerFilters  loading={setLoading} emptyResponse={setEmptyResponse}/>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : hasReadPermission ? (
            renderContent()
          ) : (
            <IoseAlertLoadingCards
              text={
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              }
            />
          )}
        </S.WrappedContainer>
      ) : (
        <div style={{ marginTop: '100px' }}>
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        </div>
      )}
    </Grow>
  );
}
