// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, AccountContext, Messages } from 'common';
import { useQuery } from '@tanstack/react-query';

function useContracts() {
  const [noContractsError, setNoContractsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getContracts = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllContracts(headers, 10000, 300);

    const { data } = response.data;
    const contracts = data || [];

    if (contracts.length === 0) {
      setNoContractsError(Messages.noRegisteredContracts);
      throw new Error(Messages.noRegisteredContracts);
    }
    return contracts;
  };

  const {
    data: contracts,
    error: contractsError,
    refetch: refreshContracts,
    isLoading,
  } = useQuery(['contractsData'], getContracts);

  return {
    contracts,
    contractsError,
    noContractsError,
    refreshContracts,
    isLoading,
  };
}

export default useContracts;
