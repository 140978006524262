import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

const handleAlarmStatus = (type) => {
  switch (type) {
    case 1:
      return 'Queda de energia';
    case 2:
      return 'Retorno de energia';
    case 4:
      return 'Sem medição';
    case 5:
      return 'Horário fora de ponta';
    case 6:
      return 'Horário de ponta';
    case 28:
      return 'Abertura da porta';
    case 29:
      return 'Fechamento da porta';
    case 7:
      return 'Horário Capacitivo';
    case 56:
      return 'Reset pelo Usuário';
    case 3:
      return 'Fim do intervalo de 15 min.';
    case 102:
      return 'Fim do intervalo timeout';
    case 200:
      return 'Ultrapassagem de demanda';
    case 201:
      return 'Excesso de Reativos';
    default:
      return 'Evento não suportado';
  }
};

export default function GraficoAlarmesNoPeriodo({ data }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && data?.length > 0) {
      const eventCounts = data?.reduce((acc, item) => {
        const eventName = handleAlarmStatus(item.cod_evento);
        acc[eventName] = (acc[eventName] || 0) + 1;
        return acc;
      }, {});

      const formattedData = Object.entries(eventCounts).map(([key, value]) => ({
        value,
        name: key,
      }));

      setChartData(formattedData);
    } else {
      setChartData([]);
    }
  }, [data]);

  const option = {
    title: {
      text: 'Quantidade de Alarmes por Tipo',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/><strong>{b}</strong>: {c} ({d}%)',
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: 'Alarmes',
        type: 'pie',
        radius: '60%',
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: 350 }}
      key={JSON.stringify(option)}
    />
  );
}
