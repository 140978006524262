// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useLogs() {
  const [noLogsError, setNoLogsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getLogs = async () => {
    const { headers } = getSession();

    let allData = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getAllUserLogs(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allData = allData.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (allData.length === 0) {
      setNoLogsError(Messages.noRegisteredLogs);
      throw new Error(Messages.noRegisteredLogs);
    } else {
      const logs = allData;

      return logs
    }
  };

  const {
    data: logs,
    error: logsError,
    refetch: refreshLogs,
    isLoading,
  } = useQuery(['logsData'], getLogs);

  return {
    logs,
    logsError,
    noLogsError,
    refreshLogs,
    isLoading,
  };
}

export default useLogs;
