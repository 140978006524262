import React, { useState } from 'react';
import { TableBody, TableCell, Paper, makeStyles, TablePagination } from '@material-ui/core';

import * as S from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, rowClick }) => {
  const { uuid_item, item_type, item_constant, item_description } = value;

  const classes = useStyles();

  //eslint-disable-next-line
  const handleEditClick = () => {
    const objeto = { uuid_item, item_type, item_constant, item_description };

    rowClick(objeto);
  };

  return (
    <S.TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{item_type}</TableCellCentered>
      <TableCellCentered>{item_constant}</TableCellCentered>
      {/* <TableCellCentered>{item_description}</TableCellCentered> */}
      {/* <TableCellCentered>
        {<S.EditItemIcon onClick={() => handleEditClick()} />}
      </TableCellCentered> */}
    </S.TableRowStyled>
  );
};

const CircuitsTable = ({ data, rowClick,  page, rowsPerPage }) => {

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (

    <S.StyledTable>
      <S.TableHeadStyled>
        <S.TableRowStyled align="center">
          <TableHeaderCellCentered>TIPO</TableHeaderCellCentered>
          <TableHeaderCellCentered>VARIÁVEL</TableHeaderCellCentered>
          {/* <TableHeaderCellCentered>DESCRIÇÃO</TableHeaderCellCentered> */}
          {/* <TableHeaderCellCentered></TableHeaderCellCentered> */}
        </S.TableRowStyled>
      </S.TableHeadStyled>

      <TableBody>
        {pageData.map((value, index) => (
          <CircuitsTableRow key={index} value={value} rowClick={rowClick} />
        ))}
      </TableBody>
    </S.StyledTable>
  )
}

export default function IoseSystemVariablesTable({
  loading,
  data,
  setSelectedRow,
  handleOpenEditModal,
}) {
  const handleRowClick = (row) => {
    setSelectedRow(row);
    handleOpenEditModal();
  };

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderChartOrErrorMessage() {
    if (data && data?.length > 0) {
      return (
        <div style={{ width: '95vw' }}>
          <CircuitsTable
            data={data}
            rowClick={handleRowClick}
            id="scrollableDiv"
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div>
      );
    } else {
      return (
        <div style={{ width: '95vw' }}>
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="300px"
            width="300px"
          />
        </div>
      );
    }
  }
  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
       <Paper>
           <IoseInfinityScroll
            dataLength={data ? parseInt(data?.length) : 100}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
           <TablePagination
           component="div"
           count={data?.length || 0}
           rowsPerPage={rowsPerPage}
           page={page}
           onChangePage={handleChangePage}
           onChangeRowsPerPage={handleChangeRowsPerPage}
           labelRowsPerPage="Itens por página:"
           labelDisplayedRows={({ from, to, count }) =>
             `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
           }
           backIconButtonText="Página anterior"
           nextIconButtonText="Próxima página"
         />
       </Paper>
      )}
    </div>
  );
}
