export const defaultMenuPermissions = {
  "cadastro": {
   "access": true,
   "ambientes": true,
   "circuitos": true,
   "comercializadoras": true,
   "concessionarias": true,
   "contratos": true,
   "ecus": true,
   "fator_co2": true,
   "gemeo_digital": true,
   "tarifas": true,
   "unidades": true,
   "unidades_consumidoras": true,
   "objetos": true
  },
  "dashboards": {
   "3d": true,
   "access": true,
   "administrativo": true,
   "comparacao_consumo": true,
   "emissao_carbono": true,
   "faturas_anual": true,
   "faturas_energia": true,
   "mercado_livre": true
  },
  "faturas": {
   "access": true,
   "busca_faturas": true,
   "log_faturas": true,
   "simulador_contratos": true
  },
  "geracao": {
   "access": true,
   "beneficiarias": true,
   "dashboard_creditos": true,
   "geradoras": true,
   "geradoras_beneficiarias": true
  },
  "gerenciamento": {
   "access": true,
   "gerenciamento_contratos": true,
   "log_usuarios": true
  },
  "mapas": {
   "access": true,
   "mapa_geo": true
  },
  "parametros": {
   "access": true,
   "variaveis_sistema": true
  },
  "relatorios": {
   "access": true,
   "biene": true,
   "contabil": true,
   "creditos": true,
   "emissao_carbono": true,
   "faturas": true,
   "itens_fatura": true,
   "medicao": true,
   "sgf": true,
   "telemetria": true
  },
  "telemetria": {
   "access": true,
   "comparacao_consumo": true,
   "dashboard_telemetria": true,
   "mapa_telemetria": true,
   "usuarios_alarmes": true
  }
};
