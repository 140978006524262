import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreditosBeneficiariaTable from './CreditosBeneficiariaTable';
import { useCreateLogs } from 'hooks';
import { IoseButton2 } from 'components';
import DashBeneficiariasReport from 'reports/EnergyBills/DashBeneficiariasReport';
import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import * as S from './style';
import * as XLSX from 'xlsx';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  modal: {
    border: 'none',
    padding: theme.spacing(14, 9, 3),
  },
  whiteText: {
    color: 'white',
  },
  tableContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'auto',
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecf0f5',
    border: 'none',
  },
}));

export default function IoseBeneficariasCreditosChart({
  open,
  onClose,
  selectedCard,
  filteredData,
  hasReportPermission,
}) {
  const classes = useStyles();
  const { createLog } = useCreateLogs();

  const cardSelecionado = selectedCard?.title;
  const isReportButtonDisabled = filteredData?.length === 0;

  const renderTabelaBeneficaria = () => (
    <div className={classes.tableContainer} style={{ position: 'relative' }}>
      <CreditosBeneficiariaTable
        data={filteredData}
        cardSelecionado={cardSelecionado}
      />
    </div>
  );

  const handleDesperdicioReport = async () => {
    DashBeneficiariasReport(filteredData);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CRÉDITOS ACUMULADOS ';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  function exportTelemetryMeasuresToExcel(data) {
    // Supondo que `uc_beneficiarias` seja o formato de dados
    const allBeneficiaries = data;

    // Se precisar reorganizar as colunas
    const reorderedColumns = [
      'uc_beneficiaria',
      'mes_cred_utilizado',
      'cred_recebido',
      'city',
      'general_management',
      'regional_management',
    ];

    // Reordena os dados
    const reorderedData = allBeneficiaries.map((obj) => {
      const reorderedObj = {};
      reorderedColumns.forEach((column) => {
        reorderedObj[column] = obj[column];
      });
      return reorderedObj;
    });

    // Cria a planilha
    const worksheet = XLSX.utils.json_to_sheet(reorderedData);

    // Define o nome das colunas
    const headers = {
      uc_beneficiaria: 'UC Beneficiária',
      mes_cred_utilizado: 'Mês do Crédito Recebido',
      cred_recebido: 'Créditos Recebidos',
      city: 'Cidade',
      general_management: 'GG',
      regional_management: 'GR',
      // Adicione outros headers conforme necessário
    };

    // Adiciona headers na primeira linha
    const columns = Object.keys(headers);
    columns.forEach((column, index) => {
      const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
      headerCell.v = headers[column];
    });

    // Cria o workbook e adiciona a planilha
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Beneficiarias');
    XLSX.writeFile(workbook, 'ucBeneficiarias.xlsx');
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <BatteryChargingFullIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {`${cardSelecionado}`}
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
            {!hasReportPermission ? (
              <>
                <IoseButton2
                  onClick={handleDesperdicioReport}
                  width="150px"
                  disabled={isReportButtonDisabled}
                >
                  <span style={{ marginRight: '5px' }}>PDF</span>
                  <GetAppIcon />
                </IoseButton2>
                <IoseButton2
                  style={{
                    margin: '10px',
                  }}
                  onClick={() => exportTelemetryMeasuresToExcel(filteredData)}
                  width="150px"
                  disabled={isReportButtonDisabled}
                >
                  <span style={{ marginRight: '5px' }}>XLSX</span>
                  <GetAppIcon />
                </IoseButton2>
              </>
            ) : null}
          </S.StyledHeaderModal>
          <S.StyledWrappedModal>
            <S.ModalTitle variant="h5" gutterBottom />
            {cardSelecionado === 'UNIDADES BENEFICIARIAS' &&
              renderTabelaBeneficaria()}
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
