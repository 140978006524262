import styled from 'styled-components';
import {
  Typography,
  Grid,
  Card,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import IoseContainer from 'components/IoseContainer';
import UnityImage from './img/Unity.svg';
import SwitchboardImage from './img/Switchboard.svg';
import BusinessIcon from '@material-ui/icons/Business';

import { Table, TableCell, Tab, Tabs } from '@material-ui/core';

export const StyledTable = styled(Table)({
  width: '100%',
});

export const StyledTabs = styled(Tabs)({
  width: '100%',
});

export const StyledTab = styled(Tab)({
  width: '100%',
});

export const StyledTh = styled(TableCell)({
  backgroundColor: 'var(--primary)',
  padding: '10px',
  textAlign: 'center',
  color: 'var(--secondary)',
});

export const StyledTd = styled(TableCell)({
  padding: '10px',
  border: '1px solid #ddd',
  textAlign: 'center',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: '#ddd',
    cursor: 'pointer',
  },
});

export const TitleDashboardComponent = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;
`;

export const GridStyledList = styled(Grid)`
  flex: 1;

  @media (max-width: 500px) {
    padding: 5px 0px 5px 0px;
  }
`;

export const GridContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const HeaderGridStyled = styled(Grid)`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;
  height: 300px;
  /* border: 1px solid blue; */

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FilterContainer = styled(Grid)`
  margin: 0px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const PageTitle = styled.div`
  width: 50%;
`;

export const FilterWrapped = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 25px 0 0;
`;

export const FilterInputsWrapped = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin-left: 10px;
  }
`;

export const StyledContainer = styled(Card)`
  padding: 3%;
  width: 100%;
  height: 100%;
  border: 2px solid red;
`;

export const IoseContainerStyled = styled(IoseContainer)`
  @media (max-width: 500px) {
    padding-right: 20px;
  }
`;

export const UnityIcon = styled.img.attrs((props) => ({
  src: UnityImage,
}))`
  width: 30px;
  height: 30px;
`;

export const SwitchboardIcon = styled.img.attrs((props) => ({
  src: SwitchboardImage,
}))`
  width: 20px;
  height: 20px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  width: 70%;
  padding: 0 20px 0 0;
`;

export const RightContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 120px;

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-root > .Mui-disabled {
    opacity: 50%;
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--secondary)' : 'var(--base)'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  @media (max-width: 1400px) {
    width: 70px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`;

export const FormControlStyled = styled(FormControl)`
  width: 120px;

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  &:hover label {
    opacity: 100%;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 1400px) {
    width: 70px;
  }
`;

export const SaveButton = styled.button`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  width: 89px;
  transition: background-color 0.2s;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--primary);
`;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: black;
`;
