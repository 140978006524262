import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import IoseApiLib from 'iose-api-lib';
import { RoutesSwitchboard } from 'routers';

//Imports from common
import { useSession } from 'common/contexts/session';
import LocalStorage from 'common/local_storage_api';
import { ioseAPI, WebSocketEndPoint } from 'common/ioseAPI';
import Messages from 'common/Messages';

//Import from IOSE Components
import {
  ButtonArea,
  SubSideHeader,
  Content,
  IconSubheader,
} from 'components/IoseSideContainer';
import { IoseAddButton } from 'components/IoseButtonIcon';
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';
import IoseSearch from 'components/IoseSearch';

import BreakPanelIcon from './img/breakpanel_icone.svg';
import ioseApiLib from 'iose-api-lib';
import { AccountContext } from 'common';

export default function SidebarSwitchboardContainer({
  group,
  setGetcards,
  closeSide,
}) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { verifySession } = useSession();
  const session = LocalStorage.getSession();
  const clientName = location.state.clientName;
  const unityName = location.state.unityName;
  const uuid_unity = location.state.uuid_unity;
  const tariff_period = location.state.tariff_period;
  const tariff_data = location.state.tariff_data;
  const contracted_demand = location.state.contracted_demand;
  const { getSession } = useContext(AccountContext);

  //State to change notification type and your data
  const [notification, setNotification] = useState({ error: false, text: '' });

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  //State control when need reloading list switchboard when press backbutton in forms
  const [reloading, setreloading] = useState(false);

  //State to get all switchboards of Unit
  const [allGroups, setAllGroups] = useState('');

  //State to get all circuits of Unit
  const [allCircuits, setAllCircuits] = useState([]);

  //State to show switchboardName when click and any circuit
  const [nameSwitchboard, setNameSwitchboard] = useState('Quadros');

  //State to filter Switchboards
  const [switchboardsFiltered, setSwitchboardsFiltered] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchError, setError] = useState(false);

  //State to trigger Circuit
  const [triggerCircuit, setTriggerCircuit] = useState({});

  const [websocket, setwebsocket] = useState(null);

  useEffect(() => {
    const socket = openWebsocket();
    sideSubscribeWebSocket(socket);
    getAllData(socket);

    return () => {
      closeWebsocket(socket);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Functions related GET DATA
  async function getAllData(socket) {
    setNotification(false);
    setLoading(true);

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        if (isInContainer()) {
          const uuidUnity = location.state.uuid_unity;

          let allGroups = await getAllGroups(uuidUnity);
          let allCircuits = await getAllCircuits(uuidUnity);

          setAllGroups(allGroups.data);
          setAllCircuits(allCircuits.data);
          listenWebsocket(socket, allCircuits.data);
          setLoading(false);
        }
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.switchboardGetErro;

      handleSetNotification(true, message);
    }
  }

  function isInContainer() {
    const path = location.pathname;
    const inContainer =
      path.includes('client/switchboard') ||
      path.includes('dashboard/switchboard');

    return inContainer ? true : false;
  }

  async function getAllGroups(uuidUnity) {
    try {
      const response = await IoseApiLib.Group.getAllGroup(uuidUnity);
      return response;
    } catch (err) {
      throw err;
    }
  }

  async function getAllCircuits(uuidUnity) {
    try {
      const response = ioseApiLib.Circuit.getAllCircuit(uuidUnity);
      return response;
    } catch (err) {
      throw err;
    }
  }

  function handleSetNotification(error, message) {
    setNotification({
      error: error,
      text: message,
    });
    setLoading(false);
  }

  //Functions related DISASSOCIATE CIRCUITS
  async function desassociateCircuits(circuitsUnsubscribed) {
    setLoading(true);

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        let responseDesassociate = await Promise.all(
          circuitsUnsubscribed.map(async (circuit) => {
            let response = await removePropertyCircuit(circuit.uuid_circuit);
            return response;
          })
        );

        if (responseDesassociate.every((value) => value)) {
          setLoading(false);
          updateListCircuit();
        } else {
          handleSetNotification(true, Messages.circuitsDesassociateErro);
        }
      }
    } catch (error) {
      handleSetNotification(true, Messages.circuitsDesassociateErro);
    }
  }

  async function removePropertyCircuit(uuid_circuit) {
    try {
      await IoseApiLib.Circuit.removePropertyCircuit(uuid_circuit);
      return true;
    } catch {
      return false;
    }
  }

  async function updateListCircuit() {
    await setGetcards(true);
    await getAllData();
    handleSetNotification(false, Messages.circuitsDesassociateSuccess);
  }

  //Functions related CRUD SWITCHBOARD
  async function addSwitchboard(SwitchboadData) {
    setNotification(false);
    setLoading(true);
    const sessionData = await getSession();
    const goalNumber = parseInt(SwitchboadData.goal);

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        const response = await ioseAPI.criarQuadro(
          sessionData.headers,
          uuid_unity,
          SwitchboadData.name,
          SwitchboadData.description,
          goalNumber,
          SwitchboadData.identification_number
        );

        setreloading(true);
        handleSetNotification(false, response.message);
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.noSwichboardRegistered;

      handleSetNotification(true, message);
    }
  }

  async function editSwitchboard(SwitchboadData) {
    setNotification(false);
    setLoading(true);

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        const response = await IoseApiLib.Group.updateGroup(
          SwitchboadData.uuid,
          SwitchboadData.name,
          SwitchboadData.description,
          SwitchboadData.goal,
          SwitchboadData.identification_number
        );

        setGetcards(true);
        handleSetNotification(false, response.message);
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.switchboardNoEdited;

      handleSetNotification(true, message);
    }
  }

  //Functions related WEBSOCKET
  function getUuids(group, userId) {
    switch (group) {
      case 'admin':
        return { uuidEmployee: '', uuidAdmin: userId };

      case 'manager' || 'employee':
        return { uuidEmployee: userId, uuidAdmin: '' };

      default:
        return { uuidEmployee: '', uuidAdmin: '' };
    }
  }

  async function triggerCircuitAction(
    uuid_circuit,
    state_actuation,
    justification
  ) {
    const group = session.group;
    const userId = session.userId;

    const { uuidEmployee, uuidAdmin } = getUuids(group, userId);

    try {
      const sessionVerificated = await verifySession(session);

      if (sessionVerificated) {
        const response = await IoseApiLib.Circuit.triggerCircuit(
          uuid_circuit,
          state_actuation,
          uuidEmployee,
          uuidAdmin,
          justification
        );
        return { result: true, message: response.data.message };
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.name
        : Messages.errorTrigger;

      return { result: false, message: message };
    }
  }

  function sideSubscribeWebSocket(socket) {
    if (socket.readyState !== 1) {
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            'action': 'subscribeUnity',
            'data': { 'uuid_unity': uuid_unity },
          })
        );
      };
    }
  }

  function updateStateCircuit(websocketMessage, allCircuits) {
    if (websocketMessage.type === 'activation') {
      const circuitselected = allCircuits.filter(
        (circuit) => circuit.uuid_circuit === websocketMessage.uuid_circuit
      );

      circuitselected[0].relay_state = websocketMessage.state_circuit;
    }
  }

  function closeWebsocket(socket) {
    if (socket) {
      socket.close();
    }
  }

  function openWebsocket() {
    const socket = new WebSocket(WebSocketEndPoint);
    setwebsocket(socket);

    return socket;
  }

  function listenWebsocket(socket, allCircuits) {
    let socketLocal = socket ? socket : websocket;

    socketLocal.onmessage = (e) => {
      let websocketMessage = JSON.parse(e.data);

      setTriggerCircuit(websocketMessage);
      updateStateCircuit(websocketMessage, allCircuits);
    };
  }

  //Function to REDIRECT
  function redirectCircuit(circuitData, uuid_group) {
    history.push({
      pathname: `${path}/circuit`,
      state: {
        uuid_unity: uuid_unity,
        uuid_client: location.state.uuid_client,
        uuid_group: uuid_group,
        clientName: clientName,
        unityName: unityName,
        circuitData: circuitData,
        switchboardData: location.state.switchboardData,
        backbutton: true,
        allGroups: allGroups,
        allCircuits: allCircuits,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
  }

  async function redirectDashboard(switchboardData) {
    setTimeout(async () => {
      await closeSide();
    }, 500);

    history.push({
      pathname: '/global/dashboard/switchboard',
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: uuid_unity,
        switchboardData: switchboardData,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
  }

  function redirectCreateSwitchboard() {
    history.push({
      pathname: `${path}/add`,
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_client: location.state.uuid_client,
        uuid_unity: uuid_unity,
        switchboardData: location.state.switchboardData,
        tariff_period: tariff_period,
        tariff_data: tariff_data,
        contracted_demand: contracted_demand,
      },
    });
  }

  function clickBackShowSwitchboards() {
    setNameSwitchboard('Quadros');
    history.push({
      pathname: '/global/client/switchboard',
      state: {
        clientName: clientName,
        unityName: unityName,
        uuid_unity: location.state.uuid_unity,
        uuid_client: location.state.uuid_client,
        tariff_period: location.state.tariff_period,
        tariff_data: location.state.tariff_data,
        contracted_demand: location.state.contracted_demand,
      },
    });
    reloading && getAllData();
  }

  //Functons to RENDER
  const renderNotification = () => {
    if (notification.error) {
      return <IoseAlertErro text={notification.text} />;
    } else {
      return <IoseAlertSuccess text={notification.text} />;
    }
  };

  const renderSideHeader = () => {
    const path = location.pathname;
    const iconTitle = <IconSubheader src={BreakPanelIcon} />;

    const showSearch = isInContainer();

    const showAddButton =
      (group === 'super' || group === 'admin') &&
      path.includes('dashboard') === false;

    return (
      <SubSideHeader>
        <h3>
          {iconTitle}
          {nameSwitchboard}
        </h3>

        {showSearch && (
          <IoseSearch
            sidebar={'true'}
            placeholder="Pesquisar Quadro..."
            positionbutton="absolute"
            funcSearch={filterSwichtboard}
          />
        )}

        {showAddButton && (
          <ButtonArea>
            <IoseAddButton
              tooltip="Criar Quadro"
              onClick={() => redirectCreateSwitchboard()}
            />
          </ButtonArea>
        )}
      </SubSideHeader>
    );
  };

  function filterSwichtboard(swichtboardNameSearch) {
    const swichtboardNameSearchUppcase = swichtboardNameSearch.toUpperCase();

    //Clean old info search
    setSwitchboardsFiltered([]);
    setError(false);

    if (swichtboardNameSearch === '') {
      setSearch(false);
    } else {
      setSearch(true);

      const swichtboardFiltered = allGroups.filter((switchboard) =>
        switchboard.name.toUpperCase().includes(swichtboardNameSearchUppcase)
      );

      if (swichtboardFiltered.length !== 0) {
        setSwitchboardsFiltered(swichtboardFiltered);
      } else {
        setSearch(true);
        setError(true);
      }
    }
  }

  return (
    <>
      {renderSideHeader()}
      <Content>
        {notification && renderNotification()}
        {loading && <LoadingIndicatorSidebar loading={loading} />}

        <RoutesSwitchboard
          group={group}
          allGroups={search ? switchboardsFiltered : allGroups}
          allCircuits={allCircuits}
          loading={loading}
          searchError={searchError}
          triggerCircuit={triggerCircuit}
          getAllCircuits={getAllCircuits}
          setNameSwitchboard={setNameSwitchboard}
          setGetcards={setGetcards}
          showCircuit={redirectCircuit}
          showDashboard={redirectDashboard}
          clickBack={clickBackShowSwitchboards}
          desassociateCircuits={desassociateCircuits}
          addSwitchboard={addSwitchboard}
          editSwitchboard={editSwitchboard}
          triggerCircuitAction={triggerCircuitAction}
          closeSide={closeSide}
          getAllDataSwitchboardContainer={getAllData}
        />
      </Content>
    </>
  );
}
