import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import IoseButtonSideBar from 'components/IoseButton';
import { IoseBackButton } from 'components/IoseButtonIcon';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
  ContainerInputsTC,
  RadioGroupStyled,
  FormControlLabelStyled,
  YellowRadio,
  DivInputsCircuits,
  IoseTextFieldSidebarStyled2,
} from './style';

/**
 * This component is a form to edit a Circuit in the system
 */
export default function IoseFormCircuitEditCard({
  editCircuit,
  clickBack,
  name,
  description,
}) {
  const location = useLocation();

  const circuitData = location.state.circuitData;

  const [has_tc, setHas_tc] = useState('false');

  useEffect(() => {
    verifyHasTC();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Schema validation for edit Circuit
  const CircuitEditSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.description,
    primary_tc: validationsForm.number,
    secondary_tc: validationsForm.number,
    identification_number: validationsForm.identification_number,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
  ];

  const labelCheckBoxTC = [
    { label: 'Sim', variable: 'true' },
    { label: 'Não', variable: 'false' },
  ];

  const labelsTC = [
    { label: 'Primário', variable: 'primary_tc' },
    { label: 'Secundário', variable: 'secondary_tc' },
  ];

  const verifyHasTC = () => {
    const has_tc_primary = 'primary_tc' in circuitData;
    const has_tc_secondary = 'secondary_tc' in circuitData;

    if (has_tc_primary && has_tc_secondary) {
      let factor = circuitData.primary_tc / circuitData.secondary_tc;

      if (factor !== 1) setHas_tc('true');
    }
  };

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);

      editCircuit(
        values.name,
        values.description,
        values.primary_tc,
        values.secondary_tc,
        values.identification_number
      );
    }, 500);
  };

  const renderInputsTC = (
    initialValues,
    handleChange,
    handleBlur,
    errors,
    touched
  ) => {
    return (
      <>
        <p>Qual a relação?</p>
        <ContainerInputsTC>
          {labelsTC.map((value) => (
            <div key={value.variable}>
              <Field
                component={IoseTextFieldSidebarStyled}
                id={value.variable}
                name={value.variable}
                type={'text'}
                label={value.label}
                defaultvalue={initialValues[value.variable]}
                onchange={handleChange}
                onblur={handleBlur}
                helpertext={
                  errors[value.variable] && touched[value.variable]
                    ? errors[value.variable]
                    : ''
                }
              />
            </div>
          ))}
        </ContainerInputsTC>
      </>
    );
  };

  const renderCheckBoxTC = () => {
    return (
      <>
        <p>Possui Transformador de Corrente ?</p>
        <RadioGroupStyled
          row={true}
          value={has_tc}
          onChange={(e) =>
            setHas_tc(e.target.value === 'true' ? 'true' : 'false')
          }
        >
          {labelCheckBoxTC.map((field) => (
            <Field
              key={field.variable}
              component={FormControlLabelStyled}
              type="radio"
              value={field.variable}
              control={<YellowRadio />}
              label={field.label}
            />
          ))}
        </RadioGroupStyled>
      </>
    );
  };

  return (
    <Container>
      <Formik
        initialValues={{
          name: name,
          description: description,
          primary_tc: circuitData.primary_tc ? circuitData.primary_tc : 1,
          secondary_tc: circuitData.secondary_tc ? circuitData.secondary_tc : 1,
          identification_number: circuitData.identification_number
            ? circuitData.identification_number
            : 0,
        }}
        validationSchema={CircuitEditSchema}
        onSubmit={onSubmitForm}
      >
        {({
          handleChange,
          handleBlur,
          submitForm,
          touched,
          isSubmitting,
          errors,
          initialValues,
        }) => (
          <FormStyled>
            {/**Header*/}
            <Header>
              <div>
                {location.state.backbutton && (
                  <IoseBackButton onClick={(e) => clickBack()} />
                )}
                Editar Circuito
              </div>
              <IoseButtonSideBar type="submit" disabled={isSubmitting}>
                Confirmar
              </IoseButtonSideBar>
            </Header>

            {/**Inputs*/}
            {labels.map((value) => {
              return (
                <Field
                  component={IoseTextFieldSidebarStyled}
                  key={value.variable}
                  id={value.variable}
                  name={value.variable}
                  type={'text'}
                  label={value.label}
                  defaultvalue={initialValues[value.variable]}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors[value.variable] && touched[value.variable]
                      ? errors[value.variable]
                      : ''
                  }
                />
              );
            })}

            {renderCheckBoxTC()}

            {has_tc === 'true' &&
              renderInputsTC(
                initialValues,
                handleChange,
                handleBlur,
                errors,
                touched
              )}

            <DivInputsCircuits>
              <Field
                component={IoseTextFieldSidebarStyled2}
                id={'identification_number'}
                name={'identification_number'}
                type={'text'}
                label={'Unidade Consumidora'}
                defaultvalue={initialValues['identification_number']}
                onchange={handleChange}
                onblur={handleBlur}
                helpertext={
                  errors['identification_number'] &&
                  touched['identification_number']
                    ? errors['identification_number']
                    : ''
                }
              />
            </DivInputsCircuits>
          </FormStyled>
        )}
      </Formik>
    </Container>
  );
}

IoseFormCircuitEditCard.propTypes = {
  /** This props is a function to edit circuit in container*/
  editCircuit: PropType.func,
  /** This props is function to return Switchboard list*/
  clickBack: PropType.func,
  /** This props is circuit name to edit*/
  name: PropType.string,
  /** This props is circuit name to edit*/
  description: PropType.string,
};
