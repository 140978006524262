import React, { useState } from "react";
import BillsModalBillSubHeader from "./bills-modal-bill-sub-header";
import BillsModalBillContentHeader from "./bills-modal-bill-content-header";
import * as S from '../style'
import BillsModalBillTable from "./bills-modal-bill-table";
import BillsModalBillTableMeasurements from "./bills-modal-bill-table-measurements";
import { IoseInfinityScroll } from "components/IoseInfinityScroll";
import { useConsumerGroup } from "hooks";
import BillsModalBillTableTaxes from "./bills-modal-bill-table-taxes";
import BillsModalNavNextButton from "./bills-modal-nav-next-button";
import BillsModalNavPreviousButton from "./bills-modal-nav-previous-button";
import { IoseAlertNoExtract } from "components/IoseAlert";

export default function BillsModalBillContent({ energyBills, linksACL }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { consumerGroups } = useConsumerGroup();

  if (energyBills) {
    // Extrair todas as páginas disponíveis a partir dos itens
  const pages = [...new Set(energyBills?.map(item => parseInt(item?.pagina?.split('/')[0], 10)))];
  pages.sort((a, b) => a - b);

  // Filtrar itens para a página atual
  const currentItems = energyBills?.filter(item => parseInt(item?.pagina?.split('/')[0], 10) === currentPage);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < pages[pages.length - 1]) {
      setCurrentPage(prevPage => prevPage + 1);
    } else if (direction === 'previous' && currentPage > pages[0]) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  let address = consumerGroups?.filter(
    (item) => item?.identification_number === currentItems[0]?.identification_number
  )?.[0]?.address;

  return (
    <div>
      <IoseInfinityScroll
        dataLength={20}
        next={() => {}}
        hasMore={false}
        loading={false}
        endList={false}
        sidebar={false}
        marginTop={0}
        padding={0}
        colortextend={null}
        sizetextend={null}
        scrollableTarget="scrollableDiv"
        height={750}
        scrollbarColor="#ECF0F5"
        >
        <BillsModalBillSubHeader currentEnergyBill={currentItems} linksACL={linksACL}/>

        <S.Container>
          <div style={{ width: '88.5vw' }}>
            <div>
              <BillsModalBillContentHeader ucAddress={address} currentEnergyBill={currentItems}/>
              <BillsModalBillTable currentEnergyBill={currentItems}/>
              <div style={{marginTop: '10px'}}>
                <BillsModalBillTableMeasurements currentEnergyBill={currentItems}/>
              </div>
              <div style={{marginTop: '10px'}}>
                <BillsModalBillTableTaxes currentEnergyBill={currentItems}/>
              </div>
            </div>
            {/* <S.NavContainer>
              <IoseNavButton
                icon={<NavigateBeforeIcon />}
                tooltip="ANTERIOR"
                onClick={() => handlePageChange('previous')}
                disabled={currentPage === pages[0]}
              />
              <div>
                <S.pageNumbers>
                  {`Página ${currentPage} de ${pages.length}`}
                </S.pageNumbers>
              </div>
              <IoseNavButton
                icon={<NavigateNextIcon />}
                tooltip="PRÓXIMO"
                onClick={() => handlePageChange('next')}
                disabled={currentPage === pages[pages.length - 1]}
              />
            </S.NavContainer> */}
          </div>
        </S.Container>

        <BillsModalNavPreviousButton
          onClick={() => handlePageChange('previous')}
          disabled={currentPage === pages[0]}
          currentPage={currentPage}
          pages={pages}
        />

        <BillsModalNavNextButton
          onClick={() => handlePageChange('next')}
          disabled={currentPage === pages[pages.length - 1]}
          currentPage={currentPage}
          pages={pages}
        />

      </IoseInfinityScroll>
    </div>
  )
  } else {
    return (
      <div style={{marginTop: '250px'}}>
        <IoseAlertNoExtract text="Esta UC ainda não possui fatura."/>;
      </div>
    );

  }
}