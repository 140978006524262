import React from "react";
import { FixedButtonNextContainer, NextIconWrapper, NextPageIcon, PaginationInfo } from "../style";
import { Tooltip } from "@material-ui/core";

export default function BillsModalNavNextButton({ onClick, disabled, currentPage, pages }) {
  return (
    <FixedButtonNextContainer>
      <div>
        <NextIconWrapper
          onClick={() => onClick()}
          disabled={disabled}
        >
          <Tooltip title="PRÓXIMA PÁGINA" placement="top" arrow>
            <NextPageIcon />
          </Tooltip>
        </NextIconWrapper>
      </div>

      <div>
        <PaginationInfo> {`${currentPage} / ${pages.length}`}</PaginationInfo>
      </div>
    </FixedButtonNextContainer>
  )
}