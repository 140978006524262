// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useNewTariffs() {
  const [noTariffsError, setNoTariffsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getNewTariffs = async () => {
    const { headers } = getSession();

    let tariffs = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getNewTariffs(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      tariffs = tariffs.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (tariffs.length === 0) {
      setNoTariffsError(Messages.noRegisteredConcessionaires);
      throw new Error(Messages.noRegisteredConcessionaires);
    }

    return tariffs;
  };

  const {
    data: tarifas,
    error: tariffsError,
    refetch: refreshConcessionaires,
    isLoading,
  } = useQuery(['newTariffs'], getNewTariffs);

  return {
    tarifas,
    tariffsError,
    noTariffsError,
    refreshConcessionaires,
    isLoading,
  };
}

export default useNewTariffs;
