import React from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const IosePermissionsModal = ({ open, onClose, submit, cancel, text }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        <Typography variant="h6" gutterBottom>
          {text}
        </Typography>
        <Divider />
        <div
          style={{
            display: 'flex',
            gap: '10px',
            marginTop: '10px',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            style={{
              backgroundColor: '#015A9E',
              color: '#fff',
            }}
            autoFocus
          >
            Sim
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancel}
            style={{ backgroundColor: '#D60606', color: 'white' }}
            autoFocus
          >
            Não
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};
export default IosePermissionsModal;
