import styled from 'styled-components';
import IlustratioinImg from './Img/404_page_not_found_.svg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
  color: ${(props) => props.sidebar && 'rgb(255,255,255,0.9)'};
  h1 {
    font-size: 50px;
    margin-top: 16px;
  }

  h2 {
    font-size: 25px;
  }
  p {
    font-size: ${(props) => (props.sidebar ? '14px' : '15px')};
    padding: 2% 10%;
    text-align: ${(props) => (props.sidebar ? 'left' : 'center')};
    font-weight: ${(props) => (props.sidebar ? 'normal' : 'bolder')};
    line-height: 24px;
    color: ${(props) => props.sidebar && 'rgb(255,255,255,0.7)'};

    @media (max-width: 767px) {
      padding: 8%;
      width: 100%;
      line-height: 24px;
      text-align: left;
    }
  }
`;

export const Ilustration = styled.img.attrs((props) => ({
  src: IlustratioinImg,
}))`
  height: 220px;
`;
