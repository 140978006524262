import React, { useState } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IoseSwitch from 'components/IoseSwitch';
import IoseLinearProgress from 'components/IoseLinearProgress';
import { IoseEditButton } from 'components/IoseButtonIcon';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  TableCell,
} from '@material-ui/core';
import {
  TableStyled,
  TableContainerStyled,
  TablePaginationStyled,
  TableCellName,
  TableCellRelay,
  TableCellActions,
  Container,
  TableCellHeader,
} from './style';

export default function IoseCircuitTable({
  showCircuit,
  rows = [],
  group,
  dashboard,
  redirectEditCircuit,
  deleteCircuit,
  triggerCircuit,
  triggerCircuitAction,
}) {
  const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
      textColor: '#FFFFFF',
    },
  }));
  const classes = useStyles();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('energy_consumed');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Functions to order
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = Array.isArray(array)
      ? array.map((el, index) => [el, index])
      : [];

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  //Function to render header
  function EnhancedTableHead({
    classes,
    order,
    orderBy,
    onRequestSort,
    group,
    dashboard,
  }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    //Const to insert labels head
    const headCells = [
      {
        id: 'name',
        numeric: false,
        label: 'Nome',
        sort: true,
      },
      {
        id: 'energy_consumed',
        numeric: true,
        label: 'Consumido',
        sort: true,
      },
      { id: 'money_consumed', numeric: true, label: 'Valor', sort: true },
      {
        id: 'actions',
        numeric: true,
        label: 'On/Off',
        sort: false,
      },
      {
        id: 'percent',
        numeric: false,
        label: 'Porcentagem',
        sort: false,
      },
      {
        id: '',
        numeric: false,
        label: '',
        sort: false,
      },
    ];

    const renderTableCellHeader = (headCell) => {
      const cellSortHeader = (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );

      const cellHeader = (
        <TableCellHeader
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
          align="center"
        >
          {headCell.sort ? (
            cellSortHeader
          ) : (
            <p className="NoSortLabel">{headCell.label}</p>
          )}
        </TableCellHeader>
      );

      if (group !== 'operator') {
        return cellHeader;
      } else if (headCell.id !== 'actions') {
        return cellHeader;
      }
    };

    return (
      <TableHead>
        {dashboard === 'true' && (
          <TableRow style={{ color: '#015A9E' }}>
            {headCells.map((headCell) => renderTableCellHeader(headCell))}
          </TableRow>
        )}
      </TableHead>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Const to calc the number empty Rows
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function mountCircuitData(uuid_circuit, action) {
    const circuitSelected = rows.filter(
      (row) => row.uuid_circuit === uuid_circuit
    );

    return action === 'show'
      ? showCircuit(circuitSelected[0])
      : action === 'delete'
      ? deleteCircuit(circuitSelected[0].uuid_circuit)
      : redirectEditCircuit(circuitSelected[0]);
  }

  function renderSwitch(dashboard, row) {
    return (
      <TableCell align={dashboard === 'true' ? 'center' : 'right'}>
        {group !== 'operator' && (
          <IoseSwitch
            relay_state={row.relay_state}
            id={row.uuid_circuit}
            triggerCircuit={triggerCircuit}
            triggerCircuitAction={triggerCircuitAction}
            dashboard={dashboard}
            name_circuit={row.name}
          />
        )}
      </TableCell>
    );
  }

  function renderCircuitButtons(dashboard, row) {
    return dashboard === 'true' ? (
      <TableCell>
        <TableCellActions>
          <IoseEditButton
            tooltip="Editar Circuito"
            onClick={(e) => mountCircuitData(row.uuid_circuit, 'edit')}
          ></IoseEditButton>

          {/* {group === 'super' && (
            <IoseDeleteInputModal
              title="DELETAR CIRCUITO"
              name={row.name}
              deletefunc={(e) => mountCircuitData(row.uuid_circuit, 'delete')}
              left={'10px'}
            />
          )} */}
        </TableCellActions>
      </TableCell>
    ) : (
      <></>
    );
  }

  function renderLinerProgessCircuit(row) {
    return (
      <TableCell align="center">
        <IoseLinearProgress value={row.percent_energy_consumed} />
      </TableCell>
    );
  }

  const renderHeader = () => (
    <EnhancedTableHead
      classes={classes}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
      group={group}
      dashboard={dashboard}
    />
  );

  const renderButtonRelay = (row) => {
    const hasRelay =
      typeof row.has_relay !== 'undefined' && row.has_relay === true;

    if (hasRelay) {
      return renderSwitch(dashboard, row);
    } else {
      return <TableCellRelay dashboard={dashboard}>Sem Relé</TableCellRelay>;
    }
  };

  const renderTableCellName = (row) => (
    <Tooltip title={row.name} placement="bottom" arrow>
      <TableCellName
        component="th"
        scope="row"
        padding="none"
        onClick={() => mountCircuitData(row.uuid_circuit, 'show')}
        dashboard={dashboard}
      >
        <ArrowUpwardIcon />
        {row.name}
      </TableCellName>
    </Tooltip>
  );

  const renderBody = () => {
    const inDashboard = dashboard === 'true';

    return (
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            return (
              <TableRow hover tabIndex={-1} key={row.uuid_circuit}>
                {renderTableCellName(row)}

                {inDashboard && (
                  <TableCell align="center">
                    {row.energy_consumed} kwh
                  </TableCell>
                )}

                {inDashboard && (
                  <TableCell align="center">R$ {row.money_consumed}</TableCell>
                )}

                {renderButtonRelay(row)}

                {inDashboard && renderLinerProgessCircuit(row)}

                {renderCircuitButtons(dashboard, row)}
              </TableRow>
            );
          })}
        {/* {emptyRows > 5 && (
          <TableRow style={{ height: 33 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )} */}
      </TableBody>
    );
  };

  const renderPagination = () => (
    <TablePaginationStyled
      rowsPerPageOptions={[5, 10, 20, 30]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      dashboard={dashboard}
      labelRowsPerPage={'Circuitos por página'}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} ao ${to} de ${count !== -1 ? count : `mais que ${to}`}`
      }
    />
  );

  return (
    <Container>
      <TableContainerStyled>
        <TableStyled dashboard={dashboard} group={group}>
          {renderHeader()}
          {renderBody()}
        </TableStyled>
      </TableContainerStyled>
      {renderPagination()}
    </Container>
  );
}

IoseCircuitTable.propTypes = {
  /** This props get the data rows, should have this format : <br>
   *
    {
      description: "Descrição Ar Condicionado ",
      energy_consumed: 0,
      money_consumed: "0.00",
      name: "Ar Condicionado ",
      uuid_circuit: "8",
    }

   */
  rows: PropTypes.array,
  /** This props is boolen that indicate if it to be in dashboard*/
  dashboard: PropTypes.string,
  /** This props is a function that redirect to IoseCircuitShowCard*/
  showCircuit: PropTypes.func,
  /** This props indicates which group's user and from there show actuation or not*/
  group: PropTypes.string,
  /** This props is a function that redirect to IoseFormCircuitEditCard*/
  redirectEditCircuit: PropTypes.func,
  /** This props is a function that to delete circuit*/
  deleteCircuit: PropTypes.func,
};
