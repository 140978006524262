import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Import components from Style
import { InputBaseStyled, DivSearch, SearchIconStyled } from './style';

/**
 *
 * This a search used in all component that need search anything sidebar and containers
 */
export default function IoseSearch({
  placeholder,
  funcSearch,
  sidebar,
  getcards,
}) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (getcards) {
      funcSearch('');
      setSearch('');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getcards]);

  useEffect(() => {
    funcSearch(search);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <DivSearch sidebar={sidebar}>
        <InputBaseStyled
          placeholder={placeholder}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          type="text"
          sidebar={sidebar}
        ></InputBaseStyled>
        <SearchIconStyled />
      </DivSearch>
    </>
  );
}

IoseSearch.propTypes = {
  /** This prop get the name of search */
  placeholder: PropType.string,
  /** This prop is a functino to search any itens in container */
  funcSearch: PropType.func,
  /** This prop indicate if component to be in sidebar*/
  sidebar: PropType.oneOfType([PropType.bool, PropType.string]),
  /** This prop set position button "absolute or relative" */
  positionbutton: PropType.string,
  /** This prop gindicate if component to be in form*/
  inForm: PropType.bool,
  /** This prop set the opacity button search*/
  opacitybutton: PropType.string,
  /** This prop clean search when page was reloading*/
  getcards: PropType.bool,
};
