// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConcessionaires() {
  const [noConcessionairesError, setNoConcessionairesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getConcessionaires = async () => {
    const { headers } = getSession();

    let allConcessionaires = [];
    let lastEvaluatedKey = null;
    const limit = 10000;

    do {
      const response = await ioseAPI.getConcessionaires(
        headers,
        lastEvaluatedKey,
        limit
      );

      const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

      allConcessionaires = allConcessionaires.concat(data);
      lastEvaluatedKey = newLastEvaluatedKey;
    } while (lastEvaluatedKey);

    if (allConcessionaires.length === 0) {
      setNoConcessionairesError(Messages.noRegisteredConcessionaires);
      throw new Error(Messages.noRegisteredConcessionaires);
    }

    return allConcessionaires;
  };

  const {
    data: concessionaires,
    error: concessionairesError,
    refetch: refreshConcessionaires,
    isLoading,
  } = useQuery(['concessionairesData'], getConcessionaires);

  return {
    concessionaires,
    concessionairesError,
    noConcessionairesError,
    refreshConcessionaires,
    isLoading,
  };
}

export default useConcessionaires;
