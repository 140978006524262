import React from "react";
import * as S from '../style'
import IoseModalTextField from "components/IoseModalTextField";

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalExtractContentHeader({ ucAddress, currentExtract }) {

  const extract = currentExtract[0]

  return (
    <>
      <div>
        <S.Header>
          <S.HeaderItem>
            <S.TypographyTitle>
              DADOS EXTRATO DE ENERGIA
            </S.TypographyTitle>
          </S.HeaderItem>
        </S.Header>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
          }}
        >
          <div style={{ width: '35%' }}>
            <IoseModalTextField label="Endereço" value={ucAddress} />
          </div>

          <div style={{ width: '35%' }}>
            <IoseModalTextField
              label="Tarifa Horária"
              value={extract?.bandeira}
            />
          </div>

          <div style={{ width: '35%' }}>
            <IoseModalTextField
              label="Tipo do Medidor"
              value={extract?.tipo_medidor}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Data Emissão"
          value={extract?.data_emissao}
        />

        <IoseModalTextField
          label="Perdas de Transformação"
          value={convertNumberToDecimalStyle(extract?.perdas_transformacao)}
        />

        <IoseModalTextField
          label="Mês de referência"
          value={extract?.reference_month}
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Leitura atual"
          value={extract?.leitura_atual}
        />

        <IoseModalTextField label="Rel. Transform. Corrente" value={extract?.rel_corrent} />

        <IoseModalTextField
          label="Vencimento"
          value={extract?.vencimento}
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Leitura Anterior"
          value={extract?.leitura_anterior}
        />

        <IoseModalTextField
          label="Rel. Transform. Potencial"
          value={extract?.rel_potencial}
        />

        <IoseModalTextField
          label="Total a pagar"
          value={convertNumberToDecimalStyle(extract?.valor_total)}
        />
      </div>
    </>
  )
}