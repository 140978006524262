import React, { useState, useMemo } from 'react';
import { TableBody, TableCell, Paper, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as S from './style';
import { LoadingIndicator } from 'components';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  smallerColumn: {
    minWidth: '80px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  mediumColumn: {
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  addressColumn: {
    minWidth: '150px',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();
  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children, onClick, sortedBy, direction }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.tableHeaderCell} onClick={onClick}>
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const CircuitsTableRow = ({ value }) => {

  return (
    <S.TableRowStyled align="center">
    <TableCellCentered >{value.identification_number}</TableCellCentered>
    <TableCellCentered >{value.reference_month}</TableCellCentered>
    <TableCellCentered>
      {'R$ ' + convertNumberToDecimalStyle(value.account_value)}
    </TableCellCentered>
  </S.TableRowStyled>
  )
}

const CircuitsTable = ({ data, page, rowsPerPage, handleSort, sortBy, sortDirection }) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <S.StyledTable>
      <S.TableHeadStyled>
        <S.TableRowStyled align="center">
          <TableHeaderCellCentered
            onClick={() => handleSort('identification_number')}
            sortedBy={sortBy === 'identification_number'}
            direction={sortDirection}
          >
            UNIDADE CONSUMIDORA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('reference_month')}
            sortedBy={sortBy === 'reference_month'}
            direction={sortDirection}
          >
            REF: MÊS/ANO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('account_value')}
            sortedBy={sortBy === 'account_value'}
            direction={sortDirection}
          >
            VALOR DA CONTA
          </TableHeaderCellCentered>
        </S.TableRowStyled>
      </S.TableHeadStyled>
      <TableBody>
        {pageData.map((value, index) => (
          <CircuitsTableRow key={index} value={value} />
        ))}
      </TableBody>
    </S.StyledTable>
  );
};

export default function IoseEnergyBillTable({ loading, energyBillData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Ordena os dados baseado na coluna e direção
  const sortedData = useMemo(() => {
    if (!sortBy) return energyBillData;

    const sorted = [...energyBillData].sort((a, b) => {
      let aValue = a[sortBy];
      let bValue = b[sortBy];

      // Tratamento especial para o campo de data
      if (sortBy === 'reference_month') {
        const [monthA, yearA] = aValue.split('/');
        const [monthB, yearB] = bValue.split('/');
        const dateA = new Date(yearA, monthA - 1);
        const dateB = new Date(yearB, monthB - 1);
        aValue = dateA;
        bValue = dateB;
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [energyBillData, sortBy, sortDirection]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <CircuitsTable
            data={sortedData}
            page={page}
            rowsPerPage={rowsPerPage}
            handleSort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
          <TablePagination
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Faturas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
            backIconButtonText="Página anterior"
            nextIconButtonText="Próxima página"
          />
        </Paper>
      )}
    </div>
  );
}
