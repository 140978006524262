import React from 'react';
import { LoadingIndicator } from 'components';

import * as S from './style';

import {
  TrendingUp as TrendingUpIcon,
  ShowChart as ShowChartIcon,
  TrendingDown as TrendingDownIcon,
} from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const getIcon = (title) => {
  switch (title) {
    case 'Geração total':
      return <TrendingUpIcon />;
    case 'Reativo total':
      return <TrendingDownIcon />
    default:
      return null;
  }
};

export default function Iose3dListSummaryGeracao({ data, loading }) {
  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }
  const totalGerado = data?.reduce(
    (total, obj) => total + obj.gerado,
    0
  );
  const totalReativo = data?.reduce(
    (total, obj) => total + obj.reativoGerado,
    0
  );


  const SummaryUnitysData = [
    {
      title: 'Geração total',
      description: 'Valor total de geração medido pelo IOSE',
      value: convertNumberToDecimalStyle(totalGerado) + ' kWh',
    },
    {
      title: 'Reativo total',
      description: 'Valor total de reativo medido durante a geração pelo IOSE',
      value: convertNumberToDecimalStyle(totalReativo) + ' VArh',
    },
  ];

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  return (
    <S.SummaryListContainer>
      <S.TitleList>RESUMO</S.TitleList>
      <S.ListContainer>
        {SummaryUnitysData.map(({ title,description, value }) => (
            <Tooltip
              title={description}
              placement="bottom"
              arrow
            >
            <S.ListStyled key={title}>
              
              <S.SummaryIconWrapper>{getIcon(title)}</S.SummaryIconWrapper>
              <S.ListItemTextStyled primary={title} secondary={value} />
              
            </S.ListStyled>
          </Tooltip>
        ))}
      </S.ListContainer>
    </S.SummaryListContainer>
  );
}
