import styled from 'styled-components';
import {
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  IconButton,
} from '@material-ui/core';

export const TableStyled = styled(Table)`
  .MuiTableCell-root {
    display: table-cell;
    padding: 12px 24px;
    font-family: 'Montserrat', sans-serif;
    color: var(--secondary);
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    text-align: center;
    border: none;

    &:hover {
      .MuiTableSortLabel-root,
      .MuiTableSortLabel-icon {
        color: var(--secondary);
      }
    }

    .MuiTableSortLabel-active,
    .MuiTableSortLabel-iconDirectionAsc,
    .MuiTableSortLabel-iconDirectionDesc {
      color: var(--secondary);
    }
  }
`;

export const TableCellHeader = styled(TableCell)`
  margin: 10px 0px;
  font-weight: 600;
  text-align: center;
  padding-left: 20px;
  font-size: 2em;

  .MuiButtonBase-root {
    font-size: 12px;
    margin: 10px 0px;
    font-weight: 600;
    padding-left: 20px;
  }
`;

export const TableContainerStyled = styled(TableContainer)`
  /* height: 220px; */
  background: white;
  width: 100%;
  overflow-x: none;
  overflow-y: scroll;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  /* border: solid 1px red; */
  margin-top: 8px;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const TablePaginationStyled = styled(TablePagination)`
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  display: flex;
  align-items: baseline;
  align-content: baseline;
  justify-content: flex-end;
  margin-bottom: 64px;
  color: var(--secondary);
  border-radius: 0px 0px 4px 4px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  svg {
    color: var(--secondary);
  }
`;

export const Error = styled.p`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '20px')};
  margin-top: ${(props) => props.top && props.top};
  font-size: ${(props) => props.font && props.font};
  font-weight: ${(props) => (props.bold === 'false' ? '500' : 'bolder')};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  color: red;
`;

export const TableRowStyled = styled(TableRow)`
  background: #f5f5f5;
  transition: all 0.2s ease-in-out;
`;

export const IconButtonStyled = styled(IconButton)`
  color: var(--secondary);
`;

export const TextCell = styled.p`
  color: ${(props) => (props.color ? props.color : 'var(--secondary)')};
  font-size: 12px;
`;
