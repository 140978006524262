import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Imports from @date-io
import DateFnsUtils from '@date-io/date-fns';

import { useLocation } from 'react-router-dom';

//Imports from IOSE components
import IoseListTransferCircuits from 'components/IoseListTransferCircuits';
import IoseButtonSideBar from 'components/IoseButton';
import IoseSelection from 'components/IoseSelection';
import IoseSearch from 'components/IoseSearch';

//Imports from Material UI components
import { createMuiTheme, Grid, Grow } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from 'common/ValidationsForms';

//Imports from Style
import {
  Container,
  Header,
  YellowCheck,
  YellowRadio,
  RadioGroupStyled,
  FormControlLabelStyled,
  IoseTextFieldSidebarStyled,
  EmojiObjectsIconStyled,
  GridStyled,
  FormStyled,
  Error,
  DateTimePickerStyled,
} from './style';

//Theme to TimerPicker this is a single mod to chand css it
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E8BE00',
        color: '#015A9E',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#E8BE00',
      },
      thumb: {
        borderColor: '#E8BE00',
      },
      noPoint: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        backgroundColor: '#E8BE00',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: '#e8be00',
      },
      dayDisabled: {
        color: '#e8be00',
      },
      current: {
        color: '#e8be00',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#e8be00',
      },
    },
    MuiTab: {
      fullWidth: { backgroundColor: '#e8be00' },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#015A9E',
      },
    },
  },
});

/**
 * This component render a form to add new Scheduling
 */
export default function IoseFormScheduleCreateCard({
  addSchedule,
  redirectValidation,
}) {
  let location = useLocation();

  const allGroups = location.state.allGroups;
  const allCircuits = location.state.allCircuits;
  const scheduleData = location.state.scheduleData;

  //Schema validation for Scheduling
  const SchedulerAddSchema = Yup.object().shape({
    name: validationsForm.name,
  });

  const labels = [{ label: 'Nome', variable: 'name' }];

  const labelsAction = [
    { label: 'Ligar', variable: 'true' },
    { label: 'Desligar', variable: 'false' },
  ];

  const labelRepeat = [
    { label: 'Uma vez', variable: 'false' },
    { label: 'Repetir', variable: 'true' },
  ];

  let labelWeek = [
    { name: 'sun', label: 'Dom' },
    { name: 'mon', label: 'Seg' },
    { name: 'tue', label: 'Ter' },
    { name: 'wed', label: 'Quar' },
    { name: 'thu', label: 'Qui' },
    { name: 'fri', label: 'Sex' },
    { name: 'sat', label: 'Sab' },
  ];

  //STATES----------------------

  // State and function to change the scheduling action
  const [action, setAction] = useState(
    scheduleData ? (scheduleData.action_relay ? 'true' : 'false') : 'true'
  );

  const [repeat, setRepeat] = useState(
    scheduleData ? (scheduleData.repeat ? 'true' : 'false') : 'false'
  );

  const [switchboard, setSwitchboard] = useState(
    scheduleData ? scheduleData.switchboard : allGroups[0]
  );

  const [circuitsSwitchboard, setCircuitsSwitchboard] = useState([]);
  const [circuitsSelecteds, setCircuitsSelecteds] = useState(
    scheduleData ? scheduleData.circuits : []
  );

  const [circuitsFiltered, setCircuitsFiltered] = useState([]);
  const [search, setSearch] = useState(false);

  const [week, setWeek] = useState(
    scheduleData
      ? scheduleData.week
      : {
          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
        }
  );

  const [run_at, setRun_at] = useState(
    scheduleData ? scheduleData.run_at : new Date()
  );

  const [errorEmptyCircuitChosen, setErrorEmptyCircuitChosen] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  //USEEFFECTS----------------------

  useEffect(() => {
    if (allCircuits.length !== 0 && allGroups.length !== 0) {
      filterCircuits();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCircuits, allGroups, switchboard]);

  //FUNCTIONS----------------------

  const handleChangeDays = (e) => {
    const { name } = e.target;

    setWeek((week) => ({
      ...week,
      [name]: !week[name],
    }));
  };

  //Const to render the Checkboxes Days in the form
  const renderDaysCheck = (day) => {
    return (
      <Grid item xs={4} sm={1} key={day.name}>
        <FormControlLabelStyled
          control={<YellowCheck checked={week[day.name]} name={day.name} />}
          label={day.label}
          onChange={handleChangeDays}
        />
      </Grid>
    );
  };

  function filterCircuits() {
    const circuitsFilter = allCircuits.filter(
      (circuit) => circuit.uuid_group === switchboard.uuid_group
    );
    setCircuitsSwitchboard(circuitsFilter);
  }

  //Function to filter Circuit
  function searchCircuit(circuitNameSearch) {
    const circuitNameSearchUppcase = circuitNameSearch.toUpperCase();

    //Clean old info search
    setCircuitsFiltered([]);

    if (circuitNameSearch === '') {
      return setSearch(false);
    } else {
      setSearch(true);

      const circuitFiltered = circuitsSwitchboard.filter((card) =>
        card.name.toUpperCase().includes(circuitNameSearchUppcase)
      );

      if (circuitFiltered.length !== 0) {
        setCircuitsFiltered(circuitFiltered);
      } else {
        setSearch(true);
      }
    }
  }

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setErrorEmptyCircuitChosen(false);

    setTimeout(() => {
      setSubmitting(false);

      if (circuitsSelecteds.length !== 0 && !errorDate) {
        const arrayIdCircuits = circuitsSelecteds.map(
          (circuit) => circuit.uuid_circuit
        );

        run_at.setSeconds(0, 0);

        repeat !== 'true' && setWeekOneShot();

        const scheduleDataAdd = {
          name: values.name,
          action_relay: action === 'true' ? true : false,
          repeat: repeat === 'true' ? true : false,
          week: week,
          switchboard: switchboard,
          circuits: circuitsSelecteds,
          run_at: scheduleData ? scheduleData.run_at : run_at.toISOString(),
        };

        addSchedule({
          ...scheduleDataAdd,
          circuits: arrayIdCircuits,
        });

        redirectValidation(circuitsSelecteds, 'create', scheduleDataAdd);
      } else {
        circuitsSelecteds.length === 0 && setErrorEmptyCircuitChosen(true);
      }
    }, 500);
  };

  const handleRunAt = (runAtSelected) => {
    setErrorDate(false);
    const currentyDate = new Date();

    if (runAtSelected < currentyDate) {
      setErrorDate(true);
    }

    setRun_at(runAtSelected);
  };

  //This function set week in one shot mode
  const setWeekOneShot = () => {
    const numberWeek = run_at.getDay();

    let weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    weekDays.forEach((day) => {
      week[day] = false;
    });

    week[weekDays[numberWeek]] = true;
  };

  return (
    <Container>
      <Formik
        initialValues={{
          name: scheduleData ? scheduleData.name : '',
        }}
        validationSchema={SchedulerAddSchema}
        onSubmit={onSubmitForm}
      >
        {({
          handleChange,
          handleBlur,
          submitForm,
          touched,
          isSubmitting,
          errors,
          initialValues,
        }) => (
          <FormStyled>
            {/**Header*/}
            <Header>
              Criar
              <IoseButtonSideBar
                type="submit"
                disabled={isSubmitting}
                // onClick={submitForm}
              >
                Confirmar
              </IoseButtonSideBar>
            </Header>

            {/**Input Name*/}
            {labels.map((value) => {
              return (
                <Field
                  component={IoseTextFieldSidebarStyled}
                  key={value.variable}
                  id={value.variable}
                  name={value.variable}
                  type={value.variable === 'email' ? 'email' : 'text'}
                  label={value.label}
                  defaultvalue={initialValues[value.variable]}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors[value.variable] && touched[value.variable]
                      ? errors[value.variable]
                      : ''
                  }
                />
              );
            })}

            <Grid container alignItems="center">
              {/**DatePicker and TimePicker*/}
              <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DateTimePickerStyled
                    autoOk
                    variant="inline"
                    label="Data e Hora"
                    value={run_at}
                    onChange={(e) => handleRunAt(e)}
                    theme="white"
                    ampm={false}
                    minDate={new Date()}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>

              {/**Action checkboxes*/}
              <RadioGroupStyled
                row={true}
                value={action}
                onChange={(e) =>
                  setAction(e.target.value === 'true' ? 'true' : 'false')
                }
              >
                {labelsAction.map((field) => (
                  <Field
                    component={FormControlLabelStyled}
                    type="radio"
                    value={field.variable}
                    control={<YellowRadio />}
                    label={field.label}
                    key={field.variable}
                  />
                ))}
                <EmojiObjectsIconStyled on={action} />
              </RadioGroupStyled>
            </Grid>

            {errorDate && (
              <Error>Escolha uma data maior ou igual à atual!</Error>
            )}

            {/**Repeat ckeckboxes*/}
            <RadioGroupStyled
              row={true}
              value={repeat}
              onChange={(e) =>
                setRepeat(e.target.value === 'true' ? 'true' : 'false')
              }
            >
              {labelRepeat.map((field) => (
                <Field
                  component={FormControlLabelStyled}
                  type="radio"
                  value={field.variable}
                  control={<YellowRadio />}
                  label={field.label}
                  key={field.variable}
                />
              ))}
            </RadioGroupStyled>

            {/**Days checkboxes*/}
            {repeat === 'true' && (
              <Grow in={true} {...{ timeout: 1000 }}>
                <section>
                  <Grid container justify="space-between">
                    {labelWeek.map(renderDaysCheck)}
                  </Grid>
                </section>
              </Grow>
            )}

            {/**List check to Switchboard and Circuits*/}
            {allGroups.length !== 0 ? (
              <>
                <GridStyled
                  container
                  direction="row"
                  alignItems="baseline"
                  justify="space-between"
                >
                  <Field
                    component={IoseSelection}
                    id={switchboard}
                    name={'quadros'}
                    type={'text'}
                    func={setSwitchboard}
                    title={'Quadros'}
                    object={allGroups}
                    defaultvalue={switchboard}
                    sidebar="true"
                  />
                </GridStyled>

                <Grid container justify={'flex-end'}>
                  <IoseSearch
                    placeholder="Pesquisar Circuito..."
                    funcSearch={searchCircuit}
                    sidebar={'true'}
                    positionbutton="absolute"
                    inForm={true}
                  />
                  {errorEmptyCircuitChosen && (
                    <Error>Escolha ao menos um circuito!</Error>
                  )}
                  <IoseListTransferCircuits
                    choices={search ? circuitsFiltered : circuitsSwitchboard}
                    switchboard={switchboard}
                    allGroups={allGroups}
                    setCircuitsSelecteds={setCircuitsSelecteds}
                    initialChosen={scheduleData && scheduleData.circuits}
                  />
                </Grid>
              </>
            ) : (
              <Error>Sem quadros cadastrados!</Error>
            )}
          </FormStyled>
        )}
      </Formik>
    </Container>
  );
}

IoseFormScheduleCreateCard.propTypes = {
  /** This props get is the function to add sheduling in DB, it comes SidebarSheduleContainer */
  addSchedule: PropType.func,
};
