/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { useConsumerGroup, useCreateLogs } from 'hooks';
import { IoseButton, IoseDatePicker } from 'components';
import { AccountContext, ioseAPI } from 'common';
import exportTelemetriaToExcel from '../excelTelemetriaReport';
import IoseDatePicker2 from 'components/IoseDatePicker2';

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function TelemetriaFiltros({ loading, emptyResponse }) {
  const { telemetryConsumerGroups } = useConsumerGroup();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const [initialSelectedDate, setInitialSelectedDate] = useState(null);
  const [finalSelectedDate, setFinalSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEnergyBillsItems = useCallback(
    async (object) => {
      try {
        setIsLoading(true);
        loading(true);
        const sessionData = await getSession();

        let allData = [];
        let lastEvaluatedKey = null;
        const limit = 10000;

        do {
          const response = await ioseAPI.obterTelemetriaMesFiltrada(
            sessionData.headers,
            object,
            lastEvaluatedKey,
            limit
          );

          const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

          // Adiciona todos os dados recebidos ao array allData
          allData.push(...data);

          lastEvaluatedKey = newLastEvaluatedKey;
        } while (lastEvaluatedKey);

        const operacao = 'EMITIR RELATÓRIO';
        const form = 'RELATÓRIO DE TELEMETRIA';
        const logContent = {};

        await createLog(operacao, form, logContent);

        // const combinedData = allData.map((allDataItem) => {
        //   if (telemetryConsumerGroups && telemetryConsumerGroups.length > 0) {
        //     const matchingTelemetryItem = telemetryConsumerGroups.find(
        //       (telemetryItem) =>
        //         telemetryItem.numero_telemetria ===
        //         allDataItem.numero_telemetria
        //     );

        //     if (matchingTelemetryItem) {
        //       return {
        //         ...allDataItem,
        //         general_management: matchingTelemetryItem.general_management,
        //         regional_management: matchingTelemetryItem.regional_management,
        //         city: matchingTelemetryItem.city,
        //         demanda_fora: matchingTelemetryItem.demanda_fora,
        //         demanda_ponta: matchingTelemetryItem.demanda_ponta,
        //       };
        //     } else {
        //       return allDataItem;
        //     }
        //   } else {
        //     return allDataItem;
        //   }
        // });

        exportTelemetriaToExcel(
          allData,
          object.previous_reading_start,
          object.previous_reading_end
        );

        if (allData?.length === 0) {
          emptyResponse(true);
        }

        setIsLoading(false);
        loading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        loading(false);
      }
    },
    [getSession, loading, emptyResponse, telemetryConsumerGroups]
  );

  const initialValues = {
    unidade_consumidora: '',
    numero_telemetria: '',
    reference_month: '',
    previous_reading_start: '',
    previous_reading_end: '',
  };

  const onSubmit = async (values, { resetForm, setFieldValue }) => {
    const filteredValues = removeEmptyFields(values);
    await getEnergyBillsItems(filteredValues);
    resetForm();
    setFieldValue('previous_reading_start', '');
    setFieldValue('previous_reading_end', '');
    setInitialSelectedDate(null);
    setFinalSelectedDate(null);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDatePicker
                label="Data inicial"
                handleChange={(value) => {
                  const selectedDate = new Date(value);
                  selectedDate.setUTCHours(0, 0, 0, 0); // Define a hora para 00:00:00 (UTC)
                  const isoDateString = selectedDate.toISOString();
                  setFieldValue('previous_reading_start', isoDateString); // Mantém a hora 00:00:00 na string
                  setInitialSelectedDate(value);
                }}
                initialSelectedDate={initialSelectedDate}
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDatePicker2
                label="Data final"
                handleChange={(value) => {
                  setFieldValue('previous_reading_end', value);
                  setFinalSelectedDate(value);
                }}
                finalSelectedDate={finalSelectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isLoading}>
              EMITIR RELATÓRIO
            </IoseButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
