import styled from 'styled-components';

import { Typography, CircularProgress } from '@material-ui/core';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 30px !important;
  height: 30px !important;
`;

export const ModalTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

export const ModalTitleHeader = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: var(--primary);
  margin-top: 6px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const StyledHeaderModal = styled.div`
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #fff;
  height: 350px;
  width: 620px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;

export const RoomIcon = styled(MeetingRoomIcon)`
  color: var(--primary);
`;
