import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';
import { useAlert } from 'react-alert';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseCircuitOpenObjects,
  IoseInfinityScroll,
  IoseSubHeaderBar,
  IoseSearch,
} from 'components';

import {
  AccountContext,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { WrappedContainer } from './style';

import { useEnvironments, useObjects, useGroupPermissions, useClientData, useCircuits, useUnitys } from 'hooks';
import Permissions from 'common/permissions';
import { ioseIotAPI } from 'common/ioseIotAPI';
import CircuitosMainTable from './components/cuicuits-table';

export default function CircuitContainer({ getcards }) {
  // const [allCircuits, setAllCircuits] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);

  const [clientData, setClientData] = useState({});
  //eslint-disable-next-line
  const [dailyEstimateConsumption, setDailyEstimateConsumption] = useState([]);
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [formatedData, setformatedData] = useState([]);
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [lastMeasuresError, setLastMeasuresError] = useState('');
  const [measures, setMeasures] = useState([]);
  //eslint-disable-next-line
  const [nextPage, setNextPage] = useState(0);
  const [openObjectsModal, setOpenObjectsModal] = useState(false);

  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  //eslint-disable-next-line
  const [selectedObject, setSelectedObject] = useState(null);
  // const [uuidClient, setuuidClient] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);

  const alert = useAlert();
  const { client } = useClient();
  const { uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient)
  const { circuits } = useCircuits(uuidClient);
  const { getSession } = useContext(AccountContext);

  const { environments } = useEnvironments();
  const { objects, getObjects } = useObjects();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'circuitos', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSelectedObject(selectedCard);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  useEffect(() => {
    getLastMeasureAllCircuits();
    //eslint-disable-next-line
  }, [circuits]);

  useEffect(() => {
    prepareData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuits, measures, objects, unitys]);

  const handleCloseObjectsModal = () => {
    setSelectedCard(null);
    setOpenObjectsModal(false);
  };

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    // setuuidClient(clientData.uuid_client);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  async function getLastMeasureAllCircuitsRequisition(headers) {
    const currentDate = new Date();
    const initialDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    const lastMeasuresAllCircuit = await Promise.all(
      circuits?.map(({ uuid_circuit }) =>
        ioseIotAPI.getLastMeasure(
          headers,
          uuid_circuit,
          initialDate.toISOString(),
          currentDate.toISOString()
        )
      )
    );

    return lastMeasuresAllCircuit;
  }

  async function getLastMeasureAllCircuits() {
    try {
      const { headers } = await getSession();
      const data = await getLastMeasureAllCircuitsRequisition(
        headers
      );
      setMeasures(data);
    } catch (error) {
      setLastMeasuresError('Erro ao buscar medidas de algum circuito');
    }
  }

  function prepareData() {
    const circuitDataArray = [];

    const circuitsWithMeasures = circuits
      ?.map((circuit) => {

        const matchingMeasure = measures?.find(
          (measure) => measure?.data?.data?.uuid_circuit === circuit.uuid_circuit
        );

        if (!matchingMeasure) {
          return null;
        }

        const matchingObjects = objects?.filter(
          (object) => object.uuid_circuit === circuit.uuid_circuit
        );

        // if (matchingObjects?.length === 0) {
        //   return [];
        // }

        const totalPowerObjects = objects?.reduce(
          (sum, object) => sum + object?.power,
          0
        );

        const cumulativeConsumption =
          matchingMeasure?.data?.data?.measures?.arrayConsumed ?? [];

        const formattedCumulativeConsumption = cumulativeConsumption?.map(
          (measure) => Number((measure / 1000).toFixed(2))
        );

        const dailyConsumption = [cumulativeConsumption[0]];

        for (let i = 0; i < cumulativeConsumption?.length - 1; i++) {
          const dailyValue =
            cumulativeConsumption[i + 1] - cumulativeConsumption[i];
          dailyConsumption.push(dailyValue);
        }

        const formattedDailyConsumption = dailyConsumption?.map((num) =>
          Number((num / 1000).toFixed(2))
        );

        const maxValueDaily = Math.max(...formattedDailyConsumption) + 0.5;
        const minValueDaily = Math.min(...formattedDailyConsumption) - 0.5;
        const maxValueCumulative =
          Math.max(...formattedCumulativeConsumption) + 1.0;
        const minValueCumulative =
          Math.min(...formattedCumulativeConsumption) - 1.0;

        const measuresPeriod =
          matchingMeasure?.data?.data?.measures?.dates ?? [];
        const formattedDates = measuresPeriod?.map((date) =>
          new Date(date).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
          })
        );

        const matchingUnity = unitys.find(
          (unity) => unity.uuid_unity === circuit.uuid_unity
        );

        if (!matchingUnity) {
          return null;
        }

        const circuitData = {
          ...circuit,
          cumulative_consumption: formattedCumulativeConsumption,
          daily_consumption: formattedDailyConsumption,
          dates: formattedDates,
          objects: matchingObjects,
          unity_name: matchingUnity.name,
          max_value: maxValueDaily,
          min_value: minValueDaily,
          max_value_cumulative: maxValueCumulative,
          min_value_cumulative: minValueCumulative,
        };

        const currentDate = new Date();
        const daysInCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        //eslint-disable-next-line
        const daysRemaining = daysInCurrentMonth - currentDate.getDate();

        circuitData.estimated_consumption = Number(
          (
            (totalPowerObjects * 8 * daysInCurrentMonth) /
            1000 /
            daysInCurrentMonth
          ).toFixed(2)
        );

        circuitData.daily_estimated_consumption = formattedDates?.map(
          (date) => {
            const dailyEstimatedConsumption = circuitData.estimated_consumption;
            setDailyEstimateConsumption(dailyEstimatedConsumption);
            return dailyEstimatedConsumption;
          }
        );

        circuitData.max_value_estimated =
          Math.max(...circuitData.daily_estimated_consumption) + 0.5;

        circuitData.cumulative_estimated_consumption = circuitData.daily_estimated_consumption
          ?.reduce((acc, value) => {
            // O valor acumulado é a soma do valor anterior e do valor atual
            const accumulatedValue = acc?.length
              ? acc[acc?.length - 1] + value
              : value;

            // Adiciona o valor acumulado no novo array
            return [...acc, accumulatedValue];
          }, [])
          .map((value) => Number(value.toFixed(2)));

        circuitData.max_value_cumulative_estimated =
          Math.max(...circuitData.cumulative_estimated_consumption) + 0.5;

        circuitDataArray.push(circuitData);

        return circuitData;
      })
      ?.filter(Boolean);

    setformatedData(circuitsWithMeasures);
    setCards(circuitDataArray);
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const getMoreUnitys = () => nextPage && getClientData(true);

  //Function to filter Unity
  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered?.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindCircuits);
    } else {
      setSearch(false);
    }
  };

  //Functions to RENDER
  // eslint-disable-next-line
  const renderAlert = (message, type) => {
    return alert.show(`${message}`, {
      title: type,
      closeCopy: 'Fechar',
    });
  };


  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="Criar Ambiente" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title="Circuitos"
        description={'Total: ' + cards?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Circuitos…"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable = () => {
    return (
      <CircuitosMainTable 
        data={formatedData}
        selectedCard={selectedCard}
      />
   )
  }

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length}
            next={getMoreUnitys}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {hasReadPermission 
              ? renderTable()
              : setError(
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
            )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseCircuitOpenObjects
          open={openObjectsModal}
          selectedObject={selectedObject}
          onClose={handleCloseObjectsModal}
          group={group}
          clientData={clientData}
          getMoreUnitys={getMoreUnitys}
          loading={loading}
          environments={environments}
          getObjects={getObjects}
          allCircuits={circuits}
          errorAlertText={'Nenhum objeto vinculado a este circuito!'}
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////

  function handleSelectedObject(selectedCard) {
    if (selectedCard) {
      let newObject = {};

      if (selectedCard.objects) {
        newObject = {
          name: selectedCard.name ? selectedCard.name : 'Não cadastrado',
          description: selectedCard.description,
          objects: selectedCard.objects,
        };
      } else {
        newObject = {
          name: selectedCard.name ? selectedCard.name : 'Não cadastrado',
          description: selectedCard.description,
          objects: [],
        };
      }

      setSelectedObject(newObject);
    }
  }

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

CircuitContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
