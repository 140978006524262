import React from 'react';
import { TableBody, TableCell, Paper, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import * as S from './style';

import { IoseInfinityScroll } from 'components';

import { useClientData } from 'hooks';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const SwitchBoardTableRow = ({ value, onClick }) => (
  <S.TableRowStyled align="center" onClick={() => onClick(value)}>
    <TableCellCentered>{value.unity}</TableCellCentered>
    <TableCellCentered>{value.name}</TableCellCentered>
    <TableCellCentered>
      {convertNumberToDecimalStyle(value.measure)}
    </TableCellCentered>
  </S.TableRowStyled>
);

const SwitchBoardTable = ({ data, onRowClick }) => (
  <S.StyledTable>
    <S.TableHeadStyled>
      <S.TableRowStyled align="center">
        <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered>
        <TableHeaderCellCentered>QUADRO</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONSUMO KWH</TableHeaderCellCentered>
      </S.TableRowStyled>
    </S.TableHeadStyled>

    <TableBody>
      {data.map((value, index) => (
        <SwitchBoardTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </S.StyledTable>
);

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const IoseAdmDashTableSwitchBoard = ({ allCircuits, GroupsData }) => {
  const { uuidClient, clientName } = useClientData();

  const history = useHistory();

  const all_circuits = allCircuits;

  const handleRowClick = (value) => {
    const uuid_group_selected = value.uuid_group;

    const circuit_group_selected = all_circuits.filter(
      (circuit) => circuit.uuid_group === uuid_group_selected
    );

    const data = {
      uuidClient: uuidClient,
      clientName: clientName,
      circuitsOfGroup: circuit_group_selected,
      unityName: value.unityName,
      groupsData: GroupsData.filter(
        (group) => group.uuid_group === uuid_group_selected
      ),
    };

    history.push({
      pathname: '/switchboard-dashboard',
      state: data,
    });
  };

  const renderTable = () => {
    return (
      <IoseInfinityScroll
        dataLength={GroupsData?.length}
        next={() => {}}
        hasMore={false}
        loading={false}
        endList={false}
        sidebar={false}
        marginTop={0}
        padding={0}
        colortextend={null}
        sizetextend={null}
        scrollableTarget="scrollableDiv"
        height={400}
      >
        <SwitchBoardTable onRowClick={handleRowClick} data={GroupsData} />
      </IoseInfinityScroll>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <Paper>{renderTable()}</Paper>
    </Grow>
  );
};

export default IoseAdmDashTableSwitchBoard;
