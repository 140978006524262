import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import geoJson from './ParanaGeo.json';

const data = [
  { name: 'Curitiba', value: 35000000 },
  { name: 'Londrina', value: 350000 },
  { name: 'Maringá', value: 280000 },
  { name: 'Ponta Grossa', value: 240000 },
  { name: 'Cascavel', value: 200000 },
  { name: 'São José dos Pinhais', value: 180000 },
  { name: 'Foz do Iguaçu', value: 150000 },
  { name: 'Colombo', value: 140000 },
  { name: 'Guarapuava', value: 120000 },
  { name: 'Paranaguá', value: 100000 },
  { name: 'Toledo', value: 95000 },
  { name: 'Apucarana', value: 90000 },
  { name: 'Pinhais', value: 85000 },
  { name: 'Araucária', value: 80000 },
  { name: 'Cianorte', value: 75000 },
  { name: 'Campo Largo', value: 70000 },
  { name: 'Paranavaí', value: 65000 },
  { name: 'Umuarama', value: 60000 },
  { name: 'Cambé', value: 55000 },
  { name: 'Sarandi', value: 50000 },
];

echarts.registerMap('PARANA', geoJson);

const mapOption = {
  visualMap: {
    left: 'right',
    min: 500000,
    max: 38000000,
    inRange: {
      color: [
        '#313695',
        '#4575b4',
        '#74add1',
        '#abd9e9',
        '#e0f3f8',
        '#ffffbf',
        '#fee090',
        '#fdae61',
        '#f46d43',
        '#d73027',
        '#a50026',
      ],
    },
    text: ['High', 'Low'],
    calculable: true,
  },

  series: [
    {
      id: 'consumo',
      type: 'map',
      roam: true,
      map: 'PARANA',
      animationDurationUpdate: 1000,
      universalTransition: true,
      data: data,
      tooltip: {
        formatter: function (params) {
          return 'Consumo: ' + params.value;
        },
      },
    },
  ],

  tooltip: {
    trigger: 'item',
    showDelay: 0,
    transitionDuration: 0.3,
  },
};

mapOption.series[0].map = 'PARANA';
mapOption.series[0].center = [-51.5, -24.5];
mapOption.series[0].zoom = 1;

export default function MapChart() {
  return <ReactECharts option={mapOption} style={{ height: 300 }} />;
}
