import React, { useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import * as S from './style';

import {
  IoseCloseModalButton,
  IoseMapRedirectButton,
} from 'components/IoseButtonIcon';

import { AccountContext, ioseAPI } from 'common';

import {
  IoseAlertLoadingCards,
  IoseAlertSuccessModal,
} from 'components/IoseAlert';

import { IoseModalButton } from 'components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#ECF0F5',
    // height: '90vh',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

function converterStringParaNumero(valor) {
  if (!isNaN(valor)) {
    return parseFloat(valor);
  }

  valor = valor.replace(',', '.');

  return parseFloat(valor);
}

export default function IoseBillsEditItensModal({
  open,
  onClose,
  submit,
  data,
  editInitialValues,
  refreshBills,
  refreshFaturas,
  closeAuditModal,
  handleForceTableRender,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { getSession } = useContext(AccountContext);

  //eslint-disable-next-line
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const [uuidBill, setUuidBill] = useState('');
  const [showNotification, setShowNotification] = useState(null);

  useEffect(() => {
    setUuidBill(data?.uuid_energy_bill);
  }, [data]);

  function redirectToMapPage() {
    history.push('/map');
  }

  const renderContent = () => {
    async function updateBill(uuid_energy_bill, object) {
      // setEditNotificationType(0);
      setLoading(true);

      const formattedObject = { ...object };

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          if (
            key.startsWith('item_consumption_') ||
            key.startsWith('item_icms_') ||
            key.startsWith('item_piscofins_') ||
            key.startsWith('item_tax_') ||
            key.startsWith('item_total_value_') ||
            key.startsWith('item_unitary_value_')
          ) {
            const valorConvertido = converterStringParaNumero(object[key]);

            formattedObject[key] = valorConvertido;
          }
        }
      }

      try {
        const sessionData = await getSession();

        await ioseAPI.updateEnergyBillLineItens(
          sessionData.headers,
          uuid_energy_bill,
          formattedObject
        );

        refreshBills();
        refreshFaturas();
        setLoading(false);
        setShowNotification(1);

        setTimeout(() => {
          onClose();
          closeAuditModal();
          setShowNotification(null);
        }, 2500);
      } catch (err) {
        console.error(err);
      }
    }

    const handleUpdate = (values) => {
      updateBill(uuidBill, values);
    };

    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.StyledWrappedModal2>
          <Formik
            initialValues={editInitialValues}
            onSubmit={(values, { setSubmitting }) => {
              handleUpdate(values);

              setSubmitting(false);
            }}
          >
            {({
              handleChange,
              handleBlur,
              isSubmitting,
              errors,
              touched,
              values,
            }) => (
              <Form>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '15px',
                  }}
                >
                  {Object.keys(values).map((fieldName) => (
                    <S.DivInputs key={fieldName}>
                      <S.StyledLabel htmlFor={fieldName}>
                        {fieldName}
                      </S.StyledLabel>
                      <S.StyledInput
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        value={values[fieldName]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors[fieldName] && touched[fieldName] && (
                        <div>{errors[fieldName]}</div>
                      )}
                    </S.DivInputs>
                  ))}
                </div>
                <S.ButtonContainer>
                  {loading ? (
                    <S.CircularProgressStyled />
                  ) : (
                    showNotification === 1 && (
                      <IoseAlertSuccessModal
                        text={'Dados da fatura atualizados com sucesso.'}
                      />
                    )
                  )}
                  {!loading && showNotification !== 1 && (
                    <IoseModalButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Editar Campos
                    </IoseModalButton>
                  )}
                </S.ButtonContainer>
              </Form>
            )}
          </Formik>
        </S.StyledWrappedModal2>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <S.ModalContainer>
          <S.StyledHeaderModal>
            <S.RoomIcon />

            <S.ModalTitleHeader variant="h5" gutterBottom>
              EDITAR DADOS DA ÁREA DOS ITENS DA FATURA
            </S.ModalTitleHeader>

            <S.ButtonsContainer>
              {/* <IoseAuditRedirectButton
                tooltip="RETORNAR PARA UNIDADE CONSUMIDORA"
                bottom="5px"
                onClick={() => redirectToConsumerGroupPage()}
              /> */}

              <IoseMapRedirectButton
                tooltip="RETORNAR PARA O MAPA"
                bottom="5px"
                onClick={() => redirectToMapPage()}
              />

              <IoseCloseModalButton
                tooltip="FECHAR MODAL"
                bottom="5px"
                onClick={() => onClose()}
              />
            </S.ButtonsContainer>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <>
                {/* {renderSubHeaderAndSearchBar()} */}
                {renderContent()}
              </>
            </div>
          </S.StyledWrappedModal>
        </S.ModalContainer>
      </Grow>
    </Modal>
  );
}
