import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function IoseBarChartConsumptionComparision({ data }) {
  const [dates, setDates] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    formatChartData(data);
  }, [data]);

  function formatChartData(data) {
    const yAxisData = new Set();
    const seriesData = [];

    const groupedData = data?.reduce((groups, item) => {
      const key = `${item.identification_number}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});

    for (const key in groupedData) {
      const group = groupedData[key];

      group.sort((a, b) => {
        const [monthA, yearA] = a.reference_month.split('/');
        const [monthB, yearB] = b.reference_month.split('/');
        return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
      });

      const ucDataConta = {
        name: `UC ${group[0].identification_number} - CONTA`,
        displayName: 'CONTA', // Rótulo para legenda fixa
        type: 'bar',
        stack: group[0].identification_number.toString(),
        emphasis: {
          focus: 'series',
        },
        data: [],
      };

      const ucDataIOSE = {
        name: `UC ${group[0].identification_number} - IOSE`,
        displayName: 'IOSE', // Rótulo para legenda fixa
        type: 'bar',
        stack: group[0].identification_number.toString(),
        emphasis: {
          focus: 'series',
        },
        data: [],
      };

      const ucDataEstimated = {
        name: `UC ${group[0].identification_number} - ESTIMADA`,
        displayName: 'ESTIMADA', // Rótulo para legenda fixa
        type: 'bar',
        stack: group[0].identification_number.toString(),
        emphasis: {
          focus: 'series',
        },
        data: [],
      };

      for (const item of group) {
        const formattedDate = item.reference_month;
        if (item.bill_consumption !== undefined) {
          ucDataConta.data.push(item.bill_consumption);
        } else {
          ucDataConta.data.push(0);
        }
        if (item.measured_consumption !== undefined) {
          ucDataIOSE.data.push(item.measured_consumption);
        }
        if (item.bill_estimated !== undefined) {
          ucDataEstimated.data.push(item.bill_estimated);
        } else {
          ucDataIOSE.data.push(0);
        }
        yAxisData.add(formattedDate);
      }

      seriesData.push(ucDataConta);
      seriesData.push(ucDataIOSE);
      seriesData.push(ucDataEstimated);
    }

    const sortedYAxisData = Array.from(yAxisData).sort((a, b) => {
      const [monthA, yearA] = a.split('/');
      const [monthB, yearB] = b.split('/');
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    });

    setDates(sortedYAxisData);
    setSeries(seriesData);

    return { yAxisData: sortedYAxisData, seriesData };
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedValues = params.map((param) => {
          const numericValue =
            typeof param.value === 'string'
              ? parseFloat(param.value)
              : param.value;

          const formattedValue = numericValue.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue} (kWh)`;
        });

        return formattedValues.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: ['CONTA', 'IOSE', 'ESTIMADA'], // Rótulos fixos para legenda abaixo do gráfico
      icon: 'rect',
    },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '6%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: dates,
      },
    ],
  yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            if (Math.abs(value) >= 1e6) {
              // Se o valor for maior ou igual a 1 milhão, exibe em milhões (M)
              return (value / 1e6).toFixed(0) + 'M';
            } else {
              // Caso contrário, exibe em milhares (K)
              return (value / 1e3).toFixed(0) + 'K';
            }
          },
        },
      },
    ],
    series: series.map((item) => ({
      ...item,
      itemStyle: {
        color: item.name.includes('CONTA')
          ? '#015A9E'
          : item.name.includes('IOSE')
          ? '#67C6E3'
          : '#67e3c2',
      },
      name: item.displayName, // Usar o displayName como o nome da série
    })),
  };

  return <ReactECharts option={option} style={{ height: 260 }} />;
}
