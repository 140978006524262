// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useBeneficiaryPerMonth() {
  const [nogetBeneficiaryPerMonth, setNogetBeneficiaryPerMonth] = useState('');

  const { getSession } = useContext(AccountContext);

  const getBeneficiaryPerMonth = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllBeneficiaryPerMonth(headers, 3000, 3000);

    const { data } = response.data;

    const getBeneficiaryPerMonthData = data || [];

    if (getBeneficiaryPerMonthData.length === 0) {
      setNogetBeneficiaryPerMonth(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return getBeneficiaryPerMonthData;
  };

  const {
    data: getBeneficiaryPerMonthData,
    error: getBeneficiaryPerMonthDataError,
    refetch: refreshgetBeneficiaryPerMonth,
    isLoading,
  } = useQuery(['beneficiaryPerMonth'], getBeneficiaryPerMonth);

  return {
    getBeneficiaryPerMonthData,
    getBeneficiaryPerMonthDataError,
    nogetBeneficiaryPerMonth,
    refreshgetBeneficiaryPerMonth,
    isLoading,
  };
}

export default useBeneficiaryPerMonth;
