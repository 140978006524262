import styled from 'styled-components';

//Imports from Material Ui components
import {
  Avatar,
  Typography,
  CardHeader,
  CardContent,
  Card,
} from '@material-ui/core';

//Import image
import UnityIconImage from './img/Unity.svg';

export const CardStyled = styled(Card)`
  width: auto;
  height: ${(props) => (props.open ? 'auto' : '80px')};
  margin: 0px 15px 15px 0px;
  padding: 16px;
  border: solid 2px transparent;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer;

  &:hover {
    border: solid 2px var(--primary);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DivHeaderButton = styled.div`
  padding: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;

  span {
    white-space: pre-wrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-title {
    p {
      font-size: 13px !important;
      line-height: 14px;
      max-height: 28px;
      font-weight: 600;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      transition: all 0.3s ease-in-out;
    }
  }

  .MuiCardHeader-subheader {
    p {
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px !important;
    }
  }

  /* &:hover .MuiCardHeader-title {
    font-size: 13.5px;
    cursor: pointer;
  } */
`;

export const UnityIcon = styled.img.attrs((props) => ({
  src: UnityIconImage,
}))`
  width: 24px;
  height: 24px;
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--secondary);
`;

export const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px !important;
  /* height: 35px; */
`;

export const TypographyStyled = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  text-align: right;
  opacity: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0px;
  color: ${(props) =>
    props.red
      ? 'var(--alert) !important'
      : props.green
      ? 'var(--success) !important'
      : 'var(--goal) !important'};

  b {
    margin-left: 10px;
  }
  @media (max-width: 500px) {
    margin-top: 0px !important;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px;
`;
