import { useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useConsumerGroup } from 'hooks';
import { useMutation } from '@tanstack/react-query';

function useEditConsumerGroup() {
  const { getSession } = useContext(AccountContext);
  const { refreshConsumerGroups } = useConsumerGroup();

  const mutation = useMutation(
    async ({
      uuid_consumer_group,
      concessionaire,
      identification_number,
      contract,
      operating_unity,
      general_management,
      regional_management,
      coordinate_x,
      coordinate_y,
      address,
      city,
      carbon_credit,
      telemetry,
      meter,
      consumer_group_load,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      cogeracao,
      csbio,
      geradora,
      beneficiaria,
      potencia_transformador,
      subgrupo,
      modalidade,
      ativo,
      mercado_livre,
      inicio_vigencia,
      fim_vigencia,
      inicio_teste,
      fim_teste,
      constante,
      relogio_reset,
      demanda_fora,
      demanda_ponta,
      tensao,
      demanda,
      rtc,
      rtp,
      ke,
      pulso_watt,
      numero_telemetria,
      fornecedor_acl,
      boleto_id,
      empresa,
      un_negocio,
      departamento,
      localidade,
      conta_erp,
    }) => {
      const { headers } = await getSession();

      return ioseAPI.updateConsumerGroupV2(
        headers,
        uuid_consumer_group,
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        inicio_vigencia,
        fim_vigencia,
        inicio_teste,
        fim_teste,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao,
        demanda,
        rtc,
        rtp,
        ke,
        pulso_watt,
        numero_telemetria,
        fornecedor_acl,
        boleto_id,
        empresa,
        un_negocio,
        departamento,
        localidade,
        conta_erp
      );
    },
    {
      onSuccess: () => {
        refreshConsumerGroups();
      },
      onError: (error) => {
        console.error(Messages.errorEditConsumerGroup, error);
      },
    }
  );

  return mutation;
}

export default useEditConsumerGroup;
