import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';

import {
  IoseAddButton,
  IoseAuditRedirectButton,
  IoseMapRedirectButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSubHeaderBar,
  TextFieldStyled,
  IoseModalButton,
} from 'components';

import * as S from './style';

import { useClient } from 'common/contexts/client';
import LocalStorage from 'common/local_storage_api';
import { AccountContext, ioseAPI } from 'common';

export default function EditBillsMeasurerContainer() {
  const [uuidBill, setUuidBill] = useState([]);

  const [clientData, setClientData] = useState({});

  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const { client } = useClient();
  const { state } = useLocation();
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    const billData = state;

    setUuidBill(billData?.uuid_energy_bill);
  }, [state]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  function redirectToMapPage() {
    history.push('/map');
  }

  function redirectToPreviousPage() {
    history.goBack();
  }

  const getClientData = async () => {
    setError('');
    // setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle={`Editar dados da fatura - Medidor`}
        // description={'Total: ' + allContracts.length}
        button={showButton ? button : <></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseAuditRedirectButton
            tooltip="RETORNAR PARA PÁGINA DE FATURAS"
            bottom="5px"
            onClick={() => redirectToPreviousPage()}
          />

          {/* <IoseDownloadButton
            tooltip="BAIXAR FATURA"
            bottom="5px"
            onClick={() => handleDownload(downloadLink)}
          /> */}

          <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderContent = () => {
    const initialData = {};

    for (let i = 1; i <= 12; i++) {
      initialData[`measurer_${i}`] = state.measurer_data[`measurer_${i}`];
      initialData[`magnitude_${i}`] = state.measurer_data[`magnitude_${i}`];
      initialData[`timetables_${i}`] = state.measurer_data[`timetables_${i}`];
      initialData[`measurer_previous_reading_${i}`] =
        state.measurer_data[`measurer_previous_reading_${i}`];
      initialData[`measurer_current_reading_${i}`] =
        state.measurer_data[`measurer_current_reading_${i}`];
      initialData[`measurer_constant_${i}`] =
        state.measurer_data[`measurer_constant_${i}`];
      initialData[`kwh_consumption_${i}`] =
        state.measurer_data[`kwh_consumption_${i}`];
    }

    function redirectBackPage() {
      history.goBack();
    }

    async function updateBillMeasurer(
      uuid_energy_bill,
      measurer_1,
      measurer_2,
      measurer_3,
      measurer_4,
      measurer_5,
      measurer_6,
      measurer_7,
      measurer_8,
      measurer_9,
      measurer_10,
      measurer_11,
      measurer_12,
      magnitude_1,
      magnitude_2,
      magnitude_3,
      magnitude_4,
      magnitude_5,
      magnitude_6,
      magnitude_7,
      magnitude_8,
      magnitude_9,
      magnitude_10,
      magnitude_11,
      magnitude_12,
      timetables_1,
      timetables_2,
      timetables_3,
      timetables_4,
      timetables_5,
      timetables_6,
      timetables_7,
      timetables_8,
      timetables_9,
      timetables_10,
      timetables_11,
      timetables_12,
      measurer_previous_reading_1,
      measurer_previous_reading_2,
      measurer_previous_reading_3,
      measurer_previous_reading_4,
      measurer_previous_reading_5,
      measurer_previous_reading_6,
      measurer_previous_reading_7,
      measurer_previous_reading_8,
      measurer_previous_reading_9,
      measurer_previous_reading_10,
      measurer_previous_reading_11,
      measurer_previous_reading_12,
      measurer_current_reading_1,
      measurer_current_reading_2,
      measurer_current_reading_3,
      measurer_current_reading_4,
      measurer_current_reading_5,
      measurer_current_reading_6,
      measurer_current_reading_7,
      measurer_current_reading_8,
      measurer_current_reading_9,
      measurer_current_reading_10,
      measurer_current_reading_11,
      measurer_current_reading_12,
      measurer_constant_1,
      measurer_constant_2,
      measurer_constant_3,
      measurer_constant_4,
      measurer_constant_5,
      measurer_constant_6,
      measurer_constant_7,
      measurer_constant_8,
      measurer_constant_9,
      measurer_constant_10,
      measurer_constant_11,
      measurer_constant_12,
      kwh_consumption_1,
      kwh_consumption_2,
      kwh_consumption_3,
      kwh_consumption_4,
      kwh_consumption_5,
      kwh_consumption_6,
      kwh_consumption_7,
      kwh_consumption_8,
      kwh_consumption_9,
      kwh_consumption_10,
      kwh_consumption_11,
      kwh_consumption_12
    ) {
      // setEditNotificationType(0);
      // setLoading(true);

      try {
        const sessionData = await getSession();

        await ioseAPI.updateEnergyBillMeasurer(
          sessionData.headers,
          uuid_energy_bill,
          measurer_1,
          measurer_2,
          measurer_3,
          measurer_4,
          measurer_5,
          measurer_6,
          measurer_7,
          measurer_8,
          measurer_9,
          measurer_10,
          measurer_11,
          measurer_12,
          magnitude_1,
          magnitude_2,
          magnitude_3,
          magnitude_4,
          magnitude_5,
          magnitude_6,
          magnitude_7,
          magnitude_8,
          magnitude_9,
          magnitude_10,
          magnitude_11,
          magnitude_12,
          timetables_1,
          timetables_2,
          timetables_3,
          timetables_4,
          timetables_5,
          timetables_6,
          timetables_7,
          timetables_8,
          timetables_9,
          timetables_10,
          timetables_11,
          timetables_12,
          measurer_previous_reading_1,
          measurer_previous_reading_2,
          measurer_previous_reading_3,
          measurer_previous_reading_4,
          measurer_previous_reading_5,
          measurer_previous_reading_6,
          measurer_previous_reading_7,
          measurer_previous_reading_8,
          measurer_previous_reading_9,
          measurer_previous_reading_10,
          measurer_previous_reading_11,
          measurer_previous_reading_12,
          measurer_current_reading_1,
          measurer_current_reading_2,
          measurer_current_reading_3,
          measurer_current_reading_4,
          measurer_current_reading_5,
          measurer_current_reading_6,
          measurer_current_reading_7,
          measurer_current_reading_8,
          measurer_current_reading_9,
          measurer_current_reading_10,
          measurer_current_reading_11,
          measurer_current_reading_12,
          measurer_constant_1,
          measurer_constant_2,
          measurer_constant_3,
          measurer_constant_4,
          measurer_constant_5,
          measurer_constant_6,
          measurer_constant_7,
          measurer_constant_8,
          measurer_constant_9,
          measurer_constant_10,
          measurer_constant_11,
          measurer_constant_12,
          kwh_consumption_1,
          kwh_consumption_2,
          kwh_consumption_3,
          kwh_consumption_4,
          kwh_consumption_5,
          kwh_consumption_6,
          kwh_consumption_7,
          kwh_consumption_8,
          kwh_consumption_9,
          kwh_consumption_10,
          kwh_consumption_11,
          kwh_consumption_12
        );

        redirectBackPage();
      } catch (err) {
        console.error(err);
      }
    }

    const handleUpdate = (values) => {
      const {
        measurer_1,
        measurer_2,
        measurer_3,
        measurer_4,
        measurer_5,
        measurer_6,
        measurer_7,
        measurer_8,
        measurer_9,
        measurer_10,
        measurer_11,
        measurer_12,
        magnitude_1,
        magnitude_2,
        magnitude_3,
        magnitude_4,
        magnitude_5,
        magnitude_6,
        magnitude_7,
        magnitude_8,
        magnitude_9,
        magnitude_10,
        magnitude_11,
        magnitude_12,
        timetables_1,
        timetables_2,
        timetables_3,
        timetables_4,
        timetables_5,
        timetables_6,
        timetables_7,
        timetables_8,
        timetables_9,
        timetables_10,
        timetables_11,
        timetables_12,
        measurer_previous_reading_1,
        measurer_previous_reading_2,
        measurer_previous_reading_3,
        measurer_previous_reading_4,
        measurer_previous_reading_5,
        measurer_previous_reading_6,
        measurer_previous_reading_7,
        measurer_previous_reading_8,
        measurer_previous_reading_9,
        measurer_previous_reading_10,
        measurer_previous_reading_11,
        measurer_previous_reading_12,
        measurer_current_reading_1,
        measurer_current_reading_2,
        measurer_current_reading_3,
        measurer_current_reading_4,
        measurer_current_reading_5,
        measurer_current_reading_6,
        measurer_current_reading_7,
        measurer_current_reading_8,
        measurer_current_reading_9,
        measurer_current_reading_10,
        measurer_current_reading_11,
        measurer_current_reading_12,
        measurer_constant_1,
        measurer_constant_2,
        measurer_constant_3,
        measurer_constant_4,
        measurer_constant_5,
        measurer_constant_6,
        measurer_constant_7,
        measurer_constant_8,
        measurer_constant_9,
        measurer_constant_10,
        measurer_constant_11,
        measurer_constant_12,
        kwh_consumption_1,
        kwh_consumption_2,
        kwh_consumption_3,
        kwh_consumption_4,
        kwh_consumption_5,
        kwh_consumption_6,
        kwh_consumption_7,
        kwh_consumption_8,
        kwh_consumption_9,
        kwh_consumption_10,
        kwh_consumption_11,
        kwh_consumption_12,
      } = values;

      function converterStringParaNumero(valor) {
        // Verifique se o valor já é um número
        if (!isNaN(valor)) {
          return parseFloat(valor); // Se for um número, apenas retorne o valor convertido
        }

        // Substitua vírgulas por pontos
        valor = valor.replace(',', '.');

        // Use parseFloat() para converter a string em número
        return parseFloat(valor);
      }

      const f_measurer_previous_reading_1 = converterStringParaNumero(
        measurer_previous_reading_1
      );

      updateBillMeasurer(
        uuidBill,
        measurer_1,
        measurer_2,
        measurer_3,
        measurer_4,
        measurer_5,
        measurer_6,
        measurer_7,
        measurer_8,
        measurer_9,
        measurer_10,
        measurer_11,
        measurer_12,
        magnitude_1,
        magnitude_2,
        magnitude_3,
        magnitude_4,
        magnitude_5,
        magnitude_6,
        magnitude_7,
        magnitude_8,
        magnitude_9,
        magnitude_10,
        magnitude_11,
        magnitude_12,
        timetables_1,
        timetables_2,
        timetables_3,
        timetables_4,
        timetables_5,
        timetables_6,
        timetables_7,
        timetables_8,
        timetables_9,
        timetables_10,
        timetables_11,
        timetables_12,
        f_measurer_previous_reading_1,
        measurer_previous_reading_2,
        measurer_previous_reading_3,
        measurer_previous_reading_4,
        measurer_previous_reading_5,
        measurer_previous_reading_6,
        measurer_previous_reading_7,
        measurer_previous_reading_8,
        measurer_previous_reading_9,
        measurer_previous_reading_10,
        measurer_previous_reading_11,
        measurer_previous_reading_12,
        measurer_current_reading_1,
        measurer_current_reading_2,
        measurer_current_reading_3,
        measurer_current_reading_4,
        measurer_current_reading_5,
        measurer_current_reading_6,
        measurer_current_reading_7,
        measurer_current_reading_8,
        measurer_current_reading_9,
        measurer_current_reading_10,
        measurer_current_reading_11,
        measurer_current_reading_12,
        measurer_constant_1,
        measurer_constant_2,
        measurer_constant_3,
        measurer_constant_4,
        measurer_constant_5,
        measurer_constant_6,
        measurer_constant_7,
        measurer_constant_8,
        measurer_constant_9,
        measurer_constant_10,
        measurer_constant_11,
        measurer_constant_12,
        kwh_consumption_1,
        kwh_consumption_2,
        kwh_consumption_3,
        kwh_consumption_4,
        kwh_consumption_5,
        kwh_consumption_6,
        kwh_consumption_7,
        kwh_consumption_8,
        kwh_consumption_9,
        kwh_consumption_10,
        kwh_consumption_11,
        kwh_consumption_12
      );
    };

    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.Container>
          <div style={{ width: '96vw' }}>
            <div style={{ gap: '10px' }}>
              <S.Header>
                <S.HeaderItem>
                  <S.TypographyTitle>
                    EDITAR DADOS DA ÁREA DO MEDIDOR DA FATURA
                  </S.TypographyTitle>
                </S.HeaderItem>
              </S.Header>

              <S.StyledWrappedModal>
                <Formik initialValues={initialData} onSubmit={handleUpdate}>
                  {({
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    errors,
                    touched,
                    initialValues,
                  }) => {
                    return (
                      <Form>
                        <S.FormConsumerGroupContainer>
                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_1'}
                                name={'measurer_1'}
                                type={'text'}
                                label={'measurer_1'}
                                defaultvalue={initialValues['measurer_1']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_1'] && touched['measurer_1']
                                    ? errors['measurer_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_2'}
                                name={'measurer_2'}
                                type={'text'}
                                label={'measurer_2'}
                                defaultvalue={initialValues['measurer_2']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_2'] && touched['measurer_2']
                                    ? errors['measurer_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_3'}
                                name={'measurer_3'}
                                type={'text'}
                                label={'measurer_3'}
                                defaultvalue={initialValues['measurer_3']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_3'] && touched['measurer_3']
                                    ? errors['measurer_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_4'}
                                name={'measurer_4'}
                                type={'text'}
                                label={'measurer_4'}
                                defaultvalue={initialValues['measurer_4']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_4'] && touched['measurer_4']
                                    ? errors['measurer_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_5'}
                                name={'measurer_5'}
                                type={'text'}
                                label={'measurer_5'}
                                defaultvalue={initialValues['measurer_5']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_5'] && touched['measurer_5']
                                    ? errors['measurer_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_6'}
                                name={'measurer_6'}
                                type={'text'}
                                label={'measurer_6'}
                                defaultvalue={initialValues['measurer_6']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_6'] && touched['measurer_6']
                                    ? errors['measurer_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_7'}
                                name={'measurer_7'}
                                type={'text'}
                                label={'measurer_7'}
                                defaultvalue={initialValues['measurer_7']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_7'] && touched['measurer_7']
                                    ? errors['measurer_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_8'}
                                name={'measurer_8'}
                                type={'text'}
                                label={'measurer_8'}
                                defaultvalue={initialValues['measurer_8']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_8'] && touched['measurer_8']
                                    ? errors['measurer_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_9'}
                                name={'measurer_9'}
                                type={'text'}
                                label={'measurer_9'}
                                defaultvalue={initialValues['measurer_9']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_9'] && touched['measurer_9']
                                    ? errors['measurer_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_10'}
                                name={'measurer_10'}
                                type={'text'}
                                label={'measurer_10'}
                                defaultvalue={initialValues['measurer_10']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_10'] &&
                                  touched['measurer_10']
                                    ? errors['measurer_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_11'}
                                name={'measurer_11'}
                                type={'text'}
                                label={'measurer_11'}
                                defaultvalue={initialValues['measurer_11']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_11'] &&
                                  touched['measurer_11']
                                    ? errors['measurer_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_12'}
                                name={'measurer_12'}
                                type={'text'}
                                label={'measurer_12'}
                                defaultvalue={initialValues['measurer_12']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_12'] &&
                                  touched['measurer_12']
                                    ? errors['measurer_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_1'}
                                name={'magnitude_1'}
                                type={'text'}
                                label={'magnitude_1'}
                                defaultvalue={initialValues['magnitude_1']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_1'] &&
                                  touched['magnitude_1']
                                    ? errors['magnitude_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_2'}
                                name={'magnitude_2'}
                                type={'text'}
                                label={'magnitude_2'}
                                defaultvalue={initialValues['magnitude_2']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_2'] &&
                                  touched['magnitude_2']
                                    ? errors['magnitude_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_3'}
                                name={'magnitude_3'}
                                type={'text'}
                                label={'magnitude_3'}
                                defaultvalue={initialValues['magnitude_3']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_3'] &&
                                  touched['magnitude_3']
                                    ? errors['magnitude_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_4'}
                                name={'magnitude_4'}
                                type={'text'}
                                label={'magnitude_4'}
                                defaultvalue={initialValues['magnitude_4']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_4'] &&
                                  touched['magnitude_4']
                                    ? errors['magnitude_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_5'}
                                name={'magnitude_5'}
                                type={'text'}
                                label={'magnitude_5'}
                                defaultvalue={initialValues['magnitude_5']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_5'] &&
                                  touched['magnitude_5']
                                    ? errors['magnitude_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_6'}
                                name={'magnitude_6'}
                                type={'text'}
                                label={'magnitude_6'}
                                defaultvalue={initialValues['magnitude_6']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_6'] &&
                                  touched['magnitude_6']
                                    ? errors['magnitude_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_7'}
                                name={'magnitude_7'}
                                type={'text'}
                                label={'magnitude_7'}
                                defaultvalue={initialValues['magnitude_7']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_7'] &&
                                  touched['magnitude_7']
                                    ? errors['magnitude_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_8'}
                                name={'magnitude_8'}
                                type={'text'}
                                label={'magnitude_8'}
                                defaultvalue={initialValues['magnitude_8']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_8'] &&
                                  touched['magnitude_8']
                                    ? errors['magnitude_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_9'}
                                name={'magnitude_9'}
                                type={'text'}
                                label={'magnitude_9'}
                                defaultvalue={initialValues['magnitude_9']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_9'] &&
                                  touched['magnitude_9']
                                    ? errors['magnitude_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_10'}
                                name={'magnitude_10'}
                                type={'text'}
                                label={'magnitude_10'}
                                defaultvalue={initialValues['magnitude_10']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_10'] &&
                                  touched['magnitude_10']
                                    ? errors['magnitude_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_11'}
                                name={'magnitude_11'}
                                type={'text'}
                                label={'magnitude_11'}
                                defaultvalue={initialValues['magnitude_11']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_11'] &&
                                  touched['magnitude_11']
                                    ? errors['magnitude_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'magnitude_12'}
                                name={'magnitude_12'}
                                type={'text'}
                                label={'magnitude_12'}
                                defaultvalue={initialValues['magnitude_12']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['magnitude_12'] &&
                                  touched['magnitude_12']
                                    ? errors['magnitude_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_1'}
                                name={'timetables_1'}
                                type={'text'}
                                label={'timetables_1'}
                                defaultvalue={initialValues['timetables_1']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_1'] &&
                                  touched['timetables_1']
                                    ? errors['timetables_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_2'}
                                name={'timetables_2'}
                                type={'text'}
                                label={'timetables_2'}
                                defaultvalue={initialValues['timetables_2']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_2'] &&
                                  touched['timetables_2']
                                    ? errors['timetables_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_3'}
                                name={'timetables_3'}
                                type={'text'}
                                label={'timetables_3'}
                                defaultvalue={initialValues['timetables_3']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_3'] &&
                                  touched['timetables_3']
                                    ? errors['timetables_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_4'}
                                name={'timetables_4'}
                                type={'text'}
                                label={'timetables_4'}
                                defaultvalue={initialValues['timetables_4']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_4'] &&
                                  touched['timetables_4']
                                    ? errors['timetables_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_5'}
                                name={'timetables_5'}
                                type={'text'}
                                label={'timetables_5'}
                                defaultvalue={initialValues['timetables_5']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_5'] &&
                                  touched['timetables_5']
                                    ? errors['timetables_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_6'}
                                name={'timetables_6'}
                                type={'text'}
                                label={'timetables_6'}
                                defaultvalue={initialValues['timetables_6']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_6'] &&
                                  touched['timetables_6']
                                    ? errors['timetables_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_7'}
                                name={'timetables_7'}
                                type={'text'}
                                label={'timetables_7'}
                                defaultvalue={initialValues['timetables_7']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_7'] &&
                                  touched['timetables_7']
                                    ? errors['timetables_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_8'}
                                name={'timetables_8'}
                                type={'text'}
                                label={'timetables_8'}
                                defaultvalue={initialValues['timetables_8']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_8'] &&
                                  touched['timetables_8']
                                    ? errors['timetables_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_9'}
                                name={'timetables_9'}
                                type={'text'}
                                label={'timetables_9'}
                                defaultvalue={initialValues['timetables_9']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_9'] &&
                                  touched['timetables_9']
                                    ? errors['timetables_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_10'}
                                name={'timetables_10'}
                                type={'text'}
                                label={'timetables_10'}
                                defaultvalue={initialValues['timetables_10']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_10'] &&
                                  touched['timetables_10']
                                    ? errors['timetables_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_11'}
                                name={'timetables_11'}
                                type={'text'}
                                label={'timetables_11'}
                                defaultvalue={initialValues['timetables_11']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_11'] &&
                                  touched['timetables_11']
                                    ? errors['timetables_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'timetables_12'}
                                name={'timetables_12'}
                                type={'text'}
                                label={'timetables_12'}
                                defaultvalue={initialValues['timetables_12']}
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['timetables_12'] &&
                                  touched['timetables_12']
                                    ? errors['timetables_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_1'}
                                name={'measurer_previous_reading_1'}
                                type={'text'}
                                label={'measurer_previous_reading_1'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_1']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_1'] &&
                                  touched['measurer_previous_reading_1']
                                    ? errors['measurer_previous_reading_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_2'}
                                name={'measurer_previous_reading_2'}
                                type={'text'}
                                label={'measurer_previous_reading_2'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_2']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_2'] &&
                                  touched['measurer_previous_reading_2']
                                    ? errors['measurer_previous_reading_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_3'}
                                name={'measurer_previous_reading_3'}
                                type={'text'}
                                label={'measurer_previous_reading_3'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_3']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_3'] &&
                                  touched['measurer_previous_reading_3']
                                    ? errors['measurer_previous_reading_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_4'}
                                name={'measurer_previous_reading_4'}
                                type={'text'}
                                label={'measurer_previous_reading_4'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_4']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_4'] &&
                                  touched['measurer_previous_reading_4']
                                    ? errors['measurer_previous_reading_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_5'}
                                name={'measurer_previous_reading_5'}
                                type={'text'}
                                label={'measurer_previous_reading_5'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_5']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_5'] &&
                                  touched['measurer_previous_reading_5']
                                    ? errors['measurer_previous_reading_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_6'}
                                name={'measurer_previous_reading_6'}
                                type={'text'}
                                label={'measurer_previous_reading_6'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_6']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_6'] &&
                                  touched['measurer_previous_reading_6']
                                    ? errors['measurer_previous_reading_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_7'}
                                name={'measurer_previous_reading_7'}
                                type={'text'}
                                label={'measurer_previous_reading_7'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_7']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_7'] &&
                                  touched['measurer_previous_reading_7']
                                    ? errors['measurer_previous_reading_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_8'}
                                name={'measurer_previous_reading_8'}
                                type={'text'}
                                label={'measurer_previous_reading_8'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_8']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_8'] &&
                                  touched['measurer_previous_reading_8']
                                    ? errors['measurer_previous_reading_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_9'}
                                name={'measurer_previous_reading_9'}
                                type={'text'}
                                label={'measurer_previous_reading_9'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_9']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_9'] &&
                                  touched['measurer_previous_reading_9']
                                    ? errors['measurer_previous_reading_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_10'}
                                name={'measurer_previous_reading_10'}
                                type={'text'}
                                label={'measurer_previous_reading_10'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_10']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_10'] &&
                                  touched['measurer_previous_reading_10']
                                    ? errors['measurer_previous_reading_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_11'}
                                name={'measurer_previous_reading_11'}
                                type={'text'}
                                label={'measurer_previous_reading_11'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_11']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_11'] &&
                                  touched['measurer_previous_reading_11']
                                    ? errors['measurer_previous_reading_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_previous_reading_12'}
                                name={'measurer_previous_reading_12'}
                                type={'text'}
                                label={'measurer_previous_reading_12'}
                                defaultvalue={
                                  initialValues['measurer_previous_reading_12']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_previous_reading_12'] &&
                                  touched['measurer_previous_reading_12']
                                    ? errors['measurer_previous_reading_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_1'}
                                name={'measurer_current_reading_1'}
                                type={'text'}
                                label={'measurer_current_reading_1'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_1']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_1'] &&
                                  touched['measurer_current_reading_1']
                                    ? errors['measurer_current_reading_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_2'}
                                name={'measurer_current_reading_2'}
                                type={'text'}
                                label={'measurer_current_reading_2'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_2']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_2'] &&
                                  touched['measurer_current_reading_2']
                                    ? errors['measurer_current_reading_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_3'}
                                name={'measurer_current_reading_3'}
                                type={'text'}
                                label={'measurer_current_reading_3'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_3']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_3'] &&
                                  touched['measurer_current_reading_3']
                                    ? errors['measurer_current_reading_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_4'}
                                name={'measurer_current_reading_4'}
                                type={'text'}
                                label={'measurer_current_reading_4'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_4']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_4'] &&
                                  touched['measurer_current_reading_4']
                                    ? errors['measurer_current_reading_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_5'}
                                name={'measurer_current_reading_5'}
                                type={'text'}
                                label={'measurer_current_reading_5'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_5']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_5'] &&
                                  touched['measurer_current_reading_5']
                                    ? errors['measurer_current_reading_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_6'}
                                name={'measurer_current_reading_6'}
                                type={'text'}
                                label={'measurer_current_reading_6'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_6']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_6'] &&
                                  touched['measurer_current_reading_6']
                                    ? errors['measurer_current_reading_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_7'}
                                name={'measurer_current_reading_7'}
                                type={'text'}
                                label={'measurer_current_reading_7'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_7']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_7'] &&
                                  touched['measurer_current_reading_7']
                                    ? errors['measurer_current_reading_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_8'}
                                name={'measurer_current_reading_8'}
                                type={'text'}
                                label={'measurer_current_reading_8'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_8']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_8'] &&
                                  touched['measurer_current_reading_8']
                                    ? errors['measurer_current_reading_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_9'}
                                name={'measurer_current_reading_9'}
                                type={'text'}
                                label={'measurer_current_reading_9'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_9']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_9'] &&
                                  touched['measurer_current_reading_9']
                                    ? errors['measurer_current_reading_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_10'}
                                name={'measurer_current_reading_10'}
                                type={'text'}
                                label={'measurer_current_reading_10'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_10']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_10'] &&
                                  touched['measurer_current_reading_10']
                                    ? errors['measurer_current_reading_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_11'}
                                name={'measurer_current_reading_11'}
                                type={'text'}
                                label={'measurer_current_reading_11'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_11']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_11'] &&
                                  touched['measurer_current_reading_11']
                                    ? errors['measurer_current_reading_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_current_reading_12'}
                                name={'measurer_current_reading_12'}
                                type={'text'}
                                label={'measurer_current_reading_12'}
                                defaultvalue={
                                  initialValues['measurer_current_reading_12']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_current_reading_12'] &&
                                  touched['measurer_current_reading_12']
                                    ? errors['measurer_current_reading_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_1'}
                                name={'measurer_constant_1'}
                                type={'text'}
                                label={'measurer_constant_1'}
                                defaultvalue={
                                  initialValues['measurer_constant_1']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_1'] &&
                                  touched['measurer_constant_1']
                                    ? errors['measurer_constant_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_2'}
                                name={'measurer_constant_2'}
                                type={'text'}
                                label={'measurer_constant_2'}
                                defaultvalue={
                                  initialValues['measurer_constant_2']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_2'] &&
                                  touched['measurer_constant_2']
                                    ? errors['measurer_constant_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_3'}
                                name={'measurer_constant_3'}
                                type={'text'}
                                label={'measurer_constant_3'}
                                defaultvalue={
                                  initialValues['measurer_constant_3']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_3'] &&
                                  touched['measurer_constant_3']
                                    ? errors['measurer_constant_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_4'}
                                name={'measurer_constant_4'}
                                type={'text'}
                                label={'measurer_constant_4'}
                                defaultvalue={
                                  initialValues['measurer_constant_4']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_4'] &&
                                  touched['measurer_constant_4']
                                    ? errors['measurer_constant_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_5'}
                                name={'measurer_constant_5'}
                                type={'text'}
                                label={'measurer_constant_5'}
                                defaultvalue={
                                  initialValues['measurer_constant_5']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_5'] &&
                                  touched['measurer_constant_5']
                                    ? errors['measurer_constant_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_6'}
                                name={'measurer_constant_6'}
                                type={'text'}
                                label={'measurer_constant_6'}
                                defaultvalue={
                                  initialValues['measurer_constant_6']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_6'] &&
                                  touched['measurer_constant_6']
                                    ? errors['measurer_constant_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_7'}
                                name={'measurer_constant_7'}
                                type={'text'}
                                label={'measurer_constant_7'}
                                defaultvalue={
                                  initialValues['measurer_constant_7']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_7'] &&
                                  touched['measurer_constant_7']
                                    ? errors['measurer_constant_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_8'}
                                name={'measurer_constant_8'}
                                type={'text'}
                                label={'measurer_constant_8'}
                                defaultvalue={
                                  initialValues['measurer_constant_8']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_8'] &&
                                  touched['measurer_constant_8']
                                    ? errors['measurer_constant_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_9'}
                                name={'measurer_constant_9'}
                                type={'text'}
                                label={'measurer_constant_9'}
                                defaultvalue={
                                  initialValues['measurer_constant_9']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_9'] &&
                                  touched['measurer_constant_9']
                                    ? errors['measurer_constant_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_10'}
                                name={'measurer_constant_10'}
                                type={'text'}
                                label={'measurer_constant_10'}
                                defaultvalue={
                                  initialValues['measurer_constant_10']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_10'] &&
                                  touched['measurer_constant_10']
                                    ? errors['measurer_constant_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_11'}
                                name={'measurer_constant_11'}
                                type={'text'}
                                label={'measurer_constant_11'}
                                defaultvalue={
                                  initialValues['measurer_constant_11']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_11'] &&
                                  touched['measurer_constant_11']
                                    ? errors['measurer_constant_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'measurer_constant_12'}
                                name={'measurer_constant_12'}
                                type={'text'}
                                label={'measurer_constant_12'}
                                defaultvalue={
                                  initialValues['measurer_constant_12']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['measurer_constant_12'] &&
                                  touched['measurer_constant_12']
                                    ? errors['measurer_constant_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>

                          <S.WrappedConsumerGroupItens>
                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_1'}
                                name={'kwh_consumption_1'}
                                type={'text'}
                                label={'kwh_consumption_1'}
                                defaultvalue={
                                  initialValues['kwh_consumption_1']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_1'] &&
                                  touched['kwh_consumption_1']
                                    ? errors['kwh_consumption_1']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_2'}
                                name={'kwh_consumption_2'}
                                type={'text'}
                                label={'kwh_consumption_2'}
                                defaultvalue={
                                  initialValues['kwh_consumption_2']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_2'] &&
                                  touched['kwh_consumption_2']
                                    ? errors['kwh_consumption_2']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_3'}
                                name={'kwh_consumption_3'}
                                type={'text'}
                                label={'kwh_consumption_3'}
                                defaultvalue={
                                  initialValues['kwh_consumption_3']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_3'] &&
                                  touched['kwh_consumption_3']
                                    ? errors['kwh_consumption_3']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_4'}
                                name={'kwh_consumption_4'}
                                type={'text'}
                                label={'kwh_consumption_4'}
                                defaultvalue={
                                  initialValues['kwh_consumption_4']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_4'] &&
                                  touched['kwh_consumption_4']
                                    ? errors['kwh_consumption_4']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_5'}
                                name={'kwh_consumption_5'}
                                type={'text'}
                                label={'kwh_consumption_5'}
                                defaultvalue={
                                  initialValues['kwh_consumption_5']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_5'] &&
                                  touched['kwh_consumption_5']
                                    ? errors['kwh_consumption_5']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_6'}
                                name={'kwh_consumption_6'}
                                type={'text'}
                                label={'kwh_consumption_6'}
                                defaultvalue={
                                  initialValues['kwh_consumption_6']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_6'] &&
                                  touched['kwh_consumption_6']
                                    ? errors['kwh_consumption_6']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_7'}
                                name={'kwh_consumption_7'}
                                type={'text'}
                                label={'kwh_consumption_7'}
                                defaultvalue={
                                  initialValues['kwh_consumption_7']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_7'] &&
                                  touched['kwh_consumption_7']
                                    ? errors['kwh_consumption_7']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_8'}
                                name={'kwh_consumption_8'}
                                type={'text'}
                                label={'kwh_consumption_8'}
                                defaultvalue={
                                  initialValues['kwh_consumption_8']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_8'] &&
                                  touched['kwh_consumption_8']
                                    ? errors['kwh_consumption_8']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_9'}
                                name={'kwh_consumption_9'}
                                type={'text'}
                                label={'kwh_consumption_9'}
                                defaultvalue={
                                  initialValues['kwh_consumption_9']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_9'] &&
                                  touched['kwh_consumption_9']
                                    ? errors['kwh_consumption_9']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_10'}
                                name={'kwh_consumption_10'}
                                type={'text'}
                                label={'kwh_consumption_10'}
                                defaultvalue={
                                  initialValues['kwh_consumption_10']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_10'] &&
                                  touched['kwh_consumption_10']
                                    ? errors['kwh_consumption_10']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_11'}
                                name={'kwh_consumption_11'}
                                type={'text'}
                                label={'kwh_consumption_11'}
                                defaultvalue={
                                  initialValues['kwh_consumption_11']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_11'] &&
                                  touched['kwh_consumption_11']
                                    ? errors['kwh_consumption_11']
                                    : ''
                                }
                              />
                            </S.DivInputs>

                            <S.DivInputs marginBottom="5px">
                              <S.StyledField
                                component={TextFieldStyled}
                                fullWidth
                                id={'kwh_consumption_12'}
                                name={'kwh_consumption_12'}
                                type={'text'}
                                label={'kwh_consumption_12'}
                                defaultvalue={
                                  initialValues['kwh_consumption_12']
                                }
                                onchange={handleChange}
                                onblur={handleBlur}
                                helpertext={
                                  errors['kwh_consumption_12'] &&
                                  touched['kwh_consumption_12']
                                    ? errors['kwh_consumption_12']
                                    : ''
                                }
                              />
                            </S.DivInputs>
                          </S.WrappedConsumerGroupItens>
                        </S.FormConsumerGroupContainer>

                        <S.ButtonContainer>
                          <IoseModalButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting}
                          >
                            Editar Fatura
                          </IoseModalButton>
                        </S.ButtonContainer>
                      </Form>
                    );
                  }}
                </Formik>
              </S.StyledWrappedModal>
            </div>
          </div>
        </S.Container>
      );
    }
  };

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>{renderContent()}</>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

EditBillsMeasurerContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
