import React from 'react';
import ReactECharts from 'echarts-for-react';
import { IoseNoDataMessage } from 'components';

export default function GraficoGeracaoTempo({ geracao }) {
  const data = [];
  const gerado = [];
  const weather = [];
  const reativo = [];
  const temp = [];

  let contador = 0;
  geracao.forEach((obj) => {
    gerado.push(obj.export_active_energy);
    reativo.push(obj.export_reactive_energy);
    if (obj.data === '0:00' && contador === 0) {
      data.push(obj.data);
      contador += 1;
    } else if (obj.data !== '0:00') {
      data.push(obj.data);
    }

    weather.push(
      `https://openweathermap.org/img/wn/${obj.weather_icon}@2x.png`
    );
    temp.push(obj.main_temp - 273.15);
  });

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '15%',
      top: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params?.map((param) => {
          const formattedValue =
            typeof param?.value === 'string'
              ? parseFloat(param?.value).toLocaleString('pt-BR')
              : param?.value.toLocaleString('pt-BR');

          return `<strong>${param?.seriesName}</strong>: ${formattedValue}`;
        });

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: ['Geração (kWh)', 'Temperatura (C°)', 'Reativo (VArh)'],
      selectedMode: false,
    },
    xAxis: {
      type: 'category',
      data: data,
    },
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        name: 'Geração (kWh)',
        data: gerado,
        type: 'bar',
        symbolSize: 40,
        lineStyle: {
          color: '#5470C6',
          width: 4,
        },
        // markPoint: {
        //   data: weather.map((icon, index) => ({
        //     coord: [data[index], gerado[index]],
        //     symbol: `image://${icon}`,
        //     symbolSize: [40, 40],label: {
        //       show: false // Oculta o rótulo do markPoint
        //     },
        //     symbolOffset: [0, -50],
        //     itemStyle: {
        //       color: 'transparent' // Torna o fundo do markPoint transparente
        //     }
        //   })),
        // }
      },
      {
        name: 'Reativo (VArh)',
        type: 'line',
        data: reativo,
        itemStyle: {
          color: '#a90000',
        },
        showSymbol: false,
      },
    ],
  };

  return geracao?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 380, width: '100%' }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}
