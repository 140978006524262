import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from '../ConsumerGroups/img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

const handleAlarmStatus = (type) => {
  switch (type) {
    case 1:
      return 'Queda de energia';
    case 2:
      return 'Retorno de energia';
    case 3:
      return 'Fim intervalo 15 min.';
    case 4:
      return 'Sem medição';
    case 5:
      return 'Horário fora de ponta';
    case 6:
      return 'Horário de ponta';
    case 7:
      return 'Horário capacitivo';
    case 28:
      return 'Abertura da porta';
    case 29:
      return 'Fechamento da porta';
    case 56:
      return 'Reset pelo usuário';
    case 102:
      return 'Fim intervalo timeou';
      case 200:
      return 'Ultrapassagem de demanda';
    case 201:
      return 'Excesso de Reativos';
    default:
      return 'Evento não suportado';
  }
};

export default async function TelemetryAlarmsReport(data) {

  // const formatDate = (dateString) => {
  //   return new Date(dateString).toLocaleString('pt-BR').replace(',', '');
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };


  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Alarmes de Telemetria do Circuito ${data[0].uuid_circuit}`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('Circuito'),
    generateHeaderCell('Alarme'),
    generateHeaderCell('Data da Criação'),
  ];
  const renderData = data?.map((item) => [
    generateCell(item.uuid_circuit),
    generateCell(handleAlarmStatus(item?.cod_evento).toLocaleUpperCase()),
    generateCell(formatDate(item?.dat_evento)),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [
    ...details,
    // summaryTable,
  ];

  const docDefinitions = {
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    pageSize: 'A4',
    pageOrientation: 'landscape',
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
