import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useCreateLogs } from 'hooks';

const useAddConsumerGroup = () => {
  const [addNotification, setAddNotification] = useState(
    'Erro ao criar Unidade Consumidora'
  );
  const [addErrorMessage, setAddErrorMessage] = useState('');

  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const addConsumerGroup = async (
    concessionaire,
    identification_number,
    contract,
    operating_unity,
    general_management,
    regional_management,
    coordinate_x,
    coordinate_y,
    address,
    city,
    carbon_credit,
    telemetry,
    meter,
    consumer_group_load,
    atividade,
    cod_local,
    cod_unidade,
    descricao_unidade,
    cod_orgao_pagador,
    cogeracao,
    csbio,
    geradora,
    beneficiaria,
    potencia_transformador,
    subgrupo,
    modalidade,
    ativo,
    mercado_livre,
    inicio_vigencia,
    fim_vigencia,
    inicio_teste,
    fim_teste,
    constante,
    relogio_reset,
    demanda_fora,
    demanda_ponta,
    tensao,
    demanda,
    rtc,
    rtp,
    ke,
    pulso_watt,
    numero_telemetria,
    fornecedor_acl,
    boleto_id,
    empresa,
    un_negocio,
    departamento,
    localidade,
    conta_erp
  ) => {
    try {
      const { headers } = await getSession();

      const response = await ioseAPI.createConsumerGroupV2(
        headers,
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        inicio_vigencia,
        fim_vigencia,
        inicio_teste,
        fim_teste,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao,
        demanda,
        rtc,
        rtp,
        ke,
        pulso_watt,
        numero_telemetria,
        fornecedor_acl,
        boleto_id,
        empresa,
        un_negocio,
        departamento,
        localidade,
        conta_erp
      );

      setAddNotification(response.data.message);

      const operacao = 'CRIAR';
      const form = 'CRIAR UNIDADE CONSUMIDORA';
      const logContent = {
        concessionaire: concessionaire,
        identification_number: identification_number,
        contract: contract,
        operating_unity: operating_unity,
        general_management: general_management,
        regional_management: regional_management,
        coordinate_x: coordinate_x,
        coordinate_y: coordinate_y,
        address: address,
        city: city,
        carbon_credit: carbon_credit,
        telemetry: telemetry,
        meter: meter,
        consumer_group_load: consumer_group_load,
        atividade: atividade,
        cod_local: cod_local,
        cod_unidade: cod_unidade,
        descricao_unidade: descricao_unidade,
        cod_orgao_pagador: cod_orgao_pagador,
        cogeracao: cogeracao,
        csbio: csbio,
        geradora: geradora,
        beneficiaria: beneficiaria,
        potencia_transformador: potencia_transformador,
        subgrupo: subgrupo,
        modalidade: modalidade,
        ativo: ativo,
        mercado_livre: mercado_livre,
        inicio_vigencia: inicio_vigencia,
        fim_vigencia: fim_vigencia,
        inicio_teste: inicio_teste,
        fim_teste: fim_teste,
        constante: constante,
        relogio_reset: relogio_reset,
        demanda_fora: demanda_fora,
        demanda_ponta: demanda_ponta,
        tensao: tensao,
        demanda: demanda,
        rtc: rtc,
        rtp: rtp,
        ke: ke,
        pulso_watt: pulso_watt,
        numero_telemetria: numero_telemetria,
        fornecedor_acl: fornecedor_acl,
        boleto_id: boleto_id,
        empresa: empresa,
        un_negocio: un_negocio,
        departamento: departamento,
        localidade: localidade,
        conta_erp: conta_erp,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      setAddErrorMessage(errorMessage);
    }
  };

  return {
    addConsumerGroup,
    addNotification,
    addErrorMessage,
  };
};

export default useAddConsumerGroup;
