import React, { useState, useMemo } from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled } from '../style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { convertNumberToMonetaryStyle } from 'services/format/format-number-to-monetary';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer',
    position: 'sticky',
    zIndex: 1,
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  addressColumn: {
    minWidth: '150px',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconColumn: {
    minWidth: '40px',
    maxWidth: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tableCell} ${className}`}>
      {children}
    </TableCell>
  );
};

const TableHeaderCellCentered = ({
  children,
  className,
  onClick,
  sortedBy,
  direction,
}) => {
  const classes = useStyles();
  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${className}`}
      onClick={onClick}
    >
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({ value, handleRowClick }) => {
  const classes = useStyles();
  return (
    <TableRowStyled
      align="center"
      className={classes.tableRow}
      onClick={() => handleRowClick(value)}
    >
      <TableCellCentered>{value.unidade_consumidora}</TableCellCentered>
      <TableCellCentered>{value.mes_referencia}</TableCellCentered>
      <TableCellCentered>{value.qtd_paginas}</TableCellCentered>
      <TableCellCentered>
        {convertNumberToMonetaryStyle(value?.total)}
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  page,
  rowsPerPage,
  handleSort,
  sortBy,
  sortDirection,
  handleRowClick,
}) => {
  const classes = useStyles();
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            onClick={() => handleSort('unidade_consumidora')}
            sortedBy={sortBy === 'unidade_consumidora'}
            direction={sortDirection}
          >
            UNIDADE CONSUMIDORA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('mes_referencia')}
            sortedBy={sortBy === 'mes_referencia'}
            direction={sortDirection}
          >
            REF: MÊS/ANO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('qtd_paginas')}
            sortedBy={sortBy === 'qtd_paginas'}
            direction={sortDirection}
          >
            QUANTIDADE DE PÁGINAS
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.addressColumn}
            onClick={() => handleSort('total')}
            sortedBy={sortBy === 'total'}
            direction={sortDirection}
          >
            VALOR DA CONTA
          </TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            handleRowClick={handleRowClick}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function TabelaDeFaturas({
  loading,
  data = [],
  openAuditModal,
  openModal,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  function openModalAndSetData(rowData) {
    openAuditModal(rowData);
  }

  function handleRowClick(rowData) {
    openModalAndSetData([rowData]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortBy, sortDirection);
  }, [data, sortBy, sortDirection]);

  function renderChartOrErrorMessage() {
    if (sortedData && sortedData.length > 0) {
      return (
        <CircuitsTable
          data={sortedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleRowClick={handleRowClick}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={data ? parseInt(data.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={288}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>
        </>
      )}
    </div>
  );
}

function sortData(data, sortBy, sortDirection) {
  if (!sortBy) return data;

  const sortedData = [...data].sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedData;
}
