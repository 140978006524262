import React, { useState } from 'react';

import {
  AttachMoney as AttachMoneyIcon,
  BatteryChargingFull as BatteryChargingFullIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { NewLoadingValueCards } from 'components/IoseLoader';

import { Tooltip } from '@material-ui/core';

import * as S from './style';

import IoseEnergyBillsCardValuesBarChart from 'components/IoseEnergyBillsCardValuesBarChart';
import IoseUcCopelChart from 'components/IoseUcCopelChart';

const convertNumberToMonetaryStyle = (number = 0) => {
  const money = number?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return money;
};

const formatNumber = (number) => {
  const formattedNumber = number?.toLocaleString();
  return formattedNumber;
};

export default function IoseCardMercadoLivre({ loading, mercadoLivre }) {
  const [isCadastroModalOpen, setIsCadastroModalOpen] = useState(false);
  const [isConsumoModalOpen, setIsConsumoModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const calcularTotalAccountValue = (items, fornecedorAcl) => {
    return items?.reduce((total, item) => {
      if (item?.comercializadora === fornecedorAcl) {
        return total + (item?.valor_total || 0);
      }
      return total;
    }, 0);
  };

  const totalAccountValueCopel = calcularTotalAccountValue(
    mercadoLivre,
    'COPEL'
  );
  const totalAccountValueTradener = calcularTotalAccountValue(
    mercadoLivre,
    'TRADENER'
  );

  const calcularTotalConsumo = (items, fornecedorAcl) => {
    return items?.reduce((total, item) => {
      if (item?.comercializadora === fornecedorAcl) {
        return total + (item?.quantidade || 0);
      }
      return total;
    }, 0);
  };

  const obterLengthPorFornecedor = (items, fornecedorAcl) => {
    return items?.filter((item) => item?.comercializadora === fornecedorAcl)
      .length;
  };

  const quantidadeCopel = obterLengthPorFornecedor(mercadoLivre, 'COPEL');
  const quantidadeTradener = obterLengthPorFornecedor(mercadoLivre, 'TRADENER');

  const totalConsumoValueCopel = calcularTotalConsumo(mercadoLivre, 'COPEL');
  const totalConsumoValueTradener = calcularTotalConsumo(
    mercadoLivre,
    'TRADENER'
  );

  const calcularValorTotalFatura = (items) => {
    return items?.reduce((total, item) => {
      return total + (item?.item_total_value_1 || 0);
    }, 0);
  };

  const totalValorFatura = calcularValorTotalFatura(mercadoLivre);

  const totalGeral = Math.abs(
    totalAccountValueCopel + totalAccountValueTradener + totalValorFatura
  );

  const filtrarUcs = (mercadoLivre, comercializadoraFiltro) => {
    if (mercadoLivre) {
      return mercadoLivre.filter(({ comercializadora }) => {
        return comercializadora === comercializadoraFiltro;
      });
    } else {
      return [];
    }
  };

  const handleCopel = () => {
    const filteredCopel = filtrarUcs(mercadoLivre, 'COPEL');
    setFilteredData(filteredCopel);
    setSelectedCard({ title: 'UCS', data: filteredCopel });
    setIsCadastroModalOpen(true);
  };

  const handleTradener = () => {
    const filteredTradener = filtrarUcs(mercadoLivre, 'TRADENER');
    setFilteredData(filteredTradener);
    setSelectedCard({ title: 'UCS', data: filteredTradener });
    setIsConsumoModalOpen(true);
  };

  const cardsData = [
    {
      icon: <AttachMoneyIcon />,
      chart: (
        <IoseEnergyBillsCardValuesBarChart
          loading={loading}
          // data={auditValues}
        />
      ),
      title: 'VALOR TOTAL (R$)',
      Tooltip: 'Valor somado por comercializadora',
      data: [
        {
          key: 'COPEL:',
          value:
            totalAccountValueCopel !== undefined
              ? convertNumberToMonetaryStyle(totalAccountValueCopel)
              : 0,
        },
        {
          key: 'TRADENER:',
          value:
            totalAccountValueTradener !== undefined
              ? convertNumberToMonetaryStyle(totalAccountValueTradener)
              : 0,
        },
        {
          key: 'FATURAS:',
          value:
            totalValorFatura !== undefined
              ? convertNumberToMonetaryStyle(totalValorFatura)
              : 0,
        },
        { key: 'spacer_1', component: <S.Spacer height="16px" /> }, // Spacer
        {
          key: 'TOTAL:',
          value:
            totalGeral !== undefined
              ? convertNumberToMonetaryStyle(totalGeral)
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'CONSUMO TOTAL (MWh)',
      Tooltip: 'Consumo somado por comercializadora',
      data: [
        {
          key: 'COPEL: ',
          value:
            totalConsumoValueCopel !== undefined
              ? `${formatNumber(totalConsumoValueCopel)}`
              : 0,
        },
        {
          key: 'TRADENER: ',
          value:
            totalConsumoValueTradener !== undefined
              ? `${formatNumber(totalConsumoValueTradener)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon style={{ color: '#D60606' }} />,
      title: 'UCS',
      data: [
        {
          key: 'COPEL:',
          value: quantidadeCopel !== undefined ? quantidadeCopel : 0,
        },
        {
          key: 'TRADENER:',
          value: quantidadeTradener !== undefined ? quantidadeTradener : 0,
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <WbIncandescentIcon />,
      showMoreIconTooltip: 'COPEL',
      onIconClick: handleCopel,
      showSecondIcon: true,
      secondIcon: <FlashOnIcon />,
      secondIconTooltip: 'TRADENER',
      onSecondIconClick: handleTradener,
    },
  ];

  return (
    <>
      <S.CardGrid container spacing={2}>
        {cardsData.map(
          ({
            icon,
            title,
            data,
            bgColor,
            showMore,
            showMoreIcon,
            Tooltip: tooltipText,
            onIconClick,
            showMoreIconTooltip,
            secondIconTooltip,
            onSecondIconClick,
            secondIcon,
          }) => (
            <S.BoxContainerHover
              key={title}
              style={{ backgroundColor: bgColor }}
              showMore={showMore}
            >
              <S.HeaderContainer>
                <S.IconHeader>{icon}</S.IconHeader>
                {tooltipText ? (
                  <Tooltip
                    title={
                      <span style={{ fontSize: '0.9rem' }}>{tooltipText}</span>
                    }
                    arrow
                    placement="top"
                  >
                    <S.CardTitle>{title}</S.CardTitle>
                  </Tooltip>
                ) : (
                  <S.CardTitle>{title}</S.CardTitle>
                )}
              </S.HeaderContainer>
              {data.map(({ key, value, component }) =>
                component ? (
                  <div key={key}>{component}</div>
                ) : (
                  <S.DataContainer key={key}>
                    <S.CardDataKey>{key}</S.CardDataKey>
                    {loading ? (
                      <NewLoadingValueCards loading={loading} margin="0px" />
                    ) : (
                      <S.CardDataValue>{value}</S.CardDataValue>
                    )}
                  </S.DataContainer>
                )
              )}
              {showMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Tooltip title={showMoreIconTooltip} arrow>
                    <S.ClickableIcon onClick={onIconClick}>
                      {showMoreIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                  <Tooltip title={secondIconTooltip} arrow>
                    <S.ClickableIcon onClick={onSecondIconClick}>
                      {secondIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                </div>
              )}
            </S.BoxContainerHover>
          )
        )}
      </S.CardGrid>
      <IoseUcCopelChart
        open={isCadastroModalOpen || isConsumoModalOpen}
        onClose={() => {
          setIsCadastroModalOpen(false);
          setIsConsumoModalOpen(false);
          setSelectedCard(null);
          setFilteredData([]);
        }}
        selectedCard={selectedCard}
        filteredData={filteredData}
      />
    </>
  );
}
