import React, { useState } from 'react';

import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import {
  NewLoadingValueCards,
} from 'components/IoseLoader';
import IoseCreditosChart from 'components/IoseCreditosChart';
import IoseBeneficariasCreditosChart from 'components/IoseBeneficariasCreditosChart';

import * as S from './style';
import { Tooltip } from '@material-ui/core';

const formatNumber = (number) => {
  return number.toLocaleString();
};

export default function IoseCardCreditDash({
  loading,
  beneficiaryJoinData,
  filteredBeneficiariaJoinData,
}) {
  const creditConnection = beneficiaryJoinData ? beneficiaryJoinData : [];
  const [selectedCard, setSelectedCard] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const uniqueGeradoras = [
    ...new Set(creditConnection?.map((item) => item.uc_geradora)),
  ].length;

  // const beneficiaria = creditConnection?.flatMap(
  //   (item) => item.uc_beneficiarias
  // ).length;

  const beneficiaria = filteredBeneficiariaJoinData?.length;

  const totalCreditoGerado = creditConnection?.reduce(
    (acc, item) => acc + (item?.gerados_fpta || 0),
    0
  );

  const totalCreditoRecebido = filteredBeneficiariaJoinData?.reduce(
    (acc, item) => {
      const credRecebido = parseFloat(item?.cred_recebido) || 0;
      return acc + credRecebido;
    },
    0
  );

  const handleCardClick = (title) => {
    setFilteredData(creditConnection);
    setSelectedCard({ title, data: creditConnection });
  };

  const cardsData = [
    {
      icon: <BatteryChargingFullIcon />,
      title: 'UNIDADES GERADORAS',
      Tooltip: 'Unidades consumidoras',
      data: [
        {
          key: 'TOTAL DE UCS: ',
          value: loading ? (
            <NewLoadingValueCards loading={loading} margin="0px" />
          ) : (
            formatNumber(uniqueGeradoras)
          ),
        },
        {
          key: 'TOTAL DE CRÉDITOS GERADOS: ',
          value: loading ? (
            <NewLoadingValueCards loading={loading} margin="0px" />
          ) : (
            formatNumber(totalCreditoGerado)
          ),
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <WbIncandescentIcon />,
      showMoreIconTooltip: 'Geradoras',
      onIconClick: () => handleCardClick('UNIDADES GERADORAS'),
    },
    {
      icon: <BatteryChargingFullIcon />,
      title: 'UNIDADES BENEFICIARIAS',
      Tooltip: 'Unidades beneficiárias',
      data: [
        {
          key: 'TOTAL DE UCS: ',
          value: loading ? (
            <NewLoadingValueCards loading={loading} margin="0px" />
          ) : (
            formatNumber(beneficiaria)
          ),
        },
        {
          key: 'TOTAL DE CRÉDITOS RECEBIDOS: ',
          value: loading ? (
            <NewLoadingValueCards loading={loading} margin="0px" />
          ) : (
            formatNumber(totalCreditoRecebido)
          ),
        },
      ],
      bgColor: 'var(--base)',
      showMore: true,
      showMoreIcon: <WbIncandescentIcon />,
      showMoreIconTooltip: 'Beneficiárias',
      onIconClick: () => handleCardClick('UNIDADES BENEFICIARIAS'),
    },
  ];

  return (
    <>
      <S.CardGrid container spacing={2}>
        {cardsData.map(
          ({
            icon,
            title,
            data,
            bgColor,
            showMore,
            Tooltip: tooltipText,
            showMoreIcon,
            onIconClick,
            showMoreIconTooltip,
          }) => (
            <S.BoxContainerHover
              key={title}
              style={{ transition: '0.5s ease', backgroundColor: bgColor }}
              showMore={showMore}
            >
              <S.HeaderContainer>
                <S.IconHeader>{icon}</S.IconHeader>

                {tooltipText ? (
                  <Tooltip
                    title={
                      <span style={{ fontSize: '0.9rem' }}>{tooltipText}</span>
                    }
                    arrow
                    placement="top"
                  >
                    <S.CardTitle>{title}</S.CardTitle>
                  </Tooltip>
                ) : (
                  <S.CardTitle>{title}</S.CardTitle>
                )}
              </S.HeaderContainer>
              {data.map(({ key, value }) => (
                <S.DataContainer key={key}>
                  <S.CardDataKey>{key}</S.CardDataKey>
                  {loading ? (
                    <NewLoadingValueCards loading={loading} margin="0px" />
                  ) : (
                    <S.CardDataValue>{value}</S.CardDataValue>
                  )}
                </S.DataContainer>
              ))}
              {showMore && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <Tooltip title={showMoreIconTooltip} arrow>
                    <S.ClickableIcon onClick={onIconClick}>
                      {showMoreIcon}
                    </S.ClickableIcon>
                  </Tooltip>
                </div>
              )}
            </S.BoxContainerHover>
          )
        )}
      </S.CardGrid>

      {/* Render chart based on selectedCard */}
      {selectedCard?.title === 'UNIDADES GERADORAS' && (
        <IoseCreditosChart
          open={!!selectedCard}
          onClose={() => {
            setSelectedCard(null);
            setFilteredData([]);
          }}
          selectedCard={selectedCard}
          filteredData={filteredData}
        />
      )}
      {selectedCard?.title === 'UNIDADES BENEFICIARIAS' && (
        <IoseBeneficariasCreditosChart
          open={!!selectedCard}
          onClose={() => {
            setSelectedCard(null);
            setFilteredData([]);
          }}
          selectedCard={selectedCard}
          filteredData={filteredBeneficiariaJoinData}
        />
      )}
    </>
  );
}
