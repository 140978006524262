import React from 'react';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import {
  IoseConsumerGroupRedirectButton,
  IoseDownloadButton2,
} from 'components/IoseButtonIcon';
import { useConsumerGroupData } from 'hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const handleDownloadExtrato = (currentExtract) => {
  const extract = currentExtract[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (extract) {
    const concessionaire = extract?.concessionaire.toLowerCase();
    const referenceMonth = extract?.reference_month.replace('/', '');
    const pdfNumber = extract?.nome_pdf;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}E/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

export default function BillsModalExtractSubHeader({ currentExtract }) {
  const history = useHistory();
  const extract = currentExtract[0];
  const { consumerGroup } = useConsumerGroupData(
    extract?.identification_number
  );

  console.log('extract: ', extract)

  function redirectToUcDash() {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: extract?.identification_number,
        uuidCircuit: consumerGroup[0]?.numero_telemetria,
      },
    });
  }

  return (
    <>
      <IoseSubHeaderBar
        subtitle={`Detalhamento do extrato de energia da UC ${extract?.identification_number}`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          {extract?.identification_number && consumerGroup ? (
            <IoseConsumerGroupRedirectButton
              tooltip="IR PARA O DASHBOARD DA UC"
              bottom="5px"
              onClick={() => redirectToUcDash()}
            />
          ) : null}

          <div>
            <IoseDownloadButton2
              tooltip={'BAIXAR EXTRATO'}
              bottom="5px"
              onClick={() => handleDownloadExtrato(currentExtract)}
            />
          </div>

          {/* <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            // onClick={() => redirectToMapPage()}
            onClick={() => {}}
          /> */}
        </div>
      </IoseSubHeaderBar>
    </>
  );
}
