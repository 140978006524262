import styled from 'styled-components';

/*
 *This component exports all styled components that
 *are used to build the contents of the sidebar
 */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

//Header
export const SideHeader = styled.div`
  width: 100%;
  padding: 10px;
  background-color: var(--primary);
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    color: var(--secondary);
    font-size: 16px;
    margin: auto 0px auto 10px;
    width: 100%;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Icon = styled.img`
  width: 30px;
`;

//Sub Header
export const SubSideHeader = styled.div`
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  color: var(--base);

  h3 {
    color: inherit;
    font-size: 14px;
    width: 100%;
    max-width: 250px;
    margin: auto 0px auto 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: 767px) {
      max-width: 120px;
    }
  }
`;

export const IconSubheader = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 5px 0px 5px;
`;

//Content side Bar
export const Content = styled.div`
  position: relative;
  padding: ${(props) => (props.admin ? '0px' : '0px 10px 0px 15px')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-top: 5px;
  margin-bottom: 60px;
  overflow-y: ${(props) => (props.admin ? 'none' : 'scroll')};

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
`;
