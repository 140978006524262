import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI, LocalStorage } from 'common';

function useCreateLogs() {
  const [noLogsError, setNoLogsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const localStorageSession = LocalStorage.getSession;
  const session = localStorageSession();

  const currentDate = new Date();
  const data = currentDate.toISOString();

  const handleCreateLog = async (log_operation, log_form, log_content) => {
    try {
      const { headers } = getSession();

      await ioseAPI.createUserLog(
        headers,
        session?.name,
        data,
        log_operation,
        log_form,
        log_content,
        session?.email
      );
    } catch (error) {
      console.error('Erro ao criar log:', error.message);
      setNoLogsError(Messages.noRegisteredLogs);
    }
  };

  const handleCreateLoginLog = async (
    sessionData,
    log_operation,
    log_form,
    log_content
  ) => {
    try {
      if (!sessionData) {
        console.error('Session data is null or undefined.');
        return;
      }

      const { headers } = getSession();

      await ioseAPI.createUserLog(
        headers,
        sessionData.name || 'DefaultName',
        sessionData.data || new Date().toISOString(),
        log_operation,
        log_form,
        log_content,
        sessionData.email || 'DefaultEmail'
      );
    } catch (error) {
      console.error('Erro ao criar log:', error.message);
      setNoLogsError(Messages.noRegisteredLogs);
    }
  };
  return {
    createLog: handleCreateLog,
    createLoginLog: handleCreateLoginLog,
    noLogsError,
  };
}

export default useCreateLogs;
