import React, { useContext, useEffect, useState } from 'react';
import { RoutesSchedule } from 'routers';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { WebSocketEndPoint, ioseAPI } from 'common/ioseAPI';

//Imports from IOSE Components
import {
  ButtonArea,
  SubSideHeader,
  Content,
  SideHeader,
  Container,
  Icon,
} from 'components/IoseSideContainer';
import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';

//Imports from Material UI Components
import { Grid } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import UnityIcon from './img/Unity.svg';

function SidebarScheduleContainer({ closeSide, setGetcards }) {
  let location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const allGroups = location.state.allGroups;
  const allCircuits = location.state.allCircuits;
  const unityData = location.state.unityData;
  const uuid_client = location.state.uuid_client;
  const clientName = location.state.clientName;
  const form_what = location.state.form_what;
  const scheduleData = location.state.scheduleData;

  const [circuitStateScheduling, setCircuitStateScheduling] = useState({});

  const unityName = location.state.unityData.name;

  const [notification, setNotification] = useState({ status: 0, text: '' });

  const [loading, setLoading] = useState(false);

  //This function return session data
  const { getSession } = useContext(AccountContext);

  const [createResponse, setCreateResponse] = useState('');
  const [deleteResponse, setDeleteResponse] = useState('');
  const [editResponse, setEditResponse] = useState('');

  useEffect(() => {
    //Websocket
    const socket = new WebSocket(WebSocketEndPoint);

    if (socket.readyState !== 1) {
      socket.onopen = (e) => {
        scheduleSubscribeWebSocket(socket);
      };
    }

    socket.onmessage = (e) => {
      let websocketMessage = JSON.parse(e.data);
      setCircuitStateScheduling(websocketMessage);
    };

    return () => {
      socket.close();
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form_what === 'delete' && verificationCircuitsDelete(scheduleData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form_what]);

  async function addSchedule(scheduleDataAdd) {
    setLoading(true);
    setNotification({ status: 0, text: '' });
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.createScheduling(
        sessionData.headers,
        scheduleDataAdd.circuits,
        scheduleDataAdd.name,
        unityData.uuid_unity,
        scheduleDataAdd.run_at,
        scheduleDataAdd.action_relay,
        scheduleDataAdd.repeat,
        scheduleDataAdd.week
      );

      setLoading(false);
      setNotification({ status: 1, text: 'Agendamento criado com sucesso' });
      setCreateResponse(response.status);
      setGetcards(true);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : 'Erro ao criar Agendamento';

      setLoading(false);
      setGetcards(true);
      setCreateResponse(error.response.status);
      setNotification({ status: 2, text: errorMessage });
    }
  }

  async function editSchedule(scheduleDataEdit) {
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateScheduling(
        sessionData.headers,
        scheduleDataEdit.name,
        scheduleDataEdit.uuid_scheduling_group,
        scheduleDataEdit.run_at,
        scheduleDataEdit.action_relay,
        scheduleDataEdit.repeat,
        scheduleDataEdit.week
      );

      const responseMessage = response.data
        ? response.data.message
        : 'Módulos incluídos com sucesso!';

      setLoading(false);
      setNotification({ status: 1, text: responseMessage });
      setEditResponse(response.status);
      setGetcards(true);
    } catch (error) {
      setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : 'Erro ao editar Agendamento';

      setNotification({ status: 2, text: errorMessage });
      setEditResponse(error.response.status);
      setGetcards(true);
    }
  }

  async function editScheduleAddCircuits(scheduleDataEdit) {
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.includeScheduling(
        sessionData.headers,
        scheduleDataEdit.uuid_scheduling_group,
        scheduleDataEdit.circuits
      );

      const responseMessage = response.data
        ? response.data.message
        : 'Módulos incluídos com sucesso!';

      setLoading(false);
      setNotification({ status: 1, text: responseMessage });
      setEditResponse(response.status);
    } catch (error) {
      setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : 'Erro ao editar Agendamento';

      setGetcards(true);
      setEditResponse(error.response.status);
      setNotification({ status: 2, text: errorMessage });
    }
  }

  async function editScheduleRemoveCircuits(scheduleDataEdit) {
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteModuleSchedulings(
        sessionData.headers,
        scheduleDataEdit.uuid_scheduling_group,
        scheduleDataEdit.circuits
      );

      const responseMessage = response.data
        ? response.data.message
        : 'Módulos removidos com sucesso!';

      setLoading(false);
      setNotification({ status: 1, text: responseMessage });
      setEditResponse(response.status);
    } catch (error) {
      setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : 'Erro ao editar Agendamento';

      setGetcards(true);
      setEditResponse(error.response.status);
      setNotification({ status: 2, text: errorMessage });
    }
  }

  async function deleteSchedule(scheduleDataDelete) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteScheduling(
        sessionData.headers,
        scheduleDataDelete.uuid_scheduling_group,
        scheduleDataDelete.uuid_unity
      );

      const responseMessage = response.data
        ? response.data.message
        : 'Agendamento Deletado com sucesso';

      setLoading(false);
      setNotification({
        status: 1,
        text: responseMessage,
      });
      setDeleteResponse(response.status);
      setGetcards(true);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : 'Erro ao Deletar Agendamento';

      setLoading(false);
      setDeleteResponse(error.response.status);
      setNotification({ status: 2, text: errorMessage });
    }
  }

  // Condition to check if getSchedulingModulesByGroup requisition in container was success
  function verificationCircuitsDelete(scheduleDataDelete) {
    setLoading(true);
    setNotification({ status: 0, text: '' });

    const circuitsScheduling = location.state.circuitsSelecteds;

    if (circuitsScheduling !== []) {
      deleteSchedule(scheduleDataDelete);
    } else {
      setLoading(false);
      setDeleteResponse(false);
      setNotification({
        status: 2,
        text: 'Erro ao buscar circuitos relacionados ao agendamento',
      });
    }
  }

  async function getSchedulingModulesByGroup(uuid_scheduling_group) {
    setNotification({ status: 0, text: '' });
    setLoading(true);
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getSchedulingModulesByGroup(
        sessionData.headers,
        uuid_scheduling_group,
        0,
        2000
      );
      setLoading(false);
      return { result: true, message: response.data && response.data.data };
    } catch (error) {
      setLoading(false);
      const messageError = error.response
        ? error.response.data.message
        : 'Erro ao buscar circuitos relacionados ao agendamento';

      setNotification({ status: 2, text: messageError });

      return {
        result: false,
        message: messageError,
      };
    }
  }

  const redirectValidation = (circuitsSelecteds, form_what, scheduleData) => {
    history.push({
      pathname: `${path}/validation`,
      state: {
        clientName: clientName,
        uuid_client: uuid_client,
        unityData: unityData,
        allGroups: allGroups,
        allCircuits: allCircuits,
        circuitsSelecteds: circuitsSelecteds,
        form_what: form_what,
        scheduleData: scheduleData,
      },
    });
  };

  const redirectToForm = (form_what, scheduleData) => {
    history.push({
      pathname: form_what === 'create' ? `${path}/add` : `${path}/edit`,
      state: {
        clientName: clientName,
        uuid_client: uuid_client,
        unityData: unityData,
        allGroups: allGroups,
        allCircuits: allCircuits,
        form_what: form_what,
        scheduleData: scheduleData,
      },
    });
  };

  const nameSwitchboard = (uuid_group) => {
    const nameSwitchboardArray = allGroups.filter(
      (group) => group.uuid_group === uuid_group
    );

    return nameSwitchboardArray[0] && nameSwitchboardArray[0].name;
  };

  //Function to Subscribe in Websocket
  function scheduleSubscribeWebSocket(socket) {
    socket.send(
      JSON.stringify({
        'action': 'subscribeUnity',
        'data': { 'uuid_unity': unityData.uuid_unity },
      })
    );
  }

  function resendRequistion(uuid_circuit) {
    try {
      // const sessionData = await getSession();

      // const response = await ioseAPI.getSchedulingModulesByGroup(
      //   sessionData.headers,
      //   uuid_scheduling_group,
      //   0,
      //   2000
      //   );

      // return { result: true, message: response.data && response.data.data };

      return { result: true, message: 'resolved' };
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : 'Erro ao reenviar requisição!';

      return {
        result: false,
        message: message,
      };
    }
  }

  return (
    <Container>
      <SideHeader>
        <Icon src={UnityIcon} />
        <h2>{unityName}</h2>
        <ButtonArea>
          <></>
        </ButtonArea>
      </SideHeader>
      <SubSideHeader>
        <Grid container direction="row">
          <EventIcon />
          <h3>Agendamento</h3>
        </Grid>
      </SubSideHeader>
      <Content>
        {notification.status === 1 && (
          <IoseAlertSuccess text={notification.text} />
        )}
        {notification.status === 2 && (
          <IoseAlertErro text={notification.text} />
        )}

        {loading && <LoadingIndicatorSidebar loading={loading} />}
        <RoutesSchedule
          addSchedule={addSchedule}
          editSchedule={editSchedule}
          editScheduleAddCircuits={editScheduleAddCircuits}
          editScheduleRemoveCircuits={editScheduleRemoveCircuits}
          redirectValidation={redirectValidation}
          redirectToForm={redirectToForm}
          nameSwitchboard={nameSwitchboard}
          closeSide={closeSide}
          circuitStateScheduling={circuitStateScheduling}
          getSchedulingModulesByGroup={getSchedulingModulesByGroup}
          resendRequistion={resendRequistion}
          createResponse={createResponse}
          deleteResponse={deleteResponse}
          editResponse={editResponse}
        />
      </Content>
    </Container>
  );
}

export default SidebarScheduleContainer;
