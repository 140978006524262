import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

//Import from formik (Library to logic's form)
import { useFormik } from 'formik';

import { ForgotPasswordSchema } from 'common/validation_schemas';

//Import from Message
import Messages from 'common/Messages';

//Import from IOSE Components
import IoseTextField from 'components/IoseTextField';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';

//Import from Styled Components
import {
  GridMain,
  GridImage,
  BoxLoginContent,
  Illustration,
  Form,
  ButtonConfirmation,
  Information,
  LinkForgotPassword,
  GridContent,
  ErroText,
} from './style';

//Import images
import Section1Image from './img/authentication.svg';
import Section3Image from './img/password.svg';

import IoseCognito from 'common/iose_cognito';

/**
 *
 *This is the Login Page with all elements
 * @export
 * @returns
 */
export default function ForgotPassword() {
  const history = useHistory();

  const [section, setSection] = useState(1);

  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const [newPasswordData, setNewPasswordData] = useState({
    code: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => sendCode(values.email),
  });

  const sendCode = async (email) => {
    setLoading(true);
    setError('');
    try {
      await IoseCognito.sendCode(email);
      setEmail(email);
      setLoading(false);
      setSection(2);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const code = newPasswordData.code;
    const newPassword = newPasswordData.newPassword;
    const newPasswordConfirmation = newPasswordData.newPasswordConfirmation;

    try {
      await IoseCognito.resetPassword(
        email,
        code,
        newPassword,
        newPasswordConfirmation
      );
      setLoading(false);
      setSection(3);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const renderIllustration = () => {
    if (section === 3) {
      return Section3Image;
    } else {
      return Section1Image;
    }
  };

  const renderSection = () => {
    switch (section) {
      case 1:
        return renderSendEmailSection();

      case 2:
        return renderCreateNewPasswordSection();

      case 3:
        return renderNotificationUSerSection();

      default:
        return renderSendEmailSection();
    }
  };

  const renderSendEmailSection = () => {
    const fieldError =
      formik.errors.email && formik.touched.email ? true : false;
    const fieldErrorMsg =
      formik.errors.email && formik.touched.email ? formik.errors.email : null;

    return (
      <Form onSubmit={formik.handleSubmit}>
        <Information>
          <b>{Messages.forgotPassword}</b>
        </Information>

        <IoseTextField
          label="Digite seu e-mail"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={fieldError}
          helperText={fieldErrorMsg}
          onblur={formik.handleBlur}
        />

        <ButtonConfirmation>Enviar</ButtonConfirmation>
        <LinkForgotPassword to="/">Voltar para o login.</LinkForgotPassword>
      </Form>
    );
  };

  const renderCreateNewPasswordSection = () => {
    let code = newPasswordData.code;
    let newPassword = newPasswordData.newPassword;
    let newPasswordConfirmation = newPasswordData.newPasswordConfirmation;

    const handleCode = (event) =>
      setNewPasswordData({
        ...newPasswordData,
        code: event.target.value,
      });

    const handleNewPassword = (event) =>
      setNewPasswordData({
        ...newPasswordData,
        newPassword: event.target.value,
      });

    const handleNewPasswordConfirmation = (event) =>
      setNewPasswordData({
        ...newPasswordData,
        newPasswordConfirmation: event.target.value,
      });

    return (
      <>
        <Form onSubmit={resetPassword}>
          <Information>
            {Messages.codeVerificationMessage}
            <b> {Messages.requisitionPassowordCognito}</b>
          </Information>

          <IoseTextField
            label="Código de Verificação"
            value={code}
            onChange={handleCode}
          />
          <IoseTextField
            label="Nova Senha"
            value={newPassword}
            type="password"
            onChange={handleNewPassword}
          />
          <IoseTextField
            label="Confirme Nova Senha"
            value={newPasswordConfirmation}
            type="password"
            onChange={handleNewPasswordConfirmation}
          />

          <ButtonConfirmation>Salvar</ButtonConfirmation>
          <LinkForgotPassword to="/">Voltar para o login</LinkForgotPassword>
        </Form>
      </>
    );
  };

  const renderNotificationUSerSection = () => (
    <>
      <Information>
        <b>{Messages.newPasswordConfirmartion}</b>
      </Information>

      <ButtonConfirmation onClick={() => history.replace('/')}>
        Fazer Login
      </ButtonConfirmation>
    </>
  );

  return (
    <GridMain>
      <GridImage />
      <GridContent>
        <BoxLoginContent>
          {section !== 2 && <Illustration image={renderIllustration()} />}
          {renderSection()}
          <ErroText>{error}</ErroText>
          <LoadingIndicatorSidebar
            loading={loading}
            color={'var(--secondary)'}
          />
        </BoxLoginContent>
        {/* <IoseFooterLogin /> */}
      </GridContent>
    </GridMain>
  );
}
