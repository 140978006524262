import React, { useEffect, useState } from "react";
import * as S from '../style';
import { IoseButton2 } from "components";
import GetAppIcon from '@material-ui/icons/GetApp';
import { xlsxReports } from "reports/Xlsx";

function convertNumberToDecimalStyle(value) {
  if (value === undefined || value === null) return '0';
  const convertedToNumber = parseFloat(value);
  const decimalNumber = convertedToNumber.toLocaleString('pt-BR');
  return decimalNumber;
}

const sortMonths = (a, b) => {
  const [monthA, yearA] = a.split('/').map(Number);
  const [monthB, yearB] = b.split('/').map(Number);

  if (yearA !== yearB) {
    return yearA - yearB;
  }

  return monthA - monthB;
};

const Table = ({ title, data }) => {
  if (!data) {
    return null;
  }

  let tableData = [];
  if (title === "B3") {
    tableData = [
      { description: 'Consumo', valor: data?.consumo_valor, kwh: data?.consumo_kwh },
      { description: 'CIP', valor: data?.cip_valor, kwh: data?.cip_kwh },
      { description: 'Cons. Excedente Reativo', valor: data?.consumo_exe_reat_valor, kwh: data?.consumo_exe_reat_kwh },
      { description: 'Ener. Injetada', valor: data?.energia_inj_valor, kwh: data?.energia_inj_kwh },
      { description: 'Outros', valor: data?.outros_valor, kwh: data?.outros_kwh },
      { description: 'Qtd. UCS', valor: data?.qtd_ucs }
    ];
  }

  else if (title === 'ACL - COPEL' || title === 'ACL - TRADENER') {
    tableData = [
      { description: 'Consumo', valor: data?.consumo_acl_valor, kwh: data?.consumo_acl_kwh },
      { description: 'Qtd. UCS', valor: data?.qtd_ucs }
    ];
  } else {
    tableData = [
      { description: 'Consumo PTA', valor: data?.consumo_pta_valor, kwh: data?.consumo_pta_kwh },
      { description: 'Consumo FP', valor: data?.consumo_fp_valor, kwh: data?.consumo_fp_kwh },
      { description: 'Cons. Excedente Reativo PTA', valor: data?.consumo_exe_reat_pta_valor, kwh: data?.consumo_exe_reat_pta_kwh },
      { description: 'Cons. Excedente Reativo FP', valor: data?.consumo_exe_reat_fp_valor, kwh: data?.consumo_exe_reat_fp_kwh },
      { description: 'Demanda PTA', valor: data?.demanda_pta_valor, kwh: data?.demanda_pta_kwh },
      { description: 'Demanda FP', valor: data?.demanda_fp_valor, kwh: data?.demanda_fp_kwh },
      { description: 'Demanda', valor: data?.demanda_valor, kwh: data?.demanda_kwh },
      { description: 'Dem. Exedente Reativo PTA', valor: data?.demanda_exe_reat_pta_valor, kwh: data?.demanda_exe_reat_pta_kwh },
      { description: 'Dem. Exedente Reativo FP', valor: data?.demanda_exe_reat_fp_valor, kwh: data?.demanda_exe_reat_fp_kwh },
      { description: 'Dem. Exedente Reativo', valor: data?.demanda_exe_reat_valor, kwh: data?.demanda_exe_reat_kwh },
      { description: 'Adicional Demanda PTA', valor: data?.ad_demanda_pta_valor, kwh: data?.ad_demanda_pta_kwh },
      { description: 'Adicional Demanda FP', valor: data?.ad_demanda_fp_valor, kwh: data?.ad_demanda_fp_kwh },
      { description: 'Adicional Demanda', valor: data?.ad_demanda_valor, kwh: data?.ad_demanda_kwh },
      { description: 'Ultrapassagem de Demanda PTA', valor: data?.demanda_ult_pta_valor, kwh: data?.demanda_ult_pta_kwh },
      { description: 'Ultrapassagem de Demanda FP', valor: data?.demanda_ult_fp_valor, kwh: data?.demanda_ult_fp_kwh },
      { description: 'Ultrapassagem de Demanda ', valor: data?.demanda_ult_valor, kwh: data?.demanda_ult_kwh },
      { description: 'CIP', valor: data?.cip_valor, kwh: data?.cip_kwh },
      { description: 'Energ. Injetada', valor: data?.energia_inj_valor, kwh: data?.energia_inj_kwh },
      { description: 'Outros', valor: data?.outros_valor, kwh: data?.outros_kwh },
      { description: 'Qtd. UCS', valor: data?.qtd_ucs }
    ];
  }

  const totalValor = tableData
  .filter(item => item.description !== 'Qtd. UCS')
  .reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);

  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{title}</h3>
      <S.CardTable>
        <S.CardTableHeader>
          <S.CardTableRow>
            <S.CardTableHeaderCell>Descrição</S.CardTableHeaderCell>
            <S.CardTableHeaderCell>(R$)</S.CardTableHeaderCell>
            <S.CardTableHeaderCell>Unidade</S.CardTableHeaderCell>
          </S.CardTableRow>
        </S.CardTableHeader>
        <tbody>
          {tableData.map((item, index) => (
            <S.CardTableRow key={index}>
              <S.CardTableCell modalidade={title}>{item.description}</S.CardTableCell>
              <S.CardTableCell modalidade={title}>{convertNumberToDecimalStyle(item.valor)}</S.CardTableCell>
              <S.CardTableCell modalidade={title}>{convertNumberToDecimalStyle(item.kwh)}</S.CardTableCell>
            </S.CardTableRow>
          ))}
          <S.CardTableRow>
            <S.TotalCell colSpan={2} modalidade={title}>Total (R$)</S.TotalCell>
            <S.TotalCell modalidade={title}>{convertNumberToDecimalStyle(totalValor)}</S.TotalCell>
          </S.CardTableRow>
        </tbody>
      </S.CardTable>
    </div>
  );
};

export default function SummaryDashYearTables({ data }) {

  const [groupedData, setGroupedData] = useState({});

  const sumFields = (acc, item, fields) => {
    fields.forEach(field => {
      acc[field] = (acc[field] || 0) + (parseFloat(item[field]) || 0);
    });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const groupedData = data.reduce((acc, item) => {
        const { reference_month, modalidade, subgrupo, comercializadora_acl } = item;

        if (!acc[reference_month]) {
          acc[reference_month] = { VERDE: {}, AZUL: {}, B3: {}, COPEL: {}, TRADENER: {} };
        }

        // Processamento para B3
        if (subgrupo === 'B3') {
          acc[reference_month].B3.qtd_ucs = (acc[reference_month].B3.qtd_ucs || 0) + (parseFloat(item.qtd_ucs) || 0);

          const b3Fields = [
            'consumo_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor', 'consumo_kwh', 'cip_kwh',
            'energia_inj_kwh', 'outros_kwh', 'consumo_exe_reat_kwh', 'consumo_exe_reat_valor'
          ];
          sumFields(acc[reference_month].B3, item, b3Fields);
        }

        // Processamento para COPEL
        if (comercializadora_acl === "COPEL") {
          acc[reference_month].COPEL.qtd_ucs = (acc[reference_month].COPEL.qtd_ucs || 0) + (parseFloat(item.qtd_ucs) || 0);

          const copelFields = ['consumo_acl_kwh', 'consumo_acl_valor'];
          sumFields(acc[reference_month].COPEL, item, copelFields);
        }

        // Processamento para TRADENER
        if (comercializadora_acl === "TRADENER") {
          acc[reference_month].TRADENER.qtd_ucs = (acc[reference_month].TRADENER.qtd_ucs || 0) + (parseFloat(item.qtd_ucs) || 0);

          const tradenerFields = ['consumo_acl_kwh', 'consumo_acl_valor'];
          sumFields(acc[reference_month].TRADENER, item, tradenerFields);
        }

        // Processamento para VERDE e AZUL
        if (modalidade === 'VERDE' || modalidade === 'AZUL') {
          if (!acc[reference_month][modalidade]) {
            acc[reference_month][modalidade] = { qtd_ucs: 0 };
          }

          // Soma qtd_ucs
          acc[reference_month][modalidade].qtd_ucs = (acc[reference_month][modalidade].qtd_ucs || 0) + (parseFloat(item.qtd_ucs) || 0);

          const commonFields = [
            'consumo_pta_valor', 'consumo_fp_valor', 'consumo_exe_reat_pta_valor', 'consumo_exe_reat_fp_valor',
            'demanda_pta_valor', 'demanda_fp_valor', 'demanda_exe_reat_pta_valor', 'demanda_exe_reat_fp_valor',
            'ad_demanda_pta_valor', 'ad_demanda_fp_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor',
            'consumo_pta_kwh', 'consumo_fp_kwh', 'consumo_exe_reat_pta_kwh', 'consumo_exe_reat_fp_kwh',
            'demanda_pta_kwh', 'demanda_fp_kwh', 'demanda_exe_reat_pta_kwh', 'demanda_exe_reat_fp_kwh',
            'ad_demanda_pta_kwh', 'ad_demanda_fp_kwh', 'cip_kwh', 'energia_inj_kwh', 'outros_kwh',
            'demanda_ult_pta_kwh', 'demanda_ult_pta_valor', 'demanda_ult_fp_kwh', 'demanda_ult_fp_valor',
            'demanda_ult_kwh', 'demanda_ult_valor', 'demanda_valor', 'demanda_kwh', 'demanda_exe_reat_valor',
            'demanda_exe_reat_kwh', 'ad_demanda_kwh', 'ad_demanda_valor', 'consumo_exe_reat_kwh', 'consumo_exe_reat_valor',
            'consumo_valor', 'consumo_kwh'
          ];

          sumFields(acc[reference_month][modalidade], item, commonFields);
        }

        return acc;
      }, {});

      // Ordenar os meses
      const orderedGroupedData = Object.keys(groupedData)
        .sort(sortMonths)
        .reduce((obj, key) => {
          obj[key] = groupedData[key];
          return obj;
        }, {});

      setGroupedData(orderedGroupedData);
    }
  }, [data]);


  return (
    <S.CardsSummaryContainer>
      <S.CardTitleHeaderContainer>
        <div>
          <S.TitleContainer>RESUMOS POR MÊS DO ANO SELECIONADO</S.TitleContainer>
        </div>
        <div>
          <IoseButton2
            style={{
              margin: '10px',
            }}
            onClick={() =>xlsxReports.exportYearSummaryByMonthToExcel(groupedData)}
            width="150px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      </S.CardTitleHeaderContainer>
      <S.GridCardsContainer>
        {Object.keys(groupedData).map(month => {
          const monthData = groupedData[month];

          const calculateTotal = (data, fields) => {
            return fields.reduce((acc, field) => {
              return acc + (parseFloat(data[field]) || 0);
            }, 0);
          };

          const totalVerde = calculateTotal(monthData?.VERDE || {}, [
            'consumo_pta_valor', 'consumo_fp_valor', 'consumo_exe_reat_pta_valor', 'consumo_exe_reat_fp_valor',
            'demanda_pta_valor', 'demanda_fp_valor', 'demanda_valor', 'demanda_exe_reat_pta_valor', 'demanda_exe_reat_fp_valor',
            'demanda_exe_reat_valor', 'ad_demanda_pta_valor', 'ad_demanda_fp_valor', 'ad_demanda_valor',
            'demanda_ult_pta_valor', 'demanda_ult_fp_valor', 'demanda_ult_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor',
            'consumo_exe_reat_valor'
          ]);

          const totalAzul = calculateTotal(monthData?.AZUL || {}, [
            'consumo_pta_valor', 'consumo_fp_valor', 'consumo_exe_reat_pta_valor', 'consumo_exe_reat_fp_valor',
            'demanda_pta_valor', 'demanda_fp_valor', 'demanda_valor', 'demanda_exe_reat_pta_valor', 'demanda_exe_reat_fp_valor',
            'demanda_exe_reat_valor', 'ad_demanda_pta_valor', 'ad_demanda_fp_valor', 'ad_demanda_valor',
            'demanda_ult_pta_valor', 'demanda_ult_fp_valor', 'demanda_ult_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor',
            'consumo_exe_reat_valor'
          ]);

          const totalB3 = calculateTotal(monthData?.B3 || {}, [
            'consumo_valor', 'cip_valor', 'energia_inj_valor', 'outros_valor', 'demanda_ult_valor', 'consumo_exe_reat_valor'
          ]);

          const totalCopel = calculateTotal(monthData?.COPEL || {}, [
            'consumo_acl_valor',
          ]);

          const totalTradener = calculateTotal(monthData?.TRADENER || {}, [
            'consumo_acl_valor',
          ]);

          // Total do mês sem considerar qtd_ucs
          const monthlyTotal = totalVerde + totalAzul + totalB3 + totalCopel + totalTradener;


          return (
            <S.Card key={month}>
              <S.CardTitle>{month}</S.CardTitle>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Table title="VERDE" data={monthData?.VERDE} />
                <Table title="AZUL" data={monthData?.AZUL} />
                <div>
                  <Table title="B3" data={monthData?.B3} />
                  <div style={{ marginTop: '10px' }}>
                    <Table title="ACL - COPEL" data={monthData?.COPEL} />
                  </div>
                   <div style={{ marginTop: '10px' }}>
                    <Table title="ACL - TRADENER" data={monthData?.TRADENER} />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <h4>Total do Mês (R$): {convertNumberToDecimalStyle(monthlyTotal)}</h4>
              </div>
            </S.Card>
          );
        })}
      </S.GridCardsContainer>
    </S.CardsSummaryContainer>
  );
}
