import styled from 'styled-components';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

export const SummaryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ecf0f5;
  width: 20%;
  height: 400px;
  border-radius: 10px;
  overflow-y: scroll;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const TitleList = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: var(--primary);
  margin: 10px 0 0 15px;
`;

export const SummaryIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  transition: 0.3s ease;
  border-radius: 10px;
`;

export const ListItemTextStyled = styled(ListItemText)`
  font-size: 20px;
`;

export const ListStyled = styled(ListItem)`
  transition: 0.3s;
  margin-bottom: 35px;
  &:hover {
    background-color: #fff;
    border-radius: 10px;
    border-left: 2px solid var(--primary);

    ${SummaryIconWrapper} {
      width: 42px;
      height: 42px;
      background-color: var(--secondary);
    }
  }
`;

export const ListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;
