const LocalStorage = {};

LocalStorage.storageSession = (session) => {
  const sessionAsJSON = JSON.stringify(session);
  localStorage.setItem('session', sessionAsJSON);
};

LocalStorage.storageSessionDynamoData = (sessionDynamoData) => {
  const sessionDynamoDataAsJSON = JSON.stringify(sessionDynamoData);
  localStorage.setItem('sessionDynamoData', sessionDynamoDataAsJSON);
};

LocalStorage.storageClientData = (clientData) => {
  const clientDataAsJSON = JSON.stringify(clientData);
  localStorage.setItem('clientData', clientDataAsJSON);
};

LocalStorage.storageUnityData = (unityData) => {
  const unityDataAsJSON = JSON.stringify(unityData);
  localStorage.setItem('unityData', unityDataAsJSON);
};

LocalStorage.getSession = () => {
  const session = localStorage.getItem('session');
  return JSON.parse(session);
};

LocalStorage.getSessionDynamoData = () => {
  const session = localStorage.getItem('sessionDynamoData');
  return JSON.parse(session);
};

LocalStorage.getClientData = () => {
  const clientData = localStorage.getItem('clientData');
  return JSON.parse(clientData);
};

LocalStorage.getUnityData = () => {
  const unityData = localStorage.getItem('unityData');
  return JSON.parse(unityData);
};

LocalStorage.removeAll = () => {
  return localStorage.clear();
};

export default LocalStorage;
