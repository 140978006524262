import React, { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AccountContext } from 'common/contexts/Accounts';
import { Grid } from '@material-ui/core';
import { GridContainer } from 'components/IoseDashboardInfoSwitchboard/style';
import ioseApiLib from 'iose-api-lib';
import IoseDashboardAnalysisCard from 'components/IoseDashboardAnalysisCard';
import IoseButtonIcon2 from 'components/IoseButtonIcon2';
import IoseDashboardListCircuits from 'components/IoseDashboardListCircuits';
import IoseDashboardInfoSwitchboard from 'components/IoseDashboardInfoSwitchboard';
import IoseDashboardInfoCircuitsSelected from 'components/IoseDashboardInfoCircuitsSelected';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';
import { IoseEditButton } from 'components/IoseButtonIcon';
import LocalStorage from 'common/local_storage_api';
import messages from 'common/Messages';
import UnityIcon from './img/Unity.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { useState } from 'react';
import { WebSocketEndPoint, ioseAPI } from 'common/ioseAPI';
import {
  GridStyled,
  GridStyledList,
  IoseContainerStyled,
  WrappedContainer,
} from './style';
import { ioseIotAPI } from 'common/ioseIotAPI';

export default function SwitchboardDashboardContainer() {
  const { state } = useLocation();
  const { circuitsOfGroup, groupsData, uuid_unity } = state;

  const unityName = groupsData[0].unity;
  const unityUuid = groupsData[0].uuid_unity;
  const groupName = groupsData[0].name;
  const groupUuid = groupsData[0].uuid_group;
  const tariff_period = 1;
  const goal = 10;

  const switchboardData = {
    uuid_group: groupUuid,
    circuits: circuitsOfGroup,
    name: groupName,
    uuid_unity: unityUuid,
    description: 'teste',
    goal: 10,
  };

  let socket = {};

  const current_year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  const current_day = new Date().getDate();
  const month = current_day < tariff_period ? current_month - 1 : current_month;
  const defaultInitialDay = tariff_period
    ? new Date(current_year, month, tariff_period)
    : new Date();

  //================= STATES ================ //

  const [initialDate, setInitialDate] = useState(defaultInitialDay);
  const [initialDateHistoric, setInitialDateHistoric] = useState(initialDate);

  const [finalDate, setFinalDate] = useState(
    new Date(
      new Date(defaultInitialDay).setMonth(
        new Date(defaultInitialDay).getMonth() + 1
      )
    ).toISOString()
  );
  const [finalDateHistoric, setFinalDateHistoric] = useState(finalDate);

  const [allMeasuresError, setAllMeasuresError] = useState('');
  const [circuits, setCircuits] = useState(switchboardData.circuits);
  const [chartMode, setChartMode] = useState('Acumulado');
  const [chartType, setChartType] = useState('Sem Geração');
  const [dataTableHistoric, setDataTableHistoric] = useState([]);
  const [dataChanged, setDataChanged] = useState({});
  const [description, setDescription] = useState(switchboardData.description);
  const [flag, setflag] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [getInfoError, setGetInfoError] = useState('');
  const [getFlagError, setGetFlagError] = useState('');
  const [consumptionTotal, setConsumptionTotal] = useState(0);
  const [lastMeasuresError, setLastMeasuresError] = useState('');
  const [loadingSeeHistoric, setLoadingSeeHistoric] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [measures, setMeasures] = useState([]);
  const [money_consumption, setMoneyConsumption] = useState(0);
  const [modifiedChart, setModifiedChart] = useState(false);
  const [pageCodeHistoricTable, setPageCodeHistoricTable] = useState([]);
  const [preparedData, setPreparedData] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [totalAddFlag, setTotalAddFlag] = useState(0);
  const [triggerCircuit, setTriggerCircuit] = useState({});
  const [limitsChartBar, setlimitsChartBar] = useState({
    positive: 200,
    negative: 200,
  });
  const [
    consumptionTotalSwitchboard,
    setConsumptionTotalSwitchboard,
  ] = useState(0);
  const [circuitsSelected, setcircuitsSelected] = useState(
    switchboardData.circuits
  );
  const [
    moneyConsumptionSwitchboard,
    setMoneyConsumptionSwitchboard,
  ] = useState(0);

  //================= EFFECTS ================ //

  // Change circuit list when circuits change
  useEffect(() => {
    getLastMeasureAllCircuits();
    getInfoCircuits();
    setHistoricDate(initialDate, finalDate);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitsOfGroup, initialDate, finalDate]);

  useEffect(() => {
    getLastFlag();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update states when change switchbards
  useEffect(() => {
    setFirstLoad(true);
    setCircuits(switchboardData.circuits);
    setDescription(switchboardData.description);
    socket.readyState === 1 && dashSubscribeWebSocket(socket);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsData]);

  //Prepare Data to send Charbar always first load
  useEffect(() => {
    if (!firstLoad) {
      prepareData();
      changeChart('', true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad, circuitsSelected, measures, chartMode]);

  // Desconect websocket when out from Dashboard and listen server
  useEffect(() => {
    if (circuits.lenght !== 0) {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      socket = new WebSocket(WebSocketEndPoint);

      if (socket.readyState !== 1) {
        socket.onopen = (e) => {
          dashSubscribeWebSocket(socket);
        };
      }

      socket.onmessage = (e) => {
        let websocketMessage = JSON.parse(e.data);

        setTriggerCircuit(websocketMessage);
        updateStateCircuit(websocketMessage);
      };

      return () => {
        socket.close();
      };
    }
  }, [circuits]);
  //================= EFFECTS ================ //

  const sessionData = LocalStorage.getSession();
  const userGroup = sessionData.group;
  const { getSession } = useContext(AccountContext);

  const history = useHistory();

  function redirectToGlobalPage() {
    if (userGroup === 'super') {
      history.push('/global/super');
    } else {
      history.push('/global/client');
    }
  }

  function errorHandlingDeleteSwitchboard(error) {
    alert.show(error ? error : `${messages.errorDeleteSwitchboard}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  const deleteSwitchboard = () => {
    getSession()
      .then(({ headers }) => {
        ioseAPI
          .deleteGroup(headers, groupUuid)
          .then(() => {
            alert.show(`${messages.successDeleteSwitchboard}`, {
              title: 'Sucesso!',
              closeCopy: 'Fechar',
            });
            // setGetcards(true);
          })
          .catch((error) => {
            errorHandlingDeleteSwitchboard(error.response.data.message);
          });
      })
      .catch((error) => {
        errorHandlingDeleteSwitchboard();
      });
  };

  const button = (
    <IoseButtonIcon2
      icon1={UnityIcon}
      icon2={<ArrowForwardIcon />}
      click={redirectToGlobalPage}
    />
  );

  function setDate(initialDateCard, finalDateCard) {
    setInitialDate(initialDateCard);
    setFinalDate(finalDateCard);
  }

  function setHistoricDate(initialDateCard, finalDateCard) {
    setInitialDateHistoric(initialDateCard);
    setFinalDateHistoric(finalDateCard);
  }

  async function getLastMeasureAllCircuitsRequisition(circuits, headers) {
    const dateinitial = new Date(Date.parse(initialDate));
    const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
    const initialDateZeroHour = new Date(
      initialDateHistoricConverted
    ).toISOString();

    const datefinal = new Date(Date.parse(finalDate));
    const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
    const finalDateZeroHour = new Date(
      finalDateHistoricConverted
    ).toISOString();

    const lastMeasuresAllCircuit = circuits.map((circuit) =>
      ioseIotAPI.getLastMeasure(
        headers,
        circuit.uuid_circuit,
        initialDateZeroHour,
        finalDateZeroHour
      )
    );
    return Promise.all(lastMeasuresAllCircuit);
  }

  async function getLastMeasureAllCircuits() {
    getSession().then(({ headers }) => {
      getLastMeasureAllCircuitsRequisition(circuits, headers)
        .then((data) => {
          setMeasures(data);
          setFirstLoad(false);
          setLoadingChart(false);
          setLastMeasuresError('');
        })
        .catch(() => {
          setLoadingChart(false);
          setLastMeasuresError('Erro ao buscar medidas de algum circuito');
        });
    });
  }

  async function getAllMeasureAllCircuitsRequisition(
    circuits,
    headers,
    firstTime
  ) {
    if (firstTime) {
      const dateinitial = new Date(Date.parse(initialDateHistoric));
      const initialDateHistoricConverted = dateinitial.setHours(0, 0, 0);
      const initialDate = new Date(initialDateHistoricConverted).toISOString();

      const datefinal = new Date(Date.parse(finalDateHistoric));
      const finalDateHistoricConverted = datefinal.setHours(0, 0, 0);
      const finalDate = new Date(finalDateHistoricConverted).toISOString();

      const allDataMeasuresCircuits = circuits.map((circuit) =>
        ioseIotAPI.getAllMeasure(
          headers,
          circuit.uuid_circuit,
          initialDate,
          finalDate,
          0,
          1000
        )
      );

      return Promise.all(allDataMeasuresCircuits);
    } else {
      let allDataMeasuresCircuits = [];

      for (let i = 0; i < circuits.length; i++) {
        const circuit = circuits[i];

        let nextpage = pageCodeHistoricTable.filter(
          (pageCode) => pageCode.uuid_circuit === circuit.uuid_circuit
        );

        nextpage.length !== 0 &&
          allDataMeasuresCircuits.push(
            ioseIotAPI.getAllMeasure(
              headers,
              circuit.uuid_circuit,
              initialDateHistoric,
              finalDateHistoric,
              nextpage[0],
              1000
            )
          );
      }

      return Promise.all(allDataMeasuresCircuits);
    }
  }

  async function getAllMeasuresAllCircuit(firstTime) {
    setLoadingSeeHistoric(true);

    const getHeader = () => {
      getSession().then(({ headers }) => {
        getAllMeasureAllCircuitsRequisition(circuits, headers, firstTime)
          .then((data) => {
            setLoading(false);
            prepareDataHistoricTable(data, firstTime);
            setAllMeasuresError('');
          })
          .catch((error) => {
            setLoading(false);
            setAllMeasuresError('Erro ao buscar medidas de algum circuito');
          });
      });
    };

    if (firstTime) {
      setLoading(true);
      setPageCodeHistoricTable([]);
      getHeader();
    } else {
      pageCodeHistoricTable.length !== 0
        ? getHeader()
        : setLoadingSeeHistoric(false);
    }
  }

  //Function to get consumption and vaule each circuit
  async function getInfoCircuits() {
    setGetInfoError('');

    const allcircuitsdIdArray = circuits.map((circuit) => {
      return circuit.uuid_circuit;
    });

    try {
      const sessionData = await getSession();

      const response = await ioseIotAPI.getTariffBasedUnityType(
        sessionData.headers,
        uuid_unity,
        allcircuitsdIdArray,
        initialDate,
        finalDate
      );

      let allInfo = response.data;

      setTotalAddFlag(allInfo.totalAddFlag);

      return { result: true, message: allInfo };
    } catch (error) {
      let messageErro = error
        ? error.response.data.message
        : 'Erro ao buscar dados dos circuitos!';

      setGetInfoError(messageErro);
      return { result: false, message: messageErro };
    }
  }

  async function getLastFlag() {
    setGetFlagError('');

    try {
      const sessionData = await getSession();

      const response = await ioseIotAPI.getLastFlag(sessionData.headers);

      let flagLocal = response.data.data.flag;

      setflag(flagLocal);

      return { result: true, message: flagLocal };
    } catch (error) {
      const messageErro = error.reponse
        ? error.response.data.message
        : 'Erro ao buscar bandeira!';

      setGetFlagError(messageErro);
      return { result: false, message: messageErro };
    }
  }

  const convertToNegative = (value) => {
    return value !== 0 ? -1 * value : 0;
  };

  //Function for prepare data before send to ChartBar
  function prepareData() {
    //Ids selected to prepare data
    const id = circuitsSelected.map((circuit) => {
      return circuit.uuid_circuit;
    });

    //Array to guard objects json to form DataChartBar
    let chartData = [];
    let chartDataDaily = [];

    //Select measures according id circuits selecteds
    let selectedMeasures = id.map((idCircuit) => {
      return measures.filter(
        (selected) => selected.data.data.uuid_circuit === idCircuit
      )[0];
    });

    selectedMeasures = selectedMeasures.map((measure) => measure.data);

    if (selectedMeasures.length > 0) {
      const arrayDate = selectedMeasures[0].data.measures.dates;

      // Sum all generate data
      let arrayGenerated = selectedMeasures.map(
        (data) => data.data.measures.arrayGenerate
      );
      arrayGenerated = arrayGenerated.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all consumed data
      let arrayConsumed = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumed
      );

      arrayConsumed = arrayConsumed.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Sum all predicted data
      let arrayConsumedPredicted = selectedMeasures.map(
        (data) => data.data.measures.arrayConsumedPredicted
      );
      arrayConsumedPredicted = arrayConsumedPredicted.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );

      //Form a object to accumulate mode chart
      for (let i = 0; i < arrayDate.length; i++) {
        let arrayDateConverted = new Date(arrayDate[i]);
        let month = arrayDateConverted.getMonth() + 1;
        let day = arrayDateConverted.getDate();

        const dateFormated = `${day}/${('00' + month).slice(-2)}`;

        chartData.push({
          'date': dateFormated,
          'consumido': convertToKWH(arrayConsumed[i]),
          'gerado': convertToKWH(arrayGenerated[i]),
          'balanço': convertToKWH(arrayConsumed[i] - arrayGenerated[i]),
          'previsao': convertToKWH(arrayConsumedPredicted[i]),
        });
      }

      //Form a object to daily mode chart
      chartDataDaily = chartData.map((currentDay, index) => {
        const lastDay = chartData[index - 1];

        const arrayDate = currentDay.date.split('/');
        const defaultData = new Date();
        defaultData.setDate(arrayDate[0]);
        defaultData.setMonth(arrayDate[1] - 1);
        const lessThanToday = defaultData <= new Date();

        let newConsumido = 0;
        let newGenerated = 0;
        let newBalance = 0;

        if (lastDay && lessThanToday) {
          newConsumido = Number(
            (currentDay.consumido - lastDay.consumido).toFixed(2)
          );

          newGenerated = Number(
            (currentDay.gerado - lastDay.gerado).toFixed(2)
          );

          newBalance = Number(
            (currentDay.balanço - lastDay.balanço).toFixed(2)
          );
        } else {
          newConsumido = currentDay.consumido;
          newGenerated = currentDay.gerado;
          newBalance = currentDay.balanço;
        }

        return {
          'date': currentDay.date,
          'consumido': newConsumido,
          'gerado': newGenerated,
          'balanço': newBalance,
          'previsao': 0,
        };
      });

      getLimitsChartValue(chartMode === 'Diário' ? chartDataDaily : chartData);

      if (chartType === 'Sem geração') {
        setPreparedData(chartMode === 'Diário' ? chartDataDaily : chartData);
      } else {
        let newChartDataDaily = [...chartDataDaily];
        newChartDataDaily = newChartDataDaily.map((data) => {
          data.gerado = convertToNegative(data.gerado);

          return data;
        });

        let newChartData = [...chartData];
        newChartData = newChartData.map((data) => {
          data.gerado = convertToNegative(data.gerado);
          return data;
        });

        setPreparedData(
          chartMode === 'Diário' ? newChartDataDaily : newChartData
        );
      }
    } else {
      setPreparedData([]);

      if (circuits.length === 0) {
        setConsumptionTotalSwitchboard(0);
        setMoneyConsumptionSwitchboard(0);
        setConsumptionTotal(0);
        setMoneyConsumption(0);
      }
    }
  }

  function getLimitsChartValue(data) {
    let maxValue = 0;

    const compareValues = (a, b) => a - b;

    const keyValueArray = Object.entries(data);

    const arrayConsump = keyValueArray
      .map((element) => element[1].consumido)
      .sort((a, b) => compareValues(a, b));

    const arrayGenerate = keyValueArray
      .map((element) => element[1].gerado)
      .sort((a, b) => compareValues(a, b));

    const arrayBalance = keyValueArray
      .map((element) => element[1].balanço)
      .sort((a, b) => compareValues(a, b));

    const maxValueConsump = arrayConsump[arrayConsump.length - 1];
    const maxValueGenerate = arrayGenerate[arrayGenerate.length - 1];
    const maxValueBalance = arrayBalance[arrayBalance.length - 1];

    if (maxValueConsump > maxValueGenerate) {
      maxValue = maxValueConsump;
    } else {
      maxValue =
        maxValueGenerate > maxValueBalance ? maxValueGenerate : maxValueBalance;
    }

    const limits = {
      positive: maxValue + 5,
      negative: (maxValue + 5) * -1,
    };

    setlimitsChartBar(limits);
  }

  //Function for prepare data before send Historic/Measure Table
  function prepareDataHistoricTable(data, firstTime) {
    const preparedDataHistoricTable = [];
    const nextPagesArray = [];

    for (let j = 0; j < data.length; j++) {
      const value = data[j];

      if (typeof value.data.next_page !== 'undefined') {
        nextPagesArray.push(value.data.next_page);
      } else {
        setLoadingSeeHistoric(false);
      }

      const circuit = circuits.filter(
        (circuitSelected) => value.data.uuid_circuit === circuitSelected.uuid
      );

      const circuitName = circuit[j].name;
      const circuitMeasures = value.data.data.measures;

      if (circuitMeasures.length !== 0) {
        for (let i = 0; i < circuitMeasures.length; i++) {
          preparedDataHistoricTable.push({
            'circuit': circuitName,
            'uuid': circuitMeasures[i].uuid_measure,
            'active_power': circuitMeasures[i].active_power,
            'apparent_power': circuitMeasures[i].apparent_power,
            'line_frequency': circuitMeasures[i].line_frequency,
            'power_factor': circuitMeasures[i].power_factor,
            'reactive_power': circuitMeasures[i].reactive_power,
            'relay_state': circuitMeasures[i].relay_state,
            'voltage': circuitMeasures[i].voltage,
            'data': circuitMeasures[i].created_at,
            'current': circuitMeasures[i].current,
            'export_active_energy': convertToKWH(
              circuitMeasures[i].export_active_energy
            ),
            'export_reactive_energy': convertToKWH(
              circuitMeasures[i].export_reactive_energy
            ),
            'import_active_energy': convertToKWH(
              circuitMeasures[i].import_active_energy
            ),
            'import_reactive_energy': convertToKWH(
              circuitMeasures[i].import_reactive_energy
            ),
          });
        }
      }
    }
    setPageCodeHistoricTable(nextPagesArray);

    if (firstTime) {
      setDataTableHistoric(preparedDataHistoricTable);
    } else {
      setDataTableHistoric(dataTableHistoric.concat(preparedDataHistoricTable));
    }
  }

  function convertToKWH(measuresWatts) {
    const measuresConverted = Number((measuresWatts / 1000).toFixed(2));
    return measuresConverted;
  }

  const getUuids = (group, userId) => {
    switch (group) {
      case 'admin':
        return { uuidEmployee: '', uuidAdmin: userId };

      case 'manager' || 'employee':
        return { uuidEmployee: userId, uuidAdmin: '' };

      default:
        return { uuidEmployee: '', uuidAdmin: '' };
    }
  };

  //Function to TRIGGER CIRCUIT
  async function triggerCircuitAction(
    uuid_circuit,
    state_actuation,
    justification
  ) {
    const group = sessionData.group;
    const userId = sessionData.userId;

    const { uuidEmployee, uuidAdmin } = getUuids(group, userId);

    try {
      const response = await ioseApiLib.Circuit.triggerCircuit(
        uuid_circuit,
        state_actuation,
        uuidEmployee,
        uuidAdmin,
        justification
      );
      return { result: true, message: response.data.message };
    } catch (err) {
      const message = err.response
        ? err.response.data.name
        : messages.errorTrigger;

      return { result: false, message: message };
    }
  }

  //Function to Subscribe in Websocket
  function dashSubscribeWebSocket(socket) {
    socket.send(
      JSON.stringify({
        'action': 'subscribeGroup',
        'data': { 'uuid_group': groupUuid },
      })
    );
  }

  function updateStateCircuit(websocketMessage) {
    // const allCircuits = location.state.switchboardData.circuits;

    if (websocketMessage.type === 'activation') {
      const circuitselected = circuitsOfGroup.filter(
        (circuit) => circuit.uuid_circuit === websocketMessage.uuid_circuit
      );

      circuitselected[0].relay_state = websocketMessage.state_circuit;
    }
  }

  const changeChart = (column, close = false) => {
    const data = preparedData;

    if (!close) {
      setShowButton(true);

      const changeData = data.map((item) => {
        let defaultObj = {
          balanço: 0,
          consumido: 0,
          date: item.date,
          gerado: 0,
          previsao: 0,
        };

        const arrayKeys = Object.keys(item);

        for (let key of arrayKeys) {
          if (key === column) {
            defaultObj[key] = item[key];
          }
        }

        return defaultObj;
      });

      setDataChanged(changeData);
      setModifiedChart(true);
    } else {
      setModifiedChart(false);
      setShowButton(false);
    }
  };

  return (
    <WrappedContainer>
      <IoseContainerStyled>
        <IoseSubHeaderBar
          title={unityName}
          subtitle={groupName}
          button={button}
          description={description}
          dashboard="true"
        >
          {userGroup === 'super' || userGroup === 'admin' ? (
            <>
              <IoseDeleteInputModal
                title="DELETAR QUADRO"
                name={unityName}
                deletefunc={deleteSwitchboard}
                param={groupUuid}
                primarybutton={'true'}
              />
              <IoseEditButton
                left="20px"
                primary="true"
                tooltip="Editar Quadro"
              />
            </>
          ) : (
            <></>
          )}
        </IoseSubHeaderBar>

        <GridContainer
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <GridStyled item xs={12} sm={12} md={12}>
            <Grid container direction="row">
              <GridStyledList item xs={12} sm={5} md={3}>
                <IoseDashboardListCircuits
                  circuits={circuitsOfGroup}
                  selectCircuits={(e) => setcircuitsSelected(e)}
                  disabledList={false}
                />
              </GridStyledList>

              <GridStyled item xs={12} sm={7} md={9}>
                <IoseDashboardInfoSwitchboard
                  consumption={consumptionTotalSwitchboard}
                  money={moneyConsumptionSwitchboard}
                  flag={flag}
                  totalAddFlag={totalAddFlag}
                  getFlagError={getFlagError}
                />
                <IoseDashboardInfoCircuitsSelected
                  consumptionTotal={consumptionTotal}
                  moneyConsumption={money_consumption}
                />
              </GridStyled>

              <GridStyled item xs={12} sm={12} md={12}>
                <IoseDashboardAnalysisCard
                  dataTable={() => {}}
                  dataChartBar={preparedData}
                  setDate={setDate}
                  setHistoricDate={setHistoricDate}
                  setPageCodeHistoricTable={setPageCodeHistoricTable}
                  setDataTableHistoric={setDataTableHistoric}
                  getAllMeasuresAllCircuit={getAllMeasuresAllCircuit}
                  dataTableHistoric={dataTableHistoric}
                  loading={loading}
                  loadingChart={loadingChart}
                  goal={goal}
                  switchboardData={switchboardData}
                  initialDateHistoric={initialDateHistoric}
                  finalDateHistoric={finalDateHistoric}
                  lastMeasuresError={lastMeasuresError}
                  allMeasuresError={allMeasuresError}
                  getInfoError={getInfoError}
                  triggerCircuit={triggerCircuit}
                  triggerCircuitAction={triggerCircuitAction}
                  chartMode={chartMode}
                  setChartMode={setChartMode}
                  chartType={chartType}
                  setChartType={setChartType}
                  initialDateContainer={initialDate}
                  finalDateContainer={finalDate}
                  circuits={circuitsSelected}
                  limitsChartBar={limitsChartBar}
                  loadingSeeHistoric={loadingSeeHistoric}
                  changeChart={changeChart}
                  modifiedChart={modifiedChart}
                  dataChanged={dataChanged}
                  showButton={showButton}
                />
              </GridStyled>
              <GridStyled item xs={12} sm={12} md={12}></GridStyled>
            </Grid>
          </GridStyled>
        </GridContainer>
      </IoseContainerStyled>
    </WrappedContainer>
  );
}
