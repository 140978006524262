import { useEffect, useState } from 'react';
import { useGroupPermissions } from 'hooks';
import { LocalStorage } from 'common';
import Permissions from 'common/permissions';


export default function usePermission(permissionPath) {
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    const permission = checkPermissions(userSession, groupPermissions, permissionPath);
    setHasPermission(permission);
  }, [userSession, groupPermissions, permissionPath]);

  return hasPermission;
}

function checkPermissions(userSession, groupPermissions, permissionPath) {
  if (userSession && groupPermissions) {
    return Permissions.hasPermissions(userSession, groupPermissions, permissionPath);
  }
  return false;
}
