import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useTributos() {
  const [noTributos, setSemtributos] = useState('');

  const { getSession } = useContext(AccountContext);

  const obterValoresTributarios = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.obterValoresTributarios(headers);
    const { data } = response.data;

    const tributos = data || [];
    if (tributos.length === 0) {
      setSemtributos(Messages.noFindTributos);
      throw new Error(Messages.noFindTributos);
    }
    return tributos;
  };

  const {
    data: tributosTodos,
    error: noTributosError,
    refetch: refreshTributos,
    isLoading,
  } = useQuery(['tributos'], obterValoresTributarios);

  return {
    tributosTodos,
    noTributosError,
    noTributos,
    refreshTributos,
    isLoading,
  };
}

export default useTributos;
