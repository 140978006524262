export const referentes = [
  'Demanda Ultrap. PTA',
  'Demanda Ultrap. FP',
  'Demanda EXC. Reativa',
  'Demanda Ultrap.',
  'Adicional de demanda',
  'Adicional de demanda PTA',
  'Adicional de demanda FP',
  'Demanda EXC. Reativa PTA',
  'Demanda EXC. Reativa FP',
  'Consumo Exc. Reativo FP',
  'Consumo Exc. Reativo PTA',
  'Consumo Exc. Reativo',
].map((item) => ({ label: item, value: item }));
