import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2 } from 'components';
import GetAppIcon from '@material-ui/icons/GetApp';
import { xlsxReports } from 'reports/Xlsx';

function aggregateGreenDemandByMonth(data) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;
    let demandaContratada;

    if (item.demanda_contratada_fp === 0) {
      demandaContratada = item.demanda_contratada * 1000;
    } else {
      demandaContratada = item.demanda_contratada_fp * 1000;
    }
    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        demandaContratada: demandaContratada,
        demanda_faturada: 0,
      };
    }

    if (item.referente === 'Demanda') {
      aggregatedData[month].demanda_faturada += item.referente_consumption;
    } else if (item.referente === 'Demanda FP') {
      aggregatedData[month].demanda_faturada += item.referente_consumption;
    }
  });

  const aggregatedArray = Object.values(aggregatedData);

  aggregatedArray.sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);

    return dateA - dateB;
  });

  return aggregatedArray;
}

function aggregateBlueDemandByMonth(data) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        demanda_fp: 0,
        demanda_pta: 0,
      };
    }

    if (item.referente === 'Demanda FP') {
      aggregatedData[month].demanda_fp += item.referente_consumption;
    } else if (item.referente === 'Demanda PTA') {
      aggregatedData[month].demanda_pta += item.referente_consumption;
    }
  });

  const aggregatedArray = Object.values(aggregatedData);

  aggregatedArray.sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);

    return dateA - dateB;
  });

  return aggregatedArray;
}

export const GraficoDemandaRf12 = ({ data, unidadeConsumidora }) => {
  const [formattedDemand, setFormattedDemand] = useState([]);
  const uc = unidadeConsumidora ? unidadeConsumidora[0] : null;

  useEffect(() => {
    if (data && data.length > 0) {
      if (data[0].modalidade === 'VERDE') {
        setFormattedDemand(aggregateGreenDemandByMonth(data));
      } else if (data[0].modalidade === 'AZUL') {
        setFormattedDemand(aggregateBlueDemandByMonth(data));
      }
    }
  }, [data]);

  const months = formattedDemand.map((item) => item.reference_month);
  const demandaPta = formattedDemand.map((item) => item.demanda_pta);
  const demandaFp = formattedDemand.map((item) => item.demanda_fp);
  const demanda = formattedDemand.map((item) => item.demanda_faturada);
  const demandaContratadaFp = uc?.demanda_fora;
  const demandaContratadaEnergisa = uc?.demanda;
  let demandaContratada;
  if (demandaContratadaFp === 0) {
    demandaContratada =
      uc?.modalidade === 'VERDE' ? demandaContratadaEnergisa : null;
  } else {
    demandaContratada = uc?.modalidade === 'VERDE' ? demandaContratadaFp : null;
  }
  const demandaContratadaPta = uc?.demanda_ponta;

  const toleranciaContratadaFp = uc?.demanda_fora * 1.05;
  const toleranciaContratadaPta = uc?.demanda_ponta * 1.05;
  const toleranciaContratada =
    uc?.modalidade === 'VERDE' ? demandaContratada * 1.05 : null;

  const markLines = [];

  if (uc?.modalidade === 'AZUL') {
    if (demandaContratadaPta) {
      markLines.push({
        yAxis: demandaContratadaPta,
        label: { formatter: 'Contratada PTA' },
        lineStyle: {
          type: 'dashed',
          width: 4,
        },
        z: 10,
      });
    }
    if (toleranciaContratadaPta) {
      markLines.push({
        yAxis: toleranciaContratadaPta,
        label: { formatter: 'Tolerância PTA' },
        lineStyle: {
          type: 'solid',
          width: 2,
        },
        z: 5,
      });
    }
    if (demandaContratadaFp) {
      markLines.push({
        yAxis: demandaContratadaFp,
        label: { formatter: 'Contratada FP' },
        lineStyle: {
          type: 'solid',
          width: 2,
        },
      });
    }
    if (toleranciaContratadaFp) {
      markLines.push({
        yAxis: toleranciaContratadaFp,
        label: { formatter: 'Tolerância FP' },
        lineStyle: {
          type: 'dashed',
          width: 4,
        },
      });
    }
  }

  if (uc?.modalidade === 'VERDE') {
    if (toleranciaContratada) {
      markLines.push({
        yAxis: toleranciaContratada,
        label: { formatter: 'Tolerância' },
        lineStyle: {
          type: 'dashed',
          width: 3,
        },
      });
    }
    if (demandaContratada) {
      markLines.push({
        yAxis: demandaContratada,
        label: { formatter: 'Contratada' },
        lineStyle: {
          type: 'dashed',
          width: 3,
        },
      });
    }
  }

  const series = [];

  if (uc?.modalidade === 'AZUL') {
    series.push(
      {
        name: 'Demanda Faturada PTA (kW)',
        type: 'bar',
        data: demandaPta,
        barWidth: 20,
      },
      {
        name: 'Demanda Faturada FP (kW)',
        type: 'bar',
        data: demandaFp,
        barWidth: 20,
      },
      {
        name: 'Tolerância FP (kW)',
        type: 'line',
        color: '#FF0000',
        data: [toleranciaContratadaFp],
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Tolerância FP'
          ),
        },
      },
      {
        name: 'Tolerância PTA (kW)',
        type: 'line',
        data: [toleranciaContratadaPta],
        color: '#C70039',
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Tolerância PTA'
          ),
        },
      },
      {
        name: 'Contratada PTA (kW)',
        type: 'line',
        data: [demandaContratadaPta],
        color: '#FF7800',
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Contratada PTA'
          ),
        },
      },
      {
        name: 'Contratada FP (kW)',
        type: 'line',
        data: [demandaContratadaFp],
        color: '#F1AD70',
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Contratada FP'
          ),
        },
      }
    );
  }
  if (uc?.modalidade === 'VERDE') {
    series.push(
      {
        name: 'Demanda Faturada (kW)',
        type: 'bar',
        data: demanda,
        barWidth: 30,
      },
      {
        name: 'Contratada (kW)',
        type: 'line',
        color: '#FF7F7F',
        data: [demandaContratada],
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Contratada'
          ),
        },
      },
      {
        name: 'Tolerância (kW)',
        type: 'line',
        data: [toleranciaContratada],
        color: '#FF0000',
        itemStyle: {
          normal: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        markLine: {
          data: markLines.filter(
            (line) => line.label.formatter === 'Tolerância'
          ),
        },
      }
    );
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedParams = params.map((param) => {
          const formattedValue =
            typeof param.value === 'string'
              ? parseFloat(param.value)?.toLocaleString('pt-BR')
              : param.value?.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });

        const xAxisValue = params[0]?.axisValueLabel;

        formattedParams.unshift(`<strong>Data:</strong> ${xAxisValue}`);

        return formattedParams.join('<br>') || 'Sem dados';
      },
    },
    legend: {
      data: series.map((serie) => serie.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    grid: {
      width: '92%',
      left: '2%',
      right: '2%',
      top: '10%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: months,
      axisLabel: {
        interval: 0,
        fontSize: 10
      },
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        formatter: (value) => value.toLocaleString('pt-BR'),
      },
    },
    series: series,
  };

  return (
    <div>
      {formattedDemand && formattedDemand.length > 0 && (
        <div style={{
          width: '100%',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'end'
        }}>
          <IoseButton2
            onClick={() =>
              xlsxReports.exportResumoGraficoDemandaRf12(formattedDemand, uc?.identification_number)}
            width="80px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      )}
      <div>
          {formattedDemand.length > 0 ? (
            <ReactECharts option={option} style={{ height: 475 }} />
          ) : (
            <IoseNoDataMessage
              message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
              height="300px"
              width="280px"
            />
          )}
      </div>
    </div>
  )
};
