import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Imports from Material UI components
import {
  makeStyles,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
} from '@material-ui/core';

//Imports from Style
import {
  TableStyled,
  TableContainerStyled,
  TablePaginationStyled,
  TableCellHeader,
  Container,
  // TableCellRelay,
  Error,
} from './style';

//Functions to order
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'data',
    numeric: false,
    disablePadding: false,
    label: 'Data',
    sort: true,
  },
  {
    id: 'circuit',
    numeric: false,
    disablePadding: false,
    label: 'Circuito',
    sort: true,
  },
  {
    id: 'voltage',
    numeric: true,
    disablePadding: false,
    label: 'Tensão (V)',
    sort: true,
  },
  {
    id: 'current',
    numeric: true,
    disablePadding: false,
    label: 'Corrente (A)',
    sort: true,
  },
  {
    id: 'export_active_energy',
    numeric: true,
    disablePadding: false,
    label: 'Energia Gerada Ativa (kWh)',
    sort: true,
  },
  {
    id: 'import_active_energy',
    numeric: true,
    disablePadding: false,
    label: 'Energia Consumida Ativa (kWh)',
    sort: true,
  },
  {
    id: 'active_power',
    numeric: true,
    disablePadding: false,
    label: 'Potência Ativa (W)',
    sort: true,
  },
  {
    id: 'reactive_power',
    numeric: true,
    disablePadding: false,
    label: 'Potência Reativa (W)',
    sort: true,
  },
  {
    id: 'apparent_power',
    numeric: true,
    disablePadding: false,
    label: 'Potência Aparente (W)',
    sort: true,
  },
  {
    id: 'export_reactive_energy',
    numeric: true,
    disablePadding: false,
    label: 'Energia Gerada Reativa (kWh)',
    sort: true,
  },
  {
    id: 'import_reactive_energy',
    numeric: true,
    disablePadding: false,
    label: 'Energia Consumida Reativa (kWh)',
    sort: true,
  },
  {
    id: 'line_frequency',
    numeric: true,
    disablePadding: false,
    label: 'Frequência (Hz)',
    sort: true,
  },
  {
    id: 'power_factor',
    numeric: true,
    disablePadding: false,
    label: 'Fator de Potência',
    sort: true,
  },
  // {
  //   id: 'relay_state',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Estado do Relé',
  // },
];

//Function to render header
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderTableCellHeader = (headCell) => {
    const cellSortHeader = (
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    );

    const cellHeader = (
      <TableCellHeader
        key={headCell.id}
        sortDirection={orderBy === headCell.id ? order : false}
        align="center"
      >
        {headCell.sort ? (
          cellSortHeader
        ) : (
          <p className="NoSortLabel">{headCell.label}</p>
        )}
      </TableCellHeader>
    );

    return cellHeader;
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => renderTableCellHeader(headCell))}
      </TableRow>
    </TableHead>
  );
}

//Props to render header
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//Css class to hidden the order label
const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    textColor: '#FFFFFF',
  },
}));

/** This component render a responsive table with measures circuit selected, pagination and ordination */
export default function IoseCircuitMeasuresTable({
  rows,
  dashboard,
  getAllMeasuresAllCircuit,
  alltMeasuresError,
  setOrderingCSV,
}) {
  const classes = useStyles();

  //States and Funciton to order the table asc and desc
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('data');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //States and Functions to pagination's table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Const to calc the number empty Rows
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function convertData(dateInfo) {
    const date = new Date(Date.parse(dateInfo));

    const day = String(date.getDate()).padStart(2, '0');
    const mounth = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    const circuitData = `${day}/${mounth}/${year}  ${hour}:${minute}:${second}`;
    return circuitData;
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllMeasuresAllCircuit(false);
      // verificNewRequisition();
    }
    return () => (mounted = false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    setOrderingCSV({
      order: order,
      orderBy: orderBy,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  return (
    <Container>
      <TableContainerStyled>
        <Error>
          {rows.length === 0 &&
            alltMeasuresError === '' &&
            'Sem medidas nesse período!'}
        </Error>
        <TableStyled dashboard={dashboard}>
          {/* Header */}
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          {/* Body */}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow tabIndex={-1} key={row.uuid}>
                    <TableCell component="th" id={labelId} scope="row">
                      {convertData(row.data)}
                    </TableCell>
                    <TableCell align="center">{row.circuit} </TableCell>
                    <TableCell align="center">{row.voltage} </TableCell>
                    <TableCell align="center">{row.current} </TableCell>
                    <TableCell align="center">
                      {row.export_active_energy}
                    </TableCell>
                    <TableCell align="center">
                      {row.import_active_energy}
                    </TableCell>

                    <TableCell align="center">{row.active_power} </TableCell>
                    <TableCell align="center">{row.reactive_power} </TableCell>
                    <TableCell align="center">{row.apparent_power} </TableCell>
                    <TableCell align="center">
                      {row.export_reactive_energy}
                    </TableCell>
                    <TableCell align="center">
                      {row.import_reactive_energy}
                    </TableCell>
                    <TableCell align="center">{row.line_frequency}</TableCell>
                    <TableCell align="center">{row.power_factor}</TableCell>
                    {/* <TableCellRelay
                      align="center"
                      relaystate={
                        row.relay_state ? row.relay_state.toString() : 'false'
                      }
                    >
                      {row.relay_state ? 'Fechado' : 'Aberto'}
                    </TableCellRelay> */}
                  </TableRow>
                );
              })}
            {emptyRows > 10 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>

      {/* Pagination */}
      <TablePaginationStyled
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        dashboard={dashboard}
        labelRowsPerPage={'Medidas por página'}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} ao ${to} de ${count !== -1 ? count : `mais que ${to}`}`
        }
        SelectProps={{
          id: 'stable-select-id',
          labelId: 'stable-select-label-id',
        }}
      />
    </Container>
  );
}

IoseCircuitMeasuresTable.propTypes = {
  /** This props get the info row in measure table, should have this format: <br>
   *
    {
      active_power: 255,
      apparent_power: 455,
      circuit: `Módulo com medidas `,
      current: 20,
      data: "12/02/2021  15:45:00",
      export_active_energy: 0,
      export_reactive_energy: 8.81,
      import_active_energy: 114.6,
      import_reactive_energy: ,
      line_frequency: 60.04,
      power_factor: 0.25,
      reactive_power:0.65 ,
      relay_state: true,
      uuid: `240AC415992`,
      voltage: 220,
    }
   <br>
  */
  rows: PropTypes.array,
  /** This props is a boolean that indicate that table to be in dashboard, adn determinate style is apply it*/
  dashboard: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** This props is a function when invoke get all measure that all circuits in container, it is used to pagination*/
  getAllMeasuresAllCircuit: PropTypes.func,
  /** This props indicate a erro in resquest API*/
  alltMeasuresError: PropTypes.string,
};
