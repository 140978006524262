import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';

export const BoxContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 0px;
  padding: 10px 0 0 0px;
  width: 320px;
  height: 190px;
  border-radius: 10px;
  z-index: 0;
  overflow: hidden;
  scale: 1;

  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:not(:hover) {
    transform: scale(0.9);
  }

  @media (max-width: 1400px) {
    width: 250px;

    &:hover {
      transform: scale(1.01);
    }
  }
`;
export const CardGrid = styled(Grid)`
  margin: 0px;
  display: flex;
  justify-content: space-around;
  gap: 5px;
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  color: var(--primary);
`;

export const InputBaseStyled = styled(InputBase)`
  width: 30%;
  opacity: 80%;
  height: 30px;
  background: white;
  border-radius: 5px;
  padding-left: 10px;
  transition: all 0.3s linear;
  color: black;
  margin-left: 50px;

  @media (max-width: 50px) {
    width: 40%;
    opacity: 100%;
  }
`;

export const CardTitle = styled(Typography)`
  font-size: 15px;
  color: var(--primary);
  font-weight: bold;

  @media (max-width: 1490px) {
    font-size: 13px;
  }
`;

export const Spacer = styled.div`
  height: ${(props) => props.height || '16px'};
`;

export const Separator = styled.hr`
  border: 0.3px solid #4f7cac;
  margin: 16px 0;
  margin-left: auto;
  margin-right: 0;
  width: 32%;
`;

export const CardDataKey = styled(Typography)`
  font-size: 11.7px;
  color: var(--primary);
  font-style: italic;
  font-weight: bold;
  transition: 0.5s ease;
  margin-bottom: 3px;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const CardDataValue = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color: var(--primary);
  transition: 0.5s ease;

  @media (max-width: 1450px) {
    font-size: 11px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 15px 0 15px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const IconHeader = styled.div`
  margin: 0 8px 8px 0;
  color: var(--primary);
`;
