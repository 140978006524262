import React from 'react';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import {
  IoseDownloadButton2,
} from 'components/IoseButtonIcon';

const handleDownloadExtrato = (currentExtract) => {
  const extract = currentExtract[0];
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;

  if (extract) {
    const concessionaire = extract?.concessionaire.toLowerCase();
    const referenceMonth = extract?.reference_month.replace('/', '');
    const pdfNumber = extract?.nome_pdf;

    let downloadLink = `${s3BaseUrl}${referenceMonth}/${concessionaire}E/${pdfNumber}`;

    if (downloadLink) {
      window.open(downloadLink, '_blank');
    }
  }
};

export default function BillsModalExtractSubHeader({ currentExtract }) {
  const extract = currentExtract[0];

  return (
    <>
      <IoseSubHeaderBar
        subtitle={`Detalhamento do extrato de energia da UC ${extract?.identification_number}`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <div>
            <IoseDownloadButton2
              tooltip={'BAIXAR EXTRATO'}
              bottom="5px"
              onClick={() => handleDownloadExtrato(currentExtract)}
            />
          </div>

          {/* <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            // onClick={() => redirectToMapPage()}
            onClick={() => {}}
          /> */}
        </div>
      </IoseSubHeaderBar>
    </>
  );
}
