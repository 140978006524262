import styled from 'styled-components';

export const GridContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ChartsContainer = styled.div`
  display: flex;
`;
