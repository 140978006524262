// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useBeneficiaryJoin() {
  const [noBeneficiaryJoin, setNoBeneficiaryJoin] = useState('');

  const { getSession } = useContext(AccountContext);

  const getBeneficiaryJoin = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllBeneficiaryJoin(headers, 3000, 3000);

    const { data } = response.data;

    const BeneficiaryJoinData = data || [];

    if (BeneficiaryJoinData.length === 0) {
      setNoBeneficiaryJoin(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return BeneficiaryJoinData;
  };

  const {
    data: BeneficiaryJoinData,
    error: BeneficiaryJoinDataError,
    refetch: refreshBeneficiaryJoin,
    isLoading,
  } = useQuery(['BeneficiaryJoin'], getBeneficiaryJoin);

  return {
    BeneficiaryJoinData,
    BeneficiaryJoinDataError,
    noBeneficiaryJoin,
    refreshBeneficiaryJoin,
    isLoading,
  };
}

export default useBeneficiaryJoin;
