import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from '../../common/contexts/Accounts';

//Imports IOSE components
import IoseButton from 'components/Buttons/IoseButton';

//Imports from Style
import { Ilustration, Container } from './style';

import RestoreIcon from '@material-ui/icons/Restore';

export default function NoConnectionPAge() {
  //Get function from AccountContext to avalible if user is logged or not
  const { getGroup, getSession } = useContext(AccountContext);

  //State to show or not the page
  const [status, setStatus] = useState(true);

  //Function useHistory routers works
  const history = useHistory();

  //Function to avaible user session when component was rendered
  useEffect(() => {
    getSession().catch(() => {
      setStatus(false);
    });
  }, [getSession]);

  //Function to go home
  const GoHome = () => {
    getGroup()
      .then((arrayGroups) => {
        arrayGroups.map((name) => {
          return name === 'super'
            ? history.replace('/global/super')
            : history.replace('/global/client');
        });
      })
      .catch((err) => {
        console.log('');
      });
  };

  return (
    <Container>
      {status ? (
        <>
          <Ilustration />
          <h1>OoPS!</h1>
          <h2>Sem conexão!</h2>
          <p>
            Parece que você está sem conexão com a internet.
            <br />
            Verifique a conexão e então click no botão abaixo.
          </p>
          <IoseButton
            onClick={() => {
              GoHome();
            }}
          >
            <RestoreIcon />
          </IoseButton>
        </>
      ) : (
        history.replace('/')
      )}
    </Container>
  );
}
