import styled from 'styled-components';

//Imports from IOSE components
import IoseSideCard from 'components/IoseSideCard';
import IoseTextFieldSidebar from 'components/IoseTextFieldSidebar';

//Imports from Material UI components
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import {
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

//Import from formik
import { Form } from 'formik';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;

  h4 {
    text-align: center;
    font-size: 13px;
    color: var(--base);
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  color: var(--base);
  font-size: 14px !important;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 13px !important;
  }
`;

export const YellowCheck = styled(Checkbox)`
  color: var(--primary);

  &.MuiCheckbox-root {
    color: var(--primary);
  }
`;

export const YellowRadio = styled(Radio)`
  color: var(--secondary);
  width: 25px;
  height: 25px;

  &.MuiRadio-root {
    color: var(--secondary);
  }
`;

export const FormArea = styled.div`
  color: var(--base);
  padding-left: 10%;
  padding-right: 10%;
`;

export const RadioGroupStyled = styled(RadioGroup)`
  margin: 10px 5px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-top: 5px;
  margin-right: 20px;
  .MuiTypography-body1 {
    font-family: 'Montserrat', sans-serif;
    color: var(--base);
    font-size: 12px;
  }
`;

export const EmojiObjectsIconStyled = styled(EmojiObjectsIcon)`
  margin: 8px 15px 0px 0px;
  color: ${(props) => (props.on === 'true' ? 'var(--primary)' : 'var(--dark)')};
`;

export const GridStyled = styled(Grid)`
  color: white;
  font-size: 12px;

  p {
    margin-top: 10px;
  }
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: var(--base);

    margin-top: 20px;
  }
`;

export const IoseTextFieldSidebarStyled = styled(IoseTextFieldSidebar).attrs(
  (props) => ({
    required: true,
  })
)``;

export const Error = styled.span`
  color: red;
  /* font-weight: bold; */
  text-align: center;
  margin-top: 10px;
`;

export const DateTimePickerStyled = styled(DateTimePicker)`
  width: 150px;
  margin: 10px 50px 10px 0px;

  span {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-underline:after {
    border-bottom: 2.5px solid var(--primary);
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInput-input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }

  .MuiInputBase-root input {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 12px;
  }

  .MuiInputBase-root input:after {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-family: 'Montserrat', sans-serif;
    border-bottom: 2px solid #ffff;
    font-size: 18px;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
    font-size: 12px;
  }

  label.Mui-focused {
    color: ${(props) =>
      props.theme === 'black'
        ? 'var(--secondary) !important'
        : 'var(--base) !important'};
  }
`;
