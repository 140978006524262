import styled from 'styled-components';

//Imports from IOSE components
import IoseSideCard from 'components/IoseSideCard';
import { List, ListItem, ListItemText } from '@material-ui/core';

export const Container = styled(IoseSideCard)`
  padding: 16px;
  flex-direction: column;
  align-items: center;

  h4 {
    text-align: center;
    font-size: 13px;
    color: var(--base);
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  color: var(--base);

  div {
    font-weight: 600;
  }
`;

export const ListStyled = styled(List)`
  width: 100%;
  /* max-width: 360px; */
  background: none;
  color: ${(props) =>
    props.color === 'black' ? 'var(--dark)' : 'var(--base)'};
  height: 200px;
  overflow-y: scroll;
  margin-top: 20px;

  cursor: pointer;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const ListItemStyled = styled(ListItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiTypography-colorTextSecondary {
    color: rgb(255, 255, 255, 0.5);
    margin: 0px;
  }
`;
