import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as S from '../style';
import { useConsumerGroup, useCreateLogs } from 'hooks';
import { IoseButton, IoseSelectReferenceMonth } from 'components';
import { itens } from '../data';
import IoseDynamicSelect from 'components/IoseDynamicSelect';
import { AccountContext, ioseAPI } from 'common';
import exportBillItensToExcel from '../excelReport';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const removeEmptyFields = (obj) => {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
};

export default function ItensBillContainerFilters({ loading, emptyResponse }) {
  const { consumerGroupsForSelect } = useConsumerGroup();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const [referenteOptions, setReferenteOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const uniqueValues = new Set();
    itens.forEach((item) => {
      uniqueValues.add(item?.referente);
    });
    const options = Array.from(uniqueValues).map((valor) => ({
      value: valor,
      label: valor,
    }));
    options?.sort((a, b) => a.label.localeCompare(b.label));
    setReferenteOptions(options);
  }, []);

  const getEnergyBillsItems = useCallback(
    async (object) => {
      try {
        setIsLoading(true);
        loading(true);
        const sessionData = await getSession();

        let allData = [];
        let lastEvaluatedKey = null;
        const limit = 10000;

        do {
          const response = await ioseAPI.getFilteredBillItems(
            sessionData.headers,
            object,
            lastEvaluatedKey,
            limit
          );

          const { data, lastEvaluatedKey: newLastEvaluatedKey } = response.data;
          allData = allData.concat(data);
          lastEvaluatedKey = newLastEvaluatedKey;
        } while (lastEvaluatedKey);

        const operacao = 'EMITIR RELATÓRIO';
        const form = 'RELATÓRIO DE ITENS DA FATURA';
        const logContent = {};

        await createLog(operacao, form, logContent);

        if (allData?.length === 0) {
          loading(false);
          emptyResponse(true);
        } else {
          exportBillItensToExcel(allData);
          setIsLoading(false);
          loading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        loading(false);
      }
    },
    //eslint-disable-next-line
    [getSession, loading, emptyResponse]
  );

  const initialValues = {
    identification_number: '',
    referente: '',
    reference_month: '',
  };

  const onSubmit = async (values, { resetForm }) => {
    const filteredValues = removeEmptyFields(values);
    await getEnergyBillsItems(filteredValues);
    resetForm();
    setSelectedDate(null);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <S.FormDivContainer>
            <S.FormDivItem>
              <IoseDynamicSelect
                label="Unidade Consumidora"
                value={values.identification_number}
                onChange={(value) =>
                  setFieldValue('identification_number', value)
                }
                options={consumerGroupsForSelect}
                width="220px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Itens da fatura"
                value={values.referente}
                onChange={(value) => setFieldValue('referente', value)}
                options={referenteOptions}
                width="220px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseSelectReferenceMonth
                label="REF:MÊS/ANO"
                handleChange={(value) => {
                  const formattedDate = formatToBrazilianDate(value);
                  setSelectedDate(value);
                  setFieldValue('reference_month', formattedDate);
                }}
                selectedDate={selectedDate}
              />
            </S.FormDivItem>

            <IoseButton height="35px" type="submit" disabled={isLoading}>
              EMITIR RELATÓRIO
            </IoseButton>
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
