/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  IoseButton,
  LoadingIndicatorDashboard,
} from 'components';

import {
  useClientData,
  useGroupPermissions,
  useNewTariffs,
  useTariffs,
  useTributos
} from 'hooks';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';
import DynamicTable from 'components/DynamicTable';
import CardsSimuladorTarifario from 'components/Cards/cardsSimuladorTarifarios';
import { isNumber } from 'lodash';
import CardsSimuladorTarifarioMercadoLivre from 'components/Cards/cardsSimuladorTarifarioMercadoLivre';

const tributosRecentes = (tributos) => {
  let tributosMaisRecente = {
    pis: 0,
    cofins: 0,
    icms: 0
  }
  let vigencias = []
  tributos?.forEach(tributo => {
    vigencias.push(parseDate(tributo.fim_vigencia))
  })
  if (vigencias.length !== 0) {
    let data = new Date()
    let maisRecente = vigencias.reduce((a, b) =>{
      let aDif = Math.abs(a-data)
      let bDif = Math.abs(b-data)
      return aDif < bDif ? a: b
    })
    tributos?.forEach(tributo => {
      if (parseDate(tributo.fim_vigencia).toISOString() === maisRecente.toISOString()){
        tributosMaisRecente.cofins = tributo.tributo_cofins
        tributosMaisRecente.pis = tributo.tributo_pis
        tributosMaisRecente.icms = tributo.tributo_icms
      }
    })
  }

  return tributosMaisRecente
}

const parseDate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

const filterByValidityPeriod = (data) => {
  const currentDate = new Date();

  const isValid = (item) => {
    const startDate = parseDate(item?.inicio_vigencia);
    const endDate = parseDate(item?.fim_vigencia);
    return startDate <= currentDate && currentDate <= endDate;
  };

  return data?.filter(isValid);
};

const validacaoDados = (dado) => {
  if (isNumber(dado)) {
    return dado
  }
  let stringDado = String(dado)

  if (stringDado !== '') {
    stringDado = stringDado.replace('.', '').replace(',','.')
    const floatDado = parseFloat(stringDado)
    if(isNaN(floatDado)) {
      return 0
    }
    else {
      return floatDado
    }
  }
  else {
    return stringDado
  }
}

const geraDadosBase = () => {
  let meses = [
    'Janeiro', 'Fevereiro', 'Março',
    'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'
  ];
  let dados = meses.map(mes => {
    return {
      mes: mes,
      demanda_ponta: '',
      demanda_fora_ponta: '',
      consumo_ponta: '',
      consumo_fora_ponta: ''
    };
  });
  return dados
}

export default function SimulatorContainer() {
  const { clientData } = useClientData();
  const { groupPermissions } = useGroupPermissions();

  const { tarifas } = useNewTariffs()

  const { tributosTodos } = useTributos();

  const userSession = LocalStorage.getSession();

  const [valoresTributos, setValoresTributos] = useState();

  const [valoresTarifaVerde, setValoresTarifaVerde] = useState();

  const [valoresTarifaAzul, setValoresTarifaAzul] = useState();

  const [valoresTarifaConvencional, setValoresTarifaConvencional] = useState();

  const [valoresTarifaMercadoLivre, setValoresTarifaMercadoLivre] = useState();

  const [valoresTarifa, setValoresTarifas] = useState();

  const [valoresDesconto , setValoresDesconto] = useState();

  const [valoresTarifaMercadoLivreAzul, setValoresTarifaMercadoLivreAzul] = useState();

  const [valoresTarifaMercadoLivreVerde, setValoresTarifaMercadoLivreVerde] = useState();

  const [data, setData] = useState([]);

  const [error, setError] = useState('');


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filteredTariffs = filterByValidityPeriod(tarifas);
    setValoresTarifas(filteredTariffs);
  }, [tarifas]);

  const [hasReadPermission, setHasReadPermission] = useState(true);

  useEffect(()=> {
    const tributos = tributosRecentes(tributosTodos)
    setValoresTributos(tributos)
  }, [tributosTodos]);

  useEffect(()=> {
    const tabelaMeses = geraDadosBase()
    setData(tabelaMeses)
  }, [])

  useEffect(() => {
    const readPath = ['faturas', 'simulador_faturas', 'leitura'];

    if (userSession && groupPermissions) {
      setLoading(true);
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
      setLoading(false);
    }
    setLoading(false);
  }, [userSession, groupPermissions]);


  const handleOnClick = () => {
    const pis = valoresTributos.pis/100
    const cofins = valoresTributos.cofins/100
    const icms = valoresTributos.icms/100
    let valores = []
    data.forEach(valoresMes => {

      valoresMes.consumo_fora_ponta = validacaoDados(valoresMes.consumo_fora_ponta)
      valoresMes.consumo_ponta = validacaoDados(valoresMes.consumo_ponta)
      valoresMes.demanda_fora_ponta = validacaoDados(valoresMes.demanda_fora_ponta)
      valoresMes.demanda_ponta = validacaoDados(valoresMes.demanda_ponta)
      let TarifaMercadoLivre = validacaoDados(valoresTarifaMercadoLivre)
      if (TarifaMercadoLivre === '' ) {
        TarifaMercadoLivre = 0
      }
      let desconto = validacaoDados(valoresDesconto)
      if (desconto === '' ) {
        desconto = 0
      }

      let valoresTotais = {
        'mes': valoresMes.mes,
        'cativo': {
          'azul': 0,
          'verde': 0,
          'convencional': 0
        },
        'livre': {
          'azul': 0,
          'verde': 0
        }
      }

      valoresTarifa.forEach(tarifa => {
        if(
          tarifa.tarifa === 'TE' &&
          tarifa.posto === 'PONTA' &&
          tarifa.grandeza === 'CONSUMO' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = (tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms)) * valoresMes.consumo_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
          }
        }
        if (
          tarifa.tarifa === 'TUSD' &&
          tarifa.posto === 'PONTA' &&
          tarifa.grandeza === 'CONSUMO' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = (tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
            valoresTotais.livre.azul = valoresTotais.livre.azul + (TarifaMercadoLivre/1000 / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_ponta) + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
            valoresTotais.livre.verde = valoresTotais.livre.verde + (TarifaMercadoLivre/1000 / ((1-(cofins+pis))*(1-icms))  * valoresMes.consumo_ponta) + (valor_energia - (valor_energia * desconto/100))
          }
        }
        if (
          tarifa.tarifa === 'TE' &&
          tarifa.posto === 'FORA PONTA' &&
          tarifa.grandeza === 'CONSUMO' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = (tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_fora_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
          }
        }
        if (
          tarifa.tarifa === 'TUSD' &&
          tarifa.posto === 'FORA PONTA' &&
          tarifa.grandeza === 'CONSUMO' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = (tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_fora_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
            valoresTotais.livre.azul = valoresTotais.livre.azul + (TarifaMercadoLivre/1000 / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_fora_ponta) + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
            valoresTotais.livre.verde = valoresTotais.livre.verde + (TarifaMercadoLivre/1000 / ((1-(cofins+pis))*(1-icms))* valoresMes.consumo_fora_ponta) + valor_energia
          }
        }
        if (
          tarifa.tarifa === 'TE' &&
          tarifa.posto === 'PONTA' &&
          tarifa.grandeza === 'DEMANDA' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = tarifa.valor / ((1-(cofins+pis))*(1-icms))* valoresMes.demanda_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
          }
        }
        if (
          tarifa.tarifa === 'TUSD' &&
          (tarifa.posto === 'PONTA'|| tarifa.posto === 'NA PONTA' || tarifa.posto === 'NA') &&
          tarifa.grandeza === 'DEMANDA' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = tarifa.valor / ((1-(cofins+pis))*(1-icms)) * valoresMes.demanda_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
            valoresTotais.livre.azul = valoresTotais.livre.azul  + (valor_energia - (valor_energia * desconto/100))
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
            valoresTotais.livre.verde = valoresTotais.livre.verde + (valor_energia - (valor_energia * desconto/100))
          }
        }
        if (
          tarifa.tarifa === 'TE' &&
          tarifa.posto ==='FORA PONTA' &&
          tarifa.grandeza === 'DEMANDA' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = tarifa.valor / ((1-(cofins+pis))*(1-icms))* valoresMes.demanda_fora_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
          }
        }
        if(
          tarifa.tarifa === 'TUSD' &&
          (tarifa.posto === 'FORA PONTA' || tarifa.posto === 'NA FORA PONTA' || tarifa.posto === 'NA') &&
          tarifa.grandeza === 'DEMANDA' &&
          tarifa.subgrupo === 'A4'
        ) {
          const valor_energia = (tarifa.valor / ((1-(cofins+pis))*(1-icms)))* valoresMes.demanda_fora_ponta
          if (tarifa.modalidade === 'AZUL') {
            valoresTotais.cativo.azul = valoresTotais.cativo.azul + valor_energia
            valoresTotais.livre.azul = valoresTotais.livre.azul + (valor_energia - (valor_energia * desconto/100))
          } else if (tarifa.modalidade === 'VERDE') {
            valoresTotais.cativo.verde = valoresTotais.cativo.verde + valor_energia
            valoresTotais.livre.verde = valoresTotais.livre.verde  + (valor_energia - (valor_energia * desconto/100))
          }
        }
        if (
          tarifa.subgrupo === 'B3' &&
          tarifa.tarifa === 'TE'
        ) {
          const valor_energia = (
            ((tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms)) * valoresMes.consumo_fora_ponta) +
            ((tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms)) * valoresMes.consumo_ponta)
          )
          valoresTotais.cativo.convencional = valoresTotais.cativo.convencional + valor_energia
        }
        if (
          tarifa.subgrupo === 'B3' &&
          tarifa.tarifa === 'TUSD'
        ) {
          const valor_energia = (
            ((tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms)) * valoresMes.consumo_fora_ponta) +
            ((tarifa.valor/1000) / ((1-(cofins+pis))*(1-icms)) * valoresMes.consumo_ponta)
          )
          valoresTotais.cativo.convencional = valoresTotais.cativo.convencional + valor_energia
        }
      })
      valores.push(valoresTotais)
    })

    let azul = 0
    let verde = 0
    let convencional = 0
    let livreAzul = 0
    let livreVerde = 0
    for (let i = 0; i < valores.length; i++) {
      azul = azul + valores[i].cativo.azul;
      verde = verde + valores[i].cativo.verde;
      convencional = convencional + valores[i].cativo.convencional
      livreAzul = livreAzul + valores[i].livre.azul
      livreVerde = livreVerde + valores[i].livre.verde
    }


    setValoresTarifaVerde(verde);
    setValoresTarifaAzul(azul);
    setValoresTarifaConvencional(convencional);
    setValoresTarifaMercadoLivreAzul(livreAzul);
    setValoresTarifaMercadoLivreVerde(livreVerde);
  }


  const renderSubHeaderAndSearchBar = (titulo, subtitulo) => {
    return (
      <IoseSubHeaderBar
        title={titulo}
        subtitle={subtitulo} //"Simulador de Contratos"
        description={''}
        button={<></>}
        style={{ margin: '0' }}
      >
      </IoseSubHeaderBar>
    );
  };

  const renderValoresTarifas = () => {
    return (
      <CardsSimuladorTarifario
        loading={loading}
        totalAzul={valoresTarifaAzul}
        totalVerde={valoresTarifaVerde}
        totalConvecional={valoresTarifaConvencional}
        totalMercadoLivre={valoresTarifaMercadoLivre}
      />
    )
  }

  const renderTarifasUtilizadas = () => {
    let tarifas = []
    if (valoresTarifa !== undefined && valoresTarifa?.length !== 0){
      valoresTarifa?.forEach(tarifa => {
        if (tarifa.subgrupo === 'A4' || tarifa.subgrupo === 'B3') {
          tarifas.push(
            {
              'valor': tarifa.valor, 'modalidade': tarifa.modalidade, 'posto': tarifa.posto,  'inicio_vigencia': tarifa.inicio_vigencia,
              'grandeza': tarifa.grandeza, 'tarifa': tarifa.tarifa, 'fim_vigencia': tarifa.fim_vigencia
            }
          )
        }
      })
    }
    return (
      <S.StyledTable>
        <thead>
        <tr style={{fontSize: 10}}>
          <S.StyledTh>TARIFA</S.StyledTh>
          <S.StyledTh>VALOR</S.StyledTh>
          <S.StyledTh>MODALIDADE</S.StyledTh>
          <S.StyledTh>POSTO</S.StyledTh>
          <S.StyledTh>GRANDEZA</S.StyledTh>
          <S.StyledTh>INICIO DE VIGÊNCIA</S.StyledTh>
          <S.StyledTh>FIM DE VIGÊNCIA</S.StyledTh>
        </tr>
      </thead>
      <tbody>
        {tarifas.map((item) => (
          <tr style={{fontSize: 10}}>
            <S.StyledTd>{item.tarifa}</S.StyledTd>
            <S.StyledTd>{item.valor}</S.StyledTd>
            <S.StyledTd>{item.modalidade}</S.StyledTd>
            <S.StyledTd>{item.posto}</S.StyledTd>
            <S.StyledTd>{item.grandeza}</S.StyledTd>
            <S.StyledTd>{item.inicio_vigencia}</S.StyledTd>
            <S.StyledTd>{item.fim_vigencia}</S.StyledTd>
          </tr>
        ))}
      </tbody>
      </S.StyledTable>
    )
  }

  const renderValoresTarifasMercadoLivre = () => {
    return (
      <CardsSimuladorTarifarioMercadoLivre
        loading={loading}
        totalMercadoLivreAzul={valoresTarifaMercadoLivreAzul}
        totalMercadoLivreVerde={valoresTarifaMercadoLivreVerde}
      />
    )
  }

  const renderTabelaConsumoDemanda = () => {
    return (
        <S.FormContainerPaper>
          <DynamicTable data={data} setData={setData}/>
          <S.FormFooterContainer>
            <S.StyledText
              label='Tarifa Mercado Livre (R$)'
              onChange={(e) => setValoresTarifaMercadoLivre(e.target.value)}
            />

            <S.StyledText
              label='Desconto (%)'
              onChange={(e) => setValoresDesconto(e.target.value)}
            />

            <IoseButton
              onClick={() =>
                handleOnClick(valoresTarifa)
              }
            >
              SIMULAR
            </IoseButton>
          </S.FormFooterContainer>
        </S.FormContainerPaper>
    )
  }

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (

        <S.ContentContainer>
          <S.ContainerPaper>
            {renderTabelaConsumoDemanda()}
            <S.ContainerTarifasPaper>
              <S.ValoresContainerPaper>
                <S.ContainerTarifasPaperTitle>
                  <h1>MERCADO CATIVO</h1>
                </S.ContainerTarifasPaperTitle>
                <S.ContainerTarifasPaperContent>
                  {renderValoresTarifas()}
                </S.ContainerTarifasPaperContent>
              </S.ValoresContainerPaper>
              <S.ValoresContainerPaper>
                <S.ContainerTarifasPaperTitle>

                  <h1>MERCADO LIVRE</h1>
                </S.ContainerTarifasPaperTitle>
                <S.ContainerTarifasPaperContent>
                  {renderValoresTarifasMercadoLivre()}
                </S.ContainerTarifasPaperContent>
              </S.ValoresContainerPaper>
            </S.ContainerTarifasPaper>
            {renderTarifasUtilizadas()}
          </S.ContainerPaper>
        </S.ContentContainer>

      );
    }
  };

  return (
    <S.WrapContainer>
      {loading ? (
        <LoadingIndicatorDashboard loading={loading} />
      ) : (
        <>
          {hasReadPermission ? (
            <>
              {renderSubHeaderAndSearchBar(clientData?.name, "Simulador de Contratos")}
              {renderContent()}
            </>
          ) : (
            <div style={{ marginTop: '35px' }}>
              <IoseAlertLoadingCards
                text={
                  'Seu grupo de usuários não tem permissão de acesso a este módulo.'
                }
              />
            </div>
          )}
        </>
      )}
    </S.WrapContainer>
  );
}

SimulatorContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
