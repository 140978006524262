import styled from 'styled-components';
import SwitchboardImage from './img/Switchboard.svg';
import { Avatar, Card, Grid } from '@material-ui/core';
import Consumption_energy_icon from './img/consumption_energy_icon.svg';
import Generation_energy_icon from './img/generation_energy_icon.svg';

export const Container = styled(Card)`
  padding: 5%;
  flex-direction: column;
  width: 100%;

  @media (min-width: 960px) {
    padding: 2%;
  }
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  direction: 'row',
  justify: props.justify,
  alignItems: 'center',
  spacing: 2,
}))`
  margin-top: ${(props) => props.margintop};
  margin-bottom: ${(props) => props.marginbottom};
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 12,
  md: props.md,
}))`
  display: flex;
  flex-direction: row;

  h4 {
    font-size: 11px;
  }

  div {
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const AvatarStyled = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: ${(props) => props.color};

  margin-right: 5px;
  width: 30px;
  height: 30px;
  border: solid 2px var(--primary);
  color: var(--secondary);
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  h3 {
    margin-left: 5px;
  }
`;

export const SwitchboardIcon = styled.img.attrs((props) => ({
  src: SwitchboardImage,
}))`
  width: 20px;
  height: 20px;
`;

export const AddFlagText = styled.p`
  font-weight: bold;
  color: ${(props) => props.color};
  padding-left: 16px;
`;

export const Error = styled.b`
  color: red;
  padding: 8px;
`;

export const ConsumptionEnergyIcon = styled.img.attrs((props) => ({
  src: Consumption_energy_icon,
}))`
  width: 20px;
  height: 20px;
`;

export const GenerationEnergyIcon = styled.img.attrs((props) => ({
  src: Generation_energy_icon,
}))`
  width: 20px;
  height: 20px;
`;
