import React, { useState } from 'react';
import PropType from 'prop-types';

//Imports from IOSE components
import {
  IoseEditButton,
  IoseCalendarButton,
  IoseMoreOptions,
} from 'components/IoseButtonIcon';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';
import Tooltip from '@material-ui/core/Tooltip';

//Imports from Material Ui components
import { Grow } from '@material-ui/core';

//Imports from Style
import {
  CardStyled,
  UnityIcon,
  AvatarStyled,
  // TypographyStyled,
  CardHeaderStyled,
  CardContentStyled,
  DivHeaderButton,
  DivButtons,
} from './style';

/**
 * This component create a card with all information about unity and allows editing/deleting unity.
 */
export default function IoseCardUnity(props) {
  const {
    uuid_unity,
    name,
    description,
    goal,
    group,
    energy,
    money,
    tariff_period,
    tariff_data,
    contracted_demand,
    clickCard,
    clickDelete,
    clickEdit,
    clickSchedule,
    identification_number,
    bin_link,
    id_3d,
    showEdit,
    showDelete,
  } = props;

  const [moreOptions, setMoreOptions] = useState(false);

  const unityData = {
    uuid_unity: uuid_unity,
    name: name,
    description: description,
    goal: goal,
    group: group,
    energy: energy,
    money: money,
    tariff_period: tariff_period,
    tariff_data: tariff_data,
    contracted_demand: contracted_demand,
    identification_number: identification_number,
    bin_link: bin_link,
    id_3d: id_3d,
  };

  function handleClickShedule() {
    clickSchedule(unityData);
  }

  const renderButtonsCard = () => {
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <DivButtons>
          {showDelete ? (
            <IoseDeleteInputModal
              title="DELETAR UNIDADE"
              name={name}
              deletefunc={(e) => clickDelete(uuid_unity)}
              cardbutton={'true'}
              primarybutton={'true'}
            />
          ) : null}
          {showEdit ? (
            <IoseEditButton
              primary="true"
              card="true"
              tooltip="EDITAR UNIDADE"
              onClick={(e) => clickEdit(unityData)}
            />
          ) : null}
        </DivButtons>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <CardStyled open={moreOptions}>
        <DivHeaderButton disableSpacing>
          <CardHeaderStyled
            avatar={
              <AvatarStyled>
                <UnityIcon />
              </AvatarStyled>
            }
            onClick={(e) => clickCard(unityData)}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></CardHeaderStyled>
          <div>
            <IoseCalendarButton
              primary="true"
              card="true"
              tooltip="Agendamentos da Unidade"
              onClick={(e) => handleClickShedule()}
              left="5px"
            />
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </DivHeaderButton>

        <CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </CardContentStyled>
      </CardStyled>
    </Grow>
  );
}

IoseCardUnity.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
