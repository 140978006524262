/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { xlsxReports } from 'reports/Xlsx';
import { IoseButton2 } from 'components/IoseButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useContracts } from 'hooks';

// Função de agregação de dados
function aggregateA4ConsumptionByMonth(data, contract) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;
    const initialDate = new Date(contract.initial_date);

    const [monthItem, yearItem] = month.split('/').map(Number);

    const itemDate = new Date(yearItem, monthItem - 1);

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        total_consumo_pta: 0,
        total_consumo_fp: 0,
      };
    }

    // Verifica se a data do item é anterior ao início do contrato
    const shouldDivideBy2 =
      itemDate <= new Date(initialDate.getFullYear(), initialDate.getMonth());

    if (item.referente === 'Consumo PTA') {
      aggregatedData[month].total_consumo_pta += shouldDivideBy2
        ? item.referente_consumption / 2
        : item.referente_consumption;
    } else if (item.referente === 'Consumo FP') {
      aggregatedData[month].total_consumo_fp += shouldDivideBy2
        ? item.referente_consumption / 2
        : item.referente_consumption;
    }
  });

  const aggregatedArray = Object.values(aggregatedData);

  aggregatedArray.sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);

    return dateA - dateB;
  });

  return aggregatedArray;
}

// Função de agregação para o grupo B3
function aggregateB3ConsumptionByMonth(data, contract) {
  const aggregatedData = {};

  data?.forEach((item) => {
    const month = item.reference_month;
    const initialDate = new Date(contract.initial_date);
    const [monthItem, yearItem] = month.split('/').map(Number);

    const itemDate = new Date(yearItem, monthItem - 1);

    if (!aggregatedData[month]) {
      aggregatedData[month] = {
        reference_month: month,
        total_consumo: 0,
      };
    }

    const shouldDivideBy2 =
      itemDate <= new Date(initialDate.getFullYear(), initialDate.getMonth());

    if (item.referente === 'Consumo') {
      aggregatedData[month].total_consumo += shouldDivideBy2
        ? item.referente_consumption / 2
        : item.referente_consumption;
    }
  });

  const aggregatedArray = Object.values(aggregatedData);

  aggregatedArray.sort((a, b) => {
    const [monthA, yearA] = a.reference_month.split('/').map(Number);
    const [monthB, yearB] = b.reference_month.split('/').map(Number);

    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);

    return dateA - dateB;
  });

  return aggregatedArray;
}

export const IoseBarChartConsumerGroup = ({ data }) => {
  const { contracts } = useContracts();
  const [formattedConsumptions, setFormattedConsumptions] = useState([]);

  const uc = data ? data[0]?.identification_number : '';

  useEffect(() => {
    if (data) {
      // Busca o contrato correspondente ao UC
      const contract = contracts?.find(
        (contract) => contract?.contract_number === uc
      );

      // Se não houver contrato, aplica a divisão por 2 para todos os meses
      if (!contract) {
        // Aplicar divisão por 2 para o subgrupo A4 e A3a
        if (data[0]?.subgrupo === 'A3a' || data[0]?.subgrupo === 'A4') {
          const adjustedData = data.map((item) => ({
            ...item,
            referente_consumption: item?.referente_consumption / 2,
          }));
          setFormattedConsumptions(
            aggregateA4ConsumptionByMonth(adjustedData, {
              initial_date: '01/01/0001',
            })
          );
        }

        if (data[0]?.subgrupo === 'B3') {
          const adjustedData = data?.map((item) => ({
            ...item,
            referente_consumption: item?.referente_consumption / 2,
          }));
          setFormattedConsumptions(
            aggregateB3ConsumptionByMonth(adjustedData, {
              initial_date: '01/01/0001',
            })
          );
        }
      } else {
        // Se houver contrato verifica a data de início
        if (data[0]?.subgrupo === 'A3a' || data[0]?.subgrupo === 'A4') {
          setFormattedConsumptions(
            aggregateA4ConsumptionByMonth(data, contract)
          );
        }

        if (data[0]?.subgrupo === 'B3') {
          setFormattedConsumptions(
            aggregateB3ConsumptionByMonth(data, contract)
          );
        }
      }
    }
  }, [data, contracts]);

  // Extrai os dados para o gráfico
  const months = formattedConsumptions?.map((item) => item.reference_month);
  const consumptionPta = formattedConsumptions?.map(
    (item) => item?.total_consumo_pta
  );
  const consumptionFp = formattedConsumptions?.map(
    (item) => item?.total_consumo_fp
  );
  const consumptionTotal = formattedConsumptions?.map(
    (item) => item?.total_consumo_pta + item.total_consumo_fp
  );
  const consumption = formattedConsumptions?.map((item) => item.total_consumo);

  // Opções de configuração do gráfico
  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    show: true,
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params?.length > 0) {
          let tooltipContent = `${params[0].axisValue}<br />`;
          params.forEach((param) => {
            tooltipContent += `${
              param.seriesName
            }: ${param.value.toLocaleString('pt-BR')}<br />`;
          });

          const dataIndex = params[0].dataIndex;
          const total = consumptionTotal[dataIndex];
          tooltipContent += `Consumo Total (kWh): ${total.toLocaleString(
            'pt-BR'
          )}`;

          return tooltipContent;
        }
        return 'Sem dados';
      },
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0,
          fontSize: 10
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        name: 'Consumo PTA (kWh)',
        type: 'bar',
        barWidth: '30%',
        data: consumptionPta,
        color: '#A90000',
      },
      {
        name: 'Consumo FP (kWh)',
        type: 'bar',
        barWidth: '30%',
        data: consumptionFp,
        color: '#87E35A',
      },
    ],
  };

  const option2 = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params?.length > 0) {
          let tooltipContent = `${params[0].axisValue}<br />`;
          params.forEach((param) => {
            tooltipContent += `${
              param.seriesName
            }: ${param.value.toLocaleString('pt-BR')}<br />`;
          });

          return tooltipContent;
        }
        return 'Sem dados';
      },
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0,
          fontSize: 10
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        name: 'Consumo (kWh)',
        type: 'bar',
        barWidth: '30%',
        data: consumption,
        color: '#015A9E',
        itemStyle: {
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
          emphasis: {
            barBorderRadius: [10, 10],
          },
        },
      },
    ],
  };

  return (
    <div>
      {data && data.length > 0 && (
        <div
          style={{
            width: '100%',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <IoseButton2
            onClick={() =>
              xlsxReports.exportResumoGraficoConsumo(formattedConsumptions, uc)
            }
            width="80px"
          >
            <span style={{ marginRight: '5px' }}>XLSX</span>
            <GetAppIcon />
          </IoseButton2>
        </div>
      )}
      <div>
        {data?.length > 0 ? (
          <ReactECharts
            option={data && data[0]?.subgrupo === 'B3' ? option2 : option}
            style={{ height: '485px' }}
          />
        ) : (
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
            height="300px"
            width="280px"
          />
        )}
      </div>
    </div>
  );
};
