import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import BusinessIcon from '@material-ui/icons/Business';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { CircularProgress, FormControl, InputLabel, Select, Table, TableCell, TableHead, TableRow, Typography, Input, TextField } from '@material-ui/core';


export const SuperUserBar = styled.div`
  display: flex;
  height: 50px;
  margin-top: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
  }
`;

export const InfiniteScrollStyle = styled(InfiniteScroll)`
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }

  @media (max-width: 500px) {
    height: 400px !important;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContainerTable = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ConcessionaireIcon = styled(BusinessIcon)`
  color: black;
`;

export const ContractsIcon = styled(LibraryBooksIcon)`
  color: black;
`;

export const WrappedContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

export const TableBox = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;


export const StyledTable = styled(Table)`
  tbody tr:hover {
    background-color: #f5f5f5;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const TableRowStyled = styled(TableRow)`
  border-radius: 10px;
  text-align: center;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 12px;
  font-weight: bold;
  align-items: center;
`;

export const TableHeadStyled = styled(TableHead)`
  background-color: var(--primary);
  margin-top: 15px;
`;

export const FormDivContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  margin-bottom: 25px;
  align-items: center;
`

export const FormDivItem = styled.div`
  width: 200px;
`

export const Label = styled(InputLabel)`
  color: var(--primary);
`;

export const Control = styled(FormControl)`
  label {
    color: var(--primary);
  }

  .MuiFormLabel-root.Mui-focused {
    color: var(--primary);
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid var(--primary);
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid var(--primary);
  }

  .MuiFormControl-marginNormal {
    margin: 0 !important;
  }
`;

export const StyledSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 12px;
  }

  @media (max-width: 1360px) {
    .MuiInputBase-input {
      font-size: 11px;
    }
  }
`;

export const TextFieldStyled = styled(Input)`
  color: var(--primary);
`;

export const TextFieldSelectStyled = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 5px;

    &.Mui-focused fieldset {
      border-color: #015A9E;
    }

    & fieldset {
      border-radius: 10px;
    }

    height: 50px;
  }

  & .MuiInputBase-root {
    &::before {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &::after {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    & input {
      color: #015A9E;
      font-weight: bold;
    }

    & input:-webkit-autofill {
      -webkit-text-fill-color: #015A9E;
      font-weight: bold;
    }

    height: 30px;
    padding: 0 0 10px 10px;
  }

  & .MuiInputLabel-shrink {
    display: none;
  }

  // posição da label
  & .MuiInputLabel-root {
    padding: 0 0 15px 15px;
    font-weight: bold;
    margin-top: -8px;
  }
`;

export const DivCircularProgress = styled.div`
  /* border: solid 1px red; */
  width: 100%;
  padding: 5%;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 30px !important;
  height: 30px !important;
`;

export const ModalTitle = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

export const ModalTitleHeader = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: var(--secondary);
  margin-top: 6px;
`;


export const StyledHeaderModal = styled.div`
  background-color: var(--primary);
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px 15px 0 0;
`;

export const StyledLeftHeaderModal = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

export const StyledRightHeaderModal = styled.div`
  width: 80%;
  display: flex;
  align-items: left;
`;

export const StyledWrappedModal = styled.div`
  background-color: #fff;
  height: 350px;
  width: 620px;
  padding: 10px;
  border-radius: 0 0 15px 15px;
`;
