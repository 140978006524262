import React from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { LoadingIndicator } from 'components';

export default function EnergyBillBlueChart({ data, loading }) {

  if (loading) {
    return (
      <LoadingIndicator loading={loading} />
    );
  }


  if (!data || data.length === 0) {
    return (
      <IoseNoDataMessage
        message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
        height="300px"
        width="280px"
      />
    );
  }

  const sortedData = data?.sort((a, b) => a.identification_number.localeCompare(b.identification_number));

  const identificationNumbers = sortedData?.map(item => item.identification_number);
  const demandaContratadaFP = sortedData?.map(item => item.demanda_contratada_fp);
  const demandaContratadaPTA = sortedData?.map(item => item.demanda_contratada_pta);
  const totalDemandaFP = sortedData?.map(item => item.total_demanda_fp);
  const totalDemandaPTA = sortedData?.map(item => item.total_demanda_pta);

  // Configura as opções do gráfico
  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '10%',
      top: '15%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const uc = params[0].axisValue;

        const formattedValues = params.map((param) => {
          const numericValue = typeof param.value === 'string' ? parseFloat(param.value) : param.value;
          const formattedValue = numericValue.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue}`;
        });

        return `<strong>UC:</strong> ${uc}<br>` + formattedValues.join('<br>') || 'Sem dados';
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataZoom: {
          yAxisIndex: 'none',
        },
      },
    },
    legend: {
      data: ['Demanda Contratada FP', 'Demanda Contratada PTA', 'Demanda Faturada FP', 'Demanda Faturada PTA'],
      top: '5%',
      left: 'center',
      orient: 'horizontal',
    },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0],
        start: 0,
        end: 100,
      },
      {
        type: 'slider',
        xAxisIndex: [0],
        start: 0,
        end: 100,
      }
    ],
    xAxis: [
      {
        type: 'category',
        data: identificationNumbers,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            if (Math.abs(value) >= 1e6) {
              return (value / 1e6).toFixed(0) + 'M';
            } else {
              return (value / 1e3).toFixed(0) + 'K';
            }
          },
        },
      },
    ],
    series: [
      {
        name: 'Demanda Contratada FP',
        type: 'bar',
        data: demandaContratadaFP,
      },
      {
        name: 'Demanda Contratada PTA',
        type: 'bar',
        data: demandaContratadaPTA,
      },
      {
        name: 'Demanda Faturada FP',
        type: 'bar',
        data: totalDemandaFP,
      },
      {
        name: 'Demanda Faturada PTA',
        type: 'bar',
        data: totalDemandaPTA,
      }
    ],
  };

  return data.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 480 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}



