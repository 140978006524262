import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import * as S from './style';
import { LocalStorage, AccountContext } from 'common';
import IoseEnergyBillListTable from 'components/IoseEnergyBillListTable';
import IoseSearchMessage from 'components/IoseSearchMessage';
import Permissions from 'common/permissions';
import EnergyBillContainerFilters from './components/energy-bill-container-filtros';

import { useGroupPermissions } from 'hooks';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  LoadingIndicator,
} from 'components';
import { getBillByUUID } from 'services/get-bill-by-uuid';
import { getExtractsByUuid } from 'services/get-extracts-by-uuid';
import IoseBillsAndAclModal from 'components/Modals/IoseBillsAndAclModal';
import { getBillsByIdentificationNumber } from 'services/get-bills-by-identification-number';
import { getFaturasAclByIdentificationNumber } from 'services/get-faturas-acl-by-identification-number';

export default function SearchEnergyBillContainer() {
  const { getSession } = useContext(AccountContext);
  const { groupPermissions } = useGroupPermissions();

  const [loading, setLoading] = useState(false);
  const [energyBills, setEnergyBills] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  // Permissions States
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['faturas', 'buscar_faturas', 'leitura'];
    const reportPath = ['faturas', 'buscar_faturas', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  const openAuditModal = () => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  };

  const renderTable = () => {
    const getEnergyBill = async (rowData) => {
      setLoading(true);
      const { uuid_original_bill, identification_number, link_pdf } = rowData;

      const sessionData = await getSession();

      try {
        const [result, extracts, bills, faturasAcl] = await Promise.all([
          getBillByUUID(identification_number, sessionData, uuid_original_bill),
          getExtractsByUuid(identification_number, sessionData),
          getBillsByIdentificationNumber(identification_number, sessionData),
          getFaturasAclByIdentificationNumber(identification_number, sessionData)
        ]);


        const url = `${link_pdf}${result[0].pdf_name}`;
        result[0].link_completo = url;

        const combinedData = {
          result,
          extracts,
          bills,
          faturasAcl
        };

        setSelectedRowData(combinedData);

      } catch (error) {
        throw error;
      }


      setLoading(false);
      openAuditModal();
    };

    if (energyBills) {
      return (
        <S.ContainerTable>
          <IoseEnergyBillListTable
            tableTitle=""
            searchInTable={false}
            data={energyBills}
            loading={loading}
            onClickFunction={getEnergyBill}
            hasPermission={hasReportPermission}
          />
        </S.ContainerTable>
      );
    } else {
      return (
        <IoseSearchMessage
          message={`Selecione os filtros de busca desejados e clique em "FILTRAR".`}
          height="400px"
          width="400px"
        />
      );
    }
  };

  const renderContent = () => {
      return (
        <>
          <div>
            {renderTable()}
            {renderAuditModal()}
          </div>
        </>
      );
  };


  const renderAuditModal = () => {
    return (
      <IoseBillsAndAclModal
        open={openModal}
        onClose={openAuditModal}
        data={selectedRowData}
      />
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        <IoseSubHeaderBar title="Busca de Faturas" />
        <EnergyBillContainerFilters
          energyBills={setEnergyBills}
          loading={setLoading}
          />
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

