import React from 'react';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import IoseButtonSideBar from 'components/IoseButton';
// import { IoseBackButton } from 'components/IoseButtonIcon';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
  DivInputsSwitchboard,
  IoseTextFieldSidebarStyled2,
} from './style';

/**
 * This component is a form to edit a switchboard in the system
 */
export default function IoseFormSwitchboardEditCard({ editSwitchboard }) {
  let location = useLocation();

  const uuid_group = location.state.switchboardData.uuid_group;
  const name = location.state.name;
  const description = location.state.description;
  const goal = location.state.goal;
  const identification_number = location.state.identification_number;

  //Schema validation for edit Switchboard
  const SwitchboardEditSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.name,
    goal: validationsForm.number,
    identification_number: validationsForm.identificationNumber,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
    { label: 'Meta', variable: 'goal' },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    const identificationNumber = parseInt(values.identification_number);
    setTimeout(() => {
      const SwitchboadData = {
        uuid: uuid_group,
        name: values.name,
        description: values.description,
        goal: values.goal,
        identification_number: identificationNumber,
      };
      setSubmitting(false);
      editSwitchboard(SwitchboadData);
    }, 500);
  };

  return (
    <>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: name,
            description: description,
            goal: goal,
            identification_number: identification_number,
          }}
          validationSchema={SwitchboardEditSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>Editar</div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              {/**Inputs*/}
              {labels.map((value) => {
                return (
                  <Field
                    component={IoseTextFieldSidebarStyled}
                    key={value.variable}
                    id={value.variable}
                    name={value.variable}
                    type={'text'}
                    label={value.label}
                    defaultvalue={initialValues[value.variable]}
                    onchange={handleChange}
                    onblur={handleBlur}
                    helpertext={
                      errors[value.variable] && touched[value.variable]
                        ? errors[value.variable]
                        : ''
                    }
                  />
                );
              })}
              <DivInputsSwitchboard>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'identification_number'}
                  name={'identification_number'}
                  type={'text'}
                  label={'Unidade Consumidora'}
                  defaultvalue={initialValues['identification_number']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['identification_number'] &&
                    touched['identification_number']
                      ? errors['identification_number']
                      : ''
                  }
                />
              </DivInputsSwitchboard>
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseFormSwitchboardEditCard.propTypes = {
  /**This props is a function to edit switchboard in the container */
  editSwitchboard: PropType.func,
};
