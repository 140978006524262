import React, { useState, useMemo } from 'react';
import {
  TableBody,
  TableCell,
  Paper,
  makeStyles,
  TablePagination,
  Tooltip,
  Button,
} from '@material-ui/core';
import { StyledTable, TableHeadStyled, TableRowStyled } from '../style';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CreateIcon from '@material-ui/icons/Create';
import {
  IoseConsumerGroupDeleteModal,
  IoseEditConsumerGroupModal,
} from 'components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
    cursor: 'pointer', // Add cursor style to indicate sortable columns
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  addressColumn: {
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconColumn: {
    minWidth: '40px',
    maxWidth: '40px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  mediumColumn: {
    minWidth: '80px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  largeColumn: {
    minWidth: '80px',
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  smartColumn: {
    minWidth: '65px',
    maxWidth: '65px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const TableCellCentered = ({ children, className }) => {
  const classes = useStyles();
  return (
    <TableCell className={`${classes.tableCell} ${className}`}>
      {children}
    </TableCell>
  );
};

const TableHeaderCellCentered = ({
  children,
  className,
  onClick,
  sortedBy,
  direction,
}) => {
  const classes = useStyles();
  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${className}`}
      onClick={onClick}
    >
      {children}
      {sortedBy && (direction === 'asc' ? ' 🔼' : ' 🔽')}
    </TableCell>
  );
};

const CircuitsTableRow = ({
  value,
  onDeleteClick,
  onEditClick,
  redirectToConsumerGrouDash,
}) => {
  const classes = useStyles();
  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered className={classes.mediumColumn}>
        {value.identification_number}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.atividade}
      </TableCellCentered>
      <TableCellCentered className={classes.largeColumn}>
        {value.concessionaire}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.general_management}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.regional_management}
      </TableCellCentered>
      <TableCellCentered className={classes.addressColumn}>
        {value.address}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.telemetry === 'NAO' ? 'NÃO' : value.telemetry}
      </TableCellCentered>

      <TableCellCentered className={classes.smartColumn}>
        {value.subgrupo}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.modalidade}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.operating_unity}
      </TableCellCentered>
      <TableCellCentered className={classes.smartColumn}>
        {value.mercado_livre === 'NAO' ? 'NÃO' : value.mercado_livre}
      </TableCellCentered>
      <TableCellCentered>
        {value.csbio === 'NAO' ? 'NÃO' : value.csbio}
      </TableCellCentered>
      <TableCellCentered className={classes.iconColumn}>
        <Tooltip title="EDITAR REGISTRO">
          <Button style={{ height: '30px' }} onClick={() => onEditClick(value)}>
            <CreateIcon style={{ color: '#015A9E' }} />
          </Button>
        </Tooltip>
      </TableCellCentered>
      <TableCellCentered className={classes.iconColumn}>
        <Tooltip title="VISUALIZAR DASHBOARD">
          <Button
            style={{ height: '30px' }}
            onClick={() => redirectToConsumerGrouDash(value)}
          >
            <EqualizerIcon style={{ color: '#015A9E' }} />
          </Button>
        </Tooltip>
      </TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({
  data,
  onRowClick,
  page,
  rowsPerPage,
  onDeleteClick,
  onEditClick,
  redirectToConsumerGrouDash,
  handleSort,
  sortBy,
  sortDirection,
}) => {
  const classes = useStyles();
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const pageData = data.slice(startIndex, endIndex);

  return (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('identification_number')}
            sortedBy={sortBy === 'identification_number'}
            direction={sortDirection}
          >
            UC
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('atividade')}
            sortedBy={sortBy === 'atividade'}
            direction={sortDirection}
          >
            ATIVIDADE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.largeColumn}
            onClick={() => handleSort('concessionaire')}
            sortedBy={sortBy === 'concessionaire'}
            direction={sortDirection}
          >
            CONCESSIONÁRIA
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('general_management')}
            sortedBy={sortBy === 'general_management'}
            direction={sortDirection}
          >
            GG
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            onClick={() => handleSort('regional_management')}
            sortedBy={sortBy === 'regional_management'}
            direction={sortDirection}
          >
            GR
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.addressColumn}
            onClick={() => handleSort('address')}
            sortedBy={sortBy === 'address'}
            direction={sortDirection}
          >
            ENDEREÇO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('telemetry')}
            sortedBy={sortBy === 'telemetry'}
            direction={sortDirection}
          >
            TELEMETRIA
          </TableHeaderCellCentered>

          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('subgrupo')}
            sortedBy={sortBy === 'subgrupo'}
            direction={sortDirection}
          >
            SUBGRUPO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.mediumColumn}
            onClick={() => handleSort('modalidade')}
            sortedBy={sortBy === 'modalidade'}
            direction={sortDirection}
          >
            MODALIDADE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('operating_unity')}
            sortedBy={sortBy === 'operating_unity'}
            direction={sortDirection}
          >
            UNIDADE OP.
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('mercado_livre')}
            sortedBy={sortBy === 'mercado_livre'}
            direction={sortDirection}
          >
            M. LIVRE
          </TableHeaderCellCentered>
          <TableHeaderCellCentered
            className={classes.smartColumn}
            onClick={() => handleSort('csbio')}
            sortedBy={sortBy === 'csbio'}
            direction={sortDirection}
          >
            USBIO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered />
          <TableHeaderCellCentered />
        </TableRowStyled>
      </TableHeadStyled>
      <TableBody>
        {pageData?.map((value, index) => (
          <CircuitsTableRow
            key={index}
            value={value}
            onClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            redirectToConsumerGrouDash={redirectToConsumerGrouDash}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default function ConsumerGroupMainTable({
  loading,
  data = [],
  getAll,
  filter,
  getFilteredConsumerGroups,
  lastFilterObject,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const history = useHistory();

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleRemoveItem = (itemToRemove) => {
    setOpenDeleteModal(true);
    setSelectedItem(itemToRemove);
  };

  const handleEditItem = (itemToRemove) => {
    setOpenEditModal(true);
    setSelectedItem(itemToRemove);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortBy, sortDirection);
  }, [data, sortBy, sortDirection]);

  function redirectToConsumerGrouDash(value) {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: {
        consumerGroup: value?.identification_number,
        uuidCircuit: value?.numero_telemetria,
      },
    });
  }

  function renderChartOrErrorMessage() {
    if (sortedData && sortedData.length > 0) {
      return (
        <CircuitsTable
          data={sortedData}
          onRowClick={() => {}}
          page={page}
          rowsPerPage={rowsPerPage}
          onDeleteClick={handleRemoveItem}
          onEditClick={handleEditItem}
          redirectToConsumerGrouDash={redirectToConsumerGrouDash}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <Paper>
            <IoseInfinityScroll
              dataLength={data ? parseInt(data.length) : 0}
              next={() => {}}
              hasMore={false}
              loading={false}
              endList={false}
              sidebar={false}
              marginTop="0"
              padding="0"
              colortextend={null}
              sizetextend={null}
              scrollableTarget="scrollableDiv"
              height={530}
            >
              {renderChartOrErrorMessage()}
            </IoseInfinityScroll>

            <TablePagination
              component="div"
              count={data.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage="Itens por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
              }
              backIconButtonText="Página anterior"
              nextIconButtonText="Próxima página"
            />
          </Paper>

          <IoseEditConsumerGroupModal
            modalHeaderTitle="UNIDADE CONSUMIDORA"
            modalTitle="EDITAR UNIDADE CONSUMIDORA"
            open={openEditModal}
            onClose={handleCloseEditModal}
            selectedItem={selectedItem}
            getAll={getAll}
            filter={filter}
            getFilteredConsumerGroups={getFilteredConsumerGroups}
            lastFilterObject={lastFilterObject}
          />

          <IoseConsumerGroupDeleteModal
            modalHeaderTitle="UNIDADE CONSUMIDORA"
            modalTitle="DELETAR UNIDADE CONSUMIDORA"
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            selectedItem={selectedItem}
          />
        </>
      )}
    </div>
  );
}

function sortData(data, sortBy, sortDirection) {
  if (!sortBy) return data;

  const sortedData = [...data].sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  return sortedData;
}
