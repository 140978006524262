import { useQuery } from '@tanstack/react-query';
import { Messages } from 'common';
import ioseApiLib from 'iose-api-lib';
import { useState } from 'react';

function useUnitys(uuid_client, next_page = 0) {
  const [noUnitys, setNoUnitys] = useState('');

  const getAllUnity = async () => {
    const response = await ioseApiLib.Unity.getAllUnity(
      uuid_client,
      next_page
    );

    const { data } = response;

    const unitys = data || [];

    if (unitys.length === 0) {
      setNoUnitys(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return unitys;
  };

  const {
    data: unitys,
    error: unitysError,
    refetch: refreshUnitys,
    isLoading,
  } = useQuery(['unitys', uuid_client], getAllUnity);

  return {
    unitys,
    unitysError,
    noUnitys,
    refreshUnitys,
    isLoading,
  };
}
export default useUnitys;

// const useUnitys1 = (uuidClient) => {
//   const [unitys, setUnitys] = useState([]);

//   const getAllUnitys = useCallback(async (uuid_client, nextpage = 0) => {
//     try {
//       let response = await ioseApiLib.Unity.getAllUnity(uuid_client, nextpage);
//       return response;
//     } catch (err) {
//       throw err;
//     }
//   }, []);

//   const getAllUnity = useCallback(async () => {
//     let allUnitys = [];
//     allUnitys = await getAllUnitys(uuidClient);

//     setUnitys(allUnitys.data);
//   }, [getAllUnitys, uuidClient]);

//   useEffect(() => {
//     getAllUnity(uuidClient);
//   }, [getAllUnity, uuidClient]);

//   return {
//     unitys,
//   };
// };
