import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import * as S from '../style';

const sumFields = (acc, item, fields) => {
  fields.forEach(field => {
    acc[field] = (acc[field] || 0) + (parseFloat(item[field]) || 0);
  });
};

const sortMonths = (a, b) => {
  const [monthA, yearA] = a.split('/').map(Number);
  const [monthB, yearB] = b.split('/').map(Number);

  if (yearA !== yearB) {
    return yearA - yearB;
  }

  return monthA - monthB;
};

export const SummaryDashYearDesperdiciosChart = ({ data }) => {
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const fieldsToSum = [
        'consumo_exe_reat_pta_valor', 'demanda_exe_reat_fp_valor', 'demanda_exe_reat_pta_valor',
        'consumo_exe_reat_fp_valor', 'ad_demanda_valor', 'ad_demanda_fp_valor',
        'consumo_exe_reat_valor', 'ad_demanda_pta_valor', 'demanda_exe_reat_valor',
        'demanda_ult_valor', 'demanda_ult_pta_valor', 'demanda_ult_fp_valor'
      ];

      const groupedData = data.reduce((acc, item) => {
        const { reference_month } = item;

        // Inicializa o objeto para o mês, se ainda não existir
        if (!acc[reference_month]) {
          acc[reference_month] = {};
        }

        sumFields(acc[reference_month], item, fieldsToSum);

        return acc;
      }, {});

      const orderedGroupedData = Object.keys(groupedData)
        .sort(sortMonths)
        .map(month => ({
          month,
          ...groupedData[month]
        }));

      setGroupedData(orderedGroupedData);
    }
  }, [data]);

  const months = groupedData.map(data => data.month);

  // Criação de séries para cada tipo de custo
  const series = [
    { name: 'Consumo Exc. Reat. PTA (R$)', data: groupedData.map(data => data.consumo_exe_reat_pta_valor || 0) },
    { name: 'Demanda Exc. Reat. FP (R$)', data: groupedData.map(data => data.demanda_exe_reat_fp_valor || 0) },
    { name: 'Demanda Exc. Reat. PTA (R$)', data: groupedData.map(data => data.demanda_exe_reat_pta_valor || 0) },
    { name: 'Consumo Exc. Reat. FP (R$)', data: groupedData.map(data => data.consumo_exe_reat_fp_valor || 0) },
    { name: 'Adicional Demanda (R$)', data: groupedData.map(data => data.ad_demanda_valor || 0) },
    { name: 'Adicional Demanda FP (R$)', data: groupedData.map(data => data.ad_demanda_fp_valor || 0) },
    { name: 'Consumo Exc. Reat. (R$)', data: groupedData.map(data => data.consumo_exe_reat_valor || 0) },
    { name: 'Adicional Demanda PTA (R$)', data: groupedData.map(data => data.ad_demanda_pta_valor || 0) },
    { name: 'Demanda Exc. Reat. (R$)', data: groupedData.map(data => data.demanda_exe_reat_valor || 0) },
    { name: 'Demanda (R$)', data: groupedData.map(data => data.demanda_ult_valor || 0) },
    { name: 'Demanda PTA (R$)', data: groupedData.map(data => data.demanda_ult_pta_valor || 0) },
    { name: 'Demanda FP (R$)', data: groupedData.map(data => data.demanda_ult_fp_valor || 0) },
  ].map(seriesData => ({
    ...seriesData,
    type: 'bar',
    stack: 'total',
  }));

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params.length > 0) {
          let tooltipContent = `${params[0].axisValue}<br />`;

          // Ordena as séries pelo valor, do maior para o menor
          const sortedParams = params.sort((a, b) => b.value - a.value);

          sortedParams.forEach((param) => {
            tooltipContent += `${param.seriesName}: ${param.value.toLocaleString('pt-BR')}<br />`;
          });

          return tooltipContent;
        }
        return 'Sem dados';
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: series,
  };

  return groupedData.length > 0 ? (
    <S.ChartPaperContainer>
      <div style={{ display: 'flex', marginBottom: '5px' }}>
        <S.ContractsIcon />
        <S.TypographyTitle>
          GRÁFICO DE DESPERDÍCIOS ANUAL (R$)
        </S.TypographyTitle>
      </div>
      <ReactECharts
        option={option}
        style={{ height: 450 }}
      />
    </S.ChartPaperContainer>
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
};
