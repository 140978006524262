import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import DateErrorImage from './img/error_message.svg';

export const StyledIoseErrorMessage = styled(Typography)`
  /* color: var(--base); */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-left: 12px;
  margin-top: 5px;

  @media (max-width: 1380px) {
    font-size: 13px;
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledWrapp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

export const StyledImage = styled.div`
  width: ${(props) => (props.width ? props.width : '350px')};
  height: ${(props) => (props.height ? props.height : '300px')};
  background-image: url(${DateErrorImage});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1380px) {
    width: 250px;
    height: 150px;
  }
`;
