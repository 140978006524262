import React from 'react';
import PropType from 'prop-types';

import * as S from './style';

// import { IoseFilters } from 'components';

export default function IoseSubHeaderBarWithFilters({
  title,
  subtitle,
  description,
  dashboard,
}) {
  const renderSubInfo = () => {
    return (
      <S.GridTiles item xs={12} sm={12} md={5} lg={5} xl={5}>
        <div>
          <S.TypographyTitle>{title}</S.TypographyTitle>
          <S.TypographySubTitle>{subtitle}</S.TypographySubTitle>
          <S.TypographyDescription dashboard={dashboard}>
            {description}
          </S.TypographyDescription>
        </div>
      </S.GridTiles>
    );
  };

  // const renderFilters = () => {
  //   return (
  //     <div>
  //       <IoseFilters
  //         dates={dates}
  //         initialDate={initialDate}
  //         finalDate={finalDate}
  //         handleInitialChange={handleInitialChange}
  //         handleFinalChange={handleFinalChange}
  //         handleFilter={handleFilter}
  //       />
  //     </div>
  //   );
  // };

  return (
    <S.GridContainer container>
      {renderSubInfo()}
      {/* {renderFilters()} */}
    </S.GridContainer>
  );
}

IoseSubHeaderBarWithFilters.propTypes = {
  /** This prop get the title of container */
  title: PropType.string,
  /** This prop get   the subtitle of container */
  subtitle: PropType.string,
  /**  This prop get   the description of container */
  description: PropType.string,
  /** This prop get any element inside the IoseSubHeaderBar*/
  content: PropType.element,
  /** This prop get mean button of container */
  button: PropType.element,
  /** This prop works for change the position of element of Grid Content this happen just in dashboard  */
  dashboard_position_icon: PropType.bool,
};
