import React from 'react';
import PropType from 'prop-types';

import * as S from './style';

export default function IoseSubHeaderBar(props) {
  const { title, subtitle, description, button, dashboard } = props;

  const content = props.children;

  return (
    <S.GridContainer container>
      <S.GridTiles item xs={9} sm={6} md={6}>
        <S.TypographyTitle>{title}</S.TypographyTitle>
        <S.TypographySubTitle>{subtitle}</S.TypographySubTitle>
        <S.TypographyDescription dashboard={dashboard}>
          {description}
        </S.TypographyDescription>

        {dashboard === 'true' && (
          <S.GridContent item xs={12} sm={12} md={4} dashboard={dashboard}>
            {content}
          </S.GridContent>
        )}
      </S.GridTiles>

      {button}

      {dashboard === 'true' ? (
        <></>
      ) : (
        <S.GridContent item xs={12} sm={12} md={4} dashboard={dashboard}>
          {content}
        </S.GridContent>
      )}
    </S.GridContainer>
  );
}

IoseSubHeaderBar.propTypes = {
  /** This prop get the title of container */
  title: PropType.string,
  /** This prop get   the subtitle of container */
  subtitle: PropType.string,
  /**  This prop get   the description of container */
  description: PropType.string,
  /** This prop get any element inside the IoseSubHeaderBar*/
  content: PropType.element,
  /** This prop get mean button of container */
  button: PropType.element,
  /** This prop works for change the position of element of Grid Content this happen just in dashboard  */
  dashboard_position_icon: PropType.bool,
};
