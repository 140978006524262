// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCalculateTotalConsumption() {
  const [noDataError, setNoDataError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCalculateTotalConsumption = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getCalculateTotalConsumption(headers);

    const totalConsumptions = response.data.data || [];

    if (totalConsumptions.length === 0) {
      setNoDataError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return totalConsumptions;
  };

  const {
    data: totalConsumptions,
    error: totalConsumptionsError,
    refetch: refreshTotalConsumptions,
    isLoading,
  } = useQuery(['totalConsumptions'], getCalculateTotalConsumption);

  return {
    totalConsumptions,
    totalConsumptionsError,
    noDataError,
    refreshTotalConsumptions,
    isLoading,
  };
}

export default useCalculateTotalConsumption;
