import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import {
  ButtonContainer,
  StyledTable,
  TableHeadStyled,
  TableRowStyled,
} from './style';

import * as XLSX from 'xlsx';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';
import { IoseButton2 } from 'components/IoseButton';
import { useCreateLogs } from 'hooks';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data?.map((obj) => {
    const { uuid_items, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'item_description_1',
    'item_consumption_1',
    'reference_month',
    'item_total_value_1',
    'referente',
  ];

  const reorderedData = filteredData?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      if (column === 'vencimento') {
        reorderedObj[column] = transformDateString(obj[column]);
      } else {
        reorderedObj[column] = obj[column];
      }
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    item_description: 'Itens da Fatura',
    item_consumption_1: 'Consumo do Item',
    reference_month: 'REF: MÊS/ANO',
    item_total_value_1: 'Valor',
    referente: 'Referente',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'itens-fatura-iose.xlsx');
}

function transformDateString(inputDate) {
  const parts = inputDate.split('T')[0].split('-');
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  return `${day}/${month}/${year}`;
}

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function formatNumberToCurrency(numberString) {
  const number = Number(numberString);

  if (isNaN(number)) {
    return 'Invalid Number';
  }

  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}
const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <TableRowStyled
      align="center"
      onClick={() => onClick(value)}
      className={classes.tableRow}
    >
      <TableCellCentered>{value.identification_number}</TableCellCentered>
      <TableCellCentered>{value.item_description_1}</TableCellCentered>
      <TableCellCentered>{value.item_consumption_1}</TableCellCentered>
      <TableCellCentered>{value.reference_month}</TableCellCentered>
      <TableCellCentered>
        {formatNumberToCurrency(value.item_total_value_1)}
      </TableCellCentered>
      <TableCellCentered>{value.referente}</TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>UC</TableHeaderCellCentered>
        <TableHeaderCellCentered>ITENS DA FATURA</TableHeaderCellCentered>
        <TableHeaderCellCentered>CONSUMO</TableHeaderCellCentered>
        <TableHeaderCellCentered>REF: MÊS/ANO</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR</TableHeaderCellCentered>
        <TableHeaderCellCentered>REFERENTE</TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseItemBillSearchTable({
  loading,
  data,
  onClickFunction,
  hasReportPermission,
}) {
  const { createLog } = useCreateLogs();

  const handleDownloadButtonClick = async (data) => {
    exportToExcel(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE ITENS DA FATURA';
    const logContent = {};

    await createLog(operacao, form, logContent);
  };

  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          {hasReportPermission ? (
            <>
              <ButtonContainer>
                <IoseButton2 onClick={() => handleDownloadButtonClick(data)}>
                  Download XLSX
                </IoseButton2>
              </ButtonContainer>
            </>
          ) : null}
          <CircuitsTable
            data={data}
            id="scrollableDiv"
            onRowClick={onClickFunction}
          />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="400px"
          width="400px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={data?.length}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={0}
          padding={0}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={600}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
