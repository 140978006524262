import React, { useEffect, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  IoseMercadoLIvreDashHeader,
  IoseSubHeaderBarWithFilters,
  IoseAlertLoadingCards,
  IoseButton2,
  IoseNoDataMessage,
} from 'components';

import * as S from './style';
import { useCreateLogs, useGroupPermissions } from 'hooks';
import { LocalStorage } from 'common';
import Permissions from 'common/permissions';
import MercadoLivreDashboardFilters from './components/mercado-livre-dashboard-filtros';
import MercadoLivreTable from './components/mercado-livre-table';
import { xlsxReports } from 'reports/Xlsx';
import MercadoLivreProcessingModal from './components/mercado-livre-processing-modal';

export default function MercadoLivreContainer() {
  const { groupPermissions } = useGroupPermissions();
  const { createLog } = useCreateLogs();
  const userSession = LocalStorage.getSession();

  const [loading, setLoading] = useState(true);
  //eslint-disable-next-line
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [mercadoLivre, setFilteredMercadoLivre] = useState([]);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [processingData, setProcessingData] = useState();
  const [processedData, setProcessedData] = useState();
  const [isProcessingVisible, setIsProcessingVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, []);

  useEffect(() => {
    const readPath = ['dashboards', 'faturas', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  function sumByComercializadora(data) {
    const groupedData = {};

    data?.forEach((item) => {
      const comercializadora = item?.comercializadora;

      if (groupedData[comercializadora]) {
        groupedData[comercializadora].quantity += item?.quantity;
      } else {
        groupedData[comercializadora] = {
          ...item,
          quantity: item?.quantity,
        };
      }
    });

    return Object.values(groupedData);
  }

  useEffect(() => {
    const billLogsFormatted = sumByComercializadora(processingData);

    setProcessedData(billLogsFormatted);
  }, [processingData]);

  const cleanFilters = () => {
    setFilteredMercadoLivre([]);
  };

  const renderCards = () => {
    return (
      <IoseMercadoLIvreDashHeader
        loading={loading}
        mercadoLivre={mercadoLivre}
      />
    );
  };
  const isReportButtonDisabled = mercadoLivre?.length === 0;

  async function handleDownloadConsumoAcl(data) {
    xlsxReports.exportMercadoLivreonsumptionTableToExcel(data);

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO DE CONSUMOS MERCADO LIVRE - XLSX';
    const logContent = {};

    await createLog(operacao, form, logContent);
  }

  return (
    <S.GridContainer>
      <S.HeaderDiv>
        <S.LeftHeaderDiv>
          <S.Title>Dashboard de mercado livre</S.Title>
          <MercadoLivreDashboardFilters
            setFilteredMercadoLivre={setFilteredMercadoLivre}
            setSelectedRefMonth={setSelectedRefMonth}
            setProcessingData={setProcessingData}
            cleanFilters={cleanFilters}
            setLoading={setLoading}
          />
        </S.LeftHeaderDiv>
        <S.RightHeaderDiv>
          {isProcessingVisible ? (
            <MercadoLivreProcessingModal
              data={processedData}
              onClose={() => setIsProcessingVisible(false)}
            />
          ) : null}
        </S.RightHeaderDiv>
      </S.HeaderDiv>
      {hasReadPermission ? (
        <>
          <IoseSubHeaderBarWithFilters title={'Totais Gerais: '} />
          {renderCards()}
          <S.GridDataContainer>
            <S.TableMercadoLivreContainer>
              <S.HeaderContainer>
                <div style={{ display: 'flex' }}>
                  <S.ContractsIcon />
                  <S.TypographyTitle>CONSUMO</S.TypographyTitle>
                </div>
                <div>
                  <IoseButton2
                    onClick={() => handleDownloadConsumoAcl(mercadoLivre)}
                    disabled={isReportButtonDisabled}
                    width="80px"
                  >
                    <span style={{ marginRight: '5px' }}>XLSX</span>
                    <GetAppIcon />
                  </IoseButton2>
                </div>
              </S.HeaderContainer>
              {mercadoLivre.length > 0 ? (
                <MercadoLivreTable data={mercadoLivre} loading={loading} />
              ) : (
                <IoseNoDataMessage
                  message="Ops... sem dados suficientes para exibir a tabela!"
                  height="200px"
                  width="200px"
                />
              )}
            </S.TableMercadoLivreContainer>
          </S.GridDataContainer>
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.GridContainer>
  );
}
