import React, { useState } from 'react';
import * as S from './style';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';

const DynamicTable = ({data, setData}) => {
  const [edicao, setEdicao] = useState({
    mes: null,
    campoEditado: null,
  });

  const [novoDado, setNovoDado] = useState('');

  const handleDoubleClick = (mes, campoEditado, valorCampo) => {
    setEdicao({
      mes: mes,
      campoEditado: campoEditado,
    });
    setNovoDado(valorCampo);
  };

  const handleOkClick = (mes) => {
    const mesEditado = mes;
    const campoEditado = edicao.campoEditado;
    const novoValor = novoDado;

    setData((prevDados) =>
      prevDados.map((item) =>
        item.mes === mesEditado
          ? { ...item, [campoEditado]: novoValor }
          : item
      )
    );
    setEdicao({ mes: null, campoEditado: null });
  };

  const handleCancelClick = () => {
    setEdicao({ mes: null, campoEditado: null });
  };

  const renderEditableCell = (item, campo) => {
    return (
      <S.StyledTd
        key={`${item.mes}-${campo}`}
        onDoubleClick={() =>
          handleDoubleClick(item.mes, campo, item[campo])
        }
      >
        {edicao.mes === item.mes &&
        edicao.campoEditado === campo ? (
          <>
            <S.InputContainer>
              <S.InputField
                type="text"
                value={novoDado}
                onChange={(e) => setNovoDado(e.target.value)}
              />
              <S.ButtonContainer>
                <S.RemoveButton onClick={handleCancelClick}>
                  <RemoveIcon />
                </S.RemoveButton>
                <S.CheckButton
                  onClick={() => handleOkClick(item.mes)}
                >
                  <CheckIcon />
                </S.CheckButton>
              </S.ButtonContainer>
            </S.InputContainer>
          </>
        ) : (
          item[campo]
        )}
      </S.StyledTd>
    );
  };

  return (
    <S.StyledTable>
      <thead>
        <tr>
          <S.StyledTh>MES</S.StyledTh>
          <S.StyledTh>DEMANDA PONTA (kW)</S.StyledTh>
          <S.StyledTh>DEMANDA FORA PONTA (kW)</S.StyledTh>
          <S.StyledTh>CONSUMO PONTA (kWh)</S.StyledTh>
          <S.StyledTh>CONSUMO FORA PONTA (kWh)</S.StyledTh>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.mes}>
            <S.StyledTd>{item.mes}</S.StyledTd>
            {renderEditableCell(item, 'demanda_ponta')}
            {renderEditableCell(item, 'demanda_fora_ponta')}
            {renderEditableCell(item, 'consumo_ponta')}
            {renderEditableCell(item, 'consumo_fora_ponta')}
          </tr>
        ))}
      </tbody>
    </S.StyledTable>
  );
};

export default DynamicTable;
