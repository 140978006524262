import React, { useEffect, useState, useCallback } from 'react';
import PropType from 'prop-types';
import * as echarts from 'echarts';
import axios from 'axios';
import { WrappedContainer } from './style';
import { useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';
import { LocalStorage } from 'common';
import { IoseAlertLoadingCards, LoadingIndicator } from 'components';
import * as S from './style';
import IoseCardCo2 from 'components/Cards/IoseCardCo2';

export default function CarbonDashContainer() {
  const apiUrl =
    'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/scanCarbon';
  const secondApiUrl =
    'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/scanCarbonSolar';

  const [chartData, setChartData] = useState([]);
  const [secondChartData, setSecondChartData] = useState([]);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [loading, setLoading] = useState(true);

  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    const readPath = ['dashboards', 'emissao_carbono', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  const Card = ({ title, content, footer, loading }) => {
    return (
      <S.CardPaper>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <S.TitleSeparator>
                <S.CardTitle>{title}</S.CardTitle>
              </S.TitleSeparator>
            </div>
            <div style={{ flexGrow: 1 }}>{content}</div>
            <div style={{ borderTop: '1px solid #eee', paddingTop: '10px' }}>
              {footer}
            </div>
          </div>
        )}
      </S.CardPaper>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Primeira chamada de API
        const response1 = await axios.get(apiUrl);
        const data1 = JSON.parse(response1.data.body);
        setChartData(data1);

        // Segunda chamada de API
        const response2 = await axios.get(secondApiUrl);
        const data2 = JSON.parse(response2.data.body);
        setSecondChartData(data2);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, []);

  const initComparisonChart = useCallback(() => {
    const chartDom = document.getElementById('comparisonChart');
    const myChart = echarts.init(chartDom);

    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    let comparisonData = months.map(() => ({ conventional: 0, solar: 0 }));

    chartData.forEach((item) => {
      const index = item.mes - 1;
      comparisonData[index].conventional += Number(item.resultado_tco2);
    });

    secondChartData.forEach((item) => {
      const index = item.mes - 1;
      comparisonData[index].solar += Number(item.resultado_tco2);
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          if (params.length > 0) {
            const tooltipContent = params
              .map((param) => {
                const seriesName = param.seriesName;
                const value =
                  param.value !== undefined && param.value !== null
                    ? param.value
                    : 'Sem dados';

                const numericValue =
                  typeof value === 'string' ? parseFloat(value) : value;

                return `<div><strong>${seriesName}</strong>: ${numericValue.toLocaleString(
                  'pt-BR'
                )}</div>`;
              })
              .join('');

            return tooltipContent;
          }

          return 'Sem dados';
        },
      },
      legend: {
        data: ['CO2 Convencional', 'CO2 Solar'],
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: months, // Usando a variável 'months' aqui
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        axisLabel: {
          formatter: (value) => {
            if (value >= 1e6) {
              return (value / 1e6).toFixed(2) + 'Mt'; // Milhões de toneladas
            } else if (value >= 1e3) {
              return (value / 1e3).toFixed(2) + 'kt'; // Milhares de toneladas
            } else if (value >= 1) {
              return value.toFixed(2) + 't'; // Toneladas
            } else if (value >= 1e-3) {
              return (value * 1e3).toFixed(0) + 'g'; // Gramas
            } else {
              return (value * 1e6).toFixed(0) + 'mg'; // Miligramas
            }
          },
        },
      },
      series: [
        {
          name: 'CO2 Convencional',
          type: 'line',
          data: comparisonData.map((data) => data.conventional.toFixed(2)),
          smooth: false,
        },
        {
          name: 'CO2 Solar',
          type: 'line',
          data: comparisonData.map((data) => data.solar),
          smooth: false,
        },
      ],
    };

    myChart.setOption(option);
  }, [chartData, secondChartData]);

  const initTimeSeriesChart = useCallback(() => {
    const chartDom = document.getElementById('timeSeriesChart');
    const myChart = echarts.init(chartDom);

    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    let monthlyData = {};

    // Inicializa os dados mensais com zeros
    months.forEach((month) => {
      monthlyData[month] = { kwh_total: 0, resultado_tco2: 0 };
    });

    // Preenche os dados existentes
    chartData.forEach((item) => {
      const monthName = months[item.mes - 1];
      monthlyData[monthName].kwh_total += item.kwh_total;
      monthlyData[monthName].resultado_tco2 += item.resultado_tco2;
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          if (params.length > 0) {
            const tooltipContent = params
              .map((param) => {
                const seriesName = param.seriesName;
                const value =
                  param.value !== undefined && param.value !== null
                    ? param.value
                    : 'Sem dados';

                const numericValue =
                  typeof value === 'string' ? parseFloat(value) : value;

                return `<div><strong>${seriesName}</strong>: ${numericValue.toLocaleString(
                  'pt-BR'
                )}</div>`;
              })
              .join('');

            return tooltipContent;
          }

          return 'Sem dados';
        },
      },
      legend: {
        data: ['Consumo Total kWh', 'Emissão de CO2'],
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: months,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => {
            if (value < 1000) return value.toString();
            if (value >= 1000 && value < 1000000)
              return (value / 1000).toFixed(1) + 'k';
            if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M';
          },
        },
      },
      series: [
        {
          name: 'Consumo Total kWh',
          type: 'line',
          data: months.map((month) => monthlyData[month].kwh_total.toFixed(2)),
          smooth: true,
          lineStyle: {
            width: 15,
          },
        },
        {
          name: 'Emissão de CO2',
          type: 'line',
          data: months.map((month) =>
            monthlyData[month].resultado_tco2.toFixed(2)
          ),
          smooth: true,
          lineStyle: {
            width: 3,
          },
        },
      ],
    };
    myChart.setOption(option);
  }, [chartData]);

  const initDistributionChart = useCallback(() => {
    const chartDom = document.getElementById('distributionChart');
    const myChart = echarts.init(chartDom);
    const concessionaires = [
      ...new Set(chartData.map((item) => item.concessionaire)),
    ];
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          if (params.length > 0) {
            const tooltipContent = params
              .map((param) => {
                const seriesName = param.seriesName;
                const value =
                  param.value !== undefined && param.value !== null
                    ? param.value
                    : 'Sem dados';

                const numericValue =
                  typeof value === 'string' ? parseFloat(value) : value;

                return `<div> <strong>${seriesName}</strong>: ${numericValue.toLocaleString(
                  'pt-BR'
                )}</div>`;
              })
              .join('');

            return tooltipContent;
          }

          return 'Sem dados';
        },
      },
      xAxis: {
        type: 'category',
        data: concessionaires,
      },
      yAxis: {
        type: 'log',
        logBase: 10,
        min: 1,
        axisLabel: {
          formatter: (value) => {
            if (value < 1000) return value.toString();
            if (value < 1000000) return value / 1000 + 'k';
            return (value / 1000000).toLocaleString('pt-BR') + 'M';
          },
        },
      },
      series: [
        {
          name: 'Consumo Total kWh por Concessionária',
          type: 'bar',
          data: concessionaires.map((concessionaire) => {
            const totalKwh = chartData
              .filter((item) => item.concessionaire === concessionaire)
              .reduce((total, item) => total + item.kwh_total, 0);
            return {
              name: concessionaire,
              value: totalKwh,
            };
          }),
          itemStyle: {
            color: '#1f77b4',
          },
          label: {
            show: true,
            position: 'top',
            formatter: (params) => {
              const value = params.value;
              if (value >= 1000000000)
                return (value / 1000000000).toLocaleString('pt-BR') + 'G+';
              if (value >= 1000000)
                return (value / 1000000).toLocaleString('pt-BR') + 'M';
              if (value >= 1000)
                return (value / 1000).toLocaleString('pt-BR') + 'k';
              return value.toLocaleString('pt-BR');
            },
            overflow: 'truncate',
            width: 70,
          },
        },
      ],
    };
    myChart.setOption(option);
  }, [chartData]);

  const initYearlyCO2Chart = useCallback(() => {
    const chartDom = document.getElementById('yearlyCO2Chart');
    const myChart = echarts.init(chartDom);

    const groupedData = {};
    chartData.forEach((item) => {
      const yearMonthKey = `${item.ano}-${item.mes}`;
      if (!groupedData[yearMonthKey]) {
        groupedData[yearMonthKey] = 0;
      }
      groupedData[yearMonthKey] += item.resultado_tco2;
    });

    const sortedKeys = Object.keys(groupedData).sort();
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const sortedKeysWithNames = sortedKeys.map((key) => {
      const [year, monthNumber] = key.split('-');
      const monthIndex = parseInt(monthNumber, 10) - 1; // convert to 0-indexed
      return `${monthNames[monthIndex]}, ${year}`;
    });

    const seriesData = sortedKeys.map((key) => groupedData[key]);

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          if (params.length > 0) {
            const tooltipContent = params
              .map((param) => {
                const seriesName = param.seriesName;
                const value =
                  param.value[0] !== undefined && param.value[0] !== null
                    ? param.value[0]
                    : 'Sem dados';

                const numericValue =
                  typeof value === 'string' ? parseFloat(value) : value;

                return `<div><strong>${seriesName}</strong>: ${numericValue.toLocaleString(
                  'pt-BR'
                )}</div>`;
              })
              .join('');

            return tooltipContent;
          }

          return 'Sem dados';
        },
      },
      legend: {
        data: ['Emissão de CO2'],
      },
      xAxis: {
        type: 'category',
        data: sortedKeysWithNames,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => {
            if (value < 1000) return value.toString();
            if (value >= 1000 && value < 1000000)
              return (value / 1000).toFixed(1) + 'k';
            if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M';
            return value.toLocaleString('pt-BR');
          },
        },
      },
      series: [
        {
          name: 'Emissão de CO2',
          type: 'bar', // ou 'line' para gráfico de área
          data: seriesData.map((value) => {
            // Trunca o valor para duas casas decimais
            return parseFloat(value.toFixed(2));
          }),
          emphasis: {
            focus: 'series',
          },
          smooth: true,
          itemStyle: {
            // Ajustes adicionais de estilo podem ser feitos aqui
          },
          label: {
            show: true,
            position: 'top',
            formatter: (params) => {
              // Converte o valor para uma string com vírgula como separador decimal
              return params.value.toLocaleString('pt-BR');
            },
          },
        },
      ],
    };

    myChart.setOption(option);
  }, [chartData]);

  const initEnergyConsumptionChart = useCallback(() => {
    const chartDom = document.getElementById('energyConsumptionChart');
    const myChart = echarts.init(chartDom);

    // Dados estáticos do gráfico de consumo de energia
    const data = [
      { value: 31.6, name: 'Petróleo 31,6' },
      { value: 26.7, name: 'Carvão 26,7' },
      { value: 23.5, name: 'Gás Natural 23,5' },
      { value: 7.5, name: 'Nuclear 7,5' },
      { value: 6.7, name: 'Hidrelétrica 6,7' },
      { value: 4.0, name: 'Renováveis 4,0' },
    ];

    const option = {
      tooltip: {
        trigger: 'item',
      },
      label: {
        // ...
        fontSize: 11,
        formatter: function (params) {
          return params.name.replace(' ', '\n');
        },
      },

      series: [
        {
          name: 'Fonte de energia',
          type: 'pie',
          radius: '45%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    myChart.setOption(option);
  }, []);

  useEffect(() => {
    if (hasReadPermission && chartData.length > 0 && !loading) {
      initTimeSeriesChart();
      initDistributionChart();
      initYearlyCO2Chart();
      initEnergyConsumptionChart();
    }

    if (
      hasReadPermission &&
      chartData.length > 0 &&
      secondChartData.length > 0
    ) {
      initComparisonChart();
    }
  }, [
    chartData,
    secondChartData,
    initTimeSeriesChart,
    initYearlyCO2Chart,
    initEnergyConsumptionChart,
    initDistributionChart,
    initComparisonChart,
    hasReadPermission,
    loading,
  ]);

  const renderCards = () => {
    return (
      <>
        <S.ContainerPaper>
          <IoseCardCo2 data={chartData} loading={loading} />
        </S.ContainerPaper>
      </>
    );
  };

  const renderCharts = () => {
    return (
      <>
        <S.Wrapper>
          <S.ChartsContainer>
            <Card
              title="CONSUMO X MÊS"
              loading={loading}
              content={
                <div
                  id="timeSeriesChart"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              }
            />

            <Card
              title="CONSUMO X CONCESSIONÁRIA"
              loading={loading}
              content={
                <div
                  id="distributionChart"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              }
            />

            <Card
              title="CONSUMO ANUAL DE C02"
              loading={loading}
              content={
                <div
                  id="yearlyCO2Chart"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              }
              footer={<p></p>}
            />
          </S.ChartsContainer>
        </S.Wrapper>

        <S.Wrapper>
          <S.ChartsContainer>
            <Card
              title="COMPARAÇÃO DE EMISSÃO DE CO2"
              loading={loading}
              content={
                <div
                  id="comparisonChart"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              }
            />

            <Card
              title="CONSUMO PRIMÁRIO DE ENERGIA GLOBAL 2022"
              loading={loading}
              content={
                <div
                  id="energyConsumptionChart"
                  style={{ maxWidth: '100%', height: '300px' }}
                />
              }
              footer={
                <p>
                  Fonte:{' '}
                  <a
                    href="https://www.forbes.com/sites/rrapier/2023/08/06/global-energy-trends-insights-from-the-2023-statistical-review-of-world-energy/?sh=73c496163502"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Forbes
                  </a>
                </p>
              }
            />
          </S.ChartsContainer>
        </S.Wrapper>
      </>
    );
  };

  return (
    <div>
      <WrappedContainer>
        <h3
          style={{
            fontSize: '23px',
            paddingTop: '5px',
            color: '#015A9E',
            marginTop: '15px',
          }}
        >
          Dashboard de emissão de CO2
        </h3>
        <h2 style={{ color: 'grey', paddingTop: '11px', marginBottom: '10px' }}>
          Comparação de emissão de CO2
        </h2>
      </WrappedContainer>

      {hasReadPermission ? (
        <>
          {renderCards()}
          {renderCharts()}
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </div>
  );
}

CarbonDashContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
