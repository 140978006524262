import React from 'react';
import PropType from 'prop-types';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import IoseButtonSideBar from 'components/IoseButton';
import { IoseBackButton } from 'components/IoseButtonIcon';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
  DivInputsSwitchboard,
} from './style';
import { IoseTextFieldSidebarStyled2 } from 'components/IoseFormUnityAddCard/style';

/**
 * This component is a form to create a switchboard(Group) in the system.
 */
export default function IoseFormSwitchboardCreateCard({
  addSwitchboard,
  clickBack,
}) {
  //Schema validation for add Switchboard
  const SwitchboardAddSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.name,
    goal: validationsForm.number,
    identification_number: validationsForm.identificationNumber,
  });

  //Labels forms to render fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
    { label: 'Meta', variable: 'goal' },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      const identificationNumber = parseInt(values.identification_number);

      const SwitchboadData = {
        name: values.name,
        description: values.description,
        goal: values.goal,
        identification_number: identificationNumber,
      };
      setSubmitting(false);
      addSwitchboard(SwitchboadData);
    }, 500);
  };

  return (
    <>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: '',
            description: '',
            goal: 0,
            identification_number: 0,
          }}
          validationSchema={SwitchboardAddSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>
                  <IoseBackButton onClick={(e) => clickBack()} />
                  Criar
                </div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>
              {/**Inputs*/}
              {labels.map((value) => {
                return (
                  <Field
                    component={IoseTextFieldSidebarStyled}
                    key={value.variable}
                    id={value.variable}
                    name={value.variable}
                    type={'text'}
                    label={value.label}
                    defaultvalue={initialValues[value.variable]}
                    onchange={handleChange}
                    onblur={handleBlur}
                    helpertext={
                      errors[value.variable] && touched[value.variable]
                        ? errors[value.variable]
                        : ''
                    }
                  />
                );
              })}
              <DivInputsSwitchboard>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'identification_number'}
                  name={'identification_number'}
                  type={'text'}
                  label={'Unidade Consumidora'}
                  defaultvalue={initialValues['identification_number']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['identification_number'] &&
                    touched['identification_number']
                      ? errors['identification_number']
                      : ''
                  }
                />
              </DivInputsSwitchboard>
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseFormSwitchboardCreateCard.propTypes = {
  /** This props is a function to add switchboard in container */
  addSwitchboard: PropType.func,
  /** This props is a function to return a switchboard lsit  */
  clickBack: PropType.func,
};
