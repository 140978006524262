import React from 'react';
import PropType from 'prop-types';
import { LoaderStyle, LoaderSidebarStyle, LoaderDashboardStyle } from './style';

/** This component is a group of spins used in system
 *@visibleName IoseLoader
 */
export const LoadingIndicator = (props) => {
  const loading = props.loading;

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderStyle
          type="Puff"
          style={{ color: '#015A9E' }}
          height="100"
          width="100"
          margin={props.margin}
        />
      </div>
    )
  );
};

export const MapLoading = (props) => {
  const loading = props.loading;

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
        }}
      >
        <LoaderStyle
          type="Puff"
          style={{ color: '#015A9E' }}
          height="100"
          width="100"
          margin={props.margin}
        />
      </div>
    )
  );
};

export const LoadingIndicatorSidebar = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderSidebarStyle
          type="ThreeDots"
          color={color}
          height="100"
          width="100"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const LoadingIndicatorResponsive = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div style={{ alignItems: 'center' }}>
        <LoaderSidebarStyle
          type="ThreeDots"
          color={color}
          height="80"
          width="80"
        />
      </div>
    )
  );
};

export const LoadingIndicatorDashboard = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderDashboardStyle
          type="Bars"
          color={color}
          height="100"
          width="100"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const LoadingICards = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderDashboardStyle
          type="Puff"
          color={color}
          height="50"
          width="50"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const LoadingValueCards = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '50%',
          height: '10',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoaderDashboardStyle
          type="ThreeDots"
          color={color}
          height="5"
          width="20"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const NewLoadingValueCards = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '20%',
          height: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoaderDashboardStyle
          type="ThreeDots"
          color={color}
          height="5"
          width="20"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const LoadingAuth = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div>
        <LoaderDashboardStyle
          type="ThreeDots"
          color={color}
          height="100"
          width="100"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

export const UploadLoading = (props) => {
  const loading = props.loading;

  const color = props.color ? props.color : '#015A9E';
  // setTimeout(() => {
  //   loading = false;
  // }, 15000);

  return (
    loading && (
      <div
        style={{
          width: '100%',
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderSidebarStyle
          type="ThreeDots"
          color={color}
          height="80"
          width="80"
          margin={props.margin}
          margin767px={props.margin767px}
        />
      </div>
    )
  );
};

LoadingIndicator.propTypes = {
  /** This prop is a boolean tha indicates if will be loading or not */
  loading: PropType.bool,
  /** This prop set margin of component */
  margin: PropType.string,
};
