import { useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useEnergyBillLogs() {
  const { getSession } = useContext(AccountContext);

  const getEnergyBillLogs = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllEnergyBillLogs(headers, 10000, 300);

    const { data } = response.data;

    const energyBillLogs = data || [];

    if (energyBillLogs.length === 0) {
      console.error(Messages.noFindLogs);
      throw new Error(Messages.noFindLogs);
    }

    return energyBillLogs;
  };

  const {
    data: energyBillLogs,
    error: energyBillLogsError,
    isLoading,
  } = useQuery(['energyBillLogs'], getEnergyBillLogs);

  return { energyBillLogs, energyBillLogsError, isLoading };
}

export default useEnergyBillLogs;
