function converterData(dataString, temDia = true) {
  if (temDia) {
    const partes = dataString.split('/');
    return new Date(partes[2], partes[1] - 1, partes[0]);
  } else {
    const partes = dataString.split('/');
    return new Date(1, partes[1] - 1, partes[0]);
  }
}

const realizaCalculosTarifa = (dados) => {
  const dataAtual = new Date();
  const tarifas = dados.tarifas;
  const faturas = dados.faturas;
  const items = dados.itens;
  const demanda = dados.demandas[0];
  const demanda_fora = dados.demandas[1];

  let valores = [];

  faturas.forEach((fatura) => {
    const pis = fatura.taxes_data.pis_aliquot / 100;
    const cofins = fatura.taxes_data.cofins_aliquot / 100;
    const icms = fatura.taxes_data.icms_aliquot / 100;
    let fatura_tarifarias = {
      'mes': '',
      'fatura': 0,
      'azul': 0,
      'verde': 0,
      'consumoFatura': 0,
      'consumoAzul': 0,
      'consumoVerde': 0,
      'demandaFatura': 0,
      'demandaAzul': 0,
      'demandaVerde': 0,
      'demandaUltrapFatura': 0,
      'demandaUltrapAzul': 0,
      'demandaUltrapVerde': 0,
      'adcionalDemandaFatura': 0,
      'adcionalDemandaAzul': 0,
      'adcionalDemandaVerde': 0,
      'reativosFatura': 0,
      'reativosVerde': 0,
      'reativosAzul': 0,
      'outros': 0,
    };

    let contador = 1;
    let tarifaB1 = 0;
    tarifas.forEach((tarifa) => {
      if (
        dataAtual <= converterData(tarifa.fim_vigencia) &&
        dataAtual >= converterData(tarifa.inicio_vigencia)
      ) {
        if (
          tarifa.subgrupo === 'B1' &&
          tarifa.concessionaria === dados.concessionaria
        ) {
          tarifaB1 = tarifa.valor;
        }
      }
    });
    tarifas.forEach((tarifa) => {
      if (
        dataAtual <= converterData(tarifa.fim_vigencia) &&
        dataAtual >= converterData(tarifa.inicio_vigencia)
      ) {
        if (
          tarifa.subgrupo === dados.subgrupo &&
          tarifa.concessionaria === dados.concessionaria
        ) {
          const valor_tarifa = tarifa.valor;
          fatura_tarifarias.mes = fatura.reference_month;
          items.forEach((item) => {
            const valor_unitario =
              valor_tarifa / 1000 / ((1 - (cofins + pis)) * (1 - icms));
            if (
              item.reference_month === fatura.reference_month &&
              fatura.pagina === '1/1'
            ) {
              if (
                item.referente === 'Consumo PTA' &&
                tarifa.tarifa === 'TUSD' &&
                (item.tarifa === 'TUSD' || item.tarifa === 'SOMA') &&
                tarifa.posto === 'PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                fatura_tarifarias.consumoFatura += item.item_total_value_1;
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo PTA' &&
                tarifa.tarifa === 'TE' &&
                (item.tarifa === 'TE' || item.tarifa === 'SOMA') &&
                tarifa.posto === 'PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (!(item.tarifa === 'SOMA')) {
                  fatura_tarifarias.consumoFatura += item.item_total_value_1;
                }
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo FP' &&
                tarifa.tarifa === 'TUSD' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TUSD') &&
                tarifa.posto === 'FORA PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                if (!(item.tarifa === 'SOMA')) {
                  fatura_tarifarias.consumoFatura += item.item_total_value_1;
                }
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo FP' &&
                tarifa.tarifa === 'TE' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TE') &&
                tarifa.posto === 'FORA PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                fatura_tarifarias.consumoFatura += item.item_total_value_1;
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo' &&
                tarifa.tarifa === 'TE' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TE') &&
                tarifa.posto === 'FORA PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                fatura_tarifarias.consumoFatura += item.item_total_value_1;
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo' &&
                tarifa.tarifa === 'TUSD' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TUSD') &&
                tarifa.posto === 'PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                if (!item.tarifa === 'SOMA') {
                  fatura_tarifarias.consumoFatura += item.item_total_value_1;
                }
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo' &&
                tarifa.tarifa === 'TE' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TE') &&
                tarifa.posto === 'PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                fatura_tarifarias.consumoFatura += item.item_total_value_1;
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Consumo' &&
                tarifa.tarifa === 'TUSD' &&
                (item.tarifa === 'SOMA' || item.tarifa === 'TUSD') &&
                tarifa.posto === 'FORA PONTA' &&
                tarifa.grandeza === 'CONSUMO'
              ) {
                if (!item.tarifa === 'SOMA') {
                  fatura_tarifarias.consumoFatura += item.item_total_value_1;
                }
                const valor_energia = valor_unitario * item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.consumoAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.consumoVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Demanda' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA PONTA' ||
                  tarifa.posto === 'NA' ||
                  tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaAzul += valor_energia;
                  fatura_tarifarias.demandaFatura +=
                    item.item_total_value_1 / 2;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.demandaVerde += valor_energia;
                  fatura_tarifarias.demandaFatura += item.item_total_value_1;
                }
              }
              if (
                item.referente === 'Demanda PTA' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                fatura_tarifarias.demandaFatura += item.item_total_value_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaAzul += valor_energia;
                }
              }
              if (
                item.referente === 'Demanda FP' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                fatura_tarifarias.demandaFatura += item.item_total_value_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.demandaVerde += valor_energia;
                }
              }

              if (
                item.referente === 'Demanda Ultrap.' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA PONTA' ||
                  tarifa.posto === 'NA' ||
                  tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  ((valor_tarifa * 2) / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaUltrapAzul += valor_energia;
                  fatura_tarifarias.demandaUltrapFatura +=
                    item.item_total_value_1 / 2;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.demandaUltrapVerde += valor_energia;
                  fatura_tarifarias.demandaUltrapFatura +=
                    item.item_total_value_1;
                }
              }
              if (
                item.referente === 'Demanda Ultrap. FP' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  ((valor_tarifa * 2) / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                fatura_tarifarias.demandaUltrapFatura +=
                  item.item_total_value_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaUltrapAzul += valor_energia;
                } else if (tarifa.modalidade === 'VERDE') {
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.demandaUltrapVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Demanda Ultrap. PTA' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  ((valor_tarifa * 2) / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                fatura_tarifarias.demandaUltrapFatura +=
                  item.item_total_value_1;
                if (tarifa.modalidade === 'AZUL') {
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.demandaUltrapAzul += valor_energia;
                }
              }
              if (
                item.referente === 'Adicional de demanda' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' ||
                  tarifa.posto === 'NA PONTA' ||
                  tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / (1 - (cofins + pis))) *
                  item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  if (demanda === 30 || demanda_fora === 30) {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.demandaAzul += valor_energia;
                    fatura_tarifarias.demandaFatura +=
                      item.item_total_value_1 / 2;
                  } else {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.adcionalDemandaAzul += valor_energia;
                    fatura_tarifarias.adcionalDemandaFatura +=
                      item.item_total_value_1 / 2;
                  }
                } else if (tarifa.modalidade === 'VERDE') {
                  if (demanda === 30 || demanda_fora === 30) {
                    fatura_tarifarias.verde += valor_energia;
                    fatura_tarifarias.demandaVerde += valor_energia;
                    fatura_tarifarias.demandaFatura += item.item_total_value_1;
                  } else {
                    fatura_tarifarias.verde += valor_energia;
                    fatura_tarifarias.adcionalDemandaVerde += valor_energia;
                    fatura_tarifarias.adcionalDemandaFatura +=
                      item.item_total_value_1;
                  }
                }
              }
              if (
                item.referente === 'Adicional de demanda PTA' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / (1 - (cofins + pis))) *
                  item.item_consumption_1;
                if (tarifa.modalidade === 'AZUL') {
                  if (demanda === 30 || demanda_fora === 30) {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.demandaAzul += valor_energia;
                    fatura_tarifarias.demandaFatura += item.item_total_value_1;
                  } else {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.adcionalDemandaAzul += valor_energia;
                    fatura_tarifarias.adcionalDemandaFatura +=
                      item.item_total_value_1;
                  }
                }
              }
              if (
                item.referente === 'Adicional de demanda FP' &&
                tarifa.tarifa === 'TUSD' &&
                item.tarifa === 'TUSD' &&
                (tarifa.posto === 'NA' || tarifa.posto === 'NA FORA PONTA') &&
                tarifa.grandeza === 'DEMANDA'
              ) {
                const valor_energia =
                  (valor_tarifa / (1 - (cofins + pis))) *
                  item.item_consumption_1;

                if (tarifa.modalidade === 'AZUL') {
                  if (demanda === 30 || demanda_fora === 30) {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.demandaAzul += valor_energia;
                    fatura_tarifarias.demandaFatura += item.item_total_value_1;
                  } else {
                    fatura_tarifarias.azul += valor_energia;
                    fatura_tarifarias.adcionalDemandaAzul += valor_energia;
                    fatura_tarifarias.adcionalDemandaFatura +=
                      item.item_total_value_1;
                  }
                } else if (tarifa.modalidade === 'VERDE') {
                  if (demanda <= 30) {
                    fatura_tarifarias.verde += valor_energia;
                    fatura_tarifarias.demandaVerde += valor_energia;
                    fatura_tarifarias.demandaFatura += item.item_total_value_1;
                  } else {
                    fatura_tarifarias.verde += valor_energia;
                    fatura_tarifarias.adcionalDemandaVerde += valor_energia;
                    fatura_tarifarias.adcionalDemandaFatura +=
                      item.item_total_value_1;
                  }
                }
              }
              if (item.referente === 'Outros') {
                const valor_energia = item.item_total_value_1;
                if (contador === 1) {
                  fatura_tarifarias.outros += valor_energia;
                  fatura_tarifarias.fatura =
                    fatura_tarifarias.fatura + fatura_tarifarias.outros;
                  fatura_tarifarias.azul =
                    fatura_tarifarias.azul + fatura_tarifarias.outros;
                  fatura_tarifarias.verde =
                    fatura_tarifarias.verde + fatura_tarifarias.outros;
                }
              }
              if (item.referente === 'CIP') {
                const valor_energia = item.item_total_value_1;
                if (contador === 1) {
                  fatura_tarifarias.outros += valor_energia;
                  fatura_tarifarias.fatura =
                    fatura_tarifarias.fatura + fatura_tarifarias.outros;
                  fatura_tarifarias.azul =
                    fatura_tarifarias.azul + fatura_tarifarias.outros;
                  fatura_tarifarias.verde =
                    fatura_tarifarias.verde + fatura_tarifarias.outros;
                }
              }
              if (
                item.referente === 'Consumo Exc. Reativo PTA' ||
                item.referente === 'Consumo Exc. Reativo FP' ||
                item.referente === 'Consumo Exc. Reativo'
              ) {
                const valor_energia =
                  (tarifaB1 / 1000 / ((1 - (cofins + pis)) * (1 - icms))) *
                  item.item_consumption_1;
                if (contador === 1) {
                  fatura_tarifarias.reativosFatura += item.item_total_value_1;
                  fatura_tarifarias.azul += valor_energia;
                  fatura_tarifarias.reativosAzul += valor_energia;
                  fatura_tarifarias.verde += valor_energia;
                  fatura_tarifarias.reativosVerde += valor_energia;
                }
              }
              if (
                item.referente === 'Demanda EXC. Reativa PTA' ||
                item.referente === 'Demanda EXC. Reativa FP' ||
                item.referente === 'Demanda EXC. Reativa'
              ) {
                if (contador === 1) {
                  fatura_tarifarias.reativosFatura += item.item_total_value_1;
                  fatura_tarifarias.azul += item.item_total_value_1;
                  fatura_tarifarias.reativosAzul += item.item_total_value_1;
                  fatura_tarifarias.verde += item.item_total_value_1;
                  fatura_tarifarias.reativosVerde += item.item_total_value_1;
                }
              }
            }
          });
          contador += 1;
        }
      }
      fatura_tarifarias.fatura =
        fatura_tarifarias.adcionalDemandaFatura / 2 +
        fatura_tarifarias.consumoFatura / 2 +
        fatura_tarifarias.demandaUltrapFatura / 2 +
        fatura_tarifarias.reativosFatura +
        fatura_tarifarias.outros +
        fatura_tarifarias.demandaFatura / 2;
    });

    if (fatura_tarifarias.azul !== 0 && fatura_tarifarias.verde !== 0) {
      valores.push(fatura_tarifarias);
    }
  });

  return valores;
};

export { realizaCalculosTarifa };
