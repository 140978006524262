import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as S from '../style';
import { useConsumerGroup, useUsers } from 'hooks';
import { IoseButton } from 'components';
import useCreateUserAlarm from 'hooks/useCreateUserAlarms';
import { IoseErroMessage, IoseSuccessMessage } from 'components/IoseAlert';
import IoseDynamicSelect from 'components/IoseDynamicSelect';

export default function UsuariosXAlarmesAdicionar() {
  const { emailsForSelect } = useUsers();
  const { telemetryConsumerGroups, telemetryConsumerGroupsForSelect } = useConsumerGroup();
  const [notificationType, setNotificationType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const criarUsuariosXAlarmes = useCreateUserAlarm()

  const alarms = [
    {
      label: 'Sem medição',
      value: 'Sem medicao'
    },
    {
      label: 'Queda de energia',
      value: 'Queda de energia'
    },
    {
      label: 'Ultrapassagem de demanda',
      value: 'Ultrapassagem de demanda'
    },
    {
      label: 'Excesso de Reativos',
      value: 'Excesso de Reativos'
    },
  ]

  const initialValues = {
    email_usuario: '',
    unidade_consumidora: '',
    numero_telemetria: '',
    tipo_alarme: '',
    push_token: ''
  };

  const onSubmit = (values, { setSubmitting, resetForm, }) => {
    setIsSubmitting(true)
    criarUsuariosXAlarmes.mutate(values, {
      onSuccess: () => {
        setSubmitting(false);
        setNotificationType(1);
        resetForm()
        setTimeout(() => {
          setNotificationType();
        }, 5000);
        setIsSubmitting(false)
      },
      onError: () => {
        setSubmitting(false);
        setNotificationType(2)
        resetForm()
        setTimeout(() => {
          setNotificationType();
        }, 5000);
        setIsSubmitting(false)
      },
    });
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <S.FormDivContainer>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Usuário *"
                value={values.email_usuario}
                onChange={(value) => setFieldValue('email_usuario', value)}
                options={emailsForSelect}
                width="200px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="UC *"
                value={values.unidade_consumidora}
                onChange={(selectedValue) => {
                  setFieldValue('unidade_consumidora', selectedValue);

                  const selectedGroup = telemetryConsumerGroups.find(
                    (group) => group.identification_number === selectedValue
                  );

                  setFieldValue('numero_telemetria', selectedGroup?.numero_telemetria || '');
                }}
                options={telemetryConsumerGroupsForSelect}
                width="200px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <IoseDynamicSelect
                label="Tipo do alarme *"
                value={values.tipo_alarme}
                onChange={(value) => setFieldValue('tipo_alarme', value)}
                options={alarms}
                width="200px"
              />
            </S.FormDivItem>

            <S.FormDivItem>
              <S.Control fullWidth>
                {/* <S.Label id="push_token">
                  Token
                </S.Label> */}
                <Field
                  as={S.TextFieldSelectStyled}
                  name="push_token"
                  labelid="push_token"
                  id="push_token"
                  type={'text'}
                  required={true}
                  label="Token"
                >
                </Field>
              </S.Control>
            </S.FormDivItem>


            <IoseButton
              height='35px'
              type="submit"
              disabled={isSubmitting}>
              ADICIONAR
            </IoseButton>

            {notificationType === 1 && (
              <IoseSuccessMessage text='Registro cadastrado com sucesso!' />
            )}

            {notificationType === 2 && (
              <IoseErroMessage text='Erro ao cadastrar registro!' />
            )}
          </S.FormDivContainer>
        </Form>
      )}
    </Formik>
  );
}
