import styled from 'styled-components';

//Imports from Material UI components
import { Grid, Card } from '@material-ui/core';

export const Container = styled(Card)`
  padding: 20px;
  flex-direction: column;
  width: 100%;

  p {
    /* text-align: center;
    margin-bottom: 20px; */

    b {
      color: var(--alert);
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h3 {
    span {
      display: flex;
      align-items: center;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      margin-right: 5px;
    }
  }

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;

    h3 {
      span {
        margin-bottom: 16px;
      }
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const TitleTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 16px;

  span {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 5px;
    }
  }

  @media (max-width: 767px) {
    margin: 40px 0px 32px 0px;
  }
`;

export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  direction: props.direction,
  justify: props.justify,
  alignItems: props.alignItems ? props.alignItems : 'flex-start',
  alignContent: 'flex-start',
  spacing: 2,
}))`
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : '24px'};
  margin-left: ${(props) => (props.left ? props.left : '0px')};
  margin-right: ${(props) =>
    props.marginright ? props.marginright : '0px'} !important;
  padding: ${(props) => props.padding} !important;
  border: ${(props) => props.border === 'true' && 'solid 1px rgb(0,0,0,0.2)'};
  border-radius: ${(props) => props.border === 'true' && '8px'};
  width: ${(props) => (props.width ? props.width : '100%')};

  @media (max-width: 767px) {
    margin: ${(props) => props.marginmobile};
    padding: ${(props) => props.paddingmobile} !important;
    width: 100%;
  }
  /* border: solid 1px red; */
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: props.xs,
  sm: props.sm,
  md: props.md,
}))`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : 'flex-start'};
  align-items: center !important;
  margin-top: ${(props) => props.margintop} !important;
  margin-bottom: ${(props) => props.marginbottom} !important;
  border: ${(props) => props.border === 'true' && 'solid 1px rgb(0,0,0,0.2)'};
  border-radius: ${(props) => props.border === 'true' && '8px'};

  @media (max-width: 767px) {
    margin-bottom: ${(props) => props.marginbottom} !important;
    padding: ${(props) => props.padding} !important;
    padding-top: ${(props) => props.paddingtop};
    padding-right: ${(props) => props.paddingright} !important;
  }
`;

export const GridSelection = styled(GridItem)`
  justify-content: center;
`;

export const GridChartContainer = styled.div`
  overflow-x: ${(props) => (props.data > 5 ? 'scroll' : 'hidden')};
  overflow-y: hidden;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  /* &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px !important;
    background-color: red; */
  /* } */
  /* &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  } */
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const GridChart = styled(Grid).attrs((props) => ({
  item: true,
}))`
  height: 340px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 20px;
    width: ${(props) => (props.data > 5 ? '1000px' : 'auto')};
  }
`;

export const GridButton = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 1,
}))`
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
    margin: ${(props) =>
      props.marginmobile ? props.marginmobile : '32px 0px'};
    padding: 0% !important;
  }
`;

export const Error = styled.div`
  color: red;
  margin-bottom: 16px;
  font-weight: bolder;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 0px;
    text-align: left;
  }
`;
