import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
      margin:0;
      padding:0;
      box-sizing: border-box;
      /* color: var(--secondary); */
      font-family: 'Montserrat', sans-serif !important;
      font-size: 13px;
    }

  html,body, #root{
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100%;
    background: var(--base);
  }

  *, button, input{
      border: 0;
      background: none;

  }

  html {
      background: var(--base);
  }

  :root {
      --primary:#015A9E;
      --secondary:#FFFF;
      --base:#ECF0F5;
      --alert:#D60606;
      --success:#00A65A;
      --goal:#4F7CAC;
      --gray: gray;
      --dark: black;
      --primaryback:#015A9E40;
  }

`;
