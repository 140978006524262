import React from "react";
import * as S from '../style'
import IoseModalTextField from "components/IoseModalTextField";

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillsModalAclContentHeader({ faturasAcl }) {

  const fatura = faturasAcl[0]

  return (
    <>
      <div>
        <S.Header>
          <S.HeaderItem>
            <S.TypographyTitle>
              DADOS DA FATURA ACL
            </S.TypographyTitle>
          </S.HeaderItem>
        </S.Header>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '10px',
          }}
        >
          <div style={{ width: '35%' }}>
            <IoseModalTextField label="Endereço" value={fatura?.address} />
          </div>

          <div style={{ width: '35%' }}>
            <IoseModalTextField
              label="Cidade"
              value={fatura?.city}
            />
          </div>

          <div style={{ width: '35%' }}>
            <IoseModalTextField
              label="Comercializadora"
              value={fatura?.comercializadora}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Modalidade"
          value={fatura?.modalidade}
        />

        <IoseModalTextField
          label="Subgrupo"
          value={convertNumberToDecimalStyle(fatura?.subgrupo)}
        />

        <IoseModalTextField
          label="Mês de referência"
          value={fatura?.reference_month}
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
        }}
      >
        <IoseModalTextField
          label="Unidade"
          value={fatura?.un}
        />

        <IoseModalTextField label="Atividade" value={fatura?.atividade} />
      </div>
    </>
  )
}