import * as XLSX from 'xlsx';

const convertToKWH = (valor) => {
  return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export default async function exportConsumoToExcel(
  data
) {
  if (data[0].unidade === undefined) {
    const final = []
    data.forEach((circuito) => {
      let corrente_media = 0
      let tensao_media = 0
      circuito.data.data.measures.map((measures)=> {
        corrente_media += measures.current
        tensao_media += measures.voltage
      })
      const numero_pacotes = circuito.data.data.measures.length
      if (numero_pacotes > 0) {
        final.push({
          'circuito': circuito.data.data.uuid_circuit,
          'leitura_inicial_consumido': convertToKWH(circuito.data.data.measures[numero_pacotes - 1].import_active_energy/1000),
          'leitura_final_consumido': convertToKWH(circuito.data.data.measures[0].import_active_energy/1000),
          'leitura_inicial_gerado': convertToKWH(circuito.data.data.measures[numero_pacotes -1].export_active_energy/1000),
          'leitura_final_gerado': convertToKWH(circuito.data.data.measures[0].export_active_energy/1000),
          'consumido': convertToKWH((circuito.data.data.measures[0].import_active_energy/1000 - circuito.data.data.measures[numero_pacotes -1].import_active_energy/1000).toFixed(2)), 
          'gerado': convertToKWH((circuito.data.data.measures[0].export_active_energy/1000 - circuito.data.data.measures[numero_pacotes -1].export_active_energy/1000).toFixed(2)),
          'corrente': convertToKWH((corrente_media/numero_pacotes).toFixed(2)),
          'fator_potencia': circuito.data.data.measures[0].power_factor,
          'tensao': convertToKWH((tensao_media/numero_pacotes).toFixed(2))
        })
      }
      else {
        final.push({
          'circuito': circuito.data.data.uuid_circuit,
          'leitura_inicial_consumido': 0,
          'leitura_final_consumido': 0,
          'leitura_inicial_gerado': 0,
          'leitura_final_gerado': 0,
          'consumido': 0,
          'gerado': 0,
          'corrente': 0,
          'fator_potencia': 0,
          'tensao': 0,
        })
      }
    })
    
    const headers = {
      circuito: 'ID do circuito',
      leitura_inicial_consumido: 'Leitura inicial de consumo (kWh)',
      leitura_final_consumido: 'Leitura final de consumo (kWh)',
      leitura_inicial_gerado: 'Leitura inicial de geração (kWh)',
      leitura_final_gerado: 'Leitura final de geração (kWh)',
      consumido: 'Consumo (kWh)',
      gerado: 'Geração (kWh)',
      corrente: 'Corrente média (A)',
      fator_potencia: 'Fator de potência',
      tensao: 'Tensão média (V)'
    };
  
    const reorderedColumns = [
      'circuito',
      'leitura_inicial_consumido',
      'leitura_final_consumido',
      'leitura_inicial_gerado',
      'leitura_final_gerado',
      'consumido',
      'gerado',
      'corrente',
      'fator_potencia',
      'criado',
      'tensao'
    ];
    const worksheet = XLSX.utils.json_to_sheet(final, {
      header: reorderedColumns,
    });
  
    // Aplicando os rótulos aos cabeçalhos das colunas
    reorderedColumns.forEach((column, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].v = headers[column];
    });
  
    // Criando o arquivo Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
    XLSX.writeFile(workbook, 'relatorio-consumo-unidade-iose.xlsx');
  }
  else {
    const final = [] 
    data.forEach((unidade) => {
      if (unidade?.medicao.length > 0) {
        let consumoUnidade = 0
        let tensao_media = 0
        let corrente_media = 0
        let leitura_inicial = 0
        let leitura_final = 0
        unidade.medicao.map((medicao) => {
          let tensao = 0
          let corrente = 0
          const numeroMedicoes = medicao.data.data.measures.length
          if (numeroMedicoes > 0) {
            consumoUnidade += (medicao.data.data.measures[0].import_active_energy/1000 - medicao.data.data.measures[numeroMedicoes -1].import_active_energy/1000)
            medicao.data.data.measures.map((measures)=> {
              corrente += measures.current
              tensao += measures.voltage
            })
            corrente_media += corrente/numeroMedicoes
            tensao_media += tensao/numeroMedicoes 
            leitura_inicial += medicao.data.data.measures[numeroMedicoes -1].import_active_energy/1000
            leitura_final += medicao.data.data.measures[0].import_active_energy/1000
          }
        })
        final.push({
          'unidade': unidade.unidade,
          'leitura_inicial': convertToKWH(leitura_inicial),
          'leitura_final': convertToKWH(leitura_final),
          'consumo_medido': convertToKWH(consumoUnidade),
          'consumo_estimado': convertToKWH(unidade.estimado),
          'consumo_fatura': convertToKWH(unidade.fatura),
          'corrente': convertToKWH(corrente_media),
          'tensao': convertToKWH(tensao_media),
          'circuitos': unidade.circuitos.length
        })
      } else {
        final.push({
          'unidade': unidade.unidade,
          'leitura_inicial': 0,
          'leitura_final': 0,
          'consumo_medido': 0,
          'consumo_estimado': unidade.estimado,
          'consumo_fatura': unidade.fatura,
          'corrente': 0,
          'tensao': 0,
          'circuitos': unidade.circuitos.length
        })
      }
    })
    const headers = {
      unidade: 'Unidade',
      leitura_inicial: 'Leitura inicial(kWh)',
      leitura_final: 'Leitura final (kWh)',
      consumo_medido: 'Consumo medido(kWh)',
      consumo_estimado: 'Consumo estimado (kWh)',
      consumo_fatura: 'Consumo fatura (kWh)',
      corrente: 'Corrente média (A)',
      circuitos: 'Quantidade de circuitos',
      tensao: 'Tensão média (V)'
    };
  
    const reorderedColumns = [
      'unidade',
      'leitura_inicial',
      'leitura_final',
      'consumo_medido',
      'consumo_estimado',
      'consumo_fatura',
      'corrente',
      'circuitos',
      'tensao'
    ];
    const worksheet = XLSX.utils.json_to_sheet(final, {
      header: reorderedColumns,
    });
  
    // Aplicando os rótulos aos cabeçalhos das colunas
    reorderedColumns.forEach((column, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].v = headers[column];
    });
  
    // Criando o arquivo Excel
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
    XLSX.writeFile(workbook, 'relatorio-consumo-unidade-iose.xlsx');
  }
}
