import styled from 'styled-components';

import {
  CardHeader,
  Card,
  Checkbox,
  List,
  Button,
  ListItemText,
} from '@material-ui/core';

export const CardHeaderStyled = styled(CardHeader)`
  background-color: var(--primary);
  border-bottom: solid 1px rgba(0, 0, 0, 0.8);

  .MuiTypography-displayBlock {
    color: var(--base);
  }
`;

export const CardStyled = styled(Card)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.5);
  background-color: var(--primary);
  color: var(--base);
  border: 1px solid var(--secondary);
`;

export const CheckboxStyled = styled(Checkbox)`
  color: var(--secondary);

  .MuiSvgIcon-root {
    color: var(--secondary) !important;
  }
`;

export const ListStyled = styled(List)`
  background: none;
  color: var(--secondary);
  overflow-y: scroll;
  padding: 0px !important;

  cursor: pointer;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 2px;
  }
`;

export const ButtonStyled = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: white;
  margin: 0.5em 0em;
`;

export const ListItemTextStyled = styled(ListItemText)`
  .MuiTypography-colorTextSecondary {
    color: rgb(255, 255, 255, 0.5);
    margin: 0px;
  }
`;
