import styled from 'styled-components';

//Imports from IOSE components
import IoseSideCard from 'components/IoseSideCard';

//Imports from Material Ui components
import { Avatar, Grid, CardHeader } from '@material-ui/core';

//Imports images
import TCIconImg from './img/tcIcon.svg';
import circuitIconImage from './img/circuitIcon.svg';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import CropFreeIcon from '@material-ui/icons/CropFree';

//General
export const Container = styled(IoseSideCard)`
  flex-direction: column;
  width: 100%;
  color: var(--base);
  margin-top: 10px !important;
  padding-bottom: 8px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

//Header
export const DivHeaderButton = styled(Grid).attrs(() => ({
  container: true,
}))`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.1s ease-in-out;
  border: 2px solid transparent;
  margin-bottom: 24px;
`;

export const TitleHeader = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 6,
  lg: 6,
  sm: 6,
}))`
  display: flex;
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0px;
  color: var(--base);
  width: 100%;

  span {
    white-space: wrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--base);

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .MuiCardHeader-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.15s linear;
  }
`;

export const CircuitIcon = styled.img.attrs((props) => ({
  src: circuitIconImage,
}))`
  width: 24px;
  height: 24px;
`;

export const AvatarStyledHeader = styled(Avatar).attrs((props) => ({
  variant: 'rounded',
}))`
  background: var(--primary);
`;

export const ContainerButtons = styled(Grid).attrs(() => ({
  xs: 12,
  md: 6,
  lg: 6,
  sm: 6,
  item: true,
}))`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

//Content
export const GridContainer = styled(Grid).attrs((props) => ({
  container: true,
  direction: 'row',
  spacing: 1,
}))`
  padding: 0px 16px 16px 16px;
`;

export const GridItem = styled(Grid).attrs((props) => ({
  item: true,
  xs: 12,
  sm: 12,
  md: 4,
}))`
  display: flex;
  flex-direction: row;

  b {
    margin-right: 15px;
  }

  @media (max-width: 767px) {
    padding-left: 0px !important;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin-right: 15px;
  }
`;

export const Subtitle = styled.div`
  color: rgb(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  div {
    display: flex;
    align-items: center;

    p {
      margin-left: 10px;
      font-size: 12px;

      @media (max-width: 767px) {
        width: 80%;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export const Error = styled.div`
  color: red;
  font-size: 12px;
  text-align: center;
  width: 100%;
`;

export const TCIcon = styled.img.attrs((props) => ({
  src: TCIconImg,
}))`
  width: 15px;
  height: 15px;
`;

export const ContainerInfo = styled.div`
  padding: 0px 12px 12px 0px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media (max-width: 767px) {
      margin-bottom: 8px;
    }

    p {
      margin-left: 10px;
      font-size: 12px;
    }
  }

  p {
    margin-left: 15px;
    font-size: 12px;

    @media (max-width: 767px) {
      margin-left: 30px;
      margin-bottom: 4px;
    }
  }

  @media (max-width: 767px) {
    padding: 0px 12px 24px;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const LogIcon = styled(FormatListBulleted)`
  width: 22px;
  height: 24px;
`;

export const QRIcon = styled(CropFreeIcon)`
  width: 15px;
  height: 15px;
`;

export const ContainerLogTable = styled.div`
  padding-left: 48px;

  @media (max-width: 767px) {
    padding-left: 16px;
  }
`;

export const HeaderLogTable = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 12px;
    font-size: 14px;
    letter-spacing: 0.4px;
  }
`;

export const ContainerButton = styled.div`
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 16px;
  text-align: right;
`;
