import React, { useState, useContext, useEffect } from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as S from './style';

import {
  IoseCardObjects,
  IoseSubHeaderBar,
  IoseAddButton,
  IoseSearch,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseObjectAddModalEnv,
  IoseObjectEditModalEnv,
} from 'components';

import { ioseAPI, AccountContext, Messages } from 'common';

import { ContainerCards } from 'containers/EnvironmentContainer/style';
import { useClientData, useEnvironments, useObjects, useUnitys } from 'hooks';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '400px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
}));

export default function IoseEnvironmentOpenObjects({
  open,
  onClose,
  selectedObject,
}) {
  const [addNotificationType, setAddNotificationType] = useState(0);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [cardSelected, setCardSelected] = useState(false);
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedUnity, setSelectedUnity] = useState(null);
  const [selectedItemWithObjects, setSelectedItemWithObjects] = useState(null);


  const classes = useStyles();
  const { getSession } = useContext(AccountContext);
  const { refreshObjects, objects } = useObjects()
  const { group, uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { environments } = useEnvironments()

  useEffect(() => {
    getObjectsCards();
    //eslint-disable-next-line
  }, [selectedItemWithObjects]);

  const getObjectsCards = () => {
    setCards(selectedItemWithObjects?.objects);
  };

  useEffect(() => {
    function handleSelectedUnity(selectedCard) {
      const selectedUnity = unitys?.find(
        (unity) => unity.uuid_unity === selectedCard?.uuid_unity
      );

      setSelectedUnity(selectedUnity);
    }

    handleSelectedUnity(selectedObject);
  }, [selectedObject, unitys]);


  useEffect(() => {
    function handleSelectedObject(selectedObject, selectedUnity) {
      if (selectedObject && selectedUnity) {
        const objectsWithSameEnvironment = objects?.filter(
          (obj) => obj.uuid_environment === selectedObject.uuid_environment
        );

        const newObject = {
          name: selectedObject?.name ? selectedObject?.name : 'Não cadastrado',
          description: selectedObject?.description,
          name_unity: selectedUnity?.name,
          uuid_unity: selectedObject.uuid_unity,
          uuid_environment: selectedObject.uuid_environment,
          id_3d: selectedObject?.id_3d,
          objects: objectsWithSameEnvironment,
        };

        setSelectedItemWithObjects(newObject);
      }
    }
    handleSelectedObject(selectedObject, selectedUnity);
  }, [selectedObject, selectedUnity, objects]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = (environment) => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const filterObjects = (searchName) => {
    setSearch(true);

    const searchNameUppcase = searchName?.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item?.name?.toUpperCase().includes(searchNameUppcase)
      );

      setCardsFiltered(itemFiltered);
    } else {
      setSearch(false);
    }
  };

  const renderObjectsCards = (cards) => {
    return (
      <IoseCardObjects
        key={cards.uuid_environment}
        uuid_environment={cards.uuid_environment}
        name={cards.name}
        description={cards.description}
        voltage={cards.voltage}
        power={cards.power}
        current={cards.current}
        temperature={cards.temperature}
        condition={cards.condition}
        charge_type={cards.charge_type}
        handleOpenEditModal={handleOpenEditModal}
        onClose={handleCloseEditModal}
        environments={environments}
        onClick={() => handleCardClick(cards)}
        showEditButton={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = group === 'super' || group === 'admin' ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR OBJETO NESSE AMBIENTE"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={selectedObject?.name}
        subtitle="Objetos"
        description={`Total: ${cards?.length ? cards?.length : 0}`}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar objetos…"
          funcSearch={filterObjects}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllObjectsCards = () => {
    return (
      <ContainerCards>
        {search
          ? cardsFiltered?.map(renderObjectsCards)
          : cards?.map(renderObjectsCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (cards?.length === 0) {
      return <IoseAlertLoadingCards text='Nenhum objeto encontrado neste ambiente!' />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length ? cards.length : 5}
            hasMore={true}
            loading={loading}
            endList={false}
            scrollableTarget={'container'}
          >
            {renderAllObjectsCards()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModalHeader = () => {
    return (
      <S.HeaderModal>
        <S.LeftHeaderModal>
          <S.RoomIcon />
        </S.LeftHeaderModal>
        <S.RightHeaderModal>
          <S.ModalTitleHeader variant="h5" gutterBottom>
            {selectedObject?.name?.toUpperCase()}
          </S.ModalTitleHeader>
        </S.RightHeaderModal>
      </S.HeaderModal>
    );
  };

  const renderModals = () => {
    return (
      <>
        <IoseObjectAddModalEnv
          open={openAddModal}
          onClose={handleCloseAddModal}
          selectedObject={selectedItemWithObjects}
          addObjects={addObjects}
          loading={loading}
          notification={notification}
          addNotificationType={addNotificationType}
          showNotification={showNotification}
        />
        <IoseObjectEditModalEnv
          open={openEditModal}
          onClose={handleCloseEditModal}
          environments={environments}
          selectedObject={selectedItemWithObjects}
          editObject={editObject}
          loading={loading}
          notification={notification}
          editNotificationType={editNotificationType}
          cardSelected={cardSelected}
          showNotification={showNotification}
        />
      </>
    );
  };

  function errorHandlingAddObjects(error) {
    setLoading(false);

    setNotification(error);
    setAddNotificationType(2);
  }

  async function addObjects(
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type
  ) {
    setAddNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.createObjects(
        sessionData.headers,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type
      );

      setLoading(false);
      setNotification(response.data.message);
      setAddNotificationType(1);
      setShowNotification(true);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingAddObjects(errorMessage);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseAddModal();
        refreshObjects();
        setAddNotificationType(0);
        setShowNotification(false);
        onClose();
      }, 2500);
    }
  }

  function errorHandlingEditUnity(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  async function editObject(
    uuid_object,
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type,
    id_3d
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateObjects(
        sessionData.headers,
        uuid_object,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type,
        id_3d
      );

      setNotification(response.data.message);
      setEditNotificationType(1);
      setShowNotification(true);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingEditUnity(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseEditModal();
        refreshObjects();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  function handleCardClick(card) {
    setCardSelected(card);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          {renderModalHeader()}
          <S.WrappedModal>
            <S.ObjectPaper>
              {renderSubHeaderAndSearchBar()}
              {renderContent()}
              {renderModals()}
            </S.ObjectPaper>
          </S.WrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
