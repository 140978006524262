import React, { useState, useEffect } from 'react';

//Imports from Material UI components
import {
  makeStyles,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
} from '@material-ui/core';

//Imports from Style
import {
  TableStyled,
  TableContainerStyled,
  TablePaginationStyled,
  TableCellHeader,
  TextCell,
} from './style';

/** This component render a responsive table with unity's schedulings, pagination and ordination */
export default function IoseEventsTable({ rows, getAllAlarms, inFilter,formatDate,setOrderingCSV }) {
  //State to order the table asc and desc
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  //States to pagination's table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    !inFilter && getAllAlarms(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    setOrderingCSV(
      {
        order:order,
        orderBy:orderBy
      }
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order,orderBy]);

  //Css class to hidden the order label
  const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
      textColor: '#FFFFFF',
    },
  }));

  const classes = useStyles();

  //Functions to order
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Data',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'relay',
      numeric: false,
      disablePadding: false,
      label: 'Relé',
    },
    {
      id: 'network',
      numeric: false,
      disablePadding: false,
      label: 'Conexão',
    },
    {
      id: 'power',
      numeric: false,
      disablePadding: false,
      label: 'Carga',
    },
  ];

  //Function to render header
  function EnhancedTableHead({ classes, order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCellHeader
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCellHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function chooseColorText(data) {
    if (data.includes('Não') || data.includes('Aberto')) {
      return 'red';
    } else {
      return 'var(--success)';
    }
  }

  return (
    <>
      <TableContainerStyled>
        <TableStyled>
          {/* Header */}
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          {/* Body */}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{formatDate(row.created_at)}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      <TextCell color={chooseColorText(row.relay)}>
                        {row.relay}
                      </TextCell>
                    </TableCell>
                    <TableCell>
                      <TextCell color={chooseColorText(row.network)}>
                        {row.network}
                      </TextCell>
                    </TableCell>
                    <TableCell>
                      <TextCell color={chooseColorText(row.power)}>
                        {row.power}
                      </TextCell>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>

      {/* Pagination */}
      <TablePaginationStyled
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={'Eventos por página'}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} ao ${to} de ${count !== -1 ? count : `mais que ${to}`}`
        }
        SelectProps={{
          id: 'stable-select-id',
          labelId: 'stable-select-label-id',
        }}
      />
    </>
  );
}
