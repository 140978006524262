// hooks/useContractEffects.js
import { useContext, useState } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUsers() {
  const [noUsersError, setNoUsersError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCognitoUsers = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.listCognitoUsersAndGroups(headers);

    const users = response?.data;

    if (!users || users.length === 0) {
      setNoUsersError(Messages.noRegisteredUsers);
      throw new Error(Messages.noRegisteredUsers);
    }

    const allEmails = users.map(user =>
      user.Attributes.find(attr => attr.Name === 'email')?.Value
    );

    const emails = allEmails?.slice().sort((a, b) => a.localeCompare(b));

    const emailsForSelect = emails?.map(email => ({
      value: email,
      label: email
    }));

    return { users, emails, emailsForSelect };
  };

  const {
    data: { users, emails, emailsForSelect  } = {},
    error: usersError,
    refetch: refreshUsers,
    isLoading,
  } = useQuery(['usersData'], getCognitoUsers);

  return {
    users: users || [],
    emails: emails || [],
    emailsForSelect: emailsForSelect || [],
    usersError,
    noUsersError,
    refreshUsers,
    isLoading,
  };
}

export default useUsers;
