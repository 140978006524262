import React from 'react';
import PropType from 'prop-types';

//Imports from Material UI components
import {
  Container,
  GridContainer,
  GridItem,
  AvatarStyled,
  Header,
  SwitchboardIcon,
  ConsumptionEnergyIcon,
  GenerationEnergyIcon,
} from './style';

//Imports from Material Icons
// import PowerIcon from '@material-ui/icons/Power';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

/** This component create a card in dashboard with consumption, consumption, goal and flag value about switchboard selected */
export default function IoseDashboardInfoSwitchboard({
  consumption,
  generation,
  money,
  flag,
  goal,
  totalAddFlag,
}) {
  // let totalAddFlagFixed = totalAddFlag && totalAddFlag.toFixed(2);

  //Array info to render the GridItem with circuit info chosen in list circuit dashboard
  let info = [
    {
      name: 'Consumo',
      value: convertNumberToDecimalStyle(consumption) + ' kWh',
    },
    {
      name: 'Valor Consumido',
      value: convertNumberToMonetaryStyle(money),
    },
    {
      name: 'Geração',
      value: convertNumberToDecimalStyle(generation) + ' kWh',
    },
    { name: 'Meta', value: convertNumberToDecimalStyle(goal) + ' kWh' },
  ];

  function convertNumberToMonetaryStyle(number = 0) {
    // number = typeof number === 'string' && Number(number);

    const money = number.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  function chooseColor(name) {
    let color = '';

    switch (name) {
      case 'Consumo':
        color = 'var(--secondary)';
        break;

      case 'Valor Consumido':
        color = 'var(--primary)';
        break;

      case 'Geração':
        color = '#FFF';
        break;

      case 'Valor Gerado':
        color = '#8DA255';
        break;

      case 'Meta':
        color = 'var(--goal)';
        break;

      case 'Bandeira':
        color = chooseColorFlag();
        break;

      default:
        color = 'white';
    }

    return color;
  }

  function chooseColorFlag() {
    let color = '';

    switch (flag) {
      case 'verde':
        color = 'var(--success)';
        break;

      case 'amarela':
        color = 'var(--primary)';
        break;

      case 'vermelha p1':
        color = 'var(--alert)';
        break;

      case 'vermelha p2':
        color = 'var(--alert)';
        break;

      default:
        color = 'white';
    }

    return color;
  }

  const renderInfo = (info) => {
    return (
      <GridItem key={info.name} md={info.name === 'Bandeira' ? 4 : 2}>
        <AvatarStyled color={chooseColor(info.name)}>
          {info.name === 'Consumo' ? (
            <ConsumptionEnergyIcon />
          ) : info.name === 'Valor Consumido' ? (
            <AttachMoneyIcon />
          ) : info.name === 'Geração' ? (
            <GenerationEnergyIcon />
          ) : info.name === 'Valor Gerado' ? (
            <MoneyOffIcon />
          ) : (
            <TrendingUpIcon />
          )}
        </AvatarStyled>
        <div>
          <h4>{info.name}</h4>

          <div>
            <h2>{info.value}</h2>
          </div>
        </div>
      </GridItem>
    );
  };

  return (
    <Container>
      <Header>
        <SwitchboardIcon />
        <h3>QUADRO</h3>
      </Header>
      <GridContainer
        justify="flex-start"
        alignItems="center"
        alignContent="center"
      >
        {info.map(renderInfo)}
      </GridContainer>
    </Container>
  );
}

IoseDashboardInfoSwitchboard.propTypes = {
  /** This props get the energy consumption switchboard selected. This info comes from container */
  consumption: PropType.oneOfType([PropType.string, PropType.number]),
  /** This props get the money switchboard selected. This info comes from container */
  money: PropType.oneOfType([PropType.string, PropType.number]),
  /** This props get the flag switchboard selected. This info comes from container */
  flag: PropType.string,
  /** This props get the goal switchboard selected. This info comes from container */
  goal: PropType.oneOfType([PropType.string, PropType.number]),
};
