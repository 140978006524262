import React, { useState } from 'react';
import PropType from 'prop-types';
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ptLocale from 'date-fns/locale/pt-BR';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import Logo from './img/logo-iose-black.jpg';
import * as S from './style';

import {
  IoseButton,
  ReportMessage,
  NoDataMessage,
  DateErrorMessage,
} from 'components';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function CarbonMonthlyContainer() {
  const [data, setData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const [dateError, setDateError] = useState(false);
  const [noDataError, setNoDataError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#015A9E',
      },
    },
  });

  const fetchData = async () => {
    if (!startDate || !endDate) {
      setDateError(true);
      setNoDataError(true);
      return;
    }

    const dataInicio = startDate ? new Date(startDate + '-01') : new Date();
    const dataFim = endDate ? new Date(endDate + '-01') : new Date();

    if (dataFim < dataInicio) {
      setDateError(true);
      return;
    } else {
      setDateError(false);
    }

    const startYear = dataInicio.getFullYear();
    const startMonth = dataInicio.getMonth() + 1;
    const endYear = dataFim.getFullYear();
    const endMonth = dataFim.getMonth() + 1;

    try {
      const response = await axios.get(
        'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/scanCarbonMonthlyTable',
        {
          params: { startYear, startMonth, endYear, endMonth },
        }
      );
      const parsedData = JSON.parse(response.data.body);

      setSearchPerformed(true);

      const filteredData = parsedData.filter((item) => {
        const ano = parseInt(item.ano, 10);
        const mes = parseInt(item.mes, 10);
        const inicioAno = dataInicio.getFullYear();
        const inicioMes = dataInicio.getMonth() + 1;
        const fimAno = dataFim.getFullYear();
        const fimMes = dataFim.getMonth() + 1;

        return (
          (ano > inicioAno || (ano === inicioAno && mes >= inicioMes)) &&
          (ano < fimAno || (ano === fimAno && mes <= fimMes))
        );
      });

      if (filteredData.length === 0) {
        setNoDataError(true);
      } else {
        setNoDataError(false);
      }

      setData(filteredData);
    } catch (error) {
      throw new Error(error);
    }
  };

  const convertImageToBase64 = (imagePath) => {
    return fetch(imagePath)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  const generatePDF = async (data) => {
    data.sort((a, b) => a.mes - b.mes);

    const totalKwh = data.reduce(
      (sum, item) => sum + parseFloat(item.total_kwh),
      0
    );
    const totalTco2 = data.reduce(
      (sum, item) => sum + parseFloat(item.resultado_total_tco2),
      0
    );

    const formattedStartDate =
      typeof startDate === 'string'
        ? startDate
        : startDate.toISOString().substring(0, 7);
    const formattedEndDate =
      typeof endDate === 'string'
        ? endDate
        : endDate.toISOString().substring(0, 7);

    const logoBase64 = await convertImageToBase64(Logo);

    const documentDefinition = {
      content: [
        {
          columns: [
            {
              image: logoBase64,
              width: 50,
            },
            {
              text: `Relatório de Emissões de Carbono - Período: ${formattedStartDate} a ${formattedEndDate}`,
              style: 'header',

              margin: [15, 0, 0, 0], // Margens: esquerda, superior, direita, inferior
            },
          ],
        },

        {
          style: 'tableStyle',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*'],
            body: [
              // Cabeçalhos da tabela
              [
                { text: 'Ano', style: 'tableHeader' },
                { text: 'Mês', style: 'tableHeader' },
                { text: 'Fator Mensal', style: 'tableHeader' },
                { text: 'Total tCO2', style: 'tableHeader' },
                { text: 'Total kWh', style: 'tableHeader' },
              ],
              // Dados da tabela
              ...data.map((item) => [
                { text: item.ano.toString(), noWrap: true, style: 'tableData' },
                { text: item.mes.toString(), noWrap: true, style: 'tableData' },
                {
                  text: item.fator_mensal.toString(),
                  noWrap: true,
                  style: 'tableData',
                },
                {
                  text: item.resultado_total_tco2.toFixed(2).toString(),
                  noWrap: true,
                  style: 'tableData',
                },
                {
                  text: item.total_kwh.toFixed(2).toString(),
                  noWrap: true,
                  style: 'tableData',
                },
              ]),
            ],
          },
        },
        {
          text: 'Resumo',
          style: 'header',
          pageBreak: 'before',
        },
        {
          style: 'tableStyle',
          table: {
            body: [
              [
                { text: 'Total de Consumo kWh', style: 'tableHeader' },
                { text: totalKwh.toFixed(2), style: 'tableData' },
              ],
              [
                { text: 'Total de Emissão tCO2', style: 'tableHeader' },
                { text: totalTco2.toFixed(2), style: 'tableData' },
              ],
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 5, 0, 10],
        },
        tableStyle: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 8,
          color: 'black',
          fillColor: '#cccccc',
          alignment: 'center',
        },
        tableData: {
          fontSize: 8,
          alignment: 'center',
        },
      },

      pageMargins: [15, 15, 15, 15], // Margens: esquerda, superior, direita, inferior
    };

    pdfMake.createPdf(documentDefinition).download('file.pdf');
  };

  const renderTable = () => {
    return (
      <>
        <h3
          style={{
            fontSize: '23px',
            paddingTop: '5px',
            color: '#015A9E',
            marginTop: '15px',
          }}
        >
          Sanepar
        </h3>
        <h2 style={{ color: 'grey', paddingTop: '11px', marginBottom: '10px' }}>
          Relatório de emissão de CO2
        </h2>

        <S.FilterWrappedContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
            <ThemeProvider theme={theme}>
              <DatePicker
                disableToolbar
                openTo="year"
                views={['year', 'month']}
                variant="inline"
                format="MM/yyyy"
                margin="normal"
                id="startDate"
                label="REF:MÊS/ANO"
                value={startDate}
                onChange={setStartDate}
              />
              <DatePicker
                disableToolbar
                openTo="year"
                views={['year', 'month']}
                variant="inline"
                format="MM/yyyy"
                margin="normal"
                id="endDate"
                label="REF:MÊS/ANO"
                value={endDate}
                onChange={setEndDate}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>

          <IoseButton onClick={fetchData}>Filtrar Dados</IoseButton>

          {searchPerformed && !noDataError && startDate && endDate && (
            <IoseButton onClick={() => generatePDF(data)}>
              Emitir Relatório
            </IoseButton>
          )}
        </S.FilterWrappedContainer>

        {dateError ? (
          <DateErrorMessage message="Ops! A data final não pode ser anterior à inicial." />
        ) : searchPerformed ? (
          noDataError ? (
            <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
          ) : (
            <ReportMessage message="Para emitir o relatório, selecione os filtros desejados e clique em EMITIR RELATÓRIO." />
          )
        ) : (
          <ReportMessage message="Para emitir o relatório, selecione os filtros desejados e clique em EMITIR RELATÓRIO." />
        )}
      </>
    );
  };

  const renderContent = () => {
    return <>{renderTable()}</>;
  };

  return <S.WrappedContainer>{renderContent()}</S.WrappedContainer>;
}

CarbonMonthlyContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
