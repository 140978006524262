import React from 'react';
import { IoseButton } from 'components';
import * as S from '../style';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';

export default function TelemetryDashFilter({
  inicialDataConsumo,
  finalDataConsumo,
  setInicialDataConsumo,
  setFinalDataConsumo,
  obterConsumoGraf30Dias,
  erroPeriodo,
}) {
  const gerenciarDataInicialConsumo = (data) => {
    let dataISOString = data.toISOString();
    setInicialDataConsumo(dataISOString);
  };

  const gerenciarDataFinalConsumo = (data) => {
    let dataISOString = data.toISOString();
    setFinalDataConsumo(dataISOString);
  };

  return (
    <S.FilterContainer>
      <IoseSelectDayMouth
        title="Data inicial"
        theme="black"
        getDate={gerenciarDataInicialConsumo}
        initialDate={inicialDataConsumo}
        margin={'0xp 50px 0px 0px'}
      />

      <IoseSelectDayMouth
        title="Data final"
        theme="black"
        getDate={gerenciarDataFinalConsumo}
        initialDate={finalDataConsumo}
        margin={'0xp 50px 0px 0px'}
      />

      <IoseButton
        theme="black"
        onClick={() => obterConsumoGraf30Dias(inicialDataConsumo, finalDataConsumo)}
      >
        FILTRAR
      </IoseButton>

      {erroPeriodo ? (
        <S.ErrorText>Período máximo de 7 dias.</S.ErrorText>
      ) : null}
    </S.FilterContainer>
  );
};


