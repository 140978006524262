import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function energyBillRecalcReport(data) {
  // const difference_value_total = data?.reduce(
  //   (total, item) => total + item.difference_value,
  //   0
  // );
  const totalObjects = data?.length;

  function convertNumberToMonetaryStyle(number = 0) {
    // number = typeof number === 'string' && Number(number);

    const money = number.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Fatura X Recálculo`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 7,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('UC'),
    generateHeaderCell('Concessionária'),
    generateHeaderCell('Mês Referência'),
    generateHeaderCell('Valor Fatura'),
    generateHeaderCell('valor Recálculo'),
    generateHeaderCell('Diferença Valor'),
  ];
  const renderData = data
    ?.filter((bill) => bill?.identification_number)
    .map((bill) => [
      generateCell(bill.identification_number),
      generateCell(bill.concessionaire),
      generateCell(bill.reference_month),
      generateCell(convertNumberToMonetaryStyle(bill.bill_value)),
      generateCell(convertNumberToMonetaryStyle(bill.recalc_value)),
      generateCell(convertNumberToMonetaryStyle(bill.difference_value)),
    ]);

  const details = [
    {
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: Array(6).fill('*'),
        body: [generateHeaderRow, ...renderData],
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de unidades',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalObjects.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        // [
        //   {
        //     text: 'Total de diferença',
        //     style: 'tableHeader',
        //     fontSize: 8,
        //     alignment: 'left',
        //     bold: true,
        //   },
        //   {
        //     text: convertNumberToMonetaryStyle(difference_value_total),
        //     fontSize: 8,
        //     alignment: 'left',
        //   },
        // ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    // pageSize: {
    //   width: 930, // A4 width
    //   height: 831.89, // A4 height
    // },
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
